import { BaseHelper } from './BaseGraphQLAPIHelper';

const BRAND_RELATED_CONTENT = `
attributeTags {
  edges {
      node {
          pk
          id
          name
          tagKey {
              pk
              id
              name
          }
      }
  }
}
translations {
  edges {
    node {
      language
      name
      description
      pk
    }
  }
}
stores {
  edges {
    node {
      id
      pk
      name
      address
      description
      translations {
        edges {
          node {
            language
            name
            address
            description
            pk
          }
        }
      }
    }
  }
}
`;

export const getBrands = (
  afterCursor = '',
  {
    rank = true,
    searchKey = '',
    search = '',
    isAll = false,
    moreSearch,
    params,
    isSelectorLoad,
  },
) => {
  console.log('@@63: ', params);
  let searchString = isAll ? 'first: 100' : 'first: 20';
  let orderBy = '-pk';
  if (params?.sort || moreSearch?.sort) {
    orderBy = params?.sort || moreSearch?.sort;
  }
  if (afterCursor) {
    searchString += `, after: "${afterCursor}"`;
  }
  searchString += `, orderBy: "${orderBy}"`;
  if (searchKey) {
    if (search) {
      searchString += `, search: "${search}"`;
    } else {
      searchString += `, nameIcontains: "${searchKey}"`;
    }
  }
  console.log('@@22: ', params);
  let filter = '';
  if (params?.handleFortuneBagEntitlement !== undefined) {
    filter += `, handleFortuneBagEntitlement: ${params.handleFortuneBagEntitlement}`;
  }

  if (params?.option !== undefined) {
    filter += `, option: "${params?.option}"`;
  }
  if (params?.goodieBagIn) {
    filter += `, goodieBagIn: "${params?.goodieBagIn}"`;
  }

  const query = `{
    brands(${searchString}${filter}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          name
          icon
          description
          handleFortuneBagEntitlement
          handleOption
          ${
            params?.isSelectorLoad || isSelectorLoad || isAll
              ? ''
              : BRAND_RELATED_CONTENT
          }
        }
      }
    }
  }`;
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getBrand = (brandID) => {
  const query = `
  {
    brand(id: "${brandID}") {
      id
      pk
      name
      description
      icon
      attributeTags {
        edges {
            node {
                pk
                id
                name
                tagKey {
                    pk
                    id
                    name
                }
            }
        }
      }
      translations {
        edges {
          node {
            language
            name
            description
            pk
          }
        }
      }
      stores {
        edges {
          node {
            pk
            id
            name
          }
        }
      }
      handleFortuneBagEntitlement
      handleOption
      relatedFortuneBagCampaign
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteBrand = (inputBody) => {
  const query = `
  mutation DeleteBrands($input: DeleteBrandsInput!) {
    deleteBrands(input: $input) {
      success
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createBrand = (inputBody) => {
  const query = `
  mutation CreateBrand($input: CreateBrandInput!) {
    createBrand(input: $input) {
      node {
        id
        pk
        name
        icon
        description
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateBrand = (inputBody) => {
  const query = `
  mutation UpdateBrand($input: UpdateBrandInput!) {
    updateBrand(input: $input) {
      node {
        id
        pk
        name
        icon
        description
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
