import React, { useEffect, useState } from 'react';

import { useHistory, useLocation, withRouter } from 'react-router-dom';
import CustomBreadcrumb from '../../components/base/CustomBreadcrumb';
import { couponSetBreads } from '../../config/CustomBreadConfig';
import { connect, useDispatch, useSelector } from 'react-redux';
import CustomListComponent from '../../components/base/CustomListComponent';
import { createAction, getHashKeyString } from '../../utils';
import CouponsOverview from '../../components/coupon/couponList/CouponsOverview';
import DateRangeCompare from '../../components/dashboard/DateRangeCompare';
import DateRangeSelection from '../../components/dashboard/DateRangeSelection';

import { TimeFormater } from '../../utils/TimeFormatUtil';

export const TAB_OVERVIEW = '';

export default function CouponsAnalytics() {
  const dispatch = useDispatch();
  const location = useLocation();

  const fromModel = 'COUPON';

  const {
    couponsOverviewDate,
    couponOverviewFilter,
    // singleCouponList,
  } = useSelector((state) => ({
    couponsOverviewDate: state.overview.couponsOverviewDate,
    couponOverviewFilter: state.overview.couponOverviewFilter,
    // singleCouponList: state.singleCoupon.singleCouponList,
  }));

  const [pickerCouponsOverviewDate, setPickerCouponsOverviewDate] = useState(null);
  const tabs = [{
    name: TAB_OVERVIEW,
    content: (pickerCouponsOverviewDate?.startDate) && <CouponsOverview
      couponsOverviewDate={pickerCouponsOverviewDate} />
  }];
  const activeTab = getHashKeyString(location?.hash) || TAB_OVERVIEW;

  const [datePicker, setDatePicker] = useState();

  const requestData = (start, end) => {
    if (!start || !end) {
      return;
    }
    dispatch({ type: 'overview/clearStateExcludeFilter' });
    dispatch(
      createAction('overview/updateState')({
        couponsOverviewDate: {
          startDate: start.format(TimeFormater.GTMFormat),
          endDate: end.format(TimeFormater.GTMFormat),
          startDateWithoutFormat: start,
          endDateWithoutFormat: end,
        },
      }),
    );
  };

  useEffect(() => {
    const start = datePicker?.start;
    const end = datePicker?.end;
    if (start && end) {
      setPickerCouponsOverviewDate({
        startDate: start.format(TimeFormater.GTMFormat),
        endDate: end.format(TimeFormater.GTMFormat),
        startDateWithoutFormat: start,
        endDateWithoutFormat: end,
      })
    }
  }, [datePicker])

  useEffect(() => {
    return () => {
      dispatch({ type: 'overview/clearState' });
    };
  }, []);

  // useEffect(() => {
  //   setFirstTimePickerChange(true);
  // }, [activeTab]);

  useEffect(() => {
    if (couponsOverviewDate?.startDate && couponsOverviewDate?.endDate) {
      console.log('@175', couponOverviewFilter);
      dispatch(
        createAction('overview/getCouponsData')({
          fromModel: fromModel,
          startDate: couponsOverviewDate.startDate,
          endDate: couponsOverviewDate.endDate,
          couponOverviewFilter: {
            brand: couponOverviewFilter?.brand?.pk,
            couponSet: couponOverviewFilter?.couponSet?.pk,
            isStarred: couponOverviewFilter?.isStarred,
          },
        }),
      );
    }
  }, [couponOverviewFilter, couponsOverviewDate]);

  useEffect(() => {
    if (couponsOverviewDate?.startDate && couponsOverviewDate?.endDate) {
      dispatch(
        createAction('overview/getCouponsRakingTable')({
          fromModel: fromModel,
          isStarred: couponOverviewFilter?.isStarred,
        }),
      );
      dispatch(
        createAction('overview/getCouponsTop10RedemptionByDistrict')({
          fromModel: fromModel,
          brand: couponOverviewFilter?.brand?.pk,
          isStarred: couponOverviewFilter?.isStarred,
        }),
      );
      dispatch(
        createAction('overview/getCouponsTop10RedemptionByStore')({
          fromModel: fromModel,
          brand: couponOverviewFilter?.brand?.pk,
          isStarred: couponOverviewFilter?.isStarred,
        }),
      );
    }
  }, [couponsOverviewDate, couponOverviewFilter]);

  return (
    <>
      <CustomListComponent
        breadcrumb={<CustomBreadcrumb />}
        buttons={[]}
        tabs={tabs}
        hideTab={false}
        defaultActiveKey={activeTab}
        caution={{
          detail:
            'Coupon set stores the content and controls a list of coupons. After you create a coupon set, you can generate a list of single coupons for your customers',
          title: 'Coupon Set',
        }}
        renderCompare={() => {
          return activeTab === TAB_OVERVIEW ? (
            <>
              <DateRangeCompare inCustomListComponent />
              <button
                type="button"
                className="btn-further btn btn-primary"
                onClick={() => {
                  requestData(datePicker?.start, datePicker?.end);
                }}
              >
                Refresh
              </button>
            </>
          ) : null;
        }}
        renderTabsRight={() => {
          return (
            <DateRangeSelection
              onTab
              applyDateRangeEvent={(startDate, endDate) => {
                // setFirstTimePickerChange(false);
                setDatePicker({ start: startDate, end: endDate });
                // if (firstTimePickerChange) {
                //   return;
                // }
                // requestData(startDate, endDate);
              }}
              clearDataEvent={() => {
                dispatch(createAction('overview/clearStateExcludeFilter')());
              }}
            />
          );
        }}
      />
    </>
  );
}
