import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { createAction } from '../../../utils';

import CustomTitleWithInput from '../../../components/base/CustomTitleWithInput';
import { hasError } from '../../../components/base/ErrorFieldMessage';
import ContentSections from '../../../components/base/ContentSections';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import BaseMutipleSelectorV2 from '../../../components/base/BaseMultipleSelectorV2';

function ChannelContent({}) {
  const {
    formState: { errors },
    setValue,
    watch,
    register,
  } = useFormContext();

  const param = useParams();

  const watchName = watch('name');
  const watchMarkets = watch('markets');

  const { marketList } = useSelector((state) => ({
    marketList: state.targetMarket.notPagedAllList,
  }));

  return (
    <>
      <label className="create-section-title">General</label>

      <CustomTitleWithInput
        title="Name"
        defaultValue={watchName}
        setValue={(value) => {
          setValue('name', value, {
            shouldDirty: true,
          });
        }}
        error={
          hasError(errors, 'name')
            ? {
                id: 'name',
                error: hasError(errors, 'name'),
                message: 'please input a name',
              }
            : {}
        }
        disabled={!!param.id}
      />

      <BaseMutipleSelectorV2
        title={'Support Market'}
        namespace="targetMarket"
        data={{
          sourceData: marketList,
          targetData: watchMarkets,
          targetChange: (value) => {
            setValue('markets', value, {
              shouldDirty: true,
            });
          },
        }}
        error={
          hasError(errors, 'markets')
            ? {
                id: 'markets',
                error: hasError(errors, 'markets'),
                message: 'please select one market',
              }
            : {}
        }
      />
    </>
  );
}

export default function CreateChannelContent({}) {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const { getValues, setError, clearErrors } = useFormContext();
  const validate = async () => {
    clearErrors(['name', 'markets']);
    const [name, markets] = getValues(['name', 'markets']);

    if (!name) {
      setError(
        'name',
        {
          type: 'require',
          message: 'please input name',
        },
        { shouldFocus: true },
      );
    }

    if (!markets || markets?.length <= 0) {
      setError(
        'markets',
        {
          type: 'require',
          message: 'please select one market',
        },
        { shouldFocus: true },
      );
    }

    return name && markets && markets?.length > 0;
  };

  return (
    <>
      <ContentSections sections={[<ChannelContent />]} hidePreview={true} />
      <SaveAndBackButtons
        saveText={params.id ? 'Update' : 'Save'}
        backAction={() => history.goBack()}
        saveAction={async () => {
          console.log('@@103');
          const isValid = await validate();
          if (isValid) {
            dispatch(createAction('channel/createOrUpdate')(getValues()));
          }
        }}
      />
    </>
  );
}
