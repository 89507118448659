import { authAPIHelper } from '../services/AuthApiHelper';
import { createAction, removeFromSessionStorage } from '../utils/index';
import { AdminLoginMethod, SESSION_KEYS } from '../config/CustomEnums';
import {
  addAuthorization,
  removeAuthorization,
  updateRefreshToken,
  ACCESS_TOKEN,
  REFRESH_TOKEN,
} from '../services/BaseRefreshHelper';
import Cookies from 'cookies-js';
import { apiWithResponseHandle, loading } from './LoadingUtil';
import { getAdminLoginMethod, sendOtpCode } from '../services/AdminApiHelper';

const userPermissionsKey = 'userPermissions';
const allPermissionsKey = 'allPermissions';

export const getUserPermissions = () => {
  const permissions = getPermissionsFromStorage(userPermissionsKey) || [];
  return permissions;
};

const getPermissionsFromStorage = (key) => {
  const storagePermissions = localStorage.getItem(key) || '[]';
  let permissions = [];
  try {
    permissions = JSON.parse(fromBase64(storagePermissions));
  } catch (err) {
    localStorage.removeItem(key);
  }
  return permissions;
};

function toBase64(str) {
  return window.btoa(encodeURIComponent(str));
}

function fromBase64(str) {
  return decodeURIComponent(window.atob(str));
}

const removeAPIHelperToken = () => {
  removeAuthorization();
};

const setUpAPIHelperToken = (token) => {
  addAuthorization(token);
};

const initUser = {
  isLogin: false,
  firstLogin: false,
  loginFailed: false,
  isSuperuser: false,
  allPermissions: [],
  adminType: '',
  userPermissions: [],
  workingTeams: [],
  userExist: true,
  isLoginBySaml: false,
};

export default {
  namespace: 'users',
  state: initUser,

  reducers: {
    updateState(state, { payload }) {
      console.log('@@64: user state', state);
      return { ...state, ...payload };
    },

    resetUser(state, { payload }) {
      return { ...initUser, isLoginBySaml: payload?.isLoginBySaml };
    },
  },
  effects: {
    *startUp({ payload }, { call, put, select }) {
      const accessToken = Cookies.get(ACCESS_TOKEN);
      const refreshToken = Cookies.get(REFRESH_TOKEN);
      console.log('startUp refreshKey:', refreshToken);
      if (refreshToken) {
        yield put({ type: 'updateState', payload: { isLogin: true } });
      } else {
        yield put({ type: 'logout' });
      }
      setUpAPIHelperToken(accessToken);

      const userPermissions = getUserPermissions();
      const allPermissions = getPermissionsFromStorage(allPermissionsKey);
      yield put({
        type: 'updateState',
        payload: {
          userPermissions,
          allPermissions,
        },
      });
    },
    *login({ payload }, { call, put }) {
      const { username, password, method, code, handleFailed } = payload;
      const response = yield call(authAPIHelper.createAccessToken, {
        username,
        password,
        method,
        code,
      });
      console.log('login:', response);
      if (response.status === 200) {
        const tokenAuthData = response.data.data.tokenAuth;
        if (tokenAuthData) {
          setUpAPIHelperToken(tokenAuthData.token);
          updateRefreshToken(tokenAuthData.refreshToken);
          const userPermissions =
            tokenAuthData.administrator?.allPermissions?.map(
              (val) => val.split('.')[1],
            );
          const allPermissions = tokenAuthData.administrator?.allPermissions;
          try {
            const userPermissionString = JSON.stringify(userPermissions);
            console.log('permissions:', userPermissionString.length);
            localStorage.setItem(
              userPermissionsKey,
              toBase64(userPermissionString),
            );
            const allPermissionsString = JSON.stringify(allPermissions);
            localStorage.setItem(
              allPermissionsKey,
              toBase64(allPermissionsString),
            );
          } catch (err) {
            console.log('setLocalError');
          }
          yield put({
            type: 'updateState',
            payload: {
              payload: {
                username: tokenAuthData.payload.username,
                exp: tokenAuthData.payload.exp,
              },
              adminType: tokenAuthData.administrator?.adminType,
              isLogin: true,
              loginFailed: false,
              isSuperuser: tokenAuthData.administrator?.isSuperuser,
              allPermissions,
              userPermissions,
              username: tokenAuthData.administrator?.username,
              firstLogin: true,
              isLoginBySaml: method === AdminLoginMethod.Saml,
              workingTeams:
                tokenAuthData.administrator?.workingTeams?.edges?.map(
                  (val) => val.node,
                ),
            },
          });
        }
        const error = response?.data?.errors?.[0]?.message;
        if (error) {
          const loginError = {};
          if (error === 'Please enter valid credentials') {
            loginError.credentialsError = true;
          } else if (error === 'Invalid OTP code') {
            loginError.code = { message: error, error: true };
          }
          yield put({
            type: 'updateState',
            payload: {
              loginFailed: true,
              loginError,
            },
          });
          handleFailed && handleFailed();
        }
      } else {
        yield put({
          type: 'updateState',
          payload: {
            loginFailed: true,
            isLogin: false,
          },
        });
        handleFailed && handleFailed();
      }
    },

    logout: [
      function* ({ payload }, { call, put, select }) {
        const cookieToken = Cookies.get(REFRESH_TOKEN);
        const { refreshToken, isLoginBySaml, samlLogoutSessionIndex } = yield select((state) => ({
          refreshToken: state.users.refreshToken,
          isLoginBySaml: state.users.isLoginBySaml,
          samlLogoutSessionIndex: state.users.samlLogoutSessionIndex
        }));
        const response = yield call(
          authAPIHelper.logout,
          refreshToken || cookieToken,
          isLoginBySaml,
          samlLogoutSessionIndex
        );
        const samlLogoutUrl = response?.data?.data?.revokeToken?.samlLogoutUrl;
        if (isLoginBySaml && samlLogoutUrl) {
          window.location.href = samlLogoutUrl;
        }
        Object.keys(SESSION_KEYS).forEach((key) =>
          removeFromSessionStorage(SESSION_KEYS[key]),
        );
        removeAPIHelperToken();
        localStorage.removeItem(userPermissionsKey);
        localStorage.removeItem(allPermissionsKey);
        yield put({
          type: 'resetUser',
          payload: { isLoginBySaml },
        });
      },
      { type: 'takeLatest' },
    ],

    sendCode: [
      function* ({ payload }, { call, put, select }) {
        console.log('@@send otp code', payload);
        const afterAction = payload?.afterAction || (() => {});
        const handleSuccess = payload?.handleSuccess || (() => {});
        const serviceArgs = [sendOtpCode, payload?.data];

        function* onSuccess(data) {
          if (!data.sendOtpCode?.success) {
            const loginError = { credentialsError: true };
            yield put({
              type: 'updateState',
              payload: {
                isLogin: false,
                loginError,
              },
            });
          } else {
            handleSuccess();
          }
          afterAction();
        }

        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    checkAdminLoginValid: [
      function* ({ payload }, { call, put }) {
        const serviceArgs = [getAdminLoginMethod, payload.username];
        function* onSuccess(data) {
          console.log('@@235', data);
          const methods = data.administrator2fas?.edges[0]?.node?.methods;
          const adminLoginMethod =
            data.administrator2fas?.edges[0]?.node?.adminLoginMethod;
          if (methods?.length > 0) {
            yield put({
              type: 'updateState',
              payload: {
                haveLoginMethod: methods,
                userExist: true,
                adminLoginMethodError:
                  adminLoginMethod == AdminLoginMethod.Saml,
              },
            });
          } else {
            yield put({
              type: 'updateState',
              payload: {
                userExist: false,
                adminLoginMethodError:
                  adminLoginMethod == AdminLoginMethod.Saml,
              },
            });
          }
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
};
