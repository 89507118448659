import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import './CampaignOverviewTab.scss';
import OverviewCard from '../../dashboard/OverviewCard';
import FilterSection from './CampaignOverviewFilter';
import { createAction, getHashKeyString } from '../../../utils';
import OverviewTable from '../../base/OverviewTable';
import { COUPOINS_DATA_LIST_CONFIG } from '../../coupon/couponList/CouponsOverview';
import { URLFragments } from '../../../components/campaign/CampaignUrlConfig';
import { CampaignType } from '../../../config/CustomEnums';

const CAMPAINGNS_DATA_LIST_CONFIG = [
  {
    title: 'Published coupon campaign',
    key: 'totalNumberOfPublishedCouponCampaigns',
  },
  {
    title: 'Non-published coupon campaign',
    key: 'totalNumberOfNonpublishedCouponCampaigns',
  },
  {
    title: 'Expired coupon campaign',
    key: 'totalNumberOfExpiredCouponCampaigns',
  },
  {
    title: 'Published general message campaign',
    key: 'totalNumberOfPublishedGeneralMessageCampaigns',
  },
  {
    title: 'Non-published general message campaign',
    key: 'totalNumberOfNonpublishedGeneralMessageCampaigns',
  },
  {
    title: 'Expired general message campaign',
    key: 'totalNumberOfExpiredGeneralMessageCampaigns',
  },

  {
    title: 'Published Cash voucher',
    key: 'totalNumberOfPublishedCashVoucherCampaigns',
  },
  {
    title: 'Non-published Cash voucher',
    key: 'totalNumberOfNonpublishedCashVoucherCampaigns',
  },
  {
    title: 'Expired Cash voucher',
    key: 'totalNumberOfExpiredCashVoucherCampaigns',
  },
  {
    title: 'Published Goodie bag coupon',
    key: 'totalNumberOfPublishedGiftCampaigns',
  },
  {
    title: 'Non-published Goodie bag coupon',
    key: 'totalNumberOfNonpublishedGiftCampaigns',
  },
  {
    title: 'Expired Goodie bag coupon',
    key: 'totalNumberOfExpiredGiftCampaigns',
  },
  {
    title: 'Published Goodie bag offer (Direct assign)',
    key: 'totalNumberOfPublishedFortuneCampaigns',
  },
  {
    title: 'Non-Published Goodie bag offer (Direct assign)',
    key: 'totalNumberOfNonpublishedFortuneCampaigns',
  },
  {
    title: 'Expired Goodie bag offer (Direct assign)',
    key: 'totalNumberOfExpiredFortuneCampaigns',
  },
];

const CAMPAIGN_RAKING_TABLE_TITLE = [
  { name: 'Coupon acquired ranking', fieldName: 'acquired_rank' },
  { name: 'Coupon redeemed ranking', fieldName: 'used_rank' },
  { name: 'Campaign', fieldName: 'name' },
  {
    name: 'Number of coupon acquired',
    fieldName: 'acquired_count',
  },
  {
    name: 'Number of coupon redeemed',
    fieldName: 'used_count',
  },
];

const getCampaignsRakingTableStatus = (campaignOverviewFilter) => {
  if (campaignOverviewFilter?.campaign?.pk) {
    return 'Not applicable if filtered by specific campaign';
  }
  let campaignsLabel = 'all';
  if (campaignOverviewFilter?.isStarred) {
    campaignsLabel = 'starred';
  } else if (campaignOverviewFilter?.isStarred === false) {
    campaignsLabel = 'non-starred';
  }

  return `Showing result of ${campaignsLabel} campaign(s) from 
    ${
      campaignOverviewFilter?.brand?.name
        ? campaignOverviewFilter?.brand?.name
        : 'all brands'
    }.`;
};

const getCampaignsSectionStatus = (campaign, campaignList, campaignsStatus) => {
  if (campaign) {
    if (campaign?.type === CampaignType.generalMessageCampaign) {
      return 'Not applicable if the campaign selected is a general message campaign.';
    }
    return 'Not applicable if filtered by specific campaign.';
  }
  if (campaignList?.length <= 0) {
    return 'No campaign found, please fine tune the fitler setting.';
  }
  return campaignsStatus;
};

const getCouponsSectionStatus = (campaign, campaignList, campaignsStatus) => {
  if (campaign) {
    if (campaign?.type === CampaignType.generalMessageCampaign) {
      return 'Not applicable if the campaign selected is a general message campaign.';
    }
  }
  if (campaignList?.length <= 0) {
    return 'No campaign found, please fine tune the fitler setting.';
  }
  return campaignsStatus;
};

const CampaignRakingTable = ({
  data = {},
  campaignOverviewFilter,
  overviewDate,
}) => {
  const dispatch = useDispatch();
  const [sort, sortChange] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [searchName, setSearchName] = useState();

  console.log('@293', campaignOverviewFilter);

  useEffect(() => {
    console.log('@96', sort, searchName);
    sortChange('');
    setSearchName('');
  }, [overviewDate]);

  return (
    <OverviewTable
      name="COUPON ACQUIRED & REDEMPTION Ranking - by campaign"
      hidden={campaignOverviewFilter?.campaign?.pk}
      status={getCampaignsRakingTableStatus(campaignOverviewFilter)}
      placeholder="Search by campaign name"
      titles={CAMPAIGN_RAKING_TABLE_TITLE}
      onSortChange={sortChange}
      currentSort={sort}
      data={data?.tableData}
      searchName={searchName}
      onFileterChange={(key) => {
        setSearchName(key);
      }}
      currentPage={currentPage}
      totalCount={data?.totalCount}
      pageChange={(page) => {
        setCurrentPage(page);
      }}
      hasCountInfo={true}
      disableRank={false}
      actionKey={'overview/getCampaignsRankingTable'}
      fromModel="CAMPAIGN"
    />
  );
};

const CampaignOverviewTab = (props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const activeTab = getHashKeyString(location.hash) || URLFragments.overview;
  const {
    overviewData,
    campaignOverviewFilter,
    tableData,
    campaignsStatus,
    campaignList,
    overviewDate,
  } = useSelector((state) => ({
    overviewData: state.overview,
    campaignOverviewFilter: state.overview.campaignOverviewFilter,
    tableData: state.overview.campaignsRakingTable,
    campaignsStatus: state.overview.campaignOverviewCampaignsStatus,
    campaignList: state.campaignList.allListByOverview,
    overviewDate: state.overview.campaignOverviewDate,
  }));


  console.log('@308', tableData, campaignOverviewFilter?.campaign);

  return (
    <>
      <FilterSection propCampaignOverviewDate={overviewDate?.startDate?overviewDate:props.campaignOverviewDate} />
      <div>
        <div className="overview-row-title overview-campaigns-title">
          <label>campaigns</label>
          <label className="overview-campaigns-status-information">
            {getCampaignsSectionStatus(
              campaignOverviewFilter?.campaign,
              campaignList,
              campaignsStatus,
            )}
          </label>
        </div>
        {campaignList?.length <= 0 
        || campaignOverviewFilter?.campaign?.pk 
        ? (
          <div className="overview-row"></div>
        ) : (
          <div className="overview-row overview-row-campaigns">
            {CAMPAINGNS_DATA_LIST_CONFIG.map((config, index) => (
              <OverviewCard
                title={config.title}
                value={overviewData?.[config.key]?.value}
                change={overviewData?.[config.key]?.change}
                showPercentage={config.showPercentage}
              />
            ))}
          </div>
        )}
        <div className="overview-row-title overview-coupons-title">
          <label>coupons</label>
          <label className="overview-coupons-status-information">
            {getCouponsSectionStatus(
              campaignOverviewFilter?.campaign,
              campaignList,
              campaignsStatus,
            )}
          </label>
        </div>
        {campaignList?.length <= 0 ||
        campaignOverviewFilter?.campaign?.type ===
          CampaignType.generalMessageCampaign ? (
          <div className="overview-row"></div>
        ) : (
          <div className="overview-row overview-row-coupons">
            {COUPOINS_DATA_LIST_CONFIG.map((config, index) => (
              <OverviewCard
                title={config.title}
                value={overviewData?.[config.key]?.value}
                change={overviewData?.[config.key]?.change}
                showPercentage={config.showPercentage}
                asOfDate={
                  config.asOf ? overviewDate?.endDateWithoutFormat : null
                }
              />
            ))}
          </div>
        )}
      </div>
      <CampaignRakingTable
        data={tableData}
        campaignOverviewFilter={campaignOverviewFilter}
        overviewDate={overviewDate}
      />
    </>
  );
};

export default CampaignOverviewTab;
