import { BaseHelper } from './BaseGraphQLAPIHelper';

export const getSearchSettings = () => {
  const query = `{
      campaignSearchFieldSettings {
        pk
        campaignNameWeight
        campaignShortDescriptionWeight
        campaignCategoryNameWeight
        brandNameWeight
        storeNameWeight
        storeAddressWeight
        tourCardNameWeight
        attributeTagNameWeight
        attributeTagKeyNameWeight
        searchTagsWeight
        semTagsWeight
        threshold
      }
    }
    `;

  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateSearchSettings = (payload) => {
  const query = `mutation UpdateCampaignSearchFieldSettings($input: UpdateCampaignSearchFieldSettingsInput!) {
        updateCampaignSearchFieldSettings(input: $input) {
          success
          errors {
            field
            messages
          }
        }
      }
    `;
  const variables = { input: payload };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
