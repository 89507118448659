import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch, connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {
  LanguageConfig,
  TermsAndConditionsType,
} from '../../config/CustomEnums';
import { SaveAndBackWithOutTempButtons } from '../base/BottomStepComponent';
import ContentSections from '../base/ContentSections';
import CustomTitleWithInput from '../base/CustomTitleWithInput';
import { hasError, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import { createAction } from '../../utils';
import CustomRadios from '../base/CustomRadios';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import { Row } from 'react-bootstrap';
import CustomEditor from '../base/CustomEditor';
import BasePrompt from '../base/prompt/BasePrompt';

const TERMS_AND_CONDITIONS_ERRORS = {
  title: {
    required: 'Please provide title',
  },
  content: {
    required: 'Please provide content',
  },
};

const TERMS_AND_CONDITIONS_OPTION_TYPE = [
  { label: 'Merchant T & C', value: 'FOR_MERCHANT' },
  { label: 'Generic T & C', value: 'FOR_GENERIC' },
];

const CreateTermsAndConditionsStepOne = ({
  detail,
  languages,
  relatedCampaignList,
  relatedCouponsetList,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const { id } = params;
  const [showChangePrompt, setShowChangePrompt] = useState(false);
  const {
    control,
    watch,
    setValue,
    getValues,
    register,
    trigger,
    formState: { errors, isDirty },
  } = useFormContext();

  const renderLanguageTab = (language) => {
    const watchType = watch('templateType') || TermsAndConditionsType.merchant;
    const watchTitle = watch(`translations.${language}.title`);
    const watchContent = watch(`translations.${language}.content`);

    return (
      <div>
        <div className="first-section-title">General</div>
        {language === LanguageConfig.english ? (
          <Controller
            control={control}
            name="type"
            render={() => (
              <div>
                <CustomTitleLabel title={'Template type'} />
                <Row className="featured-exclusive-row">
                  <CustomRadios
                    disabled={id}
                    onChange={(value) => {
                      setValue('templateType', value, { shouldDirty: true });
                    }}
                    default={watchType}
                    options={TERMS_AND_CONDITIONS_OPTION_TYPE}
                  />
                </Row>
              </div>
            )}
          />
        ) : null}
        <Controller
          control={control}
          name={`translations.${language}.title`}
          rules={TERMS_AND_CONDITIONS_ERRORS.title}
          render={() => (
            <>
              <CustomTitleWithInput
                title={`${
                  watchType === TermsAndConditionsType.generic
                    ? 'Generic'
                    : 'Merchant'
                } Terms & Conditions section title`}
                error={{
                  error: hasError(errors, `translations.${language}.title`),
                }}
                defaultValue={watchTitle}
                setValue={(value) => {
                  setValue(`translations.${language}.title`, value, {
                    shouldDirty: true,
                  });
                }}
              />
              <ReactHookFormErrorMessage
                errors={errors}
                id={`translations.${language}.title`}
                extra="display-priority-errors"
              />
            </>
          )}
        />
        <Controller
          name={`translations.${language}.content`}
          control={control}
          rules={TERMS_AND_CONDITIONS_ERRORS.content}
          render={() => (
            <>
              <CustomTitleLabel
                title={`${
                  watchType === TermsAndConditionsType.generic
                    ? 'Generic'
                    : 'Merchant'
                } Terms & Conditions section content`}
              />
              <CustomEditor
                initialValue={watchContent}
                onValueChange={(data) => {
                  setValue(`translations.${language}.content`, data, {
                    shouldDirty: true,
                  });
                }}
                errorMessage={TERMS_AND_CONDITIONS_ERRORS.content.required}
                error={hasError(errors, `translations.${language}.content`)}
              />
            </>
          )}
        />
      </div>
    );
  };

  const otherBody = () => {
    return (
      <div className="terms-and-conditions-confirm-to-save">
        {relatedCouponsetList?.map((coupon, index) => (
          <li
            id={`couponSet-${index}`}
          >{`[ID: ${coupon.pk}] ${coupon.name}`}</li>
        ))}
        {relatedCampaignList?.map((campaign, index) => (
          <li
            id={`campaign-${index}`}
          >{`[ID: ${campaign.pk}] ${campaign.name}`}</li>
        ))}
      </div>
    );
  };

  return (
    <>
      <ContentSections
        languageTabContent={{
          containers: languages?.map((item) => ({
            container: [renderLanguageTab(item.code)],
            key: item.code,
            title: item.sourceName,
          })),
        }}
        hidePreview={true}
      />
      <SaveAndBackWithOutTempButtons
        backAction={() => {
          history.goBack();
        }}
        saveText="Save"
        saveAction={async () => {
          console.log('@155', getValues());
          const isValid = await trigger([
            'translations.en.title',
            'translations.en.content',
          ]);
          if (isValid) {
            const data = getValues();

            if (id) {
              if (
                (data?.appliedCouponSet?.length === 0 &&
                  data?.appliedCampaign?.length === 0) ||
                isDirty === false
              ) {
                dispatch(
                  createAction('termsAndConditions/updateTermsAndConditions')({
                    data,
                  }),
                );
                return;
              }
              setShowChangePrompt(true);
            } else {
              dispatch(
                createAction('termsAndConditions/createTermsAndConditions')({
                  data,
                }),
              );
            }
          }
        }}
      />
      <BasePrompt
        show={showChangePrompt}
        closeAction={() => {
          setShowChangePrompt(false);
        }}
        rightButton={{
          text: 'Confirm to save',
          action: () => {
            const data = getValues();
            dispatch(
              createAction('termsAndConditions/updateTermsAndConditions')({
                data,
              }),
            );
            setShowChangePrompt(false);
          },
        }}
        leftButton={{
          text: 'Cancel',
          action: () => {
            setShowChangePrompt(false);
          },
        }}
        title={'Confirm to save the changes'}
        description={`Below ${relatedCouponsetList?.length || 0} Coupon Sets and
          ${
            relatedCampaignList?.length || 0
          } Campaigns have set to follow this Template.
          Upon you confirm to save, all the changes from this Template will update to the Coupon Sets and campaigns below.`}
        // description={`Related Coupon Sets and Campaigns have set to follow this Template.
        //   Upon you confirm to save, all the changes from this Template will update to the Coupon Sets and campaigns.`}
        otherBody={otherBody}
      />
    </>
  );
};

const mapPropsToState = (state) => ({
  languages: state.language.allList,
  relatedCampaignList: state.campaignList.notPagedAllList,
  relatedCouponsetList: state.couponList.notPagedAllList,
});

export default connect(mapPropsToState)(CreateTermsAndConditionsStepOne);
