import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { hasError } from '../base/ErrorFieldMessage';
import NumberInput from './NumberInput';

function OverallAmountSection() {
  const { watch, setValue, formState } = useFormContext();
  const errors = formState.errors;
  const watchTotalGoodieBagCreated = watch('quantityOfCreated');
  const watchOverallAmount = watch('overallAmount');

  return (
    <>
      <label className="create-section-title">Overall Amount</label>
      <label className="section-short-description">
        Assign the amount of goodie bag
      </label>
      <div className="overall-amount-section-total">
        Total goodie bag created (exclude this batch) :{' '}
        {watchTotalGoodieBagCreated || 0}
      </div>
      <NumberInput
        labelText="Number of goodie bag to be created in this batch"
        type=""
        valueChange={(value) => {
          setValue('overallAmount', parseInt(value), {
            shouldDirty: true,
          });
        }}
        defaultValue={watchOverallAmount}
        errors={errors}
        onError={hasError(errors, 'overallAmount')}
        errorFieldName={'overallAmount'}
      />
    </>
  );
}

export function OverallAmountFortuneBagSection({ sourceCampaigns }) {
  const { watch, setValue, formState } = useFormContext();
  const errors = formState.errors;
  const watchTotalGoodieBagCreated = watch('quantityOfCreated');
  const watchTotalGoodieBagAcquired = watch('quantityOfAcquired');
  const watchOverallAmount = watch('overallAmount');
  const latestCampaigns = watch('slotFortuneBagCampaigns');

  const now = new Date();

  let latest = useMemo(() => {
    let total = [];
    let stock = [];
    let ids = [];

    const campaigns = Object.values(latestCampaigns || {})?.[0] || [];

    campaigns.forEach((item) => {
      if (
        (!item.endDate ||
          Date(item.startDate) <= now <= new Date(item.endDate)) &&
        item.isPublished
      ) {
        total.push(
          parseInt(
            (item.couponCampaignInfo?.quantityOfGenerate || 0) /
              (item.goodieBagReward || 1),
          ),
        );
        stock.push(
          parseInt(
            (item.couponCampaignInfo?.quantityOfRemaining || 0) /
              (item.goodieBagReward || 1),
          ),
        );
      }
      ids.push(item.pk);
    });

    return campaigns.length > 0
      ? { total, stock, ids }
      : { total: [0], stock: [0], ids };
  }, [latestCampaigns]);

  let source = useMemo(() => {
    let total = [];
    let stock = [];

    let ids = [];

    const campaigns = sourceCampaigns || [];

    campaigns.forEach((item) => {
      if (
        (!item.endDate ||
          new Date(item.startDate) <= now <= new Date(item.endDate)) &&
        item.isPublished
      ) {
        total.push(
          parseInt(
            (item.couponCampaignInfo?.quantityOfGenerate || 0) /
              (item.goodieBagReward || 1),
          ),
        );
        stock.push(
          parseInt(
            (item.couponCampaignInfo?.quantityOfRemaining || 0) /
              (item.goodieBagReward || 1),
          ),
        );
      }

      ids.push(item.pk);
    });

    return campaigns.length > 0
      ? { total, stock, ids }
      : { total: [0], stock: [0], ids };
  }, [sourceCampaigns]);
  console.log(
    '@@114: ',
    latest.ids,
    source.ids,
    JSON.stringify(latest.ids.sort()) === JSON.stringify(source.ids.sort()),
  );
  const remainingGoodies = useMemo(() => {
    return (
      (watchTotalGoodieBagCreated || 0) - (watchTotalGoodieBagAcquired || 0)
    );
  }, [watchTotalGoodieBagCreated, watchTotalGoodieBagAcquired]);

  const canAlsoBeCreatedNumber = useMemo(() => {
    return watchTotalGoodieBagCreated
      ? Math.min(...latest.total) - watchTotalGoodieBagCreated
      : Math.min(...latest.total);
  }, [watchTotalGoodieBagCreated, latest]);

  return (
    <>
      <label className="create-section-title">Overall Amount</label>
      <label className="section-short-description">
        Assign the amount of goodie bag
      </label>
      <div className="overall-amount-section-total">
        Total goodie bag created (exclude this batch) :{' '}
        {watchTotalGoodieBagCreated || 0}
      </div>

      <div className="d-flex flex-column goodie-bag-overall-area">
        <label className="overall-amount-section-total font16-blod">
          Remaining goodie bag link: {remainingGoodies}
        </label>

        <label className="overall-amount-section-total font16-blod">
          Number of valid goodie bag:{' '}
          {Math.min(...[remainingGoodies || 0, Math.min(...latest.stock)])}
        </label>
        <label className="tips-message goodie-bag-overall-area-tips">
          This value show the existing number of valid goodie bag. If new
          campaigns (coupon sets) have added / removed in step 1, this value
          will be updated (to reflect the new definition of valid goodie bag).
        </label>
        <li className="section-short-description">
          Existing (by adding single coupon):{' '}
          {Math.min(...[remainingGoodies, Math.min(...source.stock)])}
        </li>
        <li className="section-short-description">
          Will be updated in this batch (by adding/removing coupon set in step
          1):{' '}
          {JSON.stringify(latest.ids.sort()) !==
          JSON.stringify(source.ids.sort())
            ? Math.min(...[remainingGoodies, Math.min(...latest.stock)])
            : 0}
        </li>

        <label className="overall-amount-section-total font16-blod">
          Number of empty goodie bag:{' '}
          {remainingGoodies -
            Math.min(...[remainingGoodies || 0, Math.min(...latest.stock)])}
        </label>
        <label className="tips-message goodie-bag-overall-area-tips">
          This value = Remaining goodie bag link - Number of valid goodie bag
        </label>
        <li className="section-short-description">
          Existing (by adding single coupon):{' '}
          {remainingGoodies -
            Math.min(...[remainingGoodies || 0, Math.min(...source.stock)])}
        </li>
        <li className="section-short-description">
          Will be updated in this batch (by adding/removing coupon set in step
          1):{' '}
          {JSON.stringify(latest.ids.sort()) !==
          JSON.stringify(source.ids.sort())
            ? remainingGoodies -
              Math.min(...[remainingGoodies || 0, Math.min(...latest.stock)])
            : 0}
        </li>
      </div>

      <NumberInput
        labelText="Number of goodie bag to be created in this batch"
        type={`Max number of valid goodie bags that can be created: ${
          canAlsoBeCreatedNumber > 0 ? canAlsoBeCreatedNumber : 0
        }
        \n(If the number of goodie bag to be created in this batch > ${
          canAlsoBeCreatedNumber > 0 ? canAlsoBeCreatedNumber : 0
        }, empty goodie bag will be created)`}
        typeClassName="tip2"
        typePosition="column"
        valueChange={(value) => {
          setValue('overallAmount', parseInt(value), {
            shouldDirty: true,
          });
        }}
        defaultValue={watchOverallAmount}
        errors={errors}
        onError={hasError(errors, 'overallAmount')}
        errorFieldName={'overallAmount'}
      />
    </>
  );
}

export default OverallAmountSection;
