import React, { useEffect, useState } from 'react';
import './CustomerList.scss';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Image } from 'react-bootstrap';
import FlatList from 'flatlist-react/lib';

import { ImportResource } from '../../../models/DownloadImportModel';

import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';

import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import AuthButton from '../../../components/base/AuthButton';
import ListButtonsGroup from '../../../components/base/ListButtonsGroup';
import CustomRadios from '../../../components/base/CustomRadios';
import BasePrompt from '../../../components/base/prompt/BasePrompt';
import DeletePrompt from '../../../components/base/DeletePrompt';


import Filter from '../../../components/customer/Filter';

import {
  DELETE_RELATED_SECTIONS,
  LanguageTransLation,
  StatusTag,
} from '../../../config/CustomEnums';
import { NavBarNames } from '../../../config/NavBarNameList';
import { PermissionCodes } from '../../../config/PermissionCodes';
import {
  createAction,
  getHashKeyString,
  useDeviceDetect,
} from '../../../utils';
import { TimeFormater, formatDate } from '../../../utils/TimeFormatUtil';

import CustomerListCard from '../../../components/customer/CustomerListCard';
import BaseMobileListContainer from '../../base/BaseMobileListContainer';
import CustomerMobileListHeader from './CustomerMobileListHeader';
const TAB_OVERVIEW = 'Overivew';
const TAB_LIST_OF_CUSTOMER = 'List of Customers';

function CustomerList() {
  const isMobile = useDeviceDetect(768);

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const [firstTimePickerChange, setFirstTimePickerChange] = useState(true);
  const [datePicker, setDatePicker] = useState();
  const [promptConfig, setPromptConfig] = useState();
  const [includeFilter] = useState({ isInclude: false });
  const [showActionPrompt, setShowActionPrompt] = useState(false);
  const [actionType, setActionType] = useState('Delete');
  const [remarks, setRemarks] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);

  const {
    listFields,
    dataList,
    pageInfo,
    totalPage,
    totalCount,
    checkedList,
    filters,
    importedFile,
    isSuperuser,
    userPermissions,
  } = useSelector((state) => ({
    // levelTabList: state.levels.levelTabList || [
    //   { key: 'all', name: 'All Types' },
    // ],
    listFields: state.customerList.listDisplayFields,
    dataList: state.customerList.pagedCustomerList,
    pageInfo: state.customerList.pageInfo,
    totalPage: state.customerList.totalPage,
    totalCount: state.customerList.totalCount,
    checkedList: state.customerList.checkedList,
    filters: state.customerList.filters,
    importedFile: state.uploadFiles.importedFile,
    isSuperuser: state.users.isSuperuser,
    userPermissions: state.users.userPermissions,
  }));

  const activeTab = getHashKeyString(location.hash) || TAB_LIST_OF_CUSTOMER;

  const hiddenFileInput = React.useRef(null);

  const handleImportClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    // props.handleFile(fileUploaded);
    console.log('@@77');
    dispatch(
      createAction('uploadFiles/uploadFile')({
        file: fileUploaded,
        source: event,
      }),
    );

    event.target.value = null;
  };
  const exportCSVAction = () => {
    const parsedSearch = queryString.parse(location.search);
    const exportData = {};
    if (parsedSearch.interest_preference) {
      exportData.interest_preference =
        parsedSearch.interest_preference.split(',');
    }
    if (parsedSearch.age) {
      exportData.age = parsedSearch.age;
    }
    if (parsedSearch.start_date) {
      exportData.from_date = formatDate(
        parsedSearch.start_date,
        TimeFormater.GTMFormat,
      );
    }
    if (parsedSearch.end_date) {
      exportData.to_date = formatDate(
        parsedSearch.end_date,
        TimeFormater.GTMFormat,
      );
    }
    if (parsedSearch.search) {
      exportData.search = parsedSearch.search;
    }

    const related = !includeFilter.isInclude
      ? {}
      : {
          filterConditions: JSON.stringify(exportData),
        };

    dispatch({
      type: 'downloadAndImport/createDownloadTask',
      payload: { from: ImportResource.customer, related: related },
    });
    includeFilter.isInclude = false;
  };

  const buttons = [
    <ListButtonsGroup
      hideExtraButtonWidth={500}
      extraButtons={[
        <AuthButton
          customClass="btn-back-button-common btn-download"
          title="Export .csv"
          action={() => setPromptConfig(exportConfig)}
          requires={PermissionCodes.addExportjob}
        />,
        <AuthButton
          title={'Import .csv'}
          action={handleImportClick}
          requires={PermissionCodes.addImportjob}
        />,
      ]}
      extraPopContent={[
        {
          requires: PermissionCodes.addExportjob,
          action: () => setPromptConfig(exportConfig),
          content: 'Export .csv',
        },
        {
          requires: PermissionCodes.addImportjob,
          action: handleImportClick,
          content: 'Import .csv',
        },
      ]}
    />,
    <input
      type="file"
      ref={hiddenFileInput}
      onChange={handleChange}
      style={{ display: 'none' }}
      accept=".csv"
    />,
  ];

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        // setShowPrompt(true);
        setShowActionPrompt(true);
        setActionType('Delete');
      },
      requires: PermissionCodes.deleteCustomer,
    },
  ];

  const otherBody = (data) => {
    return (
      <>
        <div className="customer-action-remarks">
          <textarea
            onChange={({ target }) => {
              setRemarks(target.value);
            }}
            className="custom-textarea-remakrs"
            onFocus={() => {}}
            value={remarks}
            placeholder="Please input remark"
          />
        </div>
      </>
    );
  };

  const deactiveAction = (item) => {
    setShowActionPrompt(true);
    setActionType(
      item.isForcedInactive || item.isLocked ? 'Activate' : 'Deactivate',
    );
    setSelectedItem(item);
  };

  const tabs = [
    isSuperuser ||
    userPermissions?.filter(
      (val) => val.split('_')[1] === PermissionCodes.customer,
    ).length > 0
      ? {
          name: TAB_LIST_OF_CUSTOMER,
          content: (
            <BaseTabListContainer
              hideTab={true}
              tabs={[]}
              groupActions={groupActions}
              // defaultTabKey={levels}
              pageInfo={pageInfo}
              totalCount={totalCount}
              filter={{
                hasFilter: true,
                componment: Filter,
                customFilterAreaClass: isMobile ? 'customer-filter-mobile' : '',
              }}
              searchPlaceholder="Search by membership ID"
              needMobile
              listContent={
                <BaseListContainer
                  fields={listFields}
                  dataList={dataList}
                  totalPage={totalPage}
                  model={'customerList'}
                  permissionGroup={PermissionCodes.customer}
                  deleteInfo={{
                    data: [],
                    title: 'customers',
                    relatedName: '',
                    relatedSections: DELETE_RELATED_SECTIONS.CUSTOMER,
                    inputRemarks: true,
                    remarks,
                    actionType,
                    basePromptButtonsClassName: 'prompt-with-remarks',
                    onClose: () => {
                      setRemarks('');
                    },
                    onConfirm: () => {
                      dispatch(
                        createAction('customerList/delete')({
                          remarks: remarks,
                          afterAction: () => {
                            history.push(location);
                          },
                        }),
                      );
                      setRemarks('');
                    },
                    message: { otherBody },
                  }}
                  customDeleteAction={() => setActionType('Delete')}
                  hasOtherActions={true}
                  customActions={{
                    deactive: deactiveAction,
                  }}
                />
              }
              hideSearch={isMobile}
              hideActionHeader={isMobile}
              mobileListContent={
                <BaseMobileListContainer
                  header={
                    <CustomerMobileListHeader
                      title={'Customer'}
                      totalCount={totalCount}
                      searchTitle={'Search by membership ID'}
                    />
                  }
                  dataList={dataList}
                  renderItem={(item, index) => (
                    <CustomerListCard
                      item={item}
                      fields={[
                        {
                          name: 'Membership ID',
                          value: item.membershipId,
                          isLink: true,
                        },
                        { name: 'UUID', value: item.ssoUid },
                        {
                          name: 'Interest Preferences',
                          value: item.interestPreferences
                            ?.map((val) => val.name)
                            ?.join(', '),
                        },
                        { name: 'Test User', value: item.assignToTest },
                      ]}
                      index={index}
                      deactiveAction={deactiveAction}
                      deleteAction={() => {
                        setShowActionPrompt(true);
                        setActionType('Delete');
                      }}
                      canEdit={
                        isSuperuser ||
                        userPermissions.includes(
                          `change_${PermissionCodes.customer}`,
                        )
                      }
                      canView={
                        isSuperuser ||
                        userPermissions.includes(
                          `view_${PermissionCodes.customer}`,
                        )
                      }
                    />
                  )}
                  hasNext={pageInfo.hasNextPage}
                  loadMore={() => {
                    console.log('@@374: loadmore');
                    const searchDict = queryString.parse(location.search);
                    const stringPage = searchDict['page'] || 1;
                    const page = parseInt(stringPage);
                    history.push({
                      pathname: location.pathname,
                      search: queryString.stringify({
                        ...searchDict,
                        page: page + 1,
                      }),
                    });
                  }}
                  listHeight="calc(100vh - 60px - 207px - 48px)"
                />
              }
            />
          ),
        }
      : {},
  ];

  // useEffect(() => {
  //   // dispatch(createAction('levels/getLevelList')({}));
  //   // dispatch(
  //   //   createAction('customerList/getPagedCustomers')({
  //   //     page: 0,
  //   //     reverse: true,
  //   //     search: '',
  //   //   }),
  //   // );
  // }, [dispatch]);

  useEffect(() => {
    // console.log('@@202: ', filters);
    // if (activeTab === TAB_LIST_OF_CUSTOMER) {

    // }
    const parsedSearch = queryString.parse(location.search);

    const searchKey = parsedSearch['search'] || '';
    const stringRank = parsedSearch['rank'] || 'true';
    const stringPage = parsedSearch['page'] || 1;
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);

    if (isMobile && page > 1 && dataList.length < 20) {
      history.push({
        pathname: location.pathname,
        search: queryString.stringify({
          ...parsedSearch,
          page: 1,
        }),
      });
      return;
    }

    console.log('@@202: ', parsedSearch);
    dispatch(
      createAction('customerList/getPagedCustomers')({
        ...parsedSearch,
        page,
        reverse: rank,
        search: searchKey,
        isLoadMore: isMobile && stringPage > 1 ? true : false,
      }),
    );
  }, [dispatch, location, isMobile]);

  useEffect(() => {
    if (importedFile) {
      dispatch(
        createAction('downloadAndImport/importFile')({
          from: ImportResource.customer,
          file: importedFile,
        }),
      );
    }
  }, [dispatch, importedFile]);

  const { importError } = useSelector((state) => ({
    importError: state.downloadAndImport.error || {},
  }));

  const importConfig = {
    title: importError.title,
    content: importError.content,
    closeAction: () => {
      setPromptConfig(null);
      dispatch({
        type: 'downloadAndImport/updateState',
        payload: { error: {} },
      });
    },
    leftButton: {
      text: 'Cancel',
      action: () => {
        setPromptConfig(null);
        dispatch({
          type: 'downloadAndImport/updateState',
          payload: { error: {} },
        });
      },
    },
    rightButton: {
      text: 'Import again',
      action: () => {
        handleImportClick();
        setPromptConfig(null);
        dispatch({
          type: 'downloadAndImport/updateState',
          payload: { error: {} },
        });
      },
      requires: PermissionCodes.addImportjob,
    },
  };

  const exportFilterChoice = (
    <>
      <label className={`create-section-label landing-title-margin-bottom `}>
        {'Select the export range'}
      </label>
      <Row style={{ marginLeft: '0px', marginRight: '0px', marginTop: '20px' }}>
        <CustomRadios
          onChange={(value) => {
            console.log('CustomRadios:', value);
            includeFilter.isInclude = value;
          }}
          default={includeFilter.isInclude}
          options={[
            {
              label: 'All items',
              value: false,
            },
            {
              label: 'All filtered items from the list',
              value: true,
            },
          ]}
        />
      </Row>
    </>
  );

  const exportConfig = {
    title: 'Export Customer (.csv)',
    content: importError.content,
    closeAction: () => {
      setPromptConfig(null);
      includeFilter.isInclude = false;
    },
    leftButton: {
      text: 'Cancel',
      action: () => {
        setPromptConfig(null);
        includeFilter.isInclude = false;
      },
    },
    rightButton: {
      text: 'Export',
      action: () => {
        exportCSVAction();
        setPromptConfig(null);
      },
      requires: PermissionCodes.addExportjob,
    },
    otherBody: () => {
      return <div>{exportFilterChoice}</div>;
    },
  };

  useEffect(() => {
    if (Object.keys(importError).length > 0) {
      setPromptConfig(importConfig);
    }
  }, [importError]);

  return (
    <>
      <CustomListComponent
        caution={{
          detail:
            'All the customers are listed here, and you can view their details and records. You can learn about the user usage of the app here.',
          title: NavBarNames.customer,
        }}
        defaultActiveKey={activeTab}
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        hideTab={isMobile}
        hideHeader={isMobile}
        // onTabChange={(key) => {
        //   // setActiveTab(key);
        //   history.push({ pathname: location.pathname, hash: key });
        // }}
      />

      <DeletePrompt
        model={'customerList'}
        data={checkedList}
        title={actionType !== 'Delete' ? 'customer' : 'customers'}
        relatedName={''}
        relatedSections={DELETE_RELATED_SECTIONS.CUSTOMER}
        inputRemarks={true}
        remarks={remarks}
        actionType={actionType}
        show={showActionPrompt}
        basePromptButtonsClassName={'prompt-with-remarks'}
        onClose={() => {
          setShowActionPrompt(false);
          setRemarks('');
        }}
        onConfirm={() => {
          setShowActionPrompt(false);

          if (actionType !== 'Delete') {
            if (selectedItem) {
              dispatch(
                createAction('customerList/updateCustomerActive')({
                  data: {
                    isForcedInactive:
                      selectedItem.isForcedInactive || selectedItem.isLocked,
                    id: selectedItem.pk,
                    remarks: remarks,
                  },
                  afterAction: () => history.push(location),
                }),
              );
            }
          } else {
            dispatch(
              createAction('customerList/delete')({
                remarks: remarks,
                afterAction: () => {
                  history.push(location);
                },
              }),
            );
          }
          setRemarks('');
        }}
        message={{
          otherBody,
        }}
      />

      <BasePrompt
        show={!!promptConfig}
        title={promptConfig?.title}
        description={promptConfig?.description}
        closeAction={promptConfig?.closeAction}
        leftButton={promptConfig?.leftButton}
        rightButton={promptConfig?.rightButton}
        otherBody={promptConfig?.otherBody}
      />
    </>
  );
}

export default CustomerList;
