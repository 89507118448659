import React, { useState, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import i18n from '../../I18n';
import { LanguageConfig } from '../../config/CustomEnums';
import { hasError, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import CustomSwitchButton from '../base/CustomSwitchButton';
import CustomTitleWithInput from '../../components/base/CustomTitleWithInput';
import { CustomTitleWithDropDown } from '../customer/CustomTitleWithDropDown';
import { useDispatch, useSelector } from 'react-redux';
import BaseMutipleSelectorV2 from '../base/BaseMultipleSelectorV2';
import { PermissionCodes } from '../../config/PermissionCodes';
import { useParams } from 'react-router-dom';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import ImageUploader from '../base/ImageUploader';
import AlertInfoPrompt from '../base/prompt/AlertInfoPrompt';
import BasePrompt from '../base/prompt/BasePrompt';
import referenceBefore from '../../assets/images/campaign/campaign_category_reference_before.svg';
import referenceAfter from '../../assets/images/campaign/campaign_category_reference_after.svg';
import referenceArrow from '../../assets/images/campaign/campaign_category_reference_arrow.svg';
import { Image } from 'react-bootstrap';

const CampaignCategoryContentSection = ({ language }) => {
  const dispatch = useDispatch();
  const params = useParams();

  const isEnglish = language === LanguageConfig.english;
  const { register, watch, setValue, control, formState, clearErrors } =
    useFormContext();
  const { errors } = formState;
  const watchName = isEnglish
    ? watch('name')
    : watch(`translations.${language}.name`);
  const watchIcon = watch('icon') || '';
  const watchOrder = watch('order');
  const watchIsForcedInactive = watch('isForcedInactive');

  const watchTagKey = watch('tagKey');
  const watchTags = watch('tags');

  const { tagKeyList, attributeTagList } = useSelector((state) => ({
    tagKeyList: state.attributeTagKey.allList,
    attributeTagList: state.attributeTag.notPagedAllList,
  }));

  const [showImagePrompt, setShowImagePrompt] = useState(false);

  useEffect(() => {
    dispatch({
      type: 'attributeTagKey/getList',
      payload: {
        isSelectorLoad: true,
        linkedCampaignCategory: `false,${params.id || ''}`,
      },
    });
  }, []);

  useEffect(() => {
    console.log('@@44: ', watchTagKey);

    if (watchTagKey?.value) {
      dispatch({
        type: 'attributeTag/getAllList',
        payload: { tagKeyIn: watchTagKey?.value?.pk },
      });
    } else if (!params.id) {
    } else {
      setValue('tags', [], { shouldDirty: true });
    }
  }, [watchTagKey]);

  return (
    <>
      <div className="create-section-title">
        {i18n.t('content', { locale: 'en' })}
      </div>
      <Controller
        control={control}
        name={isEnglish ? 'name' : `translations.${language}.name`}
        rules={isEnglish ? { required: 'Please provide a name' } : {}}
        render={() => (
          <CustomTitleWithInput
            title={i18n.t('name', { locale: 'en' })}
            defaultValue={watchName}
            error={{
              error: hasError(
                errors,
                isEnglish ? 'name' : `translations.${language}.name`,
              ),
            }}
            setValue={(value) => {
              setValue(
                isEnglish ? 'name' : `translations.${language}.name`,
                value,
                {
                  shouldDirty: true,
                },
              );
            }}
            extra={{ maxLength: 50 }}
          />
        )}
      />
      <ReactHookFormErrorMessage
        errors={errors}
        id={isEnglish ? 'name' : `translations.${language}.name`}
      />

      {isEnglish ? (
        <>
          <Controller
            control={control}
            name="tagKey"
            render={() => (
              <>
                <div className="create-section-label-bottom-space" />
                <div className="step-link-coupon-area-select">
                  <CustomTitleWithDropDown
                    title={'Linked with Tag (optional)'}
                    titleStyle={'create-section-label-no-top-space'}
                    defaultValue={watchTagKey}
                    setValue={(value) => {
                      setValue('tagKey', value || {}, { shouldDirty: true });
                    }}
                    source={tagKeyList}
                    loadMoreAction={'attributeTagKey/getList'}
                    filterAction={'attributeTagKey/getList'}
                    isReset={false}
                    resetChange={() => {}}
                    defaultFilter={{
                      linkedCampaignCategory: `false,${params.id || ''}`,
                    }}
                  />
                </div>
              </>
            )}
          />
          {watchTagKey?.value?.pk ? (
            <BaseMutipleSelectorV2
              title={'Linked with Attribute Tag (optional)'}
              namespace={'attributeTag'}
              data={{
                sourceData: watchTagKey?.value ? attributeTagList : [],
                targetData: watchTags,
                targetChange: (value) => {
                  setValue('tags', value, { shouldDirty: true });
                },
              }}
              searchPlaceholder={'Search by ID , name'}
              custom={{
                customItem: (value) => (
                  <>
                    <label>
                      [ID:{value.pk}] {value.name}
                    </label>
                  </>
                ),
                customFilter: (inputValue, option) => {
                  const inputIgnoreCase = inputValue?.toLowerCase();
                  const optionIgnoreCase = option?.name?.toLowerCase();
                  return (
                    optionIgnoreCase?.includes(inputIgnoreCase) ||
                    option.pk == inputValue
                  );
                },
              }}
              requires={PermissionCodes.changeAttributeTags}
            />
          ) : null}

          <Controller
            control={control}
            name={'icon'}
            render={() => (
              <>
                <CustomTitleLabel
                  className={'campaign-category-image-title'}
                  title={'Image (optional)'}
                />
                <label className="create-campaign-category-image-reminder">
                  Suggested Image Size: 300 px * 300 px, At least : 180 px * 180
                  px (Less than 2MB, support JPG, PNG and GIF only)
                </label>
                <label className="create-campaign-category-image-reminder-2">
                  The square image uploaded in CMS will be cropped into a circle
                  by the frontend.
                  <button
                    type="button"
                    className="btn-link see-reference-button channel-show-more-button"
                    onClick={() => {
                      setShowImagePrompt(true);
                    }}
                  >
                    See reference
                  </button>
                </label>
                <ImageUploader
                  images={watchIcon?[watchIcon]:[]}
                  minWidth={180}
                  minHeight={180}
                  maxImageNum={1}
                  isCircle={true}
                  aspect={1 / 1}
                  uploadImageClicked={() => {}}
                  onImageStateChange={(newState) => {
                    console.log('@203', newState[0]);
                    setValue('icon', newState[0]);
                  }}
                />
              </>
            )}
          />

          <Controller
            control={control}
            name={'order'}
            rules={{
              min: { value: 1, message: 'Minimum value is 1' },
              required: 'Please provide order',
            }}
            render={() => (
              <CustomTitleWithInput
                title={'Display order'}
                error={{ error: hasError(errors, 'order') }}
                defaultValue={watchOrder}
                setValue={(value) => {
                  setValue('order', value, {
                    shouldDirty: true,
                  });
                }}
                type={'number'}
                // extra={{
                //   min: 1,
                // }}
              />
            )}
          />
          <ReactHookFormErrorMessage errors={errors} id={'order'} />
          <label className="coupon-campaign-section-caution-description">
            Minimum value is 1. The smaller value, the higher priority.
          </label>
          <label className="create-section-label create-section-label-bottom-space">
            Is forced inactive
          </label>
          <Controller
            control={control}
            name="isForcedInactive"
            render={() => (
              <CustomSwitchButton
                defaultChecked={watchIsForcedInactive}
                onChange={(value) => {
                  setValue('isForcedInactive', value, {
                    shouldDirty: true,
                  });
                }}
              />
            )}
          />
          <ReactHookFormErrorMessage errors={errors} id="isForcedInactive" />
          <BasePrompt
            show={showImagePrompt}
            title={'Reference'}
            otherBody={() => {
              return (
                <>
                  <div className="reference-promt-iamge">
                    <Image
                      src={referenceBefore}
                      className={'reference-promt-before-image'}
                    />
                    <span>
                      <Image
                        src={referenceArrow}
                        className={'reference-promt-arrow'}
                      />
                      <i className="arrow-right" />
                    </span>
                    <Image
                      src={referenceAfter}
                      className={'reference-promt-after-image'}
                    />
                  </div>
                </>
              );
            }}
            closeAction={() => {
              setShowImagePrompt(false);
            }}
          />
        </>
      ) : null}
    </>
  );
};

export default CampaignCategoryContentSection;
