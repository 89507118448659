import React, { useState } from 'react';
import { Dropdown, Image } from 'react-bootstrap';
import './CustomDropDownButton.scss';
import NarrowUp from '../../assets/images/narrowBlue.svg';

export function CustomDropDownButton({
  title,
  items,
  customToogleAfter = true,
  customClass,
}) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Dropdown
      // drop="top"
      className={customClass}
      onToggle={(isOpen) => {
        setIsOpen(isOpen);
      }}
      flip={true}
    >
      <Dropdown.Toggle
        bsPrefix={`custom-dropdown-toggle ${
          customToogleAfter ? 'hide-after' : ''
        }`}
      >
        <>
          <span>{title}</span>
          {customToogleAfter ? (
            <Image
              src={NarrowUp}
              className={`toogle-area-indicator ${!isOpen ? 'rtoate180' : ''}`}
            />
          ) : null}
        </>
      </Dropdown.Toggle>

      <Dropdown.Menu bsPrefix="custom-dropdown-menu">
        {items.map((item, index) => {
          if (item.group) {
            return (
              <>
                {index > 0 ? <div className="devide-line" /> : null}
                <div className="action-list">
                  <label className={`action-group-title`}>{item.group}</label>
                  {item.items.map((item, index) => {
                    return (
                      <Dropdown.Item
                        bsPrefix="custom-dropdown-item"
                        onClick={() => item.action()}
                      >
                        {item.title}
                      </Dropdown.Item>
                    );
                  })}
                </div>
              </>
            );
          }

          return (
            <Dropdown.Item
              bsPrefix="custom-dropdown-item"
              onClick={() => item.action()}
              className={`${
                item.hasSperateLine ? 'action-group-title-sperate-line' : ''
              } ${item.isGroup ? 'action-group-title' : ''}`}
            >
              {item.title}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}
