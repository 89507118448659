import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PermissionCodes } from '../../config/PermissionCodes';
import './CustomImportFilePrompt.scss';
import BasePrompt from './prompt/BasePrompt';
import DisabledZipIcon from '../../assets/images/zipIconDisabled.svg';
import EnabledZipIcon from '../../assets/images/zipIconEnabled.svg';
import AuthButton from './AuthButton';
import { createAction } from '../../utils';
import { Image } from 'react-bootstrap';
import LoadingIcon from '../../assets/images/loading.gif';

export function CustomImportFilePrompt({
  show,
  title,
  description,
  accept = '.xlsx',
  onClose = () => {},
  importAction = () => {},
  from,
}) {
  const dispatch = useDispatch();
  const hiddenFileInput = React.useRef(null);

  const { importedFile, uploading, importError } = useSelector((state) => ({
    importedFile: state.uploadFiles.importedFile,
    uploading: state.uploadFiles.uploading,
    importError: state.downloadAndImport.error,
  }));

  const [fileName, setFileName] = useState();
  const [showFile, setShowFile] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (importedFile) {
      console.log(importedFile);
      setShowFile(true);
    } else {
      setShowFile(false);
    }
  }, [importedFile]);

  useEffect(() => {
    setShowError(Object.keys(importError || {}).length > 0);
  }, [importError]);

  useEffect(() => {
    if (!show) {
      dispatch({
        type: 'uploadFiles/updateState',
        payload: { importedFile: '' },
      });
      setFileName();
    }
  }, [show]);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    dispatch(
      createAction('uploadFiles/uploadFile')({
        file: fileUploaded,
        source: event,
      }),
    );

    setShowFile(false);
    setFileName(event.target.value.replace(/^.*[\\\/]/, ''));

    event.target.value = null;

    // action();
  };

  return (
    <BasePrompt
      show={show}
      closeAction={onClose}
      leftButton={{
        text: 'Cancel',
        action: () => {
          //   setShowError(false);
          onClose();
        },
      }}
      rightButton={{
        text: 'Import',
        disabled: !importedFile,
        action: () => {
          //   handleClick();
          //   setShowError(false);

          importAction();

          dispatch(
            createAction('downloadAndImport/importFile')({
              from,
              related: {},
              file: importedFile,

              successAction: onClose,
            }),
          );
        },
        requires: PermissionCodes.addImportjob,
      }}
      title={title}
      description={description}
      basePromptDescriptionClassName="upload-description"
      otherBody={() => {
        return (
          <div className="upload-file-area">
            <label className="upload-title">
              Upload Sheet and Image (.Zip)
            </label>
            <img
              src={importedFile ? EnabledZipIcon : DisabledZipIcon}
              height="100"
              style={
                importedFile
                  ? { marginTop: 20, marginBottom: 8 }
                  : { marginTop: 32, marginBottom: 32 }
              }
            />
            {importedFile ? (
              <label className="upload-file-name">{fileName}</label>
            ) : null}
            {uploading ? (
              <Image
                src={LoadingIcon}
                className="loading-icon loading-icon-align-center"
              ></Image>
            ) : (
              <AuthButton
                className={'btn-further upload-select-button'}
                title={importedFile ? 'Re-select' : 'Select the file'}
                action={handleClick}
                requires={PermissionCodes.addImportjob}
              />
            )}

            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: 'none' }}
              accept={accept}
            />
          </div>
        );
      }}
    />
  );
}
