import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import NextStepPrompt from '../base/prompt/NextStepPrompt';
import { PermissionCodes } from '../../config/PermissionCodes';

function ScheduledSuccessPrompt(props) {
  const queryString = require('query-string');
  const history = useHistory();
  const defaultShow = props.show || false;
  const onClose = props.onClose || (() => {});
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  if (!show && defaultShow) {
    setShow(true);
  }

  return (
    <NextStepPrompt
      show={show}
      title={`Successfully scheduled!`}
      description={`General alert is successfully Scheduled.`}
      steps={null}
      buttons={[
        {
          text: `Back to general alert list`,
          action: () => {
            handleClose()
            history.push({
              pathname: '/general_alert',
              search: queryString.stringify({
                page: 1,
                rank: true,
                search: '',
              }),
            });
          },
          requires: PermissionCodes.changeMessage,
        },
      ]}
    />
  );
}

export default ScheduledSuccessPrompt;
