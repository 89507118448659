import React from 'react';
import {
  TRANSACTION_RECORD_TYPE,
  TRANSACTION_EVENT_TYPE,
} from '../../containers/record/records/TransactionList';
import FilterableDropdown from '../base/FilterableDropdown';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';

function OfflineEventTypeSelectDropdown(props) {
  const { transactionType } = props;
  const selectEventType = props.selectEventType || (() => {});

  const { defaultEventType, defaultDisplayEventType } = props;

  const getEventTypeOptions = () => {
    if (transactionType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS) {
      return [
        {
          value: 'PENDING',
          label: TRANSACTION_EVENT_TYPE.TYPE_PENDING,
        },
        {
          value: 'AUTHORIZED',
          label: TRANSACTION_EVENT_TYPE.TYPE_AUTHORIZED,
        },
        {
          value: 'PARTIALLY_PAID',
          label: TRANSACTION_EVENT_TYPE.TYPE_PARTIALLY_PAID,
        },
        {
          value: 'PAID',
          label: TRANSACTION_EVENT_TYPE.TYPE_PAID,
        },
        {
          value: 'PARTIALLY_REFUNDED',
          label: TRANSACTION_EVENT_TYPE.TYPE_PARTIALLY_REFUNDED,
        },
        {
          value: 'REFUNDED',
          label: TRANSACTION_EVENT_TYPE.TYPE_REFUNDED,
        },
        {
          value: 'VOIDED',
          label: TRANSACTION_EVENT_TYPE.TYPE_VOIDED,
        },
      ];
    }
    return [
      {
        value: 'WAITING_FOR_APPROVAL',
        label: TRANSACTION_EVENT_TYPE.TYPE_WAITING,
      },
      {
        value: 'APPROVED',
        label: TRANSACTION_EVENT_TYPE.TYPE_APPROVED,
      },
      {
        value: 'REJECTED',
        label: TRANSACTION_EVENT_TYPE.TYPE_REJECTED,
      },
      {
        value: 'RECALLED',
        label: TRANSACTION_EVENT_TYPE.TYPE_RECALLED,
      },
    ];
  };

  return (
    <>
      <CustomTitleLabel title="Offline event type" />
      <FilterableDropdown
        defaultValue={{
          value: defaultEventType,
          label: defaultDisplayEventType,
        }}
        options={getEventTypeOptions()}
        selectChange={(item) => {
          selectEventType(item);
        }}
        needFilter={false}
      />
    </>
  );
}

export default OfflineEventTypeSelectDropdown;
