import React from 'react';
import './PreviewImage.scss';
import BasePrompt from '../../base/prompt/BasePrompt';
function PreviewImage(props) {
  return (
    <BasePrompt
      show={props.show}
      closeAction={props.onClose}
      title={'Preview'}
      customContentStyle={{ minWidth: "300px" }}
      otherBody={() => {
        return (
          <img
            src={props.imageUrl}
            style={{
              width: 315,
              height: 315 * 1.7,
              border: '1px solid #DADADA',
              objectFit: 'cover',
              marginTop: 18,
            }}
          />
        );
      }}
    />
  );
}

export default PreviewImage;
