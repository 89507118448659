import React, { useEffect } from 'react';
import CustomListComponent from '../../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import ContentSections from '../../../components/base/ContentSections';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';
import './CouponTransactionOverview.scss';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from '../../../utils';
import { OnlyBackButton } from '../../../components/base/BottomStepComponent';
import { couponRecordQueryKey } from '../../../components/pointRecord/RecordsUrlConfig';
import { ADMIN_TYPE } from '../../../config/CustomEnums';

function CouponTransactionOverview() {
  const queryString = require('query-string');
  const dispatch = useDispatch();
  const history = useHistory();
  const couponTransactionPK = useParams().id;

  useEffect(() => {
    dispatch(
      createAction('couponTransactions/getSingleCouponTransactionRecord')({
        couponTransactionPK,
      }),
    );
  }, [couponTransactionPK, dispatch]);

  const { record, userAdminType } = useSelector((state) => ({
    record: state.couponTransactions.selectedCouponTransaction,
    userAdminType: state.users.adminType,
  }));

  const generalFields = [
    ...(userAdminType === ADMIN_TYPE.HKTB_ADMIN
      ? [[{ title: 'Membership ID', field: 'membershipId' }]]
      : []),
    [{ title: 'Transaction type', field: 'displayType' }],
    ...(userAdminType === ADMIN_TYPE.HKTB_ADMIN
      ? [
          [
            {
              title: 'Transaction value',
              field: 'transactionAmountSurveyValue',
            },
          ],
        ]
      : []),
    [{ title: 'Coupon campaign ID', field: 'campaignID' }],
    [{ title: 'Coupon campaign name', field: 'campaignName' }],
    [{ title: 'Coupon set ID', field: 'couponTemplateID' }],
    [{ title: 'Coupon set name', field: 'couponTemplateName' }],
    [{ title: 'Single coupon ID', field: 'couponID' }],
    [
      {
        title: 'Single coupon serial Number',
        field: 'couponSerialNumber',
        link:
          userAdminType === ADMIN_TYPE.HKTB_ADMIN
            ? `/coupons/${record?.couponTemplateID}/${record?.couponID}/edit`
            : null,
      },
    ],
    [{ title: 'Reference ID', field: 'referenceId' }],
    [{ title: 'Coupon code display format', field: 'discountCodeFormat' }],
    [{ title: 'Coupon Barcode', field: 'couponBarcodeFormat' }],
    [{ title: 'Expiry date', field: 'couponExpiryDate' }],

    [{ title: 'Days of the Week', field: 'weeklyRulesDisplay' }],
    [{ title: 'Special Date Inclusion', field: 'dateRulesDisplay' }],
    [{ title: 'Blackout Date Exclusion', field: 'excludeDateRulesDisplay' }],

    [{ title: 'Record create date', field: 'creationDate' }],
    [{ title: 'Coupon use date & time', field: 'usedDate' }],
    [{ title: 'Coupon used at (Store)', field: 'storeName' }],
    [{ title: 'Used platform', field: 'usedPlatform' }],
    [{ title: 'Use method', field: 'useMethod' }],
    [{ title: 'Admin account type', field: 'adminType' }],
    [{ title: 'Admin account', field: 'displayAdmin' }],
  ];
  // const { isHighValueCoupon } = record;
  // const highValueFields =
  //   userAdminType === ADMIN_TYPE.HKTB_ADMIN
  //     ? [
  //         [{ title: 'Owner Full name', field: 'ownerProfileFullName' }],
  //         [{ title: 'Owner Mobile number', field: 'ownerProfilePhoneNumber' }],
  //         [
  //           {
  //             title: 'Owner HKID / Passport number',
  //             field: 'ownerProfileIdentityNumber',
  //           },
  //         ],
  //       ]
  //     : [];
  const tabs = [
    {
      name: '',
      content: (
        <div className="scroll-container-common">
          <ContentSections
            sections={[
              <MembershipInfoCard
                title="General"
                data={record}
                fields={generalFields}
              />,
              // isHighValueCoupon ? (
              //   <MembershipInfoCard
              //     title="High-value coupon"
              //     data={record}
              //     fields={highValueFields}
              //   />
              // ) : null,
            ]}
            hidePreview
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail: '', //view coupon records
          title: 'View Coupon records',
        }}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={true}
        tabs={tabs}
      />
      <OnlyBackButton
        backAction={() => {
          history.push({
            pathname: '/coupon_records',
            search: queryString.stringify({
              [couponRecordQueryKey.searchKey]: '',
              [couponRecordQueryKey.page]: 1,
              [couponRecordQueryKey.rank]: true,
              [couponRecordQueryKey.tab]: 'all',
            }),
          });
        }}
      />
    </>
  );
}

export default CouponTransactionOverview;
