import React, { useState } from 'react';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';

import { connect, useDispatch } from 'react-redux';

import { useFormContext } from 'react-hook-form';
import PromptButton from '../base/prompt/BaseButtons';
import WorkingTeamSelectorPrompt from '../base/prompt/WorkingTeamSelectorPrompt';

function WorkingTeamField(props) {
  const dispatch = useDispatch();
  const { control, watch, setValue, formState } = useFormContext();
  const { errors } = formState;

  const workingTeams = watch('workingTeams');
  const [showPrompt, setShowPrompt] = useState(props.showPrompt);

  return (
    <div>
      <div className="first-section-title">
        Working team
        <PromptButton
          title="Edit"
          action={() => {
            setShowPrompt(true);
          }}
          className="edit-button"
          type="link"
        />
      </div>

      <div className="d-flex flex-column">
        <CustomTitleLabel title="Selected working team" />
        <label>
          {workingTeams?.length > 0
            ? workingTeams.map((item) => `[${item.pk}]${item.name}`).join(', ')
            : 'All admin users'}
        </label>
      </div>

      <WorkingTeamSelectorPrompt
        show={showPrompt}
        onClose={() => {
          setShowPrompt(false);
        }}
        title={props.from}
        selectedTeams={workingTeams}
        isCreate={props.showPrompt}
        doneAction={(selectedTeams) => {
          // let result = null;
          //   if (
          //     props.from === 'Coupon set' &&
          //     !props.showPrompt &&
          //     selectedTeams.length > 0
          //   ) {
          //     //check coupon related campagin whether in selectedTeams
          //     const selectedTeamsIds = selectedTeams.map((item) => item.pk);
          //     const notHasSameTeamRelatedCampaigns =
          //       props.relatedCampaigns?.filter(
          //         (item) =>
          //           item &&
          //           item.campaignWorkingTeamIds?.length > 0 &&
          //           item.campaignWorkingTeamIds?.filter((val) => {
          //             return selectedTeamsIds.indexOf(val) != -1;
          //           })?.length <= 0,
          //       );

          //     const hasLinkedTeams = workingTeams?.filter((item) => {
          //       console.log(
          //         '@@',
          //         item.pk,
          //         props.relatedCampaigns,
          //         props.relatedCampaigns?.filter((val) => {
          //           return (
          //             val && val.campaignWorkingTeamIds?.indexOf(item.pk) >= 0
          //           );
          //         }).length,
          //       );
          //       return (
          //         props.relatedCampaigns?.filter(
          //           (val) =>
          //             val && val.campaignWorkingTeamIds?.indexOf(item.pk) >= 0,
          //         ).length > 0
          //       );
          //     });
          //     const hasLinkedTeamsIds = hasLinkedTeams.map((item) => item.pk);
          //     const sameTeamWithRelatedCampaigns = props.relatedCampaigns?.filter(
          //       (item) =>
          //         item &&
          //         item.campaignWorkingTeamIds?.length > 0 &&
          //         item.campaignWorkingTeamIds?.filter((val) => {
          //           return hasLinkedTeamsIds.indexOf(val) >= 0;
          //         })?.length > 0,
          //     );

          //     console.log(
          //       notHasSameTeamRelatedCampaigns,
          //       sameTeamWithRelatedCampaigns,
          //       hasLinkedTeams,
          //     );
          //     result =
          //       notHasSameTeamRelatedCampaigns.length > 0
          //         ? {
          //             hasLinkedTeams,
          //             sameTeamWithRelatedCampaigns,
          //           }
          //         : null;

          //     //check coupon related campaign related goodiebag whether in selectedTeams
          //   }
          // if (!result) {
          //   setValue('workingTeams', selectedTeams, { shouldDirty: true });
          // }

          // return result;

          setValue('workingTeams', selectedTeams, { shouldDirty: true });
        }}
      />
    </div>
  );
}

const mapPropsToState = (state) => ({
  // relatedCampaigns: state.createCoupon.relatedCampaigns,
});

export default connect(mapPropsToState)(WorkingTeamField);
