import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import { ArrayStringData, createAction } from '../../../utils';
import {
  COUPON_REDEMPTION_METHOD,
  CouponMediumType,
  DisplayCouponMediumType,
} from '../../../config/CustomEnums';

function SingleCouponFilter({ backAction = () => {} }) {
  const location = useLocation();
  const dispatch = useDispatch();

  const parsedSearch = queryString.parse(location.search);
  const searchcouponMedium = parsedSearch['coupon_medium'] || '';
  const searchSingleCouponStatus = parsedSearch['single_coupon_status'] || '';

  const [couponMedium, setCouponMedium] = useState(
    ArrayStringData(searchcouponMedium),
  );
  const [singleCouponStatus, setSingleCouponStatus] = useState(
    ArrayStringData(searchSingleCouponStatus),
  );

  const content = [
    {
      title: 'Coupon medium',
      data: [
        { name: DisplayCouponMediumType.EWALLET, pk: CouponMediumType.EWallet },
        {
          name: DisplayCouponMediumType.PHYSICAL,
          pk: CouponMediumType.Physical,
        },
      ],
      value: couponMedium,
      setValue: setCouponMedium,
      componment: FilterTypeEnum.choice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Single coupon status',
      data: [
        { name: 'Acquired', pk: 'BUY' },
        { name: 'Active(Acquired)', pk: 'GRANT' },
        { name: 'Use', pk: 'USE' },
        { name: 'Expired', pk: 'EXPIRED' },
        { name: 'Voided', pk: 'VOID' },
        { name: 'Active', pk: 'ACTIVE' },
        { name: 'Inactive', pk: 'INACTIVE' },
      ],
      value: singleCouponStatus,
      setValue: setSingleCouponStatus,
      componment: FilterTypeEnum.choice,
    },
  ];

  return (
    <BaseFilter content={content} backAction={backAction} init={() => {}} />
  );
}

export default SingleCouponFilter;
