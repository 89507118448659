import React from 'react';
import './CreateCouponStepOne.scss';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { OnlyContinueButton } from '../../../components/base/BottomStepComponent';
import ContentSections from '../../../components/base/ContentSections';
import ItemTypeSet from '../../../components/base/ItemTypeSet';
import {
  COUPON_SET_TYPES,
  COUPON_REDEMPTION_METHOD,
  CouponMediumType,
  DisplayCouponMediumType,
} from '../../../config/CustomEnums';
import { createAction } from '../../../utils';
import CustomRadios from '../../../components/base/CustomRadios';
import { saveCouponToSessionStorage } from '../../../models/CreateCouponModel';
import SystemGenerated from '../../../assets/images/coupon/systemGenerated.svg';
import ImportExistingCoupon from '../../../assets/images/coupon/importExistingCoupon.svg';
import WorkingTeamField from '../../../components/workingTeam/WorkingTeamField';
import PropertyGeneralSection from '../../merchants/stores/PropertyGeneralSection';
import { CustomTitleWithRadio } from '../../../components/earning/CustomBaseComponments';

export const COUPON_TYPES_CONFIG = [
  {
    id: COUPON_SET_TYPES.SYSTEM_GENERATED,
    title: 'System generated',
    image: SystemGenerated,
    description: 'Coupon generated by the system',
  },
  {
    id: COUPON_SET_TYPES.IMPORT_EXISTING_COUPON,
    title: 'Import existing coupon',
    image: ImportExistingCoupon,
    description: 'Import existing coupon to the system',
  },
];

export const COUPON_REDEMPTION_METHOD_CONFIG = [
  {
    label: 'Mechant app scan Coupon QR code',
    value: COUPON_REDEMPTION_METHOD.MERCHANT_APP_SCAN_COUPON_QR_CODE,
  },
  {
    label: 'Users scan Store QR code',
    value: COUPON_REDEMPTION_METHOD.USERS_SCAN_STORE_QR_CODE,
  },
  {
    label: 'Users reveal the coupon (discount code, URL, QR code/Barcode)',
    value: COUPON_REDEMPTION_METHOD.USERS_COPY_THE_DISCOUNT_CODE,
  },
];

const COUPON_MEDIUM_OPTION = [
  { label: DisplayCouponMediumType.EWALLET, value: CouponMediumType.EWallet },
  { label: DisplayCouponMediumType.PHYSICAL, value: CouponMediumType.Physical },
  { label: DisplayCouponMediumType.BOTH, value: CouponMediumType.Both },
];

const CreateCouponStepOne = ({ id }) => {
  const dispatch = useDispatch();
  const { register, watch, setValue, getValues } = useFormContext();
  const watchCouponSetType = watch('couponSetType');
  const watchRedemptionMethod = watch('redemptionMethod');
  const watchIsLinkedCustomer = watch('isLinkedCustomer');
  const watchCouponMedium = watch('couponMedium');
  const [methodOptions, setMethodOptions] = React.useState(
    watchCouponSetType === COUPON_SET_TYPES.IMPORT_EXISTING_COUPON
      ? COUPON_REDEMPTION_METHOD_CONFIG
      : COUPON_REDEMPTION_METHOD_CONFIG.slice(0, 2),
  );
  const hasCouponMedium =
    watchCouponSetType === COUPON_SET_TYPES.SYSTEM_GENERATED &&
    watchRedemptionMethod ===
      COUPON_REDEMPTION_METHOD.MERCHANT_APP_SCAN_COUPON_QR_CODE;
  const hasPhysical =
    hasCouponMedium &&
    (watchCouponMedium === CouponMediumType.Physical ||
      watchCouponMedium === CouponMediumType.Both);

  // console.log('CreateCouponStepOne:', getValues());
  const sections = [
    <WorkingTeamField
      from="Coupon set"
      showPrompt={!id && !watchCouponSetType}
    />,
    <div>
      <label className="step-one-title">Coupon set type</label>
      <div className="step-type-area message-channel-area">
        {COUPON_TYPES_CONFIG.map((typeItem) => (
          <ItemTypeSet
            moreThanThree={false}
            extraClassName="couponset-card"
            key={`${typeItem.id}-${typeItem.description}`}
            item={typeItem}
            selected={watchCouponSetType}
            onClick={(id) => {
              setValue('couponSetType', id, { shouldDirty: true });
              if (id === COUPON_SET_TYPES.SYSTEM_GENERATED) {
                setMethodOptions(COUPON_REDEMPTION_METHOD_CONFIG.slice(0, 2));
                if (
                  watchRedemptionMethod ===
                  COUPON_REDEMPTION_METHOD.USERS_COPY_THE_DISCOUNT_CODE
                ) {
                  setValue('redemptionMethod', null, { shouldDirty: true });
                }
              } else {
                setMethodOptions(COUPON_REDEMPTION_METHOD_CONFIG);
              }
            }}
            disabled={(watchIsLinkedCustomer || hasPhysical) && id}
          />
        ))}
      </div>
      <label className="step-one-title">Redemption method</label>
      <div style={{ display: 'flex', marginTop: 8 }}>
        <CustomRadios
          onChange={(value) => {
            setValue('redemptionMethod', value, { shouldDirty: true });
          }}
          default={watchRedemptionMethod}
          disabled={(watchIsLinkedCustomer || hasPhysical) && id}
          options={methodOptions}
        />
      </div>
      {hasCouponMedium ? (
        <CustomTitleWithRadio
          title="Coupon medium"
          options={COUPON_MEDIUM_OPTION}
          defaultValue={watchCouponMedium}
          setValue={(value) => {
            setValue('couponMedium', value, { shouldDirty: true });
          }}
          disabled={!!id}
        />
      ) : null}
    </div>,
  ];
  return (
    <>
      <div className="create-coupon-step-one-container">
        <ContentSections sections={sections} hidePreview={true} />
      </div>
      <OnlyContinueButton
        disabledContinue={
          (watchCouponSetType || '').length === 0 ||
          (watchRedemptionMethod || '').length === 0 ||
          (hasCouponMedium && !watchCouponMedium)
        }
        continueAction={() => {
          dispatch(
            createAction('createCoupon/stepChange')({
              currentStep: 0,
              isValid: true,
            }),
          );
          saveCouponToSessionStorage(getValues());
        }}
      />
    </>
  );
};
export default CreateCouponStepOne;
