import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import { ArrayStringData } from '../../../utils';

function Filter({ backAction = () => {} }) {
  const location = useLocation();

  const parsedSearch = queryString.parse(location.search);
  const searchStatus = parsedSearch['status'] || '';

  const [status, setStatus] = useState(searchStatus);

  const content = [
    {
      title: 'Status',
      data: [
        { name: 'Active', pk: 'true' },
        { name: 'Inactive', pk: 'false' },
      ],
      value: status,
      setValue: setStatus,
      componment: FilterTypeEnum.singleChoiceSegment,
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

export default Filter;
