import {
  createEarningRule,
  updateEarningRule,
  getOneEarningRule,
} from '../services/EarningRuleHelper';
import {
  EarningRuleType,
  EarningRuleTypeKey,
  LanguageConfig,
  CheckStatus,
  EarningRuleRewardType,
  SESSION_KEYS
} from '../config/CustomEnums';
import { defaultStep, getNewStepConfig } from './StepBarUtil';
import { apiWithResponseHandle, loading } from './LoadingUtil';
import { convertCursorToNumber, convertNumberToCursor } from '../utils';
import {
  getFileNameFromUrl,
  saveToSessionStorage,
  removeFromSessionStorage,
  getObjectFromSessionStorage,
  createAction,
  delay,
  addDomainToImage,
  convertPKToId,
  getKeyByValue,
} from '../utils';
import { EarningRuleErrorHandleField } from '../components/earning/EarningRuleHandleError';
import { SaveAndBackWithOutTempButtons } from '../components/base/BottomStepComponent';

const stepNames = ['Type', 'Content', 'Properties'];
const sessionKey = SESSION_KEYS.EARNING_RULE_SESSION_KEY

const getInitialState = () => ({
  currentStep: 0,
  stepConfig: defaultStep(stepNames),
  earningRule: {},
  errorFields: [],
  formChanged: false,
  saved: -1,
  checked: CheckStatus.initOrNotChecked,
});

export default {
  namespace: 'createEarningRules',
  state: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    stepChange(state, { payload }) {
      const currentStep = payload.step;
      const isBack = payload.isBack;
      const data = payload.data;
      const isSave = payload.isSave;

      let errorFields = [];
      let checked = CheckStatus.initOrNotChecked;
      if (!isBack) {
        const requiredFieldsConfig = [
          [],
          ['generalName'],
          {
            general_purchase: ['minSpending', 'maxSpending'],
            gps_check_in: ['latitude', 'longitude', 'radius'],
            birthday: ['birthStart', 'birthEnd'],
            reward: ['rewardType', 'coupons', 'quantity', 'rewardTypeX'],
            new_member: [],
            member_referral: [],
            qr_code_scanning: ['qrCodes'],
          },
        ];

        const requiredFields =
          currentStep === 2
            ? [
                ...requiredFieldsConfig[2][data.earningRuleType.toLowerCase()],
                ...requiredFieldsConfig[currentStep]['reward'],
              ]
            : requiredFieldsConfig[currentStep];

        requiredFields.forEach((field, index) => {
          if (field === 'maxSpending') {
            if (
              data[field] &&
              parseInt(data[field]) <= parseInt(data['minSpending'])
            ) {
              errorFields.push(EarningRuleErrorHandleField[field].name);
              return;
            }
            return;
          }

          if (
            field === 'coupons' &&
            data['rewardType'] !== EarningRuleRewardType.coupons
          ) {
            return;
          }

          if (
            field === 'rewardTypeX' &&
            data['type'] !== EarningRuleType.generalPurchase
          ) {
            return;
          }

          if (
            field === 'rewardTypeX' &&
            data['rewardType'] !== EarningRuleRewardType.points &&
            data['type'] === EarningRuleType.generalPurchase
          ) {
            return;
          }

          const value = currentStep === 1 ? data[LanguageConfig.english] : data;

          if (!value[field] || value[field].length <= 0) {
            errorFields.push(EarningRuleErrorHandleField[field].name);
            return;
          }

          if (['latitude', 'longitude'].includes(field)) {
            const dataField = data[field] + '';
            if (dataField.split('.')[0].length > 3) {
              errorFields.push(
                EarningRuleErrorHandleField[`${field}Limit`].name,
              );
              return;
            }
          }
        });

        checked =
          errorFields.length <= 0
            ? CheckStatus.checkedWithSuccess
            : CheckStatus.checkedWithFail;
      }

      const checkInValid = checked === CheckStatus.checkedWithFail;

      const stepConfig = getNewStepConfig(
        currentStep,
        state.stepConfig,
        checkInValid,
        isBack,
      );
      let nextStep = currentStep;
      if (!checkInValid) {
        // if (isBack) {
        //   step = step - 1;
        // } else if (!isSave) {
        //   step = step + 1;
        // }

        nextStep = isBack
          ? currentStep - 1
          : isSave
          ? currentStep
          : currentStep + 1;
      }
      console.log(
        '@@106: ',
        currentStep,
        '->',
        nextStep,
        checkInValid,
        isSave,
        isBack,
        state.checked,
      );

      let earningRule = {};
      if (!isBack) {
        earningRule = getObjectFromSessionStorage(sessionKey);
      }

      return {
        ...state,
        currentStep: nextStep,
        stepConfig,
        errorFields,
        checked:
          nextStep !== currentStep ? CheckStatus.initOrNotChecked : checked,
        earningRule: {
          ...state.earningRule,
          ...earningRule,
        },
      };
    },

    loadEarningRuleFromCookie(state, { payload }) {
      const tempEarningRule = getObjectFromSessionStorage(sessionKey);
      const defaultData = payload ? payload.data.earningRule : {};

      let earningRule = defaultData;
      if (!tempEarningRule) {
        let formatData = defaultData;
        let commonData = {};

        if (Object.keys(defaultData).length > 0) {
          const type = EarningRuleTypeKey[defaultData.type];
          formatData = {
            id: defaultData.id,
            pk: defaultData.pk,
            type: defaultData.type,
            en: {
              generalName: defaultData.name,
              instructionSectionTitle: defaultData.instructionSectionTitle,
              instructionSectionContent: defaultData.instructionSectionContent,
              detailSectionTitle: defaultData.detailSectionTitle,
              detailSectionContent: defaultData.detailSectionContent,
            },

            minSpending: defaultData.generalPurchaseTypeMinimumSpending,
            maxSpending: defaultData.generalPurchaseTypeMaximumSpending,
            eligibleDays:
              defaultData.generalPurchaseTypeEligibleNumberOfDaysSincePurchase,
            selectedStores: defaultData.generalPurchaseTypeLimitedToStores?.edges.map(
              (item, index) => item.node,
            ),
            isExcludeDeliveryCost:
              defaultData.generalPurchaseTypeWillExcludeDeliveryCost,
            isExcludeOtherCharges:
              defaultData.generalPurchaseTypeWillExcludeOtherCharges,
            specialSkus: defaultData.generalPurchaseTypeLimitedToSkus?.edges.map(
              (item) => ({
                pk: item.node.skuId,
                name: item.node.sku,
                category: {
                  pk: item.node.categoryId,
                  name: item.node.categoryName,
                },
              }),
            ),

            referralType: defaultData.memberReferralTypeBeneficiary,
            maxInvitees: defaultData.memberReferralTypeLimit,

            latitude: defaultData.gpsCheckInTypeLatitude,
            longitude: defaultData.gpsCheckInTypeLongitude,
            radius: defaultData.gpsCheckInTypeRadiusInMeter,

            birthStart: defaultData.birthdayTypePeriodStartDate,
            birthEnd: defaultData.birthdayTypePeriodEndDate,

            qrCodes: defaultData.qrCodeScanningTypeQrCodes,

            rewardType: defaultData[`${type}TypeRewardType`],
            quantity:
              defaultData[`${type}TypeRewardType`] ===
              EarningRuleRewardType.points
                ? defaultData[`${type}TypePointsRewardTypePoints`]
                : defaultData[`${type}TypeCouponRewardTypeQuantity`], // template pouints
            coupons: defaultData[`${type}TypeCouponRewardTypeCouponTemplate`],
          };

          if (defaultData.translations?.edges.length > 0) {
            defaultData.translations.edges.forEach((item) => {
              const node = item.node;
              formatData[node.language] = {
                id: node.pk,
              };
              console.log('@@252: ', node);
              formatData[node.language]['instructionSectionTitle'] =
                node.instructionSectionTitle;
              formatData[node.language]['instructionSectionContent'] =
                node.instructionSectionContent;
              formatData[node.language]['detailSectionTitle'] =
                node.detailSectionTitle;
              formatData[node.language]['detailSectionContent'] =
                node.detailSectionContent;
            });
          }

          if (!('zh-Hant' in formatData)) {
            formatData['zh-Hant'] = {};
            formatData['zh-Hant']['language'] = 'zh-Hant';
            formatData['zh-Hant']['instructionSectionTitle'] = '';
            formatData['zh-Hant']['instructionSectionContent'] = '';
            formatData['zh-Hant']['detailSectionTitle'] = '';
            formatData['zh-Hant']['detailSectionContent'] = '';
          }

          if (!('zh-Hans' in formatData)) {
            formatData['zh-Hans'] = {};
            formatData['zh-Hans']['language'] = 'zh-Hans';
            formatData['zh-Hans']['instructionSectionTitle'] = '';
            formatData['zh-Hans']['instructionSectionContent'] = '';
            formatData['zh-Hans']['detailSectionTitle'] = '';
            formatData['zh-Hans']['detailSectionContent'] = '';
          }

          switch (defaultData.type) {
            case EarningRuleType.generalPurchase:
              commonData = {
                quantity:
                  defaultData.generalPurchaseTypeRewardType ===
                  EarningRuleRewardType.points
                    ? defaultData.generalPurchaseTypePointsRewardTypePointsPerXDollarsSpent
                    : defaultData.generalPurchaseTypeCouponRewardTypeQuantity,
                rewardTypeX: defaultData.generalPurchaseTypePointsRewardTypeX,
              };
              break;
            case EarningRuleType.gpsCheckIn:
            case EarningRuleType.qrCodeScanning:
              commonData = {
                overallLimit: defaultData[`${type}TypeOverallLimit`],
                perHeadLimit: defaultData[`${type}TypePerHeadLimit`],
                periodicLimit: defaultData[`${type}TypePeriodicLimit`],
                periodicLimitDays:
                  defaultData[`${type}TypePeriodicLimitEffectiveNumberOfDays`],
                perHeadPeriodicLimit:
                  defaultData[`${type}TypePerHeadPeriodicLimit`],
                perHeadPeriodicLimitDays:
                  defaultData[
                    `${type}TypePerHeadPeriodicLimitEffectiveNumberOfDays`
                  ],
              };

              break;

            case EarningRuleType.memberReferral:
              commonData = {};
              break;
            case EarningRuleType.birthday:
              commonData = {};
              break;

            default:
              break;
          }
        }

        earningRule = { ...formatData, ...commonData };
      }

      if (!defaultData.pk) {
        earningRule = tempEarningRule || {};
        delete earningRule.id;
        delete earningRule.pk;
      }

      saveToSessionStorage(sessionKey, earningRule);
      return {
        ...state,
        earningRule,
      };
    },
    removeEarningRuleFromCookie(state, { payload }) {
      removeFromSessionStorage(sessionKey);

      return {
        ...state,
        formChanged: false,
      };
    },

    // saveOrRemoveEarningRuleFromCookie(state, { payload }) {
    //   let campaign = getObjectFromSessionStorage(sessionKey);
    //   if (payload.data) {
    //     campaign = { ...payload.data };
    //     saveToSessionStorage(sessionKey, campaign);
    //   } else {
    //     removeFromSessionStorage(sessionKey);
    //   }
    //   return {
    //     ...state,
    //   };
    // },
    changeVals(state, { payload }) {
      console.log('@@138: vals changed', payload);
      let tempEarningRule = getObjectFromSessionStorage(sessionKey);
      if (payload.vals) {
        let data = {};
        if (payload.language) {
          data[payload.language] = {
            ...tempEarningRule[payload.language],
            ...payload.vals,
          };
        } else {
          data = payload.vals;
        }

        tempEarningRule = { ...tempEarningRule, ...data };
        saveToSessionStorage(sessionKey, tempEarningRule);
      }

      return {
        ...state,
        formChanged: true,
      };
    },
    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },
  },

  effects: {
    getOneEarningRule: [
      function* ({ payload }, { call, select, put }) {
        const serviceArgs = [
          getOneEarningRule,
          convertPKToId('EarningRuleNode', payload.id),
        ];
        function* onSuccess(data) {
          //save to state.earningRule
          removeFromSessionStorage(sessionKey);
          yield put({
            type: 'loadEarningRuleFromCookie',
            payload: { data },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    createOrUpdateEarningRule: [
      function* ({ payload }, { call, all, put }) {
        const tempEarningRule = getObjectFromSessionStorage(sessionKey);
        if (!tempEarningRule?.type) {
          return;
        }
        const key = EarningRuleTypeKey[tempEarningRule.type];

        let data = {
          name: tempEarningRule.en.generalName,
          type: tempEarningRule.type,
          instructionSectionTitle: tempEarningRule.en.instructionSectionTitle,
          instructionSectionContent:
            tempEarningRule.en.instructionSectionContent,
          detailSectionTitle: tempEarningRule.en.detailSectionTitle,
          detailSectionContent: tempEarningRule.en.detailSectionContent,
          translations: [
            LanguageConfig.traditionalChinese,
            LanguageConfig.simplifiedChinese,
          ].map((lang) => {
            delete tempEarningRule[lang].generalName;
            return {
              ...tempEarningRule[lang],
              language: lang,
            };
          }),
        };
        data[`${key}TypeRewardType`] = tempEarningRule.rewardType;
        let rewardData = {};
        if (tempEarningRule.rewardType === EarningRuleRewardType.points) {
          rewardData[`${key}TypePointsRewardTypePoints`] =
            tempEarningRule.quantity;
        } else {
          rewardData[`${key}TypeCouponRewardTypeCouponTemplate`] =
            tempEarningRule.coupons.pk;
          rewardData[`${key}TypeCouponRewardTypeQuantity`] =
            tempEarningRule.quantity;
        }

        let values = {};
        switch (tempEarningRule.type) {
          case EarningRuleType.generalPurchase:
            values = {
              generalPurchaseTypeMinimumSpending: tempEarningRule.minSpending,
              generalPurchaseTypeMaximumSpending:
                tempEarningRule.maxSpending || null,
              generalPurchaseTypeEligibleNumberOfDaysSincePurchase:
                tempEarningRule.eligibleDays || null,
              generalPurchaseTypeWillExcludeDeliveryCost:
                tempEarningRule.isExcludeDeliveryCost || false,
              generalPurchaseTypeWillExcludeOtherCharges:
                tempEarningRule.isExcludeOtherCharges || false,
              generalPurchaseTypeLimitedToStores: tempEarningRule.selectedStores
                ? tempEarningRule.selectedStores.map((item, index) => item.pk)
                : [],
              generalPurchaseTypeRewardType: tempEarningRule.rewardType,
              // generalPurchaseTypeLimitedSkus: tempEarningRule.specialSkus,
              generalPurchaseTypeLimitedSkus: tempEarningRule.specialSkus?.map(
                (item) => ({
                  skuId: item.pk,
                  sku: item.name,
                  categoryName: item.category.name,
                  categoryId: item.category.pk,
                }),
              ),
            };
            if (tempEarningRule.rewardType === EarningRuleRewardType.coupons) {
              values['generalPurchaseTypeCouponRewardTypeCouponTemplate'] =
                tempEarningRule.coupons.pk;
              values['generalPurchaseTypeCouponRewardTypeQuantity'] =
                tempEarningRule.quantity;
            } else {
              values[
                'generalPurchaseTypePointsRewardTypePointsPerXDollarsSpent'
              ] = tempEarningRule.quantity;
              values['generalPurchaseTypePointsRewardTypeX'] =
                tempEarningRule.rewardTypeX;
            }
            break;
          case EarningRuleType.gpsCheckIn:
            values = {
              gpsCheckInTypeLatitude: tempEarningRule.latitude,
              gpsCheckInTypeLongitude: tempEarningRule.longitude,
              gpsCheckInTypeRadiusInMeter: tempEarningRule.radius,
              gpsCheckInTypeOverallLimit: tempEarningRule.overallLimit,
              gpsCheckInTypePerHeadLimit: tempEarningRule.perHeadLimit,
              gpsCheckInTypePeriodicLimit: tempEarningRule.periodicLimit,
              gpsCheckInTypePeriodicLimitEffectiveNumberOfDays:
                tempEarningRule.periodicLimitDays,
              gpsCheckInTypePerHeadPeriodicLimit:
                tempEarningRule.perHeadPeriodicLimit,
              gpsCheckInTypePerHeadPeriodicLimitEffectiveNumberOfDays:
                tempEarningRule.perHeadPeriodicLimitDays,
              ...rewardData,
            };
            break;
          case EarningRuleType.memberReferral:
            values = {
              memberReferralTypeBeneficiary: tempEarningRule.referralType.toUpperCase(),
              memberReferralTypeLimit: tempEarningRule.maxInvitees,
              ...rewardData,
            };
            break;
          case EarningRuleType.birthday:
            values = {
              birthdayTypePeriodStartDate: tempEarningRule.birthStart,
              birthdayTypePeriodEndDate: tempEarningRule.birthEnd,
              ...rewardData,
            };
            break;
          case EarningRuleType.qrCodeScanning:
            values = {
              qrCodeScanningTypeQrCodes: tempEarningRule.qrCodes,
              qrCodeScanningTypeOverallLimit: tempEarningRule.overallLimit,
              qrCodeScanningTypePerHeadLimit: tempEarningRule.perHeadLimit,
              qrCodeScanningTypePeriodicLimit: tempEarningRule.periodicLimit,
              qrCodeScanningTypePeriodicLimitEffectiveNumberOfDays:
                tempEarningRule.periodicLimitDays,
              qrCodeScanningTypePerHeadPeriodicLimit:
                tempEarningRule.perHeadPeriodicLimit,
              qrCodeScanningTypePerHeadPeriodicLimitEffectiveNumberOfDays:
                tempEarningRule.perHeadPeriodicLimitDays,
              ...rewardData,
            };
            break;
          default:
            values = {
              ...rewardData,
            };
            break;
        }

        let serviceArgs = [createEarningRule, { ...data, ...values }];
        if (tempEarningRule.pk) {
          serviceArgs = [
            updateEarningRule,
            { id: tempEarningRule.pk, ...data, ...values },
          ];
        }
        console.log('@@493: ', { ...data, ...values });
        function* onSuccess(data) {
          console.log('@@267', data);
          if (
            ('createEarningRule' in data && data.createEarningRule.errors) ||
            ('updateEarningRule' in data && data.updateEarningRule.errors)
          ) {
            yield put({
              type: 'updateState',
              payload: {
                saved: 0,
                formChanged: false,
                checked: CheckStatus.initOrNotChecked,
              },
            });
          } else {
            yield put({
              type: 'updateState',
              payload: {
                saved: 1,
                formChanged: false,
                earningRule: { pk: data.createEarningRule?.node?.pk },
                checked: CheckStatus.initOrNotChecked,
              },
            });
          }

          yield put({ type: 'removeEarningRuleFromCookie' });
          // removeFromSessionStorage(sessionKey);
        }

        function* onFail(errors) {
          console.log('@275:', errors);

          yield put({
            type: 'updateState',
            payload: {
              saved: 0,
              formChanged: false,
              checked: CheckStatus.initOrNotChecked,
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFail);
      },
      { type: 'takeLatest' },
    ],
  },
};
