import React, { useEffect,use } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Image, Table } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import './couponUseRecordSection.scss';
import {

} from '../../config/CustomEnums';
import { Button } from 'react-bootstrap';
import { createAction } from '../../utils';


function CouponUseRecordSection() {
  const dispatch = useDispatch();
  const { reportPK } = useSelector((state) => ({
    reportPK: state.paymentSettlementList.detail?.pk,
  }));

  const GoToViewCouponRecordPage = (reportPK) => {
    const paymentAndSettlementReport = reportPK;
    const newWindow = window.open(
      `/coupon_records?paymentAndSettlementReport=${reportPK}`,
      '_blank',
    );
  };

  const GoToViewCouponRecordButton= (reportPK) => {
    return (
      <button
        onClick={() => {
            GoToViewCouponRecordPage(reportPK);
          }}
        className="payment-settlement-button payment-settlement-coupon-use-record-section-button "
      >Go to view coupon record</button>
    );
  }
  return (
    <>
      <label className="create-section-title">COUPON USE RECORD</label>
      {GoToViewCouponRecordButton(reportPK)}
    </>
  );
}

export default CouponUseRecordSection;
