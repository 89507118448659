import React, { useEffect, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CustomBreadcrumb from '../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../components/base/CustomListComponent';
import { campaignListBreads } from '../../config/CustomBreadConfig';
import CampaignOverviewTab from '../../components/campaign/campaignList/CampaignOverviewTab';
import { getHashKeyString, createAction } from '../../utils';
import { URLFragments } from '../../components/campaign/CampaignUrlConfig';
import { CampaignType } from '../../config/CustomEnums';

import DateRangeSelection from '../../components/dashboard/DateRangeSelection';
import DateRangeCompare from '../../components/dashboard/DateRangeCompare';

import { TimeFormater } from '../../utils/TimeFormatUtil';

function CampaignsAnalytics() {
  const dispatch = useDispatch();
  // const [firstTimePickerChange, setFirstTimePickerChange] = useState(true);
  const [datePicker, setDatePicker] = useState();
  const [pickerCampaignOverviewDate, setPickerCampaignOverviewDate] = useState(null);

  const location = useLocation();
  const activeTab = getHashKeyString(location.hash) || '';
  const { campaignOverviewFilter, campaignOverviewDate } = useSelector(
    (state) => ({
      campaignOverviewFilter: state.overview.campaignOverviewFilter,
      campaignOverviewDate: state.overview.campaignOverviewDate,
    }),
  );
  const fromModel = 'CAMPAIGN';

  useEffect(() => {
    const start = datePicker?.start;
    const end = datePicker?.end;
    if (start && end) {
      setPickerCampaignOverviewDate({
        startDate: start.format(TimeFormater.GTMFormat),
        endDate: end.format(TimeFormater.GTMFormat),
        startDateWithoutFormat: start,
        endDateWithoutFormat: end,
      })
    }
  }, [datePicker])

  useEffect(() => {
    return () => {
      dispatch({ type: 'overview/clearState' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);




  const requestData = (start, end) => {
    if (!start || !end) {
      return;
    }
    dispatch({ type: 'overview/clearStateExcludeFilter' });
    console.log('@79', campaignOverviewFilter);
    const fomartStartDate = start.format(TimeFormater.GTMFormat);
    const fomartEndDate = end.format(TimeFormater.GTMFormat);

    dispatch(
      createAction('overview/updateState')({
        campaignOverviewDate: {
          startDate: fomartStartDate,
          endDate: fomartEndDate,
          startDateWithoutFormat: start,
          endDateWithoutFormat: end,
        },
      }),
    );

    console.log('@63', campaignOverviewFilter?.brand, campaignOverviewDate);
    if (start && end) {
      dispatch(
        createAction('overview/getCampaignsRankingTable')({
          fromModel: fromModel,
          brand: campaignOverviewFilter?.brand?.pk,
        }),
      );
    }

    if (start && end) {
      console.log('@140', campaignOverviewFilter);
      dispatch(
        createAction('overview/getCampaignsData')({
          fromModel: fromModel,
          startDate: fomartStartDate,
          endDate: fomartEndDate,
          campaignOverviewFilter: {
            channel: campaignOverviewFilter?.channel?.pk,
            targetMarket: campaignOverviewFilter?.targetMarket?.pk,
            brand: campaignOverviewFilter?.brand?.pk,
            campaign: campaignOverviewFilter?.campaign?.pk,
            isStarred: campaignOverviewFilter?.isStarred,
          },
        }),
      );
    }
  };

  const tabs = [
    {
      name: '',
      content: (pickerCampaignOverviewDate?.startDate) && <CampaignOverviewTab
        campaignOverviewDate={pickerCampaignOverviewDate} />
    },
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail: 'Campaign list, should edit with the following',
          title: 'Campaigns',
        }}
        defaultActiveKey={activeTab}
        breadcrumb={<CustomBreadcrumb breadcrumbs={campaignListBreads()} />}
        buttons={[]}
        renderCompare={() => {
          return (
            <>
              <DateRangeCompare inCustomListComponent />
              <button
                type="button"
                className="btn-further btn btn-primary"
                onClick={() => {
                  requestData(datePicker?.start, datePicker?.end);
                }}
              >
                Refresh
              </button>
            </>
          );
        }}
        renderTabsRight={() => {
          return (
            <DateRangeSelection
              onTab
              applyDateRangeEvent={(startDate, endDate) => {
                console.log('@348', startDate, endDate);
                // setFirstTimePickerChange(false);
                setDatePicker({ start: startDate, end: endDate });
                // if (firstTimePickerChange) {
                //   return;
                // }
                // requestData(startDate, endDate);
              }}
              clearDataEvent={() => {
                dispatch(createAction('overview/clearStateExcludeFilter')());
              }}
            />
          );
        }}
        tabs={tabs}
      />
    </>
  );
}

export default CampaignsAnalytics;
