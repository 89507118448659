import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../../components/base/BaseFilter';
import { useSelector, useDispatch } from 'react-redux';
import { ArrayStringData } from '../../../../utils/index';

function GoodieBagEntitlementTrackingCodeFilter({ backAction = () => {} }) {
  const location = useLocation();
  const dispatch = useDispatch();

  const parsedSearch = queryString.parse(location.search);
  const searchActivePeriod = parsedSearch['active_period'] || '';
  const searchStatus = parsedSearch['status'] || '';

  const [activePeriod, setActivePeriod] = useState(searchActivePeriod);
  const [status, setStatus] = useState(ArrayStringData(searchStatus));

  const content = [
    {
      // displayTitle: 'Active period',
      title: 'Active period',
      value: activePeriod,
      setValue: setActivePeriod,
      componment: FilterTypeEnum.dateRange,
    },
    {
      title: 'Status',
      data: [
        { name: 'Active', pk: 'ACTIVE' },
        { name: 'Inactive', pk: 'INACTIVE' },
        { name: 'Expired', pk: 'EXPIRED' },
      ],
      value: status,
      setValue: setStatus,
      componment: FilterTypeEnum.choice,
      className: 'fit-content-height-area',
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

export default GoodieBagEntitlementTrackingCodeFilter;
