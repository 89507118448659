import React, { useEffect, useState } from 'react';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { PermissionCodes } from '../../../config/PermissionCodes';
import AuthButton from '../../../components/base/AuthButton';
import { useHistory, useLocation } from 'react-router-dom';
import DeletePrompt from '../../../components/base/DeletePrompt';
import { useDispatch, connect } from 'react-redux';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import queryString from 'query-string';
import { t } from 'i18n-js';

function CampaignGroupList({
  pageInfo,
  totalCount,
  campaignGroupList,
  totalPage,
  checkedList,
  listDisplayFields,
}) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  useEffect(() => {
    console.log('@37', campaignGroupList);

    const searchDict = queryString.parse(location.search);
    const searchKey = searchDict['search'];
    const stringRank = searchDict['rank'] || 'true';
    const stringPage = searchDict['page'] || 1;
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);
    dispatch({
      type: 'campaignGroups/getList',
      payload: {
        ...searchDict,
        page,
        rank,
        filterName: searchKey,
      },
    });
  }, [dispatch, location]);

  const buttons = [
    <AuthButton
      title="Create Fortune Bag group"
      action={() => {
        history.push('/campaign_groups/create');
      }}
      requires={PermissionCodes.changeGoodieBagGroup}
    />,
  ];

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.changeGoodieBagGroup,
    },
  ];

  const tabs = [
    {
      name: '',
      content: (
        <BaseTabListContainer
          hideTab={true}
          groupActions={groupActions}
          filter={{
            hasFilter: false,
          }}
          pageInfo={pageInfo}
          totalCount={totalCount ? totalCount : 0}
          listContent={
            <BaseListContainer
              fields={listDisplayFields}
              model={'campaignGroups'}
              permissionGroup={PermissionCodes.campaignGroup}
              dataList={campaignGroupList}
              totalPage={totalPage}
              rankDisabled
              deleteInfo={{
                data: [],
                title: 'Fortune Bag groups',
                onComfirm: {},
              }}
              actions={['Edit', 'Detail', 'Delete']}
            />
          }
        />
      ),
    },
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail: t('Able to select some fortune bags and group them'),
          title: 'Fortune Bag groups',
        }}
        buttons={buttons}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={true}
        tabs={tabs}
      />
      <DeletePrompt
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch({
            type: 'campaignGroups/delete',
            payload: {
              afterAction: () => history.push(location),
            },
          });
        }}
        data={checkedList}
        title={'Fortune Bag groups'}
      />
    </>
  );
}
const mapPropsToState = (state) => ({
  pageInfo: state.campaignGroups.pageInfo,
  totalCount: state.campaignGroups.totalCount,
  campaignGroupList: state.campaignGroups.pagedList,
  totalPage: state.campaignGroups.totalPage,
  checkedList: state.campaignGroups.checkedList,
  listDisplayFields: state.campaignGroups.listDisplayFields,
});
export default connect(mapPropsToState)(CampaignGroupList);
