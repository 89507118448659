import React, { useEffect } from 'react';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { createAction } from '../../../utils';

import { APIStatus } from '../../../config/CustomEnums';
import BaseForm from '../../../components/base/v2/BaseForm';
import Loading from '../../../components/base/Loading';
import CreateChannelContent from './CreateChannelContent';

function CreateChannel() {
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = params;

  const {
    formHasSubmitted,
    channel,
    hasUpdatedDefaultValues,
    createStatus,
    apiStatus,
  } = useSelector((state) => ({
    formHasSubmitted: state.channel.formHasSubmitted,
    channel: state.channel.detail,
    hasUpdatedDefaultValues: state.channel.hasUpdatedDefaultValues,
    createStatus: state.channel.createStatus,
    apiStatus: state.channel.apiStatus,
  }));

  const stepSet = [<CreateChannelContent />];
  useEffect(() => {
    if (id) {
      dispatch(createAction('channel/getOneDetail')({ id: params.id }));
    } else {
      dispatch(createAction('channel/loadFromCookie')());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  useEffect(() => {
    dispatch({ type: 'targetMarket/getAllList' });
    return () => {
      dispatch({ type: 'channel/clearData' });
      dispatch({ type: 'targetMarket/clearData' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (apiStatus === APIStatus.success) history.push('/channels');
  }, [apiStatus, history]);

  if (id && !channel?.pk) {
    return <Loading customClass={'common-full-height'} />;
  }

  return (
    <>
      <BaseForm
        defaultValues={channel}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={formHasSubmitted}
        tempSave={(save, getValues) => {
          if (!save) {
            dispatch({
              type: 'channel/removeFromCookie',
            });
          } else {
            dispatch({
              type: 'channel/saveToCookie',
              payload: getValues(),
            });
          }
        }}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: 'Channel is successfully created.',
          steps: null,
          buttons: [
            {
              text: 'Back to channel list',
              action: () => {
                history.push({
                  pathname: '/channels',
                });
              },
            },
          ],
        }}
        continueCreateConfig={{
          onConfirm: () => {
            history.push(history.location.state?.from);
          },
          onCancel: () => history.push('/channels'),
          onClose: () => history.push('/channels'),
        }}
        showFinishPop={createStatus === APIStatus.success}
        content={stepSet}
        currentStep={0}
        breadcrumb={
          <CustomBreadcrumb
            name={params.id ? channel.name : 'Create channel'}
          />
        }
        caution={{
          detail: 'coming soon...',
          title: params.id ? channel.name : 'Create channel',
        }}
      />
    </>
  );
}

export default CreateChannel;
