import React from 'react';
import { Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import './campaignSection.scss';

function CampaignSection(props) {
  const { detail } = useSelector((state) => ({
    detail: state.paymentSettlementList.detail
  }));

  const fieldNameText = (value) => {
    return (
      <th className="create-section-label campaign-section-field-clumn">
        {value}
      </th>
    );
  };

  const fieldValueText = (value, className) => {
    return (
      <td
        className={className ? className : "campaign-section-field-input"}
      >
        {value}
      </td>
    );
  };

  const viewCampaignList = (detail) => {
    return(
      <>  
      {detail.campaigns?.map((item) => (
        <tr>
          {fieldValueText(item.id,"campaign-section-field-input-id")}
          {fieldValueText(item.name)}
          {fieldValueText(item.active_period)}
          {fieldValueText(item.visible_period)}
        </tr>
      ))}
      </>)
  };

  return (
    <>
      <label className="create-section-title">CAMPAIGN</label>
        <Table bordered className="campaign-section-table-container">
          <thead>
            <tr>
              {fieldNameText('Campaign ID')}
              {fieldNameText('Campaign name')}
              {fieldNameText('Active period')}
              {fieldNameText('Visible period')}
            </tr>
          </thead>
          <tbody>
              {viewCampaignList(detail)}
          </tbody>
        </Table>
    </>
  );
}

export default CampaignSection;
