import React from 'react';
import WeekHours from './WeekHours';
import HolidayHours from './HolidayHours';

export default function BusinessHours({ language, focusChange }) {
  console.log('BusinessHours');
  return (
    <>
      <WeekHours language={language} focusChange={focusChange} />
      <HolidayHours language={language} focusChange={focusChange} />
    </>
  );
}
