import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import ImageUploader from '../../base/ImageUploader';
import './KeyVisualSection.scss';
import { LanguageConfig, CampaignType } from '../../../config/CustomEnums';
import i18n from '../../../I18n';
import { CampaignErrorHandleField } from './CreateCampaignHandleError';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { useFormContext } from 'react-hook-form';
import { errorMessage } from '../../base/ErrorFieldMessage';
import {
  insertKeyToTranslations,
  createAction,
  insertKeyToAllLanguages,
} from '../../../utils';
import CreateLogoLabelPrompt from '../../logoLabel/CreateLogoLabelPrompt';
import LabelWithSwitch, { ApplySwitch } from '../../base/LabelWithSwitch';
import {
  changeSwitch,
  isShowCouponRelated,
} from '../../../models/CreateCampaignModel';
import BaseMutipleSelectorV2 from '../../base/BaseMultipleSelectorV2';

function KeyVisualSection({
  logoLabelList,
  language,
  newLogoLabelDetail,
  newLogoLabelPk,
}) {
  const dispatch = useDispatch();
  const { watch, setValue, formState, getValues } = useFormContext();
  const errors = formState.errors;
  const translations = watch('translations') || {};
  const coverPhoto = translations[language]?.coverPhoto || '';
  const detailPhotos = translations[language]?.detailPhotos || [];
  const logoLabels = translations[language]?.logoLabels || [];
  const campaignCoverPhoto = [coverPhoto]?.filter((coverPhoto) => coverPhoto);
  const campaignOtherPhotos = detailPhotos?.filter((item) => item);
  const [showPrompt, setShowPrompt] = useState(false);
  const [newLogoLabel, setNewLogoLabel] = useState(false);
  const watchCampaignType = watch('campaignType');
  const languages = watch('selectedLanguages') || [];
  const applyCoverPhotoToAllLanguage = watch('applyCoverPhotoToAllLanguage');
  const applyDetailPhotosToAllLanguage = watch(
    'applyDetailPhotosToAllLanguage',
  );
  const isFollowCouponTemplateCoverPhoto = watch(
    'isFollowCouponTemplateCoverPhoto',
  );
  const isFollowCouponTemplateDetailPhotos = watch(
    'isFollowCouponTemplateDetailPhotos',
  );
  const isFollowCouponTemplateLogoLabels = watch(
    'isFollowCouponTemplateLogoLabels',
  );
  const isCouponRelated = isShowCouponRelated(watchCampaignType);

  useEffect(() => {
    if (newLogoLabel && newLogoLabelPk) {
      dispatch(
        createAction('logoLabel/getOneDetail')({
          id: newLogoLabelPk,
        }),
      );
    }
  }, [newLogoLabel, newLogoLabelPk]);

  useEffect(() => {
    if (newLogoLabel && newLogoLabelDetail?.pk && logoLabels) {
      const filterLogoLabel = logoLabels.filter(
        (item) => item.pk != newLogoLabelDetail.pk,
      );
      insertKeyToTranslations({
        language,
        key: 'logoLabels',
        value: [...filterLogoLabel, newLogoLabelDetail],
        setValue,
        getValues,
      });
      setNewLogoLabel(false);
    }
  }, [newLogoLabelDetail]);

  useEffect(() => {
    dispatch({ type: 'logoLabel/clearState' });
    dispatch({ type: 'logoLabel/getAllList' });
    return () => {
      dispatch({ type: 'logoLabel/clearState' });
    };
  }, []);
  console.log('campaignCoverPhoto:', campaignCoverPhoto);
  return (
    <>
      <label className="create-section-title">
        {i18n.t('key_visual', { locale: 'en' })}
      </label>
      {language === LanguageConfig.english ? null : (
        <label className="create-campaign-cover-photo-reminder">
          {i18n.t('campaign.cover_photo_reminder', { locale: 'en' })}
        </label>
      )}
      <LabelWithSwitch
        labelText={i18n.t('cover_photo', { locale: 'en' })}
        checked={isFollowCouponTemplateCoverPhoto}
        className="create-section-label-bottom-space"
        onChange={() => {
          changeSwitch({
            getValues,
            setValue,
            languages,
            key: 'isFollowCouponTemplateCoverPhoto',
            value: !isFollowCouponTemplateCoverPhoto,
          });
          setValue('applyCoverPhotoToAllLanguage', false, {
            shouldDirty: true,
          });
        }}
        showSwitch={isCouponRelated}
      />
      <label className="create-campaign-cover-photo-reminder">
        {i18n.t('campaign_cover_photo_suggest', { locale: 'en' })}
      </label>
      <ImageUploader
        minWidth={634}
        minHeight={476}
        disabled={
          isFollowCouponTemplateCoverPhoto ||
          (language !== LanguageConfig.english && applyCoverPhotoToAllLanguage)
        }
        images={campaignCoverPhoto}
        onImageStateChange={(newState) => {
          const applyLanguages = applyCoverPhotoToAllLanguage
            ? languages
            : [language];
          insertKeyToAllLanguages({
            languages: applyLanguages,
            key: 'coverPhoto',
            value: newState[0],
            setValue,
            getValues,
          });
        }}
        aspect={634 / 476}
        maxImageNum={1}
        language={language}
        photoSectionTitle={'cover_photo'}
        errorMessage={errorMessage(
          errors,
          `translations.${language}.coverPhoto`,
        )}
      />
      <span id={`${CampaignErrorHandleField.coverPhoto.name}-${language}`} />
      <ApplySwitch
        disabled={false}
        show={
          language === LanguageConfig.english &&
          !isFollowCouponTemplateCoverPhoto
        }
        checked={applyCoverPhotoToAllLanguage}
        onChange={(value) => {
          setValue('applyCoverPhotoToAllLanguage', value, {
            shouldDirty: true,
          });
          if (value) {
            insertKeyToAllLanguages({
              languages,
              key: 'coverPhoto',
              value: coverPhoto,
              setValue,
              getValues,
            });
          }
        }}
      />
      <LabelWithSwitch
        labelText={i18n.t('other_photos', { locale: 'en' })}
        checked={isFollowCouponTemplateDetailPhotos}
        className="create-section-label-bottom-space"
        onChange={() => {
          changeSwitch({
            getValues,
            setValue,
            languages,
            key: 'isFollowCouponTemplateDetailPhotos',
            value: !isFollowCouponTemplateDetailPhotos,
          });
          setValue('applyDetailPhotosToAllLanguage', false, {
            shouldDirty: true,
          });
        }}
        showSwitch={isCouponRelated}
      />

      <label className="create-campaign-cover-photo-reminder">
        {i18n.t('campaign_cover_photo_suggest', { locale: 'en' })}
      </label>
      <ImageUploader
        minWidth={634}
        minHeight={476}
        disabled={
          isFollowCouponTemplateDetailPhotos ||
          (language !== LanguageConfig.english &&
            applyDetailPhotosToAllLanguage)
        }
        images={campaignOtherPhotos}
        onImageStateChange={(newState) => {
          const applyLanguages = applyDetailPhotosToAllLanguage
            ? languages
            : [language];
          insertKeyToAllLanguages({
            languages: applyLanguages,
            key: 'detailPhotos',
            value: newState,
            setValue,
            getValues,
          });
        }}
        aspect={634 / 476}
        maxImageNum={4}
        language={language}
        photoSectionTitle={'other_photo'}
      />
      <ApplySwitch
        disabled={false}
        show={
          language === LanguageConfig.english &&
          !isFollowCouponTemplateDetailPhotos
        }
        checked={applyDetailPhotosToAllLanguage}
        onChange={(value) => {
          setValue('applyDetailPhotosToAllLanguage', value, {
            shouldDirty: true,
          });
          if (value) {
            insertKeyToAllLanguages({
              languages,
              key: 'detailPhotos',
              value: detailPhotos,
              setValue,
              getValues,
            });
          }
        }}
      />
      <BaseMutipleSelectorV2
        title={i18n.t('logo_label_optional', { locale: 'en' })}
        namespace="logoLabel"
        switchInfo={{
          checked: isFollowCouponTemplateLogoLabels,
          checkChange: () =>
            changeSwitch({
              getValues,
              setValue,
              languages,
              key: 'isFollowCouponTemplateLogoLabels',
              value: !isFollowCouponTemplateLogoLabels,
            }),
          showSwitch: isCouponRelated,
        }}
        disabled={isFollowCouponTemplateLogoLabels && isCouponRelated}
        addButton={{
          action: () => {
            setShowPrompt(true);
          },
          title: 'Add Logo label',
          requires: PermissionCodes.addLogoLabel,
          customClass: 'general-section-add-new-brand btn-add-button-common',
        }}
        data={{
          sourceData: logoLabelList,
          targetData: logoLabels,
          targetChange: (value) => {
            insertKeyToTranslations({
              language,
              key: 'logoLabels',
              value: value,
              setValue,
              getValues,
            });
          },
        }}
      />
      <CreateLogoLabelPrompt
        show={showPrompt}
        onClose={() => {
          setShowPrompt(false);
        }}
        onConfirm={() => {
          setNewLogoLabel(true);
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  logoLabelList: state.logoLabel.notPagedAllList,
  newLogoLabelDetail: state.logoLabel.detail,
  newLogoLabelPk: state.logoLabel?.newLogoLabelPk,
});

export default connect(mapPropsToState)(KeyVisualSection);
