import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { EarningRuleType } from '../../../config/CustomEnums';
import ContentSections from '../../../components/base/ContentSections';

import { SaveAndBackWithOutTempButtons } from '../../../components/base/BottomStepComponent';
import RewardSection from '../../../components/earning/RewardSection';
import GeneralPurchaseSection from '../../../components/earning/GeneralPurchaseSection';
import GPSCheckInSection from '../../../components/earning/GPSCheckInSection';
import ReferralSection from '../../../components/earning/ReferralSection';
import BirthdaySection from '../../../components/earning/BirthdaySection';
import QRCodeScanningSection from '../../../components/earning/QRCodeScanningSection';
import { createAction, getObjectFromSessionStorage } from '../../../utils';

const dataArray = [];

function CreateEarningruleStepThree(props) {
  let tempEarningRule = getObjectFromSessionStorage('tempEarningRule');
  const earningRuleType = tempEarningRule?.type;

  const { checked } = useSelector((state) => ({
    checked: state.createEarningRules.checked,
  }));

  const dispatch = useDispatch();
  const [onCheck, setOnCheck] = useState({
    value: false,
    isBack: false,
  });
  const isChecking = ({ isBack }) => {
    setOnCheck({
      value: true,
      isBack: isBack,
    });
  };

  const stopCheck = () => {
    setOnCheck({
      value: false,
      isBack: false,
    });
  };

  useEffect(() => {
    if (checked > 0) {
      dispatch(
        createAction('createEarningRules/createOrUpdateEarningRule')({
          isBack: onCheck.isBack,
          step: 2,
          isSave: true,
        }),
      );
    }

    if (onCheck.value) {
      let formatedData = { earningRuleType };
      dataArray.forEach((item) => {
        formatedData = {
          ...formatedData,
          ...item,
        };
      });

      dispatch({
        type: 'createEarningRules/stepChange',
        payload: {
          data: formatedData,
          isBack: onCheck.isBack,
          step: 2,
          isSave: true,
        },
      });
    }

    stopCheck();
  }, [dispatch, onCheck.isBack, onCheck.value, earningRuleType, checked]);

  const submitAction = (data) => {
    dataArray.push(data);
  };

  const earningRuleProperties = () => {
    let properties;
    switch (earningRuleType) {
      case EarningRuleType.generalPurchase:
        properties = (
          <GeneralPurchaseSection
            onSubmit={onCheck.value}
            onSubmitAction={(data) => {
              submitAction(data);
            }}
            defaultValue={tempEarningRule}
          />
        );
        break;
      case EarningRuleType.gpsCheckIn:
        properties = (
          <GPSCheckInSection
            onSubmit={onCheck.value}
            onSubmitAction={(data) => {
              submitAction(data);
            }}
            defaultValue={tempEarningRule}
          />
        );
        break;
      case EarningRuleType.memberReferral:
        properties = (
          <ReferralSection
            onSubmit={onCheck.value}
            onSubmitAction={(data) => {
              submitAction(data);
            }}
            defaultValue={tempEarningRule}
          />
        );
        break;
      case EarningRuleType.birthday:
        properties = (
          <BirthdaySection
            onSubmit={onCheck.value}
            onSubmitAction={(data) => {
              submitAction(data);
            }}
            defaultValue={tempEarningRule}
          />
        );
        break;
      case EarningRuleType.qrCodeScanning:
        properties = (
          <QRCodeScanningSection
            onSubmit={onCheck.value}
            onSubmitAction={(data) => {
              submitAction(data);
            }}
            defaultValue={tempEarningRule}
          />
        );
        break;
      default:
        properties = null;
        break;
    }
    return properties;
  };

  // const earningRulePropertie = earningRuleProperties();
  const sections = [
    earningRuleProperties(),
    <RewardSection
      onSubmit={onCheck.value}
      onSubmitAction={(data) => {
        submitAction(data);
      }}
      defaultValue={tempEarningRule}
    />,
  ];

  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <SaveAndBackWithOutTempButtons
        saveText={'Save'}
        saveAction={() => {
          isChecking({ isBack: false });
        }}
        backAction={() => {
          isChecking({ isBack: true });
        }}
      />
    </>
  );
}

export default CreateEarningruleStepThree;
