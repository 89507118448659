import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { NavBarNames } from '../../../config/NavBarNameList';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import AuthButton from '../../../components/base/AuthButton';
import AdminActivityLogExportPrompt from '../../../components/admin/AdminActivityLogExportPrompt';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import Filter from './Filter.js';
import { ImportResource } from '../../../models/DownloadImportModel';
import { createAction } from '../../../utils';
import { PermissionCodes } from '../../../config/PermissionCodes';

function AdminActivityLogList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const [showExportView, setShowExportView] = useState(false);

  const { listFields, dataList, pageInfo, totalPage, totalCount, recordCount } =
    useSelector((state) => ({
      listFields: state.adminActivityLog.listDisplayFields,
      dataList: state.adminActivityLog.pagedList,
      pageInfo: state.adminActivityLog.pageInfo,
      totalPage: state.adminActivityLog.totalPage,
      totalCount: state.adminActivityLog.totalCount,
      checkedList: state.adminActivityLog.checkedList,
      recordCount: state.adminActivityLog.recordCount,
    }));

  const getFilterConditionsFromUrl = () => {
    const queryString = require('query-string');
    const querySearch = queryString.parse(location.search);
    const searchCreateAt = querySearch['record_period'] || '';
    const searchActivityType = querySearch['type'] || '';
    const searchAdminGroup = querySearch['administrators_group'] || '';
    const searchAdmin = querySearch['user'] || '';

    let filterConditions = {
      creation_date_range: searchCreateAt,
      activity_type_in: searchActivityType,
      administrator_groups: searchAdminGroup,
      administrators: searchAdmin,
    };
    return filterConditions;
  };

  const itemAction = [];
  const buttons = [
    <AuthButton
      customClass="btn-back-button-common btn-download"
      title="Export"
      action={() => {
        setShowExportView(true);
      }}
      // requires={PermissionCodes.adminActivityLog}
    />,
  ];
  const customActions = {};
  const groupActions = [];

  const tabs = [
    {
      name: '',
      content: (
        <BaseTabListContainer
          hideTab={true}
          tabs={{}}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount}
          hideSearch={true}
          filter={{
            hasFilter: true,
            customFilterAreaClass: 'all-list-filter-container-without-search',
            offset: '360px',
            componment: Filter,
          }}
          listContent={
            <BaseListContainer
              fields={listFields}
              dataList={dataList}
              totalPage={totalPage}
              model={'adminActivityLog'}
              // permissionGroup={PermissionCodes.adminActivityLog}
              deleteInfo={{
                data: [],
                title: 'Admin activity log',
                relatedName: '',
                onComfirm: {},
                relatedSections: [],
              }}
              hideActions={true}
              hasOtherActions={true}
              actions={itemAction}
              customActions={customActions}
              hideCheckbox={true}
            />
          }
        />
      ),
    },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].name);

  useEffect(() => {
    dispatch({ type: 'adminActivityLog/clearData' });

    const queryString = require('query-string');
    const querySearch = queryString.parse(location.search);
    const searchCreateAt = querySearch['record_period'] || '';
    const searchActivityType = querySearch['type'] || '';
    const searchAdminGroup = querySearch['administrators_group'] || '';
    const searchAdmin = querySearch['user'] || '';
    const stringRank = querySearch['rank'] || 'true';
    const stringPage = querySearch['page'] || 1;
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);

    dispatch(
      createAction('adminActivityLog/getList')({
        ...querySearch,
        page,
        reverse: rank,
        searchCreateAt,
        searchActivityType,
        searchAdminGroup,
        searchAdmin,
      }),
    );
  }, [dispatch, location]);

  return (
    <>
      <CustomListComponent
        caution={{
          detail: '',
          title: NavBarNames.adminActivityLog,
        }}
        defaultActiveKey={activeTab}
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        hideTab={true}
        onTabChange={(key) => {
          setActiveTab(key);
        }}
      />
      <AdminActivityLogExportPrompt
        show={showExportView}
        onClose={() => setShowExportView(false)}
        title={'Export admin activity log'}
        exportFilterConditions={getFilterConditionsFromUrl()}
        total={recordCount}
      />
    </>
  );
}

export default AdminActivityLogList;
