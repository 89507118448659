import React, { useState } from 'react';
import BasePrompt from '../../../components/base/prompt/BasePrompt';

const listDisplayItem = (datas) => {
  return datas?.map((data, index) => <li key={index}>{data}</li>);
};

function StoreShowMorePromt({ datas, promptTitle }) {
  const [showRelatedPrompt, setShowRelatedPrompt] = useState(false);
  const [relatedPromptKey, setRelatedPromptKey] = useState(null);
  const [relatedPromptTitle, setRelatedPromptTitle] = useState(null);

  return (
    <>
      {listDisplayItem(datas?.slice(0, 10))}
      {datas?.length > 10 ? (
        <button
          type="button"
          className="btn-link reset-button show-more-button channel-show-more-button"
          onClick={() => {
            setShowRelatedPrompt(true);
            setRelatedPromptKey(datas);
            setRelatedPromptTitle(promptTitle);
          }}
        >
          show more
        </button>
      ) : null}
      <BasePrompt
        show={showRelatedPrompt}
        title={relatedPromptTitle}
        description=""
        closeAction={() => {
          setShowRelatedPrompt(false);
          setRelatedPromptKey(null);
        }}
        otherBody={() => (
          <ul className="related-items-container terms-and-conditions-related-items-container">
            {listDisplayItem(relatedPromptKey)}
          </ul>
        )}
      />
    </>
  );
}

export default StoreShowMorePromt;
