import React from 'react';
import './EntitlementOptions.scss';
import { CustomTitleLabel } from '../../../../components/earning/CustomBaseComponments';
import { isValidHttpUrl } from '../../../../utils';

function Options({ title, fields }) {
  return (
    <>
      <label className="create-section-title">{title}</label>
      {fields.map((section) => {
        return (
          <>
            <div>
              <CustomTitleLabel title={section.title} />
              {section.titleButton}
            </div>
            <div>
              <label className="option-field">
                {isValidHttpUrl(section.field) ? (
                  <a className="option-link" href={section.field}>
                    {section.field}
                  </a>
                ) : (
                  section.field
                )}
                {section.timeString && (
                  <label className="option-time-string">
                    &nbsp; | {section.timeString}
                  </label>
                )}
                {section.fieldButton}
              </label>
            </div>
          </>
        );
      })}
    </>
  );
}

export default Options;
