import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PermissionCodes } from '../../config/PermissionCodes';
import './CustomBatchDownloadPrompt.scss';
import BasePrompt from './prompt/BasePrompt';
import DisabledZipIcon from '../../assets/images/zipIconDisabled.svg';
import EnabledZipIcon from '../../assets/images/zipIconEnabled.svg';
import AuthButton from './AuthButton';
import { createAction } from '../../utils';
import { Image } from 'react-bootstrap';
import LoadingIcon from '../../assets/images/loading.gif';
import { ImportResource } from '../../models/DownloadImportModel';

function Row({ title, buttonName, link, resource, reference, fromModel }) {
  const dispatch = useDispatch();
  return (
    <div className="row">
      <label>{title}</label>
      {link !== undefined ? (
        <a
          className="btn-further upload-select-button"
          href={link}
          disabled={!link}
        >
          {buttonName || 'Download'}
        </a>
      ) : (
        <button
          className="btn-further upload-select-button"
          onClick={() => {
            dispatch(
              createAction('downloadAndImport/createDownloadTask')({
                from: 'SIMPLE_DATA', //resource || title.replace(' ', '_').toUpperCase(),
                related: {
                  fromModel: fromModel,
                  ...(buttonName?.includes('all')
                    ? {
                        downloadModels: reference?.map(
                          (r) =>
                            r.resource?.replaceAll('_', '') ||
                            r.title.replaceAll(' ', '').toUpperCase(),
                        ),
                      }
                    : {
                        downloadModels: [
                          resource?.replaceAll('_', '') ||
                            title.replaceAll(' ', '').toUpperCase(),
                        ],
                      }),
                },
              }),
            );
          }}
        >
          {buttonName || 'Download'}
        </button>
      )}
    </div>
  );
}

export function CustomBatchDownloadPrompt({
  show,
  title,
  description,
  onClose = () => {},
  fromModel,
  source,
}) {
  const dispatch = useDispatch();

  return (
    <BasePrompt
      show={show}
      closeAction={onClose}
      title={title}
      description={description}
      customClass={'download-prompt '}
      otherBody={() => {
        return (
          <div className="download-area">
            {source.map((item, index) => {
              console.log('@@77: ', item);
              return (
                <>
                  {index > 0 ? <div className="sperate-line"></div> : null}
                  <div className="download-area-part">
                    <Row
                      title={item.title}
                      buttonName={item.downloadTitle}
                      link={item.link}
                      reference={item.reference}
                      resource={item.resource}
                      fromModel={fromModel}
                    />
                    <div className="download-area-reference-part">
                      {item?.reference?.map((reference) => (
                        <Row
                          title={reference.title}
                          buttonName={reference.downloadTitle}
                          resource={reference.resource}
                          fromModel={fromModel}
                        />
                      ))}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        );
      }}
    />
  );
}
