import React, { useEffect } from 'react';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import './TransactionAmountSection.scss';
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from '../../utils';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import { CreateTransactionError } from '../../models/CreateTransactionModel';
import {
  isShowError,
  firstError,
} from '../campaign/campaignCreation/CreateCampaignHandleError';
import { LanguageConfig } from '../../config/CustomEnums';

function TransactionAmountSection(props) {
  const dispatch = useDispatch();
  const setTotalValue = props.setTotalValue || (() => {});

  const { totalValue, errorFields } = useSelector((state) => ({
    totalValue: state.createTransaction.transaction.totalValue,
    errorFields: state.createTransaction.errorFields,
  }));

  useEffect(() => {
    firstError(
      CreateTransactionError.totalValue.name,
      errorFields,
      LanguageConfig.english,
    );
    firstError(
      CreateTransactionError.totalValueFormat.name,
      errorFields,
      LanguageConfig.english,
    );
  }, [errorFields]);

  const isError = (fieldName) => {
    return isShowError(fieldName, errorFields, LanguageConfig.english);
  };

  return (
    <>
      <div className="first-section-title">AMOUNT</div>
      <CustomTitleLabel title="Total value" />
      <div className="d-flex">
        <label className="transaction-amount-total-value-label">HK$</label>
        <input
          type="text"
          className={`day-input-fields ${
            isError(CreateTransactionError.totalValue.name) ||
            isError(CreateTransactionError.totalValueFormat.name)
              ? 'error-field'
              : ''
          }`}
          defaultValue={totalValue}
          onInput={(e) => {
            let data = e.target.value;
            data = data.replace(/[^0-9.]/g, '');
            setTotalValue(data);
            dispatch(
              createAction('createTransaction/updateTransactionState')({
                totalValue: data,
              }),
            );
          }}
        />
      </div>
      <ErrorFieldMessage
        id={CreateTransactionError.totalValue.name}
        error={isError(CreateTransactionError.totalValue.name)}
        message={CreateTransactionError.totalValue.message}
      />
      <ErrorFieldMessage
        id={CreateTransactionError.totalValueFormat.name}
        error={isError(CreateTransactionError.totalValueFormat.name)}
        message={CreateTransactionError.totalValueFormat.message}
      />
    </>
  );
}

export default TransactionAmountSection;
