import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { createAction, ArrayStringData } from '../../../utils';
import { useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import { CouponTransactionBy, CampaignType } from '../../../config/CustomEnums';

function CustomerGoodieBagRecordCouponFilter({ backAction = () => {} }) {
  const dispatch = useDispatch();
  const location = useLocation();

  const { couponSetList } = useSelector((state) => ({
    couponSetList: state.couponList.notPagedAllList,
  }));

  const [sourceCouponSet, setSourceCouponSet] = useState();

  const parsedSearch = queryString.parse(location.search);
  const searchReportPeriod = parsedSearch['report_period'] || '';
  const searchTransactionBy = parsedSearch['transaction_by'] || '';
  const searchCouponSet = parsedSearch['coupon_set'] || '';

  const searchTransactionType = parsedSearch['transaction_type'] || '';

  const searchCampaignIsStarred = parsedSearch['starred_campaign'] || '';

  const searchCampaignType = parsedSearch['campaign_type'] || '';

  const [reportPeriod, setReportPeriod] = useState(searchReportPeriod);
  const [transactionBy, setTransactionBy] = useState(searchTransactionBy);
  const [couponSet, setCouponSet] = useState(ArrayStringData(searchCouponSet));

  const [transactionType, setTransactionType] = useState(
    ArrayStringData(searchTransactionType),
  );

  const [campaignIsStarred, setCampaignIsStarred] = useState(
    searchCampaignIsStarred,
  );

  const [campaignType, setCampaignType] = useState(searchCampaignType);

  useEffect(() => {
    dispatch({
      type: 'couponList/getAllList',
      payload: {
        isAll: true,
        pageSize: 1000,
        // listName: 'notPagedAllList',
      },
    });

    return () => {
      dispatch({ type: 'brand/clearData' });
      dispatch({ type: 'couponList/clearData' });
      dispatch({ type: 'campaignList/clearData' });
    };
  }, []);

  const content = [
    {
      title: 'Report period',
      value: reportPeriod,
      setValue: setReportPeriod,
      allowEmpty: [true, true],
      componment: FilterTypeEnum.dateRange,
    },
    {
      title: 'Starred campaign',
      data: [
        { pk: 'true', name: 'Yes' },
        { pk: 'false', name: 'No' },
      ],
      value: campaignIsStarred,
      setValue: setCampaignIsStarred,
      componment: FilterTypeEnum.singleChoice,
    },
    {
      title: 'Transaction by',
      data: [
        { pk: CouponTransactionBy.SYSTEM, name: 'System' },
        { pk: CouponTransactionBy.ADMIN, name: 'Admin' },
        { pk: CouponTransactionBy.CUSTOMER, name: 'Customer' },
      ],
      value: transactionBy,
      setValue: setTransactionBy,
      componment: FilterTypeEnum.singleChoice,
    },
    {
      title: 'Transaction Type',
      data: [
        // { name: 'Admin added', pk: 'GRANT' },
        { name: 'Customer acquired', pk: 'BUY' },
        { name: 'Customer use', pk: 'USE' },
        { name: 'Coupon expired', pk: 'EXPIRED' },
        { name: 'Admin removed', pk: 'RECLAIM' },
      ],
      value: transactionType,
      setValue: setTransactionType,
      componment: FilterTypeEnum.choice,
    },

    {
      title: 'Campaign type',
      data: [
        { pk: CampaignType.couponsCampaign, name: 'Coupon campaign' },
        { pk: CampaignType.cashVoucherCampaign, name: 'Cash Voucher' },
        { pk: CampaignType.giftCampaign, name: 'Goodie Bag Coupon' },
        {
          pk: CampaignType.fortuneBagForGoodieBagCampaign,
          name: 'Goodie bag offer (Direct assign)',
        },
      ],
      value: campaignType,
      setValue: setCampaignType,
      componment: FilterTypeEnum.singleChoice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Coupon set',
      data: sourceCouponSet || couponSetList,
      value: couponSet,
      setValue: setCouponSet,
      componment: FilterTypeEnum.searchableChoice,
      placeholder: 'Search by ID, Name',
      filterAction: (searchKey) => {
        const newData = couponSetList?.filter((item) => {
          const name = item.name?.toLowerCase() || '';
          return (
            name.includes(searchKey?.toLowerCase() || '') ||
            item.pk.toString().includes(searchKey?.toLowerCase() || '')
          );
        });
        setSourceCouponSet(newData);
      },
      renderDisplayName: (item) => `[ID:${item.pk}] ${item.name}`,
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

export default CustomerGoodieBagRecordCouponFilter;
