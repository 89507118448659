import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { connect } from 'react-redux';
import AuthButton from '../../../components/base/AuthButton';
import ContentSections from '../../../components/base/ContentSections';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { createAction } from '../../../utils';
import Loading from '../../../components/base/Loading';

import BaseOverView from '../../../components/coupon/couponList/BaseOverview';
import { Link } from 'react-router-dom';
import BasePrompt from '../../../components/base/prompt/BasePrompt';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';

const WorkingTeamDetail = ({ detail, dispatch, languages }) => {
  const history = useHistory();
  const params = useParams();
  const { id } = params;
  useEffect(() => {
    dispatch(createAction('workingTeam/getOneDetail')({ id }));
    return () => {
      dispatch(createAction('workingTeam/clearData')());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabs = [
    {
      name: 'Detail',
      content: detail.pk ? (
        <div className="scroll-container-common">
          <ContentSections
            hidePreview
            sections={[
              <MembershipInfoCard
                title={'Information'} //i18n.t('information', { locale: language }) ||
                data={{
                  ...detail,
                  displayAdmins: detail.administrators?.map(
                    (item) => `• [ID:${item.pk}] ${item.username}`,
                  ),
                }}
                fields={[
                  [
                    {
                      title: 'ID', //i18n.t('tag', { locale: language }) ||
                      field: 'pk',
                    },
                  ],
                  [
                    {
                      title: 'Name', //i18n.t('tag', { locale: language }) ||
                      field: 'name',
                    },
                  ],
                  [
                    {
                      title: 'Related admin', //i18n.t('attributeTag', { locale: language }) ||
                      field: 'displayAdmins',
                    },
                  ],
                ]}
              />,
            ]}
          />
        </div>
      ) : (
        <Loading />
      ),
    },
  ];

  const buttons = [
    <AuthButton
      title="Edit"
      action={() => {
        history.push({
          pathname: 'edit/',
        });
      }}
      requires={PermissionCodes.changeWorkingTeam}
    />,
  ];
  return (
    <>
      <CustomListComponent
        caution={{
          detail: '',
          title: detail?.name,
        }}
        defaultActiveKey={tabs[0].name}
        breadcrumb={<CustomBreadcrumb name={detail?.name} />}
        buttons={buttons}
        tabs={tabs}
      />
    </>
  );
};

const mapPropsToState = (state) => ({
  detail: state.workingTeam.detail,
});
export default connect(mapPropsToState)(WorkingTeamDetail);
