import i18n from 'i18n-js';
import {
  CouponMediumType,
  DisplayCouponMediumType,
  DisplayUseMethod,
  LanguageConfig,
  UsedPlatform,
} from '../config/CustomEnums';
import {
  COUPON_REDEMPTION_METHOD_CONFIG,
  COUPON_TYPES_CONFIG,
} from '../containers/engagement/coupon/CreateCouponStepOne';
import { addDomainToImage } from '../utils';
import { parseBrand, parseStore } from './BrandModel';
import { IS_HIGH_VALUE_COUPON } from './CouponListModel';
import { getDisplayUsedPlatForm } from './CouponTransactionModel';
import { DISCOUNT_CODE_FORMAT } from './CreateCouponModel';
import { getTermsAndConditionsTemplateDate } from './TermsAndConditionsModel';
import { weekdays } from './StoreModel';
import { TimeFormater, formatDate } from '../utils/TimeFormatUtil';
import moment from 'moment';
function getCouponTemplateTranslation(node, allData) {
  const language = node.language || LanguageConfig.english;
  let otherPhotos = [];
  if (addDomainToImage(node.detailPhoto1)) {
    otherPhotos.push(addDomainToImage(node.detailPhoto1));
  }
  if (addDomainToImage(node.detailPhoto2)) {
    otherPhotos.push(addDomainToImage(node.detailPhoto2));
  }
  if (addDomainToImage(node.detailPhoto3)) {
    otherPhotos.push(addDomainToImage(node.detailPhoto3));
  }
  if (addDomainToImage(node.detailPhoto4)) {
    otherPhotos.push(addDomainToImage(node.detailPhoto4));
  }

  let merchantTermsAndConditionsTitle = node.merchantTermsAndConditionsTitle;
  let merchantTermsAndConditionsContent =
    node.merchantTermsAndConditionsContent;
  let genericTermsAndConditionsTitle = node.genericTermsAndConditionsTitle;
  let genericTermsAndConditionsContent = node.genericTermsAndConditionsContent;

  if (
    allData?.isFollowMerchantTermsAndConditionsTemplate &&
    allData?.merchantTermsAndConditionsTemplate?.pk
  ) {
    merchantTermsAndConditionsTitle =
      allData?.merchantTermsAndConditionsTemplateDate?.translations?.[language]
        ?.title;
    merchantTermsAndConditionsContent =
      allData?.merchantTermsAndConditionsTemplateDate?.translations?.[language]
        ?.content;
  }
  if (
    allData?.isFollowGenericTermsAndConditionsTemplate &&
    allData?.genericTermsAndConditionsTemplate?.pk
  ) {
    genericTermsAndConditionsTitle =
      allData?.genericTermsAndConditionsTemplateDate?.translations?.[language]
        ?.title;
    genericTermsAndConditionsContent =
      allData?.genericTermsAndConditionsTemplateDate?.translations?.[language]
        ?.content;
  }

  return {
    [language]: {
      name: node.name,
      shortDescription: node.shortDescription,
      id: node.id,
      pk: node.pk,
      tourCardSectionTitle: node.tourCardSectionTitle,
      instructionSectionTitle: node.instructionSectionTitle,
      instructionSectionContent: node.instructionSectionContent,
      // detailSectionTitle: node.detailSectionTitle,
      // detailSectionContent: node.detailSectionContent,
      merchantTermsAndConditionsTitle: merchantTermsAndConditionsTitle,
      merchantTermsAndConditionsContent: merchantTermsAndConditionsContent,
      genericTermsAndConditionsTitle: genericTermsAndConditionsTitle,
      genericTermsAndConditionsContent: genericTermsAndConditionsContent,
      offerDetailTitle: node.offerDetailTitle,
      offerDetailContent: node.offerDetailContent,
      coverPhoto: addDomainToImage(node.coverPhoto),
      otherPhoto: otherPhotos,
      logoLabel: (node.logoLabels?.edges || []).map(
        (labelNode) => labelNode.node,
      ),
      landingPageButtonName:
        node.landingPageButtonName || i18n.t('book_now2', language),
      landingPageUrl: node.landingPageUrl,
      indicatorText: allData?.showColorIndicator ? node.indicatorText : null,
    },
  };
}

const campaignFields = [
  'isAllFollowCouponTemplate',
  'isFollowCouponTemplateStores',
  'isFollowCouponTemplateTourCard',
  'isFollowCouponTemplateName',
  'isFollowCouponTemplateShortDescription',
  'isFollowCouponTemplateCoverPhoto',
  'isFollowCouponTemplateDetailPhotos',
  'isFollowCouponTemplateLogoLabels',
  'isFollowCouponTemplateOfferSection',
  'isFollowCouponTemplateInstructionSection',
  'isFollowCouponTemplateMerchantTerms',
  'isFollowCouponTemplateGenericTerms',
];

function parseCampaign(data, couponName) {
  if (!data) {
    return [];
  }
  const campaigns = data.edges || [];
  const result = [];
  campaigns.forEach(({ node }) => {
    for (let i = 0; i < campaignFields.length; i++) {
      const field = campaignFields[i];
      if (!!node[field]) {
        console.log('campaignFields:', campaignFields, couponName);
        const name =
          node.isAllFollowCouponTemplate || node.isFollowCouponTemplateName
            ? couponName
            : node.name;

        result.push({ name, pk: node.pk });
        return;
      }
      const translations = node?.translations?.edges || [];
      for (let i = 0; i < translations.length; i++) {
        const translation = translations[i].node;
        if (!!translation[field]) {
          result.push({ name: node.name, pk: node.pk });
          return;
        }
      }
    }
  });
  return result;
}

const covertTimeToDatetime = (time) => {
  const date = new Date(); // Creates a Date Object using the clients current time

  const [hours, minutes, seconds] = time.split(':');

  date.setHours(+hours); // Set the hours, using implicit type coercion
  date.setMinutes(minutes); // can pass Number or String - doesn't really matter
  date.setSeconds(seconds);

  return date;
};

export const parseSingleCouponRedeemPeriods = (data) => {
  if (!data?.periodRestriction) {
    return {};
  }

  const redeemPeriodsDataDisplay = {
    weeklyRulesDisplay: [],
    dateRulesDisplay: [],
    excludeDateRulesDisplay: [],
    physicalWeeklyRulesDisplay: [],
    physicalDateRulesDisplay: [],
    physicalExcludeDateRulesDisplay: [],
  };

  const redeemPeriodsData = {
    weeklyRules: {},
    dateRules: {},
    excludeDateRules: {},
    physicalWeeklyRules: {},
    physicalDateRules: {},
    physicalExcludeDateRules: {},
  };

  (data?.weeklyRules?.edges || []).forEach((node) => {
    const rule = node.node;

    const status =
      rule.timePeriodType === 'WHOLE_DAY'
        ? 'full day'
        : rule.timePeriods?.edges
            ?.map(
              (period) =>
                `${formatDate(
                  covertTimeToDatetime(period.node.startTime),
                  TimeFormater.timePeriod,
                )} - ${formatDate(
                  covertTimeToDatetime(period.node.endTime),
                  TimeFormater.timePeriod,
                )}`,
            )
            .join(', ');
    (rule.weekDays?.edges || []).forEach((node) => {
      if (node.couponMedium === CouponMediumType.Physical) {
        redeemPeriodsData.physicalWeeklyRules[node.node.pk] = status;
      } else {
        redeemPeriodsData.weeklyRules[node.node.pk] = status;
      }
    });
  });

  const weeklyDays = Object.keys(redeemPeriodsData.weeklyRules || {});
  const physicalWeeklyDays = Object.keys(
    redeemPeriodsData.physicalWeeklyRules || {},
  );

  weekdays.forEach((weekday) => {
    if (weeklyDays.includes(weekday.key.toString())) {
      redeemPeriodsDataDisplay.weeklyRulesDisplay.push(
        `${weekday.shortName}, ${redeemPeriodsData.weeklyRules[weekday.key]}`,
      );
    } else {
      redeemPeriodsDataDisplay.weeklyRulesDisplay.push(
        `${weekday.shortName}, close`,
      );
    }
    if (physicalWeeklyDays.includes(weekday.key.toString())) {
      redeemPeriodsDataDisplay.physicalWeeklyRulesDisplay.push(
        `${weekday.shortName}, ${
          redeemPeriodsData.physicalWeeklyRules[weekday.key]
        }`,
      );
    } else {
      redeemPeriodsDataDisplay.physicalWeeklyRulesDisplay.push(
        `${weekday.shortName}, close`,
      );
    }
  });

  (data?.dateRules?.edges || []).forEach((node) => {
    const rule = node.node;

    const status =
      rule.timePeriodType === 'WHOLE_DAY'
        ? 'full day'
        : rule.timePeriods?.edges
            ?.map(
              (period) =>
                `${formatDate(
                  covertTimeToDatetime(period.node.startTime),
                  TimeFormater.timePeriod,
                )} - ${formatDate(
                  covertTimeToDatetime(period.node.endTime),
                  TimeFormater.timePeriod,
                )}`,
            )
            .join(', ');

    const message = `${formatDate(
      new Date(rule.dayDate),
      TimeFormater.fullDayMonthYearWeek,
    )}, ${status}`;

    if (rule.couponMedium === CouponMediumType.Physical) {
      if (rule.isExclude) {
        redeemPeriodsDataDisplay.physicalExcludeDateRulesDisplay.push(message);
      } else {
        redeemPeriodsDataDisplay.physicalDateRulesDisplay.push(message);
      }
    } else {
      if (rule.isExclude) {
        redeemPeriodsDataDisplay.excludeDateRulesDisplay.push(message);
      } else {
        redeemPeriodsDataDisplay.dateRulesDisplay.push(message);
      }
    }
  });

  return redeemPeriodsDataDisplay;
};

const parseWeeklyRedeemPeriods = (data) => {
  const redeemPeriodsData = {
    weeklyRules: [],
    dateRules: [],
    excludeDateRules: [],
    physicalWeeklyRules: [],
    physicalDateRules: [],
    physicalExcludeDateRules: [],
  };

  (data.weeklyRules?.edges || []).forEach((node) => {
    const rule = node.node;
    const ruleWeekdays = rule.weekDays?.edges?.map((node) => node.node.pk);

    const oneRule = {
      weekdays: weekdays.filter((day) => ruleWeekdays.includes(day.key)),
      isWholeDay: rule.timePeriodType === 'WHOLE_DAY',
      timePeriods:
        rule.timePeriodType === 'WHOLE_DAY'
          ? []
          : rule.timePeriods?.edges?.map((period) => {
              return {
                start: covertTimeToDatetime(period.node.startTime),
                end: covertTimeToDatetime(period.node.endTime),
              };
            }),
    };

    if (node.couponMedium === CouponMediumType.Physical) {
      redeemPeriodsData.physicalWeeklyRules.push(oneRule);
    } else {
      redeemPeriodsData.weeklyRules.push(oneRule);
    }
  });

  (data.dateRules?.edges || []).forEach((node) => {
    const rule = node.node;
    const oneRule = {
      date: new Date(rule.dayDate),
      isWholeDay: rule.timePeriodType === 'WHOLE_DAY',
      timePeriods:
        rule.timePeriodType === 'WHOLE_DAY'
          ? []
          : rule.timePeriods?.edges?.map((period) => ({
              start: covertTimeToDatetime(period.node.startTime),
              end: covertTimeToDatetime(period.node.endTime),
            })),
    };

    if (rule.couponMedium === CouponMediumType.Physical) {
      if (rule.isExclude) {
        redeemPeriodsData.physicalExcludeDateRules.push(oneRule);
      } else {
        redeemPeriodsData.physicalDateRules.push(oneRule);
      }
    } else {
      if (rule.isExclude) {
        redeemPeriodsData.excludeDateRules.push(oneRule);
      } else {
        redeemPeriodsData.dateRules.push(oneRule);
      }
    }
  });

  return redeemPeriodsData;
};

export function parseCouponSet(data) {
  if (!data) {
    return;
  }

  const translationEdges = data.translations?.edges;
  const campaignList = parseCampaign(data.campaigns, data.name);
  data.merchantTermsAndConditionsTemplateDate =
    getTermsAndConditionsTemplateDate(data?.merchantTermsAndConditionsTemplate);
  data.genericTermsAndConditionsTemplateDate =
    getTermsAndConditionsTemplateDate(data?.genericTermsAndConditionsTemplate);
  const enTranslation = getCouponTemplateTranslation(data, data);
  let reducedTranslation = null;
  if (translationEdges) {
    const chineseTranslation = translationEdges.map((item) => {
      return getCouponTemplateTranslation(item.node, data);
    });
    reducedTranslation = chineseTranslation.reduce(function (obj, item) {
      return {
        ...obj,
        ...item,
      };
    }, {});
  }

  const selectedBrand = data.brand;
  let selectedStores = data.applicableStores?.edges;
  if (selectedStores && selectedStores.length > 0) {
    selectedStores = selectedStores.map((store) => parseStore(store));
  }

  return {
    ...data,
    id: data.id,
    pk: data.pk,
    title: `[${data.pk}] ${data.name}`,
    name: data.name,
    campaignList,
    isLinkedCustomer: data.isLinkedCustomer,
    tourCardSectionTitle: data.tourCardSectionTitle,
    shortDescription: data.shortDescription,
    lastModifiedDate: data.lastModifiedDate,
    applyCoverPhotoToAllLanguage: data.applyCoverPhotoToAllLanguage,
    applyDetailPhotosToAllLanguage: data.applyDetailPhotosToAllLanguage,
    displayLastModifiedDate: parseCouponSetListDate(data.lastModifiedDate),
    creationDate: data.creationDate,
    displayCreationDate: parseCouponSetListDate(data.creationDate),
    // linkedCampaignsName: data.linkedCampaignsName,
    // linkedCampaignsName: data.linkedcampaignsname?.edges
    //   ?.map((node) => node?.node?.name)
    //   .join(', '),
    linkedCampaignsName: data.linkedCampaignsName
      ?.map((node) => node?.name)
      .join(', '),
    absoluteEffectiveDate: data.absoluteEffectiveDate,
    absoluteExpiryDate: data.absoluteExpiryDate,
    status: data.status,
    isLinkedFortuneBag: data.isLinkedFortuneBag,
    isLinkedGoodieBag: data.isLinkedGoodieBag,
    stock: data.stock,
    totalNubmerOfGeneratedCoupons: data.totalNubmerOfGeneratedCoupons,
    validPeriodType: data.validPeriodType,
    numberOfDaysToExpireAfterAcquisition:
      data.numberOfDaysToExpireAfterAcquisition,
    displayIn: data.displayIn,
    requireTransactionAmountSurvey: data.requireTransactionAmountSurvey,
    transactionAmountSurvey: data.transactionAmountSurvey,
    translations: {
      ...enTranslation,
      ...reducedTranslation,
    },
    selectedBrand: selectedBrand ? parseBrand(selectedBrand) : null,
    selectedStores: selectedStores,
    shouldBeUsedInStores: data.shouldUseInStore,
    isForcedInactive: data.isForcedInactive,
    expiryDate: parseExpiredDate(data),

    redemptionMethod: data.redemptionMethod,
    redemptionMethodDisplay:
      COUPON_REDEMPTION_METHOD_CONFIG.filter(
        (item) => item.value === data.redemptionMethod,
      )?.[0]?.label || '',
    requireHkidOrPassportNumber: data.requireHkidOrPassportNumber,
    isHighValueCoupon: data.requireHkidOrPassportNumber
      ? IS_HIGH_VALUE_COUPON.YES
      : IS_HIGH_VALUE_COUPON.NO,
    formats: data.formats,
    barcodeFormat: data?.barcodeFormat,
    settlementValueForHktb: data.settlementValueForHktb,
    settlementValueForMerchant: data.settlementValueForMerchant,
    couponSetType: data.couponTemplateType,
    couponSetTypeDisplay:
      COUPON_TYPES_CONFIG.filter(
        (item) => item.id === data.couponTemplateType,
      )?.[0]?.title || '',
    showTourCard: data.showTourCard,
    tourCards: data.tourCards?.edges?.map((tourCardEdge) => {
      const { tourCard, displayPriority } = tourCardEdge.node;
      return {
        displayPriority,
        ...tourCard,
      };
    }),

    couponCodeType: data.couponCodeType,
    genericCode: data.genericCode,
    merchantTermsAndConditionsTemplate: getTermsAndConditionsTemplateDate(
      data.merchantTermsAndConditionsTemplate,
    ),
    isFollowMerchantTermsAndConditionsTemplate:
      data.isFollowMerchantTermsAndConditionsTemplate,
    genericTermsAndConditionsTemplate: getTermsAndConditionsTemplateDate(
      data.genericTermsAndConditionsTemplate,
    ),
    isFollowGenericTermsAndConditionsTemplate:
      data.isFollowGenericTermsAndConditionsTemplate,
    leftOverStock: data.handledLeftover
      ? data.totalNubmerOfLeftoverdCoupons
      : data.totalNubmerOfCanbeLeftoverdCoupons,

    showLandingPage: data.haveLandingPage,

    showLowStockLabel: data.showLowStockLabel,
    lowStockMethod: data.measureLowStockWay,
    lowStockValue: data.lowStockStandardValue,
    workingTeams: data.workingTeams?.edges?.map((item) => item.node),
    workingTeamIds: data.workingTeams?.edges?.map((item) => item.node.pk),
    displayCouponMedium:
      DisplayCouponMediumType?.[data?.couponMedium] ||
      DisplayCouponMediumType.EWALLET,

    hasSpecialDays: data.periodRestriction,
    ...parseWeeklyRedeemPeriods(data),
    relativeSetDeadline: data.relativeValidSetDeadline,
    relativeDeadline: data.absoluteExpiryDate,
  };
}

export const parseExpiredDate = (couponTemplate) => {
  if (couponTemplate?.couponMedium === CouponMediumType.Physical) {
    return '';
  }
  if (couponTemplate.validPeriodType === 'ABSOLUTE') {
    const startDate = parseCouponSetListDate(
      couponTemplate.absoluteEffectiveDate,
    );
    const endDate = parseCouponSetListDate(couponTemplate.absoluteExpiryDate);
    return `${startDate} - ${endDate}`;
  } else if (couponTemplate.validPeriodType === 'RELATIVE') {
    return `${couponTemplate.numberOfDaysToExpireAfterAcquisition} days since acquiring`;
  } else {
    return `All time valid`;
  }
};

export const parsePhysicalExpiredDate = (couponTemplate) => {
  if (couponTemplate?.couponMedium === CouponMediumType.EWallet) {
    return '';
  }
  if (couponTemplate.physicalValidPeriodType === 'ABSOLUTE') {
    const startDate = parseCouponSetListDate(
      couponTemplate.physicalAbsoluteEffectiveDate,
    );
    const endDate = parseCouponSetListDate(
      couponTemplate.physicalAbsoluteExpiryDate,
    );
    return `${startDate} - ${endDate}`;
  } else {
    return `All time valid`;
  }
};

export function assembleCouponTemplates(couponTemplatesData) {
  return couponTemplatesData.map((data) => {
    return parseCouponSet(data.node);
  });
}

export const parseCouponExpiryDate = (couponSet, coupon) => {
  const effectiveDate = coupon.effectiveDate;
  const expiryDate = coupon.expiryDate;
  if (effectiveDate && expiryDate) {
    return `${parseCouponSetListDate(effectiveDate)} - ${parseCouponSetListDate(
      expiryDate,
    )}`;
  }
  if (couponSet?.validPeriodType === 'ALL_TIME') {
    return 'All time valid';
  }
  if (couponSet?.validPeriodType === 'ABSOLUTE') {
    return `${effectiveDate ? parseCouponSetListDate(effectiveDate) : ''} - ${
      expiryDate ? parseCouponSetListDate(expiryDate) : ''
    }`;
  }
  if (couponSet?.validPeriodType === 'RELATIVE') {
    return `${couponSet.numberOfDaysToExpireAfterAcquisition} days since acquiring`;
  }
  return '-';
};

// export const parseCouponExpiryDate = (couponSet, coupon) => {
//   const effectiveDate = coupon.effectiveDate;
//   const expiryDate = coupon.expiryDate;
//   if (effectiveDate && expiryDate) {
//     return `${parseCouponSetListDate(effectiveDate)} - ${parseCouponSetListDate(
//       expiryDate,
//     )}`;
//   }
//   if (couponSet?.validPeriodType === 'ALL_TIME') {
//     return 'All time valid';
//   }
//   if (couponSet?.validPeriodType === 'ABSOLUTE') {
//     return `${effectiveDate ? parseCouponSetListDate(effectiveDate) : ''} - ${
//       expiryDate ? parseCouponSetListDate(expiryDate) : ''
//     }`;
//   }
//   if (couponSet?.validPeriodType === 'RELATIVE') {
//     return `${couponSet.numberOfDaysToExpireAfterAcquisition} days since acquiring`;
//   }
//   return '-';
// };


export const parseSingleCoupon = (couponSet, singleCouponData) => {
  const isPhysical =
    singleCouponData.node?.batch?.couponMedium === CouponMediumType.Physical;
  return {
    ...singleCouponData.node,
    id: singleCouponData.node.id,
    pk: singleCouponData.node.pk,
    name: singleCouponData.node.serialNumber,
    serialNumber: singleCouponData.node.serialNumber,
    code: singleCouponData.node.code,
    membershipId:
      isPhysical && singleCouponData.node.isUsed
        ? 'Guest user'
        : singleCouponData.node.owner?.membershipId,
    expiryDate: singleCouponData.node.expiryDate,
    displayExpiryDate: parseCouponExpiryDate(couponSet, singleCouponData.node),
    creationDate: singleCouponData.node.creationDate,
    displayCreationDate: parseCouponSetListDate(
      singleCouponData.node.creationDate,
    ),
    lastModifiedDate: singleCouponData.node.lastModifiedDate,
    displayLastModifiedDate: parseCouponSetListDate(
      singleCouponData.node.lastModifiedDate,
    ),
    dateOfPurchase: singleCouponData.node.dateOfPurchase,
    dateOfEarning: singleCouponData.node.dateOfEarning,
    dateOfGrant: singleCouponData.node.dateOfGrant,
    isUsed: singleCouponData.node.isUsed,
    isExpired: singleCouponData.node.isExpired,
    isActive: singleCouponData.node.isActive,
    isForcedInactive: singleCouponData.node.isForcedInactive,
    effectiveDate: singleCouponData.node.effectiveDate,
    // store: singleCouponData.node.store,
    usedPlatform: getDisplayUsedPlatForm(singleCouponData.node?.usedPlatform),
    couponMedium: singleCouponData.node?.batch?.couponMedium,
    displayCouponMedium: isPhysical
      ? DisplayCouponMediumType.PHYSICAL
      : DisplayCouponMediumType.EWALLET,
    // qrCodeString: btoa(
    //   singleCouponData.node?.serialNumber +
    //     (!isPhysical && singleCouponData.node?.owner
    //       ? singleCouponData.node?.owner?.membershipId.slice(-4)
    //       : ''),
    // ),
    qrCodeString: singleCouponData.node.serialNumberEncrypted,
  };
};

export const parseCouponSetListDate = (dateString) => {
  if (!dateString || dateString === '') {
    return '';
  }
  const options = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  };
  const date = new Date(dateString);
  const format = new Intl.DateTimeFormat('en', options);
  const [{ value: weekday }, , { value: month }, , { value: day }, ,] =
    format.formatToParts(date);
  const year = date.getFullYear();
  const currentYear = new Date().getFullYear();
  if (currentYear !== year) {
    return `${day} ${month} ${year}`;
  }
  return `${day} ${month} (${weekday})`;
};

export const parseCouponSetOverviewDatetime = (datetimeString) => {
  const datetime = new Date(datetimeString);
  const weekday = new Intl.DateTimeFormat('en', { weekday: 'short' }).format(
    datetime,
  );
  let localeString = datetime.toLocaleString('en');
  localeString = localeString.replace(',', `(${weekday})`);
  return localeString;
};

export const getUseMethod = (coupon) => {
  if (!coupon || !coupon?.isUsed) {
    return '';
  }
  const useMethod = coupon?.useMethod;
  const redemptionMethod = coupon?.template?.redemptionMethod;
  return (
    DisplayUseMethod?.[useMethod] || DisplayUseMethod?.[redemptionMethod] || ''
  );
};

export const parseBarcodeFormat = (value) => {
  if (!value) {
    return '-';
  }
  switch (value) {
    case 'Code 128':
      return 'CODE128';
    case 'Code 39':
      return 'CODE39';
    case 'UPC-A':
      return 'upc';
    case 'EAN-13':
      return 'EAN13';
    case 'EAN-8':
      return 'EAN8';
    default:
      return value;
  }
};

export const checkPartnerCodeFormat = (value) => {
  return (
    value === DISCOUNT_CODE_FORMAT.QRCODE ||
    value === DISCOUNT_CODE_FORMAT.BARCODE
  );
};
