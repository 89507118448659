import React, { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import queryString from 'query-string';
import { useLocation, useParams } from 'react-router-dom';
import BaseFilter, { FilterTypeEnum } from '../base/BaseFilter';
import { ArrayStringData } from '../../utils/index';

function EntitlementFilter({ brandList, backAction = () => {} }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;

  const parsedSearch = queryString.parse(location.search);
  const searchBrands = parsedSearch['ota'] || '';

  const [brands, setBrands] = useState(ArrayStringData(searchBrands));
  const [brandListData, setBrandListData] = useState();

  useEffect(() => {
    dispatch({
      type: 'brand/getAllList',
      payload: {
        params: { goodieBagIn: `${id}` },
      },
    });
    return () => {
      dispatch({ type: 'brand/clearData' });
    };
  }, []);

  const content = [
    {
      displayTitle: 'OTA',
      title: 'ota',
      data: !!brandListData ? brandListData : brandList,
      value: brands,
      setValue: setBrands,
      componment: FilterTypeEnum.searchableChoice,
      placeholder: 'Search by name',
      renderDisplayName: (item) => `${item.name}`,
      filterAction: (searchKey) => {
        const newData = brandList?.filter((item) => {
          const name = item.name?.toLowerCase() || '';
          return (
            name.includes(searchKey?.toLowerCase() || '') ||
            String(item.pk) === searchKey
          );
        });
        setBrandListData(newData);
      },
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

const mapPropsToState = (state) => ({
  brandList: state.brand.notPagedAllList,
});
export default connect(mapPropsToState)(EntitlementFilter);
