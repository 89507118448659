import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createAction } from '../../../utils';
import './SingleCouponList.scss';
import '../../base/CustomListComponent.scss';
import { withRouter } from 'react-router-dom';
import DeactiveSingleCouponView from './DeactiveSingleCouponView';
import { PermissionCodes } from '../../../config/PermissionCodes';
import BaseTabListContainer from '../../../containers/base/BaseTabListContainer';
import BaseListContainer from '../../../containers/base/BaseListContainer';
import DeletePrompt from '../../base/DeletePrompt';
import { COUPON_STATUS } from '../../../models/CouponListModel';
import {
  COUPON_TEMPLATE_TYPE,
  DELETE_RELATED_SECTIONS,
} from '../../../config/CustomEnums';
import { DISCOUNT_CODE_FORMAT } from '../../../models/CreateCouponModel';
import SingleCouponFilter from './SingleCouponFilter';
const queryString = require('query-string');

@connect(({ couponList, singleCoupon }) => ({
  getSingleCouponListStatus: couponList.getSingleCouponListStatus,
  pageInfo: singleCoupon.pageInfo,
  totalCount: singleCoupon.totalCount,
  totalPage: singleCoupon.totalPage,
  displayFields: singleCoupon.listDisplayFields,
  imageDisplayFields: singleCoupon.listImageDisplayFields,
  singleCouponList: singleCoupon.currentPageSingleCouponList,
  checkedList: singleCoupon.checkedList,
}))
class SingleCouponList extends Component {
  constructor(props) {
    super(props);
    this.couponSetPK = props.match.params.id;
    const urlParams = this.getUrlParams();
    this.state = {
      selectedSingleCoupon: null,
      showDeleteView: false,
      showDeactiveView: false,
      clickedOneCoupon: false,
    };
    props.dispatch(
      createAction('couponList/getCouponSet')({
        couponSetPK: this.couponSetPK,
        notSaveToSession: true,
      }),
    );
    props.dispatch(
      createAction('singleCoupon/getCurrentPageSingleCouponList')({
        templatePK: this.couponSetPK,
        rank: urlParams.rank,
        currentPage: urlParams.currentPage,
        filterKey: urlParams.searchKey,
        search: {
          couponMedium: urlParams.couponMedium,
          singleCouponStatus: urlParams.singleCouponStatus,
        },
      }),
    );
  }

  getUrlParams = () => {
    const searchDict = queryString.parse(this.props.location.search);
    const searchKey = searchDict['search'];
    const stringRank = searchDict['rank'] || 'true';
    const stringPage = searchDict['page'] || 1;
    const rank = stringRank === 'true';
    const currentPage = parseInt(stringPage);
    const couponMedium = searchDict['coupon_medium'] || '';
    const singleCouponStatus = searchDict['single_coupon_status'] || '';
    return {
      rank,
      searchKey,
      currentPage,
      couponMedium,
      singleCouponStatus,
    };
  };

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      const urlParams = this.getUrlParams();
      this.setState({
        ...urlParams,
      });
      this.couponSetPK = this.props.match.params.id;
      this.props.dispatch(
        createAction('singleCoupon/getCurrentPageSingleCouponList')({
          templatePK: this.props.match.params.id,
          rank: urlParams.rank,
          currentPage: urlParams.currentPage,
          filterKey: urlParams.searchKey,
          search: {
            couponMedium: urlParams.couponMedium,
            singleCouponStatus: urlParams.singleCouponStatus,
          },
        }),
      );
    }
  }

  multipleActionList = [
    {
      name: 'Delete',
      action: () => {
        this.setState({
          showDeleteView: true,
          clickedOneCoupon: false,
        });
      },
      requires: PermissionCodes.deleteCoupon,
    },
  ];

  render() {
    return (
      <>
        <BaseTabListContainer
          hideTab={true}
          groupActions={this.multipleActionList}
          pageInfo={this.props.pageInfo}
          totalCount={this.props.totalCount ? this.props.totalCount : 0}
          filter={{ hasFilter: true, componment: SingleCouponFilter }}
          searchPlaceholder={'Search by ID'}
          listContent={
            <BaseListContainer
              fields={
                this.props.couponSet.formats ===
                COUPON_TEMPLATE_TYPE.couponCodeImage
                  ? this.props.imageDisplayFields
                  : [
                      { displayName: 'ID', fieldName: 'pk' },
                      {
                        displayName: 'Serial Number',
                        fieldName: 'name',
                        linked: true,
                      },
                      {
                        displayName: 'Reference ID',
                        fieldName: 'referenceId',
                      },
                      {
                        displayName: 'Coupon QR code',
                        fieldName: 'qrCodeString',
                        referenceFieldName: 'srk',
                      },
                      {
                        displayName: 'coupon medium',
                        fieldName: 'displayCouponMedium',
                      },
                      {
                        displayName:
                          this.props.couponSet.discountCodeFormat ===
                          DISCOUNT_CODE_FORMAT.URL
                            ? 'Import url'
                            : 'Import code',
                        fieldName: 'code',
                      },
                      {
                        displayName: 'Membership ID',
                        fieldName: 'membershipId',
                      },
                      {
                        displayName: 'Expriy Date',
                        fieldName: 'displayExpiryDate',
                      },
                      {
                        displayName: 'Create at',
                        fieldName: 'displayCreationDate',
                      },
                      {
                        displayName: 'Last Modified',
                        fieldName: 'displayLastModifiedDate',
                      },
                      {
                        displayName: 'Used platform',
                        fieldName: 'usedPlatform',
                      },
                      { displayName: 'Status', fieldName: 'status' },
                    ]
              }
              model={'singleCoupon'}
              permissionGroup={PermissionCodes.coupon}
              dataList={this.props.singleCouponList}
              totalPage={this.props.totalPage}
              deleteInfo={{
                data: [],
                title: 'Single Coupon',
                relatedName: '',
                onComfirm: {},
                relatedSections: DELETE_RELATED_SECTIONS.COUPON,
              }}
              // specificActions={[
              //   {
              //     condition: (item) => {
              //       return item.type === CampaignType.fortuneBagCampaign;
              //     },
              //     buttons: [
              //       {
              //         name: 'View entitlement',
              //         action: (item) => {
              //           history.push(`/campaigns/${item.pk}/edit/entitlement/`);
              //         },
              //         requires: PermissionCodes.changeEntitlement,
              //       },
              //     ],
              //   },
              // ]}
              actions={['Edit', 'Delete']}
              hasOtherActions={true}
              customActions={{
                deactive: (item) => {
                  if (
                    item.status === COUPON_STATUS.ACTIVE ||
                    item.status === COUPON_STATUS.ACTIVE_ACQUIRED
                  ) {
                    this.setState({
                      showDeactiveView: true,
                      selectedSingleCoupon: item,
                    });
                  } else if (item.status === COUPON_STATUS.INACTIVE) {
                    this.props.dispatch(
                      createAction('singleCoupon/activeSingleCoupon')({
                        couponPK: item.pk,
                        activeAction: () =>
                          this.props.history.push(this.props.location),
                      }),
                    );
                  }
                },
                requires: PermissionCodes.changeCoupon,
              }}
            />
          }
        />
        <DeletePrompt
          data={this.props.checkedList}
          show={this.state.showDeleteView}
          title={'Coupon'}
          relatedName=""
          relatedSections={DELETE_RELATED_SECTIONS.COUPON}
          onClose={() => this.setState({ showDeleteView: false })}
          onConfirm={() => {
            this.props.dispatch(
              createAction('singleCoupon/delete')({
                afterAction: () => this.props.history.push(this.props.location),
              }),
            );
            this.setState({ showDeleteView: false });
          }}
        />
        <DeactiveSingleCouponView
          showDeactiveView={this.state.showDeactiveView}
          onHide={() => this.setState({ showDeactiveView: false })}
          singleCoupon={this.state.selectedSingleCoupon}
          couponSet={this.props.couponSet}
          activeBtnClicked={() => {
            this.props.dispatch(
              createAction('singleCoupon/deactiveSingleCoupon')({
                couponPK: this.state.selectedSingleCoupon.pk,
                afterAction: () => {
                  this.props.history.push(this.props.location);
                },
              }),
            );
          }}
        />
      </>
    );
  }
}

export default withRouter(SingleCouponList);
