import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './SimpleDashboard.scss';
import '../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../components/base/CustomBreadcrumb';
import { NavBarNames } from '../../config/NavBarNameList';
import CustomTips from '../../components/base/CustomTips';
import DateRangeCompare from '../../components/dashboard/DateRangeCompare';
import { formatDate, TimeFormater } from '../.././utils/TimeFormatUtil';
import DataTable from '../../components/dashboard/DataTable';
import moment from 'moment';
import { DatePicker } from 'antd';
import { CustomDropDownButton } from '../../components/base/CustomDropDownButton';
import { Button } from 'react-bootstrap';
import CouponDownloadList from '../../components/dashboard/CouponDownloadList';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { ImportResource } from '../../models/DownloadImportModel';
import { getStartEndDate } from '../../components/dashboard/DateRangeSelection';
import { starredCampaignValue } from '../../components/dashboard/SimpleDashboardFilter';
import { CampaignType } from '../../config/CustomEnums';

const { RangePicker } = DatePicker;

const TIME_FORMAT = 'HH:mm';

const SimpleDasnboard = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const startEndDate = useMemo(
    () => getStartEndDate(null, null, null, true),
    [location],
  );
  const [tempStartDate, setTempStartDate] = useState(startEndDate.startDate);
  const [tempEndDate, setTempEndDate] = useState(startEndDate.endDate);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [isActiveStarCampaign, setIsActiveStarCampaign] = useState(true);

  const [couponDataList, setCouponDataList] = useState([]);

  const {
    customerData,
    loadingCustomer,
    couponData,
    loadingCoupon,
    starredCampaignList,
    couponTotalData,
  } = useSelector((state) => ({
    customerData: state.simpleDashboardModel.customerData,
    loadingCustomer: state.simpleDashboardModel.loadingCustomer,
    couponData: state.simpleDashboardModel.couponData,
    loadingCoupon: state.simpleDashboardModel.loadingCoupon,
    starredCampaignList: state.campaignList.notPagedAllList,
    couponTotalData: state.simpleDashboardModel.couponTotalData,
  }));

  const couponRefreshAction = () => {
    console.log('coupon refresh');

    const activePeriod =
      (tempStartDate?.format(TimeFormater.yearMonthDay) || '') +
      ',' +
      (tempEndDate?.format(TimeFormater.yearMonthDay) || '');
    const querySearch = queryString.parse(location.search);
    const hasSearch = Object.values(querySearch)?.length > 0;
    const searchCouponMedium = querySearch['coupon_medium'];
    const searchBrand = querySearch['brand'];
    const searchCategory = querySearch['campaign_category'];
    const searchStarredActive = querySearch['starred_campaign'];
    const searchCampaignStatus = querySearch['campaign_status'];
    const searchVisiblePeriod = querySearch['campaign_visible_period'];
    const searchIsFeatured = querySearch['featured_campaign'];
    const searchGoodieBagIn = querySearch['goodie_bag_id'];
    const searchAttributeTag = querySearch['tag'];
    const searchCampaignType = querySearch['campaign_type'];
    const searchGoodiebagRewardType = querySearch['goodie_bag_reward_type'];
    const parsedFilter = {
      coupon_medium_in: searchCouponMedium,
      brand: searchBrand,
      campaign_category: searchCategory,
      visible_period: searchVisiblePeriod,
      status: searchCampaignStatus,
      is_featured: searchIsFeatured,
      for_goodie_bag: searchGoodieBagIn,
      tags: searchAttributeTag,
      typeIn: searchCampaignType,
      goodiebagRewardType: searchGoodiebagRewardType,
    };

    switch (searchStarredActive) {
      case starredCampaignValue.starredAndActive:
        parsedFilter.starred_campaign = 'true';
        parsedFilter.activeCampaignIn = `${formatDate(
          tempStartDate,
          TimeFormater.GTMFormat,
        )},${formatDate(tempEndDate, TimeFormater.GTMFormat)},true`;
        break;
      case starredCampaignValue.starredAndInactive:
        parsedFilter.starred_campaign = 'true';
        parsedFilter.activeCampaignIn = `${formatDate(
          tempStartDate,
          TimeFormater.GTMFormat,
        )},${formatDate(tempEndDate, TimeFormater.GTMFormat)},false`;
        break;
      case starredCampaignValue.nonStarredAndActive:
        parsedFilter.starred_campaign = 'false';
        parsedFilter.activeCampaignIn = `${formatDate(
          tempStartDate,
          TimeFormater.GTMFormat,
        )},${formatDate(tempEndDate, TimeFormater.GTMFormat)},true`;
        break;
      default:
        parsedFilter.activeCampaignIn = `${formatDate(
          tempStartDate,
          TimeFormater.GTMFormat,
        )},${formatDate(tempEndDate, TimeFormater.GTMFormat)},true`;
        break;
    }

    dispatch({
      type: 'campaignList/updateState',
      payload: {
        pagedList: [],
      },
    });
    hasSearch &&
      dispatch({
        type: 'campaignList/getAllList',
        payload: {
          others: {
            isAll: true,
            getAll: 'false',
            ...parsedFilter,
            // typeIn: [
            //   CampaignType.couponsCampaign,
            //   CampaignType.cashVoucherCampaign,
            //   CampaignType.giftCampaign,
            // ],
          },
          filterAllNeedFields: `goodieBag {
            id
            pk
            rewardType
          }`,
          pageSize: 1000,
          afterAction: () => {
            dispatch({
              type: 'simpleDashboardModel/updateState',
              payload: {
                couponTotalData: [],
                couponData: [],
              },
            });
            dispatch({
              type: 'simpleDashboardModel/getCouponData',
              payload: {
                startDate: formatDate(tempStartDate, TimeFormater.GTMFormat),
                endDate: formatDate(tempEndDate, TimeFormater.GTMFormat),
              },
            });
          },
        },
      });
  };

  const getFilterConditionsFromUrl = () => {
    const activePeriod =
      (tempStartDate?.format(TimeFormater.yearMonthDay) || '') +
      ',' +
      (tempEndDate?.format(TimeFormater.yearMonthDay) || '');
    const querySearch = queryString.parse(location.search);
    const searchCouponMedium = querySearch['coupon_medium'];
    const searchBrand = querySearch['brand'];
    const searchCategory = querySearch['campaign_category'];
    const searchStarredActive = querySearch['starred_campaign'];
    const searchCampaignStatus = querySearch['campaign_status'];
    const searchVisiblePeriod = querySearch['campaign_visible_period'];
    const searchIsFeatured = querySearch['featured_campaign'];
    const searchGoodieBagIn = querySearch['goodie_bag_id'];
    const searchAttributeTag = querySearch['tag'];
    const searchGoodiebagRewardType = querySearch['goodie_bag_reward_type'];
    const searchCampaignType = querySearch['campaign_type'];
    const filterConditions = {
      coupon_medium_in: searchCouponMedium,
      is_featured: searchIsFeatured,
      goodie_bag_in: searchGoodieBagIn,
      brand_in: searchBrand,
      categories_in: searchCategory,
      attribute_tags_in: searchAttributeTag,
      is_published: searchCampaignStatus,
      display_time_range: searchVisiblePeriod,
      reward_type_in: searchGoodiebagRewardType,
      type_in: searchCampaignType,
    };

    switch (searchStarredActive) {
      case starredCampaignValue.starredAndActive:
        filterConditions.is_starred = true;
        filterConditions.active_campaign_in = `${formatDate(
          tempStartDate,
          TimeFormater.GTMFormat,
        )},${formatDate(tempEndDate, TimeFormater.GTMFormat)},true`;
        break;
      case starredCampaignValue.starredAndInactive:
        filterConditions.is_starred = true;
        filterConditions.active_campaign_in = `${formatDate(
          tempStartDate,
          TimeFormater.GTMFormat,
        )},${formatDate(tempEndDate, TimeFormater.GTMFormat)},false`;
        break;
      case starredCampaignValue.nonStarredAndActive:
        filterConditions.is_starred = false;
        filterConditions.active_campaign_in = `${formatDate(
          tempStartDate,
          TimeFormater.GTMFormat,
        )},${formatDate(tempEndDate, TimeFormater.GTMFormat)},true`;
        break;
      default:
        filterConditions.active_campaign_in = `${formatDate(
          tempStartDate,
          TimeFormater.GTMFormat,
        )},${formatDate(tempEndDate, TimeFormater.GTMFormat)},true`;
        break;
    }

    return filterConditions;
  };

  useEffect(() => {
    return () => {
      dispatch({
        type: 'campaignList/clearData',
      });

      dispatch({
        type: 'simpleDashboardModel/getCouponData',
        payload: {
          startDate: null,
          endDate: null,
        },
      });

      dispatch({
        type: 'simpleDashboardModel/clearData',
      });
    };
  }, []);

  useEffect(() => {
    couponRefreshAction();
  }, [location]);

  useEffect(() => {
    setCouponDataList(couponData);
  }, [couponData]);

  const getCautionDetail = (absoluteValues, latestValues) => {
    return (
      <>
        Types of data
        <br />
        Absolute value (accumulated value) within the selected time period:
        <ul>
          {absoluteValues.map((item, index) => (
            <li key={index}>
              <span>{item} </span>
            </li>
          ))}
        </ul>
        Latest value (snapshot) at the “end time” of the selected time period:
        <ul>
          {latestValues.map((item, index) => (
            <li key={index}>
              <span>{item} </span>
            </li>
          ))}
        </ul>
      </>
    );
  };
  return (
    <>
      <div className="list-section-container-header list-section-container-header-bottom dashboard-header">
        <CustomBreadcrumb />
        <div className="list-section-container-button-and-title">
          <div className="list-section-container-header-left dashboard-header-left">
            <div className="dashboard-title">
              <CustomTips
                detail={
                  'The dashboard shows an overview of the customers and coupons.'
                }
              >
                <label className="list-section-container-header-title">
                  {NavBarNames.dashboard}
                </label>
              </CustomTips>
            </div>
            {/* <DateRangeCompare /> */}
          </div>
          {/* <DateRangeSelection
            applyDateRangeEvent={(start, end) => {
              // setFirstTimePickerChange(false);
              setDatePicker({ start: start, end: end });
              // if (firstTimePickerChange) {
              //   return;
              // }
              // requestData(start, end);
            }}
            clearDataEvent={() => {
              dispatch(createAction('dashboard/clearState')());
            }}
          /> */}
          <div className="time-period">
            {/* <label>
              <div class="box"></div>
              <span>time period cannot over 24 hours</span>
            </label> */}
            <RangePicker
              showTime={{ format: TIME_FORMAT }}
              value={[tempStartDate, tempEndDate]}
              className="date-picker-extra"
              format={TimeFormater.dayMonthYearTimePeriod}
              disabledDate={(current) => current > moment().endOf('day')}
              separator={'-'}
              onChange={(values) => {
                if (!values) {
                  return;
                }
                console.log(values, values[1] - values[0]);
                // if ((values[1] - values[0]) / 1000 / 60 / 60 > 24) {
                //   //   alert('time period cannot over 24 hours');
                //   return;
                // }
                // console.log(values[0], values[1]);
                setTempStartDate(values[0]);
                setTempEndDate(values[1]);
              }}
              onOpenChange={setDatePickerOpen}
              // minuteStep={15}
            />
          </div>
        </div>
      </div>
      <div className="table-content">
        <div>
          <DataTable
            name={'Customer user'}
            data={customerData}
            titles={['No. of New Register user', 'Total registered user']}
            refreshAction={() => {
              console.log('customer refresh');

              dispatch({
                type: 'simpleDashboardModel/updateState',
                payload: {
                  customerData: [],
                },
              });

              dispatch({
                type: 'simpleDashboardModel/getCustomerData',
                payload: {
                  startDate: formatDate(tempStartDate, TimeFormater.GTMFormat),
                  endDate: formatDate(tempEndDate, TimeFormater.GTMFormat),
                },
              });
            }}
            isLoading={loadingCustomer}
            refreshButtonDisabled={loadingCustomer}
            customButton={
              <Button
                className={'btn-back-button-common btn-download'}
                onClick={() => {
                  dispatch({
                    type: 'downloadAndImport/createDownloadTask',
                    payload: {
                      from: ImportResource.simpleCustomer,
                      related: {
                        fromDate: formatDate(
                          tempStartDate,
                          TimeFormater.GTMFormat,
                        ),
                        toDate: formatDate(tempEndDate, TimeFormater.GTMFormat),
                      },
                    },
                  });
                }}
              >
                Export
              </Button>
            }
            cautionDetail={getCautionDetail(
              ['No. of New Register user'],
              ['Total registered user'],
            )}
          />
        </div>
        <div>
          <CouponDownloadList
            tips={
              'Showing campaign that has active period within the selected period.'
            }
            refreshAction={couponRefreshAction}
            refreshButtonDisabled={loadingCoupon}
            loadingCoupon={loadingCoupon}
            // tips={
            //   starredCampaignList?.length <= 0
            //     ? 'No starred campaigns'
            //     : `${
            //         isActiveStarCampaign ? 'Active' : 'Inactive'
            //       } starred campaign count: ${starredCampaignList?.length}`
            // }
            cautionDetail={getCautionDetail(
              [
                'e-Coupon download qty',
                'e-Coupon download unique user',
                'Coupon redeem qty',
                'e-Coupon redeem unique user',
                'Coupon redeem %',
              ],
              [
                'Total qty',
                'Total e-coupon qty',
                'Total physical coupon qty',
                'e-Coupon remaining qty',
                'e-Coupon remaining %',
              ],
            )}
            dataList={couponDataList}
            exportAction={() => {
              dispatch({
                type: 'downloadAndImport/createDownloadTask',
                payload: {
                  from: ImportResource.simpleCampaign,
                  related: {
                    fromDate: formatDate(tempStartDate, TimeFormater.GTMFormat),
                    toDate: formatDate(tempEndDate, TimeFormater.GTMFormat),
                    filterConditions: JSON.stringify(
                      getFilterConditionsFromUrl(),
                    ),
                  },
                },
              });
            }}
            totalPage={Math.ceil(starredCampaignList?.length / 20)}
            sortAction={(orderKey, rank) => {
              let newCouponDataList = null;
              switch (orderKey) {
                case 'couponName':
                  newCouponDataList = couponDataList.sort((a, b) =>
                    rank
                      ? b[orderKey].localeCompare(a[orderKey])
                      : a[orderKey].localeCompare(b[orderKey]),
                  );
                  break;
                default:
                  newCouponDataList = couponDataList.sort((a, b) =>
                    rank
                      ? b[orderKey] - a[orderKey]
                      : a[orderKey] - b[orderKey],
                  );
                  break;
              }
              setCouponDataList(newCouponDataList);
            }}
            totalCount={starredCampaignList?.length || 0}
          />
        </div>
      </div>
    </>
  );
};

export default SimpleDasnboard;
