import { BaseHelper } from './BaseGraphQLAPIHelper';

const TOUR_CARD_NODE = `{
  id
  pk
  title
  description
  coverPhoto
  translations {
      edges {
          node {
              language
              pk
              title
              description
              coverPhoto
          }
      }
  }
}`;

export const getTourCardList = (afterCursor, params) => {
  let filter = '';
  // console.log('@33', afterCursor, params);
  const { sort, filterName } = params || {};
  if (filterName) {
    filter = `, nameIcontains:"${filterName}"`;
  }
  if (params.filter) {
    filter += `, nameOrIdIcontains:"${params.filter}"`;
  }

  let orderBy = '-pk';
  if (sort) {
    orderBy = sort;
  }
  const query = `{
    tourCards(first: 20, after:"${afterCursor}"${filter}, orderBy: "${orderBy}") {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node ${TOUR_CARD_NODE}
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getTourCardDetail = (id) => {
  const query = `{
    tourCard(id: "${id}")
      ${TOUR_CARD_NODE}
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const createTourCard = (payload) => {
  const query = `mutation CreateTourCard($input: CreateTourCardInput!) {
    createTourCard(input: $input) {
      node ${TOUR_CARD_NODE}
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: {
      ...payload,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateTourCard = (payload) => {
  const query = `mutation UpdateTourCard($input: UpdateTourCardInput!) {
    updateTourCard(input: $input) {
      node ${TOUR_CARD_NODE}
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: {
      ...payload,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteTourCards = (ids) => {
  const query = `mutation DeleteTourCards($input: DeleteTourCardsInput!) {
    deleteTourCards(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids,
    },
  };
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};
