import { decodedStripedHtml } from '../../utils';
import { LanguageConfig } from '../../config/CustomEnums';

const CreateGeneralAlertError = {
  content: {
    [LanguageConfig.english]: {
      name: 'content',
      message: 'Please provide a content. limit 1000',
    },
    [LanguageConfig.simplifiedChinese]: {
      name: 'contentHans',
      message: 'Please provide a content. limit 1000',
    },
    [LanguageConfig.traditionalChinese]: {
      name: 'contentHant',
      message: 'Please provide a content. limit 1000',
    },
    others: {
      name: 'content',
      message: 'Please provide a content. limit 1000',
    },
  },

  displayPriority: {
    name: 'displayPriority',
    message: 'Please provide a display priority.',
  },
  severityLevel: {
    name: 'everityLevel',
    message: 'Please provide a everity level.',
  },

  scheduledDate: {
    name: 'scheduledDate',
    message: 'start time need earlier than end time',
  },
};

function firstStepValidate({ getValues, setError, languages }) {
  const errorList = [];
  const languagesList = languages?.map((item) => item.code);
  const [displayPriority, severityLevel] = getValues([
    'displayPriority',
    'severityLevel',
  ]);
  (languagesList || []).forEach((item) => {
    const [content] = getValues([`translations.${item}.content`]);
    const contentLength = decodedStripedHtml(content)?.length;
    let error = false;
    if (item === LanguageConfig.english && (!content || contentLength > 1000)) {
      error = true;
    } else if (content && contentLength > 1000) {
      error = true;
    }
    if (error) {
      errorList.push({
        name: `translations.${item}.content`,
        message: (
          CreateGeneralAlertError.content?.[item] ||
          CreateGeneralAlertError.content.others
        )?.message,
      });
    }
  });

  if (!displayPriority || displayPriority < 1) {
    errorList.push({
      name: 'displayPriority',
      message: CreateGeneralAlertError.displayPriority.message,
    });
  }
  if (!severityLevel) {
    errorList.push({
      name: 'severityLevel',
      message: CreateGeneralAlertError.severityLevel.message,
    });
  }
  errorList.forEach((error) => {
    setError(error.name, {
      type: 'manual',
      message: error.message,
    });
  });
  console.log('errorListMessage:', errorList);
  return !errorList.length;
}

function secondStepValidate({ getValues, setError }) {
  const [startDate, endDate] = getValues(['startDate', 'endDate']);
  const errorList = [];
  if (startDate >= endDate) {
    errorList.push({
      name: 'endDate',
      message: CreateGeneralAlertError.scheduledDate.message,
    });
  }
  errorList.forEach((error) => {
    setError(error.name, {
      type: 'manual',
      message: error.message,
    });
  });
  return !errorList.length;
}

export function validate({
  getValues,
  setError,
  clearErrors,
  step,
  isBack,
  languages,
}) {
  if (isBack) {
    return true;
  }
  clearErrors();
  switch (step) {
    case 0:
      const validStepOne = firstStepValidate({
        getValues,
        setError,
        languages,
      });
      return validStepOne;
    case 1:
      const validStepTwo = secondStepValidate({ getValues, setError });
      return validStepTwo;
    default:
      const validOne = firstStepValidate({ getValues, setError });
      const validTwo = secondStepValidate({ getValues, setError });
      return validOne && validTwo;
  }
}
