import { BaseHelper } from './BaseGraphQLAPIHelper';
const UPLOAD_FILE_PATH = '/files';

function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);

  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

class UploadFileHelper {
  uploadImages = (imageList) => {
    let bodyFormData = new FormData();
    imageList.map((image, index) => {
      let imageURL = image.imageDataUrl;
      let imageType = image.imageType;
      let blob = dataURItoBlob(imageURL);
      let file = new File([blob], 'canvasImage.jpg', { type: imageType });
      return bodyFormData.append(`${index}_key-for-local-caching`, file);
    });
    return BaseHelper.callUpLoadFile(UPLOAD_FILE_PATH, bodyFormData).catch(
      (error) => error,
    );
  };

  uploadImage = (image) => {
    let blob = dataURItoBlob(image.imageDataUrl);
    let file = new File([blob], 'canvasImage.jpg', { type: image.imageType });
    let bodyFormData = new FormData();
    bodyFormData.append(image.imageName, file);
    return BaseHelper.callUpLoadFile(UPLOAD_FILE_PATH, bodyFormData).catch(
      (error) => error,
    );
  };

  uploadFile = (file) => {
    let bodyFormData = new FormData();
    bodyFormData.append('import', file);
    return BaseHelper.callUpLoadFile(UPLOAD_FILE_PATH, bodyFormData).catch(
      (error) => error,
    );
  };
}
export const uploadFileHelper = new UploadFileHelper();
