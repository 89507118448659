import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, connect } from 'react-redux';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import BaseForm from '../../../components/base/v2/BaseForm';
import { createAction } from '../../../utils';
import CreateAttributeTagContent from './CreateAttributeTagContent';
import { APIStatus } from '../../../config/CustomEnums';

const CreateAttributeTag = ({
  defaultValues,
  dispatch,
  hasUpdatedDefaultValues,
  formHasSubmitted,
  apiStatus,
  createStatus,
  createdNew,
}) => {
  const params = useParams();
  const history = useHistory();
  console.log('createdNew:', createdNew);
  const { id } = params;
  useEffect(() => {
    if (id) {
      dispatch(createAction('attributeTagKey/getOneDetail')({ id }));
    } else {
      dispatch(createAction('attributeTagKey/loadFromCookie')());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(createAction('attributeTagKey/clearData')());
    };
  }, []);

  useEffect(() => {
    if (formHasSubmitted && apiStatus === APIStatus.success) {
      history.push({ pathname: '/tags', search: '?sort=displayPriority' });
    }
  }, [formHasSubmitted, apiStatus]);

  return (
    <>
      <BaseForm
        defaultValues={defaultValues}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={formHasSubmitted}
        tempSave={(save, getValues) => {
          if (!save) {
            dispatch({
              type: 'attributeTagKey/removeFromCookie',
            });
          } else {
            dispatch({
              type: 'attributeTagKey/saveToCookie',
              payload: getValues(),
            });
          }
        }}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: 'Tag is successfully created.',
          steps: null,
          buttons: [
            {
              text: 'Back to tag list',
              action: () => {
                history.push({
                  pathname: '/tags',
                  search: '?sort=displayPriority',
                });
              },
            },
          ],
        }}
        continueCreateConfig={{
          onConfirm: () => {
            history.push({
              ...history.location.state?.from,
              state: {
                newAttribute: createdNew,
              },
            });
          },
          onCancel: () =>
            history.push({
              pathname: '/tags',
              search: '?sort=displayPriority',
            }),
          onClose: () =>
            history.push({
              pathname: '/tags',
              search: '?sort=displayPriority',
            }),
        }}
        showFinishPop={createStatus === APIStatus.success}
        content={[
          <CreateAttributeTagContent
            attributeTagKeyLength={
              Object.keys(defaultValues?.attributeTags || {})?.length
            }
          />,
        ]}
        currentStep={0}
        breadcrumb={
          <CustomBreadcrumb name={id ? defaultValues?.name : 'Create Tag'} />
        }
        caution={{
          detail: 'TBC',
          title: id ? defaultValues?.name : 'Create Tag',
        }}
      />
    </>
  );
};
const mapPropsToState = (state) => ({
  defaultValues: state.attributeTagKey.detail,
  hasUpdatedDefaultValues: state.attributeTagKey.hasUpdatedDefaultValues,
  formHasSubmitted: state.attributeTagKey.formHasSubmitted,
  apiStatus: state.attributeTagKey.apiStatus,
  createStatus: state.attributeTagKey.createStatus,
  createdNew: state.attributeTagKey.createdNew,
});
export default connect(mapPropsToState)(CreateAttributeTag);
