import React from 'react';
import './CustomStatusTag.scss';
import { checkCampaignIsExpired } from '../../utils/TimeFormatUtil';
import {
  StatusTag,
  DownloadTag,
  MessageTag,
  PublishTagType,
  DisplayPaymentSettlementReviewStatusType,
  YES_OR_NO_TAG,
  CouponTransactionBy,
} from '../../config/CustomEnums';
import {
  COUPON_STATUS,
  IS_HIGH_VALUE_COUPON,
} from '../../models/CouponListModel';
const TagType = {
  published: 'Published',
  unPublished: 'Unpublished',
  expired: 'Expired',
};
const getFormatedStatus = (campaign) => {
  if (!campaign.isPublished) {
    return {
      status: TagType.unPublished,
      className: 'custom-tag-label-un-publish',
    };
  }

  const isExpired = checkCampaignIsExpired(campaign.endDate);
  if (!isExpired) {
    return { status: TagType.published, className: 'custom-tag-label-publish' };
  }
  return { status: TagType.expired, className: 'custom-tag-label-expired' };
};

function CustomStatusTag(props) {
  const campaign = props.item;
  const tag = getFormatedStatus(campaign);
  return (
    <label className={`custom-tag-label ${tag.className}`}>{tag.status}</label>
  );
}

export const CustomStatusTagFromText = (status, extraStyle) => {
  let statusClass = '';
  switch (status) {
    case StatusTag.active:
    case DownloadTag.completed:
    case MessageTag.scheduled:
    case MessageTag.sent:
    case MessageTag.sending:
    case DownloadTag.importCompleted:
    case DownloadTag.generated:
    case COUPON_STATUS.ACTIVE_ACQUIRED:
    case COUPON_STATUS.ACQUIRED:
    case COUPON_STATUS.ACTIVE:
    case DownloadTag.updated:
    case PublishTagType.published:
    case DisplayPaymentSettlementReviewStatusType.agree:
    case IS_HIGH_VALUE_COUPON.YES:
    case YES_OR_NO_TAG.yes:
    case COUPON_STATUS.LEFTOVER_HANDLED:
    case CouponTransactionBy.SYSTEM:
      statusClass = 'custom-tag-label-publish';
      break;
    case COUPON_STATUS.LINKED_TO_FORTUNE_BAG:
    case COUPON_STATUS.LINKED_TO_GOODIE_BAG:
      statusClass = 'custom-tag-label-publish-full-height';
      break;
    case StatusTag.deactive:
    case StatusTag.acquired:
    case StatusTag.expired:
    case COUPON_STATUS.EWALLET_EXPIRED:
    case COUPON_STATUS.PHYSICAL_EXPIRED:
    case COUPON_STATUS.EXPIRED:
    case COUPON_STATUS.USED:
    case PublishTagType.expired:
    case DownloadTag.error:
    case MessageTag.error:
    case DisplayPaymentSettlementReviewStatusType.disagree:
    case IS_HIGH_VALUE_COUPON.NO:
    case YES_OR_NO_TAG.no:
    case COUPON_STATUS.VOID:
      statusClass = 'custom-tag-label-expired';
      break;
    case DownloadTag.cancelled:
    case StatusTag.inactive:
    case StatusTag.inactiveByCustomer:
    case StatusTag.inactiveByAdmin:
    case StatusTag.inactiveAsAccountLocked:
    case StatusTag.deleted:
    case MessageTag.saved:
    case COUPON_STATUS.INACTIVE:
    case COUPON_STATUS.INACTIVE_CRONJOB:
    case PublishTagType.unPublished:
    case CouponTransactionBy.CUSTOMER:
      statusClass = 'custom-tag-label-un-publish';
      break;
    case DownloadTag.downloading:
    case DownloadTag.importing:
    case DownloadTag.generating:
      statusClass = 'custom-tag-label-downloading';
      break;
    case DisplayPaymentSettlementReviewStatusType.waiting:
    case DownloadTag.pendingForImport:
    case DownloadTag.pendingForExport:
    case DownloadTag.pendingForGenerate:
    case CouponTransactionBy.ADMIN:
      statusClass = 'custom-tag-label-waiting';
      break;
    case DownloadTag.scheduledImport:
      statusClass = 'custom-tag-label-scheduled';
      break;
    default:
      statusClass = '';
      break;
  }

  return (
    <label className={`custom-tag-label ${statusClass} ${extraStyle}`}>
      {status}
    </label>
  );
};

export default CustomStatusTag;
