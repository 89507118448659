import { BaseHelper } from './BaseGraphQLAPIHelper';

const OFFER_LABEL_NODE = `{
  id
  pk
  name
  translations {
    edges {
      node {
        id
        pk
        language
        name
      }
    }
  }
  relatedCampaigns {
    id
    name
  }
}`;

const BASE_OFFER_LABEL_NODE = `{
  id
  pk
  name
  translations {
    edges {
      node {
        id
        pk
        language
        name
      }
    }
  }
}`;

export const getOfferTypeLabelList = (afterCursor, moreSearch) => {
  let filter = '';
  console.log("moreSearch:", moreSearch)
  const searchName = moreSearch?.filterName || moreSearch?.searchKey
  if (searchName) {
    
    filter = `, nameIcontains:"${searchName}"`;
  }
  let orderBy = moreSearch?.rank ? '-pk' : 'pk';
  if (moreSearch?.sort) {
    orderBy = moreSearch.sort;
  }
  const query = `{
    offerTypeLabels(first: 20, after:"${afterCursor}", orderBy:"${orderBy}"${filter}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node ${moreSearch?.isSelectorLoad ? BASE_OFFER_LABEL_NODE : OFFER_LABEL_NODE}
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getOfferTypeLabel = (id) => {
  const query = `{
    offerTypeLabel(id: "${id}") ${OFFER_LABEL_NODE}
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const deleteOfferTypeLabels = (ids) => {
  const query = `mutation DeleteOfferTypeLabels($input: DeleteOfferTypeLabelsInput!) {
    deleteOfferTypeLabels(input: $input) {
      success
    }
  }
  `;
  const variables = { input: { ids: ids } };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createOrUpdateOfferTypeLabel = (values) => {
  const action = values.id ? 'Update' : 'Create';
  let query = `mutation ${action}OfferTypeLabel($input: ${action}OfferTypeLabelInput!) {
    ${action.toLowerCase()}OfferTypeLabel(input: $input) {
      success
      node ${OFFER_LABEL_NODE}
      errors {
        field
        messages
      }
    }
  }

  `;

  const variables = { input: values };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
