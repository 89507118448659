import {
  baseRefreshToken,
  baseAPIinstance,
  addAuthorization,
  ACCESS_TOKEN,
} from './BaseRefreshHelper';
import Cookies from 'cookies-js';
const GET_BRANDS_PATH = '/graphql';

class APIHelper {
  constructor() {
    const token = Cookies.get(ACCESS_TOKEN);
    addAuthorization(token);
    baseRefreshToken();
  }
  callGraphQLAPI = (payload) => {
    const { query, variables, xLabel } = payload;
    const graphQL = {
      query: query,
      variables: variables,
    };
    var headers = {};
    if (xLabel) {
      headers['x-label'] = xLabel;
    }
    return baseAPIinstance
      .post(GET_BRANDS_PATH, JSON.stringify(graphQL), {
        headers: headers,
      })
      .catch((error) => {
        return { ...(error.response || {}) };
      });
  };

  callUpLoadFile = (path, body) => {
    console.log('uploadImageCall', path, body);
    const apiResponse = baseAPIinstance
      .post(path, body, {
        headers: { 'Content-Type': '*/*', Accept: '*/*' },
      })
      .catch((error) => ({ ...(error.response || {}) }));
    console.log('uploadImageCall', apiResponse);
    return apiResponse;
  };
}

export const BaseHelper = new APIHelper();
