import { BaseHelper } from './BaseGraphQLAPIHelper';

const NODE = `
  id
  pk
  creationDate
  activityType
  logContent {
    membershipId
    couponId
    couponTemplateId
    couponTemplateName
    goodieBagId
    goodieBagName
    campaignId
    campaignName
  }
  changeTo
  remarks
`;

export const getAdminActivityLogList = (afterCursor, params) => {
  let filter = '';
  console.log('@33', afterCursor, params);
  const {
    sort,
    searchCreateAt,
    searchActivityType,
    searchAdminGroup,
    searchAdmin,
  } = params || {};
  if (searchCreateAt) {
    filter += `, creationDateRange: "${searchCreateAt}"`;
  }
  if (searchActivityType) {
    filter += `, activityTypeIn:"${searchActivityType}"`;
  }
  if (searchAdminGroup) {
    filter += `, administratorGroups:"${searchAdminGroup}"`;
  }
  if (searchAdmin) {
    filter += `, administrators:"${searchAdmin}"`;
  }

  let orderBy = '-pk';
  if (sort) {
    orderBy = sort;
  }
  const query = `{
    administratorActivityLogs(first: ${
      params.pageSize || 20
    }, after:"${afterCursor}"${filter}, orderBy: "${orderBy}") {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ${NODE}
          administrator {
            pk
            username
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getAdminActivityLogDetail = (id) => {
  const query = `{
    administratorActivityLog(id: "${id}"){
      ${NODE}
      administrator {
        pk
        username
      }
    }
      
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getAdminActivityLogCount = (params) => {
  console.log('@91', params);
  let filter = '';
  const {
    creation_date_range,
    creation_datetime_range,
    activity_type_in,
    administrator_groups,
    administrators,
  } = params || {};
  if (creation_date_range) {
    filter += `, creationDateRange: "${creation_date_range}"`;
  }
  if (creation_datetime_range) {
    filter += `, creationDatetimeRange: "${creation_datetime_range}"`;
  }
  if (activity_type_in) {
    filter += `, activityTypeIn:"${activity_type_in}"`;
  }
  if (administrator_groups) {
    filter += `, administratorGroups:"${administrator_groups}"`;
  }
  if (administrators) {
    filter += `, administrators:"${administrators}"`;
  }
  const query = `{
    administratorActivityLogs(first: 20 ${filter}) {
      totalCount
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const createAdministratorActivityLog = (values) => {
  const query = `
  mutation CreateAdministratorActivityLog($input: CreateAdministratorActivityLogInput!) {
    createAdministratorActivityLog(input: $input) {
      success
      errors {
        field
        messages
      }
    }
  }


    `;
  const variables = { input: values };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
