import React from 'react';
import { useSelector } from 'react-redux';
import { CustomTitleWithDropDown } from '../../customer/CustomTitleWithDropDown';
import { CustomTitleLabel } from '../../earning/CustomBaseComponments';

function SurveyFormDropdown(props) {
  const customClass = props.customClass || '';
  const title = props.title;
  const defaultValue = props.defaultValue;
  const onSelectChange = props.onSelectChange || (() => {});
  const addButton = props.addButton;
  const editable = props.editable;
  const onFocus = props.onFocus || (() => {});
  const { surveyFormList } = useSelector((state) => ({
    surveyFormList: state.surveyForm.allList,
  }));

  if (editable)
    return (
      <CustomTitleWithDropDown
        customClass={customClass}
        title={title}
        source={surveyFormList}
        defaultValue={defaultValue}
        setValue={onSelectChange}
        loadMoreAction={'surveyForm/getList'}
        filterAction={'surveyForm/getList'}
        addButtonInfo={addButton}
        onFocus={onFocus}
        defaultFilter={{isSelectorLoad: true}}
      />
    );
  else
    return (
      <div className="brand-select-dropdown-container">
        <CustomTitleLabel title={title} />
        <label>{defaultValue?.label || '-'}</label>
      </div>
    );
}

export default SurveyFormDropdown;
