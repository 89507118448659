import React, { useEffect, useState } from 'react';
import { SectionTopTitle } from '../../base/CommonComponent';
import CustomTitleWithInput from '../../base/CustomTitleWithInput';
import ImageUploader from '../../base/ImageUploader';
import { CustomTitleLabel } from '../../earning/CustomBaseComponments';
import BaseMarkDownAreaWithoutController from '../../base/BaseMarkDownAreaWithoutController';
import CustomTitleWithSwitch from '../../base/CustomTitleWithSwitch';
import CustomEditor from '../../base/CustomEditor';
import './PhysicalCouponTemplateCard.scss';
import { Button, Toast } from 'react-bootstrap';
import PhysicalCouponTemplateReference from './PhysicalCouponTemplateReference';
import { ContinueWithBackButtons } from '../../base/BottomStepComponent';
import { useDispatch } from 'react-redux';
import { ImportResource } from '../../../models/DownloadImportModel';
import AuthButton from '../../base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { parsePhotoUrlForAPI } from '../../../utils';
import SaveDiscardToast from '../../base/SaveDiscardToast';
import { ToastType } from '../../../models/NavBarModel';

function PhysicalCouponTemplateCard({ couponSet, template }) {
  const dispatch = useDispatch();
  const [showExpiryDate, setShowExpiryDate] = useState(false);
  const [eventLogo, setEventLogo] = useState(null);
  const [couponPhoto, setCouponPhoto] = useState(null);
  const [showReference, setshowReference] = useState(false);
  const [documentTitle, setDocumentTitle] = useState('');
  const [freeTextTitleOne, setFreeTextTitleOne] = useState('');
  const [freeTextContentOne, setFreeTextContentOne] = useState('');
  const [freeTextTitleTwo, setFreeTextTitleTwo] = useState('');
  const [freeTextContentTwo, setFreeTextContentTwo] = useState('');
  const [freeTextTitleThree, setFreeTextTitleThree] = useState('');
  const [freeTextContentThree, setFreeTextContentThree] = useState('');
  const [freeTextTitleFour, setFreeTextTitleFour] = useState('');
  const [freeTextContentFour, setFreeTextContentFour] = useState('');
  const [freeTextTitleFive, setFreeTextTitleFive] = useState('');
  const [freeTextContentFive, setFreeTextContentFive] = useState('');
  const [freeTextTitleSix, setFreeTextTitleSix] = useState('');
  const [freeTextContentSix, setFreeTextContentSix] = useState('');
  const [freeTextTitleSeven, setFreeTextTitleSeven] = useState('');
  const [freeTextContentSeven, setFreeTextContentSeven] = useState('');
  const [freeTextContentEight, setFreeTextContentEight] = useState('');

  useEffect(() => {
    if (template) {
      setDocumentTitle(template?.physicalDocumentTitle);
      setEventLogo(template?.eventLogo);
      setCouponPhoto(template?.physicalCouponPhoto);
      setShowExpiryDate(template.showCouponSetExpiryDate);
      setFreeTextTitleOne(template?.freeTextTitleOne);
      setFreeTextContentOne(template?.freeTextContentOne);
      setFreeTextTitleTwo(template?.freeTextTitleTwo);
      setFreeTextContentTwo(template?.freeTextContentTwo);
      setFreeTextTitleThree(template?.freeTextTitleThree);
      setFreeTextContentThree(template?.freeTextContentThree);
      setFreeTextTitleFour(template?.freeTextTitleFour);
      setFreeTextContentFour(template?.freeTextContentFour);
      setFreeTextTitleFive(template?.freeTextTitleFive);
      setFreeTextContentFive(template?.freeTextContentFive);
      setFreeTextTitleSix(template?.freeTextTitleSix);
      setFreeTextContentSix(template?.freeTextContentSix);
      setFreeTextTitleSeven(template?.freeTextTitleSeven);
      setFreeTextContentSeven(template?.freeTextContentSeven);
      setFreeTextContentEight(template?.freeTextContentEight);
    }
  }, [template]);

  const imageComponent = ({
    title,
    tips,
    image,
    setValue = () => {},
    minWidth,
    minHeight,
    aspect,
    skipSize,
    skipCheckImageSize = false,
  }) => {
    return (
      <div className="d-flex flex-column">
        <CustomTitleLabel title={title} />
        {tips ? (
          <label className="tips-message wording-new-line">{tips}</label>
        ) : null}
        <ImageUploader
          minWidth={minWidth}
          minHeight={minHeight}
          images={image ? [image] : []}
          onImageStateChange={setValue}
          uploadImageClicked={() => {}}
          aspect={aspect}
          maxImageNum={1}
          language={'en'}
          skipSize={skipSize}
          skipCheckImageSize={skipCheckImageSize}
        />
      </div>
    );
  };

  const freeText = (index) => {
    const titleValue = () => {
      switch (index) {
        case 1:
          return freeTextTitleOne;
        case 2:
          return freeTextTitleTwo;
        case 3:
          return freeTextTitleThree;
        case 4:
          return freeTextTitleFour;
        case 5:
          return freeTextTitleFive;
        case 6:
          return freeTextTitleSix;
        case 7:
          return freeTextTitleSeven;
      }
    };

    const contentValue = () => {
      switch (index) {
        case 1:
          return freeTextContentOne;
        case 2:
          return freeTextContentTwo;
        case 3:
          return freeTextContentThree;
        case 4:
          return freeTextContentFour;
        case 5:
          return freeTextContentFive;
        case 6:
          return freeTextContentSix;
        case 7:
          return freeTextContentSeven;
      }
    };

    let setTitle = () => {};
    switch (index) {
      case 1:
        setTitle = setFreeTextTitleOne;
        break;
      case 2:
        setTitle = setFreeTextTitleTwo;
        break;
      case 3:
        setTitle = setFreeTextTitleThree;
        break;
      case 4:
        setTitle = setFreeTextTitleFour;
        break;
      case 5:
        setTitle = setFreeTextTitleFive;
        break;
      case 6:
        setTitle = setFreeTextTitleSix;
        break;
      case 7:
        setTitle = setFreeTextTitleSeven;
        break;
      default:
        break;
    }

    let setContent = () => {};
    switch (index) {
      case 1:
        setContent = setFreeTextContentOne;
        break;
      case 2:
        setContent = setFreeTextContentTwo;
        break;
      case 3:
        setContent = setFreeTextContentThree;
        break;
      case 4:
        setContent = setFreeTextContentFour;
        break;
      case 5:
        setContent = setFreeTextContentFive;
        break;
      case 6:
        setContent = setFreeTextContentSix;
        break;
      case 7:
        setContent = setFreeTextContentSeven;
        break;
      default:
        break;
    }
    return (
      <BaseMarkDownAreaWithoutController
        title={{
          titleName: `Free text ${index} title (optional)`,
          titleValue: titleValue(),
        }}
        setTitleValue={(data) => {
          setTitle(data);
        }}
        content={{
          contentKey: '',
          contentName: `Free text ${index} content (optional)`,
          contentValue: contentValue(),
          contentTips:
            'The font size setting in the field cannot reflect in the physical template.',
          contentExtra: {
            toolBars: [['bold', 'italic'], ['quote'], ['ul', 'ol'], ['link']],
          },
        }}
        setContentValue={(data) => {
          setContent(data);
        }}
      />
    );
  };

  const markDownArea = ({ title, tips, value, setValue }) => {
    return (
      <div className="base-mark-down-area">
        <CustomTitleLabel title={title} />
        <label className={`tips-message wording-new-line`}>{tips}</label>
        <CustomEditor
          initialValue={value}
          onValueChange={setValue}
          toolBars={[['bold', 'italic'], ['quote'], ['ul', 'ol'], ['link']]}
        />
      </div>
    );
  };

  const saveAction = () => {
    const payload = {
      id: couponSet?.pk,
      physicalDocumentTitle: documentTitle,
      freeTextTitleOne,
      freeTextContentOne,
      freeTextTitleTwo,
      freeTextContentTwo,
      eventLogo,
      showCouponSetExpiryDate: showExpiryDate,
      physicalCouponPhoto: couponPhoto,
      freeTextTitleThree,
      freeTextContentThree,
      freeTextTitleFour,
      freeTextContentFour,
      freeTextTitleFive,
      freeTextContentFive,
      freeTextTitleSix,
      freeTextContentSix,
      freeTextTitleSeven,
      freeTextContentSeven,
      freeTextContentEight,
    };
    dispatch({
      type: 'physicalCoupon/updatePhysicalCouponTemplate',
      payload: {
        ...payload,
        afterAction: () => {
          dispatch({
            type: 'physicalCoupon/getPhysicalCouponTemplate',
            payload: { template: couponSet?.pk },
          });
          dispatch({
            type: 'navBars/updateState',
            payload: {
              saveDiscardToastShowing: {
                value: true,
                type: ToastType.save,
              },
            },
          });
        },
        failedAction: () => {
          dispatch({
            type: 'navBars/updateState',
            payload: {
              saveDiscardToastShowing: {
                value: true,
                type: ToastType.saveFailed,
              },
            },
          });
        },
      },
    });
  };

  const previewAction = () => {
    dispatch({
      type: 'downloadAndImport/createDownloadTask',
      payload: {
        from: ImportResource.physicalCouponTemplate,
        related: {
          data: JSON.stringify({
            template_id: couponSet?.pk,
            physical_document_title: documentTitle,
            free_text_1_title: freeTextTitleOne,
            free_text_1_content: freeTextContentOne,
            free_text_2_title: freeTextTitleTwo,
            free_text_2_content: freeTextContentTwo,
            event_logo: parsePhotoUrlForAPI(eventLogo),
            show_coupon_set_expiry_date: showExpiryDate,
            physical_coupon_photo: parsePhotoUrlForAPI(couponPhoto),
            free_text_3_title: freeTextTitleThree,
            free_text_3_content: freeTextContentThree,
            free_text_4_title: freeTextTitleFour,
            free_text_4_content: freeTextContentFour,
            free_text_5_title: freeTextTitleFive,
            free_text_5_content: freeTextContentFive,
            free_text_6_title: freeTextTitleSix,
            free_text_6_content: freeTextContentSix,
            free_text_7_title: freeTextTitleSeven,
            free_text_7_content: freeTextContentSeven,
            free_text_8_content: freeTextContentEight,
          }),
        },
      },
    });
  };

  return (
    <div className={'coupon-overview-container'}>
      <div className="second-section">
        <SectionTopTitle
          title={'Manage physical coupon template information (For export)'}
        />
        <Button
          variant="link"
          className={'see-reference-button'}
          onClick={() => {
            setshowReference(true);
          }}
        >
          See reference
        </Button>
        <>
          {markDownArea({
            title: 'Physical document title (optional)',
            tips: 'The font size setting in the field cannot reflect in the physical template.',
            value: documentTitle,
            setValue: (data) => {
              setDocumentTitle(data);
            },
          })}

          {freeText(1)}

          {freeText(2)}

          {imageComponent({
            title: 'Logo (optional)',
            tips: `Image aspect ratio: 7.11 cm*1.35 cm. Suggested: 202 px*38 px(72DPI); 420 px*80 px(150DPI); 
            840 px*160 px(300DPI). Support JPG, PNG only.`,
            image: eventLogo,
            setValue: (newState) => {
              setEventLogo(newState[0] ? newState[0] : null);
            },
            aspect: 168 / 32,
            skipSize: true,
            skipCheckImageSize: true,
          })}

          <CustomTitleWithSwitch
            title={'Show coupon set expiry date'}
            tips={
              'Default OFF. If On, it will display “到期日 / 到期日 / Expires on: YYYY-MM-DD (HH:mm)” in template.'
            }
            defaultValue={showExpiryDate}
            setValue={(value) => {
              setShowExpiryDate(value);
            }}
          />

          {imageComponent({
            title: 'Physical coupon photo (optional)',
            tips: `Image aspect ratio: 24.49 cm*3.81 cm. Suggested: 694 px*108 px(72DPI); 1446 px*225 px(150DPI);
            2892 px*450 px(300DPI). Support JPG, PNG only.`,
            image: couponPhoto,
            setValue: (newState) => {
              setCouponPhoto(newState[0] ? newState[0] : null);
            },
            minWidth: 964,
            minHeight: 150,
            aspect: 964 / 150,
            skipCheckImageSize: true,
          })}

          {freeText(3)}

          {freeText(4)}

          {freeText(5)}

          {freeText(6)}

          {freeText(7)}

          {markDownArea({
            title: 'Free text 8 content (optional)',
            tips: `The font size setting in the field cannot reflect in the physical template.
                When the input font is bold, the font in the template will show HKTB brand red + bold.`,
            value: freeTextContentEight,
            setValue: (data) => {
              setFreeTextContentEight(data);
            },
          })}
        </>
        <div className="bottom-button-container">
          <AuthButton
            customClass={'btn-further save-button'}
            title={'Save'}
            action={() => {
              saveAction();
            }}
            requires={PermissionCodes.changeCouponTemplate}
          />
          <AuthButton
            customClass={'btn-back-button-common preview-button'}
            title={'Preview'}
            action={() => {
              previewAction();
            }}
            requires={PermissionCodes.changeCouponTemplate}
          />
        </div>
      </div>
      <PhysicalCouponTemplateReference
        show={showReference}
        closeAction={() => {
          setshowReference(false);
        }}
      />
      <SaveDiscardToast />
    </div>
  );
}

export default PhysicalCouponTemplateCard;
