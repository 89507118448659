import React, { useEffect } from 'react';
import './PhysicalCouponOverview.scss';
import { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import CouponBatchPageCard from './CouponBatchPageCard';
import PhysicalCouponTemplateCard from './PhysicalCouponTemplateCard';
import { useParams, useLocation } from 'react-router-dom';

function PhysicalCouponOverview({ couponSet, physicalCouponTemplate }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();
  useEffect(() => {
    dispatch({
      type: 'physicalCoupon/getPhysicalCouponTemplate',
      payload: { template: id },
    });
  }, [location]);
  return (
    <>
      <CouponBatchPageCard
        couponSet={couponSet}
        template={physicalCouponTemplate}
      />
      <PhysicalCouponTemplateCard
        couponSet={couponSet}
        template={physicalCouponTemplate}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  physicalCouponTemplate: state.physicalCoupon.physicalCouponTemplate,
  couponBatches: state.couponBatch.currentPageCouponBatches,
  totalCount: state.couponBatch.totalCount,
});
export default connect(mapPropsToState)(PhysicalCouponOverview);
