import { BaseHelper } from './BaseGraphQLAPIHelper';

const LOGO_LABEL_NODE = `{
  id
  pk
  name
  logoLabelUrl
  icon
  displayPriority
  relatedCoupons {
    id
    name
  }
  relatedCampaigns {
    id
    name
  }
  translations {
    edges {
      node {
        language
        name
        logoLabelUrl
        icon
        pk
      }
    }
  }
}`;

export const getLogoLabelList = (afterCursor, params) => {
  let filter = '';
  let translationIds = '';
  // console.log('@31', afterCursor, params);
  const { sort, filterName, isAll, allWithTranslation } = params || {};
  if (filterName) {
    filter = `, nameIcontains:"${filterName}"`;
  }

  let orderBy = params.rank ? '-pk' : 'pk';
  if (sort) {
    orderBy = sort;
  }

  if (params?.campaignIn) {
    console.log('@@45');
    filter += `, campaignIn: "${params?.campaignIn}"`;
  }

  if (params?.campaignTranslationIn) {
    filter += `, campaignTranslationIn: "${params?.campaignTranslationIn}"`;
    translationIds = params?.campaignTranslationIn;
  }

  if (params?.couponTemplateIn) {
    filter += `, couponTemplateIn: "${params?.couponTemplateIn}"`;
  }

  if (params?.couponTemplateTranslationIn) {
    filter += `, couponTemplateTranslationIn: "${params?.couponTemplateTranslationIn}"`;
    translationIds = params?.couponTemplateTranslationIn;
  }

  const translationIn = params?.translationIn
    ? `${params?.translationIn}(idIn: "${translationIds}")`
    : null;

  const query = `{
    logoLabels(first: ${
      params.pageSize ? params.pageSize : 20
    }, after:"${afterCursor}"${filter}, orderBy: "${orderBy}") {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node ${
          allWithTranslation
            ? `{id
            pk
            icon
            name
            translations {
              edges {
                node {
                  language
                  name
                  icon
                  pk
                }
              }
            }
          }`
            : isAll
            ? `{id
              pk
              icon
              name}`
            : translationIn
            ? `{id
                pk
                name
              ${translationIn}{
                edges {
                  node {
                    pk
                  }
                }
              }}`
            : LOGO_LABEL_NODE
        }        
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getLogoLabelDetail = (id) => {
  const query = `{
    logoLabel(id: "${id}")
      ${LOGO_LABEL_NODE}
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const createLogoLabel = (payload) => {
  const query = `mutation CreateLogoLabel($input: CreateLogoLabelInput!) {
    createLogoLabel(input: $input) {
      node ${LOGO_LABEL_NODE}
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: {
      ...payload,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateLogoLabel = (payload) => {
  const query = `mutation UpdateLogoLabel($input: UpdateLogoLabelInput!) {
    updateLogoLabel(input: $input) {
      node ${LOGO_LABEL_NODE}
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: {
      ...payload,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteLogoLabels = (ids) => {
  const query = `mutation DeleteLogoLabels($input: DeleteLogoLabelsInput!) {
    deleteLogoLabels(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids,
    },
  };
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};
