import React from 'react';
import { useSelector } from 'react-redux';
import './GoodiebagCard.scss';

import { Link } from 'react-router-dom';
import icon from '../../assets/images/customer_overview_vector.svg';
import { SectionItem } from './CouponsCardNew';

const GoodiebagCard = () => {
  const {
    totalNumberOfGeneratedGoodieBags,
    totalNumberOfAcquiredGoodieBags,
    totalNumberOfPublishedGoodieBags,
    totalNumberOfNonpublishedGoodieBags,
    totalNumberOfExpiredGoodieBags,
  } = useSelector((state) => ({
    totalNumberOfGeneratedGoodieBags:
      state.dashboard.totalNumberOfGeneratedGoodieBags,
    totalNumberOfAcquiredGoodieBags:
      state.dashboard.totalNumberOfAcquiredGoodieBags,
    totalNumberOfPublishedGoodieBags:
      state.dashboard.totalNumberOfPublishedGoodieBags,
    totalNumberOfNonpublishedGoodieBags:
      state.dashboard.totalNumberOfNonpublishedGoodieBags,
    totalNumberOfExpiredGoodieBags:
      state.dashboard.totalNumberOfExpiredGoodieBags,
  }));
  return (
    <div className="dashbard-coupon-card dashboard-goodiebag-card">
      <div className="histogram-zoomable-card-chart-title">Goodie Bags</div>

      <div className="dashboard-goodiebag-card-sections">
        <SectionItem
          label="Generated"
          count={totalNumberOfGeneratedGoodieBags?.value}
          percentage={totalNumberOfGeneratedGoodieBags?.change}
        />
        <SectionItem
          label="Acquired"
          count={totalNumberOfAcquiredGoodieBags?.value}
          percentage={totalNumberOfAcquiredGoodieBags?.change}
        />
        <SectionItem
          label="Published"
          count={totalNumberOfPublishedGoodieBags?.value}
          percentage={totalNumberOfPublishedGoodieBags?.change}
        />
        <SectionItem
          label="Non-published"
          count={totalNumberOfNonpublishedGoodieBags?.value}
          percentage={totalNumberOfNonpublishedGoodieBags?.change}
        />
        <SectionItem
          label="Expired"
          count={totalNumberOfExpiredGoodieBags?.value}
          percentage={totalNumberOfExpiredGoodieBags?.change}
        />
      </div>
      <Link
        className="dashboard-overview-link coupons-overview-link"
        to={{ pathname: '/dashboard_goodiebag' }}
      >
        See detail
        <img className="dashboard-overview-link-icon" src={icon} alt="" />
      </Link>
    </div>
  );
};

export default GoodiebagCard;
