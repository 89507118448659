import { BaseHelper } from './BaseGraphQLAPIHelper';

const COUNTRY_CODE_NODE = `
  id
  pk
  name
  displayPriority
`;

export const getCountryCodeList = (afterCursor, moreSearch) => {
  let filter = '';
  let pageSize = 20;
  let orderBy = 'displayPriority';
  if (moreSearch?.filterName) {
    filter = `, nameIcontains:"${moreSearch.filterName}"`;
  }

  if (moreSearch?.campaignIn) {
    filter += `, campaignIn:"${moreSearch.campaignIn}"`;
  }

  if (moreSearch?.goodieBagIn) {
    filter += `, goodieBagIn:"${moreSearch.goodieBagIn}"`;
  }

  if (moreSearch?.pageSize) {
    pageSize = moreSearch?.pageSize;
  }

  const query = `{
      countryCodes(first: ${pageSize}, orderBy: "${orderBy}", after:"${afterCursor}"${filter}) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            ${COUNTRY_CODE_NODE}
          }
        }
      }
    }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};
