import React from 'react';
import './ItemTypeSet.scss';
import { Image } from 'react-bootstrap';
import { useMainContentWidth, getScrollbarWidth } from '../../utils/ScreenUtil';

export const MIN_WIDTH = 230;
export const MAX_WIDTH = 270;
export const SPACE_BETWEEN = 30;

function ItemTypeSet({
  item,
  onClick,
  selected,
  moreThanThree = true,
  extraClassName = '',
  disabled = false,
}) {
  const mainContentWidth = useMainContentWidth();
  const fullContainerWith = mainContentWidth - 80 - 60 - getScrollbarWidth();
  let width = MIN_WIDTH;
  if (moreThanThree) {
    if (fullContainerWith >= (MAX_WIDTH + SPACE_BETWEEN) * 3) {
      width = MAX_WIDTH;
    } else if (fullContainerWith >= (MIN_WIDTH + SPACE_BETWEEN) * 3) {
      width = (fullContainerWith - SPACE_BETWEEN * 3) / 3;
    } else if (fullContainerWith >= (MIN_WIDTH + SPACE_BETWEEN) * 2) {
      width = (fullContainerWith - SPACE_BETWEEN * 2) / 2;
    } else {
      width = fullContainerWith;
    }
  }

  return (
    <div
      key={`${item.id}-${item.description}`}
      className={`${
        item.id !== selected
          ? 'campaign-type-container'
          : 'campaign-type-container selected'
      } ${extraClassName}`}
      style={moreThanThree ? { width } : { flex: 1 }}
      onClick={disabled 
        ? () => {}
        : () => {
        onClick(item.id);
      }}
    >
      <label className="campaign-type-title">{item.title}</label>
      <Image className={disabled ? "campaign-type-image campaign-type-image-disabled": "campaign-type-image"} src={item.image} />
      <label className="campaign-type-description">{item.description}</label>
    </div>
  );
}

export default ItemTypeSet;
