import React from 'react';
import MembershipInfoCard from '../customer/MembershipInfoCard';
import { useFormContext } from 'react-hook-form';

function StepFourOutOfStockSection({ data }) {
  const { getValues } = useFormContext() || {};
  const goodieBag = data || getValues() || {};
  return (
    <div className="goodie-bag-step-four-properties">
      <MembershipInfoCard
        title="Out-of-stock goodie bag campaign"
        data={{
          hideOutOfStockCampaign: goodieBag.hideOutOfStockCampaign
            ? 'Yes'
            : 'No',
        }}
        fields={[
          [
            {
              title: 'Hide the goodie bag campaign which is out-of-stock',
              field: 'hideOutOfStockCampaign',
            },
          ],
        ]}
      />
    </div>
  );
}

export default StepFourOutOfStockSection;
