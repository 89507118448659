import React, { useState, useEffect, createRef, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './DHKPreviewBody.scss';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import TourCard, { getTranslationField } from './TourCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import CustomViewer from '../../../../components/base/CustomViewer';
import i18n from '../../../../I18n';
import {
  CampaignType,
  CountryCodeOptions,
  LanguageConfig,
  PublishTagType,
} from '../../../../config/CustomEnums';
import Loading from '../../../../components/base/Loading';
import FacebookMedia from '../../../../assets/images/preview/facebook.svg';
import TwitterMedia from '../../../../assets/images/preview/twitter.svg';
import MailMedia from '../../../../assets/images/preview/mail.svg';
import LinkMedia from '../../../../assets/images/preview/link.svg';
import StarLimitIcon from '../../../../assets/images/preview/starLimitIcon.svg';
import { createAction } from '../../../../utils';
import { CustomTitleWithDropDown } from '../../../../components/customer/CustomTitleWithDropDown';
import { t } from 'i18n-js';
import BasePrompt from '../../../../components/base/prompt/BasePrompt';
import CustomMap from '../../../../components/base/CustomGoogleMap';

SwiperCore.use([Pagination, Autoplay]);

const defaultLanguage = { code: 'en', name: 'English', parameter: 'EN', pk: 1 };
const starColor = '#ff580a';

function FacebookMedia2() {
  return (
    <svg
      id="FB"
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      viewBox="0 0 50 50"
    >
      <defs>
        <mask id="sharing_mask_id_4">
          <rect
            width="100%"
            height="100%"
            fill="#FFFFFF"
            x="0"
            y="0"
            // style="transform: translate(0%, 0%); transition: all 0s ease 0s;"
          ></rect>
        </mask>
      </defs>
      <g class="icon-hover__hidden">
        <path
          d="M25,49A24,24,0,1,1,49,25,24,24,0,0,1,25,49ZM25,2.45A22.55,22.55,0,1,0,47.55,25,22.58,22.58,0,0,0,25,2.45Z"
          fill-rule="evenodd"
          // style="fill: rgb(1, 152, 57);"
        ></path>
        <path
          d="M22.55,38.5V26.19H19v-4.8h3.55V17.85c0-4.11,2.15-6.35,5.28-6.35a25.81,25.81,0,0,1,3.17.19V16H28.82c-1.7,0-2,.95-2,2.34v3.06h4.06l-.53,4.8H26.79V38.5Z"
          fill-rule="evenodd"
          // style="fill: rgb(1, 152, 57);"
        ></path>
      </g>
      <g class="icon-hover__showed" mask="url(#sharing_mask_id_4)">
        <path
          d="M25,49A24,24,0,1,1,49,25,24,24,0,0,1,25,49ZM25,2.45A22.55,22.55,0,1,0,47.55,25,22.58,22.58,0,0,0,25,2.45Z"
          fill="#000000"
          fill-rule="evenodd"
        ></path>
        <path
          d="M22.55,38.5V26.19H19v-4.8h3.55V17.85c0-4.11,2.15-6.35,5.28-6.35a25.81,25.81,0,0,1,3.17.19V16H28.82c-1.7,0-2,.95-2,2.34v3.06h4.06l-.53,4.8H26.79V38.5Z"
          fill="#000000"
          fill-rule="evenodd"
        ></path>
      </g>
    </svg>
  );
}

function TwitterMedia2() {
  return (
    <svg
      id="twitter"
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      viewBox="0 0 50 50"
    >
      <defs>
        <mask id="sharing_mask_id_5">
          <rect
            width="100%"
            height="100%"
            fill="#FFFFFF"
            x="0"
            y="0"
            // style="transform: translate(0%, 0%); transition: all 0s ease 0s;"
          ></rect>
        </mask>
      </defs>
      <g class="icon-hover__hidden">
        <path
          d="M25,49A24,24,0,1,1,49,25,24,24,0,0,1,25,49ZM25,2.45A22.55,22.55,0,1,0,47.55,25,22.58,22.58,0,0,0,25,2.45Z"
          // style="fill: rgb(123, 48, 206);"
        ></path>
        <path
          d="M37.75,17h0M29.91,14.5a5.27,5.27,0,0,0-5.24,5.3h0A5.48,5.48,0,0,0,24.81,21,14.82,14.82,0,0,1,14,15.47a5.37,5.37,0,0,0,1.62,7.08,5.22,5.22,0,0,1-2.37-.67V22a5.3,5.3,0,0,0,4.2,5.2,5,5,0,0,1-2.36.09A5.24,5.24,0,0,0,20,30.92a10.55,10.55,0,0,1-7.75,2.2,14.73,14.73,0,0,0,8,2.38A14.89,14.89,0,0,0,35.16,20.61v-.19c0-.23,0-.46,0-.69A10.63,10.63,0,0,0,37.75,17a10.7,10.7,0,0,1-3,.83,5.32,5.32,0,0,0,2.3-2.93,10.5,10.5,0,0,1-3.32,1.28,5.23,5.23,0,0,0-3.82-1.67"
          // style="fill: rgb(123, 48, 206);"
        ></path>
      </g>
      <g class="icon-hover__showed" mask="url(#sharing_mask_id_5)">
        <path
          d="M25,49A24,24,0,1,1,49,25,24,24,0,0,1,25,49ZM25,2.45A22.55,22.55,0,1,0,47.55,25,22.58,22.58,0,0,0,25,2.45Z"
          fill="#000000"
        ></path>
        <path
          d="M37.75,17h0M29.91,14.5a5.27,5.27,0,0,0-5.24,5.3h0A5.48,5.48,0,0,0,24.81,21,14.82,14.82,0,0,1,14,15.47a5.37,5.37,0,0,0,1.62,7.08,5.22,5.22,0,0,1-2.37-.67V22a5.3,5.3,0,0,0,4.2,5.2,5,5,0,0,1-2.36.09A5.24,5.24,0,0,0,20,30.92a10.55,10.55,0,0,1-7.75,2.2,14.73,14.73,0,0,0,8,2.38A14.89,14.89,0,0,0,35.16,20.61v-.19c0-.23,0-.46,0-.69A10.63,10.63,0,0,0,37.75,17a10.7,10.7,0,0,1-3,.83,5.32,5.32,0,0,0,2.3-2.93,10.5,10.5,0,0,1-3.32,1.28,5.23,5.23,0,0,0-3.82-1.67"
          fill="#000000"
          fill-rule="even-odd"
        ></path>
      </g>
    </svg>
  );
}

function MailMedia2() {
  return (
    <svg
      id="mail"
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      viewBox="0 0 50 50"
    >
      <defs>
        <mask id="sharing_mask_id_6">
          <rect
            width="100%"
            height="100%"
            fill="#FFFFFF"
            x="0"
            y="0"
            // style="transform: translate(0%, 0%); transition: transform 0.3s ease 0s;"
          ></rect>
        </mask>
      </defs>
      <g class="icon-hover__hidden">
        <path
          d="M25,49A24,24,0,1,1,49,25,24,24,0,0,1,25,49ZM25,2.45A22.55,22.55,0,1,0,47.55,25,22.58,22.58,0,0,0,25,2.45Z"
          // style="fill: rgb(0, 0, 0);"
        ></path>
        <path
          d="M13.75,16.75v16.5h22.5V16.75Zm21.5,14.81L28.72,25l6.53-6.45v13ZM14.75,18.44,21.33,25l-6.58,6.6ZM26,26.32a1.38,1.38,0,0,1-.95.35,1.34,1.34,0,0,1-1-.36l-8.63-8.58H34.68ZM22,25.67,23.37,27a2.44,2.44,0,0,0,3.32,0L28,25.7l6.54,6.56H15.45Z"
          fill-rule="even-odd"
          // style="fill: rgb(0, 0, 0);"
        ></path>
      </g>
      <g class="icon-hover__showed" mask="url(#sharing_mask_id_6)">
        <path
          d="M25,49A24,24,0,1,1,49,25,24,24,0,0,1,25,49ZM25,2.45A22.55,22.55,0,1,0,47.55,25,22.58,22.58,0,0,0,25,2.45Z"
          fill="#000000"
        ></path>
        <path
          d="M13.75,16.75v16.5h22.5V16.75Zm21.5,14.81L28.72,25l6.53-6.45v13ZM14.75,18.44,21.33,25l-6.58,6.6ZM26,26.32a1.38,1.38,0,0,1-.95.35,1.34,1.34,0,0,1-1-.36l-8.63-8.58H34.68ZM22,25.67,23.37,27a2.44,2.44,0,0,0,3.32,0L28,25.7l6.54,6.56H15.45Z"
          fill-rule="even-odd"
          fill="#000000"
        ></path>
      </g>
    </svg>
  );
}

function LinkMedia2() {
  return (
    <svg
      id="link"
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      viewBox="0 0 50 50"
    >
      <defs>
        <mask id="sharing_mask_id_7">
          <rect
            width="100%"
            height="100%"
            fill="#FFFFFF"
            x="0"
            y="0"
            // style="transform: translate(0%, 0%); transition: all 0s ease 0s;"
          ></rect>
        </mask>
      </defs>
      <g class="icon-hover__hidden">
        <path
          d="M25,49A24,24,0,1,1,49,25,24,24,0,0,1,25,49ZM25,2.45A22.55,22.55,0,1,0,47.55,25,22.58,22.58,0,0,0,25,2.45Z"
          // style="fill: rgb(123, 48, 206);"
        ></path>
        <path
          d="M21.79,32l-1.22,1.2a2.72,2.72,0,0,1-3.81,0,2.64,2.64,0,0,1,0-3.74h0l4.47-4.43c.92-.92,2.67-2.27,3.93-1a1.49,1.49,0,0,0,2.1-2.12c-2.15-2.14-5.34-1.74-8.12,1l-4.47,4.43a5.61,5.61,0,0,0,0,7.94l0,0a5.69,5.69,0,0,0,8,0l1.22-1.2A1.49,1.49,0,1,0,21.79,32ZM35.33,14.81a5.3,5.3,0,0,0-7.49-.49,2,2,0,0,0-.21.19L26.11,16a1.49,1.49,0,0,0,2.1,2.11l1.52-1.5a2.41,2.41,0,0,1,3.39.15l.12.15a2.64,2.64,0,0,1,0,3.74h0L28.47,25.4c-2.18,2.17-3.2,1.15-3.64.72a1.49,1.49,0,0,0-2.1,2.11,4.73,4.73,0,0,0,3.34,1.49,6.58,6.58,0,0,0,4.49-2.21l4.77-4.73a5.59,5.59,0,0,0,0-7.93l0,0Z"
          // style="fill: rgb(123, 48, 206);"
        ></path>
      </g>
      <g class="icon-hover__showed" mask="url(#sharing_mask_id_7)">
        <path
          d="M25,49A24,24,0,1,1,49,25,24,24,0,0,1,25,49ZM25,2.45A22.55,22.55,0,1,0,47.55,25,22.58,22.58,0,0,0,25,2.45Z"
          fill="#000000"
        ></path>
        <path
          d="M21.79,32l-1.22,1.2a2.72,2.72,0,0,1-3.81,0,2.64,2.64,0,0,1,0-3.74h0l4.47-4.43c.92-.92,2.67-2.27,3.93-1a1.49,1.49,0,0,0,2.1-2.12c-2.15-2.14-5.34-1.74-8.12,1l-4.47,4.43a5.61,5.61,0,0,0,0,7.94l0,0a5.69,5.69,0,0,0,8,0l1.22-1.2A1.49,1.49,0,1,0,21.79,32ZM35.33,14.81a5.3,5.3,0,0,0-7.49-.49,2,2,0,0,0-.21.19L26.11,16a1.49,1.49,0,0,0,2.1,2.11l1.52-1.5a2.41,2.41,0,0,1,3.39.15l.12.15a2.64,2.64,0,0,1,0,3.74h0L28.47,25.4c-2.18,2.17-3.2,1.15-3.64.72a1.49,1.49,0,0,0-2.1,2.11,4.73,4.73,0,0,0,3.34,1.49,6.58,6.58,0,0,0,4.49-2.21l4.77-4.73a5.59,5.59,0,0,0,0-7.93l0,0Z"
          fill="#000000"
          fill-rule="even-odd"
        ></path>
      </g>
    </svg>
  );
}

function WalletIcon({ fill = '#FFFFFF' }) {
  // 👇️ paste SVG into a component
  // take fill and stroke colors as props
  return (
    <svg
      class="add-to-my-e-wallet-btn-ico"
      width="30"
      height="35"
      viewBox="0 0 30 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.7316 7.93356C20.2914 7.82051 19.8318 7.80545 19.3852 7.88941L6.99784 10.0037L6.98356 10.0062C6.2731 10.1416 5.63216 10.5207 5.17129 11.0781C4.71052 11.6353 4.45861 12.3359 4.45898 13.059V13.0592V14.6104C4.45898 14.6109 4.45898 14.6115 4.45898 14.612V23.6477C4.45898 25.3111 5.80746 26.6596 7.47089 26.6596H22.5304C24.1938 26.6596 25.5423 25.3111 25.5423 23.6477V14.612C25.5423 13.131 24.4734 11.8997 23.065 11.6474V10.941C23.0649 10.4839 22.9639 10.0325 22.7692 9.61893C22.5745 9.20539 22.2909 8.83992 21.9387 8.54859C21.5865 8.25727 21.1743 8.04726 20.7316 7.93356ZM21.5591 11.6001V10.9414V10.9412C21.559 10.7058 21.5069 10.4734 21.4067 10.2604C21.3064 10.0474 21.1603 9.8591 20.9788 9.70902C20.7974 9.55894 20.5851 9.45075 20.357 9.39218C20.1289 9.33361 19.8908 9.3261 19.6595 9.37018L19.6452 9.37275L7.25905 11.4868C7.07108 11.5234 6.89266 11.5932 6.73154 11.6915C6.9681 11.6318 7.21579 11.6001 7.47089 11.6001H21.5591ZM5.96494 14.6115C5.96494 14.6117 5.96494 14.6118 5.96494 14.612L5.96494 15.3649L5.96494 23.6477C5.96494 24.4794 6.63917 25.1537 7.47089 25.1537H22.5304C23.3621 25.1537 24.0364 24.4794 24.0364 23.6477V14.612C24.0364 13.7803 23.3621 13.106 22.5304 13.106H7.47089C6.63934 13.106 5.96521 13.78 5.96494 14.6115Z"
        fill={fill}
      ></path>
    </svg>
  );
}

function StoreLocationIcon({ fill = '#298B50', width = '15', height = '15' }) {
  return (
    <svg
      t="1640679522411"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="4144"
      width={width}
      height={height}
    >
      <defs> </defs>
      <path
        d="M507.531222 244.23249c-88.173179 0-159.635767 71.462588-159.635767 159.635767s71.462588 159.635767 159.635767 159.635767 159.635767-71.462588 159.635767-159.635767S595.7044 244.23249 507.531222 244.23249zM507.531222 523.595083c-66.13014 0-119.726825-53.596685-119.726825-119.726825s53.596685-119.726825 119.726825-119.726825 119.726825 53.596685 119.726825 119.726825S573.662385 523.595083 507.531222 523.595083z"
        p-id="4145"
        fill={fill}
      ></path>
      <path
        d="M510.85185 71.290673c-184.835705 0-335.226924 150.391219-335.226924 335.235111 0 178.654936 304.032458 507.074827 316.971142 522.337439l18.255783 21.51399 18.255783-21.51399c12.938684-15.262612 316.979328-343.682503 316.979328-522.337439C846.086961 221.681892 695.695742 71.290673 510.85185 71.290673zM510.773056 875.860055C416.162258 760.481258 223.515656 531.585057 223.515656 406.525784c0-158.435429 128.908952-287.344381 287.336194-287.344381 158.435429 0 287.344381 128.908952 287.344381 287.344381C798.196231 544.476669 576.561411 795.044448 510.773056 875.860055z"
        p-id="4146"
        fill={fill}
      ></path>
    </svg>
  );
}

function StoreMobileIcon({ fill = '#298B50' }) {
  return (
    <svg
      t="1640679448989"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="3327"
      width="20"
      height="20"
    >
      <defs></defs>
      <path
        d="M634.592 611.776c-8.256 7.632-20.032 20.704-35.04 38.976l-12.96 15.776-17.648-10.256c-84.352-49.008-152.368-121.216-190.832-204.912l-7.2-15.696 12.592-11.824c17.232-16.192 29.824-28.912 37.6-37.952a122.24 122.24 0 0 0 4.096-4.976 340.08 340.08 0 0 1-12.272-98.624 22.56 22.56 0 0 0-1.312-0.72 47.248 47.248 0 0 0-17.92-4.288l-99.44-3.472c-3.936-0.16-4.432 0.32-4.576 4.256-8.864 254 189.28 466.464 443.264 475.344 3.76 0.128 4.448-0.512 4.576-4.272l3.376-96.8c0.272-7.584 0.272-11.36-0.08-15.552-0.704-8.352-0.32-7.792-1.84-7.712-33.952 1.2-66.096-5.376-102.528-18.992a102.768 102.768 0 0 0-1.856 1.696z m-58.688-7.488c10.208-11.84 18.88-21.072 26.08-27.744 17.44-16.16 29.568-23.136 45.328-13.696 33.584 13.12 61.44 19.264 89.76 18.256 33.072-1.44 48.96 20.864 51.584 51.632 0.56 6.672 0.544 11.84 0.208 21.28l-3.376 96.8c-1.056 30.352-24.064 51.616-54.224 50.56-280.48-9.792-499.344-244.48-489.552-524.992 1.072-30.432 23.776-51.616 54.224-50.56l99.424 3.472c33.872 1.184 66.8 17.824 65.648 51.008a292.64 292.64 0 0 0 12.56 95.088c5.776 18.72-5.152 33.76-44.336 71.248 32.336 62.4 83.712 117.264 146.672 157.648z"
        p-id="3328"
        fill={fill}
      ></path>
    </svg>
  );
}

function StoreArrowIcon({ fill = '#298B50' }) {
  return (
    <svg
      t="1640679541353"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="4932"
      width="20"
      height="20"
    >
      <defs> </defs>
      <path
        d="M593.450667 512.128L360.064 278.613333l45.290667-45.226666 278.613333 278.762666L405.333333 790.613333l-45.226666-45.269333z"
        p-id="4933"
        fill={fill}
      ></path>
    </svg>
  );
}

function StoreClockIcon({ fill = '#298B50' }) {
  return (
    <svg
      t="1645428859514"
      class="clock-icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="2062"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      width="16"
      height="16"
    >
      <defs> </defs>
      <path
        d="M511.488 0C228.864 0 0 229.376 0 512s228.864 512 511.488 512C794.624 1024 1024 794.624 1024 512s-229.376-512-512.512-512z m21.76 556.416V219.52H438.912v392.32h1.472l243.84 140.8 47.296-81.728-198.144-114.432zM512 921.6A409.472 409.472 0 0 1 102.4 512c0-226.304 183.296-409.6 409.6-409.6 226.304 0 409.6 183.296 409.6 409.6 0 226.304-183.296 409.6-409.6 409.6z"
        fill={fill}
        p-id="2063"
      ></path>
    </svg>
  );
}

function StoreIcon({ fill = '#298B50' }) {
  return (
    <svg
      t="1640679331768"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="5946"
      width="20"
      height="20"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <defs> </defs>
      <path
        d="M1015.36 195.264l-4.608-14.432h-5.6L802.08 0.096h-60.544V0H205.568L16.928 180.832h-5.536L6.816 195.2C2.912 207.552 0 221.248 0 236.096c0 47.584 24.768 89.088 61.568 113.184v316.8c0.064 37.376 30.24 67.552 67.552 67.552l740.8 0.064c37.248-0.064 67.424-30.24 67.552-67.616V361.696c49.472-20.096 84.64-68.576 84.704-125.6 0-14.848-2.912-28.544-6.816-40.832zM786.432 41.184l156.992 139.648H779.552l-67.36-139.648h74.24z m-253.792 0h133.92l67.424 139.648H532.64V41.184z m196.48 180.8v31.232c-8.16 44.16-46.624 77.824-92.992 77.76-46.432 0.064-84.768-33.536-92.992-77.6v-31.392H729.12z m-238.112-180.8v-0.064h0.544v139.712H286.496l55.968-139.648h148.544z m-12 180.8v31.104c-8.096 44.288-46.496 77.952-92.992 77.888-46.496 0.064-84.896-33.536-92.992-77.76v-31.232h185.984zM222.048 41.12h76.192l-56.064 139.712H76.384L222.048 41.12zM91.52 319.296c-29.952-16.128-50.4-47.104-50.4-83.2 0-4.736 0.48-9.376 1.344-14.112h186.432v31.392c-8.288 44.128-46.624 77.632-92.96 77.6-7.296 0-14.592-1.088-22.4-3.04-0.256-0.128-0.864-0.32-1.632-0.544-7.36-1.952-13.824-4.576-20.064-7.904l-0.32-0.192z m663.456 373.248H617.28V430.72h137.696v261.824z m141.472-26.464c0 14.496-12.16 26.464-26.528 26.464h-73.824V389.568H576.128v302.976H129.12c-14.368 0-26.528-11.968-26.528-26.464V367.552c0.416 0.128 0.864 0.192 1.344 0.352 9.6 2.368 20.448 4.128 32 4.128 56.48 0 104.544-34.368 125.12-83.264 20.448 48.864 68.64 83.264 124.992 83.264 56.48 0 104.544-34.432 124.992-83.36 20.576 48.928 68.64 83.36 125.12 83.36 56.352 0 104.48-34.368 124.992-83.264 20.576 48.864 68.64 83.264 125.12 83.264 3.52 0 6.944-0.192 10.208-0.48V666.08z m4.384-336.704c-5.6 1.024-10.208 1.568-14.592 1.568-46.496 0.064-84.832-33.472-92.992-77.6v-31.36h186.464c0.832 4.736 1.344 9.376 1.344 14.112 0 47.296-34.688 86.112-80.224 93.28zM192.416 635.584h315.072V389.568H192.416v246.016zM233.44 430.72h232.992v163.808H233.44V430.72z m518.08 122.464h-61.472v19.712h61.472v-19.712z"
        fill={fill}
        p-id="5947"
      ></path>
    </svg>
  );
}

const getImages = (language, campaign) => {
  const images = [];
  const fields = ['coverPhoto', 'detailPhotos'];

  fields.forEach((item) => {
    const image = getTranslationField({ language, field: item, campaign });
    if (image) {
      if (Array.isArray(image)) {
        image.forEach((item) => {
          if (item?.value) {
            images.push(item.value);
          } else {
            images.push(item);
          }
        });
      } else {
        if (image?.value) {
          images.push(image.value);
        } else {
          images.push(image);
        }
      }
    }
  });
  return images;
};

function Images({ language, campaign, isMobile }) {
  const images = getImages(language, campaign);
  return (
    <Swiper
      init={true}
      observer="true"
      autoplay={
        isMobile
          ? false
          : {
              stopOnLastSlide: false,
              disableOnInteraction: false,
              delay: 3000,
            }
      }
      loop={images.length > 1}
      className={`image-container`}
      pagination={{
        clickable: true,
        renderBullet: function (index, className) {
          if (images?.length > 1) {
            return '<span class="' + className + '"></span>';
          } else {
            return '';
          }
        },
      }}
      controlsProps={{
        // dotsTouchable: true,
        // prevPos: 'left',
        // nextPos: 'right',
        // nextTitle: '',
        // prevTitle: '',
        dotsWrapperStyle: { marginBottom: 20 },
      }}
    >
      {images.map((item, index) => (
        <SwiperSlide key={`slide-${index}`} className="swiper-slide">
          <img src={item} alt="" />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

function MediaContainer({ isStarCampaign }) {
  return (
    <div className="social-media-list-container">
      <div className="sharing__container float-left">
        <ul className="sharing__list">
          {[FacebookMedia, TwitterMedia, MailMedia, LinkMedia].map((item) => {
            return (
              <li
                className="sharing__item icon-hover3 icon-hover"
                data-sharing="facebook"
                data-on-enter="false"
                // mouseenter={() => {}}
              >
                <img src={item}></img>
              </li>
            );
          })}
          {/* {[
            <FacebookMedia2 />,
            <TwitterMedia2 />,
            <MailMedia2 />,
            <LinkMedia2 />,
          ].map((item) => (
            <li
              className={`sharing__item icon-hover3 icon-hover ${
                isStarCampaign ? 'star' : ''
              }`}
              data-sharing="facebook"
              data-on-enter="false"
              // onMouseEnter={(e) => {
              //   // style="transform: translate(0%, 0%); transition: all 0s ease 0s;"
              //   const mask = e.currentTarget.querySelector('rect');
              //   const path = e.currentTarget.querySelector(
              //     'g.icon-hover__hidden path',
              //   );
              //   console.log(path);
              //   path.classList();
              //   mask.animate(
              //     [
              //       { transform: 'translate(0%, -100%)' },
              //       { transform: 'translate(0%, 0%)' },
              //       // { transform: 'rotate(0) scale(1)' },
              //       // { transform: 'rotate(360deg) scale(0)' },
              //     ],
              //     {
              //       duration: 300,
              //       iterations: 1,
              //     },
              //   );
              // }}
            >
              {item}
            </li>
          ))} */}
        </ul>
      </div>
    </div>
  );
}

function StoreItem({ store, language, isStarredCampaign, onClick = () => {} }) {
  const storeAddress =
    language === 'en'
      ? store.address
      : store.translations?.edges?.filter(
          (item) => item.node.language === language,
        )?.[0]?.node?.address;
  const storeMobile =
    (store.phoneNumberCountryCode?.label || '') +
    ' ' +
    (store.phoneNumberSubscriberNumber || '');

  return (
    <div className="store" data-store-id={store.pk} onClick={onClick}>
      <div className="store-content">
        <div className="store-name -es-font-title">
          {language === 'en'
            ? store.name
            : store.translations?.edges?.filter(
                (item) => item.node.language === language,
              )?.[0]?.node?.name || ''}
        </div>
        {storeAddress ? (
          <div className="store-info-item">
            <div
              style={{ marginTop: '-1px', width: 'auto' }}
              className="item-ico"
            >
              <StoreLocationIcon
                fill={isStarredCampaign ? starColor : '#298B50'}
              />
            </div>
            <div className="item-content -es-font">{storeAddress}</div>
          </div>
        ) : null}

        {storeMobile.trim() ? (
          <div className="store-info-item">
            <div
              style={{ marginTop: '-1px', width: 'auto' }}
              className="item-ico"
            >
              <StoreMobileIcon
                fill={isStarredCampaign ? starColor : '#298B50'}
              />
            </div>
            <div className="item-content -es-font">+{storeMobile}</div>
          </div>
        ) : null}
      </div>
      <div className="store-arrow">
        <StoreArrowIcon fill={isStarredCampaign ? starColor : '#298B50'} />
      </div>
    </div>
  );
}

function StoreArea({ stores, language, isStarredCampaign }) {
  const allLocationSelection = {
    label: t('All location', { locale: language.code }),
    value: { pk: null },
  };

  const allAreaSelection = {
    label: t('All area', { locale: language.code }),
    value: { pk: null },
  };

  const [selectedDistinct, setSelectedDistinct] =
    useState(allLocationSelection);
  const [selectedRegion, setSelectedRegion] = useState(allAreaSelection);
  const [filteredStore, setFilteredStore] = useState(stores || []);
  const [showMoreStore, setShowMoreStore] = useState(false);

  const [storeAreaList, setStoreAreaList] = useState([]);
  const [storeLocationList, setStoreLocationList] = useState([]);
  const [storeAreaLocation, setStoreAreaLocation] = useState({});

  const [showStoreDetail, setShowStoreDetail] = useState(false);
  const [promptStore, setPromptStore] = useState();

  useEffect(() => {
    if (!selectedDistinct?.value?.pk && !selectedRegion?.value?.pk) {
      setFilteredStore(stores);
    } else {
      if (selectedDistinct?.value?.pk && selectedRegion?.value?.pk) {
        setFilteredStore(
          stores?.filter(
            (item) =>
              item.district?.value?.pk == selectedDistinct.value.pk &&
              item.district?.value?.region.pk === selectedRegion.value.pk,
          ),
        );
      } else if (selectedDistinct?.value?.pk && !selectedRegion?.value?.pk) {
        setFilteredStore(
          stores?.filter(
            (item) =>
              item && item.district?.value?.pk == selectedDistinct.value.pk,
          ),
        );
      } else if (selectedRegion?.value?.pk && !selectedDistinct?.value?.pk) {
        setFilteredStore(
          stores?.filter(
            (item) =>
              item &&
              item.district?.value?.region?.pk === selectedRegion.value.pk,
          ),
        );
      }
    }
  }, [selectedDistinct, selectedRegion]);

  useEffect(() => {
    if (stores?.length > 0) {
      let tempStoreAreaList = [allAreaSelection];

      const tempStoreAreaIds = [];

      let tempStoreLocationList = [allLocationSelection];

      const tempStoreLocationIds = [];

      const regionLocationMap = {};

      (stores || []).forEach((store) => {
        let storeDistict = store.district.value;

        let storeRegion = `${storeDistict.region.pk}`; //-${regionLanguageName}
        if (!regionLocationMap[storeRegion]) {
          regionLocationMap[storeRegion] = [];
        }

        if (tempStoreAreaIds.indexOf(storeDistict.region.pk) < 0) {
          let regionLanguageName = storeDistict.region.name;
          if (language.code !== 'en') {
            regionLanguageName = storeDistict.region.translations.edges?.filter(
              (val) => val.node.language === language.code,
            )?.[0]?.node?.name;
          }

          if (regionLanguageName) {
            tempStoreAreaIds.push(storeDistict.region.pk);
            tempStoreAreaList.push({
              label: regionLanguageName,
              pk: storeDistict.region.pk,
            });
          }
        }

        if (tempStoreLocationIds.indexOf(storeDistict.pk) < 0) {
          let locationLanguageName = storeDistict.name;
          if (language.code !== 'en') {
            locationLanguageName = storeDistict.translations.edges?.filter(
              (val) => val.node.language === language.code,
            )?.[0]?.node?.name;
          }

          if (locationLanguageName) {
            tempStoreLocationIds.push(storeDistict.pk);
            tempStoreLocationList.push({
              label: locationLanguageName,
              pk: storeDistict.pk,
            });

            if (
              storeRegion &&
              regionLocationMap[storeRegion]?.filter(
                (s) => s.pk === storeDistict.pk,
              ).length <= 0
            ) {
              regionLocationMap[storeRegion].push({
                label: locationLanguageName,
                pk: storeDistict.pk,
              });
            }
          }
        }
      });
      regionLocationMap[`null`] = tempStoreLocationList;

      setStoreAreaList(tempStoreAreaList);
      setStoreLocationList(tempStoreLocationList);
      setStoreAreaLocation(regionLocationMap);
    } else {
      setStoreAreaList([]);
      setStoreLocationList([]);
      setStoreAreaLocation({});
    }

    setSelectedRegion(allAreaSelection);
    setSelectedDistinct(allLocationSelection);
  }, [stores, language]);
  // console.log('@@625: ', storeAreaLocation);

  const storeOnClick = (store) => {
    const storeName =
      language.code === 'en'
        ? store.name
        : store.translations?.edges?.filter(
            (item) => item.node.language === language.code,
          )?.[0]?.node?.name || '';

    const storeAddress =
      language.code === 'en'
        ? store.address
        : store.translations?.edges?.filter(
            (item) => item.node.language === language.code,
          )?.[0]?.node?.address;
    const storeMobile =
      (store.phoneNumberCountryCode?.label || '') +
      ' ' +
      (store.phoneNumberSubscriberNumber || '');

    setPromptStore({
      ...store,
      name: storeName,
      address: storeAddress,
      mobile: storeMobile,
    });
    setShowStoreDetail(true);
  };

  const otherBody = () => {
    if (!promptStore) {
      return null;
    }

    const availableWorkHours = [
      ...promptStore?.weekHours?.sort((a, b) => {
        const aMinDay = Math.min.apply(
          Math,
          a.days.map((day) => day.key),
        );
        const bMinDay = Math.min.apply(
          Math,
          b.days.map((day) => day.key),
        );
        return aMinDay - bMinDay;
      }),
    ];

    // if (promptStore?.holidayHour && !promptStore?.holidayHour.isClose) {
    //   availableWorkHours.push({
    //     ...promptStore?.holidayHour,
    //     name: 'Public holidays',
    //   });
    // }
    const promptStoreLanguage = promptStore?.translations?.edges?.filter(
      (item) => item.node.language === language.code,
    )?.[0];

    const description =
      language.code === LanguageConfig.english
        ? promptStore?.description
        : promptStoreLanguage?.node?.description;

    const address =
      language.code === LanguageConfig.english
        ? promptStore?.address
        : promptStoreLanguage?.node?.address;
    const countryCode = promptStore?.phoneNumberCountryCode?.value;
    const displayMobile = `+
    ${
      countryCode == CountryCodeOptions[0].value ||
      countryCode == CountryCodeOptions[1].value
        ? promptStore?.mobile.slice(0, 8) + ' ' + promptStore?.mobile.slice(8)
        : promptStore?.mobile
    }`;
    return (
      <div className="detailPrompt">
        {description?.trim() ? (
          <div className="store-info-item">
            <div
              style={{ marginTop: '3px', width: 'auto' }}
              className="item-ico"
            >
              <StoreIcon
                fill={isStarredCampaign ? starColor : '#298B50'}
                // width={20}
                // height={20}
              />
            </div>
            {/* <div className="item-content -es-font">{ }</div> */}
            <CustomViewer
              className="item-content -es-font"
              initValue={description}
            />
          </div>
        ) : null}
        {promptStore?.mobile?.trim() ? (
          <div className="store-info-item">
            <div
              style={{ marginTop: '-1px', width: 'auto' }}
              className="item-ico"
            >
              <StoreMobileIcon
                fill={isStarredCampaign ? starColor : '#298B50'}
                width={20}
                height={20}
              />
            </div>
            <div className="item-content -es-font">{displayMobile}</div>
          </div>
        ) : null}
        {availableWorkHours?.length > 0 ||
        (promptStore?.holidayHour && !promptStore?.holidayHour.isClose) ? (
          <div className="store-info-item store-business-hour">
            <div
              style={{ marginTop: '-1px', width: 'auto' }}
              className="item-ico"
            >
              {isStarredCampaign ? (
                <StoreClockIcon fill={starColor} />
              ) : (
                <StoreClockIcon />
              )}
            </div>
            <div className="store-content store-business-hour-content -es-font">
              {availableWorkHours?.map((val) => {
                // if (!val || val.isClose) {
                //   return null;
                // }

                let displayName = '';

                const dayKeys = val.days.map((d) => d.key);
                const minDay = Math.min.apply(Math, dayKeys);
                const maxDay = Math.max.apply(Math, dayKeys);
                if (
                  val.days.length > 1 &&
                  dayKeys.length + minDay - 1 === maxDay
                ) {
                  const minDayName = val.days.filter((d) => d.key === minDay)[0]
                    .name;
                  const maxDayName = val.days.filter((d) => d.key === maxDay)[0]
                    .name;
                  displayName = `${t(minDayName, {
                    locale: language.code,
                  })} ${t('to', { locale: language.code })} ${t(maxDayName, {
                    locale: language.code,
                  })}`;
                } else {
                  displayName = val.days
                    .sort((a, b) => a.key - b.key)
                    .map((d) => t(d.name, { locale: language.code }))
                    .join(', ');
                }

                const displayHour = val.isClose
                  ? t('closed', { locale: language.code })
                  : `${val.openHour ? val.openHour.slice(0, 5) : ''} - ${
                      val.closeHour ? val.closeHour.slice(0, 5) : ''
                    }`;

                return (
                  <div className="store-business-hour-content-wrapper">
                    <div className="store-business-hour-col1">
                      {displayName}
                    </div>
                    <div className="store-business-hour-col2">
                      {displayHour?.trim() === '-' ? '' : displayHour}
                    </div>
                  </div>
                );
              })}

              {promptStore?.holidayHour && !promptStore?.holidayHour.isClose ? (
                <div className="store-business-hour-content-wrapper">
                  <div className="store-business-hour-col1">
                    {t('Public holidays', { locale: language.code })}
                  </div>
                  <div className="store-business-hour-col2">
                    {`${
                      promptStore?.holidayHour.openHour
                        ? promptStore?.holidayHour.openHour.slice(0, 5)
                        : ''
                    } - ${
                      promptStore?.holidayHour.closeHour
                        ? promptStore?.holidayHour.closeHour.slice(0, 5)
                        : ''
                    }`}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
        {address ? (
          <div className="store-info-item">
            <div
              style={{ marginTop: '-1px', width: 'auto' }}
              className="item-ico"
            >
              <StoreLocationIcon
                fill={isStarredCampaign ? starColor : '#298B50'}
                width={'20'}
                height={'20'}
              />
            </div>
            <div className="item-content -es-font">{address}</div>
          </div>
        ) : null}
        <div className="store-info-item store-map">
          <div
            className="store-map-container"
            style={{ position: 'relative', overflow: 'hidden' }}
          >
            <CustomMap
              lat={promptStore.latitude}
              lng={promptStore.longitude}
              height={300}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="applicable-stores-wrapper">
      <div className="area-wrapper">
        <div className="region-wrapper" data-select2-id="18">
          <CustomTitleWithDropDown
            defaultValue={selectedRegion}
            setValue={(val) => {
              setSelectedRegion(val);

              if (val.value.pk) {
                setStoreLocationList([
                  allLocationSelection,
                  ...storeAreaLocation[`${val.value.pk}`],
                ]);
              } else {
                // console.log('@@641: ', `${val.value.pk}`);
                setStoreLocationList(
                  storeAreaLocation[`${val.value.pk || null}`],
                );
              }
              setSelectedDistinct(allLocationSelection);
            }}
            source={storeAreaList}
            needDelete={false}
            needFilter={false}
            customClass={
              selectedRegion.label &&
              selectedRegion.label !== allAreaSelection.label
                ? isStarredCampaign
                  ? 'filterable-dropdown-sigle-star-selected'
                  : 'filterable-dropdown-sigle-selected'
                : ''
            }
          />
        </div>
        <div class="district-wrapper" data-select2-id="160">
          <CustomTitleWithDropDown
            defaultValue={selectedDistinct}
            setValue={(val) => {
              setSelectedDistinct(val);
            }}
            source={storeLocationList}
            needDelete={false}
            needFilter={false}
            customClass={
              selectedDistinct.label &&
              selectedDistinct.label !== allLocationSelection.label
                ? isStarredCampaign
                  ? 'filterable-dropdown-sigle-star-selected'
                  : 'filterable-dropdown-sigle-selected'
                : ''
            }
          />
        </div>
      </div>

      <div className="store-list">
        {filteredStore?.slice(0, 4)?.map((store, index) => {
          return (
            <>
              <StoreItem
                store={store}
                language={language.code}
                isStarredCampaign={isStarredCampaign}
                onClick={() => {
                  storeOnClick(store);
                }}
              />
              {index < store.length - 1 ? (
                <div style={{ backgroundColor: '#ccc', height: 1 }} />
              ) : null}
            </>
          );
        })}
        {filteredStore?.length > 4 && !showMoreStore ? (
          <div
            className="more-stores-text-wrapper"
            onClick={() => {
              setShowMoreStore(true);
            }}
          >
            <span className="more-stores-text -es-font">
              {t('See more', { locale: language.code })}
            </span>
          </div>
        ) : null}
        {showMoreStore
          ? filteredStore?.slice(4)?.map((store, index) => {
              return (
                <>
                  <StoreItem
                    store={store}
                    language={language.code}
                    isStarredCampaign={isStarredCampaign}
                    onClick={() => {
                      storeOnClick(store);
                    }}
                  />
                  {index < store.length - 1 ? (
                    <div
                      style={{
                        backgroundColor: '#ccc',
                        height: 1,
                      }}
                    />
                  ) : null}
                </>
              );
            })
          : null}
      </div>

      <BasePrompt
        show={showStoreDetail}
        closeAction={() => {
          setShowStoreDetail(false);
          setPromptStore();
        }}
        title={promptStore?.name}
        otherBody={otherBody}
        customClass="dhk-preview-prompt"
        dialogClassName={'dhk-preview-prompt-dialog'}
        // customContentStyle={{ width: '600px', height: '620px' }}
      />
    </div>
  );
}

export const useDeviceDetect = (maxWidth = 1024) => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return width <= maxWidth;
};

function CTAButton({ campaign, language }) {
  console.log(campaign, campaign.landingPageUrl);
  return campaign.campaignType === CampaignType.generalMessageCampaign &&
    campaign.haveLandingPage ? (
    <a
      className={`add-to-my-e-wallet-btn ${
        campaign.isStarred ? 'star-btn' : ''
      }`}
      href={getTranslationField({
        language,
        field: 'landingPageUrl',
        campaign,
      })}
    >
      <span>
        {getTranslationField({
          language,
          field: 'landingPageButtonName',
          campaign,
        }) || i18n.t('book_now', { locale: language?.code })}
      </span>
    </a>
  ) : [
      CampaignType.fortuneBagCampaign,
      CampaignType.generalMessageCampaign,
    ].indexOf(campaign.campaignType) < 0 ? (
    <div
      className={`add-to-my-e-wallet-btn ${
        campaign.isStarred ? 'star-btn' : ''
      }`}
    >
      {/* <img
  className="add-to-my-e-wallet-btn-ico"
  src={add_to_my_e_wallet_ico}
/> */}

      <WalletIcon fill={campaign.isStarred ? '#FF580A' : '#FFFFFF'} />
      <span>{i18n.t('add_to_my_e_wallet', { locale: language?.code })}</span>
    </div>
  ) : null;
}

function OfferBasicDetailPc({
  campaign,
  language,
  offerRuleTabSelected,
  setOfferRuleTabSelected,
  stores,
  languageLogoLabels,
  starPerheadLimit,
  offerTypeLabel,
}) {
  const offerRuleRef = useRef();
  const offerDetailContent = getTranslationField({
    language,
    field: 'offerDetailContent',
    campaign,
  });

  const instructionSectionContent = getTranslationField({
    language,
    field: 'instructionSectionContent',
    campaign,
  });

  const merchantTermsAndConditionsContent = getTranslationField({
    language,
    field: 'merchantTermsAndConditionsContent',
    campaign,
  });

  const shortDescription = getTranslationField({
    language,
    field: 'shortDescription',
    campaign,
  });

  const landingPageButtonName = getTranslationField({
    language,
    field: 'landingPageButtonName',
    campaign,
  });

  useEffect(() => {
    if (instructionSectionContent) {
      setOfferRuleTabSelected('instruction');
    } else if (stores?.length > 0) {
      setOfferRuleTabSelected('applicableStore');
    } else if (merchantTermsAndConditionsContent) {
      setOfferRuleTabSelected('merchantTerms');
    } else {
      setOfferRuleTabSelected();
    }
  }, [language]);

  let left = 0;
  let downX = 0;
  const handleMove = (e) => {
    let x = left + (downX - e.clientX);
    if (x < 0) x = 0;

    offerRuleRef.current.scrollTo(x, 0);
  };

  return (
    <>
      <div className="offer-basic-detail-pc">
        <div className="detail-left">
          <div
            className={`detail-photos-wrapper ${
              campaign.isStarred ? 'star' : ''
            }`}
          >
            <Images language={language} campaign={campaign} />
          </div>
          <MediaContainer isStarCampaign={campaign.isStarred} />
          {offerDetailContent ? (
            <div className="detail-content-wrapper">
              <div
                className={`detail-content-title ${
                  campaign.isStarred ? 'star-detail-content-title' : ''
                }`}
              >
                {getTranslationField({
                  language,
                  field: 'offerDetailTitle',
                  campaign,
                }) ||
                  i18n.t('preview_detail_title', { locale: language?.code })}
              </div>
              <CustomViewer
                className="detail-content detail-html-content"
                initValue={offerDetailContent}
              />
            </div>
          ) : null}
          {campaign.showTourCard ? (
            <TourCard language={language} campaign={campaign} />
          ) : null}

          {/* <div style={{}}>
            <div
              className="offer-rule-tab-titles-test"
              style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}
              ref={offerRuleRef}
              onMouseDown={(e) => {
                if (e.button === 0) {
                  console.log(
                    e.target,
                    e.clientX,
                    offerRuleRef.current.scrollLeft,
                  );

                  left = offerRuleRef.current.scrollLeft;
                  downX = e.clientX;
                  // console.log(offerRuleRef.current);
                  // offerRuleRef.current.scrollTo(20, 0);

                  offerRuleRef.current.addEventListener(
                    'mousemove',
                    handleMove,
                  );
                }
              }}
              onMouseUp={(e) => {
                offerRuleRef.current.removeEventListener(
                  'mousemove',
                  handleMove,
                );
              }}
            >
              <span>instruction Section Content</span>
              <div class="swiper-slide sep-wrapper swiper-slide-next">
                <div class="sep"></div>
              </div>
              <span>applicable store</span>
              <div class="swiper-slide sep-wrapper swiper-slide-next">
                <div class="sep"></div>
              </div>
              <span>
                instruction Section Content instruction Section Content
                instruction Section Content
              </span>
            </div>
          </div> */}

          <div className="offer-rule-tabs">
            <div
              className="offer-rule-tab-titles noselect"
              ref={offerRuleRef}
              onMouseDown={(e) => {
                if (e.button === 0) {
                  left = offerRuleRef.current.scrollLeft;
                  downX = e.clientX;
                  // console.log(offerRuleRef.current);
                  // offerRuleRef.current.scrollTo(20, 0);

                  offerRuleRef.current.addEventListener(
                    'mousemove',
                    handleMove,
                  );
                }
              }}
              onMouseUp={(e) => {
                offerRuleRef.current.removeEventListener(
                  'mousemove',
                  handleMove,
                );
              }}
              onMouseOut={(e) => {
                if (e.target === offerRuleRef.current) {
                  offerRuleRef.current.removeEventListener(
                    'mousemove',
                    handleMove,
                  );
                }
              }}
            >
              {instructionSectionContent ? (
                <span
                  className={`offer-rule-tab-title ${
                    campaign.isStarred ? 'star-offer-rule-tab' : ''
                  } ${offerRuleTabSelected === 'instruction' ? 'active' : ''}`}
                  onClick={() => {
                    setOfferRuleTabSelected('instruction');
                  }}
                >
                  {getTranslationField({
                    language,
                    field: 'instructionSectionTitle',
                    campaign,
                  }) ||
                    i18n.t('preview_detail_instruction', {
                      locale: language?.code,
                    })}
                </span>
              ) : null}

              {instructionSectionContent && stores?.length > 0 ? (
                <div class="swiper-slide sep-wrapper swiper-slide-next">
                  <div class="sep"></div>
                </div>
              ) : null}

              {stores?.length > 0 ? (
                <span
                  className={`offer-rule-tab-title ${
                    campaign.isStarred ? 'star-offer-rule-tab' : ''
                  } ${
                    offerRuleTabSelected === 'applicableStore' ? 'active' : ''
                  }`}
                  onClick={() => {
                    setOfferRuleTabSelected('applicableStore');
                  }}
                >
                  {i18n.t('applicable_store', { locale: language?.code })}
                </span>
              ) : null}

              {(instructionSectionContent || stores?.length > 0) &&
              merchantTermsAndConditionsContent ? (
                <div class="swiper-slide sep-wrapper swiper-slide-next">
                  <div class="sep"></div>
                </div>
              ) : null}

              {merchantTermsAndConditionsContent ? (
                <span
                  className={`offer-rule-tab-title ${
                    campaign.isStarred ? 'star-offer-rule-tab' : ''
                  } ${
                    offerRuleTabSelected === 'merchantTerms' ? 'active' : ''
                  }`}
                  onClick={() => {
                    setOfferRuleTabSelected('merchantTerms');
                  }}
                >
                  {getTranslationField({
                    language,
                    field: 'merchantTermsAndConditionsTitle',
                    campaign,
                  }) ||
                    i18n.t('preview_detail_merchant', {
                      locale: language?.code,
                    })}
                </span>
              ) : null}
            </div>
            <div className="offer-rule-tab-content">
              {offerRuleTabSelected === 'instruction' ? (
                <CustomViewer
                  className="detail-html-content"
                  initValue={instructionSectionContent}
                />
              ) : (
                <></>
              )}
              {offerRuleTabSelected === 'applicableStore' ? (
                <StoreArea
                  stores={stores}
                  language={language}
                  isStarredCampaign={campaign.isStarred}
                />
              ) : (
                <></>
              )}
              {offerRuleTabSelected === 'merchantTerms' ? (
                <CustomViewer
                  className="detail-html-content"
                  initValue={merchantTermsAndConditionsContent}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div
          className={`detail-right detail-box ${
            campaign.isStarred ? 'detail-box-star' : ''
          }`}
        >
          <div className="box-header">
            {offerTypeLabel ? (
              <div className="offer-labels">
                <div className="offer-label">{offerTypeLabel}</div>
              </div>
            ) : null}
            {/* <div className="offer-share">
              <img className="offer-share-ico" src={share_ico} />
              <span>{i18n.t('offer_share', { locale: language?.code })}</span>
            </div> */}
          </div>
          <div className="box-content">
            {campaign?.linkedBrand?.icon ? (
              <div className="offer-brand">
                <img
                  className="offer-brand-ico"
                  src={campaign?.linkedBrand?.icon}
                />
                <label className="offer-brand-title">
                  {language?.code === LanguageConfig.english
                    ? campaign?.linkedBrand?.name
                    : campaign?.linkedBrand?.translations?.[language?.code]
                        ?.name}
                </label>
              </div>
            ) : null}

            <div className="offer-name">
              {getTranslationField({
                language,
                field: 'generalName',
                campaign,
              })}
            </div>
            {shortDescription ? (
              <CustomViewer
                className={`offer-short-desc short-description-html-viewer ${
                  campaign?.isStarred ? 'star' : ''
                }`}
                initValue={shortDescription}
              />
            ) : (
              <label></label>
            )}
            {languageLogoLabels?.length > 0 ? (
              <div className="offer-logo-labels">
                {languageLogoLabels?.map((item) => {
                  return (
                    <div className="offer-logo-label">
                      <img className="logo-label-ico" src={item?.icon} />
                      <div className="logo-label-desc">{item?.name}</div>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
          {campaign.isStarred ? (
            <div className="offer-hint-ct" style={{}}>
              <img src={StarLimitIcon} />
              <div className="offer-hint offer-hint-hot-summer">
                {t(
                  'Each user can only save a maximum of limited quota offers in My Wallet at the same time',
                  {
                    locale: language.code,
                    limit: starPerheadLimit,
                  },
                )}
              </div>
            </div>
          ) : null}
          {/* {((campaign.campaignType === CampaignType.generalMessageCampaign &&
            campaign.haveLandingPage) ||
            (campaign.campaignType === CampaignType.couponsCampaign)) &&
            <div className="add-to-my-e-wallet-btn-ct">
              <a className="add-to-my-e-wallet-btn">
                {(campaign.campaignType === CampaignType.generalMessageCampaign) &&
                  <span>{landingPageButtonName}</span>
                }
                {(campaign.campaignType === CampaignType.couponsCampaign) &&
                  <>
                    <WalletIcon fill={campaign.isStarred ? '#FF580A' : '#FFFFFF'} />
                    <span>
                      {i18n.t('add_to_my_e_wallet', { locale: language?.code })}
                    </span>
                  </>
                }
              </a>
            </div>
          } */}

          <div className="add-to-my-e-wallet-btn-ct">
            <CTAButton language={language} campaign={campaign} />
          </div>
        </div>
      </div>
    </>
  );
}

function OfferBasicDetailMb({
  campaign,
  language,
  offerRuleTabSelected,
  setOfferRuleTabSelected,
  stores,
  languageLogoLabels,
  starPerheadLimit,
  offerTypeLabel,
}) {
  const offerDetailContent = getTranslationField({
    language,
    field: 'offerDetailContent',
    campaign,
  });

  const instructionSectionContent = getTranslationField({
    language,
    field: 'instructionSectionContent',
    campaign,
  });

  const merchantTermsAndConditionsContent = getTranslationField({
    language,
    field: 'merchantTermsAndConditionsContent',
    campaign,
  });

  const shortDescription = getTranslationField({
    language,
    field: 'shortDescription',
    campaign,
  });

  const landingPageButtonName = getTranslationField({
    language,
    field: 'landingPageButtonName',
    campaign,
  });

  useEffect(() => {
    if (instructionSectionContent) {
      setOfferRuleTabSelected('instruction');
    } else if (stores?.length > 0) {
      setOfferRuleTabSelected('applicableStore');
    } else if (merchantTermsAndConditionsContent) {
      setOfferRuleTabSelected('merchantTerms');
    }
  }, [language]);

  return (
    <>
      <div className="offer-basic-detail-mb">
        <div className="detail-photo-cover-wrapper">
          {/* <img
            className="detail-photo-cover"
            src={`${getImages(language, campaign)?.[0]}`}
            alt=""
          /> */}
          {/* <div className="offer-labels">
            <div className="offer-label">{campaign?.offerTypeLabel?.label}</div>
          </div> */}
          <Images language={language} campaign={campaign} isMobile={true} />
        </div>

        {/* <div
          className={`detail-photos-wrapper ${
            campaign.isStarred ? 'star' : ''
          }`}
        >
          <Images language={language} campaign={campaign} />
        </div> */}

        <div className="detail-wrapper">
          <div
            className={`detail-box ${
              campaign.isStarred ? 'detail-box-star' : ''
            }`}
          >
            {offerTypeLabel ? (
              <div class="offer-labels">
                <div class="offer-label ">{offerTypeLabel}</div>
              </div>
            ) : null}

            <div className="box-content">
              {campaign?.linkedBrand?.icon ? (
                <div className="offer-brand">
                  <img
                    className="offer-brand-ico"
                    src={campaign?.linkedBrand?.icon}
                  />
                  <label className="offer-brand-title">
                    {language?.code === LanguageConfig.english
                      ? campaign?.linkedBrand?.name
                      : campaign?.linkedBrand?.translations?.[language?.code]
                          ?.name}
                  </label>
                </div>
              ) : null}

              <div className={`offer-name `}>
                {getTranslationField({
                  language,
                  field: 'generalName',
                  campaign,
                })}
              </div>
              {shortDescription ? (
                <CustomViewer
                  className={`offer-short-desc short-description-html-viewer ${
                    campaign?.isStarred ? 'star' : ''
                  }`}
                  initValue={shortDescription}
                />
              ) : null}
              {/* <div class="state-btn-box">
                {campaign.showLowStock ? (
                  <span class="state-btn state-btn-1">Limited quota offer</span>
                ) : null}
              </div> */}

              {languageLogoLabels?.length > 0 ? (
                <div className="offer-logo-labels">
                  {languageLogoLabels?.map((item) => {
                    return (
                      <div className="offer-logo-label">
                        <img className="logo-label-ico" src={item?.icon} />
                        <div className="logo-label-desc">{item?.name}</div>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
            {campaign.isStarred ? (
              <div className="offer-hint-ct" style={{}}>
                <img src={StarLimitIcon} />
                <div className="offer-hint offer-hint-hot-summer">
                  {t(
                    'Each user can only save a maximum of limited quota offers in My Wallet at the same time',
                    {
                      locale: language.code,
                      limit: starPerheadLimit,
                    },
                  )}
                </div>
              </div>
            ) : null}
          </div>

          <div className="social-media-list-wrapper">
            <MediaContainer isStarCampaign={campaign.isStarred} />
          </div>

          {offerDetailContent ? (
            <div className="detail-content-wrapper">
              <div
                className={`detail-content-title ${
                  campaign.isStarred ? 'star-detail-content-title' : ''
                }`}
              >
                {getTranslationField({
                  language,
                  field: 'offerDetailTitle',
                  campaign,
                }) ||
                  i18n.t('preview_detail_title', { locale: language?.code })}
              </div>
              <CustomViewer
                className="detail-content detail-html-content"
                initValue={offerDetailContent}
              />
            </div>
          ) : null}
          {campaign.showTourCard ? (
            <TourCard language={language} campaign={campaign} isDHK={true} />
          ) : null}

          {/* <div style={{}}>
            <div>
              <span>instruction Section Content</span>
              <div class="swiper-slide sep-wrapper swiper-slide-next">
                <div class="sep"></div>
              </div>
              <span>applicable store</span>
              <div class="swiper-slide sep-wrapper swiper-slide-next">
                <div class="sep"></div>
              </div>
              <span>instruction Section Content</span>
            </div>
          </div> */}

          <div className="offer-rule-tabs">
            <div className="offer-rule-tab-titles">
              {instructionSectionContent ? (
                <span
                  className={`offer-rule-tab-title ${
                    campaign.isStarred ? 'star-offer-rule-tab' : ''
                  } ${offerRuleTabSelected === 'instruction' ? 'active' : ''}`}
                  onClick={() => {
                    setOfferRuleTabSelected('instruction');
                  }}
                >
                  {getTranslationField({
                    language,
                    field: 'instructionSectionTitle',
                    campaign,
                  }) ||
                    i18n.t('preview_detail_instruction', {
                      locale: language?.code,
                    })}
                </span>
              ) : null}

              {instructionSectionContent && stores?.length > 0 ? (
                <div class="swiper-slide sep-wrapper swiper-slide-next">
                  <div class="sep"></div>
                </div>
              ) : null}

              {stores?.length > 0 ? (
                <span
                  className={`offer-rule-tab-title ${
                    campaign.isStarred ? 'star-offer-rule-tab' : ''
                  } ${
                    offerRuleTabSelected === 'applicableStore' ? 'active' : ''
                  }`}
                  onClick={() => {
                    setOfferRuleTabSelected('applicableStore');
                  }}
                >
                  {i18n.t('applicable_store', { locale: language?.code })}
                </span>
              ) : null}

              {(instructionSectionContent || stores?.length > 0) &&
              merchantTermsAndConditionsContent ? (
                <div class="swiper-slide sep-wrapper swiper-slide-next">
                  <div class="sep"></div>
                </div>
              ) : null}

              {merchantTermsAndConditionsContent ? (
                <span
                  className={`offer-rule-tab-title ${
                    campaign.isStarred ? 'star-offer-rule-tab' : ''
                  } ${
                    offerRuleTabSelected === 'merchantTerms' ? 'active' : ''
                  }`}
                  onClick={() => {
                    setOfferRuleTabSelected('merchantTerms');
                  }}
                >
                  {getTranslationField({
                    language,
                    field: 'merchantTermsAndConditionsTitle',
                    campaign,
                  }) ||
                    i18n.t('preview_detail_merchant', {
                      locale: language?.code,
                    })}
                </span>
              ) : null}
            </div>
            <div className="offer-rule-tab-content">
              {offerRuleTabSelected === 'instruction' ? (
                <CustomViewer
                  className="offer-rule-tab-content detail-html-content"
                  initValue={instructionSectionContent}
                />
              ) : (
                <></>
              )}
              {offerRuleTabSelected === 'applicableStore' ? (
                <StoreArea
                  stores={stores}
                  language={language}
                  isStarredCampaign={campaign.isStarred}
                />
              ) : (
                <></>
              )}
              {offerRuleTabSelected === 'merchantTerms' ? (
                <CustomViewer
                  className="offer-rule-tab-content detail-html-content"
                  initValue={merchantTermsAndConditionsContent}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        {/* {((campaign.campaignType === CampaignType.generalMessageCampaign &&
          campaign.haveLandingPage) ||
          (campaign.campaignType === CampaignType.couponsCampaign)) &&
          <div className={`add-to-my-e-wallet-btn ${campaign.isStarred ? 'star-btn' : ''}`}>
            {(campaign.campaignType === CampaignType.generalMessageCampaign) &&
              <span>{landingPageButtonName}</span>
            }
            {(campaign.campaignType === CampaignType.couponsCampaign) &&
              <>
                <WalletIcon fill={campaign.isStarred ? '#FF580A' : '#FFFFFF'} />
                <span>
                  {i18n.t('add_to_my_e_wallet', { locale: language?.code })}
                </span>
              </>
            }

          </div>
        } */}
        <CTAButton language={language} campaign={campaign} />
      </div>
    </>
  );
}

function DHKPreviewBody({
  language = defaultLanguage,
  campaign,
  linkCouponDetail = false,
}) {
  const dispatch = useDispatch();

  const {
    linkedStores,
    storeLoading,
    allLogoLabels,
    starPerheadLimit,
    offerTypeLabel,
  } = useSelector((state) => ({
    linkedStores: state.storeModel.notPagedAllList,
    storeLoading: state.storeModel.allListLoading,
    allLogoLabels: state.logoLabel.notPagedAllList,
    starPerheadLimit: state.campaignList.starPerheadLimit,
    offerTypeLabel: state.offerTypeLabel.detail,
  }));

  const [offerRuleTabSelected, setOfferRuleTabSelected] =
    useState('instruction');
  const [
    moregenericTermsAndConditionsContent,
    setMoregenericTermsAndConditionsContent,
  ] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [languageLogoLabels, setLanguageLogoLabels] = useState([]);

  const isMobile = useDeviceDetect();

  useEffect(() => {
    console.log('campaign:', campaign);
    // console.log(
    //   'loading:',
    //   campaign?.pk,
    //   campaign?.linkedCoupon?.pk,
    //   linkCouponDetail,
    // );
    if ((campaign?.pk && !campaign?.linkedCoupon?.pk) || linkCouponDetail) {
      window.dispatchEvent(new Event('resize'));
      setIsLoading(false);
    } else if (campaign && !campaign?.pk) {
      window.dispatchEvent(new Event('resize'));
      setIsLoading(false);

      if (!campaign?.pk) {
        if (campaign.linkedStores?.length > 0) {
          const storeIds = campaign.linkedStores.map((s) => s.pk);

          dispatch(
            createAction('storeModel/getAllList')({
              ids: storeIds.join(','),
              showDistrictTranslation: true,
              status: PublishTagType.published,
            }),
          );
        }
      }
    }

    if (campaign?.pk) {
      if (
        // campaign.isAllFollowCouponTemplate ||
        // campaign.isFollowCouponTemplateStores
        campaign.campaignType !== CampaignType.generalMessageCampaign &&
        campaign.campaignType !== CampaignType.fortuneBagCampaign
      ) {
        if (campaign?.linkedCoupon?.pk) {
          dispatch(
            createAction('storeModel/getAllList')({
              couponTemplateIn: campaign?.linkedCoupon?.pk,
              showDistrictTranslation: true,
              status: PublishTagType.published,
            }),
          );
        }
      } else {
        dispatch(
          createAction('storeModel/getAllList')({
            campaignIn: campaign.pk,
            showDistrictTranslation: true,
            status: PublishTagType.published,
          }),
        );
      }
    }

    if (campaign?.offerTypeLabel?.label) {
      dispatch(
        createAction('offerTypeLabel/getOneDetail')({
          id: campaign?.offerTypeLabel.value.pk,
        }),
      );
    }

    // dispatch({ type: 'campaignList/getStarLimit' });
  }, [campaign, linkCouponDetail]);

  useEffect(() => {
    const logolabels = getTranslationField({
      language,
      field: 'logoLabels',
      campaign,
    });
    if (logolabels?.length > 0) {
      const logolabelsPKs = logolabels.map((val) => val.pk);

      const tempLogoLabelList = [];

      allLogoLabels.forEach((item) => {
        if (tempLogoLabelList.length === logolabelsPKs.length) {
          return;
        }

        if (logolabelsPKs.indexOf(item.pk) >= 0) {
          let icon = item.icon;
          let name = item.name;
          if (language.code !== 'en') {
            icon = item?.translations?.edges?.filter(
              (l) => l && l.node?.language === language.code,
            )?.[0]?.node?.icon;
            name = item?.translations?.edges?.filter(
              (l) => l && l.node?.language === language.code,
            )?.[0]?.node?.name;
          }
          if (name) {
            tempLogoLabelList.push({ icon, name });
          }
        }
      });

      setLanguageLogoLabels(tempLogoLabelList);
    } else {
      setLanguageLogoLabels([]);
    }
  }, [language, allLogoLabels]);

  const onScroll = (e) => {
    if (!isMobile) {
      const mainHeight = document.querySelector(
        'div.offer-basic-detail-container',
      ).offsetHeight;

      const detailElement = document.querySelector(
        'div.detail-right.detail-box',
      );
      if (detailElement) {
        const contentHeight = detailElement.offsetHeight;

        if (e.target.documentElement.scrollTop > mainHeight - contentHeight) {
          detailElement.style.top = mainHeight - contentHeight + 'px';
        } else {
          detailElement.style.top = e.target.documentElement.scrollTop + 'px';
        }
      }
    }

    // setScrollTop(e.target.documentElement.scrollTop);
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    if (!isLoading && !storeLoading) {
      const swiperContainer = document.querySelector('.swiper-container');

      if (swiperContainer) {
        swiperContainer.appendChild(
          document.querySelector('div.swiper-pagination'),
        );
      }
    }
  }, [isLoading, storeLoading]);

  // console.log('loading: ', isLoading, storeLoading);
  if (isLoading || storeLoading) {
    return <Loading customClass={'common-full-height'} />;
  }

  const genericTermsAndConditionsContent = getTranslationField({
    language,
    field: 'genericTermsAndConditionsContent',
    campaign,
  });

  if (!campaign?.translations?.[language.code]?.generalName) {
    return (
      <div
        className="offer-basic-detail-container"
        style={{ marginTop: 100, textAlign: 'center' }}
      >
        campaign not has this language
      </div>
    );
  }
  // console.log('@@1881: ', campaign);
  return (
    <>
      <div className="offer-basic-detail-container">
        {isMobile ? (
          <OfferBasicDetailMb
            campaign={campaign}
            language={language}
            offerRuleTabSelected={offerRuleTabSelected}
            setOfferRuleTabSelected={setOfferRuleTabSelected}
            stores={
              language.code === 'en'
                ? linkedStores
                : linkedStores.filter(
                    (item) =>
                      item &&
                      item.translations.edges.filter(
                        (val) =>
                          val.node.language === language.code &&
                          val.node.name !== '',
                      ).length > 0,
                  )
            }
            languageLogoLabels={languageLogoLabels}
            starPerheadLimit={
              starPerheadLimit > 9
                ? starPerheadLimit
                : t(starPerheadLimit?.toString(), { locale: language.code })
            }
            offerTypeLabel={getTranslationField({
              language,
              field: 'name',
              data: offerTypeLabel,
            })}
          />
        ) : (
          <OfferBasicDetailPc
            campaign={campaign}
            language={language}
            offerRuleTabSelected={offerRuleTabSelected}
            setOfferRuleTabSelected={setOfferRuleTabSelected}
            stores={
              language.code === 'en'
                ? linkedStores
                : linkedStores.filter(
                    (item) =>
                      item &&
                      item.translations.edges.filter(
                        (val) =>
                          val.node.language === language.code &&
                          val.node.name !== '',
                      ).length > 0,
                  )
            }
            languageLogoLabels={languageLogoLabels}
            starPerheadLimit={
              starPerheadLimit > 9
                ? starPerheadLimit
                : t(starPerheadLimit?.toString(), { locale: language.code })
            }
            offerTypeLabel={getTranslationField({
              language,
              field: 'name',
              data: offerTypeLabel,
            })}
          />
        )}
      </div>
      {/* <div className="more-offers-container">
        <div className="more-offers-bg-shadow"></div>
        <div className="more-offers-wrapper"></div>
      </div> */}
      <div className="generic-tac-content-container">
        {genericTermsAndConditionsContent ? (
          <div className="generic-tac-content">
            <div className="generic-tac-title">
              {getTranslationField({
                language,
                field: 'genericTermsAndConditionsTitle',
                campaign,
              }) ||
                i18n.t('preview_detail_generalt', { locale: language?.code })}
            </div>
            <div
              className={`generic-tac-desc-wrapper${
                moregenericTermsAndConditionsContent ? '-auto' : ''
              }`}
            >
              <CustomViewer
                className="generic-tac-desc"
                initValue={getTranslationField({
                  language,
                  field: 'genericTermsAndConditionsContent',
                  campaign,
                })}
              />
            </div>
            {moregenericTermsAndConditionsContent ? null : (
              <div className="more-mask-layer">
                <div
                  className="more-btn"
                  onClick={() =>
                    setMoregenericTermsAndConditionsContent(
                      !moregenericTermsAndConditionsContent,
                    )
                  }
                  style={campaign.isStarred ? { color: starColor } : {}}
                >
                  {moregenericTermsAndConditionsContent
                    ? i18n.t('hide_genericterms_condition_content', {
                        locale: language?.code,
                      })
                    : i18n.t('more_genericterms_condition_content', {
                        locale: language?.code,
                      })}
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div>
      {isMobile ? <div style={{ width: '100%', height: '50px' }}></div> : null}
    </>
  );
}

export default DHKPreviewBody;
