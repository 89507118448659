import {
  getCustomerData,
  CUSTOMER_PAGE_DATA_TYPE,
} from '../services/DashboardCustomerAPIHelper';
import { apiWithResponseHandle } from './LoadingUtil';
import { parseCustomerChartData } from './DashboardModel';

const parseChartData = (originalData, key, basekey) => {
  const { periods, points } = originalData?.[key] || {};
  const { value } = originalData?.[basekey] || {};
  console.log('parseChartData:', periods, points);
  const data = [];
  if (!periods?.length || !points?.length) {
    return [];
  }
  if (!value) {
    return [];
  }
  points.forEach((item, index) => {
    const period = periods[index];
    if (period) {
      const lastPoint = Math.floor((item / value) * 1000);
      data.push({
        period: period,
        percentage: lastPoint / 1000,
        point: item,
      });
    }
  });
  return data;
};

const initState = {
  customers: {},
  customerLoading: true,
  [CUSTOMER_PAGE_DATA_TYPE.INTEREST_PREFERENCE]: null,
  [CUSTOMER_PAGE_DATA_TYPE.CAMPAIGN_CATEGORY]: null,
  [CUSTOMER_PAGE_DATA_TYPE.ATTRIBUTE_TAGE]: null,
  startDate: null,
  endDate: null,
  attributeTagOfTimesCouponAcquiredChart: {
    loading: true,
    data: [],
    filterTags: [],
  },
};

export default {
  namespace: 'dashboardCustomer',
  state: initState,
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clearState(state, { payload }) {
      return initState;
    },
  },
  effects: {
    getTablePagedData: [
      function* ({ payload }, { put, select }) {
        const { startDate, endDate } = yield select((state) => ({
          startDate: state.dashboardCustomer.startDate,
          endDate: state.dashboardCustomer.endDate,
        }));
        if (!endDate || !startDate) {
          return;
        }
        const tableKey = payload.tableKey;
        const serviceArgs = [
          getCustomerData,
          { startDate, endDate, ...payload },
        ];
        function* updateTableData(data) {
          yield put({
            type: 'updateState',
            payload: {
              [tableKey]: data,
            },
          });
        }
        function* onSuccess(data) {
          console.log('@getTablePagedData: ', data);
          const analyticData = data?.analyticsData || {};
          yield updateTableData(analyticData[tableKey]);
        }
        function* onFailed(data) {
          console.log('@getTablePagedData failed: ', data);
          yield updateTableData({ totalCount: 0, tableData: [] });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed, onFailed);
      },
    ],

    getAttributeChart: [
      function* ({ payload }, { put, select }) {
        const { startDate, endDate } = yield select((state) => ({
          startDate: state.dashboardCustomer.startDate,
          endDate: state.dashboardCustomer.endDate,
        }));
        // const startDate = payload.startDate;
        // const endDate = payload.endDate;

        const { campaginTypes, isStarred } = yield select((state) => ({
          campaginTypes:
            state.dashboard.campaginAttributeTagsFilter?.selectedCampaignType,
          isStarred: state.dashboard.campaginAttributeTagsFilter?.isStarred,
        }));
        if (!endDate || !startDate) {
          return;
        }
        console.log('getAttributeTagChart:', payload);
        yield put({
          type: 'updateState',
          payload: {
            attributeTagOfTimesCouponAcquiredChart: {
              loading: true,
              filterTags: payload.filterTags || [],
            },
          },
        });
        const serviceArgs = [
          getCustomerData,
          {
            startDate,
            endDate,
            ...payload,
            isAttribute: true,
            campaginTypes,
            isStarred,
          },
        ];
        function* updateTableData(data) {
          yield put({
            type: 'updateState',
            payload: {
              attributeTagOfTimesCouponAcquiredChart: { data, loading: false },
            },
          });
        }
        function* onSuccess(data) {
          const analyticData = data?.analyticsData || {};
          const chartData = parseChartData(
            analyticData,
            'attributeTagOfTimesCouponAcquiredChart',
            'totalNumberOfTimesCouponAcquired',
          );
          console.log('getAttributeChart:', chartData);
          yield updateTableData(chartData);
        }
        function* onFailed(data) {
          yield updateTableData([]);
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed, onFailed);
      },
      { type: 'takeLatest' },
    ],

    getCustomerAnalyticData: [
      function* ({ payload }, { call, select, put }) {
        // const { campaginTypes, isStarred } = yield select((state) => ({
        //   campaginTypes:
        //     state.dashboard.couponRedeemFilter?.selectedCampaignType,
        //   isStarred: state.dashboard.couponRedeemFilter?.isStarred,
        // }));

        const serviceArgs = [getCustomerData, { ...payload }];
        yield put({
          type: 'updateState',
          payload: { customerLoading: true, ...payload },
        });
        function* onSuccess(data) {
          console.log('@getPreferencesChart: ', data);
          const analyticData = data?.analyticsData || {};
          if (!analyticData) {
            return;
          }
          const interestChart = parseChartData(
            analyticData,
            'interestPreferenceOfCustomersChart',
            'totalNumberOfCustomersWithInterestPreference',
          );
          // const categoryChart = parseChartData(
          //   analyticData,
          //   'campaignCategoryOfTimesCouponAcquiredChart',
          //   'totalNumberOfTimesCouponAcquired',
          // );

          const ageChart = analyticData?.totalNumberOfCustomersAgeChart?.map(
            (item) => {
              return {
                period: item?.name,
                point: item?.count,
                percentage: analyticData?.totalNumberOfCustomersWithAge?.value
                  ? Math.floor(
                      (item?.count /
                        analyticData?.totalNumberOfCustomersWithAge?.value) *
                        1000,
                    ) / 1000
                  : 0,
              };
            },
          );
          const newCustomerChartData = parseCustomerChartData(
            analyticData.totalNumberOfNewCustomersChart,
          );
          const customers = {
            ...analyticData,
            interestChart,
            // categoryChart,
            ageChart,
            newCustomerChartData: newCustomerChartData,
          };
          yield put({
            type: 'updateState',
            payload: {
              customers,
              customerLoading: false,
            },
          });
        }
        function* onFailed(data) {
          console.log('@Get dashboard data failed: ', data);
          yield put({
            type: 'updateState',
            payload: {
              customerLoading: false,
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed, onFailed);

        if (payload?.isChart) {
          yield put({
            type: 'getAttributeChart',
            payload: payload,
          });

          yield put({
            type: 'getCategoryAnalyticData',
            payload: payload,
          });
          return;
        }

        const pageTableValues = Object.values(CUSTOMER_PAGE_DATA_TYPE);
        for (let i = 0; i < pageTableValues.length; i++) {
          yield put({
            type: 'getTablePagedData',
            payload: {
              ...payload,
              tableKey: pageTableValues[i],
              orderBy: 'rank',
            },
          });
        }
      },
      { type: 'takeLatest' },
    ],
    getCategoryAnalyticData: [
      function* ({ payload }, { call, select, put }) {
        const { startDate, endDate } = yield select((state) => ({
          startDate: state.dashboardCustomer.startDate,
          endDate: state.dashboardCustomer.endDate,
        }));
        const { campaginTypes, isStarred } = yield select((state) => ({
          campaginTypes:
            state.dashboard.campaginCategoryFilter?.selectedCampaignType,
          isStarred: state.dashboard.campaginCategoryFilter?.isStarred,
        }));
        if (!endDate || !startDate) {
          return;
        }

        const serviceArgs = [
          getCustomerData,
          {
            startDate,
            endDate,
            ...payload,
            campaginTypes,
            isStarred,
            isCategory: true,
          },
        ];
        yield put({
          type: 'updateState',
          payload: { customerLoading: true, ...payload },
        });
        function* onSuccess(data) {
          console.log('@getPreferencesChart: ', data);
          const analyticData = data?.analyticsData || {};
          if (!analyticData) {
            return;
          }

          const categoryChart = parseChartData(
            analyticData,
            'campaignCategoryOfTimesCouponAcquiredChart',
            'totalNumberOfTimesCouponAcquired',
          );

          yield put({
            type: 'updateState',
            payload: {
              categoryChart,
              customerLoading: false,
            },
          });
        }
        function* onFailed(data) {
          console.log('@Get dashboard data failed: ', data);
          yield put({
            type: 'updateState',
            payload: {
              customerLoading: false,
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed, onFailed);

        if (payload?.isChart) {
          yield put({
            type: 'getAttributeChart',
            payload: payload,
          });
          return;
        }
        const pageTableValues = Object.values(CUSTOMER_PAGE_DATA_TYPE);
        for (let i = 0; i < pageTableValues.length; i++) {
          yield put({
            type: 'getTablePagedData',
            payload: {
              ...payload,
              tableKey: pageTableValues[i],
              orderBy: 'rank',
            },
          });
        }
      },
      { type: 'takeLatest' },
    ],
  },
};
