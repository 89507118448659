import React, { useEffect, useState } from 'react';
import './WorkingTeamSelectorPrompt.scss';
import BasePrompt from './BasePrompt';
import { Row } from 'react-bootstrap';
import CustomRadios from '../CustomRadios';
import { useDispatch, useSelector } from 'react-redux';
import BaseMutipleSelectorV2 from '../BaseMultipleSelectorV2';
import { createAction } from '../../../utils';
import ErrorFieldMessage from '../ErrorFieldMessage';

function WorkingTeamSelectorPrompt(props) {
  const dispatch = useDispatch();

  const title = props.title;
  const subTitle = props.subTitle;
  const otherBody = props.otherBody || {};

  const [selectedTeams, setSelectedTeams] = useState(props.selectedTeams || []);
  const [isAll, setIsAll] = useState(
    !props.selectedTeams || props.selectedTeams?.length <= 0,
  );
  const [checkResult, setCheckResult] = useState();

  const {
    allWorkingTeams,
    userWorkingTeams,
    relatedCampaigns,
    userIsSuperuser,
  } = useSelector((state) => ({
    allWorkingTeams: state.workingTeam.notPagedAllList,
    userWorkingTeams: state.users.workingTeams,
    relatedCampaigns: state.createCoupon.relatedCampaigns,
    userIsSuperuser: state.users.isSuperuser,
  }));

  useEffect(() => {
    if (props.show) {
      setSelectedTeams(props.selectedTeams);
      dispatch(createAction('workingTeam/getAllList')());
      setCheckResult();
    }
  }, [props.show]);

  const getOtherBody = () => {
    return (
      <>
        <label className={`create-section-label landing-title-margin-bottom `}>
          Select team
        </label>
        <Row
          style={{ marginLeft: '0px', marginRight: '0px', marginTop: '20px' }}
        >
          <CustomRadios
            onChange={(value) => {
              setIsAll(value);
            }}
            default={isAll}
            options={[
              {
                label: 'All admin users',
                value: true,
              },
              {
                label: 'Specific team',
                value: false,
              },
            ]}
          />
        </Row>

        {!isAll ? (
          <>
            <BaseMutipleSelectorV2
              size={['shorter']}
              searchPlaceholder="Search by name"
              data={{
                sourceData: allWorkingTeams,
                targetData: selectedTeams,
                targetChange: setSelectedTeams,
              }}
              custom={{
                customTitle: <label style={{ display: 'none' }}></label>,
                customItem: (value) => (
                  <>
                    <label className="slot-title">[{value.pk}]</label>
                    {value.name}
                  </>
                ),
                customFilter: (inputValue, option) => {
                  const inputIgnoreCase = inputValue?.toLowerCase();
                  const optionIgnoreCase = option?.name?.toLowerCase();
                  return optionIgnoreCase?.includes(inputIgnoreCase);
                  //   ||
                  //   option.pk == inputValue
                },
              }}
              hideTopSpace
            />
            <ErrorFieldMessage
              error={checkResult}
              message={
                checkResult?.notMatchAdmin
                  ? `Select at least one of the team which you belong to.`
                  : `${checkResult?.description}
              The common team that cannot be removed:
              ${checkResult?.hasLinkedTeams
                .map((item) => `[ID: ${item.pk}] ${item.name}`)
                .join(', ')}
              Linked campaign:
              ${checkResult?.sameTeamWithRelatedCampaigns
                .map((item) => `[ID: ${item.id}] ${item.name}`)
                .join(', ')}`
              }
              id="workingTeams"
              extra="newline prompt-error-message"
            />
          </>
        ) : null}
      </>
    );
  };

  return (
    <BasePrompt
      show={props.show}
      closeAction={props.onClose}
      description={`If the content has selected ‘All admin users’, everyone can view, edit and apply for this ${title.toLowerCase()}.
      If the content has selected working team, only selected teams can view, edit and apply this ${title}.`}
      rightButton={{
        text: 'Done',
        action: () => {
          console.log('@@133: ', userWorkingTeams);
          const selectedTeamsIds = selectedTeams?.map((item) => item.pk);
          if (
            !userIsSuperuser &&
            !isAll &&
            userWorkingTeams.filter(
              (item) => selectedTeamsIds.indexOf(item.pk) != -1,
            ).length <= 0
          ) {
            setCheckResult({ notMatchAdmin: true });
            return;
          }

          if (
            props.title === 'Coupon set' &&
            !props.isCreate &&
            !isAll &&
            selectedTeams.length > 0
          ) {
            //check coupon related campagin whether in selectedTeams

            const notHasSameTeamRelatedCampaigns = relatedCampaigns?.filter(
              (item) =>
                item &&
                item.campaignWorkingTeamIds?.length > 0 &&
                item.campaignWorkingTeamIds?.filter((val) => {
                  return selectedTeamsIds.indexOf(val) != -1;
                })?.length <= 0,
            );

            if (notHasSameTeamRelatedCampaigns?.length > 0) {
              console.log('@@165: ', notHasSameTeamRelatedCampaigns);
              if (props.selectedTeams?.length > 0) {
                const hasLinkedTeams = props.selectedTeams?.filter((item) => {
                  console.log(
                    '@@',
                    item.pk,
                    relatedCampaigns,
                    relatedCampaigns?.filter((val) => {
                      return (
                        val && val.campaignWorkingTeamIds?.indexOf(item.pk) >= 0
                      );
                    }).length,
                  );
                  return (
                    relatedCampaigns?.filter(
                      (val) =>
                        val &&
                        val.campaignWorkingTeamIds?.indexOf(item.pk) >= 0,
                    ).length > 0
                  );
                });
                const hasLinkedTeamsIds = hasLinkedTeams.map((item) => item.pk);
                const sameTeamWithRelatedCampaigns = relatedCampaigns?.filter(
                  (item) =>
                    item &&
                    item.campaignWorkingTeamIds?.length > 0 &&
                    item.campaignWorkingTeamIds?.filter((val) => {
                      return hasLinkedTeamsIds.indexOf(val) >= 0;
                    })?.length > 0,
                );

                console.log(
                  notHasSameTeamRelatedCampaigns,
                  sameTeamWithRelatedCampaigns,
                  hasLinkedTeams,
                );

                setCheckResult({
                  description:
                    'The team which is common amongs the linked campaign or goodie bag cannot be removed.',
                  hasLinkedTeams,
                  sameTeamWithRelatedCampaigns,
                });
              } else {
                // const hasLinkedTeams = notHasSameTeamRelatedCampaigns
                //   .map((item) => item.campaignWorkingTeamIds)
                //   .flat();

                let commonIds = [];

                notHasSameTeamRelatedCampaigns
                  .map((item) => item.campaignWorkingTeamIds)
                  .forEach((element) => {
                    if (
                      element.length === 1 &&
                      commonIds.indexOf(element[0]) === -1
                    ) {
                      commonIds.push(element[0]);
                    } else if (
                      element.length > 1 &&
                      element.filter((item) => commonIds.indexOf(item) != -1)
                        .length <= 0
                    ) {
                      commonIds = commonIds.concat(element);
                    }
                  });

                setCheckResult({
                  description:
                    'The change can not be proceed. Since the coupon set has been linked to a coupon campaign. The working team of the coupon set should at least have one common working team with the linked coupon campaign.',
                  hasLinkedTeams: allWorkingTeams.filter(
                    (item) => commonIds.indexOf(item.pk) != -1,
                  ), //[...new Set(hasLinkedTeams)],
                  sameTeamWithRelatedCampaigns: notHasSameTeamRelatedCampaigns,
                });
              }

              return;
            }

            //check coupon related campaign related goodiebag whether in selectedTeams
          }
          props.doneAction(isAll ? [] : selectedTeams);
          props.onClose();
        },
        // requires: PermissionCodes.addExportjob,
        disabled: !isAll && selectedTeams?.length <= 0,
      }}
      title={'Select related working team (Optional)'}
      otherBody={getOtherBody}
    />
  );
}

export default WorkingTeamSelectorPrompt;
