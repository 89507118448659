import { createModel } from './BaseModel';
import {
  getOfferTypeLabelList,
  getOfferTypeLabel,
  deleteOfferTypeLabels,
  createOrUpdateOfferTypeLabel,
} from '../services/OfferTypeLabelAPIHelper';
import { LanguageConfig, SESSION_KEYS } from '../config/CustomEnums';

const parseDetail = (data) => {
  const relatedCampaignNames = data.relatedCampaigns?.map((item) => item.name);
  const translations = { en: { name: data.name } };
  const temp = data.translations.edges.forEach((item) => {
    translations[item.node.language || LanguageConfig.english] = {
      pk: item.node.pk,
      name: item.node.name,
    };

    return item;
  });

  return {
    ...data,
    relatedCampaignNames,
    displayRelatedCampaigns: relatedCampaignNames?.join(', '),
    translations,
  };
};

export default createModel({
  namespace: 'offerTypeLabel',
  params: {
    sessionKey: SESSION_KEYS.OFFER_TYPE_SESSION_KEY,
    dataKey: SESSION_KEYS.OFFER_TYPE_DATA_SESSION_KEY,
    listAPI: getOfferTypeLabelList,
    parse: (data) =>
      data?.offerTypeLabels.edges.map((item) => parseDetail(item.node)),
    deleteAPI: deleteOfferTypeLabels,
    pkNode: 'OfferTypeLabelNode',
    detailAPI: getOfferTypeLabel,
    parseDetail: (data) => parseDetail(data.offerTypeLabel),
    createdNewParse: (data) => parseDetail(data.createOfferTypeLabel.node),
  },
  states: {
    listDisplayFields: [
      { displayName: 'ID', fieldName: 'pk', orderField: 'pk' },
      {
        displayName: 'Offer type name',
        fieldName: 'name',
      },
      { displayName: 'Related campaign', fieldName: 'displayRelatedCampaigns' },
    ],
    detail: {
      sourceName: '',
      parameter: '',
    },
    // createdNew: {}
  },
  reducers: {},
  effects: {
    createOrUpdate: [
      function* ({ payload }, { call, select, put }) {
        const detail = yield select((state) => state.offerTypeLabel.detail);

        const data = {
          name: payload.translations.en.name,
          translations: Object.keys(payload.translations)
            .filter((key) => key !== 'en')
            .map((key) => {
              const translation = {
                language: key,
                name: payload.translations[key].name,
              };
              console.log('@@72: ', key, detail?.translations);
              if (key in (detail?.translations || {})) {
                translation.id = detail.translations[key].pk;
              }
              return translation;
            }),
        };

        if (payload.id) {
          data.id = payload.pk;
          data.translations = [...data.translations];
        } else {
          const languages = yield select((state) => state.language.allList);
          const filledTranslations = languages
            .map((item) => {
              if (item.code in payload.translations) {
                return;
              }

              return {
                language: item.code,
                name: '',
              };
            })
            .filter((item) => item !== undefined);
          data.translations = [...data.translations, ...filledTranslations];
        }
        console.log('@@90: ', payload.translations, data, payload.id);
        const serviceArgs = [createOrUpdateOfferTypeLabel, data];
        yield put({
          type: 'responseCreateOrUpdate',
          payload: { serviceArgs, isCreate: payload.id ? false : true },
        });
      },
      { type: 'takeLatest' },
    ],
  },
});
