import { createModel } from './BaseModel';
import {
  getSearchSettings,
  updateSearchSettings,
} from '../services/SearchSettingApiHelper';
import { apiWithResponseHandle } from './LoadingUtil';
import { APIStatus } from '../config/CustomEnums';
import { convertPKToId } from '../utils';

const getInitialState = () => ({
  // searchSettingsData: {},
  // detail: null,
  hasUpdatedDefaultValues: false,
  formHasSubmitted: false,
  errorFields: [],
});

export default createModel({
  namespace: 'searchSetting',
  params: {
    initState: {},
    dataKey: 'searchSetting',
  },
  states: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },
  },
  effects: {
    getOneDetail: [
      function* ({ payload }, { all, select, put }) {
        yield put({
          type: 'updateState',
          payload: { apiStatus: APIStatus.calling },
        });
        const afterGet = payload?.afterAction || (() => {});
        const serviceArgs = [getSearchSettings];
        function* onSuccess(data) {
          const detail = data.campaignSearchFieldSettings;
          afterGet(detail);
          yield all([
            put({
              type: 'updateState',
              payload: {
                detail: detail,
                hasUpdatedDefaultValues: true,
                apiStatus: APIStatus.success,
              },
            }),
          ]);
        }
        function* onError(err) {
          yield put({
            type: 'updateState',
            payload: { apiStatus: APIStatus.failed },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],
    updateSearchSettings: [
      function* ({ payload }, { put, call }) {
        yield put({
          type: 'updateState',
          payload: { apiStatus: APIStatus.calling },
        });
        function* onSuccess(data) {
          yield put({
            type: 'updateState',
            payload: {
              apiStatus: APIStatus.success,
              formHasSubmitted: true,
            },
          });
        }
        function* onError(err) {
          yield put({
            type: 'updateState',
            payload: { apiStatus: APIStatus.failed },
          });
        }
        const serviceArgs = [updateSearchSettings, payload];
        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],
  },
});
