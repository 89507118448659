import React, { useState, useEffect } from 'react';
import './App.scss';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';
import 'antd/dist/antd.css';
import { slide as Menu } from 'react-burger-menu';
import { withResizeDetector } from 'react-resize-detector';
import SideMenuNav from './components/bars/SideMenuNav';
import Header from './components/bars/Header';
import routes from './config/Routes';
import Login from './containers/user/Login';
import PreviewCampaignPage from './containers/engagement/campaign/preview/PreviewCampaignPage';
import DHKPreviewCampaignPage from './containers/engagement/campaign/preview/DHKPreviewCampaignPage';
import { useSelector, useDispatch } from 'react-redux';
import SaveDiscardToast from './components/base/SaveDiscardToast';
import { PermissionCodes } from './config/PermissionCodes';
import { saveToSessionStorage, checkHasPermission } from './utils';
import { FIRST_TIME_ENTER_CAMPAIGN } from './models/CampaignListModel';
import LoginMiddleware from './containers/user/LoginMiddleware';

function PageRoute({ width, height }) {
  const [openMenu, setOpenMenu] = useState(true);
  const user = useSelector((state) => state.users);
  const [appRoutes, setAppRoutes] = useState();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: 'navBars/updateState', payload: { openMenu: openMenu } });
  }, [openMenu]);

  useEffect(() => {
    setAppRoutes(routes(user));

    if (checkHasPermission(user, PermissionCodes.viewGoodieBag)) {
      console.log('@checkExistsCouponOutOfStockFortuneBag first');
      dispatch({ type: 'goodieBags/checkExistsCouponOutOfStockFortuneBag' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.isSuperuser, user.userPermissions]);

  useEffect(() => {
    const pathname = location.pathname;
    if (!pathname.includes('campaigns')) {
      saveToSessionStorage(FIRST_TIME_ENTER_CAMPAIGN, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    let interval;
    if (
      checkHasPermission(user, PermissionCodes.viewGoodieBag) &&
      user?.isLogin
    ) {
      interval = setInterval(() => {
        dispatch({ type: 'goodieBags/checkExistsCouponOutOfStockFortuneBag' });
      }, 1000 * 60 * 60);
    } else if (!user?.isLogin && !user?.isLoginBySaml) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (!user.isLogin && !user.isLoginBySaml) {
    return <Redirect to="/login" />;
  }
  return (
    <div className="main-container">
      <div className={`main-content ${openMenu ? 'main-content-padding' : ''}`}>
        <Header openMenu={openMenu} closeMenuAction={() => setOpenMenu(true)} />
        <Switch>{appRoutes}</Switch>
      </div>
      <div
        className={`sidebar-container ${
          openMenu ? 'sidebar-container-full-width' : ''
        }`}
      >
        <SideMenuNav
          closeMenuAction={() => {
            setOpenMenu(false);
          }}
        />
      </div>
    </div>
  );
}

const SizeDetectPageRoute = withResizeDetector(PageRoute);

function App() {
  return (
    <BrowserRouter>
      <SaveDiscardToast />
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/login_res">
          <LoginMiddleware />
        </Route>
        <Route
          path={[
            '/campaigns/preview/:id/:channel',
            '/campaigns/preview/:channel',
          ]}
        >
          <PreviewCampaignPage />
        </Route>
        <Route
          path={[
            '/campaigns/dhk_preview/:id/:channel',
            '/campaigns/dhk_preview/:channel',
          ]}
        >
          <DHKPreviewCampaignPage />
          {/* <PreviewDHKTC /> */}
        </Route>
        <Route>
          <SizeDetectPageRoute />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
