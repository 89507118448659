import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
import { hasError, errorMessage } from '../base/ErrorFieldMessage';
import { CustomTitleWithDropDown } from '../customer/CustomTitleWithDropDown';

function GeneralSection({ couponLabelList }) {
  const { watch, setValue, formState, control } = useFormContext();
  const couponLabel = watch('couponLabel');

  return (
    <>
      <label className="create-section-title">General</label>
      <CustomTitleWithDropDown
        title={'Coupon label (optional)'}
        source={couponLabelList}
        defaultValue={couponLabel}
        setValue={(value) =>
          setValue('couponLabel', value, { shouldDirty: true })
        }
        loadMoreAction={'couponLabel/getList'}
        filterAction={'couponLabel/getList'}
        errors={[]}
        errorName={'couponLabel'}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  couponLabelList: state.couponLabel.allList,
});

export default connect(mapPropsToState)(GeneralSection);
