import modelExtend from 'dva-model-extend';
import { apiWithResponseHandle } from './LoadingUtil';
import { createModel } from './BaseModel';
import {
  getTargetMarketList,
  getTargetMarket,
  createOrUpdateTargetMarket,
  deleteTargetMarkets,
} from '../services/TargetMarketAPIHelper';
import { convertNumberToCursor } from '../utils';
import { SESSION_KEYS } from '../config/CustomEnums';

const parseDetail = (data) => {
  const supportLanguages = data.languages?.edges?.map(
    (item) =>
      `${item.node.name}${
        item.node.parameter ? '(' + item.node.parameter + ')' : ''
      }`,
  );
  const relatedChannel = data.channels?.map((item) => item.name);
  // const relatedCampaign = data.relatedCampaigns?.map((item) => item.name);
  const relatedCampaign = data.relatedCampaignNames
    ? data.relatedCampaignNames.map((item) => item)
    : data.relatedCampaigns?.map((item) => item.name);
  return {
    ...data,
    sourceName: data.name,
    name: `${data.name}${data.parameter ? '(' + data.parameter + ')' : ''}`,
    displaySupportedLanguages: supportLanguages?.join(', '),
    displayRelatedChannels: relatedChannel?.join(', '),
    marketDisplayRelatedCampaigns: relatedCampaign,
    supportLanguages,
    relatedChannel,
    relatedCampaign,
    languages: data.languages?.edges?.map(
      (marketLanguage) => marketLanguage.node,
    ),
    selectedLanguages:
      data.languages?.edges?.length > 0
        ? data.languages?.edges.map((item) => ({
            pk: item.node.pk,
            name: `${item.node.name}${
              item.node.parameter ? '(' + item.node.parameter + ')' : ''
            }`,
          }))
        : [{ pk: 1, name: 'English(EN)', disabled: true }],
  };
};

export const parseDetailGroupByChannel = (data, filteredChannels) => {
  const result = data?.targetMarkets?.edges?.map((element) => {
    return {
      pk: element.node.pk,
      name: element.node.name,
      // channel: item,
      channels: element.node.channels,
      languages: element.node.languages?.edges.map((item) => item.node),
    };
  });

  // let result = [];
  // for (var i = 0; i < data?.targetMarkets?.edges?.length; i++) {
  //   const element = data?.targetMarkets?.edges[i].node;
  //   result = result.concat(
  //     element.channels
  //       .filter((item) =>
  //         filteredChannels?.length > 0
  //           ? filteredChannels?.includes(item.pk)
  //           : true,
  //       )
  //       .map((item) => ({
  //         pk: element.pk,
  //         name: element.name,
  //         channel: item,
  //         languages: element.languages?.edges.map((item) => item.node),
  //       })),
  //   );
  // }
  return result;
};

export default createModel({
  namespace: 'targetMarket',
  states: {
    listDisplayFields: [
      { displayName: 'ID', fieldName: 'pk', orderField: 'pk' },
      {
        displayName: 'Market name(Parameter)',
        fieldName: 'name',
      },
      {
        displayName: 'Support Language(Parameter)',
        fieldName: 'displaySupportedLanguages',
      },
      {
        displayName: 'Related Channel',
        fieldName: 'displayRelatedChannels',
      },
      {
        displayName: 'Related campaign',
        fieldName: 'marketDisplayRelatedCampaigns',
      },
    ],
    detail: {
      sourceName: '',
      parameter: '',
    },
  },
  reducers: {},
  params: {
    initState: {
      detail: {
        selectedLanguages: [{ pk: 1, name: 'English(EN)', disabled: true }],
      },
    },
    sessionKey: SESSION_KEYS.TARGET_MARKET_SESSION_KEY,
    dataKey: SESSION_KEYS.TARGET_MARKET_DATA_SESSION_KEY,
    listAPI: getTargetMarketList,
    parse: (data) =>
      data?.targetMarkets.edges.map((item) => parseDetail(item.node)),
    deleteAPI: deleteTargetMarkets,
    pkNode: 'TargetMarketNode',
    detailAPI: getTargetMarket,
    parseDetail: (data) => parseDetail(data.targetMarket),
    objectKey: 'targetMarkets',
  },
  effects: {
    createOrUpdate: [
      function* ({ payload }, { call, select, put }) {
        const data = {
          name: payload.sourceName,
          parameter: payload.parameter,
          languages: payload.selectedLanguages.map((item) => item.pk),
        };
        if (payload.id) {
          data.id = payload.pk;
        }

        const serviceArgs = [createOrUpdateTargetMarket, data];
        yield put({
          type: 'responseCreateOrUpdate',
          payload: { serviceArgs, isCreate: !payload.id },
        });
      },
      { type: 'takeLatest' },
    ],
    getListGroupByChannel: [
      function* ({ payload }, { call, select, put }) {
        const page = payload.page || 1;
        const pageCursor = convertNumberToCursor((page - 1) * 20 - 1);

        const serviceArgs = [getTargetMarketList, pageCursor, payload];
        function* onSuccess(data) {
          yield put({
            type: 'updateListData',
            payload: {
              ...payload,
              data: parseDetailGroupByChannel(data, payload.channel),
              totalCount: data.targetMarkets.totalCount,
              pageInfo: data.targetMarkets.pageInfo,
            },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
});
