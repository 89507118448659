import {
  LanguageConfig,
  CampaignType,
  APIStatus,
  SESSION_KEYS,
} from '../config/CustomEnums';
import {
  createCampaign,
  getCampaign,
  updateCampaign,
  getOneCoupon,
  getPreviewCampaign,
  getCampaignParticipationRecordsList,
} from '../services/CampaignAPIHelper';
import { defaultStep, getNewStepConfig } from './StepBarUtil';
import {
  getFileNameFromUrl,
  saveToSessionStorage,
  removeFromSessionStorage,
  getObjectFromSessionStorage,
  createAction,
  addDomainToImage,
  convertPKToId,
  insertKeyToTranslations,
  convertNumberToCursor,
  convertCursorToNumber,
} from '../utils';
import { apiWithResponseHandle } from './LoadingUtil';
import {
  CREATE_CAMPAIGN_CATEGORY_FAILD,
  CREATE_CAMPAIGN_CATEGORY_SUCCESS,
} from './CreateCampaignCategoryModel';
import { getTypeDisplay } from './CampaignListModel';
import { createModel } from './BaseModel';
import { parseDetailGroupByChannel } from './TargetMarketModel';
import { IMAGE_TYPES } from './UploadFilesModel';
import { getTermsAndConditionsTemplateDate } from './TermsAndConditionsModel';
import { getGoodieBagDetail } from '../services/GoodieBagsApiHelper';
import { parseGoodieBagFromCampaign } from './GoodieBagsModel';
import { t } from 'i18n-js';
import { getCountryCodeList } from '../services/CountryCodeApiHelper';
import { getLogoLabelList } from '../services/LogoLabelAPIHelper';
import { getAttributeTagList } from '../services/AttributeTagAPIHelper';
import { getCampaignCategoryList } from '../services/CampaignCategoryAPIHelper';

export const isGoodieBagType = (campaignType) => {
  return (
    campaignType === CampaignType.cashVoucherCampaign ||
    campaignType === CampaignType.giftCampaign ||
    campaignType === CampaignType.fortuneBagForGoodieBagCampaign
  );
};

export const isShowCouponRelated = (campaignType) => {
  return (
    isGoodieBagType(campaignType) ||
    campaignType === CampaignType.couponsCampaign
  );
};

export const getCampaignDisplayName = (campaignType, usedInDetail = false) => {
  switch (campaignType) {
    case CampaignType.generalMessageCampaign:
      return 'General Message Campaign';
    case CampaignType.couponsCampaign:
      return 'Coupon Campaign';
    case CampaignType.fortuneBagCampaign:
      return 'Fortune Bag Campaign';
    case CampaignType.cashVoucherCampaign:
      return 'Cash voucher for Goodie bag';
    case CampaignType.giftCampaign:
      if (usedInDetail) {
        return 'Coupon campaign for Goodie bag';
      }
      return 'Goodie Bag Coupon';
    case CampaignType.fortuneBagForGoodieBagCampaign:
      return 'Goodie bag offer (Direct assign)';
    default:
      return '-';
  }
};

export const markdownUpdateField = {
  shortDescription: 'generalContent',
  offerContent: 'offerContent',
  instructionContent: 'instructionContent',
  merchantContent: 'merchantContent',
  genericContent: 'genericContent',
};

export const sessionDataKey = {
  objectKey: SESSION_KEYS.CREATE_CAMPAIGN_SESSION_KEY,
  stepEndKey: SESSION_KEYS.CREATE_CAMPAIGN_END_SESSION_KEY,
  fortuneCoupons: SESSION_KEYS.CREATE_FORTUNE_COUPONS,
};

export const GENERAL_TYPE = {
  customerGroup: 'Customer group',
  segment: 'Segment',
};

export const LINK_COUPON_STRING = 'linkedCouponInfo';

const isFollowTranslations = [
  'isFollowCouponTemplateStores',
  'isFollowCouponTemplateTourCard',
  'isFollowCouponTemplateName',
  'isFollowCouponTemplateShortDescription',
  'isFollowCouponTemplateCoverPhoto',
  'isFollowCouponTemplateDetailPhotos',
  'isFollowCouponTemplateLogoLabels',
  'isFollowCouponTemplateOfferSection',
  'isFollowCouponTemplateInstructionSection',
  'isFollowCouponTemplateMerchantTerms',
  'isFollowCouponTemplateGenericTerms',
];

const applyCouponToCampaign = ({
  getValues,
  setValue,
  languages = [],
  key,
}) => {
  const couponString = localStorage.getItem(LINK_COUPON_STRING);
  const newCoupon = JSON.parse(couponString || '{}');
  let couponKeyOne = '';
  let couponKeyTwo = '';
  switch (key) {
    case 'isFollowCouponTemplateName':
      couponKeyOne = 'generalName';
      break;
    case 'isFollowCouponTemplateShortDescription':
      couponKeyOne = 'shortDescription';
      break;
    case 'isFollowCouponTemplateCoverPhoto':
      couponKeyOne = 'coverPhoto';
      break;
    case 'isFollowCouponTemplateDetailPhotos':
      couponKeyOne = 'detailPhotos';
      break;
    case 'isFollowCouponTemplateLogoLabels':
      couponKeyOne = 'logoLabels';
      break;
    case 'isFollowCouponTemplateOfferSection':
      couponKeyOne = 'offerDetailTitle';
      couponKeyTwo = 'offerDetailContent';
      break;
    case 'isFollowCouponTemplateInstructionSection':
      couponKeyOne = 'instructionSectionTitle';
      couponKeyTwo = 'instructionSectionContent';
      break;
    case 'isFollowCouponTemplateMerchantTerms':
      couponKeyOne = 'merchantTermsAndConditionsTitle';
      couponKeyTwo = 'merchantTermsAndConditionsContent';

      break;
    case 'isFollowCouponTemplateGenericTerms':
      couponKeyOne = 'genericTermsAndConditionsTitle';
      couponKeyTwo = 'genericTermsAndConditionsContent';
      break;
    case 'isFollowCouponTemplateTourCard':
      const tourCards = getTourCard(newCoupon.tourCards?.edges);
      setValue('tourCards', tourCards);
      setValue('showTourCard', newCoupon.showTourCard);
      couponKeyOne = 'tourCardSectionTitle';
      break;
    default:
      break;
  }

  const translations = getTranslationForCampaign(newCoupon);
  languages.forEach((item) => {
    const language = item.code;
    if (!language) {
      return;
    }
    if (couponKeyOne) {
      const couponValueOne = translations?.[language]?.[couponKeyOne];
      console.log('campaignKeyOne:', couponKeyOne, key, couponValueOne);
      insertKeyToTranslations({
        getValues,
        setValue,
        language,
        key: couponKeyOne,
        value: couponValueOne,
      });
    }

    if (couponKeyTwo) {
      const couponValueTwo = translations?.[language]?.[couponKeyTwo];
      insertKeyToTranslations({
        getValues,
        setValue,
        language,
        key: couponKeyTwo,
        value: couponValueTwo,
      });
    }
  });
};

export const changeSwitch = ({
  getValues,
  setValue,
  languages,
  key,
  value,
}) => {
  console.log('languages:', languages);
  setValue(key, value, { shouldDirty: true });
  if (!!value) {
    applyCouponToCampaign({
      getValues,
      setValue,
      languages,
      key,
    });
  }
  const allSwitchOn = isAllSwitchOn({ getValues });
  console.log('allSwitchOn:', allSwitchOn);
  setValue('isAllFollowCouponTemplate', !!allSwitchOn);
};

export const switchAll = ({ getValues, setValue, isOn }) => {
  console.log('switchAll:');
  try {
    if (isOn) {
      const couponString = localStorage.getItem(LINK_COUPON_STRING);
      const newCoupon = JSON.parse(couponString);
      transferCouponContentToCampagin({
        newCoupon,
        setValue,
        getValues,
      });
    }
  } catch (e) {
    console.log('couponParseError: ', e);
  }
  console.log('switchAllcouponParseError: ', getValues());
  isFollowTranslations.forEach((item) => {
    setValue(item, isOn, {
      shouldDirty: true,
    });
  });
  setValue('isAllFollowCouponTemplate', isOn);
};

const isAllSwitchOn = ({ getValues }) => {
  const switchValues = getValues(isFollowTranslations) || [];
  console.log('switchValues:', switchValues);
  const isAllSwitched = switchValues.every((item) => !!item);
  return isAllSwitched;
};

const stepNames = ['Type', 'Content', 'Properties', 'Preview'];

export function fieldTransfer({ setValue, getValues }) {
  try {
    const couponString = localStorage.getItem(LINK_COUPON_STRING);
    const newCoupon = JSON.parse(couponString);
    if (!newCoupon) {
      return;
    }
    if (newCoupon.brand) {
      const brandSection = getBrandSectionForCampaign(newCoupon.brand);
      setValue('linkedBrand', brandSection.linkedBrand);
      setValue('brandSection', brandSection);
    }
    setValue('coupon_stock', newCoupon.stock || 0);
    const switchValues = getValues(isFollowTranslations) || [];
    const [selectedLanguages] = getValues(['selectedLanguages']) || [];
    switchValues.forEach((item, index) => {
      const fieldName = isFollowTranslations[index];
      if (!!item) {
        changeSwitch({
          getValues,
          setValue,
          languages: selectedLanguages,
          key: fieldName,
          value: item,
        });
      }
    });
  } catch (e) {
    console.log('fieldTransfer error: ', e);
  }
}

function transferCouponContentToCampagin({ newCoupon, setValue, getValues }) {
  if (!newCoupon) {
    return;
  }
  const translations = getTranslationForCampaign(newCoupon);
  console.log('translations:', translations);
  const languageKeys = Object.keys(translations || []);
  languageKeys.forEach((key) => {
    Object.keys(markdownUpdateField).forEach((item) => {
      insertKeyToTranslations({
        language: key,
        key: markdownUpdateField[item],
        value: true,
        setValue,
        getValues,
      });
    });
    const translation = translations[key];
    const translationKeys = Object.keys(translation || []);
    console.log('translationKeys:', translationKeys);
    translationKeys.forEach((valueKey) => {
      insertKeyToTranslations({
        language: key,
        key: valueKey,
        value: translation[valueKey],
        setValue,
        getValues,
      });
    });
  });
  if (newCoupon.tourCards) {
    const tourCards = getTourCard(newCoupon?.tourCards?.edges);
    setValue('tourCards', tourCards);
    setValue('showTourCard', newCoupon.showTourCard);
  }
}

function createNewItemAutoAdd(originData, newItems) {
  if (!newItems || !originData) {
    return;
  }
  console.log('newAttribute:', newItems);
  const newAttribute = newItems.newAttribute || {};
  const tags = newAttribute.tags || [];
  if (tags.length) {
    const addingAttributes = tags.map((item) => ({
      id: item.id,
      pk: item.pk,
      name: item.name,
      tagKey: {
        id: newAttribute.id,
        pk: newAttribute.pk,
        name: newAttribute.name,
      },
    }));
    console.log('tags:', addingAttributes);
    originData.attributeTags = [
      ...(originData.attributeTags || []),
      ...addingAttributes,
    ];
  }
}

export const saveDataToSessionStorage = (data = {}) => {
  saveToSessionStorage(sessionDataKey.objectKey, data);
};

export const removeDataFromSessionStorage = () => {
  removeFromSessionStorage(sessionDataKey.objectKey);
  removeFromSessionStorage(sessionDataKey.fortuneCoupons);
};

export const getImageUrl = (data) => {
  if (!data) {
    return '';
  }
  if (typeof data === 'string') {
    return data;
  }
  if (data.type !== IMAGE_TYPES.TYPE_URL) {
    return '';
  }
  return data.value;
};

export const getImageImage = (data) => {
  if (!data) {
    return null;
  }
  const url = getImageUrl(data);
  const imageName = getFileNameFromUrl(url);
  return imageName;
};

function getTranlationDataForCreateCampaign(campaign, language, update) {
  let languageField = {};
  const translation = campaign?.translations?.[language] || {};
  if (language !== LanguageConfig.english) {
    languageField = {
      language: language,
    };
    if (translation.pk && update) {
      languageField['id'] = translation.pk;
    }
  }
  console.log('@@388: ', language, t('book_now', { locale: language }));
  // const originalLandingButton = {
  //   [LanguageConfig.english]: 'Book now',
  //   [LanguageConfig.simplifiedChinese]: '馬上預定',
  //   [LanguageConfig.traditionalChinese]: '马上预定',
  // };

  const result = {
    ...languageField,
    shortDescription: translation.shortDescription,
    name: translation.generalName,
    instructionSectionTitle: translation.instructionSectionTitle,
    instructionSectionContent: translation.instructionSectionContent,
    detailSectionTitle: translation.detailSectionTitle,
    detailSectionContent: translation.detailSectionContent,
    merchantTermsAndConditionsTitle:
      translation.merchantTermsAndConditionsTitle,
    merchantTermsAndConditionsContent:
      translation.merchantTermsAndConditionsContent,
    genericTermsAndConditionsTitle: translation.genericTermsAndConditionsTitle,
    genericTermsAndConditionsContent:
      translation.genericTermsAndConditionsContent,
    coverPhoto: getImageImage(translation.coverPhoto) || null,
    detailPhoto1: getImageImage(translation.detailPhotos?.[0]) || null,
    detailPhoto2: getImageImage(translation.detailPhotos?.[1]) || null,
    detailPhoto3: getImageImage(translation.detailPhotos?.[2]) || null,
    detailPhoto4: getImageImage(translation.detailPhotos?.[3]) || null,
    tourCardSectionTitle: translation.tourCardSectionTitle,
    logoLabels: translation.logoLabels?.map((item) => item.pk),
    landingPageUrl: translation.landingPageUrl,
    landingPageButtonName: translation.landingPageUrl
      ? translation.landingPageButtonName || t('book_now', { locale: language })
      : t('book_now', { locale: language }),

    offerDetailTitle: translation.offerDetailTitle,
    offerDetailContent: translation.offerDetailContent,
    statementWordingForCountryCodeLimit:
      campaign?.countryCodes?.length > 0
        ? translation.statementWordingForCountryCodeLimit
        : '',
  };
  return result;
}

function getDataForPerTargetMarketLimit(data) {
  const targetMarketLimits = Object.keys(data || {}).map((marketPk) => {
    const marketLimit = {
      targetMarket: marketPk,
      shouldShowCouponStock: data[marketPk].displayLimit || false,
      perCampaignDownloadLimit:
        data[marketPk].overallLimit == 0
          ? 0
          : data[marketPk].overallLimit || null,
    };

    if (data[marketPk]?.perLimitPk) {
      marketLimit.id = data[marketPk]?.perLimitPk;
    }

    return marketLimit;
  });

  return targetMarketLimits;
}

function getDataForCreateCampaign(campaign, update) {
  const categories = campaign.categories?.map((category) => category.pk);
  let targetGroup = [];
  let segments = [];
  const generalType = campaign.generalType;
  if (generalType) {
    const groupLength = campaign.customerGroup?.length;
    const segmentsLength = campaign.segments?.length;
    if (generalType === GENERAL_TYPE.customerGroup && groupLength) {
      targetGroup = campaign.customerGroup?.map((group) => group.pk);
    } else if (generalType === GENERAL_TYPE.segment && segmentsLength) {
      segments = campaign.segments?.map((segment) => segment.pk);
    }
  }
  let earningRule = {};
  let coupon = {};
  const isCouponRelated = isShowCouponRelated(campaign.campaignType);
  if (isCouponRelated) {
    const couponCampaignPerChannelLimits = Object.keys(
      campaign.channelLimit || [],
    ).map((key) => {
      const data = {
        publishChannel: key === 'null' ? null : key,
        shouldShowCouponStock: campaign.channelLimit[key].displayLimit || false,
        enableMarketLimit:
          campaign.channelLimit[key].enableMarketLimit || false,
        perCampaignDownloadLimit:
          campaign.channelLimit[key].overallLimit == 0
            ? 0
            : campaign.channelLimit[key].overallLimit || null,
        campaignPerTargetMarketLimits: getDataForPerTargetMarketLimit(
          campaign.channelLimit[key].perTargetMarketLimit,
        ),
      };

      if (campaign.channelLimit[key].pk) {
        data.id = campaign.channelLimit[key].pk;
      }

      return data;
    });

    coupon = {
      couponCampaignTypeCouponTemplate: campaign.linkedCoupon.pk,
      couponCampaignTypePerHeadLimit: campaign.perHeadLimit || null,
      couponCampaignTypePerHeadDownloadLimit:
        campaign.perHeadDownloadLimit || 1,
      couponCampaignTypePerHeadOwnedLimit: campaign.perHeadOwnedLimit || null,
      couponCampaignTypePerHeadDownloadForEachTime:
        campaign.perHeadDownloadForEachTime || 1,
    };

    if (campaign.editAllocation) {
      coupon.couponCampaignPerChannelLimits = couponCampaignPerChannelLimits;
    }
  }
  if (campaign.campaignType === CampaignType.earningRulesCampaign) {
    earningRule = {
      earningCampaignTypeEarningRule: campaign.linkedEarningRules.pk,
    };
  }
  const formattedTranslation = getTranlationDataForCreateCampaign(
    campaign,
    LanguageConfig.english,
    update,
  );

  const otherTranslations = [];
  Object.keys(campaign?.translations || {}).forEach((language) => {
    if (language === LanguageConfig.english) {
      return;
    }
    otherTranslations.push(
      getTranlationDataForCreateCampaign(campaign, language, update),
    );
  });

  const publishChannels = campaign.channels?.filter((item) => !!item.pk) || [];

  const result = {
    type: campaign.campaignType,
    categories: categories,
    targetedCustomerGroups: targetGroup,
    targetedSegments: segments,
    isFeatured: campaign.isFeatured,
    isExclusive: campaign.isExclusive,
    haveLandingPage: campaign.haveLandingPage,
    isRedirectionCampaign: campaign.isRedirectionCampaign,
    brand: campaign.linkedBrand?.pk || null,
    goodieBag: campaign.goodieBag?.pk,
    goodieBagCampaignSlots: campaign.goodieBagCampaignSlots?.map(
      (item) => item.pk,
    ),
    goodieBagReward:
      campaign.goodieBagReward === '' ? null : campaign.goodieBagReward,
    stores: campaign.linkedStores?.map((item) => item.pk),
    offerTypeLabels: campaign.offerTypeLabel?.value?.pk || null,
    attributeTags: campaign.attributeTags?.map((item) => item.pk),
    accessCampaignCopyUrl: campaign.accessCampaignCopyUrl,
    isFollowGoodieBagVisiblePeriod: campaign.isFollowGoodieBagVisiblePeriod,
    isFollowGoodieBagActivePeriod: campaign.isFollowGoodieBagActivePeriod,
    translations: otherTranslations,
    ...formattedTranslation,
    editAllocation: campaign.editAllocation || false,
    ...coupon,
    ...earningRule,
    merchantTermsAndConditionsTemplate:
      campaign.merchantTermsAndConditionsTemplate?.pk || null,
    isFollowMerchantTermsAndConditionsTemplate:
      campaign.merchantTermsAndConditionsTemplate
        ? campaign.isFollowMerchantTermsAndConditionsTemplate
        : false,
    genericTermsAndConditionsTemplate:
      campaign.genericTermsAndConditionsTemplate?.pk || null,
    isFollowGenericTermsAndConditionsTemplate:
      campaign.genericTermsAndConditionsTemplate
        ? campaign.isFollowGenericTermsAndConditionsTemplate
        : false,
    isAllFollowCouponTemplate: campaign.isAllFollowCouponTemplate,
    isFollowCouponTemplateStores: campaign.isFollowCouponTemplateStores,
    isFollowCouponTemplateTourCard: campaign.isFollowCouponTemplateTourCard,
    isFollowCouponTemplateName: campaign.isFollowCouponTemplateName,
    isFollowCouponTemplateShortDescription:
      campaign.isFollowCouponTemplateShortDescription,
    isFollowCouponTemplateCoverPhoto: campaign.isFollowCouponTemplateCoverPhoto,
    isFollowCouponTemplateDetailPhotos:
      campaign.isFollowCouponTemplateDetailPhotos,
    isFollowCouponTemplateLogoLabels: campaign.isFollowCouponTemplateLogoLabels,
    isFollowCouponTemplateOfferSection:
      campaign.isFollowCouponTemplateOfferSection,
    isFollowCouponTemplateInstructionSection:
      campaign.isFollowCouponTemplateInstructionSection,
    isFollowCouponTemplateMerchantTerms:
      campaign.isFollowCouponTemplateMerchantTerms,
    isFollowCouponTemplateGenericTerms:
      campaign.isFollowCouponTemplateGenericTerms,
    applyCoverPhotoToAllLanguage: campaign.applyCoverPhotoToAllLanguage,
    applyDetailPhotosToAllLanguage: campaign.applyDetailPhotosToAllLanguage,
    publishChannels: publishChannels.map((item) => item.pk),
    targetMarkets: campaign.markets?.map((item) => item.pk),
    showTourCard: campaign.showTourCard,
    tourCards: campaign.tourCards?.map((item, index) => ({
      tourCard: item.pk,
      displayPriority: index + 1,
    })),
    isStarred: campaign.isStarred || false,
    showInWechatHomepage: campaign.showInWechatHomepage || false,

    showLowStockLabel: campaign.showLowStock,
    showOutOfStockLabel: campaign.showOutOfStock,

    workingTeams: campaign.workingTeams?.map((item) => item.pk),
    isAgeRestricted: campaign.inAgeRestricted || false,
    showCouponDownloadConfirmation:
      campaign.showCouponDownloadConfirmation || false,

    isEvent: campaign.isEvent || false,
    eventName: campaign.eventName,

    searchTags: campaign.searchTags,
    semTags: campaign.semTags,

    showStatementWordingForCountryCodeLimit:
      campaign?.countryCodes?.length > 0
        ? campaign?.showStatementWordingForCountryCodeLimit
        : false,
    countryCodes: campaign?.countryCodes?.map((item) => item.pk),
  };
  const isGoodieBag = isGoodieBagType(campaign.campaignType);

  if (isGoodieBag) {
    if (!campaign.isFollowGoodieBagActivePeriod) {
      result['endDate'] = campaign.activeEndDate;
      result['startDate'] = campaign.activeStartDate;
    }
    if (!campaign.isFollowGoodieBagVisiblePeriod) {
      result['displayEndDate'] = campaign.visibleEndDate;
      result['displayStartDate'] = campaign.visibleStartDate;
    }
  } else {
    if (!campaign.isAlwaysActivePeriod && !campaign.isRedirectionCampaign) {
      result['endDate'] = campaign.activeEndDate;
      result['startDate'] = campaign.activeStartDate;
    } else {
      result['endDate'] = null;
      result['startDate'] = campaign.creationDate || new Date();
    }
    if (!campaign.isAlwaysVisiblePeriod) {
      result['displayEndDate'] = campaign.visibleEndDate;
      result['displayStartDate'] = campaign.visibleStartDate;
    } else {
      result['displayEndDate'] = null;
      result['displayStartDate'] = campaign.creationDate || new Date();
    }
  }

  return result;
}

function getDataForCreateFortuneCampaign(originalCampaign, languageList) {
  const campaignData = {
    type: originalCampaign.campaignType,
    name: originalCampaign.translations.en.generalName,
    fortuneBagBrands: originalCampaign.isPublished
      ? originalCampaign.batchOta?.map((item) => item.pk)
      : originalCampaign.ota.map((item) => item.pk),
    assignRemaining: originalCampaign.assignRemain,
    workingTeams: originalCampaign.workingTeams?.map((item) => item.pk),
    countryCodes: originalCampaign?.countryCodes?.map((item) => item.pk),
    fortuneBagCampaignTypePerHeadLimit:
      originalCampaign.fortuneBagCampaignTypePerHeadLimit || null,
  };
  const fortuneCouponSlots = originalCampaign.fortunes;
  if (!originalCampaign.isAlwaysActivePeriod) {
    campaignData['endDate'] = originalCampaign.activeEndDate;
    campaignData['startDate'] = originalCampaign.activeStartDate;
  } else {
    campaignData['endDate'] = null;
    campaignData['startDate'] = new Date();
  }
  campaignData['translations'] = languageList
    .filter((language) => language.code !== 'en')
    .map((language) => {
      const translationData = {
        language: language.code,
        name: originalCampaign.translations[language.code]?.generalName,
      };
      if (originalCampaign.translations[language.code]?.pk) {
        translationData.id = originalCampaign.translations[language.code].pk;
      }

      return translationData;
    });

  if (originalCampaign.assignRemain) {
    campaignData.couponSlots = [];
    campaignData.fortuneBagQuantity = 0;
  } else {
    campaignData.couponSlots = Object.keys(fortuneCouponSlots).map((key) => ({
      index:
        key.replace('new', '') === '-Infinity' ? '0' : key.replace('new', ''),
      linkCoupons: fortuneCouponSlots[key].map((item) => item.pk),
    }));
    campaignData.fortuneBagQuantity = originalCampaign.batchBagQuantity;
  }

  return campaignData;
}

function getBrandSectionForCampaign(brandSection) {
  if (!brandSection) {
    return { brandSection: {}, linkedBrand: {} };
  }
  const translations = {};
  const translationEdges = brandSection?.translations?.edges || [];
  translationEdges.forEach((trans) => {
    const language = trans.node?.language;
    if (language) {
      translations[language] = { name: trans.node.name };
    }
  });
  return {
    brandSection: {
      id: brandSection.id,
      pk: brandSection.pk,
    },
    linkedBrand: {
      id: brandSection.id,
      pk: brandSection.pk,
      name: brandSection.name,
      icon: brandSection.icon,
      translations,
    },
  };
}

function getCouponAndEarningRuleForCampaign(couponSection) {
  if (!couponSection) {
    return {};
  }
  console.log('@@472: ', couponSection);
  return {
    ...couponSection,
    id: couponSection.id,
    pk: couponSection.pk,
    name: couponSection.name,
    stock: couponSection.stock,
    tourCards: couponSection.tourCards?.edges?.map(
      (item) => item.node.tourCard,
    ),
    workingTeamIds: couponSection.workingTeams?.edges?.map(
      (item) => item.node.pk,
    ),
  };
}

function getCategoriesAndTargetGroupForCampaign(section) {
  if (!section?.edges?.length) {
    return [];
  }
  const targetGroup = section?.edges?.map((edge) => {
    const target = edge.node;
    return target;
  });
  return targetGroup;
}

const getDetailPhotos = (node) => {
  const photos = [];
  if (node.detailPhoto1) {
    photos.push(addDomainToImage(node.detailPhoto1));
  }
  if (node.detailPhoto2) {
    photos.push(addDomainToImage(node.detailPhoto2));
  }

  if (node.detailPhoto3) {
    photos.push(addDomainToImage(node.detailPhoto3));
  }
  if (node.detailPhoto4) {
    photos.push(addDomainToImage(node.detailPhoto4));
  }
  return photos;
};

function translationAssemble(node, isCampaign, allData) {
  if (!node) {
    return {};
  }
  // console.log(
  //   '@@742: ',
  //   allData,
  //   allData.applyCoverPhotoToAllLanguage,
  //   allData.coverPhoto,
  // );

  const language = node.language || LanguageConfig.english;

  let merchantTermsAndConditionsTitle = node.merchantTermsAndConditionsTitle;
  let merchantTermsAndConditionsContent =
    node.merchantTermsAndConditionsContent;
  let genericTermsAndConditionsTitle = node.genericTermsAndConditionsTitle;
  let genericTermsAndConditionsContent = node.genericTermsAndConditionsContent;

  if (!isCampaign) {
    const merchantTermsAndConditions =
      language === LanguageConfig.english
        ? allData.merchantTermsAndConditionsTemplate
        : allData.merchantTermsAndConditionsTemplate?.translations?.edges?.filter(
            (item) => item.node.language === language,
          )?.[0]?.node;
    const genericTermsAndConditions =
      language === LanguageConfig.english
        ? allData.genericTermsAndConditionsTemplate
        : allData.genericTermsAndConditionsTemplate?.translations?.edges?.filter(
            (item) => item.node.language === language,
          )?.[0]?.node;
    if (merchantTermsAndConditions) {
      merchantTermsAndConditionsTitle = merchantTermsAndConditions.title;
      merchantTermsAndConditionsContent = merchantTermsAndConditions.content;
    }
    if (genericTermsAndConditions) {
      genericTermsAndConditionsTitle = genericTermsAndConditions.title;
      genericTermsAndConditionsContent = genericTermsAndConditions.content;
    }
  }
  const data = {
    shortDescription: node.shortDescription,

    instructionSectionTitle: node.instructionSectionTitle,
    instructionSectionContent: node.instructionSectionContent,
    detailSectionTitle: node.detailSectionTitle,
    detailSectionContent: node.detailSectionContent,
    coverPhoto: allData.applyCoverPhotoToAllLanguage
      ? addDomainToImage(allData.coverPhoto)
      : addDomainToImage(node.coverPhoto),
    detailPhotos: allData.applyDetailPhotosToAllLanguage
      ? getDetailPhotos(allData)
      : getDetailPhotos(node),
    merchantTermsAndConditionsTitle,

    merchantTermsAndConditionsContent,
    genericTermsAndConditionsTitle,
    genericTermsAndConditionsContent,

    logoLabels: node.logoLabels?.edges?.map((item) => item.node),

    landingPageUrl: node.landingPageUrl,
    landingPageButtonName: node.landingPageButtonName,

    offerDetailTitle: node.offerDetailTitle,
    offerDetailContent: node.offerDetailContent,
    generalName: node.name,
    tourCardSectionTitle: node.tourCardSectionTitle,
  };
  if (isCampaign) {
    data.id = node.id;
    data.pk = node.pk;
    data.statementWordingForCountryCodeLimit =
      node.statementWordingForCountryCodeLimit;
  }
  if (
    allData?.isFollowMerchantTermsAndConditionsTemplate &&
    allData?.merchantTermsAndConditionsTemplate?.pk
  ) {
    data.merchantTermsAndConditionsTitle =
      allData?.merchantTermsAndConditionsTemplateDate?.translations?.[
        language
      ]?.title;
    data.merchantTermsAndConditionsContent =
      allData?.merchantTermsAndConditionsTemplateDate?.translations?.[
        language
      ]?.content;
  }
  if (
    allData?.isFollowGenericTermsAndConditionsTemplate &&
    allData?.genericTermsAndConditionsTemplate?.pk
  ) {
    data.genericTermsAndConditionsTitle =
      allData?.genericTermsAndConditionsTemplateDate?.translations?.[
        language
      ]?.title;
    data.genericTermsAndConditionsContent =
      allData?.genericTermsAndConditionsTemplateDate?.translations?.[
        language
      ]?.content;
  }

  return {
    [language]: data,
  };
}

function getTranslationForCampaign(campaign, isCampaign) {
  const enTranslation =
    translationAssemble(campaign, isCampaign, campaign) || {};
  const chineseTranslation = campaign?.translations?.edges?.map((item) => {
    const data = translationAssemble(item.node, isCampaign, campaign) || {};
    return data;
  });
  const reducedTranslation = chineseTranslation?.reduce(function (obj, item) {
    return {
      ...obj,
      ...item,
    };
  }, {});
  return {
    ...enTranslation,
    ...reducedTranslation,
  };
}

function patchCouponToCampaign(newCoupon, campaign) {
  if (!campaign || !newCoupon) {
    return;
  }
  const newCampaign = campaign || {};
  newCampaign.linkedCoupon = newCoupon;
  if (newCoupon.brand) {
    const brandSection = getBrandSectionForCampaign(newCoupon.brand);
    newCampaign.linkedBrand = brandSection.linkedBrand;
    newCampaign.brandSection = brandSection;
  }
  const couponTranslations = getTranslationForCampaign(newCoupon) || {};
  console.log('@@884: ', couponTranslations);
  const languages = Object.keys(couponTranslations);
  newCampaign.coupon_stock = newCoupon.stock || 0;
  if (newCampaign.isFollowCouponTemplateTourCard) {
    newCampaign.showTourCard = newCoupon.showTourCard;
    newCampaign.tourCards = getTourCard(newCoupon.tourCards?.edges || []);
  }
  newCampaign.linkedStores = newCoupon.applicableStores?.edges?.map(
    (item) => item.node,
  );
  newCampaign.totalNubmerOfAvailableCoupons =
    newCoupon.totalNubmerOfAvailableCoupons || 0;
  newCampaign.totalNubmerOfGeneratedCoupons =
    newCoupon.totalNubmerOfGeneratedCoupons || 0;
  newCampaign.totalNubmerOfAcquiredCoupons =
    newCoupon.totalNubmerOfAcquiredCoupons || 0;
  newCampaign.totalNubmerOfExpiredCoupons =
    newCoupon.totalNubmerOfExpiredCoupons || 0;
  newCampaign.totalNubmerOfDeactivatedCoupons =
    newCoupon.totalNubmerOfDeactivatedCoupons || 0;

  languages.forEach((language) => {
    const languageObject = {};
    const couponObject = couponTranslations?.[language] || {};
    if (newCampaign.isFollowCouponTemplateTourCard) {
      languageObject.tourCardSectionTitle = couponObject.tourCardSectionTitle;
    }
    if (newCampaign.isFollowCouponTemplateName) {
      languageObject.generalName = couponObject.generalName;
    }
    if (newCampaign.isFollowCouponTemplateShortDescription) {
      languageObject.shortDescription = couponObject.shortDescription;
    }
    if (newCampaign.isFollowCouponTemplateCoverPhoto) {
      languageObject.coverPhoto = couponObject.coverPhoto;
    }
    if (newCampaign.isFollowCouponTemplateDetailPhotos) {
      languageObject.detailPhotos = couponObject.detailPhotos;
    }
    if (newCampaign.isFollowCouponTemplateLogoLabels) {
      languageObject.logoLabels = couponObject.logoLabels;
    }
    if (newCampaign.isFollowCouponTemplateOfferSection) {
      languageObject.offerDetailTitle = couponObject.offerDetailTitle;
      languageObject.offerDetailContent = couponObject.offerDetailContent;
    }

    if (newCampaign.isFollowCouponTemplateInstructionSection) {
      languageObject.instructionSectionTitle =
        couponObject.instructionSectionTitle;
      languageObject.instructionSectionContent =
        couponObject.instructionSectionContent;
    }

    if (newCampaign.isFollowCouponTemplateMerchantTerms) {
      languageObject.merchantTermsAndConditionsTitle =
        couponObject.merchantTermsAndConditionsTitle;
      languageObject.merchantTermsAndConditionsContent =
        couponObject.merchantTermsAndConditionsContent;
    }
    if (newCampaign.isFollowCouponTemplateGenericTerms) {
      languageObject.genericTermsAndConditionsTitle =
        couponObject.genericTermsAndConditionsTitle;
      languageObject.genericTermsAndConditionsContent =
        couponObject.genericTermsAndConditionsContent;
    }
    console.log(
      'newCampaign.translations?.[language]:',
      newCoupon?.translations,
    );
    console.log('languageObject:', languageObject);
    newCampaign.translations = {
      ...newCampaign.translations,
      [language]: {
        ...(newCampaign.translations?.[language] || {}),
        ...languageObject,
      },
    };
  });
  console.log('newCoupon:', newCoupon);
  console.log('newCampaign:', newCampaign);
  return newCampaign;
}

function getMillisecondsFromDate(date) {
  if (!date) {
    return '';
  }
  const momentTime = new Date(date);
  return momentTime;
}

function getLanguagesFromMarket(markets) {
  const langauges = [];

  if (!markets) {
    return [];
  }
  markets.forEach((element) => {
    const elementLanguages = element.languages || [];
    elementLanguages.forEach((language) => {
      langauges.push(language);
    });
  });
  return [...new Map(langauges.map((item) => [item['code'], item])).values()];
}

const getTourCard = (data) => {
  return data?.map(({ node }) => {
    const translations = {};
    const edges = node.tourCard?.translations?.edges || [];
    edges.forEach(({ node }) => {
      translations[node.language] = node;
    });
    return {
      ...node.tourCard,
      displayPriority: node.displayPriority,
      campaignTourCardId: node.pk,
      translations,
    };
  });
};

function getPerTargetMarketLimit(data) {
  let targetMarketLimits = {};
  (data || []).forEach((item, index) => {
    const market = item.node;
    targetMarketLimits = {
      ...targetMarketLimits,
      [market?.targetMarket?.pk]: {
        indexOf: index,
        pk: market?.targetMarket?.pk,
        name: market?.targetMarket?.name,
        perLimitPk: market?.pk,
        displayLimit: market?.shouldShowCouponStock,
        overallLimit: market?.perCampaignDownloadLimit,
      },
    };
  });

  return targetMarketLimits;
}

function assembleCampaign(campaignData) {
  console.log('@468', campaignData);
  if (!campaignData) {
    return {};
  }
  campaignData.brandSection = campaignData.brand;

  const linkedCoupon = getCouponAndEarningRuleForCampaign(
    campaignData.couponCampaignTypeCouponTemplate,
  );
  const linkedEarningRules = {
    ...campaignData.earningCampaignTypeEarningRule,
    ...getCouponAndEarningRuleForCampaign(
      campaignData.earningCampaignTypeEarningRule,
    ),
  };
  const categories = getCategoriesAndTargetGroupForCampaign(
    campaignData.categories,
  );
  const targetGroup = getCategoriesAndTargetGroupForCampaign(
    campaignData.targetedCustomerGroups,
  );
  const segments = getCategoriesAndTargetGroupForCampaign(
    campaignData.targetedSegments,
  );

  const markets = parseDetailGroupByChannel(
    campaignData,
    campaignData.publishChannels?.edges?.map((item) => item.node.pk),
  );
  const selectedLanguages = getLanguagesFromMarket(markets);

  campaignData.merchantTermsAndConditionsTemplateDate =
    getTermsAndConditionsTemplateDate(
      campaignData?.merchantTermsAndConditionsTemplate,
    );
  campaignData.genericTermsAndConditionsTemplateDate =
    getTermsAndConditionsTemplateDate(
      campaignData?.genericTermsAndConditionsTemplate,
    );
  const translations = getTranslationForCampaign(campaignData, true);

  const brandSection = getBrandSectionForCampaign(campaignData.brandSection);
  const startTime = getMillisecondsFromDate(campaignData.startDate);
  const endTime = getMillisecondsFromDate(campaignData.endDate);
  const visibleStartTime = getMillisecondsFromDate(
    campaignData.displayStartDate,
  );
  const visibleEndTime = getMillisecondsFromDate(campaignData.displayEndDate);
  const publicationTime = getMillisecondsFromDate(campaignData.publicationDate);
  let generalType = GENERAL_TYPE.customerGroup;
  if (segments && segments.length > 0) {
    generalType = GENERAL_TYPE.segment;
  }
  const channelLimit = {};
  (campaignData.perChannelLimits?.edges || []).forEach((item) => {
    channelLimit[item.node.publishChannel?.pk || null] = {
      pk: item.node?.pk,
      channelId: item.node?.publishChannel?.pk,
      name: item.node?.publishChannel?.name || 'e-Solution',
      overallLimit: item.node?.perCampaignDownloadLimit,
      enableMarketLimit: item.node?.enableMarketLimit,
      displayLimit: item.node?.shouldShowCouponStock,
      perTargetMarketLimit: getPerTargetMarketLimit(
        item.node?.perTargetMarketLimits?.edges,
      ),
    };
  });

  const tourCards = getTourCard(campaignData.tourCards?.edges);

  const fortuneBagMerchants = [].concat.apply(
    [],
    campaignData?.batches?.edges?.map((batch) =>
      batch.node.fortuneBagBrands?.edges?.map((item) => item.node),
    ),
  );

  const fortunes = {};
  const batchesFortuneBag = {};
  (campaignData?.batches?.edges || []).forEach((batch) => {
    batchesFortuneBag[batch.node.index] = {
      ...batch.node,
      linkedCoupons: [].concat.apply(
        [],
        batch.node.couponSlots?.edges.map((slot) =>
          slot.node.linkCoupons?.edges?.map((coupon) => coupon.node),
        ),
      ),
    };
  });
  if (!campaignData?.isPublished) {
    (campaignData?.batches?.edges[0]?.node.couponSlots?.edges || []).forEach(
      (slot) => {
        fortunes[slot.node.index] = slot.node.linkCoupons?.edges?.map(
          (item) => ({
            ...item.node,
            workingTeamIds: item.node.workingTeams?.edges?.map(
              (val) => val.node.pk,
            ),
          }),
        );
      },
    );

    saveToSessionStorage('fortuneBagCoupons', fortunes);
  }
  const goodieBag = parseGoodieBagFromCampaign(campaignData.goodieBag);
  return {
    id: campaignData.id,
    pk: campaignData.pk,
    isRedirectionCampaign: campaignData.isRedirectionCampaign,
    creationDate: campaignData.creationDate,
    campaignType: campaignData.type,
    isExclusive: campaignData.isExclusive,
    isFeatured: campaignData.isFeatured,
    showInWechatHomepage: campaignData.showInWechatHomepage,
    isAlwaysActivePeriod: !endTime,
    activeStartDate: startTime,
    activeEndDate: endTime || startTime,
    isAlwaysVisiblePeriod: !visibleEndTime,
    visibleStartDate: visibleStartTime,
    visibleEndDate: visibleEndTime || visibleStartTime,
    publicationDate: publicationTime,
    perHeadLimit: campaignData.couponCampaignTypePerHeadLimit,
    requiredPoints: campaignData.couponCampaignTypeRequiredPoints,
    isPublished: campaignData.isPublished,
    linkedBrand: brandSection.linkedBrand,
    brandSection: brandSection,
    isFollowGoodieBagActivePeriod: campaignData.isFollowGoodieBagActivePeriod,
    isFollowGoodieBagVisiblePeriod: campaignData.isFollowGoodieBagVisiblePeriod,
    goodieBag,
    goodieBagReward: campaignData.goodieBagReward,
    applyCoverPhotoToAllLanguage: campaignData.applyCoverPhotoToAllLanguage,
    applyDetailPhotosToAllLanguage: campaignData.applyDetailPhotosToAllLanguage,
    linkedStores: campaignData.stores?.edges?.map((item) => item.node),
    isAllFollowCouponTemplate: campaignData.isAllFollowCouponTemplate,
    isFollowCouponTemplateStores:
      campaignData.isAllFollowCouponTemplate ||
      campaignData.isFollowCouponTemplateStores,
    isFollowCouponTemplateTourCard:
      campaignData.isAllFollowCouponTemplate ||
      campaignData.isFollowCouponTemplateTourCard,
    isFollowCouponTemplateName:
      campaignData.isAllFollowCouponTemplate ||
      campaignData.isFollowCouponTemplateName,
    isFollowCouponTemplateShortDescription:
      campaignData.isAllFollowCouponTemplate ||
      campaignData.isFollowCouponTemplateShortDescription,
    isFollowCouponTemplateCoverPhoto:
      campaignData.isAllFollowCouponTemplate ||
      campaignData.isFollowCouponTemplateCoverPhoto,
    isFollowCouponTemplateDetailPhotos:
      campaignData.isAllFollowCouponTemplate ||
      campaignData.isFollowCouponTemplateDetailPhotos,
    isFollowCouponTemplateLogoLabels:
      campaignData.isAllFollowCouponTemplate ||
      campaignData.isFollowCouponTemplateLogoLabels,
    isFollowCouponTemplateOfferSection:
      campaignData.isAllFollowCouponTemplate ||
      campaignData.isFollowCouponTemplateOfferSection,
    isFollowCouponTemplateInstructionSection:
      campaignData.isAllFollowCouponTemplate ||
      campaignData.isFollowCouponTemplateInstructionSection,
    isFollowCouponTemplateMerchantTerms:
      campaignData.isAllFollowCouponTemplate ||
      campaignData.isFollowCouponTemplateMerchantTerms,
    isFollowCouponTemplateGenericTerms:
      campaignData.isAllFollowCouponTemplate ||
      campaignData.isFollowCouponTemplateGenericTerms,
    merchantTermsAndConditionsTemplate: getTermsAndConditionsTemplateDate(
      campaignData.merchantTermsAndConditionsTemplate,
    ),
    isFollowMerchantTermsAndConditionsTemplate:
      campaignData.isAllFollowCouponTemplate ||
      campaignData.isFollowMerchantTermsAndConditionsTemplate,
    genericTermsAndConditionsTemplate: getTermsAndConditionsTemplateDate(
      campaignData.genericTermsAndConditionsTemplate,
    ),
    isFollowGenericTermsAndConditionsTemplate:
      campaignData.isAllFollowCouponTemplate ||
      campaignData.isFollowGenericTermsAndConditionsTemplate,
    linkedEarningRules,
    linkedCoupon,
    sourceLinkedCoupon: linkedCoupon,
    categories,
    customerGroup: targetGroup || [],
    translations,
    generalType,
    segments: segments || [],
    typeDisplay: getTypeDisplay(campaignData.type),
    order: campaignData.displayPriority,
    offerTypeLabel: {
      label: campaignData.offerTypeLabels?.name,
      value: campaignData.offerTypeLabels,
    },
    attributeTags: campaignData.attributeTags?.edges?.map((item) => item.node),
    perHeadDownloadLimit: campaignData.couponCampaignTypePerHeadDownloadLimit,
    perHeadOwnedLimit: campaignData.couponCampaignTypePerHeadOwnedLimit,
    perHeadDownloadForEachTime:
      campaignData.couponCampaignTypePerHeadDownloadForEachTime || 1,
    selectedLanguages,
    markets,
    channels: campaignData.publishChannels?.edges?.map((item) => item.node),
    CouponCampaignPerChannelLimit: campaignData.perChannelLimits,
    acquiredCouponsFromThisCampaign:
      campaignData.acquiredCouponsFromThisCampaign,
    channelLimit,
    fortunes,
    batchesFortuneBag,
    haveLandingPage: campaignData.haveLandingPage,
    showTourCard: campaignData.showTourCard,
    tourCards,
    accessCampaignCopyUrl: campaignData.accessCampaignCopyUrl,
    ota: fortuneBagMerchants?.map((item) => ({
      ...item,
      handleOption: {
        name: item.handleOption?.toLowerCase().replace('_', ' '),
        value: item.handleOption,
      },
    })),
    coupon_stock: linkedCoupon?.stock,
    // fortuneBagQuantity: campaignData.fortuneBagQuantityInfo?.quantityOfGenerate,
    // fortuneBagRemained:
    //   campaignData.fortuneBagQuantityInfo?.quantityOfRemaining,
    isStarred: campaignData.isStarred,

    showLowStock: campaignData.showLowStockLabel,
    showOutOfStock: campaignData.showOutOfStockLabel,

    workingTeams: campaignData.workingTeams?.edges?.map((item) => item.node),
    inAgeRestricted: campaignData.isAgeRestricted,
    showCouponDownloadConfirmation: campaignData.showCouponDownloadConfirmation,

    isEvent: campaignData.isEvent,
    eventName: campaignData.isEvent ? campaignData.eventName : '',

    searchTags: campaignData.searchTags,
    semTags: campaignData.semTags,

    goodieBagCampaignSlots: campaignData.campaignSlots?.edges?.map(
      (item) => item.node,
    ),

    showStatementWordingForCountryCodeLimit:
      campaignData.showStatementWordingForCountryCodeLimit,
    countryCodes: campaignData.countryCodes?.edges?.map((item) => item.node),
    fortuneBagCampaignTypePerHeadLimit:
      campaignData.fortuneBagCampaignTypePerHeadLimit,
    groups: campaignData?.groups?.edges?.map((item) => item.node),
    displayGroups: campaignData?.groups?.edges
      ?.map((item) => `[ID: ${item?.node?.pk}] ${item?.node?.name}`)
      .join(','),
  };
}

function getTranlation() {
  return {
    generalName: '',
    shortDescription: '',
    coverPhoto: '',
    squareCoverPhoto: '',
    detailPhotos: [],
    instructionSectionTitle: '',
    instructionSectionContent: '',
    detailSectionTitle: '',
    detailSectionContent: '',
    tourCardSectionTitle: '',
  };
}

const campaignInit = () => ({
  id: null,
  pk: null,
  fortunes: {
    new1: [],
  },
  applyCoverPhotoToAllLanguage: false,
  isRedirectionCampaign: false,
  applyDetailPhotosToAllLanguage: false,
  isAllFollowCouponTemplate: false,
  isFollowCouponTemplateStores: false,
  isFollowCouponTemplateTourCard: false,
  isFollowCouponTemplateName: false,
  isFollowCouponTemplateShortDescription: false,
  isFollowCouponTemplateCoverPhoto: false,
  isFollowCouponTemplateDetailPhotos: false,
  isFollowCouponTemplateLogoLabels: false,
  isFollowCouponTemplateOfferSection: false,
  isFollowCouponTemplateInstructionSection: false,
  isFollowCouponTemplateMerchantTerms: false,
  isFollowCouponTemplateGenericTerms: false,
  batchFortunes: { new1: [] },
  showTourCard: true,
  tourCards: [],
  editAllocation: false,
  isFollowGoodieBagActivePeriod: true,
  isFollowGoodieBagVisiblePeriod: true,
  isExclusive: false,
  creationDate: null,
  isFeatured: false,
  campaignType: '',
  isAlwaysActivePeriod: false,
  activeStartDate: null,
  activeEndDate: null,
  isAlwaysVisiblePeriod: false,
  visibleStartDate: null,
  visibleEndDate: null,
  overallLimit: null,
  perHeadLimit: null,
  requiredPoints: 0,
  displayOverLimit: false,
  linkedCoupon: null,
  linkedEarningRules: null,
  linkedBrand: null,
  order: null,
  typeDisplay: null,
  categories: [],
  customerGroup: [],
  segments: [],
  generalType: GENERAL_TYPE.customerGroup,
  landingPageButtonName: 'Book now',
  translations: {
    [LanguageConfig.english]: { landingPageButtonName: 'Book now' },
  },
  perHeadDownloadLimit: 1,
  perHeadDownloadForEachTime: 1,
  showCouponDownloadConfirmation: false,
});

const getInitialState = () => ({
  campaign: campaignInit(),
  linkedCoupon: {},
  hasUpdatedDefaultValues: false,
  formHasSubmitted: false,
  errorFields: [],
  stepConfig: defaultStep(stepNames),
  currentStep: 0,
  languageTag: LanguageConfig.english,
  createStatus: APIStatus.none,
  linkCouponDetail: false,
  campaignPublish: APIStatus.none,
  couponAcquired: {},
});

// function saveDataToSessions(oldCampaign) {
//   if (oldCampaign?.pk) {
//     return;
//   }
//   const localCampaign = getObjectFromSessionStorage(sessionDataKey.objectKey);

//   const localEn = localCampaign?.translations?.[LanguageConfig.english] || {};
//   const localTraditional =
//     localCampaign?.translations?.[LanguageConfig.traditionalChinese] || {};
//   const localSimplified =
//     localCampaign?.translations?.[LanguageConfig.simplifiedChinese] || {};

//   const finalCampaign = {
//     ...localCampaign,
//     ...oldCampaign,
//     translations: {
//       [LanguageConfig.english]: {
//         ...localEn,
//         ...oldCampaign.translations[LanguageConfig.english],
//       },
//       [LanguageConfig.traditionalChinese]: {
//         ...localTraditional,
//         ...oldCampaign.translations[LanguageConfig.traditionalChinese],
//       },
//       [LanguageConfig.simplifiedChinese]: {
//         ...localSimplified,
//         ...oldCampaign.translations[LanguageConfig.simplifiedChinese],
//       },
//     },
//   };
//   saveToSessionStorage(sessionDataKey.objectKey, finalCampaign);
// }

export default createModel({
  namespace: 'createCampaign',
  states: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    updateCampaign(state, { payload }) {
      console.log('updateCampaign: ', payload);
      const campaign = {
        ...state.campaign,
        ...payload,
      };
      return { ...state, campaign: campaign };
    },
    // updateCampaignTranslation(state, { payload }) {
    //   const campaign = {
    //     ...state.campaign,
    //     translations: {
    //       [LanguageConfig.english]: {
    //         ...(state.campaign.translations[LanguageConfig.english] || {}),
    //         ...(payload.translations[LanguageConfig.english] || {}),
    //       },
    //       [LanguageConfig.simplifiedChinese]: {
    //         ...(state.campaign.translations[LanguageConfig.simplifiedChinese] ||
    //           {}),
    //         ...(payload.translations[LanguageConfig.simplifiedChinese] || {}),
    //       },
    //       [LanguageConfig.traditionalChinese]: {
    //         ...(state.campaign.translations[
    //           LanguageConfig.traditionalChinese
    //         ] || {}),
    //         ...(payload.translations[LanguageConfig.traditionalChinese] || {}),
    //       },
    //     },
    //   };
    //   if (!payload.notSave) {
    //     saveToSessionStorage(sessionDataKey.objectKey, campaign);
    //     saveDataToSessions(campaign);
    //   }

    //   return { ...state, campaign: campaign };
    // },

    assembleCampaign(state, { payload }) {
      const { newItems } = payload;
      const campaignData = assembleCampaign(payload.campaign);

      const linkedCoupon = campaignData.linkedCoupon || {};
      const promptShowed = Object.keys(linkedCoupon).length > 0;
      const campaign = {
        ...state.campaign,
        ...campaignData,
        translations: {
          ...state.campaign.translations,
          ...campaignData.translations,
        },
      };
      createNewItemAutoAdd(campaign, newItems);
      return {
        ...state,
        promptShowed,
        campaign: campaign,
        hasUpdatedDefaultValues: true,
      };
    },

    loadCampaignFromCookie(state, { payload }) {
      const campaign =
        getObjectFromSessionStorage(sessionDataKey.objectKey) || campaignInit();
      if (campaign?.pk) {
        removeDataFromSessionStorage();
      }
      const linkedCoupon = campaign?.linkedCoupon || {};
      const promptShowed = Object.keys(linkedCoupon).length > 0;
      createNewItemAutoAdd(campaign, payload);
      return {
        ...state,
        promptShowed: promptShowed,
        campaign: campaign,
        hasUpdatedDefaultValues: true,
      };
    },

    stepChange(state, { payload }) {
      const isBack = payload.isBack;
      let step = payload.step;
      const isValid = payload.isValid;
      const stepConfig = getNewStepConfig(
        step,
        state.stepConfig,
        !isValid,
        isBack,
      );
      if (isValid) {
        step = isBack ? step - 1 : step + 1;
      }
      return {
        ...state,
        currentStep: step,
        stepConfig,
        createStatus: APIStatus.none,
      };
    },

    clearData(state, { payload }) {
      if (payload?.deleteSession) {
        removeDataFromSessionStorage();
      }
      localStorage.removeItem(LINK_COUPON_STRING);
      return { ...state, ...getInitialState() };
    },
  },

  effects: {
    // *setFieldToSession({ payload }, { select }) {
    //   const oldCampaign = yield select(
    //     (state) => state.createCampaign.campaign,
    //   );
    //   const language = payload.language;
    //   let campaign = {};
    //   if (language) {
    //     delete payload.language;
    //     campaign = {
    //       ...oldCampaign,
    //       translations: {
    //         ...oldCampaign.translations,
    //         [language]: {
    //           ...oldCampaign.translations[language],
    //           ...payload,
    //         },
    //       },
    //     };
    //   } else {
    //     campaign = { ...oldCampaign, ...payload };
    //   }
    //   saveDataToSessions(campaign);
    // },

    createOrUpdateCampaign: [
      function* ({ payload }, { call, select, put, all }) {
        yield put({
          type: 'updateState',
          payload: { createStatus: APIStatus.calling },
        });
        saveToSessionStorage(sessionDataKey.stepEndKey, true);

        const { modelCampaign, storeEditMode } = yield select((state) => ({
          storeEditMode: state.storeModel.storeEditMode,
          modelCampaign: state.createCampaign.campaign,
        }));
        const originalCampaign = payload?.data || modelCampaign;
        console.log('originalCampaign:', originalCampaign);
        let campaignData = {};
        if (originalCampaign.campaignType === CampaignType.fortuneBagCampaign) {
          const languageList = yield select((state) => state.language.allList);

          campaignData = getDataForCreateFortuneCampaign(
            originalCampaign,
            languageList,
          );
        } else {
          campaignData = getDataForCreateCampaign(
            originalCampaign,
            originalCampaign.pk,
          );
        }
        console.log('1548Campaign:', campaignData, payload);
        if (!storeEditMode && campaignData.pk) {
          delete campaignData.linkedStores;
          delete campaignData.stores;
        }
        let serviceArgs = [createCampaign, campaignData];
        if (originalCampaign.pk) {
          campaignData.id = originalCampaign.pk;
          serviceArgs = [updateCampaign, campaignData];
        }

        function* onSuccess(data) {
          const campaign =
            data[`${originalCampaign.pk ? 'update' : 'create'}Campaign`].node;
          if (!campaign) {
            yield put({
              type: 'updateState',
              payload: {
                createStatus: APIStatus.failed,
              },
            });
            return;
          }

          yield all([
            put({
              type: 'updateState',
              payload: {
                apiError: false,
                createStatus: APIStatus.success,
                formHasSubmitted: true,
              },
            }),
            put({
              type: 'updateCampaign',
              payload: { id: campaign.id, pk: campaign.pk },
            }),
            payload.isPublished !== undefined &&
              put({
                type: 'campaignList/publish',
                payload: {
                  ...payload,
                  campaignPk: campaign.pk,
                  isPublished: !payload.isPublished,
                  otaAllocationNum: payload.otaAllocatedNum,
                },
              }),
          ]);
        }

        function* failed(data) {
          console.log('createOneCampaign failed:', data);
          yield put({ type: 'updateState', payload: { apiError: true } });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, failed, failed);
      },
      { type: 'takeLatest' },
    ],

    createCamapigncategory: [
      function* ({ payload }, { call, all, put, race, take }) {
        const {
          enCategory,
          simpleCategory,
          traditionalCategory,
          priority,
          successAction,
        } = payload;

        yield put(
          createAction('createCampaignCategory/createCampaignCategory')({
            enCategory,
            simpleCategory,
            traditionalCategory,
            priority,
            isForcedInactive: false,
            successAction,
          }),
        );
        const [success] = yield race([
          take(CREATE_CAMPAIGN_CATEGORY_SUCCESS),
          take(CREATE_CAMPAIGN_CATEGORY_FAILD),
        ]);
        if (success) {
          yield put(
            createAction('campaignCategoryList/getCampaignCategoryList')({}),
          );
        }
      },
      { type: 'takeLatest' },
    ],

    getCampaignParticipationRecordsList: [
      function* ({ payload }, { all, select, put }) {
        if (!payload?.id || !payload?.page) {
          return;
        }
        console.log('@@1559', payload);
        const pageSize = 1000;
        const pageCursor = convertNumberToCursor(
          (payload.page - 1) * pageSize - 1,
        );
        const serviceArgs = [
          getCampaignParticipationRecordsList,
          payload.id,
          pageCursor,
        ];
        function* onSuccess(data) {
          const totalCount =
            data?.campaignParticipationRecords?.totalCount || 0;
          const endCursor =
            convertCursorToNumber(
              data?.campaignParticipationRecords?.pageInfo?.endCursor || '',
            ) || 0;
          const newData = {
            ...payload.data,
            campaignParticipationRecords: {
              ...payload.data?.campaignParticipationRecords,
              edges: [
                ...(payload.data?.campaignParticipationRecords?.edges || []),
                ...data?.campaignParticipationRecords?.edges,
              ],
            },
          };
          if (endCursor && endCursor + 1 < totalCount) {
            yield put({
              type: 'getCampaignParticipationRecordsList',
              payload: {
                id: payload.id,
                data: newData,
                page: payload.page + 1,
              },
            });
            return;
          }
          const records = newData?.campaignParticipationRecords?.edges?.map(
            (item) => {
              return {
                channel: item.node?.desiredCouponPublishChannel,
                market: item.node?.desiredCouponTargetMarket,
                quantity: item.node?.desiredCouponQuantity,
              };
            },
          );

          let couponAcquired = {};
          (records || []).forEach((item) => {
            const channelPK = item.channel?.pk
              ? `channel${item.channel?.pk}`
              : null;
            const marketPK = item.market?.pk
              ? `market${item.market?.pk}`
              : null;
            const quantity = item.quantity || 0;
            couponAcquired[channelPK] = {
              ...(couponAcquired?.[channelPK] || {}),
              pk: item.channel?.pk || null,
              acquired: (couponAcquired?.[channelPK]?.acquired || 0) + quantity,
              markets: {
                ...(couponAcquired?.[channelPK]?.markets || {}),
                [marketPK]: {
                  ...(couponAcquired?.[channelPK]?.markets?.[marketPK] || {}),
                  pk: item.market?.pk || null,
                  acquired:
                    (couponAcquired?.[channelPK]?.markets?.[marketPK]
                      ?.acquired || 0) + quantity,
                },
              },
            };
          });
          console.log('@@1606', couponAcquired);

          yield all([
            put({
              type: 'updateState',
              payload: {
                couponAcquired,
              },
            }),
          ]);
        }
        function* onError(err) {
          yield put({
            type: 'updateState',
            payload: { apiStatus: APIStatus.failed },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
    ],

    getOneCampaign: [
      function* ({ payload }, { all, select, put }) {
        yield put({
          type: 'updateState',
          payload: { createStatus: APIStatus.calling },
        });
        const id = convertPKToId('CampaignNode', payload.id);
        const serviceArgs = [getCampaign, id];
        function* onSuccess(data) {
          console.log('@@1755 getOneCampaign', payload.id);
          yield put({
            type: 'assembleCampaign',
            payload: {
              campaign: data.campaign,
              isDetail: payload.isDetail,
              newItems: payload,
            },
          });
          console.log('getOneCampaign:', data);

          yield all([
            put({
              type: 'updateProperty',
              payload: {
                campaignIn: [payload.id],
                pageSize: 1000,
                page: 1,
                stateName: 'campaign',
                dataKey: 'countryCodes',
                updateKey: 'countryCodes',
                updateApi: getCountryCodeList,
              },
            }),
            put({
              type: 'updateProperty',
              payload: {
                campaignIn: [payload.id],
                pageSize: 100,
                page: 1,
                stateName: 'campaign',
                dataKey: 'attributeTags',
                updateKey: 'attributeTags',
                updateApi: getAttributeTagList,
              },
            }),
            put({
              type: 'updateProperty',
              payload: {
                campaignIn: [payload.id],
                pageSize: 100,
                page: 1,
                dataKey: 'campaignCategories',
                updateKey: 'categories',
                updateApi: getCampaignCategoryList,
              },
            }),
          ]);

          const translations = data?.campaign?.translations?.edges?.map(
            (item) => item.node,
          );
          console.log('@@1766', translations);
          const languages = {};
          translations.forEach((item) => {
            languages[item.language] = item.pk;
          });

          yield put({
            type: 'updateAllLogoLabel',
            payload: {
              campaignIn: [payload.id],
              pageSize: 100,
              page: 1,
              language: LanguageConfig.english,
              modelNamespace: 'createCampaign',
              stateName: 'campaign',
              logoLabelKey: 'logoLabels',
            },
          });
          yield put({
            type: 'updateAllLogoLabel',
            payload: {
              campaignTranslationIn: translations.map((item) => item.pk),
              pageSize: 100,
              page: 1,
              languages,
              translationIn: 'campaignTranslations',
              modelNamespace: 'createCampaign',
              stateName: 'campaign',
              logoLabelKey: 'logoLabels',
            },
          });

          const couponId = data?.campaign?.couponCampaignTypeCouponTemplate?.pk;
          if (!payload?.isDetail || !couponId) {
            yield put({
              type: 'updateState',
              payload: { createStatus: APIStatus.success },
            });
            return;
          }

          yield put({
            type: 'getAndLinkCouponDetail',
            payload: {
              couponId: couponId,
              couponGetted: function* (newCoupon) {
                console.log('couponGetted:', newCoupon);
                const campaign = yield select(
                  (state) => state.createCampaign.campaign,
                );
                const newCampaign = patchCouponToCampaign(newCoupon, campaign);
                yield put({
                  type: 'updateState',
                  payload: {
                    createStatus: APIStatus.success,
                    campaign: newCampaign,
                    linkCouponDetail: true,
                  },
                });
              },
            },
          });
        }
        function* onError(err) {
          console.log('getOneCampaign onError :', err);
          yield put({
            type: 'updateState',
            payload: { createStatus: APIStatus.failed },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],

    getAndLinkCouponDetail: [
      function* ({ payload }, { call, select, put }) {
        console.log('getAndLinkCouponDetail:', payload);
        if (!payload?.couponId) {
          return;
        }
        const couponGetted = payload?.couponGetted || (() => {});
        const couponId = convertPKToId(
          'CouponStockTemplateNode',
          payload.couponId,
        );
        const linkedCoupon = yield select(
          (state) => state.createCampaign.linkedCoupon,
        ) || {};
        const serviceArgs = [getOneCoupon, couponId];
        function* onSuccess(data) {
          const couponSet = {
            ...data.couponStockTemplate,
            workingTeamIds: data.couponStockTemplate?.workingTeams?.edges?.map(
              (item) => item.node.pk,
            ),
            // workingTeams: data.couponStockTemplate?.workingTeams?.edges?.map(
            //   (item) => item.node,
            // ),
          };
          const newCoupon = {
            ...linkedCoupon,
            ...couponSet,
          };
          console.log('newCoupon:', newCoupon);
          console.log('linkedCoupon:', linkedCoupon);
          yield put({
            type: 'updateState',
            payload: {
              linkedCoupon: newCoupon,
            },
          });
          const couponString = JSON.stringify(newCoupon || {});
          localStorage.setItem(LINK_COUPON_STRING, couponString);
          yield couponGetted(newCoupon);
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    getAndLinkGoodieBagDetail: [
      function* ({ payload }, { call, select, put }) {
        console.log('getAndLinkGoodieBagDetail:', payload);
        if (!payload?.id) {
          return;
        }
        const setValue = payload?.setValue || (() => {});
        const id = convertPKToId('GoodieBagNode', payload.id);
        const serviceArgs = [getGoodieBagDetail, id, true]; // callFromCampaign = true
        function onSuccess(data) {
          console.log('goodieDetail:', data);
          const goodieBag = parseGoodieBagFromCampaign(data.goodieBag);
          if (goodieBag.couponAcquirePeriodType === 'RELATIVE') {
            setValue('isFollowGoodieBagActivePeriod', true);
          }
          setValue('goodieBag', goodieBag);
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    getOneDetail: [
      function* ({ payload }, { all, select, put }) {
        const id = convertPKToId('CampaignNode', payload.id);
        const serviceArgs = [getPreviewCampaign, id];
        function* onSuccess(data) {
          const couponId = data?.campaign?.couponCampaignTypeCouponTemplate?.pk;
          yield all([
            put({
              type: 'assembleCampaign',
              payload: { campaign: data.campaign, isDetail: payload.isDetail },
            }),
            put({
              type: 'updateState',
              payload: { createStatus: APIStatus.success },
            }),
            put({
              type: 'getAndLinkCouponDetail',
              payload: {
                couponId: couponId,
                couponGetted: function* (newCoupon) {
                  console.log('couponGetted:', newCoupon);
                  const campaign = yield select(
                    (state) => state.createCampaign.campaign,
                  );
                  const newCampaign = patchCouponToCampaign(
                    newCoupon,
                    campaign,
                  );
                  yield put({
                    type: 'updateState',
                    payload: {
                      createStatus: APIStatus.success,
                      campaign: newCampaign,
                      linkCouponDetail: true,
                    },
                  });
                },
              },
            }),
          ]);
        }
        function* onError(err) {
          yield put({
            type: 'updateState',
            payload: { apiStatus: APIStatus.failed },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],

    updateAllLogoLabel: [
      function* ({ payload }, { all, select, put }) {
        const {
          page,
          pageSize,
          modelNamespace,
          stateName,
          language,
          languages,
          translationIn,
          logoLabelKey,
        } = payload;
        console.log('@@1970', payload);
        const pageCursor = convertNumberToCursor((page - 1) * pageSize - 1);
        const serviceArgs = [getLogoLabelList, pageCursor, payload];
        function* onSuccess(data) {
          payload = {
            ...payload,
            unFilterLogoLabels: [
              ...(payload?.unFilterLogoLabels || []),
              ...data?.logoLabels?.edges?.map((item) => item.node),
            ],
          };

          if (data?.logoLabels?.pageInfo?.hasNextPage) {
            yield put({
              type: 'updateAllLogoLabel',
              payload: {
                ...payload,
                pageSize: payload.pageSize,
                page: payload.page + 1,
              },
            });
            return;
          }

          const selectedObject = yield select(
            (state) => state[modelNamespace][stateName],
          );
          const newSelectedObject = {
            ...selectedObject,
          };
          const newSelectedObjectTranslations = Object.keys(
            newSelectedObject.translations,
          );
          if (language) {
            newSelectedObjectTranslations?.includes(language) &&
              (newSelectedObject.translations[language][logoLabelKey] = [
                ...payload?.unFilterLogoLabels,
              ]);
          } else if (languages && translationIn) {
            Object.keys(languages).forEach((language) => {
              const filterLogoLabels = payload?.unFilterLogoLabels?.filter(
                (logoLabel) =>
                  logoLabel?.[translationIn]?.edges?.some((translation) => {
                    return translation?.node?.pk === languages[language];
                  }),
              );
              newSelectedObjectTranslations?.includes(language) &&
                (newSelectedObject.translations[language][logoLabelKey] = [
                  ...filterLogoLabels,
                ]);
            });
          }

          yield put({
            type: `${modelNamespace}/updateState`,
            payload: {
              [stateName]: {
                ...newSelectedObject,
              },
            },
          });
          console.log('@2031', payload?.unFilterLogoLabels);
        }
        function* onError(err) {
          yield put({
            type: `${modelNamespace}/updateState`,
            payload: { apiStatus: APIStatus.failed },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError);
      },
    ],
  },
});
