import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ADMIN_TYPE } from '../../config/CustomEnums';
import Dashboard from '../dashboard/Dashboard';
import { tabList } from '../../config/NavBarConfig';
import { PermissionCodes } from '../../config/PermissionCodes';

function Home() {
  const history = useHistory();
  const { adminType, userPermissions, admin } = useSelector((state) => ({
    adminType: state.users.adminType,
    userPermissions: state.users.userPermissions,
    admin: state.users,
  }));

  if (
    adminType === ADMIN_TYPE.MERCHANT_ADMIN ||
    !userPermissions.includes(PermissionCodes.viewDashboard)
  ) {
    let pathname = '';
    const viewPermissions = (userPermissions || [])
      .filter((permission) => permission?.includes('view_'))
      .map((permission) => permission?.split('_')?.[1]);

    (tabList || []).some((section) =>
      (section.list || []).some((navBar) =>
        viewPermissions?.includes(navBar.codename)
          ? (pathname = navBar.path)
          : false,
      ),
    );
    if (pathname) {
      history.push(pathname);
    }
  }
  return <Dashboard />;
}
export default Home;
