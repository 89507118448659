import React, { useState, useEffect } from 'react';
import ContentGeneralSection from './ContentGeneralSection';
import { useFormContext } from 'react-hook-form';
import { LanguageConfig } from '../../../config/CustomEnums';
import ContentSections from '../../../components/base/ContentSections';
import { ContinueWithBackButtons } from '../../../components/base/BottomStepComponent';
import { useDispatch, connect } from 'react-redux';
import { createAction } from '../../../utils';
import { saveDataToSessionStorage } from '../../../models/StoreModel';
import { useHistory } from 'react-router-dom';
import DetailSection from './DetailSection';
import BusinessHours from './BusinessHours';
import './CreateStoreStepOne.scss';

const getTabs = (language, setActiveSection) => {
  const tabs = [
    <ContentGeneralSection
      language={language}
      focusChange={setActiveSection}
    />,
    <DetailSection language={language} focusChange={setActiveSection} />,
  ];
  if (language === LanguageConfig.english) {
    tabs.push(
      <BusinessHours language={language} focusChange={setActiveSection} />,
    );
  }
  return tabs;
};

function CreateStoreStepOne({ languages }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [activeSection, setActiveSection] = useState(null);
  const { getValues, trigger } = useFormContext();

  useEffect(() => {
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
  }, [dispatch]);

  return (
    <div className="store-create-step-one">
      <ContentSections
        languageTabContent={{
          containers: languages?.map((item) => ({
            container: getTabs(item.code, setActiveSection),
            key: item.code,
            title: item.sourceName,
          })),
        }}
        hidePreview={true}
        activeSection={activeSection}
      />
      <ContinueWithBackButtons
        continueAction={async () => {
          const isValid = await trigger([
            'storeTranslations.en.name',
            'brand',
            'storeTranslations.en.address',
            'weekHours',
            'district',
            'emailAddress',
            'latitude',
            'longitude',
          ]);
          console.log('@@68: ', isValid, getValues());
          dispatch({
            type: 'storeModel/stepChange',
            payload: { currentStep: 0, isValid },
          });
          saveDataToSessionStorage(getValues());
        }}
        backAction={() => {
          history.push({
            pathname: '/stores',
            search: '?sort=displayPriority',
          });
        }}
      />
    </div>
  );
}

const mapPropsToState = (state) => ({
  languages: state.language.allList,
});

export default connect(mapPropsToState)(CreateStoreStepOne);
