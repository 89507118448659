export const AdminActivityType = {
  CUSTOMER_ACTIVE: { pk: 'CUSTOMER_ACTIVE', name: 'Reactive customer' },
  CUSTOMER_DEACTIVE: { pk: 'CUSTOMER_DEACTIVE', name: 'Deactive customer' },
  CUSTOMER_DELETE: { pk: 'CUSTOMER_DELETE', name: 'Delete customer' },
  CUSTOMER_PROFILE_UPDATE: {
    pk: 'CUSTOMER_PROFILE_UPDATE',
    name: 'Update customer information',
  },
  COUPON_ACTIVE: { pk: 'COUPON_ACTIVE', name: 'Reactivated coupon' },
  COUPON_DEACTIVE: { pk: 'COUPON_DEACTIVE', name: 'Deactivated coupon' },
  COUPON_GRANT: { pk: 'COUPON_GRANT', name: 'Admin added coupon' },
  COUPON_RECLAIM: { pk: 'COUPON_RECLAIM', name: 'Admin removed coupon' },
  COUPON_DELETE: { pk: 'COUPON_DELETE', name: 'Admin deleted coupon' },
  COUPON_EXPIRY_DATE_EXTEND: {
    pk: 'COUPON_EXPIRY_DATE_EXTEND',
    name: 'Extended coupon expiry',
  },
  COUPON_GENERATED: { pk: 'COUPON_GENERATED', name: 'Generate coupons' },
  COUPON_MANUAL_USE: { pk: 'COUPON_MANUAL_USE', name: 'Manual use coupon' },
  GOODIE_BAG_EXPIRY_DATE_EXTEND: {
    pk: 'GOODIE_BAG_EXPIRY_DATE_EXTEND',
    name: 'Extended goodie bag expiry',
  },
  VIEW_ENTITLEMENT: { pk: 'VIEW_ENTITLEMENT', name: 'View Entitlement' },
  DOWNLOAD_ENTITLEMENT_SIGN_LIST: {
    pk: 'DOWNLOAD_ENTITLEMENT_SIGN_LIST',
    name: 'Download goodie bag entitlement list',
  },
  CAMPAIGN_CREATE: { pk: 'CAMPAIGN_CREATE', name: 'Create campaign' },
  CAMPAIGN_UPDATE: { pk: 'CAMPAIGN_UPDATE', name: 'Modify campaign' },
  CAMPAIGN_DELETE: { pk: 'CAMPAIGN_DELETE', name: 'Delete campaign' },
  CAMPAIGN_PUBLISH: { pk: 'CAMPAIGN_PUBLISH', name: 'Publish campaign' },
  CAMPAIGN_UNPUBLISH: { pk: 'CAMPAIGN_UNPUBLISH', name: 'Unpublish campaign' },
  STARRED_CAMPAIGN_LIMIT_SETTINGS_UPDATE: {
    pk: 'STARRED_CAMPAIGN_LIMIT_SETTINGS_UPDATE',
    name: 'Update starred campaign limit setting',
  },
  CAMPAIGN_SEARCH_FIELD_SETTINGS_UPDATE: {
    pk: 'CAMPAIGN_SEARCH_FIELD_SETTINGS_UPDATE',
    name: 'Update search function',
  },
  CAMPAIGN_SETTINGS_UPDATE_FILTER: {
    pk: 'CAMPAIGN_SETTINGS_UPDATE_FILTER',
    name: 'Update wechat campaign list - filter',
  },
  CAMPAIGN_SETTINGS_UPDATE_HIDE_OUT_OF_STOCK: {
    pk: 'CAMPAIGN_SETTINGS_UPDATE_HIDE_OUT_OF_STOCK',
    name: 'Update wechat campaign list - hide out of stock campaigns',
  },
  CAMPAIGN_SETTINGS_UPDATE_SHOW_OUT_OF_STOCK: {
    pk: 'CAMPAIGN_SETTINGS_UPDATE_SHOW_OUT_OF_STOCK',
    name: 'Update wechat campaign list - show out of stock campaigns',
  },
  COUPON_TEMPLATE_CREATE: {
    pk: 'COUPON_TEMPLATE_CREATE',
    name: 'Create coupon set',
  },
  COUPON_TEMPLATE_UPDATE: {
    pk: 'COUPON_TEMPLATE_UPDATE',
    name: 'Modify coupon set',
  },
  COUPON_TEMPLATE_DELETE: {
    pk: 'COUPON_TEMPLATE_DELETE',
    name: 'Delete coupon set',
  },
  COUPON_TEMPLATE_ACTIVE: {
    pk: 'COUPON_TEMPLATE_ACTIVE',
    name: 'Reactivate coupon set',
  },
  COUPON_TEMPLATE_DEACTIVE: {
    pk: 'COUPON_TEMPLATE_DEACTIVE',
    name: 'Deactivate coupon set',
  },
  ADMINISTRATOR_CREATE: {
    pk: 'ADMINISTRATOR_CREATE',
    name: 'Create administrator',
  },
  ADMINISTRATOR_UPDATE: {
    pk: 'ADMINISTRATOR_UPDATE',
    name: 'Modify administrator',
  },
  ADMINISTRATOR_DELETE: {
    pk: 'ADMINISTRATOR_DELETE',
    name: 'Delete administrator',
  },
  ADMINISTRATOR_ACTIVE: {
    pk: 'ADMINISTRATOR_ACTIVE',
    name: 'Reactivate administrator',
  },
  ADMINISTRATOR_DEACTIVE: {
    pk: 'ADMINISTRATOR_DEACTIVE',
    name: 'Deactivate administrator',
  },
  CUSTOMER_OTP_CHECK: { pk: 'CUSTOMER_OTP_CHECK', name: 'Check customer OTP' },
  CUSTOMER_MEMBERSHIP_ID_CHECK: {
    pk: 'CUSTOMER_MEMBERSHIP_ID_CHECK',
    name: 'Check membership ID',
  },
  HOMEPAGE_BANNER_CREATE: {
    pk: 'HOMEPAGE_BANNER_CREATE',
    name: 'Create banner',
  },
  HOMEPAGE_BANNER_UPDATE: {
    pk: 'HOMEPAGE_BANNER_UPDATE',
    name: 'Modify banner',
  },
  HOMEPAGE_BANNER_DELETE: {
    pk: 'HOMEPAGE_BANNER_DELETE',
    name: 'Delete banner',
  },
  HOMEPAGE_BANNER_PUBLISH: {
    pk: 'HOMEPAGE_BANNER_PUBLISH',
    name: 'Publish banner',
  },
  HOMEPAGE_BANNER_UNPUBLISH: {
    pk: 'HOMEPAGE_BANNER_UNPUBLISH',
    name: 'Unpublish banner',
  },
  INTEREST_PREFERENCE_CREATE: {
    pk: 'INTEREST_PREFERENCE_CREATE',
    name: 'Create interest preference',
  },
  INTEREST_PREFERENCE_UPDATE: {
    pk: 'INTEREST_PREFERENCE_UPDATE',
    name: 'Modify interest preference',
  },
  INTEREST_PREFERENCE_DELETE: {
    pk: 'INTEREST_PREFERENCE_DELETE',
    name: 'Delete interest preference',
  },
  OTP_CHANNEL_UPDATE: { pk: 'OTP_CHANNEL_UPDATE', name: 'Update OTP channel' },
  GOODIE_BAG_CREATE: { pk: 'GOODIE_BAG_CREATE', name: 'Create goodie bag' },
  GOODIE_BAG_UPDATE: { pk: 'GOODIE_BAG_UPDATE', name: 'Modify goodie bag' },
  GOODIE_BAG_DELETE: { pk: 'GOODIE_BAG_DELETE', name: 'Delete goodie bag' },
  GOODIE_BAG_PUBLISH: { pk: 'GOODIE_BAG_PUBLISH', name: 'Publish goodie bag' },
  GOODIE_BAG_UNPUBLISH: {
    pk: 'GOODIE_BAG_UNPUBLISH',
    name: 'Unpublish goodie bag',
  },
  GOODIE_BAG_GROUP_CREATE: {
    pk: 'GOODIE_BAG_GROUP_CREATE',
    name: 'Create goodie bag groups',
  },
  GOODIE_BAG_GROUP_UPDATE: {
    pk: 'GOODIE_BAG_GROUP_UPDATE',
    name: 'Modify goodie bag groups',
  },
  GOODIE_BAG_GROUP_DELETE: {
    pk: 'GOODIE_BAG_GROUP_DELETE',
    name: 'Delete goodie bag groups',
  },
  CAMPAIGN_CATEGORY_CREATE: {
    pk: 'CAMPAIGN_CATEGORY_CREATE',
    name: 'Create campaign categories',
  },
  CAMPAIGN_CATEGORY_UPDATE: {
    pk: 'CAMPAIGN_CATEGORY_UPDATE',
    name: 'Modify campaign categories',
  },
  CAMPAIGN_CATEGORY_DELETE: {
    pk: 'CAMPAIGN_CATEGORY_DELETE',
    name: 'Delete campaign categories',
  },
  CAMPAIGN_CATEGORY_ACTIVE: {
    pk: 'CAMPAIGN_CATEGORY_ACTIVE',
    name: 'Reactivate campaign categories',
  },
  CAMPAIGN_CATEGORY_DEACTIVE: {
    pk: 'CAMPAIGN_CATEGORY_DEACTIVE',
    name: 'Deactivate campaign categories',
  },
  GENERAL_ALERT_CREATE: {
    pk: 'GENERAL_ALERT_CREATE',
    name: 'Create general alert',
  },
  GENERAL_ALERT_UPDATE: {
    pk: 'GENERAL_ALERT_UPDATE',
    name: 'Modify general alert',
  },
  GENERAL_ALERT_DELETE: {
    pk: 'GENERAL_ALERT_DELETE',
    name: 'Delete general alert',
  },
  GENERAL_ALERT_ACTIVE: {
    pk: 'GENERAL_ALERT_ACTIVE',
    name: 'Reactivate general alert',
  },
  GENERAL_ALERT_DEACTIVE: {
    pk: 'GENERAL_ALERT_DEACTIVE',
    name: 'Deactivate general alert',
  },
  ATTRIBUTE_TAG_KEY_CREATE: {
    pk: 'ATTRIBUTE_TAG_KEY_CREATE',
    name: 'Create tag',
  },
  ATTRIBUTE_TAG_KEY_UPDATE: {
    pk: 'ATTRIBUTE_TAG_KEY_UPDATE',
    name: 'Modify tag',
  },
  ATTRIBUTE_TAG_KEY_DELETE: {
    pk: 'ATTRIBUTE_TAG_KEY_DELETE',
    name: 'Delete tag',
  },
  LOGO_LABEL_CREATE: { pk: 'LOGO_LABEL_CREATE', name: 'Create logo label' },
  LOGO_LABEL_UPDATE: { pk: 'LOGO_LABEL_UPDATE', name: 'Modify logo label' },
  LOGO_LABEL_DELETE: { pk: 'LOGO_LABEL_DELETE', name: 'Delete logo label' },
  LANGUAGE_CREATE: { pk: 'LANGUAGE_CREATE', name: 'Create language' },
  LANGUAGE_UPDATE: { pk: 'LANGUAGE_UPDATE', name: 'Modify language' },
  LANGUAGE_DELETE: { pk: 'LANGUAGE_DELETE', name: 'Delete language' },
  CHANNEL_CREATE: { pk: 'CHANNEL_CREATE', name: 'Create channel' },
  CHANNEL_UPDATE: { pk: 'CHANNEL_UPDATE', name: 'Modify channel' },
  CHANNEL_DELETE: { pk: 'CHANNEL_DELETE', name: 'Delete channel' },
  TARGET_MARKET_CREATE: {
    pk: 'TARGET_MARKET_CREATE',
    name: 'Create target market',
  },
  TARGET_MARKET_UPDATE: {
    pk: 'TARGET_MARKET_UPDATE',
    name: 'Modify target market',
  },
  TARGET_MARKET_DELETE: {
    pk: 'TARGET_MARKET_DELETE',
    name: 'Delete target market',
  },
  SURVEY_FORM_CREATE: { pk: 'SURVEY_FORM_CREATE', name: 'Create survey form' },
  SURVEY_FORM_UPDATE: { pk: 'SURVEY_FORM_UPDATE', name: 'Modify survey form' },
  SURVEY_FORM_DELETE: { pk: 'SURVEY_FORM_DELETE', name: 'Delete survey form' },
  OFFER_TYPE_LABEL_CREATE: {
    pk: 'OFFER_TYPE_LABEL_CREATE',
    name: 'Create offer type label',
  },
  OFFER_TYPE_LABEL_UPDATE: {
    pk: 'OFFER_TYPE_LABEL_UPDATE',
    name: 'Modify offer type label',
  },
  OFFER_TYPE_LABEL_DELETE: {
    pk: 'OFFER_TYPE_LABEL_DELETE',
    name: 'Delete offer type label',
  },
  TOUR_CARD_CREATE: { pk: 'TOUR_CARD_CREATE', name: 'Create tour card' },
  TOUR_CARD_UPDATE: { pk: 'TOUR_CARD_UPDATE', name: 'Modify tour card' },
  TOUR_CARD_DELETE: { pk: 'TOUR_CARD_DELETE', name: 'Delete tour card' },
  TERMS_AND_CONDITIONS_TEMPLATE_CREATE: {
    pk: 'TERMS_AND_CONDITIONS_TEMPLATE_CREATE',
    name: 'Create Terms and Conditions',
  },
  TERMS_AND_CONDITIONS_TEMPLATE_UPDATE: {
    pk: 'TERMS_AND_CONDITIONS_TEMPLATE_UPDATE',
    name: 'Modify Terms and Conditions',
  },
  TERMS_AND_CONDITIONS_TEMPLATE_DELETE: {
    pk: 'TERMS_AND_CONDITIONS_TEMPLATE_DELETE',
    name: 'Delete Terms and Conditions',
  },
  BRAND_CREATE: { pk: 'BRAND_CREATE', name: 'Create brand' },
  BRAND_UPDATE: { pk: 'BRAND_UPDATE', name: 'Modify brand' },
  BRAND_DELETE: { pk: 'BRAND_DELETE', name: 'Delete brand' },
  STORE_CREATE: { pk: 'STORE_CREATE', name: 'Create store' },
  STORE_UPDATE: { pk: 'STORE_UPDATE', name: 'Modify store' },
  STORE_DELETE: { pk: 'STORE_DELETE', name: 'Delete store' },
  STORE_ACTIVE: { pk: 'STORE_ACTIVE', name: 'Reactivate store' },
  STORE_DEACTIVE: { pk: 'STORE_DEACTIVE', name: 'Deactivate store' },
  DOWNLOAD_STORE_CODE: {
    pk: 'DOWNLOAD_STORE_CODE',
    name: 'Download store code',
  },
  ADMINISTRATOR_GROUP_CREATE: {
    pk: 'ADMINISTRATOR_GROUP_CREATE',
    name: 'Create administrator group',
  },
  ADMINISTRATOR_GROUP_UPDATE: {
    pk: 'ADMINISTRATOR_GROUP_UPDATE',
    name: 'Modify administrator group',
  },
  ADMINISTRATOR_GROUP_DELETE: {
    pk: 'ADMINISTRATOR_GROUP_DELETE',
    name: 'Delete administrator group',
  },
  WORKING_TEAM_CREATE: {
    pk: 'WORKING_TEAM_CREATE',
    name: 'Create working team',
  },
  WORKING_TEAM_UPDATE: {
    pk: 'WORKING_TEAM_UPDATE',
    name: 'Modify working team',
  },
  WORKING_TEAM_DELETE: {
    pk: 'WORKING_TEAM_DELETE',
    name: 'Delete working team',
  },
  ENTITLEMENT_TRACKING_CODE_CREATE: {
    pk: 'ENTITLEMENT_TRACKING_CODE_CREATE',
    name: 'Create tracking code',
  },
  ENTITLEMENT_TRACKING_CODE_UPDATE: {
    pk: 'ENTITLEMENT_TRACKING_CODE_UPDATE',
    name: 'Modify tracking code',
  },
  ENTITLEMENT_TRACKING_CODE_ACTIVE: {
    pk: 'ENTITLEMENT_TRACKING_CODE_ACTIVE',
    name: 'Activate tracking code',
  },
  ENTITLEMENT_TRACKING_CODE_DEACTIVE: {
    pk: 'ENTITLEMENT_TRACKING_CODE_DEACTIVE',
    name: 'Deactivate tracking code',
  },
  COUPON_LABEL_CREATE: {
    pk: 'COUPON_LABEL_CREATE',
    name: 'Create coupon label',
  },
  COUPON_LABEL_UPDATE: {
    pk: 'COUPON_LABEL_UPDATE',
    name: 'Modify coupon label',
  },
  COUPON_LABEL_DELETE: {
    pk: 'COUPON_LABEL_DELETE',
    name: 'Delete coupon label',
  },
  IMPORT_CAMPAIGN: { pk: 'IMPORT_CAMPAIGN', name: 'Import campaigns' },
  IMPORT_COUPON_TEMPLATE: {
    pk: 'IMPORT_COUPON_TEMPLATE',
    name: 'Import coupon sets',
  },
  IMPORT_STORE: { pk: 'IMPORT_STORE', name: 'Import stores' },
  IMPORT_GOODIE_BAG_TRACKING_CODE: {
    pk: 'IMPORT_GOODIE_BAG_TRACKING_CODE',
    name: 'Import goodie bag tracking code',
  },
  EXPORT_SIMPLE_CUSTOMER_REPORT: {
    pk: 'EXPORT_SIMPLE_CUSTOMER_REPORT',
    name: 'Export simple dashboard - customer report',
  },
  EXPORT_SIMPLE_CAMPAIGN_PERFORMANCE_REPORT: {
    pk: 'EXPORT_SIMPLE_CAMPAIGN_PERFORMANCE_REPORT',
    name: 'Export simple dashboard - performance report',
  },
  EXPORT_COUPON_RANKING_REPORT_BY_COUPON: {
    pk: 'EXPORT_COUPON_RANKING_REPORT_BY_COUPON',
    name: 'Export coupon dashboard - ranking report',
  },
  EXPORT_CAMPAIGN_PERFORMANCE_REPORT_BY_COUPON: {
    pk: 'EXPORT_CAMPAIGN_PERFORMANCE_REPORT_BY_COUPON',
    name: 'Export coupon dashboard - performance report',
  },
  EXPORT_COUPON_RANKING_REPORT_BY_CAMPAIGN: {
    pk: 'EXPORT_COUPON_RANKING_REPORT_BY_CAMPAIGN',
    name: 'Export campaign dashboard - ranking report',
  },
  EXPORT_CAMPAIGN_PERFORMANCE_REPORT_BY_CAMPAIGN: {
    pk: 'EXPORT_CAMPAIGN_PERFORMANCE_REPORT_BY_CAMPAIGN',
    name: 'Export campaign dashboard - performance report',
  },
  EXPORT_COUPON_RANKING_REPORT_BY_GOODIE_BAG: {
    pk: 'EXPORT_COUPON_RANKING_REPORT_BY_GOODIE_BAG',
    name: 'Export goodie bag dashboard - ranking report',
  },
  EXPORT_CAMPAIGN_PERFORMANCE_REPORT_BY_GOODIE_BAG: {
    pk: 'EXPORT_CAMPAIGN_PERFORMANCE_REPORT_BY_GOODIE_BAG',
    name: 'Export goodie bag dashboard - performance report',
  },
  EXPORT_CUSTOMER_REPORT: {
    pk: 'EXPORT_CUSTOMER_REPORT',
    name: 'Export customer dashboard report',
  },
  EXPORT_CUSTOMER: { pk: 'EXPORT_CUSTOMER', name: 'Export customer list' },
  EXPORT_CAMPAIGN: { pk: 'EXPORT_CAMPAIGN', name: 'Export campaigns' },
  EXPORT_GOODIE_BAG_PARTICIPATION_RECORD: {
    pk: 'EXPORT_GOODIE_BAG_PARTICIPATION_RECORD',
    name: 'Export goodie bag records',
  },
  EXPORT_GOODIE_BAG_CONFIG: {
    pk: 'EXPORT_GOODIE_BAG_CONFIG',
    name: 'Export goodie bag configuration',
  },
  EXPORT_COUPON_TEMPLATE: {
    pk: 'EXPORT_COUPON_TEMPLATE',
    name: 'Export coupon sets',
  },
  EXPORT_COUPON: { pk: 'EXPORT_COUPON', name: 'Export coupon set reports' },
  EXPORT_PAYMENT_AND_SETTLEMENT_REPORT: {
    pk: 'EXPORT_PAYMENT_AND_SETTLEMENT_REPORT',
    name: 'Export payment settlement',
  },
  EXPORT_STORE: { pk: 'EXPORT_STORE', name: 'Export stores' },
  EXPORT_STORE_QR_CODE: {
    pk: 'EXPORT_STORE_QR_CODE',
    name: 'Export store codes',
  },
  EXPORT_COUPON_RECORD: {
    pk: 'EXPORT_COUPON_RECORD',
    name: 'Export coupon records',
  },
  EXPORT_ADMINISTRATOR_ACTIVITY_LOG: {
    pk: 'EXPORT_ADMINISTRATOR_ACTIVITY_LOG',
    name: 'Export admin activity',
  },
  EXPORT_COUPON_LEFTOVER: {
    pk: 'EXPORT_COUPON_LEFTOVER',
    name: 'Handle leftover coupons',
  },
  EXPORT_ENTITLEMENT_TRACKING_CODE: {
    pk: 'EXPORT_ENTITLEMENT_TRACKING_CODE',
    name: 'Export Tracking Code',
  },
  ADMINISTRATOR_LOGIN: { pk: 'ADMINISTRATOR_LOGIN', name: 'Login' },
  ADMINISTRATOR_LOGOUT: { pk: 'ADMINISTRATOR_LOGOUT', name: 'Logout by admin' },
  ADMINISTRATOR_LOGOUT_SESSION_EXPIRED: {
    pk: 'ADMINISTRATOR_LOGOUT_SESSION_EXPIRED',
    name: 'Logout (session expired)',
  },
  SYSTEM_SETTINGS_UPDATE_RECAPTCHA_OPEN: {
    pk: 'SYSTEM_SETTINGS_UPDATE_RECAPTCHA_OPEN',
    name: 'Turn on reCAPTCHA in Frontend',
  },
  SYSTEM_SETTINGS_UPDATE_RECAPTCHA_CLOSE: {
    pk: 'SYSTEM_SETTINGS_UPDATE_RECAPTCHA_CLOSE',
    name: 'Turn off reCAPTCHA in Frontend',
  },
  PAYMENT_AND_SETTLEMENT_MAKE_AMENDMENT: {
    pk: 'PAYMENT_AND_SETTLEMENT_MAKE_AMENDMENT',
    name: 'Make amendment to payment settlement',
  },
  COUPON_GRANT_EXCEED_RATE_LIMIT: {
    pk: 'COUPON_GRANT_EXCEED_RATE_LIMIT',
    name: 'Admin added coupon failed (denied as rate limit)',
  },
  COUPON_STATUS_MODIFICATION_EXCEED_RATE_LIMIT: {
    pk: 'COUPON_STATUS_MODIFICATION_EXCEED_RATE_LIMIT',
    name: 'Coupon status modification failed (denied as rate limit)',
  },
  COUPON_GENERATED_EXCEED_RATE_LIMIT: {
    pk: 'COUPON_GENERATED_EXCEED_RATE_LIMIT',
    name: 'Generate coupons failed (denied as rate limit)',
  },
  CUSTOMER_OTP_CHECK_EXCEED_RATE_LIMIT: {
    pk: 'CUSTOMER_OTP_CHECK_EXCEED_RATE_LIMIT',
    name: 'Check customer OTP failed (denied as rate limit)',
  },
  CUSTOMER_MEMBERSHIP_ID_CHECK_EXCEED_RATE_LIMIT: {
    pk: 'CUSTOMER_MEMBERSHIP_ID_CHECK_EXCEED_RATE_LIMIT',
    name: 'Check membership ID failed (denied as rate limit)',
  },
  ADMINISTRATOR_CREATE_EXCEED_RATE_LIMIT: {
    pk: 'ADMINISTRATOR_CREATE_EXCEED_RATE_LIMIT',
    name: 'Create administrator failed (denied as rate limit)',
  },
  EXPORT_SIMPLE_CUSTOMER_REPORT_EXCEED_RATE_LIMIT: {
    pk: 'EXPORT_SIMPLE_CUSTOMER_REPORT_EXCEED_RATE_LIMIT',
    name: 'Export simple dashboard - customer report failed (denied as rate limit)',
  },
  EXPORT_SIMPLE_CAMPAIGN_PERFORMANCE_REPORT_EXCEED_RATE_LIMIT: {
    pk: 'EXPORT_SIMPLE_CAMPAIGN_PERFORMANCE_REPORT_EXCEED_RATE_LIMIT',
    name: 'Export simple dashboard - performance report failed (denied as rate limit)',
  },
  EXPORT_COUPON_RANKING_REPORT_BY_COUPON_EXCEED_RATE_LIMIT: {
    pk: 'EXPORT_COUPON_RANKING_REPORT_BY_COUPON_EXCEED_RATE_LIMIT',
    name: 'Export coupon dashboard - ranking report failed (denied as rate limit)',
  },
  EXPORT_CAMPAIGN_PERFORMANCE_REPORT_BY_COUPON_EXCEED_RATE_LIMIT: {
    pk: 'EXPORT_CAMPAIGN_PERFORMANCE_REPORT_BY_COUPON_EXCEED_RATE_LIMIT',
    name: 'Export coupon dashboard - performance report failed (denied as rate limit)',
  },
  EXPORT_COUPON_RANKING_REPORT_BY_CAMPAIGN_EXCEED_RATE_LIMIT: {
    pk: 'EXPORT_COUPON_RANKING_REPORT_BY_CAMPAIGN_EXCEED_RATE_LIMIT',
    name: 'Export campaign dashboard - ranking report failed (denied as rate limit)',
  },
  EXPORT_CAMPAIGN_PERFORMANCE_REPORT_BY_CAMPAIGN_EXCEED_RATE_LIMIT: {
    pk: 'EXPORT_CAMPAIGN_PERFORMANCE_REPORT_BY_CAMPAIGN_EXCEED_RATE_LIMIT',
    name: 'Export campaign dashboard - performance report failed (denied as rate limit)',
  },
  EXPORT_COUPON_RANKING_REPORT_BY_GOODIE_BAG_EXCEED_RATE_LIMIT: {
    pk: 'EXPORT_COUPON_RANKING_REPORT_BY_GOODIE_BAG_EXCEED_RATE_LIMIT',
    name: 'Export goodie bag dashboard - ranking report failed (denied as rate limit)',
  },
  EXPORT_CAMPAIGN_PERFORMANCE_REPORT_BY_GOODIE_BAG_EXCEED_RATE_LIMIT: {
    pk: 'EXPORT_CAMPAIGN_PERFORMANCE_REPORT_BY_GOODIE_BAG_EXCEED_RATE_LIMIT',
    name: 'Export goodie bag dashboard - performance report failed (denied as rate limit)',
  },
  EXPORT_CUSTOMER_REPORT_EXCEED_RATE_LIMIT: {
    pk: 'EXPORT_CUSTOMER_REPORT_EXCEED_RATE_LIMIT',
    name: 'Export customer dashboard report failed (denied as rate limit)',
  },
  EXPORT_CUSTOMER_EXCEED_RATE_LIMIT: {
    pk: 'EXPORT_CUSTOMER_EXCEED_RATE_LIMIT',
    name: 'Export customer list failed (denied as rate limit)',
  },
  EXPORT_CAMPAIGN_EXCEED_RATE_LIMIT: {
    pk: 'EXPORT_CAMPAIGN_EXCEED_RATE_LIMIT',
    name: 'Export campaigns failed (denied as rate limit)',
  },
  EXPORT_GOODIE_BAG_PARTICIPATION_RECORD_EXCEED_RATE_LIMIT: {
    pk: 'EXPORT_GOODIE_BAG_PARTICIPATION_RECORD_EXCEED_RATE_LIMIT',
    name: 'Export goodie bag records failed (denied as rate limit)',
  },
  EXPORT_GOODIE_BAG_CONFIG_EXCEED_RATE_LIMIT: {
    pk: 'EXPORT_GOODIE_BAG_CONFIG_EXCEED_RATE_LIMIT',
    name: 'Export goodie bag configuration failed (denied as rate limit)',
  },
  EXPORT_COUPON_TEMPLATE_EXCEED_RATE_LIMIT: {
    pk: 'EXPORT_COUPON_TEMPLATE_EXCEED_RATE_LIMIT',
    name: 'Export coupon sets failed (denied as rate limit)',
  },
  EXPORT_COUPON_EXCEED_RATE_LIMIT: {
    pk: 'EXPORT_COUPON_EXCEED_RATE_LIMIT',
    name: 'Export coupon set reports failed (denied as rate limit)',
  },
  EXPORT_PAYMENT_AND_SETTLEMENT_REPORT_EXCEED_RATE_LIMIT: {
    pk: 'EXPORT_PAYMENT_AND_SETTLEMENT_REPORT_EXCEED_RATE_LIMIT',
    name: 'Export payment settlement failed (denied as rate limit)',
  },
  EXPORT_STORE_EXCEED_RATE_LIMIT: {
    pk: 'EXPORT_STORE_EXCEED_RATE_LIMIT',
    name: 'Export stores failed (denied as rate limit)',
  },
  EXPORT_STORE_QR_CODE_EXCEED_RATE_LIMIT: {
    pk: 'EXPORT_STORE_QR_CODE_EXCEED_RATE_LIMIT',
    name: 'Export store codes failed (denied as rate limit)',
  },
  EXPORT_COUPON_RECORD_EXCEED_RATE_LIMIT: {
    pk: 'EXPORT_COUPON_RECORD_EXCEED_RATE_LIMIT',
    name: 'Export coupon records failed (denied as rate limit)',
  },
  EXPORT_ADMINISTRATOR_ACTIVITY_LOG_EXCEED_RATE_LIMIT: {
    pk: 'EXPORT_ADMINISTRATOR_ACTIVITY_LOG_EXCEED_RATE_LIMIT',
    name: 'Export admin activity failed (denied as rate limit)',
  },
  EXPORT_COUPON_LEFTOVER_EXCEED_RATE_LIMIT: {
    pk: 'EXPORT_COUPON_LEFTOVER_EXCEED_RATE_LIMIT',
    name: 'Handle leftover coupons failed (denied as rate limit)',
  },
  EXPORT_ENTITLEMENT_TRACKING_CODE_EXCEED_RATE_LIMIT: {
    pk: 'EXPORT_ENTITLEMENT_TRACKING_CODE_EXCEED_RATE_LIMIT',
    name: 'Export Tracking Code failed (denied as rate limit)',
  },
  EXPORT_ENTITLEMENT_MERCHANT_TRACKING_CODES: {
    pk: 'EXPORT_ENTITLEMENT_MERCHANT_TRACKING_CODES',
    name: 'Export Merchant Tracking Codes',
  },
};

export const CustomerActivityType = {
  REGISTER_AT_DHK: 'REGISTER_AT_DHK',
  REGISTER_AT_ESOLUTION: 'REGISTER_AT_ESOLUTION',
  REGISTER_AT_WECHAT: 'REGISTER_AT_WECHAT',
  LOGIN_AT_DHK: 'LOGIN_AT_DHK',
  LOGIN_AT_ESOLUTION: 'LOGIN_AT_ESOLUTION',
  LOGIN_AT_WECHAT: 'LOGIN_AT_WECHAT',
  LOGIN_FAIL_WITH_WRONG_PASSWORD: 'LOGIN_FAIL_WITH_WRONG_PASSWORD',
  LOGIN_LOCKED: 'LOGIN_LOCKED',
  LOGOUT_AT_DHK: 'LOGOUT_AT_DHK',
  LOGOUT_AT_ESOLUTION: 'LOGOUT_AT_ESOLUTION',
  LOGOUT_AT_WECHAT: 'LOGOUT_AT_WECHAT',
  OTP_INPUT_WRONG: 'OTP_INPUT_WRONG',
  FORGOT_PASSWORD_OTP_REQUEST_SUCCESS_SMS:
    'FORGOT_PASSWORD_OTP_REQUEST_SUCCESS_SMS',
  FORGOT_PASSWORD_OTP_REQUEST_FAIL_WITH_RECAPTCHA_SCORE_SMS:
    'FORGOT_PASSWORD_OTP_REQUEST_FAIL_WITH_RECAPTCHA_SCORE_SMS',
  FORGOT_PASSWORD_OTP_REQUEST_SUCCESS_WHATSAPP:
    'FORGOT_PASSWORD_OTP_REQUEST_SUCCESS_WHATSAPP',
  FORGOT_PASSWORD_OTP_REQUEST_FAIL_WITH_RECAPTCHA_SCORE_WHATSAPP:
    'FORGOT_PASSWORD_OTP_REQUEST_FAIL_WITH_RECAPTCHA_SCORE_WHATSAPP',
  FORGOT_PASSWORD_OTP_REQUEST_SUCCESS_VOICE:
    'FORGOT_PASSWORD_OTP_REQUEST_SUCCESS_VOICE',
  FORGOT_PASSWORD_OTP_REQUEST_FAIL_WITH_RECAPTCHA_SCORE_VOICE:
    'FORGOT_PASSWORD_OTP_REQUEST_FAIL_WITH_RECAPTCHA_SCORE_VOICE',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  COUPON_ACQUIRE_SUCCESS: 'COUPON_ACQUIRE_SUCCESS',
  COUPON_ACQUIRE_FAIL_WITH_OUT_OF_STOCK:
    'COUPON_ACQUIRE_FAIL_WITH_OUT_OF_STOCK',
  COUPON_ACQUIRE_FAIL_WITH_WRONG_COUNTRY_CODE:
    'COUPON_ACQUIRE_FAIL_WITH_WRONG_COUNTRY_CODE',
  COUPON_ACQUIRE_FAIL_WITH_CAMPAIGN_EXPIRED:
    'COUPON_ACQUIRE_FAIL_WITH_CAMPAIGN_EXPIRED',
  COUPON_ACQUIRE_FAIL_WITH_EXCEED_PER_HEAD_LIMIT:
    'COUPON_ACQUIRE_FAIL_WITH_EXCEED_PER_HEAD_LIMIT',
  COUPON_ACQUIRE_FAIL_WITH_EXCEED_PER_HEAD_OWNED_LIMIT:
    'COUPON_ACQUIRE_FAIL_WITH_EXCEED_PER_HEAD_OWNED_LIMIT',
  COUPON_ACQUIRE_FAIL_WITH_EXCEED_START_PER_HEAD_OWNED_LIMIT:
    'COUPON_ACQUIRE_FAIL_WITH_EXCEED_START_PER_HEAD_OWNED_LIMIT',
  COUPON_ACQUIRE_FAIL_WITH_LACK_CREDIR_OR_QUOTA:
    'COUPON_ACQUIRE_FAIL_WITH_LACK_CREDIR_OR_QUOTA',
  COUPON_ACQUIRE_FAIL_WITH_EXCEED_ACQUIRE_PERIOD:
    'COUPON_ACQUIRE_FAIL_WITH_EXCEED_ACQUIRE_PERIOD',
  GOODIE_BAG_ACQUIRE_SUCCESS: 'GOODIE_BAG_ACQUIRE_SUCCESS',
  GOODIE_BAG_ACQUIRE_FAIL_WITH_WRONG_COUNTRY_CODE:
    'GOODIE_BAG_ACQUIRE_FAIL_WITH_WRONG_COUNTRY_CODE',
  GOODIE_BAG_ACQUIRE_FAIL_WITH_OUT_OF_STOCK:
    'GOODIE_BAG_ACQUIRE_FAIL_WITH_OUT_OF_STOCK',
  GOODIE_BAG_ACQUIRE_FAIL_WITH_EXCEED_PER_HEAD_LIMIT:
    'GOODIE_BAG_ACQUIRE_FAIL_WITH_EXCEED_PER_HEAD_LIMIT',
  GOODIE_BAG_ACQUIRE_FAIL_WITH_EXCEED_GROUP_PER_HEAD_LIMIT:
    'GOODIE_BAG_ACQUIRE_FAIL_WITH_EXCEED_GROUP_PER_HEAD_LIMIT',
  GOODIE_BAG_ACQUIRE_FAIL_WITH_ACQUIRED_BY_USER:
    'GOODIE_BAG_ACQUIRE_FAIL_WITH_ACQUIRED_BY_USER',
  GOODIE_BAG_ACQUIRE_FAIL_WITH_ACQUIRED_BY_OTHERS:
    'GOODIE_BAG_ACQUIRE_FAIL_WITH_ACQUIRED_BY_OTHERS',
  GOODIE_BAG_ACQUIRE_FAIL_WITH_EXPIRED: 'GOODIE_BAG_ACQUIRE_FAIL_WITH_EXPIRED',
  GOODIE_BAG_ACQUIRE_FAIL_WITH_NOT_ACTIVE_YET:
    'GOODIE_BAG_ACQUIRE_FAIL_WITH_NOT_ACTIVE_YET',
  COUPON_USE_SUCCESS: 'COUPON_USE_SUCCESS',
  COUPON_USE_FAIL_WITH_CAMERA_PERMISSION_DECLINED:
    'COUPON_USE_FAIL_WITH_CAMERA_PERMISSION_DECLINED',
  COUPON_USE_FAIL_WITH_BLACKOUT_PERIOD: 'COUPON_USE_FAIL_WITH_BLACKOUT_PERIOD',
  COUPON_USE_FAIL_WITH_NOT_ACTIVE_YET: 'COUPON_USE_FAIL_WITH_NOT_ACTIVE_YET',
  COUPON_USE_FAIL_WITH_EXPIRED: 'COUPON_USE_FAIL_WITH_EXPIRED',
  COUPON_USE_FAIL_WITH_SCAN_WRONG_STORE_CODE:
    'COUPON_USE_FAIL_WITH_SCAN_WRONG_STORE_CODE',
  COUPON_USE_FAIL_WITH_SCAN_INVALID_QR_CODE:
    'COUPON_USE_FAIL_WITH_SCAN_INVALID_QR_CODE',
  COUPON_USE_FAIL_WITH_USED: 'COUPON_USE_FAIL_WITH_USED',
  PASSWORD_UPDATE_SUCCESS: 'PASSWORD_UPDATE_SUCCESS',
  PASSWORD_UPDATE_FAIL: 'PASSWORD_UPDATE_FAIL',
  CUSTOMER_REQUEST_TO_DELETE: 'CUSTOMER_REQUEST_TO_DELETE',
  CUSTOMER_CONFIRM_TO_DELETE: 'CUSTOMER_CONFIRM_TO_DELETE',
  CUSTOMER_ACTIVE: 'CUSTOMER_ACTIVE',
  CUSTOMER_DEACTIVE: 'CUSTOMER_DEACTIVE',
  CUSTOMER_UNLOCK: 'CUSTOMER_UNLOCK',
  COUPON_ACTIVE: 'COUPON_ACTIVE',
  COUPON_DEACTIVE: 'COUPON_DEACTIVE',
  COUPON_GRANT: 'COUPON_GRANT',
  COUPON_RECLAIM: 'COUPON_RECLAIM',
  COUPON_EXPIRY_DATE_EXTEND: 'COUPON_EXPIRY_DATE_EXTEND',
  GOODIE_BAG_EXPIRY_DATE_EXTEND: 'GOODIE_BAG_EXPIRY_DATE_EXTEND',
  LOGOUT_BY_FORCE: 'LOGOUT_BY_FORCE',
  COUPON_EXPITED: 'COUPON_EXPITED',
  GOODIE_BAG_EXPIRED: 'GOODIE_BAG_EXPIRED',
  CUSTOMER_PROFILE_UPDATE: 'CUSTOMER_PROFILE_UPDATE',
  CUSTOMER_PROFILE_UPDATE_FAIL: 'CUSTOMER_PROFILE_UPDATE_FAIL',
  CUSTOMER_DELETE: 'CUSTOMER_DELETE',
  SEND_EMAIL_ADDRESS_VERIFICATION_EMAIL:
    'SEND_EMAIL_ADDRESS_VERIFICATION_EMAIL',
  SEND_EMAIL_ADDRESS_VERIFICATION_EMAIL_MANUAL:
    'SEND_EMAIL_ADDRESS_VERIFICATION_EMAIL_MANUAL',
  EMAIL_ADDRESS_VERIFICATION_SUCCESS: 'EMAIL_ADDRESS_VERIFICATION_SUCCESS',
  EMAIL_ADDRESS_VERIFICATION_FAIL: 'EMAIL_ADDRESS_VERIFICATION_FAIL',
  EMAIL_ADDRESS_VERIFICATION_FAIL_WITH_EXPIRED_LINK:
    'EMAIL_ADDRESS_VERIFICATION_FAIL_WITH_EXPIRED_LINK',
  EMAIL_ADDRESS_VERIFICATION_FAIL_WITH_VERIFIED_BY_USER:
    'EMAIL_ADDRESS_VERIFICATION_FAIL_WITH_VERIFIED_BY_USER',
  EMAIL_ADDRESS_VERIFICATION_FAIL_WITH_VERIFIED_BY_OTHERS:
    'EMAIL_ADDRESS_VERIFICATION_FAIL_WITH_VERIFIED_BY_OTHERS',
  CUSTOMER_PROFILE_UPDATE_WITH_OTP_IN: 'CUSTOMER_PROFILE_UPDATE_WITH_OTP_IN',
  CUSTOMER_PROFILE_UPDATE_WITH_OTP_OUT: 'CUSTOMER_PROFILE_UPDATE_WITH_OTP_OUT',
  CUSTOMER_PROFILE_UPDATE_WITH_EMAIL_ADD_SUCCESS:
    'CUSTOMER_PROFILE_UPDATE_WITH_EMAIL_ADD_SUCCESS',
  CUSTOMER_PROFILE_UPDATE_WITH_EMAIL_ADD_FAIL_INVALID:
    'CUSTOMER_PROFILE_UPDATE_WITH_EMAIL_ADD_FAIL_INVALID',
  CUSTOMER_PROFILE_UPDATE_WITH_EMAIL_ADD_FAIL_USED:
    'CUSTOMER_PROFILE_UPDATE_WITH_EMAIL_ADD_FAIL_USED',
  CUSTOMER_PROFILE_UPDATE_WITH_EMAIL_ADD_FAIL_EXCEED_LIMIT: '',
  CUSTOMER_PROFILE_UPDATE_WITH_EMAIL_UPDATE_SUCCESS:
    'CUSTOMER_PROFILE_UPDATE_WITH_EMAIL_UPDATE_SUCCESS',
  CUSTOMER_PROFILE_UPDATE_WITH_EMAIL_UPDATE_FAIL_INVALID:
    'CUSTOMER_PROFILE_UPDATE_WITH_EMAIL_UPDATE_FAIL_INVALID',
  CUSTOMER_PROFILE_UPDATE_WITH_EMAIL_UPDATE_FAIL_USED:
    'CUSTOMER_PROFILE_UPDATE_WITH_EMAIL_UPDATE_FAIL_USED',
  CUSTOMER_PROFILE_UPDATE_WITH_EMAIL_UPDATE_FAIL_EXCEED_LIMIT:
    'CUSTOMER_PROFILE_UPDATE_WITH_EMAIL_UPDATE_FAIL_EXCEED_LIMIT',
  CUSTOMER_PROFILE_UPDATE_WITH_EMAIL_REMOVE_VERIFIED:
    'CUSTOMER_PROFILE_UPDATE_WITH_EMAIL_REMOVE_VERIFIED',
  CUSTOMER_PROFILE_UPDATE_WITH_EMAIL_REMOVE_UNVERIFIED:
    'CUSTOMER_PROFILE_UPDATE_WITH_EMAIL_REMOVE_UNVERIFIED',
};

export const GoodieBagRewardType = {
  allTypes: 'all',
  cashVoucher: 'CASH_VOUCHER',
  gift: 'GIFT',
  both: 'BOTH',
  fortuneBag: 'FORTUNE_BAG',
};

export const CampaignType = {
  allTypes: 'all',
  generalMessageCampaign: 'GENERAL_MESSAGE_CAMPAIGN',
  earningRulesCampaign: 'EARNING_CAMPAIGN',
  couponsCampaign: 'COUPON_CAMPAIGN',
  fortuneBagCampaign: 'FORTUNE_BAG_CAMPAIGN',
  cashVoucherCampaign: 'CASH_VOUCHER_CAMPAIGN_FOR_GOODIE_BAG',
  giftCampaign: 'GIFT_CAMPAIGN_FOR_GOODIE_BAG',
  fortuneBagForGoodieBagCampaign: 'FORTUNE_CAMPAIGN_FOR_GOODIE_BAG',
};

export const COUPON_SET_TYPES = {
  SYSTEM_GENERATED: 'SYSTEM_GENERATED',
  IMPORT_EXISTING_COUPON: 'IMPORT_EXISTING_COUPON',
};
export const COUPON_REDEMPTION_METHOD = {
  MERCHANT_APP_SCAN_COUPON_QR_CODE: 'MERCHANT_APP_SCAN_COUPON_QR_CODE',
  USERS_SCAN_STORE_QR_CODE: 'USERS_SCAN_STORE_QR_CODE',
  USERS_COPY_THE_DISCOUNT_CODE: 'USERS_COPY_THE_DISCOUNT_CODE',
};

export const COUPON_FORMATS = {
  COUPON_QR_CODE: 'COUPON_QR_CODE',
  COUPON_BAR_CODE: 'COUPON_BAR_CODE',
};

export const COUPON_CODE_TYPE = {
  GENERIC: 'GENERIC',
  UNIQUE: 'UNIQUE',
};

export const COUPON_LOW_STOCK = {
  SPECIFIC_VALUE: 'SPECIFIC_VALUE',
  SPECIFIC_PERCENTAGE: 'SPECIFIC_PERCENTAGE',
};

export const StepStatus = {
  error: 'error',
  processing: 'processing',
  finished: 'finished',
  waiting: 'waiting',
};

export const LanguageConfig = {
  english: 'en',
  simplifiedChinese: 'zh-Hans',
  traditionalChinese: 'zh-Hant',
};

export const LanguageUpperConfig = {
  english: 'EN',
  simplifiedChinese: 'ZH_HANS',
  traditionalChinese: 'ZH_HANT',
};

export const EarningRuleType = {
  allTypes: 'all',
  generalPurchase: 'GENERAL_PURCHASE',
  memberReferral: 'MEMBER_REFERRAL',
  birthday: 'BIRTHDAY',
  qrCodeScanning: 'QR_CODE_SCANNING',
  gpsCheckIn: 'GPS_CHECK_IN',
  newMember: 'NEW_MEMBER',
};

export const EarningRuleTypeKey = {
  All: 'allTypes',
  GENERAL_PURCHASE: 'generalPurchase',
  MEMBER_REFERRAL: 'memberReferral',
  BIRTHDAY: 'birthday',
  QR_CODE_SCANNING: 'qrCodeScanning',
  GPS_CHECK_IN: 'gpsCheckIn',
  NEW_MEMBER: 'newMember',
};

export const TermsAndConditionsType = {
  merchant: 'FOR_MERCHANT',
  generic: 'FOR_GENERIC',
};

export const PaymentSettlementType = {
  byMonthly: 'MONTHLY',
  byMerchant: 'MERCHANT',
};

export const DisplayPaymentSettlementReviewStatusType = {
  waiting: 'Waiting for review',
  disagree: 'Merchant disagreed',
  agree: 'Merchant agreed',
};

export const PaymentAndSettlementReviewStatusType = {
  waiting: 'WAITING_FOR_REVIEW',
  disagree: 'MERCHANT_DISAGREED',
  agree: 'MERCHANT_AGREED',
};

export const LanguageTabTransLation = {
  english: 'English',
  simplifiedChinese: '简体',
  traditionalChinese: '繁体',
};

export const LanguageTransLation = {
  en: 'English',
  'zh-Hans': '简体',
  'zh-Hant': '繁体',
};

export const AmOrPm = {
  am: 'am',
  pm: 'pm',
};

export const SavingStatus = {
  onSaving: 'onSaving',
  onFinished: 'onFinished',
};

export const APIStatus = {
  success: 'success',
  calling: 'calling',
  failed: 'failed',
  none: 'none',
};

export const MessageChannel = {
  push: 'Push',
  email: 'Email',
  sms: 'SMS',
  web: 'Web',
  inbox: 'Inbox',
};

export const CheckStatus = {
  initOrNotChecked: -1,
  checkedWithFail: 0,
  checkedWithSuccess: 1,
};

export const SavedStatus = {
  init: -1,
  savedWithFail: 0,
  savedWithSuccess: 1,
  saving: 2,
};

export const EarningRuleRewardType = {
  coupons: 'COUPON',
  points: 'POINTS',
};

export const StatusTag = {
  active: 'Active',
  activeRequired: 'Active(Acquired)',
  deactive: 'Deactive',
  inactive: 'Inactive',
  inactiveByCustomer: 'Inactive (by customer)',
  inactiveByAdmin: 'Inactive (by admin)',
  inactiveAsAccountLocked: 'Inactive (Account is locked)',
  acquired: 'Acquired',
  linkedFortuneBag: 'Linked to Goodie bag offer (Direct assign)',
  linkedGoodieBag: 'Linked to Goodie Bag',
  deleted: 'Deleted',
  expired: 'Expired',
};

export const YES_OR_NO_TAG = {
  yes: 'Yes',
  no: 'No',
};

export const PublishTagType = {
  published: 'Published',
  unPublished: 'Unpublished',
  expired: 'Expired',
};

export const DownloadTag = {
  completed: 'Exported',
  cancelled: 'Cancelled',
  downloading: 'Exporting',
  importing: 'Importing',
  importCompleted: 'Imported',
  updated: 'Updated',
  error: 'Error',
  generating: 'Generating',
  generated: 'Generated',
  pendingForImport: 'Pending',
  pendingForExport: 'Pending',
  pendingForGenerate: 'Pending',
  scheduledImport: 'Scheduled import',
};

export const MessageTag = {
  scheduled: 'Scheduled',
  saved: 'Saved',
  sent: 'Sent',
  sending: 'Sending',
  error: 'Failed',
  expired: 'Expired',
};

export const ConditionTag = {
  EQUAL_TO: 'Equal to',
  NOT_EQUAL_TO: 'Not equal to',
  IS_EMPTY: 'Is empty',
  IS_NOT_EMPTY: 'Is not empty',
  BETWEEN: 'Between',
  LESS_THAN: 'Less than',
  GREATER_THAN: 'greater than',
  IN: 'In',
};

export const DaysTag = {
  '1_DAY': '1 day',
  '7_DAYS': '7 days',
  '1_MONTH': '1 month',
  '3_MONTHS': '3 months',
  '6_MONTHS': '6 months',
  '1_YEAR': '1 year',
};

const domain =
  process.env.REACT_APP_IMAGE || 'https://nginx.aillia.hkdev.motherapp.com';

export const ImageDomain = `${domain}/media/`;

export const GenderOptions = [
  { name: 'Male', value: 'MALE' },
  { name: 'Female', value: 'FEMALE' },
  { name: 'Prefer not to say', value: 'NOT_DISCLOSED' },
];

export const MonthOptions = [
  { name: 'January', value: '1' },
  { name: 'February', value: '2' },
  { name: 'March', value: '3' },
  { name: 'April', value: '4' },
  { name: 'May', value: '5' },
  { name: 'June', value: '6' },
  { name: 'July', value: '7' },
  { name: 'August', value: '8' },
  { name: 'September', value: '9' },
  { name: 'October', value: '10' },
  { name: 'November', value: '11' },
  { name: 'December', value: '12' },
];

export const CountryCodeOptions = [
  { name: '+852', value: 852 },
  { name: '+853', value: 853 },
  { name: '+86', value: 86 },
];

export const BANNER_CONTENT_TYPE = {
  ALL_TYPE: 'all',
  CAMPAIGN: 'CAMPAIGN',
  PRODUCT: 'PRODUCT',
};

export const POINT_RECORD_TYPES = [
  { key: 'TYPE_POINT_ADD', name: 'Add Points' },
  { key: 'TYPE_POINT_REMOVE', name: 'Remove Points' },
];

export const COUPON_TEMPLATE_TYPE = {
  storeCode: 'STORE_CODE',
  couponCode: 'COUPON_CODE',
  couponQRCode: 'COUPON_QR_CODE',
  couponCodeImage: 'COUPON_CODE_IMAGE',
};

export const COUPON_TEMPLATE_TYPE_NAME = {
  STORE_CODE: 'Store code',
  COUPON_CODE: 'Coupon code',
  COUPON_QR_CODE: 'Coupon QR code',
  COUPON_CODE_IMAGE: 'Coupon code image',
};

export const DELETE_RELATED_SECTIONS = {
  CUSTOMER: [
    'AppInstallation',
    'CampaignParticipationRecord',
    'Coupon',
    'CouponTransaction',
    'PointTransaction',
    'Transaction',
  ],
  SEGMENT: ['Campaign'],
  CUSTOMER_GROUP: ['Campaign', 'ParticipationCustomer'],
  CAMPAIGN: [
    'Message',
    'CampaignParticipationRecord',
    'CouponTransaction',
    'PointsTransaction',
    'Segment Campaign Characteristic',
    'Banner',
  ],
  CAMPAIGN_CATEGORY: ['Campaign'],
  MESSAGE: ['InboxMessage'],
  EARNING_RULE: ['Campaign'],
  COUPON_TEMPLATE: ['Campaign', 'Coupon', 'EarningRule'],
  COUPON: ['CouponTransaction'],
  COUPON_LABEL: ['GoodieBag'],
  BRAND: ['Campaign', 'CouponTemplate', 'Store'],
  STORE: ['CampaignParticipationRecord', 'Coupon', 'Transaction'],
  STORE_CATEGORY: ['StoreSubcategory'],
  TRANSACTION: [
    'CampaignParticipationRecord',
    'PurchasedItem',
    'Coupon transaction',
    'Point transaction',
  ],
  POINT_RECORD: [],
  COUPON_RECORD: [],
  ADMIN_GROUP: ['ParticipationAdmin'],
  ADMIN: ['CouponTransaction', 'ImportJob', 'ExportJob'],
  BANNER: [],
  GENERAL_ALERT: ['General Alert'],
};

export const ADMIN_TYPE = {
  ALL_TYPE: 'all',
  HKTB_ADMIN: 'HKTB_ADMIN',
  MERCHANT_ADMIN: 'MERCHANT_ADMIN',
  MERCHANT_APP_ACCOUNT: 'MERCHANT_APP_ACCOUNT',
};

export const EMAIL_REG =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const CUSTOMER_ID_TYPE = {
  EMAIL: 'Email Address',
  MOBILE_NUMBER: 'Mobile No.',
};
export const SESSION_KEYS = {
  ADMIN_GROUP_SESSION_KEY: 'tempAdminGroup',
  ADMIN_SESSION_KEY: 'tempAdmin',
  ATTRIBUTE_TAG_KEY_SESSION_KEY: 'attributeTagKey',
  ATTRIBUTE_TAG_KEY_DATA_SESSION_KEY: 'attributeTagKeys',
  ATTRIBUTE_TAG_SESSION_KEY: 'attributeTag',
  ATTRIBUTE_TAG_DATA_SESSION_KEY: 'attributeTags',
  CREATE_BRAND_SESSION_KEY: 'createBrand',
  CREATE_BRAND_END_SESSION_KEY: 'createBrandStepEnd',
  CREATE_BRAND_ORIGIN_DATA_SESSION_KEY: 'origionalData',
  CREATE_CAMPAIGN_SESSION_KEY: 'createCampaign',
  CREATE_CAMPAIGN_PERVIEW_SESSION_KEY: 'createCampaignPreviewData',
  CREATE_FORTUNE_COUPONS: 'fortuneBagCoupons',
  CREATE_GOODIE_BAG: 'createGoodieBag',
  GOODIE_BAG_GROUP_SESSION_KEY: 'goodieBagGroup',
  CREATE_CAMPAIGN_END_SESSION_KEY: 'createCampaignStepEnd',
  CREATE_CAMPAIGN_ORIGIN_DATA_SESSION_KEY: 'createCampaignOriginalData',
  CREATE_COUPON_SESSION_KEY: 'createCoupon',
  CREATE_COUPON_END_SESSION_KEY: 'createCouponStepEnd',
  CREATE_COUPON_ORIGIN_DATA_SESSION_KEY: 'createCouponOriginalData',
  CAMPAIGN_LIST_SESSION_KEY: 'campaigns',
  CHANNEL_SESSION_KEY: 'channel',
  CHANNEL_DATA_SESSION_KEY: 'channels',
  CAMPAIGN_CATEGORY_SESSION_KEY: 'campaignCategoryKey',
  EARNING_RULE_SESSION_KEY: 'tempEarningRule',
  CREATE_MESSAGE_SESSION_KEY: 'createMessage',
  CREATE_MESSAGE_END_SESSION_KEY: 'createMessageStepEnd',
  CREATE_MESSAGE_ORIGIN_DATA_SESSION_KEY: 'createMessageOriginalData',
  CREATE_POINT_SESSION_KEY: 'createPoint',
  CREATE_POINT_END_SESSION_KEY: 'createPointStepEnd',
  CREATE_POINT_ORIGIN_DATA_SESSION_KEY: 'createPointOriginalData',
  STORE_CATEGORY_SESSION_KEY: 'storeCategoryKey',
  CREATE_TRANSACTION_SESSION_KEY: 'createTransaction',
  CREATE_TRANSACTION_END_SESSION_KEY: 'createTransactionStepEnd',
  CREATE_TRANSACTION_ORIGIN_DATA_SESSION_KEY: 'createTransactionOriginalData',
  CREATE_CUSTOMER_GROUP_SESSION_KEY: 'createCustomerGroup',
  CREATE_CUSTOMER_GROUP_END_SESSION_KEY: 'createCustomerGroupStepEnd',
  CREATE_CUSTOMER_GROUP_ORIGIN_DATA_SESSION_KEY:
    'createCustomerGroupOriginalData',
  TEMP_CUSTOMER_SESSION_KEY: 'tempCustomer',
  ENTITLEMENT_CUSTOMER_SESSION_KEY: 'entitlementCustomer',
  ENTITLEMENT_CUSTOMERS_SESSION_KEY: 'entitlementCustomers',
  ENTITLEMENT_TRACKING_CODE_SESSION_KEY: 'entitlementTrackingCode',
  ENTITLEMENT_TRACKING_CODES_SESSION_KEY: 'entitlementTrackingCodes',
  CREATE_GENERAL_ALERT_SESSION_KEY: 'createGeneralAlert',
  CREATE_GENERAL_ALERT_END_SESSION_KEY: 'createGeneralAlertStepEnd',
  CREATE_GENERAL_ALERT_ORIGIN_DATA_SESSION_KEY:
    'createGeneralAlertOriginalData',
  BANNER_SESSION_KEY: 'bannerKey',
  INTEREST_PREFERENCE_SESSION_KEY: 'createInterestPreference',
  LANGUAGE_SESSION_KEY: 'language',
  LANGUAGE_DATA_SESSION_KEY: 'languages',
  LOGO_LABEL_SESSION_KEY: 'createLogoLabel',
  OFFER_TYPE_SESSION_KEY: 'offerTypeLabel',
  OFFER_TYPE_DATA_SESSION_KEY: 'offerTypeLabels',
  SEGMENT_SESSION_KEY: 'tempSegment',
  STORE_SESSION_KEY: 'tempStore',
  SURVEY_FORM_SESSION_KEY: 'surveyForms',

  TARGET_MARKET_SESSION_KEY: 'targetMarket',
  TARGET_MARKET_DATA_SESSION_KEY: 'targetMarkets',
  TOUR_CARD_SESSION_KEY: 'createTourCard',
  TERMS_AND_CONDITIONS_SESSION_KEY: 'createTermsAndConditions',

  OTP_CHANNEL_DATA_SESSION_KEY: 'otpChannels',
  OTP_CHANNEL_SESSION_KEY: 'otpChannels',

  WORKING_TEAM_DATA_SESSION_KEY: 'workingTeams',
  WORKING_TEAM_SESSION_KEY: 'workingTeams',

  RATE_LIMIT_DATA_SESSION_KEY: 'rateLimits',
  RATE_LIMIT_SESSION_KEY: 'rateLimits',
  COUPON_LABEL_DATA_SESSION_KEY: 'couponLabels',
  COUPON_LABEL_SESSION_KEY: 'couponLabels',
};

export const STORE_LOADING_TYPE = {
  BRAND: 'brands',
  CAMPAIGN: 'campaignIn',
  COUPON: 'couponTemplateIn',
};

export const TokenErrors = {
  Expiry: 'Signature has expired',
  DecodingError: 'Error decoding signature',
  RefreshTokenInvalid: 'Invalid refresh token',
  RefreshTokenExpired: 'Refresh token is expired',
  NoPermission: 'You do not have permission to perform this action',
};

export const AdminLoginMethod = {
  All: 'ALL',
  Saml: 'SAML',
};

export const UsedPlatform = {
  CustomerApp: { key: 'CUSTOMER_APP', value: 'Used in Customer App' },
  MerchantApp: { key: 'MERCHANT_APP', value: 'Used by Merchant App' },
  CMS: { key: 'CMS', value: 'Used by CMS' },
  MerchantAppPhysical: {
    key: 'MERCHANT_APP_PHYSICAL',
    value: 'Used by Merchant app (physical coupon)',
  },
};

export const CouponMediumType = {
  Both: 'BOTH',
  EWallet: 'EWALLET',
  Physical: 'PHYSICAL',
};

export const DisplayCouponMediumType = {
  BOTH: 'Both eWallet and Physical',
  EWALLET: 'eWallet only',
  PHYSICAL: 'Physical only',
};

export const CouponBatchExportType = {
  Image: 'PHYSICAL_COUPON_QRCODE_IMAGE',
  Template: 'PHYSICAL_COUPON_QRCODE_IMAGE_AND_TEMPLATE',
  CSV: 'PHYSICAL_COUPON_QRCODE_CSV',
};

export const ExportType = {
  QRCODE_IMAGE: 'QRCODE_IMAGE',
  QRCODE_CSV: 'QRCODE_CSV',
};

export const DisplayAdminType = [
  { label: 'HKTB administrator', value: ADMIN_TYPE.HKTB_ADMIN },
  { label: 'Merchant administrator', value: ADMIN_TYPE.MERCHANT_ADMIN },
  { label: 'Merchant app account', value: ADMIN_TYPE.MERCHANT_APP_ACCOUNT },
];

export const UsedMethodType = {
  MERCHANT_APP_SCAN_COUPON_QR_CODE: 'MERCHANT_APP_SCAN_COUPON_QR_CODE',
  USERS_SCAN_STORE_QR_CODE: 'USERS_SCAN_STORE_QR_CODE',
  USERS_COPY_THE_DISCOUNT_CODE: 'USERS_COPY_THE_DISCOUNT_CODE',
  MERCHANT_SCAN_QR_CODE: 'MERCHANT_SCAN_QR_CODE',
  MERCHANT_INPUT_SRK_CODE: 'MERCHANT_INPUT_SRK_CODE',
  MERCHANT_ADMIN_INPUT_SRK_CODE: 'MERCHANT_ADMIN_INPUT_SRK_CODE',
  HKTB_ADMIN_INPUT_SRK_CODE: 'HKTB_ADMIN_INPUT_SRK_CODE',
};

export const DisplayUseMethod = {
  [UsedMethodType.MERCHANT_APP_SCAN_COUPON_QR_CODE]:
    'Mechant app scan Coupon QR code',
  [UsedMethodType.USERS_SCAN_STORE_QR_CODE]: 'Users scan Store QR code',
  [UsedMethodType.USERS_COPY_THE_DISCOUNT_CODE]:
    'Users reveal the coupon (discount code, URL, QR code/Barcode)',
  [UsedMethodType.MERCHANT_SCAN_QR_CODE]: "Merchant scan user's coupon QR code",
  [UsedMethodType.MERCHANT_INPUT_SRK_CODE]:
    "Merchant input user's coupon SRK code",
  [UsedMethodType.MERCHANT_ADMIN_INPUT_SRK_CODE]:
    "Merchant admin input user's coupon SRK code",
  [UsedMethodType.HKTB_ADMIN_INPUT_SRK_CODE]:
    "HKTB admin input user's coupon SRK code",
};

export const DefaultLanguages = [
  { label: 'English', value: 'en' },
  { label: 'Traditional Chinese', value: 'zh-Hant' },
  { label: 'Simplified Chinese', value: 'zh-Hans' },
];

export const CouponTransactionBy = {
  SYSTEM: 'System',
  ADMIN: 'Admin',
  CUSTOMER: 'Customer',
};
