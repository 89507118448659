import React from 'react';
// import 'codemirror/lib/codemirror.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';
import './CustomEditorV2.scss';
import { isLongScreen } from '../../utils/ScreenUtil';
import { findDOMNode } from 'react-dom';
const showdown = require('showdown');
const converter = new showdown.Converter();
// const toolBars = [
//   'heading',
//   'bold',
//   'italic',
//   'quote',
//   'divider',
//   'ul',
//   'ol',
//   'link',
//   'htmlInline',
//   'redo',
//   'undo',
// ];
const toolBars = [
  ['heading', 'bold', 'italic'],
  ['quote'],
  ['ul', 'ol'],
  ['link'],
];
class CustomEditor extends React.Component {
  constructor(props) {
    super(props);
    this.editorRef = React.createRef();
    this.initialValue = props.initialValue;
    // this.resetInit = props.resetInit || (() => {});
    // this.resetInitByTC = props.resetInitByTC || (() => {});
    this.onValueChange = props.onValueChange || (() => {});
    this.errorMessage = props.errorMessage;
    this.onFocus = props.onFocus || (() => {});
    this.toolBars = props.toolBars;

    this.state = { editorValue: props.initialValue };
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.initialValue &&
      this.props.initialValue &&
      this.editorRef.current.getInstance().getHTML() !== this.props.initialValue
    ) {
      this.editorRef.current.getInstance().setHTML(this.props.initialValue);
    }

    if (
      prevProps.initialValue &&
      this.props.initialValue !== prevProps.initialValue &&
      this.editorRef.current.getInstance().getHTML() !== this.props.initialValue
    ) {
      this.editorRef.current.getInstance().setHTML(this.props.initialValue);
    }
    // if (!prevProps.resetInitValue && this.props.resetInitValue) {
    //   this.editorRef.current.getInstance().setHTML(this.props.initialValue);
    //   this.resetInit();
    // }
    // if (!prevProps.resetInitValueByTC && this.props.resetInitValueByTC) {
    //   this.editorRef.current.getInstance().setHTML(this.props.initialValue);
    //   this.resetInitByTC();
    // }

    if (prevProps.disabled !== this.props.disabled || this.props.disabled) {
      this.editorRef.current
        .getInstance()
        .wwEditor.el.parentNode.parentNode.parentNode.parentNode.querySelectorAll(
          'div.toastui-editor-toolbar-group',
        )
        .forEach((group) =>
          group
            .querySelectorAll('button')
            .forEach((button) => (button.disabled = this.props.disabled)),
        );

      this.editorRef.current
        .getInstance()
        .wwEditor.el.querySelectorAll('div.ProseMirror.toastui-editor-contents')
        .forEach((editor) =>
          editor.setAttribute('contenteditable', !this.props.disabled),
        );

      // [
      //   ...findDOMNode(this.editorRef.current.getInstance()._ui._toolbar.el)
      //     .children,
      // ].forEach((element) => {
      //   if (element.tagName === 'BUTTON')
      //     element.disabled = this.props.disabled;
      // });

      // [
      //   ...findDOMNode(
      //     this.editorRef.current.getInstance()._ui._editor.wwEditor
      //       .editorContainerEl,
      //   ).children,
      // ].forEach((element) => {
      //   if (element.hasAttribute('contenteditable'))
      //     element.setAttribute('contenteditable', !this.props.disabled);
      // });
    }
  }

  render() {
    // console.log('@@custom-editor:init: ', this.props.initialValue);
    return (
      <>
        <div
          className={`customer-editor-container ${
            this.props.disabled ? 'editor-disabled' : ''
          } ${this.props.error ? 'editor-container-error' : ''} ${
            isLongScreen ? '' : 'customer-editor-container-short '
          }`}
          onFocus={() => this.onFocus()}
        >
          <Editor
            // onChange={(e, v) => {
            //   console.log('@@custom-editor:onchange: ', e, v);
            //   // const originMarkdown = this.editorRef.current
            //   //   .getInstance()
            //   //   .getMarkdown();
            //   // const markdown = converter.makeHTML(originMarkdown);
            //   const markdown = this.editorRef.current.getInstance().getHTML();
            // }}
            // onBlur={() => {
            //   this.onValueChange(this.state.editorValue)
            // }}
            onChange={() => {
              const htmlValue = this.editorRef.current
                ?.getInstance()
                ?.getHTML();
              const cleandHtml = htmlValue?.replace(/<\/?[^>]+(>|$)/g, '');
              console.log('@@119: ', cleandHtml);
              if (
                htmlValue !== this.state.editorValue &&
                (htmlValue || this.state.editorValue)
              ) {
                if (
                  htmlValue !== this.props.initialValue &&
                  (htmlValue || this.props.initialValue)
                ) {
                  this.onValueChange(cleandHtml ? htmlValue : '');
                }

                this.setState({
                  editorValue: cleandHtml ? htmlValue : '',
                });
                //.replaceAll(
                //   '<br data-tomark-pass=""><br>',
                //   '<br>',
                // ),
              } else if (!cleandHtml) {
                this.onValueChange('');
                // this.setState({
                //   editorValue: cleandHtml ? htmlValue : '',
                // });
              }
            }}
            initialValue={this.props.initialValue || ''}
            previewStyle="vertical"
            height="270px"
            useCommandShortcut={true}
            initialEditType="wysiwyg"
            hideModeSwitch={true}
            toolbarItems={this.toolBars || toolBars}
            ref={this.editorRef}
            autofocus={false}
            // customHTMLRenderer={{
            //   paragraph(node, { origin, options }) {
            //     // const { customProp = {} } = options;
            //     // const showFrontMatter =
            //     //   customProp.showFrontMatter && node.customType;
            //     // const attributes = {};

            //     // // prevent to edit the front matter in WYSIWYG
            //     // if (showFrontMatter) {
            //     //   attributes.contenteditable = false;
            //     // }

            //     console.log('@@162: ', origin);
            //     return { ...origin() };
            //   },
            // }}
            // extendedAutolinks={true}
            // extendedAutolinks={(content) => {
            //   console.log('@@content: ', content);
            //   const reToastuiEditorRepo = /tui\.editor/g;
            //   const matched = content.match(reToastuiEditorRepo);
            //   console.log('@@matched: ', matched);
            //   if (matched) {
            //     return matched.map((m) => ({
            //       text: 'toastui-editor',
            //       url: 'https://github.com/nhn/tui.editor',
            //       range: [0, 1],
            //     }));
            //   }
            //   return null;
            // }}
            // contenteditable={!this.props.disabled}
            // disabled={true}
          />
          {/* {this.props.disabled ? <div className="editor-disable" /> : null} */}
        </div>
        {this.props.error ? (
          <label className="editor-container-error-message">
            {this.props.errorMessage}
          </label>
        ) : null}
      </>
    );
  }
}

export default CustomEditor;
