import { CreateGoodieBagGroupError } from '../../../models/GoodieBagGroupModel';

function firstStepValidate({ getValues, setError }) {
  const errorList = [];
  const [name, goodieBags, perHeadLimit] = getValues([
    'name',
    'goodieBags',
    'perHeadLimit',
  ]);

  if (!name) {
    errorList.push({
      name: 'name',
      message: CreateGoodieBagGroupError.name.message,
    });
  }

  if (!goodieBags?.length) {
    errorList.push({
      name: 'goodieBags',
      message: CreateGoodieBagGroupError.goodieBags.message,
    });
  }

  if (!perHeadLimit || parseInt(perHeadLimit) < 1) {
    errorList.push({
      name: 'perHeadLimit',
      message: CreateGoodieBagGroupError.perHeadLimit.message,
    });
  }

  errorList.forEach((error) => {
    setError(error.name, {
      type: 'manual',
      message: error.message,
    });
  });
  return !errorList.length;
}

export function validate({ getValues, setError, clearErrors }) {
  console.log('GoodieBagGroupValidate:', getValues());
  clearErrors();
  const valid = firstStepValidate({ getValues, setError });
  return valid;
}
