import React, { useEffect } from 'react';
import BaseOverView from './BaseOverview';
import { SectionTopTitle } from '../../base/CommonComponent';
import './CouponBatchPageCard.scss';
import { Button } from 'react-bootstrap';
import CouponBatchExportPrompt from './CouponBatchExportPrompt';
import { useState } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import CustomPagination from '../../base/CustomPagination';
import { connect, useDispatch } from 'react-redux';
import { CouponMediumType } from '../../../config/CustomEnums';

function CouponBatchPageCard({
  totalCount = 0,
  couponBatches = [],
  template,
  couponSet,
}) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryString = require('query-string');
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState();
  const [exportBatch, setExportBatch] = useState();

  useEffect(() => {
    const searchDict = queryString.parse(location.search);
    const stringRank = searchDict['rank'] || 'true';
    const stringPage = searchDict['page'] || 1;
    const rank = stringRank === 'true';
    const currentPage = parseInt(stringPage);
    setCurrentPage(currentPage - 1);
    console.log('@@29', searchDict, location.search);
    dispatch({
      type: 'couponBatch/getCurrentPageCouponBatches',
      payload: {
        template: id,
        rank,
        page: currentPage,
        couponMediumIn: [CouponMediumType.Physical],
        pageSize: 5,
        isAll: true,
      },
    });
  }, [location]);

  return (
    <div className={'coupon-overview-container'}>
      <div className="second-section">
        <SectionTopTitle title={'Physical coupon generates records'} />
        <table border="1" className="physical-coupon-overview-table">
          <thead>
            <tr>
              <td>Batch no.</td>
              <td>No. of physical coupon generated/imported</td>
              <td>Generate/import time</td>
              <td>Action</td>
            </tr>
          </thead>
          <tbody>
            {couponBatches.map((batch, batchIndex) => {
              const { index, displayCreationDate, quantity } = batch;
              return (
                <tr key={batchIndex}>
                  <td>{index}</td>
                  <td>{quantity}</td>
                  <td>{displayCreationDate}</td>
                  <td>
                    <Button
                      variant="link"
                      className={'physical-coupon-batch-export-button'}
                      onClick={() => {
                        setExportBatch(batch);
                      }}
                    >
                      Export
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {totalCount > 0 ? (
          <div className="all-list-scrollable-area-pagination">
            <CustomPagination
              initPage={currentPage}
              pageSize={Math.ceil(totalCount / 5)}
              pageChange={(page) => {
                setCurrentPage(page);
                history.push({
                  pathname: location.pathname,
                  hash: location.hash,
                  search: queryString.stringify({
                    ...queryString.parse(location.search),
                    page: page + 1,
                  }),
                });
              }}
            />
          </div>
        ) : null}
        <CouponBatchExportPrompt
          exportBatch={exportBatch}
          show={!!exportBatch}
          closeAction={() => {
            setExportBatch(null);
          }}
          template={template}
          couponSet={couponSet}
          exportAction={() => {
            setExportBatch(null);
          }}
        />
      </div>
    </div>
  );
}

const mapPropsToState = (state) => ({
  couponBatches: state.couponBatch.currentPageCouponBatches,
  totalCount: state.couponBatch.totalCount,
});
export default connect(mapPropsToState)(CouponBatchPageCard);
