import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from '../../../utils';
import { CustomTitleWithDropDown } from '../../customer/CustomTitleWithDropDown';
import { useParams } from 'react-router-dom';

function CouponBarcodeSelectDropdown(props) {
  const { id } = useParams();
  const customClass = props.customClass || '';
  const title = props.title;
  const defaultValue = props.defaultValue;
  const onSelectChange = props.onSelectChange || (() => {});
  const onFocus = props.onFocus || (() => {});
  const couponTemplate = props.couponTemplate || {};
  const dispatch = useDispatch();
  const { couponBarcodeList } = useSelector((state) => ({
    couponBarcodeList: state.createCoupon?.couponBarcodeList,
  }));

  useEffect(() => {
    dispatch(createAction('createCoupon/getBarcodeList')({}));
  }, [dispatch]);

  const filterCouponBarcodeList = (list) => {
    if (
      !id ||
      (couponTemplate?.totalNubmerOfGeneratedCoupons < 1 &&
        couponTemplate?.totalNubmerOfGeneratedPhysicalCoupons < 1)
    ) {
      return list;
    }
    const barcodeFormat = couponTemplate?.barcodeFormat?.name;
    let canSelectedBarcodeFormats = ['Code 128'];
    if (['EAN-8', 'EAN-13', 'Code 39', 'UPC-A'].includes(barcodeFormat)) {
      canSelectedBarcodeFormats = [...canSelectedBarcodeFormats, 'Code 39'];
    }

    if (!canSelectedBarcodeFormats.includes(barcodeFormat)) {
      canSelectedBarcodeFormats = [...canSelectedBarcodeFormats, barcodeFormat];
    }

    return list?.filter((item) =>
      canSelectedBarcodeFormats.includes(item?.name),
    );
  };

  return (
    <CustomTitleWithDropDown
      customClass={customClass}
      title={title}
      source={filterCouponBarcodeList(couponBarcodeList)}
      defaultValue={defaultValue}
      setValue={onSelectChange}
      loadMoreAction={() => {}}
      filterAction={'createCoupon/getBarcodeList'}
      onFocus={onFocus}
    />
  );
}

export default CouponBarcodeSelectDropdown;
