import React, { useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useDispatch, connect } from 'react-redux';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { createAction } from '../../utils';

import CustomTitleWithInput from '../base/CustomTitleWithInput';
import { hasError, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import ContentSections from '../base/ContentSections';
import { SaveAndBackButtons } from '../base/BottomStepComponent';
import { LanguageConfig } from '../../config/CustomEnums';

const COUPON_LABEL_ERRORS = {
  name: {
    required: 'Please provide name',
    maxLength: 'Please input 50 characters in max.',
  },
  displayPriority: {
    required: 'Please provide display priority',
  },
};

function CreateCouponLabelStepOne({ languages }) {
  const params = useParams();
  const { id } = params;
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    control,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    setValue,
    watch,
    register,
    trigger,
  } = useFormContext();

  const renderCouponLabelContent = (language) => {
    const watchName = watch(`translations.${language}.name`);
    const watchDisplayPriority = watch(`displayPriority`);

    return [
      <>
        <div className="second-title-section">
          <div className="second-section-title">Name</div>
        </div>
        <div>
          <input
            type="text"
            className={`text-input-field ${
              hasError(errors, `translations.${language}.name`)
                ? 'error-field'
                : ''
            }`}
            {...register(
              `translations.${language}.name`,
              language === LanguageConfig.english
                ? { required: 'please input name' }
                : {},
            )}
          />
        </div>
        <ReactHookFormErrorMessage
          errors={errors}
          id={`translations.${language}.name`}
        />
        {language === LanguageConfig.english ? (
          <Controller
            control={control}
            name="displayPriority"
            rules={{
              validate: {
                min: (value) =>
                  parseInt(value) > 0 ||
                  COUPON_LABEL_ERRORS.displayPriority.required,
              },
            }}
            render={() => (
              <>
                <CustomTitleWithInput
                  title={'Display order'}
                  error={{
                    error: hasError(errors, 'displayPriority'),
                  }}
                  defaultValue={watchDisplayPriority}
                  setValue={(value) => {
                    setValue('displayPriority', value, {
                      shouldDirty: true,
                    });
                  }}
                  type={'number'}
                  extra={{
                    min: 1,
                  }}
                />
                <label className="create-message-suggest">
                  {`Minimum value is 1. The smaller value, the higher priority.`}
                </label>
                <ReactHookFormErrorMessage
                  errors={errors}
                  id="displayPriority"
                  extra="display-priority-errors"
                />
              </>
            )}
          />
        ) : null}
      </>,
    ];
  };

  useEffect(() => {
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
  }, []);

  return (
    <>
      <ContentSections
        hidePreview
        languageTabContent={{
          containers: languages?.map((item) => ({
            container: [renderCouponLabelContent(item.code)],
            key: item.code,
            title: item.sourceName,
          })),
        }}
      />
      <SaveAndBackButtons
        saveText={params.id ? 'Update' : 'Save'}
        backAction={() => history.goBack()}
        saveAction={async () => {
          let isValid = await trigger([
            'displayPriority',
            'translations.en.name',
          ]);

          const [name] = getValues(['translations.en.name']);
          if (name && name.length > 50) {
            setError(
              'translations.en.name',
              {
                type: 'maxLength',
                message: COUPON_LABEL_ERRORS.name.maxLength,
              },
              { shouldFocus: true },
            );
            isValid = false;
          }

          if (isValid) {
            const data = getValues();
            if (id) {
              dispatch(createAction('couponLabel/updateCouponLabel')({ data }));
            } else {
              dispatch(createAction('couponLabel/createCouponLabel')({ data }));
            }
          }
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  languages: state.language.allList,
});

export default connect(mapPropsToState)(CreateCouponLabelStepOne);
