import React, { useState, forwardRef, useEffect } from 'react';
import { Dropdown, Image } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import NarrowUp from '../../assets/images/drop_down_not_show_narrow.svg';
import OmitIcon from '../../assets/images/omit_dropdown_icon.svg';
import './ActionsDropdown.scss';
import '../base/FilterableDropdown.scss';
import { checkHasPermission, rewritePermission } from '../../utils';

const CustomGroupToggle = forwardRef(({ children, onClick }, ref) => (
  <div
    className="filterable-toogle-area filterable-toogle-area-special "
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

const CustomItemToggle = forwardRef(({ onClick }, ref) => (
  <Image
    src={OmitIcon}
    className="dropdown-for-item-toggle sort-disabled"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  />
));

export function ActionsDropdownForGroup(props) {
  const options = props.actions || [];
  const [value, setValue] = useState();
  const location = useLocation();

  const { isSuperuser, userPermissions } = useSelector((state) => ({
    isSuperuser: state.users.isSuperuser,
    userPermissions: state.users.userPermissions,
  }));

  const permissionedActions = () => {
    if (isSuperuser) return options;

    let permissionedActions = [];
    options.map((action) => {
      const newRequires = rewritePermission(action.requires);
      if (userPermissions.includes(newRequires) || !action.requires) {
        permissionedActions.push(action);
      }
    });

    return permissionedActions;
  };

  useEffect(() => {
    setValue('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  return (
    <>
      <label className="dropdown-for-group-title">Action</label>
      <ToggleDropdown
        options={permissionedActions()}
        toggleText={value ? `${value}` : 'Please select'}
        dropdownClass={'dropdown-for-group-container'}
        optionChange={(option) => setValue(option.name)}
      />
    </>
  );
}

export function DropDownWithDefaultSelect(props) {
  const options = props.actions;
  const defaultValue = props.defaultValue;
  const name = props.name;
  const [value, setValue] = useState(
    defaultValue ? defaultValue : options[0].name,
  );

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <ToggleDropdown
      options={options}
      toggleText={value}
      dropdownClass={'dropdown-section'}
      name={name}
      optionChange={(option) => setValue(option.name)}
    />
  );
}

function ToggleDropdown(props) {
  const options = props.options;
  const toggleText = props.toggleText;
  const dropdownClass = props.dropdownClass;
  const name = props.name;
  const optionChange = props.optionChange || (() => {});
  const [isOpen, setIsOpen] = useState();
  return (
    <>
      <Dropdown
        name={name}
        onToggle={(isOpen) => {
          setIsOpen(isOpen);
        }}
        className={dropdownClass}
      >
        <Dropdown.Toggle as={CustomGroupToggle}>
          <>
            {/* <label
              className={`btn-link-text-label ${
                toggleText ? 'btn-link-text-label-active' : ''
              }`}
            >
              {toggleText}
            </label> */}
            <label
              className={`btn-link-text-label ${
                toggleText && toggleText !== 'Please select'
                  ? 'btn-link-text-label-active'
                  : ''
              }`}
            >
              {toggleText}
            </label>
            <Image
              src={NarrowUp}
              className={`filterable-toogle-area-indicator ${
                isOpen ? 'rtoate180' : ''
              }`}
            />
          </>
        </Dropdown.Toggle>

        <Dropdown.Menu
          className="filterable-dropdown-menu"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            margin: 0,
            right: 'auto',
            bottom: 'auto',
          }}
        >
          <BaseDropItem
            options={options}
            extraAction={(option) => optionChange(option)}
          />
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

function BaseDropItem(props) {
  const options = props.options;
  const object = props.object;
  const extraAction = props.extraAction || (() => {});
  return options?.map((option, index) => (
    <Dropdown.Item
      key={`${index}-${option.name}`}
      className="filterable-item"
      onSelect={(eventKey) => {
        if (option.action) {
          option.action(object);
        }
        extraAction(option);
      }}
      eventKey={index}
    >
      {option.name}
    </Dropdown.Item>
  ));
}

function ActionsForDowndownItem(props) {
  const title = props.title;
  const options = props.options;
  const object = props.object;
  return options.length > 0 ? (
    <>
      <label className="dropdown-for-item-title">{title}</label>
      <BaseDropItem options={options} object={object} />
    </>
  ) : null;
}

export function ActionsDropdownForItem(props) {
  const { isSuperuser, userPermissions } = useSelector((state) => ({
    isSuperuser: state.users.isSuperuser,
    userPermissions: state.users.userPermissions,
  }));

  const permissionedActions = (actions) => {
    const originalActions = actions || [];

    if (isSuperuser) return originalActions;

    let permissionedActions = [];
    originalActions.map((action) => {
      const requiresPermissions = Array.isArray(action.requires)
        ? action.requires
        : [action.requires];
      if (
        (requiresPermissions || []).filter((val) => {
          const newRequires = rewritePermission(val);

          return userPermissions.includes(newRequires) || !newRequires;
        }).length > 0
      ) {
        permissionedActions.push(action);
      }
    });

    return permissionedActions;
  };

  const options = permissionedActions(props.actions);
  const otherOptions = permissionedActions(props.otherActions);

  const object = props.object || '';

  const onToggle = props.onToggle;
  const popperConfig = {
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 60],
        },
      },
      {
        name: 'computeStyles',
        options: {
          gpuAcceleration: false,
          adaptive: false,
        },
      },
    ],
  };
  return (
    <>
      <Dropdown drop="left" onToggle={onToggle} align="end" flip={true}>
        <Dropdown.Toggle as={CustomItemToggle} />
        {options.length <= 0 && otherOptions.length <= 0 ? null : (
          <Dropdown.Menu
            className="dropdown-for-item-menu"
            // style={{
            //   // position: 'absolute',
            //   top: 0,
            //   left: 0,
            //   margin: 0,
            //   right: 'auto',
            //   bottom: 'auto',
            // }}
            popperConfig={popperConfig}
          >
            <ActionsForDowndownItem
              title="Actions"
              options={options}
              object={object}
            />
            {options.length > 0 && otherOptions.length > 0 ? (
              <Dropdown.Divider className="dropdown-for-item-divider" />
            ) : null}
            <ActionsForDowndownItem
              title="Others"
              options={otherOptions}
              object={object}
            />
          </Dropdown.Menu>
        )}
      </Dropdown>
    </>
  );
}
