import React, { useState, useEffect } from 'react';
import './PreviewCampaignPage.scss';
import LanguageChange from './LanguageChange';
import DHKPreviewBody from './DHKPreviewBody';
import Loading from '../../../../components/base/Loading';
import { useParams, useHistory } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { createAction, getObjectFromSessionStorage } from '../../../../utils';
import { CampaignType, SESSION_KEYS } from '../../../../config/CustomEnums';
import { t } from 'i18n-js';
import HKTBIcon from '../../../../assets/images/preview/logo_header.svg';
import homeHeaderIcon from '../../../../assets/images/preview/homeHeaderIcon.svg';
import dealsIcon from '../../../../assets/images/preview/dealsIcon.svg';
import dealOutIcon from '../../../../assets/images/preview/dealOutIcon.svg';
import HKTBSCIcon from '../../../../assets/images/preview/hktb-logo-sc.svg';
import HKTBTCIcon from '../../../../assets/images/preview/hktbLogoTc.svg';
import './Header.scss';

function Header({
  menuConfig,
  isMobileSize,
  previewCampaign,
  language,
  onLanguageChange,
}) {
  return (
    <>
      <div className="header">
        <img
          className="header-logo"
          src={
            language?.code === 'zh-Hant'
              ? HKTBTCIcon
              : language?.code === 'zh-Hans'
              ? HKTBSCIcon
              : HKTBIcon
          }
          onClick={() => {
            // window.location.href = homeLink;
          }}
        />
        <div className="header-right header-blod-font">
          <div className="header-language">
            {/* {isMobileSize ? (
              <LanguageMobile
                languageChangeAction={() => {
                  menuConfig?.setShowMenu(false);
                }}
              />
            ) : (
              <HeaderLangugage />
            )} */}
            <LanguageChange
              onLanguageChange={onLanguageChange}
              campaign={previewCampaign}
              selectedLanguage={language}
              showLanguageName={true}
            />
          </div>

          {/* {isMobileSize ? (
            <div className="header-language" style={{ marginLeft: 5 }}>
              <img
                alt=""
                src={menuConfig?.showMenu ? CloseIcon : MenuIcon}
                // onClick={() => menuConfig?.setShowMenu(!menuConfig.showMenu)}
              />
            </div>
          ) : null} */}

          {/* <PageNavButton
              visibility={backAction ? true : false}
              onClick={backAction}
              // onMenuClick={() => setShowMenu(!showMenu)}
              // showMenu={showMenu}
            /> */}

          {/* <div id="header-right" style={{ display: 'none' }}>
            <div className="header-right-line"></div>

            <div className="view-hover view-hover-red" onClick={() => {}}>
              <img
                src={homeHeaderIcon}
                className="view-hover view-hover-red home-image"
              />
              <label className="header-blod-font view-hover view-hover-red">
                {t('home', { locale: language?.code })}
              </label>
            </div>
            <div
              className="view-hover view-hover-red"
              onClick={() => {
                // utils.trackHomeMenu("header_home", false);
                // window.open(
                //   `${config.dhkDomain}/${
                //     i18n.language === 'en' ? 'hk-eng' : 'hk-tc'
                //   }/deals/in-town-offers.html`,
                //   '_blank',
                // );
                // window.location.href = ;
              }}
            >
              <img
                src={dealsIcon}
                className="view-hover view-hover-red"
                style={{ marginRight: '3px', height: '18px' }}
              />
              <label className="header-blod-font view-hover view-hover-red">
                {t('DEALS', { locale: language?.code })}
              </label>
              <img
                src={dealOutIcon}
                className="view-hover view-hover-red"
                style={{ marginLeft: '8px', height: '12px' }}
              />
            </div>

            <label className="header-blod-font">
              {t('Login / Sign up', { locale: language?.code })}
            </label>
          </div> */}
        </div>
      </div>
    </>
  );
}

function DHKPreviewCampaignPage({ campaign, linkCouponDetail }) {
  const params = useParams();
  const dispatch = useDispatch();
  const campaignId = params?.id;
  const [paramCampaign, setParamCampaign] = useState();
  const [language, setLanguage] = useState();
  const [previewCampaign, setPreviewCampaign] = useState();
  useEffect(() => {
    dispatch(createAction('targetMarket/getAllList')({ needChannel: true }));
    dispatch({ type: 'campaignList/getStarLimit' });
    dispatch(
      createAction('logoLabel/getAllList')({
        allWithTranslation: true,
      }),
    );

    document
      .querySelector('body')
      .style.setProperty('background-color', '#f8f8f8', 'important');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setParamCampaign(
      getObjectFromSessionStorage(
        SESSION_KEYS.CREATE_CAMPAIGN_PERVIEW_SESSION_KEY,
      ),
    );
    dispatch(
      createAction('createCampaign/getOneDetail')({
        id: campaignId,
        isDetail: true,
      }),
    );
    return () => dispatch({ type: 'createCampaign/clearData' });
  }, []);

  useEffect(() => {
    if (paramCampaign && campaignId == paramCampaign?.pk) {
      setPreviewCampaign(paramCampaign);

      // if (!paramCampaign?.pk) {
      //   if (campaign.linkedStores?.length > 0) {
      //     const storeIds = campaign.linkedStores.map((s) => s.pk);
      //     console.log('@@51: ', storeIds);
      //     // dispatch(
      //     //   createAction('storeModel/getAllList')({
      //     //     ids: storeIds.join(','),
      //     //     showDistrictTranslation: true,
      //     //   }),
      //     // );
      //   }
      // }
      return;
    }
    if (campaign?.pk) {
      setPreviewCampaign(campaign);

      // if (
      //   // campaign.isAllFollowCouponTemplate ||
      //   // campaign.isFollowCouponTemplateStores

      //   campaign.type !== CampaignType.generalMessageCampaign
      // ) {
      //   if (campaign?.linkedCoupon?.pk) {
      //     dispatch(
      //       createAction('storeModel/getList')({
      //         couponTemplateIn: campaign?.linkedCoupon?.pk,
      //         showDistrictTranslation: true,
      //       }),
      //     );
      //   }
      // } else {
      //   // dispatch(
      //   //   createAction('storeModel/getAllList')({
      //   //     campaignIn: campaign.pk,
      //   //     showDistrictTranslation: true,
      //   //   }),
      //   // );
      // }
    }
  }, [paramCampaign, campaign]);

  return (
    <div className="dhk">
      <Header
        onLanguageChange={(language) => setLanguage(language)}
        previewCampaign={previewCampaign}
        language={language}
      />
      <div className="preview-container">
        {/* <LanguageChange
        onLanguageChange={(language) => setLanguage(language)}
        campaign={previewCampaign}
        selectedLanguage={language}
      /> */}
        <DHKPreviewBody
          language={language}
          campaign={previewCampaign}
          linkCouponDetail={linkCouponDetail}
        />
      </div>
    </div>
  );
}

const mapPropsToState = (state) => ({
  campaign: state.createCampaign.campaign,
  linkCouponDetail: state.createCampaign.linkCouponDetail,
});

export default connect(mapPropsToState)(DHKPreviewCampaignPage);
