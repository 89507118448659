import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { connect } from 'react-redux';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import BaseForm from '../../../components/base/v2/BaseForm';
import { createAction } from '../../../utils';
import { APIStatus } from '../../../config/CustomEnums';
import CreateInterestPreferenceStepOne from '../../../components/interestPreference/CreateInterestPreferenceStepOne';
import { saveInterestPreferenceToSessionStorage, removeInterestPreferenceSessionStroage } from '../../../models/InterestPreferenceModel';

const CreateInterestPreference = ({
  defaultValues,
  dispatch,
  hasUpdatedDefaultValues,
  formHasSubmitted,
  apiStatus,
}) => {
  const params = useParams();
  const history = useHistory();
  const { id } = params;
  useEffect(() => {
    if (id) {
      dispatch(createAction('interestPreference/getOneDetail')({ id }));
    } else {
      dispatch(createAction('interestPreference/loadInterestPreferenceFromCookie')());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
    return () => {
      dispatch(createAction('interestPreference/clearData')());
    };
  }, []);

  useEffect(() => {
    if (formHasSubmitted && apiStatus === APIStatus.success) {
      history.push({ pathname: '/interest_preferences' });
    }
  }, [formHasSubmitted, apiStatus]);
  console.log('@@45: ', defaultValues);
  return (
    <>
      <BaseForm
        defaultValues={defaultValues}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={formHasSubmitted}
        tempSave={(save, getValues) => {
          if (save) {
            saveInterestPreferenceToSessionStorage(getValues());
          } else {
            removeInterestPreferenceSessionStroage();
          }
        }}
        content={[<CreateInterestPreferenceStepOne />]}
        currentStep={0}
        breadcrumb={
          <CustomBreadcrumb
            name={id ? defaultValues?.name : 'Create Interest preference'}
          />
        }
        caution={{
          detail: 'TBC',
          title: id ? defaultValues?.name : 'Create Interest preference',
        }}
      />
    </>
  );
};
const mapPropsToState = (state) => ({
  defaultValues: state.interestPreference.detail,
  hasUpdatedDefaultValues: state.interestPreference.detail.hasUpdatedDefaultValues,
  formHasSubmitted: state.interestPreference.formHasSubmitted,
  apiStatus: state.interestPreference.apiStatus,
});
export default connect(mapPropsToState)(CreateInterestPreference);
