import { BaseHelper } from './BaseGraphQLAPIHelper';

const NODE = `{
  rateLimitType
  settings {
    pk
    administratorGroup {
      pk
      name
    }
    threshold
    duration
  }
}`;

export const getPagedList = (searchDict) => {
  const rateLimitType = searchDict?.rateLimitType || '';
  const query = `{
    administratorRateLimits(rateLimitType: "${rateLimitType}")
    ${NODE}
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const createOrUpdate = (values) => {
  const action = values.id ? 'Update' : 'Create';
  let query = `mutation UpdateAdministratorRateLimit($input: UpdateAdministratorRateLimitInput!) {
    updateAdministratorRateLimit(input: $input) {
      success
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = { input: values };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
