import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

function NoPermission({ shouldLogout = true }) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (shouldLogout) {
      console.log('no permission -> logout');
      dispatch({ type: 'users/logout' });
    }
  }, []);

  //   const history = useHistory();
  //   return history.push({ pathname: '/login', state: { isLogout: true } });
  return (
    <div className="dashboard-error">
      {`You do not have any permission to view Esolution CMS.\nPlease contact administrator.`}
    </div>
  );
}
export default NoPermission;
