import React, { useEffect, useState } from 'react';
import '../../containers/customers/customer/CustomerOverviewDetail.scss';
import { useDispatch, useSelector } from 'react-redux';
import CustomTitleWithSwitch from '../base/CustomTitleWithSwitch';
import { PermissionCodes } from '../../config/PermissionCodes';
import CustomerFilterableDropdown from '../transactions/CustomerFilterableDropdown';
import { useFormContext, Controller } from 'react-hook-form';
import { hasError, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import BaseMutipleSelectorV2 from '../base/BaseMultipleSelectorV2';
import { CustomTitleWithDropDown } from './CustomTitleWithDropDown';
import { DefaultLanguages } from '../../config/CustomEnums';

function MembershipInfoEditCard({ title, customer }) {
  const dispatch = useDispatch();
  const { customerCoupons, interestPreferences } = useSelector((state) => ({
    customerCoupons: state.singleCoupon.pagedList,
    interestPreferences: state.customerList.interestPreferences,
  }));
  const { watch, setValue, control, formState } = useFormContext();
  const { errors } = formState;
  const watchRemovedCoupons = watch('removedCoupons') || [];
  const watchReferrerUser = watch('referrerUser');
  const watchIsForcedInactive = watch('isForcedInactive');
  const watchIsPreviewTester = watch('isPreviewTester');

  const customerCouponsData = customerCoupons.map((item) => ({
    name: item.template.name,
    pk: item.pk,
  }));
  const [targetCoupons, setTargetCoupons] = useState(customerCouponsData);
  useEffect(() => {
    setTargetCoupons(
      customerCoupons.map((item) => ({
        name: item.template.name,
        pk: item.pk,
      })),
    );
  }, [customerCoupons]);

  return (
    <div>
      <label className="create-section-title">{title}</label>
      <div className="customer-interests">
        <Controller
          control={control}
          name="interestPreferences"
          render={({ field: { value } }) => (
            <BaseMutipleSelectorV2
              title={'Interest preference (Optional)'}
              data={{
                sourceData: interestPreferences?.map((item) => ({
                  name: item.name,
                  pk: item.pk,
                })), //customer?.ownedCoupons,
                targetData: value,
                targetChange: (value) => {
                  setValue('interestPreferences', value, { shouldDirty: true });
                },
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="interestPreferences"
          render={({ field: { value } }) => (
            <div className="customer-interests-mobile">
              {interestPreferences?.map((item) => {
                const hasSelected =
                  value?.filter((v) => v.pk === item.pk).length > 0;
                return (
                  <label
                    className={`${hasSelected ? 'selected' : ''}`}
                    onClick={(e) => {
                      if (hasSelected) {
                        setValue(
                          'interestPreferences',
                          value?.filter((v) => v.pk !== item.pk),
                          { shouldDirty: true },
                        );
                      } else {
                        setValue(
                          'interestPreferences',
                          [...(value || []), item],
                          {
                            shouldDirty: true,
                          },
                        );
                      }
                    }}
                  >
                    {item.name}
                  </label>
                );
              })}
            </div>
          )}
        />
      </div>

      <Controller
        control={control}
        name="preferredMessageLanguage"
        render={({ field: { value } }) => (
          <CustomTitleWithDropDown
            title={'Preferred message language (Optional)'}
            source={DefaultLanguages}
            defaultValue={value}
            setValue={(value) => {
              setValue('preferredMessageLanguage', value, {
                shouldDirty: true,
              });
            }}
            needFilter={false}
          />
        )}
      />

      <Controller
        control={control}
        name="hasAgreedDirectMarketing"
        render={({ field: { value } }) => (
          <>
            <CustomTitleWithSwitch
              title={'Direct marketing preference (Optional)'}
              defaultValue={value === 'Opt-In'}
              setValue={(value) => {
                setValue('hasAgreedDirectMarketing', value, {
                  shouldDirty: true,
                });
              }}
            />
          </>
        )}
      />

      <Controller
        control={control}
        name="removedCoupons"
        render={() => (
          <BaseMutipleSelectorV2
            title={'Owned coupons(Optional)'}
            data={{
              sourceData: customerCouponsData,
              targetData: targetCoupons,
              targetChange: (value) => {
                setTargetCoupons(value);
              },
              revertTargetChange: (value) => {
                setValue('removedCoupons', value, { shouldDirty: true });
              },
            }}
            addButton={{ title: 'Add Single Coupons ', link: '/coupons' }}
            revert={true}
          />
        )}
      />
      <ReactHookFormErrorMessage errors={errors} id="removedCoupons" />
      {/* <Controller
        control={control}
        name="referrerUser"
        render={() => (
          <CustomerFilterableDropdown
            title={'Referrer(Optional)'}
            defaultSelectedCustomer={watchReferrerUser}
            selectCustomer={(value) => {
              setValue('referrerUser', value, { shouldDirty: true });
            }}
          />
        )}
      />
      <ReactHookFormErrorMessage errors={errors} id="referrerUser" /> */}
      <Controller
        control={control}
        name="isPreviewTester"
        render={() => (
          <>
            <CustomTitleWithSwitch
              title={'Test User'}
              defaultValue={watchIsPreviewTester}
              setValue={(value) => {
                setValue('isPreviewTester', value, { shouldDirty: true });
              }}
            />
            <label className="tips-message">
              If On, Can view saved “Goodie bag list”, “List page of cash
              voucher, goodie bag coupon ”, “detail page of cash voucher,
              limited offer” and coupon campaign.
            </label>
          </>
        )}
      />
      <Controller
        control={control}
        name="isForcedInactive"
        render={() => (
          <CustomTitleWithSwitch
            title={'Is forced inactive'}
            defaultValue={watchIsForcedInactive}
            setValue={(value) => {
              setValue('isForcedInactive', value, { shouldDirty: true });
            }}
          />
        )}
      />
      <ReactHookFormErrorMessage errors={errors} id="isForcedInactive" />
    </div>
  );
}

export default MembershipInfoEditCard;
