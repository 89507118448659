import React, { useState } from "react";
import { Image } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import deleteSelectedButton from '../../../assets/images/drop_down_delete_selected.png'
import CustomSwitchButton from "../../base/CustomSwitchButton";
import { hasError } from "../../base/ErrorFieldMessage";
import NumberInputArea from "../../base/NumberInputArea";
import { CustomTitleLabel } from "../../earning/CustomBaseComponments";
import './PerTargetMarketLimitArea.scss';

function PerTargetMarketLimitArea({
  disabled = false,
  isOtherMarket = false,
  channel,
  targetMarket,
  targetMarketAcquired = 0,
  areaTitle,
  deleteButtonAction = (() => { }),
  perTargetMarketLimitNumber = null,
  setPerTargetMarketLimitNumber = (() => { }),
  // defaultChecked,
  // displayNumberInCampaignAction = (() => { })
}) {
  const { formState } = useFormContext();
  const errors = formState.errors;

  // if (isOtherMarket && !perTargetMarketLimitNumber) {
  //   return <></>;
  // }

  return (
    <div className="per-target-market-limit-single-area" key={`${channel}-${targetMarket}-area`}>
      <div className="per-target-market-limit-area-title">
        {isOtherMarket
          ? <div className="per-target-market-limit-available other-market">
            <label className="per-target-market-limit-available-name">
              Maximum limit could be set:
            </label>
            <label className="per-target-market-limit-available-value">
              {perTargetMarketLimitNumber || 0}
            </label>
          </div>
          : <>
            <label className="per-target-market-limit-title">
              # {areaTitle}
            </label>
            {targetMarketAcquired > 0
              ? <></>
              : <Image
                src={deleteSelectedButton}
                className="per-target-market-limit-delete"
                onClick={deleteButtonAction}
              />
            }
            <div className="per-target-market-limit-available">
              <label className="per-target-market-limit-available-name">
                Per target maket limit:
              </label>
              <label className="per-target-market-limit-available-value">
                {perTargetMarketLimitNumber || 0}
              </label>
            </div>
          </>
        }
      </div>
      <NumberInputArea
        disabled={disabled}
        labelText={isOtherMarket ? "Limit for other target markets" : "Per target market coupon download limit"}
        labelClass={isOtherMarket ? "number-input-area-other-market" : ""}
        type="number of coupons"
        valueChange={(value) => setPerTargetMarketLimitNumber(value)}
        defaultValue={perTargetMarketLimitNumber}
        errors={errors}
        errorFieldName={`${channel}-${targetMarket}-limit`}
        index={`${channel}-${targetMarket}`}
      />
      <label className="tips-message">
        Acquired: {targetMarketAcquired}, {isOtherMarket 
          ? "Available to acquired in the remaining target markets:" 
          : "Available to acquired in this target market:"
        } {(perTargetMarketLimitNumber || 0) - (targetMarketAcquired || 0)}
      </label>
      {/* <CustomTitleLabel title="Display the available number of coupons in the campaign" />
      <CustomSwitchButton
        defaultChecked={defaultChecked}
        onChange={displayNumberInCampaignAction}
      /> */}
    </div>
  )
}

export default PerTargetMarketLimitArea;