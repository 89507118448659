import {
  CheckStatus,
  CUSTOMER_ID_TYPE,
  SavedStatus,
  StatusTag,
  SESSION_KEYS,
} from '../config/CustomEnums';
import {
  activeEntitlementCustomers,
  deleteEntitlementCustomers,
  entitlementCustomers,
  getEntitlements,
  getEntitlement,
} from '../services/CampaignAPIHelper';
import {
  convertCursorToNumber,
  convertPKToId,
  convertNumberToCursor,
} from '../utils';
import { formatDate, TimeFormater } from '../utils/TimeFormatUtil';
import { createModel } from './BaseModel';
import { parseCouponSetListDate } from './CouponUtil';
import { apiWithResponseHandle, loading } from './LoadingUtil';

export const FORTUNEBAG_ENTITLEMENT_OPTIONS = {
  A: 'a',
  B: 'b',
  C: 'c',
  D: 'd',
};

export const FORTUNEBAG_OPTIONS_BUTTON_TYPE = {
  COPY: 'copy',
  UPLOAD: 'upload',
  DOWNLOAD: 'download',
};

const getInitialState = () => ({
  customerList: [],
  customerGroup: [],
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk', orderField: 'pk' },
    { displayName: 'Customer ID', fieldName: 'displayCustomer' },
    { displayName: 'Type', fieldName: 'customerType' },
    { displayName: 'No. of bags', fieldName: 'amount' },
    { displayName: 'Create at', fieldName: 'creationDate' },
    { displayName: 'Last Update', fieldName: 'lastModifiedDate' },
    { displayName: 'Upload by', fieldName: 'administrator' },
    { displayName: 'Status', fieldName: 'status' },
  ],
  pagedCustomerList: [],
  checked: CheckStatus.initOrNotChecked,
  saved: SavedStatus.init,
  activityLogs: [],
  allEntitlementOptionDatasTemp: [],
  entitlementOptionDatas: [],
  entitlementOption: '',
  entitlementTotalCount: null,
  entitlementPageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  entitlementDetail: null,
  merchantTableHeaders: [],
});

const parseEntitlementOptions = (rawDatas) => {
  const optionMapping = {
    OPTION_A: FORTUNEBAG_ENTITLEMENT_OPTIONS.A,
    OPTION_B: FORTUNEBAG_ENTITLEMENT_OPTIONS.B,
    OPTION_C: FORTUNEBAG_ENTITLEMENT_OPTIONS.C,
    OPTION_D: FORTUNEBAG_ENTITLEMENT_OPTIONS.D,
  };

  let option = optionMapping?.[rawDatas[0]?.node?.method];

  const optionData = rawDatas?.map((item) => {
    return {
      pk: item.node.pk,
      creationDate: item.node.creationDate,
      displayLastModifiedDate: formatDate(
        item.node.lastModifiedDate,
        TimeFormater.monthDayYearTime,
      ),
      entitlementId: item.node.pk,
      merchantName: `[ID:${item.node.merchant.pk}] ${item.node.merchant.name}`,
      merchantId: item.node.merchant.pk,
      secretKey: item.node.merchantScretkey,
      merchantStaticLink: item.node.staticLink,
      wechatStaticLink: item.node.wechatStaticLink,
      entitledCustomerFileName: item.node.entitledCustomerFileName,
      fortuneBagUrl: item.node.fortuneBagUrls,
      fortuneBagQuantity: item.node.fortuneBagQuantity
        ? item.node.fortuneBagQuantity
        : 0,
      batch: item.node.batch,
      batchNo: item.node.batch.index,
    };
  });
  return [optionData, option];
};

const parseEntitlement = (data) => {
  const goodieBag = data?.batch?.goodieBag;
  return {
    ...data,
    goodieBag: {
      ...goodieBag,
      activePeriod: goodieBag?.endDate
        ? `${formatDate(
            goodieBag?.startDate,
            TimeFormater.dayMonthYearWeekTimeA,
          )} - ${formatDate(
            goodieBag?.endDate,
            TimeFormater.dayMonthYearWeekTimeA,
          )}`
        : 'All the time',
      visiblePeriod: goodieBag?.displayEndDate
        ? `${formatDate(
            goodieBag?.displayStartDate,
            TimeFormater.dayMonthYearWeekTimeA,
          )} - ${formatDate(
            goodieBag?.displayEndDate,
            TimeFormater.dayMonthYearWeekTimeA,
          )}`
        : 'All the time',
    },
  };
};

const parseDetail = (node) => {
  const maskLength = node.customer.length / 3;
  const start = node.customerType === 'EMAIL' ? 0 : 3;

  const displayCustomerId = () => {
    const name = node.customer.replace(
      node.customer.substring(start, start + maskLength),
      '****',
    );

    if (node.customerType === 'EMAIL') {
      return name;
    } else if (name.indexOf('+') !== 0) {
      return `+${name}`;
    }
    return name;
  };

  return {
    ...node,
    displayCustomer: displayCustomerId(),
    customerType:
      node.customerType === 'EMAIL'
        ? CUSTOMER_ID_TYPE.EMAIL
        : CUSTOMER_ID_TYPE.MOBILE_NUMBER,
    creationDate: parseCouponSetListDate(node.creationDate),
    lastModifiedDate: parseCouponSetListDate(node.lastModifiedDate),
    administrator: node.administrator?.username,
    status: ((nodeStatus) => {
      let status;
      switch (nodeStatus) {
        case 'ACTIVE':
          status = StatusTag.active;
          break;
        case 'DEACTIVE':
          status = StatusTag.deactive;
          break;
        case 'ACQUIRED':
          status = StatusTag.acquired;
          break;
        default:
          status = StatusTag.deactive;
          break;
      }
      return status;
    })(node.status),
  };
};

export default createModel({
  namespace: 'fortuneBagEntitlement',
  states: getInitialState(),
  params: {
    sessionKey: SESSION_KEYS.ENTITLEMENT_CUSTOMER_SESSION_KEY,
    dataKey: SESSION_KEYS.ENTITLEMENT_CUSTOMERS_SESSION_KEY,
    listAPI: entitlementCustomers,
    pageCount: 5,
    parse: (data) =>
      data?.entitlementCustomers.edges.map((item) => parseDetail(item.node)),
    deleteAPI: deleteEntitlementCustomers,
  },
  reducers: {},
  effects: {
    getEntitlements: [
      function* ({ payload }, { put }) {
        const page = payload?.page || 1;
        const pageCount = payload.pageSize || 20;
        const pageCursor = convertNumberToCursor((page - 1) * pageCount - 1);

        const serviceArgs = [
          getEntitlements,
          payload.pageSize ? pageCursor : null,
          payload,
        ];
        function* onSuccess(data) {
          console.log('@135', data);
          const [entitlementOptionDatas, entitlementOption] =
            parseEntitlementOptions(data?.entitlements.edges);
          const pageInfo = data?.entitlements.pageInfo;
          yield put({
            type: 'updateState',
            payload: {
              entitlementOptionDatas,
              entitlementOption,
              entitlementTotalCount: data?.entitlements.totalCount,
              entitlementPageInfo: {
                startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1,
                endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1,
              },
            },
          });
        }
        function onFailed(data) {
          console.log('@@157: ', data);
        }
        yield loading(serviceArgs, onSuccess, onFailed);
      },
    ],
    getEntitlement: [
      function* ({ payload }, { put }) {
        const id = convertPKToId('EntitlementNode', payload.id);
        const serviceArgs = [getEntitlement, id];
        function* onSuccess(data) {
          console.log('@135', data);
          yield put({
            type: 'updateState',
            payload: {
              entitlementDetail: parseEntitlement(data?.entitlement),
            },
          });
        }
        function onFailed(data) {
          console.log('@@157: ', data);
        }
        yield loading(serviceArgs, onSuccess, onFailed);
      },
    ],
    getAllEntitlements: [
      function* ({ payload }, { call, select, put }) {
        const pageSize = 100;
        console.log('@@239: ', payload);
        yield put({
          type: 'updateState',
          payload: {
            allListLoading: true,
          },
        });

        function* loadResult(data = {}, prePayload = {}) {
          const tempKey = 'allEntitlementOptionDatasTemp';
          const allKey = 'entitlementOptionDatas';

          const endCursor = data.endCursor || 0;
          const totalCount = data.totalCount || 0;
          const pagedList = data['entitlementOptionDatas'] || [];
          let oldTempList = yield select(
            (state) => state['fortuneBagEntitlement'][tempKey],
          ) || [];
          const newList = [...oldTempList, ...pagedList];
          console.log('totalCount:', 'entitlements', totalCount, endCursor);
          if (endCursor + 1 < totalCount) {
            const currentPage = (endCursor + 1) / pageSize + 1;
            yield put({
              type: 'updateState',
              payload: {
                [tempKey]: newList,
              },
            });

            yield put({
              type: 'getAllEntitlements',
              payload: { ...prePayload, pageSize: 100, page: currentPage },
            });
            return;
          }
          yield put({
            type: 'updateState',
            payload: {
              [allKey]: newList,
              [tempKey]: [],
              allListLoading: false,
            },
          });
        }

        const page = payload.page || 1;
        const pageCursor = payload.page
          ? convertNumberToCursor((page - 1) * pageSize - 1)
          : '';
        const serviceArgs = [
          getEntitlements,
          payload.pageSize ? pageCursor : null,
          {
            ...payload,
            pageSize,
            isAll: true,
          },
        ];
        function* onSuccess(data) {
          const objectData = data?.entitlements || {};
          const pageInfo = objectData.pageInfo;

          if (!pageInfo) {
            return;
          }
          console.log('71: apiSuccess:', data, 'entitlements');
          const [entitlementOptionDatas, entitlementOption] =
            parseEntitlementOptions(objectData.edges);
          const endCursor = convertCursorToNumber(pageInfo.endCursor);
          yield loadResult(
            {
              entitlementOptionDatas,
              entitlementOption,
              endCursor: endCursor,
              totalCount: objectData.totalCount,
            },
            payload,
          );
        }
        function* onError(data) {
          console.log('onErrordata:', data);
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],

    activeEntitlementCustomers: [
      function* ({ payload }) {
        const inputBody = {
          id: payload.data.id,
          isActive: payload.data.isActive,
        };
        const afterAction = payload.afterAction || (() => {});

        const serviceArgs = [activeEntitlementCustomers, inputBody];

        function onSuccess(data) {
          console.log('241', data);
          afterAction();
        }

        function onFailed(data) {
          console.log('@@122: ', data);
        }

        yield loading(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
  },
});
