import React from 'react';
import { LanguageConfig } from '../../config/CustomEnums';
import i18n from '../../I18n';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import './MessageGeneralSection.scss';
import ErrorFieldMessage, {
  hasError,
  errorMessage,
} from '../base/ErrorFieldMessage';
import CustomEditor from '../base/CustomEditor';
import { Row } from 'react-bootstrap';
import CustomRadios from '../base/CustomRadios';
import { insertKeyToTranslations } from '../../utils';
import { useFormContext } from 'react-hook-form';

function GeneralAlertGeneralSection(props) {
  const language = props.language || LanguageConfig.english;
  const { getValues, watch, formState, setValue } = useFormContext();
  const errors = formState.errors || {};
  const translations = watch('translations') || {};
  const displayPriority = watch('displayPriority');
  const severityLevel = watch('severityLevel');
  const content = translations[language]?.content;

  const getRadioOptions = (language) => [
    {
      label: i18n.t('informative', { locale: 'en' }),
      value: 'NORMAL',
    },
    {
      label: i18n.t('critical', { locale: 'en' }),
      value: 'CRITICAL',
    },
  ];
  const getDispayPriority = () => {
    return displayPriority || '-';
  };
  const getDispayOptions = () => {
    if (severityLevel === 'NORMAL')
      return i18n.t('informative', { locale: 'en' });
    if (severityLevel === 'CRITICAL')
      return i18n.t('critical', { locale: 'en' });
    return '-';
  };

  return (
    <>
      <label className="create-section-title">
        {i18n.t('general', { locale: 'en' })}
      </label>
      <CustomTitleLabel title={i18n.t('content', { locale: 'en' })} />
      <CustomEditor
        initialValue={content}
        onValueChange={(data) => {
          console.log('@@55');
          insertKeyToTranslations({
            language,
            key: 'content',
            value: data,
            setValue,
            getValues,
          });
        }}
      />
      <ErrorFieldMessage
        id={`translations.${language}.content`}
        error={hasError(errors, `translations.${language}.content`)}
        message={errorMessage(errors, `translations.${language}.content`)}
      />
      <CustomTitleLabel title={i18n.t('display_order', { locale: 'en' })} />
      {language === LanguageConfig.english ? (
        <>
          <div>
            <input
              value={displayPriority}
              type="number"
              min="1"
              className={`coupon-campaign-section-input`}
              onChange={({ target }) => {
                setValue('displayPriority', target.value, {
                  shouldDirty: true,
                });
              }}
            />
          </div>
          <label className="create-message-suggest">
            {i18n.t('display_order_placeholder', { locale: 'en' })}
          </label>
          <ErrorFieldMessage
            id={`displayPriority`}
            error={hasError(errors, `displayPriority`)}
            message={errorMessage(errors, `displayPriority`)}
          />
        </>
      ) : (
        <label className="custom-record-normal-font custom-timeline-time-color list-new-line">
          {getDispayPriority()}
        </label>
      )}

      <CustomTitleLabel title={i18n.t('severity_level', { locale: 'en' })} />
      {language === LanguageConfig.english ? (
        <>
          <Row className="featured-exclusive-row">
            <CustomRadios
              onChange={(value) => {
                setValue('severityLevel', value, { shouldDirty: true });
              }}
              default={severityLevel}
              options={getRadioOptions()}
            />
          </Row>
          <ErrorFieldMessage
            id={`severityLevel`}
            error={hasError(errors, 'severityLevel')}
            message={errorMessage(errors, 'severityLevel')}
          />
        </>
      ) : (
        <label className="custom-record-normal-font custom-timeline-time-color list-new-line">
          {getDispayOptions()}
        </label>
      )}
    </>
  );
}

export default GeneralAlertGeneralSection;
