import { getCouponBatches } from '../services/CouponAPIHelper';
import { convertCursorToNumber, convertNumberToCursor } from '../utils';
import { formatDate } from '../utils/TimeFormatUtil';
import { createModel } from './BaseModel';
import { loading } from './LoadingUtil';

const getInitialState = () => ({
  totalCount: 0,
  currentPageCouponBatches: [],
});

const parseCouponBatch = (batch) => {
  return {
    ...batch?.node,
    displayCreationDate: formatDate(
      batch?.node?.creationDate,
      'MMM DD, YYYY, hh:mm A',
    ),
  };
};

export default createModel({
  namespace: 'couponBatch',
  params: {
    sessionKey: 'couponBatches',
    dataKey: 'couponBatches',
    listAPI: getCouponBatches,
    parse: (data) =>
      data?.couponBatches?.edges?.map((item) => parseCouponBatch(item)),
    objectKey: 'countryCodes',
  },
  states: getInitialState(),
  reducers: {},
  effects: {
    getCurrentPageCouponBatches: [
      function* ({ payload }, { put, all }) {
        console.log('@@25: ', payload);
        const { page, searchKey, search, rank } = payload;
        let afterCursor = '';
        if (page > 1) {
          afterCursor = convertNumberToCursor((page - 1) * 5 - 1);
        }
        const serviceArgs = [
          getCouponBatches,
          afterCursor,
          {
            rank: rank,
            searchKey: searchKey,
            search: search,
            ...payload,
          },
        ];
        function* onSuccess(data) {
          const couponBatchData = data.couponBatches.edges;
          const pageInfo = data.couponBatches.pageInfo;
          const totalCount = data.couponBatches.totalCount;
          const currentLastCursor = pageInfo.endCursor;
          const couponBatches = couponBatchData.map((item) =>
            parseCouponBatch(item),
          );
          yield put({
            type: 'updateState',
            payload: {
              currentPageCouponBatches: couponBatches,
              pageInfo: {
                startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1,
                endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1,
              },
              currentLastCursor,
              totalCount,
              totalPage: Math.ceil(totalCount / 10),
            },
          });
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
});
