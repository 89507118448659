import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomEditableRank from '../base/CustomEditableRank';
import './DataTable.scss';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import CustomTips from '../base/CustomTips';
import { ImageTextButton } from '../base/CustomImageButton';
import { APIStatus } from '../../config/CustomEnums';
import Loading from '../base/Loading';
import NoDataPage from '../base/NoDataPage';
import { useLocation, useHistory, Link } from 'react-router-dom';
import CustomLinkableName from '../base/CustomLinkableName';
import CustomPagination from '../base/CustomPagination';
import filterImage from '../../assets/images/32filter.svg';
import { Button } from 'react-bootstrap';
import SimpleDasnboardFilter from './SimpleDashboardFilter';
import './CouponDownloadList.scss';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CountComponent from '../base/CountComponent';
import { createNumberArray } from '../../utils';

function CouponDownloadList({
  cautionDetail,
  tips,
  refreshAction,
  refreshButtonDisabled,
  dataList = [],
  totalPage = 0,
  exportAction = () => {},
  sortAction = () => {},
  totalCount,
  loadingCoupon,
}) {
  const history = useHistory();
  const location = useLocation();
  const [showFilter, setShowFilter] = useState(false);
  const [tableStartReach, setTableStartReach] = useState(true);
  const [tableEndReach, setTableEndReach] = useState(false);
  const queryString = require('query-string');
  const [currentPage, setCurrentPage] = useState(1);
  const [rankList, setRankList] = useState({});

  const loadCount = dataList?.length || 0;
  dataList = dataList.slice((currentPage - 1) * 20, currentPage * 20);

  const { status, fields } = useSelector((state) => ({
    status: state.loading.status,
    fields: state.simpleDashboardModel.couponDownloadListDisplayFields,
  }));

  useEffect(() => {
    setCurrentPage(1);
  }, [location]);

  const getFilterCount = () => {
    let filterCount = 0;
    const querySearch = queryString.parse(location.search);
    Object.entries(querySearch).map(([key, value]) => {
      if (Object.keys(querySearch).includes(querySearch[key])) {
        return;
      }
      if (!['page', 'rank', 'search', 'sort'].includes(key) && value) {
        filterCount += 1;
      }
    });

    return filterCount;
  };

  const filterCount = useMemo(() => getFilterCount(), [location]);

  const getTableTitles = () => {
    return (
      <tr>
        {fields.map((item, index) => {
          if (item.orderField) {
            const orderField = item.orderField;
            return (
              <th key={index + 1}>
                <CustomEditableRank
                  rank={rankList?.[orderField]}
                  title={item.displayName}
                  extraAreaStyle={'coupon-download-list-rank-container'}
                  onClick={(rank) => {
                    setRankList({ ...rankList, [orderField]: rank });
                    sortAction(orderField, rank);
                    setCurrentPage(1);
                  }}
                />
              </th>
            );
          }

          return (
            <th key={index + 1}>
              <div
                className={`all-list-scrollable-area-table-item-common all-list-scrollable-area-title `}
                style={item.style || {}}
              >
                {item.displayName}
              </div>
            </th>
          );
        })}
      </tr>
    );
  };

  const renderRowData = (item, index) => {
    return (
      <tr key={index}>
        {fields.map((field, subIndex) => {
          switch (field.fieldName) {
            // case 'couponName':
            // case 'totalECouponQty':
            // case 'totalPhysicalCouponQty':
            // case 'eCouponDownloadQty':
            // case 'eCouponDownloadUniqueUser':
            // case 'eCouponRemainingQty':
            // case 'eCouponRemaining':
            // case 'couponRedeemQty':
            // case 'eCouponRedeemUniqueUser':
            // case 'couponRedeem':
            //   return (
            //     <td key={subIndex}>
            //       <div className=" all-list-scrollable-area-table-item-common">
            //         <label className="all-list-scrollable-area-text-common list-new-line">
            //           {item[field.fieldName] || '-'}
            //         </label>
            //       </div>
            //     </td>
            //   );
            default:
              return (
                <td key={subIndex}>
                  <div
                    className={`all-list-scrollable-area-table-item-common`}
                    style={{
                      ...(field.style || {}),
                    }}
                  >
                    <label
                      className={`all-list-scrollable-area-text-common 'list-new-line'`}
                    >
                      {item[field.fieldName] || '-'}
                    </label>
                  </div>
                </td>
              );
          }
        })}
      </tr>
    );
  };

  const renderTableBody = () => {
    return (
      <tbody>{dataList.map((item, index) => renderRowData(item, index))}</tbody>
    );
  };

  const renderBody = () => {
    if (status === APIStatus.calling) {
      return <Loading />;
    }
    if (dataList?.length <= 0) {
      return <NoDataPage />;
    }

    const tableIncludeImage = fields.some(
      (field) =>
        field.fieldName === 'coverPhoto' ||
        field.fieldName === 'icon' ||
        field.fieldName === 'displayImage',
    );
    const tableReachHeight = tableIncludeImage
      ? dataList.length * 140 + 82 > 630
        ? 630
        : dataList.length * 140 + 82
      : (dataList.length + 1) * 82 > 630
      ? 630
      : (dataList.length + 1) * 82;
    return (
      <>
        <PerfectScrollbar
          onScrollLeft={() => {
            setTableEndReach(false);
            console.log('onScrollLeft');
          }}
          onScrollRight={() => {
            setTableStartReach(false);
            console.log('onScrollRight');
          }}
          onXReachStart={() => {
            setTableStartReach(true);
            console.log('onXReachStart');
          }}
          onXReachEnd={() => {
            setTableEndReach(true);
            console.log('onXReachEnd');
          }}
          onScrollUp={() => {
            setTableEndReach(false);
            console.log('onScrollUp');
          }}
          onScrollDown={() => {
            setTableStartReach(false);
            console.log('onScrollDown');
          }}
          onYReachStart={() => {
            setTableStartReach(true);
            console.log('onYReachStart');
          }}
          onYReachEnd={() => {
            setTableEndReach(true);
            console.log('onYReachEnd');
          }}
        >
          <table className={'coupon-download-list-table'}>
            <thead>{getTableTitles()}</thead>
            {renderTableBody()}
          </table>
        </PerfectScrollbar>
        {!tableStartReach ? (
          <div
            className="table-shadow-left"
            style={{ height: `${tableReachHeight}px` }}
          />
        ) : null}
        {!tableEndReach ? (
          <div
            className="coupon-download-table-shadow-right"
            style={{ height: `${tableReachHeight}px` }}
          />
        ) : null}
      </>
    );
  };

  const getDisabledPage = () => {
    return createNumberArray(totalPage).filter(
      (item) => item > Math.ceil(loadCount / 20) - 1,
    );
  };

  return (
    <div className="customer-table-overview custom-data-table">
      <div className="coupon-download-table-header">
        <div className="d-flex flex-column">
          <div className="d-flex flex-row">
            <CustomTitleLabel
              title={'Coupon Download/Remaining/Redeem'}
              className={'letter-uppercase'}
            />
            <CustomTips
              detail={cautionDetail}
              customClass={{
                area: 'data-table-caution-area',
                PopoverContentClass: 'data-table-caution-content',
              }}
              popoverName={'data-table-caution-container'}
            />
          </div>
          <label className="tips-message">{tips}</label>
        </div>
        <div className="buttons">
          <Button
            className="refresh-button btn-further"
            onClick={() => {
              if (filterCount > 0) {
                refreshAction();
                setCurrentPage(1);
              } else {
                setShowFilter(true);
              }
            }}
            disabled={refreshButtonDisabled}
          >
            Refresh
          </Button>
          <label className="tips-message coupon-download-list-refresh-tips">
            {"Please set ‘Filter’ before\n clicking ''Refresh'"}
          </label>
          <div className="custom-button">
            <Button
              className={'btn-back-button-common btn-download'}
              onClick={exportAction}
            >
              Export performance report
            </Button>
          </div>
        </div>
      </div>
      <div>
        {showFilter ? (
          <SimpleDasnboardFilter
            backAction={() => {
              setShowFilter(false);
              return { page: null };
            }}
          />
        ) : null}
        <div className="coupon-download-show-count ">
          <label className="count-text">
            {loadingCoupon ? (
              <div className="coupon-download-list-loading-status">
                Loading...
              </div>
            ) : filterCount > 0 && loadCount === totalCount ? (
              <div className="coupon-download-list-loaded-status">
                Load completed.
              </div>
            ) : null}
            {`Showing ${loadCount > 0 ? (currentPage - 1) * 20 + 1 : 0} to ${
              loadCount > 0
                ? currentPage * 20 <= totalCount
                  ? currentPage * 20
                  : totalCount
                : 0
            } of ${
              loadingCoupon
                ? `(${loadCount}/${totalCount})`
                : filterCount > 0
                ? totalCount
                : 0
            } entries`}
          </label>
        </div>
        <div className="all-list-scrollable-area">
          <div className="all-list-table-content">
            <div>
              <nav className="coupon-download-list-tab-container nav nav-tabs" />
              <div
                className="campaign-list-section coupon-download-table-overflow "
                style={{ minHeight: 'unset' }}
              >
                {renderBody()}
                {dataList.length > 0 ? (
                  <div className="all-list-scrollable-area-pagination">
                    <CustomPagination
                      initPage={currentPage - 1}
                      pageSize={totalPage}
                      pageChange={(page) => {
                        setCurrentPage(page + 1);
                      }}
                      disabledPage={getDisabledPage()}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div
            className={`coupon-download-list-filter-container ${
              showFilter ? 'filter-right-more' : ''
            }`}
          >
            <ImageTextButton
              action={() => setShowFilter(!showFilter)}
              image={filterImage}
              title={'Filter'}
            />
            {filterCount > 0 ? (
              <label className="filter-badge-icon">{filterCount}</label>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CouponDownloadList;
