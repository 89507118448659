import { loading } from './LoadingUtil';
import { convertNumberToCursor, convertCursorToNumber } from '../utils';
import { getCampaignCategoryList } from '../services/CampaignCategoryAPIHelper';
import { StatusTag } from '../config/CustomEnums';
import { createModel } from './BaseModel';

const getInitialState = () => ({
  categoryList: [],
  totalCount: 0,
  totalPage: 0,
  pageInfo: {
    startCursor: 0,
    endCursor: 0,
  },
  checkedList: [],
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    { displayName: 'Name', fieldName: 'name', orderField: 'name' },
    {
      displayName: 'Display order',
      fieldName: 'order',
      orderField: 'displayPriority',
    },
    { displayName: 'Linked with Tag', fieldName: 'displayAttributeTagKey' },
    { displayName: 'Attribute tag', fieldName: 'displayAttributeTags' },
    { displayName: 'Status', fieldName: 'status' },
  ],
});

const parseDetail = (node) => {
  const item = {
    ...node,
    id: node.id,
    pk: node.pk,
    name: node.name,
    order: node.displayPriority,
    isForcedInactive: node.isForcedInactive,
    status: node.isForcedInactive ? StatusTag.inactive : StatusTag.active,
    displayAttributeTagKey: node.attributeTagKey
      ? `[ID:${node.attributeTagKey.pk}] ${node.attributeTagKey.name}`
      : '-',
    displayAttributeTags: node.attributeTags?.edges
      ?.map((item) => `[ID:${item.node?.pk}] ${item.node.name}`)
      .join(', '),
  };
  return item;
};

export default createModel({
  namespace: 'campaignCategoryList',
  states: getInitialState(),
  params: {
    listAPI: getCampaignCategoryList,
    objectKey: 'campaignCategories',
    parse: (data) =>
      data?.campaignCategories?.edges.map((item) => parseDetail(item.node)),
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    assembleCategoryList(state, { payload }) {
      const categoryList = payload.categoryList.map(({ node }, index) =>
        parseDetail(node),
      );
      const pageInfo = payload.pageInfo;
      const startCursor = convertCursorToNumber(pageInfo.startCursor);
      const endCursor = convertCursorToNumber(pageInfo.endCursor);
      console.log('getCampaignCategoryList:', categoryList);
      return {
        ...state,
        categoryList:
          payload.isSelectorLoad && payload.page > 1
            ? [...state.categoryList, ...categoryList]
            : categoryList,
        totalCount: payload.totalCount,
        totalPage: Math.ceil(payload.totalCount / 20),
        pageInfo: {
          startCursor: startCursor + 1,
          endCursor: endCursor + 1,
        },
      };
    },
  },
  effects: {
    getCampaignCategoryList: [
      function* ({ payload }, { put }) {
        const { searchKey } = payload;
        const page = payload.page !== undefined ? payload.page : 1;
        const pageCursor = page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const serviceArgs = [
          getCampaignCategoryList,
          pageCursor,
          payload,
          searchKey,
        ];

        function* onSuccess(data) {
          const categories = data.campaignCategories;
          yield put({
            type: 'assembleCategoryList',
            payload: {
              categoryList: categories.edges,
              totalCount: categories.totalCount,
              pageInfo: categories.pageInfo,
              ...payload,
            },
          });
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
});
