import { createModel } from './BaseModel';
import {
  getLogoLabelList,
  getLogoLabelDetail,
  createLogoLabel,
  deleteLogoLabels,
  updateLogoLabel,
} from '../services/LogoLabelAPIHelper';
import {
  createAction,
  getObjectFromSessionStorage,
  parsePhotoUrlForAPI,
  removeFromSessionStorage,
  saveToSessionStorage,
} from '../utils';
import { APIStatus, LanguageConfig, SESSION_KEYS } from '../config/CustomEnums';
import { apiWithResponseHandle } from './LoadingUtil';

const LOGO_LABEL_SESSION_KEY = SESSION_KEYS.LOGO_LABEL_SESSION_KEY;

export const saveLogoLabelToSessionStorage = (data) => {
  saveToSessionStorage(LOGO_LABEL_SESSION_KEY, data);
};

export const removeLogoLabelSessionStroage = () => {
  removeFromSessionStorage(LOGO_LABEL_SESSION_KEY);
};

const parseLogoLabel = (apiLogoLabel) => {
  if (!apiLogoLabel) {
    return;
  }
  const translations = {
    [LanguageConfig.english]: {
      name: apiLogoLabel.name,
      logoLabelUrl: apiLogoLabel?.logoLabelUrl,
      icon: apiLogoLabel.icon,
    },
  };

  const temp = apiLogoLabel.translations?.edges.forEach((translation) => {
    const { language, name, logoLabelUrl, icon, pk } = translation.node;
    translations[language] = { name, logoLabelUrl, icon, pk };
  });
  return {
    pk: apiLogoLabel.pk,
    name: apiLogoLabel.name,
    logoLabelUrl: apiLogoLabel?.logoLabelUrl,
    icon: apiLogoLabel.icon,
    displayPriority: apiLogoLabel.displayPriority,
    translations,
    hasUpdatedDefaultValues: true,
    relatedCoupons: apiLogoLabel.relatedCoupons,
    relatedCampaigns: apiLogoLabel.relatedCampaigns,
  };
};

const cleanLogoLabelTranslation = (translations, detailTranslations) => {
  const cleanedTranslations = [];
  console.log(translations, detailTranslations);
  const temp = Object.keys(translations).forEach((language) => {
    if (language === LanguageConfig.english) {
      return;
    }
    const translation = translations[language];
    const cleanedOneTranslation = {
      language,
      name: translation.name,
      logoLabelUrl: translation.logoLabelUrl,
      icon: parsePhotoUrlForAPI(translation.icon),
    };

    if (detailTranslations) {
      cleanedOneTranslation.id = detailTranslations[language]?.pk;
    }
    cleanedTranslations.push(cleanedOneTranslation);
  });
  return cleanedTranslations;
};

const cleanLogoLabel = (data, detail) => {
  const enData = data.translations[LanguageConfig.english];
  const payload = {
    name: enData.name,
    logoLabelUrl: enData.logoLabelUrl,
    icon: parsePhotoUrlForAPI(enData.icon),
    displayPriority: data.displayPriority,
    translations: cleanLogoLabelTranslation(
      data.translations,
      detail?.translations,
    ),
  };
  if (detail) {
    payload.id = detail.pk;
  }
  return payload;
};

const parseLogoLabelList = (data) =>
  data?.logoLabels.edges.map((item) => ({
    ...item.node,
    relatedCouponsNameList:
      item.node.relatedCoupons?.map(
        (coupon) => `[ID: ${coupon.id}] ${coupon.name}`,
      ) || '-',
    relatedCouponsNameWithoutIDList:
      item.node.relatedCoupons?.map((coupon) => coupon.name).join(', \n') ||
      '-',
    relatedCampaignsNameList:
      item.node.relatedCampaigns?.map(
        (campaign) => `[ID: ${campaign.id}] ${campaign.name}`,
      ) || '-',
    relatedCampaignsNameWithoutIDList:
      item.node.relatedCampaigns
        ?.map((campaign) => campaign.name)
        .join(', \n') || '-',
  }));

export default createModel({
  namespace: 'logoLabel',
  params: {
    dataKey: 'logoLabels',
    listAPI: getLogoLabelList,
    pkNode: 'LogoLabelNode',
    detailAPI: getLogoLabelDetail,
    deleteAPI: deleteLogoLabels,
    parse: parseLogoLabelList,
    parseDetail: (data) => parseLogoLabel(data.logoLabel),
    objectKey: 'logoLabels',
  },
  states: {
    hasUpdatedDefaultValues: false,
    formHasSubmitted: false,
  },
  reducers: {
    loadLogoLabelFromCookie(state, { payload }) {
      const logoLabel = getObjectFromSessionStorage(LOGO_LABEL_SESSION_KEY);
      if (!logoLabel) {
        return {
          ...state,
          detail: {
            hasUpdatedDefaultValues: false,
          },
        };
      }
      return {
        ...state,
        detail: {
          ...logoLabel,
          hasUpdatedDefaultValues: true,
        },
      };
    },
  },
  effects: {
    createLogoLabel: [
      function* ({ payload }, { all, select, put }) {
        const { data, afterAction } = payload;
        const serviceArgs = [createLogoLabel, cleanLogoLabel(data)];
        yield put(
          createAction('updateState')({
            apiStatus: APIStatus.calling,
          }),
        );
        function* onSuccess(data) {
          removeLogoLabelSessionStroage();
          yield put(
            createAction('updateState')({
              apiStatus: APIStatus.success,
              formHasSubmitted: true,
              newLogoLabelPk: data?.createLogoLabel?.node?.pk,
            }),
          );
          if (afterAction) {
            yield afterAction();
          }
        }
        function* onError(err) {
          yield put(
            createAction('updateState')({
              apiStatus: APIStatus.failed,
            }),
          );
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],
    duplicate: [
      function* ({ payload }, { all, select, put }) {
        const { data, afterAction } = payload;
        const duplicateData = parseLogoLabel(data);
        const name = duplicateData.translations?.[LanguageConfig.english]?.name;
        if (name) {
          duplicateData.translations[
            LanguageConfig.english
          ].name = `Copy of ${name}`;
        }
        const serviceArgs = [createLogoLabel, cleanLogoLabel(duplicateData)];
        yield put(
          createAction('updateState')({
            apiStatus: APIStatus.calling,
          }),
        );
        function* onSuccess(data) {
          yield put(
            createAction('updateState')({
              apiStatus: APIStatus.success,
            }),
          );
          if (afterAction) {
            yield afterAction();
          }
        }
        function* onError(err) {
          yield put(
            createAction('updateState')({
              apiStatus: APIStatus.failed,
            }),
          );
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],
    updateLogoLabel: [
      function* ({ payload }, { all, select, put }) {
        const { data } = payload;
        const detail = yield select((state) => state.logoLabel.detail);

        const serviceArgs = [updateLogoLabel, cleanLogoLabel(data, detail)];
        yield put(
          createAction('updateState')({
            apiStatus: APIStatus.calling,
          }),
        );
        function* onSuccess(data) {
          removeLogoLabelSessionStroage();
          yield put(
            createAction('updateState')({
              apiStatus: APIStatus.success,
              formHasSubmitted: true,
            }),
          );
        }
        function* onError(err) {
          yield put(
            createAction('updateState')({
              apiStatus: APIStatus.failed,
            }),
          );
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],
    updateLogoLabelOrder: [
      function* ({ payload }, { all, select, put }) {
        const { data, afterAction } = payload;
        const serviceArgs = [updateLogoLabel, data];
        yield put(
          createAction('updateState')({
            apiStatus: APIStatus.calling,
          }),
        );
        function* onSuccess(data) {
          if (afterAction) {
            yield afterAction();
          }
        }
        function* onError(err) {
          yield put(
            createAction('updateState')({
              apiStatus: APIStatus.failed,
            }),
          );
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],
  },
});
