import React, { useEffect, useState, useRef } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import { ArrayStringData, createAction } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';

function Filter({ backAction = () => { } }) {
  const location = useLocation();
  const dispatch = useDispatch();

  const parsedSearch = queryString.parse(location.search);
  const searchChannel = parsedSearch['channel'] || '';
  const searchLanguage = parsedSearch['language'] || '';

  const { channelList, languageList } = useSelector((state) => ({
    channelList: state.channel.allList,
    languageList: state.language.allList,
  }));

  useEffect(() => {
    dispatch(createAction('channel/getList')({ isSelectorLoad: true }));
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
  }, [dispatch]);

  const [messageChannel, setMessageChannel] = useState(
    ArrayStringData(searchChannel),
  );

  const [langauge, setLanguages] = useState(ArrayStringData(searchLanguage));

  const content = [
    {
      title: 'Language',
      data: languageList,
      value: langauge,
      setValue: setLanguages,
      componment: FilterTypeEnum.choice,
    },
    {
      title: 'Channel',
      data: channelList.map((item) => item),
      value: messageChannel,
      setValue: setMessageChannel,
      componment: FilterTypeEnum.choice,
      className: 'fit-content-height-area',
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

export default Filter;
