import React from 'react';
import './SearchArea.scss';
import SearchAreaContent from './SearchAreaContent';

function SearchArea({
  countryCodeList,
  isRegister,
  searchMembershipId,
  isCheckOTP,
}) {
  return (
    <div className="check-otp-search-container">
      <div className="check-otp-search-title">SEARCH</div>
      <SearchAreaContent
        countryCodeList={countryCodeList}
        isRegister={isRegister}
        searchMembershipId={searchMembershipId}
        isCheckOTP={isCheckOTP}
      />
    </div>
  );
}

export default SearchArea;
