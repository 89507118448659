import {
  updatePhysicalCouponTemplate,
  getPhysicalCouponTemplateList,
} from '../services/CouponAPIHelper';
import { addDomainToImage, parsePhotoUrlForAPI } from '../utils';
import { createModel } from './BaseModel';
import { apiWithResponseHandle, loading } from './LoadingUtil';

const getInitialState = () => ({
  physicalCouponTemplate: {},
});

const parseTemplate = (item) => {
  const data = item?.node;
  return {
    ...data,
    eventLogo: addDomainToImage(data?.eventLogo),
    physicalCouponPhoto: addDomainToImage(data?.physicalCouponPhoto),
    couponTemplate: data?.couponTemplate?.pk,
    freeTextTitleOne: data?.freeText1Title,
    freeTextContentOne: data?.freeText1Content,
    freeTextTitleTwo: data?.freeText2Title,
    freeTextContentTwo: data?.freeText2Content,
    freeTextTitleThree: data?.freeText3Title,
    freeTextContentThree: data?.freeText3Content,
    freeTextTitleFour: data?.freeText4Title,
    freeTextContentFour: data?.freeText4Content,
    freeTextTitleFive: data?.freeText5Title,
    freeTextContentFive: data?.freeText5Content,
    freeTextTitleSix: data?.freeText6Title,
    freeTextContentSix: data?.freeText6Content,
    freeTextTitleSeven: data?.freeText7Title,
    freeTextContentSeven: data?.freeText7Content,
    freeTextContentEight: data?.freeText8Content,
  };
};

const cleanTemplate = (data) => {
  return {
    id: data?.id,
    physicalDocumentTitle: data?.physicalDocumentTitle,
    freeText1Title: data?.freeTextTitleOne,
    freeText1Content: data?.freeTextContentOne,
    freeText2Title: data?.freeTextTitleTwo,
    freeText2Content: data?.freeTextContentTwo,
    eventLogo: parsePhotoUrlForAPI(data?.eventLogo) || '',
    showCouponSetExpiryDate: data?.showCouponSetExpiryDate,
    physicalCouponPhoto: parsePhotoUrlForAPI(data?.physicalCouponPhoto) || '',
    freeText3Title: data?.freeTextTitleThree,
    freeText3Content: data?.freeTextContentThree,
    freeText4Title: data?.freeTextTitleFour,
    freeText4Content: data?.freeTextContentFour,
    freeText5Title: data?.freeTextTitleFive,
    freeText5Content: data?.freeTextContentFive,
    freeText6Title: data?.freeTextTitleSix,
    freeText6Content: data?.freeTextContentSix,
    freeText7Title: data?.freeTextTitleSeven,
    freeText7Content: data?.freeTextContentSeven,
    freeText8Content: data?.freeTextContentEight,
  };
};

export default createModel({
  namespace: 'physicalCoupon',
  params: {
    dataKey: 'physicalCouponTemplates',
    parse: (data) =>
      data?.physicalCouponTemplates?.edges?.map((item) => parseTemplate(item)),
    objectKey: 'physicalCouponTemplates',
  },
  states: getInitialState(),
  reducers: {},
  effects: {
    updatePhysicalCouponTemplate: [
      function* ({ payload }, { put, all }) {
        console.log('@@77: ', payload);
        const afterAction = payload?.afterAction || (() => {});
        const failedAction = payload?.failedAction || (() => {});
        const serviceArgs = [
          updatePhysicalCouponTemplate,
          cleanTemplate(payload),
        ];
        function* onSuccess(data) {
          afterAction();
        }
        function* onFailed(data) {
          failedAction();
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getPhysicalCouponTemplate: [
      function* ({ payload }, { put, call }) {
        const serviceArgs = [getPhysicalCouponTemplateList, null, payload];
        function* onSuccess(data) {
          const physicalCouponTemplate =
            data?.physicalCouponTemplates?.edges?.map((item) =>
              parseTemplate(item),
            )?.[0];

          yield put({
            type: 'updateState',
            payload: {
              physicalCouponTemplate: physicalCouponTemplate || {},
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
});
