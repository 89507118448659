import React, { useEffect, useState } from 'react';
import './GoodieBagEntitlement.scss';
import queryString from 'query-string';
import ContentSections from '../../../../components/base/ContentSections';
import { SaveAndBackButtons } from '../../../../components/base/BottomStepComponent';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { PermissionCodes } from '../../../../config/PermissionCodes';
import CustomListComponent from '../../../../components/base/CustomListComponent';
import AuthButton from '../../../../components/base/AuthButton';
import CustomBreadcrumb from '../../../../components/base/CustomBreadcrumb';

import RecordTimelineCard from '../../../../components/customer/RecordTimelineCard';
import {
  checkHasPermission,
  createAction,
  getHashKeyString,
} from '../../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import BaseTabListContainer from '../../../base/BaseTabListContainer';
import BaseListContainer from '../../../base/BaseListContainer';
import { ADMIN_TYPE, AdminActivityType } from '../../../../config/CustomEnums';
import {
  FORTUNEBAG_OPTIONS_BUTTON_TYPE,
  FORTUNEBAG_ENTITLEMENT_OPTIONS,
} from '../../../../models/FortuneBagEntitlementModel';
import { Image } from 'react-bootstrap';
import DeletePrompt from '../../../../components/base/DeletePrompt';
import copy from 'copy-to-clipboard';
import CopyIcon from '../../../../assets/images/campaign/copy_icon.svg';
import Instruction_PDF from '../../../../media/OperationInstructionGoodieBag.pdf';
import { ImportResource } from '../../../../models/DownloadImportModel';
import { ToastType } from '../../../../models/NavBarModel';
import { formatDate, TimeFormater } from '../../../../utils/TimeFormatUtil';
import NoDataPage from '../../../../components/base/NoDataPage';
import {
  GOODIE_BAG_ENTITLEMENT_METHOD_TYPE_DISPLAY,
  GOODIE_BAG_ENTITLEMENT_METHOD_TYPE,
} from '../../../../models/GoodieBagsModel';
import { goodieBagsEntitlementBreads } from '../../../../config/CustomBreadConfig';
import { downloadZipFiles } from '../../../../containers/merchants/stores/QRCodeDownloadUtil';
import OptionDEntitlement from '../../../../components/goodieBags/OptionDEntitlement';
import EntitlementTrackingCodeExportPrompt from '../../../../components/goodieBags/EntitlementTrackingCodeExportPrompt';

const WECHAT_APP_ID = process.env.REACT_APP_WECHAT_APP_ID;

// time is only for merchant admin optionC download btn
function OptionButton({
  type,
  content,
  toastMessage = '',
  customAction = null,
  setUploadedFileName = () => {},
}) {
  const dispatch = useDispatch();
  const hiddenFileInput = React.useRef(null);

  let image,
    action,
    extraField,
    requires,
    displayString = content;

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    console.log('@@77', fileUploaded);
    setUploadedFileName(fileUploaded.name);
    dispatch(
      createAction('uploadFiles/uploadFile')({
        file: fileUploaded,
        source: event,
      }),
    );
    event.target.value = null;
  };
  switch (type) {
    case FORTUNEBAG_OPTIONS_BUTTON_TYPE.COPY:
      image = CopyIcon;
      action = () => {
        copy(content);
        dispatch({
          type: 'navBars/updateState',
          payload: {
            saveDiscardToastShowing: {
              value: true,
              type: toastMessage || ToastType.merchantContentCopied,
            },
          },
        });
      };
      displayString = '';
      break;
    case FORTUNEBAG_OPTIONS_BUTTON_TYPE.UPLOAD:
      displayString = content?.includes('.csv') ? 'Re-upload' : 'Upload';
      requires =
        PermissionCodes.changeGoodiebagEntitlementCustomerParticipationRecord;
      action = () => {
        console.log('re upload btn clicked!');
        hiddenFileInput.current.click();
      };
      extraField = (
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: 'none' }}
          accept=".csv"
        />
      );
      break;
    case FORTUNEBAG_OPTIONS_BUTTON_TYPE.DOWNLOAD:
      extraField = (
        <a href={content} className="lebel-right-button">
          Download
        </a>
      );
      action = customAction;
      displayString = '';
      break;
    default:
      break;
  }
  return (
    <AuthButton
      className="lebel-right-button"
      onClick={(e) => {
        if (action) {
          if (type && type === FORTUNEBAG_OPTIONS_BUTTON_TYPE.DOWNLOAD) {
            e.preventDefault();
          }
          action();
        }
      }}
      requires={requires}
      variant="link"
    >
      {displayString}
      {image && <Image src={image} />}
      {extraField && extraField}
    </AuthButton>
  );
}

// get merchant admin datas based on current option type
const merchantAdminDatas = (method) => {
  let title = '',
    tab = '',
    fields = [];
  switch (method) {
    case GOODIE_BAG_ENTITLEMENT_METHOD_TYPE.optionA:
      title = GOODIE_BAG_ENTITLEMENT_METHOD_TYPE_DISPLAY.OPTION_A.title;
      tab = GOODIE_BAG_ENTITLEMENT_METHOD_TYPE_DISPLAY.OPTION_A.title;
      fields = [
        {
          displayName: 'Merchant name',
          fieldName: 'merchantName',
        },
        {
          displayName: 'Merchant ID',
          fieldName: 'merchantId',
          fieldButton: FORTUNEBAG_OPTIONS_BUTTON_TYPE.COPY,
        },
        {
          displayName: 'Secret Key',
          fieldName: 'secretKey',
          fieldButton: FORTUNEBAG_OPTIONS_BUTTON_TYPE.COPY,
        },
      ];
      break;
    case GOODIE_BAG_ENTITLEMENT_METHOD_TYPE.optionB:
      title = GOODIE_BAG_ENTITLEMENT_METHOD_TYPE_DISPLAY.OPTION_B.title;
      tab = GOODIE_BAG_ENTITLEMENT_METHOD_TYPE_DISPLAY.OPTION_B.title;
      fields = [
        {
          displayName:
            'Static URL - Distribute from email or SMS, and open in web browser',
          fieldName: 'merchantStaticLink',
          fieldButton: FORTUNEBAG_OPTIONS_BUTTON_TYPE.COPY,
        },
        {
          displayName:
            'Static URL - Distribute from mini program, and open in mini program',
          fieldName: 'wechatStaticLink',
          fieldButton: FORTUNEBAG_OPTIONS_BUTTON_TYPE.COPY,
        },
        {
          displayName: 'Entitled customer (.csv)',
          fieldName: 'entitledCustomerFileName',
          fieldButton: FORTUNEBAG_OPTIONS_BUTTON_TYPE.UPLOAD,
        },
      ];
      break;
    case GOODIE_BAG_ENTITLEMENT_METHOD_TYPE.optionC:
      title = GOODIE_BAG_ENTITLEMENT_METHOD_TYPE_DISPLAY.OPTION_C.title;
      tab = GOODIE_BAG_ENTITLEMENT_METHOD_TYPE_DISPLAY.OPTION_C.title;
      fields = [
        {
          displayName: 'Batch no.',
          fieldName: 'batchNo',
        },
        {
          displayName: 'No. of bags',
          fieldName: 'fortuneBagQuantity',
        },
        {
          displayName: 'Last updated time',
          fieldName: 'displayLastModifiedDate',
        },
        {
          displayName: 'Goodie bag list',
          fieldName: 'fortuneBagUrl',
          fieldButton: FORTUNEBAG_OPTIONS_BUTTON_TYPE.DOWNLOAD,
        },
      ];
      break;
    case GOODIE_BAG_ENTITLEMENT_METHOD_TYPE.optionD:
      title = GOODIE_BAG_ENTITLEMENT_METHOD_TYPE_DISPLAY.OPTION_D.title;
      tab = GOODIE_BAG_ENTITLEMENT_METHOD_TYPE_DISPLAY.OPTION_D.title;
      fields = [
        {
          displayName:
            'Generic URL - Distribute from email or SMS, and open in web browser',
          fieldName: 'merchantStaticLink',
          fieldButton: FORTUNEBAG_OPTIONS_BUTTON_TYPE.COPY,
        },
        {
          displayName:
            'Generic URL - Distribute from mini program, and open in mini program',
          fieldName: 'wechatStaticLink',
          fieldButton: FORTUNEBAG_OPTIONS_BUTTON_TYPE.COPY,
        },
      ];
      break;
    default:
      break;
  }

  return {
    title: title,
    tab: tab,
    fields: fields,
  };
};

function MerchantTable({
  method,
  userAdminType,
  entitlementOptionDatas,
  setUploadedFileName,
}) {
  const dispatch = useDispatch();

  const isMerchant = userAdminType === ADMIN_TYPE.MERCHANT_ADMIN;
  const isAdmin = userAdminType === ADMIN_TYPE.HKTB_ADMIN;

  let header = [];
  if (isAdmin) {
    switch (method) {
      case GOODIE_BAG_ENTITLEMENT_METHOD_TYPE.optionA:
        header.push(
          {
            displayName: 'Merchant name',
            fieldName: 'merchantName',
          },
          {
            displayName: 'Merchant ID',
            fieldName: 'merchantId',
            fieldButton: FORTUNEBAG_OPTIONS_BUTTON_TYPE.COPY,
          },
          {
            displayName: 'Secret Key',
            fieldName: 'secretKey',
            fieldButton: FORTUNEBAG_OPTIONS_BUTTON_TYPE.COPY,
          },
        );
        break;
      case GOODIE_BAG_ENTITLEMENT_METHOD_TYPE.optionB:
        header.push(
          {
            displayName: 'Merchant name',
            fieldName: 'merchantName',
          },
          {
            displayName:
              'Static URL - Distribute from email or SMS, and open in web browser',
            fieldName: 'merchantStaticLink',
            fieldButton: FORTUNEBAG_OPTIONS_BUTTON_TYPE.COPY,
          },
          {
            displayName:
              'Static URL - Distribute from mini program, and open in mini program',
            fieldName: 'wechatStaticLink',
            fieldButton: FORTUNEBAG_OPTIONS_BUTTON_TYPE.COPY,
          },
        );
        break;
      case GOODIE_BAG_ENTITLEMENT_METHOD_TYPE.optionC:
        header.push(
          {
            displayName: 'Batch no.',
            fieldName: 'batchNo',
          },
          {
            displayName: 'Merchant name',
            fieldName: 'merchantName',
          },
          {
            displayName: 'No. of bags',
            fieldName: 'fortuneBagQuantity',
          },
          {
            displayName: 'Last updated time',
            fieldName: 'displayLastModifiedDate',
          },
          {
            displayName: 'Goodie bag list',
            fieldName: 'fortuneBagUrl',
            fieldButton: FORTUNEBAG_OPTIONS_BUTTON_TYPE.DOWNLOAD,
          },
        );
        break;
      case GOODIE_BAG_ENTITLEMENT_METHOD_TYPE.optionD:
        header.push(
          {
            displayName: 'Merchant name',
            fieldName: 'merchantName',
          },
          {
            displayName:
              'Static URL - Distribute from email or SMS, and open in web browser',
            fieldName: 'merchantStaticLink',
            fieldButton: FORTUNEBAG_OPTIONS_BUTTON_TYPE.COPY,
          },
          {
            displayName:
              'Static URL - Distribute from mini program, and open in mini program',
            fieldName: 'wechatStaticLink',
            fieldButton: FORTUNEBAG_OPTIONS_BUTTON_TYPE.COPY,
          },
        );
        break;
      default:
        break;
    }
  }

  if (isMerchant) {
    header = merchantAdminDatas(method)?.fields;
  }

  if (entitlementOptionDatas?.length <= 0) {
    return <NoDataPage />;
  }

  let newEntitlementOptionDatas = entitlementOptionDatas;
  if (method === GOODIE_BAG_ENTITLEMENT_METHOD_TYPE.optionA) {
    let merchantList = [];
    newEntitlementOptionDatas = entitlementOptionDatas
      ?.sort(function (a, b) {
        return a.pk - b.pk;
      })
      ?.filter((optionData) => {
        if (merchantList.includes(optionData.merchantId)) {
          return false;
        }
        merchantList.push(optionData.merchantId);
        return true;
      });
  }

  // utils
  const fieldForDisplay = (field) => {
    if (typeof field !== 'string') {
      return field;
    }

    const regex = '([^\\/]+.csv$)';
    const found = field.match(regex);
    return found ? found[0] : field;
  };

  return (
    <>
      <label className="create-section-title">
        {isMerchant ? merchantAdminDatas(method)?.title : 'MERCHANTS'}
      </label>
      <div className="goodie-bag-mini-program">
        <label className="goodie-bag-mini-program-title">
          App ID (HKTB mini program):
        </label>
        <label className="goodie-bag-mini-program-id">{WECHAT_APP_ID}</label>
        <OptionButton
          type={FORTUNEBAG_OPTIONS_BUTTON_TYPE.COPY}
          content={WECHAT_APP_ID}
          toastMessage={'App ID copied'}
          setUploadedFileName={(value) => setUploadedFileName(value)}
        />
      </div>
      <table className="goodie-bag-merchant-table">
        <thead>
          <tr>
            {header.map((headerContent, index) => {
              return <th>{headerContent.displayName}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {newEntitlementOptionDatas
            .sort(function (a, b) {
              return b.batchNo - a.batchNo;
            })
            .map((fieldContent, index) => {
              return (
                <tr>
                  {header.map((headerContent) => {
                    const fieldString = fieldContent[headerContent.fieldName];
                    const fieldButtonType = headerContent.fieldButton;
                    const customAction = () => {
                      if (
                        fieldButtonType ===
                        FORTUNEBAG_OPTIONS_BUTTON_TYPE.DOWNLOAD
                      ) {
                        dispatch({
                          type: 'adminActivityLog/createAdministratorActivityLog',
                          payload: {
                            activityType:
                              AdminActivityType.DOWNLOAD_ENTITLEMENT_SIGN_LIST
                                .pk,
                            objectId: fieldContent.entitlementId,
                          },
                        });
                        downloadZipFiles(fieldString);
                      }
                    };
                    return (
                      <td>
                        {fieldForDisplay(fieldString)}
                        {fieldButtonType ? (
                          <OptionButton
                            type={fieldButtonType}
                            content={fieldString}
                            toastMessage={null}
                            customAction={customAction}
                            setUploadedFileName={(value) =>
                              setUploadedFileName(value)
                            }
                          />
                        ) : null}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      </table>
    </>
  );
}

function CustomerList({ setShowDeletePrompt }) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { listFields, dataList, pageInfo, totalPage, totalCount } = useSelector(
    (state) => ({
      listFields: state.fortuneBagEntitlement.listDisplayFields,
      dataList: state.fortuneBagEntitlement.pagedList,
      pageInfo: state.fortuneBagEntitlement.pageInfo,
      totalPage: state.fortuneBagEntitlement.totalPage,
      totalCount: state.fortuneBagEntitlement.totalCount,
    }),
  );

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setShowDeletePrompt(true);
      },
      requires:
        PermissionCodes.changeGoodiebagEntitlementCustomerParticipationRecord,
    },
  ];

  return (
    <BaseTabListContainer
      hideTab={true}
      groupActions={groupActions}
      searchPlaceholder={'Search by customer ID'}
      pageInfo={pageInfo}
      totalCount={totalCount}
      listContent={
        <BaseListContainer
          fields={listFields}
          dataList={dataList}
          totalPage={totalPage}
          model={'fortuneBagEntitlement'}
          permissionGroup={
            PermissionCodes.goodiebagEntitlementCustomerParticipationRecord
          }
          actions={['Delete']}
          deleteInfo={{
            data: [],
            title: 'Entitlement Customers',
            onComfirm: {},
          }}
          hasOtherActions={true}
          customActions={{
            deactive: (item) => {
              dispatch(
                createAction(
                  'fortuneBagEntitlement/activeEntitlementCustomers',
                )({
                  data: {
                    id: item.pk,
                    isActive: item.status === 'Active' ? false : true,
                  },
                  afterAction: () => history.push(location),
                }),
              );
            },
          }}
        />
      }
    />
  );
}

export default function GoodieBagEntitlement() {
  const params = useParams();
  const { id } = params;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const {
    goodieBagDetail,
    activityLogs,
    entitlementOptionDatas,
    // entitlementOption,
    userAdminType,
    checkedList,
    importedFile,
    fortuneBagEntitlement,
    adminUser,
  } = useSelector((state) => ({
    goodieBagDetail: state.goodieBags.detail,
    activityLogs: state.customerList.activityLogs,
    entitlementOptionDatas: state.fortuneBagEntitlement.entitlementOptionDatas,
    // entitlementOption: state.fortuneBagEntitlement.entitlementOption,
    userAdminType: state.users.adminType,
    checkedList: state.offerTypeLabel.checkedList,
    importedFile: state.uploadFiles.importedFile,
    fortuneBagEntitlement: state.fortuneBagEntitlement,
    adminUser: state.users,
  }));

  console.log('@65', fortuneBagEntitlement, activityLogs);

  const TAB_METHOD = 'Method';
  const TAB_CUSTOMER_LIST = 'Customer list';
  const isMerchant = userAdminType === ADMIN_TYPE.MERCHANT_ADMIN;
  const isAdmin = userAdminType === ADMIN_TYPE.HKTB_ADMIN;
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  const hasActivityLogs = activityLogs.length > 0;
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [showExportTrackingCodeView, setShowExportTrackingCodeView] =
    useState(false);

  const [method, setMethod] = useState();

  useEffect(() => {
    if (history.location?.state?.goodieBagMethod) {
      setMethod(history.location?.state?.goodieBagMethod);
    }
    if (
      !history.location?.state?.goodieBagMethod ||
      history.location?.state?.goodieBagMethod ===
        GOODIE_BAG_ENTITLEMENT_METHOD_TYPE.optionD
    ) {
      dispatch(
        createAction('goodieBags/getOneGoodieBag')({
          id,
          getDetailOnly: true,
          callFromEntitlement: true,
          afterAction: (goodieBagMethod) => {
            setMethod(goodieBagMethod);
          },
        }),
      );
    }
  }, []);

  useEffect(() => {
    dispatch({ type: 'customerList/clearData' });
    dispatch({ type: 'fortuneBagEntitlement/clearData' });
    if (method) {
      if (method !== GOODIE_BAG_ENTITLEMENT_METHOD_TYPE.optionD) {
        dispatch(
          createAction('customerList/getCustomerActivityLog')({
            goodieBagId: id,
          }),
        );
        // dispatch(
        //   createAction('fortuneBagEntitlement/getEntitlements')({
        //     goodieBagId: id,
        //   }),
        // );
        dispatch(
          createAction('fortuneBagEntitlement/getAllEntitlements')({
            goodieBagId: id,
          }),
        );
      } else {
        const searchDict = queryString.parse(location.search);
        const searchKey = searchDict['search'];
        const stringPage = searchDict['page'] || 1;
        const searchOTA = searchDict['ota'];
        const page = parseInt(stringPage);
        dispatch({
          type: 'fortuneBagEntitlement/getEntitlements',
          payload: {
            goodieBagId: id,
            // rank,
            search: searchKey,
            page,
            pageSize: 20,
          },
        });
      }
    }
  }, [method]);

  useEffect(() => {
    const searchDict = queryString.parse(location.search);
    const searchKey = searchDict['search'];
    const stringPage = searchDict['page'] || 1;
    const page = parseInt(stringPage);

    if (method === GOODIE_BAG_ENTITLEMENT_METHOD_TYPE.optionB) {
      dispatch(
        createAction('fortuneBagEntitlement/getList')({
          ...searchDict,
          page,
          filterName: searchKey,
          goodieBagId: id,
        }),
      );
    } else if (method === GOODIE_BAG_ENTITLEMENT_METHOD_TYPE.optionD) {
      const otaIds = searchDict['ota'];
      dispatch({
        type: 'fortuneBagEntitlement/getEntitlements',
        payload: {
          goodieBagId: id,
          otaIds: otaIds,
          // rank,
          search: searchKey,
          page,
          pageSize: 20,
        },
      });
    }
  }, [dispatch, location, id]);

  useEffect(() => {
    if (
      importedFile &&
      history.location?.state?.goodieBagMethod ===
        GOODIE_BAG_ENTITLEMENT_METHOD_TYPE.optionB
    ) {
      dispatch(
        createAction('downloadAndImport/importFile')({
          related: {
            entitlementId: entitlementOptionDatas[0].entitlementId,
            fileName: uploadedFileName,
          },
          file: importedFile,
          from: ImportResource.optionBGoodieBagEntitlementCustomers,
        }),
      );

      dispatch({
        type: 'uploadFiles/updateState',
        payload: { importedFile: null },
      });

      if (
        entitlementOptionDatas[0].entitledCustomerFileName !== uploadedFileName
      ) {
        let newDatas = [...entitlementOptionDatas];
        newDatas[0].entitledCustomerFileName = uploadedFileName;
        dispatch(
          createAction('fortuneBagEntitlement/updateState')({
            entitlementOptionDatas: newDatas,
          }),
        );
      }
    }
  }, [importedFile]);

  useEffect(() => {
    if (entitlementOptionDatas?.length) {
      const merchantIds = entitlementOptionDatas.map((item) => {
        return item.merchantId;
      });
      dispatch(
        createAction('entitlementTrackingCodes/getTrackingCodeCount')({
          goodieBagId: id,
          merchants: merchantIds,
        }),
      );
    }
  }, [entitlementOptionDatas]);

  const firstTabName = () => {
    return isMerchant ? TAB_METHOD : merchantAdminDatas(method).tab;
  };

  const getTabs = () => {
    // use method and isMerchant to determine layout
    const getSections = () => {
      let sections = [];
      if (method === GOODIE_BAG_ENTITLEMENT_METHOD_TYPE.optionD) {
        sections.push(
          <OptionDEntitlement
            setShowExportView={(value) => {
              setShowExportTrackingCodeView(value);
            }}
          />,
        );
      } else {
        sections.push(
          <MerchantTable
            method={method}
            userAdminType={userAdminType}
            entitlementOptionDatas={entitlementOptionDatas}
            setUploadedFileName={(value) => setUploadedFileName(value)}
          />,
        );
      }

      if (
        method === GOODIE_BAG_ENTITLEMENT_METHOD_TYPE.optionB &&
        hasActivityLogs &&
        [
          PermissionCodes.viewGoodiebagEntitlementCustomerParticipationRecord,
          PermissionCodes.changeGoodiebagEntitlementCustomerParticipationRecord,
        ].filter((val) => checkHasPermission(adminUser, val)).length > 0
      ) {
        sections.push(
          <div className="customer">
            <RecordTimelineCard
              title={'RECORDS'}
              data={activityLogs}
              entitlement={true}
            />
          </div>,
        );
      }

      return sections;
    };

    let tabs = [];
    tabs.push({
      name: isMerchant ? TAB_METHOD : firstTabName(),
      content: (
        <>
          <div className="scroll-container-common entitlement-content-section">
            <ContentSections sections={getSections()} hidePreview={true} />
            <SaveAndBackButtons
              saveTempText={''}
              saveText={''}
              backAction={() => history.goBack()}
              saveAction={() => {}}
              saveTempAction={() => {}}
            />
          </div>
        </>
      ),
    });

    // hide CustomerList
    if (
      // isMerchant &&
      method === GOODIE_BAG_ENTITLEMENT_METHOD_TYPE.optionB &&
      [
        PermissionCodes.viewGoodiebagEntitlementCustomerParticipationRecord,
        PermissionCodes.changeGoodiebagEntitlementCustomerParticipationRecord,
      ].filter((val) => checkHasPermission(adminUser, val)).length > 0
    ) {
      tabs.push({
        name: TAB_CUSTOMER_LIST,
        content: (
          <CustomerList
            setShowDeletePrompt={(value) => setShowDeletePrompt(value)}
          />
        ),
      });
    }
    return tabs;
  };

  const buttons = [
    <AuthButton
      title="Operation Instruction pdf"
      action={() => {
        window.open(Instruction_PDF);
      }}
    />,
  ];

  const activeTab = getHashKeyString(location.hash) || firstTabName();

  return (
    <>
      <CustomListComponent
        caution={{
          title: 'Entitlement',
        }}
        defaultActiveKey={activeTab}
        onTabChange={(key) => {
          history.replace({
            pathname: location.pathname,
            hash: key,
            state: location.state,
          });
        }}
        hideTab={false}
        breadcrumb={
          <CustomBreadcrumb
            breadcrumbs={goodieBagsEntitlementBreads(
              id,
              history.location?.state?.goodieBagName || goodieBagDetail?.name,
            )}
          />
        }
        buttons={buttons}
        tabs={getTabs()}
      />
      <DeletePrompt
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch(
            createAction('fortuneBagEntitlement/delete')({
              afterAction: () => history.push(location),
            }),
          );
        }}
        data={checkedList}
        title={'Entitlement Customer'}
      />
      <EntitlementTrackingCodeExportPrompt
        show={showExportTrackingCodeView}
        goodieBag={goodieBagDetail}
        closeAction={() => {
          setShowExportTrackingCodeView(false);
        }}
        exportAction={() => {
          setShowExportTrackingCodeView(false);
        }}
      />
    </>
  );
}
