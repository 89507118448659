import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import { createAction } from '../../../utils';
import {
  saveDataToSessionStorage,
  removeDataFromSessionStorage,
} from '../../../models/AdminModel';
import {
  hasError,
  ReactHookFormErrorMessage,
} from '../../../components/base/ErrorFieldMessage';
import BaseForm from '../../../components/base/v2/BaseForm';
import Loading from '../../../components/base/Loading';
import {
  SectionTopTitle,
  FieldControl,
} from '../../../components/base/CommonComponent';
import {
  DELETE_RELATED_SECTIONS,
  SavedStatus,
  ADMIN_TYPE,
  EMAIL_REG,
  CountryCodeOptions,
  AdminLoginMethod,
  DisplayAdminType,
} from '../../../config/CustomEnums';
import { CustomTitleWithRadio } from '../../../components/earning/CustomBaseComponments';
import BrandSelectDropdown from '../../../components/campaign/campaignCreation/BrandSelectDropdown';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import ContentSections from '../../../components/base/ContentSections';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import DeletePrompt from '../../../components/base/DeletePrompt';
import CustomTitleWithInput from '../../../components/base/CustomTitleWithInput';
import CustomTitleWithSwitch from '../../../components/base/CustomTitleWithSwitch';
import { AdminErrorHandleFields } from './AdminErrorHandleFields';
import { CustomTitleLabel } from '../../../components/earning/CustomBaseComponments';
import { Button } from 'react-bootstrap';
import ChangePasswordPrompt from '../../../components/admin/ChangePasswordPrompt';
import AddNewAdminGroupPrompt from '../../../components/admin/AddNewAdminGroupPrompt';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { ToastType } from '../../../models/NavBarModel';
import BasePrompt from '../../../components/base/prompt/BasePrompt';
import BaseMutipleSelectorV2 from '../../../components/base/BaseMultipleSelectorV2';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { CustomTitleWithDropDown } from '../../../components/customer/CustomTitleWithDropDown';

const PERMISSION_TYPE = {
  GROUP: 'GROUP',
  PERMISSION: 'PERMISSION',
};

const ChangePasswordCom = ({ changeAction }) => {
  return (
    <div className="d-flex flex-column">
      <CustomTitleLabel title="Password" />
      <label className="tips-message">
        Raw passwords are not stored, so there is no way to see this user's
        password.
      </label>
      <label className="tips-message">{'*'.repeat(10)}</label>
      <Button className="btn-back-button-common" onClick={changeAction}>
        Change
      </Button>
    </div>
  );
};

function InputFieldControl({
  name,
  title,
  rules,
  type = 'text',
  tips,
  value,
  setValue,
  optional,
  ...inputValues
}) {
  const { formState } = useFormContext();
  const { errors } = formState;
  return (
    <FieldControl
      name={name}
      rules={rules}
      render={() => (
        <>
          <CustomTitleWithInput
            title={title}
            tips={tips}
            type={type}
            error={{ error: hasError(errors, name) }}
            setValue={setValue}
            extra={{ ...inputValues, value: value }}
          />
          {optional ? null : (
            <ReactHookFormErrorMessage errors={errors} id={name} />
          )}
        </>
      )}
    />
  );
}

const adminLoginMethodOptions = [
  {
    label: 'e-solution admin (with option to enable PartnerNet SSO)',
    value: AdminLoginMethod.All,
  },
  { label: 'PartnerNet SSO only', value: AdminLoginMethod.Saml },
];

function BrandSelect({ setPopConfig = () => {} }) {
  const { watch, setValue, formState } = useFormContext();
  const { errors } = formState;
  const watchBrand = watch('brand');
  const history = useHistory();
  const dispatch = useDispatch();
  const queryString = require('query-string');
  const newBrand = queryString.parse(history.location?.hash)?.newBrand;

  useEffect(() => {
    if (newBrand) {
      dispatch(
        createAction('brand/getBrand')({
          brandPK: newBrand,
        }),
      );
    }
  }, [history]);

  const { selectedBrand } = useSelector((state) => ({
    selectedBrand: state.brand.selectedBrand,
  }));

  useEffect(() => {
    if (newBrand && selectedBrand) {
      setValue('brand', selectedBrand, { shouldDirty: true });
    }
  }, [selectedBrand]);

  useEffect(() => {
    return () => {
      dispatch(createAction('brand/cleanBrand')());
    };
  }, [dispatch]);

  return (
    <>
      <FieldControl
        name={'brand'}
        rules={{ required: 'Please provide related brand.' }}
        render={() => (
          <>
            <BrandSelectDropdown
              customClass={hasError(errors, 'brand') ? 'field-has-error' : ''}
              title={'Related brand'}
              defaultValue={
                newBrand && selectedBrand
                  ? {
                      value: selectedBrand,
                      label: selectedBrand?.name,
                    }
                  : {
                      value: watchBrand,
                      label: watchBrand?.name,
                    }
              }
              onSelectChange={(item) => {
                if (item?.value !== watchBrand) {
                  setValue('brand', item?.value, { shouldDirty: true });
                  setValue('store', null, { shouldDirty: true });
                }
              }}
              addButton={{
                title: 'Create Now',
                action: () => {
                  setPopConfig({
                    rightButton: {
                      text: `Go to create Brand`,
                      action: () => {
                        setPopConfig(null);
                        history.push({
                          pathname: '/brands/create',
                          state: {
                            from: history.location,
                            title: 'Continue to create admin?',
                            content: 'You can continue to create the admin.',
                          },
                        });
                      },
                    },
                    title: `Go to create a Brand?`,
                    description: `You will leave the admin creation process. After you create a new Brand, you can back to this page.`,
                  });
                },
                customClass: 'multiple-select-option-add btn-add-button-common',
              }}
              editable={true}
            />
            <ReactHookFormErrorMessage errors={errors} id="brand" />
          </>
        )}
      />
    </>
  );
}

function StoreSelect({ setPopConfig = () => {} }) {
  const { watch, setValue, formState } = useFormContext();
  const { errors } = formState;
  const watchBrand = watch('brand');
  const watchStore = watch('store');
  const history = useHistory();
  const dispatch = useDispatch();

  const { storeList } = useSelector((state) => ({
    storeList: state.storeModel.brandStoreList,
  }));

  useEffect(() => {
    if (watchBrand?.pk) {
      dispatch({
        type: 'storeModel/doStoreRequest',
        payload: { brands: [watchBrand.pk] },
      });
    } else {
      dispatch({
        type: 'storeModel/updateState',
        payload: { brandStoreList: [] },
      });
    }
  }, [watchBrand]);

  return (
    <>
      <FieldControl
        name={'store'}
        rules={{ required: 'Please provide related store.' }}
        render={() => (
          <>
            <CustomTitleWithDropDown
              customClass={hasError(errors, 'store') ? 'field-has-error' : ''}
              title="Related store"
              defaultValue={{
                value: watchStore,
                label: watchStore?.storeName || watchStore?.name,
              }}
              source={storeList}
              setValue={(item) => {
                setValue('store', item?.value, { shouldDirty: true });
              }}
              // disabled={!watchBrand}
              addButtonInfo={{
                title: 'Create Now',
                action: () => {
                  setPopConfig({
                    rightButton: {
                      text: `Go to create Store`,
                      action: () => {
                        setPopConfig(null);
                        history.push({
                          pathname: '/stores/create',
                          state: {
                            from: history.location,
                            title: 'Continue to create admin?',
                            content: 'You can continue to create the admin.',
                          },
                        });
                      },
                    },
                    title: `Go to create a Store?`,
                    description: `You will leave the admin creation process. After you create a new Store, you can back to this page.`,
                  });
                },
                customClass:
                  'general-section-add-new-brand btn-add-button-common',
                requires: PermissionCodes.addStore,
              }}
              filterAction={'storeModel/doStoreRequest'}
              defaultFilter={{ brands: [watchBrand?.pk] }}
            />
            <ReactHookFormErrorMessage errors={errors} id="store" />
          </>
        )}
      />
    </>
  );
}

function PersonalInfoSections() {
  const { watch, setValue } = useFormContext();
  const watchAdminType = watch('adminType') || ADMIN_TYPE.HKTB_ADMIN;
  const watchEmailAddress = watch('email');
  const watchFirstName = watch('firstName');
  const watchLastName = watch('lastName');
  const watchMobile = watch('mobile');
  const isMerchant = watchAdminType === ADMIN_TYPE.MERCHANT_ADMIN;
  const isMerchantApp = watchAdminType === ADMIN_TYPE.MERCHANT_APP_ACCOUNT;
  const watchAdminLoginMethod =
    watch('adminLoginMethod') || AdminLoginMethod.All;
  const isSaml =
    watchAdminType === ADMIN_TYPE.MERCHANT_ADMIN &&
    watchAdminLoginMethod === AdminLoginMethod.Saml;

  const validMobileNumber = (countryCode, mobile) => {
    if (!countryCode || !mobile) {
      return false;
    }

    if (countryCode === CountryCodeOptions[2].value) {
      return mobile?.match(/^[1-9]\d{10}$/)?.length === 1;
    }
    if (countryCode == CountryCodeOptions[0].value) {
      return mobile?.match(/^[1-9]\d{7}$/)?.length === 1;
    }
    return /^[1-9]\d+$/.test(mobile);
  };

  const params = useParams();
  const { id } = params;
  const { admin } = useSelector((state) => ({
    admin: state.admin.oneAdmin,
  }));
  const [showChangePassPrompt, setShowChangePassPrompt] = useState(false);
  const watchName = watch('name');
  const watchPassword = watch('password');
  const watchConfirmPassword = watch('confirmPassword');
  const adminName = useSelector((state) => state.admin.oneAdmin.name);
  const dispatch = useDispatch();
  const [popConfig, setPopConfig] = useState(null);

  const passwordRex = /\D/g;
  const nameSection = (
    <InputFieldControl
      name={'name'}
      title={'Name'}
      rules={{ required: AdminErrorHandleFields.required.username }}
      value={watchName}
      setValue={(value) => {
        setValue('name', value, { shouldDirty: true });
      }}
    />
  );
  const passwordSection = (
    <InputFieldControl
      name={'password'}
      title={'Password'}
      autoComplete="new-password"
      tips="Password at least 8 characters, can't be entirely numeric."
      type="password"
      rules={{
        validate: {
          lessThanEight: (value) => {
            return (
              isSaml ||
              value?.length >= 8 ||
              AdminErrorHandleFields.others.password.length
            );
          },
          allNumeric: (value) => {
            return (
              passwordRex.test(value) ||
              AdminErrorHandleFields.others.password.numeric
            );
          },
        },
      }}
      value={watchPassword}
      setValue={(value) => {
        setValue('password', value, { shouldDirty: true });
      }}
    />
  );
  const confirmPasswordSection = (
    <InputFieldControl
      name={'confirmPassword'}
      title={'Password confirmation'}
      tips="Enter the same password as before, for verification"
      type="password"
      autoComplete="new-password"
      rules={{
        validate: {
          lessThanEight: (value) => {
            return (
              isSaml ||
              value?.length ||
              AdminErrorHandleFields.required.passwordConfirmation
            );
          },
          allNumeric: (value) => {
            if (watchPassword !== value) {
              setValue('password', '', { shouldDirty: true });
              setValue('confirmPassword', '', { shouldDirty: true });
              dispatch({
                type: 'navBars/updateState',
                payload: {
                  saveDiscardToastShowing: {
                    value: true,
                    type: ToastType.passwordConfirmation,
                  },
                },
              });
            }
            return (
              watchPassword === value ||
              'Confirmation password should be same with password entered before.'
            );
          },
        },
      }}
      value={watchConfirmPassword}
      setValue={(value) => {
        setValue('confirmPassword', value, { shouldDirty: true });
      }}
    />
  );

  const adminType = (
    <>
      <FieldControl
        name={'adminType'}
        rules={{}}
        render={() => (
          <CustomTitleWithRadio
            title="Administrator type"
            setValue={(value) => {
              setValue('adminType', value, { shouldDirty: true });
            }}
            defaultValue={watchAdminType}
            options={DisplayAdminType}
          />
        )}
      />
    </>
  );

  const firstName = (
    <InputFieldControl
      name={'firstName'}
      title={'First name (optional)'}
      rules={{}}
      value={watchFirstName}
      setValue={(value) => {
        setValue('firstName', value, { shouldDirty: true });
      }}
    />
  );

  const lastName = (
    <InputFieldControl
      name={'lastName'}
      title={'Last name (optional)'}
      rules={{}}
      value={watchLastName}
      setValue={(value) => {
        setValue('lastName', value, { shouldDirty: true });
      }}
    />
  );

  const emailAddress = (
    <InputFieldControl
      name={'email'}
      title={'Email address'}
      tips={'To login via PartnerNet, you must provide PartnerNet email.'}
      rules={{
        validate: {
          merchantEmailRequire: (value) => {
            return (
              !isSaml ||
              watchEmailAddress?.length ||
              AdminErrorHandleFields.required.merchantAdminEmail
            );
          },
          emailRequire: (value) => {
            return (
              isSaml ||
              watchMobile?.length ||
              watchEmailAddress?.length ||
              AdminErrorHandleFields.required.emialOrMobile
            );
          },
          validEmail: (value) =>
            !watchEmailAddress?.length ||
            EMAIL_REG.test(watchEmailAddress) ||
            AdminErrorHandleFields.others.email.valid,
        },
      }}
      value={watchEmailAddress}
      setValue={(value) => {
        setValue('email', value, { shouldDirty: true });
      }}
    />
  );

  const mobile = (
    <InputFieldControl
      name={'mobile'}
      title={'Phone number'}
      tips={
        'Input a valid mobile number with country or region code. For example: +85299999999'
      }
      rules={{
        validate: {
          mobileRequire: (value) => {
            return (
              isSaml ||
              watchMobile?.length ||
              watchEmailAddress?.length ||
              AdminErrorHandleFields.required.emialOrMobile
            );
          },
          validMobile: (value) => {
            const formatNumber = watchMobile?.trim();
            const phoneNumber =
              formatNumber && parsePhoneNumberFromString(formatNumber);
            const { countryCallingCode, nationalNumber } = phoneNumber || {};
            console.log('@@439', watchEmailAddress?.length);
            return (
              !watchMobile?.length ||
              (watchMobile?.startsWith('+') &&
                validMobileNumber(countryCallingCode, nationalNumber)) ||
              AdminErrorHandleFields.others.mobile.valid
            );
          },
        },
      }}
      value={watchMobile}
      setValue={(value) => {
        setValue('mobile', value?.trim(), { shouldDirty: true });
      }}
    />
  );

  return (
    <>
      <SectionTopTitle title="General & Personal Information" />
      {nameSection}
      {adminType}
      {isMerchant ? (
        <FieldControl
          name={'adminLoginMethod'}
          rules={{}}
          render={() => (
            <CustomTitleWithRadio
              title={'Select merchant login method'}
              defaultValue={watchAdminLoginMethod}
              options={adminLoginMethodOptions}
              setValue={(value) => {
                setValue('adminLoginMethod', value, { shouldDirty: true });
              }}
            />
          )}
        />
      ) : null}
      {isMerchant || isMerchantApp ? (
        <BrandSelect setPopConfig={setPopConfig} />
      ) : null}
      {isMerchantApp ? <StoreSelect setPopConfig={setPopConfig} /> : null}
      {isMerchant || isMerchantApp ? null : firstName}
      {isMerchant || isMerchantApp ? null : lastName}
      {!isMerchantApp ? emailAddress : null}
      {isSaml || isMerchantApp ? null : mobile}
      {isSaml ? null : id && !admin?.passwordWithEmpty ? (
        <ChangePasswordCom changeAction={() => setShowChangePassPrompt(true)} />
      ) : (
        <>
          {passwordSection}
          {confirmPasswordSection}
        </>
      )}

      <ChangePasswordPrompt
        admin={{ username: adminName, pk: id }}
        show={showChangePassPrompt}
        onClose={() => setShowChangePassPrompt(false)}
        onConfirm={() => setShowChangePassPrompt(false)}
      />
      <BasePrompt
        show={!!popConfig}
        closeAction={() => {
          setPopConfig(null);
        }}
        rightButton={{ ...popConfig?.rightButton }}
        title={popConfig?.title}
        description={popConfig?.description}
      />
    </>
  );
}

function StatusSections() {
  const { watch, setValue } = useFormContext();
  const watchAdminType = watch('adminType') || ADMIN_TYPE.HKTB_ADMIN;
  const watchIsActive = watch('isActive');
  const watchSuperAdmin = watch('isSuperuser');
  const isMerchant = watchAdminType === ADMIN_TYPE.MERCHANT_ADMIN;
  const isMerchantApp = watchAdminType === ADMIN_TYPE.MERCHANT_APP_ACCOUNT;
  const statusSection = (
    <FieldControl
      name="isActive"
      render={() => (
        <CustomTitleWithSwitch
          title={'Active'}
          defaultValue={watchIsActive === undefined ? true : watchIsActive}
          setValue={(value) => {
            setValue('isActive', value, { shouldDirty: true });
          }}
        />
      )}
    />
  );
  const superAdminStatus = (
    <FieldControl
      name="isSuperuser"
      render={() => (
        <CustomTitleWithSwitch
          title={'Super Admin'}
          defaultValue={watchSuperAdmin}
          setValue={(value) => {
            setValue('isSuperuser', value, { shouldDirty: true });
          }}
        />
      )}
    />
  );
  return (
    <>
      <SectionTopTitle title="Status" />
      {statusSection}
      {isMerchant || isMerchantApp ? null : superAdminStatus}
    </>
  );
}

function PermissionFieldControl({
  name,
  type,
  datas,
  selected,
  setValue,
  button,
}) {
  const isGroup = type === PERMISSION_TYPE.GROUP;
  const title = isGroup ? 'Administrator groups' : 'Administrator permission';
  const namespace = isGroup ? 'adminGroup' : 'admin';
  const tips = isGroup
    ? 'Select the Administration group(s) for this admin user.'
    : 'Select specific permissions for this user.';
  return (
    <FieldControl
      name={name}
      render={() => (
        <>
          <BaseMutipleSelectorV2
            title={title}
            namespace={namespace}
            data={{
              sourceData: datas,
              targetData: selected,
              targetChange: setValue,
            }}
            requires={PermissionCodes.addAdministratorGroup}
            addButton={button}
            tips={tips}
          />
        </>
      )}
    />
  );
}

function PermissionSections() {
  const history = useHistory();

  const { watch, setValue } = useFormContext();
  const watchPermissionGroups = watch('groups') || [];
  const watchPermissions = watch('permissions') || [];
  const watchAdminType = watch('adminType') || ADMIN_TYPE.HKTB_ADMIN;
  const watchMerchantPermissionGroups = watch('merchantGroups');
  const watchMerchantPermissions = watch('merchantPermissions');
  const isMerchant = watchAdminType === ADMIN_TYPE.MERCHANT_ADMIN;
  const groupName = isMerchant ? 'merchantGroups' : 'groups';
  const dispatch = useDispatch();
  const [showAddAdminGroupPrompt, setShowAddAdminGroupPrompt] = useState(false);
  const [addPromptConfig, setAddPromptConfig] = useState(false);
  const {
    permissionGroups,
    allPermissions,
    merchantPermissionList,
    workingTeams,
  } = useSelector((state) => {
    return {
      permissionGroups: state.adminGroup.notPagedAllList || [],
      allPermissions: state.admin.allPermissionList,
      merchantPermissionList: state.admin.merchantPermissionList,
      workingTeams: state.workingTeam.notPagedAllList,
    };
  });

  useEffect(() => {
    dispatch({
      type: 'admin/getAllPermissions',
      payload: { adminType: ADMIN_TYPE.HKTB_ADMIN },
    });
    dispatch({
      type: 'admin/getAllPermissions',
      payload: {
        adminType: ADMIN_TYPE.MERCHANT_ADMIN,
      },
    });
    dispatch({ type: 'adminGroup/getAllList' });
    dispatch({ type: 'workingTeam/getAllList' });
  }, []);

  useEffect(() => {
    if (history.location.state?.from?.state?.newWorkingTeam) {
      console.log(
        '@@549: ',
        history.location.state?.from?.state?.newWorkingTeam,
      );
    }
  }, [history.location.state?.from?.state?.newWorkingTeam]);

  const getPermissionGroups = (adminType) => {
    if (adminType !== ADMIN_TYPE.MERCHANT_ADMIN) {
      return permissionGroups;
    }
    return permissionGroups?.filter(
      (item) => item.adminType === ADMIN_TYPE.MERCHANT_ADMIN,
    );
  };
  const getPermissionGroup = ({
    type,
    datas,
    selected,
    setValue,
    button,
    name,
  }) => {
    return (
      <PermissionFieldControl
        type={type}
        datas={datas}
        selected={selected}
        setValue={setValue}
        button={button}
        name={name}
      />
    );
  };

  const hktbGroup = getPermissionGroup({
    type: PERMISSION_TYPE.GROUP,
    datas: getPermissionGroups(ADMIN_TYPE.HKTB_ADMIN),
    selected: watchPermissionGroups,
    setValue: (value) => {
      setValue('groups', value, { shouldDirty: true });
    },
    button: {
      title: 'Add Administrator group',
      action: () => setShowAddAdminGroupPrompt(true),
      customClass: 'btn-back-button-common add-remove-botton-style',
    },
    name: 'groups',
  });

  const hktbPermission = getPermissionGroup({
    type: PERMISSION_TYPE.PERMISSION,
    datas: allPermissions,
    selected: watchPermissions,
    setValue: (value) => {
      setValue('permissions', value, { shouldDirty: true });
    },
    name: 'permissions',
  });

  const merchantGroup = getPermissionGroup({
    adminType: ADMIN_TYPE.MERCHANT_ADMIN,
    type: PERMISSION_TYPE.GROUP,
    datas: getPermissionGroups(ADMIN_TYPE.MERCHANT_ADMIN),
    selected: watchMerchantPermissionGroups,
    setValue: (value) => {
      setValue('merchantGroups', value, { shouldDirty: true });
    },
    button: {
      title: 'Add Administrator group',
      action: () => setShowAddAdminGroupPrompt(true),
      customClass: 'btn-back-button-common add-remove-botton-style',
    },
    name: 'merchantGroups',
  });

  const merchantPermissions = getPermissionGroup({
    adminType: ADMIN_TYPE.MERCHANT_ADMIN,
    type: PERMISSION_TYPE.PERMISSION,
    datas: merchantPermissionList,
    selected: watchMerchantPermissions,
    setValue: (value) => {
      setValue('merchantPermissions', value, { shouldDirty: true });
    },
    name: 'merchantPermissions',
  });

  return (
    <>
      <SectionTopTitle title="Permission(optional)" />
      <FieldControl
        name={'workingTeams'}
        render={({ field: { value } }) => (
          <>
            <BaseMutipleSelectorV2
              title={'Working Team'}
              namespace={'workingTeams'}
              data={{
                sourceData: workingTeams,
                targetData: value,
                targetChange: (value) => {
                  setValue('workingTeams', value, { shouldDirty: true });
                },
              }}
              requires={PermissionCodes.changeWorkingTeam}
              addButton={{
                title: 'Add working team',
                action: () => {
                  setAddPromptConfig({
                    button: {
                      text: 'create working team',
                      actionPath: '/working_teams/create',
                    },
                    title: 'Go to create working team?',
                    description: `Go to create working team.`,
                  });
                },
                customClass: 'btn-back-button-common add-remove-botton-style',
              }}
              tips={'Select the Working Team(s) for this admin user.'}
            />
          </>
        )}
      />
      {isMerchant ? (
        <>
          {merchantGroup}
          {merchantPermissions}
        </>
      ) : null}
      {!isMerchant ? (
        <>
          {hktbGroup}
          {hktbPermission}
        </>
      ) : null}

      <AddNewAdminGroupPrompt
        adminType={watchAdminType}
        show={showAddAdminGroupPrompt}
        onClose={() => setShowAddAdminGroupPrompt(false)}
        onConfirm={(item) => {
          dispatch(
            createAction('adminGroup/getPagedAdminGroups')({
              adminType: watchAdminType,
            }),
          );
          setValue(
            groupName,
            [
              ...(isMerchant
                ? watchMerchantPermissionGroups
                : watchPermissionGroups),
              item,
            ],
            {
              shouldDirty: true,
            },
          );
          setShowAddAdminGroupPrompt(false);
        }}
      />

      <BasePrompt
        show={!!addPromptConfig}
        closeAction={() => {
          setAddPromptConfig(null);
        }}
        rightButton={
          addPromptConfig
            ? {
                text: addPromptConfig.button.text,
                action: () => {
                  setAddPromptConfig(null);
                  history.push({
                    pathname: addPromptConfig.button.actionPath,
                    state: {
                      from: history.location,
                      title: 'Continue to Create administrators',
                      content: 'You can continue to create the administrators.',
                    },
                  });
                },
              }
            : {}
        }
        title={addPromptConfig?.title}
        description={addPromptConfig?.description}
      />
    </>
  );
}

const adminPermission = [
  PermissionCodes.changeAdministrator,
  PermissionCodes.changeAdministratorGroup,
];
function MainContent() {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;
  const { watch, getValues, trigger, formState } = useFormContext();
  const { errors } = formState;
  const watchSuperAdmin = watch('isSuperuser');
  const watchAdminType = watch('adminType') || ADMIN_TYPE.HKTB_ADMIN;
  const watchPermissionGroups = watch('groups');
  const watchPermissions = watch('permissions');
  const watchMerchantPermissionGroups = watch('merchantGroups');
  const watchMerchantPermissions = watch('merchantPermissions');
  const [saveAlertConfig, setSaveAlertConfig] = useState(null);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const isMerchant = watchAdminType === ADMIN_TYPE.MERCHANT_ADMIN;
  const isMerchantApp = watchAdminType === ADMIN_TYPE.MERCHANT_APP_ACCOUNT;
  const permissions = isMerchant ? watchMerchantPermissions : watchPermissions;
  const groups = isMerchant
    ? watchMerchantPermissionGroups
    : watchPermissionGroups;
  const { saved, admin } = useSelector((state) => ({
    saved: state.admin.saved,
    admin: state.admin.oneAdmin,
  }));
  const patchData = () => {
    const data = getValues();
    if (!id) {
      delete data.pk;
    }
    dispatch({
      type: 'admin/updateOrCreate',
      payload: { data },
    });
  };

  const sections = [<PersonalInfoSections />, <StatusSections />];

  if (
    (!watchSuperAdmin || watchAdminType === ADMIN_TYPE.MERCHANT_ADMIN) &&
    !isMerchantApp
  ) {
    sections.push(<PermissionSections />);
  }
  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <SaveAndBackButtons
        saveTempText={id ? 'Delete' : null}
        saveText={id ? 'Update' : 'Save'}
        backAction={() => {
          history.push('/administrators');
        }}
        saveAction={async () => {
          if (saved === SavedStatus.saving) {
            return;
          }
          const [adminType, adminLoginMethod] = getValues([
            'adminType',
            'adminLoginMethod',
          ]);
          const triggerFields = isMerchantApp
            ? ['name', 'password', 'confirmPassword']
            : ['name', 'email', 'mobile', 'password', 'confirmPassword'];
          if (adminType === ADMIN_TYPE.MERCHANT_ADMIN) {
            triggerFields.push('brand');
            if (adminLoginMethod === AdminLoginMethod.Saml) {
              triggerFields.splice(triggerFields.indexOf('password'), 1);
              triggerFields.splice(triggerFields.indexOf('confirmPassword'), 1);
              triggerFields.splice(triggerFields.indexOf('mobile'), 1);
            }
          } else if (adminType === ADMIN_TYPE.MERCHANT_APP_ACCOUNT) {
            triggerFields.push('brand', 'store');
          }
          // const [password, confirmPassword] = getValues(['password', 'confirmPassword']);
          const isValid = await trigger(triggerFields);
          console.log('@@915', isValid);
          if (!isValid) {
            return;
          }

          if (watchSuperAdmin) {
            setSaveAlertConfig({
              title: 'Confirm to give this admin permission',
              content:
                'As this a superadmin, then the admin will gain full system permission by doing so. ',
            });
            return;
          }
          console.log('groups:', groups);
          const selectedAdminPermissionGroups = groups?.filter(
            (group) =>
              (group.permissions?.edges || group.permissions || []).filter(
                (val) =>
                  adminPermission.includes(
                    val?.node?.codename || val?.codename,
                  ),
              ).length > 0,
          );

          if (selectedAdminPermissionGroups?.length > 0) {
            setSaveAlertConfig({
              title: 'Confirm to give this admin permission',
              content: `As this admin belong to ${selectedAdminPermissionGroups
                .map((group) => group.name)
                .join(
                  ',',
                )}, then the admin will gain full system permission by doing so. `,
            });
            return;
          }

          const selectedAdminPermissions = permissions?.filter((permission) =>
            adminPermission.includes(permission.codename),
          );
          if (selectedAdminPermissions?.length > 0) {
            setSaveAlertConfig({
              title: 'Confirm to give this admin permission',
              content: `As "change admin permissions" is set, then the admin will gain full system permission by doing so. `,
            });
            return;
          }
          patchData();
        }}
        saveTempAction={() => setShowDeletePrompt(true)}
        tempRequires={PermissionCodes.deleteAdministrator}
      />
      <DeletePrompt
        message={{
          title: 'Delete this Admin',
          content: `Are you sure you want to delete the administrator "${admin.name}"? All of the following related items will be deleted:`,
        }}
        title={'Admin'}
        data={admin}
        relatedSections={DELETE_RELATED_SECTIONS.ADMIN}
        show={showDeletePrompt}
        onClose={() => {
          setShowDeletePrompt(false);
        }}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch({
            type: `admin/updateState`,
            payload: { checkedList: [admin], formChanged: false },
          });
          dispatch(
            createAction('admin/delete')({
              afterAction: () => {
                history.push('/administrators');
              },
            }),
          );
        }}
      />
      <BasePrompt
        show={!!saveAlertConfig}
        closeAction={() => setSaveAlertConfig(null)}
        rightButton={{
          text: 'Confirm',
          action: () => {
            setSaveAlertConfig(null);
            patchData();
          },
        }}
        title={saveAlertConfig?.title}
        description={saveAlertConfig?.content}
      />
    </>
  );
}

function CreateAdmin() {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;
  const { admin, formHasSubmitted, saved, hasUpdatedDefaultValues } =
    useSelector((state) => ({
      admin: state.admin.oneAdmin,
      formHasSubmitted: state.admin.formHasSubmitted,
      hasUpdatedDefaultValues: state.admin.hasUpdatedDefaultValues,
      saved: state.admin.saved,
    }));

  useEffect(() => {
    if (
      saved === SavedStatus.savedWithSuccess &&
      history.location.pathname.includes('edit')
    ) {
      history.push('/administrators');
    }
  }, [saved, history]);

  useEffect(() => {
    if (id) {
      dispatch(createAction('admin/getOneAdmin')({ id }));
    } else {
      dispatch({ type: 'admin/loadDataFromCookieSession' });
    }
  }, [dispatch, id]);

  if (id && !admin?.id) {
    return <Loading customClass={'common-full-height'} />;
  }
  const stepSet = [<MainContent />];
  return (
    <BaseForm
      defaultValues={admin}
      formHasSubmitted={formHasSubmitted}
      hasUpdatedDefaultValues={hasUpdatedDefaultValues}
      tempSave={(save, getValues) => {
        if (save) {
          saveDataToSessionStorage(getValues());
        } else {
          removeDataFromSessionStorage();
        }
      }}
      showFinishPop={saved === SavedStatus.savedWithSuccess}
      nextStepConfig={{
        title: 'Successfully Created!',
        description: 'Administrators is successfully created.',
        steps: null,
        buttons: [
          {
            text: 'Back to administrators list',
            action: () =>
              history.push({
                pathname: '/administrators',
              }),
          },
        ],
      }}
      content={stepSet}
      breadcrumb={
        <CustomBreadcrumb
          name={id ? admin.username : 'Create Administrators'}
        />
      }
      currentStep={0}
      caution={{
        detail: '',
        title: `${id ? admin.username : 'Create Administrators'}`,
        model: 'admin',
      }}
    />
  );
}

export default CreateAdmin;
