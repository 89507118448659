import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Image, Button } from 'react-bootstrap';
import { hasError, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import { CustomTitleWithDropDown } from '../customer/CustomTitleWithDropDown';
import CloseIcon from '../../assets/images/close.svg';
import { createAction } from '../../utils';
import './CreateRateLimitGeneral.scss';

function LimitItem({
  index,
  totalCount,
  item,
  adminGroupsSource,
  setLimitItemValue,
  onDelete,
}) {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <div key={`${index}`}>
      <div
        className="rate-limit-item"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <CustomTitleWithDropDown
          outsideCustomClass={'rate-limit-admin-group-select-outside'}
          customClass={'rate-limit-admin-group-select'}
          source={adminGroupsSource}
          defaultValue={{
            value: item.administratorGroup,
            label: item.administratorGroup?.name,
          }}
          value={{
            value: item.administratorGroup,
            label: item.administratorGroup?.name,
          }}
          setValue={(value) => {
            setLimitItemValue({
              administratorGroup: value
                ? {
                    pk: value.value.pk,
                    name: value.value.name,
                  }
                : null,
            });
          }}
          selectPlaceholder="Please select target admin group"
          placeholder="Search by name"
        />

        <input
          type="number"
          min="0"
          onChange={({ target }) =>
            setLimitItemValue({ threshold: target.value })
          }
          className="custom-number-input-short margin-left-30-percent rate-limit-threshold"
          value={item.threshold || item.threshold === 0 ? item.threshold : ''}
        />

        <label className="help-message margin-left-10-percent margin-right-10-percent">
          times per
        </label>
        <input
          type="number"
          min="0"
          onChange={({ target }) =>
            setLimitItemValue({ duration: target.value })
          }
          className="custom-number-input-short rate-limit-duration"
          value={item.duration || item.duration === 0 ? item.duration : ''}
        />

        <label className="help-message margin-left-10-percent">mins</label>
        {totalCount > 1 ? (
          <Image
            src={CloseIcon}
            className="delete-rate-limit-button margin-left-30-percent"
            onClick={onDelete}
          />
        ) : null}
      </div>
      {hasError(errors, `settings.${index}.administratorGroup`) ||
      hasError(errors, `settings.${index}.threshold`) ||
      hasError(errors, `settings.${index}.duration`) ? (
        <div
          key={index}
          className="rate-limit-item-errors"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <ReactHookFormErrorMessage
            errors={errors}
            id={`settings.${index}.administratorGroup`}
            extra="error-message-administratorGroup"
          />
          <ReactHookFormErrorMessage
            errors={errors}
            id={`settings.${index}.threshold`}
            extra="error-message-threshold"
          />
          <ReactHookFormErrorMessage
            errors={errors}
            id={`settings.${index}.duration`}
            extra="error-message-duration"
          />
        </div>
      ) : null}
    </div>
  );
}

function CreateRateLimitGeneral(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const { rateLimitType } = params;

  const {
    rateLimitDetail,
    adminGroups,
    // errorFields,
  } = useSelector((state) => ({
    rateLimitDetail: state.rateLimit.detail,
    adminGroups: state.adminGroup.notPagedAllList || [],
    // errorFields: state.createPointRecord.errorFields,
  }));

  const {
    control,
    watch,
    setValue,
    getValues,
    clearErrors,
    setError,
    formState: { errors },
  } = useFormContext();
  const watchSettings = watch('settings');

  const [limits, setLimits] = useState(rateLimitDetail?.settings || []);

  useEffect(() => {
    dispatch({ type: 'adminGroup/getAllList' });
  }, []);

  useEffect(() => {
    setValue(`settings`, limits, {
      shouldDirty: true,
    });
  }, [limits]);

  const limitedGroups = useCallback(() => {
    return limits.map((val) => val.administratorGroup?.pk);
  }, [limits, adminGroups]);

  return (
    <>
      <div className="first-section-title">GENERAL</div>

      <CustomTitleLabel title="Function" />
      <br />
      <label className="viewer-content">{rateLimitType}</label>

      <CustomTitleLabel title="Limit" />
      {limits.map((item, index) => {
        const setLimitItemValue = (newItem) => {
          limits[index] = {
            ...item,
            ...newItem,
          };
          setLimits(limits);
          const newData = limits.map((oldLimit, oldIndex) => {
            if (index === oldIndex) {
              return {
                ...item,
                ...newItem,
              };
            }
            return oldLimit;
          });
          setLimits(newData);
        };

        const onDelete = () => {
          const newData = limits.filter(
            (oldLimit, oldIndex) => index != oldIndex,
          );
          setLimits(newData);
        };

        return (
          <LimitItem
            index={index}
            totalCount={limits?.length}
            item={item}
            adminGroupsSource={(
              adminGroups?.filter(
                (item) => !limitedGroups(item).includes(item?.pk),
              ) || []
            )
              .concat(item.administratorGroup ? [item.administratorGroup] : [])
              .sort((firstItem, secondItem) => firstItem.pk - secondItem.pk)}
            setLimitItemValue={setLimitItemValue}
            onDelete={onDelete}
          />
        );
      })}

      <Button
        className="btn-back-button-common add-rate-limit-button"
        onClick={() => {
          setLimits([
            ...limits,
            {
              administratorGroup: null,
              threshold: null,
              duration: null,
            },
          ]);
        }}
      >
        Add limit
      </Button>
    </>
  );
}

export default CreateRateLimitGeneral;
