import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { paymentSettlementReportBreads } from '../../../config/CustomBreadConfig';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { NavBarNames } from '../../../config/NavBarNameList';
import {
  PaymentAndSettlementReviewStatusType,
  ADMIN_TYPE
} from '../../../config/CustomEnums';
import { ImportResource } from '../../../models/DownloadImportModel';
import AuthButton from '../../../components/base/AuthButton';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import { createAction, getHashKeyString } from '../../../utils';
import queryString from 'query-string';
import Filter from './Filter';
import './PaymentSettlementReportList.scss';
import AlertInfoPrompt from '../../../components/base/prompt/AlertInfoPrompt';

export default function PaymentSettlementReportList() {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const params = useParams();
    const { reportName } = params;
    const { merchantAdministrators } = params;
    const [showAlterInfoPrompt, setShowAlterInfoPrompt] = useState(false);
    const [alertDict, setAlertDict] = useState({
      id: null,
      title: "", 
      subTitle: "", 
      content:<></>, 
      rightButton: "", 
      status:""
    });
    const [amendmentNumber, setAmendmentNumber] = useState("");
    const [Remark, setRemark] = useState("");

    const {
      totalCount,
      pageInfo,
      totalPage,
      displayFields,
      pagedList,
      adminType,
      stateReportName,
      stateMerchantName,
      displayFieldsWithCouponData,
      brand,
    } = useSelector((state) => ({
      totalCount: state.paymentSettlementList.totalCount,
      pageInfo: state.paymentSettlementList.pageInfo,
      totalPage: state.paymentSettlementList.totalPage,
      displayFields: state.paymentSettlementList.listDisplayFields,
      displayFieldsWithCouponData: state.paymentSettlementList.listDisplayFieldsWithCouponData,
      pagedList: state.paymentSettlementList.pagedList,
      adminType: state.users.adminType,
      stateReportName: state.paymentSettlementList.pagedList[0]?.reportName,
      stateMerchantName: state.paymentSettlementList.pagedList[0]?.brandName,
      brand: state.brand.currentPageBrandList?.[0]?.pk
    }));

    const isHKTB = adminType === ADMIN_TYPE.HKTB_ADMIN;
    const isMerchant = adminType === ADMIN_TYPE.MERCHANT_ADMIN;

    const tabs = [
      {
        name: 'ReportList',
        content: (
          <BaseTabListContainer
            hideTab={true}
            pageInfo={pageInfo}
            totalCount={totalCount || 0}
            filter={{ hasFilter: true, componment: Filter }}
            listContent={
              <BaseListContainer
                fields= {getHashKeyString(location.hash) == "withCouponData" ? 
                  displayFieldsWithCouponData :
                  displayFields }
                model={'paymentSettlement'}
                permissionGroup={PermissionCodes.viewPaymentSettlement}
                dataList={pagedList}
                totalPage={totalPage}
                deleteInfo={{
                    data: [],
                    title: 'PaymentSettlement',
                    relatedName: '',
                    onComfirm: {},
                    relatedSections: [],
                  }}
                hideActions={ isHKTB ? true :false }
                actions={[]}
                hasOtherActions={ isMerchant ? true : false}
                customActions={{
                  paymentSettlementAgree: (item) => {
                    setShowAlterInfoPrompt(true);
                    setAlertDict({
                      id: item.pk,
                      title: "Confirm to agree?", 
                      subTitle: "Please check the report carefully. Once you click \"agree\", you will not change the status.",
                      content: <></>,
                      rightButton: "Confirm to agree",
                      status: PaymentAndSettlementReviewStatusType.agree,
                    });
                  },
                  paymentSettlementDisagree: (item) => {
                    setShowAlterInfoPrompt(true);
                    setAlertDict({
                      id: item.pk,
                      title: "Confirm to disagree?", 
                      subTitle: "You can fill in some information to help HKTBD administrator understand your objection.",
                      content: getDisagreeRemark(),
                      rightButton: "Submit",
                      status: PaymentAndSettlementReviewStatusType.disagree,
                    });
                  },
                }}
              />
            }
          />
        ),
      },
    ];

    const getList = () => {
      const searchDict = queryString.parse(location.search);
      const searchKey = searchDict['search'];
      const stringRank = searchDict['rank'] || 'true';
      const stringPage = searchDict['page'] || 1;
      const reviewStatus = searchDict['review_status'];
      const rank = stringRank === 'true';
      const page = parseInt(stringPage);
      if (reportName){
        const month = reportName;
        dispatch(
          createAction('paymentSettlementList/getListByName')({
            ...searchDict,
            rank: rank,
            filterName: searchKey,
            page: page,
            reviewStatus: reviewStatus,
            month: month,
          }),
        );
      }
      else{
        const merchant = isHKTB ? merchantAdministrators : brand ;
        dispatch(
          createAction('paymentSettlementList/getListByMerchant')({
            ...searchDict,
            rank: rank,
            filterName: searchKey,
            page: page,
            reviewStatus: reviewStatus,
            merchant: merchant,
          }),
        );
      };
    }

    const updatePaymentSettlementReportStatus = (id) =>{
      setShowAlterInfoPrompt(false);
      dispatch(createAction('paymentSettlementList/updatePaymentSettlementReportStatus')({
        id: id , 
        reviewStatus: alertDict['status'],
        remark: Remark,
        amendmentNumber: amendmentNumber,
        afterAction: () => {history.push(location)}
      }));
    }

    const getDisagreeRemark=() =>{
      return <>
        <label className="payment-settlement-disagree-content">
          Remark(optional)
        </label>
        <>
        <textarea
          className="payment-settlement-disagree-remark" 
          onChange={({ target }) => {
            const { value } = target;
            setRemark(value);
          }}
          rows="4"
          defaultValue={
            Remark
          }/>
        </>
      </>
    }
  
    useEffect(() => {
      dispatch({type: 'brand/getCurrentPageBrands', payload: {}})
      return () => {
        dispatch({type: 'brand/clearData'});
        dispatch({ type: 'paymentSettlementList/clearData' });
      };
    }, []);

    useEffect(() => {
      if (isHKTB || (isMerchant && brand)) {
        getList();
      }
    }, [dispatch, location, brand]);
    
    const exportPDFAction = () => {
      dispatch(
        createAction('downloadAndImport/createDownloadTask')({
          from: ImportResource.paymentAndSettlementReport,
          related: reportName ? 
          {reportMonth: reportName } : 
          {reportMerchant: parseInt(isHKTB ? merchantAdministrators : brand)}
        }),
      );
    };

    const buttons = [
        <AuthButton
          title="Export PDF"
          action={exportPDFAction}
          requires={PermissionCodes.addExportjob}
        />,
      ];

    return (
      <>
        <CustomListComponent
          caution={{
            detail: 'report list',
            title: (adminType == ADMIN_TYPE.MERCHANT_ADMIN 
            ? NavBarNames.paymentSettlement 
            : (reportName ? stateReportName : stateMerchantName))
          }}
          defaultActiveKey={tabs[0].name}
          breadcrumb={<CustomBreadcrumb breadcrumbs={
            paymentSettlementReportBreads( 
              reportName ? stateReportName : stateMerchantName
              )
          }/>}
          buttons={buttons}
          tabs={tabs}
          hideTab={true}
        />
        <AlertInfoPrompt
        show = {showAlterInfoPrompt}
        onClose={() => setShowAlterInfoPrompt(false)}
        onCancel =  {() => setShowAlterInfoPrompt(false)}
        onConfirm = {() => updatePaymentSettlementReportStatus(alertDict['id'])}
        title = {alertDict['title']}
        subTitle = {alertDict['subTitle']}
        content = {alertDict['content']}
        rightButton = {alertDict['rightButton']}
        contentClass="payment-settlement-alert-info-prompt"
      />
    </>
    );
  }