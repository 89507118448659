import { BaseHelper } from './BaseGraphQLAPIHelper';

export const getSystemSettings = () => {
  const query = `{
        systemSettings {
            pk
            recaptchaOpen
            recaptchaScoreOfSendOtp
            recaptchaScoreOfAcquire
            recaptchaScoreOfMerchantApp
        }
    }
    `;

  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateSystemSettings = (payload) => {
  const query = `mutation UpdateSystemSettings($input: UpdateSystemSettingsInput!) {
        updateSystemSettings(input: $input) {
          success
          errors {
            field
            messages
          }
        }
      }
    `;
  const variables = { input: payload };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
