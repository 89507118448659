import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { createAction, useCompare } from '../../utils';

import { CustomTitleWithDropDown } from '../customer/CustomTitleWithDropDown';
import ExportRankingAndPerformanceReport from '../base/prompt/ExportRankingAndPerformanceReport';
import { CampaignType } from '../../config/CustomEnums';
import { GOODIE_BAG_REWARD_TYPE_OPTIONS } from '../../models/GoodieBagsModel';

const StarOptions = [
  { label: 'Starred campaign', value: 'true' },
  { label: 'Non-Starred campaign', value: 'false' },
];

const FilterSection = ({
  goodieBagsList,
  cashCampaignList,
  giftCampaignList,
  goodiebagOverviewDate,
  propGoodiebagOverviewDate,
  fortunebagCampaignList,
}) => {
  const dispatch = useDispatch();
  const [filterByRewardType, setFilterByRewardType] = useState();
  const [filterByGoodiebags, setFilterByGoodiebags] = useState();
  const [filterByCashVouchers, setFilterByCashVouchers] = useState();
  const [filterByGoodieBagCoupons, setFilterByGoodieBagCoupons] = useState();
  const [filterByFortuneBags, setFilterByFortuneBags] = useState();
  const [filterIsStarred, setFilterIsStarred] = useState();
  const [showExportRakingReportPrompt, setShowExportRakingReportPrompt] =
    useState(false);
  const [
    showExportPerformanceReportPrompt,
    setShowExportPerformanceReportPrompt,
  ] = useState(false);

  const rewardTypeChanged = useCompare({
    filterByRewardType,
  });

  const getOverviewStatus = (
    goodiebag,
    cashVoucherCampaign,
    giftCampaign,
    isStarred,
  ) => {
    // console.log('@21', channel, market, brand, campaign);
    // if (campaign?.value?.pk) {
    //   return `Showing result of campaign ID: ${campaign?.value?.pk} from ${brand?.value?.name}.`;
    // }
    // if (channel?.value?.pk) {
    //   return `Showing result of all campaign(s) from
    //       ${channel?.value?.name},
    //       ${
    //         market?.value ? market?.value?.name : 'all related target markets'
    //       },
    //       and ${brand?.value ? brand?.value?.name : 'all brands'}.`;
    // }
    // if (market?.value?.pk) {
    //   return `Showing result of all campaign(s) from
    //       ${market?.value?.name}, all related channels,
    //       and ${brand?.value ? brand?.value?.name : 'all brands'}.`;
    // }
    // if (brand?.value?.pk) {
    //   return `Showing result of all campaign(s) from
    //       ${brand?.value?.name}, all channels, and all target markets.`;
    // }
    return 'Showing result of all goodie bag(s).';
  };

  useEffect(() => {
    setFilterByCashVouchers({ label: null, value: null });
    setFilterByGoodieBagCoupons({ label: null, value: null });
    setFilterByFortuneBags({ label: null, value: null });

    const goodiebagCampaginTypes = [
      { type: CampaignType.cashVoucherCampaign, listName: 'cashCampaignList' },
      { type: CampaignType.giftCampaign, listName: 'giftCampaignList' },
      {
        type: CampaignType.fortuneBagForGoodieBagCampaign,
        listName: 'fortunebagCampaignList',
      },
    ];

    goodiebagCampaginTypes.forEach((type, index) => {
      dispatch(
        createAction('campaignList/getCampaignList')({
          ...(filterByGoodiebags?.length > 0
            ? {
                for_goodie_bag: filterByGoodiebags
                  .map((item) => item.value?.pk)
                  .join(','),
              }
            : {}),
          ...(filterIsStarred?.value?.value
            ? { starred_campaign: filterIsStarred?.value?.value }
            : {}),
          type: `${type.type}`,
          isSelectorLoad: true,

          listName: `${type.listName}`,
          getAll: 'false',
        }),
      );
    });
  }, [filterByGoodiebags, filterIsStarred]);

  useEffect(() => {
    dispatch(
      createAction('overview/updateState')({
        goodiebagOverviewFilter: {
          goodiebagRewardType: filterByRewardType,
          goodiebag: filterByGoodiebags,
          cashVoucher: filterByCashVouchers?.value,
          goodiebagCoupons: filterByGoodieBagCoupons?.value,
          fortunebags: filterByFortuneBags?.value,
          isStarred: filterIsStarred?.value?.value,
        },
        goodiebagOverviewStatus: getOverviewStatus(
          // filterByRewardType,
          filterByGoodiebags,
          filterByCashVouchers,
          filterByGoodieBagCoupons,
          filterIsStarred,
        ),
      }),
    );
  }, [
    filterByRewardType,
    filterByGoodiebags,
    filterIsStarred,
    filterByCashVouchers,
    filterByGoodieBagCoupons,
    filterByFortuneBags,
  ]);

  return (
    <>
      <div className="campaign-overview-filter">
        <ExportRankingAndPerformanceReport
          showExportRakingReportPrompt={showExportRakingReportPrompt}
          setShowExportRakingReportPrompt={setShowExportRakingReportPrompt}
          showExportPerformanceReportPrompt={showExportPerformanceReportPrompt}
          setShowExportPerformanceReportPrompt={
            setShowExportPerformanceReportPrompt
          }
          overviewDate={
            goodiebagOverviewDate?.startDate
              ? goodiebagOverviewDate
              : propGoodiebagOverviewDate
          }
          preSelectedCampaign={[filterByCashVouchers, filterByGoodieBagCoupons]
            .filter((item) => item?.value)
            .map((item) => item.value)}
          fromModel="GOODIE_BAG"
          preSelectedGoodieBags={filterByGoodiebags
            ?.filter((item) => item?.value)
            .map((item) => item.value)}
          preSelectedIsStarred={filterIsStarred?.value?.value}
        />
        <div className="campaign-overview-filter-head">
          <label className="campaign-overview-filter-title">FILTER</label>
          {filterByGoodiebags?.length > 0 ||
          filterIsStarred?.value ||
          filterByCashVouchers?.value ||
          filterByGoodieBagCoupons?.value ? (
            <label
              className="campaign-overview-filter-reset-all"
              onClick={() => {
                setFilterByRewardType({ label: null, value: null });
                setFilterByGoodiebags();
                setFilterIsStarred({ label: null, value: null });
                setFilterByCashVouchers({ label: null, value: null });
                setFilterByGoodieBagCoupons({ label: null, value: null });
              }}
            >
              Reset All
            </label>
          ) : (
            <></>
          )}
        </div>
        <div className="campaign-overview-channel-select">
          <CustomTitleWithDropDown
            customClass={''}
            title={'Filter by Goodie bag reward type'}
            source={Object.values(GOODIE_BAG_REWARD_TYPE_OPTIONS).flat(1)}
            defaultValue={
              filterByRewardType?.value
                ? filterByRewardType
                : { label: 'All goodie bag', value: null }
            }
            setValue={(v) => {
              setFilterByRewardType(v);
              setFilterByGoodiebags();
            }}
            needFilter={false}
          />
        </div>
        <div className="campaign-overview-channel-select">
          <CustomTitleWithDropDown
            customClass={''}
            title={'Filter by Goodie bags'}
            source={goodieBagsList}
            placeholder={'Search by goodie bag ID, name'}
            labelContainPk={true}
            defaultValue={filterByGoodiebags ? filterByGoodiebags : []}
            setValue={(v) => {
              setFilterByGoodiebags(v);
            }}
            loadMoreAction={'goodieBags/getList'}
            filterAction={'goodieBags/getList'}
            defaultFilter={{
              isSelectorLoad: true,
              isSimplify: true,
              ...(filterByRewardType?.value
                ? { type: filterByRewardType?.value.value }
                : {}),
            }}
            multiple={{
              default: 'All Goodie bags',
            }}
            isReset={rewardTypeChanged}
          />
        </div>
        <div className="campaign-overview-channel-select">
          <CustomTitleWithDropDown
            customClass={''}
            title={'Filter by starred campaign'}
            source={StarOptions}
            defaultValue={
              filterIsStarred?.value
                ? filterIsStarred
                : { label: 'All Campaigns', value: null }
            }
            setValue={setFilterIsStarred}
            needFilter={false}
          />
        </div>

        {filterByGoodiebags?.length > 0 && cashCampaignList?.length > 0 ? (
          <div className="campaign-overview-brand-select">
            <CustomTitleWithDropDown
              customClass={''}
              title={'Filter by Cash vouchers'}
              source={cashCampaignList}
              placeholder={'Search by Cash vouchers ID, name'}
              labelContainPk={true}
              defaultValue={
                filterByCashVouchers?.value
                  ? filterByCashVouchers
                  : {
                      label: `All Cash vouchers ${
                        filterIsStarred?.value?.value
                          ? `(${filterIsStarred?.label.replace(
                              ' campaign',
                              '',
                            )})`
                          : ''
                      }`,
                      value: null,
                    }
              }
              setValue={setFilterByCashVouchers}
              loadMoreAction={'campaignList/getCampaignList'}
              filterAction={'campaignList/getCampaignList'}
              defaultFilter={{
                isSelectorLoad: true,
                type: `${CampaignType.cashVoucherCampaign}`,
                others: {
                  ...(filterByGoodiebags?.length > 0
                    ? {
                        for_goodie_bag: filterByGoodiebags
                          .map((item) => item.value?.pk)
                          .join(','),
                      }
                    : null),
                  ...(filterIsStarred?.value?.value
                    ? { starred_campaign: filterIsStarred?.value?.value }
                    : {}),
                },

                listName: 'cashCampaignList',
                getAll: 'false',
              }}
            />
          </div>
        ) : null}

        {filterByGoodiebags?.length > 0 && giftCampaignList?.length > 0 ? (
          <div className="campaign-overview-brand-select">
            <CustomTitleWithDropDown
              customClass={''}
              title={'Filter by Goodie bag coupons'}
              source={giftCampaignList}
              placeholder={'Search by Goodie bag coupons ID, name'}
              labelContainPk={true}
              defaultValue={
                filterByGoodieBagCoupons?.value
                  ? filterByGoodieBagCoupons
                  : {
                      label: `All Goodie bag coupons ${
                        filterIsStarred?.value?.value
                          ? `(${filterIsStarred?.label.replace(
                              ' campaign',
                              '',
                            )})`
                          : ''
                      }`,
                      value: null,
                    }
              }
              setValue={setFilterByGoodieBagCoupons}
              loadMoreAction={'campaignList/getCampaignList'}
              filterAction={'campaignList/getCampaignList'}
              defaultFilter={{
                isSelectorLoad: true,
                type: `${CampaignType.giftCampaign}`,
                others: {
                  ...(filterByGoodiebags?.length > 0
                    ? {
                        for_goodie_bag: filterByGoodiebags
                          .map((item) => item.value?.pk)
                          .join(','),
                      }
                    : null),
                  ...(filterIsStarred?.value?.value
                    ? { starred_campaign: filterIsStarred?.value?.value }
                    : {}),
                },

                listName: 'giftCampaignList',
              }}
            />
          </div>
        ) : null}

        {filterByGoodiebags?.length > 0 &&
        fortunebagCampaignList?.length > 0 ? (
          <div className="campaign-overview-brand-select">
            <CustomTitleWithDropDown
              customClass={''}
              title={'Filter by Goodie bag offer (Direct assign)'}
              source={fortunebagCampaignList}
              placeholder={
                'Search by Goodie bag offer (Direct assign) ID, name'
              }
              labelContainPk={true}
              defaultValue={
                filterByFortuneBags?.value
                  ? filterByFortuneBags
                  : {
                      label: `All Goodie bag offer (Direct assign) ${
                        filterIsStarred?.value?.value
                          ? `(${filterIsStarred?.label.replace(
                              ' campaign',
                              '',
                            )})`
                          : ''
                      }`,
                      value: null,
                    }
              }
              setValue={setFilterByFortuneBags}
              loadMoreAction={'campaignList/getCampaignList'}
              filterAction={'campaignList/getCampaignList'}
              defaultFilter={{
                isSelectorLoad: true,
                type: `${CampaignType.fortuneBagForGoodieBagCampaign}`,
                others: {
                  ...(filterByGoodiebags?.length > 0
                    ? {
                        for_goodie_bag: filterByGoodiebags
                          .map((item) => item.value?.pk)
                          .join(','),
                      }
                    : null),
                  ...(filterIsStarred?.value?.value
                    ? { starred_campaign: filterIsStarred?.value?.value }
                    : {}),
                },

                listName: 'fortunebagCampaignList',
              }}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

const mapPropsToState = (state) => ({
  goodieBagsList: state.goodieBags.allList,
  cashCampaignList: state.campaignList.cashCampaignList,
  giftCampaignList: state.campaignList.giftCampaignList,
  goodiebagOverviewDate: state.overview.goodiebagOverviewDate,
  fortunebagCampaignList: state.campaignList.fortunebagCampaignList,
});

export default connect(mapPropsToState)(FilterSection);
