import { createModel } from './BaseModel';
import {
  createInterestPreference,
  getInterestPreferenceDetail,
  getInterestPreferenceList,
  deleteInterestPreferences,
  updateInterestPreference,
} from '../services/InterestPreferenceApiHelper';
import { APIStatus, LanguageConfig, SESSION_KEYS } from '../config/CustomEnums';
import {
  createAction,
  getObjectFromSessionStorage,
  removeFromSessionStorage,
  saveToSessionStorage,
} from '../utils';
import { apiWithResponseHandle } from './LoadingUtil';

const INTEREST_PREFERENCE_SESSION_KEY = SESSION_KEYS.INTEREST_PREFERENCE_SESSION_KEY;

export const saveInterestPreferenceToSessionStorage = (data) => {
  saveToSessionStorage(INTEREST_PREFERENCE_SESSION_KEY, data);
};

export const removeInterestPreferenceSessionStroage = () => {
  removeFromSessionStorage(INTEREST_PREFERENCE_SESSION_KEY);
};

const parseOneInterestPreference = (apiInterestPreference) => {
  const translations = {
    [LanguageConfig.english]: {
      name: apiInterestPreference.name,
      relatedCustomerNumber: apiInterestPreference.relatedCustomerNumber,
    },
  };

  const temp = apiInterestPreference.translations?.edges.forEach((translation) => {
    const { language, name, relatedCustomerNumber, pk } = translation.node;
    translations[language] = { name, relatedCustomerNumber, pk };
  });
  return {
    pk: apiInterestPreference.pk,
    name: apiInterestPreference.name,
    relatedCustomerNumber: apiInterestPreference.relatedCustomerNumber,
    translations,
    hasUpdatedDefaultValues: true,
  };
};

const clearnInterestPreferenceTranslation = (formTranslationData, oldTranslationData) => {
  const cleanedTranslations = [];
  const temp = Object.keys(formTranslationData).forEach((language) => {
    if (language === LanguageConfig.english) {
      return;
    }
    const translation = formTranslationData[language];
    const cleanedOneTranslation = {
      language,
      name: translation.name,
    };

    if (oldTranslationData) {
      cleanedOneTranslation.id = oldTranslationData[language]?.pk;
    }
    cleanedTranslations.push(cleanedOneTranslation);
  });
  return cleanedTranslations;
}

const cleanInterestPreference = (formData, oldData) => {
  const enData = formData.translations[LanguageConfig.english];
  const payload = {
    name: enData.name,
    translations: clearnInterestPreferenceTranslation(
      formData.translations,
      oldData?.translations,
    ),
  };
  if (oldData) {
    payload.id = oldData.pk;
  }
  return payload;
}

export default createModel({
  namespace: 'interestPreference',
  params: {
    dataKey: 'interestPreferences',
    listAPI: getInterestPreferenceList,
    pkNode: 'InterestPreferenceNode',
    detailAPI: getInterestPreferenceDetail,
    deleteAPI: deleteInterestPreferences,
    parse: (data) => data.interestPreferences.edges.map((edge) => ({ ...edge.node })),
    parseDetail: (data) => parseOneInterestPreference(data.interestPreference),
  },
  states: {
    hasUpdatedDefaultValues: false,
    formHasSubmitted: false,
  },
  reducers: {
    loadInterestPreferenceFromCookie(state, { payload }) {
      const logoLabel = getObjectFromSessionStorage(INTEREST_PREFERENCE_SESSION_KEY);
      if (!logoLabel) {
        return {
          ...state,
          detail: {
            hasUpdatedDefaultValues: false,
          },
        };
      }
      return {
        ...state,
        detail: {
          ...logoLabel,
          hasUpdatedDefaultValues: true,
        },
      };
    },
  },
  effects: {
    createInterestPreference: [
      function* ({ payload }, { all, select, put }) {
        const { data } = payload;
        const serviceArgs = [createInterestPreference, cleanInterestPreference(data)];
        yield put(
          createAction('updateState')({
            apiStatus: APIStatus.calling,
          }),
        );
        function* onSuccess(data) {
          removeInterestPreferenceSessionStroage();
          yield put(
            createAction('updateState')({
              apiStatus: APIStatus.success,
              formHasSubmitted: true,
            }),
          );
        }
        function* onError(err) {
          yield put(
            createAction('updateState')({
              apiStatus: APIStatus.failed,
            }),
          );
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],
    duplicate: [
      function* ({ payload }, { all, select, put }) {
        const { data, afterAction } = payload;
        const duplicateData = parseOneInterestPreference(data);
        const name = duplicateData.translations?.[LanguageConfig.english]?.name;
        if (name) {
          duplicateData.translations[
            LanguageConfig.english
          ].name = `Copy of ${name}`;
        }
        const serviceArgs = [createInterestPreference, cleanInterestPreference(duplicateData)];
        yield put(
          createAction('updateState')({
            apiStatus: APIStatus.calling,
          }),
        );
        function* onSuccess(data) {
          yield put(
            createAction('updateState')({
              apiStatus: APIStatus.success,
            }),
          );
          if (afterAction) {
            yield afterAction();
          }
        }
        function* onError(err) {
          yield put(
            createAction('updateState')({
              apiStatus: APIStatus.failed,
            }),
          );
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],
    updateInterestPreference: [
      function* ({ payload }, { all, select, put }) {
        const { data } = payload;
        const detail = yield select((state) => state.interestPreference.detail);

        const serviceArgs = [updateInterestPreference, cleanInterestPreference(data, detail)];
        yield put(
          createAction('updateState')({
            apiStatus: APIStatus.calling,
          }),
        );
        function* onSuccess(data) {
          removeInterestPreferenceSessionStroage();
          yield put(
            createAction('updateState')({
              apiStatus: APIStatus.success,
              formHasSubmitted: true,
            }),
          );
        }
        function* onError(err) {
          yield put(
            createAction('updateState')({
              apiStatus: APIStatus.failed,
            }),
          );
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],
  },
});
