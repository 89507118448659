import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch, connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { SaveAndBackWithOutTempButtons } from '../../components/base/BottomStepComponent';
import ContentSections from '../../components/base/ContentSections';
import {
  hasError,
  ReactHookFormErrorMessage,
} from '../../components/base/ErrorFieldMessage';
import { createAction } from '../../utils';
import { ERROR_MESSAGE } from '../../utils';
import CreateRateLimitGeneral from './CreateRateLimitGeneral';

function CreateRateLimitContent() {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const { rateLimitType } = params;
  const {
    control,
    getValues,
    clearErrors,
    setError,
    formState: { errors },
  } = useFormContext();

  const validate = async () => {
    clearErrors(['settings']);
    const [settings] = getValues(['settings']);

    let isValid = true;

    if (!settings || !settings.length) {
      isValid = false;
      setError(
        'settings',
        {
          type: 'require',
          message: ERROR_MESSAGE.MISS_FIELD,
        },
        { shouldFocus: true },
      );
    } else {
      for (let i = 0; i < settings.length; i++) {
        if (!settings[i].administratorGroup?.pk) {
          isValid = false;
          setError(
            `settings.${i}.administratorGroup`,
            {
              type: 'require',
              message: ERROR_MESSAGE.MISS_FIELD,
            },
            { shouldFocus: true },
          );
        }
        if (
          !(
            settings[i].threshold ||
            settings[i].threshold === 0 ||
            settings[i].threshold === '0'
          )
        ) {
          isValid = false;
          setError(
            `settings.${i}.threshold`,
            {
              type: 'require',
              message: ERROR_MESSAGE.MISS_FIELD,
            },
            { shouldFocus: true },
          );
        }
        if (
          !settings[i].duration ||
          settings[i].duration === 0 ||
          settings[i].duration === '0'
        ) {
          isValid = false;
          setError(
            `settings.${i}.duration`,
            {
              type: 'require',
              message: ERROR_MESSAGE.MISS_FIELD,
            },
            { shouldFocus: true },
          );
        }
      }
    }

    return isValid;
  };

  return (
    <>
      <ContentSections
        sections={[<CreateRateLimitGeneral />]}
        hidePreview={true}
      />
      <SaveAndBackWithOutTempButtons
        backAction={() => {
          history.goBack();
        }}
        saveText="Save"
        saveAction={async () => {
          const isValid = await validate();
          if (isValid) {
            const data = getValues();
            if (rateLimitType) {
              dispatch(createAction('rateLimit/createOrUpdate')({ data }));
            }
          }
        }}
      />
    </>
  );
}
export default CreateRateLimitContent;
