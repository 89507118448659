import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PermissionCodes } from '../../config/PermissionCodes';
import BasePrompt from '../base/prompt/BasePrompt';
import { formatDate, TimeFormater } from '../../utils/TimeFormatUtil';
import { useSelector } from 'react-redux';
import './ScheduledFailedPrompt.scss';
import CustomViewer from '../base/CustomViewer';
import { decodedStripedHtml } from '../../utils';

function ScheduledFailedPrompt(props) {
  const queryString = require('query-string');
  const history = useHistory();
  const defaultShow = props.show || false;
  const onClose = props.onClose || (() => {});
  const onChangeItem = props.onChangeItem || (() => {});
  const selectedItem = props.selectedItem || {};
  const scheduledData = props.data || [];
  const [show, setShow] = useState(false);
  const saveButtonAction = props.saveButtonAction || (() => {});
  const [itemShow, setItemShow] = useState(false);
  const [item, setItem] = useState();

  const { startDate, endDate } = useSelector((state) => ({
    startDate: state.generalAlert.detail.startDate,
    endDate: state.generalAlert.detail.endDate,
  }));

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  const handleItemClose = () => {
    setItemShow(false);
    onClose();
  };

  if (!show && defaultShow) {
    setShow(true);
  }

  const scheduledItemClick = (item) => {
    console.log('id');
    setItemShow(show);
    setItem(item);
  };

  const formatScheduledDate = (date) => {
    return formatDate(date, TimeFormater.dayMonthYearWeekTimeA);
  };

  const scheduledItems = () => {
    return (
      <>
        {scheduledData.map((item) => {
          return (
            <div className="scheduled-item-container">
              <div className="display-dot">
                <div className="dot" />
                <label
                  className="schediled-item label-one-line"
                  onClick={() => scheduledItemClick(item)}
                >{`[ID:${item.pk}]${decodedStripedHtml(item.content)}`}</label>

                {/* <CustomViewer
                  className="schediled-item label-one-line"
                  onClick={() => scheduledItemClick(item)}
                  initValue={`[ID:${item.pk}]${item.content}`}
                /> */}
              </div>
              <label className="scheduled-item-descroption">{`${formatScheduledDate(
                item.startDate,
              )} - ${formatScheduledDate(item.endDate)}`}</label>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <>
      <BasePrompt
        show={show}
        customClass={`next-step-prompt-container`}
        title={`Schedule collision`}
        description={`5 alert have been scheduled and active between ${formatScheduledDate(
          startDate,
        )} - ${formatScheduledDate(
          endDate,
        )}, please change the schedule time of current alert or below alerts.`}
        otherBody={scheduledItems}
        closeAction={handleClose}
        rightButton={{
          text: `Change time`,
          action: () => {
            handleClose();
            onChangeItem(selectedItem);
          },
          requires: PermissionCodes.changeMessage,
        }}
        leftButton={{
          text: `Save only`,
          action: () => {
            handleClose();
            saveButtonAction(selectedItem);
          },
          requires: PermissionCodes.changeMessage,
        }}
      />
      <BasePrompt
        show={itemShow}
        customClass={`next-step-prompt-container leave-general-alert`}
        title={decodedStripedHtml(item?.content)}
        description={`You will leave the general alert creation process. After leave the current alert will be saved auto.`}
        closeAction={handleItemClose}
        rightButton={{
          text: `Go to the alert`,
          action: () => {
            console.log('@@121: ', `/general_alert/${item.pk}/edit/`);
            // handleClose();
            saveButtonAction(selectedItem, () => {
              // window.location.href = `/general_alert/${item.pk}/edit/`;
              return history.push(`/general_alert/${item.pk}/edit/`);
            });
          },
          requires: PermissionCodes.changeMessage,
        }}
      />
    </>
  );
}

export default ScheduledFailedPrompt;
