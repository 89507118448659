import { BaseHelper } from './BaseGraphQLAPIHelper';

const TASK_DETAIL = `
pendingAndProcessingCount
scheduledPendingAndProcessingCount
edges {
  cursor
  node {
    id
    pk
    scheduleType
    type
    resource
    fileUrl
    fileName
    resultFileUrl
    status
    errors {
      numberOfImported
      numberOfFailed
      indexOfFailedRows
    }
    related {
      templateId
      couponQuantity
      couponPrefixCode

      includeTransaction
      includeHighValueCouponForm
      targetCouponTemplateIds
      separateFiles
    }
  }
}`;

export const importCustomers = (values) => {
  const query = `
  mutation CreateImportJob($input: CreateImportJobInput!) {
    createImportJob(input: $input) {
      node {
        id
        pk
        resource #CUSTOMER,COUPON,TRANSACTION
        file
        status #PENDING,PROCESSING,COMPLETED,ERROR
      }
      errors {
        field
        messages
      }
    }
  }

  `;
  const variables = { input: values };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createDownloadTask = (values) => {
  const query = `
  mutation CreateExportJob($input: CreateExportJobInput!) {
    createExportJob(input: $input) {
      node {
        id
        pk
        resource #CUSTOMER,TRANSACTION,COUPON_RECORD,POINTS_RECORD
        file
        status #PENDING,PROCESSING,COMPLETED,ERROR
      }
      errors {
        field
        messages
      }
    }
  }


    `;
  const variables = { input: values };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const downloadFile = (id) => {
  const query = `
  {
    exportJob(id: "${id}") {
      id
      pk
      resource
      file
      status
    }
  }

      `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getExportTasks = () => {
  const query = `
  {
    exportJobs(first: 10, after: "", orderBy: "-pk",) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          resource
          file
          fileUrl
          fileName
          status
          creationDate
        }
      }
    }
  }


        `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getSystemTasks = () => {
  const query = `
  {
    systemTasks(first: 50, orderBy: "-creationDate", taskType: "GENERAL") {
      ${TASK_DETAIL}
    }
  }
  `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getScheduledTasks = () => {
  const query = `
  {
    systemTasks(first: 50, orderBy: "-creationDate", taskType: "SCHEDULED") {
      ${TASK_DETAIL}
    }
  }
  `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateSystemTask = ({ id, type, status }) => {
  const updateImportTask = `
  mutation UpdateImportJob($input: UpdateImportJobInput!) {
    updateImportJob(input: $input) {
      success
    }
  }
`;
  const updateExportTask = `
mutation UpdateExportJob($input: UpdateExportJobInput!) {
  updateExportJob(input: $input) {
    success
  }
}
`;
  const updateQuery =
    type === 'IMPORT_JOB' ? updateImportTask : updateExportTask;

  const variables = { input: { id, status } };
  return BaseHelper.callGraphQLAPI({ query: updateQuery, variables });
};

export const cancelSystemTask = ({ id, type }) => {
  const cancelImportTask = `
  mutation CancelImportJob($input: CancelImportJobInput!) {
    cancelImportJob(input: $input) {
      success
    }
  }
`;
  const cancelExportTask = `
mutation CancelExportJob($input: CancelExportJobInput!) {
  cancelExportJob(input: $input) {
    success
  }
}
`;
  const cancelQuery =
    type === 'IMPORT_JOB' ? cancelImportTask : cancelExportTask;

  const variables = { input: { id } };
  return BaseHelper.callGraphQLAPI({ query: cancelQuery, variables });
};
