import React from 'react';
import { useFormContext } from 'react-hook-form';
import { changeSwitch, isShowCouponRelated } from '../../../models/CreateCampaignModel';
import { insertKeyToTranslations } from '../../../utils';
import BaseMarkDownAreaWithoutController from '../../base/BaseMarkDownAreaWithoutController';
import { errorMessage, hasError } from '../../base/ErrorFieldMessage';
import LabelWithSwitch from '../../base/LabelWithSwitch';

function CreateCampaignStepTwoMarkDownArea({
  language,
  areaTitle,
  followKey,
  resetKey,
  titleKey,
  titleName,
  contentKey,
  contentName,
}) {
  const { watch, setValue, formState, getValues } = useFormContext();
  const errors = formState.errors;
  const watchCampaignType = watch('campaignType');
  const languages = watch('selectedLanguages') || [];
  const translations = watch('translations') || {};
  const titleValue = translations?.[language]?.[titleKey];
  const contentValue = translations?.[language]?.[contentKey];
  const resetValue = translations?.[language]?.[resetKey];
  const isCouponRelated = isShowCouponRelated(watchCampaignType);
  const isFollow = watch(followKey);

  return (
    <>
      <LabelWithSwitch
        labelText={areaTitle}
        className="create-section-title"
        notUseDefaultLabelStyle
        showSwitch={isCouponRelated}
        checked={isFollow}
        onChange={() => {
          changeSwitch({
            getValues,
            setValue,
            languages,
            key: followKey,
            value: !isFollow,
          });
          if (!isFollow) {
            insertKeyToTranslations({
              language,
              key: resetKey,
              value: true,
              setValue,
              getValues,
            });
          }
        }}
      />
      <BaseMarkDownAreaWithoutController
        title={{
          titleKey: `translations.${language}.${titleKey}`,
          titleName: titleName,
          titleValue: titleValue,
          titleDisabled: isFollow,
          titleError: hasError(errors, titleKey),
          titleErrorMessage: errorMessage(errors, titleKey),
          titleExtra: { value: titleValue },
        }}
        setTitleValue={(data) => {
          insertKeyToTranslations({
            language,
            key: titleKey,
            value: data,
            setValue,
            getValues,
          })
        }}
        content={{
          contentKey: `translations.${language}.${contentKey}`,
          contentName: contentName,
          contentValue: contentValue,
          contentDisabled: isFollow,
          contentError: hasError(errors, contentKey),
          contentErrorMessage: errorMessage(errors, contentKey),
          contentExtra: {
            resetInitValue: resetValue,
            resetInit: () => {
              insertKeyToTranslations({
                language,
                key: resetKey,
                value: false,
                setValue,
                getValues,
              });
            }
          },
        }}
        setContentValue={(data) => {
          insertKeyToTranslations({
            language,
            key: contentKey,
            value: data,
            setValue,
            getValues,
          })
        }}
      />
    </>
  );
}

export default CreateCampaignStepTwoMarkDownArea;