import React, { useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import ContentSections from '../base/ContentSections';
import { ContinueWithBackButtons } from '../base/BottomStepComponent';
import { useFormContext, Controller } from 'react-hook-form';
import { validate } from './CreateGoodieBagValidate';
import BasePrompt from '../base/prompt/BasePrompt';
import { createAction, insertKeyToTranslations } from '../../utils';
import CustomTitleWithInput from '../base/CustomTitleWithInput';
import CustomEditor from '../base/CustomEditor';
import ErrorFieldMessage, {
  hasError,
  errorMessage,
} from '../base/ErrorFieldMessage';
import i18n from '../../I18n';
import ImageUploader from '../base/ImageUploader';
import {
  TermsAndConditionsType,
  LanguageConfig,
} from '../../config/CustomEnums';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import SpecificCustomSwitchButton from '../../containers/merchants/stores/SpecificCustomSwitchButton';
import './CreateGoodieBagStepTwo.scss';
import MarkDownAreaWithTC from '../base/MarkDownAreaWithTC';
import { GOODIE_BAG_REWARD_TYPE_OPTIONS } from '../../models/GoodieBagsModel';
import CustomSwitchButton from '../base/CustomSwitchButton';
import ColorIndicatorSection from '../coupon/couponCreation/ColorIndicatorSection';
import CustomTitleWithSwitch from '../base/CustomTitleWithSwitch';
import AuthButton from '../base/AuthButton';

function GeneralSection({
  language,
  languages,
  genericTermsAndConditionsList,
  rewardType,
}) {
  const { formState, setValue, control } = useFormContext();
  const errors = formState.errors;

  const descriptionSection = ({ title, descriptionKey, initialValue }) => {
    return (
      <>
        <div
          className={'create-section-label create-section-label-bottom-space'}
        >
          {title}
        </div>

        <Controller
          control={control}
          name={`translations.${language}.${descriptionKey}`}
          render={({ field: { value } }) => (
            <>
              <CustomEditor
                initialValue={value}
                onValueChange={(value) => {
                  // insertKeyToTranslations({
                  //   language,
                  //   key: descriptionKey,
                  //   value: value,
                  //   setValue,
                  //   getValues,
                  // });
                  setValue(
                    `translations.${language}.${descriptionKey}`,
                    value,
                    { shouldDirty: true },
                  );
                }}
                onFocus={() => { }}
                errorMessage={errorMessage(
                  errors,
                  `translations.${language}.${descriptionKey}`,
                )}
                error={hasError(
                  errors,
                  `translations.${language}.${descriptionKey}`,
                )}
              />
            </>
          )}
        />
      </>
    );
  };

  const coverPhotoSection = ({
    title,
    suggest,
    minWidth,
    minHeight,
    initialValue,
    photoKey,
    aspect,
  }) => {
    return (
      <>
        <CustomTitleLabel title={title} />
        <div className="second-section_description">{suggest}</div>
        <Controller
          control={control}
          name={`translations.${language}.${photoKey}`}
          render={({ field: { value } }) => (
            <ImageUploader
              minWidth={minWidth}
              minHeight={minHeight}
              images={value ? [value] : []}
              onImageStateChange={(newState) => {
                setValue(
                  `translations.${language}.${photoKey}`,
                  newState ? (newState?.[0] ? newState?.[0] : null) : null,
                  {
                    shouldDirty: true,
                  },
                );
              }}
              aspect={aspect}
              maxImageNum={1}
              language={language}
              uploadImageClicked={() => { }}
              errorMessage={
                hasError(errors, `translations.${language}.${photoKey}`)
                  ? errorMessage(errors, `translations.${language}.${photoKey}`)
                  : ''
              }
            />
          )}
        />
      </>
    );
  };

  return (
    <div className="create-goodie-bag-step-two">
      <div className="first-section-title">General</div>
      <Controller
        control={control}
        name={`translations.${language}.pk`}
        render={() => () => { }}
      />
      <Controller
        control={control}
        name={`translations.${language}.name`}
        render={({ field: { value } }) => (
          <>
            <CustomTitleWithInput
              title="Name"
              customClass="create-goodie-bag-input"
              defaultValue={value}
              useDefaultValue={false}
              setValue={(val) => {
                // insertKeyToTranslations({
                //   language,
                //   key: 'name',
                //   value: value,
                //   setValue,
                //   getValues,
                // });
                console.log('@@153: ', val);
                setValue(`translations.${language}.name`, val, {
                  shouldDirty: true,
                });
              }}
              error={{
                id: `translations.${language}.name`,
                error: hasError(errors, `translations.${language}.name`),
                message: errorMessage(errors, `translations.${language}.name`),
              }}
            />
          </>
        )}
      />

      {descriptionSection({
        title: 'Short Description for Goodie Bag Landing Page',
        // initialValue: watchShortDescription,
        descriptionKey: 'shortDescription',
      })}

      {rewardType !== GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value &&
        descriptionSection({
          title:
            'Short Description for Cash Voucher / Goodie Bag Coupon Listing Page (Optional)',
          // initialValue: watchShortDescriptionInCampaignListingPage,
          descriptionKey: 'shortDescriptionInCampaignListingPage',
        })}

      {rewardType !== GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value ? (
        <>
          {coverPhotoSection({
            title: 'Cover Image on My Rewards for Desktop',
            suggest:
              'Suggested Image Size: 1920px*1440px  At least : 488px*366px(Less than 2MB, support JPG, PNG and GIF only.)',
            minWidth: 488,
            minHeight: 366,
            // initialValue: watchCoverPhoto,
            photoKey: 'coverPhoto',
            aspect: 4 / 3,
          })}
          {coverPhotoSection({
            title:
              'Cover Image on Goodie Bag Campaign List for Desktop (optional)',
            suggest:
              'Suggested Image Size: 1920px*500px  At least : 1024px*266px(Less than 2MB, support JPG, PNG and GIF only.)',
            minWidth: 1024,
            minHeight: 266,
            // initialValue: watchCoverPhoto,
            photoKey: 'coverPhotoForCampaignListingPage',
            aspect: 1920 / 500,
          })}

          {coverPhotoSection({
            title: 'Cover Image on My Rewards for Mobile',
            suggest:
              'Suggested Image Size: 1125px*633px  At least : 375px*211px(Less than 2MB, support JPG, PNG and GIF only.)',
            minWidth: 375,
            minHeight: 211,
            // initialValue: watchMobilePhoto,
            photoKey: 'mobilePhoto',
            aspect: 375 / 211,
          })}
          {coverPhotoSection({
            title:
              'Cover Image on Goodie Bag Campaign List for Mobile (optional)',
            suggest:
              'Suggested Image Size: 1125px*633px  At least : 375px*211px(Less than 2MB, support JPG, PNG and GIF only.)',
            minWidth: 375,
            minHeight: 211,
            // initialValue: watchMobilePhoto,
            photoKey: 'mobilePhotoForCampaignListingPage',
            aspect: 375 / 211,
          })}
        </>
      ) : (
        <>
          {coverPhotoSection({
            title: 'Cover Image for Desktop',
            suggest:
              'Suggested Image Size: 1920px*1440px  At least : 488px*366px(Less than 2MB, support JPG, PNG and GIF only.)',
            minWidth: 488,
            minHeight: 366,
            // initialValue: watchCoverPhoto,
            photoKey: 'coverPhoto',
            aspect: 4 / 3,
          })}

          {coverPhotoSection({
            title: 'Cover Image for Mobile',
            suggest:
              'Suggested Image Size: 1125px*633px  At least : 375px*211px(Less than 2MB, support JPG, PNG and GIF only.)',
            minWidth: 375,
            minHeight: 211,
            // initialValue: watchMobilePhoto,
            photoKey: 'mobilePhoto',
            aspect: 375 / 211,
          })}
        </>
      )}

      <ColorIndicatorSection
        language={language}
        showColorIndicatorTips1={
          'If on, this color indicator will be visible on both the web and WeChat frontend. It will be displayed on the coupon detail page as well as the success redemption page of the coupons.'
        }
        showColorIndicatorTips2={
          'If the coupon has selected color indicator, the color selected from the coupon will replace the color set in here (goodie bag).'
        }
      />

      <MarkDownAreaWithTC
        languages={languages}
        language={language}
        hideTC={false}
        linkToTCTemplate="genericTermsAndConditionsTemplate"
        followTemplateKey="isFollowGenericTermsAndConditionsTemplate"
        getTCListKey="getGenericList"
        defaultFilterTC={{ type: TermsAndConditionsType.generic }}
        TCList={genericTermsAndConditionsList}
        areaTitle={null}
        titleKey="genericTermsAndConditionsTitle"
        titleName="Generic Terms & Conditions section title"
        contentKey="genericTermsAndConditionsContent"
        contentName="Generic Terms & Conditions section content"
      />
    </div>
  );
}

function OthersSection({ language }) {
  const { watch, setValue, control, formState } = useFormContext();
  const errors = formState.errors;
  const rewardType = watch('rewardType');
  const landingSlot = watch('landingSlot');
  const slotCampaigns =
    rewardType === GOODIE_BAG_REWARD_TYPE_OPTIONS.both.value
      ? {
        ...watch('slotCashCampaigns'),
        ...watch('slotGiftCampaigns'),
      }
      : rewardType === GOODIE_BAG_REWARD_TYPE_OPTIONS.gift.value
        ? watch('slotGiftCampaigns')
        : watch('slotCashCampaigns');
  const landingSlotName = watch(`slotNames.${landingSlot}.${language}.name`);
  const isSetLanding = watch(`isSetLanding`);

  // const slotNames = watch('slotNames');
  // ,
  // landingPageSetting: {
  //   index: landingSlot,
  //   onChange: (index) => {
  //     setValue('landingSlot', index);
  //   },
  // },

  return (
    <div className="create-goodie-bag-step-two">
      <div className="first-section-title">Others</div>
      <Controller
        control={control}
        name={`isSetLanding`}
        render={({ field: { value } }) => (
          <>
            <CustomTitleWithSwitch
              title="Set as landing after acquire"
              defaultValue={value}
              setValue={(v) => setValue('isSetLanding', v)}
              tips="If ON, you can select one of the slots below as the landing page after acquiring the goodie bag instead of landing to My Rewards."
            />

            {value ? (
              <>
                <CustomTitleLabel title="Selected slot" />
                <br />
                <label className="upload-file-name">
                  {landingSlot
                    ? `Slot ${Object.keys(slotCampaigns).indexOf(landingSlot) + 1
                    } name: ${landingSlotName || '-'}`
                    : '-'}
                </label>
              </>
            ) : null}
            <br />
            <ErrorFieldMessage
              error={hasError(errors, 'isSetLanding')}
              message={'Please select a slot'}
            />
          </>
        )}
      />

      <div
        className={`campaign-devide-line ${isSetLanding ? 'content-section-table-container' : ''
          }`}
      ></div>

      {Object.keys(slotCampaigns).map((key, index) => (
        <>
          {index ? <hr className="slot-dotted-line" /> : null}
          <Controller
            control={control}
            name={`slotNames.${key}.${language}.name`}
            render={({ field: { value } }) => (
              <div className="slot-name-setting">
                <CustomTitleWithInput
                  title={`Slot ${index + 1} name`}
                  defaultValue={value}
                  setValue={(value) =>
                    setValue(`slotNames.${key}.${language}.name`, value, {
                      shouldDirty: true,
                    })
                  }
                  error={{
                    id: `slotNames.${key}.${language}`,
                    error: hasError(
                      errors,
                      `slotNames.${key}.${language}.name`,
                    ),
                    message: errorMessage(
                      errors,
                      `slotNames.${key}.${language}.name`,
                    ),
                  }}
                />
                {isSetLanding ? (
                  key === landingSlot ? (
                    <label className="create-section-label-bottom-space landing">
                      Selected
                    </label>
                  ) : (
                    <AuthButton
                      title={'Select'}
                      customClass={'btn-back-button-common'}
                      action={() => {
                        setValue('landingSlot', key);
                      }}
                    />
                  )
                ) : null}
              </div>
            )}
          />
          <Controller
            control={control}
            name={`slotNames.${key}.${language}.label`}
            render={({ field: { value } }) => (
              <div className="slot-name-setting">
                <CustomTitleWithInput
                  title={`Slot ${index + 1} label (optional)`}
                  customTitleClass="slot-label-title"
                  defaultValue={value}
                  setValue={(value) =>
                    setValue(`slotNames.${key}.${language}.label`, value, {
                      shouldDirty: true,
                    })
                  }
                  error={{
                    id: `slotNames.${key}.${language}`,
                    error: hasError(
                      errors,
                      `slotNames.${key}.${language}.label`,
                    ),
                    message: errorMessage(
                      errors,
                      `slotNames.${key}.${language}.label`,
                    ),
                  }}
                />
              </div>
            )}
          />
        </>
      ))}
    </div>
  );
}

function AgeRestrictedSection({ language }) {
  const { watch, setValue, control, formState } = useFormContext();
  const errors = formState.errors;

  const isAgeRestricted = watch('isAgeRestricted');

  const disabled = useMemo(
    () => language === LanguageConfig.traditionalChinese,
    [language],
  );

  const primaryButtonHasError = useMemo(
    () => hasError(errors, `translations.${language}.primaryButton`),
    [errors],
  );

  const secondaryButtonHasError = useMemo(
    () => hasError(errors, `translations.${language}.secondaryButton`),
    [errors],
  );

  // const slotNames = watch('slotNames');
  return (
    <div className="create-goodie-bag-step-two d-flex flex-column">
      <div className="first-section-title">age restricted</div>

      {language === LanguageConfig.english ? (
        <>
          <CustomTitleLabel title="Is age restricted" />
          <label className="tips-message">
            When ON, Front-end users will display an 'Age Declaration' prompt
            when they enter the Goodie bag landing page.
            <br />
            EN and SC can be filled in separately, TC’s content follow EN’s one
            (cannot be modified).
          </label>
          <Controller
            control={control}
            name={`isAgeRestricted`}
            render={({ field: { value } }) => (
              <>
                <CustomSwitchButton
                  defaultChecked={value}
                  onChange={(val) => {
                    setValue('isAgeRestricted', val, { shouldDirty: true });
                  }}
                />
              </>
            )}
          />
        </>
      ) : null}

      <div style={{ display: isAgeRestricted ? 'block' : 'none' }}>
        <CustomTitleLabel title="Age restricted Terms & Conditions title" />
        <Controller
          control={control}
          name={`translations.${language}.ageRestrictedConditionsTitle`}
          render={({ field: { value } }) => (
            <>
              <CustomEditor
                disabled={disabled}
                initialValue={value}
                onValueChange={(value) => {
                  // insertKeyToTranslations({
                  //   language,
                  //   key: descriptionKey,
                  //   value: value,
                  //   setValue,
                  //   getValues,
                  // });
                  setValue(
                    `translations.${language}.ageRestrictedConditionsTitle`,
                    value,
                    {
                      shouldDirty: true,
                    },
                  );

                  if (language === LanguageConfig.english) {
                    setValue(
                      `translations.zh-Hant.ageRestrictedConditionsTitle`,
                      value,
                      {
                        shouldDirty: true,
                      },
                    );
                  }
                }}
                onFocus={() => { }}
                errorMessage={errorMessage(
                  errors,
                  `translations.${language}.ageRestrictedConditionsTitle`,
                )}
                error={hasError(
                  errors,
                  `translations.${language}.ageRestrictedConditionsTitle`,
                )}
              />
            </>
          )}
        />

        <CustomTitleLabel title="Age restricted Terms & Conditions content" />
        <Controller
          control={control}
          name={`translations.${language}.ageRestrictedConditionsContent`}
          render={({ field: { value } }) => (
            <>
              <CustomEditor
                disabled={disabled}
                initialValue={value}
                onValueChange={(value) => {
                  // insertKeyToTranslations({
                  //   language,
                  //   key: descriptionKey,
                  //   value: value,
                  //   setValue,
                  //   getValues,
                  // });
                  setValue(
                    `translations.${language}.ageRestrictedConditionsContent`,
                    value,
                    {
                      shouldDirty: true,
                    },
                  );

                  if (language === LanguageConfig.english) {
                    setValue(
                      `translations.zh-Hant.ageRestrictedConditionsContent`,
                      value,
                      {
                        shouldDirty: true,
                      },
                    );
                  }
                }}
                onFocus={() => { }}
                errorMessage={errorMessage(
                  errors,
                  `translations.${language}.ageRestrictedConditionsContent`,
                )}
                error={hasError(
                  errors,
                  `translations.${language}.ageRestrictedConditionsContent`,
                )}
              />
            </>
          )}
        />

        <CustomTitleLabel title="Primary button" />
        <label className="tips-message">
          Fill in 'EN' and 'TC/SC' before and after '/', so that the mobile
          version of the button can be displayed in separate lines.
        </label>
        <div className="multiple-input-row">
          <Controller
            control={control}
            name={`translations.${language}.primaryButton1`}
            render={({ field: { value } }) => (
              <input
                className="custom-markdown-area-title "
                disabled={disabled}
                value={value}
                onChange={(e) => {
                  setValue(
                    `translations.${language}.primaryButton1`,
                    e.target.value,
                    {
                      shouldDirty: true,
                    },
                  );

                  if (language === LanguageConfig.english) {
                    setValue(
                      `translations.zh-Hant.primaryButton1`,
                      e.target.value,
                      {
                        shouldDirty: true,
                      },
                    );
                  }
                }}
              ></input>
            )}
          />

          <label>/</label>
          <Controller
            control={control}
            name={`translations.${language}.primaryButton2`}
            render={({ field: { value } }) => (
              <input
                className="custom-markdown-area-title "
                disabled={disabled}
                value={value}
                onChange={(e) => {
                  setValue(
                    `translations.${language}.primaryButton2`,
                    e.target.value,
                    {
                      shouldDirty: true,
                    },
                  );

                  if (language === LanguageConfig.english) {
                    setValue(
                      `translations.zh-Hant.primaryButton2`,
                      e.target.value,
                      {
                        shouldDirty: true,
                      },
                    );
                  }
                }}
              ></input>
            )}
          />
        </div>
        {primaryButtonHasError ? (
          <label className="editor-container-error-message">
            {errorMessage(errors, `translations.${language}.primaryButton`)}
          </label>
        ) : null}

        <CustomTitleLabel title="Secondary button" />
        <label className="tips-message">
          Fill in 'EN' and 'TC/SC' before and after '/', so that the mobile
          version of the button can be displayed in separate lines.
        </label>
        <div className="multiple-input-row">
          <Controller
            control={control}
            name={`translations.${language}.secondaryButton1`}
            render={({ field: { value } }) => (
              <input
                className="custom-markdown-area-title "
                disabled={disabled}
                value={value}
                onChange={(e) => {
                  setValue(
                    `translations.${language}.secondaryButton1`,
                    e.target.value,
                    {
                      shouldDirty: true,
                    },
                  );

                  if (language === LanguageConfig.english) {
                    setValue(
                      `translations.zh-Hant.secondaryButton1`,
                      e.target.value,
                      {
                        shouldDirty: true,
                      },
                    );
                  }
                }}
              ></input>
            )}
          />

          <label>/</label>
          <Controller
            control={control}
            name={`translations.${language}.secondaryButton2`}
            render={({ field: { value } }) => (
              <input
                className="custom-markdown-area-title "
                disabled={disabled}
                value={value}
                onChange={(e) => {
                  setValue(
                    `translations.${language}.secondaryButton2`,
                    e.target.value,
                    {
                      shouldDirty: true,
                    },
                  );

                  if (language === LanguageConfig.english) {
                    setValue(
                      `translations.zh-Hant.secondaryButton2`,
                      e.target.value,
                      {
                        shouldDirty: true,
                      },
                    );
                  }
                }}
              ></input>
            )}
          />
        </div>
        {secondaryButtonHasError ? (
          <label className="editor-container-error-message">
            {errorMessage(errors, `translations.${language}.secondaryButton`)}
          </label>
        ) : null}
      </div>
    </div>
  );
}

function CreateGoodieBagStepTwo({
  languageList,
  genericTermsAndConditionsList,
}) {
  const { getValues, clearErrors, setError, watch } = useFormContext();
  console.log('@@284: ', getValues());
  const watchRewardType = watch('rewardType');

  const dispatch = useDispatch();

  const isFortuneBag = useMemo(
    () => watchRewardType === GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value,
    [watchRewardType],
  );

  const getTabs = (language) => {
    return [
      <GeneralSection
        language={language}
        languages={languageList}
        genericTermsAndConditionsList={genericTermsAndConditionsList}
        rewardType={watchRewardType}
      />,
      isFortuneBag ? null : <OthersSection language={language} />,
      isFortuneBag ? <AgeRestrictedSection language={language} /> : null,
    ];
  };

  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 1,
      isBack,
    });
    dispatch({
      type: 'goodieBags/stepChange',
      payload: { isBack: isBack, step: 1, isValid },
    });
  };

  return (
    <>
      <ContentSections
        languageTabContent={{
          containers: languageList.map((language) => {
            return {
              container: getTabs(language.code),
              key: language.code,
              title: language.sourceName,
            };
          }),
        }}
        hidePreview
      />
      <ContinueWithBackButtons
        continueAction={() => {
          stepChangeAction(false);
        }}
        backAction={() => {
          stepChangeAction(true);
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  languageList: state.language.allList,
  genericTermsAndConditionsList: state.termsAndConditions?.genericList || [],
});

export default connect(mapPropsToState)(CreateGoodieBagStepTwo);
