import { createModel } from './BaseModel';
import {
  getTermsAndConditionsList,
  getTermsAndConditionsDetail,
  createTermsAndConditions,
  updateTermsAndConditions,
  deleteTermsAndConditions,
} from '../services/TermsAndConditionsApiHelper';
import {
  convertNumberToCursor,
  convertCursorToNumber,
  createAction,
  getObjectFromSessionStorage,
  removeFromSessionStorage,
  saveToSessionStorage,
} from '../utils';
import {
  APIStatus,
  LanguageConfig,
  SESSION_KEYS,
  TermsAndConditionsType,
} from '../config/CustomEnums';
import { apiWithResponseHandle } from './LoadingUtil';
import { TimeFormater } from '../utils/TimeFormatUtil';
import moment from 'moment';

const TERMS_AND_CONDITIONS_SESSION_KEY =
  SESSION_KEYS.TERMS_AND_CONDITIONS_SESSION_KEY;

export const saveTermsAndConditionsToSessionStorage = (data) => {
  saveToSessionStorage(TERMS_AND_CONDITIONS_SESSION_KEY, data);
};

export const removeTermsAndConditionsSessionStroage = () => {
  removeFromSessionStorage(TERMS_AND_CONDITIONS_SESSION_KEY);
};

const TERMS_AND_CONDITIONS_TYPE = {
  FOR_MERCHANT: 'Merchant T & C',
  FOR_GENERIC: 'Generic T & C',
};

const getDateFormat = (date, format) => {
  if (!date || !format) {
    return '';
  }
  return moment(date).format(format);
};

const parseTermsAndConditionsTranslations = (data) => {
  if (!data) {
    return;
  }
  const translations = {
    [LanguageConfig.english]: {
      title: data.title,
      content: data.content,
    },
  };

  const temp = data.translations?.edges?.forEach((translation) => {
    const { language, title, content, pk } = translation?.node;
    translations[language] = { title, content, pk };
  });
  return translations;
};

export const getTermsAndConditionsTemplateDate = (data) => {
  if (!data) {
    return;
  }
  const translations = parseTermsAndConditionsTranslations(data);
  return {
    ...data,
    name: data?.title,
    translations,
  };
};

const getAppliedLinkedTerms = (data) => {
  if (!data) {
    return;
  }
  return data.edges?.map((item) => item?.node?.name);
};

const getAppliedLinkedTermsWithPk = (data) => {
  if (!data) {
    return;
  }
  return data.edges?.map((item) => ({
    pk: item?.node?.pk,
    name: item?.node?.name,
  }));
};

const parseTermsAndConditions = (data) => {
  if (!data) {
    return;
  }
  const translations = parseTermsAndConditionsTranslations(data);
  // let appliedCampaign = getAppliedLinkedTerms(data.campaignsMerchantTerms);
  // let appliedCouponSet = getAppliedLinkedTerms(data.couponTemplatesMerchantTerms);
  // let appliedCampaignWithPk = getAppliedLinkedTermsWithPk(data.campaignsMerchantTerms);
  // let appliedCouponSetWithPk = getAppliedLinkedTermsWithPk(data.couponTemplatesMerchantTerms);

  // if (data.templateType === TermsAndConditionsType.generic) {
  //   appliedCampaign = getAppliedLinkedTerms(data.campaignsGenericTerms);
  //   appliedCouponSet = getAppliedLinkedTerms(data.couponTemplatesGenericTerms);
  //   appliedCampaignWithPk = getAppliedLinkedTermsWithPk(data.campaignsGenericTerms);
  //   appliedCouponSetWithPk = getAppliedLinkedTermsWithPk(data.couponTemplatesGenericTerms);
  // }

  return {
    pk: data.pk,
    name: data.title,
    title: data.title,
    content: data.content,
    creationDate: getDateFormat(
      data.creationDate,
      TimeFormater.dayMonthYearWeekTimeAWithComma,
    ),
    lastModifiedDate: getDateFormat(
      data.lastModifiedDate,
      TimeFormater.dayMonthYearWeekTimeAWithComma,
    ),
    creationDateDetail: getDateFormat(
      data.creationDate,
      TimeFormater.monthDayYearWeekTimeAWithoutComma,
    ),
    lastModifiedDateDetail: getDateFormat(
      data.lastModifiedDate,
      TimeFormater.monthDayYearWeekTimeAWithoutComma,
    ),
    templateType: data.templateType,
    type: TERMS_AND_CONDITIONS_TYPE?.[data.templateType] || null,
    translations,
    // appliedCampaign,
    // appliedCouponSet,
    // appliedCampaignWithPk,
    // appliedCouponSetWithPk,
    hasUpdatedDefaultValues: true,
  };
};

const cleanTermsAndConditionsTranslation = (
  translations,
  detailTranslations,
) => {
  const cleanedTranslations = [];
  const temp = Object.keys(translations).forEach((language) => {
    if (language === LanguageConfig.english) {
      return;
    }
    const translation = translations[language];
    const cleanedOneTranslation = {
      language,
      title: translation.title,
      content: translation.content,
    };

    if (detailTranslations) {
      cleanedOneTranslation.id = detailTranslations[language]?.pk;
    }
    cleanedTranslations.push(cleanedOneTranslation);
  });
  return cleanedTranslations;
};

const cleanTermsAndConditions = (data, detail) => {
  const enData = data.translations[LanguageConfig.english];
  const payload = {
    title: enData.title,
    content: enData.content,
    templateType: data?.templateType || TermsAndConditionsType.merchant,
    translations: cleanTermsAndConditionsTranslation(
      data.translations,
      detail?.translations,
    ),
  };
  if (detail) {
    payload.id = detail.pk;
  }
  return payload;
};

const parseTermsAndConditionsList = (data) =>
  data?.termsAndConditionsTemplates?.edges?.map((item) => ({
    ...parseTermsAndConditions(item?.node),
  }));

export default createModel({
  namespace: 'termsAndConditions',
  params: {
    dataKey: 'termsAndConditionsTemplates',
    listAPI: getTermsAndConditionsList,
    pkNode: 'TermsAndConditionsTemplateNode',
    detailAPI: getTermsAndConditionsDetail,
    deleteAPI: deleteTermsAndConditions,
    parse: parseTermsAndConditionsList,
    parseDetail: (data) =>
      parseTermsAndConditions(data.termsAndConditionsTemplate),
  },
  states: {
    formHasSubmitted: false,
    detail: {
      hasUpdatedDefaultValues: false,
    },
  },
  reducers: {
    updateSingleListData(state, { payload }) {
      console.log('@138', state, payload);
      const page = payload.page || 1;
      const pageCount = 20;
      const data = payload.data;
      let newPayload = {};
      if (payload.type === TermsAndConditionsType.merchant) {
        newPayload = {
          merchantList: page > 1 ? [...state.merchantList, ...data] : data,
        };
      }
      if (payload.type === TermsAndConditionsType.generic) {
        newPayload = {
          genericList: page > 1 ? [...state.genericList, ...data] : data,
        };
      }
      console.log('@156', newPayload);
      const pageInfo = payload.pageInfo;
      const startCursor = convertCursorToNumber(pageInfo.startCursor);
      const endCursor = convertCursorToNumber(pageInfo.endCursor);
      newPayload = {
        ...newPayload,
        pagedList: data,
        totalCount: payload.totalCount,
        totalPage: Math.ceil(payload.totalCount / pageCount),
        pageInfo: {
          startCursor: isNaN(startCursor) ? 0 : startCursor + 1,
          endCursor: isNaN(endCursor) ? 0 : endCursor + 1,
        },
      };
      console.log('@169', newPayload);
      return { ...state, ...newPayload };
    },
    loadTermsAndConditionsFromCookie(state, { payload }) {
      const termsAndConditions = getObjectFromSessionStorage(
        TERMS_AND_CONDITIONS_SESSION_KEY,
      );
      if (!termsAndConditions) {
        return {
          ...state,
          detail: {
            hasUpdatedDefaultValues: false,
          },
        };
      }
      return {
        ...state,
        detail: {
          ...termsAndConditions,
          hasUpdatedDefaultValues: true,
        },
      };
    },
  },
  effects: {
    getMerchantList: [
      function* ({ payload }, { call, select, put }) {
        console.log('@192', payload);
        const page = payload.page || 1;
        const pageCount = 20;
        const pageCursor = convertNumberToCursor((page - 1) * pageCount - 1);
        const serviceArgs = [getTermsAndConditionsList, pageCursor, payload];
        function* onSuccess(data) {
          yield put({
            type: 'updateSingleListData',
            payload: {
              ...payload,
              data: parseTermsAndConditionsList(data),
              totalCount: data['termsAndConditionsTemplates'].totalCount,
              pageInfo: data['termsAndConditionsTemplates'].pageInfo,
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getGenericList: [
      function* ({ payload }, { call, select, put }) {
        console.log('@192', payload);
        const page = payload.page || 1;
        const pageCount = 20;
        const pageCursor = convertNumberToCursor((page - 1) * pageCount - 1);
        const serviceArgs = [getTermsAndConditionsList, pageCursor, payload];
        function* onSuccess(data) {
          yield put({
            type: 'updateSingleListData',
            payload: {
              ...payload,
              data: parseTermsAndConditionsList(data),
              totalCount: data['termsAndConditionsTemplates'].totalCount,
              pageInfo: data['termsAndConditionsTemplates'].pageInfo,
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    createTermsAndConditions: [
      function* ({ payload }, { all, select, put }) {
        const { data, afterAction } = payload;
        const serviceArgs = [
          createTermsAndConditions,
          cleanTermsAndConditions(data),
        ];
        yield put(
          createAction('updateState')({
            apiStatus: APIStatus.calling,
          }),
        );
        function* onSuccess(data) {
          removeTermsAndConditionsSessionStroage();
          yield put(
            createAction('updateState')({
              apiStatus: APIStatus.success,
              formHasSubmitted: true,
            }),
          );
          if (afterAction) {
            yield afterAction();
          }
        }
        function* onError(err) {
          yield put(
            createAction('updateState')({
              apiStatus: APIStatus.failed,
            }),
          );
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],
    duplicate: [
      function* ({ payload }, { all, select, put }) {
        const { data, afterAction } = payload;
        const duplicateData = data;
        const title =
          duplicateData.translations?.[LanguageConfig.english]?.title;
        if (title) {
          duplicateData.translations[
            LanguageConfig.english
          ].title = `Copy of ${title}`;
        }
        const serviceArgs = [
          createTermsAndConditions,
          cleanTermsAndConditions(duplicateData),
        ];
        yield put(
          createAction('updateState')({
            apiStatus: APIStatus.calling,
          }),
        );
        function* onSuccess(data) {
          yield put(
            createAction('updateState')({
              apiStatus: APIStatus.success,
            }),
          );
          if (afterAction) {
            yield afterAction();
          }
        }
        function* onError(err) {
          yield put(
            createAction('updateState')({
              apiStatus: APIStatus.failed,
            }),
          );
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],
    updateTermsAndConditions: [
      function* ({ payload }, { all, select, put }) {
        const { data } = payload;
        const detail = yield select((state) => state.termsAndConditions.detail);
        console.log('@226', data, detail);
        const serviceArgs = [
          updateTermsAndConditions,
          cleanTermsAndConditions(data, detail),
        ];
        yield put(
          createAction('updateState')({
            apiStatus: APIStatus.calling,
          }),
        );
        function* onSuccess(data) {
          removeTermsAndConditionsSessionStroage();
          yield put(
            createAction('updateState')({
              apiStatus: APIStatus.success,
              formHasSubmitted: true,
            }),
          );
        }
        function* onError(err) {
          yield put(
            createAction('updateState')({
              apiStatus: APIStatus.failed,
            }),
          );
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],
  },
});
