import { createModel } from './BaseModel';
import {
  getWorkingTeamList,
  getWorkingTeamDetail,
  deleteWorkingTeams,
  createWorkingTeam,
  updateWorkingTeam,
} from '../services/WorkingTeamApiHelper';
import { SESSION_KEYS } from '../config/CustomEnums';

const parseDetail = (data) => {
  return {
    ...data,
    displayRelatedAdmins: data.administrators?.edges?.map(
      (item) => `[ID:${item.node.pk}]${item.node.username}`,
    ),
    administrators: data.administrators?.edges?.map((item) => item.node),
  };
};

const parseWorkingTeamList = (data) =>
  data?.workingTeams.edges.map((item) => ({
    ...item.node,
    displayRelatedAdmins:
      item.node.administrators?.edges
        ?.map((item) => `[ID:${item.node.pk}]${item.node.username}`)
        .join(', ') || '-',
  }));

export default createModel({
  namespace: 'workingTeam',
  states: {
    listDisplayFields: [
      { displayName: 'ID', fieldName: 'pk', orderField: 'pk' },
      { displayName: 'Name', fieldName: 'name' },
      { displayName: 'Related admin', fieldName: 'displayRelatedAdmins' },
    ],
  },
  reducers: {},
  params: {
    dataKey: SESSION_KEYS.WORKING_TEAM_SESSION_KEY,
    listAPI: getWorkingTeamList,
    parse: parseWorkingTeamList,
    deleteAPI: deleteWorkingTeams,
    pkNode: 'WorkingTeamNode',
    detailAPI: getWorkingTeamDetail,
    parseDetail: (data) => parseDetail(data.workingTeam),
    objectKey: 'workingTeams',
  },
  effects: {
    createOrUpdate: [
      function* ({ payload }, { call, put }) {
        const data = {
          name: payload.values.name,
          administrators: payload.values.administrators?.map((item) => item.pk),
        };

        let apiCall = createWorkingTeam;

        if (payload.values.pk) {
          data.id = payload.values.pk;

          apiCall = updateWorkingTeam;
        }
        console.log('@@63: ', payload, data);
        yield put({
          type: 'responseCreateOrUpdate',
          payload: { serviceArgs: [apiCall, data], isCreate: !data.id },
        });
      },
      { type: 'takeLatest' },
    ],
    duplicate: [
      function* ({ payload }, { all, put, select }) {
        const copy = payload.data;
        console.log('@@324: ', payload);
        const data = {
          name: `Copy of ${copy.name}`,
          administrators: copy.administrators?.edges?.map(
            (item) => item.node.pk,
          ),
        };

        yield put({
          type: 'responseCreateOrUpdate',
          payload: {
            serviceArgs: [createWorkingTeam, data],
            isCreate: true,
            afterActions: payload.afterAction,
          },
        });
      },
      { type: 'takeLatest' },
    ],
  },
});
