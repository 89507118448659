import React, { useMemo, useState } from 'react';
import AlertInfoPrompt from '../base/prompt/AlertInfoPrompt';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';

function ListFortuneBagCampaigns({
  isPreview,
  campaigns = [],
  goodiebagRemainedCount = 0,
}) {
  const [showPrompt, setShowPrompt] = useState(false);
  const lessRemainingCampaigns = useMemo(() => {
    return (
      campaigns
        ?.filter(
          (item) =>
            (item.couponCampaignInfo?.quantityOfRemaining || 0) /
              (item.goodieBagReward || 1) <
            goodiebagRemainedCount,
        )
        .sort((v1, v2) => {
          return v1.couponCampaignInfo?.quantityOfRemaining -
            v2.couponCampaignInfo?.quantityOfRemaining >
            0
            ? 1
            : -1;
        }) || []
    );
  }, [campaigns, goodiebagRemainedCount]);

  const moreRemainingCampaigns = useMemo(() => {
    return (
      campaigns
        ?.filter(
          (item) =>
            (item.couponCampaignInfo?.quantityOfRemaining || 0) /
              (item.goodieBagReward || 1) >=
            goodiebagRemainedCount,
        )
        .sort((v1, v2) => {
          return v1.couponCampaignInfo?.quantityOfRemaining -
            v2.couponCampaignInfo?.quantityOfRemaining >
            0
            ? 1
            : -1;
        }) || []
    );
  }, [campaigns, goodiebagRemainedCount]);

  return (
    <>
      {isPreview ? (
        <>
          <CustomTitleLabel
            title={
              'Linked Goodie bag offer (Direct assign) (with number of coupon can be acquired per entitlement)'
            }
          />
          <br />
        </>
      ) : (
        <label className="create-section-title">
          Number of coupon can be acquired per entitlement
        </label>
      )}

      <label
        className={`section-short-description ${
          !campaigns || campaigns?.length === 0
            ? ''
            : 'goodie-bag-view-linked-coupon-campaign'
        }`}
        onClick={() => setShowPrompt(true)}
      >
        {!campaigns || campaigns?.length === 0
          ? '-'
          : 'View linked Goodie bag offer (Direct assign)'}
      </label>

      <AlertInfoPrompt
        show={showPrompt}
        onCancel={() => setShowPrompt(false)}
        onClose={() => setShowPrompt(false)}
        onConfirm={() => setShowPrompt(false)}
        contentClass="alert-info-content"
        title={'Linked Goodie bag offer (Direct assign)'}
        content={
          <div
            className="d-flex flex-column"
            style={{ maxHeight: '600px', overflowY: 'auto' }}
          >
            <CustomTitleLabel
              className="without-margin-top"
              title={`Total number of Goodie bag offer (Direct assign) campaigns linked up with this goodie bag: ${campaigns?.length}`}
            />
            <label
              className="custom-record-normal-font"
              style={{ marginTop: 20 }}
            >
              No. of remaining coupon (No. of coupon per entitlement) -
              [Campaign ID] Campaign name (Status)
            </label>
            <CustomTitleLabel
              title={`Coupon qty less than remaining goodie bag link qty:`}
            />
            {lessRemainingCampaigns && lessRemainingCampaigns.length > 0
              ? lessRemainingCampaigns.map((item) => (
                  <a
                    className="channels-show-more-content"
                    href={`/coupons/${item.couponTemplateID}/`}
                    target="_blank"
                  >
                    <li>
                      {item.couponCampaignInfo?.quantityOfRemaining || 0} (
                      {item.goodieBagReward || 1}) - [{item.pk}] {item.name}{' '}
                      {item.isPublished ? '' : `(unPublished)`}
                    </li>
                  </a>
                ))
              : '-'}
            <CustomTitleLabel
              title={`Coupon qty more and equal than remaining goodie bag link qty:`}
            />
            {moreRemainingCampaigns && moreRemainingCampaigns.length > 0
              ? moreRemainingCampaigns.map((item) => (
                  <a
                    className="channels-show-more-content"
                    href={`/coupons/${item.couponTemplateID}/`}
                    target="_blank"
                  >
                    <li>
                      {item.couponCampaignInfo?.quantityOfRemaining || 0}(
                      {item.goodieBagReward || 1}) - [{item.pk}] {item.name}{' '}
                      {item.isPublished ? '' : `(unPublished)`}
                    </li>
                  </a>
                ))
              : '-'}
          </div>
        }
      />
    </>
  );
}

export default ListFortuneBagCampaigns;
