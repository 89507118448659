import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Image, Form } from 'react-bootstrap';
import './OverviewTable.scss';
import { createAction } from '../../utils';
import SearchIcon from '../../assets/images/drop_down_filter.svg';
import CustomEditableRank from '../../components/base/CustomEditableRank';
import CustomPagination from '../../components/base/CustomPagination';
import { useLocation } from 'react-router-dom';
import { CampaignType } from '../../config/CustomEnums';

function TableSearcher({ onSearch, placeholder }) {
  const location = useLocation();
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const searchKey = form.searchKey.value;
    onSearch(searchKey);
  };
  const [searchKey, setSearchKey] = useState();

  useEffect(() => {
    setSearchKey('');
  }, [location]);

  return (
    <Form
      autoComplete="off"
      noValidate
      className="list-filter-list-search-section"
      onSubmit={handleSubmit}
    >
      <Image src={SearchIcon} className="list-filter-search-icon" />
      <input
        name="searchKey"
        value={searchKey}
        onChange={({ target }) => setSearchKey(target.value)}
        type="text"
        className="list-filter-search-field"
        placeholder={placeholder}
      />
    </Form>
  );
}

const OverviewTable = ({
  name,
  hidden,
  status,
  placeholder = 'Search name',
  data = [],
  currentPage = 0,
  totalCount,
  pageChange = () => {},
  onSortChange = () => {},
  onFileterChange = () => {},
  titles = [],
  currentSort,
  hideFilter,
  searchName,
  hasCountInfo = false,
  disableRank = true,
  actionKey = '',
  fromModel = '',
  campaginTypes = null,
  goodiebagOverviewFilter = {},
  overviewDate = {},
}) => {
  const dispatch = useDispatch();
  console.log('currentSort:', currentSort);

  const doFilter = (object) => {
    let key = '';
    if (campaginTypes?.includes(CampaignType.cashVoucherCampaign)) {
      key = 'cashCampaignRankingTable';
    } else if (campaginTypes?.includes(CampaignType.giftCampaign)) {
      key = 'giftCampaignRankingTable';
    }else if(campaginTypes?.includes(CampaignType.fortuneBagForGoodieBagCampaign)) {
      key = 'fortunebagCampaignRankingTable';
    }
    if (object.pageNumber) {
      pageChange(object.pageNumber - 1);
    } else {
      pageChange(0);
    }
    actionKey && dispatch(
      createAction(actionKey)({
        key: key,
        orderBy: currentSort?.fieldName,
        searchName: searchName,
        fromModel: fromModel,
        campaginTypes: campaginTypes,
        ...object,
        goodiebagOverviewFilter: goodiebagOverviewFilter,
        overviewDate: overviewDate,
      }),
    );
  };
  const getSecondEntry = () => {
    if (!totalCount) {
      return 0;
    }
    if (totalCount <= 20) {
      return totalCount;
    }
    const pageNumber = (currentPage + 1) * 20;
    if (pageNumber > totalCount) {
      return totalCount;
    }
    return pageNumber;
  };
  const firstEntry = !!totalCount ? currentPage * 20 + 1 : 0;
  const secondEntry = getSecondEntry();
  console.log('@181', data);

  return (
    <div className="coupon-table-overview">
      <div className="overview-row-title overview-campaigns-ranking-table-title">
        <label>{name}</label>
        <label className="overview-campaigns-status-information">
          {status}
        </label>
      </div>
      {hidden ? (
        <div className="coupon-table-hidden"></div>
      ) : (
        <>
          {hasCountInfo ? (
            <label className="coupon-over-page-info">
              Showing {firstEntry} to {secondEntry} of {totalCount} entries
            </label>
          ) : (
            <></>
          )}
          <div
            className="coupon-list-section common-table-overflow overview"
            key={`${name}-list`}
          >
            {hideFilter ? null : (
              <TableSearcher
                placeholder={placeholder}
                onSearch={(name) => {
                  onFileterChange(name);
                  doFilter({ searchName: name });
                }}
              />
            )}
            <table
              className={`overview-all-list-table coupon-overview overview`}
            >
              <thead>
                <tr>
                  {titles.map((title, index) => {
                    return (
                      <th
                        key={index + 1}
                        style={{ width: `${100 / titles.length}%` }}
                      >
                        <CustomEditableRank
                          disabled={disableRank}
                          rank={currentSort?.fieldName?.includes('-')}
                          show={currentSort?.name === title.name}
                          title={title.name}
                          extraAreaStyle={'base-rank-container'}
                          onClick={(rank) => {
                            if (currentSort?.fieldName?.includes('-')) {
                              const newField = title.fieldName?.substring(0);
                              onSortChange({
                                name: title.name,
                                fieldName: newField,
                              });
                              doFilter({ orderBy: newField });
                              return;
                            }
                            onSortChange({
                              name: title.name,
                              fieldName: `-${title.fieldName}`,
                            });
                            doFilter({ orderBy: `-${title.fieldName}` });
                          }}
                        />
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  return (
                    <tr>
                      {item.map((column, index) => {
                        return (
                          <td style={{ width: `${100 / titles.length}%` }}>
                            <div>
                              <label>{column}</label>
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {totalCount > 20 ? (
              <div className="all-list-scrollable-area-pagination">
                <CustomPagination
                  initPage={currentPage}
                  pageSize={Math.ceil(totalCount / 20)}
                  pageChange={(page) => {
                    doFilter({ pageNumber: page + 1 });
                  }}
                />
              </div>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
};

export default OverviewTable;
