import React, { useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { ADMIN_TYPE } from '../../config/CustomEnums';
import { useFormContext, Controller } from 'react-hook-form';
import BaseMutipleSelectorV2 from '../base/BaseMultipleSelectorV2';
import BaseEditSection from '../../containers/base/BaseEditSection';
import CustomTitleWithInput from '../base/CustomTitleWithInput';
import { AdminGroupErrorHandleFields } from '../../containers/admin/adminGroups/AdminGroupErrorHandleFields';
import { CustomTitleWithRadio } from '../earning/CustomBaseComponments';
import { ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';

const radioOptions = [
  { label: 'HKTB administrator', value: ADMIN_TYPE.HKTB_ADMIN },
  { label: 'Merchant administrator', value: ADMIN_TYPE.MERCHANT_ADMIN },
];

function CreateAdminGroupStepOneGeneralSection({
  allPermissions,
  merchantPermissionList,
}) {
  const { watch, setValue, control, formState } = useFormContext();
  const dispatch = useDispatch();
  const { errors } = formState;
  const watchName = watch('name');
  const watchAdminType = watch('adminType') || ADMIN_TYPE.HKTB_ADMIN;
  let isMerchant = watchAdminType === ADMIN_TYPE.MERCHANT_ADMIN;
  const watchMerchantPermission = watch('merchantPermissions') || [];
  const watchPermissions = watch('permissions') || [];

  useEffect(() => {
    dispatch({
      type: 'admin/getAllPermissions',
      payload: { adminType: ADMIN_TYPE.HKTB_ADMIN },
    });
    dispatch({
      type: 'admin/getAllPermissions',
      payload: {
        adminType: ADMIN_TYPE.MERCHANT_ADMIN,
      },
    });
    return () => {
      dispatch({ type: 'admin/clearState' });
    };
  }, []);

  const getPermissionSelector = ({
    name = 'permissions',
    data = [],
    selected = [],
    updateVlaue = () => {},
  }) => {
    return (
      <>
        <Controller
          control={control}
          name={name}
          render={() => (
            <BaseMutipleSelectorV2
              title={'Administrator group(s) permission(optional)'}
              namespace="admin"
              data={{
                sourceData: data,
                targetData: selected,
                targetChange: (value) => {
                  console.log('targetChange:', value);
                  updateVlaue(value);
                },
              }}
            />
          )}
        />
      </>
    );
  };

  const merchantSection = getPermissionSelector({
    name: 'merchantPermissions',
    data: merchantPermissionList,
    selected: watchMerchantPermission,
    updateVlaue: (value) => {
      console.log('updateVlaue:', value);
      setValue('merchantPermissions', value, {
        shouldDirty: true,
      });
    },
  });

  const hktbSection = getPermissionSelector({
    name: 'permissions',
    data: allPermissions,
    selected: watchPermissions,
    updateVlaue: (value) => {
      console.log('updateVlaue:', value);
      setValue('permissions', value, {
        shouldDirty: true,
      });
    },
  });

  return (
    <>
      <BaseEditSection
        title="General"
        fields={[
          <Controller
            control={control}
            name="name"
            rules={{ required: AdminGroupErrorHandleFields.name.required }}
            render={() => (
              <CustomTitleWithInput
                title={'Name'}
                defaultValue={watchName}
                useDefaultValue={false}
                setValue={(value) => {
                  setValue('name', value, {
                    shouldDirty: true,
                  });
                }}
              />
            )}
          />,
          <ReactHookFormErrorMessage errors={errors} id="name" />,
          <Controller
            control={control}
            name="adminType"
            render={() => (
              <CustomTitleWithRadio
                title="Administrator group type"
                setValue={(value) => {
                  setValue('adminType', value, {
                    shouldDirty: true,
                  });
                }}
                defaultValue={watchAdminType}
                options={radioOptions}
              />
            )}
          />,
          <ReactHookFormErrorMessage errors={errors} id="adminType" />,

          isMerchant ? merchantSection : null,
          !isMerchant ? hktbSection : null,
        ]}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  allPermissions: state.admin.allPermissionList, // state.admin.cmsAllPermissions,
  merchantPermissionList: state.admin.merchantPermissionList,
});

export default connect(mapPropsToState)(CreateAdminGroupStepOneGeneralSection);
