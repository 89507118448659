import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import './GeneralSection.scss';
import {
  CampaignType,
  LanguageConfig,
  PublishTagType,
  STORE_LOADING_TYPE,
} from '../../../config/CustomEnums';
import i18n from '../../../I18n';
import { CampaignErrorHandleField } from './CreateCampaignHandleError';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { createAction, insertKeyToTranslations } from '../../../utils';

import { ContinueCreate } from '../CampaignUrlConfig';
import { CustomTitleLabel } from '../../earning/CustomBaseComponments';
import CustomMarkDownArea from '../../base/CustomMarkDownArea';
import BrandSelectDropdown from './BrandSelectDropdown';
import BasePrompt from '../../base/prompt/BasePrompt';
import CustomTitleWithSwitch from '../../base/CustomTitleWithSwitch';
import CustomTitleWithInput from '../../base/CustomTitleWithInput';
import SpecificCustomSwitchButton from '../../../containers/merchants/stores/SpecificCustomSwitchButton';
import { useFormContext } from 'react-hook-form';
import ErrorFieldMessage, {
  hasError,
  errorMessage,
  ReactHookFormErrorMessage,
} from '../../base/ErrorFieldMessage';
import {
  changeSwitch,
  markdownUpdateField,
  isShowCouponRelated,
} from '../../../models/CreateCampaignModel';
import BaseMutipleSelectorV2 from '../../base/BaseMultipleSelectorV2';
import { isLongScreen } from '../../../utils/ScreenUtil';
import LabelWithSwitch from '../../base/LabelWithSwitch';
import CustomEditor from '../../base/CustomEditor';
import CustomSwitchButton from '../../base/CustomSwitchButton';

function GeneralSection({
  brandStoreList,
  appliableStoreList,
  storeEditMode,
  language,
  originalBrandPK,
  originalLinkedStores,
  hideDescription,
}) {
  const params = useParams();
  const { id } = params;
  const dispatch = useDispatch();
  const history = useHistory();
  const { watch, setValue, formState, getValues } = useFormContext();
  const errors = formState.errors;
  const watchBrand = watch('linkedBrand') || {};
  const watchCoupon = watch('linkedCoupon') || {};
  const watchCampaignType = watch('campaignType');
  const watchHaveLandingPage = watch('haveLandingPage');
  const isRedirectionCampaign = watch('isRedirectionCampaign');
  const watchStores = watch('linkedStores');
  const translations = watch('translations') || {};
  const languages = watch('selectedLanguages') || [];
  const isFollowCouponTemplateName = watch('isFollowCouponTemplateName');
  const isFollowCouponTemplateShortDescription = watch(
    'isFollowCouponTemplateShortDescription',
  );
  const shortDescriptionReset =
    translations[language]?.[markdownUpdateField.shortDescription];
  const isFollowCouponTemplateStores = watch('isFollowCouponTemplateStores');
  const watchName = translations[language]?.generalName;
  const watchDescription = translations[language]?.shortDescription;
  console.log('watchName:', translations);
  console.log('shortDescriptionReset:', shortDescriptionReset);
  const watchLandingName =
    translations[language]?.landingPageButtonName ||
    i18n.t('book_now', { locale: language });
  const watchLandingUrl = translations[language]?.landingPageUrl;
  const watchLimitWording =
    translations[language]?.statementWordingForCountryCodeLimit;
  const watchShowWording = watch('showStatementWordingForCountryCodeLimit');
  const watchCountryCode = watch('countryCodes');
  const [addPromptConfig, setAddPromptConfig] = useState();
  const isCouponRelated = isShowCouponRelated(watchCampaignType);
  const getRelatedStores = (keys) => {
    console.log('getRelatedStores: ', keys);
    dispatch({
      type: 'storeModel/doStoreRequest',
      payload: { ...keys },
    });
  };

  const stopStoreLoading = () => {
    dispatch({
      type: 'storeModel/updateState',
      payload: { allListLoading: false },
    });
  };

  useEffect(() => {
    if (!id) {
      setValue('storeEditMode', true);
    }
  }, []);

  useEffect(() => {
    if (!watchBrand.pk) {
      dispatch({
        type: 'storeModel/updateState',
        payload: { appliableStoreList: [], brandStoreList: [] },
      });
    }
  }, [watchBrand.pk]);

  useEffect(() => {
    if (watchCoupon.pk) {
      dispatch(
        createAction('couponList/getCouponSet')({
          couponSetPK: watchCoupon.pk,
          notSaveToSession: true,
        }),
      );
    }
  }, [watchCoupon.pk]);

  useEffect(() => {
    if (!watchBrand.pk || watchBrand.pk === originalBrandPK) {
      return;
    }
    dispatch({
      type: 'storeModel/updateState',
      payload: { storeEditMode: true },
    });
  }, [watchBrand.pk]);

  useEffect(() => {
    if (!watchBrand.pk || language !== LanguageConfig.english) {
      return;
    }
    if (id && !storeEditMode) {
      stopStoreLoading();
      return;
    }
    if (!id || storeEditMode) {
      if (isCouponRelated && watchCoupon.pk) {
        getRelatedStores({
          [STORE_LOADING_TYPE.COUPON]: watchCoupon.pk,
          status: PublishTagType.published,
        });
      } else if (watchBrand.pk) {
        getRelatedStores({
          [STORE_LOADING_TYPE.BRAND]: watchBrand.pk,
          status: PublishTagType.published,
        });
      }
      return;
    }
    if (!id) {
      return;
    }
    getRelatedStores({
      [STORE_LOADING_TYPE.CAMPAIGN]: id,
      notBrandRelate: true,
    });
  }, [watchBrand.pk, watchCoupon.pk, storeEditMode]);

  useEffect(() => {
    if (appliableStoreList) {
      setValue('linkedStores', appliableStoreList, { shouldDirty: true });
    }
  }, [appliableStoreList]);

  useEffect(() => {
    if (isCouponRelated || watchBrand.pk !== originalBrandPK) {
      setValue('linkedStores', brandStoreList || [], { shouldDirty: true });
    } else if (!isCouponRelated && watchBrand.pk === originalBrandPK) {
      setValue('linkedStores', originalLinkedStores || [], {
        shouldDirty: true,
      });
    }
  }, [brandStoreList]);

  const enableStoreEdit = () => {
    if (!id) {
      return null;
    }
    if (!watchBrand.pk) {
      return null;
    }
    if (language !== LanguageConfig.english) {
      return null;
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <CustomTitleLabel title="Edit Applicable stores " />
        <SpecificCustomSwitchButton
          checked={storeEditMode || false}
          onChange={(isChecked) => {
            setValue('storeEditMode', isChecked);
            dispatch({
              type: 'storeModel/updateState',
              payload: { storeEditMode: isChecked },
            });
          }}
        />
      </div>
    );
  };

  return (
    <>
      <label className="create-section-title">GENERAL</label>
      <LabelWithSwitch
        labelText="Name"
        className="create-section-label-bottom-space"
        checked={isFollowCouponTemplateName}
        onChange={() =>
          changeSwitch({
            getValues,
            setValue,
            languages,
            key: 'isFollowCouponTemplateName',
            value: !isFollowCouponTemplateName,
          })
        }
        showSwitch={isCouponRelated}
      />
      <input
        type="text"
        maxLength={200}
        onChange={({ target }) => {
          insertKeyToTranslations({
            language,
            key: 'generalName',
            value: target?.value,
            setValue,
            getValues,
          });
        }}
        disabled={isFollowCouponTemplateName && isCouponRelated}
        value={watchName}
        className={`custom-markdown-area-title ${
          hasError(errors, `translations.${language}.generalName`)
            ? 'error-field'
            : ''
        } ${isLongScreen ? '' : 'custom-markdown-area-title-short'}`}
      />
      <ErrorFieldMessage
        id={`${CampaignErrorHandleField.generalName.name}-${language}`}
        error={hasError(errors, `translations.${language}.generalName`)}
        message={errorMessage(errors, `translations.${language}.generalName`)}
      />
      {hideDescription ? null : (
        <>
          <LabelWithSwitch
            labelText={`Short description${
              watchCampaignType === CampaignType.generalMessageCampaign
                ? '(optional)'
                : ''
            }`}
            className="create-section-label-bottom-space"
            checked={isFollowCouponTemplateShortDescription}
            onChange={() => {
              changeSwitch({
                getValues,
                setValue,
                languages,
                key: 'isFollowCouponTemplateShortDescription',
                value: !isFollowCouponTemplateShortDescription,
              });
              if (!isFollowCouponTemplateShortDescription) {
                insertKeyToTranslations({
                  language,
                  key: markdownUpdateField.shortDescription,
                  value: true,
                  setValue,
                  getValues,
                });
              }
            }}
            showSwitch={isCouponRelated}
          />
          <CustomEditor
            disabled={isFollowCouponTemplateShortDescription && isCouponRelated}
            initialValue={watchDescription}
            onValueChange={(value) => {
              insertKeyToTranslations({
                language,
                key: 'shortDescription',
                value: value,
                setValue,
                getValues,
              });
            }}
            errorMessage={errorMessage(
              errors,
              `translations.${language}.shortDescription`,
            )}
            error={hasError(
              errors,
              `translations.${language}.shortDescription`,
            )}
            // resetInitValue={shortDescriptionReset}
            // resetInit={() => {
            //   insertKeyToTranslations({
            //     language,
            //     key: markdownUpdateField.shortDescription,
            //     value: false,
            //     setValue,
            //     getValues,
            //   });
            // }}
          />
        </>
      )}

      {[
        CampaignType.couponsCampaign,
        CampaignType.cashVoucherCampaign,
        CampaignType.giftCampaign,
      ].includes(watchCampaignType) && watchCountryCode?.length > 0 ? (
        <>
          <CustomTitleLabel
            className="general-section-limit-wording-label"
            title={'Show statement wording for country code limit'}
          />
          {language === LanguageConfig.english || watchShowWording ? (
            <label className="general-section-limit-wording-tips">
              {language === LanguageConfig.traditionalChinese ||
              language === LanguageConfig.simplifiedChinese
                ? i18n.t('country_code_limit_wording_tips', {
                    locale: language,
                  })
                : i18n.t('country_code_limit_wording_tips', { locale: 'en' })}
            </label>
          ) : null}
          {language === LanguageConfig.english ? (
            <>
              <CustomSwitchButton
                defaultChecked={watchShowWording}
                onChange={(isChecked) => {
                  setValue(
                    'showStatementWordingForCountryCodeLimit',
                    isChecked,
                    {
                      shouldDirty: true,
                    },
                  );
                }}
              />
            </>
          ) : null}
          {watchShowWording ? (
            <CustomTitleWithInput
              customClass={
                language === LanguageConfig.english
                  ? 'general-section-limit-wording-input'
                  : null
              }
              defaultValue={watchLimitWording}
              setValue={(value) => {
                insertKeyToTranslations({
                  language,
                  key: 'statementWordingForCountryCodeLimit',
                  value: value,
                  setValue,
                  getValues,
                });
              }}
            />
          ) : null}
        </>
      ) : null}
      <ReactHookFormErrorMessage
        errors={errors}
        id={'statementWordingForCountryCodeLimit'}
      />

      {watchCampaignType === CampaignType.generalMessageCampaign ? (
        <>
          {language === LanguageConfig.english && !isRedirectionCampaign ? (
            <CustomTitleWithSwitch
              title={'Have Landing page URL'}
              defaultValue={watchHaveLandingPage}
              setValue={(value) => {
                setValue('haveLandingPage', !!value, { shouldDirty: true });
              }}
            />
          ) : null}

          {watchHaveLandingPage || isRedirectionCampaign ? (
            <>
              <CustomTitleWithInput
                title="Landing page URL"
                defaultValue={watchLandingUrl}
                setValue={(value) => {
                  insertKeyToTranslations({
                    language,
                    key: 'landingPageUrl',
                    value: value,
                    setValue,
                    getValues,
                  });
                }}
                error={{
                  id: `translations.${language}.landingPageUrl`,
                  error: hasError(
                    errors,
                    `translations.${language}.landingPageUrl`,
                  ),
                  message: errorMessage(
                    errors,
                    `translations.${language}.landingPageUrl`,
                  ),
                }}
                multipleLines={true}
                customClass={'muiltpleLinesInput'}
              />
              <CustomTitleWithInput
                title={`Landing page button name ${
                  isRedirectionCampaign
                    ? i18n.t('optional', { locale: 'en' })
                    : ''
                }`}
                defaultValue={watchLandingName}
                setValue={(value) => {
                  insertKeyToTranslations({
                    language,
                    key: 'landingPageButtonName',
                    value: value,
                    setValue,
                    getValues,
                  });
                }}
                error={{
                  id: `translations.${language}.landingPageButtonName`,
                  error: hasError(
                    errors,
                    `translations.${language}.landingPageButtonName`,
                  ),
                  message: errorMessage(
                    errors,
                    `translations.${language}.landingPageButtonName`,
                  ),
                }}
              />
            </>
          ) : null}
        </>
      ) : null}
      <div className="create-campaign-brand-select">
        <BrandSelectDropdown
          title={
            i18n.t('related_brand', { locale: 'en' }) +
            (isCouponRelated || isRedirectionCampaign
              ? ''
              : ` ${i18n.t('optional', { locale: 'en' })}`)
          }
          defaultValue={{ label: watchBrand?.name, value: watchBrand }}
          onSelectChange={(value) => {
            setValue('linkedBrand', value?.value || {}, { shouldDirty: true });
          }}
          addButton={{
            action: () => {
              setAddPromptConfig({
                button: { text: 'create brand', actionPath: '/brands/create' },
                title: 'Go to create brand?',
                description: `You will leave the campaign creation process. After you create a new brand, you can back to this page.`,
              });
            },
            title: 'Create Now',
            requires: PermissionCodes.addBrand,
            customClass: 'general-section-add-new-brand btn-add-button-common',
          }}
          editable={
            language === LanguageConfig.english &&
            (watchCampaignType === CampaignType.generalMessageCampaign ||
              (isCouponRelated &&
                watchCoupon.redemptionMethod ===
                  'MERCHANT_APP_SCAN_COUPON_QR_CODE' &&
                !watchBrand.pk &&
                watchCoupon.brand))
          }
        />
      </div>
      <ReactHookFormErrorMessage errors={errors} id={'linkedBrand'} />
      {enableStoreEdit()}

      {language === LanguageConfig.english && (!id || storeEditMode) ? (
        <>
          <BaseMutipleSelectorV2
            // switchInfo={{
            //   showSwitch: isCouponRelated,
            //   checked: isFollowCouponTemplateStores,
            //   checkChange: () => {
            //     changeSwitch({
            //       getValues,
            //       setValue,
            //       key: 'isFollowCouponTemplateStores',
            //       value: !isFollowCouponTemplateStores,
            //     });
            //     if (!isFollowCouponTemplateStores) {
            //       setValue('linkedStores', brandStoreList);
            //     }
            //   },
            // }}
            // disabled={isCouponRelated}
            disabled={isCouponRelated}
            title={`${i18n.t('applicable_stores', { locale: 'en' })} ${
              !isCouponRelated ? i18n.t('optional', { locale: 'en' }) : ''
            }`}
            namespace="storeModel"
            data={{
              sourceData: brandStoreList,
              targetData: watchStores,
              targetChange: (value) => {
                setValue('linkedStores', value, { shouldDirty: true });
              },
            }}
            addButton={
              isCouponRelated
                ? null
                : {
                    action: () => {
                      setAddPromptConfig({
                        button: {
                          text: 'create store',
                          actionPath: '/stores/create',
                        },
                        title: 'Go to create store?',
                        description: `You will leave the campaign creation process. After you create a new store, you can back to this page.`,
                      });
                    },
                    title: 'Add store',
                    requires: PermissionCodes.addStore,
                    customClass:
                      'general-section-add-new btn-add-button-common',
                  }
            }
            error={{
              id: 'linkedStores',
              error: hasError(errors, 'linkedStores'),
              message: errorMessage(errors, 'linkedStores'),
            }}
          />
        </>
      ) : null}

      <BasePrompt
        show={!!addPromptConfig}
        closeAction={() => {
          setAddPromptConfig(null);
        }}
        rightButton={
          addPromptConfig
            ? {
                text: addPromptConfig?.button.text,
                action: () =>
                  history.push({
                    pathname: addPromptConfig?.button.actionPath,
                    state: { from: history.location, ...ContinueCreate },
                  }),
              }
            : null
        }
        title={addPromptConfig?.title}
        description={addPromptConfig?.description}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  appliableStoreList: state.storeModel.appliableStoreList || [],
  brandStoreList: state.storeModel.brandStoreList || [],
  storeEditMode: state.storeModel.storeEditMode,
  originalBrandPK: state.createCampaign?.campaign?.linkedBrand?.pk,
  originalLinkedStores: state.storeModel?.notPagedAllList,
});

export default connect(mapPropsToState)(GeneralSection);
