import React, { useState, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { GOODIE_BAG_REWARD_TYPE_OPTIONS } from '../../models/GoodieBagsModel';
import { hasError } from '../base/ErrorFieldMessage';
import NumberInput from './NumberInput';
import { Image } from 'react-bootstrap';
import '../base/CustomRadios.scss';
import CheckedRadio from '../../assets/images/checked_radio.svg';
import UncheckedRadio from '../../assets/images/unchecked_radio.svg';

function AssignRewardRadios(props) {
  const [selected, setSelected] = useState();
  const disabled = props.disabled || false;

  const assignRewardKey = props.assignRewardKey;
  const assignRewardIndex = props.assignRewardIndex;

  const { watch, setValue, formState, getValues, control } = useFormContext();
  const errors = formState.errors;
  const [assignRewardValue] = getValues([`assignReward.${assignRewardKey}`]);

  const options = ['limited', 'unlimited'];

  useEffect(() => {
    setSelected(
      assignRewardValue || assignRewardValue === 0 ? options[0] : options[1],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignRewardValue]);

  return (
    <div className="assign-reward-radio-container">
      <div
        className={`radio-button-container `}
        key={`radios-${assignRewardIndex}-div-1`}
        onClick={() => {
          if (disabled) {
            return;
          }
          if (options[0] === selected) {
            return;
          }
          setSelected(options[0]);
        }}
      >
        <Image
          src={selected === options[0] ? CheckedRadio : UncheckedRadio}
          className={`radio-button ${disabled ? 'radio-button-disabled' : ''}`}
        />
        <Controller
          control={control}
          name={`assignReward.${assignRewardKey}`}
          render={({ field: { value } }) => (
            <NumberInput
              disabled={disabled}
              labelText=""
              // type="Per link"
              type={`for Slot ${
                assignRewardIndex +
                1 +
                (props.rewardType === GOODIE_BAG_REWARD_TYPE_OPTIONS.both.value
                  ? Object.keys(props.slotCashCampaigns).length
                  : 0)
              }`}
              valueChange={(value) => {
                setValue(`assignReward.${assignRewardKey}`, value, {
                  shouldDirty: true,
                });
              }}
              defaultValue={value}
              errors={errors}
              onError={hasError(errors, `assignReward.${assignRewardKey}`)}
              errorFieldName={`assignReward.${assignRewardKey}`}
            />
          )}
        />
      </div>
      <div
        className={`radio-button-container `}
        key={`radios-${assignRewardIndex}-div-2`}
        onClick={() => {
          if (disabled) {
            return;
          }
          if (options[1] === selected) {
            return;
          }
          setSelected(options[1]);
          setValue(`assignReward.${assignRewardKey}`, 'unlimited', {
            shouldDirty: true,
          });
        }}
      >
        <Image
          src={selected === options[1] ? CheckedRadio : UncheckedRadio}
          className={`radio-button ${disabled ? 'radio-button-disabled' : ''}`}
        />
        <label
          className={`radio-button-label ${
            disabled ? 'radio-button-label-disabled' : ''
          } ${props.labelClass}`}
        >
          unlimited
        </label>
      </div>
    </div>
  );
}

function AssignedCreditQuotaPerEntitlementSection() {
  const { watch, setValue, formState, getValues, control } = useFormContext();
  const params = useParams();
  const errors = formState.errors;
  // const watchCredit = watch('assignCreditPerLink');
  // const watchQuota = watch('assignQuotaPerLink');

  const slotCashCampaigns = watch('slotCashCampaigns') || { newCash0: [] };
  const slotGiftCampaigns = watch('slotGiftCampaigns') || { newGift0: [] };

  const [isPublished, rewardType] = getValues(['isPublished', 'rewardType']);

  return (
    <>
      <label className="create-section-title">
        Assigned credit/quota per entitlement
      </label>
      <label className="section-short-description">
        Must assign either credit only / quota only or both for each goodie bag.
      </label>

      {rewardType !== GOODIE_BAG_REWARD_TYPE_OPTIONS.gift.value ? (
        <>
          <label className="create-section-label create-section-label-bottom-space">
            Credit
          </label>
          {Object.keys(slotCashCampaigns).map((key, index) => (
            <>
              {index > 0 ? <div style={{ height: '8px' }}></div> : null}
              <Controller
                control={control}
                name={`assignReward.${key}`}
                render={({ field: { value } }) => (
                  <NumberInput
                    disabled={isPublished && key.indexOf('new') !== 0}
                    labelText=""
                    // type="Per link"
                    type={`for Slot ${index + 1}`}
                    valueChange={(value) => {
                      setValue(`assignReward.${key}`, value, {
                        shouldDirty: true,
                      });
                    }}
                    defaultValue={value}
                    errors={errors}
                    onError={hasError(errors, `assignReward.${key}`)}
                    errorFieldName={`assignReward.${key}`}
                  />
                )}
              />
            </>
          ))}
        </>
      ) : (
        <></>
      )}

      {rewardType &&
      rewardType !== GOODIE_BAG_REWARD_TYPE_OPTIONS.cashVoucher.value ? (
        <>
          <label className="create-section-label create-section-label-bottom-space">
            Quota
          </label>
          {Object.keys(slotGiftCampaigns).map((key, index) => (
            <>
              {index > 0 ? <div style={{ height: '8px' }}></div> : null}
              <AssignRewardRadios
                disabled={isPublished && key.indexOf('new') !== 0}
                assignRewardKey={key}
                assignRewardIndex={index}
                rewardType={rewardType}
                slotCashCampaigns={slotCashCampaigns}
              />
              {/* <Controller
                control={control}
                name={`assignReward.${key}`}
                render={({ field: { value } }) => (
                  <NumberInput
                    disabled={isPublished && key.indexOf('new') !== 0}
                    labelText=""
                    // type="Per link"
                    type={`for Slot ${
                      index +
                      1 +
                      (rewardType === GOODIE_BAG_REWARD_TYPE_OPTIONS.both.value
                        ? Object.keys(slotCashCampaigns).length
                        : 0)
                    }`}
                    valueChange={(value) => {
                      setValue(`assignReward.${key}`, value, {
                        shouldDirty: true,
                      });
                    }}
                    defaultValue={value}
                    errors={errors}
                    onError={hasError(errors, `assignReward.${key}`)}
                    errorFieldName={`assignReward.${key}`}
                  />
                )}
              /> */}
            </>
          ))}
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default AssignedCreditQuotaPerEntitlementSection;
