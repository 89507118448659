import React, { useEffect } from 'react';
import ScrollableComponent from '../../../components/base/ScrollableComponent';
import CreatePointRecordStepOne from '../../../components/pointRecord/CreatePointRecordStepOne';
import CreatePointRecordStepTwo from '../../../components/pointRecord/CreatePointRecordStepTwo';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { useSelector, useDispatch } from 'react-redux';
import { createAction } from '../../../utils';
import { sessionDataKey } from '../../../models/CreatePointRecordModel';
import { useHistory } from 'react-router-dom';

function CreatePointRecord() {
  const { stepConfig, currentStep, pointRecordCreated } = useSelector(
    (state) => ({
      stepConfig: state.createPointRecord.stepConfig,
      currentStep: state.createPointRecord.currentStep,
      pointRecordCreated: state.createPointRecord.pointRecordCreated,
    }),
  );
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(createAction('createPointRecord/loadRecordFromCookie')());
    return () => {
      dispatch(createAction('createPointRecord/clearData')());
    };
  }, [dispatch]);
  const stepSet = [<CreatePointRecordStepOne />, <CreatePointRecordStepTwo />];
  const caution = {
    detail: '', //Create point record
    title: 'Update Point record ',
  };
  return (
    <div class="customer">
      <ScrollableComponent
        tempSave={(save) => {
          dispatch({
            type: 'createPointRecord/saveOrRemoveRecordFromCookie',
            payload: save,
          });
        }}
        showFinishPop={pointRecordCreated}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: 'Point records is successfully created.',
          steps: null,
          buttons: [
            {
              text: 'Back to point records list',
              action: () =>
                history.push({
                  pathname: '/point_records',
                }),
            },
          ],
        }}
        sessionDataKey={sessionDataKey}
        stepsConfig={stepConfig}
        content={stepSet}
        currentStep={currentStep}
        breadcrumb={<CustomBreadcrumb />}
        caution={caution}
      />
    </div>
  );
}

export default CreatePointRecord;
