import { BaseHelper } from './BaseGraphQLAPIHelper';

export const getGoodieBagGroupList = (afterCursor, others) => {
  let filter = '';
  const { filterName, sort, search, goodie_bag_in } = others || {};
  if (search) {
    filter = `, search:"${search}"`;
  }
  if (filterName) {
    filter = `, nameIcontains:"${filterName}"`;
  }
  if (sort) {
    filter += `, orderBy: "${sort}"`;
  } else {
    filter += `, orderBy: "-pk"`;
  }
  if (goodie_bag_in) {
    filter += `, goodieBagIn: "${goodie_bag_in}"`;
  }
  const query = `{
      goodieBagGroups(first: ${others.pageSize || 20}, after:"${afterCursor}"${filter}) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            pk
            name
            perHeadLimit
            alertMessageOfExceedLimit
            translations(first: 10) {
              edges {
                node {
                  pk
                  language
                  alertMessageOfExceedLimit
                }
              }
            }
            goodieBags(first: 10) {
              edges {
                node {
                  pk
                  name
                }
              }
            }
          }
        }
      }
    }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getGoodieBagGroup = (goodieBagGroupID) => {
  const query = `
    {
      goodieBagGroup(id: "${goodieBagGroupID}") {
        id
        pk
        name
        perHeadLimit
        alertMessageOfExceedLimit
        translations(first: 10) {
          edges {
            node {
              pk
              language
              alertMessageOfExceedLimit
            }
          }
        }
        goodieBags(first: 10) {
          edges {
            node {
              pk
              name
            }
          }
        }
      }
    }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createGoodieBagGroup = (inputBody) => {
  const query = `
  mutation CreateGoodieBagGroup($input: CreateGoodieBagGroupInput!) {
    createGoodieBagGroup(input: $input) {
      success
      node {
        id
        pk
        name
        perHeadLimit
        alertMessageOfExceedLimit
        translations(first: 10) {
          edges {
            node {
              language
              alertMessageOfExceedLimit
            }
          }
        }
        goodieBags(first: 10) {
          edges {
            node {
              pk
              name
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateGoodieBagGroup = (inputBody) => {
  const query = `
  mutation UpdateGoodieBagGroup($input: UpdateGoodieBagGroupInput!) {
    updateGoodieBagGroup(input: $input) {
      success
      node {
        id
        pk
        name
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteGoodieBagGroups = (ids) => {
  const query = `
  mutation DeleteGoodieBagGroups($input: DeleteGoodieBagGroupsInput!) {
    deleteGoodieBagGroups(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
