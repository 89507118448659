import React from 'react';
import './CouponContentSection.scss';
import BaseMarkDownArea from '../../base/BaseMarkDownArea';
import { useFormContext } from 'react-hook-form';
import { insertKeyToTranslations } from '../../../utils';

const CouponContentSection = ({
  language,
  areaTitle,
  titleName,
  titleKey,
  contentName,
  contentKey,
}) => {
  const { watch, setValue, getValues } = useFormContext();
  const translations = watch('translations') || {};
  const titleValue = translations?.[language]?.[titleKey];
  const contentValue = translations?.[language]?.[contentKey];

  return (
    <>
      <label className="create-section-title">{areaTitle}</label>
      <BaseMarkDownArea
        title={{
          titleKey: `translations.${language}.${titleKey}`,
          titleName: titleName,
          titleValue: titleValue,
          // titleExtra: { value: titleValue },
        }}
        setTitleValue={(data) => {
          insertKeyToTranslations({
            language,
            key: titleKey,
            value: data,
            setValue,
            getValues,
          })
        }}
        content={{
          contentKey: `translations.${language}.${contentKey}`,
          contentName: contentName,
          contentValue: contentValue,
        }}
        setContentValue={(data) => {
          insertKeyToTranslations({
            language,
            key: contentKey,
            value: data,
            setValue,
            getValues,
          })
        }}
      />
    </>
  );
};

export default CouponContentSection;
