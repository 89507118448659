import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import i18n from '../../I18n';
import {
  isShowError,
  firstError,
} from '../campaign/campaignCreation/CreateCampaignHandleError';
import { EarningRuleErrorHandleField } from './EarningRuleHandleError';
import '../base/CustomMarkDownArea.scss';
import ErrorFieldMessage from '../base/ErrorFieldMessage';

import { isLongScreen } from '../../utils/ScreenUtil';
import { useCompare } from '../../utils';

function OverviewSection(props) {
  const focusChange = props.focusChange;
  const language = props.language;
  const onSubmit = props.onSubmit;
  const areaTitle = props.areaTitle;
  const model = props.model;
  const onSubmitAction = props.onSubmitAction || (() => {});
  const defaultValue = props.defaultValue;

  const { errorFields } = useSelector((state) => ({
    errorFields: state[model].errorFields,
  }));

  const defaultNameValue = () => {
    const tempEarningRule = defaultValue;

    if (tempEarningRule) {
      return tempEarningRule['generalName'];
    }
    return '';
  };

  const [generalName, setGeneralName] = useState(defaultNameValue());

  const isError = (name) => {
    const error = isShowError(
      EarningRuleErrorHandleField[name].name,
      errorFields,
      language,
    );
    return error;
  };

  useEffect(() => {
    setGeneralName(defaultNameValue());
  }, [props.defaultValue]);

  const dispatch = useDispatch();
  const vals = { generalName };
  const hasValChanged = useCompare(vals);
  useEffect(() => {
    if (hasValChanged) {
      dispatch({
        type: `${model}/changeVals`,
        payload: { vals, language },
      });
    }
  });

  useEffect(() => {
    if (onSubmit) {
      onSubmitAction(vals);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmit]);

  useEffect(() => {
    firstError(
      `${EarningRuleErrorHandleField.generalName.name}-${language}`,
      errorFields,
      language,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorFields]);

  return (
    <>
      {areaTitle ? (
        <label className="create-section-title">
          {i18n.t(areaTitle, { locale: language })}
        </label>
      ) : null}

      <label
        className={
          areaTitle
            ? 'create-section-label create-section-label-bottom-space'
            : 'create-section-label'
        }
      >
        {i18n.t('name', { locale: language })}
      </label>
      {language === 'en' ? (
        <>
          <input
            type="text"
            name="generalName"
            disabled={language !== 'en'}
            onChange={({ target }) => setGeneralName(target.value)}
            defaultValue={generalName || ''}
            className={`custom-markdown-area-title ${
              isError('generalName') ? 'error-field' : ''
            } ${isLongScreen ? '' : 'custom-markdown-area-title-short'}`}
            onFocus={() => focusChange('name')}
          />
          <ErrorFieldMessage
            id={`${EarningRuleErrorHandleField.generalName.name}-${language}`}
            error={isError('generalName')}
            message={`${EarningRuleErrorHandleField.generalName.message}`}
          />
        </>
      ) : (
        <label className="custom-record-normal-font custom-timeline-time-color">
          {generalName}
        </label>
      )}
    </>
  );
}

export default OverviewSection;
