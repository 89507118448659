import { SavingStatus } from '../config/CustomEnums';

export default {
  namespace: 'settings',
  state: {
  },

  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
  },
};
