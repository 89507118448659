import React from 'react';
import { useFormContext } from 'react-hook-form';
import ContentSections from '../../../components/base/ContentSections';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import { useDispatch } from 'react-redux';
import { saveDataToSessionStorage } from '../../../models/StoreModel';
import { useHistory, useParams } from 'react-router-dom';
import PropertyGeneralSection from './PropertyGeneralSection';
import StoreQRCodeSection from './StoreQRCodeSection';
import { SavedStatus } from '../../../config/CustomEnums';
import { useSelector } from 'react-redux';
import Loading from '../../../components/base/Loading';

const getSections = (history) => {
  const sections = [<PropertyGeneralSection />];
  if (history.location.pathname.includes('edit')) {
    sections.push(<StoreQRCodeSection />);
  }
  return sections;
};

export default function CreateStoreStepTwo() {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;
  const { getValues, trigger } = useFormContext();
  const sections = getSections(history);
  const { saved } = useSelector((state) => ({ saved: state.storeModel.saved }));

  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <SaveAndBackButtons
        saveText={id ? 'Update' : 'Save'}
        saveAction={async () => {
          if (saved === SavedStatus.saving) {
            return;
          }
          const isValid = await trigger(['displayPriority', 'attributeTags']);
          const storeData = getValues();

          if (!id) {
            delete storeData.pk;
          }
          if (isValid) {
            dispatch({
              type: 'storeModel/createOrUpdate',
              payload: { data: storeData },
            });
          }
        }}
        backAction={() => {
          if (saved === SavedStatus.saving) {
            return;
          }
          dispatch({
            type: 'storeModel/stepChange',
            payload: { currentStep: 1, isValid: true, isBack: true },
          });
          saveDataToSessionStorage(getValues());
        }}
        save={{ disabled: saved === SavedStatus.saving }}
      />
    </>
  );
}
