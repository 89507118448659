import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import { ADMIN_ACTIVITY_TYPE } from '../../../models/AdminActivityLogModel';
import { useSelector, useDispatch } from 'react-redux';
import { ArrayStringData } from '../../../utils/index';
import { AdminActivityType } from '../../../config/CustomEnums';

function Filter({ backAction = () => {} }) {
  const location = useLocation();
  const dispatch = useDispatch();

  const { adminGroupList, adminList } = useSelector((state) => ({
    adminGroupList: state.adminGroup.notPagedAllList,
    adminList: state.admin.notPagedAllList,
  }));

  const parsedSearch = queryString.parse(location.search);
  const searchCreateAt = parsedSearch['record_period'] || '';
  const searchActivityType = parsedSearch['type'] || '';
  const searchAdminGroup = parsedSearch['administrators_group'] || '';
  const searchAdmin = parsedSearch['user'] || '';

  const [createAt, setCreateAt] = useState(searchCreateAt);
  const [activityType, setActivityType] = useState(
    ArrayStringData(searchActivityType),
  );
  const [adminGroupData, setAdminGroupData] = useState(null);
  const [adminGroup, setAdminGroup] = useState(
    ArrayStringData(searchAdminGroup),
  );

  const [adminData, setAdminData] = useState(null);
  const [admin, setAdmin] = useState(ArrayStringData(searchAdmin));

  useEffect(() => {
    dispatch({
      type: 'adminGroup/getAllList',
      payload: {
        isAll: true,
      },
    });
    dispatch({
      type: 'admin/getAllList',
      payload: {
        isAll: true,
      },
    });
  }, []);

  useEffect(() => {
    setAdminGroupData(adminGroupList);
  }, [adminGroupList]);

  useEffect(() => {
    setAdminData(adminList);
  }, [adminList]);

  const ActivityLogActions = Object.values(AdminActivityType)

  const [activityLogActionData, setActivityLogActionData] =
    useState(ActivityLogActions);
  const content = [
    {
      title: 'Record period',
      value: createAt,
      setValue: setCreateAt,
      componment: FilterTypeEnum.dateRange,
    },
    {
      displayTitle: 'Action',
      title: 'type',
      data: activityLogActionData,
      value: activityType,
      setValue: setActivityType,
      componment: FilterTypeEnum.searchableChoice,
      placeholder: 'Search by name',
      filterAction: (searchKey) => {
        const newData = ActivityLogActions.filter((item) => {
          const name = item.name?.toLowerCase() || '';
          return (
            name.includes(searchKey?.toLowerCase() || '') ||
            String(item.pk) === searchKey
          );
        });
        setActivityLogActionData(newData);
      },
    },
    {
      title: 'Administrators group',
      data: adminGroupData ? adminGroupData : adminGroupList,
      value: adminGroup,
      setValue: setAdminGroup,
      placeholder: 'Search by ID, Name',
      componment: FilterTypeEnum.searchableChoice,
      renderDisplayName: (item) => `[ID:${item.pk}] ${item.name}`,
      filterAction: (searchKey) => {
        const newData = adminGroupList?.filter((item) => {
          const name = item.name?.toLowerCase() || '';
          return (
            name.includes(searchKey?.toLowerCase() || '') ||
            String(item.pk) === searchKey
          );
        });
        setAdminGroupData(newData);
      },
    },
    {
      title: 'User',
      data: adminData ? adminData : adminList,
      value: admin,
      setValue: setAdmin,
      placeholder: 'Search by ID, Name',
      componment: FilterTypeEnum.searchableChoice,
      renderDisplayName: (item) => `[ID:${item.pk}] ${item.username}`,
      filterAction: (searchKey) => {
        const newData = adminList?.filter((item) => {
          const name = item.username?.toLowerCase() || '';
          return (
            name.includes(searchKey?.toLowerCase() || '') ||
            String(item.pk) === searchKey
          );
        });
        setAdminData(newData);
      },
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

export default Filter;
