import React from 'react';
import './DeleteCampaignPrompt.scss';
import { useSelector } from 'react-redux';
import {
  LanguageConfig,
  DELETE_RELATED_SECTIONS,
} from '../../../config/CustomEnums';
import BasePrompt from '../../base/prompt/BasePrompt';
import { deleteMessage } from '../../base/DeletePrompt';

function DeleteCampaignPrompt(props) {
  const onClose = props.onClose || (() => {});
  const onConfirm = props.onConfirm || (() => {});
  const campaign = props.campaign;
  const { checkedList, campaignList } = useSelector((state) => ({
    checkedList: state.campaignList.checkedList,
    campaignList: state.campaignList.pagedList,
  }));

  const getLanguageDisplayTraditionalChinese = (translation, campaignName) => {
    if (!translation) {
      return null;
    }
    return !translation[LanguageConfig.traditionalChinese] ? null : (
      <li className="delete-campaign-pop-content-text">
        Campaign translation: {campaignName} translation in Traditional Chinese
      </li>
    );
  };

  const getLanguageDisplaySimplifiedChinese = (translation, campaignName) => {
    if (!translation) {
      return null;
    }
    return !translation[LanguageConfig.simplifiedChinese] ? null : (
      <li className="delete-campaign-pop-content-text">
        Campaign translation: {campaignName} translation in Simplified Chinese
      </li>
    );
  };

  const getDisplay = () => {
    let ids = checkedList?.map((campaign) => campaign.pk);
    if (campaign) {
      ids = [campaign.pk];
    }
    const deleteCampaigns = campaignList.filter(
      (campaign) => ids.indexOf(campaign.pk) !== -1,
    );
    return (
      <div className="delete-campaign-pop-content">
        {deleteCampaigns.map((campaign, index) => {
          const campaignName = campaign.name;
          const campaignBrand = campaign.brand;
          const translations = campaign.translations;
          return (
            <ul className="delete-campaign-pop-content-text" key={index}>
              <li className="delete-campaign-pop-content-text">
                Campaign: {campaignName}
                <ul className="delete-campaign-pop-content-text">
                  {!campaignBrand ? null : (
                    <li className="delete-campaign-pop-content-text">
                      Campaign brand section: {campaignName} campaign's brand
                      section
                    </li>
                  )}
                  {getLanguageDisplayTraditionalChinese(
                    translations,
                    campaignName,
                  )}
                  {getLanguageDisplaySimplifiedChinese(
                    translations,
                    campaignName,
                  )}
                </ul>
              </li>
            </ul>
          );
        })}
      </div>
    );
  };

  const getDescription = () => {
    let ids = checkedList?.map((campaign) => campaign.pk);
    if (campaign) {
      ids = [campaign.pk];
    }

    let deleteCampaigns = [];
    campaignList.map((campaign) => {
      if (ids.indexOf(campaign.pk) !== -1) {
        deleteCampaigns.push(campaign.name);
      }
    });

    return deleteMessage(
      deleteCampaigns,
      DELETE_RELATED_SECTIONS.CAMPAIGN,
      'Campaign',
    );
  };

  return (
    <BasePrompt
      show={props.show}
      closeAction={onClose}
      rightButton={{
        text: 'Delete',
        action: onConfirm,
      }}
      leftButton={{
        text: 'Cancel',
        action: onClose,
      }}
      title={'Delete the Campaign'}
      // description={`You will not be able to recover it. All of the following objects and
      // their related items will be deleted:`}
      // otherBody={getDisplay}
      description={getDescription()}
    />
  );
}

export default DeleteCampaignPrompt;
