import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { createAction } from '../../../utils';
import {
  DELETE_RELATED_SECTIONS,
  SavedStatus,
  ADMIN_TYPE,
  APIStatus,
} from '../../../config/CustomEnums';
import BaseForm from '../../../components/base/v2/BaseForm';
import CreateWorkingTeamContent from './CreateWorkingTeamContent';

function CreateWorkingTeam({
  workingTeam,
  apiStatus,
  formHasSubmitted,
  hasUpdatedDefaultValues,
  createStatus,
  createdNew,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    return () => {
      dispatch(createAction('workingTeam/clearData')());
    };
  }, [dispatch]);

  //   useEffect(() => {
  //     console.log('@@27: ', saved);
  //     if (
  //       saved === SavedStatus.savedWithSuccess &&
  //       history.location.pathname.includes('edit')
  //     ) {
  //       history.push('/working_teams');
  //     }
  //   }, [saved, history]);

  useEffect(() => {
    if (formHasSubmitted && apiStatus === APIStatus.success) {
      history.push({ pathname: '/working_teams' });
    }
  }, [formHasSubmitted, apiStatus, history]);

  useEffect(() => {
    const id = params.id;
    if (id) {
      dispatch(createAction('workingTeam/getOneDetail')({ id }));
    } else {
      dispatch({
        type: 'workingTeam/loadDataFromSessionAndSession',
      });
    }
    dispatch({
      type: 'admin/getAllList',
      payload: {
        grade: 'false',
        status: 'true',
      },
    });
  }, [dispatch, params?.id]);

  const stepSet = [<CreateWorkingTeamContent />];

  return (
    <div className="customer">
      <BaseForm
        defaultValues={workingTeam}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={formHasSubmitted}
        tempSave={(save, getValues) => {
          if (save) {
            dispatch({
              type: 'workingTeam/saveAdminGroupToSessionStorage',
              payload: { values: getValues() },
            });
          } else {
            dispatch({
              type: 'workingTeam/removeAdminGroupFromSessionStorage',
            });
          }
        }}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: 'Working team is successfully created.',
          steps: null,
          buttons: [
            {
              text: 'Back to working team list',
              action: () =>
                history.push({
                  pathname: '/working_teams',
                }),
            },
          ],
        }}
        continueCreateConfig={{
          onConfirm: () => {
            history.push({
              ...history.location.state?.from,
              state: {
                newWorkingTeam: createdNew,
              },
            });
          },
          onCancel: () => history.push({ pathname: '/working_teams' }),
          onClose: () => history.push({ pathname: '/working_teams' }),
        }}
        showFinishPop={formHasSubmitted && createStatus === APIStatus.success}
        content={stepSet}
        currentStep={0}
        breadcrumb={
          <CustomBreadcrumb
            name={params.id ? workingTeam.name : 'Create working team'}
          />
        }
        caution={{
          detail: '',
          title: params.id ? `Edit ${workingTeam.name}` : 'Create working team',
        }}
      />
    </div>
  );
}

const mapPropsToState = (state) => ({
  workingTeam: state.workingTeam.detail || {},
  apiStatus: state.workingTeam.apiStatus,
  hasUpdatedDefaultValues: state.workingTeam.hasUpdatedDefaultValues,
  formHasSubmitted: state.workingTeam.formHasSubmitted,
  createStatus: state.workingTeam.createStatus,
  createdNew: state.workingTeam.createdNew,
});

export default connect(mapPropsToState)(CreateWorkingTeam);
