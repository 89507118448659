import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { LanguageConfig, APIStatus } from '../../../config/CustomEnums';
import { createAction } from '../../../utils';
import './CreateBanner.scss';
import { PermissionCodes } from '../../../config/PermissionCodes';
import CreateBannerStepOne from './CreateBannerStepOne';
import BaseForm from '../../../components/base/v2/BaseForm';

export const ContentType = {
  Campaign: 'Campaign',
  Product: 'Product',
};

const CreateBanner = ({ banner, createStatus, hasUpdatedDefaultValues }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const bannerId = params.id;
    if (bannerId) {
      dispatch(createAction('bannerList/getOneBanner')({ id: bannerId }));
    } else {
      dispatch({
        type: 'bannerList/loadFromSessionStorage',
      });
    }
  }, [dispatch, params.id]);

  useEffect(() => {
    return () => {
      dispatch({ type: 'bannerList/clearData' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (createStatus === APIStatus.success && params.id) {
      history.push('/banners?sort=displayPriority');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStatus]);

  const stepContent = [<CreateBannerStepOne />];

  return (
    <div className="create-banner">
      <BaseForm
        defaultValues={banner}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={createStatus === APIStatus.success}
        tempSave={(save, getValues) => {
          if (save) {
            dispatch({
              type: 'bannerList/saveBannerToSessionStorage',
              payload: { save, values: getValues() },
            });
          } else {
            dispatch({
              type: 'bannerList/removeBannerFromSessionStorage',
            });
          }
        }}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: 'Banner is successfully created.',
          steps: null,
          buttons: [
            {
              text: 'Back to Banner list',
              action: () => history.push('/banners?sort=displayPriority'),
              requires: PermissionCodes.changeBanner,
            },
          ],
        }}
        showFinishPop={createStatus === APIStatus.success}
        content={stepContent}
        currentStep={0}
        breadcrumb={
          <CustomBreadcrumb name={`${params.id ? 'Edit' : 'Create'} Banner`} />
        }
        caution={{
          detail: '',
          title: `${params.id ? 'Edit' : 'Create'} Banner`,
        }}
      />
    </div>
  );
};

const mapPropsToState = (state) => ({
  banner: state.bannerList.banner,
  createStatus: state.bannerList.createStatus,
  hasUpdatedDefaultValues: state.bannerList.hasUpdatedDefaultValues,
});

export default connect(mapPropsToState)(CreateBanner);
