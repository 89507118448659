import React, { useEffect, useState } from 'react';
import './MessageList.scss';
import CustomListComponent from '../../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { useHistory, useLocation } from 'react-router-dom';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import { useSelector, useDispatch } from 'react-redux';
import BaseListContainer from '../../base/BaseListContainer';
import { createAction } from '../../../utils';
import DeletePrompt from '../../../components/base/DeletePrompt';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { DELETE_RELATED_SECTIONS } from '../../../config/CustomEnums';
import Filter from './GeneralAlertFilter';
import ScheduledFailedPrompt from '../../../components/message/ScheduledFailedPrompt';

function GeneralAlertList() {
  const queryString = require('query-string');
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [showScheduledFailedPrompt, setShowScheduledFailedPrompt] =
    useState(false);
  const [scheduledData, setScheduledData] = useState([]);
  const [selecteditem, setSelectedItem] = useState({});
  const displayFields = [
    { displayName: 'ID', fieldName: 'pk' },
    { displayName: 'Content', fieldName: 'title', linked: true }, //, isHtml: true
    { displayName: 'Severity level', fieldName: 'severityLevel' },
    {
      displayName: 'Order',
      fieldName: 'displayPriority',
      orderField: 'displayPriority',
    },
    {
      displayName: 'Delivery Time',
      fieldName: 'displayDeliveryDate',
      orderField: 'startDate',
      style: { width: '265px' },
    },
    { displayName: 'Scheduled Status', fieldName: 'scheduledStatus' },
    { displayName: 'Status', fieldName: 'displayStatus' },
  ];

  const { pageInfo, totalCount, generalAlertList, totalPage, checkedList } =
    useSelector((state) => ({
      pageInfo: state.generalAlert.pageInfo,
      totalCount: state.generalAlert.totalCount,
      generalAlertList: state.generalAlert.pagedList,
      totalPage: state.generalAlert.totalPage,
      checkedList: state.generalAlert.checkedList,
    }));

  useEffect(() => {
    const searchDict = queryString.parse(location.search);
    const searchKey = searchDict['search'];
    const stringRank = searchDict['rank'] || 'true';
    const stringPage = searchDict['page'] || 1;
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);
    dispatch(
      createAction('generalAlert/getList')({
        ...searchDict,
        page,
        rank,
        filterName: searchKey,
      }),
    );
  }, [dispatch, location, queryString]);

  const buttons = [
    <AuthButton
      title="Create General Alert"
      action={() => {
        history.push('/general_alert/create');
      }}
      requires={PermissionCodes.addMessage}
    />,
  ];

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.deleteMessage,
    },
  ];

  const updateOrder = (pk, order) => {
    const data = {};
    if (!pk) {
      return;
    }
    data.id = pk;
    console.log('@@98', order);
    if (order) {
      data.displayPriority = order;
    }
    dispatch({
      type: 'generalAlert/updateOrder',
      payload: {
        data,
        afterAction: () => history.push(location),
      },
    });
  };

  const tabs = [
    {
      name: '',
      content: (
        <BaseTabListContainer
          hideTab={true}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount ? totalCount : 0}
          filter={{ hasFilter: true, componment: Filter }}
          listContent={
            <BaseListContainer
              sortable={true}
              fields={displayFields}
              model={'generalAlert'}
              permissionGroup={PermissionCodes.message}
              dataList={generalAlertList}
              totalPage={totalPage}
              deleteInfo={{
                data: [],
                title: 'General Alert',
                relatedName: 'channels',
                onComfirm: {},
                // relatedSections: DELETE_RELATED_SECTIONS.GENERAL_ALERT,
              }}
              actions={['Edit', 'Detail', 'Delete']}
              hasOtherActions={true}
              customActions={{
                schedule: (item) => {
                  setSelectedItem(item);
                  dispatch(
                    createAction('generalAlert/DeactiveOrAcitve')({
                      id: item.pk,
                      // isPublished: item.isActive ? false : true,
                      isActive: !item.isActive,
                      afterActions: (data) => {
                        const generalAlert = data.updateGeneralAlert;
                        if (generalAlert && generalAlert.generalAlerts) {
                          dispatch(
                            createAction('generalAlert/updateState')({
                              detail: item,
                            }),
                          );

                          setScheduledData(generalAlert.generalAlerts);
                          setShowScheduledFailedPrompt(true);
                        } else {
                          dispatch(
                            createAction('generalAlert/updateScheduleState')({
                              data: generalAlert.node,
                            }),
                          );
                        }
                      },
                    }),
                  );
                },
                moveToFirst: (item) => {
                  updateOrder(item.pk, 1);
                },
                moveToLast: (item) => {
                  updateOrder(item.pk, totalCount);
                },
                require: PermissionCodes.changeMessage,
              }}
              onSortEnd={(oldIndex, newIndex) => {
                if (oldIndex === newIndex) return;
                const startOrder = generalAlertList[0].displayPriority;
                const movedItem = generalAlertList[oldIndex];
                updateOrder(movedItem?.pk, startOrder + newIndex);
              }}
              onOrderChangedByEdit={(pk, newOrder) => {
                updateOrder(pk, newOrder);
              }}
            />
          }
        />
      ),
    },
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail: '',
          title: 'General Alert',
        }}
        buttons={buttons}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={true}
        tabs={tabs}
      />
      <DeletePrompt
        relatedSections={DELETE_RELATED_SECTIONS.GENERAL_ALERT}
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch(
            createAction('generalAlert/delete')({
              afterAction: () => history.push(location),
            }),
          );
        }}
        data={checkedList}
        title={'General Alert'}
        relatedName={'channels'}
      />
      <ScheduledFailedPrompt
        data={scheduledData}
        selectedItem={selecteditem}
        show={showScheduledFailedPrompt}
        onClose={() => setShowScheduledFailedPrompt(false)}
        onChangeItem={(item) => {
          history.push({
            pathname: `/general_alert/${item.pk}/edit`,
          });
        }}
        saveButtonAction={(item, afterAction = () => {}) => {
          dispatch(
            createAction('generalAlert/DeactiveOrAcitve')({
              id: item.pk,
              // isPublished: false,
              isActive: !item.isActive,
              afterActions: (data) => {
                dispatch(
                  createAction('generalAlert/updateScheduleState')({
                    data: data.updateGeneralAlert?.node,
                  }),
                );

                afterAction();
              },
            }),
          );
        }}
      />
    </>
  );
}

export default GeneralAlertList;
