import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import dva from './Dva';
import NavBarsModel from './models/NavBarModel';
import UsersModel from './models/UsersModel';
import CreateCampaignModel from './models/CreateCampaignModel';
import CreateCouponModel from './models/CreateCouponModel';
import SettingModel from './models/SettingModel';
import UploadFilesModel from './models/UploadFilesModel';
import BrandModel from './models/BrandModel';
import StoreModel from './models/StoreModel';
import CouponListModel from './models/CouponListModel';
import SingleCouponModel from './models/SingleCouponModel';
import CustomerListModel from './models/CustomerListModel';
import CampaignListModel from './models/CampaignListModel';
import GoodieBagsModel from './models/GoodieBagsModel';
import DashboardModel from './models/DashboardModel';
import EarningRulesModel from './models/EarningRulesModel';
import CreateEarningRulesModel from './models/CreateEarningRulesModel';
import LoadingModel from './models/LoadingModel';
import SegmentModel from './models/SegmentModel';
import LevelModel from './models/LevelModel';
import DownloadAndImport from './models/DownloadImportModel';
import CouponTransactionModel from './models/CouponTransactionModel';
import CreatePointRecordModel from './models/CreatePointRecordModel';
import PointTransactionListModel from './models/PointTransactionListModel';
import TransactionModel from './models/TransactionModel';
import CreateTransactionModel from './models/CreateTransactionModel';
import AdminModel from './models/AdminModel';
import AdminGroupModel from './models/AdminGroupModel';
import CustomerGroupModel from './models/CustomerGroupModel';
import MessageListModel from './models/MessageListModel';
import CampaignCategoryListModel from './models/CampaignCategoryListModel';
import CreateCampaignCategoryModel from './models/CreateCampaignCategoryModel';
import CreateMessageModel from './models/CreateMessageModel';
import { persistStore, persistReducer } from 'redux-persist';
import { CookieStorage } from 'redux-persist-cookie-storage';
import { createFilter } from 'redux-persist-transform-filter';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { PersistGate } from 'redux-persist/integration/react';
import Cookies from 'cookies-js';
import OverviewModel from './models/OverviewModel';
import StoreCategoryModel from './models/StoreCategoryModel';
import CreateStoreCategoryModel from './models/CreateStoreCategoryModel';
import HomeBannersModel from './models/HomeBannersModel';
import HomeFeaturedModel from './models/HomeFeaturedModel';
import TargetMarketModel from './models/TargetMarketModel';
import LogoLabelModel from './models/LogoLabelModel';
import OfferTypeLabelModel from './models/OfferTypeLabelModel';
import AttributeTagModel from './models/AttributeTagModel';
import ChannelModel from './models/ChannelModel';
import LanguageModel from './models/LanguageModel';
import SurveyForm from './models/SurveyFormModel';
import AttributeTagKeyModel from './models/AttributeTagKeyModel';
import TourCardModel from './models/TourCardModel';
import TermsAndConditionsModel from './models/TermsAndConditionsModel';
import PaymentSettlementListModel from './models/PaymentSettlementListModel';
import InterestPreferenceModel from './models/InterestPreferenceModel';
import GeneralAlertModel from './models/GeneralAlertModel';
import FortuneBagEntitlementModel from './models/FortuneBagEntitlementModel';
import EntitlementTrackingCodeModel from './models/EntitlementTrackingCodeModel';
import DashboardCustomerModel from './models/DashboardCustomerModel';
import OTPChannelModel from './models/OTPChannelModel';
import SimpleDashboardModel from './models/SimpleDashboard';
import WorkingTeamModel from './models/WorkingTeamModel';
import AdminActivityLogModel from './models/AdminActivityLogModel';
import RateLimitModel from './models/RateLimitModel';
import CountryCodeModel from './models/CountryCodeModel';
import GoodieBagGroupModel from './models/GoodieBagGroupModel';
import CampaignGroupModel from './models/CampaignGroupModel';
import CouponBatchModel from './models/CouponBatchModel';
import PhysicalCouponModel from './models/PhysicalCouponModel';
import OtpCodeModel from './models/OTPCodeModel';
import SystemSettingModel from './models/SystemSettingModel';
import SearchSettingModel from './models/SearchSettingModel';
import CouponLabelModel from './models/CouponLabelModel';

const midwares = [];
const usersFilter = createFilter('users', [
  'isLogin',
  'username',
  'isSuperuser',
  'firstLogin',
  'adminType',
  'workingTeams',
]);

const persistConfig = {
  key: 'root',
  storage: new CookieStorage(Cookies),
  whitelist: ['users'],
  stateReconciler: autoMergeLevel2,
  transforms: [usersFilter],
};

const app = dva({
  initialState: {},
  models: [
    NavBarsModel,
    UsersModel,
    CampaignListModel,
    CreateCampaignModel,
    CampaignGroupModel,
    GoodieBagsModel,
    GoodieBagGroupModel,
    CouponListModel,
    SingleCouponModel,
    CreateCouponModel,
    EarningRulesModel,
    SettingModel,
    UploadFilesModel,
    BrandModel,
    StoreModel,
    CustomerListModel,
    LoadingModel,
    CreateEarningRulesModel,
    SegmentModel,
    LevelModel,
    DownloadAndImport,
    CouponTransactionModel,
    CreatePointRecordModel,
    PointTransactionListModel,
    DashboardModel,
    OverviewModel,
    TransactionModel,
    CreateTransactionModel,
    AdminModel,
    AdminGroupModel,
    CustomerGroupModel,
    MessageListModel,
    CampaignCategoryListModel,
    CreateCampaignCategoryModel,
    CreateMessageModel,
    StoreCategoryModel,
    CreateStoreCategoryModel,
    HomeBannersModel,
    HomeFeaturedModel,
    TargetMarketModel,
    LogoLabelModel,
    OfferTypeLabelModel,
    AttributeTagModel,
    ChannelModel,
    LanguageModel,
    SurveyForm,
    AttributeTagKeyModel,
    TourCardModel,
    TermsAndConditionsModel,
    PaymentSettlementListModel,
    InterestPreferenceModel,
    GeneralAlertModel,
    FortuneBagEntitlementModel,
    EntitlementTrackingCodeModel,
    DashboardCustomerModel,
    OTPChannelModel,
    SimpleDashboardModel,
    WorkingTeamModel,
    AdminActivityLogModel,
    RateLimitModel,
    CountryCodeModel,
    CouponBatchModel,
    PhysicalCouponModel,
    OtpCodeModel,
    SystemSettingModel,
    SearchSettingModel,
    CouponLabelModel,
  ],
  onReducer: (rootReducer) => persistReducer(persistConfig, rootReducer),
  onAction: midwares,
  onError(e) {
    console.log('onError', e);
  },
});

const persistor = persistStore(app.getStore(), null, () => {
  app.getStore().dispatch({ type: 'users/startUp' });
});

const DvaApp = app.start(<App />);
ReactDOM.render(
  <React.StrictMode>
    <PersistGate loading={null} persistor={persistor}>
      <DvaApp />
    </PersistGate>
  </React.StrictMode>,
  document.getElementById('root'),
);
serviceWorker.unregister();
