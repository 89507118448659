import React, { useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import OverViewContent from '../../../components/brand/OverViewContent';
import { useParams, useHistory } from 'react-router-dom';
import { PermissionCodes } from '../../../config/PermissionCodes';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { createAction } from '../../../utils';
import { LanguageConfig } from '../../../config/CustomEnums';
import AuthButton from '../../../components/base/AuthButton';
import ContentSections from '../../../components/base/ContentSections';
import CustomListComponent from '../../../components/base/CustomListComponent';

function BrandOverView({
  selectedBrand,
  languages,
}) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
    dispatch(
      createAction('brand/getBrand')({
        brandPK: id,
      }),
    );
  }, [id]);

  const getLanguageTab = (language) => {
    return <OverViewContent language={language} />;
  };

  const buttons = [
    <AuthButton
      title="Edit"
      action={() => {
        history.push(`/brands/${id}/edit`);
      }}
      requires={PermissionCodes.changeBrand}
    />,
  ];
  const tabs = [
    {
      name: '',
      content: (
        <div>
          <ContentSections 
            languageTabContent={{
              containers: languages?.map((item) => ({
                container: [getLanguageTab(item.code)],
                key: item.code,
                title: item.sourceName,
              })),
            }}
            hidePreview 
          />
        </div>
      ),
    },
  ];
  return (
    <div className="campaign-category-detail">
      <CustomListComponent
        caution={{
          title: selectedBrand?.name,
        }}
        buttons={buttons}
        breadcrumb={<CustomBreadcrumb name={selectedBrand?.name} />}
        hideTab={true}
        tabs={tabs}
      />
    </div>
  );
}

const mapPropsToState = (state) => ({
  selectedBrand: state.brand.selectedBrand,
  languages: state.language.allList,
});

export default connect(mapPropsToState)(BrandOverView);