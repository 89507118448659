import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import CustomListComponent from '../../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import { createAction } from '../../../utils';
import DeletePrompt from '../../../components/base/DeletePrompt';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import './TermsAndConditionsList.scss';
import Filter from './Filter';
import BasePrompt from '../../../components/base/prompt/BasePrompt';

const TermsAndConditionsList = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  const {
    pageInfo,
    totalCount,
    termsAndConditionsList,
    totalPage,
    checkedList,
    relatedCampaignList,
    relatedCouponsetList,
  } = useSelector((state) => ({
    pageInfo: state.termsAndConditions.pageInfo,
    totalCount: state.termsAndConditions.totalCount,
    termsAndConditionsList: state.termsAndConditions.pagedList,
    totalPage: state.termsAndConditions.totalPage,
    checkedList: state.termsAndConditions.checkedList,
    relatedCampaignList: state.campaignList.notPagedAllList,
    relatedCouponsetList: state.couponList.notPagedAllList,
  }));

  useEffect(() => {
    const searchDict = queryString.parse(location.search);
    const searchKey = searchDict['search'];
    const stringRank = searchDict['rank'] || 'true';
    const stringPage = searchDict['page'] || 1;
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);
    dispatch(
      createAction('termsAndConditions/getList')({
        ...searchDict,
        page,
        rank,
        filterName: searchKey,
      }),
    );
  }, [dispatch, location]);

  const getAppliedNumber = (data, key) => {
    let appliedNumber = 0;
    (data || []).forEach((item) => {
      appliedNumber += item?.[key]?.length || 0;
    });
    return appliedNumber;
  };

  useEffect(() => {
    dispatch(createAction('couponList/clearData')());
    dispatch(createAction('campaignList/clearData')());

    if (checkedList?.length > 0) {
      const ids = checkedList.map((item) => item.pk);

      dispatch(
        createAction('campaignList/getAllList')({
          others: {
            termsAndConditionsTemplateIn: ids.join(),
            getAll: false,
          },
        }),
      );
      dispatch(
        createAction('couponList/getAllList')({
          termsAndConditionsTemplateIn: ids.join(),
          getAll: false,
        }),
      );
    }

    return () => {
      dispatch(createAction('campaignList/clearData')());
      dispatch(createAction('couponList/clearData')());
    };
  }, [checkedList]);

  const getDescription = (data) => {
    // const appliedCouponSetNumber = getAppliedNumber(data, 'appliedCouponSet');
    // const appliedCampaignNumber = getAppliedNumber(data, 'appliedCampaign');
    const appliedCouponSetNumber = relatedCouponsetList?.length || 0;
    const appliedCampaignNumber = relatedCampaignList?.length || 0;
    if (appliedCouponSetNumber === 0 && appliedCampaignNumber === 0) {
      return `Do you confirm to delete ${
        data?.length > 1 ? 'these templates' : 'this template'
      }? \n
      Related Coupon Sets and Campaigns have set to follow this Template. 
      Upon you confirm to delete, All T&C of the following Coupons Sets and campaigns will be deleted.`;
    }

    const description = `Below ${appliedCouponSetNumber} Coupon Sets and
      ${appliedCampaignNumber} Campaigns have set to follow this Template.
      Upon you confirm to delete, All T&C of the following Coupons Sets and campaigns will be deleted.`;

    // const description = `Related Coupon Sets and Campaigns have set to follow this Template.
    //   Upon you confirm to delete, All T&C of the following Coupons Sets and campaigns will be deleted.`;

    return description;
  };

  const otherBody = (data) => {
    return (
      <div className="terms-and-conditions-confirm-to-delete">
        {relatedCouponsetList?.map((coupon, index) => (
          <li
            id={`couponSet-${index}`}
          >{`[ID: ${coupon.pk}] ${coupon.name}`}</li>
        ))}
        {relatedCampaignList?.map((campaign, index) => (
          <li
            id={`campaign-${index}`}
          >{`[ID: ${campaign.pk}] ${campaign.name}`}</li>
        ))}
      </div>
    );
  };

  const buttons = [
    // <AuthButton
    //   customClass="btn-back-button-common btn-import-terms-and-conditions-template"
    //   title="import T&C Template"
    //   action={() => {

    //   }}
    //   requires={PermissionCodes.addTermsAndConditions}
    // />,
    <AuthButton
      title="Create T&C Template"
      action={() => {
        history.push('/terms_and_conditions/create');
      }}
      requires={PermissionCodes.addTermsAndConditions}
    />,
  ];

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.deleteTermsAndConditions,
    },
  ];

  const tabs = [
    {
      name: '',
      content: (
        <BaseTabListContainer
          hideTab={true}
          groupActions={groupActions}
          filter={{
            hasFilter: true,
            componment: Filter,
          }}
          pageInfo={pageInfo}
          totalCount={totalCount ? totalCount : 0}
          listContent={
            <BaseListContainer
              fields={[
                { displayName: 'ID', fieldName: 'pk', orderField: 'pk' },
                {
                  displayName: 'Name',
                  fieldName: 'title',
                  orderField: 'title',
                },
                { displayName: 'Type', fieldName: 'type' },
                {
                  displayName: 'Last Modified',
                  fieldName: 'lastModifiedDate',
                  orderField: 'lastModifiedDate',
                },
                {
                  displayName: 'Create at',
                  fieldName: 'creationDate',
                  orderField: 'creationDate',
                },
                // {
                //   displayName: 'Applied Coupon Set',
                //   fieldName: 'appliedCouponSet',
                // },
                // {
                //   displayName: 'Applied Campaign',
                //   fieldName: 'appliedCampaign',
                // },
              ]}
              model={'termsAndConditions'}
              permissionGroup={PermissionCodes.termsAndConditions}
              dataList={termsAndConditionsList}
              totalPage={totalPage}
              deleteInfo={{
                message: {
                  title: 'Confirm to Delete',
                  description: ' ',
                  otherBody: (data) => {
                    console.log('@154', data);
                    return (
                      <div className="terms-and-conditions-single-delete">
                        <div className="terms-and-conditions-single-delete-description">
                          {getDescription([data])}
                        </div>
                        {otherBody([data])}
                      </div>
                    );
                  },
                },
              }}
              actions={['Edit', 'Detail', 'Duplicate', 'Delete']}
            />
          }
        />
      ),
    },
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail:
            'Create  T & C template for applying in Campaign or Coupon set.',
          title: 'Terms & Conditions ',
        }}
        buttons={buttons}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={true}
        tabs={tabs}
      />
      <BasePrompt
        show={showDeletePrompt}
        closeAction={() => {
          setShowDeletePrompt(false);
        }}
        rightButton={{
          text: 'Confirm',
          action: () => {
            dispatch(
              createAction('termsAndConditions/delete')({
                afterAction: () => history.push(location),
              }),
            );
            setShowDeletePrompt(false);
          },
        }}
        leftButton={{
          text: 'Cancel',
          action: () => {
            setShowDeletePrompt(false);
          },
        }}
        title={'Confirm to Delete'}
        description={getDescription(checkedList)}
        otherBody={() => otherBody(checkedList)}
      />
    </>
  );
};

export default TermsAndConditionsList;
