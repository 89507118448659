import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { connect } from 'react-redux';
import AuthButton from '../../../components/base/AuthButton';
import ContentSections from '../../../components/base/ContentSections';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { createAction } from '../../../utils';
import Loading from '../../../components/base/Loading';
import { LanguageConfig } from '../../../config/CustomEnums';
import './InterestPreferenceDetail.scss';
import BaseOverview from '../../../components/coupon/couponList/BaseOverview';
import queryString from 'query-string';

const InterestPreferenceDetail = ({ detail, dispatch, languages }) => {
  const history = useHistory();
  const params = useParams();
  const { id } = params;
  useEffect(() => {
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
    dispatch(createAction('interestPreference/getOneDetail')({ id }));
    return () => {
      dispatch(createAction('interestPreference/clearData')());
    };
  }, [id]);

  const renderTabForLanguage = (language) => {
    console.log(detail);
    const name =
      language === LanguageConfig.english
        ? detail.name
        : detail.translations?.[language]?.name;
    return (
      <>
        <BaseOverview
          hideSection
          title="information"
          itemTitleList={['Name']}
          itemValueList={[name]}
        />
      </>
    );
  };

  const renderRelatedCustomerButton = () => {
    return (
      <button
        type="button"
        className="btn-further btn btn-primary"
        onClick={() => {
          history.push({
            pathname: '/customers',
            hash: 'List of Customers',
            search: queryString.stringify({
              page: 1,
              interestPreferenceIn: id,
            }),
          });
        }}
      >
        {' '}
        Go to view Related customers{' '}
      </button>
    );
  };

  const tabs = [
    {
      name: 'Detail',
      content: detail.pk ? (
        <div className="scroll-container-common">
          <ContentSections
            hidePreview
            languageTabContent={{
              containers: languages?.map((item) => ({
                container: [renderTabForLanguage(item.code)],
                key: item.code,
                title: item.sourceName,
              })),
            }}
          />
          <div className="interest-preference-detail-section">
            <BaseOverview
              title="Related customer"
              itemTitleList={['Related Customer number', 'Related Customer']}
              itemValueList={[
                detail.relatedCustomerNumber,
                { component: renderRelatedCustomerButton() },
              ]}
            />
          </div>
        </div>
      ) : (
        <Loading />
      ),
    },
  ];

  const buttons = [
    <AuthButton
      title="Edit"
      action={() => {
        history.push({
          pathname: 'edit/',
        });
      }}
      requires={PermissionCodes.changeInterestPreference}
    />,
  ];
  return (
    <>
      <CustomListComponent
        caution={{
          detail: '',
          title: detail?.name,
        }}
        defaultActiveKey={tabs[0].name}
        breadcrumb={<CustomBreadcrumb name={detail?.name} />}
        buttons={buttons}
        tabs={tabs}
      />
    </>
  );
};

const mapPropsToState = (state) => ({
  detail: state.interestPreference.detail,
  languages: state.language.allList,
});
export default connect(mapPropsToState)(InterestPreferenceDetail);
