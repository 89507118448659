import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { NavBarNames } from '../../../config/NavBarNameList';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import { createAction } from '../../../utils';
import AuthButton from '../../../components/base/AuthButton';
import DeletePrompt from '../../../components/base/DeletePrompt';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { DELETE_RELATED_SECTIONS } from '../../../config/CustomEnums';
import ListButtonsGroup from '../../../components/base/ListButtonsGroup';
// import BaseTest from '../../base/BaseTest';

function AdminGroupList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    listFields,
    dataList,
    pageInfo,
    totalPage,
    totalCount,
    checkedList,
  } = useSelector((state) => ({
    listFields: state.adminGroup.listDisplayFields,
    dataList: state.adminGroup.adminGroupList,
    pageInfo: state.adminGroup.pageInfo,
    totalPage: state.adminGroup.totalPage,
    totalCount: state.adminGroup.totalCount,
    checkedList: state.adminGroup.checkedList,
  }));
  const crateAdminGroupAction = () => {
    dispatch({ type: `adminGroup/clearData` });
    history.push('/administrator_groups/create');
  };
  const buttons = [
    <ListButtonsGroup
      hideExtraButtonWidth={580}
      hideAllButtonWidth={580}
      primaryButton={
        <AuthButton
          title="Create Administrator group"
          action={crateAdminGroupAction}
          requires={PermissionCodes.addAdministratorGroup}
        />
      }
      primaryPopContent={{
        requires: PermissionCodes.addAdministratorGroup,
        action: crateAdminGroupAction,
        content: 'Create Administrator group',
      }}
    />,
  ];

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        // setShowPrompt(true);
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.deleteAdministratorGroup,
    },
  ];

  const tabs = [
    // {
    //   name: 'Overview',
    //   content: <div>Overview</div>,
    // },
    {
      name: 'List of Customers',
      content: (
        <BaseTabListContainer
          hideTab={true}
          tabs={[]}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount}
          listContent={
            <BaseListContainer
              fields={listFields}
              dataList={dataList}
              totalPage={totalPage}
              model={'adminGroup'}
              permissionGroup={PermissionCodes.administratorgroup}
              deleteInfo={{
                data: [],
                title: 'Administrator group',
                relatedName: '',
                onComfirm: {},
                relatedSections: DELETE_RELATED_SECTIONS.ADMIN_GROUP,
              }}
              actions={['Edit', 'Delete', 'Duplicate']}
            />
          }
        />
      ),
    },
  ];

  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  useEffect(() => {
    dispatch({ type: `adminGroup/clearData` });
    dispatch(
      createAction('adminGroup/getPagedAdminGroups')({
        page: 1,
        reverse: true,
        search: '',
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    const queryString = require('query-string');
    const searchDict = queryString.parse(location.search);
    const searchKey = queryString.parse(location.search)['search'];
    const stringRank = queryString.parse(location.search)['rank'] || 'true';
    const stringPage = queryString.parse(location.search)['page'] || 1;
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);

    dispatch(
      createAction('adminGroup/getPagedAdminGroups')({
        page,
        reverse: rank,
        search: searchKey,
        moreSearch: { ...searchDict },
      }),
    );
  }, [dispatch, location]);

  return (
    <>
      <CustomListComponent
        caution={{
          detail:
            'Can create a administrator group and give corresponding permissions.',
          title: NavBarNames.adminGroup,
        }}
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        hideTab={true}
      />
      <DeletePrompt
        data={checkedList}
        title={'Administrator group'}
        relatedName={''}
        relatedSections={DELETE_RELATED_SECTIONS.ADMIN_GROUP}
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch(
            createAction('adminGroup/delete')({
              afterAction: () => {
                history.push(location);
              },
            }),
          );
        }}
      />
    </>
  );
}

export default AdminGroupList;
