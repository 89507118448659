import React from 'react';
import { CampaignErrorHandleField } from './CreateCampaignHandleError';
import PeriodSection from './PeriodSection';
import CustomTitleWithSwitch from '../../base/CustomTitleWithSwitch';
import { useFormContext } from 'react-hook-form';
import { hasError, errorMessage } from '../../base/ErrorFieldMessage';
import { isGoodieBagType } from '../.././../models/CreateCampaignModel';

function VisiblePeriodSection() {
  const { watch, setValue, formState } = useFormContext();
  const errors = formState.errors;
  const isAlwaysVisiblePeriod = watch('isAlwaysVisiblePeriod');
  const visibleStartDate = watch('visibleStartDate');
  const visibleEndDate = watch('visibleEndDate');
  const accessCampaignCopyUrl = watch('accessCampaignCopyUrl');
  const campaignType = watch('campaignType');
  const goodieBag = watch('goodieBag');
  const isFollowGoodieBagVisiblePeriod = watch(
    'isFollowGoodieBagVisiblePeriod',
  );
  const isGoodieBag = isGoodieBagType(campaignType);
  console.log('CampaignGoodieBag:', goodieBag);
  const timeSection = () => {
    return (
      <>
        {isGoodieBag ? (
          <>
            <label className="create-section-label create-section-label-bottom-space">
              Follow the visible period from the linked Goodie bag
            </label>
            <label className="section-short-description">
              {goodieBag?.displayVisiblePeriod}
            </label>
          </>
        ) : null}
        <PeriodSection
          title="Visible Period"
          switchButtonTitle={
            !isGoodieBag ? 'It is an all-time visible campaign' : null
          }
          isAlwaysPeriod={
            isGoodieBag ? isFollowGoodieBagVisiblePeriod : isAlwaysVisiblePeriod
          }
          switchOnChange={(value) => {
            setValue(
              isGoodieBag
                ? 'isFollowGoodieBagVisiblePeriod'
                : 'isAlwaysVisiblePeriod',
              value,
              { shouldDirty: true },
            );
          }}
          startDate={visibleStartDate}
          startDateChange={(date) =>
            setValue('visibleStartDate', date, { shouldDirty: true })
          }
          endDate={visibleEndDate}
          endDateChange={(date) =>
            setValue('visibleEndDate', date, { shouldDirty: true })
          }
          startErrorObject={{
            id: 'visibleStartDate',
            error: hasError(errors, 'visibleStartDate'),
            message: errorMessage(errors, 'visibleStartDate'),
          }}
          endErrorObject={{
            id: 'visibleEndDate',
            error: hasError(errors, 'visibleEndDate'),
            message: errorMessage(errors, 'visibleEndDate'),
          }}
          reminder={isGoodieBag ? CampaignErrorHandleField.visibleEndDateInvalid.message : null}
        /> 
      </>
    );
  };

  return (
    <>
      <label className="create-section-title">Visible Period</label>
      <label className="section-short-description">
        Available time to display this campaign.
      </label>

      <CustomTitleWithSwitch
        title="Access the campaign detail page via URL only"
        defaultValue={accessCampaignCopyUrl}
        tips="If “On”, then once the campaign is saved, the campaign URL can be copied in Step 1 when edit campaign or campaign listing page."
        setValue={(value) => {
          setValue('accessCampaignCopyUrl', value, { shouldDirty: true });
        }}
      />
      {timeSection()}
    </>
  );
}

export default VisiblePeriodSection;
