import React, { useEffect, useState } from 'react';
import '../../components/base/prompt/FilterExportPrompt.scss';
import { Row } from 'react-bootstrap';
import { PermissionCodes } from '../../config/PermissionCodes';
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from '../../utils';
import moment from 'moment';
import { formatDate, TimeFormater } from '../../utils/TimeFormatUtil';
import BasePrompt from '../base/prompt/BasePrompt';
import { DatePicker } from 'antd';
import {
  COUPON_ACQUIRED_PERIOD_TYPE_CONFIG,
  GOODIE_BAG_ENTITLEMENT_METHOD_TYPE,
} from '../../models/GoodieBagsModel';
import './CustomerGoodieBagRecordTimeExtendPrompt.scss';

const getOriginalAcquireCouponExpiryDate = (customerParticipationRecord) => {
  const goodieBag = customerParticipationRecord?.goodieBag;
  if (!goodieBag) return null;

  var date = goodieBag.endDate;
  if (
    goodieBag.couponAcquirePeriodType ===
    COUPON_ACQUIRED_PERIOD_TYPE_CONFIG.absolute
  ) {
    date = goodieBag.absoluteExpiryDate;
  } else if (
    goodieBag.couponAcquirePeriodType ===
      COUPON_ACQUIRED_PERIOD_TYPE_CONFIG.relative &&
    goodieBag.numberOfDaysToExpireAfterAcquisition
  ) {
    date = moment(customerParticipationRecord.creationDate);
    date = date.add(goodieBag.numberOfDaysToExpireAfterAcquisition, 'days');
    if (goodieBag.endDate && date > goodieBag.endDate) {
      date = goodieBag.endDate;
    }
  }
  if (
    goodieBag.entitlementMethod ===
      GOODIE_BAG_ENTITLEMENT_METHOD_TYPE.optionD &&
    (!date || (goodieBag.displayEndDate && date > goodieBag.displayEndDate))
  ) {
    date = goodieBag.displayEndDate;
  }
  return date && new Date(date);
};

function CustomerGoodieBagRecordTimeExtendPrompt({
  show,
  customerParticipationRecord,
  onClose = () => {},
}) {
  const dispatch = useDispatch();
  const [selectDate, setSelectDate] = useState();
  const [remarks, setRemarks] = useState('');
  const [disabledButton, setDisabledButton] = useState(false);

  var maxDate = customerParticipationRecord?.goodieBag.endDate;
  if (
    customerParticipationRecord?.goodieBag?.entitlementMethod ===
      GOODIE_BAG_ENTITLEMENT_METHOD_TYPE.optionD &&
    (!maxDate ||
      (customerParticipationRecord?.goodieBag.displayEndDate &&
        customerParticipationRecord?.goodieBag.displayEndDate < maxDate))
  ) {
    maxDate = customerParticipationRecord?.goodieBag.displayEndDate;
  }
  var originalAcquireCouponExpiryDate = getOriginalAcquireCouponExpiryDate(
    customerParticipationRecord,
  );
  console.log('@49 extend', maxDate, originalAcquireCouponExpiryDate);
  if (originalAcquireCouponExpiryDate) {
    originalAcquireCouponExpiryDate.setDate(
      originalAcquireCouponExpiryDate.getDate() + 180,
    );
    originalAcquireCouponExpiryDate.setHours(23, 59, 59, 999);
    if (!maxDate || maxDate > originalAcquireCouponExpiryDate) {
      maxDate = originalAcquireCouponExpiryDate;
    }
  }
  const originalMaxDate = maxDate && new Date(maxDate);
  maxDate = maxDate && new Date(maxDate).setHours(23, 59, 59, 999);
  console.log('@59 extend', maxDate, originalMaxDate);

  const getOtherBody = () => {
    return (
      <>
        <label className={`create-section-label landing-title-margin-bottom `}>
          Goodie Bag
        </label>
        <label className="section-value">
          {customerParticipationRecord?.name}
        </label>

        <label className={`create-section-label landing-title-margin-bottom `}>
          Existing coupon acquiring period
        </label>
        <label className="section-value">
          {customerParticipationRecord?.couponAcquireExpiryDate
            ? formatDate(
                customerParticipationRecord?.couponAcquireEffectiveDate,
                TimeFormater.dayMonthYearWeekTime,
              ) +
              ' - ' +
              formatDate(
                customerParticipationRecord?.couponAcquireExpiryDate,
                TimeFormater.dayMonthYearWeekTime,
              )
            : 'All time'}
        </label>

        <label className={`create-section-label landing-title-margin-bottom `}>
          Extend to
        </label>
        <DatePicker
          className="extend-to-date-picker"
          disabledDate={(d) => {
            return !d || (maxDate && d > maxDate);
          }}
          placeholder=""
          value={selectDate}
          showTime={false}
          format={TimeFormater.dayMonthYear}
          onChange={(date, dateString) => {
            setSelectDate(date);
          }}
          onOk={(value) => {
            setSelectDate(value);
          }}
        />

        <label className={`create-section-label landing-title-margin-bottom `}>
          Remark
        </label>
        <textarea
          onChange={({ target }) => {
            setRemarks(target.value);
          }}
          className="custom-textarea-remakrs"
          onFocus={() => {}}
          value={remarks}
          placeholder="Please input remark"
        />
      </>
    );
  };

  return (
    <BasePrompt
      show={show}
      closeAction={onClose}
      title="Extend coupon acquiring period of the Goodie Bag"
      customClass="extend-goodie-bag-date-prompt"
      leftButton={{
        text: 'Cancel',
        action: () => {
          onClose();
        },
      }}
      rightButton={{
        text: 'Confirm',
        action: () => {
          var selectDateEnd = new Date(selectDate).setHours(23, 59, 59, 999);
          setDisabledButton(true);
          dispatch({
            type: 'goodieBags/extendGoodieBagRecordExpiryDate',
            payload: {
              data: {
                id: customerParticipationRecord.pk,
                extendTo:
                  selectDate?.format(TimeFormater.yearMonthDay) +
                  (originalMaxDate && selectDateEnd > originalMaxDate
                    ? formatDate(originalMaxDate, 'THH:mm:ss+08:00')
                    : 'T23:59:59+0800'),
                remarks: remarks,
              },
              afterAction: () => {
                setDisabledButton(false);
                setSelectDate(null);
                setRemarks('');
                dispatch(
                  createAction(
                    'goodieBags/getCustomerOneGoodieBagParticipationRecord',
                  )({
                    id: customerParticipationRecord.pk,
                  }),
                );
              },
            },
          });
          onClose();
        },
        requires: PermissionCodes.changeGoodieBag,
        disabled: !remarks || !selectDate || disabledButton,
      }}
      otherBody={getOtherBody}
    />
  );
}
export default CustomerGoodieBagRecordTimeExtendPrompt;
