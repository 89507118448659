import React, { useState, forwardRef, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import AuthButton from './AuthButton';
import { CustomMenu } from './FilterableDropdown';
import './FilterableDropdownClickButton.scss';

const CustomToggle = forwardRef(({ children, onClick }, ref) => (
  <div
    className="click-button-filterable-toogle-area"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

function FilterableDropdownClickButton(props) {
  const buttonTitle = props.buttonTitle || "";
  const buttonAction = props.buttonAction || (() => {});
  const buttonClass = props.buttonClass || "";
  const needFilter = props.needFilter === undefined ? true : props.needFilter;
  const filterButtons = props.filterButtons || [];
  const placeholder = props.placeholder || "Search for...";
  const customClass = props.customClass;
  const onSelectChange = props.selectChange || (() => {});
  const onToggle = props.onToggle || (() => {});
  const [options, setOptions] = useState(props.options);
  const isPromptDropdown = props.isPromptDropdown || false;
  const filterAction = props.filterAction || (() => {});
  const loadMoreAction = props.loadMoreAction || (() => {});
  const [isLoadMore, setIsLoadMore] = useState(false);

  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);

  return (
    <Dropdown
      onToggle={(isOpen) => {
        onToggle(isOpen);
      }}
      className={`${
        isPromptDropdown
          ? 'filterable-dropdown-area'
          : 'filterable-dropdown-area-prompt'
      } ${customClass}`}
      onScroll={(event) => {
        if (
          event.nativeEvent.target.scrollTop +
            event.nativeEvent.target.clientHeight ===
          event.nativeEvent.target.scrollHeight
        ) {
          loadMoreAction();
          setIsLoadMore(true);
        }
      }}
    >
      <Dropdown.Toggle as={CustomToggle}>
        <>
          <AuthButton
            variant="primary"
            title={buttonTitle}
            action={buttonAction}
            customClass={`btn-back-button-common ${buttonClass}`}
          />
        </>
      </Dropdown.Toggle>

      <Dropdown.Menu
        as={CustomMenu}
        className="filterable-dropdown-menu"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          margin: 0,
          right: 'auto',
          bottom: 'auto',
        }}
        options={options}
        filter={needFilter}
        filterButtons={filterButtons}
        filterAction={filterAction}
        isLoadMore={setIsLoadMore}
        placeholder={placeholder}
      >
        {options.map((option, index) => (
          <Dropdown.Item
            key={`${index}-${option.label}`}
            className="filterable-item"
            onSelect={(eventKey) => {
              onSelectChange(option);
            }}
            eventKey={index}
          >
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default FilterableDropdownClickButton;
