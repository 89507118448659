import React from 'react';
import AnimatedNumber from 'animated-number-react';
import { useSelector } from 'react-redux';
import './CampaignsCard.scss';
import { Link } from 'react-router-dom';
import icon from '../../assets/images/customer_overview_vector.svg';
import { ANIMATION_DURATION } from '../../config/DashboardConfig';
import { enLocaleNumberFormatter } from '../../utils';
import { URLFragments } from '../campaign/CampaignUrlConfig';
import { getValueChangeLabel } from './CustomersCard';
import StarredCampaignFilter from '../../containers/dashboard/StarredCampaignFilter';
import { SectionItem } from './CouponsCardNew';

const CampaignsCardV2 = () => {
  const { totalPublished, totalNonpublished, totalExpired } = useSelector(
    (state) => state.dashboard.campaigns,
  );

  const getSingleValueLabel = (name, value, change) => {
    return (
      <div className="dashboard-campaigns-type-data-single">
        <label className="dashboard-campaigns-type-data-single-name">
          {name}
        </label>
        <AnimatedNumber
          className="dashboard-campaigns-type-data-single-value"
          duration={ANIMATION_DURATION}
          value={value || 0}
          formatValue={(value) => enLocaleNumberFormatter(value.toFixed(0))}
        />
        {getValueChangeLabel(change)}
      </div>
    );
  };

  const getCampaignTypeData = (title, campaignTypeData) => {
    return (
      <div className="dashboard-campaigns-type">
        <label className="dashboard-campaigns-type-title">{title}</label>
        <div className="dashboard-campaigns-type-data">
          {getSingleValueLabel(
            'Published',
            campaignTypeData?.published?.value,
            campaignTypeData?.published?.change,
          )}
          <div className="dashboard-campaigns-type-data-separate"></div>
          {getSingleValueLabel(
            'Non-published',
            campaignTypeData?.unpublished?.value,
            campaignTypeData?.unpublished?.change,
          )}
          <div className="dashboard-campaigns-type-data-separate"></div>
          {getSingleValueLabel(
            'Expired',
            campaignTypeData?.expired?.value,
            campaignTypeData?.expired?.change,
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="dashboard-campaign-card">
      <div className="dashboard-card-title">Campaigns</div>
      <StarredCampaignFilter filterModel={'campaignFilter'} />
      <div className="campaign-type-card">
        <SectionItem
          label="Published campaign"
          count={totalPublished?.value}
          percentage={totalPublished?.change}
        />
      </div>
      <div className="campaign-type-card">
        <SectionItem
          label="Non-published campaign"
          count={totalNonpublished?.value}
          percentage={totalNonpublished?.change}
        />
      </div>
      <div className="campaign-type-card">
        <SectionItem
          label="Expired campaign"
          count={totalExpired?.value}
          percentage={totalExpired?.change}
        />
      </div>

      {/* <div className="dashboard-campaign-card-main">
        {getCampaignTypeData('Coupon campaign', couponCampaign)}
        {getCampaignTypeData(
          'General message campaign',
          generalMessageCampaign,
        )}
      </div> */}
      <Link
        className="dashboard-overview-link coupons-overview-link"
        to={{ pathname: '/dashboard_campaign' }}
      >
        {/* Campaign Overview */}
        See detail
        <img className="dashboard-overview-link-icon" src={icon} alt="" />
      </Link>
    </div>
  );
};

export default CampaignsCardV2;
