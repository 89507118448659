import React, { useEffect, useState } from 'react';
// import './MessageList.scss';
import queryString from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import CustomListComponent from '../../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import { createAction } from '../../../utils';
import DeletePrompt from '../../../components/base/DeletePrompt';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
// import { DELETE_RELATED_SECTIONS } from '../../../config/CustomEnums';

const LogoLabelList = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  const { pageInfo, totalCount, logoLabelList, totalPage, checkedList } =
    useSelector((state) => ({
      pageInfo: state.logoLabel.pageInfo,
      totalCount: state.logoLabel.totalCount,
      logoLabelList: state.logoLabel.pagedList,
      totalPage: state.logoLabel.totalPage,
      checkedList: state.logoLabel.checkedList,
    }));
  // console.log('logoLabelList', logoLabelList);

  useEffect(() => {
    const searchDict = queryString.parse(location.search);
    const searchKey = searchDict['search'];
    const stringRank = searchDict['rank'] || 'true';
    const stringPage = searchDict['page'] || 1;
    const rank = stringRank !== 'true';
    const page = parseInt(stringPage);
    dispatch(
      createAction('logoLabel/getList')({
        ...searchDict,
        page,
        rank,
        filterName: searchKey,
      }),
    );
  }, [dispatch, location]);

  const buttons = [
    <AuthButton
      title="Create Logo label"
      action={() => {
        history.push('/logo_label/create');
      }}
      requires={PermissionCodes.addLogoLabel}
    />,
  ];

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.deleteLogoLabel,
    },
  ];

  const updateOrder = (pk, order) => {
    const data = {};
    if (!pk) {
      return;
    }
    data.id = pk;
    if (order) {
      data.displayPriority = order;
    }
    dispatch(
      createAction('logoLabel/updateLogoLabelOrder')({
        data,
        afterAction: () => history.push(history.location),
      }),
    );
  };

  const tabs = [
    {
      name: '',
      content: (
        <BaseTabListContainer
          hideTab={true}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount ? totalCount : 0}
          listContent={
            <BaseListContainer
              fields={[
                { displayName: 'ID', fieldName: 'pk' },
                { displayName: 'Name', fieldName: 'name' },
                { displayName: 'Logo image', fieldName: 'icon' },
                {
                  displayName: 'Order',
                  fieldName: 'displayPriority',
                  orderField: 'displayPriority',
                  show: true,
                },
                {
                  displayName: 'Related Coupon',
                  fieldName: 'relatedCouponsNameWithoutIDList',
                },
                {
                  displayName: 'Related Campaign',
                  fieldName: 'relatedCampaignsNameWithoutIDList',
                },
              ]}
              model={'logoLabel'}
              permissionGroup={PermissionCodes.logolabel}
              dataList={logoLabelList}
              totalPage={totalPage}
              sortable
              hasOtherActions
              rankDisabled
              onSortEnd={(oldIndex, newIndex) => {
                if (oldIndex === newIndex) return;
                const startOrder = logoLabelList[0].displayPriority;
                const movedItem = logoLabelList[oldIndex];
                dispatch(
                  createAction('logoLabel/updateLogoLabelOrder')({
                    data: {
                      id: movedItem.pk,
                      displayPriority: startOrder + newIndex,
                    },
                    afterAction: () => history.push(location),
                  }),
                );
              }}
              onOrderChangedByEdit={(pk, newOrder) => {
                updateOrder(pk, newOrder);
              }}
              deleteInfo={{
                data: [],
                title: 'Logo label',
                onComfirm: {},
              }}
              actions={['Edit', 'Detail', 'Duplicate', 'Delete']}
              customActions={{
                moveToFirst: (item) => {
                  updateOrder(item.pk, 1);
                },
                moveToLast: (item) => {
                  updateOrder(item.pk, totalCount);
                },
              }}
            />
          }
        />
      ),
    },
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail: 'TBC',
          title: 'Logo label',
        }}
        buttons={buttons}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={true}
        tabs={tabs}
      />
      <DeletePrompt
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch(
            createAction('logoLabel/delete')({
              afterAction: () => history.push(location),
            }),
          );
        }}
        data={checkedList}
        title={'Logo label'}
      />
    </>
  );
};

export default LogoLabelList;
