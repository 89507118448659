import React from 'react';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import CustomDateTimeSelect from '../base/CustomDateTimeSelect';
import ErrorFieldMessage, {
  hasError,
  errorMessage,
} from '../base/ErrorFieldMessage';
import { formatDate, TimeFormater } from '../../utils/TimeFormatUtil';
import { useFormContext } from 'react-hook-form';
import moment from 'moment';

function GeneralAlertSettingSection() {
  const { watch, setValue, formState } = useFormContext();
  const errors = formState.errors;
  const startDate = watch('startDate');
  const endDate = watch('endDate');
  const id = watch('id');
  const originalStartDate = watch('originalStartDate');
  const originalEndDate = watch('originalEndDate');

  return (
    <>
      <label className="create-section-title">SETTING</label>
      {id ? (
        <label className="create-message-suggest">
          {'If change the schedule date, then will need re-schedule.'}
        </label>
      ) : null}
      <CustomTitleLabel title="Start time" />
      <CustomDateTimeSelect
        defaultTime={startDate ? new Date(startDate) : new Date()}
        onTimeChange={(datetime) => {
          const formatedDate = formatDate(datetime, TimeFormater.GTMFormat);
          setValue('startDate', formatedDate, {
            shouldDirty: true,
          });
          setValue(
            'deliveryStartDate',
            formatDate(datetime, TimeFormater.dayMonthYearWeekTimeA),
          );
          const isSame = moment(datetime).isSame(moment(originalStartDate));
          setValue('startDateChanged', !isSame);
        }}
      />

      <CustomTitleLabel title="End time" />
      <CustomDateTimeSelect
        defaultTime={endDate ? new Date(endDate) : new Date()}
        onTimeChange={(datetime) => {
          const formatedDate = formatDate(datetime, TimeFormater.GTMFormat);
          setValue('endDate', formatedDate, {
            shouldDirty: true,
          });
          setValue(
            'deliveryEndDate',
            formatDate(datetime, TimeFormater.dayMonthYearWeekTimeA),
          );
          const isSame = moment(datetime).isSame(moment(originalEndDate));
          setValue('endDateChanged', !isSame);
        }}
      />
      <ErrorFieldMessage
        id={'scheduledDate'}
        error={hasError(errors, 'endDate')}
        message={errorMessage(errors, 'endDate')}
      />
    </>
  );
}

export default GeneralAlertSettingSection;
