import GeneralMessageCampaign from '../../../assets/images/campaign/general_campaign_icon.png';
import CouponCampaign from '../../../assets/images/campaign/coupon_campaign_icon.png';
import EarningCampaign from '../../../assets/images/campaign/earning_campaign_icon.png';
import FortuneBagCampaign from '../../../assets/images/campaign/fortune_bag_goodie_bag_campaign.svg';
import CampaignListModel from '../../../models/CampaignListModel';
import CashCampaign from '../../../assets/images/campaign/cash_voucher_card.svg';
import GiftCampaign from '../../../assets/images/campaign/gift_campaign_card.svg';
import { CampaignType } from '../../../config/CustomEnums';

const couponCampaignPromptConfig = {
  title: 'Go to create a Coupon Set?',
  message: `You will leave the campaign creation process. After you create a coupon set, you can come back to create a coupon campaign.`,
  button: 'Go to Create Coupon Set',
};

const earningCampaignPromptConfig = {
  title: 'Go to create a earning rule Set?',
  message: `You will leave the campaign creation process. After you create a earning set, you can come back to create a earning campaign.`,
  button: 'Go to Create Earning Rule Set',
};

export const CampaignTypesConfig = [
  {
    id: CampaignType.generalMessageCampaign,
    title: 'General Message',
    image: GeneralMessageCampaign,
    description: 'Present information to customers',
  },
  // {
  //   id: CampaignType.earningRulesCampaign,
  //   title: 'Earning',
  //   image: EarningCampaign,
  //   description:
  //     'Customers will earn the points and level points by completing specific activities',
  // },
  {
    id: CampaignType.couponsCampaign,
    title: 'Coupon',
    image: CouponCampaign,
    description: 'For customer to acquire coupons',
  },

  {
    id: CampaignType.cashVoucherCampaign,
    title: 'Cash Voucher campaign for Goodie Bag',
    image: CashCampaign,
    description:
      'Similar to coupon campaign, but it is for linking with a cash voucher (coupon set) and is specific for Goodie Bag.',
  },
  {
    id: CampaignType.giftCampaign,
    title: `Coupon campaign for Goodie bag`,
    image: GiftCampaign,
    description:
      'Similar to coupon campaign, but it is for linking with a gift (coupon set) and is specific for Goodie Bag.',
  },
  {
    id: CampaignType.fortuneBagForGoodieBagCampaign,
    title: 'Goodie bag offer (Direct assign)',
    image: FortuneBagCampaign,
    description:
      'Deliver multiple coupons to customer via goodie bag offer (direct assign) mechanism for Goodie Bag.',
  },
];

export const getCampaignTypeSelectPromptConfig = (type) => {
  return type === CampaignType.couponsCampaign
    ? couponCampaignPromptConfig
    : earningCampaignPromptConfig;
};
