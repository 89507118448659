import { createModel } from './BaseModel';
import { getSSOtpCode } from '../services/AdminApiHelper';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { TimeFormater, formatDate } from '../utils/TimeFormatUtil';
import { getAllCustomers } from '../services/CustomerAPIHelper';
import { convertCursorToNumber, convertNumberToCursor } from '../utils';
import { loading } from './LoadingUtil';

const parseDetail = (data) => {
  const phoneNumber = parsePhoneNumberFromString(data?.mobile);
  const { countryCallingCode, nationalNumber } = phoneNumber || {};
  return {
    countryCode: '+' + countryCallingCode || '',
    mobileNumber: nationalNumber || '',
    requestCodeDateTime: formatDate(
      data?.requestCodeDateTime,
      TimeFormater.yearMonthDayTimeSecondsWithBlank,
    ),
    membershipId: data?.membershipId,
    requestCode: data?.requestCode,
  };
};

const getInitState = () => {
  return {
    listDisplayFields: [
      { displayName: 'Date time', fieldName: 'requestCodeDateTime' },
      { displayName: 'Country code', fieldName: 'countryCode' },
      { displayName: 'Mobile number', fieldName: 'mobileNumber' },
      { displayName: 'Membership ID', fieldName: 'membershipId' },
      { displayName: 'OTP code', fieldName: 'requestCode' },
    ],
    detail: {},
    otpCodeList: [],
    hasSearched: false,
    requestLimitExceedError: null,
    pageInfo: {
      startCursor: 0,
      endCursor: 0,
    },
  };
};

export default createModel({
  namespace: 'otpCode',
  params: {
    states: {},
    listAPI: getSSOtpCode,
    parse: (data) =>
      data?.ssoMobileVerifyCodes.edges.map((item) => parseDetail(item.node)),
    initState: {
      createUpdateError: [],
    },
    dataKey: 'ssoMobileVerifyCodes',
  },
  states: getInitState(),
  reducers: {
    updateCodeList(state, { payload }) {
      const { otpCodeList } = payload;

      return {
        ...state,
        otpCodeList:
          otpCodeList?.length > 0 ? [...state.otpCodeList, ...otpCodeList] : [],
      };
    },
    clearState(state, { payload }) {
      return { ...getInitState() };
    },
  },
  effects: {
    checkUserRegister: [
      function* ({ payload }, { call, put }) {
        const { countryCode, mobileNumber } = payload;
        const search = {
          // mobilePhoneNumberCountryCodeIn: `"${countryCode}"`,
          // mobilePhoneNumberSubscriberNumber: `"${mobileNumber}"`,
          mobilePhoneNumberLimitCheck: `"${countryCode},${mobileNumber},otp"`,
        };
        const customerResponse = yield call(getAllCustomers, '', search, {
          isCheckOtp: true,
        });
        const requestLimitExceed =
          customerResponse.data?.errors?.[0]?.message?.startsWith(
            'You are not allowed to perform the action (',
          );
        const customers = customerResponse.data?.data?.customers?.edges;
        const membershipId = customers?.[0]?.node?.membershipId;
        if (requestLimitExceed) {
          yield put({
            type: 'updateState',
            payload: {
              ...getInitState(),
              totalCount: 0,
              totalPage: 0,
              pagedList: [],
              isRegister: undefined,
              hasSearched: false,
              searchMembershipId: null,
              requestLimitExceedError:
                customerResponse.data?.errors?.[0]?.message,
              loadData: false,
            },
          });
        } else {
          yield put({
            type: 'updateState',
            payload: {
              isRegister: customers?.length > 0,
              hasSearched: true,
              searchMembershipId: membershipId,
              requestLimitExceedError: null,
              // loadData: false,
              searchCountryCode: countryCode,
              searchMobileNumber: mobileNumber,
            },
          });
        }
      },
      { type: 'takeLatest' },
    ],
    loadMoreOtpCodes: [
      function* ({ payload }, { call, put, all }) {
        const { page } = payload;

        const afterCursor = page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const serviceArgs = [getSSOtpCode, afterCursor, payload];
        function* onSuccess(data) {
          const ssoMobileVerifyCodes = data.ssoMobileVerifyCodes;
          const otpCodeList = ssoMobileVerifyCodes?.edges?.map((item) =>
            parseDetail(item?.node),
          );
          const pageInfo = ssoMobileVerifyCodes.pageInfo;
          const totalCount = ssoMobileVerifyCodes.totalCount;
          yield all([
            put({
              type: 'updateState',
              payload: {
                totalCount,
                totalPage: Math.ceil(totalCount / 20),
                pageInfo: {
                  ...pageInfo,
                  startCursor:
                    convertCursorToNumber(pageInfo?.startCursor) + 1 || 0,
                  endCursor:
                    convertCursorToNumber(pageInfo?.endCursor) + 1 || 0,
                },
              },
            }),
            put({ type: 'updateCodeList', payload: { otpCodeList } }),
            put({ type: 'updateState', payload: { loadData: false } }),
          ]);
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
});
