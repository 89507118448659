import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import {
  createAction,
  useDeviceDetect,
  checkHasPermission,
} from '../../../utils';
import ContentSections from '../../../components/base/ContentSections';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';
import RecordTableCard from '../../../components/customer/RecordTableCard';
import RecordTimelineCard from '../../../components/customer/RecordTimelineCard';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { StatusTag } from '../../../config/CustomEnums';
import BackIcon from '../../../assets/images/backBlue.svg';
import { Button } from 'react-bootstrap';
import { OnlySaveButton } from '../../../components/base/BottomStepComponent';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import CustomerMobileListHeader from './CustomerMobileListHeader';
import CustomerListCard from '../../../components/customer/CustomerListCard';
import BaseMobileListContainer from '../../base/BaseMobileListContainer';
import CustomerActivityLogFilter from './CustomerActivityLogFilter';

const URLFragments = {
  profile: 'Personal Profile',
  gooiebagRecord: 'Goodies & Coupons Records',
  records: 'Records',
  customerActivityLog: 'Activity Log',
};

function CustomerOverviewDetail() {
  const queryString = require('query-string');

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const id = params.id;

  const isMobile = useDeviceDetect(768);

  const [goodieBagRecordPage, setGoodieBagRecordPage] = useState(1);
  const [goodieBagRecordSearchKey, setGoodieBagRecordSearchKey] = useState();

  const {
    user,
    customer,
    activityLogs,
    activityLogsVersion2,
    activityLogsVersion2ListFields,
    activityLogsVersion2PageInfo,
    activityLogsVersion2TotalPage,
    activityLogsVersion2TotalCount,
    couponTransactions,
    transactions,
    avaliableCampaigns,
    coupons,
    customerParticipationRecords,
  } = useSelector((state) => ({
    user: state.users,
    customer: state.customerList.customer,
    activityLogs: state.customerList.activityLogs,
    activityLogsVersion2: state.customerList.activityLogsVersion2,
    activityLogsVersion2ListFields:
      state.customerList.activityLogsVersion2ListFields,
    activityLogsVersion2PageInfo:
      state.customerList.activityLogsVersion2PageInfo,
    activityLogsVersion2TotalPage:
      state.customerList.activityLogsVersion2TotalPage,
    activityLogsVersion2TotalCount:
      state.customerList.activityLogsVersion2TotalCount,
    couponTransactions: state.couponTransactions.couponTransactionList || [],
    transactions: state.transactions.currentPageTransactionList || [],
    avaliableCampaigns: state.campaignList.pagedList,
    coupons: state.singleCoupon.pagedList,
    customerParticipationRecords: state.goodieBags.customerParticipationRecords,
  }));

  var tabs = [
    {
      name: URLFragments.profile,
      content: (
        <div className="scroll-container-common">
          <ContentSections
            sections={[
              <MembershipInfoCard
                title={'Personal Information'}
                data={customer}
                fields={[[{ title: 'Salutation', field: 'salutationLabel' }]]}
              />,
              <MembershipInfoCard
                title={'Membership Information'}
                data={customer}
                fields={[
                  [{ title: 'Membership ID', field: 'membershipId' }],
                  [{ title: 'Join Channel', field: 'signUpChannel' }],
                  // [
                  //   { title: 'Current Level', field: 'level' },
                  //   { title: 'Level renewal date', field: 'levelRenewalDate' },
                  //   {
                  //     title: 'Level expired in(days)',
                  //     field: 'levelExpiredIndays',
                  //   },
                  // ],
                  // [{ title: 'Segment', field: 'segment' }],
                  // [{ title: 'Customer group', field: 'group' }],
                  [{ title: 'Referral source', field: 'referralSource' }],
                  [{ title: 'Create at', field: 'formateCreated' }],
                  [
                    {
                      title: 'Interest preference',
                      field: 'displayInterestPreferences',
                    },
                  ],
                  [{ title: 'Customer Lifetime Value', field: 'lifetime' }],
                  [
                    {
                      title: 'Preferred message language',
                      field: 'preferredMessageLanguageDisplay',
                    },
                  ],
                  // [{ title: 'Average Order Value', field: 'averageOrder' }],
                  // [{ title: 'Total number of orders', field: 'totalOrders' }],
                  // [{ title: 'Days from last order', field: 'lastOrderDays' }],
                  [
                    {
                      title: 'Direct Marketing Preference',
                      field: 'hasAgreedDirectMarketing',
                    },
                    // { title: 'Legal agreement', field: 'legalAgreement' },
                    {
                      // title: 'Data processing agreement',
                      title:
                        'Agree to terms & conditions and privacy policy agreement',
                      field: 'dataProcessingAgreement',
                    },
                  ],
                  [
                    {
                      // title: 'Data processing agreement',
                      title:
                        'Transfer personal information to third parties TnC',
                      field: 'transferPersonlaInfo',
                    },
                  ],
                  // [
                  //   { title: 'Points balance', field: 'pointsBalance' },
                  //   {
                  //     title: 'Total lifetime points used',
                  //     field: 'totalLifetimePointsUsed',
                  //   },
                  //   {
                  //     title: 'Total lifetime points expired',
                  //     field: 'totalLifetimePointsExpired',
                  //   },
                  // ],
                  // [{ title: 'TPE', field: 'tpe' }],
                  // [{ title: 'Level Privilege', field: 'levelPrivilege' }],
                  [{ title: 'Status', field: 'status' }],
                  [{ title: 'Available campaign', field: 'availableCampaign' }],
                  [{ title: 'Test User', field: 'isPreviewTesterDisplay' }],
                  [{ title: 'Coupons', field: 'coupons' }],
                ]}
                extraDataFields={{
                  availableCampaign: avaliableCampaigns.map(
                    (item) => item.name,
                  ),
                  coupons: coupons?.map((item) => item.template?.name),
                }}
              />,
              // <MembershipInfoCard
              //   title={'Personal Information'}
              //   data={customer}
              //   fields={[
              // [{ title: 'First name', field: 'firstName' }],
              // [{ title: 'Last name', field: 'lastName' }],
              // [{ title: 'Chinese First name', field: 'chineseFirstName' }],
              // [{ title: 'Chinese Last name', field: 'chineseLastName' }],
              // [{ title: 'Preferred name (optional)', field: 'nickname' }],
              // [{ title: 'Gender (optional)', field: 'displayGender' }],
              // [{ title: 'Birth date', field: 'displayDateOfBirth' }],
              // [
              //   {
              //     title: 'Preferred message language',
              //     field: 'preferredMessageLanguageDisplay',
              //   },
              // ],
              // [{ title: 'Assign to test customer', field: 'assignToTest' }],
              //   ]}
              // />,
              // <MembershipInfoCard
              //   title={'Contact Information'}
              //   data={customer}
              //   fields={[
              //     // [{ title: 'Mobile number', field: 'mobileNumber' }],
              //     // [{ title: 'Email', field: 'email' }],
              //     [
              //       {
              //         title: 'Social media (optional)',
              //         field: 'socialMedia',
              //       },
              //     ],
              //     [{ title: 'Address (optional)', field: 'address' }],
              //   ]}
              // />,
            ]}
            hidePreview={true}
            // sectionStyle="detail-info-section-item"
          />
        </div>
      ),
    },
    {
      name: URLFragments.records,
      content: (
        <div className="scroll-container-common">
          <ContentSections
            sections={[
              <RecordTableCard
                title={'Transaction'}
                data={transactions?.slice(0, 10)}
                fields={[
                  { displayName: 'ID', name: 'pk' },
                  { displayName: 'Event create date', name: 'createdDate' },
                  {
                    displayName: 'Transaction type',
                    name: 'transactionDisplayType',
                  },
                  { displayName: 'Event type', name: 'eventType' },
                  { displayName: 'BrandName', name: 'brand' },
                  { displayName: 'Store name', name: 'displayStoreName' },
                  {
                    displayName: 'Transaction value($)',
                    name: 'transactionValue',
                  },
                ]}
                viewAllLink={`/transactions?search=${
                  customer.nickname ? customer.nickname : customer.owner
                }`}
                buttonRequires={PermissionCodes.viewTransaction}
              />,
              <RecordTableCard
                title={'Coupons'}
                data={couponTransactions.slice(0, 10)}
                fields={[
                  { displayName: 'ID', name: 'pk' },
                  { displayName: 'Event create date', name: 'createdDate' },
                  { displayName: 'Transaction type', name: 'displayType' },
                  { displayName: 'Event type', name: 'eventType' },
                  { displayName: 'Expired time', name: 'couponExpiryDate' },
                ]}
                viewAllLink={`/coupon_records?search=${customer.membershipId}`}
                tableWidth={694}
                buttonRequires={PermissionCodes.viewCouponTransaction}
              />,
              <RecordTimelineCard
                title={'points and level'}
                data={activityLogs}
              />,
            ]}
            hidePreview={true}
            sectionStyle="detail-record-section-item"
          />
        </div>
      ),
    },
  ];

  if (
    checkHasPermission(user, PermissionCodes.viewGoodieBagParticipationRecord)
  ) {
    tabs.splice(1, 0, {
      name: URLFragments.gooiebagRecord,
      content: (
        <BaseTabListContainer
          hideTab={true}
          tabs={[]}
          tabTitle={'Goodie Bag Acquired Summary'}
          // groupActions={groupActions}
          // defaultTabKey={levels}
          pageInfo={customerParticipationRecords?.pageInfo}
          totalCount={customerParticipationRecords?.totalCount}
          searchPlaceholder="Search by goodie bag name"
          needMobile
          // onSearch={(key) => {
          //   setGoodieBagRecordSearchKey(key);
          //   setGoodieBagRecordPage(1);
          // }}
          listContent={
            <BaseListContainer
              fields={[
                {
                  displayName: 'Goodie Bag',
                  fieldName: 'name',
                  linked: true,
                },
                {
                  displayName: 'Acquire Date',
                  fieldName: 'acquireDate',
                },
                {
                  displayName: 'Credit Used / Total',
                  fieldName: 'creditUsedTotal',
                },
                {
                  displayName: 'Quota Used / Total',
                  fieldName: 'quotaUsedTotal',
                },
                {
                  displayName: 'Status',
                  fieldName: 'status',
                },
              ]}
              hideCheckbox
              deleteInfo={{}}
              dataList={customerParticipationRecords?.data}
              totalPage={customerParticipationRecords?.totalPage}
              model={'goodieBags'}
              permissionGroup={PermissionCodes.goodieBagParticipationRecord}
              actions={['Detail']}
              // onPageChange={(page) => {
              //   console.log('@@250: ', page);
              //   setGoodieBagRecordPage(page + 1);
              // }}
            />
          }
          hideSearch={isMobile}
          hideActionHeader={isMobile}
          hideTabNav={isMobile}
          mobileListContent={
            <BaseMobileListContainer
              header={
                <CustomerMobileListHeader
                  title={'Goodie Bag Acquired Summary'}
                  titleClassName="goodiebag-acquire-summary-title"
                  totalCount={customerParticipationRecords?.totalCount}
                  searchTitle={'Search by goodie bag name'}
                  // onSearch={(key) => {
                  //   setGoodieBagRecordSearchKey(key);
                  //   setGoodieBagRecordPage(1);
                  // }}
                />
              }
              dataList={customerParticipationRecords?.data}
              renderItem={(item, index) => (
                <CustomerListCard
                  item={item}
                  fields={[
                    { name: 'Goodie bag', value: item.name, isLink: true },
                    { name: 'Acquire Date Time', value: item.acquireDate },
                    {
                      name: 'Credit Used / Acquired',
                      value: item.creditUsedTotal,
                    },
                    {
                      name: 'Quota Used / Acquired',
                      value: item.quotaUsedTotal,
                    },
                  ]}
                  index={index}
                  canView={
                    user.isSuperuser ||
                    user.userPermissions.includes(
                      `view_${PermissionCodes.goodieBagParticipationRecord}`,
                    )
                  }
                  hideAction={true}
                />
              )}
              hasNext={customerParticipationRecords?.pageInfo.hasNextPage}
              loadMore={() => {
                // setGoodieBagRecordPage(goodieBagRecordPage + 1);
                // console.log('@@374: loadmore');
                const searchDict = queryString.parse(location.search);
                const stringPage = searchDict['page'] || 1;
                const page = parseInt(stringPage);
                history.push({
                  pathname: location.pathname,
                  hash: location.hash,
                  search: queryString.stringify({
                    ...searchDict,
                    page: page + 1,
                  }),
                });
              }}
              listHeight="calc(100vh - 60px - 207px - 48px - 60px)"
              listStyle={{ marginBottom: '30px' }}
            />
          }
        />
      ),
    });
  }

  // const getFilterConditionsFromUrl = () => {
  //   const queryString = require('query-string');
  //   const querySearch = queryString.parse(location.search);
  //   const searchCreateAt = querySearch['record_period'] || '';
  //   const searchLogBy = querySearch['log_by'] || '';
  //   const searchActivityGroupType = querySearch['group_type'] || '';

  //   let filterConditions = {
  //     creation_date_range: searchCreateAt,
  //     log_by: searchLogBy,
  //     group_activity_type_in: searchActivityGroupType,
  //   };
  //   return filterConditions;
  // };

  if (checkHasPermission(user, PermissionCodes.viewCustomerActivityLog)) {
    tabs.push({
      name: URLFragments.customerActivityLog,
      content: (
        <BaseTabListContainer
          hideTab={true}
          tabs={[]}
          pageInfo={activityLogsVersion2PageInfo}
          totalCount={activityLogsVersion2TotalCount}
          filter={{
            hasFilter: true,
            customFilterAreaClass: 'all-list-filter-container-without-search',
            offset: '360px',
            componment: CustomerActivityLogFilter,
          }}
          listContent={
            <BaseListContainer
              fields={activityLogsVersion2ListFields}
              hideCheckbox
              deleteInfo={{}}
              dataList={activityLogsVersion2}
              totalPage={activityLogsVersion2TotalPage}
              model={'customerActivityLog'}
              permissionGroup={PermissionCodes.customerActivityLog}
              actions={[]}
              hideActions={true}
              customClassName={'customer-activity-logs'}
            />
          }
          hideSearch={true}
        />
      ),
    });
  }

  let buttons = [];
  if (customer.status && customer.status !== StatusTag.deleted) {
    buttons.push(
      <AuthButton
        title="Edit"
        action={() => {
          dispatch({ type: 'customerList/clearData' });
          history.push({
            pathname: 'edit/',
          });
        }}
        requires={PermissionCodes.changeCustomer}
      />,
    );
  }

  const [activeTab, setActiveTab] = useState(tabs[0].name);

  useEffect(() => {
    dispatch({ type: 'customerList/clearData' });
    dispatch({ type: 'goodieBags/clearData' });
    return () => {
      dispatch({ type: 'customerList/clearData' });
      dispatch({ type: 'goodieBags/clearData' });
    };
  }, [dispatch]);

  useEffect(() => {
    if (activeTab === URLFragments.profile) {
      dispatch(createAction('customerList/getOneCustomer')({ id }));
    }

    if (activeTab === URLFragments.gooiebagRecord) {
      const searchDict = queryString.parse(location.search);
      // const couponTransactionType = searchDict['type'];
      const searchKey = searchDict['search'];
      const stringPage = searchDict['page'] || 1;
      const page = parseInt(stringPage);
      if (isMobile) {
        if (
          page > 1 &&
          customerParticipationRecords?.data?.length < (page - 1) * 20
        ) {
          history.push({
            pathname: location.pathname,
            hash: location.hash,
            search: queryString.stringify({
              ...searchDict,
              page: 1,
            }),
          });
          return;
        }

        dispatch(
          createAction('goodieBags/getCustomerGoodieBagParticipationRecords')({
            page: page,
            filter: {
              customer: id,
              filterName: searchKey,
            },
            isLoadMore: true,
          }),
        );
      } else {
        dispatch(
          createAction('goodieBags/getCustomerGoodieBagParticipationRecords')({
            page: page,
            filter: {
              customer: id,
              filterName: searchKey,
            },
            isLoadMore: false,
          }),
        );
      }
    }

    const locationHash = decodeURI(location.hash).substring(1);
    if (locationHash !== activeTab) {
      setActiveTab(locationHash);
    }
  }, [activeTab, id, location]);

  useEffect(() => {
    if (!customer || !customer?.pk) {
      return;
    }
    if (activeTab === URLFragments.records) {
      dispatch(
        createAction('campaignList/getCampaignList')({
          ssoUid: customer.ssoUid,
          isAll: true,
          getAll: 'true',
        }),
      );
      dispatch(
        createAction('singleCoupon/getList')({
          ssoUid: customer.ssoUid,
          isAll: true,
          needTemplate: true,
          isExpired: 'false',
          isUsed: 'false',
          orderBy: '-pk',
          pageSize: 100,
          // getAllTemplate: true,
        }),
      );
      dispatch(
        createAction('customerList/getCustomerActivityLog')({
          ssoUid: customer.ssoUid,
        }),
      );
      dispatch(
        createAction('couponTransactions/getCouponTransactions')({
          searchKey: customer.membershipId,
        }),
      );
      dispatch(
        createAction('transactions/getCurrentPageTransactions')({
          searchKey: customer.membershipId,
          rank: true,
        }),
      );
    }
    if (activeTab === URLFragments.customerActivityLog) {
      const queryString = require('query-string');
      const querySearch = queryString.parse(location.search);
      const searchCreateAt = querySearch['record_period'] || '';
      const searchLogBy = querySearch['log_by'] || '';
      const searchActivityGroupType = querySearch['group_type'] || '';
      const sort = querySearch['sort'] || '';
      const page = parseInt(querySearch['page'] || 1);
      dispatch(
        createAction('customerList/getCustomerActivityLogVersion2')({
          ssoUid: customer.ssoUid,
          createAt: searchCreateAt,
          logBy: searchLogBy,
          activityGroupTypeIn: searchActivityGroupType,
          sort: sort,
          page,
        }),
      );
    }
  }, [activeTab, id, location, customer]);

  return (
    <div className="customer">
      <div className="container-common scroll-container-common-header mobile">
        <Button onClick={() => history.goBack()}>
          <img src={BackIcon} />
          <span>Back</span>
        </Button>
        <label className="caution-text">{customer.name}</label>
      </div>
      <CustomListComponent
        hideHeader={isMobile}
        caution={{
          detail: '',
          title: customer.name,
        }}
        defaultActiveKey={activeTab}
        breadcrumb={<CustomBreadcrumb name={customer.name} />}
        buttons={buttons}
        tabs={tabs}
        onTabChange={(key) => {
          // setActiveTab(key);
          // setGoodieBagRecordPage(1);
          history.push({
            location: location.pathname,
            hash: key,
          });
        }}
      />
      {isMobile &&
      customer &&
      customer.status !== StatusTag.deleted &&
      checkHasPermission(user, PermissionCodes.changeCustomer) ? (
        <OnlySaveButton
          saveRequires={PermissionCodes.changeCustomer}
          baseTempSaveArea="customer-detail-bottom-buttons"
          saveAction={() => {
            history.push(`/customers/${customer.pk}/edit/`);
          }}
          saveText={'Edit'}
        />
      ) : null}
    </div>
  );
}

export default CustomerOverviewDetail;
