import React from 'react';
import { useFormContext } from 'react-hook-form';
import { hasError, errorMessage } from '../base/ErrorFieldMessage';
import { Collapse } from 'react-bootstrap';
import { GoodieBagErrorHandleField } from './CreateGoodieBagValidate';
import CustomRadios from '../base/CustomRadios';
import { Row } from 'react-bootstrap';
import CustomDateTimeSelect from '../base/CustomDateTimeSelect';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import { COUPON_ACQUIRED_PERIOD_OPTIONS } from '../../models/GoodieBagsModel';
import NumberInput from './NumberInput';

function CouponAcquiredPeriodSection() {
  const { watch, setValue, formState } = useFormContext();
  const errors = formState.errors;
  const watchCouponAcquirePeriodType =
    watch('couponAcquirePeriodType') || COUPON_ACQUIRED_PERIOD_OPTIONS[0].value;
  const watchAbsoluteStartDate = watch('absoluteStartDate');
  const watchAbsoluteExpiryDate = watch('absoluteExpiryDate');
  const watchNumberOfDaysToExpireAfterAcquisition = watch(
    'numberOfDaysToExpireAfterAcquisition',
  );

  return (
    <>
      <label className="create-section-title">Coupon Acquired Period</label>
      <label className="section-short-description">
        Available time to acquire the coupons in this goodie bag.
      </label>
      <label className="create-section-label create-section-label-bottom-space">
        Valid period
      </label>
      <Row className="featured-exclusive-row">
        <CustomRadios
          onChange={(value) => {
            setValue('couponAcquirePeriodType', value, { shouldDirty: true });
          }}
          default={watchCouponAcquirePeriodType}
          options={COUPON_ACQUIRED_PERIOD_OPTIONS}
        />
      </Row>
      <Collapse
        in={
          watchCouponAcquirePeriodType ===
          COUPON_ACQUIRED_PERIOD_OPTIONS[1].value
        }
      >
        <div>
          <div className="create-section-label create-section-label-bottom-space">
            Start date
          </div>
          <CustomDateTimeSelect
            error={hasError(errors, 'absoluteStartDate')}
            disabled={
              !(
                watchCouponAcquirePeriodType ===
                COUPON_ACQUIRED_PERIOD_OPTIONS[1].value
              )
            }
            defaultTime={watchAbsoluteStartDate}
            onTimeChange={(date) => {
              setValue('absoluteStartDate', date, { shouldDirty: true });
            }}
          />
          <div
            className={`tips-message 
							${
                hasError(errors, 'absoluteStartDateBeyondTheRange')
                  ? 'goodie-bag-absolute-date-error'
                  : ''
              }`}
          >
            The selected time must be within the active period.
          </div>
          <ErrorFieldMessage
            id={GoodieBagErrorHandleField.absoluteStartDateBeyondTheRange.name}
            error={hasError(errors, 'absoluteStartDate')}
            message={errorMessage(errors, 'absoluteStartDate')}
          />
          <div className="create-section-label create-section-label-bottom-space">
            Expire date
          </div>
          <CustomDateTimeSelect
            error={hasError(errors, 'absoluteExpiryDate')}
            defaultTime={watchAbsoluteExpiryDate}
            disabled={
              !(
                watchCouponAcquirePeriodType ===
                COUPON_ACQUIRED_PERIOD_OPTIONS[1].value
              )
            }
            onTimeChange={(date) => {
              setValue('absoluteExpiryDate', date, { shouldDirty: true });
            }}
            seconds={59}
          />
          <div
            className={`tips-message 
							${
                hasError(errors, 'absoluteExpiryDateBeyondTheRange')
                  ? 'goodie-bag-absolute-date-error'
                  : ''
              }`}
          >
            The selected time must be within the active period.
          </div>
          <ErrorFieldMessage
            id={GoodieBagErrorHandleField.absoluteExpiryDate.name}
            error={hasError(errors, 'absoluteExpiryDate')}
            message={errorMessage(errors, 'absoluteExpiryDate')}
          />
        </div>
      </Collapse>
      {watchCouponAcquirePeriodType ===
      COUPON_ACQUIRED_PERIOD_OPTIONS[2].value ? (
        <NumberInput
          labelText="No. of days"
          type="days since acquiring"
          valueChange={(value) => {
            setValue('numberOfDaysToExpireAfterAcquisition', value, {
              shouldDirty: true,
            });
          }}
          defaultValue={watchNumberOfDaysToExpireAfterAcquisition}
          errors={errors}
          onError={hasError(errors, 'numberOfDaysToExpireAfterAcquisition')}
          errorFieldName={'numberOfDaysToExpireAfterAcquisition'}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default CouponAcquiredPeriodSection;
