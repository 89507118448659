import React from 'react';
import { connect } from 'react-redux';
import './ContentSection.scss';
import { LanguageConfig, CampaignType } from '../../../config/CustomEnums';
import { useFormContext } from 'react-hook-form';
import {
  getImageUrl,
  isGoodieBagType,
} from '../../../models/CreateCampaignModel';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';
import i18n from '../../../I18n';
import ContentSections from '../../../components/base/ContentSections';

function ContentSection({ languageList, data }) {
  const { getValues } = useFormContext() || {};
  const campaign = data || getValues() || {};
  const translations = campaign.translations || {};
  console.log('@16', campaign);

  const getValueFromTranslations = (language, valueKeys) => {
    const data = {};
    const datas = valueKeys?.forEach((valueKey) => {
      switch (valueKey) {
        case 'coverPhoto': {
          const url = getImageUrl(translations?.[language]?.[valueKey]);
          if (!url || url === '') {
            data[valueKey] = null;
          }
          data[valueKey] = url;
          break;
        }
        case 'detailPhotos': {
          data[valueKey] = translations?.[language]?.[valueKey]?.map((item) => {
            const url = getImageUrl(item);
            if (!url || url === '') {
              return null;
            }
            return url;
          });
          break;
        }
        case 'logoLabels': {
          data[valueKey] = translations?.[language]?.[valueKey]?.map((item) => {
            return item.name;
          });
          break;
        }
        case 'tourCards': {
          if (language === LanguageConfig.english) {
            data[valueKey] = campaign?.tourCards?.map((item) => {
              return {
                title: item?.title,
                coverPhoto: item?.coverPhoto,
              };
            });
          } else {
            data[valueKey] = campaign?.tourCards
              ?.map((item) => {
                return {
                  title: item?.translations?.[language]?.title,
                  coverPhoto: item?.translations?.[language]?.coverPhoto,
                };
              })
              .filter((item) => item.coverPhoto);
          }
          break;
        }
        default: {
          data[valueKey] = translations?.[language]?.[valueKey];
        }
      }
    });

    return data;
  };

  const renderTabForLanguage = (language) => {
    const data = getValueFromTranslations(language, [
      'generalName',
      'shortDescription',
      'coverPhoto',
      'detailPhotos',
      'tourCardSectionTitle',
      'tourCards',
      'logoLabels',
      'offerDetailTitle',
      'offerDetailContent',
      'instructionSectionTitle',
      'instructionSectionContent',
      'merchantTermsAndConditionsTitle',
      'merchantTermsAndConditionsContent',
      'genericTermsAndConditionsTitle',
      'genericTermsAndConditionsContent',
    ]);

    return (
      <MembershipInfoCard
        title={i18n.t('content', { locale: 'en' })}
        data={data}
        fields={
          campaign.campaignType === CampaignType.fortuneBagForGoodieBagCampaign
            ? [
                [
                  {
                    title: 'Title',
                    field: 'generalName',
                  },
                ],
              ]
            : [
                [
                  {
                    title: 'Title',
                    field: 'generalName',
                  },
                ],
                [
                  {
                    title: i18n.t('short_description', { locale: 'en' }),
                    field: 'shortDescription',
                    isHtml: true,
                  },
                ],
                [
                  {
                    title: 'Cover image',
                    field: 'coverPhoto',
                  },
                ],
                [
                  {
                    title: 'Image',
                    field: 'detailPhotos',
                  },
                ],
                [
                  {
                    title: 'Tour card title',
                    field: 'tourCardSectionTitle',
                  },
                ],
                [
                  {
                    title: 'Tour card',
                    field: 'tourCards',
                  },
                ],
                [
                  {
                    title: 'logo Label',
                    field: 'logoLabels',
                  },
                ],
                [
                  {
                    title: 'Offer detail title',
                    field: 'offerDetailTitle',
                  },
                ],
                [
                  {
                    title: 'Offer detail content',
                    field: 'offerDetailContent',
                    isHtml: true,
                  },
                ],
                [
                  {
                    title: 'Instruction section title',
                    field: 'instructionSectionTitle',
                  },
                ],
                [
                  {
                    title: 'Instruction section content',
                    field: 'instructionSectionContent',
                    isHtml: true,
                  },
                ],
                [
                  {
                    title: 'Merchant Terms & Conditions Title',
                    field: 'merchantTermsAndConditionsTitle',
                  },
                ],
                [
                  {
                    title: 'Merchant Terms & Conditions Content',
                    field: 'merchantTermsAndConditionsContent',
                    isHtml: true,
                  },
                ],
                [
                  {
                    title: 'Generic Terms & Conditions Title',
                    field: 'genericTermsAndConditionsTitle',
                  },
                ],
                [
                  {
                    title: 'Generic Terms & Conditions Content',
                    field: 'genericTermsAndConditionsContent',
                    isHtml: true,
                  },
                ],
              ]
        }
      />
    );
  };

  const isShowAllLanguages =
    campaign.campaignType === CampaignType.fortuneBagCampaign ||
    isGoodieBagType(campaign.campaignType);

  return (
    <ContentSections
      hidePreview
      languageTabContent={{
        containers: (isShowAllLanguages
          ? languageList
          : campaign.selectedLanguages?.sort(
              (first, second) => first?.pk - second?.pk,
            )
        )?.map((item) => {
          return {
            container: [renderTabForLanguage(item.code)],
            key: item.code,
            title: item.sourceName || item.name,
          };
        }),
      }}
      activeSection={LanguageConfig.english}
      // sectionStyle=""
      contentStyle="campaign-detail-language-content-sections"
    />
  );
}
const mapPropsToState = (state) => ({
  languageList: state.language.allList,
});

export default connect(mapPropsToState)(ContentSection);
