import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createAction } from '../../utils';
import { CreateTransactionError } from '../../models/CreateTransactionModel';
import RedirectToStorePrompt from './RedirectToStorePrompt';
import { CustomTitleWithDropDown } from '../customer/CustomTitleWithDropDown';
import { PermissionCodes } from '../../config/PermissionCodes';

function StoreSelectDropdown(props) {
  const dispatch = useDispatch();

  const defaultSelectedStore = props.selectedStore;
  const selectStore = props.selectStore || (() => {});
  const hideAddButton = props.hideAddButton || false;
  const addButtonRequires = props.addButtonRequires;

  const { storeList, errorFields } = useSelector((state) => ({
    storeList: state.storeModel.storeList,
    errorFields: state.createTransaction.errorFields,
  }));
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    dispatch(
      createAction('storeModel/getPagedStoreList')({
        isSelectorLoad: true,
        search: '',
      }),
    );
  }, [dispatch]);

  return (
    <div>
      <CustomTitleWithDropDown
        title="Store"
        defaultValue={{
          value: defaultSelectedStore,
          label: defaultSelectedStore?.storeName,
        }}
        source={storeList}
        setValue={(item) => {
          selectStore(item.value);
        }}
        addButtonInfo={
          hideAddButton
            ? null
            : {
                title: 'Add',
                action: () => setShowPrompt(true),
                customClass:
                  'general-section-add-new-brand btn-add-button-common',
                requires: PermissionCodes.addStore,
              }
        }
        errors={errorFields}
        errorName={CreateTransactionError.store.name}
        loadMoreAction={'storeModel/getPagedStoreList'}
        filterAction={'storeModel/getPagedStoreList'}
      />
      <RedirectToStorePrompt
        show={showPrompt}
        onHide={() => {
          setShowPrompt(false);
        }}
      />
    </div>
  );
}

export default StoreSelectDropdown;
