import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { createAction } from '../../../utils';
import ContentSections from '../../../components/base/ContentSections';
import {
  APIStatus,
  LanguageConfig,
  LanguageUpperConfig,
} from '../../../config/CustomEnums';
import Loading from '../../../components/base/Loading';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';

export default function MarketDetail() {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  const { market, status } = useSelector((state) => ({
    market: state.targetMarket.detail,
    status: state.loading.status,
  }));

  const tabs = [
    {
      name: 'Detail',
      content: (
        <ContentSections
          sections={
            status === APIStatus.calling
              ? [<Loading />]
              : [
                  <MembershipInfoCard
                    title="information"
                    data={market}
                    fields={[
                      [{ title: 'Name', field: 'sourceName' }],
                      [{ title: 'Parameter', field: 'parameter' }],
                      [
                        {
                          title: 'Support Language(Parameter)',
                          field: 'supportLanguages',
                        },
                      ],
                      [{ title: 'Related Channel', field: 'relatedChannel' }],
                      [
                        {
                          title: 'Related Campaign',
                          field: 'relatedCampaigns',
                          linkTo: 'campaigns',
                        },
                      ],
                    ]}
                  />,
                ]
          }
          hidePreview
          // sectionStyle="detail-info-section-item"
        />
      ),
    },
  ];

  const buttons = [
    <AuthButton
      title="Edit"
      action={() => {
        // dispatch({ type: 'customerList/clearData' });
        dispatch({ type: 'targetMarket/clearData' });
        history.push({
          pathname: 'edit/',
        });
      }}
      requires={PermissionCodes.changeTargetMarket}
    />,
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].name);

  useEffect(() => {
    if (params.id) {
      dispatch(
        createAction('targetMarket/getOneDetail')({
          id: params.id,
          view: true,
        }),
      );
    }
    return () => {
      dispatch({ type: 'targetMarket/clearData' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  console.log('@@94: ', market);
  return (
    <div className="campaign-category-detail">
      <CustomListComponent
        caution={{
          detail: '',
          title: market.name,
        }}
        defaultActiveKey={activeTab}
        breadcrumb={<CustomBreadcrumb name={market.name} />}
        buttons={buttons}
        tabs={tabs}
        onTabChange={(key) => {
          setActiveTab(key);
        }}
      />
    </div>
  );
}
