import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { createAction } from '../../../utils';

import CustomTitleWithInput from '../../../components/base/CustomTitleWithInput';
import { hasError } from '../../../components/base/ErrorFieldMessage';
import ContentSections from '../../../components/base/ContentSections';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import { APIStatus } from '../../../config/CustomEnums';
import BasePrompt from '../../../components/base/prompt/BasePrompt';
import { PermissionCodes } from '../../../config/PermissionCodes';
import BaseMutipleSelectorV2 from '../../../components/base/BaseMultipleSelectorV2';

function MarketContent({}) {
  const history = useHistory();
  const {
    formState: { errors },
    setValue,
    watch,
    register,
  } = useFormContext();

  const watchSourceName = watch('sourceName');
  const watchParameter = watch('parameter');
  const watchLanguages = watch('selectedLanguages') || [
    { pk: 1, name: 'English(EN)', disabled: true },
  ];

  console.log('watchLanguage:', watchLanguages);

  const { langaugeList } =
    useSelector((state) => ({
      langaugeList: state.language.notPagedAllList,
    })) || [];
  const dataList = langaugeList.map((lang) => {
    if (lang.pk === 1) {
      return { ...lang, disabled: true };
    }
    return lang;
  });

  const [addPromptConfig, setAddPromptConfig] = useState(null);

  return (
    <>
      <label className="create-section-title">General</label>

      <CustomTitleWithInput
        title="Name"
        defaultValue={watchSourceName}
        setValue={(value) => {
          setValue('sourceName', value, {
            shouldDirty: true,
          });
        }}
        error={
          hasError(errors, 'sourceName')
            ? {
                id: 'name',
                error: hasError(errors, 'sourceName'),
                message: 'please input a name',
              }
            : {}
        }
      />
      <CustomTitleWithInput
        title="Parameter"
        defaultValue={watchParameter}
        setValue={(value) => {
          setValue('parameter', value, {
            shouldDirty: true,
          });
        }}
        error={
          hasError(errors, 'parameter')
            ? {
                id: 'parameter',
                error: hasError(errors, 'parameter'),
                message: 'please input a capital alphabetical parameter',
              }
            : {}
        }
        customClass="custom-input-short custom-input-upper"
        extra={{ maxLength: '2' }}
      />
      <label className="tips-message">2 digit, capital alphabetical </label>

      <BaseMutipleSelectorV2
        title={'Support Language'}
        namespace="language"
        data={{
          sourceData: dataList,
          targetData: watchLanguages,
          targetChange: (value) => {
            setValue('selectedLanguages', value, {
              shouldDirty: true,
            });
          },
        }}
        error={
          hasError(errors, 'selectedLanguages')
            ? {
                id: 'languages',
                error: hasError(errors, 'languages'),
                message: 'please select one language',
              }
            : {}
        }
      />

      <BasePrompt
        show={addPromptConfig ? true : false}
        closeAction={() => {
          setAddPromptConfig(null);
        }}
        rightButton={
          addPromptConfig
            ? {
                text: addPromptConfig?.button.text,
                action: () =>
                  history.push({
                    pathname: addPromptConfig?.button.actionPath,
                    state: {
                      from: history.location,
                      title: 'continue title',
                      description: 'continue content',
                    },
                  }),
              }
            : null
        }
        title={addPromptConfig?.title}
        description={addPromptConfig?.description}
      />
    </>
  );
}

export default function CreateMarketContent({}) {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const { getValues, setError, clearErrors } = useFormContext();

  const validate = async () => {
    clearErrors(['sourceName', 'parameter', 'selectedLanguages']);
    const [sourceName, parameter, selectedLanguages] = getValues([
      'sourceName',
      'parameter',
      'selectedLanguages',
    ]);

    if (!sourceName) {
      setError(
        'sourceName',
        {
          type: 'require',
          message: 'please input name',
        },
        { shouldFocus: true },
      );
    }

    let parameterOK = true;
    if (
      !parameter ||
      !parameter.match(/[A-Z]/g) ||
      parameter.match(/[A-Z]/g).length < 2
    ) {
      setError(
        'parameter',
        {
          type: 'require',
          message: 'please input alphabetical parameter',
        },
        { shouldFocus: true },
      );

      parameterOK = false;
    }

    if (selectedLanguages === undefined || selectedLanguages?.length <= 0) {
      setError(
        'selectedLanguages',
        {
          type: 'require',
          message: 'please selecte one Language',
        },
        { shouldFocus: true },
      );
    }
    return sourceName && parameterOK; // && selectedLanguages?.length > 0;
  };

  return (
    <>
      <ContentSections sections={[<MarketContent />]} hidePreview={true} />
      <SaveAndBackButtons
        saveText={params.id ? 'Update' : 'Save'}
        backAction={() => history.goBack()}
        saveAction={async () => {
          const isValid = await validate();
          if (isValid) {
            const data = getValues();
            if (data.selectedLanguages === undefined) {
              data.selectedLanguages = [{ pk: 1, name: 'English(EN)' }];
            }

            dispatch(createAction('targetMarket/createOrUpdate')(data));
          }
        }}
      />
    </>
  );
}
