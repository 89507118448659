import { call, put } from 'redux-saga/effects';
import { APIStatus } from '../config/CustomEnums';
import { ToastType } from '../models/NavBarModel';

export function* loading(
  apiServiceWithArgs,
  onSuccess = () => {},
  onFailed = () => {},
  onArgumentsError = () => {},
) {
  yield operations(
    apiServiceWithArgs,
    onSuccess,
    onFailed,
    onArgumentsError,
    true,
  );
}

export function* apiWithResponseHandle(
  apiServiceWithArgs,
  onSuccess = () => {},
  onFailed = () => {},
  onArgumentsError = () => {},
) {
  yield operations(
    apiServiceWithArgs,
    onSuccess,
    onFailed,
    onArgumentsError,
    false,
  );
}

function* operations(
  apiServiceWithArgs,
  onSuccess,
  onFailed,
  onArgumentsError,
  isLoading,
) {
  if (isLoading) {
    yield put({
      type: 'loading/updateState',
      payload: { status: APIStatus.calling },
    });
  }
  const apiResult = yield call(...apiServiceWithArgs);
  console.log('operations:', apiResult);

  if (!apiResult || !apiResult.status || apiResult.status >= 300) {
    if (isLoading) {
      yield put({
        type: 'loading/updateState',
        payload: {
          status: APIStatus.failed,
        },
      });
    }

    if (apiResult.status !== 409) {
      yield put({
        type: 'navBars/updateState',
        payload: {
          saveDiscardToastShowing: {
            value: true,
            type: ToastType.serverError,
          },
        },
      });
    }

    yield onFailed(apiResult);
    return;
  }

  const successData = apiResult.data?.data;
  try {
    const keys = Object.keys(successData);
    const updateKey = keys[0];
    const updateErrors =
      updateKey === 'manualUseCoupon' ||
      updateKey === 'getCouponInfoBySrk' ||
      updateKey === 'createEntitlementTrackingCode'
        ? []
        : successData[updateKey]
        ? successData[updateKey].errors || []
        : [];
    const otherErrors = apiResult.data?.errors || [];
    let arguError = false;
    let errorMessage = '';
    if (updateErrors?.length || otherErrors?.length) {
      const updateErrorsMessages = updateErrors[0]?.messages || [];
      arguError = true;
      errorMessage = otherErrors[0]?.message || updateErrorsMessages[0];
    }
    if (arguError) {
      if (errorMessage === 'User is disabled') {
        yield put({ type: 'users/logout' });
        return;
      }
      if (isLoading) {
        yield put({
          type: 'loading/updateState',
          payload: {
            status: APIStatus.failed,
          },
        });
      }
      if (
        errorMessage?.startsWith(
          'You are not allowed to perform the action (',
        ) &&
        apiResult.data?.data?.customers === null
      ) {
        // only for check membership id
        successData['errorMessage'] = errorMessage;
      } else {
        yield put({
          type: 'navBars/updateState',
          payload: {
            saveDiscardToastShowing: {
              value: true,
              type: errorMessage,
            },
          },
        });
        yield onArgumentsError(apiResult.data);
        return;
      }
    }
  } catch (e) {
    console.log('check api error:', e);
  }

  yield onSuccess(successData);
  if (isLoading) {
    yield put({
      type: 'loading/updateState',
      payload: {
        status: APIStatus.success,
      },
    });
  }
}
