import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { connect, useDispatch, useSelector } from 'react-redux';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { createAction, getHashKeyString } from '../../../utils';

import { PermissionCodes } from '../../../config/PermissionCodes';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import { NavBarNames } from '../../../config/NavBarNameList';
import './RateLimitList.scss';

export default function RateLimitList() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const { totalCount, pageInfo, totalPage, displayFields, pagedList } =
    useSelector((state) => ({
      totalCount: state.rateLimit.totalCount,
      pageInfo: state.rateLimit.pageInfo,
      totalPage: state.rateLimit.totalPage,
      displayFields: state.rateLimit.listDisplayFields,
      pagedList: state.rateLimit.pagedList,
    }));

  const tabs = [
    {
      name: 'List',
      content: (
        <BaseTabListContainer
          hideTab={true}
          hideSearch={true}
          totalCount={totalCount || 0}
          pageInfo={pageInfo}
          filter={{ hasFilter: false, componment: null }}
          listContent={
            <BaseListContainer
              hideCheckbox={true}
              fields={displayFields}
              model={'rateLimit'}
              customClassName={'rate-limit-list-table'}
              // permissionGroup={PermissionCodes.otpChannel}
              dataList={pagedList}
              totalPage={totalPage}
              deleteInfo={{
                data: [],
                title: 'Rate Limit Control',
                relatedName: '',
                onComfirm: {},
                relatedSections: [],
              }}
              actions={['Edit']}
              showTableLeftShadow={false}
              showTableRightShadow={false}
              showPlaceholderAction={true}
              hidePagination={true}
            />
          }
        />
      ),
    },
  ];

  useEffect(() => {
    dispatch(createAction('rateLimit/getList')({}));
  }, []);

  return (
    <>
      <CustomListComponent
        caution={{
          detail: '',
          title: NavBarNames.rateLimit,
        }}
        defaultActiveKey={tabs[0].name}
        breadcrumb={<CustomBreadcrumb />}
        buttons={[]}
        tabs={tabs}
        hideTab={true}
      />
    </>
  );
}
