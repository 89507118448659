import React, { useEffect, useState, useRef } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import { ArrayStringData, createAction } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';

function Filter({ backAction = () => {} }) {
  const location = useLocation();
  const dispatch = useDispatch();

  const parsedSearch = queryString.parse(location.search);
  const searchStatus = parsedSearch['review_status'] || '';
  const searchMonth = parsedSearch['month'] || '';
  const [status, setStatus] = useState(searchStatus);
  const [month, setMonth] = useState(searchMonth);
  const content = location.hash ? [
      {
        title: 'Review status',
        data: [
          { name: 'Merchant agreed', pk: 'MERCHANT_AGREED' },
          { name: 'Waiting for review', pk: 'WAITING_FOR_REVIEW' },
          { name: 'Merchant disagreed', pk: 'MERCHANT_DISAGREED' },
        ],
        value: status,
        setValue: setStatus,
        componment: FilterTypeEnum.singleChoice,
      },
      {
        title: 'Month',
        data:[
          {name: 'January', pk: '1'},
          {name: 'February', pk: '2'},
          {name: 'March', pk: '3'},
          {name: 'April', pk: '4'},
          {name: 'May', pk: '5'},
          {name: 'June', pk: '6'},
          {name: 'July', pk: '7'},
          {name: 'August', pk: '8'},
          {name: 'September', pk: '9'},
          {name: 'October', pk: '10'},
          {name: 'November', pk: '11'},
          {name: 'December', pk: '12'},
        ],
        value: month,
        setValue: setMonth,
        componment: FilterTypeEnum.singleChoice,
      },
  ]:[
    {
      title: 'Review status',
      data: [
        { name: 'Merchant agreed', pk: 'MERCHANT_AGREED' },
        { name: 'Waiting for review', pk: 'WAITING_FOR_REVIEW' },
        { name: 'Merchant disagreed', pk: 'MERCHANT_DISAGREED' },
      ],
      value: status,
      setValue: setStatus,
      componment: FilterTypeEnum.singleChoice,
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

export default Filter;
