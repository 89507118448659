import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import BaseForm from '../../../components/base/v2/BaseForm';
import {
  createAction,
  removeFromSessionStorage,
  saveToSessionStorage,
} from '../../../utils';
import { APIStatus, SESSION_KEYS } from '../../../config/CustomEnums';
import CreateRateLimitContent from '../../../components/rateLimit/CreateRateLimitContent';
import Loading from '../../../components/base/Loading';
import BasePrompt from '../../../components/base/prompt/BasePrompt';
import { revertParseRateLimitType } from '../../../models/RateLimitModel';

function CreateRateLimit() {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { rateLimitType } = params;

  const queryString = require('query-string');

  const {
    defaultValues,
    hasUpdatedDefaultValues,
    formHasSubmitted,
    apiStatus,
  } = useSelector((state) => ({
    defaultValues: state.rateLimit.detail,
    hasUpdatedDefaultValues: state.rateLimit.hasUpdatedDefaultValues,
    formHasSubmitted: state.rateLimit.formHasSubmitted,
    apiStatus: state.rateLimit.apiStatus,
  }));

  useEffect(() => {
    if (revertParseRateLimitType(rateLimitType)) {
      dispatch(createAction('rateLimit/getOneDetail')({ rateLimitType }));
    } else {
      history.push({ pathname: '/rate_limit' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, rateLimitType]);

  useEffect(() => {
    return () => {
      dispatch(createAction('rateLimit/clearData')());
    };
  }, []);

  useEffect(() => {
    if (
      formHasSubmitted &&
      apiStatus === APIStatus.success &&
      !history.location.state?.from
    ) {
      history.push({ pathname: '/rate_limit' });
    }
  }, [formHasSubmitted, apiStatus, history]);

  return (
    <>
      <BaseForm
        defaultValues={defaultValues}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={formHasSubmitted}
        tempSave={(save, getValues) => {
          if (save) {
            saveToSessionStorage(
              SESSION_KEYS.RATE_LIMIT_SESSION_KEY,
              getValues(),
            );
          } else {
            removeFromSessionStorage(SESSION_KEYS.RATE_LIMIT_SESSION_KEY);
          }
        }}
        continueCreateConfig={{
          onConfirm: () => {
            history.push({
              ...history.location.state?.from,
              hash: queryString.stringify({
                ...queryString.parse(history.location.state?.from?.hash),
              }),
            });
          },
          onCancel: () => history.push({ pathname: '/rate_limit' }),
          onClose: () => history.push({ pathname: '/rate_limit' }),
        }}
        showFinishPop={
          formHasSubmitted &&
          apiStatus === APIStatus.success &&
          history.location.state?.from
        }
        content={
          rateLimitType && defaultValues?.rateLimitType
            ? [<CreateRateLimitContent />]
            : [<Loading />]
        }
        currentStep={0}
        breadcrumb={
          <CustomBreadcrumb
            name={
              rateLimitType
                ? defaultValues?.rateLimitType
                : 'Rate Limit Control'
            }
          />
        }
        caution={{
          detail: 'TBC',
          title: rateLimitType
            ? defaultValues?.rateLimitType
            : 'Rate Limit Control',
        }}
      />
    </>
  );
}
export default CreateRateLimit;
