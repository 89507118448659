import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import {
  createAction,
  useDeviceDetect,
  checkHasPermission,
} from '../../../utils';
import ContentSections from '../../../components/base/ContentSections';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import BackIcon from '../../../assets/images/backBlue.svg';
import { Button } from 'react-bootstrap';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import CustomerMobileListHeader from './CustomerMobileListHeader';
import CustomerListCard from '../../../components/customer/CustomerListCard';
import BaseMobileListContainer from '../../base/BaseMobileListContainer';
import OverviewCard from '../../../components/dashboard/OverviewCard';
import { SectionItem } from '../../../components/dashboard/CouponsCardNew';
import CustomerGoodieBagRecordCouponFilter from './CustomerGoodieBagRecordCouponFilter';
import { ImportResource } from '../../../models/DownloadImportModel';
import { GoodieBagRewardType } from '../../../config/CustomEnums';
import CustomerGoodieBagRecordTimeExtendPrompt from '../../../components/customer/CustomerGoodieBagRecordTimeExtendPrompt';

function CouponOverviewArea({ customerParticipationRecord }) {
  return (
    <div className="goodiebag-record-coupon-overview">
      {customerParticipationRecord?.goodieBag?.rewardType !==
      GoodieBagRewardType.fortuneBag ? (
        <>
          <label className="create-section-title">
            GOODIE BAG CREDIT/QUOTA
          </label>
          <div className="d-flex flex-row">
            <OverviewCard
              title={'Credit Used / Total'}
              value={customerParticipationRecord?.creditUsedTotal}
            />
            <OverviewCard
              title={'Quota Used / Total'}
              value={customerParticipationRecord?.quotaUsedTotal}
            />
          </div>
        </>
      ) : null}

      <div className="dashbard-coupon-card goodiebag-record-coupon-card">
        <div className="histogram-zoomable-card-chart-title">
          Coupon Overview for This Goodie Bag
        </div>

        <div className="dashboard-goodiebag-card-sections">
          <SectionItem
            label="Total acquired"
            count={customerParticipationRecord?.couponOverview?.totalAcquired}
          />
          <div className="sperate-line"></div>
          <div className="d-flex flex-row">
            <SectionItem
              label="Used"
              count={customerParticipationRecord?.couponOverview?.used}
            />
            <SectionItem
              label="Pending to use"
              count={customerParticipationRecord?.couponOverview?.pendingToUse}
            />
          </div>
          <div className="d-flex flex-row">
            <SectionItem
              label="Expired"
              count={customerParticipationRecord?.couponOverview?.expired}
            />
            <SectionItem
              label="Inactive"
              count={customerParticipationRecord?.couponOverview?.inactive}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function CustomerGoodieBagRecordDetail() {
  const queryString = require('query-string');

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const id = params.id;
  // const customerId = params.customerId;

  const isMobile = useDeviceDetect(768);

  const [couponRecordPage, setCouponRecordPage] = useState(1);
  const [showExtendGoodieBagDatePrompt, setShowExtendGoodieBagDatePrompt] =
    useState(false);

  const {
    user,
    couponTransactions,
    couponTransactionsTotalPage,
    couponTransactionsPageInfo,
    couponTransactionsTotalCount,
    customerParticipationRecord,
  } = useSelector((state) => ({
    user: state.users,

    couponTransactions:
      state.couponTransactions.currentPageCouponTransactionList || [],
    couponTransactionsTotalPage: state.couponTransactions.totalPage,
    couponTransactionsPageInfo: state.couponTransactions.pageInfo,
    couponTransactionsTotalCount: state.couponTransactions.totalCount,
    customerParticipationRecord: state.goodieBags.customerParticipationRecord,
  }));

  var tabSections = [
    <MembershipInfoCard
      title={'goodie bag Overview '}
      data={customerParticipationRecord}
      fields={[
        [{ title: 'Goodie bag type', field: 'goodieBagType' }],
        [{ title: 'Tracking code', field: 'trackingCode' }],
        [{ title: 'Acquire date', field: 'acquireDate' }],
        [
          {
            title: 'Deadline to acquire coupons',
            field: 'deadline',
          },
        ],
        [{ title: 'Goodie bag status', field: 'status' }],
      ]}
    />,
    <CouponOverviewArea
      customerParticipationRecord={customerParticipationRecord}
    />,
  ];
  if (
    checkHasPermission(user, PermissionCodes.viewGoodieBagCouponTransaction)
  ) {
    tabSections.push(
      <BaseTabListContainer
        hideTab={true}
        tabs={[]}
        tabTitle={() => (
          <label>
            <span className="customer-overview-row-title">
              Coupon transaction details
            </span>
            <span className="dashboard-and-overview-as-of-label">
              Please note that there is a 15-minute delay of coupon transaction
              data
            </span>
          </label>
        )}
        pageInfo={couponTransactionsPageInfo}
        totalCount={couponTransactionsTotalCount}
        needMobile
        filter={{
          hasFilter: true,
          componment: CustomerGoodieBagRecordCouponFilter,
          customFilterAreaClass: 'goodiebag-coupon-filter',
          offset: '320px',
        }}
        listContent={
          <BaseListContainer
            fields={[
              {
                displayName: 'ID',
                fieldName: 'pk',
              },
              {
                displayName: 'Create Date Time',
                fieldName: 'goodiebagCouponCreationDate',
              },
              {
                displayName: 'Transaction Type',
                fieldName: 'displayType',
              },
              {
                displayName: 'Transaction By',
                fieldName: 'transactionBy',
              },
              {
                displayName: 'Campaign Type',
                fieldName: 'campaignType',
              },
              {
                displayName: 'Coupon',
                fieldName: 'linkedCoupon',
                linked: 'followValue',
                linkedPathField: 'couponLink',
              },
              {
                displayName: 'Starred Campaign',
                fieldName: 'campaignIsStarred',
              },
              {
                displayName: 'Expiry Date',
                fieldName: 'couponExpiryDateTime',
              },
            ]}
            hideCheckbox
            deleteInfo={{}}
            dataList={couponTransactions}
            totalPage={couponTransactionsTotalPage}
            model={'couponTransactions'}
            permissionGroup={PermissionCodes.coupon}
            hideActions
            showTableRightShadow={false}
          />
        }
        hideSearch={true}
        hideActionHeader={isMobile}
        hideTabNav={isMobile}
        mobileListContent={
          <BaseMobileListContainer
            header={
              <CustomerMobileListHeader
                // title={'Coupon transaction details'}
                // titleClassName="goodiebag-acquire-summary-title"
                customTitle={() => (
                  <label>
                    <span className="customer-overview-row-title">
                      Coupon transaction details
                    </span>
                    <span className="dashboard-and-overview-as-of-label">
                      Please note that there is a 15-minute delay of coupon
                      transaction data
                    </span>
                  </label>
                )}
                totalCount={couponTransactionsTotalCount}
              />
            }
            dataList={couponTransactions}
            renderItem={(item, index) => (
              <CustomerListCard
                item={item}
                fields={[
                  {
                    name: 'Create Date Time',
                    value: item.goodiebagCouponCreationDate,
                  },
                  {
                    name: 'Transaction Type',
                    value: item.displayType,
                  },
                  {
                    name: 'Transaction By',
                    value: item.transactionBy,
                    isTag: true,
                  },
                  {
                    name: 'Campaign Type',
                    value: item.campaignType,
                  },
                  {
                    name: 'Coupon',
                    value: item.linkedCoupon,
                    isLink: item.linkedCoupon ? true : false,
                    linkNarrow: false,
                  },
                  {
                    name: 'Starred Campaign',
                    value: item.campaignIsStarred,
                    isTag: true,
                  },
                  {
                    name: 'Expired Time',
                    value: item.couponExpiryDateTime,
                  },
                ]}
                index={index}
                canView={
                  user.isSuperuser ||
                  user.userPermissions.includes(
                    `view_${PermissionCodes.coupon}`,
                  )
                }
                hideAction={true}
                linkPath={item.couponLink}
              />
            )}
            hasNext={couponTransactionsPageInfo?.hasNextPage}
            loadMore={() => {
              if (isMobile) {
                setCouponRecordPage(couponRecordPage + 1);
                return;
              }

              const searchDict = queryString.parse(location.search);
              const stringPage = searchDict['page'] || 1;
              const page = parseInt(stringPage);
              history.push({
                pathname: location.pathname,
                search: queryString.stringify({
                  ...searchDict,
                  page: page + 1,
                }),
              });
            }}
            listHeight="calc(100vh - 60px - 17px - 82px - 50px)"
            // listHeight="668px"
          />
        }
      />,
    );
  }
  const tabs = [
    {
      name: 'Personal Profile',
      content: (
        <div className="scroll-container-common">
          <ContentSections
            sections={tabSections}
            hidePreview={true}
            // sectionStyle="detail-info-section-item"
          />
        </div>
      ),
    },
  ];

  let buttons = [];
  if (
    customerParticipationRecord?.goodieBag.rewardType &&
    customerParticipationRecord?.goodieBag.rewardType !== 'FORTUNE_BAG'
  ) {
    buttons.push(
      <AuthButton
        title="Extend Goodie Bag time"
        className={'btn-back-button-common'}
        action={() => {
          setShowExtendGoodieBagDatePrompt(true);
        }}
        requires={PermissionCodes.changeGoodieBag}
      />,
    );
  }
  buttons = buttons.concat([
    <AuthButton
      title="Export"
      className={'btn-back-button-common'}
      action={() => {
        dispatch(
          createAction('downloadAndImport/createDownloadTask')({
            from: ImportResource.customerGoodieBagReport,
            related: {
              filterConditions: JSON.stringify({
                goodie_bag_participation_record: id,
              }),
            },
          }),
        );
      }}
      // requires={PermissionCodes.changeCustomer}
    />,
  ]);

  const [activeTab, setActiveTab] = useState(tabs[0].name);

  useEffect(() => {
    dispatch(
      createAction('goodieBags/getCustomerOneGoodieBagParticipationRecord')({
        id,
      }),
    );

    return () => {
      dispatch({ type: 'goodieBags/clearData' });
      dispatch({ type: 'couponTransactions/clearData' });
    };
  }, [dispatch, id]);

  useEffect(() => {
    if (
      checkHasPermission(user, PermissionCodes.viewGoodieBagCouponTransaction)
    ) {
      const searchDict = queryString.parse(location.search);
      // const couponTransactionType = searchDict['type'];
      const searchKey = searchDict['search'];
      const stringRank = searchDict['rank'] || 'true';
      const stringPage = searchDict['page'] || 1;
      const rank = stringRank === 'true';
      const page = parseInt(stringPage);
      dispatch(
        createAction('couponTransactions/getCurrentPageCouponTransactions')({
          ...searchDict,
          // couponTransactionType,
          searchKey,
          rank,
          page,
          goodieBagParticipationRecord: id,
        }),
      );
    }
  }, [dispatch, location, queryString]);

  useEffect(() => {
    if (
      isMobile &&
      checkHasPermission(user, PermissionCodes.viewGoodieBagCouponTransaction)
    ) {
      const searchDict = queryString.parse(location.search);
      // const couponTransactionType = searchDict['type'];
      const searchKey = searchDict['search'];
      const stringRank = searchDict['rank'] || 'true';
      const rank = stringRank === 'true';
      dispatch(
        createAction('couponTransactions/getCurrentPageCouponTransactions')({
          ...searchDict,
          // couponTransactionType,
          searchKey,
          rank,
          page: couponRecordPage,
          goodieBagParticipationRecord: id,
          loadMore: true,
        }),
      );
    }
  }, [couponRecordPage]);

  return (
    <>
      <div className="customer-goodiebag-record">
        <div className="container-common scroll-container-common-header mobile">
          <Button onClick={() => history.goBack()}>
            <img src={BackIcon} />
            <span>Back</span>
          </Button>
          <label className="caution-text">
            {customerParticipationRecord?.displayName}
          </label>
        </div>
        <CustomListComponent
          hideHeader={isMobile}
          caution={{
            detail: '',
            title: customerParticipationRecord?.displayName,
          }}
          defaultActiveKey={activeTab}
          breadcrumb={
            <CustomBreadcrumb
              names={{
                2: customerParticipationRecord?.customerMembershipId,
                4: customerParticipationRecord?.displayName,
              }}
            />
          }
          buttons={buttons}
          tabs={tabs}
          hideTab
        />
      </div>
      <CustomerGoodieBagRecordTimeExtendPrompt
        show={showExtendGoodieBagDatePrompt}
        customerParticipationRecord={customerParticipationRecord}
        onClose={() => {
          setShowExtendGoodieBagDatePrompt(false);
        }}
      />
    </>
  );
}

export default CustomerGoodieBagRecordDetail;
