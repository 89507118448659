import React, { useState, useEffect } from 'react';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from '../../utils';
import CustomDateTimeSelect from '../base/CustomDateTimeSelect';
import { useHistory } from 'react-router-dom';
import { formatDate } from '../../utils/TimeFormatUtil';
import {
  isShowError,
  firstError,
} from '../campaign/campaignCreation/CreateCampaignHandleError';
import { CreateMessageError } from '../../models/CreateMessageModel';
import { LanguageConfig } from '../../config/CustomEnums';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import { PermissionCodes } from '../../config/PermissionCodes';
import BasePrompt from '../base/prompt/BasePrompt';
import BaseMutipleSelectorV2 from '../base/BaseMultipleSelectorV2';

const MessageNewPromptType = {
  segments: 'segments',
  customerGroup: 'customer groups',
  none: 'none',
};

function MessageSettingSection(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const onSubmit = props.onSubmit || false;
  const onSubmitData = props.onSubmitData || (() => {});
  const [createNewType, setCreateNewType] = useState(MessageNewPromptType.none);

  const {
    customerGroupList,
    segmentList,
    targetCustomerGroup,
    targetedSegments,
    defaultScheduleDate,
    errorFields,
  } = useSelector((state) => ({
    customerGroupList: state.customerGroup.currentPageGroupList,
    segmentList: state.segments.segmentList,
    targetCustomerGroup: state.createMessage.message.targetCustomerGroup,
    targetedSegments: state.createMessage.message.targetedSegments,
    defaultScheduleDate: state.createMessage.message.scheduledDate,
    errorFields: state.createMessage.errorFields,
  }));

  const [selectedCustomerGroup, setSelectedCustomerGroup] = useState([]);
  const [selectedSegments, setSelectedSegments] = useState([]);
  const [scheduledDate, setScheduleDate] = useState();

  useEffect(() => {
    if (onSubmit) {
      const formatData = {
        targetCustomerGroup: selectedCustomerGroup,
        targetedSegments: selectedSegments,
        scheduledDate: scheduledDate,
      };
      onSubmitData(formatData);
    }
  });

  useEffect(() => {
    firstError(
      CreateMessageError.scheduledDate.name,
      errorFields,
      LanguageConfig.english,
    );
  }, [errorFields]);

  const isError = () => {
    return isShowError(
      CreateMessageError.scheduledDate.name,
      errorFields,
      LanguageConfig.english,
    );
  };

  const getCreateNewPromptContent = () => {
    let title = 'segment';
    let button = 'Segment';
    let pathname = '/segments/create/';
    if (createNewType === MessageNewPromptType.customerGroup) {
      title = 'customer group';
      button = 'Customer Group';
      pathname = '/customer_group/create';
    }
    return {
      title: `Go to create ${title} ?`,
      description: `You will leave message creation process.`,
      button: `Go to Create ${button}`,
      action: () => {
        history.push({
          pathname: pathname,
          state: {
            from: history.location,
            title: 'Continue to Create Message',
            content: 'You can continue to create the Message.',
          },
        });
      },
    };
  };

  return (
    <>
      <label className="create-section-title">SETTING</label>

      <BaseMutipleSelectorV2
        title={'Target customer groups'}
        namespace="customerGroup"
        searchPlaceholder="Search by name or ID"
        data={{
          sourceData: customerGroupList,
          targetData: targetCustomerGroup,
          targetChange: (options) => {
            setSelectedCustomerGroup(options);
            dispatch(
              createAction('createMessage/updateMessageState')({
                targetCustomerGroup: options,
                displayTargetCustomer: options
                  .map((item) => item.name)
                  .toString(),
              }),
            );
          },
        }}
        addButton={{
          title: 'Add Customer Group',
          action: () => setCreateNewType(MessageNewPromptType.customerGroup),
        }}
        requires={PermissionCodes.addCustomerGroup}
      />

      <BaseMutipleSelectorV2
        title={'Target segments'}
        namespace="segments"
        searchPlaceholder="Search by name or ID"
        data={{
          sourceData: segmentList,
          targetData: targetedSegments,
          targetChange: (options) => {
            setSelectedSegments(options);
            dispatch(
              createAction('createMessage/updateMessageState')({
                targetedSegments: options,
                displayTargetedSegments: options
                  .map((item) => item.name)
                  .toString(),
              }),
            );
          },
        }}
        addButton={{
          title: 'Add Segments',
          action: () => setCreateNewType(MessageNewPromptType.segments),
        }}
        requires={PermissionCodes.addSegment}
      />

      <CustomTitleLabel title="Schedule time" />
      <CustomDateTimeSelect
        defaultTime={
          defaultScheduleDate ? new Date(defaultScheduleDate) : new Date()
        }
        onTimeChange={(datetime) => {
          setScheduleDate(datetime);
          const deliveryDate = formatDate(
            datetime,
            'DD MMM yyyy (ddd),HH:mm a',
          );
          dispatch(
            createAction('createMessage/updateMessageState')({
              scheduledDate: datetime,
              deliveryDate: deliveryDate,
            }),
          );
        }}
        error={isError()}
      />
      <ErrorFieldMessage
        id={CreateMessageError.scheduledDate.name}
        error={isError()}
        message={CreateMessageError.scheduledDate.message}
      />

      <BasePrompt
        show={createNewType !== MessageNewPromptType.none}
        closeAction={() => setCreateNewType(MessageNewPromptType.none)}
        rightButton={{
          text: getCreateNewPromptContent().button,
          action: getCreateNewPromptContent().action,
        }}
        title={getCreateNewPromptContent().title}
        description={getCreateNewPromptContent().description}
      />
    </>
  );
}

export default MessageSettingSection;
