import React, { useEffect, useState } from 'react';
import ScrollableComponent from '../../../components/base/ScrollableComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CreateCustomerGroupGeneral from '../../../components/customerGroup/CreateCustomerGroupGeneral';
import ContentSections from '../../../components/base/ContentSections';
import TargetCustomerSection from '../../../components/customerGroup/TargetCustomerSection';
import {
  SaveAndBackButtons,
  SaveAndBackWithOutTempButtons,
} from '../../../components/base/BottomStepComponent';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from '../../../utils';
import DeletePrompt from '../../../components/base/DeletePrompt';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { sessionDataKey } from '../../../models/CustomerGroupModel';
import { DELETE_RELATED_SECTIONS } from '../../../config/CustomEnums';

function CreateCustomerGroup() {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [showContinuePrompt, setShowContinuePrompt] = useState(false);

  const { customerGroup } = useSelector((state) => ({
    customerGroup: state.customerGroup.selectedCustomerGroup,
  }));

  useEffect(() => {
    if (params.id) {
      dispatch(
        createAction('customerGroup/getCustomerGroup')({
          id: params.id,
        }),
      );
    } else {
      dispatch(createAction('customerGroup/loadCustomerGroupFromSession')());
    }
  }, [dispatch, params]);

  useEffect(() => {
    return () => {
      dispatch(createAction('customerGroup/clearData')());
    };
  }, [dispatch]);

  const sections = [<CreateCustomerGroupGeneral />, <TargetCustomerSection />];
  const stepContent = [
    <ContentSections sections={sections} hidePreview={true} />,
  ];
  return (
    <>
      <ScrollableComponent
        tempSave={(save) => {
          dispatch({
            type: 'customerGroup/saveOrRemoveCustomerGroupFromCookie',
            payload: save,
          });
        }}
        showFinishPop={showContinuePrompt}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: 'Customer group is successfully created.',
          steps: null,
          buttons: [
            {
              text: 'Back to customer group list',
              action: () => {
                history.push({
                  pathname: '/customer_group',
                });
              },
            },
          ],
        }}
        continueCreateConfig={{
          onConfirm: () => {
            history.push(history.location.state?.from);
          },
          onCancel: () => history.push('/customer_group'),
          onClose: () => history.push('/customer_group'),
        }}
        sessionDataKey={sessionDataKey}
        content={stepContent}
        currentStep={0}
        breadcrumb={
          <CustomBreadcrumb name={params.id ? customerGroup.name : ''} />
        }
        caution={{
          detail: '', //Create various customer groups and target them with different experiences.
          title: `${params.id ? customerGroup.name : 'Create Customer Group'}`,
        }}
      />
      {params.id ? (
        <SaveAndBackButtons
          backAction={() => {
            history.push('/customer_group');
          }}
          saveTempText={'Delete'}
          tempRequires={PermissionCodes.deleteCustomerGroup}
          saveTempAction={() => {
            setShowDeletePrompt(true);
          }}
          saveText={'Updated'}
          saveAction={() => {
            dispatch(
              createAction('customerGroup/checkFields')({
                isBack: false,
                afterAction: () => {
                  dispatch(
                    createAction('customerGroup/updateCustomerGroup')({
                      afterAction: () => {
                        history.push('/customer_group');
                      },
                    }),
                  );
                },
              }),
            );
          }}
        />
      ) : (
        <SaveAndBackWithOutTempButtons
          backAction={() => history.push('/customer_group')}
          saveText="Save"
          saveAction={() => {
            dispatch(
              createAction('customerGroup/checkFields')({
                isBack: false,
                afterAction: () => {
                  dispatch(
                    createAction('customerGroup/createCustomerGroup')({
                      afterAction: () => {
                        setShowContinuePrompt(true);
                      },
                    }),
                  );
                },
              }),
            );
          }}
        />
      )}
      <DeletePrompt
        title={'customer group'}
        show={showDeletePrompt}
        relatedSections={DELETE_RELATED_SECTIONS.CUSTOMER_GROUP}
        data={customerGroup}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch(
            createAction('customerGroup/updateState')({
              checkedList: [customerGroup],
            }),
          );
          dispatch(
            createAction('customerGroup/delete')({
              afterAction: () => {
                history.push('/customer_group');
              },
            }),
          );
        }}
      />
    </>
  );
}

export default CreateCustomerGroup;
