import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import {
  ArgumentAxis,
  ValueAxis,
  Chart,
  ZoomAndPan,
  BarSeries,
} from '@devexpress/dx-react-chart-material-ui';
import { Animation } from '@devexpress/dx-react-chart';
import './HistogramZoomableCard.scss';
import icon from '../../assets/images/customer_overview_vector.svg';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Loading from '../base/Loading';
import EmptyChart from '../base/EmptyChart';
import { enLocaleNumberFormatter } from '../../utils';
import CustomTips from '../base/CustomTips';
import { formatDate, TimeFormater } from '../../utils/TimeFormatUtil';
import AttributeFilter from './AttributeFilter';
import StarredCampaignFilter from '../../containers/dashboard/StarredCampaignFilter';

const getBarPath = (x, width, y, y1) => `M ${x},${y1}
    L ${width + x} ${y1}
    L ${width + x} ${y + 5}
    C ${width + x},${y - 12} ${x},${y - 12} ${x},${y + 5}
    Z`;

const labelWidth = 50;
const labelHeight = 28;
const moveLeft = 0;
const triangleWidth = 9;
const triangleHeight = 7;

const getLabelPath = (x, y) => `M ${x - moveLeft},${y}
    L ${x - moveLeft},${y + labelHeight - triangleHeight}
    L ${x + labelWidth / 2 - triangleWidth / 2 - moveLeft},${
  y + labelHeight - triangleHeight
}
    L ${x + labelWidth / 2 - moveLeft},${y + labelHeight}
    L ${x + labelWidth / 2 + triangleWidth / 2 - moveLeft},${
  y + labelHeight - triangleHeight
}
    L ${x + labelWidth - moveLeft},${y + labelHeight - triangleHeight}
    L ${x + labelWidth - moveLeft},${y}
    Z`;

const BarWithLabel = ({ arg, val, startVal, value }) => {
  const width = 26;
  const barPath = getBarPath(arg - width / 2, width, val, startVal);
  const labelPath = getLabelPath(arg - width, val - labelHeight - 10);
  return (
    <React.Fragment>
      <defs>
        <linearGradient id="histogramGradient" gradientTransform="rotate(90)">
          <stop offset="1%" stopColor="#7375E2" />
          <stop offset="99%" stopColor="#7375E200" />
        </linearGradient>
      </defs>
      <path
        d={barPath}
        id="barPath"
        fill={!value ? 'white' : "url('#histogramGradient')"}
      />
      <path d={labelPath} fill="#002148" />
      <Chart.Label
        x={arg}
        y={val - 26}
        style={{ fill: 'white', fontFamily: 'Mulish', fontSize: '12px' }}
        dominantBaseline="middle"
        textAnchor="middle"
      >
        {`${(value * 100).toFixed(1)}%`}
      </Chart.Label>
    </React.Fragment>
  );
};

const ArgumentLabel = (props) => {
  const period = props.text || '';
  let newPeriod = props.text;
  if (period.length > 80) {
    newPeriod = `${period.substring(0, 40)}...${period.substring(
      period.length - 40,
    )}`;
  }
  return (
    <React.Fragment>
      <foreignObject
        {...props}
        x={props.x - 64}
        y={props.y}
        width="128"
        height="96"
      >
        <CustomTips
          detail={period}
          placement="top"
          popoverName="histogram-chart-tips"
        >
          <label
            onHover={() => {}}
            style={{
              wordBreak: 'break-word',
              width: '128px',
              height: '96px',
              cursor: 'pointer',
              fontFamily: 'Mulish',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '24px',
              color: '#999999',
              textOverflow: 'clip',
              display: 'inline-block',
              textAlign: 'center',
            }}
          >
            {newPeriod}
          </label>
        </CustomTips>
      </foreignObject>
    </React.Fragment>
  );
};

const format = (scale) => scale.tickFormat(null, '%');

function CardItem({ chartData, loading }) {
  const [viewport, changeViewport] = useState();
  console.log('CardItem:', chartData?.length, chartData?.length > 5, viewport);
  useEffect(() => {
    if (chartData?.length) {
      const startFit = chartData[0].period;
      const endFit = chartData[chartData.length - 1].period;
      changeViewport({ argumentEnd: endFit, argumentStart: startFit });
    }
  }, [chartData]);

  if (loading) {
    return (
      <div className="histogram-zoomable-card-item-container">
        <Loading />
      </div>
    );
  }

  const exsitData =
    chartData?.length && chartData?.find((chart) => chart.point > 0);
  if (!exsitData) {
    return (
      <div className="histogram-zoomable-card-item-container">
        <EmptyChart />
      </div>
    );
  }
  return (
    <div className="histogram-zoomable-card-item-container">
      <Paper>
        <Chart data={chartData || []}>
          <ArgumentAxis labelComponent={ArgumentLabel} />
          <ValueAxis tickFormat={format} />
          <BarSeries
            valueField="percentage"
            argumentField="period"
            pointComponent={BarWithLabel}
          />
          <ZoomAndPan
            viewport={viewport}
            onViewportChange={changeViewport}
            interactionWithValues="pan"
          />
          <Animation />
        </Chart>
      </Paper>
    </div>
  );
}

function CollectArea({ title, showFilter, endDate, starredFilter = {} }) {
  return (
    <div className="histogram-zoomable-card-chart-header">
      <div className="d-flex">
        <div className="d-flex flex-column">
          <label className="histogram-zoomable-card-chart-title">{title}</label>
          {endDate ? (
            <label className="dashboard-coupon-item-label-caution">{`as of ${formatDate(
              endDate,
              TimeFormater.dayMonthYearTimePeriod,
            )}`}</label>
          ) : null}
        </div>
        {starredFilter?.showStarredFilter ? (
          <StarredCampaignFilter
            filterModel={starredFilter?.model}
            includeGeneralMessage={false}
          />
        ) : null}
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          position: 'relative',
          marginTop: '20px',
        }}
      >
        <label className="histogram-zoomable-card-chart-hint">
          Zoom in and out by mousewheel; navigate horizontally by dragging.
        </label>

        {showFilter ? <AttributeFilter /> : null}
      </div>
    </div>
  );
}

function DataCount({ pre, suffix }) {
  return (
    <label className="histogram-zoomable-card-percentage">
      {pre + ' '}
      <label className="histogram-zoomable-card-count">{suffix}</label>
    </label>
  );
}

function BottomLinkArea({
  totalNew,
  perferenceTotal,
  customerTotal,
  isInterest,
}) {
  return (
    <div className="histogram-zoomable-card-bottom-container">
      {isInterest ? (
        <>
          <DataCount pre={'Data collected'} suffix={perferenceTotal || 0} />
          <label
            className="histogram-zoomable-card-percentage"
            style={{ marginLeft: '10px', marginRight: '10px' }}
          >
            |
          </label>
          <DataCount
            pre={'Undefined'}
            suffix={totalNew - perferenceTotal || 0}
          />
        </>
      ) : (
        <DataCount
          pre={'Data from all customer '}
          suffix={enLocaleNumberFormatter(customerTotal || 0)}
        />
      )}
      <Link
        className="dashboard-overview-link"
        to={{ pathname: '/dashboard_customer' }}
      >
        {/* Customer Overview */}
        See detail
        <img className="dashboard-overview-link-icon" src={icon} alt="" />
      </Link>
    </div>
  );
}

function HistogramZoomableCard({
  data,
  interestNumber,
  totalCustomerNumber,
  title,
  loading,
  showFilter,
  isInterest,
  endDate,
  starredFilter,
}) {
  return (
    <div className="histogram-zoomable-card">
      <CollectArea
        title={title}
        showFilter={showFilter}
        endDate={endDate}
        starredFilter={starredFilter}
      />
      <CardItem chartData={data} loading={loading} />
      <BottomLinkArea
        totalNew={totalCustomerNumber.value}
        perferenceTotal={interestNumber.value}
        customerTotal={totalCustomerNumber.value}
        isInterest={isInterest}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  interestNumber:
    state.dashboardCustomer.customers
      .totalNumberOfCustomersWithInterestPreference || {},
  totalCustomerNumber:
    state.dashboardCustomer.customers.totalNumberOfCustomers || {},
  endDate: state.dashboard.endDate,
});

export default connect(mapStateToProps)(HistogramZoomableCard);
