import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import './DateRangeCompare.scss';
import { getStartEndDate } from './DateRangeSelection';
import { TimeFormater } from '../../utils/TimeFormatUtil';
import moment from 'moment';

// const getDateRangeString = (startDate, endDate) => {
//   if (endDate - startDate < 3600 * 24 * 1000) {
//     return endDate.format('D MMM, YYYY');
//   }
//   let startDateString = startDate.format('D MMM, YYYY');
//   if (startDate.year() === endDate.year()) {
//     startDateString = startDate.format('D MMM');
//   }
//   return `${startDateString} - ${endDate.format('D MMM, YYYY')}`;
// };

// const getCompareDateString = (startDate, endDate) => {
//   const duration = endDate - startDate;
//   const compareStartDate = startDate.clone();
//   compareStartDate.add(-duration).add(-1, 'days');
//   const compareEndDate = endDate.clone();
//   compareEndDate.add(-duration).add(-1, 'days');
//   return getDateRangeString(compareStartDate, compareEndDate);
// };

const getCompareDateString = ({ startDate, endDate }) => {
  if (!startDate || !endDate) {
    return '';
  }
  const startFormatted = startDate.format(TimeFormater.dayMonthYearTimePeriod);
  const endFormatted = endDate.format(TimeFormater.dayMonthYearTimePeriod);
  console.log('getCompareDateString:', startFormatted, endFormatted);
  const duration = moment(endFormatted).diff(moment(startFormatted), 'minutes');
  console.log('duration:', duration);
  if (isNaN(duration)) {
    return;
  }
  const previousEndString = moment(startFormatted)
    .subtract(1, 'minutes')
    .format(TimeFormater.dayMonthYearTimePeriod);
  const previousStartString = moment(startFormatted)
    .subtract(duration + 1, 'minutes')
    .format(TimeFormater.dayMonthYearTimePeriod);
  return `${previousStartString} - ${previousEndString}`;
};

const DateRangeCompare = () => {
  const location = useLocation();
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  useEffect(() => {
    const { range, start, end } = queryString.parse(location.search);
    const { startDate, endDate } = getStartEndDate(range, start, end);
    console.log('useEffect:', startDate, endDate);
    setDateRange({ startDate, endDate });
  }, [location]);
  console.log('dateRange:', dateRange);
  return (
    <>
      <label className="dashboard-compare-date">
        {`Compare with ${getCompareDateString(dateRange)}`}
      </label>
    </>
  );
};
export default DateRangeCompare;
