import { BaseHelper } from './BaseGraphQLAPIHelper';

export const getPagedAdmins = (afterCursor, search = {}) => {
  let searchString = `, orderBy: "${search.reverse ? '-pk' : 'pk'}"`;
  if (search?.sort) {
    searchString = `, orderBy: "${search.sort}"`;
  }
  if (search.search) {
    searchString += `, nameIcontains: "${search.search}"`;
  }
  if (search.admin_type) {
    searchString += `, adminType: "${search.admin_type}"`;
  }
  if (search.grade) {
    searchString += `, isSuperuser: ${search.grade}`;
  }
  if (search.status) {
    searchString += `, isActive: ${search.status}`;
  }

  const query = `{
    administrators(first: ${
      search.pageSize || 20
    }, after:"${afterCursor}"${searchString}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          username
          ${
            search.isAll
              ? ''
              : `lastLogin
          isSuperuser
          userPermissions(first: 10) {
            edges {
              node {
                id
                pk
                name
                codename
              }
            }
          }
          firstName
          lastName
          email
          mobile
          adminType
          brand {
            pk
            id
            name
          }
          store {
            pk
            id
            name
          }
          isActive
          dateJoined
          isStaff
          groups(first: 10) {
            edges {
              node {
                id
                pk
                name
              }
            }
          }
          workingTeams(first: 100) {
            edges {
              node {
                id
                pk
                name
              }
            }
          }
        `
          }
        }         
      }
    }
  }
  `;
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getAllAdmins = () => {
  const query = `
  {
    administrators(first: 100) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          lastLogin
          isSuperuser
          userPermissions(first: 10) {
            edges {
              node {
                id
                pk
                name
                codename
              }
            }
          }
          username
          firstName
          lastName
          email
          mobile
          adminType
          brand {
            pk
            id
            name
          }
          store {
            pk
            id
            name
          }
          isActive
          dateJoined
          isStaff
          groups(first: 10) {
            edges {
              node {
                id
                pk
                name
              }
            }
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteAdmin = (pks) => {
  const query = `mutation DeleteAdministrators($input: DeleteAdministratorsInput!) {
    deleteAdministrators(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      ids: pks,
    },
  };

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateAdminStatus = (isActive, pk) => {
  const query = ``;

  const variables = {
    input: {
      id: pk,
    },
  };

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const duplicateAdmin = (pk) => {
  const query = ``;

  const variables = {
    input: {
      id: pk,
    },
  };

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getOneAdmin = (id) => {
  const query = `{
    administrator(id: "${id}") {
      id
      pk
      username
      firstName
      lastName
      email
      mobile
      isActive
      dateJoined
      isStaff
      lastLogin
      isSuperuser
      adminType
      adminLoginMethod
      passwordWithEmpty
      brand {
        pk
        id
        name
      }
      store {
        pk
        id
        name
      }
      groups(first: 20) {
        edges {
          node {
            id
            pk
            name
            permissions(first: 100) {
              edges {
                node {
                  name
                  codename
                }
              }
            }
          }
        }
      }
      userPermissions(first: 100) {
        edges {
          node {
            id
            pk
            name
            codename
          }
        }
      }
      workingTeams(first: 100) {
        edges {
          node {
            id
            pk
            name
          }
        }
      }

    }
  }
  `;

  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateOrCreateAdmin = (values) => {
  let query = `mutation CreateAdministrator($input: CreateAdministratorInput!) {
    createAdministrator(input: $input) {
      node {
        id
        pk
        username
        firstName
        lastName
        email
        mobile
        isActive
        dateJoined
        isStaff
      }
      errors {
        field
        messages
      }
    }
  }
  `;

  if (values.id) {
    query = `mutation UpdateAdministrator($input: UpdateAdministratorInput!) {
      updateAdministrator(input: $input) {
        node {
          id
          pk
          username
          firstName
          lastName
          email
          mobile
          isActive
          dateJoined
          isStaff
        }
        errors {
          field
          messages
        }
      }
    }

    `;
  }
  console.log(values);
  const variables = {
    input: values,
  };

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getPagedPermissions = (afterCursor, filter) => {
  let filters = '';
  if (filter?.adminType) {
    filters += `,adminType: "${filter?.adminType}"`;
  }
  filter += `, after: "${afterCursor}"`;
  const query = `{
    permissions(first: 100 ${filters}) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          name
          codename
          contentType {
            appLabel
            model
          }
        }
      }
    }
  }

  `;

  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const changePassword = (values) => {
  const query = `mutation ChangeAdministratorPassword($input: ChangeAdministratorPasswordInput!) {
    changeAdministratorPassword(input: $input) {
      success
      errors {
        field
        messages
      }
    }
  }
  `;

  const variables = { input: values };

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const sendOtpCode = (values) => {
  const query = `mutation SendOTPCode($input: SendOTPCodeInput!){
    sendOtpCode(input: $input) { 
      success
      errors{
        field 
        messages
      } 
    } 
  }`;
  const variables = { input: values };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getAdminLoginMethod = (username) => {
  const query = `{ 
    administrator2fas(nameExact: "${username}") {
      edges{
        node{
          methods
          adminLoginMethod
        } 
      } 
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCMSOtpCode = (afterCursor, mobile) => {
  const query = `{
    cmsMobileVerifyCodes(first: 20, after:"${afterCursor}", mobile: "${mobile}", orderBy: "-pk") {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          mobile
          requestCode
          requestCodeDateTime
          membershipId
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getSSOtpCode = (afterCursor, payload) => {
  const { countryCode, mobileNumber } = payload;
  const mobile = `+${countryCode}${mobileNumber}`;
  const query = `
  {
    ssoMobileVerifyCodes(first: 20, after:"${afterCursor}", mobile: "${mobile}", orderBy: "-requestCodeDateTime") {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          mobile
          requestCode
          requestCodeDateTime
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};
