import { createModel } from './BaseModel';
import {
  getAttributeTagKeyList,
  getAttributeTagKey,
  createOrUpdateAttributeTagKey,
  deleteAttributeTagKeys,
} from '../services/AttributeTagAPIHelper';
import { LanguageConfig, SESSION_KEYS } from '../config/CustomEnums';
import { apiWithResponseHandle } from './LoadingUtil';

const parseNew = (data) => {
  const tag = data.createAttributeTagKey.node;
  return tag;
};

const parseDetail = (data) => {
  const tagNames = data.tags?.map((item) => item.name);
  const translations = { [LanguageConfig.english]: { name: data.name } };

  const temp = data.translations?.edges?.forEach((element) => {
    translations[element.node.language] = {
      pk: element.node.pk,
      name: element.node.name,
    };
  });

  let attributeTags = {};
  (data.tags || []).forEach((tag, index) => {
    attributeTags[`tag_${tag.pk}`] = { en: { name: tag.name } };

    (tag.translations?.edges || []).forEach((translation) => {
      const tagTranslation = translation.node;
      attributeTags[`tag_${tag.pk}`][tagTranslation.language] = {
        pk: tagTranslation.pk,
        name: tagTranslation.name,
      };
    });
  });

  if (data?.tags?.length <= 0) {
    attributeTags = { new: { en: { name: '' } } };
  }

  return {
    ...data,
    displayAttributeTags: tagNames?.join(', '),
    translations,
    attributeTags,
    label: `[ID:${data.pk}] ${data.name}`,
    showInWechat: data.showInWechatCampaignFilterLevel1,
    showInGoodieBag: data.showInGoodieBagCampaignFilterLevel1,
    displayShowInWechat: data.showInWechatCampaignFilterLevel1 ? 'Yes' : 'No',
    displayShowInGoodieBag: data.showInGoodieBagCampaignFilterLevel1
      ? 'Yes'
      : 'No',
    order: data.displayPriority,
  };
};

export default createModel({
  namespace: 'attributeTagKey',
  params: {
    initState: {
      detail: {
        attributeTags: { new0: { en: { name: '' } } },
      },
    },
    sessionKey: 'attributeTagKey',
    dataKey: 'attributeTagKeys',
    listAPI: getAttributeTagKeyList,
    parse: (data) =>
      data?.attributeTagKeys.edges?.map((item) => parseDetail(item.node)),
    deleteAPI: deleteAttributeTagKeys,
    pkNode: 'AttributeTagKeyNode',
    detailAPI: getAttributeTagKey,
    parseDetail: (data) => parseDetail(data.attributeTagKey),
    createdNewParse: (data) => parseNew(data),
  },
  states: {
    listDisplayFields: [
      { displayName: 'ID', fieldName: 'pk', orderField: 'pk' },
      { displayName: 'Tag name', fieldName: 'name' },
      { displayName: 'Attribute Tag', fieldName: 'displayAttributeTags' },
      {
        displayName: 'Display order',
        fieldName: 'displayPriority',
        orderField: 'displayPriority',
      },
      {
        displayName: 'Show in filter level 1 in WeChat campaign list',
        fieldName: 'displayShowInWechat',
        isTag: true,
      },
      {
        displayName: 'Show in filter level 1 in Goodie bag campaign list',
        fieldName: 'displayShowInGoodieBag',
        isTag: true,
      },
    ],
  },
  reducers: {},
  effects: {
    createOrUpdate: [
      function* ({ payload }, { call, select, put }) {
        const detail = yield select((state) => state.attributeTagKey.detail) ||
          {};

        const data = {
          name: payload.translations.en.name,
          showInWechatCampaignFilterLevel1: payload.showInWechat,
          showInGoodieBagCampaignFilterLevel1: payload.showInGoodieBag,
          displayPriority: payload.order || payload.displayPriority,
          translations: Object.keys(payload.translations)
            .filter((key) => key !== 'en')
            .map((key) => {
              const translation = {
                language: key,
                name: payload.translations[key].name,
              };

              if (detail?.translations && key in (detail?.translations || {})) {
                translation.id = detail.translations[key].pk;
              }
              return translation;
            }),
          attributeTags: Object.keys(payload.attributeTags || [])
            .filter((key) => !payload.delAttributeTags?.includes(key))
            .map((key, index) => {
              const tag = {
                name: payload.attributeTags[key].en.name,
                translations: Object.keys(payload.attributeTags[key])
                  .filter((language) => language !== 'en')
                  .map((language) => {
                    const translation = {
                      language,
                      name: payload.attributeTags[key][language].name,
                    };

                    if (
                      detail?.attributeTags &&
                      key in detail?.attributeTags &&
                      language in detail.attributeTags[key]
                    ) {
                      translation.id = detail.attributeTags[key][language].pk;
                    }

                    return translation;
                  }),
              };

              if (!key.toString().startsWith('new')) {
                tag.id = parseInt(key.replace('tag_', ''));
              }

              if (!tag.id) {
                delete tag.id;
              }

              return tag;
            }),
        };

        if (payload.id) {
          data.id = payload.pk;
        } else {
          const languages = yield select((state) => state.language.allList);
          const filledTranslations = languages
            .map((item) => {
              if (item.code in payload.translations) {
                return;
              }

              return {
                language: item.code,
                name: '',
              };
            })
            .filter((item) => item !== undefined);
          data.translations = [...data.translations, ...filledTranslations];

          //   const filledTagTranslations = languages
          //   .map((item) => {
          //     if (item.code in payload.translations) {
          //       return;
          //     }

          //     return {
          //       language: item.code,
          //       name: '',
          //     };
          //   })
          //   .filter((item) => item !== undefined);
          // data.translations = [...data.translations, ...filledTranslations];
        }
        console.log('@@90: ', payload.translations, data);
        const serviceArgs = [createOrUpdateAttributeTagKey, data];
        yield put({
          type: 'responseCreateOrUpdate',
          payload: { serviceArgs, isCreate: !payload.id },
        });
      },
      { type: 'takeLatest' },
    ],

    updateOrder: [
      function* ({ payload }, { put, call }) {
        // const detailResponse = yield call(getAttributeTagKey, payload.id)

        const data = {
          displayPriority: payload.data.displayPriority,
          id: payload.data.id,
          name: payload.data.name
        };

        const serviceArgs = [createOrUpdateAttributeTagKey, data];

        const afterActions = payload.afterAction || (() => { });
        function* onSuccess(data) {
          // yield put({ type: "updateState", payload: {} })
          // console.log("@@223: ", afterActions);
          afterActions();
        }


        yield apiWithResponseHandle(
          serviceArgs,
          onSuccess,
        );

      },
      { type: 'takeLatest' },
    ],
  },
});
