import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { createAction } from '../../../utils';
import './CampaignOverviewFilter.scss';
import i18n from '../../../I18n';
import { CustomTitleWithDropDown } from '../../customer/CustomTitleWithDropDown';
import ExportRankingAndPerformanceReport from '../../base/prompt/ExportRankingAndPerformanceReport';
import { CampaignType } from '../../../config/CustomEnums';
import { CheckboxItem } from '../../base/BaseFilter';
import { CustomTitleLabel } from '../../earning/CustomBaseComponments';

const starredOptions = [
  { label: 'Starred campaign', value: true },
  { label: 'Non-Starred campaign', value: false },
];

const FilterSection = ({
  channelList,
  marketList,
  brandList,
  campaignList,
  campaignOverviewDate,
  propCampaignOverviewDate,
}) => {
  const dispatch = useDispatch();
  const [filterByChannels, setFilterByChannels] = useState();
  const [filterByTargetMarkets, setFilterByTargetMarkets] = useState();
  const [filterByBrands, setFilterByBrands] = useState();
  const [filterByCampaign, setFilterByCampaign] = useState();
  const [showExportRakingReportPrompt, setShowExportRakingReportPrompt] =
    useState(false);
  const [
    showExportPerformanceReportPrompt,
    setShowExportPerformanceReportPrompt,
  ] = useState(false);
  const [filterIsStarred, setFilterIsStarred] = useState();

  const getCampaignOverviewCampaignsStatus = (
    channel,
    market,
    brand,
    campaign,
    isStarred,
  ) => {
    console.log('@21', channel, market, brand, campaign);
    let campaignsLabel = 'all';
    if (isStarred) {
      campaignsLabel = 'starred';
    } else if (isStarred === false) {
      campaignsLabel = 'non-starred';
    }

    if (campaign?.value?.pk) {
      return `Showing result of ${campaignsLabel === 'all' ? '' : `${campaignsLabel} `
        }campaign ID: ${campaign?.value?.pk} from ${brand?.value ? brand?.value?.name : 'all brands'
        }.`;
    }
    if (channel?.value?.pk) {
      return `Showing result of ${campaignsLabel} campaign(s) from 
          ${channel?.value?.name}, 
          ${market?.value ? market?.value?.name : 'all related target markets'
        }, 
          and ${brand?.value ? brand?.value?.name : 'all brands'}.`;
    }
    if (market?.value?.pk) {
      return `Showing result of ${campaignsLabel} campaign(s) from 
          ${market?.value?.name}, all related channels, 
          and ${brand?.value ? brand?.value?.name : 'all brands'}.`;
    }
    if (brand?.value?.pk) {
      return `Showing result of ${campaignsLabel} campaign(s) from 
          ${brand?.value?.name}, all channels, and all target markets.`;
    }
    return `Showing result of ${campaignsLabel} campaign(s).`;
  };

  useEffect(() => {
    dispatch(
      createAction('channel/getList')({
        market: filterByTargetMarkets?.value?.pk,
        isSelectorLoad: true,
      }),
    );
  }, [filterByTargetMarkets]);

  useEffect(() => {
    dispatch(
      createAction('targetMarket/getList')({
        channel: filterByChannels?.value?.pk,
        isSelectorLoad: true,
      }),
    );
  }, [filterByChannels]);

  useEffect(() => {
    setFilterByCampaign({ label: null, value: null });
    dispatch(
      createAction('campaignList/getCampaignList')({
        brand: filterByBrands?.value?.pk,
        starred_campaign: filterIsStarred?.value
          ? filterIsStarred?.value?.value.toString()
          : null,
        typeIn: `${CampaignType.generalMessageCampaign},${CampaignType.couponsCampaign}`,
        isSelectorByOverview: true,
        isSelectorLoad: true,
        getAll: 'false',
      }),
    );
  }, [filterByBrands]);

  useEffect(() => {
    setFilterByCampaign({ label: null, value: null });
    dispatch(
      createAction('campaignList/getCampaignList')({
        brand: filterByBrands?.value?.pk,
        starred_campaign: filterIsStarred?.value
          ? filterIsStarred?.value?.value.toString()
          : null,
        typeIn: `${CampaignType.generalMessageCampaign},${CampaignType.couponsCampaign}`,
        isSelectorByOverview: true,
        isSelectorLoad: true,
        getAll: 'false',
      }),
    );
  }, [filterIsStarred]);

  useEffect(() => {
    dispatch(
      createAction('overview/updateState')({
        campaignOverviewFilter: {
          channel: filterByChannels?.value,
          targetMarket: filterByTargetMarkets?.value,
          brand: filterByBrands?.value,
          campaign: filterByCampaign?.value,
          isStarred: filterIsStarred?.value?.value,
        },
        campaignOverviewCampaignsStatus: getCampaignOverviewCampaignsStatus(
          filterByChannels,
          filterByTargetMarkets,
          filterByBrands,
          filterByCampaign,
          filterIsStarred?.value?.value,
        ),
      }),
    );
  }, [
    filterByChannels,
    filterByTargetMarkets,
    filterByBrands,
    filterByCampaign,
    filterIsStarred,
  ]);
  return (
    <>
      <div className="campaign-overview-filter">
        <ExportRankingAndPerformanceReport
          showExportRakingReportPrompt={showExportRakingReportPrompt}
          setShowExportRakingReportPrompt={setShowExportRakingReportPrompt}
          showExportPerformanceReportPrompt={showExportPerformanceReportPrompt}
          setShowExportPerformanceReportPrompt={
            setShowExportPerformanceReportPrompt
          }
          overviewDate={campaignOverviewDate?.startDate ? campaignOverviewDate : propCampaignOverviewDate}
          preSelectedBrand={filterByBrands}
          preSelectedCampaign={filterByCampaign}
          preSelectedIsStarred={filterIsStarred?.value?.value?.toString()}
          fromModel="CAMPAIGN"
        />
        <div className="campaign-overview-filter-head">
          <label className="campaign-overview-filter-title">FILTER</label>
          {filterByChannels?.value ||
            filterByTargetMarkets?.value ||
            filterByBrands?.value ||
            filterByCampaign?.value ||
            filterIsStarred?.value ? (
            <label
              className="campaign-overview-filter-reset-all"
              onClick={() => {
                setFilterByChannels({ label: null, value: null });
                setFilterByTargetMarkets({ label: null, value: null });
                setFilterByBrands({ label: null, value: null });
                setFilterByCampaign({ label: null, value: null });
                setFilterIsStarred({ label: null, value: null });
              }}
            >
              Reset All
            </label>
          ) : (
            <></>
          )}
        </div>
        <div className="campaign-overview-channel-select">
          <CustomTitleWithDropDown
            customClass={''}
            title={'Filter by channels'}
            source={channelList}
            placeholder={'Search by channel ID, channel name'}
            labelContainPk={true}
            defaultValue={
              filterByChannels?.value
                ? filterByChannels
                : {
                  label: `All Channels ${filterByTargetMarkets?.value?.pk
                      ? `from ${filterByTargetMarkets?.value?.name}`
                      : ''
                    }`,
                  value: null,
                }
            }
            setValue={setFilterByChannels}
            loadMoreAction={'channel/getList'}
            filterAction={'channel/getList'}
            defaultFilter={{
              isSelectorLoad: true,
              ...(filterByTargetMarkets?.value?.pk
                ? { market: filterByTargetMarkets?.value?.pk }
                : null),
            }}
          />
        </div>
        <div className="campaign-overview-brand-select">
          <CustomTitleWithDropDown
            customClass={''}
            title={'Filter by target markets'}
            source={marketList}
            placeholder={'Search by Target market ID, Target market name'}
            labelContainPk={true}
            defaultValue={
              filterByTargetMarkets?.value
                ? filterByTargetMarkets
                : {
                  label: `All Target markets ${filterByChannels?.value?.pk
                      ? `from ${filterByChannels?.value?.name}`
                      : ''
                    }`,
                  value: null,
                }
            }
            setValue={setFilterByTargetMarkets}
            loadMoreAction={'targetMarket/getList'}
            filterAction={'targetMarket/getList'}
            defaultFilter={{
              isSelectorLoad: true,
              ...(filterByChannels?.value?.pk
                ? { channel: filterByChannels?.value?.pk }
                : null),
            }}
          />
        </div>
        <div className="campaign-overview-brand-select">
          <CustomTitleWithDropDown
            customClass={''}
            title={'Filter by brands'}
            source={brandList}
            placeholder={'Search by brand ID, brand name'}
            labelContainPk={true}
            defaultValue={
              filterByBrands?.value
                ? filterByBrands
                : { label: 'All Brands', value: null }
            }
            setValue={setFilterByBrands}
            loadMoreAction={'brand/getCurrentPageBrands'}
            filterAction={'brand/getCurrentPageBrands'}
          />
        </div>
        <div className="campaign-overview-brand-select">
          <CustomTitleWithDropDown
            customClass={''}
            title={'Filter by starred campaign'}
            source={starredOptions}
            defaultValue={
              filterIsStarred?.value
                ? starredOptions.filter(
                  (item) => item.value === filterIsStarred?.value?.value,
                )?.[0]
                : { label: 'All campaign', value: null }
            }
            setValue={(value) => {
              setFilterIsStarred(value);
            }}
            needFilter={false}
          />
        </div>

        {/* <div className="d-flex flex-column">
          <CustomTitleLabel title="Filter by starred campaign" />
          <label className="tips-message">
            Unselect all checkboxes (default) means showing data of all campaign
          </label>
          <div className="display-flex-area">
            {[
              { label: 'Starred', value: true },
              { label: 'Non- Starred', value: false },
            ].map((item, index) => {
              return (
                <CheckboxItem
                  index={index}
                  title={item.label}
                  defaultValue={filterIsStarred === item.value}
                  customClass={`platform-checkbox ${
                    index === 0 ? '' : 'platform-checkbox-notfirst'
                  }`}
                  onChange={() => {
                    if (filterIsStarred === item.value) {
                      setFilterIsStarred();
                    } else {
                      setFilterIsStarred(item.value);
                    }
                  }}
                />
              );
            })}
          </div>
        </div> */}

        {filterByBrands?.value || filterIsStarred?.value ? (
          <div className="campaign-overview-brand-select">
            <CustomTitleWithDropDown
              customClass={''}
              title={'Filter by campaigns'}
              source={campaignList}
              placeholder={'Search by campaign ID, campaign name'}
              labelContainPk={true}
              defaultValue={
                filterByCampaign?.value
                  ? filterByCampaign
                  : {
                    label: `All${filterIsStarred?.value?.value === true
                        ? ' Starred '
                        : filterIsStarred?.value?.value === false
                          ? ' Non-starred '
                          : ' '
                      }Campaigns`,
                    value: null,
                  }
              }
              setValue={setFilterByCampaign}
              loadMoreAction={'campaignList/getCampaignList'}
              filterAction={'campaignList/getCampaignList'}
              defaultFilter={{
                isSelectorByOverview: true,
                typeIn: `${CampaignType.generalMessageCampaign},${CampaignType.couponsCampaign}`,
                ...(filterByBrands?.value?.pk
                  ? { brand: filterByBrands?.value?.pk }
                  : null),
                ...(filterIsStarred?.value
                  ? {
                    starred_campaign:
                      filterIsStarred?.value?.value.toString(),
                  }
                  : null),
                getAll: 'false',
              }}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

const mapPropsToState = (state) => ({
  channelList: state.channel.allList,
  marketList: state.targetMarket.allList,
  brandList: state.brand.brandList,
  campaignList: state.campaignList.allListByOverview,
  campaignOverviewDate: state.overview.campaignOverviewDate,
});

export default connect(mapPropsToState)(FilterSection);
