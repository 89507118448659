import React, { Component } from 'react';
import './SingleCouponOverview.scss';
import moment from 'moment';
import { connect } from 'react-redux';
import { createAction } from '../../../utils';
import BaseOverView from './BaseOverview';
import CustomerFilterableDropdown from '../../transactions/CustomerFilterableDropdown';
import {
  COUPON_FORMATS,
  COUPON_REDEMPTION_METHOD,
  COUPON_TEMPLATE_TYPE,
} from '../../../config/CustomEnums';
import {
  DISCOUNT_CODE_FORMAT,
  displayDiscountCodeFormat,
} from '../../../models/CreateCouponModel';
import CouponQrCode from '../../../containers/engagement/coupon/CouponQrCode';
import { getDisplayUsedPlatForm } from '../../../models/CouponTransactionModel';
import { getDisplayAdminType } from '../../../models/AdminModel';
import { checkPartnerCodeFormat } from '../../../models/CouponUtil';
import CouponPartnerQrCode from '../../../containers/engagement/coupon/CouponPartnerQrCode';
import CouponPartnerBarcode from '../../../containers/engagement/coupon/CouponPartnerBarcode';

@connect(({ customerList, singleCoupon }) => ({
  customerList: customerList.customerList,
  singleCoupon: singleCoupon.singleCoupon,
}))
class SingleCouponOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultOwner: this.props.defaultOwner.owner,
    };
  }

  componentDidMount() {
    this.props.dispatch(createAction('customerList/getPagedCustomers')({}));
  }

  parseDateTime = (datetimeStr) => {
    return moment(datetimeStr).format('D MMM YYYY (ddd), LT');
  };
  parseCouponExpiryDate = () => {
    const effectiveDate = this.props.singleCoupon?.effectiveDate;
    const expiryDate = this.props.singleCoupon?.expiryDate;
    if (effectiveDate && expiryDate) {
      return `${this.parseDateTime(effectiveDate)} - ${this.parseDateTime(
        expiryDate,
      )}`;
    }
    if (this.props.couponSet.validPeriodType === 'ALL_TIME') {
      return 'All time valid';
    }
    if (this.props.couponSet.validPeriodType === 'ABSOLUTE') {
      return `${effectiveDate ? this.parseDateTime(effectiveDate) : ''} - ${
        expiryDate ? this.parseDateTime(expiryDate) : ''
      }`;
    }
    if (this.props.couponSet.validPeriodType === 'RELATIVE') {
      return `${this.props.couponSet.numberOfDaysToExpireAfterAcquisition} days since acquiring`;
    }
    return '-';
  };
  getPhoneNumber = (ownerProfile) => {
    const {
      mobilePhoneNumberCountryCode: countryCode,
      mobilePhoneNumberSubscriberNumber: subscriberNumber,
    } = ownerProfile || {};
    if (subscriberNumber) {
      return countryCode
        ? `+${countryCode}${subscriberNumber}`
        : subscriberNumber;
    }
    return '-';
  };

  getPartnerCode = (singleCoupon) => {
    const isDiscountCodeFormat =
      singleCoupon?.template?.redemptionMethod ===
      COUPON_REDEMPTION_METHOD.USERS_COPY_THE_DISCOUNT_CODE;
    if (singleCoupon?.code && isDiscountCodeFormat) {
      if (
        singleCoupon?.template?.discountCodeFormat ===
        DISCOUNT_CODE_FORMAT.BARCODE
      ) {
        return {
          component: (
            <CouponPartnerBarcode
              value={singleCoupon.code}
              format={singleCoupon?.couponbarcodeFormat}
              customQRCodeClass={'single-coupon-overview-coupon-qr-code'}
            />
          ),
        };
      } else if (
        singleCoupon?.template?.discountCodeFormat ===
        DISCOUNT_CODE_FORMAT.QRCODE
      ) {
        return {
          component: (
            <CouponPartnerQrCode
              value={singleCoupon.code}
              customQRCodeClass={'single-coupon-overview-coupon-qr-code'}
            />
          ),
        };
      }
    }
    return '-';
  };

  render() {
    // console.log('@@63: ', this.props.singleCoupon.template);
    const ownerOptions = this.props.customerList.map((customer) => {
      return {
        value: customer,
        label: customer.owner,
      };
    });
    const firstSectionTitle = 'GENERAL';
    const generalTitleList = [
      'ID',
      this.props.singleCoupon?.template?.formats ===
      COUPON_TEMPLATE_TYPE.couponCodeImage
        ? 'Image'
        : 'Serial Number',
      'Reference ID',
      'Coupon QR code',
      'Coupon medium',
      this.props.singleCoupon?.template?.redemptionMethod !==
      COUPON_REDEMPTION_METHOD.USERS_COPY_THE_DISCOUNT_CODE
        ? null
        : 'Coupon code display format',
      this.props.singleCoupon?.template?.redemptionMethod !==
        COUPON_REDEMPTION_METHOD.USERS_COPY_THE_DISCOUNT_CODE ||
      !checkPartnerCodeFormat(
        this.props.singleCoupon?.template?.discountCodeFormat,
      )
        ? null
        : this.props.singleCoupon?.template?.discountCodeFormat ===
          DISCOUNT_CODE_FORMAT.BARCODE
        ? 'Partner Barcode'
        : 'Partner QR code',
      this.props.singleCoupon?.template?.redemptionMethod ===
        COUPON_REDEMPTION_METHOD.USERS_COPY_THE_DISCOUNT_CODE &&
      checkPartnerCodeFormat(
        this.props.singleCoupon?.template?.discountCodeFormat,
      )
        ? null
        : 'Code format',
      this.props.singleCoupon?.template?.redemptionMethod ===
        COUPON_REDEMPTION_METHOD.USERS_COPY_THE_DISCOUNT_CODE &&
      !checkPartnerCodeFormat(
        this.props.singleCoupon?.template?.discountCodeFormat,
      )
        ? null
        : 'Coupon Barcode format',
      [
        DISCOUNT_CODE_FORMAT.CODE,
        DISCOUNT_CODE_FORMAT.QRCODE,
        DISCOUNT_CODE_FORMAT.BARCODE,
      ].includes(this.props.singleCoupon?.template?.discountCodeFormat)
        ? 'Import code'
        : 'Import url',
      'Membership ID',
      'Transaction value',
      'Transaction type',
      'Coupon Set',
      'Create Date',
      'Date of Purchase',
      // 'Date of Earning',
      'Date of Grant',
      'Expiry Date',
      'Days of the Week',
      'Special Date Inclusion',
      'Blackout Date Exclusion',
      'Status',
    ];
    const generalValueList = [
      this.props.singleCoupon?.pk,
      this.props.singleCoupon?.template?.formats ===
      COUPON_TEMPLATE_TYPE.couponCodeImage
        ? this.props.singleCoupon?.displayImage
        : this.props.singleCoupon?.serialNumber,
      this.props.singleCoupon?.referenceId || '-',
      this.props.singleCoupon?.template?.formats ===
        COUPON_TEMPLATE_TYPE.couponCodeImage ||
      !this.props.singleCoupon?.qrCodeString
        ? '-'
        : {
            component: (
              <CouponQrCode
                value={this.props.singleCoupon?.qrCodeString}
                srkValue={this.props.singleCoupon?.srk}
                customQRCodeClass={'single-coupon-overview-coupon-qr-code'}
              />
            ),
          },
      this.props.singleCoupon?.displayCouponMedium || '-',
      this.props.singleCoupon?.template?.redemptionMethod !==
      COUPON_REDEMPTION_METHOD.USERS_COPY_THE_DISCOUNT_CODE
        ? null
        : this.props.singleCoupon?.template?.discountCodeFormat
        ? displayDiscountCodeFormat[
            this.props.singleCoupon?.template?.discountCodeFormat
          ]
        : '-',
      this.props.singleCoupon?.template?.redemptionMethod !==
        COUPON_REDEMPTION_METHOD.USERS_COPY_THE_DISCOUNT_CODE ||
      !checkPartnerCodeFormat(
        this.props.singleCoupon?.template?.discountCodeFormat,
      )
        ? null
        : this.getPartnerCode(this.props.singleCoupon),
      this.props.singleCoupon?.template?.redemptionMethod ===
        COUPON_REDEMPTION_METHOD.USERS_COPY_THE_DISCOUNT_CODE &&
      checkPartnerCodeFormat(
        this.props.singleCoupon?.template?.discountCodeFormat,
      )
        ? null
        : this.props.couponSet?.formats === COUPON_FORMATS.COUPON_BAR_CODE
        ? 'Coupon Barcode'
        : this.props.couponSet?.formats === COUPON_FORMATS.COUPON_QR_CODE
        ? 'Coupon QR code'
        : '-',
      this.props.singleCoupon?.template?.redemptionMethod ===
        COUPON_REDEMPTION_METHOD.USERS_COPY_THE_DISCOUNT_CODE &&
      !checkPartnerCodeFormat(
        this.props.singleCoupon?.template?.discountCodeFormat,
      )
        ? null
        : this.props.singleCoupon?.couponBarcode || '-',
      this.props.singleCoupon?.code || '-',
      this.props.singleCoupon?.membershipId
        ? this.props.singleCoupon?.membershipId
        : this.props.singleCoupon?.bindFortuneBagContent ||
          this.props.singleCoupon?.isVoid
        ? '-'
        : {
            component: (
              <CustomerFilterableDropdown
                defaultSelectedCustomer={this.props.defaultOwner}
                selectCustomer={this.props.selectedOwner}
              />
            ),
          },
      this.props.singleCoupon?.transactionValue || '-',
      this.props.singleCoupon?.transactionType || '-',
      this.props.couponSet.name,
      this.parseDateTime(this.props.singleCoupon?.creationDate),
      this.props.singleCoupon?.dateOfPurchase
        ? this.parseDateTime(this.props.singleCoupon?.dateOfPurchase)
        : '-',
      // this.props.singleCoupon?.dateOfEarning
      //   ? this.parseDateTime(this.props.singleCoupon?.dateOfEarning)
      //   : '-',
      this.props.singleCoupon?.dateOfGrant
        ? this.parseDateTime(this.props.singleCoupon?.dateOfGrant)
        : '-',
      this.parseCouponExpiryDate(),
      {
        component: (
          <div className="week-rule-area">
            {this.props.singleCoupon?.weeklyRulesDisplay?.map((item) => (
              <li>{item}</li>
            )) || '-'}
          </div>
        ),
      },
      {
        component: (
          <div className="week-rule-area">
            {this.props.singleCoupon?.dateRulesDisplay?.map((item) => (
              <li>{item}</li>
            )) || '-'}
          </div>
        ),
      },
      {
        component: (
          <div className="week-rule-area">
            {this.props.singleCoupon?.excludeDateRulesDisplay?.map((item) => (
              <li>{item}</li>
            )) || '-'}
          </div>
        ),
      },
      this.props.singleCoupon?.status,
    ];

    const secondSectionTitle = 'COUPON USAGE RECORD';
    const usageTitleList = [
      'Coupon use date & time',
      'Coupon used at (Store)',
      'Used platform',
      'Use method',
      'Admin account type',
      'Admin account',
    ];
    const usageValueList = [
      this.props.singleCoupon?.usedAt
        ? this.parseDateTime(this.props.singleCoupon?.usedAt)
        : '-',
      this.props.singleCoupon?.store?.name || '-',
      getDisplayUsedPlatForm(this.props.singleCoupon?.usedPlatform),
      this.props.singleCoupon?.useMethod,
      getDisplayAdminType(this.props.singleCoupon?.administrator?.adminType),
      this.props?.singleCoupon?.administrator?.pk
        ? `[ID:${this.props?.singleCoupon?.administrator?.pk}] ${
            this.props.singleCoupon?.administrator?.username || '-'
          }`
        : '-',
    ];
    return (
      <div>
        <BaseOverView
          className={'single-coupon-overview-general'}
          title={firstSectionTitle}
          itemTitleList={generalTitleList}
          itemValueList={generalValueList}
        />

        <BaseOverView
          className={''}
          title={secondSectionTitle}
          itemTitleList={usageTitleList}
          itemValueList={usageValueList}
        />
      </div>
    );
  }
}

export default SingleCouponOverview;
