import React, { useState, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
  hasError,
  ReactHookFormErrorMessage,
} from '../../../components/base/ErrorFieldMessage';
import { CustomTitleLabel } from '../../../components/earning/CustomBaseComponments';
import CustomTitleWithInput from '../../../components/base/CustomTitleWithInput';
import CustomSwitchButton from '../../../components/base/CustomSwitchButton';
import { StoreErrorHandleFields } from './StoreErrorHandleFields';
import { useHistory } from 'react-router-dom';
import BasePrompt from '../../../components/base/prompt/BasePrompt';
import { useDispatch, useSelector } from 'react-redux';
import SpecificCustomSwitchButton from './SpecificCustomSwitchButton';
import BaseMutipleSelectorV2 from '../../../components/base/BaseMultipleSelectorV2';

const switchButtons = (title, value, action) => {
  return (
    <>
      <label className="create-section-label create-section-label-bottom-space">
        {title}
      </label>
      <CustomSwitchButton defaultChecked={value} onChange={action} />
    </>
  );
};

const followBrandSwitchButon = (value, action) => {
  return (
    <>
      <label className="create-section-label create-section-label-bottom-space">
        Follow the brand's setting
      </label>
      <SpecificCustomSwitchButton checked={value} onChange={action} />
    </>
  );
};

const getPromptConfig = ({ isSwitching, history, hide, confirm }) => {
  if (isSwitching) {
    return {
      rightButton: {
        text: `Confirm`,
        action: confirm,
      },
      leftButton: {
        text: `Cancel`,
        action: hide,
      },
      title: `Confirm to follow the brand’s setting?`,
      description: `Upon confirmation, the current settings will be removed`,
    };
  }
  return {
    rightButton: {
      text: `Go to create attribute tag`,
      action: () => {
        confirm();
        history.push({
          pathname: '/tags/create',
          state: {
            from: history.location,
            title: 'Continue to create store?',
            description: 'You can continue to create the store.',
          },
        });
      },
    },
    title: `Go to create a attribute tag?`,
    description: `You will leave the store creation process. After you create a new attribute tag, you can back to this page.`,
  };
};

export default function PropertyGeneralSection() {
  const { watch, setValue, control, formState } = useFormContext();
  const { errors } = formState;
  const watchDisplayPriority = watch('displayPriority');
  const watchSelectedBrand = watch('brand');
  const watchFollowBrandSetting = watch('isFollowBrandAttribute');
  const watchTags = watch('attributeTags');
  const watchActive = watch('isPublished');
  const history = useHistory();
  const dispatch = useDispatch();
  const [promptConfig, setPromtConfig] = useState(false);
  const { selectedBrand, attributeTagList } = useSelector((state) => ({
    selectedBrand: state.brand.selectedBrand || [],
    attributeTagList: state.attributeTag.notPagedAllList || [],
  }));

  const switchPromtConfig = getPromptConfig({
    isSwitching: true,
    history: history,
    hide: () => {
      console.log('isFollowBrandAttribute hide:');
      setPromtConfig(null);
      setValue('isFollowBrandAttribute', false, { shouldDirty: true });
    },
    confirm: () => {
      setPromtConfig(null);
      setValue('isFollowBrandAttribute', true, { shouldDirty: true });
      setValue('attributeTags', selectedBrand?.attributeTags, {
        shouldDirty: true,
      });
    },
  });
  const createNewConfig = getPromptConfig({
    isSwitching: false,
    history: history,
    confirm: () => setPromtConfig(null),
  });

  useEffect(() => {
    return () => {dispatch({type: 'brand/clearData'})}
  }, [])

  useEffect(() => {
    if (watchSelectedBrand?.pk) {
      dispatch({
        type: 'brand/getBrand',
        payload: { brandPK: watchSelectedBrand?.pk },
      });
    }
  }, [dispatch, watchSelectedBrand]);

  useEffect(() => {
    if (watchFollowBrandSetting) {
      setValue('attributeTags', selectedBrand?.attributeTags, {
        shouldDirty: false,
      });
    }
  }, [dispatch, selectedBrand]);

  const orderArea = (
    <Controller
      control={control}
      name="displayPriority"
      rules={{
        validate: {
          min: (value) =>
            parseInt(value) > 0 ||
            StoreErrorHandleFields.displayPriority.required,
        },
      }}
      render={() => (
        <>
          <CustomTitleWithInput
            title={'Display order'}
            error={{
              error: hasError(errors, 'displayPriority'),
            }}
            defaultValue={watchDisplayPriority}
            setValue={(value) => {
              setValue('displayPriority', value, { shouldDirty: true });
            }}
            type={'number'}
          />
          <label className="create-message-suggest">
            {`Minimum value is 1. The smaller value, the higher priority.`}
          </label>
          <ReactHookFormErrorMessage errors={errors} id="displayPriority" />
        </>
      )}
    />
  );

  const followBrandSwitch = followBrandSwitchButon(
    watchFollowBrandSetting,
    (value) => {
      if (value) {
        setPromtConfig(switchPromtConfig);
        return;
      } else {
        setValue('attributeTags', [], { shouldDirty: true });
      }
      setValue('isFollowBrandAttribute', value, { shouldDirty: true });
    },
  );

  const actionSwitch = switchButtons('Active', watchActive, (value) => {
    setValue('isPublished', value, { shouldDirty: true });
  });

  const tagSelector = (
    <Controller
      control={control}
      name="attributeTags"
      rules={{
        validate: {
          min: (value) =>
            value?.length > 0 ||
            watchFollowBrandSetting ||
            StoreErrorHandleFields.displayPriority.required,
        },
      }}
      render={() => (
        <BaseMutipleSelectorV2
          error={{
            error: hasError(errors, 'attributeTags'),
            id: 'attributeTags',
            message: StoreErrorHandleFields.tags.required,
          }}
          hideTopSpace={true}
          searchPlaceholder="Search by key tag, attribute tag"
          namespace="attributeTag"
          disabled={watchFollowBrandSetting}
          addButton={
            watchFollowBrandSetting
              ? null
              : {
                  title: 'Add Attribute tag',
                  action: () => setPromtConfig(createNewConfig),
                  customClass: 'btn-back-button-common add-remove-botton-style',
                }
          }
          custom={{
            customFilter: (inputValue, option) => {
              const inputIgnoreCase = inputValue?.toLowerCase();
              const optionIgnoreCase = option?.name?.toLowerCase();
              const groupIgnoreCase = option?.tagKey?.name?.toLowerCase();
              return (
                optionIgnoreCase?.includes(inputIgnoreCase) ||
                groupIgnoreCase?.includes(inputIgnoreCase)
              );
            },
          }}
          data={{
            sourceData: attributeTagList,
            targetData: watchTags,
            targetChange: (value) => {
              setValue('attributeTags', value, { shouldDirty: true });
            },
          }}
          groupKey="tagKey"
        />
      )}
    />
  );

  const attributeTags = (
    <>
      <CustomTitleLabel title={'Attribute tag'} />
      {followBrandSwitch}
      <label>
        If Off, any further changes on the brand's attribute will not be updated
        to this store.
      </label>
      {tagSelector}
    </>
  );

  return (
    <>
      <label className="create-section-title">General</label>
      {orderArea}
      {attributeTags}
      {actionSwitch}
      <BasePrompt
        show={!!promptConfig}
        closeAction={() => {
          setPromtConfig(null);
          promptConfig?.leftButton &&
            setValue('isFollowBrandAttribute', false, { shouldDirty: true });
        }}
        leftButton={promptConfig?.leftButton}
        rightButton={promptConfig?.rightButton}
        title={promptConfig?.title}
        description={promptConfig?.description}
      />
    </>
  );
}
