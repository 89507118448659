import { createModel } from './BaseModel';
import {
  getPagedList,
  getOne,
  createOrUpdate,
} from '../services/OTPChannelApiHelper';
import {
  LanguageConfig,
  SESSION_KEYS,
  YES_OR_NO_TAG,
} from '../config/CustomEnums';

const parseDetail = (data) => {
  const enData = { ...data };
  delete enData.translations;

  const translations = { en: enData };
  (data.translations?.edges || []).forEach((item) => {
    // console.log('@@19: ', item.node?.language, item.node);
    if (item.node?.language)
      translations[item.node?.language || LanguageConfig.english] = item.node;
  });
  //   console.log(data.translations?.edges);
  return {
    ...data,
    name: data.channel,
    countryCodes: data.countryCodesList?.map((item) => {
      return {
        ...item,
        pk: parseInt(item.pk),
      };
    }),
    countryCodesList: data.countryCodesList,
    displayCountryCodes: data.countryCodesList
      ?.map((item) => `+${item.pk} ${item.name}`)
      ?.join(', '),
    status: data.showInFrontend ? YES_OR_NO_TAG.yes : YES_OR_NO_TAG.no,
    translations,
  };
};

const parseInputData = (source) => {
  return {
    title: source?.title,
    description: source?.description,
    smallDescription: source?.smallDescription,
  };
};

export default createModel({
  namespace: 'otpChannel',
  params: {
    sessionKey: SESSION_KEYS.OTP_CHANNEL_SESSION_KEY,
    dataKey: SESSION_KEYS.OTP_CHANNEL_DATA_SESSION_KEY,
    listAPI: getPagedList,
    parse: (data) =>
      data?.otpChannels.edges.map((item) => parseDetail(item.node)),
    // deleteAPI: deleteOfferTypeLabels,
    pkNode: 'OTPChannelNode',
    detailAPI: getOne,
    parseDetail: (data) => parseDetail(data.otpChannel),
    createdNewParse: (data) => parseDetail(data.createOtpChannel.node),
    initState: {
      createUpdateError: [],
    },
  },
  states: {
    listDisplayFields: [
      { displayName: 'ID', fieldName: 'pk', orderField: 'pk' },
      {
        displayName: 'OTP channel',
        fieldName: 'name',
      },
      { displayName: 'Show in Frontend', fieldName: 'status' },
    ],
    detail: {},
    // createdNew: {}
  },
  reducers: {},
  effects: {
    createOrUpdate: [
      function* ({ payload }, { call, select, put }) {
        const detail = yield select((state) => state.otpChannel.detail);
        const languages = yield select((state) => state.language.allList);

        const data = {
          channel: payload.data.channel,
          displayPriority: payload.data.displayPriority,
          countryCodes: payload.data.countryCodes?.map((item) => item.pk),
          showInFrontend: payload.data.showInFrontend || false,
          ...parseInputData(payload.data.translations.en),
          translations: languages
            .filter((item) => item.code !== 'en')
            .map((item) => {
              const key = item.code;
              const translation = {
                language: key,
                ...parseInputData(payload.data.translations[key]),
              };

              if (key in (detail?.translations || {})) {
                translation.id = detail.translations[key].pk;
              }
              return translation;
            }),
        };

        if (payload.data.id) {
          data.id = payload.data.pk;
          //   data.translations = [...data.translations];
        }
        // else {
        //   const filledTranslations = languages
        //     .map((item) => {
        //       if (item.code in payload.translations) {
        //         return;
        //       }

        //       return {
        //         language: item.code,
        //         name: '',
        //       };
        //     })
        //     .filter((item) => item !== undefined);
        //   data.translations = [...data.translations, ...filledTranslations];
        // }

        const serviceArgs = [createOrUpdate, data];
        yield put({
          type: 'responseCreateOrUpdate',
          payload: { serviceArgs, isCreate: payload.data.id ? false : true },
        });
      },
      { type: 'takeLatest' },
    ],
  },
});
