import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { createAction } from '../../../utils';
import {
  saveDataToSessionStorage,
  removeDataFromSessionStorage,
} from '../../../models/StoreModel';
import { SavedStatus } from '../../../config/CustomEnums';
import BaseForm from '../../../components/base/v2/BaseForm';
import CreateStoreStepOne from './CreateStoreStepOne';
import CreateStoreStepTwo from './CreateStoreStepTwo';
import Loading from '../../../components/base/Loading';
import './CreateStore.scss';

function CreateStore() {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = params;

  const {
    store,
    formHasSubmitted,
    saved,
    currentStep,
    stepConfig,
    hasUpdatedDefaultValues,
  } = useSelector((state) => ({
    store: state.storeModel.oneStore,
    formHasSubmitted: state.storeModel.formHasSubmitted,
    saved: state.storeModel.saved,
    currentStep: state.storeModel.currentStep,
    stepConfig: state.storeModel.stepConfig,
    hasUpdatedDefaultValues: state.storeModel.hasUpdatedDefaultValues,
  }));

  const stepSet = [<CreateStoreStepOne />, <CreateStoreStepTwo />];
  const goToStoreList = () =>
    history.push({ pathname: '/stores', search: '?sort=displayPriority' });

  useEffect(() => {
    if (id) {
      dispatch(createAction('storeModel/getOneStore')({ id }));
    } else {
      dispatch({ type: 'storeModel/loadDataFromCookie' });
    }
  }, [dispatch, id]);

  useEffect(() => {
    dispatch({ type: 'attributeTag/getAllList' });
    return () => {
      dispatch({ type: 'storeModel/clearData' });
      dispatch({ type: 'attributeTag/clearData' });
    };
  }, []);

  useEffect(() => {
    if (id && saved === SavedStatus.savedWithSuccess) {
      goToStoreList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saved]);

  if (!store?.id && id) {
    return <Loading customClass={'common-full-height '} />;
  }
  if (store.isFollowBrandAttribute === undefined) {
    store.isFollowBrandAttribute = true;
  }

  return (
    <BaseForm
      defaultValues={store}
      formHasSubmitted={formHasSubmitted}
      hasUpdatedDefaultValues={hasUpdatedDefaultValues}
      tempSave={(save, getValues) => {
        if (save) {
          saveDataToSessionStorage(getValues());
        } else {
          removeDataFromSessionStorage();
        }
      }}
      showFinishPop={saved === SavedStatus.savedWithSuccess}
      continueCreateConfig={{
        onConfirm: () => {
          history.push(history.location.state?.from);
        },
        onCancel: goToStoreList,
        onClose: goToStoreList,
      }}
      nextStepConfig={{
        title: `Successfully ${id ? 'Updated' : 'Created'}!`,
        description: `Store is successfully ${
          id ? 'updated' : 'created'
        }. You can preview and download the store QR code`,
        steps: null,
        buttons: [
          {
            text: 'Preview QR code',
            action: () =>
              history.push({
                pathname: `/stores/${store.storePK}/`,
              }),
          },
          {
            text: 'Back to store list',
            action: () =>
              history.push({
                pathname: '/stores',
                search: '?sort=displayPriority',
              }),
          },
        ],
      }}
      content={stepSet}
      currentStep={currentStep}
      breadcrumb={<CustomBreadcrumb name={id ? store.storeName : ''} />}
      stepsConfig={stepConfig}
      caution={{
        detail: '',
        title: id ? store.storeName : 'Create Store',
      }}
    />
  );
}
export default CreateStore;
