import { createModel } from './BaseModel';
import { getCountryCodeList } from '../services/CountryCodeApiHelper';

export default createModel({
  namespace: 'countryCode',
  params: {
    initState: {},
    sessionKey: 'countryCode',
    dataKey: 'countryCodes',
    listAPI: getCountryCodeList,
    parse: (data) =>
      data?.countryCodes.edges.map((item) => ({
        ...item.node,
        name: `+${item.node.pk} ${item.node.name}`,
      })),
    objectKey: 'countryCodes',
  },
  states: {},
  reducers: {},
  effects: {},
});
