import React, { useEffect } from 'react';
import ContentSections from '../base/ContentSections';
import TransactionGeneralSection from './TransactionGeneralSection';
import TransactionAmountSection from './TransactionAmountSection';
import {
  ContinueWithBackButtons,
  OnlyContinueButton,
} from '../base/BottomStepComponent';
import { useDispatch } from 'react-redux';
import { createAction } from '../../utils';
import { useLocation } from 'react-router-dom';

function CreateTransactionStepTwo() {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(createAction('admin/getAllAdmins')());
  }, [dispatch]);

  const sections = [
    <TransactionGeneralSection />,
    <TransactionAmountSection />,
  ];
  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      {location.pathname.includes('edit') ? (
        <OnlyContinueButton
          disabledContinue={false}
          continueAction={() => {
            dispatch(
              createAction('createTransaction/stepChange')({
                isBack: false,
                step: 1,
              }),
            );
          }}
        />
      ) : (
        <ContinueWithBackButtons
          backAction={() => {
            dispatch(
              createAction('createTransaction/stepChange')({
                isBack: true,
                step: 1,
              }),
            );
          }}
          continueAction={() => {
            dispatch(
              createAction('createTransaction/stepChange')({
                isBack: false,
                step: 1,
              }),
            );
          }}
        />
      )}
    </>
  );
}

export default CreateTransactionStepTwo;
