import { BaseHelper } from './BaseGraphQLAPIHelper';
import moment from 'moment';
import { TimeFormater } from '../utils/TimeFormatUtil';

const RELATED_COUPON_CAMPAIGNS = `
couponTemplatesMerchantTerms(isFollowMerchantTermsAndConditionsTemplate: true) {
  totalCount
  edges {
    node {
      pk
      name
    }
  }
}
couponTemplatesGenericTerms(isFollowGenericTermsAndConditionsTemplate: true) {
  totalCount
  edges {
    node {
      pk
      name
    }
  }
}
campaignsMerchantTerms(isFollowMerchantTermsAndConditionsTemplate: true) {
  totalCount
  edges {
    node {
      pk
      name
    }
  }
}
campaignsGenericTerms(isFollowGenericTermsAndConditionsTemplate: true) {
  totalCount
  edges {
    node {
      pk
      name
    }
  }
}`;

const TERMS_AND_CONDITIONS_NODE = `{
  creationDate
  lastModifiedDate
  language
  id
  pk
  templateType
  title
  content
  translations {
    edges {
      node {
        language
        title
        content 
        pk
      }
    }
  }
}`;

const TERMS_AND_CONDITIONS_SELECTOR_NODE = `{
  creationDate
  lastModifiedDate
  language
  id
  pk
  templateType
  title
  content
  translations {
    edges {
      node {
        language
        title
        content 
        pk
      }
    }
  }
}`;

export const getTermsAndConditionsList = (afterCursor, others) => {
  let filter = '';
  const { sort, search, type, last_modified, create_at } = others || {};
  if (search) {
    filter = `, search:"${search}"`;
  }
  if (sort) {
    filter += `, orderBy:"${sort}"`;
  } else {
    filter += `, orderBy: "-pk"`;
  }
  if (type) {
    filter += `, templateTypeIn:"${type}"`;
  }
  if (last_modified) {
    filter += `, lastModifiedDateGte:"${moment(last_modified).format(
      TimeFormater.GTMFormat,
    )}"`;
  }
  if (create_at) {
    filter += `, creationDateGte:"${moment(create_at).format(
      TimeFormater.GTMFormat,
    )}"`;
  }
  const query = `{
    termsAndConditionsTemplates(first: 20, after:"${afterCursor}"${filter}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node ${
          others?.isSelectorLoad
            ? TERMS_AND_CONDITIONS_SELECTOR_NODE
            : TERMS_AND_CONDITIONS_NODE
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getTermsAndConditionsDetail = (id) => {
  const query = `{
    termsAndConditionsTemplate(id: "${id}")
      ${TERMS_AND_CONDITIONS_NODE}
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const createTermsAndConditions = (payload) => {
  const query = `mutation createTermsAndConditionsTemplate($input: CreateTermsAndConditionsTemplateInput!) {
    createTermsAndConditionsTemplate(input: $input) {
      node ${TERMS_AND_CONDITIONS_NODE}
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: {
      ...payload,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateTermsAndConditions = (payload) => {
  const query = `mutation updateTermsAndConditionsTemplate($input: UpdateTermsAndConditionsTemplateInput!) {
    updateTermsAndConditionsTemplate(input: $input) {
      node ${TERMS_AND_CONDITIONS_NODE}
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: {
      ...payload,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteTermsAndConditions = (ids) => {
  const query = `mutation deleteTermsAndConditionsTemplates($input: DeleteTermsAndConditionsTemplatesInput!) {
    deleteTermsAndConditionsTemplates(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids,
    },
  };
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};
