import { BaseHelper } from './BaseGraphQLAPIHelper';

const BASE_MARKET_NODE = `{
  id
  pk
  name
  parameter
}`;

const BASE_MARKET_NODE_WITH_CHANNEL = `{
  id
  pk
  name
  parameter
  channels {
    pk
    name
  }
  languages {
    edges {
      node {
        pk
        name
        parameter
        code
      }
    }
  }
}`;

const MARKET_NODE = `{
  id
  pk
  name
  parameter
  channels {
    pk
    name
  }
  relatedCampaigns {
    id
    name
  }
  languages {
    edges {
      node {
        pk
        name
        parameter
        code
      }
    }
  }
}`;

const LIST_MARKET_NODE = `{
  id
  pk
  name
  parameter
  channels {
    pk
    name
  }
  relatedCampaignNames
  languages {
    edges {
      node {
        pk
        name
        parameter
        code
      }
    }
  }
}`;

export const getTargetMarketList = (afterCursor, moreSearch) => {
  let filter = '';
  if (moreSearch.filterName) {
    filter = `, nameIcontains:"${moreSearch.filterName}"`;
  }
  if (moreSearch.searchKey) {
    filter += `, search:"${moreSearch.searchKey}"`;
  }
  if (moreSearch?.channel) {
    filter += `, channelsIn:"${moreSearch.channel}"`;
  }
  if (moreSearch?.language) {
    filter += `, languagesIn:"${moreSearch.language}"`;
  }
  let orderBy = moreSearch.rank ? '-pk' : 'pk';
  console.log('@@32: ', moreSearch, filter);
  const query = `{
    targetMarkets(first: ${
      moreSearch.pageSize ? moreSearch.pageSize : 20
    }, after:"${afterCursor}", orderBy:"${orderBy}", queryCampaign: ${
    moreSearch?.isAll || moreSearch?.isSelectorLoad ? false : true
  }${filter}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node ${
          moreSearch?.isAll || moreSearch?.isSelectorLoad
            ? moreSearch?.needChannel
              ? BASE_MARKET_NODE_WITH_CHANNEL
              : BASE_MARKET_NODE
            : LIST_MARKET_NODE
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getTargetMarket = (id) => {
  const query = `{
    targetMarket(id: "${id}") ${MARKET_NODE}
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const deleteTargetMarkets = (ids) => {
  const query = `mutation DeleteTargetMarkets($input: DeleteTargetMarketsInput!) {
    deleteTargetMarkets(input: $input) {
      success
    }
  }
  `;
  const variables = { input: { ids: ids } };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createOrUpdateTargetMarket = (values) => {
  const action = values.id ? 'Update' : 'Create';
  let query = `mutation ${action}TargetMarket($input: ${action}TargetMarketInput!) {
    ${action.toLowerCase()}TargetMarket(input: $input) {
      success
      node ${MARKET_NODE}
      errors {
        field
        messages
      }
    }
  }

  `;

  const variables = { input: values };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
