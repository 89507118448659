import React, { useEffect } from 'react';
import './CreateLogoLabelStepOne.scss';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch, connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { LanguageConfig } from '../../config/CustomEnums';
import { saveLogoLabelToSessionStorage } from '../../models/LogoLabelModel';
import { SaveAndBackWithOutTempButtons } from '../base/BottomStepComponent';
import ContentSections from '../base/ContentSections';
import CustomTitleWithInput from '../base/CustomTitleWithInput';
import { hasError, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import ImageUploader from '../base/ImageUploader';
import { checkUrlIsValid, createAction, ERROR_MESSAGE } from '../../utils';

const LOGO_LABEL_ERRORS = {
  name: {
    required: 'Please provide name',
  },
  displayPriority: {
    required: 'Please provide display priority',
  },
  icon: {
    required: 'Please provide logo image',
  },
};

const CreateLogoLabelStepOne = ({ languages }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const { id } = params;
  const {
    control,
    watch,
    setValue,
    getValues,
    register,
    trigger,
    setError,
    formState: { errors },
  } = useFormContext();
  const watchDisplayPriority = watch('displayPriority');
  const renderLanguageTab = (language) => {
    const iconName = `translations.${language}.icon`;
    const watchIcon = watch(iconName);

    // const logoUrl = `translations.${language}.logoLabelUrl`;
    // const watchUrl = watch(logoUrl);

    console.log(errors);
    return (
      <div>
        <div className="first-section-title">General</div>
        <div className="second-section">
          <div className="second-title-section">
            <div className="second-section-title">Name</div>
          </div>
          <div>
            <input
              type="text"
              className={`text-input-field ${
                hasError(errors, `translations.${language}.name`)
                  ? 'error-field'
                  : ''
              }`}
              {...register(
                `translations.${language}.name`,
                language === LanguageConfig.english
                  ? LOGO_LABEL_ERRORS.name
                  : {},
              )}
            />
          </div>
          <ReactHookFormErrorMessage
            errors={errors}
            id={`translations.${language}.name`}
          />
        </div>
        <div className="second-section">
          {/* <div className="second-title-section">
            <div className="second-section-title">
              URL for logo label(optional)
            </div>
          </div> */}
          <div>
            {/* <input
              type="text"
              className={`text-input-field ${
                hasError(errors, `translations.${language}.logoLabelUrl`)
                  ? 'error-field'
                  : ''
              }`}
              {...register(`translations.${language}.logoLabelUrl`, {
                required: false,
              })}
            /> */}
            {/* <textarea
              className={`text-input-field muiltpleLinesInput ${
                hasError(errors, `translations.${language}.logoLabelUrl`)
                  ? 'error-field'
                  : ''
              }`}
              {...register(`translations.${language}.logoLabelUrl`, {
                required: false,
              })}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
            /> */}
            <Controller
              control={control}
              name={`translations.${language}.logoLabelUrl`}
              rules={{
                validate: (value) => {
                  if (value) {
                    return checkUrlIsValid(value) || ERROR_MESSAGE.INVAILD_URL;
                  }
                },
              }}
              render={({ field: { value } }) => (
                <CustomTitleWithInput
                  title="URL for logo label(optional)"
                  defaultValue={value}
                  setValue={(newValue) => {
                    setValue(
                      `translations.${language}.logoLabelUrl`,
                      newValue,
                      {
                        shouldDirty: true,
                      },
                    );
                  }}
                  multipleLines={true}
                  customClass="muiltpleLinesInput"
                />
              )}
            />
          </div>
          <ReactHookFormErrorMessage
            errors={errors}
            id={`translations.${language}.logoLabelUrl`}
          />
        </div>
        <div className="second-section">
          <div className="second-section-title">Logo image</div>
          <div className="second-section_description">
            Suggested Image Size:600 px * 600 px (Less than 2MB, support JPG,
            PNG and GIF only.)
          </div>
          <Controller
            control={control}
            name={iconName}
            rules={
              language === LanguageConfig.english ? LOGO_LABEL_ERRORS.icon : {}
            }
            render={() => (
              <ImageUploader
                minWidth={600}
                minHieght={600}
                images={watchIcon ? [watchIcon] : []}
                onImageStateChange={(newState) => {
                  console.log(newState);
                  setValue(
                    iconName,
                    newState ? (newState?.[0] ? newState?.[0] : null) : null,
                    {
                      shouldDirty: true,
                    },
                  );
                }}
                aspect={1 / 1}
                maxImageNum={1}
                language={language}
                uploadImageClicked={() => {}}
                errorMessage={
                  hasError(errors, iconName)
                    ? LOGO_LABEL_ERRORS.icon.required
                    : ''
                }
              />
            )}
          />
        </div>
        {language === LanguageConfig.english ? (
          <Controller
            control={control}
            name="displayPriority"
            rules={{
              validate: {
                min: (value) =>
                  parseInt(value) > 0 ||
                  LOGO_LABEL_ERRORS.displayPriority.required,
              },
            }}
            render={() => (
              <>
                <CustomTitleWithInput
                  title={'Display order'}
                  error={{
                    error: hasError(errors, 'displayPriority'),
                  }}
                  defaultValue={watchDisplayPriority}
                  setValue={(value) => {
                    console.log(value);
                    setValue('displayPriority', value);
                  }}
                  type={'number'}
                  extra={{
                    min: 1,
                  }}
                />
                <label className="create-message-suggest">
                  {`Minimum value is 1. The smaller value, the higher priority.`}
                </label>
                <ReactHookFormErrorMessage
                  errors={errors}
                  id="displayPriority"
                  extra="display-priority-errors"
                />
              </>
            )}
          />
        ) : null}
      </div>
    );
  };

  useEffect(() => {
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
  }, []);

  return (
    <>
      <ContentSections
        languageTabContent={{
          containers: languages?.map((item) => ({
            container: [renderLanguageTab(item.code)],
            key: item.code,
            title: item.sourceName,
          })),
        }}
        hidePreview={true}
      />
      <SaveAndBackWithOutTempButtons
        backAction={() => {
          history.goBack();
        }}
        saveText="Save"
        saveAction={async () => {
          // console.log(getValues());
          let isValid = await trigger([
            'displayPriority',
            'translations.en.name',
            'translations.en.icon',
            ...languages.map((l) => `translations.${l.code}.logoLabelUrl`),
          ]);

          if (isValid) {
            const data = getValues();
            if (id) {
              dispatch(createAction('logoLabel/updateLogoLabel')({ data }));
            } else {
              dispatch(createAction('logoLabel/createLogoLabel')({ data }));
            }
          }
        }}
      />
    </>
  );
};

const mapPropsToState = (state) => ({
  languages: state.language.allList,
});

export default connect(mapPropsToState)(CreateLogoLabelStepOne);
