import React, { useState } from 'react';
import ContentSections from '../base/ContentSections';
import MembershipInfoCard from '../customer/MembershipInfoCard';
import { connect, useDispatch } from 'react-redux';
import {
  BackAndMultipleButtons,
  SaveTempButton,
  SaveButton,
} from '../base/BottomStepComponent';
import { createAction } from '../../utils';
import { useHistory, useParams } from 'react-router-dom';
import ListButtonsGroup from '../base/ListButtonsGroup';
import ScheduledSuccessPrompt from './ScheduledSuccessPrompt';
import ScheduledFailedPrompt from './ScheduledFailedPrompt';
import { useFormContext } from 'react-hook-form';

function CreateGeneralAlertStepThree({ languages }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const queryString = require('query-string');

  const id = params.id;
  const { getValues, watch } = useFormContext();
  const translations = watch('translations') || {};
  const displayPriority = watch('displayPriority');
  const severityLevel = watch('severityLevel');
  const deliveryStartDate = watch('deliveryStartDate');
  const deliveryEndDate = watch('deliveryEndDate');
  const displayStatus = watch('displayStatus');
  const scheduledStatus = watch('scheduledStatus');
  const startDateChanged = watch('startDateChanged');
  const endDateChanged = watch('endDateChanged');

  console.log('createGeneralThree', getValues());

  const [showScheduledFailedPrompt, setShowScheduledFailedPrompt] =
    useState(false);
  const [showScheduledSuccessPrompt, setShowScheduledSuccessPrompt] =
    useState(false);
  const [scheduledData, setScheduledData] = useState([]);

  const renderTabForLanguage = (language) => {
    const name = translations[language]?.content;

    return (
      <MembershipInfoCard
        title="PROPERTIES"
        data={{
          name,
          displayPriority,
          severityLevel,
          deliveryStartDate,
          deliveryEndDate,
          displayStatus,
        }}
        fields={[
          [
            {
              title: 'Content',
              field: 'name',
              isHtml: true,
            },
          ],
          [
            {
              title: 'Order',
              field: 'displayPriority',
            },
          ],
          [
            {
              title: 'Severity level',
              field: 'severityLevel',
            },
          ],
          [
            {
              title: 'Start time',
              field: 'deliveryStartDate',
            },
          ],
          [
            {
              title: 'End time',
              field: 'deliveryEndDate',
            },
          ],
          [
            {
              title: 'Status',
              field: 'displayStatus',
            },
          ],
        ]}
      />
    );
  };

  const saveButtonAction = (
    isPublished,
    backToList,
    afterAction = () => {},
  ) => {
    dispatch(
      createAction('generalAlert/createOrUpdate')({
        isPublished,
        backToList: id,
        data: getValues(),
        afterActions: (data) => {
          const generalAlert = id
            ? data.updateGeneralAlert
            : data.createGeneralAlert;
          console.log('@step three 83', generalAlert);
          if (generalAlert && generalAlert.generalAlerts) {
            console.log('@step three 85', generalAlert.generalAlerts);
            setScheduledData(generalAlert.generalAlerts);
            setShowScheduledFailedPrompt(true);
          } else if (backToList) {
            console.log('@step three 86');
            setShowScheduledSuccessPrompt(true);
          } else {
            history.push({
              pathname: '/general_alert',
              search: queryString.stringify({
                page: 1,
                rank: true,
                search: '',
              }),
            });
          }

          afterAction();
        },
      }),
    );
  };

  const shouldScheduled = () => {
    if (
      (scheduledStatus === 'Scheduled') &
      !startDateChanged &
      !endDateChanged
    ) {
      return false;
    }
    return true;
  };

  const extraButtons = () => {
    if (shouldScheduled()) {
      return (
        <SaveTempButton
          text="Save Only"
          action={() => saveButtonAction(false, false)}
        />
      );
    }
    return (
      <SaveTempButton
        text="Save"
        action={() => saveButtonAction(true, false)}
      />
    );
  };
  return (
    <div className="general_alert_container">
      <ContentSections
        languageTabContent={{
          containers: languages?.map((item) => ({
            container: [renderTabForLanguage(item.code)],
            key: item.code,
            title: item.sourceName,
          })),
        }}
        hidePreview={true}
      />
      <BackAndMultipleButtons
        backAction={() => {
          dispatch({
            type: 'generalAlert/stepChange',
            payload: { isBack: true, step: 2, isValid: true },
          });
        }}
        multipleButtons={[
          <ListButtonsGroup
            hideExtraButtonWidth={600}
            hideAllButtonWidth={400}
            hideExtraPopPosition="top"
            hideAllPopPosition="topRight"
            extraButtons={extraButtons()}
            primaryButton={
              shouldScheduled() ? (
                <SaveButton
                  text="Schedule"
                  action={() => saveButtonAction(true, true)}
                />
              ) : null
            }
          />,
        ]}
      />
      <ScheduledSuccessPrompt
        show={showScheduledSuccessPrompt}
        onClose={() => setShowScheduledSuccessPrompt(false)}
      />
      <ScheduledFailedPrompt
        data={scheduledData}
        show={showScheduledFailedPrompt}
        onClose={() => setShowScheduledFailedPrompt(false)}
        saveButtonAction={(item, afterAction) =>
          saveButtonAction(false, false, afterAction)
        }
      />
    </div>
  );
}

const mapPropsToState = (state) => ({ languages: state.language.allList });

export default connect(mapPropsToState)(CreateGeneralAlertStepThree);
