import React, { useEffect, useState, useRef } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import { ArrayStringData } from '../../../utils';
import { TRANSACTION_EVENT_TYPE } from './TransactionList';

function Filter({ backAction = () => {} }) {
  const location = useLocation();

  const parsedSearch = queryString.parse(location.search);
  const searchTransactionType = parsedSearch['transaction_type'] || '';
  const searchOnlineEventType = parsedSearch['online_event_type'] || '';
  const searchOfflineEventType = parsedSearch['offline_event_type'] || '';
  const searchCreateDate = parsedSearch['transaction_date'] || '';

  const [transactionType, setTransactionType] = useState(
    ArrayStringData(searchTransactionType),
  );
  const [onlineEventType, setOnlineEventType] = useState(
    ArrayStringData(searchOnlineEventType),
  );
  const [offlineEventType, setOfflineEventType] = useState(
    ArrayStringData(searchOfflineEventType),
  );
  const [transactionDate, setTransactionDate] = useState(searchCreateDate);

  const content = [
    {
      title: 'Transaction type',
      data: [
        { name: 'Offline(POS)', pk: 'OFFLINE_POS' },
        { name: 'Offline(Reward claim)', pk: 'OFFLINE_REWARD_CLAIM' },
        { name: 'Online', pk: 'ONLINE' },
      ],
      value: transactionType,
      setValue: setTransactionType,
      componment: FilterTypeEnum.choice,
    },
    {
      title: 'Online event type',
      data: [
        { name: TRANSACTION_EVENT_TYPE.TYPE_PENDING, pk: 'PENDING' },
        { name: TRANSACTION_EVENT_TYPE.TYPE_AUTHORIZED, pk: 'AUTHORIZED' },
        {
          name: TRANSACTION_EVENT_TYPE.TYPE_PARTIALLY_PAID,
          pk: 'PARTIALLY_PAID',
        },
        { name: TRANSACTION_EVENT_TYPE.TYPE_PAID, pk: 'PAID' },
        {
          name: TRANSACTION_EVENT_TYPE.TYPE_PARTIALLY_REFUNDED,
          pk: 'PARTIALLY_REFUNDED',
        },
        { name: TRANSACTION_EVENT_TYPE.TYPE_REFUNDED, pk: 'REFUNDED' },
        { name: TRANSACTION_EVENT_TYPE.TYPE_VOIDED, pk: 'VOIDED' },
      ],
      value: onlineEventType,
      setValue: setOnlineEventType,
      componment: FilterTypeEnum.choice,
    },
    {
      title: 'Offline event type',
      data: [
        { name: TRANSACTION_EVENT_TYPE.TYPE_PENDING, pk: 'PENDING' },
        { name: TRANSACTION_EVENT_TYPE.TYPE_AUTHORIZED, pk: 'AUTHORIZED' },
        {
          name: TRANSACTION_EVENT_TYPE.TYPE_PARTIALLY_PAID,
          pk: 'PARTIALLY_PAID',
        },
        { name: TRANSACTION_EVENT_TYPE.TYPE_PAID, pk: 'PAID' },
        {
          name: TRANSACTION_EVENT_TYPE.TYPE_PARTIALLY_REFUNDED,
          pk: 'PARTIALLY_REFUNDED',
        },
        { name: TRANSACTION_EVENT_TYPE.TYPE_REFUNDED, pk: 'REFUNDED' },
        { name: TRANSACTION_EVENT_TYPE.TYPE_VOIDED, pk: 'VOIDED' },
        {
          name: TRANSACTION_EVENT_TYPE.TYPE_WAITING,
          pk: 'WAITING_FOR_APPROVAL',
        },
        { name: TRANSACTION_EVENT_TYPE.TYPE_APPROVED, pk: 'APPROVED' },
        { name: TRANSACTION_EVENT_TYPE.TYPE_RECALLED, pk: 'RECALLED' },
        { name: TRANSACTION_EVENT_TYPE.TYPE_REJECTED, pk: 'REJECTED' },
      ],
      value: offlineEventType,
      setValue: setOfflineEventType,
      componment: FilterTypeEnum.choice,
    },
    {
      title: 'Transaction date',
      value: transactionDate,
      setValue: setTransactionDate,
      componment: FilterTypeEnum.date,
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

export default Filter;
