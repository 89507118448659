import React, {useEffect} from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, connect } from 'react-redux';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { createAction } from '../../../utils';

import CustomTitleWithInput from '../../../components/base/CustomTitleWithInput';
import {
  hasError,
  ReactHookFormErrorMessage,
} from '../../../components/base/ErrorFieldMessage';
import ContentSections from '../../../components/base/ContentSections';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import { LanguageConfig } from '../../../config/CustomEnums';

function CreateOfferTypeLabelContent({
  languages,
}) {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    setValue,
    watch,
    register,
  } = useFormContext();

  const validate = async () => {
    clearErrors([`translations.en.name`]);
    const [name] = getValues(['translations.en.name']);

    if (!name) {
      setError(
        'translations.en.name',
        {
          type: 'require',
          message: 'please input name',
        },
        { shouldFocus: true },
      );
    }

    return name;
  };

  const renderOfferTypeLabelContent = (language) => {
    const watchName = watch(`translations.${language}.name`);

    return [
      <>
        <label className="create-section-title">General</label>

        {/* <CustomTitleWithInput
          title="Name"
          defaultValue={watchName}
          setValue={(value) => {
            setValue(`translations.${language}.name`, value, {
              shouldDirty: true,
            });
          }}
          error={
            hasError(errors, `translations.${language}.name`)
              ? {
                  id: 'name',
                  error: hasError(errors, `translations.${language}.name`),
                  message: 'please input a name',
                }
              : {}
          }
        /> */}
        <div className="second-section">
          <div className="second-title-section">
            <div className="second-section-title">Name</div>
          </div>
          <div>
            <input
              type="text"
              className={`text-input-field ${
                hasError(errors, `translations.${language}.name`)
                  ? 'error-field'
                  : ''
              }`}
              {...register(
                `translations.${language}.name`,
                language === LanguageConfig.english
                  ? { required: 'please input name' }
                  : {},
              )}
            />
          </div>
          <ReactHookFormErrorMessage
            errors={errors}
            id={`translations.${language}.name`}
          />
        </div>
      </>
    ];
  };

  useEffect(() => {
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
  }, []);

  return (
    <>
      <ContentSections
        hidePreview
        languageTabContent={{
          containers: languages?.map((item) => ({
            container: [renderOfferTypeLabelContent(item.code)],
            key: item.code,
            title: item.sourceName,
          })),
        }}
      />
      <SaveAndBackButtons
        saveText={params.id ? 'Update' : 'Save'}
        backAction={() => history.goBack()}
        saveAction={async () => {
          const isValid = await validate();
          if (isValid) {
            dispatch(
              createAction('offerTypeLabel/createOrUpdate')(getValues()),
            );
          }
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  languages: state.language.allList,
});

export default connect(mapPropsToState)(CreateOfferTypeLabelContent);