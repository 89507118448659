import { defaultStep, getNewStepConfig } from './StepBarUtil';
import {
  createAction,
  saveToSessionStorage,
  getObjectFromSessionStorage,
  removeFromSessionStorage,
} from '../utils';
import {
  createPointAddTypeRecord,
  createPointRemoveTypeRecord,
} from '../services/TransactionRecordsAPIHelper';
import { loading } from './LoadingUtil';
import { POINT_RECORD_TYPE } from '../components/pointRecord/CreatePointRecordStepOne';
import {
  SESSION_KEYS,
} from '../config/CustomEnums';

export const sessionDataKey = {
  objectKey: SESSION_KEYS.CREATE_POINT_SESSION_KEY,
  stepEndKey: SESSION_KEYS.CREATE_POINT_END_SESSION_KEY,
  origionalData: SESSION_KEYS.CREATE_POINT_ORIGIN_DATA_SESSION_KEY,
};

export const CreatePointRecordError = {
  selectCustomer: {
    name: 'selectCustomer',
    message: 'Please provide a customer.',
  },
  operatedPoints: {
    name: 'points',
    message: 'Please provide a value.',
  },
};

function pointRecordInit() {
  return {
    pointType: null,
    customer: {},
    points: null,
    remarks: null,
  };
}

function getInitState() {
  const stepNameList = ['Type', 'Properties'];
  return {
    pointRecord: pointRecordInit(),
    errorFields: [],
    stepConfig: defaultStep(stepNameList),
    currentStep: 0,
    selectedType: null,
    pointRecordCreated: false,
  };
}

function checkStepTwo(data, isBack) {
  const selectedCustomer = data?.customer;
  const points = data?.points;
  let errorFields = [];
  if (!selectedCustomer || !selectedCustomer.pk) {
    errorFields.push(CreatePointRecordError.selectCustomer.name);
  }
  if (!points) {
    errorFields.push(CreatePointRecordError.operatedPoints.name);
  }
  return {
    invalid: isBack ? false : errorFields.length > 0,
    errorFields: isBack ? [] : errorFields,
    data,
  };
}

function parsePointRecordInputBody(pointRecord) {
  return {
    customer: pointRecord.customer.pk,
    points: pointRecord.points,
    remarks: pointRecord.remarks,
  };
}

export default {
  namespace: 'createPointRecord',
  state: getInitState(),

  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    updatePointRecordState(state, { payload }) {
      const pointRecord = { ...state.pointRecord, ...payload };
      saveToSessionStorage(sessionDataKey.objectKey, pointRecord);
      return {
        ...state,
        pointRecord: pointRecord,
      };
    },
    loadRecordFromCookie(state, { payload }) {
      const record = getObjectFromSessionStorage(sessionDataKey.objectKey);
      if (!record) {
        return {
          ...state,
        };
      }
      saveToSessionStorage(sessionDataKey.origionalData, record);
      saveToSessionStorage(sessionDataKey.objectKey, record);
      return {
        ...state,
        pointRecord: record,
      };
    },
    saveOrRemoveRecordFromCookie(state, { payload }) {
      if (!payload) {
        removeFromSessionStorage(sessionDataKey.objectKey);
      }
      saveToSessionStorage(sessionDataKey.stepEndKey, true);
      return {
        ...state,
      };
    },
    stepChange(state, { payload }) {
      const isBack = payload.isBack;
      let step = payload.step;
      let result = { invalid: false, errorFields: [], data: {} };
      const stepConfig = getNewStepConfig(
        step,
        state.stepConfig,
        false,
        isBack,
      );
      if (!result.invalid) {
        step = isBack ? step - 1 : step + 1;
      }
      return {
        ...state,
        stepConfig,
        currentStep: step,
        errorFields: result.errorFields,
        pointRecord: {
          ...state.pointRecord,
          ...result.data,
        },
      };
    },
    clearData(state, { payload }) {
      return { ...state, ...getInitState() };
    },
  },

  effects: {
    *setFieldToSession({ payload }, { select }) {
      const oldTransaction = yield select(
        (state) => state.createTransaction.transaction,
      );
      const transaction = { ...oldTransaction, ...payload };

      saveToSessionStorage(sessionDataKey.objectKey, transaction);
    },
    checkCreatePointRecordStepTwo: [
      function* ({ payload }, { put, select }) {
        const { isBack } = payload;
        const pointRecord = yield select(
          (state) => state.createPointRecord.pointRecord,
        );
        const stepConfig = yield select(
          (state) => state.createPointRecord.stepConfig,
        );
        const result = checkStepTwo(pointRecord, isBack);
        const newStepConfig = getNewStepConfig(
          1,
          stepConfig,
          result.invalid,
          isBack,
        );
        if (!result.invalid) {
          const afterActions = payload.afterActions || (() => {});
          yield afterActions();
        }
        yield put(
          createAction('updateState')({
            stepConfig: newStepConfig,
            errorFields: result.errorFields,
          }),
        );
      },
      { type: 'takeLatest' },
    ],
    createPointRecord: [
      function* ({ payload }, { select, put }) {
        const pointRecord = yield select(
          (state) => state.createPointRecord.pointRecord,
        );
        const inputBody = parsePointRecordInputBody(pointRecord);
        let serviceArgs = [createPointAddTypeRecord, inputBody];
        if (pointRecord.pointType === POINT_RECORD_TYPE.TYPE_REMOVE) {
          serviceArgs = [createPointRemoveTypeRecord, inputBody];
        }
        saveToSessionStorage(sessionDataKey.stepEndKey, true);
        function* onSuccess() {
          removeFromSessionStorage(sessionDataKey.objectKey);
          yield put({
            type: 'updateState',
            payload: { pointRecordCreated: true },
          });
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
};
