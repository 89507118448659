import {
  getStores,
  getStoresOfBrand,
  createOrUpdateStore,
  deleteStores,
  getOneStore,
  getPagedStores,
  getDistricts,
  getDownloadStoreCodesZip,
} from '../services/StoreAPIHelper';
import { googleAPIHelper } from '../services/GoogleAPIHelper';
import {
  createAction,
  convertNumberToCursor,
  convertPKToId,
  convertCursorToNumber,
  getObjectFromSessionStorage,
  saveToSessionStorage,
  removeUndefinedFieldFromDict,
  removeFromSessionStorage,
  getFileNameFromUrl,
  delay,
} from '../utils';
import { apiWithResponseHandle, loading } from './LoadingUtil';
import { defaultStep, getNewStepConfig } from './StepBarUtil';
import {
  SavedStatus,
  CheckStatus,
  LanguageConfig,
  PublishTagType,
  APIStatus,
  SESSION_KEYS,
  STORE_LOADING_TYPE,
  StatusTag,
} from '../config/CustomEnums';
import { downloadZipFiles } from '../containers/merchants/stores/QRCodeDownloadUtil';
import { createModel } from './BaseModel';
import { getAttributeTagList } from '../services/AttributeTagAPIHelper';

const stepNameList = ['Content', 'Properties'];
const storeSessionKey = SESSION_KEYS.STORE_SESSION_KEY;
export const weekdays = [
  { name: 'Monday', value: 'MONDAY', key: 1, pk: 1, shortName: 'Mon' },
  { name: 'Tuesday', value: 'TUESDAY', key: 2, pk: 2, shortName: 'Tue' },
  { name: 'Wednesday', value: 'WEDNESDAY', key: 3, pk: 3, shortName: 'Wed' },
  { name: 'Thursday', value: 'THURSDAY', key: 4, pk: 4, shortName: 'Thu' },
  { name: 'Friday', value: 'FRIDAY', key: 5, pk: 5, shortName: 'Fri' },
  { name: 'Saturday', value: 'SATURDAY', key: 6, pk: 6, shortName: 'Sat' },
  { name: 'Sunday', value: 'SUNDAY', key: 7, pk: 7, shortName: 'Sun' },
];

export const saveDataToSessionStorage = (data) => {
  console.log('@@49: ', storeSessionKey);
  saveToSessionStorage(storeSessionKey, data);
};

export const removeDataFromSessionStorage = () => {
  removeFromSessionStorage(storeSessionKey);
};

function* brandApplicableStore(payload, select, put) {
  function* loadResult(data = {}, prePayload = {}) {
    const endCursor = data.endCursor || 0;
    const differCursor = endCursor - data.startCursor || 0;
    const pagedStoreList = data.stores;
    const newList = [];
    let tempKey = '';
    let finalKey = '';
    let oldTempList = [];
    console.log('prePayload:', prePayload);
    const notBrandRelate = payload.notBrandRelate;
    if (notBrandRelate) {
      oldTempList = yield select(
        (state) => state.storeModel.tempAppliableStoreList,
      );
      tempKey = 'tempAppliableStoreList';
      finalKey = 'appliableStoreList';
    } else {
      oldTempList = yield select(
        (state) => state.storeModel.tempBrandStoreList,
      );
      tempKey = 'tempBrandStoreList';
      finalKey = 'brandStoreList';
    }
    console.log('oldTempList: ', oldTempList);
    const combineList = [...(oldTempList || []), ...pagedStoreList];
    combineList.forEach((item) => {
      const exist = newList.some((newItem) => newItem.pk === item.pk);
      if (!exist) {
        newList.push(item);
      }
    });

    yield put({
      type: 'updateState',
      payload: {
        [tempKey]: newList,
      },
    });
    console.log('endCursor:', endCursor);
    if (differCursor >= 99) {
      const currentPage = (endCursor + 1) / 100 + 1;
      yield put({
        type: notBrandRelate ? 'getAppliableStores' : 'getBrandStores',
        payload: { ...prePayload, page: currentPage },
      });
      return;
    }
    yield put({
      type: 'updateState',
      payload: {
        [finalKey]: newList,
        [tempKey]: [],
      },
    });
    if (!notBrandRelate) {
      yield put({
        type: 'updateState',
        payload: {
          allListLoading: false,
        },
      });
    }
  }

  const page = payload.page || 1;
  const pageCursor = payload.page
    ? convertNumberToCursor((page - 1) * 100 - 1)
    : '';

  function* onSuccess(data) {
    const pageInfo = data?.stores?.pageInfo;
    const storeList = data?.stores?.edges.map((item) =>
      parseStore(item.node, payload?.minimalist),
    );
    const startCursor = convertCursorToNumber(pageInfo.startCursor);
    const endCursor = convertCursorToNumber(pageInfo.endCursor);
    yield loadResult(
      {
        stores: storeList,
        startCursor: startCursor,
        endCursor: endCursor,
      },
      payload,
    );
  }

  function* onError(data) {
    console.log('onErrordata:', data);
    yield loadResult({
      stores: [],
      startCursor: 0,
      endCursor: 0,
    });
  }
  const serviceArgs = [
    getPagedStores,
    pageCursor,
    { ...payload, isSelectorLoad: true },
  ];
  yield loading(serviceArgs, onSuccess, onError, onError);
}

const getTranslations = (data) => {
  const translationList = data.translations?.edges || [];
  const translation = {};
  translationList.forEach((item) => {
    const language = item.node.language;
    translation[language] = {
      ...item.node,
      name: item.node.name,
    };
  });
  translation[LanguageConfig.english] = {
    name: data.name,
    address: data.address,
    description: data.description,
    pk: data.pk,
  };
  return translation;
};

const getStoreinweekbusinesshourSet = (datas) => {
  if (!datas) {
    return null;
  }
  return datas.map(({ node }) => {
    const days = node.weekDays?.edges?.map((week) => {
      return weekdays.find((day) => day.key === week.node.pk);
    });
    return {
      pk: node.pk,
      id: node.id,
      isClose: node.isClose,
      openHour: node.openHour,
      closeHour: node.closeHour,
      days,
    };
  });
};

const workHour = (workHour = {}) => {
  const newData = {
    isClose: workHour?.isClose,
  };
  if (!workHour?.isClose) {
    newData.openHour = workHour?.openHour;
    newData.closeHour = workHour?.closeHour;
  }
  return newData;
};

const weekHoursPost = (weekHours = []) => {
  return weekHours.map((weekHour) => {
    const weekDays = weekHour.days.map((day) => day.key);
    const newData = workHour(weekHour);
    newData.weekDays = weekDays;
    return newData;
  });
};

const postAttributeTags = (data) => {
  if (data.isFollowBrandAttribute) {
    return null;
  }
  return data.attributeTags.map((attribute) => attribute.pk);
};

const postTranslations = (data) => {
  if (!data) {
    return [];
  }
  const languageList = Object.keys(data).filter(
    (language) => language !== LanguageConfig.english,
  );
  const translations = languageList.map((language) => {
    const newData = {
      language,
      name: data[language].name,
      address: data[language].address,
      description: data[language].description,
    };
    const id = data[language].pk;
    if (id) {
      newData.id = id;
    }
    return newData;
  });
  return translations;
};

const mergeOldData = (origin, old) => {
  const translationLang = Object.keys(origin?.storeTranslations || {}) || [];
  const storeTranslations = {};
  translationLang.forEach((translation) => {
    storeTranslations[translation] = {
      ...old?.storeTranslations?.[translation],
      ...origin?.storeTranslations?.[translation],
    };
    if (!origin.storePK) {
      delete storeTranslations[translation].pk;
    }
  });
  return {
    ...origin,
    storeTranslations: storeTranslations,
  };
};

const postData = (origin, old) => {
  const name =
    origin.storeTranslations?.[LanguageConfig.english]?.name || origin.name;
  if (!name) {
    return {
      ...origin,
    };
  }
  const data = mergeOldData(origin, old);
  const newData = {
    name: name,
    description: data.storeTranslations[LanguageConfig.english].description,
    phoneNumberCountryCode: data.phoneNumberCountryCode?.value || '',
    phoneNumberSubscriberNumber: data.phoneNumberSubscriberNumber,
    emailAddress: data.emailAddress,
    district: data.district?.value?.pk,
    address: data.storeTranslations[LanguageConfig.english].address,

    brand: data.brand?.pk,
    inWeekBusinessHour: weekHoursPost(data.weekHours),
    holidayBusinessHour: workHour(data.holidayHours),
    translations: postTranslations(data.storeTranslations),
    displayPriority: data.displayPriority,
    isFollowBrandAttribute: data.isFollowBrandAttribute,
    isPublished: data.isPublished,
    attributeTags: postAttributeTags(data),
  };
  if (data.latitude?.length && data.longitude?.length) {
    newData.latitude = data.latitude;
    newData.longitude = data.longitude;
  }
  if (data.pk) {
    newData.id = data.pk;
    if (old.brand) {
      delete newData.brand;
    }
  }
  if (data.isFollowBrandAttribute) {
    delete newData.attributeTags;
  }
  return newData;
};

const parseStore = (store, minimalist) => {
  if (!store) {
    return {};
  }
  if (minimalist) {
    return {
      pk: store.pk,
      storePK: store.pk,
      storeName: store.name,
      name: store.name,
    };
  }
  return {
    ...store,
    storeID: store.id,
    storePK: store.pk,
    storeName: store.name,
    displayPriority: store.displayPriority,
    order: store.displayPriority,
    address: store.address,
    phoneNumberCountryCode: {
      label: store.phoneNumberCountryCode,
      value: store.phoneNumberCountryCode,
    },
    phoneNumberSubscriberNumber: store.phoneNumberSubscriberNumber,
    latitude: store.latitude,
    longitude: store.longitude,
    emailAddress: store.emailAddress,
    description: store.description,
    codeDisplayImage: store.codeDisplayImage,
    codeDownloadImage: store.codeDownloadImage,
    codeDownloadImageA5: store.codeDownloadImageA5,
    isPublished: store.isPublished,
    isFollowBrandAttribute: store.isFollowBrandAttribute,
    weekHours: getStoreinweekbusinesshourSet(store.inWeekHours?.edges),
    holidayHours: store.holidayHour,
    district: { label: store.district?.name, value: store.district },
    brand: store.brand,
    storeTranslations: getTranslations(store),
    attributeTags: (store.isFollowBrandAttribute
      ? store.brand?.attributeTags?.edges
      : store.attributeTags?.edges
    )?.map((item) => item.node),
    displayDistrict: store.district?.name,
    displayBrand: store.brand?.name,
    displayName: store.name,
    displayPhoneNumner: store.phoneNumberCountryCode
      ? `+${store.phoneNumberCountryCode} ${store.phoneNumberSubscriberNumber}`
      : '-',
    displayAttributeTag: (store.isFollowBrandAttribute
      ? store.brand?.attributeTags?.edges
      : store.attributeTags?.edges
    )
      ?.map((item) => item.node.name)
      .join('\n'),
    status: store.isPublished ? StatusTag.active : StatusTag.inactive,
  };
};

const getInitState = () => {
  return {
    storeList: [],
    tempBrandStoreList: [],
    brandStoreList: [],
    tempAppliableStoreList: [],
    appliableStoreList: [],
    allListLoading: false,
    selectedAllListLoading: false,
    storeEditMode: false,
    listDisplayFields: [
      { displayName: 'ID', fieldName: 'pk' },
      { displayName: 'Name', fieldName: 'name', orderField: 'name' },
      {
        displayName: 'Display order',
        fieldName: 'displayPriority',
        orderField: 'displayPriority',
      },
      { displayName: 'Brands', fieldName: 'displayBrand', orderField: 'brand' },
      { displayName: 'Phone number', fieldName: 'displayPhoneNumner' },
      { displayName: 'Email', fieldName: 'emailAddress' },
      {
        displayName: 'Attribute tag',
        fieldName: 'displayAttributeTag',
      },
      { displayName: 'Status', fieldName: 'status' },
    ],
    codeListDisplayFields: [
      { displayName: 'ID', fieldName: 'pk' },
      { displayName: 'Cover Photo', fieldName: 'codeDisplayImage' },
      { displayName: 'Related Store', fieldName: 'displayName' },
    ],
    pagedStoreList: [],
    pageInfo: {
      startCursor: '',
      endCursor: '',
      hasNextPage: false,
      hasPreviousPage: false,
    },
    totalPage: 0,
    totalCount: 0,
    currentPage: 0,
    checkedList: [],
    oneStore: {},
    checked: CheckStatus.initOrNotChecked,
    errorFields: {},
    saved: SavedStatus.init,
    formChanged: false,
    districtList: [],
    stepConfig: defaultStep(stepNameList),
    currentStep: 0,
    formHasSubmitted: false,
    createCouponTemplateStatus: APIStatus.none,
  };
};

export default createModel({
  namespace: 'storeModel',
  states: getInitState(),
  params: {
    dataKey: storeSessionKey,
    listAPI: getPagedStores,
    parse: (data, minimalist) =>
      data?.stores?.edges.map((item) => parseStore(item.node, minimalist)),
    deleteAPI: deleteStores,
    pkNode: 'StoreNode',
    detailAPI: getOneStore,
    parseDetail: (data) => parseStore(data.store),
    objectKey: 'stores',
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    loadDataFromCookie(state, { payload }) {
      const sessionStore = getObjectFromSessionStorage(storeSessionKey) || {};
      const data = { ...removeUndefinedFieldFromDict(sessionStore) };
      if (sessionStore?.isFollowBrandAttribute === undefined) {
        data.isFollowBrandAttribute = true;
      }
      return {
        ...state,
        oneStore: data,
        hasUpdatedDefaultValues: true,
      };
    },

    removeFromCookie(state, { payload }) {
      removeFromSessionStorage(storeSessionKey);
      return { ...state, formChanged: false };
    },

    stepChange(state, { payload }) {
      let { isBack, currentStep, isValid } = payload;
      let newStep = currentStep;
      const stepConfig = getNewStepConfig(
        currentStep,
        state.stepConfig,
        !isValid,
        isBack,
      );
      if (isValid) {
        if (isBack) {
          newStep = currentStep - 1;
        } else {
          newStep = currentStep + 1;
        }
      }
      return {
        ...state,
        currentStep: newStep,
        stepConfig,
      };
    },

    clearData(state, { payload }) {
      return { ...state, ...getInitState() };
    },
  },
  effects: {
    *getAllStores({ payload }, { call, put, select }) {
      const response = yield call(getStores);
      const storeInfo = response.data.data.stores;
      if (storeInfo) {
        const storeList = storeInfo.edges;
        yield put(
          createAction('updateState')({
            storeList: storeList.map((item) => parseStore(item.node)),
          }),
        );
      }
    },
    *getAllStoresOfBrand({ payload }, { call, put, select }) {
      const { brandID, afterCursor } = payload;
      const response = yield call(getStoresOfBrand, brandID, afterCursor);
      if (!response || response.status >= 300 || response.data.errors) {
        return;
      }
      const storeInfo = response.data?.data?.brand?.stores;
      if (storeInfo) {
        const storeList = storeInfo.edges;
        yield put(
          createAction('updateState')({
            storeList: storeList.map((item) => parseStore(item.node)),
          }),
        );
        const pageInfo = storeInfo.pageInfo;
        if (pageInfo.hasNextPage) {
          yield put(
            createAction('getAllStoresOfBrand')({
              brandID: brandID,
              afterCursor: pageInfo.endCursor,
            }),
          );
        }
      }
    },
    getPagedStoreList: [
      function* ({ payload }, { put, select }) {
        const page = payload.page || 1;
        const pageCursor = payload.page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const serviceArgs = [getPagedStores, pageCursor, payload];
        function* onSuccess(data) {
          const pageInfo = data?.stores?.pageInfo;
          const currentLastCursor = pageInfo?.endCursor;
          const totalCount = data?.stores?.totalCount;
          const storeList = data?.stores?.edges.map((item) =>
            parseStore(item.node, payload?.minimalist),
          );
          const startCursor = convertCursorToNumber(pageInfo.startCursor);
          const endCursor = convertCursorToNumber(pageInfo.endCursor);

          if (payload.isSelectorLoad) {
            const stateStoreList = yield select(
              (state) => state.storeModel.storeList,
            );
            yield put({
              type: 'updateState',
              payload: {
                storeList:
                  page > 1 ? [...stateStoreList, ...storeList] : storeList,
              },
            });
          } else {
            yield put({
              type: 'updateState',
              payload: {
                pagedStoreList: storeList,
                pageInfo: {
                  startCursor: startCursor + 1,
                  endCursor: endCursor + 1,
                },
                currentLastCursor,
                totalCount,
                totalPage: Math.ceil(totalCount / 20),
              },
            });
          }
          const afterAction = payload?.afterAction || (() => {});
          yield afterAction(storeList.length);
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    doStoreRequest: [
      function* ({ payload }, { put }) {
        yield put({ type: 'updateState', payload: { allListLoading: true } });
        const newPayload = {
          ...payload,
          pageSize: 100,
          minimalist: true,
          isSelectorLoad: true,
        };
        console.log('doStoreRequest:', payload);
        const notBrandRelate = payload.notBrandRelate;

        yield put({
          type: 'updateState',
          payload: notBrandRelate
            ? { appliableStoreList: [] }
            : { brandStoreList: [] },
        });
        yield put({
          type: notBrandRelate ? 'getAppliableStores' : 'getBrandStores',
          payload: newPayload,
        });
      },
      { type: 'takeLatest' },
    ],

    getBrandStores: [
      function* ({ payload }, { put, select }) {
        yield brandApplicableStore(payload, select, put);
      },
      { type: 'takeLatest' },
    ],

    getAppliableStores: [
      function* ({ payload }, { put, select }) {
        yield brandApplicableStore(payload, select, put);
      },
      { type: 'takeLatest' },
    ],

    delete: [
      function* ({ payload }, { select, put, all }) {
        const { checkedSegments } = yield select((state) => ({
          checkedSegments: state.storeModel.checkedList,
        }));

        let pks = [];
        checkedSegments.forEach((item) => {
          pks.push(item.pk);
        });

        const serviceArgs = [deleteStores, pks];
        const afterAction = payload.afterAction || (() => {});
        function* onSuccess(data) {
          console.log('@@153: ', data);
          yield all([
            put({
              type: 'storeModel/updateState',
              payload: { checkedList: [] },
            }),
          ]);

          afterAction();
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getOneStore: [
      function* ({ payload }, { call, select, put }) {
        const serviceArgs = [
          getOneStore,
          convertPKToId('StoreNode', payload.id),
        ];
        function* onSuccess(data) {
          const store = parseStore(data.store);
          yield put({
            type: 'updateState',
            payload: {
              oneStore: store,
              hasUpdatedDefaultValues: true,
            },
          });
          yield put({
            type: 'updateProperty',
            payload: {
              storeIn: [payload?.id],
              pageSize: 100,
              page: 1,
              stateName: 'oneStore',
              dataKey: 'attributeTags',
              updateKey: 'attributeTags',
              updateApi: getAttributeTagList,
            },
          });
          const action = payload?.afterAction || (() => {});
          yield action(store);
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    createOrUpdate: [
      function* ({ payload }, { call, select, put }) {
        const data = payload.data;
        if (!data) {
          return;
        }
        yield put({
          type: 'updateState',
          payload: { saved: SavedStatus.saving },
        });
        const oldData = yield select((state) => state.storeModel.oneStore);
        const source = postData(data, oldData);
        const afterAction = payload.afterAction || (() => {});
        const serviceArgs = [createOrUpdateStore, source];
        function* onSuccess(data) {
          if (
            ('createStore' in data && data.createStore.errors) ||
            ('updateStore' in data && data.updateStore.errors)
          ) {
            yield put({
              type: 'updateState',
              payload: {
                saved: SavedStatus.savedWithFail,
                formChanged: false,
                checked: CheckStatus.initOrNotChecked,
              },
            });
          } else {
            const updateData = {
              formChanged: false,
              saved: SavedStatus.savedWithSuccess,
              checked: CheckStatus.initOrNotChecked,
              formHasSubmitted: true,
            };
            if (data.createStore?.node?.pk) {
              updateData.oneStore = { storePK: data.createStore?.node?.pk };
            }

            yield put({
              type: 'updateState',
              payload: updateData,
            });
            removeFromSessionStorage(storeSessionKey);
          }
          afterAction();
        }

        function* onFail(data) {
          yield put({
            type: 'updateState',
            payload: {
              formChanged: false,
              saved: SavedStatus.savedWithFail,
            },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onFail, onFail);
      },
      { type: 'takeLatest' },
    ],
    duplicate: [
      function* ({ payload }, { call, select, put }) {
        const brandData = payload?.data;
        function* storeGet(store) {
          const newStore = {
            ...store,
            storeTranslations: {
              ...store.storeTranslations,
              en: {
                ...store.storeTranslations.en,
                name: `Copy of ${store.name}`,
              },
            },
            pk: null,
            storePK: null,
          };
          yield put(
            createAction('createOrUpdate')({
              data: newStore,
              afterAction: payload.afterAction,
            }),
          );
        }
        yield put({
          type: 'getOneStore',
          payload: { id: brandData?.pk, afterAction: storeGet },
        });
      },
      { type: 'takeLatest' },
    ],
    getDistrictList: [
      function* ({ payload }, { select, put }) {
        const page = payload.page || 1;
        const pageCursor = payload.page
          ? convertNumberToCursor((page - 1) * 50 - 1)
          : '';
        const serviceArgs = [getDistricts, pageCursor, payload];
        function* onSuccess(data) {
          const districtList = data?.districts?.edges.map((item) => item.node);
          if (payload.isSelectorLoad) {
            const { stateDistrictList } = yield select((state) => ({
              stateDistrictList: state.storeModel?.districtList,
            }));
            yield put({
              type: 'updateState',
              payload: {
                districtList:
                  page > 1
                    ? [...stateDistrictList, ...districtList]
                    : districtList,
              },
            });
          } else {
            yield put({
              type: 'updateState',
              payload: {
                districtList: districtList,
              },
            });
          }
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    downloadStoreCodes: [
      function* ({ payload }, { select }) {
        const { checkedSegments } = yield select((state) => ({
          checkedSegments: state.storeModel.checkedList,
        }));
        const pks = [];
        checkedSegments.forEach((item) => {
          pks.push(item.pk);
        });
        const serviceArgs = [getDownloadStoreCodesZip, { ids: pks }];

        function* onSuccess(data) {
          const link = data?.downloadStores?.zipLink;
          downloadZipFiles(link);
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getAddressMarker: [
      function* ({ payload }, { select, call }) {
        const { address, onSuccess } = payload;
        const response = yield call(googleAPIHelper.getAddressDetail, address);
        console.log('789: ', response);
        if (response.status === 200) {
          onSuccess(response.data);
        }
      },
      { type: 'takeLatest' },
    ],
  },
});
