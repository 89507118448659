import React, { useState, useEffect } from 'react';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from '../../utils';
import './PurchaseItemSection.scss';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import { CreateTransactionError } from '../../models/CreateTransactionModel';
import {
  isShowError,
  firstError,
} from '../campaign/campaignCreation/CreateCampaignHandleError';
import { LanguageConfig } from '../../config/CustomEnums';

function PurchaseItemSection(props) {
  const dispatch = useDispatch();
  const index = props.index;

  const { purchasedItems, errorFields } = useSelector((state) => ({
    purchasedItems: state.createTransaction.transaction.purchasedItems,
    errorFields: state.createTransaction.errorFields,
  }));

  useEffect(() => {
    firstError(
      `${CreateTransactionError.purchasedItmeName.name}-${index - 1}`,
      errorFields,
      LanguageConfig.english,
    );
    firstError(
      `${CreateTransactionError.purchasedItemQuantity.name}-${index - 1}`,
      errorFields,
      LanguageConfig.english,
    );
    firstError(
      `${CreateTransactionError.purchasedItemQuantityFormat.name}-${index - 1}`,
      errorFields,
      LanguageConfig.english,
    );
    firstError(
      `${CreateTransactionError.purchasedItemValue.name}-${index - 1}`,
      errorFields,
      LanguageConfig.english,
    );
    firstError(
      `${CreateTransactionError.purchasedItemValueFormat.name}-${index - 1}`,
      errorFields,
      LanguageConfig.english,
    );
  }, [errorFields, index]);

  const isError = (fieldName) => {
    return isShowError(fieldName, errorFields, LanguageConfig.english);
  };

  return (
    <>
      <div className="d-flex">
        <div className="first-section-title">{`PURCHASED ITEMS #${index}`}</div>
        {purchasedItems.length > 1 ? (
          <button
            className="base-delete-button base-back-button"
            onClick={() => {
              let itemList = purchasedItems;
              itemList.splice(index - 1, 1);
              dispatch(
                createAction('createTransaction/updateTransactionState')({
                  purchasedItems: itemList,
                }),
              );
            }}
          >
            Delete
          </button>
        ) : null}
      </div>

      <CustomTitleLabel title="Product name" />
      <input
        className={`purchase-item-input-fields custom-number-input-long ${
          isError(
            `${CreateTransactionError.purchasedItmeName.name}-${index - 1}`,
          )
            ? 'error-field'
            : ''
        }`}
        onChange={(e) => {
          dispatch(
            createAction('createTransaction/updatePruchasedItems')({
              index: index - 1,
              data: {
                productName: e.target.value,
              },
            }),
          );
        }}
        value={purchasedItems[index - 1].productName || ''}
      />
      <ErrorFieldMessage
        id={`${CreateTransactionError.purchasedItmeName.name}-${index - 1}`}
        error={isError(
          `${CreateTransactionError.purchasedItmeName.name}-${index - 1}`,
        )}
        message={CreateTransactionError.purchasedItmeName.message}
      />
      <CustomTitleLabel title="Sku (optional)" />
      <input
        className="purchase-item-input-fields custom-number-input-long"
        onChange={(e) => {
          dispatch(
            createAction('createTransaction/updatePruchasedItems')({
              index: index - 1,
              data: {
                sku: e.target.value,
              },
            }),
          );
        }}
        value={purchasedItems[index - 1].sku || ''}
      />
      <CustomTitleLabel title="Category (optional)" />
      <input
        className="purchase-item-input-fields custom-number-input-long"
        onChange={(e) => {
          dispatch(
            createAction('createTransaction/updatePruchasedItems')({
              index: index - 1,
              data: {
                category: e.target.value,
              },
            }),
          );
        }}
        value={purchasedItems[index - 1].category || ''}
      />
      <CustomTitleLabel title="Brand (optional)" />
      <input
        className="purchase-item-input-fields custom-number-input-long"
        onChange={(e) => {
          dispatch(
            createAction('createTransaction/updatePruchasedItems')({
              index: index - 1,
              data: {
                brand: e.target.value,
              },
            }),
          );
        }}
        value={purchasedItems[index - 1].brand || ''}
      />
      <CustomTitleLabel title="Quantity" />
      <div className="d-flex">
        <input
          className={`day-input-fields custom-number-input-short ${
            isError(
              `${CreateTransactionError.purchasedItemQuantity.name}-${
                index - 1
              }` ||
                isError(
                  `${CreateTransactionError.purchasedItemQuantityFormat.name}-${
                    index - 1
                  }`,
                ),
            )
              ? 'error-field'
              : ''
          }`}
          onChange={(e) => {
            dispatch(
              createAction('createTransaction/updatePruchasedItems')({
                index: index - 1,
                data: {
                  quantity: e.target.value,
                },
              }),
            );
          }}
          value={purchasedItems[index - 1].quantity || ''}
        />
        <label className="days-label">number of items</label>
      </div>
      <ErrorFieldMessage
        id={`${CreateTransactionError.purchasedItemQuantity.name}-${index - 1}`}
        error={isError(
          `${CreateTransactionError.purchasedItemQuantity.name}-${index - 1}`,
        )}
        message={CreateTransactionError.purchasedItemQuantity.message}
      />
      <ErrorFieldMessage
        id={`${CreateTransactionError.purchasedItemQuantityFormat.name}-${
          index - 1
        }`}
        error={isError(
          `${CreateTransactionError.purchasedItemQuantityFormat.name}-${
            index - 1
          }`,
        )}
        message={CreateTransactionError.purchasedItemQuantityFormat.message}
      />
      <CustomTitleLabel title="Value" />
      <div className="d-flex">
        <label className="transaction-amount-total-value-label">HK$</label>
        <input
          className={`day-input-fields custom-number-input-short ${
            isError(
              `${CreateTransactionError.purchasedItemValue.name}-${index - 1}`,
            ) ||
            isError(
              `${CreateTransactionError.purchasedItemValueFormat.name}-${
                index - 1
              }`,
            )
              ? 'error-field'
              : ''
          }`}
          onChange={(e) => {
            dispatch(
              createAction('createTransaction/updatePruchasedItems')({
                index: index - 1,
                data: {
                  value: e.target.value,
                },
              }),
            );
          }}
          value={purchasedItems[index - 1].value || ''}
        />
      </div>
      <ErrorFieldMessage
        id={`${CreateTransactionError.purchasedItemValue.name}-${index - 1}`}
        error={isError(
          `${CreateTransactionError.purchasedItemValue.name}-${index - 1}`,
        )}
        message={CreateTransactionError.purchasedItemValue.message}
      />
      <ErrorFieldMessage
        id={`${CreateTransactionError.purchasedItemValueFormat.name}-${
          index - 1
        }`}
        error={isError(
          `${CreateTransactionError.purchasedItemValueFormat.name}-${
            index - 1
          }`,
        )}
        message={CreateTransactionError.purchasedItemValueFormat.message}
      />

      {index === purchasedItems.length ? (
        <div className="content-section-table-container">
          <Button
            onClick={() => {
              dispatch(
                createAction('createTransaction/addPurchasedItem')({
                  //   item: index,
                }),
              );
            }}
            className="btn-add-button-common"
          >
            Add Purchased Item
          </Button>
        </div>
      ) : null}
    </>
  );
}

export default PurchaseItemSection;
