import { BaseHelper } from './BaseGraphQLAPIHelper';

export const getCustomerData = ({ startDate, endDate }) => {
  let filter = `fromDate: "${startDate}", toDate: "${endDate}"`;

  const query = `{
      analyticsData(${filter}) { 
        totalNumberOfCustomers {
            value
          }
          totalNumberOfNewCustomers {
            value
          }       
      }
    }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCouponData = ({ startDate, endDate, campaign }) => {
  let filter = `fromDate: "${startDate}", toDate: "${endDate}", campaign: ${campaign}`;
  console.log(filter);
  const query = `{
        analyticsData(${filter}) { 
            couponTemplateStatusTableBySql
        }
      }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};
