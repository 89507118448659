import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { connect } from 'react-redux';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import BaseForm from '../../../components/base/v2/BaseForm';
import { createTourCardBreads } from '../../../config/CustomBreadConfig';
import { createAction } from '../../../utils';
import { APIStatus } from '../../../config/CustomEnums';
import CreateTourCardStepOne from '../../../components/tourCard/CreateTourCardStepOne';
import {
  removeTourCardSessionStroage,
  saveTourCardToSessionStorage,
} from '../../../models/TourCardModel';
import Loading from '../../../components/base/Loading';
const CreateTourCard = ({
  defaultValues,
  dispatch,
  hasUpdatedDefaultValues,
  formHasSubmitted,
  apiStatus,
  createStatus,
}) => {
  const params = useParams();
  const history = useHistory();
  const { id } = params;
  useEffect(() => {
    if (id) {
      dispatch(createAction('tourCard/getOneDetail')({ id }));
    } else {
      dispatch(createAction('tourCard/loadTourCardFromCookie')());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
    return () => {
      dispatch(createAction('tourCard/clearData')());
    };
  }, [dispatch]);

  useEffect(() => {
    if (
      formHasSubmitted &&
      apiStatus === APIStatus.success &&
      !history.location.state?.from
    ) {
      history.push({ pathname: '/tour_cards' });
    }
  }, [formHasSubmitted, apiStatus]);

  console.log('@@45: ', defaultValues);

  if (id && !defaultValues?.pk) {
    return <Loading customClass={'common-full-height'} />;
  }

  return (
    <>
      <BaseForm
        showFinishPop={formHasSubmitted && createStatus === APIStatus.success}
        defaultValues={defaultValues}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={formHasSubmitted}
        tempSave={(save, getValues) => {
          if (save) {
            saveTourCardToSessionStorage(getValues());
          } else {
            removeTourCardSessionStroage();
          }
        }}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: 'Tour card is successfully created.',
          steps: null,
          buttons: [
            {
              text: 'Back to tour card list',
              action: () =>
                history.push({
                  pathname: '/tour_cards',
                }),
            },
          ],
        }}
        continueCreateConfig={{
          onConfirm: () => {
            history.push({
              ...history.location.state?.from,
              state: { newTourCard: defaultValues },
            });
          },
          onCancel: () => history.push({ pathname: '/tour_cards' }),
          onClose: () => history.push({ pathname: '/tour_cards' }),
        }}
        content={[<CreateTourCardStepOne />]}
        currentStep={0}
        breadcrumb={
          <CustomBreadcrumb
            breadcrumbs={createTourCardBreads(id, defaultValues?.title)}
          />
        }
        caution={{
          detail: 'TBC',
          title: id ? defaultValues?.title : 'Create Tour Card',
        }}
      />
    </>
  );
};
const mapPropsToState = (state) => ({
  defaultValues: state.tourCard.detail,
  hasUpdatedDefaultValues: state.tourCard.detail.hasUpdatedDefaultValues,
  formHasSubmitted: state.tourCard.formHasSubmitted,
  apiStatus: state.tourCard.apiStatus,
  createStatus: state.tourCard.createStatus,
});
export default connect(mapPropsToState)(CreateTourCard);
