import React, { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import { useHistory, useParams } from 'react-router-dom';
import { createAction } from '../../../utils';
import DeletePrompt from '../../../components/base/DeletePrompt';
import { DELETE_RELATED_SECTIONS } from '../../../config/CustomEnums';
import ContentSections from '../../../components/base/ContentSections';
import { Controller, useFormContext } from 'react-hook-form';
import { PermissionCodes } from '../../../config/PermissionCodes';
import BaseMutipleSelectorV2 from '../../../components/base/BaseMultipleSelectorV2';
import CustomTitleWithInput from '../../../components/base/CustomTitleWithInput';
import {
  hasError,
  ReactHookFormErrorMessage,
} from '../../../components/base/ErrorFieldMessage';

function CreateWorkingTeamContent({ adminList }) {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    getValues,
    trigger,
    formState: { errors },
    watch,
    setValue,
    control,
  } = useFormContext();

  const sections = [
    <div>
      <div className="first-section-title">General</div>

      <Controller
        control={control}
        name="name"
        rules={{
          validate: (value) => {
            if (!value) {
              return 'please input name';
            }
          },
        }}
        render={({ field: { value } }) => (
          <>
            <CustomTitleWithInput
              title={'Name'}
              defaultValue={value}
              setValue={(value) => {
                setValue(`name`, value, {
                  shouldDirty: true,
                });
              }}
              error={{
                error: hasError(errors, `name`),
              }}
            />
            <ReactHookFormErrorMessage
              errors={errors}
              id="name"
              extra="name-errors"
            />
          </>
        )}
      />

      <Controller
        control={control}
        name={`administrators`}
        render={({ field: { value } }) => (
          <BaseMutipleSelectorV2
            title={'Related admin (Optional)'}
            size={['shorter']}
            data={{
              sourceData: adminList,
              targetData: value,
              targetChange: (value) => {
                console.log('targetChange:', value);
                // updateVlaue(value);
                setValue(`administrators`, value, {
                  shouldDirty: true,
                });
              },
            }}
            custom={{
              customItem: (value) => `[ID:${value?.pk}] ${value?.username}`,
              customFilter: (inputValue, option) => {
                const inputIgnoreCase = inputValue?.toLowerCase();
                const optionIgnoreCase = option?.username?.toLowerCase();
                return optionIgnoreCase?.includes(inputIgnoreCase);
              },
            }}
          />
        )}
      />
    </div>,
  ];

  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <SaveAndBackButtons
        saveText={params.id ? 'Update' : 'Save'}
        backAction={history.goBack}
        saveAction={async () => {
          let isValid = await trigger(['name'], { shouldFocus: true });
          console.log('@83', isValid, getValues());
          if (isValid) {
            dispatch(
              createAction('workingTeam/createOrUpdate')({
                values: getValues(),
                afterActions: () => history.push('/working_teams'),
              }),
            );
          }
        }}
        tempRequires={PermissionCodes.changeWorkingTeam}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  adminList: state.admin.notPagedAllList,
});

export default connect(mapPropsToState)(CreateWorkingTeamContent);
