import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import i18n from '../../../I18n.js';
import CustomMarkDownArea from '../../base/CustomMarkDownArea';
import { useCompare } from '../../../utils';

function DetailSection(props) {
  const language = props.language;

  const focusChange = props.focusChange || (() => {});
  const onSubmit = props.onSubmit;
  const onSubmitAction = props.onSubmitAction || (() => {});

  const defaultValue = props.defaultValue || {};
  const valueChangedAction = props.valueChangedAction || '';
  let detailTitle, detailContent;
  if (defaultValue) {
    detailTitle = defaultValue['detailSectionTitle'] || '';
    detailContent = defaultValue['detailSectionContent'] || '';
  }

  const [detailSectionTitle, setDetailSectionTitle] = useState(detailTitle);
  const [detailSectionContent, setDetailSectionContent] =
    useState(detailContent);

  const dispatch = useDispatch();
  const vals = { detailSectionTitle, detailSectionContent };
  const hasValChanged = useCompare(vals);

  useEffect(() => {
    if (hasValChanged) {
      dispatch({
        type: valueChangedAction,
        payload: { vals, language },
      });
    }
  });

  useEffect(() => {
    if (onSubmit) {
      onSubmitAction({
        detailSectionTitle,
        detailSectionContent,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmit]);

  return (
    <CustomMarkDownArea
      areaTitle={i18n.t('detail', { locale: language })}
      title={{
        label: i18n.t('detail_section_title', { locale: language }),
        value: detailSectionTitle,
        valueChange: (value) => {
          setDetailSectionTitle(value);
        },
        // tips: { ...props.titleTips },
        focus: () => focusChange('detailsTitle'),
      }}
      content={{
        label: i18n.t('detail_section_content', { locale: language }),

        value: detailSectionContent,
        valueChange: (value) => {
          setDetailSectionContent(value);
        },
        // tips: { ...props.contentTips },
        focus: () => focusChange('detailsContent'),
      }}
    />
  );
}

export default DetailSection;
