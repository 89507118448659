import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, CollapseNav } from 'react-bootstrap';
import { createAction, removeFromSessionStorage } from '../../../utils';
import ContentSections from '../../../components/base/ContentSections';
import ProfileSection from '../../../components/campaign/campaignCreation/ProfileSection';
import ContentSection from '../../../components/campaign/campaignCreation/ContentSection';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import { useHistory, useParams } from 'react-router-dom';
import {
  APIStatus,
  LanguageConfig,
  CampaignType,
  SESSION_KEYS,
} from '../../../config/CustomEnums';
import { PermissionCodes } from '../../../config/PermissionCodes';
import Loading from '../../../components/base/Loading';
import CustomListComponent from '../../../components/base/CustomListComponent';
import AuthButton from '../../../components/base/AuthButton';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CampaignPreviewButton from './CampaignPreviewButton';
import FortuneBagDetail from '../../../components/campaign/campaignCreation/FortuneBagDetail';
import copy from 'copy-to-clipboard';
import { ToastType } from '../../../models/NavBarModel';

export default function CampaignDetail() {
  const params = useParams();
  const { id } = params;
  const dispatch = useDispatch();
  const history = useHistory();
  const { createStatus, campaign, couponAcquired } = useSelector((state) => ({
    createStatus: state.createCampaign.createStatus,
    campaign: state.createCampaign.campaign || {},
    couponAcquired: state.createCampaign.couponAcquired,
  }));
  const selectedChannels = campaign?.selectedChannels || [];
  const channels = [{ name: 'All channel' }, ...selectedChannels];
  useEffect(() => {
    removeFromSessionStorage(SESSION_KEYS.CREATE_CAMPAIGN_PERVIEW_SESSION_KEY);
    dispatch(
      createAction('createCampaign/getOneCampaign')({ id, isDetail: true }),
    );
    dispatch(
      createAction('createCampaign/getCampaignParticipationRecordsList')({
        id,
        page: 1,
      }),
    );
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
    return () => dispatch({ type: 'createCampaign/clearData' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (createStatus === APIStatus.calling) {
    return <Loading customClass={'common-full-height '} />;
  }

  const sections = [
    <ProfileSection data={campaign} couponAcquired={couponAcquired} />,
    campaign?.campaignType === CampaignType.fortuneBagCampaign ? (
      <FortuneBagDetail detailMode={true} campaign={campaign} />
    ) : null,
  ];

  const tabs = [
    {
      name: 'Detail',
      content: (
        <div className="scroll-container-common">
          <ContentSection data={campaign} />
          <ContentSections sections={sections} hidePreview={true} />
          <SaveAndBackButtons
            saveTempText={''}
            saveText={''}
            backAction={() => history.push('/campaigns')}
            saveAction={() => {}}
            saveTempAction={() => {}}
            extraButtons={
              campaign.campaignType === CampaignType.fortuneBagCampaign
                ? null
                : [
                    campaign?.campaignType !==
                    CampaignType.generalMessageCampaign ? (
                      <AuthButton
                        title="Test using coupon"
                        className="base-save-button-temp-button base-save-and-back-component-button btn-back-button-common"
                        action={() => {
                          const domain = process.env.REACT_APP_FRONTEND_LINK;
                          copy(`${domain}/en/preview-test?campaignId=${id}`);
                          dispatch({
                            type: 'navBars/updateState',
                            payload: {
                              saveDiscardToastShowing: {
                                value: true,
                                type: ToastType.testUseCouponCopied,
                              },
                            },
                          });
                        }}
                        requires={PermissionCodes.viewCampaign}
                      />
                    ) : null,
                    campaign.campaignType !==
                    CampaignType.fortuneBagForGoodieBagCampaign ? (
                      <CampaignPreviewButton
                        channels={channels}
                        campaign={campaign}
                        fromDetail={true}
                      />
                    ) : null,
                  ]
            }
          />
        </div>
      ),
    },
  ];

  const buttons = [
    <AuthButton
      title="Edit"
      action={() => {
        dispatch({ type: 'admin/clearData' });
        history.push({
          pathname: 'edit/',
        });
      }}
      requires={PermissionCodes.changeCampaign}
    />,
  ];

  return (
    <CustomListComponent
      caution={{
        detail: '',
        title: campaign.translations?.[LanguageConfig.english]?.generalName,
      }}
      hideTab
      breadcrumb={
        <CustomBreadcrumb
          name={campaign.translations?.[LanguageConfig.english]?.generalName}
        />
      }
      buttons={buttons}
      tabs={tabs}
    />
  );
}
