import React from 'react';
import { useSelector, connect } from 'react-redux';
import { APIStatus, LanguageConfig } from '../../config/CustomEnums';
import Loading from '../base/Loading';
import MembershipInfoCard from '../customer/MembershipInfoCard';

function OverViewContent({ language, goodieBagGroup, apiStatus }) {
  const fields = [
    [
      {
        title: 'Goodie bag group Name',
        field: 'name',
      },
    ],
    [
      {
        title: 'Related goodie bag(s)',
        field: 'goodieBags',
      },
    ],
    [
      {
        title: 'Group limit',
        field: 'perHeadLimit',
      },
    ],
    [
      {
        title: 'Alert of exceeding the limit in frontend (Optional)',
        field: 'alertMessageOfExceedLimit',
      },
    ],
  ];

  const getData = () => {
    const alertMessageOfExceedLimit =
      language === LanguageConfig.english
        ? goodieBagGroup?.alertMessageOfExceedLimit
        : goodieBagGroup?.translations?.[language]?.alertMessageOfExceedLimit;
    const goodieBags = goodieBagGroup?.goodieBags?.map(
      (data) => `[ID: ${data.pk}] ${data.name}`,
    );
    const data = {
      ...goodieBagGroup,
      alertMessageOfExceedLimit,
      goodieBags,
    };
    return data;
  };

  if(apiStatus === APIStatus.calling){
    return <Loading/>
  }

  return (
    <>
      <MembershipInfoCard title={'GENERAL'} data={getData()} fields={fields} />
    </>
  );
}
const mapPropsToState = (state) => ({
  goodieBagGroup: state.goodieBagGroups.goodieBagGroup,
  apiStatus: state.goodieBagGroups.apiStatus,
});
export default connect(mapPropsToState)(OverViewContent);
