import modelExtend from 'dva-model-extend';
import { apiWithResponseHandle } from './LoadingUtil';
import { createModel } from './BaseModel';
import {
  getChannelList,
  getChannel,
  createOrUpdateChannel,
  deleteChannels,
} from '../services/ChannelAPIHelper';
import { APIStatus, SESSION_KEYS } from '../config/CustomEnums';

const parseDetail = (data) => {
  // const relatedCampaigns = data.relatedCampaigns?.map((item) => item.name);
  const relatedCampaigns = data.relatedCampaignNames
    ? data.relatedCampaignNames.map((item) => item)
    : data.relatedCampaigns?.map((item) => item.name);
  return {
    ...data,
    markets: data.markets?.edges?.map((item) => item.node),
    displayRelatedMarkets: data.markets?.edges
      ?.map(
        (item) =>
          `${item.node.name}${
            item.node.parameter ? '(' + item.node.parameter + ')' : ''
          }`,
      )
      .join(', '),
    displayRelatedCampaigns: relatedCampaigns?.join(', '),
    relatedMarkets: data.markets?.edges?.map(
      (item) =>
        `${item.node.name}${
          item.node.parameter ? '(' + item.node.parameter + ')' : ''
        }`,
    ),
    relatedCampaign: relatedCampaigns,
  };
};

export default createModel({
  namespace: 'channel',
  states: {
    listDisplayFields: [
      { displayName: 'ID', fieldName: 'pk', orderField: 'pk' },
      {
        displayName: 'Channel name',
        fieldName: 'name',
      },
      {
        displayName: 'Related Market',
        fieldName: 'displayRelatedMarkets',
      },
      { displayName: 'Related campaign', fieldName: 'displayRelatedCampaigns' },
    ],
  },
  reducers: {},
  params: {
    sessionKey: SESSION_KEYS.CHANNEL_SESSION_KEY,
    dataKey: SESSION_KEYS.CHANNEL_DATA_SESSION_KEY,
    listAPI: getChannelList,
    parse: (data) => data?.channels.edges.map((item) => parseDetail(item.node)),
    // parse: (data) => data.channels.edges?.map((item) => item.node),
    deleteAPI: deleteChannels,
    pkNode: 'PublishChannelNode',
    detailAPI: getChannel,
    parseDetail: (data) => parseDetail(data.channel),
    objectKey: 'channels',
  },
  effects: {
    createOrUpdate: [
      function* ({ payload }, { call, select, put }) {
        const data = {
          name: payload.name,
          markets: payload.markets.map((item) => item.pk),
        };
        if (payload.id) {
          data.id = payload.pk;
        }

        const serviceArgs = [createOrUpdateChannel, data];
        yield put({
          type: 'responseCreateOrUpdate',
          payload: { serviceArgs, isCreate: !payload.id },
        });
      },
      { type: 'takeLatest' },
    ],
  },
});
