import React from 'react';
import './UseCouponPrompt.scss';
import { CustomTitleLabel } from '../../earning/CustomBaseComponments';
import BasePrompt from '../../base/prompt/BasePrompt';
import { Image, Modal } from 'react-bootstrap';
import ActionButton from 'antd/lib/modal/ActionButton';
import PromptButton from '../../base/prompt/BaseButtons';
import SuccessRedeemed from '../../../assets/images/coupon/success.png';
import FaliedRedeemed from '../../../assets/images/coupon/failed.png';
import AlreadyRedeemed from '../../../assets/images/coupon/redeemed.png';
import moment from 'moment';

export const ResultUseCouponPrompt = (props) => {
  const closeAction = props.closeAction || (() => {});
  const successAction = props.successAction || (() => {});
  const customClass = props.customClass || '';
  const customContentStyle = props.customContentStyle || {};
  const show = props.show || false;
  const data = props.data || {}

  const parseDateTime = (datetimeStr) => {
    return moment(datetimeStr).format('D MMM YYYY HH:mm:s');
  };
  
  const successResult = () => {
    const filedList = [
      {title: "Coupon name:", value: data?.coupon?.template?.name},
      {title: "SRK:", value: data?.coupon?.srk},
      {title: "Reference ID:", value: data?.coupon?.referenceId}
    ]
    return <>
      {data.alreadyRedeemed ? 
        <div className='result-title-area redeemed'>
          <Image src={AlreadyRedeemed} className='result-image'></Image>
          <div className="base-prompt-header">{'Already redeemed'}</div>
          <div className='item-title' style={{marginTop: '24px'}}>{'Redemption time:'}</div>
          <div className='item-title'>{parseDateTime(data.coupon.usedAt)}</div>
        </div>
        : 
        <div className='result-title-area success'>
          <Image src={SuccessRedeemed} className='result-image'></Image>
          <div className="base-prompt-header">{'Successfully redeemed'}</div>
        </div>
      }
      <div className='result-body-area'>
        {filedList.map((ele) => {
          return <div className='item'>
            <div><label className='item-title'>{ele.title}</label></div>
            <div><label className='item-value'>{ele.value}</label></div>
          </div>
        })}
      </div>
    </>
  }

  const failedResult = () => {
    return <>
      <div className='result-title-area failed'>
        <Image src={FaliedRedeemed} className='result-image'></Image>
        <div className="base-prompt-header">{'Redeemed failed'}</div>
      </div>
      <div className='result-body-area'>
        <div className='item'>
          <div><label className='item-title'>{'Reason(s) are as follows:'}</label></div>
          <div className='item-errors'>
            {data.errors?.map((error) => {
              return <div>
              <label className='item-title'>{error.code}</label>
              <div><label className='item-value'>{error.message}</label></div>
            </div>
            })}
          </div>
        </div>
      </div>
    </>
  }
  return (
    <Modal
      show={show}
      onHide={() => {}}
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
      style={customContentStyle}
      dialogClassName={props.dialogClassName}
    >
      <Modal.Body className='base-prompt-container result-prompt-container'>
      <>
        {data.success ? successResult() : failedResult()}
        <PromptButton 
          title={"Done"}
          action={data.success && !data.alreadyRedeemed? successAction : closeAction}
          className={"result-button"}
        />
      </>
      </Modal.Body>
    </Modal>
  );
}

function ConfirmUseCouponPrompt(props) {
  const show = props.show || false;
  const onClose = props.closeAction || (() => {});
  const onConfirm = props.onConfirm || (() => {});
  const data = props.data || {};

  const getOtherBody = () => {
    const filedList = [
      {title: "Campaign name:", value: data.campaignNames?.toString() || '-'},
      {title: "Corresponding brand:", value: data.brand?.name || '-'},
      {title: "Selected store:", value: data.store?.name || '-'},
      {title: "SRK:", value: data.coupon?.srk || '-'}
    ]
    return <>
      <div className='prompt-body-area'>
        {filedList.map((ele) => {
          return <div className='item'>
            <div><label className='item-title'>{ele.title}</label></div>
            <div><label className='item-value'>{ele.value}</label></div>
          </div>
        })}
        </div>
    </>
  }

  return (
    <BasePrompt 
        show={show}
        closeAction={onClose}
        rightButton={{
          text: 'Confirm',
          action: () => onConfirm(data.store?.pk, data.coupon?.srk)
        }}
        leftButton={{
          text: 'Cancel',
          action: onClose
        }}
        title={'Confirm use coupon?'}
        otherBody={getOtherBody}
        customClass={'confirm-prompt'}
      />
  );
}

export default ConfirmUseCouponPrompt;
