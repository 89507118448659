import {
  getBrands,
  deleteBrand,
  createBrand,
  getBrand,
  updateBrand,
} from '../services/BrandAPIHelper';
import {
  createAction,
  convertCursorToNumber,
  convertNumberToCursor,
  saveToSessionStorage,
  getObjectFromSessionStorage,
  removeFromSessionStorage,
} from '../utils';
import { apiWithResponseHandle, loading } from './LoadingUtil';
import { defaultStep } from './StepBarUtil';
import { APIStatus, LanguageConfig, SESSION_KEYS } from '../config/CustomEnums';
import { createModel } from './BaseModel';
import { GOODIE_BAG_ENTITLEMENT_METHOD_TYPE_DISPLAY } from './GoodieBagsModel';
import { getAttributeTagList } from '../services/AttributeTagAPIHelper';

export const CreateBrandError = {
  brandName: {
    name: 'brandName',
    message: 'Please provide a name.',
  },
  brandIcon: {
    name: 'brandIcon',
    message: 'Please provide a logo image.',
  },
};

export const sessionDataKey = {
  objectKey: SESSION_KEYS.CREATE_BRAND_SESSION_KEY,
  stepEndKey: SESSION_KEYS.CREATE_BRAND_END_SESSION_KEY,
  origionalData: SESSION_KEYS.CREATE_BRAND_ORIGIN_DATA_SESSION_KEY,
};

const checkFields = (data) => {
  let errorFields = [];
  if (!data.name) {
    errorFields.push(CreateBrandError.brandName.name);
  }
  if (!data.coverPhoto) {
    errorFields.push(CreateBrandError.brandIcon.name);
  }
  return {
    invalid: errorFields.length > 0,
    errorFields: errorFields,
    data,
  };
};

const getInitialState = () => ({
  brandList: [],
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    { displayName: 'Name', fieldName: 'name', orderField: 'name' },
    { displayName: 'Cover photo', fieldName: 'coverPhoto' },
    { displayName: 'Attribute tag', fieldName: 'displayAttributeTag' },
    { displayName: 'Related Store', fieldName: 'relatedStoreNames' },
  ],
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  updating: false,
  currentLastCursor: '',
  currentPage: 0,
  totalPage: 0,
  totalCount: 0,
  checkedList: [],
  currentPageBrandList: [],
  selectedBrand: {},
  errorFields: [],
  stepConfig: defaultStep([]),
  currentStep: 0,
  hasUpdatedDefaultValues: false,
  formHasSubmitted: false,
});

const getTranslations = (translationList) => {
  if (!translationList) {
    return [];
  }
  const translation = {};
  translationList.forEach((item) => {
    let language = item.node.language;
    translation[language] = {
      language: item.node.language,
      name: item.node.name,
      id: item.node.pk,
      description: item.node.description,
    };
  });
  return translation;
};

const getStoreTranslations = (translationList) => {
  const translation = {};
  if (!translationList || translationList.length === 0) {
    return {};
  }
  translationList.forEach((item) => {
    const language = item.node.language;
    translation[language] = {
      storeName: item.node.name,
      storeAddress: item.node.address,
      storeDescription: item.node.description,
    };
  });
  return translation;
};

export const parseStore = (storeInfo) => {
  const store = storeInfo.node;
  return {
    storeID: store.id,
    pk: store.pk,
    storePK: store.pk,
    name: store.name,
    storeName: store.name,
    storeAddress: store.address,
    storeDescription: store.description,
    storeTranslations: getStoreTranslations(store.translations?.edges),
  };
};

const handleOptionPk = (option) => {
  switch (option) {
    case 'OPTION_D':
      return 4;
    case 'OPTION_C':
      return 3;
    case 'OPTION_B':
      return 2;
    default:
      return 1;
  }
};

export const parseBrand = (brand) => {
  if (!brand) {
    return {};
  }
  let relatedStoreNameList = [];
  if (brand.stores) {
    relatedStoreNameList = brand.stores?.edges?.map((store) => store.node.name);
  }
  return {
    ...brand,
    id: brand.id,
    pk: brand.pk,
    name: brand.name,
    coverPhoto: brand.icon,
    description: brand.description,
    attributeTags: brand.attributeTags?.edges.map((item) => item.node),
    displayAttributeTag: brand.attributeTags?.edges
      .map((item) => item.node.name)
      .join('\n'),
    translations: getTranslations(brand.translations?.edges),
    brandStores: brand.stores?.edges.map((store) => parseStore(store)),
    relatedStores: brand.stores?.edges?.map((store) => store.node),
    relatedStoreNames:
      relatedStoreNameList.length > 0 ? relatedStoreNameList.toString() : '',
    fortuneBag: {
      checked: brand.handleFortuneBagEntitlement,
      option: brand.handleOption,
    },
    fortuneBagChecked: brand.handleFortuneBagEntitlement,
    fortuneBagOption: brand.handleOption,
    handleFortuneBagEntitlement: brand.handleFortuneBagEntitlement,
    handleOption: {
      name: brand.handleOption?.toLowerCase().replace('_', ' '),
      value: brand.handleOption,
      pk: handleOptionPk(brand.handleOption),
    },
    handleOptionTitle: {
      name: GOODIE_BAG_ENTITLEMENT_METHOD_TYPE_DISPLAY?.[brand.handleOption]
        ?.title,
      value: brand.handleOption,
      pk: handleOptionPk(brand.handleOption),
    },
  };
};

export const parseBrandInputBody = (brand, isEdit = false) => {
  let brandIconNameArray = [];
  if (brand.coverPhoto.type) {
    brandIconNameArray = brand.coverPhoto.value.split('/');
  } else {
    brandIconNameArray = brand.coverPhoto.split('/');
  }
  const coverPhotoName = brandIconNameArray[brandIconNameArray.length - 1];
  const translations = [];
  const translationArray = Object.keys(brand.translations || {});
  translationArray.forEach((language) => {
    translations.push({ ...brand.translations[language], language: language });
  });

  let inputBody = {
    name: brand.name,
    icon: coverPhotoName,
    description: brand.description,
    translations,
    attributeTags: brand.attributeTags?.map((tag) => tag.pk) || [],
    handleFortuneBagEntitlement: brand.fortuneBagChecked,
    handleOption:
      brand.fortuneBagOption || (brand.fortuneBagChecked ? 'OPTION_A' : ''),
  };
  if (isEdit) {
    inputBody = {
      ...inputBody,
      id: brand.pk,
    };
  }
  return inputBody;
};

const cleanTranslations = (brand, formData) => {
  const translations = {};
  Object.keys(formData?.translations || {}).forEach((language) => {
    const translation = {
      name: '',
      description: '',
      ...brand?.translations?.[language],
      ...formData?.translations?.[language],
    };
    translations[language] = translation;
  });
  return translations;
};

export default createModel({
  namespace: 'brand',
  states: getInitialState(),
  reducers: {
    updateBrandList(state, { payload }) {
      const { newBrandList, page } = payload;
      return {
        ...state,
        brandList:
          page > 1 ? [...state.brandList, ...newBrandList] : newBrandList,
      };
    },
    loadBrandFromCookie(state, { payload }) {
      const brand = getObjectFromSessionStorage(sessionDataKey.objectKey);
      console.log('@@248: ', brand);
      if (!brand) {
        console.log('@@248-1: ', state);
        return {
          ...state,
        };
      }
      return {
        ...state,
        selectedBrand: brand,
        hasUpdatedDefaultValues: true,
      };
    },

    saveOrRemoveBrandFromCookie(state, { payload }) {
      if (!payload.save) {
        removeFromSessionStorage(sessionDataKey.objectKey);
        removeFromSessionStorage(sessionDataKey.origionalData);
      } else {
        saveToSessionStorage(sessionDataKey.objectKey, payload.value);
      }
      saveToSessionStorage(sessionDataKey.stepEndKey, true);
      return {
        ...state,
      };
    },

    cleanBrand(state, action) {
      console.log('@@248: clean');
      return {
        ...state,
        ...getInitialState(),
      };
    },
  },
  params: {
    sessionKey: sessionDataKey.objectKey,
    dataKey: sessionDataKey.origionalData,
    listAPI: getBrands,
    parse: (data) => data?.brands.edges.map((item) => parseBrand(item.node)),
    deleteAPI: deleteBrand,
    pkNode: 'BrandNode',
    detailAPI: getBrand,
    parseDetail: (data) => parseBrand(data.targetMarket),
    objectKey: 'brands',
  },
  effects: {
    checkFields: [
      function* ({ payload }, { select, put }) {
        const sessionData =
          getObjectFromSessionStorage(sessionDataKey.objectKey) || {};
        const result = checkFields(sessionData);
        console.log('checkBrandFields', result);
        yield put(
          createAction('updateState')({
            errorFields: result.errorFields,
          }),
        );
        if (!result.invalid) {
          const afterActions = payload.afterActions || (() => {});
          yield afterActions();
        }
      },
      { type: 'takeLatest' },
    ],
    getCurrentPageBrands: [
      function* ({ payload }, { put, all }) {
        console.log('@@311: ', payload);
        const { page, searchKey, search, rank, moreSearch, isAll } = payload;
        let afterCursor = '';
        if (page > 1) {
          afterCursor = convertNumberToCursor((page - 1) * 20 - 1);
        }
        const serviceArgs = [
          getBrands,
          afterCursor,
          {
            rank: rank,
            searchKey: searchKey,
            search: search,
            isAll: isAll || false,
            moreSearch: moreSearch,
            ...payload,
          },
        ];
        function* onSuccess(data) {
          const brandData = data.brands.edges;
          const pageInfo = data.brands.pageInfo;
          const totalCount = data.brands.totalCount;
          const currentLastCursor = pageInfo.endCursor;
          const brandList = brandData.map((item) => parseBrand(item.node));
          yield all([
            put({
              type: 'updateState',
              payload: {
                currentPageBrandList: brandList,
                pageInfo: {
                  startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1,
                  endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1,
                },
                currentLastCursor,
                totalCount,
                totalPage: Math.ceil(totalCount / 20),
              },
            }),
            put({
              type: 'updateBrandList',
              payload: {
                newBrandList: brandList,
                page: page,
              },
            }),
          ]);
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getBrand: [
      function* ({ payload }, { put }) {
        const { brandPK } = payload;
        const brandID = btoa(`BrandNode:${brandPK}`);
        const serviceArgs = [getBrand, brandID];

        function* onSuccess(data) {
          const brandData = data.brand;
          const brand = parseBrand(brandData);
          yield put(
            createAction('updateState')({
              selectedBrand: brand,
              hasUpdatedDefaultValues: true,
            }),
          );
          yield put({
            type: 'updateProperty',
            payload: {
              brandIn: [brandPK],
              pageSize: 100,
              page: 1,
              stateName: 'selectedBrand',
              dataKey: 'attributeTags',
              updateKey: 'attributeTags',
              updateApi: getAttributeTagList,
            },
          });
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    delete: [
      function* ({ payload }, { put, select }) {
        const { checkedList } = yield select((state) => ({
          checkedList: state.brand.checkedList,
        }));

        let ids = [];
        checkedList.forEach((item) => {
          ids.push(item.pk);
        });

        const serviceArgs = [
          deleteBrand,
          {
            ids: ids,
          },
        ];
        function* onSuccess() {
          removeFromSessionStorage(sessionDataKey.objectKey);
          const afterActions = payload.afterAction || (() => {});
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    createBrand: [
      function* ({ payload }, { select, put }) {
        const { stateBrand, updating } = yield select((state) => ({
          stateBrand: state.brand.selectedBrand,
          updating: state.brand.updating,
        }));
        if (updating) {
          return;
        }
        const payloadBrand = payload.data;
        const brand = payloadBrand || stateBrand;
        const formData = payload.values;
        const totalData = {
          ...brand,
          ...formData,
          translations: cleanTranslations(brand, formData),
        };
        const inputBody = parseBrandInputBody(totalData);
        if (payload.isDuplicate) {
          inputBody.translations.forEach((item, index) => {
            delete inputBody.translations[index].id;
          });

          delete inputBody.pk;
          delete inputBody.id;
        }

        const serviceArgs = [createBrand, inputBody];
        saveToSessionStorage(sessionDataKey.stepEndKey, true);
        function* onSuccess(data) {
          yield put({
            type: 'updateState',
            payload: { updating: false, formHasSubmitted: true },
          });
          removeFromSessionStorage(sessionDataKey.objectKey);

          const afterActions = payload.afterActions || (() => {});
          const brand = parseBrand(data?.createBrand?.node);
          yield put({ type: 'updateState', payload: { selectedBrand: brand } });
          yield afterActions(brand);
        }
        function* onFailure() {
          yield put({ type: 'updateState', payload: { updating: false } });
        }
        yield put({ type: 'updateState', payload: { updating: true } });
        yield loading(serviceArgs, onSuccess, onFailure, onFailure);
      },
      { type: 'takeLatest' },
    ],
    duplicate: [
      function* ({ payload }, { put }) {
        const brand = { ...payload.data, name: `Copy of ${payload.data.name}` };

        yield put(
          createAction('updateState')({
            selectedBrand: brand,
          }),
        );
        const afterActions = payload.afterAction || (() => {});
        yield put(
          createAction('createBrand')({
            afterActions,
            isDuplicate: true,
          }),
        );
      },
    ],
    updateBrand: [
      function* ({ payload }, { select, put }) {
        const { brand, updating } = yield select((state) => ({
          brand: state.brand.selectedBrand,
          updating: state.brand.updating,
        }));
        if (updating) {
          return;
        }
        yield put({ type: 'updateState', payload: { updating: true } });

        const formData = payload.values;
        const totalData = {
          ...brand,
          ...formData,
          translations: cleanTranslations(brand, formData),
        };
        const inputBody = parseBrandInputBody(totalData, true);
        const serviceArgs = [updateBrand, inputBody];
        saveToSessionStorage(sessionDataKey.stepEndKey, true);

        function* onSuccess() {
          removeFromSessionStorage(sessionDataKey.objectKey);
          yield put(
            createAction('updateState')({
              formHasSubmitted: true,
              updating: false,
            }),
          );

          // yield put({
          //   type: 'updateState',
          //   payload: { updating: false, formHasSubmitted: true },
          // });

          const afterActions = payload.afterActions || (() => {});
          yield afterActions();
        }

        function* onFailure() {
          yield put({ type: 'updateState', payload: { updating: false } });
        }

        yield loading(serviceArgs, onSuccess, onFailure, onFailure);
      },
      { type: 'takeLatest' },
    ],
  },
});
