import React, { useState } from 'react';
import AnimatedNumber from 'animated-number-react';
import { useSelector } from 'react-redux';
import './CouponsCard.scss';
import { enLocaleNumberFormatter } from '../../utils';
import {
  COUPON_PIE_CHART_CONFIG,
  ANIMATION_DURATION,
} from '../../config/DashboardConfig';
import { Link } from 'react-router-dom';
import icon from '../../assets/images/customer_overview_vector.svg';
import female_half from '../../assets/images/customer_gender_female_half.png';
import female from '../../assets/images/customer_gender_female.png';
import female_empty from '../../assets/images/customer_gender_female_empty.png';
import male_half from '../../assets/images/customer_gender_male_half.png';
import male from '../../assets/images/customer_gender_male.png';
import male_empty from '../../assets/images/customer_gender_male_empty.png';
import gender_icon from '../../assets/images/customer_gender_icon.png';
import { Button, Image, ButtonGroup, ProgressBar } from 'react-bootstrap';
import './CustomerGenderCard.scss';
import CustomTips from '../base/CustomTips';
import { TimeFormater, formatDate } from '../../utils/TimeFormatUtil';

const CustomerGenderCard = () => {
  const { endDate, customerGender } = useSelector((state) => ({
    endDate: state.dashboard.endDate,
    customerGender: state.dashboard.customerGender,
  }));

  const displayPicture = (percentage, image, image_half, image_empty, top) => {
    const numberOfImage = parseInt(percentage / 10);
    const rows = [];
    if (!percentage) {
      return (
        <img
          className="dashboard-customer-gender-image"
          src={image_empty}
          style={{ right: '15px', top: `${top}px` }}
        />
      );
    }
    for (let i = 0; i <= numberOfImage; i++) {
      if (i > 0) {
        rows.push(
          <img
            className="dashboard-customer-gender-image"
            src={image}
            style={{
              right: `${(numberOfImage + 1 - i) * 15}px`,
              top: `${top}px`,
            }}
          />,
        );
      }
    }
    if (percentage % 10 !== 0) {
      rows.push(
        <img
          className="dashboard-customer-gender-image-half"
          src={image_half}
          style={{ right: '25px', top: `${top}px` }}
        />,
      );
      rows.push(
        <div
          className="dashboard-customer-gender-image-half"
          style={{
            right: '0px',
            top: `${top}px`,
            backgroundColor: '#ffffff',
          }}
        />,
      );
    }
    return <>{rows}</>;
  };

  return (
    <div className="dashboard-coupon-card dashboard-customer-gender-card">
      <div className="dashboard-card-title">
        <div>GENDER</div>
        <label className="dashboard-customer-gender-label-caution">
          {endDate
            ? `as of ${formatDate(
                endDate,
                TimeFormater.dayMonthYearTimePeriod,
              )}`
            : null}
        </label>
      </div>
      <div className="dashboard-card-body">
        <div className="dashboard-card-data">
          <div className="dashboard-customer-male">
            {displayPicture(
              customerGender?.maleCustomersData?.percentage,
              male,
              male_half,
              male_empty,
              70,
            )}
            <CustomTips
              detail="Salutation: Mr"
              iconClassName="dashboard-customer-male-title-icon"
            >
              <label className="dashboard-customer-male-title-name">Male</label>
            </CustomTips>
            <div className="dashboard-customer-male-number">{`${
              customerGender?.maleCustomersData?.percentage || 0
            }%`}</div>
            <div className="dashboard-customer-male-specific-number">
              {customerGender?.maleCustomersData?.value || 0}
            </div>
          </div>
          <div className="dashboard-customer-female">
            {displayPicture(
              customerGender?.femaleCustomersData?.percentage,
              female,
              female_half,
              female_empty,
              215,
            )}
            <CustomTips
              detail="Salutation: Mrs or Ms"
              iconClassName="dashboard-customer-female-title-icon"
            >
              <label className="dashboard-customer-female-title-name">
                Female
              </label>
            </CustomTips>
            <div className="dashboard-customer-female-number">{`${
              customerGender?.femaleCustomersData?.percentage || 0
            }%`}</div>
            <div className="dashboard-customer-female-specific-number">
              {customerGender?.femaleCustomersData?.value || 0}
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard-card-data-defined">
        <div className="dashboard-card-data-collected">
          <label>Data collected</label>
          <label className="dashboard-card-data-number">
            {customerGender?.dataCollected || 0}
          </label>
        </div>
        <div className="dashboard-card-data-undefined">
          <label>Undefined</label>
          <label className="dashboard-card-data-number">
            {customerGender?.totalNumberOfCustomers -
              customerGender?.dataCollected || 0}
          </label>
        </div>
      </div>
      <Link
        className="dashboard-overview-link coupons-overview-link"
        to={{ pathname: '/dashboard_customer' }}
      >
        {/* Customer Overview */}
        See detail
        <img className="dashboard-overview-link-icon" src={icon} alt="" />
      </Link>
    </div>
  );
};

export default CustomerGenderCard;
