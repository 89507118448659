import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Dropdown, DropdownButton } from 'react-bootstrap';
import { CampaignType } from '../../config/CustomEnums';
import { CheckboxItem } from '../../components/base/BaseFilter';
import { removeElementFromArray } from '../../utils';
import UpIcon from '../../assets/images/upIcon.svg';

const campaginTypes = [
  {
    label: 'General message campaign',
    value: CampaignType.generalMessageCampaign,
  },
  {
    label: 'Coupon campaign',
    value: CampaignType.couponsCampaign,
  },

  {
    label: 'Cash voucher',
    value: CampaignType.cashVoucherCampaign,
  },
  {
    label: 'Goodie bag coupon',
    value: CampaignType.giftCampaign,
  },
  {
    label: 'Goodie bag offer (Direct assign)',
    value: CampaignType.fortuneBagForGoodieBagCampaign,
  },
];

function StarredCampaignFilter({ filterModel, includeGeneralMessage = true }) {
  const location = useLocation();
  const dispatch = useDispatch();

  const allLength = includeGeneralMessage
    ? campaginTypes.length
    : campaginTypes.length - 1;
  const selectedCampaignTypeSource = campaginTypes.filter((item) =>
    includeGeneralMessage
      ? item
      : item.value !== CampaignType.generalMessageCampaign,
  );

  const [isStarred, setIsStarred] = useState();
  const [selectedCampaignType, setSelectedCampaignType] = useState(
    selectedCampaignTypeSource.map((item) => item.value),
  );

  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    if (filterModel && !isShown) {
      dispatch({
        type: 'dashboard/updateState',
        payload: {
          [filterModel]: {
            selectedCampaignType,
            isStarred,
          },
        },
      });
    }
  }, [isShown]);

  useEffect(() => {
    console.log('@@65: ', filterModel, selectedCampaignType, isStarred);
    dispatch({
      type: 'dashboard/updateState',
      payload: {
        [filterModel]: {
          selectedCampaignType,
          isStarred,
        },
      },
    });
  }, [isStarred]);

  return (
    <div className="d-flex starred-campaign-filter">
      <div className="d-flex starred-check-area">
        {[
          { label: 'Starred', value: 'true' },
          { label: 'Non-Starred', value: 'false' },
        ].map((item, index) => {
          return (
            <CheckboxItem
              index={index}
              title={item.label}
              defaultValue={isStarred === item.value}
              customClass={`platform-checkbox ${
                index === 0 ? '' : 'platform-checkbox-notfirst'
              }`}
              onChange={() => {
                let starredValue = item.value;
                if (isStarred && isStarred === item.value) {
                  starredValue = null;
                  setIsStarred();
                } else {
                  setIsStarred(item.value);
                }
                if (filterModel) {
                  dispatch({
                    type: 'dashboard/updateState',
                    payloads: {
                      [filterModel]: {
                        selectedCampaignType,
                        isStarred: starredValue,
                      },
                    },
                  });
                }
              }}
            />
          );
        })}
      </div>
      <Dropdown
        className="d-inline display-flex-right"
        show={isShown}
        onToggle={(isOpen, e, metadata) => {
          if (metadata.source != 'select') {
            setIsShown(isOpen);
          }
        }}
        autoClose={'outside'}
        bsPrefix={'dashboard-campaign-selector'}
        align="end"
      >
        <Dropdown.Toggle id="dropdown-autoclose-outside">
          <label>
            {selectedCampaignType?.length === allLength
              ? 'All'
              : selectedCampaignType.length > 0
              ? campaginTypes
                  .filter(
                    (item) => selectedCampaignType.indexOf(item.value) >= 0,
                  )
                  .map((item) => item.label)
                  ?.join(',')
              : '-'}
          </label>
          <img src={UpIcon} />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            href="#"
            onClick={() => {
              if (selectedCampaignType?.length === allLength) {
                setSelectedCampaignType([]);
              } else {
                setSelectedCampaignType(
                  selectedCampaignTypeSource.map((item) => item.value),
                );
              }
            }}
          >
            <CheckboxItem
              index={`all`}
              title={'All'}
              defaultValue={selectedCampaignType?.length === allLength}
              customClass={`platform-checkbox`}
              showSquare={
                selectedCampaignType?.length > 0 &&
                selectedCampaignType?.length < allLength
              }
              onChange={() => {}}
            />
          </Dropdown.Item>
          {selectedCampaignTypeSource.map((item, index) => (
            <Dropdown.Item
              key={item.value}
              onClick={() => {
                if (selectedCampaignType?.indexOf(item.value) >= 0) {
                  setSelectedCampaignType(
                    removeElementFromArray(selectedCampaignType, item.value),
                  );
                } else {
                  setSelectedCampaignType([
                    ...selectedCampaignType,
                    item.value,
                  ]);
                }
              }}
            >
              <CheckboxItem
                index={`${index}-${item.value}`}
                title={item.label}
                defaultValue={selectedCampaignType?.indexOf(item.value) >= 0}
                customClass={`platform-checkbox `}
                onChange={() => {}}
              />
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default StarredCampaignFilter;
