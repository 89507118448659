import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { Tooltip } from 'antd';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import {
  BannerLocation,
  BannerType,
  TargetCustomerType,
} from '../../../models/HomeBannersModel';
import { ArrayStringData, createAction } from '../../../utils';
import LabelWithToolTips from '../../../components/base/LabelWithToolTips';

function Filter({ backAction = () => {} }) {
  const location = useLocation();
  const dispatch = useDispatch();

  const { countryCodeList } = useSelector((state) => ({
    countryCodeList: state.countryCode.notPagedAllList,
  }));

  const parsedSearch = queryString.parse(location.search);
  const searchType = parsedSearch['banner_type'] || '';
  const searchTargetCustomers = parsedSearch['target_customers'] || '';
  const searchCountryCodes = parsedSearch['country_codes'] || '';
  const searchPlatform = parsedSearch['platform'] || '';
  const searchLandingPage = parsedSearch['landing_page'] || '';
  const searchStatus = parsedSearch['status'] || '';

  const [type, setType] = useState(ArrayStringData(searchType));
  const [platform, setPlatform] = useState(ArrayStringData(searchPlatform));
  const [landingPage, setLandingPage] = useState(searchLandingPage);
  const [status, setStatus] = useState(searchStatus);

  const [targetCustomers, setTargetCustomers] = useState(
    ArrayStringData(searchTargetCustomers),
  );
  const [countryCodes, setCountryCodes] = useState(
    ArrayStringData(searchCountryCodes),
  );
  const [sourceCountryCodesData, setSourceCountryCodesData] = useState();
  const [countryCodesData, setCountryCodesData] = useState();

  useEffect(() => {
    dispatch(createAction('countryCode/getAllList')({ pageSize: 1000 }));

    return () => {
      dispatch({ type: 'countryCode/clearData' });
    };
  }, []);

  useEffect(() => {
    console.log('countryCodeList: ', countryCodeList);
    const treeData = [
      {
        pk: 'all',
        key: 'all',
        name: 'Country codes',
        title: (
          <LabelWithToolTips
            title="Country codes"
            className="list-tree-title"
          />
        ),
        children: countryCodeList.map((item) => {
          return {
            ...item,
            title: (
              <LabelWithToolTips
                title={`${item.name}`}
                className="list-tree-sub-title"
              />
            ),
            key: item.pk,
          };
        }),
      },
    ];
    setCountryCodesData(treeData);
    setSourceCountryCodesData(treeData);
  }, [countryCodeList]);

  const content = [
    {
      title: 'Banner type',
      data: [
        { pk: BannerType.HOME_PAGE, name: 'Homepage' },
        { pk: BannerType.GOODIE_BAG, name: 'My Reward page for Goodie bag' },
        {
          pk: BannerType.GOODIE_BAG_PROMPT,
          name: 'Prompt for Goodie bag',
        },
      ],
      value: type,
      setValue: setType,
      componment: FilterTypeEnum.choice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Target customers',
      data: [
        { pk: TargetCustomerType.GUEST, name: 'Guest' },
        { pk: TargetCustomerType.LOGGED_IN, name: 'Logged in' },
      ],
      value: targetCustomers,
      setValue: setTargetCustomers,
      componment: FilterTypeEnum.choice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Country codes',
      displayTitle: 'Country codes',
      data: countryCodesData,
      value: countryCodes,
      setValue: setCountryCodes,
      componment: FilterTypeEnum.searchable2LevelChoice,
      placeholder: 'Search by country code',
      filterAction: (searchKey) => {
        const newData = sourceCountryCodesData
          ?.filter((item) => {
            const name = item.title.props.title?.toLowerCase() || '';
            return (
              name.includes(searchKey?.toLowerCase() || '') ||
              item.children.filter((val) =>
                val.title.props.title
                  .toLowerCase()
                  .includes(searchKey?.toLowerCase() || ''),
              ).length > 0
            );
          })
          ?.map((item) => {
            const name = item.title.props.title?.toLowerCase() || '';
            const matched = name.includes(searchKey?.toLowerCase() || '');

            if (matched) {
              return item;
            }

            return {
              ...item,
              children: item.children.filter((val) =>
                val.title.props.title
                  .toLowerCase()
                  .includes(searchKey?.toLowerCase() || ''),
              ),
            };
          });
        setCountryCodesData(newData);
      },
    },
    {
      title: 'Platform',
      data: [
        { pk: BannerLocation.HOME_PAGE, name: 'e-Solution website' },
        { pk: BannerLocation.WECHAT, name: 'e-Solution mini program' },
      ],
      value: platform,
      setValue: setPlatform,
      componment: FilterTypeEnum.choice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Landing page',
      data: [
        { name: 'YES', pk: 'MANUAL_INPUT_URL' },
        // { name: 'Expired', pk: 'inactive' },
        { name: 'NO', pk: 'NO_LANDING_PAGE' },
      ],
      value: landingPage,
      setValue: setLandingPage,
      componment: FilterTypeEnum.singleChoiceSegment,
    },
    {
      title: 'Status',
      data: [
        { name: 'Unpublished', pk: 'false' },
        // { name: 'Expired', pk: 'inactive' },
        { name: 'Published', pk: 'true' },
      ],
      value: status,
      setValue: setStatus,
      componment: FilterTypeEnum.singleChoiceSegment,
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

export default Filter;
