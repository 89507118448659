import React from 'react';
import BasePrompt from '../../base/prompt/BasePrompt';
import './PhysicalCouponTemplateReference.scss';
import physicalCouponTemplate from '../../../assets/images/coupon/physicalCouponTemplate.svg';
import { Image } from 'react-bootstrap';
import { createNumberArray } from '../../../utils';

function PhysicalCouponTemplateReference({ show, closeAction = () => {} }) {
  const otherBody = () => {
    return <Image src={physicalCouponTemplate} />;
  };
  return (
    <BasePrompt
      show={show}
      closeAction={closeAction}
      title={'Template reference'}
      description={
        'The light red area is the display area of each part of the content.'
      }
      otherBody={otherBody}
      basePromptButtonsClassName={'reference-prompt-container-bottom'}
      customClass={'reference-prompt-container'}
      customOtherBodyClass={'reference-prompt-other-body-container'}
      basePromptDescriptionClassName={'reference-prompt-description'}
    />
  );
}

export default PhysicalCouponTemplateReference;
