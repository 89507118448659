import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { createAction } from '../../../utils';

import CustomTitleWithInput from '../../../components/base/CustomTitleWithInput';
import { hasError } from '../../../components/base/ErrorFieldMessage';
import ContentSections from '../../../components/base/ContentSections';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import { APIStatus } from '../../../config/CustomEnums';

function LanguageContent({}) {
  const {
    formState: { errors },
    setValue,
    watch,
    register,
  } = useFormContext();

  const watchSourceName = watch('sourceName');
  const watchParameter = watch('parameter');

  return (
    <>
      <label className="create-section-title">General</label>

      <CustomTitleWithInput
        title="Name"
        defaultValue={watchSourceName}
        setValue={(value) => {
          setValue('sourceName', value, {
            shouldDirty: true,
          });
        }}
        error={
          hasError(errors, 'sourceName')
            ? {
                id: 'name',
                error: hasError(errors, 'sourceName'),
                message: 'please input a name',
              }
            : {}
        }
      />
      <CustomTitleWithInput
        title="Parameter"
        defaultValue={watchParameter}
        setValue={(value) => {
          setValue('parameter', value, {
            shouldDirty: true,
          });
        }}
        error={
          hasError(errors, 'parameter')
            ? {
                id: 'parameter',
                error: hasError(errors, 'parameter'),
                message: 'please input a alphabetical parameter',
              }
            : {}
        }
        customClass="custom-input-short custom-input-upper"
        extra={{ maxLength: '2' }}
      />
      <label className="tips-message">2 digit, alphabetical </label>
    </>
  );
}

export default function CreateLanguageContent({}) {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const { getValues, setError, clearErrors } = useFormContext();
  const validate = async () => {
    clearErrors(['sourceName', 'parameter']);
    const [sourceName, parameter] = getValues(['sourceName', 'parameter']);
    let isError = false;
    if (!sourceName) {
      isError = true;
      setError(
        'sourceName',
        {
          type: 'require',
          message: 'please input name',
        },
        { shouldFocus: true },
      );
    }

    if (
      !parameter ||
      !parameter.match(/[A-Z]/g) ||
      parameter.match(/[A-Z]/g).length < 2
    ) {
      isError = true;
      setError(
        'parameter',
        {
          type: 'require',
          message: 'please input alphabetical parameter',
        },
        { shouldFocus: true },
      );
    }
    return sourceName && parameter && !isError;
  };

  return (
    <>
      <ContentSections sections={[<LanguageContent />]} hidePreview={true} />
      <SaveAndBackButtons
        saveText={params.id ? 'Update' : 'Save'}
        backAction={() => history.goBack()}
        saveAction={async () => {
          const isValid = await validate();
          if (isValid) {
            dispatch(createAction('language/createOrUpdate')(getValues()));
          }
        }}
      />
    </>
  );
}
