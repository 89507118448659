import React, { useEffect, useState } from 'react';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { useDispatch, connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Loading from '../../../components/base/Loading';
import BaseForm from '../../../components/base/v2/BaseForm';
import queryString from 'query-string';
import { saveDataToSessionStorage } from '../../../models/CampaignGroupModel';
import { removeDataFromSessionStorage } from '../../../models/CampaignGroupModel';
import CreateCampaignGroupStepOne from './CreateCampaignGroupStepOne';
import { CampaignType } from '../../../config/CustomEnums';

function CreateCampaignGroup({
  stepConfig,
  campaignGroup,
  hasUpdatedDefaultValues,
  formHasSubmitted,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch({ type: 'language/getAllList', payload: {} });
    dispatch({
      type: 'campaignList/getAllList',
      payload: {
        type: CampaignType.fortuneBagCampaign,
      },
    });

    if (params.id) {
      dispatch({
        type: 'campaignGroups/getCampaignGroup',
        payload: { campaignGroupPK: params.id },
      });
    } else {
      dispatch({
        type: 'campaignGroups/loadCampaignGroupFromCookie',
        payload: {},
      });
    }
    return () => {
      dispatch({
        type: 'campaignGroups/clearData',
        payload: {},
      });
    };
  }, []);

  const stepContent = [<CreateCampaignGroupStepOne />];

  const gotoCampaignGroupList = () => {
    history.push({
      pathname: '/campaign_groups',
      search: queryString.stringify({
        page: 1,
        rank: true,
        search: '',
      }),
    });
  };

  if ((params.id && !campaignGroup?.pk) || (!params.id && campaignGroup?.pk)) {
    return <Loading customClass={'common-full-height'} />;
  }

  return (
    <>
      <BaseForm
        defaultValues={campaignGroup}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={formHasSubmitted}
        tempSave={(save, getValues) => {
          if (save) {
            saveDataToSessionStorage(getValues());
          } else {
            removeDataFromSessionStorage();
          }
        }}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: 'Fortune Bag group is successfully created.',
          steps: null,
          buttons: [
            {
              text: 'Back to fortune bag groups list',
              action: gotoCampaignGroupList,
            },
          ],
        }}
        showFinishPop={formHasSubmitted}
        continueCreateConfig={{
          onConfirm: () => {
            history.push(history.location.state?.from);
          },
          onCancel: gotoCampaignGroupList,
          onClose: gotoCampaignGroupList,
        }}
        content={stepContent}
        currentStep={0}
        breadcrumb={
          <CustomBreadcrumb
            name={params.id ? campaignGroup.name : 'Create Fortune Bag groups'}
          />
        }
        stepsConfig={stepConfig}
        caution={{
          detail: '',
          title: params.id
            ? `Edit ${campaignGroup.name}`
            : 'Create Fortune Bag groups',
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  stepConfig: state.campaignGroups.stepConfig,
  campaignGroup: state.campaignGroups.campaignGroup,
  hasUpdatedDefaultValues: state.campaignGroups.hasUpdatedDefaultValues,
  formHasSubmitted: state.campaignGroups.formHasSubmitted,
});

export default connect(mapPropsToState)(CreateCampaignGroup);
