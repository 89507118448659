import React from 'react';
import './SearchAreaMobile.scss';
import SearchAreaContent from './SearchAreaContent';

function SearchAreaMobile({
  title,
  countryCodeList,
  isRegister,
  searchMembershipId,
  isCheckOTP,
}) {
  return (
    <div className="check-otp-search-container-mobile">
      <label className="list-section-container-header-title">{title}</label>
      <SearchAreaContent
        countryCodeList={countryCodeList}
        isRegister={isRegister}
        searchMembershipId={searchMembershipId}
        isCheckOTP={isCheckOTP}
      />
    </div>
  );
}

export default SearchAreaMobile;
