import React, { useState, useEffect } from 'react';
import './SaveDiscardToast.scss';
import { useSelector, useDispatch } from 'react-redux';
import { Toast } from 'react-bootstrap';
import { ToastType } from '../../models/NavBarModel';
import { TokenErrors } from '../../config/CustomEnums';

function SaveDiscardToast() {
  const { saveDiscardToastShowing } = useSelector((state) => ({
    saveDiscardToastShowing: state.navBars.saveDiscardToastShowing,
  }));
  const [show, setShow] = useState(
    saveDiscardToastShowing.value &&
      saveDiscardToastShowing.type !== TokenErrors.Expiry,
  );
  const dispatch = useDispatch();
  useEffect(() => {
    setShow(
      saveDiscardToastShowing.value &&
        saveDiscardToastShowing.type !== TokenErrors.Expiry,
    );
  }, [saveDiscardToastShowing.type, saveDiscardToastShowing.value]);

  const onToastHide = () => {
    setShow(false);
    dispatch({
      type: 'navBars/updateState',
      payload: {
        saveDiscardToastShowing: { value: false, type: ToastType.none },
      },
    });
  };
  if (saveDiscardToastShowing.type === ToastType.none) {
    return <></>;
  }
  return (
    <div
      className={`save-success-toast-container ${
        saveDiscardToastShowing.type === ToastType.downloading
          ? 'save-success-toast-container-full-screen'
          : ''
      }`}
    >
      <Toast
        className="save-toast-main-content"
        onClose={onToastHide}
        show={show}
        autohide
      >
        <Toast.Body className="save-toast-body">
          {/* <image src={ToastIcon} style={{ width: 31, height: 30 }} /> */}
          <label className="save-toast-text">
            {saveDiscardToastShowing.type}
          </label>
          <label onClick={onToastHide} className="save-toast-button">
            Close
          </label>
        </Toast.Body>
      </Toast>
    </div>
  );
}

export default SaveDiscardToast;
