import moment from 'moment';
import {
  getCampaignsOverviewSingleData,
  getCustomersOverviewData,
  getCouponsOverviewSingleData,
  getCampaignsOverviewRakingTable,
  getCouponsOverviewRakingTable,
  getCouponsTop10RedemptionByDistrict,
  getCouponsTop10RedemptionByStore,
  getGoodieBagOverviewSingleData,
  getGoodieBagOverviewRankingTable,
} from '../services/AnalyticsApiHelper';
import { apiWithResponseHandle } from './LoadingUtil';
import { createAction } from '../utils';
import { parseCustomerChartData } from './DashboardModel';
import { CampaignType } from '../config/CustomEnums';

const COUPON_OVERVIEW_DATA_CONFIG = {
  totalNumberOfCoupons: 'totalNumberOfCoupons',
  totalNumberOfCouponAvailable: 'totalNumberOfCouponAvailable',
  totalNumberOfCouponAcquired: 'totalNumberOfCouponAcquired',
  totalNumberOfCouponUsed: 'totalNumberOfCouponUsed',
  acquiredCouponsConversionRate: 'acquiredCouponsConversionRate',
  usedCouponsConversionRate: 'usedCouponsConversionRate',
};

const CAMPAIGN_OVERVIEW_DATA_CONFIG = {
  // totalNumberOfPublishedCampaigns: "totalNumberOfPublishedCampaigns",
  totalNumberOfPublishedGeneralMessageCampaigns:
    'totalNumberOfPublishedGeneralMessageCampaigns',
  totalNumberOfNonpublishedGeneralMessageCampaigns:
    'totalNumberOfNonpublishedGeneralMessageCampaigns',
  totalNumberOfExpiredGeneralMessageCampaigns:
    'totalNumberOfExpiredGeneralMessageCampaigns',
  totalNumberOfPublishedCouponCampaigns:
    'totalNumberOfPublishedCouponCampaigns',
  totalNumberOfNonpublishedCouponCampaigns:
    'totalNumberOfNonpublishedCouponCampaigns',
  totalNumberOfExpiredCouponCampaigns: 'totalNumberOfExpiredCouponCampaigns',
};

const CAMPAIGN_OVERVIEW_COUPON_DATA_CONFIG = {
  totalNumberOfCoupons: 'totalNumberOfCoupons',
  totalNumberOfCouponAvailable: 'totalNumberOfCouponAvailable',
  totalNumberOfCouponAcquired: 'totalNumberOfCouponAcquired',
  totalNumberOfCouponUsed: 'totalNumberOfCouponUsed',
  acquiredCouponsConversionRate: 'acquiredCouponsConversionRate',
  usedCouponsConversionRate: 'usedCouponsConversionRate',
};

const CASH_CAMPAIGN_OVERVIEW_DATA_CONFIG = {
  totalNumberOfPublishedCashVoucherCampaigns:
    'totalNumberOfPublishedCashVoucherCampaigns',
  totalNumberOfNonpublishedCashVoucherCampaigns:
    'totalNumberOfNonpublishedCashVoucherCampaigns',
  totalNumberOfExpiredCashVoucherCampaigns:
    'totalNumberOfExpiredCashVoucherCampaigns',
};

const GIFT_CAMPAIGN_OVERVIEW_DATA_CONFIG = {
  totalNumberOfPublishedGiftCampaigns: 'totalNumberOfPublishedGiftCampaigns',
  totalNumberOfNonpublishedGiftCampaigns:
    'totalNumberOfNonpublishedGiftCampaigns',
  totalNumberOfExpiredGiftCampaigns: 'totalNumberOfExpiredGiftCampaigns',
};

const FORTUNEBAG_CAMPAIGN_OVERVIEW_DATA_CONFIG = {
  totalNumberOfPublishedFortuneCampaigns:
    'totalNumberOfPublishedFortuneCampaigns',
  totalNumberOfNonpublishedFortuneCampaigns:
    'totalNumberOfNonpublishedFortuneCampaigns',
  totalNumberOfExpiredFortuneCampaigns: 'totalNumberOfExpiredFortuneCampaigns',
};

const GOODIEBAG_OVERVIEW_DATA = {
  totalNumberOfPublishedGoodieBags: 'totalNumberOfPublishedGoodieBags',
  totalNumberOfNonpublishedGoodieBags: 'totalNumberOfNonpublishedGoodieBags',
  totalNumberOfExpiredGoodieBags: 'totalNumberOfExpiredGoodieBags',
  totalNumberOfGeneratedGoodieBags: 'totalNumberOfGeneratedGoodieBags',
  totalNumberOfAcquiredGoodieBags: 'totalNumberOfAcquiredGoodieBags',
  acquiredGoodieBagsConversionRate: 'acquiredGoodieBagsConversionRate',
  totalNumberOfAcquiredGoodieBagCredit: 'totalNumberOfAcquiredGoodieBagCredit',
  totalNumberOfUnusedGoodieBagCredit: 'totalNumberOfUnusedGoodieBagCredit',
  totalNumberOfAcquiredGoodieBagQuota: 'totalNumberOfAcquiredGoodieBagQuota',
  totalNumberOfUnusedGoodieBagQuota: 'totalNumberOfUnusedGoodieBagQuota',
};

const Top10FieldEnum = {
  rank: { name: 'Rank', fieldIndex: 0 },
  name: { name: 'Name', fieldIndex: 1 },
  number: { name: 'Number', fieldIndex: 2 },
  percentage: { name: 'Percentage', fieldIndex: 3 },
};

const parseCampaignsRakingTableApiData = (resp, key) => {
  if (!resp) {
    return {};
  }
  const { analyticsData } = resp;
  return {
    [key]: analyticsData.couponAcquiredAndUsedByCampaignTableWithPagination,
  };
};

// const parseCustomersApiData = (resp) => {
//   const { analyticsData } = resp;
//   return {
//     totalCustomers: {
//       ...analyticsData.totalNumberOfCustomers,
//     },
//     activeCustomers: {
//       ...analyticsData.totalNumberOfActiveCustomers,
//     },
//     newCustomers: {
//       ...analyticsData.totalNumberOfNewCustomers,
//     },
//     chartData: parseCustomerChartData(
//       analyticsData.totalNumberOfNewCustomersChart,
//     ),
//   };
// };

const parseCouponsRakingTableApiData = (resp) => {
  const { analyticsData } = resp;
  return {
    couponsRakingTable:
      analyticsData.couponAcquiredAndUsedByBrandTableWithPagination,
  };
};

const parseCouponsTop10RedemptionByDistrictApiData = (resp) => {
  const { analyticsData } = resp;
  return {
    CouponsTop10RedemptionByDistrictTable:
      analyticsData.top10CouponUsedByDistrictTable,
  };
};

const parseCouponsTop10RedemptionByStoreApiData = (resp) => {
  const { analyticsData } = resp;
  return {
    CouponsTop10RedemptionByStoreTable:
      analyticsData.top10CouponUsedByStoreTable,
  };
};

const getTop10SortedData = (sortData, sort, isAscending) => {
  const sortName = sort?.name;
  const dataIndex = Top10FieldEnum[sortName?.toLowerCase()]?.fieldIndex;
  let sortedData = [];

  if (
    sortName === Top10FieldEnum.rank.name ||
    sortName === Top10FieldEnum.number.name ||
    sortName === Top10FieldEnum.percentage.name
  ) {
    sortedData = sortData.sort((a, b) =>
      isAscending
        ? parseInt(a[dataIndex]) - parseInt(b[dataIndex])
        : parseInt(b[dataIndex]) - parseInt(a[dataIndex]),
    );
  } else if (sortName === Top10FieldEnum.name.name) {
    sortedData = sortData.sort((a, b) =>
      isAscending
        ? a[dataIndex].localeCompare(b[dataIndex])
        : b[dataIndex].localeCompare(a[dataIndex]),
    );
  }
  return sortedData;
};

const initialOverviewState = {
  customers: {},
  coupons: {},
  campaigns: {},
  campaignsRakingTable: {},
  campaignOverviewFilter: {},
  campaignOverviewDate: {},
  campaignOverviewCampaignsStatus: '',
  couponsOverviewDate: {},
  couponOverviewFilter: {},
  couponsRakingTable: {},
  couponOverviewCouponsStatus: '',
  CouponsTop10RedemptionByDistrictTable: [],
  CouponsTop10RedemptionByStoreTable: [],
  CouponsTop10RedemptionByDistrictTableNew: [],
  CouponsTop10RedemptionByStoreTableNew: [],
};

export default {
  namespace: 'overview',
  state: initialOverviewState,
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clearState(state, { payload }) {
      return initialOverviewState;
    },
    clearStateExcludeFilter(state, { payload }) {
      return {
        ...initialOverviewState,
        campaignOverviewFilter: state?.campaignOverviewFilter || {},
        campaignOverviewCampaignsStatus:
          state?.campaignOverviewCampaignsStatus || '',
        couponOverviewFilter: state?.couponOverviewFilter || {},
        couponOverviewCouponsStatus: state?.couponOverviewCouponsStatus || '',
        goodiebagOverviewFilter: state?.goodiebagOverviewFilter || {},
        goodiebagOverviewCouponsStatus:
          state?.goodiebagOverviewCouponsStatus || '',
      };
    },
  },
  effects: {
    getCampaignDetail: [
      function* ({ payload }, { select, put, all }) {
        const { startDate, endDate, key } = payload;
        if (!startDate || !endDate) {
          return;
        }
        const campaignOverviewConfig = {
          ...CAMPAIGN_OVERVIEW_DATA_CONFIG,
          ...CASH_CAMPAIGN_OVERVIEW_DATA_CONFIG,
          ...GIFT_CAMPAIGN_OVERVIEW_DATA_CONFIG,
          ...FORTUNEBAG_CAMPAIGN_OVERVIEW_DATA_CONFIG,
          ...CAMPAIGN_OVERVIEW_COUPON_DATA_CONFIG,
          ...GOODIEBAG_OVERVIEW_DATA,
        };

        const field = campaignOverviewConfig[key];
        const serviceArgs = [
          getCampaignsOverviewSingleData,
          startDate,
          endDate,
          field,
          {
            ...payload?.campaignOverviewFilter,
            ...payload?.goodiebagOverviewFilter,
            fromModel: payload?.fromModel,
          },
        ];
        function* onSuccess(data) {
          console.log('@132', key, data);
          const currentData = data.analyticsData?.[key];
          console.log('@201', currentData);
          yield put(createAction('updateState')({ [key]: currentData }));
        }
        function* onFailed(data) {
          console.log('@Get campaigns overview data failed: ', data);
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
    ],

    getCampaignsData: [
      function* ({ payload }, { put, all }) {
        let requestData = {};
        if (payload?.typeIn) {
          if (payload?.typeIn.includes(CampaignType.cashVoucherCampaign)) {
            requestData = {
              ...requestData,
              ...CASH_CAMPAIGN_OVERVIEW_DATA_CONFIG,
            };
          }
          if (payload?.typeIn.includes(CampaignType.giftCampaign)) {
            requestData = {
              ...requestData,
              ...GIFT_CAMPAIGN_OVERVIEW_DATA_CONFIG,
            };
          }
          if (
            payload?.typeIn.includes(
              CampaignType.fortuneBagForGoodieBagCampaign,
            )
          ) {
            requestData = {
              ...requestData,
              ...FORTUNEBAG_CAMPAIGN_OVERVIEW_DATA_CONFIG,
            };
          }
          if (payload?.typeIn.length === 0) {
            requestData = {
              ...requestData,
              ...CASH_CAMPAIGN_OVERVIEW_DATA_CONFIG,
              ...GIFT_CAMPAIGN_OVERVIEW_DATA_CONFIG,
              ...FORTUNEBAG_CAMPAIGN_OVERVIEW_DATA_CONFIG,
            };
          }
        } else {
          requestData = {
            ...CAMPAIGN_OVERVIEW_DATA_CONFIG,
            ...CAMPAIGN_OVERVIEW_COUPON_DATA_CONFIG,
            ...CASH_CAMPAIGN_OVERVIEW_DATA_CONFIG,
            ...GIFT_CAMPAIGN_OVERVIEW_DATA_CONFIG,
            ...FORTUNEBAG_CAMPAIGN_OVERVIEW_DATA_CONFIG,
          };
        }

        const requestList = Object.keys(requestData).map((item) => {
          return put({
            type: 'getCampaignDetail',
            payload: { ...payload, key: item },
          });
        });
        yield all(requestList);
      },
      { type: 'takeLatest' },
    ],

    getCampaignCouponDetail: [
      function* ({ payload }, { select, put, all }) {
        const { startDate, endDate, key } = payload;
        if (!startDate || !endDate) {
          return;
        }
        const campaignOverviewConfig = {
          ...CAMPAIGN_OVERVIEW_COUPON_DATA_CONFIG,
        };

        const field = campaignOverviewConfig[key];
        const serviceArgs = [
          getCampaignsOverviewSingleData,
          startDate,
          endDate,
          field,
          {
            ...payload?.campaignOverviewFilter,
            ...payload?.goodiebagOverviewFilter,
            fromModel: payload?.fromModel,
            campaginTypes: payload?.campaginTypes,
          },
        ];
        console.log(
          '@@265: ',
          field,
          payload.dataKey,
          payload,
          payload?.campaignOverviewFilter,
          payload?.goodiebagOverviewFilter,
          {
            ...payload?.campaignOverviewFilter,
            ...payload?.goodiebagOverviewFilter,
          },
        );
        function* onSuccess(data) {
          console.log('@132', field, data);
          let currentData = { [key]: data.analyticsData?.[key] };
          console.log('@201', currentData);

          const dataKey = payload.dataKey;
          if (dataKey) {
            const { source } = yield select((state) => ({
              source: state.overview[dataKey] || {},
            }));

            currentData = {
              [dataKey]: { ...source, [key]: data.analyticsData?.[key] },
            };
          }

          yield put(createAction('updateState')(currentData));
        }
        function* onFailed(data) {
          console.log('@Get campaigns overview data failed: ', data);
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
    ],

    getCashCampaignCouponData: [
      function* ({ payload }, { put, all }) {
        const requestList = Object.keys(
          CAMPAIGN_OVERVIEW_COUPON_DATA_CONFIG,
        ).map((item) => {
          return put({
            type: 'getCampaignCouponDetail',
            payload: {
              ...payload,
              campaginTypes: [CampaignType.cashVoucherCampaign],
              key: item,
              dataKey: 'cashCampaignCoupons',
            },
          });
        });
        yield all(requestList);
      },
      { type: 'takeLatest' },
    ],

    getGiftCampaignCouponData: [
      function* ({ payload }, { put, all }) {
        const requestList = Object.keys(
          CAMPAIGN_OVERVIEW_COUPON_DATA_CONFIG,
        ).map((item) => {
          return put({
            type: 'getCampaignCouponDetail',
            payload: {
              ...payload,
              campaginTypes: [CampaignType.giftCampaign],
              key: item,
              dataKey: 'giftCampaignCoupons',
            },
          });
        });
        yield all(requestList);
      },
      { type: 'takeLatest' },
    ],

    getFortunebagCampaignCouponData: [
      function* ({ payload }, { put, all }) {
        const requestList = Object.keys(
          CAMPAIGN_OVERVIEW_COUPON_DATA_CONFIG,
        ).map((item) => {
          return put({
            type: 'getCampaignCouponDetail',
            payload: {
              ...payload,
              campaginTypes: [CampaignType.fortuneBagForGoodieBagCampaign],
              key: item,
              dataKey: 'fortunebagCampaignCoupons',
            },
          });
        });
        yield all(requestList);
      },
      { type: 'takeLatest' },
    ],

    getCampaignsRankingTable: [
      function* ({ payload }, { put, select }) {
        const { key } = payload;
        let filterStartDate, filterEndDate;
        let overviewFilter = {};
        if (key) {
          filterStartDate = payload.startDate;
          filterEndDate = payload.endDate;
          if (!filterStartDate || !filterEndDate) {
            filterStartDate = payload.overviewDate?.startDate;
            filterEndDate = payload.overviewDate?.endDate;
          }

          overviewFilter = payload.goodiebagOverviewFilter;
        } else {
          const { startDate, endDate, brand, isStarred } = yield select(
            (state) => ({
              startDate: state.overview.campaignOverviewDate?.startDate,
              endDate: state.overview.campaignOverviewDate?.endDate,
              brand: state.overview.campaignOverviewFilter?.brand?.pk,
              isStarred: state.overview.campaignOverviewFilter?.isStarred,
            }),
          );

          filterStartDate = startDate;
          filterEndDate = endDate;
          overviewFilter.brand = brand;
          overviewFilter.isStarred = isStarred;
        }

        if (!filterStartDate || !filterEndDate) {
          return;
        }
        const serviceArgs = [
          getCampaignsOverviewRakingTable,
          filterStartDate,
          filterEndDate,
          overviewFilter,
          { ...payload },
        ];
        function* onSuccess(data) {
          const processedData = parseCampaignsRakingTableApiData(
            data,
            key || 'campaignsRakingTable',
          );
          console.log('@115', data, processedData, payload);
          yield put(createAction('updateState')({ ...processedData }));
        }
        function* onFailed(data) {
          console.log('@Get campaigns overview data failed: ', data);
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      // { type: 'takeLatest' },
    ],
    // getCustomersData: [
    //   function* ({ payload }, { put }) {
    //     const { startDate, endDate } = payload;
    //     if(!startDate || !endDate) {
    //       return;
    //     }
    //     const serviceArgs = [getCustomersOverviewData, startDate, endDate];
    //     function* onSuccess(data) {
    //       const processedData = parseCustomersApiData(data);
    //       yield put(createAction('updateState')({ customers: processedData }));
    //     }
    //     function* onFailed(data) {
    //       console.log('@Get customers overview data failed: ', data);
    //     }
    //     yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
    //   },
    //   { type: 'takeLatest' },
    // ],

    getCouponDetail: [
      function* ({ payload }, { select, put, all }) {
        const { startDate, endDate, key } = payload;
        if (!startDate || !endDate) {
          return;
        }
        const field = COUPON_OVERVIEW_DATA_CONFIG[key];
        const serviceArgs = [
          getCouponsOverviewSingleData,
          startDate,
          endDate,
          field,
          { ...payload },
        ];
        function* onSuccess(data) {
          console.log('@132', data);
          const currentData = data.analyticsData?.[key];
          console.log('@201', currentData);
          yield put(createAction('updateState')({ [key]: currentData }));
        }
        function* onFailed(data) {
          console.log('@Get coupons overview data failed: ', data);
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
    ],

    getCouponsData: [
      function* ({ payload }, { put, all }) {
        const requestList = Object.keys(COUPON_OVERVIEW_DATA_CONFIG).map(
          (item) => {
            return put({
              type: 'getCouponDetail',
              payload: { ...payload, key: item },
            });
          },
        );
        yield all(requestList);
      },
      { type: 'takeLatest' },
    ],

    getCouponsRakingTable: [
      function* ({ payload }, { put, select }) {
        const { startDate, endDate } = yield select((state) => ({
          startDate: state.overview.couponsOverviewDate?.startDate,
          endDate: state.overview.couponsOverviewDate?.endDate,
        }));
        if (!startDate || !endDate) {
          return;
        }
        const serviceArgs = [
          getCouponsOverviewRakingTable,
          startDate,
          endDate,
          { ...payload },
        ];
        function* onSuccess(data) {
          const processedData = parseCouponsRakingTableApiData(data);
          console.log('@115', data, processedData);
          yield put(createAction('updateState')({ ...processedData }));
        }
        function* onFailed(data) {
          console.log('@Get coupons overview raking table failed: ', data);
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    getCouponsTop10RedemptionByDistrict: [
      function* ({ payload }, { put, select }) {
        const { startDate, endDate } = yield select((state) => ({
          startDate: state.overview.couponsOverviewDate?.startDate,
          endDate: state.overview.couponsOverviewDate?.endDate,
        }));
        if (!startDate || !endDate) {
          return;
        }
        const serviceArgs = [
          getCouponsTop10RedemptionByDistrict,
          startDate,
          endDate,
          { ...payload },
        ];
        function* onSuccess(data) {
          const processedData =
            parseCouponsTop10RedemptionByDistrictApiData(data);
          console.log('@190', data, processedData);
          yield put(createAction('updateState')({ ...processedData }));
        }
        function* onFailed(data) {
          console.log(
            '@Get coupons overview top 10 redemption by district failed: ',
            data,
          );
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    getCouponsTop10RedemptionByStore: [
      function* ({ payload }, { put, select }) {
        const { startDate, endDate } = yield select((state) => ({
          startDate: state.overview.couponsOverviewDate?.startDate,
          endDate: state.overview.couponsOverviewDate?.endDate,
        }));
        if (!startDate || !endDate) {
          return;
        }
        const serviceArgs = [
          getCouponsTop10RedemptionByStore,
          startDate,
          endDate,
          { ...payload },
        ];
        function* onSuccess(data) {
          const processedData = parseCouponsTop10RedemptionByStoreApiData(data);
          console.log('@209', data, processedData);
          yield put(createAction('updateState')({ ...processedData }));
        }
        function* onFailed(data) {
          console.log(
            '@Get coupons overview top 10 redemption by store failed: ',
            data,
          );
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],

    getGoodieBagData: [
      function* ({ payload }, { put, all }) {
        const { startDate, endDate, key } = payload;
        if (!startDate || !endDate) {
          return;
        }

        const serviceArgs = [
          getGoodieBagOverviewSingleData,
          startDate,
          endDate,
          { ...payload },
        ];
        function* onSuccess(data) {
          console.log('@132', data);

          yield put({ type: 'updateState', payload: data.analyticsData });
        }
        function* onFailed(data) {
          console.log('@Get coupons overview data failed: ', data);
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    getGoodiebagFieldData: [
      function* ({ payload }, { put, all }) {
        const { startDate, endDate, key } = payload;
        if (!startDate || !endDate) {
          return;
        }

        const requestList = Object.keys(GOODIEBAG_OVERVIEW_DATA).map((item) => {
          return put({
            type: 'getCampaignDetail',
            payload: { ...payload, key: item },
          });
        });
        yield all(requestList);
      },
      { type: 'takeLatest' },
    ],
    getGoodiebagRankingTable: [
      function* ({ payload }, { put, select }) {
        let { startDate, endDate, goodiebagOverviewFilter } = payload;
        if (!startDate || !endDate) {
          startDate = payload.overviewDate?.startDate;
          endDate = payload.overviewDate?.endDate;
        }
        if (!startDate || !endDate) {
          return;
        }
        const serviceArgs = [
          getGoodieBagOverviewRankingTable,
          startDate,
          endDate,
          goodiebagOverviewFilter?.goodiebags,
          goodiebagOverviewFilter?.isStarred,
          goodiebagOverviewFilter?.cashcampaigns,
          goodiebagOverviewFilter?.giftcampaigns,
          { ...payload },
        ];
        function* onSuccess(data) {
          const { analyticsData } = data;
          const processedData = {
            goodiebagRakingTable:
              analyticsData.goodieBagAcquiredTableWithPagination,
          };
          console.log('@115', data, processedData);
          yield put(createAction('updateState')({ ...processedData }));
        }
        function* onFailed(data) {
          console.log('@Get campaigns overview data failed: ', data);
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],

    getCouponsTop10RedemptionByDistrictNew: [
      function* ({ payload }, { put }) {
        const { sortData, sort, isAscending } = payload;
        yield put(
          createAction('updateState')({
            CouponsTop10RedemptionByDistrictTableNew: getTop10SortedData(
              sortData,
              sort,
              isAscending,
            ),
          }),
        );
      },
      { type: 'takeLatest' },
    ],

    getCouponsTop10RedemptionByStoreNew: [
      function* ({ payload }, { put }) {
        const { sortData, sort, isAscending } = payload;
        yield put(
          createAction('updateState')({
            CouponsTop10RedemptionByStoreTableNew: getTop10SortedData(
              sortData,
              sort,
              isAscending,
            ),
          }),
        );
      },
      { type: 'takeLatest' },
    ],
  },
};
