import React, { useEffect } from 'react';
import CustomListComponent from '../../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import ContentSections from '../../../components/base/ContentSections';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { createAction } from '../../../utils';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import Loading from '../../../components/base/Loading';
import { connect } from 'react-redux';
import { LanguageConfig } from '../../../config/CustomEnums';

const GeneralAlertDetail = ({ detail, dispatch }) => {
  const queryString = require('query-string');
  const params = useParams();
  const history = useHistory();
  const { id } = params;

  const { languages } = useSelector((state) => ({
    languages: state.language.allList,
  }));

  useEffect(() => {
    dispatch(createAction('generalAlert/getOneDetail')({ id }));
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
    return () => {
      dispatch(createAction('generalAlert/clearData')());
    };
  }, [id]);

  const renderTabForLanguage = (language) => {
    const name =
      language === LanguageConfig.english
        ? detail.content
        : detail.translations?.[language]?.content;
    return (
      <MembershipInfoCard
        title="PROPERTIES"
        data={{ name, ...detail }}
        fields={[
          [
            {
              title: 'Content',
              field: 'name',
              isHtml: true,
            },
          ],
          [
            {
              title: 'Order',
              field: 'displayPriority',
            },
          ],
          [
            {
              title: 'Severity level',
              field: 'severityLevel',
            },
          ],
          [
            {
              title: 'Start time',
              field: 'deliveryStartDate',
            },
          ],
          [
            {
              title: 'End time',
              field: 'deliveryEndDate',
            },
          ],
          [
            {
              title: 'Scheduled Status',
              field: 'scheduledStatus',
            },
          ],
          [
            {
              title: 'Status',
              field: 'displayStatus',
            },
          ],
        ]}
      />
    );
  };

  const tabs = [
    {
      name: '',
      content: detail.pk ? (
        <div className="scroll-container-common">
          <ContentSections
            hidePreview
            languageTabContent={{
              containers: languages?.map((item) => ({
                container: [renderTabForLanguage(item.code)],
                key: item.code,
                title: item.sourceName,
              })),
            }}
            activeSection={LanguageConfig.english}
          />
        </div>
      ) : (
        <Loading />
      ),
    },
  ];

  const buttons = [
    <AuthButton
      title="Edit"
      action={() => {
        history.push({
          pathname: 'edit/',
        });
      }}
      requires={PermissionCodes.changeMessage}
    />,
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail: '', //view message
          title: detail?.title || 'unset',
          // titleIsHtml: true,
        }}
        breadcrumb={<CustomBreadcrumb name={detail?.title} />} //nameIsHtml
        hideTab={true}
        tabs={tabs}
        buttons={buttons}
      />
    </>
  );
};

const mapPropsToState = (state) => ({
  detail: state.generalAlert.detail,
});

export default connect(mapPropsToState)(GeneralAlertDetail);
