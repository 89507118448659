import React, { useEffect, useState } from 'react';
// import './MessageList.scss';
import queryString from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import CustomListComponent from '../../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import { createAction } from '../../../utils';
import DeletePrompt from '../../../components/base/DeletePrompt';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import arrayMove from 'array-move';
// import { DELETE_RELATED_SECTIONS } from '../../../config/CustomEnums';

const AttributeTagList = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [sortDisabled, setSortDisabled] = React.useState(false);

  const {
    pageInfo,
    totalCount,
    tagList,
    totalPage,
    checkedList,
    listDisplayFields,
  } = useSelector((state) => ({
    listDisplayFields: state.attributeTagKey.listDisplayFields,
    pageInfo: state.attributeTagKey.pageInfo,
    totalCount: state.attributeTagKey.totalCount,
    tagList: state.attributeTagKey.pagedList,
    totalPage: state.attributeTagKey.totalPage,
    checkedList: state.attributeTagKey.checkedList,
  }));

  useEffect(() => {
    const searchDict = queryString.parse(location.search);
    const searchKey = searchDict['search'];
    const stringRank = searchDict['rank'] || 'true';
    const stringPage = searchDict['page'] || 1;
    const rank = stringRank === 'true';
    const sort = searchDict['sort'] || 'displayPriority';
    const page = parseInt(stringPage);
    dispatch(
      createAction('attributeTagKey/getList')({
        ...searchDict,
        page,
        rank,
        filterName: searchKey,
        sort,
      }),
    );
  }, [dispatch, location]);

  const buttons = [
    <AuthButton
      title="Create Tag"
      action={() => {
        history.push('/tags/create');
      }}
      requires={PermissionCodes.changeTagKey}
    />,
  ];

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.changeTagKey,
    },
  ];

  const doChanges = (data) => {
    dispatch({
      type: 'attributeTagKey/updateOrder',
      payload: { data, afterAction: () => history.push(location) },
    });
  };

  const tabs = [
    {
      name: '',
      content: (
        <BaseTabListContainer
          hideTab={true}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount ? totalCount : 0}
          listContent={
            <BaseListContainer
              fields={listDisplayFields}
              model={'attributeTagKey'}
              permissionGroup={PermissionCodes.tagKey}
              dataList={tagList}
              totalPage={totalPage}
              deleteInfo={{
                data: [],
                title: 'Tag',
                onComfirm: {},
              }}
              actions={['Edit', 'Detail', 'Delete']}
              sortable
              sortDisabled={sortDisabled}
              rankDisabled
              onSortEnd={(oldIndex, newIndex) => {
                if (oldIndex === newIndex) return;
                const startOrder = tagList[0].order;
                const movedItem = tagList[oldIndex];
                dispatch(
                  createAction('attributeTagKey/updateOrder')({
                    data: {
                      id: movedItem.pk,
                      displayPriority: startOrder + newIndex,
                      name: movedItem.name,
                    },
                    afterAction: () => history.push(location),
                  }),
                );
              }}
              onOrderChangedByEdit={(pk, newOrder, item) => {
                dispatch(
                  createAction('attributeTagKey/updateOrder')({
                    data: {
                      id: pk,
                      displayPriority: newOrder,
                      name: item.name,
                    },
                    afterAction: () => history.push(location),
                  }),
                );
              }}
              hasOtherActions
              customActions={{
                moveToFirst: (item) => {
                  doChanges({
                    id: item.pk,
                    displayPriority: 1,
                    name: item.name,
                  });
                },
                moveToLast: (item) => {
                  doChanges({
                    id: item.pk,
                    displayPriority: totalCount,
                    name: item.name,
                  });
                },
              }}
            />
          }
        />
      ),
    },
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail: 'TBC',
          title: 'Tag',
        }}
        buttons={buttons}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={true}
        tabs={tabs}
      />
      <DeletePrompt
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch(
            createAction('attributeTagKey/delete')({
              afterAction: () => history.push(location),
            }),
          );
        }}
        data={checkedList}
        title={'Tag'}
      />
    </>
  );
};

export default AttributeTagList;
