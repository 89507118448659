import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { createAction } from '../../../utils';
import ContentSections from '../../../components/base/ContentSections';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import ChangePasswordPrompt from '../../../components/admin/ChangePasswordPrompt';
import { ADMIN_TYPE, AdminLoginMethod } from '../../../config/CustomEnums';
import { CustomTitleLabel } from '../../../components/earning/CustomBaseComponments';
import './AdminDetail.scss';
import Loading from '../../../components/base/Loading';

function AdminDetail() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const [showChangePassPrompt, setShowChangePassPrompt] = useState(false);

  const { admin, user } = useSelector((state) => ({
    admin: state.admin.oneAdmin || {},
    user: state.users,
  }));
  const isNotMerchant = admin.adminType !== ADMIN_TYPE.MERCHANT_ADMIN;
  const personalInfoFields = [
    [{ title: 'Email address', field: 'email' }],
    [{ title: 'Phone number', field: 'mobile' }],
  ];
  const stausFields = [[{ title: 'Active', field: 'displayActive' }]];
  if (isNotMerchant) {
    personalInfoFields.unshift(
      [{ title: 'First name', field: 'firstName' }],
      [{ title: 'Last name', field: 'lastName' }],
    );
    stausFields.push([{ title: 'Superadmin status', field: 'displayIsSuper' }]);
  }

  const passwordArea = (
    <>
      <div>
        <CustomTitleLabel title="Password" />
        {user.adminType === ADMIN_TYPE.MERCHANT_ADMIN &&
        user?.username !== admin?.username ? null : (
          <button
            className="change-password"
            onClick={() => setShowChangePassPrompt(true)}
          >
            Change
          </button>
        )}
      </div>
      <label className="tips-message">{'*'.repeat(10)}</label>
    </>
  );

  const getSections = () => {
    let sections = [
      <>
        <MembershipInfoCard
          title={'General'}
          data={admin}
          fields={[
            [{ title: 'Admin type', field: 'displayAdminType' }],
            [{ title: 'Name', field: 'username' }],
            [{ title: 'Related brand', field: 'displayBrand' }],
            [{ title: 'Related store', field: 'displayStore' }],
          ]}
        />
        {admin.adminLoginMethod !== AdminLoginMethod.Saml ? passwordArea : null}
      </>,
      <MembershipInfoCard
        title={'Personal Information'}
        data={admin}
        fields={personalInfoFields}
      />,
      <MembershipInfoCard title={'Status'} data={admin} fields={stausFields} />,
      <MembershipInfoCard
        title={'Important dates'}
        data={admin}
        fields={[
          [{ title: 'Last login', field: 'displayLastLogin' }],
          [{ title: 'Date joined', field: 'displayDateJoined' }],
        ]}
      />,
    ];

    if (!admin.isSuperuser) {
      sections.splice(
        3,
        0,
        <MembershipInfoCard
          title={'Permission'}
          data={admin}
          fields={[
            [{ title: 'Working Team', field: 'displayWorkingTeams' }],
            [{ title: 'Administrator groups', field: 'displayGroups' }],
            [
              {
                title: 'Administrator permission',
                field: 'displayPermissions',
              },
            ],
          ]}
        />,
      );
    }

    return sections;
  };

  const tabs = [
    {
      name: 'Detail',
      content: (
        <div className="scroll-container-common">
          <ContentSections
            sections={getSections()}
            hidePreview={true}
            // sectionStyle="detail-info-section-item"
          />
        </div>
      ),
    },
  ];
  const buttons = [
    <AuthButton
      title={'Edit'}
      action={() => {
        dispatch({ type: 'admin/clearData' });
        history.push({
          pathname: 'edit/',
        });
      }}
      requires={PermissionCodes.changeAdministrator}
    />,
  ];

  useEffect(() => {
    dispatch({ type: 'admin/clearData' });
  }, [dispatch]);

  useEffect(() => {
    const id = params.id;
    dispatch(createAction('admin/getOneAdmin')({ id, type: 'detail' }));
  }, [dispatch, params]);

  if (params.id && !admin?.pk) {
    return <Loading customClass={'common-full-height'} />;
  }

  return (
    <div className="customer">
      <CustomListComponent
        caution={{
          detail: '',
          title: admin.name,
        }}
        defaultActiveKey={tabs[0].name}
        breadcrumb={<CustomBreadcrumb name={admin.name} />}
        buttons={buttons}
        tabs={tabs}
      />
      <ChangePasswordPrompt
        admin={admin}
        show={showChangePassPrompt}
        onClose={() => setShowChangePassPrompt(false)}
        onConfirm={() => setShowChangePassPrompt(false)}
      />
    </div>
  );
}

export default AdminDetail;
