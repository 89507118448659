import React, { useState, useEffect } from 'react';
import './CustomAgeCard.scss';
import icon from '../../assets/images/customer_overview_vector.svg';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Loading from '../base/Loading';
import AnimatedNumber from 'animated-number-react';
import { formatDate, TimeFormater } from '../../utils/TimeFormatUtil';

const CardItem = ({ data }) => {
  const [cardHeight, setCardHeight] = useState(0);
  const progressCount = 197 * data.percentage;
  let progress = progressCount;
  if (progressCount && progressCount < 33) {
    progress = 33;
  }
  useEffect(() => {
    setTimeout(() => {
      setCardHeight(progress);
    }, 500);
  }, []);
  return (
    <div className="age-chart-card-container-item" key={data.period}>
      <label style={{ marginBottom: ' 8px' }}>{data.point || 0}</label>
      <div className="age-chart-card-container-progress">
        <div style={{ height: `${cardHeight}px` }}>
          <AnimatedNumber
            duration={500}
            value={(data.percentage * 100).toFixed(1) || 0}
            formatValue={(value) => `${value}%`}
          />
        </div>
      </div>
      <label>{data.period}</label>
    </div>
  );
};

const agePerids = [
  '0~14',
  '15～24',
  '25～34',
  '35～44',
  '45～54',
  '55～64',
  '65～74',
  '75+',
];

const DefaultAge = agePerids.map((item) => ({
  period: item,
  percentage: 0,
  point: 0,
}));

function CardBody({ chartData, customerLoading }) {
  console.log('CustomAgeCard chartData:', chartData);
  if (customerLoading) {
    return (
      <div className="age-chart-card-container">
        <Loading />
      </div>
    );
  }
  return (
    <div className="age-chart-card-container">
      {(chartData || DefaultAge).map((chart, index) => {
        return <CardItem data={chart} index={index} />;
      })}
    </div>
  );
}

function DataCount({ pre, suffix }) {
  return (
    <label className="histogram-zoomable-card-percentage">
      {pre + ' '}
      <label className="histogram-zoomable-card-count">{suffix}</label>
    </label>
  );
}

function BottomLinkArea({ totalNew, perferenceTotal }) {
  return (
    <div className="histogram-zoomable-card-bottom-container">
      <DataCount pre={'Data collected'} suffix={perferenceTotal || 0} />
      <label
        className="histogram-zoomable-card-percentage"
        style={{ marginLeft: '10px', marginRight: '10px' }}
      >
        |
      </label>
      <DataCount pre={'Undefined'} suffix={totalNew - perferenceTotal || 0} />
      <Link
        className="dashboard-overview-link"
        to={{ pathname: '/dashboard_customer' }}
      >
        {/* Customer Overview */}
        See detail
        <img className="dashboard-overview-link-icon" src={icon} alt="" />
      </Link>
    </div>
  );
}

function CustomAgeCard({ data, total, totalNew, customerLoading, endDate }) {
  return (
    <div className="age-chart-card">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <label className="age-chart-title">Age</label>
        {endDate ? (
          <label className="dashboard-coupon-item-label-caution">{`as of ${formatDate(
            endDate,
            TimeFormater.dayMonthYearTimePeriod,
          )}`}</label>
        ) : null}
      </div>
      <CardBody chartData={data} customerLoading={customerLoading} />
      <BottomLinkArea totalNew={totalNew.value} perferenceTotal={total.value} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  totalNew: state.dashboardCustomer.customers.totalNumberOfCustomers || {},
  data: state.dashboardCustomer.customers.ageChart,
  total: state.dashboardCustomer.customers.totalNumberOfCustomersWithAge || {},
  customerLoading: state.dashboardCustomer.customerLoading,
  endDate: state.dashboard.endDate,
});

export default connect(mapStateToProps)(CustomAgeCard);
