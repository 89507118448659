import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import HistogramZoomableCard from './HistogramZoomableCard';

export function CategoryCard() {
  const { data, loading } = useSelector((state) => ({
    data: state.dashboardCustomer.categoryChart,
    loading: state.dashboardCustomer.customerLoading,
  }));

  return (
    <HistogramZoomableCard
      loading={loading}
      data={data}
      title="Preference - Campaign category"
      starredFilter={{
        showStarredFilter: true,
        model: 'campaginCategoryFilter',
      }}
    />
  );
}

export function AttributeCard() {
  const { data, loading } = useSelector((state) => ({
    data: state.dashboardCustomer.attributeTagOfTimesCouponAcquiredChart.data,
    loading:
      state.dashboardCustomer.attributeTagOfTimesCouponAcquiredChart.loading,
  }));
  console.log('loading:', loading);
  const dispatch = useDispatch();
  useEffect(() => {
    
  }, []);
  return (
    <HistogramZoomableCard
      showFilter
      loading={loading}
      data={data}
      title="Preference - Attribute Tags"
      starredFilter={{
        showStarredFilter: true,
        model: 'campaginAttributeTagsFilter',
      }}
    />
  );
}

export function InterestCard() {
  const { data, loading } = useSelector((state) => ({
    data: state.dashboardCustomer.customers.interestChart,
    loading: state.dashboardCustomer.customerLoading,
  }));
  return (
    <HistogramZoomableCard
      isInterest
      loading={loading}
      data={data}
      title="Preference - Interest preference"
    />
  );
}
