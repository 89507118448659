import { BaseHelper } from './BaseGraphQLAPIHelper';

export const getHomeBanners = (afterCursor, allSearch) => {
  let orderBy = 'displayPriority';
  let filter = '';
  if (allSearch?.sort) {
    orderBy = allSearch?.sort;
  }
  if (allSearch?.search) {
    filter += `, search: "${allSearch?.search}"`;
  }

  if (allSearch?.banner_type) {
    filter += `, bannerTypeIn: "${allSearch?.banner_type}"`;
  }

  if (allSearch?.platform) {
    filter += `, bannerLocationIn: "${allSearch?.platform}"`;
  }

  if (allSearch?.landing_page) {
    filter += `, landingPageTypeIn: "${allSearch?.landing_page}"`;
  }

  if (allSearch?.status) {
    filter += `, isPublished: ${allSearch?.status}`;
  }

  if (allSearch?.target_customers) {
    filter += `, targetCustomersIn: "${allSearch?.target_customers}"`;
  }

  if (allSearch?.country_codes) {
    filter += `, countryCodesIn: "${allSearch?.country_codes}"`;
  }

  console.log('@@28: ', allSearch, filter);
  const query = `{
    homepageBanners(first: 20, after: "${afterCursor}", orderBy: "${orderBy}" ${filter}) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            pk
            landingPageType
            manualInputUrl
            coverPhoto
            displayPriority
            isPublished
            mobilePhoto
            bannerType
            wechatLandingPageUrlType
            wechatUrl
            wechatAppId
            wechatPath
            bannerLocation
            targetCustomers
            selectCountryCodeType
            countryCodesList {
              pk
              name
            }
            translations {
              edges {
              node {
                  pk
                  id
                  language
                  landingPageType
                  manualInputUrl
                  coverPhoto
                  mobilePhoto
                  wechatLandingPageUrlType
                  wechatUrl
                  wechatAppId
                  wechatPath
              }
              }
          }
          }
        }
      }
      }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateHomeBanners = (values) => {
  const query = `mutation UpdateHomepageBanner($input: UpdateHomepageBannerInput!) {
        updateHomepageBanner(input: $input) {
          success
          node {
              id
              pk
          }
          errors {
            field
            messages
          }
        }
      }
      `;
  const variables = { input: values };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createHomeBanners = (values) => {
  const query = `mutation CreateHomepageBanner($input: CreateHomepageBannerInput!) {
        createHomepageBanner(input: $input) {
            success
            node {
                id
                pk
            }
            errors {
              field
              messages
            }
          }
        }
        `;
  const variables = { input: values };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getOneHomeBanner = (id) => {
  const query = `{
        homepageBanner(id: "${id}") {
          id
          pk
          landingPageType
          manualInputUrl
          coverPhoto
          mobilePhoto
          bannerType
          wechatLandingPageUrlType
          wechatUrl
          wechatAppId
          wechatPath
          bannerLocation
          targetCustomers
          selectCountryCodeType
          countryCodesList {
            pk
            name
          }
          displayPriority
          isPublished
          translations {
              edges {
              node {
                  pk
                  id
                  language
                  landingPageType
                  manualInputUrl
                  coverPhoto
                  mobilePhoto
                  wechatLandingPageUrlType
                  wechatUrl
                  wechatAppId
                  wechatPath
              }
              }
          }
        }
      }
    `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteHomeBanners = (ids) => {
  const query = `mutation DeleteHomepageBanners($input: DeleteHomepageBannersInput!) {
        deleteHomepageBanners(input: $input) {
          success
        }
      }
    `;
  const variables = { input: { ids: ids } };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
