import { PaymentSettlementType } from '../config/CustomEnums';
import {
  getPaymentSettlementList,
  getPaymentSettlement,
  getPaymentSettlementReportListByName,
  getPaymentSettlementReportListByMerchant,
  updatePaymentSettlementStatus,
} from '../services/PaymentSettlementHelper';
import { createModel } from './BaseModel';
import { convertNumberToCursor } from '../utils';
import {
  TimeFormater,
  formatDate,
  getCampignListDisplayTime,
} from '../utils/TimeFormatUtil';
import { apiWithResponseHandle } from './LoadingUtil';

const review_status = (status) => {
  const status_dictory = {
    WAITING_FOR_REVIEW: 'Waiting for review',
    MERCHANT_DISAGREED: 'Merchant disagreed',
    MERCHANT_AGREED: 'Merchant agreed',
  };

  return status_dictory[status];
};

const parseCampaigns = (jsonString) => {
  const campaign = JSON.parse(jsonString);
  const parseCampaign = campaign?.map((item) => ({
    id: item.id,
    name: item.name,
    active_period: item.end_date_str
      ? getCampignListDisplayTime(item.start_date_str, item.end_date_str)
      : 'All time',
    visible_period: item.display_end_date_str
      ? getCampignListDisplayTime(
          item.display_start_date_str,
          item.display_end_date_str,
        )
      : 'All time',
  }));
  return parseCampaign;
};

const getInitialState = () => ({
  listDisplayFieldsByMonthly: [
    { displayName: 'ID', fieldName: 'pk', orderField: 'pk' },
    {
      displayName: 'Name',
      fieldName: 'reportName',
    },
  ],
  listDisplayFieldsByMerchant: [
    { displayName: 'ID', fieldName: 'pk', orderField: 'pk' },
    {
      displayName: 'Name',
      fieldName: 'merchantAdministrators',
    },
  ],
  totalCount: 0,
  totalPage: 0,
  pageInfo: {
    startCursor: 0,
    endCursor: 0,
  },
  checkedList: [],
});

const parsePaymentSettlementList = (list) => {
  return list.map((paymentAndSettlementReport) => {
    const node = paymentAndSettlementReport.node;
    if (node)
      return {
        id: node.id,
        pk: node.pk,
        reportName: node.name,
        creationDate: node.creationDate,
        creationDate_format: formatDate(
          node.creationDate,
          TimeFormater.dayMonthWeek,
        ),
        lastModifiedDate: node.lastModifiedDate,
        lastModifiedDate_format: formatDate(
          node.lastModifiedDate,
          TimeFormater.dayMonthWeek,
        ),
        month: node.month,
        merchantAdministrators: node.merchant?.name,
        reviewer: node.reviewer?.username,
        reviewStatus: node.reviewStatus,
        displayStatus: review_status(node.reviewStatus),
        brand: node.merchant?.pk,
        brandName: node.merchant?.name,
        totalNumberOfCouponAcquiredByUser:
          node.totalNumberOfCouponAcquiredByUser,
        totalNumberOfCouponUsedByUser: node.totalNumberOfCouponUsedByUser,
        paymentSettlementComment: node.reportComments?.edges?.map(
          (item) => item?.node?.remark,
        ),
      };

    return {};
  });
};
const parseDetail = (node) => {
  return {
    id: node.id,
    pk: node.pk,
    reportName: node.name,
    creationDate: node.creationDate,
    creationDate_format: formatDate(
      node.creationDate,
      TimeFormater.dayMonthWeek,
    ),
    lastModifiedDate: node.lastModifiedDate,
    lastModifiedDate_format: formatDate(
      node.lastModifiedDate,
      TimeFormater.dayMonthWeek,
    ),
    month: node.month,
    merchantAdministrators: node.merchant.name,
    reviewer: node.reviewer?.username,
    reviewStatus: node.reviewStatus,
    displayStatus: review_status(node.reviewStatus),
    brand: node.merchant.pk,
    brandName: node.merchant.name,
    totalNumberOfCouponAcquiredByUser: node.totalNumberOfCouponAcquiredByUser,
    totalNumberOfCouponUsedByUser: node.totalNumberOfCouponUsedByUser,
    totalHktbGivesToMerchant: node.totalHktbGivesToMerchant,
    totalMerchantGivesToHktb: node.totalMerchantGivesToHktb,
    campaigns: parseCampaigns(node.campaigns),
    coupons: node.reportCouponTemplates?.edges?.map((item) => item?.node),
    comment: node.reportComments?.edges?.map((item) => item?.node),
  };
};

export default createModel({
  namespace: 'paymentSettlementList',
  states: getInitialState(),
  reducers: {},
  params: {
    dataKey: 'paymentAndSettlementReports',
    pkNode: 'PaymentAndSettlementReportNode',
    listAPI: getPaymentSettlementList,
    parse: (data) =>
      parsePaymentSettlementList(data?.paymentAndSettlementReports.edges),
    detailAPI: getPaymentSettlement,
    parseDetail: (data) => parseDetail(data.paymentAndSettlementReport),
  },
  effects: {
    getListByName: [
      function* ({ payload }, { call, select, put }) {
        const page = payload.page || 1;
        const pageCursor = convertNumberToCursor((page - 1) * 20 - 1);
        const serviceArgs = [
          getPaymentSettlementReportListByName,
          pageCursor,
          payload,
        ];
        yield put({
          type: 'updateState',
          payload: {
            ...payload,
            listDisplayFields: [
              { displayName: 'ID', fieldName: 'pk', orderField: 'pk' },
              {
                displayName: 'Merchant name',
                fieldName: 'merchantAdministrators',
                list: false,
              },
              {
                displayName: 'Merchant ID',
                fieldName: 'brand',
              },
              {
                displayName: 'Report issue date',
                fieldName: 'creationDate_format',
              },
              {
                displayName: 'Last update date',
                fieldName: 'lastModifiedDate_format',
              },
              { displayName: 'Review status', fieldName: 'displayStatus' },
            ],
          },
        });
        function* onSuccess(data) {
          yield put({
            type: 'updateListData',
            payload: {
              ...payload,
              data: parsePaymentSettlementList(
                data?.paymentAndSettlementReports.edges,
              ),
              totalCount: data['paymentAndSettlementReports'].totalCount,
              pageInfo: data['paymentAndSettlementReports'].pageInfo,
            },
          });
        }
        function* onError(err) {
          console.log('getListByName onError :', err);
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError);
      },
      { type: 'takeLatest' },
    ],

    getListByMerchant: [
      function* ({ payload }, { call, select, put }) {
        const page = payload.page || 1;
        const pageCursor = convertNumberToCursor((page - 1) * 20 - 1);
        const serviceArgs = [
          getPaymentSettlementReportListByMerchant,
          pageCursor,
          payload,
        ];
        yield put({
          type: 'updateState',
          payload: {
            ...payload,
            listDisplayFields: [
              { displayName: 'ID', fieldName: 'pk', orderField: 'pk' },
              {
                displayName: 'Report name',
                fieldName: 'reportName',
                list: false,
              },
              {
                displayName: 'Report issue date',
                fieldName: 'creationDate_format',
              },
              {
                displayName: 'Last update date',
                fieldName: 'lastModifiedDate_format',
              },
              { displayName: 'Review status', fieldName: 'displayStatus' },
            ],
            listDisplayFieldsWithCouponData: [
              { displayName: 'ID', fieldName: 'pk', orderField: 'pk' },
              {
                displayName: 'Report name',
                fieldName: 'reportName',
                list: false,
              },
              {
                displayName: 'Total number of coupon acquired',
                fieldName: 'totalNumberOfCouponAcquiredByUser',
              },
              {
                displayName: 'Total number of coupon used',
                fieldName: 'totalNumberOfCouponUsedByUser',
              },
              {
                displayName: 'Last update date',
                fieldName: 'lastModifiedDate_format',
              },
              { displayName: 'Review status', fieldName: 'displayStatus' },
            ],
          },
        });
        function* onSuccess(data) {
          yield put({
            type: 'updateListData',
            payload: {
              ...payload,
              data: parsePaymentSettlementList(
                data?.paymentAndSettlementReports.edges,
              ),
              totalCount: data['paymentAndSettlementReports'].totalCount,
              pageInfo: data['paymentAndSettlementReports'].pageInfo,
            },
          });
        }
        function* onError(err) {
          console.log('getListByName onError :', err);
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError);
      },
      { type: 'takeLatest' },
    ],

    updatePaymentSettlementReportStatus: [
      function* ({ payload }, { all, select, put }) {
        const afterAction = payload.afterAction || (() => {});
        const id = payload.id;
        const reviewStatus = payload.reviewStatus;
        let input = { id: id, reviewStatus: reviewStatus };
        if (payload?.remark) {
          input.remark = payload.remark;
        }
        if (payload?.amendmentNumber) {
          input.amendmentNumber = payload.amendmentNumber;
        }
        const serviceArgs = [updatePaymentSettlementStatus, input];

        function* onSuccess(data) {
          yield put({
            type: 'updateState',
            payload: {},
          });
          afterAction();
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
});
