import React, { useState } from 'react';
import ContentSections from '../base/ContentSections';
import { OnlyContinueButton } from '../base/BottomStepComponent';
import { useDispatch, useSelector } from 'react-redux';
import { createAction, checkHasPermission } from '../../utils';
import './CreatePointRecordStepOne.scss';
import AddPointIcon from '../../assets/images/pointRecord/addPoint.svg';
import RemovePointIcon from '../../assets/images/pointRecord/removePoint.svg';
import { PermissionCodes } from '../../config/PermissionCodes';
import ItemTypeSet, { MIN_WIDTH, SPACE_BETWEEN } from '../base/ItemTypeSet';

export const POINT_RECORD_TYPE = {
  TYPE_ADD: 'Add point',
  TYPE_REMOVE: 'Remove point',
};
const POINT_TYPE_CONFIG = [
  {
    id: POINT_RECORD_TYPE.TYPE_ADD,
    title: 'Add Point',
    image: AddPointIcon,
    description: 'Points will be given to customers',
  },
  {
    id: POINT_RECORD_TYPE.TYPE_REMOVE,
    title: 'Remove Point',
    image: RemovePointIcon,
    description: 'Deduct points from customers',
  },
];

function CreatePointRecordStepOne() {
  const dispatch = useDispatch();
  const { defaultSelectedType, user } = useSelector((state) => ({
    defaultSelectedType: state.createPointRecord.pointRecord.pointType,
    user: state.users,
  }));
  const [selectedType, setSelectedType] = useState(defaultSelectedType);

  const sections = [
    <div className="create-point-step-one-title">
      <label className="step-one-title">Point Type</label>
      <div className="point-record-step-type-area">
        {POINT_TYPE_CONFIG.map((item) => {
          return (
            <ItemTypeSet
              key={item.id}
              moreThanThree={false}
              item={item}
              selected={selectedType}
              onClick={(id) => {
                setSelectedType(id);
                dispatch(
                  createAction('createPointRecord/updateState')({
                    selectedType: id,
                  }),
                );
                dispatch(
                  createAction('createPointRecord/updatePointRecordState')({
                    pointType: id,
                  }),
                );
              }}
              selectedType={selectedType}
            />
          );
        })}
      </div>
    </div>,
  ];
  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <OnlyContinueButton
        continueAction={() => {
          dispatch(createAction('createPointRecord/stepChange')({ step: 0 }));
        }}
        disabledContinue={!selectedType}
      />
    </>
  );
}

export default CreatePointRecordStepOne;
