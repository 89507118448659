import React, { useState } from 'react';
import './CreateCouponStepThree.scss';
import CouponDateTimeSelector from '../../../components/coupon/couponCreation/CouponDateTimeSelector';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import { connect, useDispatch } from 'react-redux';
import { checkUrlIsValid, createAction, ERROR_MESSAGE } from '../../../utils';
import { CouponErrorFields } from '../../../components/coupon/couponCreation/CouponHandleError';
import {
  APIStatus,
  COUPON_CODE_TYPE,
  COUPON_FORMATS,
  COUPON_LOW_STOCK,
  COUPON_REDEMPTION_METHOD,
  COUPON_SET_TYPES,
} from '../../../config/CustomEnums';
import Loading from '../../../components/base/Loading';
import {
  checkValidTimePeriods,
  DISCOUNT_CODE_FORMAT,
  saveCouponToSessionStorage,
  VALID_PERIOD_TYPE,
} from '../../../models/CreateCouponModel';
import { useFormContext } from 'react-hook-form';
import {
  hasError,
  ReactHookFormErrorMessage,
} from '../../../components/base/ErrorFieldMessage';
import { useHistory } from 'react-router';
import BasePrompt from '../../../components/base/prompt/BasePrompt';
import CreateCouponStepThreeGeneral from '../../../components/coupon/couponCreation/CreateCouponStepThreeGeneral';

const AlertType = { temp: 'temp', generator: 'generator', none: 'none' };

const CampaignPopList = ({ campaignList }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '200px',
        overflowY: 'scroll',
      }}
    >
      {campaignList.map((campaign) => (
        <label className="create-coupon-step-three-campaign-alert">
          • [ID:{campaign.pk}] {campaign.name}
        </label>
      ))}
    </div>
  );
};

const validate = async (getValues, setError, clearErrors, trigger) => {
  let dateTimeError = false;
  const [
    validPeriodType,
    numberOfDaysToExpireAfterAcquisition,
    absoluteEffectiveDate,
    absoluteExpiryDate,
    couponSetType,
    formats,
    couponCodeType,
    genericCode,
    requireTransactionAmountSurvey,
    transactionAmountSurvey,
    settlementValueForHktb,
    settlementValueForMerchant,
    discountCodeFormat,
    redemptionMethod,
    showLowStockLabel,
    lowStockMethod,
    lowStockValue,
    barcodeFormat,
    hasSpecialDays,
    weeklyRules,
    dateRules,
    excludeDateRules,
    relativeSetDeadline,
    relativeDeadline,
  ] = getValues([
    'validPeriodType',
    'numberOfDaysToExpireAfterAcquisition',
    'absoluteEffectiveDate',
    'absoluteExpiryDate',
    'couponSetType',
    'formats',
    'couponCodeType',
    'genericCode',
    'requireTransactionAmountSurvey',
    'transactionAmountSurvey',
    'settlementValueForHktb',
    'settlementValueForMerchant',
    'discountCodeFormat',
    'redemptionMethod',
    'showLowStockLabel',
    'lowStockMethod',
    'lowStockValue',
    'barcodeFormat',
    'hasSpecialDays',
    'weeklyRules',
    'dateRules',
    'excludeDateRules',
    'relativeSetDeadline',
    'relativeDeadline',
  ]);

  let formatError = false;
  if (
    ((redemptionMethod !==
      COUPON_REDEMPTION_METHOD.USERS_COPY_THE_DISCOUNT_CODE &&
      formats === COUPON_FORMATS.COUPON_BAR_CODE) ||
      (redemptionMethod ===
        COUPON_REDEMPTION_METHOD.USERS_COPY_THE_DISCOUNT_CODE &&
        discountCodeFormat === DISCOUNT_CODE_FORMAT.BARCODE)) &&
    !barcodeFormat
  ) {
    setError(
      'barcodeFormat',
      {
        type: 'required',
        message: CouponErrorFields.barcodeFormat.message,
      },
      { shouldFocus: true },
    );
    formatError = true;
  }

  if (
    requireTransactionAmountSurvey &&
    (transactionAmountSurvey === null || transactionAmountSurvey === undefined)
  ) {
    setError(
      'transactionAmountSurvey',
      {
        type: 'required',
        message: CouponErrorFields.transactionAmountSurvey.message,
      },
      { shouldFocus: true },
    );
    formatError = true;
  } else {
    clearErrors('transactionAmountSurvey');
  }
  if (validPeriodType === VALID_PERIOD_TYPE.ABSOLUTE) {
    clearErrors('numberOfDaysToExpireAfterAcquisition');

    if (absoluteExpiryDate < absoluteEffectiveDate) {
      setError(
        'absoluteExpiryDate',
        {
          type: 'toSmall',
          message: CouponErrorFields.endDatetime.message,
        },
        { shouldFocus: true },
      );
    } else {
      clearErrors('absoluteExpiryDate');
    }
  }
  if (validPeriodType === VALID_PERIOD_TYPE.RELATIVE) {
    clearErrors('relativeDeadline');
    if ((numberOfDaysToExpireAfterAcquisition || '').length === 0) {
      setError(
        'numberOfDaysToExpireAfterAcquisition',
        {
          type: 'required',
          message: CouponErrorFields.relativeDay.message,
        },
        { shouldFocus: true },
      );
      dateTimeError = true;
    } else {
      clearErrors('numberOfDaysToExpireAfterAcquisition');
    }
  }

  if (hasSpecialDays) {
    if (
      !weeklyRules?.length &&
      !dateRules?.length &&
      !excludeDateRules?.length
    ) {
      setError(
        `nonRules`,
        {
          type: 'toSmall',
          message: CouponErrorFields.nonRules.message,
        },
        { shouldFocus: true },
      );
      formatError = true;
    }

    if (validPeriodType !== VALID_PERIOD_TYPE.ALL_TIME) {
      const rules = { dateRules, excludeDateRules };
      Object.keys(rules).forEach((key) => {
        (rules[key] || []).forEach((item, index) => {
          clearErrors(`${key}-${index}`);
          if (
            !checkValidTimePeriods(item, [
              validPeriodType === VALID_PERIOD_TYPE.RELATIVE
                ? relativeSetDeadline
                : absoluteEffectiveDate,
              validPeriodType === VALID_PERIOD_TYPE.RELATIVE
                ? relativeDeadline
                : absoluteExpiryDate,
            ])
          ) {
            setError(
              `${key}-${index}`,
              {
                type: 'toSmall',
                message: CouponErrorFields.dateRules.message,
              },
              { shouldFocus: true },
            );
            formatError = true;
          }
        });
      });
    }
  }

  if (couponSetType === COUPON_SET_TYPES.IMPORT_EXISTING_COUPON) {
    if (
      redemptionMethod !== COUPON_REDEMPTION_METHOD.USERS_COPY_THE_DISCOUNT_CODE
    ) {
      if (formats === null || formats === undefined) {
        setError(
          'formats',
          {
            type: 'required',
            message: 'Please select code formart.',
          },
          { shouldFocus: true },
        );
        formatError = true;
      } else {
        clearErrors('formats');
      }
    }

    if (couponCodeType === null || couponCodeType === undefined) {
      setError(
        'couponCodeType',
        {
          type: 'required',
          message: 'Please select coupon code type.',
        },
        { shouldFocus: true },
      );
      formatError = true;
    } else {
      clearErrors('couponCodeType');
    }

    if (
      couponCodeType === COUPON_CODE_TYPE.GENERIC &&
      (genericCode === null || genericCode === undefined || !genericCode)
    ) {
      setError(
        'genericCode',
        {
          type: 'required',
          message: ERROR_MESSAGE.MISS_FIELD, //'Please input Generic code.'
        },
        { shouldFocus: true },
      );
      formatError = true;
    } else if (
      discountCodeFormat === DISCOUNT_CODE_FORMAT.URL &&
      couponCodeType === COUPON_CODE_TYPE.GENERIC &&
      !checkUrlIsValid(genericCode)
    ) {
      setError(
        'genericCode',
        {
          type: 'required',
          message: ERROR_MESSAGE.INVAILD_URL, //'Please input Generic code.'
        },
        { shouldFocus: true },
      );
      formatError = true;
    } else {
      clearErrors('genericCode');
    }
  }

  if (showLowStockLabel) {
    clearErrors('lowStockMethod');
    clearErrors('lowStockValue');
    if (!lowStockMethod) {
      setError(
        'lowStockMethod',
        {
          type: 'required',
          message: CouponErrorFields.lowStockMethod.message,
        },
        { shouldFocus: true },
      );
      formatError = true;
    } else if (!lowStockValue && lowStockValue !== 0) {
      setError(
        'lowStockValue',
        {
          type: 'required',
          message: CouponErrorFields.lowStockValueNull.message,
        },
        { shouldFocus: true },
      );
      formatError = true;
    } else {
      if (lowStockValue <= 0) {
        setError(
          'lowStockValue',
          {
            type: 'required',
            message: CouponErrorFields.lowStockValueLessThanZero.message,
          },
          { shouldFocus: true },
        );
        formatError = true;
      } else if (
        lowStockMethod === COUPON_LOW_STOCK.SPECIFIC_PERCENTAGE &&
        lowStockValue > 100
      ) {
        setError(
          'lowStockValue',
          {
            type: 'required',
            message: CouponErrorFields.lowStockValuePercentageRange.message,
          },
          { shouldFocus: true },
        );
        formatError = true;
      }
    }
  }

  // const settlementIsValid = await trigger([
  //   'settlementValueForHktb',
  //   'settlementValueForMerchant',
  // ]);

  const regex = /^\d*(\.\d{0,2})?$/;

  if (settlementValueForHktb === '' || !regex.test(settlementValueForHktb)) {
    setError(
      'settlementValueForHktb',
      {
        type: 'valid',
        message: 'Please input positive number, limit 2 demical places',
      },
      { shouldFocus: true },
    );
    formatError = true;
  } else {
    clearErrors('settlementValueForHktb');
  }
  if (
    settlementValueForMerchant === '' ||
    !regex.test(settlementValueForMerchant)
  ) {
    setError(
      'settlementValueForMerchant',
      {
        type: 'valid',
        message: 'Please input positive number, limit 2 demical places',
      },
      { shouldFocus: true },
    );
    formatError = true;
  } else {
    clearErrors('settlementValueForMerchant');
  }

  return !dateTimeError && !formatError;
};

const NewCreateCouponStepThree = ({ createCouponTemplateStatus }) => {
  const {
    getValues,
    register,
    trigger,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useFormContext();
  const history = useHistory();
  const [showCampaignLinkAlert, setShowCampaignLinkAlert] = useState(
    AlertType.none,
  );
  const campaignList = watch('campaignList');
  const fromEdit = history.location.pathname.endsWith('edit/');
  const dispatch = useDispatch();

  const watchCouponSetType = watch('couponSetType');

  const saveAction = async (isGenerater) => {
    const data = getValues();
    saveCouponToSessionStorage(getValues());
    const isValid = await validate(getValues, setError, clearErrors, trigger);
    if (!isValid) {
      dispatch(
        createAction('createCoupon/stepChange')({
          currentStep: 2,
          isValid,
        }),
      );
    } else {
      if (fromEdit) {
        dispatch(
          createAction('createCoupon/updateCouponTemplateInput')({
            isGenerater: isGenerater,
            data: getValues(),
            // afterAction: (pk) => {
            //   history.push(`/coupons/${pk}/`);
            // },
          }),
        );
      } else {
        dispatch(
          createAction('createCoupon/createCouponTemplate')({
            isGenerater: isGenerater,
            data: getValues(),
          }),
        );
      }
    }
    console.log('@@348: ', isValid, errors);
  };

  return createCouponTemplateStatus === APIStatus.calling ? (
    <Loading />
  ) : (
    <>
      <div className="create-coupon-step-three-container">
        <label className="create-section-title">Valid period</label>
        <CouponDateTimeSelector />
      </div>
      <CreateCouponStepThreeGeneral />
      <div className="create-coupon-step-three-container has-bottom-margin">
        <label className="create-section-title">Settlement value</label>

        <div className="second-section">
          <label className="create-section-label">HKTB</label>
          <div className="d-flex">
            <input
              type="number"
              className={`day-input-fields ${
                hasError(errors, 'settlementValueForHktb') ? 'error-field' : ''
              }`}
              {...register('settlementValueForHktb')}
              min={0}
            />
            <label className="days-label">HK$</label>
          </div>
          <ReactHookFormErrorMessage
            errors={errors}
            id="settlementValueForHktb"
          />
        </div>
        <div className="second-section">
          <label className="create-section-label">Merchant</label>
          <div className="d-flex">
            <input
              type="number"
              className={`day-input-fields ${
                hasError(errors, 'settlementValueForMerchant')
                  ? 'error-field'
                  : ''
              }`}
              {...register('settlementValueForMerchant')}
              min={0}
            />
            <label className="days-label">HK$</label>
          </div>
          <ReactHookFormErrorMessage
            errors={errors}
            id="settlementValueForMerchant"
          />
        </div>
      </div>
      <SaveAndBackButtons
        saveTempText="Save Only"
        saveText={`Save and ${
          watchCouponSetType === COUPON_SET_TYPES.SYSTEM_GENERATED
            ? 'Generate'
            : 'Import'
        } Coupon`}
        saveTempAction={() => {
          if (fromEdit && campaignList.length > 0) {
            setShowCampaignLinkAlert(AlertType.temp);
            return;
          }
          saveAction(false);
        }}
        saveAction={() => {
          if (fromEdit && campaignList.length > 0) {
            setShowCampaignLinkAlert(AlertType.generator);
            return;
          }
          saveAction(true);
        }}
        backAction={() => {
          dispatch(
            createAction('createCoupon/stepChange')({
              currentStep: 2,
              isValid: true,
              isBack: true,
            }),
          );
          saveCouponToSessionStorage(getValues());
        }}
      />
      <BasePrompt
        show={showCampaignLinkAlert !== AlertType.none}
        closeAction={() => {
          setShowCampaignLinkAlert(AlertType.none);
        }}
        leftButton={{
          text: 'Cancel',
          action: () => {
            setShowCampaignLinkAlert(AlertType.none);
          },
        }}
        rightButton={{
          text: 'Confirm to save',
          action: () => {
            setShowCampaignLinkAlert(AlertType.none);
            saveAction(showCampaignLinkAlert === AlertType.generator);
          },
        }}
        title={'Confirm to save the changes'}
        description={
          'Below campaigns have set to follow this coupon set. Upon you confirm to save, all the changes from this coupon set will update to the campaigns below.'
        }
        otherBody={() => {
          return <CampaignPopList campaignList={campaignList} />;
        }}
      />
    </>
  );
};
const mapPropsToState = (state) => ({
  createCouponTemplateStatus: state.createCoupon.createCouponTemplateStatus,
});
export default connect(mapPropsToState)(NewCreateCouponStepThree);
