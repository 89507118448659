import React, { useState, useEffect } from 'react';
import './ManualUseCouponCard.scss'
import { CustomTitleWithDropDown } from '../../customer/CustomTitleWithDropDown';
import { useDispatch, connect } from 'react-redux';
import { OnlyContinueButton } from '../../base/BottomStepComponent';
import { createAction } from '../../../utils';
import ConfirmUseCouponPrompt, { ResultUseCouponPrompt } from './UseCouponPrompt';

function ManualUseCouponCard ({
  redeemedResult,
  srkCouponInfo,
  storeList,
  title,
  hint
}){

  const dispatch = useDispatch();
  const [srk1, setSrk1] = useState();
  const [srk2, setSrk2] = useState();
  const [store, setStore] = useState();
  const [showConfirmPrompt, setShowConfirmPrompt] = useState()
  const [showResultPrompt, setShowResultPrompt] = useState()
  const [getCouponError, setGetCouponError] = useState(false);
  const [getStoreError, setGetStoreError] = useState(false);

  const clearStore = () => {
    setStore(null)
    dispatch(
      createAction('storeModel/updateState')({
        storeList: []
      })
    )
  }

  const getCouponBySrk = () => {
    if(srk1 && srk2){
      clearStore()
      dispatch(
        createAction('singleCoupon/getCouponInfoBySrk')({
          srk: srk1 + '-' + srk2,
          afterAction: (pk) => {
            if(pk){
              setGetCouponError(false)
              dispatch(
                createAction('storeModel/getPagedStoreList')({
                  isSelectorLoad: true,
                  couponTemplateIn: [pk || 0],
                  afterAction: (count) => {
                    setGetStoreError( count===0 ? true : false)
                    console.log("@39 ", count)
                  }
                }),
              )
            } else {
              setGetCouponError(true)
            }
          }
        })
      )
    }
  };

  return (
      
    <div className='coupon-overview-container' >
      <div className="manual-use-coupon">
        
        <div className="first-section-title overview-section-title">
        {title}
        </div>
        <div className="manual-use-coupon-hint">
        {hint}
        </div>

        <div className="second-section">
          <div className="second-title-section">
            <div className="second-section-title">Input SRK <p className="second-section-title-quote">(Not case sensitive)</p></div>
              <div className="input-srks">
                <div className="input-srk">
                      <div className="manual-use-coupon-hint">
                      Input first part
                      </div>
                      <input
                          type="text"
                          className={
                            getCouponError && srkCouponInfo?.errors[0]?.code === 'INVALID_SRK' 
                            ? "srk-input-text-error" : "srk-input-text"
                          }
                          onChange={({ target }) => {setSrk1(target.value);}}
                          placeholder="Please input"
                          value={srk1}
                          onFocus={() => {}}
                          onBlur={getCouponBySrk}
                      />
                </div>
                <div className="srk-connector">—</div>
                <div className="input-srk">
                  <div className="manual-use-coupon-hint">
                  Input last part
                  </div>
                  <input
                      type="text"
                      className={
                        getCouponError && srkCouponInfo?.errors[0]?.code === 'INVALID_SRK' 
                        ? "srk-input-text-error" : "srk-input-text"
                      }
                      onChange={({ target }) => setSrk2(target.value)}
                      placeholder="Please input"
                      value={srk2}
                      onFocus={() => {}}
                      onBlur={getCouponBySrk}
                  />
                </div>
            </div>

            { getCouponError && srkCouponInfo?.errors[0]?.code === 'INVALID_SRK' 
            ? <div className="mamual-use-coupon-error">{srkCouponInfo?.errors[0]?.message}</div> : null}
          </div>
        </div>

        <div className="second-section">
          <div className="second-title-section">
            <div className="second-section-title">Select active store</div>
            <div className="manual-use-coupon-hint">
              Corresponding store (Please input SRK first)
            </div>
              <CustomTitleWithDropDown
                setValue={(item) => {
                  setStore(item?.value)
                  dispatch(
                    createAction('singleCoupon/updateState')({
                      srkCouponInfo: {
                        ...srkCouponInfo,
                        store: item?.value
                      }
                    }),
                  )
                }}
                source={storeList}
                defaultValue={{
                  value: store,
                  label: store?.name,
                }}
                loadMoreAction={'storeModel/getPagedStoreList'}
                filterAction={'storeModel/getPagedStoreList'}
                defaultFilter={{couponTemplateIn: [srkCouponInfo?.coupon?.template?.pk || 0 ]}}
                needDelete={false}
                needFilter={false}
                customClass={ storeList.length === 0 || getCouponError ? "manual-use-coupon-dropdown" : ''}
              />
          </div>
          { getCouponError && srkCouponInfo?.errors[0]?.code === 'INVALID_BRAND' ? <div className="mamual-use-coupon-error">{srkCouponInfo?.errors[0]?.message}</div> : null}
        </div>
          
      </div>
          
      <OnlyContinueButton
        text='Use coupon'
        disabledContinue={storeList.length>0 && srkCouponInfo.store ? false : true}
        continueAction={() => setShowConfirmPrompt(true)}
        className="use-coupon-button"
      />

      <ConfirmUseCouponPrompt 
        show={showConfirmPrompt}
        closeAction={() => setShowConfirmPrompt(false)}
        onConfirm={(storeId, srk) => {
          setShowConfirmPrompt(false)
          dispatch(
            createAction('singleCoupon/manualUseCoupon')({
              storeId: storeId,
              srk: srk,
              afterAction: () => setShowResultPrompt(true)
            })
          )
        }}
        data={srkCouponInfo}
      />
      <ResultUseCouponPrompt 
        show={showResultPrompt}
        closeAction={() => setShowResultPrompt(false)}
        data={redeemedResult}
        successAction={() => {
          setShowResultPrompt(false)
          setSrk1('')
          setSrk2('')
          clearStore()
        }}
      />
          
    </div>
  );
  }

const mapPropsToState = (state) => ({
  redeemedResult: state.singleCoupon.redeemedResult || {},
  srkCouponInfo: state.singleCoupon.srkCouponInfo || {},
  storeList: state.storeModel.storeList || [],
});

export default connect(mapPropsToState)(ManualUseCouponCard);

