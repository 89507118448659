import React from 'react';
import { CustomTitleLabel } from '../../../components/earning/CustomBaseComponments';
import QRCodeCollapse from './QRCodeCollapse';
import { useFormContext } from 'react-hook-form';

export default function StoreQRCodeSection() {
  const { watch } = useFormContext();
  const codeDisplayImage = watch('codeDisplayImage');
  const codeDownloadImage = watch('codeDownloadImage');

  return (
    <>
      <label className="create-section-title">{'Store qr code'}</label>
      <label>The store unique code. </label>
      <CustomTitleLabel title={'QR code preview'} />
      <label>The store QR code is auto generate</label>
      <QRCodeCollapse
        codeDisplayImage={codeDisplayImage}
        codeDownloadImage={codeDownloadImage}
      />
    </>
  );
}
