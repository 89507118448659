import {
  getSingleCouponListWithTemplate,
  deactiveSingleCoupon,
  activeSingleCoupon,
  grantCoupon,
  getAllSingleCouponWithTemplate,
  deleteSingleCoupons,
  getSingleCoupon,
  reclaimCoupon,
  deactiveCoupons,
  activeCoupons,
  getAllSingleCoupons,
  getSimpleSingleCoupons,
  manualUseCoupon,
  getCouponInfoBySrk,
} from '../services/CouponAPIHelper';
import {
  getUseMethod,
  parseBarcodeFormat,
  parseSingleCoupon,
  parseSingleCouponRedeemPeriods,
} from './CouponUtil';
import {
  createAction,
  convertCursorToNumber,
  convertNumberToCursor,
  convertPKToId,
  addDomainToImage,
} from '../utils';
import {
  APIStatus,
  CouponMediumType,
  DisplayCouponMediumType,
} from '../config/CustomEnums';
import { apiWithResponseHandle, loading } from './LoadingUtil';
import { COUPON_STATUS } from './CouponListModel';
import { createModel } from './BaseModel';

const getInitialState = () => {
  return {
    singleCouponList: [],
    listDisplayFields: [
      { displayName: 'ID', fieldName: 'pk' },
      { displayName: 'Serial Number', fieldName: 'name', linked: true },
      { displayName: 'Import code', fieldName: 'code' },
      { displayName: 'Membership ID', fieldName: 'membershipId' },
      { displayName: 'Expriy Date', fieldName: 'displayExpiryDate' },
      { displayName: 'Create at', fieldName: 'displayCreationDate' },
      { displayName: 'Last Modified', fieldName: 'displayLastModifiedDate' },
      { displayName: 'Status', fieldName: 'status' },
    ],
    listImageDisplayFields: [
      { displayName: 'ID', fieldName: 'pk', linked: true },
      { displayName: 'Image', fieldName: 'displayImage', enlarge: true },
      { displayName: 'Membership ID', fieldName: 'membershipId' },
      { displayName: 'Expriy Date', fieldName: 'displayExpiryDate' },
      { displayName: 'Create at', fieldName: 'displayCreationDate' },
      { displayName: 'Last Modified', fieldName: 'displayLastModifiedDate' },
      { displayName: 'Status', fieldName: 'status' },
    ],
    currentPageSingleCouponList: [],
    pageInfo: {
      startCursor: '',
      endCursor: '',
      hasNextPage: false,
      hasPreviousPage: false,
    },
    currentLastCursor: '',
    currentPage: 0,
    totalPage: 0,
    totalCount: 0,
    checkedList: [],
    singleCoupon: {},
  };
};

export const parseCouponStatus = (singleCoupon) => {
  if (!singleCoupon) {
    return '-';
  }

  let status = COUPON_STATUS.ACTIVE;

  if (singleCoupon.isUsed) {
    status = COUPON_STATUS.USED;
  } else if (singleCoupon.isVoid) {
    status = COUPON_STATUS.VOID;
  } else if (singleCoupon.isExpired) {
    status = COUPON_STATUS.EXPIRED;
  } else if (singleCoupon.isForcedInactive) {
    if (singleCoupon.forcedInactiveBy === 'CRONJOB') {
      status = COUPON_STATUS.INACTIVE_CRONJOB;
    } else {
      status = COUPON_STATUS.INACTIVE;
    }
  } else if (singleCoupon.owner) {
    if (singleCoupon.dateOfGrant) {
      status = COUPON_STATUS.ACTIVE_ACQUIRED;
    } else {
      status = COUPON_STATUS.ACQUIRED;
    }
  }
  return status;
};

const parseSingleCouponWithStatus = (couponSet, singleCouponList) => {
  if (singleCouponList?.length > 0) {
    let parsedSingleCouponList = singleCouponList.map((singleCoupon) => {
      return parseSingleCoupon(couponSet, singleCoupon);
    });
    parsedSingleCouponList = parsedSingleCouponList.map((singleCoupon) => {
      const status = parseCouponStatus(singleCoupon);
      console.log('@@90: ', singleCoupon, singleCoupon.isVoid, status);
      return {
        ...singleCoupon,
        status: status,
      };
    });
    return parsedSingleCouponList;
  } else {
    return [];
  }
};

const assembleSingleCoupon = (coupon) => {
  if (!coupon) {
    return {};
  }
  const isPhysical = coupon?.batch?.couponMedium === CouponMediumType.Physical;

  return {
    ...coupon,
    couponBarcode: coupon.template?.barcodeFormat?.name,
    membershipId:
      isPhysical && coupon.isUsed ? 'Guest user' : coupon?.membershipId,
    status: parseCouponStatus(coupon),
    displayImage: addDomainToImage(coupon.image),
    transactionValue: coupon.couponTransaction?.transactionValue,
    transactionType: coupon.couponTransaction?.transactionType,
    store: coupon.couponTransaction?.store,
    administrator: coupon.transactions?.edges?.[0]?.node?.administrator,
    couponMedium: coupon?.batch?.couponMedium,
    displayCouponMedium: isPhysical
      ? DisplayCouponMediumType.PHYSICAL
      : DisplayCouponMediumType.EWALLET,
    // qrCodeString: btoa(
    //   coupon?.serialNumber +
    //     (!isPhysical && coupon?.owner
    //       ? coupon.owner?.membershipId.slice(-4)
    //       : ''),
    // ),
    qrCodeString: coupon.serialNumberEncrypted,
    useMethod: getUseMethod(coupon),
    couponbarcodeFormat: parseBarcodeFormat(
      coupon?.template?.barcodeFormat?.name,
    ),
    ...parseSingleCouponRedeemPeriods(coupon.template),
  };
};

export default createModel({
  namespace: 'singleCoupon',
  params: {
    dataKey: 'coupons',
    objectKey: 'coupons',
    listAPI: getAllSingleCoupons,
    pkNode: 'CouponNode',
    detailAPI: getSingleCoupon,
    deleteAPI: deleteSingleCoupons,
    parse: (data) => {
      console.log(data);
      return data.coupons.edges.map((edge) => ({
        ...edge.node,
      }));
    },

    // parseDetail: (data) => parseOneTourCard(data.tourCard),
  },
  state: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    clearSingleCoupon(state, { payload }) {
      return {
        ...state,
        singleCoupon: {},
      };
    },
  },
  effects: {
    getAllSingleCoupons: [
      function* ({ payload }, { call, put }) {
        const { template } = payload;
        const serviceArgs = [getAllSingleCouponWithTemplate, template.id];
        function* onSuccess(data) {
          const couponList = data.couponTemplate.coupons.edges;
          const parsedSingleCouponList = parseSingleCouponWithStatus(
            template,
            couponList,
          );
          const afterAction = payload.afterAction || (() => {});
          yield put(
            createAction('updateState')({
              singleCouponList: parsedSingleCouponList,
            }),
          );
          yield afterAction();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    getCurrentPageSingleCouponList: [
      function* ({ payload }, { put, call, select }) {
        yield put(
          createAction('updateState')({
            getSingleCouponListStatus: APIStatus.calling,
          }),
        );
        const couponSet = yield select((state) => state.couponList.couponSet);
        const { templatePK, currentPage, rank, filterKey, search } = payload;
        const templateID = convertPKToId('CouponTemplateNode', templatePK);
        let afterCursor = undefined;
        if (currentPage > 1) {
          afterCursor = convertNumberToCursor((currentPage - 1) * 20 - 1);
        }
        const serviceArgs = [
          getSingleCouponListWithTemplate,
          templatePK,
          afterCursor,
          rank,
          filterKey,
          search,
        ];
        function* onFailed() {
          yield put(
            createAction('updateState')({
              getSingleCouponListStatus: APIStatus.failed,
            }),
          );
        }
        function* onSuccess(data) {
          const singleCoupons = data.coupons;
          const pageInfo = singleCoupons?.pageInfo;
          const totalCount = singleCoupons?.totalCount;
          const currentLastCursor = pageInfo?.endCursor;
          const singleCouponList = parseSingleCouponWithStatus(
            couponSet,
            singleCoupons.edges,
          );
          yield put(
            createAction('updateState')({
              currentPageSingleCouponList: singleCouponList,
              totalCount: totalCount,
              pageInfo: {
                startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1,
                endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1,
              },
              currentLastCursor,
              totalPage: Math.ceil(totalCount / 20),
            }),
          );
        }
        yield loading(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],

    getSingleCoupon: [
      function* ({ payload }, { put }) {
        const { pk } = payload;
        const couponID = convertPKToId('CouponNode', pk);
        const serviceArgs = [getSingleCoupon, couponID];
        function* onSuccess(data) {
          const couponData = data.coupon;
          const singlecoupon = assembleSingleCoupon(couponData);
          yield put(
            createAction('updateState')({
              singleCoupon: singlecoupon,
            }),
          );
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    delete: [
      function* ({ payload }, { put, select }) {
        const checkedList = yield select(
          (state) => state.singleCoupon.checkedList,
        );
        const ids = checkedList.map((item) => item.pk);
        const serviceArgs = [deleteSingleCoupons, ids];
        function* onSuccess() {
          const deleteAction = payload.afterAction || (() => {});
          yield deleteAction();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    deactiveSingleCoupon: [
      function* ({ payload }, { call, select, put }) {
        const { couponPK } = payload;
        const serviceArgs = [deactiveSingleCoupon, couponPK];
        function* onSuccess() {
          const deactiveAction = payload.afterAction || (() => {});
          console.log('@@237: ', deactiveAction);
          yield deactiveAction();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    activeSingleCoupon: [
      function* ({ payload }, { call }) {
        const { couponPK } = payload;
        const activeAction = payload.activeAction || (() => {});
        const serviceArgs = [activeSingleCoupon, couponPK];
        function* onSuccess() {
          yield activeAction();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    deactiveCoupons: [
      function* ({ payload }, { call, select }) {
        const coupons = yield select(
          (state) => state.singleCoupon.singleCouponList,
        );
        let ids = [];
        if (coupons && coupons.length > 0) {
          coupons.forEach((coupon) => {
            if (!coupon.dateOfGrant) {
              ids.push(coupon.pk);
            }
          });
        }
        const serviceArgs = [deactiveCoupons, ids];
        function* onSuccess() {
          const afterAction = payload.afterAction || (() => {});
          yield afterAction();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    activeCoupons: [
      function* ({ payload }, { select }) {
        const coupons = yield select(
          (state) => state.singleCoupon.singleCouponList,
        );
        let ids = [];
        if (coupons && coupons.length > 0) {
          coupons.forEach((coupon) => {
            if (!coupon.dateOfGrant) {
              ids.push(coupon.pk);
            }
          });
        }
        const serviceArgs = [activeCoupons, ids];
        function* onSuccess() {
          const afterAction = payload.afterAction || (() => {});
          yield afterAction();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    grantSingleCoupon: [
      function* ({ payload }, { call, put }) {
        const { couponPK, customerPK } = payload;
        const serviceArgs = [grantCoupon, couponPK, customerPK];
        function* onSuccess() {
          const afterAction = payload.afterAction || (() => {});
          yield afterAction();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    reclaimCoupon: [
      function* ({ payload }, { put }) {
        const { couponPK } = payload;
        const serviceArgs = [reclaimCoupon, couponPK];
        function* onSuccess() {
          const afterAction = payload.afterAction || (() => {});
          yield afterAction();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getSimpleList: [
      function* ({ payload }, { call, select, put }) {
        const page = payload?.page || 1;
        const pageCount = payload?.pageSize || 20;
        const pageCursor = convertNumberToCursor((page - 1) * pageCount - 1);
        const serviceArgs = [getSimpleSingleCoupons, pageCursor, payload];

        function* onSuccess(response) {
          console.log('@383');
          yield put({
            type: 'updateListData',
            payload: {
              ...payload,
              totalCount: response.simpleCoupons.totalCount,
              pageInfo: response.simpleCoupons.pageInfo,
              // listName: 'singleCouponList',
              data: response.simpleCoupons.edges.map((edge) => ({
                ...edge.node,
              })),
            },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    manualUseCoupon: [
      function* ({ payload }, { put }) {
        const { storeId, srk } = payload;
        const serviceArgs = [manualUseCoupon, storeId, srk];
        function* onSuccess(response) {
          yield put(
            createAction('updateState')({
              redeemedResult: response.manualUseCoupon,
            }),
          );
          const afterAction = payload.afterAction || (() => {});
          yield afterAction();
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getCouponInfoBySrk: [
      function* ({ payload }, { put }) {
        const { srk } = payload;
        const serviceArgs = [getCouponInfoBySrk, srk];
        function* onSuccess(response) {
          console.log('@423', response);
          yield put(
            createAction('updateState')({
              srkCouponInfo: response.getCouponInfoBySrk,
            }),
          );
          const afterAction = payload.afterAction || (() => {});
          yield afterAction(response.getCouponInfoBySrk?.coupon?.template?.pk);
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
});
