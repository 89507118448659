import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import './CreateCampaignStepTwo.scss';
import ContentSections from '../../base/ContentSections';
import { ContinueWithBackButtons } from '../../base/BottomStepComponent';
import { createAction, insertKeyToTranslations } from '../../../utils';
import CustomTitleWithInput from '../../base/CustomTitleWithInput';
import { useFormContext } from 'react-hook-form';
import { validate } from '../../../containers/engagement/campaign/CreateCampaignValidate';
import { hasError, errorMessage } from '../../base/ErrorFieldMessage';

function CreateFortuneBagCampaignStepTwo({ languageList }) {
  const { watch, setValue, setError, getValues, formState, clearErrors } =
    useFormContext();
  const errors = formState.errors;
  const watchTranslation = watch('translations') || {};
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
  }, []);

  const getTabs = (language) => {
    return [
      <>
        <label className="create-section-title">General</label>
        <CustomTitleWithInput
          title="Name"
          defaultValue={watchTranslation[language]?.generalName}
          setValue={(value) => {
            insertKeyToTranslations({
              language,
              key: 'generalName',
              value: value,
              setValue,
              getValues,
            });
          }}
          error={{
            id: `generalName.${language}`,
            error: hasError(errors, `translations.${language}.generalName`),
            message: errorMessage(
              errors,
              `translations.${language}.generalName`,
            ),
          }}
          customClass=""
          extra={{
            maxLength: 80,
          }}
        />
      </>,
    ];
  };

  const stepChangeFortune = async (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 1,
      isBack,
    });
    dispatch({
      type: 'createCampaign/stepChange',
      payload: { isBack: isBack, step: 1, isValid },
    });
  };

  return (
    <>
      <ContentSections
        languageTabContent={{
          containers: languageList.map((language) => {
            return {
              container: getTabs(language.code),
              key: language.code,
              title: language.sourceName,
            };
          }),
        }}
        hidePreview
      />
      <ContinueWithBackButtons
        continueAction={() => stepChangeFortune(false)}
        backAction={() => stepChangeFortune(true)}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  languageList: state.language.allList,
});

export default connect(mapPropsToState)(CreateFortuneBagCampaignStepTwo);
