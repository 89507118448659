import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { connect } from 'react-redux';
import AuthButton from '../../../components/base/AuthButton';
import ContentSections from '../../../components/base/ContentSections';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { createAction } from '../../../utils';
import Loading from '../../../components/base/Loading';
import { LanguageConfig } from '../../../config/CustomEnums';
import { Link } from 'react-router-dom';
import './OfferTypeLabelDetail.scss';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';
import i18n from '../../../I18n';

const OfferTypeLabelDetail = ({ detail, dispatch, languages }) => {
  const history = useHistory();
  const params = useParams();
  const { id } = params;
  useEffect(() => {
    dispatch(createAction('offerTypeLabel/getOneDetail')({ id }));
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
    return () => {
      dispatch(createAction('offerTypeLabel/clearData')());
    };
  }, [id]);

  // const { relatedCampaigns = [], relatedCampaignNames = [] } = detail;
  const renderTabForLanguage = (language) => {
    const name =
      language === LanguageConfig.english
        ? detail.name
        : detail.translations?.[language]?.name;

    return (
      <MembershipInfoCard
        title={i18n.t('content', { locale: 'en' })}
        data={{ name }}
        fields={[
          [
            {
              title: i18n.t('name', { locale: 'en' }),
              field: 'name',
            },
          ],
        ]}
      />
    );
  };

  const tabs = [
    {
      name: 'Detail',
      content: detail.pk ? (
        <div className="scroll-container-common">
          <ContentSections
            hidePreview
            languageTabContent={{
              containers: languages?.map((item) => ({
                container: [renderTabForLanguage(item.code)],
                key: item.code,
                title: item.sourceName,
              })),
            }}
            activeSection={LanguageConfig.english}
            sectionStyle="offer-type-labels-content"
            contentStyle="offer-type-labels-content-sections"
          />

          <ContentSections
            hidePreview
            sections={[
              <MembershipInfoCard
                title={'Related campaign'}
                data={detail}
                fields={[
                  [
                    {
                      title: 'Related campaign',
                      field: 'relatedCampaigns',
                      linkTo: 'campaigns',
                    },
                  ],
                ]}
              />,
            ]}
            sectionStyle="offer-type-labels-related-campaign"
          />

          {/* */}
        </div>
      ) : (
        <Loading />
      ),
    },
  ];

  const buttons = [
    <AuthButton
      title="Edit"
      action={() => {
        history.push({
          pathname: 'edit/',
        });
      }}
      requires={PermissionCodes.changeOfferTypeLabel}
    />,
  ];
  return (
    <>
      <CustomListComponent
        caution={{
          detail: '',
          title: detail?.name,
        }}
        defaultActiveKey={tabs[0].name}
        breadcrumb={<CustomBreadcrumb name={detail?.name} />}
        buttons={buttons}
        tabs={tabs}
      />
    </>
  );
};

const mapPropsToState = (state) => ({
  detail: state.offerTypeLabel.detail,
  languages: state.language.allList,
});
export default connect(mapPropsToState)(OfferTypeLabelDetail);
