import React, { useState, forwardRef } from 'react';
import { Image } from 'react-bootstrap';
import './CountryCodesPrompt.scss';
import BasePrompt from '../base/prompt/BasePrompt';

export default function CountryCodesPrompt({
  show,
  onClose,
  countryCodesList,
}) {
  const otherBody = () => {
    return (
      <>
        <div className="country-codes-prompt-other-body">
          {countryCodesList?.map((item) => {
            return (
              <label>
                +{item.pk} {item.name}
              </label>
            );
          })}
        </div>
      </>
    );
  };
  return (
    <>
      <BasePrompt
        show={show}
        customClass="country-codes-prompt-container"
        closeAction={() => {
          onClose();
        }}
        otherBody={otherBody}
      />
    </>
  );
}
