import { BaseHelper } from './BaseGraphQLAPIHelper';

const GENERAL_ALERT_NODE = `{
  id
  pk
  content
  displayPriority
  severityLevel
  scheduledStatus
  startDate
  endDate
  isActive
  isPublished
  translations {
      edges {
          node {
              id
              language
              pk
              content
          }
      }
  }
}`;

const GENERAL_ALERT_DUPLICATE_NODE = `{
  pk
  content
  displayPriority
  startDate
  endDate
}`;

export const getGeneralAlertList = (afterCursor, params) => {
  let filter = '';
  const {
    sort,
    filterName,
    scheduled_status,
    start_time,
    end_time,
    status,
    severity_level,
  } = params || {};
  if (filterName) {
    filter = `, contentIcontains:"${filterName}"`;
  }
  if (scheduled_status) {
    filter += `, scheduledStatus:"${scheduled_status}"`;
  }
  if (start_time) {
    filter += `, startDateGte:"${start_time.replace(' ', 'T')}:00"`;
  }
  if (end_time) {
    filter += `, endDateLte:"${end_time.replace(' ', 'T')}:00"`;
  }
  if (status && !(status.search('true') > -1 && status.search('false') > -1)) {
    filter += `, isActive:${status}`;
  }

  if (severity_level) {
    filter += `, severityLevel:"${severity_level}"`;
  }

  let orderBy = '-pk';
  if (sort) {
    orderBy = sort;
  }

  const query = `
    {
      generalAlerts(first: 20, after:"${afterCursor}"${filter}, orderBy: "${orderBy}") {
          totalCount
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          edges {
            cursor
            node ${GENERAL_ALERT_NODE}
          }
        }
      }`;
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getGeneralAlertDetail = (id) => {
  const query = `
  {
    generalAlert(id: "${id}")
      ${GENERAL_ALERT_NODE}
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createOrUpdateGeneralAlert = (inputBody) => {
  console.log('@api 88', inputBody);
  const action = inputBody.id ? 'Update' : 'Create';
  const query = `
  mutation ${action}GeneralAlert($input: ${action}GeneralAlertInput!) {
    ${action.toLowerCase()}GeneralAlert(input: $input) {
      node ${GENERAL_ALERT_NODE}
      errors {
        field
        messages
      }
      generalAlerts ${GENERAL_ALERT_DUPLICATE_NODE}
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteGeneralAlerts = (ids) => {
  console.log('@api', ids);
  const query = `
  mutation DeleteGeneralAlerts($input: DeleteGeneralAlertsInput!) {
    deleteGeneralAlerts(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids: ids,
    },
  };
  console.log('@127', query);
  return BaseHelper.callGraphQLAPI({ query, variables });
};

// export const duplicateMessage = (id) => {
//   const query = `
//   mutation CopyMessage($input: CopyMessageInput!) {
//     copyMessage(input: $input) {
//       success
//     }
//   }`;
//   const variables = {
//     input: {
//       id: id,
//     },
//   };
//   return BaseHelper.callGraphQLAPI({ query, variables });
// };
