import React, { useState } from 'react';
import './CouponKeyVisual.scss';
import { Controller, useFormContext } from 'react-hook-form';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';
import CustomTitleWithSwitch from '../../base/CustomTitleWithSwitch';
import CustomTitleWithInput from '../../base/CustomTitleWithInput';
import { CustomTitleLabel } from '../../earning/CustomBaseComponments';
import CustomSwitchButton from '../../base/CustomSwitchButton';
import CustomTips from '../../base/CustomTips';
import ErrorFieldMessage from '../../base/ErrorFieldMessage';
import { errorMessage, hasError } from '../../base/ErrorFieldMessage';

const EMPTY_TOUR_CARD_LIST = [];

const ColorIndicatorSection = ({
  language,
  focusChange,
  showColorIndicatorTips1,
  showColorIndicatorTips2,
}) => {
  const { control, watch, setValue, formState } = useFormContext();
  const { errors } = formState;

  const error = {
    id: `indicatorColor`,
    error: hasError(errors, `indicatorColor`),
    message: errorMessage(errors, `indicatorColor`),
  };

  const watchShowColorIndicator = watch(`showColorIndicator`);
  const watchIndicatorColor = watch(`indicatorColor`);

  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (color) => {
    // setColor(color.rgb);
    // setColor(color.hex);
    setValue(`indicatorColor`, color.hex.toUpperCase(), {
      shouldDirty: true,
    });
  };

  const styles = reactCSS({
    default: {
      color: {
        width: '42px',
        height: '28px',
        border: '1px solid #EEEEEE',
        borderRadius: '4px',
        // background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
        backgroundColor: watchIndicatorColor,
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        border: '1px solid #C2C2C2',
        borderRadius: '4px',
        display: 'inline-block',
        cursor: 'pointer',
        marginLeft: '6px',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  });

  return (
    <>
      <div
        onFocus={() => {
          focusChange && focusChange('showColorIndicator');
        }}
      >
        <Controller
          control={control}
          const
          name={`showColorIndicator`}
          render={() => {
            return (
              <>
                <div className="d-flex flex-column">
                  <div style={{ display: 'flex' }}>
                    <CustomTitleLabel
                      title={'Show color indicator for coupons'}
                    />
                    <CustomTips
                      detail={
                        'The purpose of this feature is to provide a clear and visible color indicator when a coupon is redeemed. This helps merchants easily identify which specific coupon is being used and determine the appropriate reward to be given to the customer.'
                      }
                      customClass={{
                        PopoverContentClass: 'switch-tips',
                        area: 'create-section-label create-section-label-bottom-space',
                      }}
                    />
                  </div>
                  <label className="tips-message">
                    {showColorIndicatorTips1}
                    <br />
                    {showColorIndicatorTips2}
                  </label>

                  <CustomSwitchButton
                    defaultChecked={watchShowColorIndicator}
                    onChange={(isChecked) => {
                      setValue(`showColorIndicator`, isChecked, {
                        shouldDirty: true,
                      });
                    }}
                  />
                </div>
              </>
              // <CustomTitleWithSwitch
              //   title="Show color indicator for coupons"
              //   defaultValue={watchShowColorIndicator}
              //   setValue={(newValue) => {
              //     setValue(
              //       `showColorIndicator`,
              //       newValue,
              //       {
              //         shouldDirty: true,
              //       },
              //     );
              //   }}
              //   tips={showColorIndicatorTips}
              //   tipsDetail={
              //     'The purpose of this feature is to provide a clear and visible color indicator when a coupon is redeemed. This helps merchants easily identify which specific coupon is being used and determine the appropriate reward to be given to the customer.'
              //   }
              //   tipsDetailOnTitle={true}
              // />
            );
          }}
        />
        {watchShowColorIndicator ? (
          <>
            <Controller
              control={control}
              name={`translations.${language}.indicatorText`}
              render={({ field: { value } }) => (
                <CustomTitleWithInput
                  title="Text for the indicator (optional)"
                  defaultValue={value}
                  setValue={(newValue) => {
                    setValue(
                      `translations.${language}.indicatorText`,
                      newValue,
                      {
                        shouldDirty: true,
                      },
                    );
                  }}
                  extra={{ maxLength: 200 }}
                />
              )}
            />
            <label className="second-section-title create-section-label-bottom-space">
              Customise the color of the indicator
            </label>
            <label className="tips-message">
              To select a color, you can either click on the color palette or
              manually input the color using the Hex color code format. There
              are preselect colors available in the color palette.
            </label>
            <div className="multiple-color-input-row">
              <Controller
                control={control}
                name={`indicatorColor`}
                render={() => (
                  <>
                    <input
                      type="text"
                      className={`text-input-field color-input-field ${
                        error.error ? 'error-field' : ''
                      }`}
                      value={
                        watchIndicatorColor &&
                        watchIndicatorColor.startsWith('#')
                          ? watchIndicatorColor.replace('#', '')
                          : watchIndicatorColor
                      }
                      onChange={({ target }) => {
                        setValue(
                          `indicatorColor`,
                          target.value ? `#${target.value}` : '',
                          {
                            shouldDirty: true,
                          },
                        );
                      }}
                      maxLength={6}
                      placeholder="Input Hex"
                    />
                    <div>
                      <div
                        className={`${error.error ? 'error-field' : ''}`}
                        style={styles.swatch}
                        onClick={handleClick}
                      >
                        <div style={styles.color} />
                      </div>
                      {displayColorPicker ? (
                        <div style={styles.popover}>
                          <div style={styles.cover} onClick={handleClose} />
                          <SketchPicker
                            disableAlpha={true}
                            color={watchIndicatorColor || ''}
                            onChange={handleChange}
                            presetColors={[
                              '#753BBD',
                              '#4C1D8D',
                              '#A6057D',
                              '#E84964',
                              '#DD3426',
                              '#01958F',
                              '#DD9117',
                              '#029663',
                              '#B11609',
                              '#1280A2',
                              '#FF6600',
                            ]}
                          />
                        </div>
                      ) : null}
                    </div>
                  </>
                )}
              />
            </div>
            {error.error ? (
              <ErrorFieldMessage
                id={error.id}
                error={error}
                message={error.message}
              />
            ) : null}
          </>
        ) : null}
      </div>
    </>
  );
};

export default ColorIndicatorSection;
