import { createModel } from './BaseModel';
import {
  getAdminActivityLogList,
  getAdminActivityLogDetail,
  getAdminActivityLogCount,
  createAdministratorActivityLog,
} from '../services/AdminActivityLogApiHelper';
import { AdminActivityType, SESSION_KEYS } from '../config/CustomEnums';
import { convertCursorToNumber } from '../utils';
import { formatDate } from '../utils/TimeFormatUtil';
import { apiWithResponseHandle } from './LoadingUtil';

const parseDetail = (data) => {
  return {
    ...data,
  };
};

const parseDisplayType = (type) => {
  let displayType = AdminActivityType[type]?.name || '';
  return displayType;
};

const parseAdminActivityLogList = (data) => {
  const dataLength = data?.administratorActivityLogs.totalCount || 0;
  const startCursor = convertCursorToNumber(
    data?.administratorActivityLogs?.pageInfo?.startCursor,
  );
  return data?.administratorActivityLogs.edges.map((item, index) => ({
    ...item.node,
    order: dataLength - startCursor - index,
    displayCreationDate: formatDate(
      item.node.creationDate,
      'MMM DD, YYYY, hh:mm A',
    ),
    displayType: parseDisplayType(item.node.activityType),
    adminId: item.node.administrator?.pk,
    adminName: item.node.administrator?.username,
    user: item.node.administrator
      ? `[ID:${item.node.administrator.pk}]${item.node.administrator.username}`
      : '',
    membershipId: item.node.logContent?.membershipId,
    displayGoodieBag: item.node.logContent?.goodieBagId
      ? `[ID:${item.node.logContent?.goodieBagId}]${item.node.logContent?.goodieBagName}`
      : '',
    displayCampaign: item.node.logContent?.campaignId
      ? `[ID:${item.node.logContent?.campaignId}]${item.node.logContent?.campaignName}`
      : '',
    displayCouponSet: item.node.logContent?.couponTemplateId
      ? `[ID:${item.node.logContent?.couponTemplateId}]${item.node.logContent?.couponTemplateName}`
      : '',
    couponId: item.node.logContent?.couponId,
  }));
};

export default createModel({
  namespace: 'adminActivityLog',
  states: {
    listDisplayFields: [
      { displayName: 'ID', fieldName: 'order' },
      { displayName: 'Create Date Time', fieldName: 'displayCreationDate' },
      { displayName: 'Action', fieldName: 'displayType' },
      { displayName: 'Membership ID', fieldName: 'membershipId' },
      { displayName: 'Goodie Bag', fieldName: 'displayGoodieBag' },
      { displayName: 'Campaign', fieldName: 'displayCampaign' },
      { displayName: 'Coupon Set', fieldName: 'displayCouponSet' },
      { displayName: 'Coupon ID', fieldName: 'couponId' },
      { displayName: 'Remark', fieldName: 'remarks' },
      { displayName: 'Change to', fieldName: 'changeTo' },
      { displayName: 'User', fieldName: 'user' },
    ],
  },
  reducers: {},
  params: {
    listAPI: getAdminActivityLogList,
    parse: parseAdminActivityLogList,
    pkNode: 'AdministratorActivityLogNode',
    detailAPI: getAdminActivityLogDetail,
    parseDetail: (data) => parseDetail(data.administratorActivityLog),
    objectKey: 'administratorActivityLogs',
  },
  effects: {
    getRecordsCount: [
      function* ({ payload }, { put }) {
        console.log('@@132', payload);
        const serviceArgs = [getAdminActivityLogCount, payload?.filter];

        function* onSuccess(data) {
          const totalCount = data?.administratorActivityLogs?.totalCount;
          yield put({
            type: 'updateState',
            payload: {
              recordCount: totalCount,
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    createAdministratorActivityLog: [
      function* ({ payload }, { call, put }) {
        const data = {
          activityType: payload.activityType,
          objectId: payload.objectId,
        };
        const serviceArgs = [createAdministratorActivityLog, data];
        function* onSuccess(data) {}
        function* onFailed(data) {}
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
  },
});
