import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import Filter from './Filter';
import ManualUseCouponCard from '../../../components/coupon/manualUseCoupon/ManualUseCouponCard';
import BaseListContainer from '../../base/BaseListContainer';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { checkHasPermission, createAction } from '../../../utils';
import { PermissionCodes } from '../../../config/PermissionCodes';

import { ImageTextButton } from '../../../components/base/CustomImageButton';
import filterImage from '../../../assets/images/32filter.svg';
import { useDeviceDetect } from '../../engagement/campaign/preview/DHKPreviewBody';
import ManualUseRecordCard from '../../../components/coupon/manualUseCoupon/ManualUseRecordCard';
import FlatList from 'flatlist-react';
import NoDataPage from '../../../components/base/NoDataPage';
import CloseIcon from '../../../assets/images/prompt_close_icon.svg';
import { Image } from 'react-bootstrap';
import Loading from '../../../components/base/Loading';
import CountComponent from '../../../components/base/CountComponent';
import './ManualUseCouponOverview.scss';
function ManualUseCouponOverview({
  manualUseDisplayFields,
  dataList,
  pageInfo,
  totalCount,
  totalPage,
  user,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryString = require('query-string');
  const isMobile = useDeviceDetect(768);
  const [activeTab, setActiveTab] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const inRecordPage = activeTab === 'Record';

  const filterBackButton = () => (
    <>
      <Image
        src={CloseIcon}
        className="filter-back-button"
        onClick={() => {
          setShowFilter(false);
        }}
      />
    </>
  );

  const fetchData = () => {
    const searchDict = queryString.parse(location.search);
    const searchCouponId = searchDict['search'];
    const stringRank = searchDict['rank'] || 'true';
    const stringPage = searchDict['page'] || 1;
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);

    dispatch(
      createAction('couponTransactions/getCurrentPageCouponTransactions')({
        ...searchDict,
        searchCouponId,
        rank,
        page,
        is_manual_use: true,
        loadMore: isMobile,
      }),
    );
  };

  const tabs = [
    checkHasPermission(user, PermissionCodes.changeCoupon)
      ? {
          name: 'Use single coupon',
          content: (
            <ManualUseCouponCard
              title={'MANUAL USE COUPON'}
              hint={'Fill in the below information to use the coupon.'}
            />
          ),
        }
      : {},
    {
      name: 'Record',
      content: (
        <BaseTabListContainer
          hideTab={true}
          hideActionHeader={isMobile}
          // tabs={couponRecordTypeTabs}
          pageInfo={pageInfo}
          totalCount={totalCount}
          groupActions={[]}
          filter={{ hasFilter: !isMobile, componment: Filter }}
          searchPlaceholder="Coupon ID"
          needMobile
          hideSearch={isMobile}
          hideTabNav={isMobile}
          listContent={
            <BaseListContainer
              hideCheckbox
              hideActions
              fields={manualUseDisplayFields}
              model={'couponTransactions'}
              dataList={dataList}
              totalPage={totalPage}
              deleteInfo={{}}
              useCustomCommonActions={[]}
            />
          }
          mobileListContent={
            <FlatList
              list={dataList}
              renderItem={(item, index) => (
                <ManualUseRecordCard key={index} couponRecord={item} />
              )}
              renderWhenEmpty={() => (
                <NoDataPage
                  customClass={'no-record-page'}
                  customImageClass={'no-record-image'}
                />
              )}
              pagination={{
                hasMore: inRecordPage && pageInfo.hasNextPage,
                loadMore: () => {
                  const searchDict = queryString.parse(location.search);
                  const stringPage = searchDict['page'] || 1;
                  const page = parseInt(stringPage);
                  history.push({
                    pathname: location.pathname,
                    search: queryString.stringify({
                      ...searchDict,
                      page: page + 1,
                    }),
                  });
                },
                loadingIndicator: (
                  <div>
                    <Loading customClass={'record-loading'} />
                  </div>
                ),
                loadingIndicatorPosition: 'center',
              }}
              wrapperHtmlTag="div"
              style={{
                overflow: 'auto',
                height: 'calc(100vh - 177px - 16px)',
              }}
            />
          }
        />
      ),
    },
  ];

  const buttons = [];

  const getFilterCount = () => {
    let filterCount = 0;
    const querySearch = queryString.parse(location.search);
    Object.entries(querySearch).map(([key, value]) => {
      if (Object.keys(querySearch).includes(querySearch[key])) {
        return;
      }
      if (!['page', 'rank', 'search', 'sort'].includes(key) && value) {
        filterCount += 1;
      }
    });

    return filterCount;
  };

  const filterCount = getFilterCount();

  useEffect(() => {
    (!isMobile || inRecordPage) && fetchData();
  }, [dispatch, location, queryString]);

  useEffect(() => {
    if (inRecordPage && (dataList || [])?.length < 1) {
      fetchData();
    }
  }, [activeTab]);

  return (
    <div className="customer">
      <CustomListComponent
        caution={{
          detail: '',
          title: 'Manual use coupon',
        }}
        defaultActiveKey={
          activeTab || (tabs[0]?.name ? tabs[0]?.name : tabs[1]?.name)
        }
        breadcrumb={
          isMobile ? null : <CustomBreadcrumb name={'Manual use coupon'} />
        }
        buttons={buttons}
        tabs={tabs}
        onTabChange={(key) => {
          setActiveTab(key);
        }}
        renderTabsRight={() => {
          return (
            <>
              {isMobile && inRecordPage && (
                <div className="filter-icon-and-button-area">
                  <ImageTextButton
                    action={() => setShowFilter(!showFilter)}
                    image={filterImage}
                    title={'Filter'}
                  />
                  {filterCount > 0 && (
                    <label className="filter-badge-icon">{filterCount}</label>
                  )}
                </div>
              )}

              {showFilter && isMobile && (
                <Filter
                  backAction={() => {
                    setShowFilter(false);
                  }}
                  renderAboveFilterText={filterBackButton}
                  customFilterAreaClass={
                    'manual-use-overview-filter-area-mobile'
                  }
                />
              )}
            </>
          );
        }}
        customHeaderClass={
          isMobile ? 'manual-use-overview-list-section-header' : ''
        }
      />
    </div>
  );
}
const mapPropsToState = (state) => ({
  user: state.users,
  manualUseDisplayFields: state.couponTransactions.manualUseListDisplayFields,
  dataList: state.couponTransactions.currentPageCouponTransactionList,
  pageInfo: state.couponTransactions.pageInfo,
  totalCount: state.couponTransactions.totalCount,
  totalPage: state.couponTransactions.totalPage,
});

export default connect(mapPropsToState)(ManualUseCouponOverview);
