import React, { useState, forwardRef } from 'react';
import { Image, Button, Dropdown } from 'react-bootstrap';
import QRCode from 'react-qr-code';
import './CouponQrCode.scss';

const popperConfig = {
  strategy: 'fixed',
  modifiers: [
    {
      name: 'computeStyles',
      options: {
        gpuAcceleration: false,
        adaptive: false,
      },
    },
  ],
};

const CustomToggle = forwardRef(({ source, customClass = '' }, ref) => {
  return (
    <QRCode
      ref={ref}
      value={source}
      size={75}
      className={`display-coupon-qr-code ${customClass}`}
    />
  );
});

function CouponQrCode({ value, srkValue, customQRCodeClass }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Dropdown
      show={isOpen}
      drop="right"
      className="coupon-qr-code-container"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <Dropdown.Toggle
        as={CustomToggle}
        source={value}
        customClass={customQRCodeClass}
      />

      <Dropdown.Menu popperConfig={popperConfig}>
        <Dropdown.Item>
          <div className="hover-qr-code-container">
            <label className="hover-qr-code-title">Coupon QR code</label>
            <QRCode value={value} size={160} className="hover-coupon-qr-code" />
            <label className="hover-qr-code-description">
              SRK: {srkValue || '-'}
            </label>
          </div>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default CouponQrCode;
