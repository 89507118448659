export const pointRecordQueryKey = {
  searchKey: 'searchKey',
  rank: 'rank',
  page: 'page',
  tab: 'type',
};

export const couponRecordQueryKey = {
  searchKey: 'searchKey',
  rank: 'rank',
  page: 'page',
  tab: 'type',
};

export const transactionQueryKey = {
  searchKey: 'searchKey',
  rank: 'rank',
  page: 'page',
  tab: 'type',
};
