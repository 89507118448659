import React, { useEffect, useState } from 'react';
import './Entitlement.scss';
import queryString from 'query-string';
import ContentSections from '../../../../components/base/ContentSections';
import { SaveAndBackButtons } from '../../../../components/base/BottomStepComponent';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { PermissionCodes } from '../../../../config/PermissionCodes';
import CustomListComponent from '../../../../components/base/CustomListComponent';
import AuthButton from '../../../../components/base/AuthButton';
import CustomBreadcrumb from '../../../../components/base/CustomBreadcrumb';
import Options from './EntitlementOptions';

import RecordTimelineCard from '../../../../components/customer/RecordTimelineCard';
import {
  checkHasPermission,
  createAction,
  getHashKeyString,
} from '../../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import BaseTabListContainer from '../../../base/BaseTabListContainer';
import BaseListContainer from '../../../base/BaseListContainer';
import { ADMIN_TYPE } from '../../../../config/CustomEnums';
import {
  FORTUNEBAG_OPTIONS_BUTTON_TYPE,
  FORTUNEBAG_ENTITLEMENT_OPTIONS,
} from '../../../../models/FortuneBagEntitlementModel';
import { Image } from 'react-bootstrap';
import DeletePrompt from '../../../../components/base/DeletePrompt';
import copy from 'copy-to-clipboard';
import CopyIcon from '../../../../assets/images/campaign/copy_icon.svg';
import Instruction_PDF from '../../../../media/OperationInstruction.pdf';
import { ImportResource } from '../../../../models/DownloadImportModel';
import { ToastType } from '../../../../models/NavBarModel';
import { formatDate, TimeFormater } from '../../../../utils/TimeFormatUtil';

export default function Entitlement() {
  const params = useParams();
  const { id } = params;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const {
    listFields,
    dataList,
    pageInfo,
    totalPage,
    totalCount,
    activityLogs,
    entitlementOptionDatas,
    entitlementOption,
    userAdminType,
    checkedList,
    importedFile,
    adminUser,
  } = useSelector((state) => ({
    listFields: state.fortuneBagEntitlement.listDisplayFields,
    dataList: state.fortuneBagEntitlement.pagedList,
    pageInfo: state.fortuneBagEntitlement.pageInfo,
    totalPage: state.fortuneBagEntitlement.totalPage,
    totalCount: state.fortuneBagEntitlement.totalCount,
    activityLogs: state.customerList.activityLogs,
    entitlementOptionDatas: state.fortuneBagEntitlement.entitlementOptionDatas,
    entitlementOption: state.fortuneBagEntitlement.entitlementOption,
    userAdminType: state.users.adminType,
    checkedList: state.offerTypeLabel.checkedList,
    importedFile: state.uploadFiles.importedFile,
    adminUser: state.users,
  }));

  const TAB_METHOD = 'Method';
  const TAB_CUSTOMER_LIST = 'Customer list';
  const isMerchant = userAdminType === ADMIN_TYPE.MERCHANT_ADMIN;
  const isAdmin = userAdminType === ADMIN_TYPE.HKTB_ADMIN;
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const hiddenFileInput = React.useRef(null);
  const hasActivityLogs = activityLogs.length > 0;
  const [uploadedFileName, setUploadedFileName] = useState('');

  useEffect(() => {
    dispatch({ type: 'customerList/clearData' });
    dispatch({ type: 'fortuneBagEntitlement/clearData' });
    dispatch(
      createAction('customerList/getCustomerActivityLog')({
        fortuneBagId: id,
      }),
    );

    dispatch(
      createAction('fortuneBagEntitlement/getEntitlements')({
        campaignId: id,
      }),
    );
  }, [dispatch, id]);

  useEffect(() => {
    const searchDict = queryString.parse(location.search);
    const searchKey = searchDict['search'];
    const stringPage = searchDict['page'] || 1;
    const page = parseInt(stringPage);
    dispatch(
      createAction('fortuneBagEntitlement/getList')({
        ...searchDict,
        page,
        filterName: searchKey,
        campaignId: id,
      }),
    );
  }, [dispatch, location, id]);

  useEffect(() => {
    if (importedFile) {
      dispatch(
        createAction('downloadAndImport/importFile')({
          related: {
            entitlementId: entitlementOptionDatas[0].entitlementId,
            fileName: uploadedFileName,
          },
          file: importedFile,
          from: ImportResource.optionBEntitlementCustomers,
        }),
      );

      dispatch({
        type: 'uploadFiles/updateState',
        payload: { importedFile: null },
      });

      if (
        entitlementOptionDatas[0].entitledCustomerFileName !== uploadedFileName
      ) {
        let newDatas = [...entitlementOptionDatas];
        newDatas[0].entitledCustomerFileName = uploadedFileName;
        dispatch(
          createAction('fortuneBagEntitlement/updateState')({
            entitlementOptionDatas: newDatas,
          }),
        );
      }
    }
  }, [importedFile]);

  // utils
  const fieldForDisplay = (field) => {
    if (typeof field !== 'string') {
      return field;
    }

    const regex = '([^\\/]+.csv$)';
    const found = field.match(regex);
    return found ? found[0] : field;
  };

  // time is only for merchant admin optionC download btn
  const optionButton = (type, content, time = '') => {
    const generalButton = (content, image, action, extraField, requires) => {
      return (
        <AuthButton
          className="lebel-right-button"
          onClick={() => {
            action && action();
          }}
          requires={requires}
          variant="link"
        >
          {content}
          {image && <Image src={image} />}
          {extraField && extraField}
        </AuthButton>
      );
    };

    let image,
      action,
      extraField,
      requires,
      displayString = content;

    const handleChange = (event) => {
      const fileUploaded = event.target.files[0];
      console.log('@@77', fileUploaded);
      setUploadedFileName(fileUploaded.name);
      dispatch(
        createAction('uploadFiles/uploadFile')({
          file: fileUploaded,
          source: event,
        }),
      );
      event.target.value = null;
    };
    switch (type) {
      case FORTUNEBAG_OPTIONS_BUTTON_TYPE.COPY:
        image = CopyIcon;
        action = () => {
          copy(content);
          dispatch({
            type: 'navBars/updateState',
            payload: {
              saveDiscardToastShowing: {
                value: true,
                type: ToastType.merchantContentCopied,
              },
            },
          });
        };
        displayString = '';
        break;
      case FORTUNEBAG_OPTIONS_BUTTON_TYPE.UPLOAD:
        displayString = 'Re-upload';
        action = () => {
          console.log('re upload btn clicked!');
          hiddenFileInput.current.click();
        };
        requires =
          PermissionCodes.changeFortunebagEntitlementCustomerParticipationRecord;
        extraField = (
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{ display: 'none' }}
            accept=".csv"
          />
        );
        break;
      case FORTUNEBAG_OPTIONS_BUTTON_TYPE.DOWNLOAD:
        extraField = (
          <a href={content} className="lebel-right-button">
            Download
          </a>
        );
        displayString = '';
        break;
      default:
        break;
    }
    return generalButton(displayString, image, action, extraField);
  };

  const MerchantTable = () => {
    let header = [
      {
        displayName: 'Merchant name',
        fieldName: 'merchantName',
      },
    ];
    switch (entitlementOption) {
      case FORTUNEBAG_ENTITLEMENT_OPTIONS.A:
        header.push(
          {
            displayName: 'Merchant ID',
            fieldName: 'merchantId',
            fieldButton: FORTUNEBAG_OPTIONS_BUTTON_TYPE.COPY,
          },
          {
            displayName: 'Secret Key',
            fieldName: 'secretKey',
            fieldButton: FORTUNEBAG_OPTIONS_BUTTON_TYPE.COPY,
          },
        );
        break;
      case FORTUNEBAG_ENTITLEMENT_OPTIONS.B:
        header.push({
          displayName: 'Static link',
          fieldName: 'merchantStaticLink',
          fieldButton: FORTUNEBAG_OPTIONS_BUTTON_TYPE.COPY,
        });
        break;
      case FORTUNEBAG_ENTITLEMENT_OPTIONS.C:
        header.push(
          {
            displayName: 'Fortune bags quantity',
            fieldName: 'fortuneBagQuantity',
          },
          {
            displayName: 'Batch no.',
            fieldName: 'batchNo',
          },
          {
            displayName: 'Last updated time',
            fieldName: 'displayLastModifiedDate',
          },
          {
            displayName: 'Fortune bag list',
            fieldName: 'fortuneBagUrl',
            fieldButton: FORTUNEBAG_OPTIONS_BUTTON_TYPE.DOWNLOAD,
          },
        );
        break;
      default:
        break;
    }

    return (
      <>
        <label className="create-section-title">MERCHANTS</label>
        <table className="merchant-table">
          <thead>
            <tr>
              {header.map((headerContent, index) => {
                return (
                  <th className="all-list-scrollable-area-title, common-name-area-without-status">
                    {headerContent.displayName}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {entitlementOptionDatas
              .sort(function (a, b) {
                return b.batchNo - a.batchNo;
              })
              .map((fieldContent, index) => {
                return (
                  <tr>
                    {header.map((headerContent) => {
                      const fieldString = fieldContent[headerContent.fieldName];
                      const fieldButtonType = headerContent.fieldButton;
                      return (
                        <td>
                          {fieldForDisplay(fieldString)}
                          {fieldButtonType &&
                            optionButton(fieldButtonType, fieldString)}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </>
    );
  };

  // get merchant admin datas based on current option type
  const merchantAdminDatas = () => {
    let title = '',
      tab = '',
      fields = [];
    switch (entitlementOption) {
      case FORTUNEBAG_ENTITLEMENT_OPTIONS.A:
        title = 'Option A';
        tab = 'OPTION A';
        fields = [
          {
            title: 'Merchant name',
            field: entitlementOptionDatas[0]?.merchantName,
          },
          {
            title: 'Merchant ID',
            field: entitlementOptionDatas[0]?.merchantId,
            titlebutton: FORTUNEBAG_OPTIONS_BUTTON_TYPE.COPY,
          },
          {
            title: 'Secret Key',
            field: entitlementOptionDatas[0]?.secretKey,
            titlebutton: FORTUNEBAG_OPTIONS_BUTTON_TYPE.COPY,
          },
        ];
        break;
      case FORTUNEBAG_ENTITLEMENT_OPTIONS.B:
        title = 'Option B';
        tab = 'OPTION B';
        fields = [
          {
            title: 'Static link',
            field: entitlementOptionDatas[0]?.merchantStaticLink,
            titlebutton: FORTUNEBAG_OPTIONS_BUTTON_TYPE.COPY,
          },
          {
            title: 'Entitled customer (.csv)',
            field: entitlementOptionDatas[0]?.entitledCustomerFileName || '-',
            titlebutton: FORTUNEBAG_OPTIONS_BUTTON_TYPE.UPLOAD,
          },
        ];
        break;
      case FORTUNEBAG_ENTITLEMENT_OPTIONS.C:
        title = 'Option C';
        tab = 'OPTION C';
        fields = [
          {
            title: 'Fortune bag list',
            field: entitlementOptionDatas[0]?.fortuneBagUrl,
            timeString: formatDate(
              entitlementOptionDatas[0]?.creationDate,
              TimeFormater.monthDayYearTime,
            ),
            fieldButton: FORTUNEBAG_OPTIONS_BUTTON_TYPE.DOWNLOAD,
          },
        ];
        break;
      default:
        break;
    }

    return {
      title: title,
      tab: tab,
      fields: fields,
    };
  };

  const firstTabName = () => {
    return isMerchant ? TAB_METHOD : merchantAdminDatas().tab;
  };

  const customerList = () => {
    if (entitlementOption !== FORTUNEBAG_ENTITLEMENT_OPTIONS.B) {
      return;
    }

    return (
      <BaseTabListContainer
        hideTab={true}
        groupActions={groupActions}
        searchPlaceholder={'Search by customer ID'}
        pageInfo={pageInfo}
        totalCount={totalCount}
        listContent={
          <BaseListContainer
            fields={listFields}
            dataList={dataList}
            totalPage={totalPage}
            model={'fortuneBagEntitlement'}
            permissionGroup={
              PermissionCodes.fortunebagEntitlementCustomerParticipationRecord
            }
            actions={['Delete']}
            deleteInfo={{
              data: [],
              title: 'Entitlement Customers',
              onComfirm: {},
            }}
            hasOtherActions={true}
            customActions={{
              deactive: (item) => {
                dispatch(
                  createAction(
                    'fortuneBagEntitlement/activeEntitlementCustomers',
                  )({
                    data: {
                      id: item.pk,
                      isActive: item.status === 'Active' ? false : true,
                    },
                    afterAction: () => history.push(location),
                  }),
                );
              },
            }}
          />
        }
      />
    );
  };

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setShowDeletePrompt(true);
      },
      requires:
        PermissionCodes.changeFortunebagEntitlementCustomerParticipationRecord,
    },
  ];

  const getTabs = () => {
    // use entitlementOption and isMerchant to determine layout
    const getSections = () => {
      let sections = [];
      if (isAdmin) {
        sections.push(<MerchantTable />);
      }

      if (isMerchant) {
        sections.push(
          <Options
            title={merchantAdminDatas().title}
            fields={merchantAdminDatas().fields.map((data) => {
              return {
                title: data.title,
                field: fieldForDisplay(data.field),
                timeString: data.timeString || '',
                titleButton:
                  data.titlebutton &&
                  optionButton(data.titlebutton, data.field),
                fieldButton:
                  data.fieldButton &&
                  optionButton(data.fieldButton, data.field),
              };
            })}
          />,
        );
      }
      if (
        entitlementOption === FORTUNEBAG_ENTITLEMENT_OPTIONS.B &&
        hasActivityLogs &&
        [
          PermissionCodes.viewFortunebagEntitlementCustomerParticipationRecord,
          PermissionCodes.changeFortunebagEntitlementCustomerParticipationRecord,
        ].filter((val) => checkHasPermission(adminUser, val)).length > 0
      ) {
        sections.push(
          <div className="customer">
            <RecordTimelineCard
              title={'RECORDS'}
              data={activityLogs}
              entitlement={true}
            />
          </div>,
        );
      }

      return sections;
    };

    let tabs = [];
    tabs.push({
      name: isMerchant ? TAB_METHOD : firstTabName(),
      content: (
        <>
          <div className="scroll-container-common entitlement-content-section">
            <ContentSections sections={getSections()} hidePreview={true} />
            <SaveAndBackButtons
              saveTempText={''}
              saveText={''}
              backAction={() => history.goBack()}
              saveAction={() => {}}
              saveTempAction={() => {}}
            />
          </div>
        </>
      ),
    });

    // hide CustomerList
    if (
      // isMerchant &&
      entitlementOption === FORTUNEBAG_ENTITLEMENT_OPTIONS.B &&
      [
        PermissionCodes.viewFortunebagEntitlementCustomerParticipationRecord,
        PermissionCodes.changeFortunebagEntitlementCustomerParticipationRecord,
      ].filter((val) => checkHasPermission(adminUser, val)).length > 0
    ) {
      tabs.push({
        name: TAB_CUSTOMER_LIST,
        content: customerList(),
      });
    }
    return tabs;
  };

  const buttons = [
    <AuthButton
      title="Operation Instruction pdf"
      action={() => {
        window.open(Instruction_PDF);
      }}
    />,
  ];

  const activeTab = getHashKeyString(location.hash) || firstTabName();

  return (
    <>
      <CustomListComponent
        caution={{
          title: 'Entitlement',
        }}
        defaultActiveKey={activeTab}
        onTabChange={(key) => {
          history.push({ pathname: location.pathname, hash: key });
        }}
        hideTab={false}
        breadcrumb={<CustomBreadcrumb name={'Entitlement'} />}
        buttons={buttons}
        tabs={getTabs()}
      />
      <DeletePrompt
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch(
            createAction('fortuneBagEntitlement/delete')({
              afterAction: () => history.push(location),
            }),
          );
        }}
        data={checkedList}
        title={'Entitlement Customer'}
      />
    </>
  );
}
