import React from 'react';
import './Loading.scss';
import { Image } from 'react-bootstrap';
import LoadingIcon from '../../assets/images/loading.gif';

function Loading({ customClass, src = LoadingIcon }) {
  return (
    <div className={`${customClass} loading-area`}>
      <Image src={src} className="loading-icon"></Image>
    </div>
  );
}

export default Loading;
