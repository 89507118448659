import React, { useEffect } from 'react';
import CustomListComponent from '../../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import ContentSections from '../../../components/base/ContentSections';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { createAction } from '../../../utils';
import { OnlyBackButton } from '../../../components/base/BottomStepComponent';
import MessageOverviewTableSection from '../../../components/message/MessageOverviewTableSection';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { APIStatus, MessageTag } from '../../../config/CustomEnums';
import Loading from '../../../components/base/Loading';

function MessageOverview() {
  const queryString = require('query-string');
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const { selectedMessage, status } = useSelector((state) => ({
    selectedMessage: state.messageList.message,
    status: state.createMessage.createStatus,
  }));

  useEffect(() => {
    dispatch(
      createAction('messageList/getMessage')({
        messagePK: params.id,
      }),
    );
  }, [dispatch, params.id]);

  useEffect(() => {
    return () => {
      dispatch(createAction('createMessage/clearData')());
      dispatch(createAction('messageList/clearData')());
    };
  }, [dispatch]);

  const tabs = [
    {
      name: '',
      content:
        status === APIStatus.calling ? (
          <Loading />
        ) : (
          <div className="scroll-container-common">
            <ContentSections
              sections={[
                <MembershipInfoCard
                  title={'PROPERTIES'}
                  data={selectedMessage}
                  fields={[
                    [{ title: 'Message channel', field: 'displayChannels' }],
                    [
                      {
                        title: 'Related campaign',
                        field: 'relatedCampaignName',
                      },
                    ],
                    [
                      {
                        title: 'Target customers',
                        field: 'displayTargetCustomer',
                      },
                    ],
                    [
                      {
                        title: 'Target segments',
                        field: 'displayTargetedSegments',
                      },
                    ],
                    [{ title: 'Delivery Time', field: 'deliveryDate' }],
                  ]}
                />,
                <MessageOverviewTableSection data={selectedMessage} />,
              ]}
              hidePreview={true}
            />
          </div>
        ),
    },
  ];

  const buttons = [
    selectedMessage.status === MessageTag.sent ? null : (
      <AuthButton
        title="Edit"
        action={() => history.push('edit/')}
        requires={PermissionCodes.changeMessage}
      />
    ),
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail: '', //view message
          title: 'View Message',
        }}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={true}
        tabs={tabs}
        buttons={buttons}
      />
      <OnlyBackButton
        backAction={() => {
          dispatch(createAction('messageList/clearMessageState')());
          history.push({
            pathname: '/messages',
            search: queryString.stringify({
              page: 1,
              rank: true,
              search: '',
            }),
          });
        }}
      />
    </>
  );
}

export default MessageOverview;
