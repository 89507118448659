import { APIStatus, LanguageConfig , SESSION_KEYS} from '../config/CustomEnums';
import {
  createStoreCategory,
  getStoreCategory,
  updateStoreCategory,
  deleteStoreCategories,
  getStoreCategoryList,
} from '../services/StoreCategoryAPIHelper';
import {
  createAction,
  convertPKToId,
  getObjectFromSessionStorage,
  removeFromSessionStorage,
} from '../utils';
import { apiWithResponseHandle } from './LoadingUtil';

const getInitialState = () => ({
  category: {
    name: null,
    order: null,
    subCategories: [],
    translations: [],
  },
  errorFields: [],
  formChanged: false,
  saved: -1,
  createStatus: APIStatus.none,
});

export const STORE_CATEGORY_SESSION_KEY = SESSION_KEYS.STORE_CATEGORY_SESSION_KEY;

export const CREATE_STORE_CATEGORY_SUCCESS = 'CREATE_STORE_CATEGORY_SUCCESS';
export const CREATE_STORE_CATEGORY_FAILD = 'CREATE_STORE_CATEGORY_FAILD';

export const STORE_CATEGORY_ORDER_LAST = 'STORE_CATEGORY_ORDER_LAST';

const validateForm = (data) => {
  const { name, order, subCategories = [], translations = [] } = data;
  const errorFields = [];
  if (name === '' || name === null || name === undefined) {
    errorFields.push({
      field: 'name',
      message: 'Please provide a name',
    });
  }
  const displayPriority = parseInt(order);
  if (isNaN(displayPriority)) {
    errorFields.push({
      field: 'order',
      message: 'Minimum value is 1',
    });
  }
  subCategories.forEach((subCategory, index) => {
    const { name, order } = subCategory;
    const hasName = !(name === '' || name === null || name === undefined);
    const displayPriority = parseInt(order);
    if (!isNaN(displayPriority) || hasName) {
      if (!hasName) {
        errorFields.push({
          field: `sub-category-name-en-${index + 1}`,
          message: 'Please provide a subcategory name',
        });
      }
      if (isNaN(displayPriority)) {
        errorFields.push({
          field: `sub-category-order-${index + 1}`,
          message: 'Minimum value is 1',
        });
      }
    }
  });
  return errorFields;
};
const assembleStoreSubCategory = (subCategory) => {
  const translations = [];
  const apiTranslations = subCategory.translations.edges || [];
  apiTranslations.forEach((item) => {
    let language = item.node.language;
    if (language === 'ZH_HANS') {
      language = LanguageConfig.simplifiedChinese;
    }
    if (language === 'ZH_HANT') {
      language = LanguageConfig.traditionalChinese;
    }
    translations.push({
      name: item.node.name,
      id: item.node.pk,
      language: language,
    });
  });
  return {
    id: subCategory.pk,
    name: subCategory.name,
    order: subCategory.displayPriority,
    translations,
  };
};

const fixTranslations = (translations) => {
  const translationsToDelete = [];
  const fixedTranslations = translations.filter((translation) => {
    const { name, id } = translation;
    if (id && (name === '' || name === null || name === undefined)) {
      translationsToDelete.push(id);
      return false;
    }
    return true;
  });
  return [translationsToDelete, fixedTranslations];
};

const assembleStoreCategory = (category) => {
  const translations = [];
  const apiTranslations = category.translations.edges || [];
  apiTranslations.forEach((item) => {
    let language = item.node.language;
    if (language === 'ZH_HANS') {
      language = LanguageConfig.simplifiedChinese;
    }
    if (language === 'ZH_HANT') {
      language = LanguageConfig.traditionalChinese;
    }
    translations.push({
      name: item.node.name,
      id: item.node.pk,
      language: language,
    });
  });
  const subCategories = category.subcategories.edges.map(({ node }) =>
    assembleStoreSubCategory(node),
  );
  return {
    name: category.name,
    order: category.displayPriority,
    subCategories,
    translations,
  };
};

export default {
  namespace: 'createStoreCategory',
  state: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    reset(state, { payload }) {
      return getInitialState();
    },
    assembleStoreCategory(state, { payload }) {
      const { category } = payload;
      return {
        ...state,
        category: assembleStoreCategory(category),
      };
    },
    loadStoreCategoryFromSessionStorage(state, { payload }) {
      try {
        const category = getObjectFromSessionStorage(
          STORE_CATEGORY_SESSION_KEY,
        );
        if (category) {
          return {
            ...state,
            category: {
              ...category,
            },
          };
        }
      } catch (error) {
        console.log(error);
      }
      return {
        ...state,
      };
    },
    formHasChanged(state, { payload }) {
      return {
        ...state,
        formChanged: true,
      };
    },
  },
  effects: {
    getCategory: [
      function* ({ payload }, { call, select, put, all }) {
        yield put({
          type: 'updateState',
          payload: { createStatus: APIStatus.calling },
        });
        const id = convertPKToId('StoreCategoryNode', payload.id);
        const serviceArgs = [getStoreCategory, id];
        function* onSuccess(data) {
          console.log('getOneStore onSuccess :', data);
          yield all([
            put({
              type: 'assembleStoreCategory',
              payload: { category: data.storeCategory },
            }),
            put({
              type: 'updateState',
              payload: { createStatus: APIStatus.none },
            }),
          ]);
        }
        function* onError(err) {
          console.log('getOneStoreCategory onError :', err);
          yield put({
            type: 'updateState',
            payload: { createStatus: APIStatus.failed },
          });
        }
        function* onArgumentsError(err) {
          console.log('getOneStoreCategory arguments error :', err);
          yield put({
            type: 'updateState',
            payload: { createStatus: APIStatus.failed },
          });
        }
        yield apiWithResponseHandle(
          serviceArgs,
          onSuccess,
          onError,
          onArgumentsError,
        );
      },
      { type: 'takeLatest' },
    ],
    checkAndSave: [
      function* ({ payload }, { call, select, put, take, race }) {
        const { name, order, subCategories, translations } = payload;
        const errorFields = validateForm(payload);
        if (errorFields && errorFields.length > 0) {
          yield put({ type: 'updateState', payload: { errorFields } });
          return;
        }
        yield put({
          type: 'updateState',
          payload: { createStatus: APIStatus.calling },
        });
        yield put(
          createAction('createStoreCategory')({
            name: name,
            order,
            subCategories,
            translations,
          }),
        );
        const [success, failed] = yield race([
          take(CREATE_STORE_CATEGORY_SUCCESS),
          take(CREATE_STORE_CATEGORY_FAILD),
        ]);
        if (success) {
          yield put({
            type: 'updateState',
            payload: { formChanged: false, createStatus: APIStatus.success },
          });
          removeFromSessionStorage(STORE_CATEGORY_SESSION_KEY);
          console.log(success);
        } else {
          yield put({
            type: 'updateState',
            payload: { createStatus: APIStatus.failed },
          });
          console.log(failed);
        }
      },
      { type: 'takeLatest' },
    ],
    checkAndUpdate: [
      function* ({ payload }, { call, select, put, all }) {
        const {
          name,
          order,
          subCategories,
          categoryID,
          translations,
        } = payload;
        const id = parseInt(categoryID);
        if (isNaN(id)) {
          return;
        }
        const errorFields = validateForm(payload);
        if (errorFields && errorFields.length > 0) {
          yield put({ type: 'updateState', payload: { errorFields } });
          return;
        }
        yield put({
          type: 'updateState',
          payload: { createStatus: APIStatus.calling },
        });
        const [translationsToDelete, fixedTranslations] = fixTranslations(
          translations,
        );

        const input = {
          name,
          displayPriority: parseInt(order),
          subcategories: subCategories.map((subCategory) => {
            const [translationsToDelete, fixedTranslations] = fixTranslations(
              subCategory.translations,
            );
            const data = {
              name: subCategory.name,
              displayPriority: parseInt(subCategory.order),
              translationsToDelete,
              translations: fixedTranslations,
            };
            if (subCategory.id) {
              data.id = subCategory.id;
            }
            return data;
          }),
          translations: fixedTranslations,
          translationsToDelete,
          id,
        };
        console.log(input);
        const response = yield call(updateStoreCategory, input);
        const responseData = response.data;
        if (
          responseData.errors ||
          responseData.data.updateStoreCategory.errors
        ) {
          yield put({
            type: 'updateState',
            payload: { createStatus: APIStatus.failed },
          });
          return;
        }
        yield put({
          type: 'updateState',
          payload: { formChanged: false, createStatus: APIStatus.success },
        });
        removeFromSessionStorage(STORE_CATEGORY_SESSION_KEY);
      },
      { type: 'takeLatest' },
    ],
    createStoreCategory: [
      function* ({ payload }, { call, put, all }) {
        const { name, order, subCategories, translations } = payload;
        const input = {
          name,
          displayPriority: parseInt(order),
          translations,
          subcategories: subCategories
            .filter(
              (subCategory) => !isNaN(subCategory.order) && subCategory.name,
            )
            .map((subCategory) => ({
              name: subCategory.name,
              displayPriority: parseInt(subCategory.order),
              translations: subCategory.translations,
            })),
        };
        const response = yield call(createStoreCategory, input);
        const responseData = response.data;
        if (
          responseData.errors ||
          responseData.data.createStoreCategory.errors
        ) {
          yield put({ type: CREATE_STORE_CATEGORY_SUCCESS });
          return;
        }
        yield put({ type: CREATE_STORE_CATEGORY_SUCCESS });
      },
      { type: 'takeLatest' },
    ],
    updateCategoryOrder: [
      function* ({ payload }, { call, put, select }) {
        const { id, order, afterAction } = payload;
        const input = {
          id,
        };
        if (order === STORE_CATEGORY_ORDER_LAST) {
          const countResponse = yield call(getStoreCategoryList, '', '');
          const countResponseData = countResponse.data;
          if (countResponseData?.data?.error) {
            return;
          }
          input.displayPriority =
            countResponseData.data.storeCategories.totalCount;
        } else {
          input.displayPriority = order;
        }
        const response = yield call(updateStoreCategory, input);
        const responseData = response.data;
        if (
          responseData.errors ||
          responseData.data.updateStoreCategory.errors
        ) {
          return;
        }
        if (afterAction) {
          afterAction();
        }
      },
      {
        type: 'takeEvery',
      },
    ],
    deleteCategories: [
      function* ({ payload }, { call, put, select }) {
        const { ids, afterAction } = payload;
        yield call(deleteStoreCategories, ids);
        if (afterAction) {
          afterAction();
        } else {
          yield put({
            type: 'updateState',
            payload: { createStatus: APIStatus.success },
          });
        }
      },
      {
        type: 'takeEvery',
      },
    ],
    duplicateCategory: [
      function* ({ payload }, { call, put, race, take }) {
        const { pk, afterAction } = payload;
        const id = convertPKToId('StoreCategoryNode', pk);
        const response = yield call(getStoreCategory, id);
        const responseData = response.data;
        if (
          responseData === undefined ||
          responseData.data?.storeCategory.pk === null
        ) {
          return;
        }
        const category = assembleStoreCategory(responseData.data.storeCategory);
        yield put(
          createAction('createStoreCategory')({
            name: `copy of ${category.name}`,
            order: category.order + 1,
            subCategories: category.subCategories.map((subCategory) => ({
              order: subCategory.order,
              name: `copy of ${subCategory.name}`,
              translations: subCategory.translations.map((translation) => ({
                language: translation.language,
                name: translation.name,
              })),
            })),
            translations: category.translations.map((translation) => ({
              language: translation.language,
              name: translation.name,
            })),
          }),
        );
        const [success, failed] = yield race([
          take(CREATE_STORE_CATEGORY_SUCCESS),
          take(CREATE_STORE_CATEGORY_FAILD),
        ]);
        if (success && afterAction) {
          afterAction();
        }
      },
      {
        type: 'takeEvery',
      },
    ],
  },
};
