import React, { useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import { TermsAndConditionsType } from '../../../config/CustomEnums';

function Filter({ backAction = () => { } }) {
  const location = useLocation();

  const parsedSearch = queryString.parse(location.search);
  const searchType = parsedSearch['type'] || '';
  const searchLastModified = parsedSearch['last_modified'] || '';
  const searchCreateAt = parsedSearch['create_at'] || '';

  const [type, setType] = useState(searchType);
  const [lastModified, setLastModified] = useState(searchLastModified);
  const [createAt, setCreateAt] = useState(searchCreateAt);

  const content = [
    {
      title: 'Type',
      data: [
        { pk: TermsAndConditionsType.merchant, name: 'Merchant T & C' },
        { pk: TermsAndConditionsType.generic, name: 'Generic T & C' },
      ],
      value: type,
      setValue: setType,
      componment: FilterTypeEnum.singleChoice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Last Modified',
      value: lastModified,
      setValue: setLastModified,
      componment: FilterTypeEnum.datetime,
    },
    {
      title: 'Create at',
      value: createAt,
      setValue: setCreateAt,
      componment: FilterTypeEnum.datetime,
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

export default Filter;
