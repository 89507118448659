import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useSelector, connect } from 'react-redux';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import BaseForm from '../../../components/base/v2/BaseForm';
import { createAction } from '../../../utils';
import CreateOfferTypeLabelContent from './CreateOfferTypeLabelContent';
import { APIStatus } from '../../../config/CustomEnums';

const CreateOfferTypeLabel = ({
  defaultValues,
  dispatch,
  hasUpdatedDefaultValues,
  formHasSubmitted,
  // apiStatus,
  // createStatus,
}) => {
  const params = useParams();
  const history = useHistory();
  const { id } = params;

  const { createStatus, apiStatus } = useSelector((state) => ({
    createStatus: state.offerTypeLabel.createStatus,
    apiStatus: state.offerTypeLabel.apiStatus,
  }));
  console.log('@@29: ', apiStatus, formHasSubmitted);
  useEffect(() => {
    if (id) {
      dispatch(createAction('offerTypeLabel/getOneDetail')({ id }));
    } else {
      dispatch(createAction('offerTypeLabel/loadFromCookie')());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(createAction('offerTypeLabel/clearData')());
    };
  }, []);

  useEffect(() => {
    if (apiStatus === APIStatus.success) {
      history.push({ pathname: '/offer_type_labels' });
    }
  }, [apiStatus]);

  return (
    <>
      <BaseForm
        defaultValues={defaultValues}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={formHasSubmitted}
        tempSave={(save, getValues) => {
          if (!save) {
            dispatch({
              type: 'offerTypeLabel/removeFromCookie',
            });
          } else {
            dispatch({
              type: 'offerTypeLabel/saveToCookie',
              payload: getValues(),
            });
          }
        }}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: 'Offer type label is successfully created.',
          steps: null,
          buttons: [
            {
              text: 'Back to offer type label list',
              action: () => {
                history.push({
                  pathname: '/offer_type_labels',
                });
              },
            },
          ],
        }}
        showFinishPop={createStatus === APIStatus.success}
        content={[<CreateOfferTypeLabelContent />]}
        currentStep={0}
        breadcrumb={
          <CustomBreadcrumb
            name={id ? defaultValues?.name : 'Create Offer type label'}
          />
        }
        caution={{
          detail: 'TBC',
          title: id ? defaultValues?.name : 'Create Offer type label',
        }}
      />
    </>
  );
};
const mapPropsToState = (state) => ({
  defaultValues: state.offerTypeLabel.detail,
  hasUpdatedDefaultValues: state.offerTypeLabel.hasUpdatedDefaultValues,
  formHasSubmitted: state.offerTypeLabel.formHasSubmitted,
  apiStatus: state.offerTypeLabel.apiStatus,
  createStatus: state.offerTypeLabel.createStatus,
});
export default connect(mapPropsToState)(CreateOfferTypeLabel);
