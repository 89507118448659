import React, { useEffect, useState } from 'react';
import Loading from '../../components/base/Loading';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { AdminLoginMethod } from '../../config/CustomEnums';
import { Accordion, Image } from 'react-bootstrap';
import menuLogo from '../../assets/images/menu_logo.svg';

function LoginMiddleware({ isLogin }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [samlLoginFailed, setSamlLoginFailed] = useState(false);
  console.log('@@14', samlLoginFailed);
  useEffect(() => {
    const loginToken = atob(Cookies.get('admin_login_token'));
    const {
      admin_login_email,
      admin_login_credential,
      admin_login_fail_reason,
      admin_session_index,
    } = JSON.parse(loginToken);

    if (!samlLoginFailed) {
      dispatch({
        type: 'users/login',
        payload: {
          username: admin_login_email,
          password: admin_login_credential,
          method: AdminLoginMethod.Saml,
          handleFailed: () => {
            setSamlLoginFailed(true);
          },
        },
      });
      dispatch({
        type: 'users/UpdateState',
        payload: {
          samlLogoutSessionIndex: admin_session_index,
        },
      });
    }

    // !samlLoginFailed &&
    //   dispatch({
    //     type: 'users/login',
    //     payload: {
    //       username: admin_login_email,
    //       password: admin_login_credential,
    //       method: AdminLoginMethod.Saml,
    //       handleFailed: () => {
    //         setSamlLoginFailed(true);
    //       },
    //     },
    //   });
  }, []);

  useEffect(() => {
    if (isLogin) {
      history.push('/');
    }
  }, [isLogin]);

  if (samlLoginFailed) {
    return (
      <div className="main-container">
        <div className={`main-content main-content-padding}`}>
          <div className="header-container"></div>
          <div className="dashboard-error">
            {`You do not have any permission to access Esolution CMS.\nPlease contact administrator.`}
          </div>
        </div>
        <div className={`sidebar-container sidebar-container-full-width`}>
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingLeft: '20px',
                paddingRight: '20px',
              }}
            >
              <Image src={menuLogo} className="side-bar-logo" />
            </div>
            <Accordion className="side-menu-nav-background"></Accordion>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Loading />
    </div>
  );
}

const mapPropsToState = (state) => ({
  isLogin: state.users.isLogin,
});

export default connect(mapPropsToState)(LoginMiddleware);
