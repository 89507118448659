import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { createAction } from '../../../utils';
import DeletePrompt from '../../../components/base/DeletePrompt';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import BaseListContainer from '../../base/BaseListContainer';
import AuthButton from '../../../components/base/AuthButton';
import { NavBarNames } from '../../../config/NavBarNameList';
import { PermissionCodes } from '../../../config/PermissionCodes';
import {
  BANNER_CONTENT_TYPE,
  DELETE_RELATED_SECTIONS,
} from '../../../config/CustomEnums';
import Filter from './Filter';

function BannerList() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { listFields, totalCount, checkedList, dataList, pageInfo, totalPage } =
    useSelector((state) => ({
      listFields: state.bannerList.listDisplayFields,
      dataList: state.bannerList.bannerList,
      pageInfo: state.bannerList.pageInfo,
      totalPage: state.bannerList.totalPage,
      totalCount: state.bannerList.totalCount,
      checkedList: state.bannerList.checkedList,
    }));

  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  const getPageList = () => {
    const querySearch = queryString.parse(history.location.search);
    dispatch({ type: 'bannerList/clearData' });
    dispatch(
      createAction('bannerList/getPageBannerList')({
        ...querySearch,
      }),
    );
  };

  useEffect(() => {
    return function () {
      dispatch({ type: 'bannerList/clearData' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPageList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location]);

  const updateBannerList = (pk, order, isPublished) => {
    const data = {};
    if (!pk) {
      return;
    }
    data.id = pk;
    if (isPublished !== null && isPublished !== undefined) {
      data.isPublished = isPublished;
    }
    if (order) {
      data.displayPriority = order;
    }
    dispatch(
      createAction('bannerList/updateBannerFromListAction')({
        data,
        afterAction: () => getPageList(),
      }),
    );
  };

  const buttons = [
    <AuthButton
      title="Create Banner"
      action={() => {
        history.push('banners/create');
      }}
      requires={PermissionCodes.changeBanner}
    />,
  ];

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.changeBanner,
    },
  ];

  const tabs = [
    {
      name: 'List of Banners',
      content: (
        <BaseTabListContainer
          hideTab={true}
          maxTabWidth={260}
          tabs={[]}
          searchPlaceholder={'Search by ID'}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount}
          filter={{
            hasFilter: true,
            componment: Filter,
          }}
          listContent={
            <BaseListContainer
              fields={listFields}
              dataList={dataList}
              totalPage={totalPage}
              sortable
              sortDisabled={false}
              rankDisabled
              model={'bannerList'}
              permissionGroup={PermissionCodes.banners}
              customClassName={'homepage-banner-list-table'}
              deleteInfo={{
                data: [],
                title: 'banner',
                relatedName: '',
                onComfirm: {},
                relatedSections: DELETE_RELATED_SECTIONS.BANNER,
              }}
              onSortEnd={(oldIndex, newIndex) => {
                if (oldIndex === newIndex) {
                  return;
                }
                const startOrder = dataList[0].order;
                const movedItem = dataList[oldIndex];
                updateBannerList(movedItem.pk, startOrder + newIndex);
              }}
              onOrderChangedByEdit={(pk, newOrder) => {
                updateBannerList(pk, newOrder);
              }}
              actions={['Edit', 'Delete', 'Duplicate']}
              hasOtherActions
              customActions={{
                moveToFirst: (item) => {
                  updateBannerList(item.pk, 1);
                },
                moveToLast: (item) => {
                  updateBannerList(item.pk, totalCount);
                },
                publish: {
                  action: (item) => {
                    updateBannerList(
                      item.pk,
                      item.displayPriority,
                      !item.isPublished,
                    );
                  },
                },
              }}
            />
          }
        />
      ),
    },
  ];

  return (
    <div className="banner-list-container">
      <CustomListComponent
        caution={{
          detail:
            'Create a banner to promote your campaign or product. You can drag and drop, or edit the order value to reorder the items.',
          title: NavBarNames.banners,
        }}
        defaultActiveKey={tabs[0].name}
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        hideTab={true}
      />
      <DeletePrompt
        data={checkedList}
        title={'banner'}
        relatedName={''}
        relatedSections={DELETE_RELATED_SECTIONS.BANNER}
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch(
            createAction('bannerList/delete')({
              afterAction: () => {
                history.push(history.location);
              },
            }),
          );
        }}
      />
    </div>
  );
}
export default BannerList;
