import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../campaign/campaignCreation/PropertiesGeneralSection.scss';
import './RewardSection.scss';
import CustomSwitchButton from '../base/CustomSwitchButton';
import BasePrompt from '../base/prompt/BasePrompt';
import {
  CustomTitleLabel,
  CustomNumberInputWithUnit,
} from './CustomBaseComponments';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import { EarningRuleErrorHandleField } from './EarningRuleHandleError';
import {
  isShowError,
  firstError,
} from '../campaign/campaignCreation/CreateCampaignHandleError';
import { createAction, useCompare } from '../../utils';
import { PermissionCodes } from '../../config/PermissionCodes';
import BaseMutipleSelectorV2 from '../base/BaseMultipleSelectorV2';

import { useHistory } from 'react-router-dom';
const options = (source) => {
  if (!source) {
    return [];
  }
  return source.map((item) => {
    return {
      name: item.storeName,
      value: item.storePK,
    };
  });
};

function GeneralPurchaseSection(props) {
  const { stores } = useSelector((state) => ({
    stores: state.storeModel.pagedStoreList,
  }));

  const dispatch = useDispatch();
  const defaultValue = props.defaultValue || {};
  const [minSpending, setMinSpending] = useState(defaultValue.minSpending);
  const [maxSpending, setMaxSpending] = useState(defaultValue.maxSpending);
  const [selectedStores, setSelectStores] = useState(
    defaultValue.selectedStores,
  );
  const [specialSkus, setSpecialSkus] = useState(defaultValue.specialSkus);
  const [showCreateStore, setShowCreateStore] = useState(false);
  const [eligibleDays, setEligibleDays] = useState(defaultValue.eligibleDays);
  const [isExcludeDeliveryCost, setIsExcludeDeliveryCost] = useState(
    defaultValue.isExcludeDeliveryCost || false,
  );
  const [isExcludeOtherCharges, setIsExcludeOtherCharges] = useState(
    defaultValue.isExcludeOtherCharges || false,
  );

  const vals = {
    minSpending,
    maxSpending,
    selectedStores,
    eligibleDays,
    isExcludeDeliveryCost,
    isExcludeOtherCharges,
    specialSkus,
  };
  const hasValsChanged = useCompare(vals);
  const history = useHistory();

  const onSubmit = props.onSubmit;
  const onSubmitAction = props.onSubmitAction || (() => {});
  const { errorFields, skuFakeData } = useSelector((state) => ({
    errorFields: state.createEarningRules.errorFields,
    skuFakeData: state.bannerList.skusWithCategoryList,
  }));
  const isError = (name) => {
    const error = isShowError(
      EarningRuleErrorHandleField[name].name,
      errorFields,
    );

    return error;
  };

  useEffect(() => {
    if (hasValsChanged) {
      dispatch({ type: 'createEarningRules/changeVals', payload: { vals } });
    }
  });

  useEffect(() => {
    dispatch(createAction('storeModel/getPagedStoreList')());
    dispatch(createAction('bannerList/getProductList')());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (onSubmit) {
      onSubmitAction(vals);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmit]);

  useEffect(() => {
    firstError(`${EarningRuleErrorHandleField.minSpending.name}`, errorFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorFields]);

  // const skuFakeData = [
  //   { pk: 1, name: 'SHOPFIY-000001', category: { pk: 1, name: 'C1' } },
  //   { pk: 2, name: 'SHOPFIY-000002', category: { pk: 1, name: 'C1' } },
  //   { pk: 3, name: 'SHOPFIY-000003', category: { pk: 2, name: 'C2' } },
  //   { pk: 4, name: 'SHOPFIY-000004', category: { pk: 3, name: 'C3' } },
  // ];

  return (
    <>
      <label className="create-section-title">GENERAL PURCHASE</label>

      <CustomTitleLabel title="Mininum spending" />
      <div style={{ display: 'flex' }}>
        <label className="help-message margin-right-10-percent">HK$</label>
        <input
          type="number"
          onChange={({ target }) => setMinSpending(target.value)}
          className="custom-number-input-short"
          defaultValue={minSpending}
          onFocus={() => {}}
        />
      </div>
      <ErrorFieldMessage
        id={`${EarningRuleErrorHandleField.minSpending.name}`}
        error={isError('minSpending')}
        message={`${EarningRuleErrorHandleField.minSpending.message}`}
      />

      <CustomTitleLabel title="Maxinum spending (optional)" />
      <div style={{ display: 'flex' }}>
        <label className="help-message margin-right-10-percent">HK$</label>
        <input
          type="number"
          onChange={({ target }) => setMaxSpending(target.value)}
          className="custom-number-input-short"
          defaultValue={maxSpending}
          onFocus={() => {}}
        />
      </div>
      <ErrorFieldMessage
        id={`${EarningRuleErrorHandleField.maxSpending.name}`}
        error={isError('maxSpending')}
        message={`${EarningRuleErrorHandleField.maxSpending.message}`}
      />

      {/* <CustomMultipleSelect
        defaultSelected={selectedStores}
        options={options(stores)}
        title={'Limited to store (optional)'}
        onSelectChange={(options) => {
          setSelectStores(options);
        }}
        createTitle={'Add'}
        addAction={() => setShowCreateStore(true)}
        addRequires={PermissionCodes.addStore}
      /> */}

      <BaseMutipleSelectorV2
        title={'Limited to store (optional)'}
        namespace="storeModel"
        searchPlaceholder="Search by name or ID"
        data={{
          sourceData: stores,
          targetData: selectedStores,
          targetChange: setSelectStores,
        }}
        addButton={{
          title: 'Add Store',
          action: () => {
            setShowCreateStore(true);
          },
          requires: PermissionCodes.addStore,
        }}
        requires={PermissionCodes.addStore}
      />

      <BaseMutipleSelectorV2
        title={'Special SKU (optional)'}
        namespace="storeCategoryList"
        searchPlaceholder="Search by name or ID"
        data={{
          sourceData: skuFakeData,
          targetData: specialSkus,
          targetChange: setSpecialSkus,
        }}
        groupKey="category"
      />

      <CustomNumberInputWithUnit
        title="Eligible number of days since purchase (optional)"
        setValue={setEligibleDays}
        unit="days"
        defaultValue={eligibleDays}
      />

      <CustomTitleLabel title="Will exclude delivery cost" />
      <CustomSwitchButton
        defaultChecked={isExcludeDeliveryCost}
        onChange={(isChecked) => {
          setIsExcludeDeliveryCost(isChecked);
        }}
      />

      <CustomTitleLabel title="Will exclude other charges" />
      <CustomSwitchButton
        defaultChecked={isExcludeOtherCharges}
        onChange={(isChecked) => {
          setIsExcludeOtherCharges(isChecked);
        }}
      />
      <BasePrompt
        show={showCreateStore}
        closeAction={() => setShowCreateStore(false)}
        rightButton={{
          text: 'create store',
          action: () =>
            history.push({
              pathname: '/stores/create',
              state: {
                from: history.location,
                title: 'Continue to Create Earning Rule',
                content: 'You can continue to create the earning rule.',
              },
            }),
        }}
        title={'Go to create store?'}
        description={'Go to create store'}
      />
    </>
  );
}

export default GeneralPurchaseSection;
