import React, { useState, useEffect } from 'react';
import './CreateCategoryPrompt.scss';
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from '../../../utils';
import BasePrompt from '../../base/prompt/BasePrompt';
import { LanguageConfig, APIStatus } from '../../../config/CustomEnums';

function CreateOfferTypePrompt(props) {
  const defaultShow = props.show;
  const onClose = props.onClose || (() => {});

  const { languages, createdNew } = useSelector((state) => ({
    languages: state.language.allList,
    createdNew: state.offerTypeLabel.createdNew,
  }));

  const [show, setShow] = useState(false);
  const [offerTypeValues, setOfferTypeValues] = useState({});

  const dispatch = useDispatch();

  const handleClose = () => {
    setShow(false);
    setOfferTypeValues({});
    onClose({ label: createdNew?.name, value: createdNew });
  };
  const startNewSection = () => {
    let translations = {};
    Object.keys(offerTypeValues).forEach((lang) => {
      translations[lang] = {};
      translations[lang].name = offerTypeValues[lang];
    });

    dispatch(
      createAction('offerTypeLabel/createOrUpdate')({
        translations,
      }),
    );
  };

  useEffect(() => {
    console.log('@@46: ', createdNew);

    if (createdNew) {
      dispatch(
        createAction('offerTypeLabel/getList')({ isSelectorLoad: true }),
      );
      handleClose();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdNew]);

  if (!show && defaultShow) {
    setShow(true);
  }

  const translation = (category, index) => {
    return (
      <div
        key={`${category.code}-${index}`}
        className="create-category-prompt-input-item"
      >
        <label className="create-section-label create-section-label-bottom-space">
          {category.sourceName}
          {category.code !== LanguageConfig.english
            ? '(optional)'
            : ''}
        </label>
        <input
          className="create-category-prompt-input-text"
          type="text"
          onChange={({ target }) =>
            setOfferTypeValues({
              ...offerTypeValues,
              [category.code]: target.value,
            })
          }
        />
      </div>
    );
  };

  const getInputArea = () => {
    return (
      <>
        <div className="create-category-prompt-input">
          {languages.map((language, index) => translation(language, index))}
        </div>
      </>
    );
  };
  return (
    <BasePrompt
      show={show}
      closeAction={handleClose}
      rightButton={{
        text: 'Save',
        action: startNewSection,
        disabled: !offerTypeValues.en,
      }}
      title={'Create new offer type label'}
      description={
        'Please enter a new offer type label with its translation below:'
      }
      otherBody={getInputArea}
    />
  );
}

export default CreateOfferTypePrompt;
