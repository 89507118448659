import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import { ArrayStringData } from '../../../utils';

function Filter({ backAction = () => {} }) {
  const location = useLocation();

  const parsedSearch = queryString.parse(location.search);
  const searchType = parsedSearch['type'] || '';
  const searchRecordType = parsedSearch['record_type'] || '';
  const searchValue = parsedSearch['value'] || '';
  const searchCreateDate = parsedSearch['create_date'] || '';

  const { maxPointValue } = useSelector((state) => ({
    maxPointValue: state.pointTransactionList.maxPointValue,
  }));

  const [type, setType] = useState(searchType);
  const [recordType, setRecordType] = useState(
    ArrayStringData(searchRecordType),
  );
  const [value, setValue] = useState(() => {
    const values = ArrayStringData(searchValue);
    if (values.length > 0) return values;
    return [0, 0];
  });
  const [createDate, setCreateDate] = useState(searchCreateDate);

  const content = [
    {
      title: 'Type',
      data: [
        { pk: 'ADD', name: 'Add Points' },
        { pk: 'REMOVE', name: 'Remove Points' },
      ],
      value: type,
      setValue: setType,
      componment: FilterTypeEnum.singleChoice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Record type',
      data: [
        { name: 'Customer earn', pk: 'CUSTOMER_EARN' },
        { name: 'Customer use', pk: 'CUSTOMER_USED' },
        { name: 'Admin edit', pk: 'ADMIN_EDITED' },
        { name: 'Point expired', pk: 'EXPIRED' },
      ],
      value: recordType,
      setValue: setRecordType,
      componment: FilterTypeEnum.choice,
    },
    {
      title: 'Create date',
      value: createDate,
      setValue: setCreateDate,
      componment: FilterTypeEnum.date,
    },
    {
      title: 'Value',
      data: [0, maxPointValue],
      value: value,
      setValue: setValue,
      componment: FilterTypeEnum.slider,
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

export default Filter;
