import React, { useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { LanguageConfig } from '../../config/CustomEnums';
import { t } from 'i18n-js';
import CustomTitleWithInput from '../base/CustomTitleWithInput';
import {
  insertKeyToAllLanguages,
  insertKeyToTranslations,
} from '../../utils/index';
import {
  CustomNumberInputWithUnit,
  CustomTitleLabel,
} from '../earning/CustomBaseComponments';
import BaseMutipleSelectorV2 from '../base/BaseMultipleSelectorV2';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

function GeneralSection({ language, languages, goodieBagList }) {
  const isEnglish = language === LanguageConfig.english;
  const { watch, setValue, control, formState, getValues } = useFormContext();
  const { errors } = formState;
  const { id } = useParams();
  // isEnglish && console.log('@@28', watch('translations'));
  const watchName = watch('name') || (isEnglish ? '' : '-');
  const watchGoodieBags = watch('goodieBags') || [];
  const watchLimit = watch('perHeadLimit');
  const watchAlertOfLimit = isEnglish
    ? watch('alertMessageOfExceedLimit')
    : watch(`translations.${language}.alertMessageOfExceedLimit`);

  const nameArea = () => {
    return isEnglish ? (
      <Controller
        control={control}
        name={'name'}
        render={() => (
          <CustomTitleWithInput
            title={'Goodie bag group Name'}
            defaultValue={watchName}
            error={{
              error: errors?.name,
              message: errors?.name?.message,
              id: 'name',
            }}
            setValue={(value) => {
              setValue('name', value, {
                shouldDirty: true,
              });
            }}
          />
        )}
      />
    ) : (
      <>
        <CustomTitleLabel title={'Goodie bag group Name'} />
        <label className="general-section-perHeadLimit-wording-tips">
          {watchName}
        </label>
      </>
    );
  };

  const goodieBagArea = () => {
    return (
      <Controller
        control={control}
        name={'goodieBags'}
        render={() => (
          <BaseMutipleSelectorV2
            title={'Select goodie bag(s)'}
            data={{
              sourceData: goodieBagList,
              targetData: watchGoodieBags,
              targetChange: (value) => {
                setValue('goodieBags', value, { shouldDirty: true });
              },
            }}
            custom={{
              customItem: (value) => (
                <>
                  <label className="slot-title">[{value.pk}]</label>
                  {value.name}
                </>
              ),
              customFilter: (inputValue, option) => {
                const inputIgnoreCase = inputValue?.toLowerCase();
                const optionIgnoreCase = option?.name?.toLowerCase();
                return (
                  optionIgnoreCase?.includes(inputIgnoreCase) ||
                  option.pk == inputValue
                );
              },
            }}
            searchPlaceholder={'Search by name or ID'}
            error={{
              error: errors?.goodieBags,
              id: 'goodieBags',
              message: errors?.goodieBags?.message,
            }}
          />
        )}
      />
    );
  };

  const limitArea = () => {
    return (
      <Controller
        control={control}
        name={'perHeadLimit'}
        render={() => (
          <CustomNumberInputWithUnit
            title={'Group limit'}
            unit={'number of goodie bag(s)'}
            error={errors?.perHeadLimit}
            errorMessage={errors?.perHeadLimit?.message}
            setValue={(value) => {
              setValue('perHeadLimit', value, { shouldDirty: true });
            }}
            defaultValue={watchLimit}
          />
        )}
      />
    );
  };

  const alertOfLimitArea = () => {
    return (
      <Controller
        control={control}
        name={'alertMessageOfExceedLimit'}
        render={() => (
          <CustomTitleWithInput
            title={'Alert of exceeding the limit in frontend (Optional)'}
            tips={'If not filled, the front end will apply the default wording'}
            defaultValue={watchAlertOfLimit}
            setValue={(value) => {
              isEnglish
                ? setValue('alertMessageOfExceedLimit', value, {
                    shouldDirty: true,
                  })
                : insertKeyToTranslations({
                    language: language,
                    key: 'alertMessageOfExceedLimit',
                    value,
                    setValue,
                    getValues,
                  });
            }}
          />
        )}
      />
    );
  };

  return (
    <>
      <div className="first-section-title">
        {t('general', { locale: 'en' })}
      </div>
      {nameArea()}
      {isEnglish ? (
        <>
          {goodieBagArea()}
          {limitArea()}
        </>
      ) : null}
      {alertOfLimitArea()}
    </>
  );
}

const mapPropsToState = (state) => ({
  goodieBagList: state.goodieBags.notPagedAllList,
});
export default connect(mapPropsToState)(GeneralSection);
