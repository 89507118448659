import FlatList from 'flatlist-react/lib';
import React, { useEffect, useState } from 'react';
import { noDataPageFunc } from '../../components/base/NoDataPage';
import Loading from '../../components/base/Loading';
import LoadingIcon from '../../assets/images/loading.gif';
import { APIStatus } from '../../config/CustomEnums';

export default function BaseMobileListContainer({
  dataList,
  header,
  renderItem,
  hasNext,
  loadMore,
  listHeight,
  listStyle,
  renderWhenEmpty,
}) {
  const blankPage = () => {
    if (dataList?.length > 0) {
      console.log('@@17');
      return <div style={{ height: '20px' }}></div>;
    }

    return noDataPageFunc();
  };

  return (
    <div className="customer-mobile">
      {header}

      <FlatList
        className="customer-mobile-list"
        list={dataList}
        renderItem={renderItem}
        renderWhenEmpty={renderWhenEmpty || blankPage}
        pagination={{
          hasMore: hasNext,
          loadMore,
          loadingIndicator: (
            <div>
              <Loading customClass={'record-loading '} src={LoadingIcon} />
            </div>
          ),
          loadingIndicatorPosition: 'center',
        }}
        wrapperHtmlTag="div"
        style={{
          overflow: 'auto',
          height: listHeight,
          backgroundColor: '#f8f9fc',
          ...(listStyle || {}),
        }}
      />
    </div>
  );
}
