import React, { useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import ContentSections from '../base/ContentSections';
import GeneralSection from './GeneralSection';
import ActivePeriodSection from './ActivePeriodSection';
import VisiblePeriodSection from './VisiblePeriodSection';
import CouponAcquiredPeriodSection from './CouponAcquiredPeriodSection';
import AssignedCreditQuotaPerEntitlementSection from './AssignedCreditQuotaPerEntitlementSection';
import OverallAmountSection, {
  OverallAmountFortuneBagSection,
} from './OverallAmountSection';
import { ContinueWithBackButtons } from '../base/BottomStepComponent';
import { useFormContext } from 'react-hook-form';
import { validate } from './CreateGoodieBagValidate';
import './CreateGoodieBagStepThree.scss';
import ShowFilterConfigList from './ShowFilterConfigList';
import PerHeadSection from './PerHeadSection';
import OutOfStockSection from './OutOfStockSection';
import ListFortuneBagCampaigns from './ListFortuneBagCampaigns';
import {
  GOODIE_BAG_REWARD_TYPE_OPTIONS,
  checkIsOptionD,
} from '../../models/GoodieBagsModel';
import { isArrayEmpty } from '../../utils';
import { TimeFormater, formatDate } from '../../utils/TimeFormatUtil';

function CreateGoodieBagStepThree({
  sourceSlotFortuneBagCampaigns,
  sourceGoodieBagDetail,
}) {
  const dispatch = useDispatch();
  const { getValues, watch, setError, clearErrors } = useFormContext();
  const rewardType = watch('rewardType');
  const quantityOfCreated = watch('quantityOfCreated') || 0;
  const quantityOfAcquired = watch('quantityOfAcquired') || 0;
  const slotFortuneBagCampaigns = watch('slotFortuneBagCampaigns') || {};

  const isOptionD = useMemo(() => {
    const goodieBag = getValues() || {};
    const merchants = isArrayEmpty(goodieBag.batchOta)
      ? goodieBag.ota
      : goodieBag.batchOta;

    return checkIsOptionD(merchants);
  }, []);
  const getSections = () => {
    return [
      <GeneralSection />,
      <ActivePeriodSection rewardType={rewardType} isOptionD={isOptionD} />,
      <VisiblePeriodSection />,
      ...(rewardType !== GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value
        ? [
            <CouponAcquiredPeriodSection />,
            <AssignedCreditQuotaPerEntitlementSection />,
            <OverallAmountSection />,
          ]
        : [
            <ListFortuneBagCampaigns
              campaigns={Object.values(slotFortuneBagCampaigns)?.[0]}
              goodiebagRemainedCount={quantityOfCreated - quantityOfAcquired}
            />,
            <OverallAmountFortuneBagSection
              sourceCampaigns={sourceSlotFortuneBagCampaigns}
            />,
          ]),

      <PerHeadSection />,
      ...(rewardType !== GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value
        ? [<ShowFilterConfigList />, <OutOfStockSection />]
        : []),
    ];
  };
  console.log('@@114: ', sourceSlotFortuneBagCampaigns);
  const sections = getSections();

  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 2,
      isBack,
    });
    dispatch({
      type: 'goodieBags/stepChange',
      payload: { isBack: isBack, step: 2, isValid },
    });

    if (sourceGoodieBagDetail.pk && !isBack && isValid && isOptionD) {
      const [displayStartDate, displayEndDate] = getValues([
        'displayStartDate',
        'displayEndDate',
      ]);
      dispatch({
        type: 'entitlementTrackingCodes/getList',
        payload: {
          pageCount: 1000,
          goodieBagId: sourceGoodieBagDetail.pk,
          inActiveTimeRange: `${formatDate(
            displayStartDate,
            TimeFormater.yearMonthDayTime,
          )},${formatDate(displayEndDate, TimeFormater.yearMonthDayTime)}`,
        },
      });
    }
  };

  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <ContinueWithBackButtons
        continueAction={() => {
          stepChangeAction(false);
        }}
        backAction={() => {
          stepChangeAction(true);
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  sourceSlotFortuneBagCampaigns:
    state.goodieBags.detail.originalGoodieBagCouponCampaigns,
  sourceGoodieBagDetail: state.goodieBags.detail,
});

export default connect(mapPropsToState)(CreateGoodieBagStepThree);
