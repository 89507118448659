import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { connect } from 'react-redux';
import AuthButton from '../../../components/base/AuthButton';
import ContentSections from '../../../components/base/ContentSections';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { createAction } from '../../../utils';
import Loading from '../../../components/base/Loading';
import { LanguageConfig } from '../../../config/CustomEnums';
import BaseOverView from '../../../components/coupon/couponList/BaseOverview';

const TourCardDetail = ({ detail, dispatch, languages }) => {
  const history = useHistory();
  const params = useParams();
  const { id } = params;
  useEffect(() => {
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
    dispatch(createAction('tourCard/getOneDetail')({ id }));
    return () => {
      dispatch(createAction('tourCard/clearData')());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTabForLanguage = (language) => {
    console.log(detail);
    const title =
      language === LanguageConfig.english
        ? detail.title
        : detail.translations?.[language]?.title;
    const coverImage =
      language === LanguageConfig.english
        ? detail.coverPhoto
        : detail.translations?.[language]?.coverPhoto;
    const description =
      language === LanguageConfig.english
        ? detail.description
        : detail.translations?.[language]?.description;

    return (
      <>
        <BaseOverView
          hideSection
          title="information"
          imageTitleNames={['Cover image']}
          itemTitleList={['Cover image', 'Title', 'Description']}
          itemValueList={[coverImage, title, description]}
        />
      </>
    );
  };

  const tabs = [
    {
      name: 'Detail',
      content: detail.pk ? (
        <div className="scroll-container-common">
          <ContentSections
            hidePreview
            languageTabContent={{
              containers: languages?.map((item) => ({
                container: [renderTabForLanguage(item.code)],
                key: item.code,
                title: item.sourceName,
              })),
            }}
          />
        </div>
      ) : (
        <Loading />
      ),
    },
  ];

  const buttons = [
    <AuthButton
      title="Edit"
      action={() => {
        history.push({
          pathname: 'edit/',
        });
      }}
      requires={PermissionCodes.changeTourCard}
    />,
  ];
  return (
    <>
      <CustomListComponent
        caution={{
          detail: '',
          title: detail?.title,
        }}
        defaultActiveKey={tabs[0].title}
        breadcrumb={<CustomBreadcrumb name={detail?.title} />}
        buttons={buttons}
        tabs={tabs}
      />
    </>
  );
};

const mapPropsToState = (state) => ({
  detail: state.tourCard.detail,
  languages: state.language.allList,
});
export default connect(mapPropsToState)(TourCardDetail);
