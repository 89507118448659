import React, { useState, useEffect } from 'react';
import './CreateCampaignStepFour.scss';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import CampaignPreviewButton from '../../../containers/engagement/campaign/CampaignPreviewButton';
import ContentSections from '../../base/ContentSections';
import ProfileSection from './ProfileSection';
import ContentSection from './ContentSection';
import PublishCampaignPrompt from './PublishCampaignPrompt';
import { SaveAndBackButtons } from '../../base/BottomStepComponent';
import { useHistory, useParams } from 'react-router-dom';
import {
  createAction,
  removeFromSessionStorage,
  saveToSessionStorage,
} from '../../../utils';
import {
  APIStatus,
  CampaignType,
  SESSION_KEYS,
} from '../../../config/CustomEnums';
import Loading from '../../base/Loading';
import { PermissionCodes } from '../../../config/PermissionCodes';
import FortuneBagsAllocationPrompt from './FortuneBagsAllocationPrompt';
import FortuneBagDetail from './FortuneBagDetail';
import { validate } from '../../../containers/engagement/campaign/CreateCampaignValidate';
import { useFormContext } from 'react-hook-form';

const ActionType = {
  save: 'save',
  publish: 'publish',
  none: 'none',
};

function CreateCampaignStepFour() {
  const params = useParams();
  const { getValues, clearErrors, setError } = useFormContext();

  const [showPublishPrompt, setShowPublishPrompt] = useState(false);
  const [actionType, setActionType] = useState(ActionType.none);
  const [showSetAllocatePrompt, setShowSetAllocatePrompt] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    createStatus,
    // isPublished,
    // selectedChannels,
    // campaignType,
    // ota,
    // batchOta,
    // campaign,
    campaignPublish,
    couponAcquired,
  } = useSelector((state) => ({
    createStatus: state.createCampaign.createStatus,
    // isPublished: state.createCampaign.campaign.isPublished,
    // selectedChannels: state.createCampaign.campaign.channels,
    // campaignType: state.createCampaign.campaign.campaignType,
    // ota: state.createCampaign.campaign.ota,
    // batchOta: state.createCampaign.campaign.batchOta,
    // campaign: state.createCampaign.campaign,
    campaignPublish: state.createCampaign.campaignPublish,
    couponAcquired: state.createCampaign.couponAcquired,
  }));

  const campaign = getValues() || {};

  console.log('@@102: ', campaign.goodieBagCampaignSlots);

  useEffect(() => {
    saveToSessionStorage(
      SESSION_KEYS.CREATE_CAMPAIGN_PERVIEW_SESSION_KEY,
      campaign,
    );
    return () => {
      removeFromSessionStorage(
        SESSION_KEYS.CREATE_CAMPAIGN_PERVIEW_SESSION_KEY,
      );
    };
  }, []);

  useEffect(() => {
    if (createStatus === APIStatus.success) {
      setShowPublishPrompt(!history.location?.state?.from);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStatus]);

  const channels = campaign.channels
    ? [{ name: 'All channel' }, ...campaign.channels]
    : [];

  const sections = [
    <ProfileSection couponAcquired={couponAcquired} />,
    campaign.campaignType === CampaignType.fortuneBagCampaign ? (
      <FortuneBagDetail campaign={campaign} />
    ) : null,
  ];

  const saveFortuneBag = (isPublish, merchants, allocatedNum) => {
    console.log('@@75: ', campaign);
    console.log('@@75: ', merchants);
    const optionCOTACount = (merchants || []).filter(
      (item) => item.handleOption.value === 'OPTION_C',
    ).length;
    console.log('@@75: ', optionCOTACount);
    if (optionCOTACount > 1) {
      setShowSetAllocatePrompt(true);
    } else if (optionCOTACount === 1) {
      setActionType(ActionType.publish);
      dispatch(
        createAction('createCampaign/createOrUpdateCampaign')({
          isPublished: isPublish,
          data: getValues(),
          otaAllocatedNum: [
            {
              brandId: merchants[0].pk,
              number: allocatedNum,
            },
          ],
        }),
      );
    } else {
      dispatch(
        createAction('createCampaign/createOrUpdateCampaign')({
          isPublished: isPublish,
          data: getValues(),
          otaAllocatedNum: [],
        }),
      );
    }
  };
  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 3,
      isBack,
    });
    dispatch({
      type: 'createCampaign/stepChange',
      payload: { isBack: isBack, step: 3, isValid },
    });
  };

  return (
    <>
      {createStatus !== APIStatus.calling ? (
        <>
          <ContentSection />
          <ContentSections sections={sections} hidePreview={true} />
        </>
      ) : (
        <ContentSections sections={[<Loading />]} hidePreview={true} />
      )}

      <SaveAndBackButtons
        hideSave={
          campaign.campaignType === CampaignType.fortuneBagCampaign &&
          campaign.isPublished
        }
        saveTempText={params.id || campaign.pk ? 'Update' : 'Save only'}
        saveText={
          campaign.isPublished || campaignPublish === APIStatus.success
            ? 'UnPublished'
            : 'Save and publish'
        }
        backAction={() => stepChangeAction(true)}
        saveAction={() => {
          if (campaign.campaignType === CampaignType.fortuneBagCampaign) {
            saveFortuneBag(
              !campaign.isPublished,
              campaign.ota,
              campaign.batchBagQuantity || 0,
            );
            // const optionCOTACount = ota.filter(
            //   (item) => item.handleOption.value === 'OPTION_C',
            // ).length;
            // if (optionCOTACount > 1) {
            //   setShowSetAllocatePrompt(true);
            // } else if (optionCOTACount === 1) {
            //   setActionType(ActionType.publish);
            //   dispatch(
            //     createAction('createCampaign/createOrUpdateCampaign')({
            //       isPublished: !isPublished,
            //       data: getValues(),
            //       otaAllocatedNum: [
            //         {
            //           brandId: ota[0].pk,
            //           number: campaign.fortuneBagQuantity,
            //         },
            //       ],
            //     }),
            //   );
            // } else {
            //   dispatch(
            //     createAction('createCampaign/createOrUpdateCampaign')({
            //       isPublished: !isPublished,
            //       data: getValues(),
            //       otaAllocatedNum: [],
            //     }),
            //   );
            // }
          } else {
            setActionType(ActionType.publish);
            dispatch(
              createAction('createCampaign/createOrUpdateCampaign')({
                data: getValues(),
                isPublished: !campaign.isPublished,
              }),
            );
          }
        }}
        saveTempAction={() => {
          if (
            campaign.campaignType === CampaignType.fortuneBagCampaign &&
            campaign.isPublished
          ) {
            saveFortuneBag(
              campaign.isPublished,
              (campaign.isPublished && campaign.batchOta?.length > 0) ||
                !campaign.isPublished
                ? campaign.batchOta
                : [...(campaign.batchOta || []), ...campaign.ota],
              campaign.batchBagQuantity || 0,
            );
          } else {
            setActionType(ActionType.save);
            dispatch(
              createAction('createCampaign/createOrUpdateCampaign')({
                data: getValues(),
              }),
            );
          }
        }}
        saveRequires={
          campaign.isPublished
            ? PermissionCodes.unPublishCampaign
            : PermissionCodes.publishCampaign
        }
        extraButtons={
          campaign.campaignType === CampaignType.fortuneBagForGoodieBagCampaign
            ? null
            : [
                <CampaignPreviewButton
                  channels={channels}
                  campaign={campaign}
                />,
              ]
        }
      />

      <PublishCampaignPrompt
        isSave={actionType === ActionType.save}
        isPublish={!campaign.isPublished}
        show={showPublishPrompt}
        continueId={campaign.pk}
      />

      {campaign.campaignType === CampaignType.fortuneBagCampaign ? (
        <FortuneBagsAllocationPrompt
          show={showSetAllocatePrompt}
          closeAction={() => {
            setShowSetAllocatePrompt(false);
          }}
          nextAction={(otaAllocatedNum) => {
            setShowSetAllocatePrompt(false);
            setActionType(ActionType.publish);
            dispatch(
              createAction('createCampaign/createOrUpdateCampaign')({
                data: getValues(),
                isPublished: true,
                otaAllocatedNum,
              }),
            );
          }}
          ota={campaign.batchOta || campaign.ota}
          campaign={campaign}
        />
      ) : null}
    </>
  );
}

export default CreateCampaignStepFour;
