import React from 'react';
import { useFormContext } from 'react-hook-form';
import { GOODIE_BAG_REWARD_TYPE_OPTIONS } from '../../models/GoodieBagsModel';
import CustomTitleWithSwitch from '../base/CustomTitleWithSwitch';
import { hasError } from '../base/ErrorFieldMessage';
import NumberInput from './NumberInput';

function ShowFilterConfigList() {
  const { watch, setValue, formState } = useFormContext();
  const errors = formState.errors;
  const watchShowCategory = watch('showCampaignCategoryFilter');
  const watchShowBrand = watch('showBrandFilter');
  const watchShowOfferTypeLabel = watch('showOfferTypeLabelFilter');
  const watchShowCreditRange = watch('showCreditRangeFilter');
  const watchShowLocationFilter = watch('showLocationFilter');

  const configList = [
    {
      title: 'Category',
      tips: 'If on, the frontend campaign list filter will display the ‘Category’ filter.',
      defaultValue: watchShowCategory,
      fieldName: 'showCampaignCategoryFilter',
    },
    {
      title: 'Brand',
      tips: 'If on, the frontend campaign list filter will display the ‘Brand’ filter.',
      defaultValue: watchShowBrand,
      fieldName: 'showBrandFilter',
    },

    {
      title: 'Offer type label',
      tips: 'If on, the frontend campaign list filter will display the ‘Offer type label’ filter.',
      defaultValue: watchShowOfferTypeLabel,
      fieldName: 'showOfferTypeLabelFilter',
    },
    // ...(watch('rewardType') !== GOODIE_BAG_REWARD_TYPE_OPTIONS.gift.value
    //   ? [
    //       {
    //         title: 'Credit range',
    //         tips: 'If on, the frontend campaign list filter will display the ‘Credit range’ filter.',
    //         defaultValue: watchShowCreditRange,
    //         fieldName: 'showCreditRangeFilter',
    //       },
    //     ]
    //   : []),
    {
      title: 'Credit range',
      tips: 'If on, the frontend campaign list filter will display the ‘Credit range’ filter.',
      defaultValue: watchShowCreditRange,
      fieldName: 'showCreditRangeFilter',
    },
    {
      isDevideLine: true,
    },
    {
      title: 'Location',
      tips: 'If on, the frontend campaign list will display ‘Location’ filter. (Display as a separate filter button in frontend.)',
      defaultValue:
        watchShowLocationFilter !== undefined ? watchShowLocationFilter : true,
      fieldName: 'showLocationFilter',
    },
  ];

  return (
    <>
      <label className="create-section-title">
        Items to show in filter in frontend campaign list
      </label>
      <label className="section-short-description">
        Manage to show or hide the frontend campaign list default filter.
      </label>
      {configList.map((item) => {
        if (item?.isDevideLine) {
          return <div className="campaign-devide-line" />;
        }
        return (
          <CustomTitleWithSwitch
            title={item.title}
            tips={item.tips}
            defaultValue={
              item.defaultValue === undefined ? true : item.defaultValue
            }
            setValue={(value) => {
              setValue(item.fieldName, !!value, { shouldDirty: true });
            }}
          />
        );
      })}
    </>
  );
}

export default ShowFilterConfigList;
