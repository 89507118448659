import { BaseHelper } from './BaseGraphQLAPIHelper';

const CONTNET = `
id
pk
type
name
generalPurchaseTypeMinimumSpending
generalPurchaseTypeMaximumSpending
generalPurchaseTypeLimitedToStores {
  edges {
    node {
      id
      pk
      name
    }
  }
}
generalPurchaseTypeEligibleNumberOfDaysSincePurchase
generalPurchaseTypeWillExcludeDeliveryCost
generalPurchaseTypeWillExcludeOtherCharges
generalPurchaseTypeExcludedProducts
generalPurchaseTypeRewardType
generalPurchaseTypePointsRewardTypePointsPerXDollarsSpent
generalPurchaseTypePointsRewardTypeX
generalPurchaseTypeCouponRewardTypeCouponTemplate {
  id
  name
}
generalPurchaseTypeCouponRewardTypeQuantity
generalPurchaseTypeLimitedToSkus {
  edges {
    node {
      id
      pk
      skuId
      sku
      categoryName
      categoryId
    }
  }
}

memberReferralTypeBeneficiary
memberReferralTypeLimit
memberReferralTypeRewardType
memberReferralTypePointsRewardTypePoints
memberReferralTypeCouponRewardTypeCouponTemplate {
  id
  name
}
memberReferralTypeCouponRewardTypeQuantity
newMemberTypeRewardType
newMemberTypePointsRewardTypePoints
newMemberTypeCouponRewardTypeCouponTemplate {
  id
  name
}
newMemberTypeCouponRewardTypeQuantity
birthdayTypePeriodStartDate
birthdayTypePeriodEndDate
birthdayTypeRewardType
birthdayTypePointsRewardTypePoints
birthdayTypeCouponRewardTypeCouponTemplate {
  id
  name
}
birthdayTypeCouponRewardTypeQuantity
qrCodeScanningTypeQrCodes
qrCodeScanningTypeOverallLimit
qrCodeScanningTypePerHeadLimit
qrCodeScanningTypePeriodicLimit
qrCodeScanningTypePeriodicLimitEffectiveNumberOfDays
qrCodeScanningTypePerHeadPeriodicLimit
qrCodeScanningTypePerHeadPeriodicLimitEffectiveNumberOfDays
qrCodeScanningTypeRewardType
qrCodeScanningTypePointsRewardTypePoints
qrCodeScanningTypeCouponRewardTypeCouponTemplate {
  id
  name
}
qrCodeScanningTypeCouponRewardTypeQuantity
gpsCheckInTypeLatitude
gpsCheckInTypeLongitude
gpsCheckInTypeRadiusInMeter
gpsCheckInTypeOverallLimit
gpsCheckInTypePerHeadLimit
gpsCheckInTypePeriodicLimit
gpsCheckInTypePeriodicLimitEffectiveNumberOfDays
gpsCheckInTypePerHeadPeriodicLimit
gpsCheckInTypePerHeadPeriodicLimitEffectiveNumberOfDays
gpsCheckInTypeRewardType
gpsCheckInTypePointsRewardTypePoints
gpsCheckInTypeCouponRewardTypeCouponTemplate {
  id
  name
}
gpsCheckInTypeCouponRewardTypeQuantity
creationDate
lastModifiedDate
campaigns {
  edges {
    node {
      id
      pk
      name
    }
  }
}
`;

export const getEarningRulesWithType = (
  afterCursor,
  rank,
  type,
  nameContains = '',
  otherSearch,
) => {
  let order = rank ? '-pk' : 'pk';
  if (otherSearch?.sort) {
    order = otherSearch.sort;
  }

  let moreSearch = '';
  if (otherSearch.reward_type) {
    moreSearch += `, rewardTypeIn: "${otherSearch.reward_type}"`;
  }

  const query = `{
    earningRules(first: 20, after: "${afterCursor}", nameIcontains: "${nameContains}", type: "${
    type === 'all' ? '' : type
  }", orderBy: "${order}"${moreSearch}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ${CONTNET}
        }
      }
    }
  }
  `;
  const variables = {
    input: {},
  };

  // console.log('@@338: ', query);

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteEarningRules = (ids) => {
  const query = `mutation DeleteEarningRules($input: DeleteEarningRulesInput!) {
    deleteEarningRules(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      ids: ids,
    },
  };

  // console.log('@@338: ', query);

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getOneEarningRule = (id) => {
  const query = `
  {
    earningRule(id: "${id}") {
      ${CONTNET}
      instructionSectionTitle
      instructionSectionContent
      detailSectionTitle
      detailSectionContent
      translations {
        edges {
          node {
            pk
            language
            instructionSectionTitle
            instructionSectionContent
            detailSectionTitle
            detailSectionContent
          }
        }
      }
    }
  }
  `;
  const variables = {
    input: {},
  };

  // console.log('@@338: ', query);

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createEarningRule = (values) => {
  const query = `
  mutation CreateEarningRule($input: CreateEarningRuleInput!) {
    createEarningRule(input: $input) {
      node {
        id
        pk
          type
          name
          instructionSectionTitle
          instructionSectionContent
          detailSectionTitle
          detailSectionContent
          generalPurchaseTypeMinimumSpending
          generalPurchaseTypeMaximumSpending
          generalPurchaseTypeLimitedToStores {
            edges {
              node {
                id
              }
            }
          }
          generalPurchaseTypeEligibleNumberOfDaysSincePurchase
          generalPurchaseTypeWillExcludeDeliveryCost
          generalPurchaseTypeWillExcludeOtherCharges
          generalPurchaseTypeExcludedProducts
          generalPurchaseTypeRewardType
          generalPurchaseTypePointsRewardTypePointsPerXDollarsSpent
          generalPurchaseTypePointsRewardTypeX
          generalPurchaseTypeCouponRewardTypeCouponTemplate {
            id
          }
          generalPurchaseTypeCouponRewardTypeQuantity
          memberReferralTypeBeneficiary
          memberReferralTypeLimit
          memberReferralTypeRewardType
          memberReferralTypePointsRewardTypePoints
          memberReferralTypeCouponRewardTypeCouponTemplate {
            id
          }
          memberReferralTypeCouponRewardTypeQuantity
          newMemberTypeRewardType
          newMemberTypePointsRewardTypePoints
          newMemberTypeCouponRewardTypeCouponTemplate {
            id
          }
          newMemberTypeCouponRewardTypeQuantity
          birthdayTypePeriodStartDate
          birthdayTypePeriodEndDate
          birthdayTypeRewardType
          birthdayTypePointsRewardTypePoints
          birthdayTypeCouponRewardTypeCouponTemplate {
            id
          }
          birthdayTypeCouponRewardTypeQuantity
          qrCodeScanningTypeQrCodes
          qrCodeScanningTypeOverallLimit
          qrCodeScanningTypePerHeadLimit
          qrCodeScanningTypePeriodicLimit
          qrCodeScanningTypePeriodicLimitEffectiveNumberOfDays
          qrCodeScanningTypePerHeadPeriodicLimit
          qrCodeScanningTypePerHeadPeriodicLimitEffectiveNumberOfDays
          qrCodeScanningTypeRewardType
          qrCodeScanningTypePointsRewardTypePoints
          qrCodeScanningTypeCouponRewardTypeCouponTemplate {
            id
          }
          qrCodeScanningTypeCouponRewardTypeQuantity
          gpsCheckInTypeLatitude
          gpsCheckInTypeLongitude
          gpsCheckInTypeRadiusInMeter
          gpsCheckInTypeOverallLimit
          gpsCheckInTypePerHeadLimit
          gpsCheckInTypePeriodicLimit
          gpsCheckInTypePeriodicLimitEffectiveNumberOfDays
          gpsCheckInTypePerHeadPeriodicLimit
          gpsCheckInTypePerHeadPeriodicLimitEffectiveNumberOfDays
          gpsCheckInTypeRewardType
          gpsCheckInTypePointsRewardTypePoints
          gpsCheckInTypeCouponRewardTypeCouponTemplate {
            id
          }
          gpsCheckInTypeCouponRewardTypeQuantity
      }
      errors {
        field
        messages
      }
    }
  }
  `;

  const variables = {
    input: values,
  };
  console.log(variables);
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateEarningRule = (values) => {
  const query = `
  mutation UpdateEarningRule($input: UpdateEarningRuleInput!) {
    updateEarningRule(input: $input) {
      node {
        id
        pk
          type
          name
          instructionSectionTitle
          instructionSectionContent
          detailSectionTitle
          detailSectionContent
          generalPurchaseTypeMinimumSpending
          generalPurchaseTypeMaximumSpending
          generalPurchaseTypeLimitedToStores {
            edges {
              node {
                id
              }
            }
          }
          generalPurchaseTypeEligibleNumberOfDaysSincePurchase
          generalPurchaseTypeWillExcludeDeliveryCost
          generalPurchaseTypeWillExcludeOtherCharges
          generalPurchaseTypeExcludedProducts
          generalPurchaseTypeRewardType
          generalPurchaseTypePointsRewardTypePointsPerXDollarsSpent
          generalPurchaseTypePointsRewardTypeX
          generalPurchaseTypeCouponRewardTypeCouponTemplate {
            id
          }
          generalPurchaseTypeCouponRewardTypeQuantity
          memberReferralTypeBeneficiary
          memberReferralTypeLimit
          memberReferralTypeRewardType
          memberReferralTypePointsRewardTypePoints
          memberReferralTypeCouponRewardTypeCouponTemplate {
            id
          }
          memberReferralTypeCouponRewardTypeQuantity
          newMemberTypeRewardType
          newMemberTypePointsRewardTypePoints
          newMemberTypeCouponRewardTypeCouponTemplate {
            id
          }
          newMemberTypeCouponRewardTypeQuantity
          birthdayTypePeriodStartDate
          birthdayTypePeriodEndDate
          birthdayTypeRewardType
          birthdayTypePointsRewardTypePoints
          birthdayTypeCouponRewardTypeCouponTemplate {
            id
          }
          birthdayTypeCouponRewardTypeQuantity
          qrCodeScanningTypeQrCodes
          qrCodeScanningTypeOverallLimit
          qrCodeScanningTypePerHeadLimit
          qrCodeScanningTypePeriodicLimit
          qrCodeScanningTypePeriodicLimitEffectiveNumberOfDays
          qrCodeScanningTypePerHeadPeriodicLimit
          qrCodeScanningTypePerHeadPeriodicLimitEffectiveNumberOfDays
          qrCodeScanningTypeRewardType
          qrCodeScanningTypePointsRewardTypePoints
          qrCodeScanningTypeCouponRewardTypeCouponTemplate {
            id
          }
          qrCodeScanningTypeCouponRewardTypeQuantity
          gpsCheckInTypeLatitude
          gpsCheckInTypeLongitude
          gpsCheckInTypeRadiusInMeter
          gpsCheckInTypeOverallLimit
          gpsCheckInTypePerHeadLimit
          gpsCheckInTypePeriodicLimit
          gpsCheckInTypePeriodicLimitEffectiveNumberOfDays
          gpsCheckInTypePerHeadPeriodicLimit
          gpsCheckInTypePerHeadPeriodicLimitEffectiveNumberOfDays
          gpsCheckInTypeRewardType
          gpsCheckInTypePointsRewardTypePoints
          gpsCheckInTypeCouponRewardTypeCouponTemplate {
            id
          }
          gpsCheckInTypeCouponRewardTypeQuantity
      }
      errors {
        field
        messages
      }
    }
  }
  `;

  const variables = {
    input: values,
  };

  return BaseHelper.callGraphQLAPI({ query, variables });
};
