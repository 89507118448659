import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BaseEditSection from '../../containers/base/BaseEditSection';
import './TargetCustomerSection.scss';
import { createAction } from '../../utils';
import BaseMutipleSelectorV2 from '../base/BaseMultipleSelectorV2';

function TargetCustomerSection() {
  const { customerList, selectedCustomerList, levelList } = useSelector(
    (state) => ({
      customerList: state.customerList.pagedCustomerList,
      selectedCustomerList: state.customerGroup.selectedCustomerGroup.customers,
      levelList: state.levels.levelList,
    }),
  );

  const dispatch = useDispatch();

  const optionList = (list) => {
    if (!list) {
      return [];
    }
    return list.map((item) => {
      return {
        name: item.name,
        value: { ...item, subFilterField: item.level },
      };
      // return { ...item, subFilterField: item.level };
    });
  };

  useEffect(() => {
    dispatch(createAction('levels/getLevelList')({}));
  }, []);

  return (
    <div className="create-customer-group-target">
      <BaseEditSection
        title="TARGET CUSTOMER"
        fields={[
          <BaseMutipleSelectorV2
            title={'Customer'}
            namespace="customerList"
            // groupKey={'selectedBrand'}
            data={{
              sourceData: customerList,
              targetData: selectedCustomerList,
              targetChange: (options) => {
                dispatch(
                  createAction('customerGroup/setFieldToSession')({
                    customers: options.map((item) => item.value),
                  }),
                );
                dispatch(
                  createAction(
                    'customerGroup/updateSelectedCustomerGroupState',
                  )({
                    customers: options.map((item) => item.value),
                  }),
                );
              },
            }}
          />,
        ]}
      />
    </div>
  );
}

export default TargetCustomerSection;
