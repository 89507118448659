import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { connect, useDispatch, useSelector } from 'react-redux';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { createAction, getHashKeyString } from '../../../utils';

import { PermissionCodes } from '../../../config/PermissionCodes';
import AuthButton from '../../../components/base/AuthButton';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';

import DeletePrompt from '../../../components/base/DeletePrompt';
import { NavBarNames } from '../../../config/NavBarNameList';

export default function OTPChannelList() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const {
    totalCount,
    pageInfo,
    totalPage,
    displayFields,
    pagedList,
    checkedList,
  } = useSelector((state) => ({
    totalCount: state.otpChannel.totalCount,
    pageInfo: state.otpChannel.pageInfo,
    totalPage: state.otpChannel.totalPage,
    displayFields: state.otpChannel.listDisplayFields,
    pagedList: state.otpChannel.pagedList,
    checkedList: state.otpChannel.checkedList,
  }));

  const actionList = [
    {
      name: 'Delete',
      action: () => setShowDeleteView(true),
      requires: PermissionCodes.changeOtpChannel,
    },
  ];

  const tabs = [
    {
      name: 'List',
      content: (
        <BaseTabListContainer
          hideTab={true}
          groupActions={[]}
          pageInfo={pageInfo}
          totalCount={totalCount || 0}
          filter={{ hasFilter: false, componment: null }}
          listContent={
            <BaseListContainer
              fields={displayFields}
              model={'otpChannel'}
              permissionGroup={PermissionCodes.otpChannel}
              dataList={pagedList}
              totalPage={totalPage}
              deleteInfo={{
                data: [],
                title: 'OTP Channal',
                relatedName: '',
                onComfirm: {},
                relatedSections: [],
              }}
              actions={['Edit', 'Detail']} //['Detail']
            />
          }
        />
      ),
    },
  ];

  const [showDeleteView, setShowDeleteView] = useState(false);

  useEffect(() => {
    const searchDict = queryString.parse(location.search);
    const searchKey = searchDict['search'];
    const stringRank = searchDict['rank'] || 'true';
    const stringPage = searchDict['page'] || 1;
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);
    console.log(searchKey);
    dispatch(
      createAction('otpChannel/getList')({
        ...searchDict,
        rank: rank,
        filterName: searchKey,
        page: page,
      }),
    );
  }, [dispatch, location]);

  const buttons = [
    <AuthButton
      title="Create OTP Channel"
      action={() => {
        dispatch(createAction('otpChannel/clearData')());
        history.push('/otp_channel/create');
      }}
      requires={PermissionCodes.changeOtpChannel}
    />,
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail: '...',
          title: NavBarNames.otpChannel,
        }}
        defaultActiveKey={tabs[0].name}
        breadcrumb={<CustomBreadcrumb />}
        buttons={[]}
        tabs={tabs}
        hideTab={true}
      />
      {/* <DeletePrompt
        data={checkedList}
        show={showDeleteView}
        title={'Language'}
        relatedName=""
        relatedSections={[]}
        onClose={() => setShowDeleteView(false)}
        onConfirm={() => {
          setShowDeleteView(false);
          dispatch(
            createAction('language/delete')({
              afterAction: () => history.push(location),
            }),
          );
        }}
      /> */}
    </>
  );
}
