import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ActionsDropdownForGroup } from '../../components/base/ActionsDropdown';
import CountComponent from '../../components/base/CountComponent';
import ListFilterComponent from '../../components/base/ListFilterComponent';
import { ImageTextButton } from '../../components/base/CustomImageButton';
import { Tabs, Tab } from 'react-bootstrap';
import filterImage from '../../assets/images/32filter.svg';
import { withResizeDetector } from 'react-resize-detector';
import { useDeviceDetect } from '../engagement/campaign/preview/DHKPreviewBody';

const TabContainer = ({
  width,
  maxTabWidth = 0,
  tabs,
  activeTab,
  listContent,
  onSelect,
}) => {
  let tabShown = true;
  if (tabShown && maxTabWidth > 0 && width - 440 < maxTabWidth) {
    tabShown = false;
  }
  return (
    <Tabs
      activeKey={activeTab}
      onSelect={onSelect}
      className={`campaign-list-tab-container ${
        tabShown ? '' : 'hide-tab-bar'
      }`}
    >
      {tabs.map((config, index) => {
        console.log(config.key === activeTab);
        return (
          <Tab
            key={`${index}-${config.key}`}
            eventKey={config.key}
            title={config.name}
          >
            {config.key === activeTab ? (
              listContent
            ) : (
              <div>
                {config.key} - {activeTab}
              </div>
            )}
          </Tab>
        );
      })}
    </Tabs>
  );
};
const TabContainerWithSize = withResizeDetector(TabContainer);

function BaseTabListContainer({
  maxTabWidth = 0,
  hideTab,
  groupActions,
  tabTitle,
  tabs,
  pageInfo,
  totalCount,
  listContent,
  defaultTabKey,
  hideSearch,
  searchPlaceholder,
  // showDeletePrompt,
  filter = {},
  tabSearchKey,
  hideTabNav,
  needMobile,
  mobileListContent,
  hideActionHeader,
  onSearch,
}) {
  const history = useHistory();
  const location = useLocation();
  const isMobile = useDeviceDetect(768);

  const queryString = require('query-string');
  const tabKey = tabSearchKey || 'type';
  const [activeTab, setActiveTab] = useState(
    queryString.parse(location.search)[tabKey] || 'all',
  );
  // const [searchKey, setSearchKey] = useState();
  const [showFilter, setShowFilter] = useState(false);

  const getFilterCount = () => {
    let filterCount = 0;
    const querySearch = queryString.parse(location.search);
    Object.entries(querySearch).map(([key, value]) => {
      if (Object.keys(querySearch).includes(querySearch[key])) {
        return;
      }
      if (!['page', 'rank', 'search', 'sort'].includes(key) && value) {
        if (
          (key === tabKey && value.toLowerCase().indexOf('all') < 0) ||
          key !== tabKey
        )
          filterCount += 1;
      }
    });

    return filterCount;
  };

  useEffect(() => {
    if (defaultTabKey) {
      setActiveTab(queryString.parse(location.search)[tabKey] || defaultTabKey);
    } else {
      setActiveTab(queryString.parse(location.search)[tabKey] || 'all');
    }
    // setSearchKey(queryString.parse(location.search)['search']);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const filterCount = getFilterCount();

  useEffect(() => {
    if (defaultTabKey) {
      setActiveTab(defaultTabKey);
    }
  }, [defaultTabKey, tabs]);
  //   useEffect(() => {
  //     setShowPrompt(showDeletePrompt);
  //   }, [showDeletePrompt]);

  return (
    <>
      {showFilter ? (
        <filter.componment backAction={() => setShowFilter(false)} />
      ) : null}
      <div className="all-list-scrollable-area" key={''}>
        {hideActionHeader ? null : (
          <div id="tabindex" className="all-list-component-action-header">
            <div className="all-list-component-action-header-div">
              {groupActions?.length > 0 ? (
                <ActionsDropdownForGroup actions={groupActions} />
              ) : null}
              {tabTitle ? (
                typeof tabTitle === 'string' ? (
                  <label className="overview-row-title">{tabTitle}</label>
                ) : (
                  typeof tabTitle === 'function' && tabTitle()
                )
              ) : null}
              <CountComponent
                start={pageInfo?.startCursor}
                end={pageInfo?.endCursor}
                total={totalCount}
              />
            </div>
          </div>
        )}

        {/* <EarningruleListData tabs={tabs} /> */}
        <div className="all-list-table-content">
          {hideTab ? (
            <div>
              {hideTabNav ? null : (
                <nav className="campaign-list-tab-container nav nav-tabs" />
              )}
              {isMobile && needMobile ? mobileListContent : listContent}
            </div>
          ) : (
            <TabContainerWithSize
              tabs={tabs}
              activeTab={activeTab}
              maxTabWidth={maxTabWidth}
              listContent={listContent}
              onSelect={(key) => {
                const queryData = {
                  search: '',
                  page: 1,
                  rank: true,
                };
                if (key !== 'all') {
                  queryData[tabKey] = key;
                }
                history.push({
                  pathname: location.pathname,
                  hash: location.hash,
                  search: queryString.stringify(queryData),
                });
                setActiveTab(key);
              }}
            />
          )}
        </div>

        {filter.hasFilter ? (
          <div
            className={`all-list-filter-container ${filter.customFilterAreaClass}`}
            style={showFilter && filter.offset ? { right: filter.offset } : {}}
          >
            <ImageTextButton
              action={() => setShowFilter(!showFilter)}
              image={filterImage}
              title={'Filter'}
            />
            {filterCount > 0 ? (
              <label className="filter-badge-icon">{filterCount}</label>
            ) : null}
          </div>
        ) : null}

        {hideSearch ? null : (
          <div className="all-list-search-container">
            <ListFilterComponent
              // defaultValue={searchKey}
              searchPlaceholder={searchPlaceholder}
              onSearch={(key) => {
                if (onSearch) {
                  onSearch(key);
                  return;
                }

                history.push({
                  pathname: location.pathname,
                  hash: location.hash,
                  search: queryString.stringify({
                    ...queryString.parse(location.search),
                    ...{ search: key, page: 1 },
                  }),
                });
              }}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default BaseTabListContainer;
