import React, { useEffect, useState } from 'react';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { useDispatch, connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { createAction } from '../../../utils';
import Loading from '../../../components/base/Loading';
import BaseForm from '../../../components/base/v2/BaseForm';
import CreateBrandStepOne from './CreateBrandStepOne';

function CreateBrand({
  stepConfig,
  selectedBrand,
  hasUpdatedDefaultValues,
  formHasSubmitted,
  updating,
}) {
  const queryString = require('query-string');
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  console.log('@@248-2: ', selectedBrand);
  useEffect(() => {
    if (params.id) {
      dispatch(
        createAction('brand/getBrand')({
          brandPK: params.id,
        }),
      );
    } else {
      dispatch(createAction('brand/loadBrandFromCookie')());
    }
  }, [dispatch, params.id]);

  useEffect(() => {
    if (!params.id && selectedBrand?.pk) {
      dispatch(createAction('brand/cleanBrand')());
    }
    dispatch({ type: 'attributeTag/getAllList' });

    dispatch(createAction('language/getAllList')());
    return () => {
      dispatch(createAction('brand/cleanBrand')());
    };
  }, []);

  useEffect(() => {
    if (formHasSubmitted && !updating && !history.location.state?.from) {
      history.push({ pathname: '/brands' });
    }
  }, [formHasSubmitted, updating, history, selectedBrand?.pk]);

  const stepContent = [<CreateBrandStepOne />];

  const gotoBrandList = () => {
    history.push({
      pathname: '/brands',
      search: queryString.stringify({
        page: 1,
        rank: true,
        search: '',
      }),
    });
  };

  // if ((params.id && !selectedBrand?.pk) || (!params.id && selectedBrand?.pk)) {
  //   return <Loading customClass={'common-full-height'} />;
  // }

  return (
    <>
      <BaseForm
        defaultValues={selectedBrand}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={formHasSubmitted}
        tempSave={(save, getValues) => {
          if (save) {
            dispatch({
              type: 'brand/saveOrRemoveBrandFromCookie',
              payload: { save, value: getValues() },
            });
          } else {
            dispatch({
              type: 'brand/saveOrRemoveBrandFromCookie',
              payload: { save },
            });
          }
        }}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: 'Brand is successfully created.',
          steps: null,
          buttons: [
            {
              text: 'Back to brand list',
              action: gotoBrandList,
            },
          ],
        }}
        showFinishPop={formHasSubmitted}
        continueCreateConfig={{
          onConfirm: () => {
            history.push({
              ...history.location.state?.from,
              state: {
                newBrand: selectedBrand,
              },
              hash: queryString.stringify({
                ...queryString.parse(history.location.state?.from?.hash),
                ...{ newBrand: selectedBrand.pk },
              }),
            });
          },
          onCancel: gotoBrandList,
          onClose: gotoBrandList,
        }}
        content={stepContent}
        currentStep={0}
        breadcrumb={
          <CustomBreadcrumb name={params.id ? selectedBrand.name : ''} />
        }
        stepsConfig={stepConfig}
        caution={{
          detail: '', //Create various brands and select them in a campaign.
          title: params.id ? 'Edit ' + selectedBrand.name : 'Create Brand',
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  stepConfig: state.brand.stepConfig,
  selectedBrand: state.brand.selectedBrand,
  hasUpdatedDefaultValues: state.brand.hasUpdatedDefaultValues,
  formHasSubmitted: state.brand.formHasSubmitted,
  updating: state.brand.updating,
});

export default connect(mapPropsToState)(CreateBrand);
