import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { connect } from 'react-redux';
import AuthButton from '../../../components/base/AuthButton';
import ContentSections from '../../../components/base/ContentSections';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { createAction } from '../../../utils';
import Loading from '../../../components/base/Loading';
import { LanguageConfig } from '../../../config/CustomEnums';
import BaseOverView from '../../../components/coupon/couponList/BaseOverview';
import { Link } from 'react-router-dom';
import BasePrompt from '../../../components/base/prompt/BasePrompt';
import { TermsAndConditionsType } from '../../../config/CustomEnums';
import './TermsAndConditionsDetail.scss';

const TermsAndConditionsDetail = ({
  detail,
  languages,
  dispatch,
  relatedCampaignList,
  relatedCouponsetList,
}) => {
  const history = useHistory();
  const params = useParams();
  const [showRelatedPrompt, setShowRelatedPrompt] = useState(false);
  const [relatedPromptKey, setRelatedPromptKey] = useState(null);
  const [relatedPromptTitle, setRelatedPromptTitle] = useState(null);
  const { id } = params;
  useEffect(() => {
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
    dispatch(createAction('termsAndConditions/getOneDetail')({ id }));

    dispatch(
      createAction('campaignList/getAllList')({
        others: {
          termsAndConditionsTemplateIn: id,
          getAll: false,
        },
      }),
    );
    dispatch(
      createAction('couponList/getAllList')({
        termsAndConditionsTemplateIn: id,
        getAll: false,
      }),
    );

    return () => {
      dispatch(createAction('termsAndConditions/clearData')());
      dispatch(createAction('campaignList/clearData')());
      dispatch(createAction('couponList/clearData')());
    };
  }, []);

  const { appliedCouponSetWithPk = [], appliedCampaignWithPk = [] } = detail;
  const renderTabForLanguage = (language) => {
    const title = detail?.translations?.[language]?.title;
    const templateType = detail?.templateType;
    const type = detail?.type;
    const content = detail?.translations?.[language]?.content;
    const createAt = detail?.creationDateDetail;
    const lastModified = detail?.lastModifiedDateDetail;

    return (
      <div className="terms-and-conditions-detail">
        <BaseOverView
          hideSection
          title="information"
          imageTitleNames={['Cover image']}
          itemTitleList={
            templateType === TermsAndConditionsType.merchant
              ? [
                  'Merchant Terms & Conditions section title(Name)',
                  'Template type',
                  'Merchant Terms & Conditions section content',
                  'Create at',
                  'Last Modified',
                ]
              : [
                  'Generic Terms & Conditions section title(Name)',
                  'Template type',
                  'Generic Terms & Conditions section content',
                  'Create at',
                  'Last Modified',
                ]
          }
          itemValueList={[title, type, content, createAt, lastModified]}
        />
        <div className="second-section-title">Applied Coupon Set</div>
        <div className="second-section-content related-items-content">
          {relatedCouponsetList?.length === 0
            ? '-'
            : relatedCouponsetList
                ?.slice(0, 10)
                ?.map((coupon) => (
                  <div className="detail-page-applied">
                    {`[ID: ${coupon.pk}] ${coupon.name}`}
                  </div>
                ))}
          {relatedCouponsetList?.length > 10 ? (
            <label
              className="show-more"
              onClick={() => {
                setShowRelatedPrompt(true);
                setRelatedPromptKey(relatedCouponsetList);
                setRelatedPromptTitle('Applied Coupon Set');
              }}
            >
              show more
            </label>
          ) : null}
        </div>
        <div className="second-section-title">Applied Campaign</div>
        <div className="second-section-content related-items-content">
          {relatedCampaignList?.length === 0
            ? '-'
            : relatedCampaignList
                ?.slice(0, 10)
                ?.map((campaign) => (
                  <div className="detail-page-applied">
                    {`[ID: ${campaign.pk}] ${campaign.name}`}
                  </div>
                ))}
          {relatedCampaignList?.length > 10 ? (
            <label
              className="show-more"
              onClick={() => {
                setShowRelatedPrompt(true);
                setRelatedPromptKey(relatedCampaignList);
                setRelatedPromptTitle('Applied Campaign');
              }}
            >
              show more
            </label>
          ) : null}
        </div>
      </div>
    );
  };

  const tabs = [
    {
      name: 'Detail',
      content: detail.pk ? (
        <div className="scroll-container-common">
          <ContentSections
            hidePreview
            languageTabContent={{
              containers: languages?.map((item) => ({
                container: [renderTabForLanguage(item.code)],
                key: item.code,
                title: item.sourceName,
              })),
            }}
          />
        </div>
      ) : (
        <Loading />
      ),
    },
  ];

  const buttons = [
    <AuthButton
      title="Edit"
      action={() => {
        history.push({
          pathname: 'edit/',
        });
      }}
      requires={PermissionCodes.changeTermsAndConditions}
    />,
  ];
  return (
    <>
      <CustomListComponent
        caution={{
          detail: '',
          title: detail?.title,
        }}
        defaultActiveKey={tabs[0].name}
        breadcrumb={<CustomBreadcrumb name={detail?.title} />}
        buttons={buttons}
        tabs={tabs}
      />
      <BasePrompt
        show={showRelatedPrompt}
        title={relatedPromptTitle}
        description=""
        closeAction={() => {
          setShowRelatedPrompt(false);
          setRelatedPromptKey(null);
        }}
        otherBody={() => (
          <ul className="related-items-container terms-and-conditions-related-items-container">
            {(relatedPromptKey || []).map((item) => (
              <li className="related-items">{`[ID: ${item.pk}] ${item.name}`}</li>
            ))}
          </ul>
        )}
      />
    </>
  );
};

const mapPropsToState = (state) => ({
  detail: state.termsAndConditions.detail,
  languages: state.language.allList,
  relatedCampaignList: state.campaignList.notPagedAllList,
  relatedCouponsetList: state.couponList.notPagedAllList,
});
export default connect(mapPropsToState)(TermsAndConditionsDetail);
