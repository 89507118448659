import React, { useEffect } from 'react';
import { ContinueWithBackButtons } from '../base/BottomStepComponent';
import { useDispatch } from 'react-redux';
import { createAction } from '../../utils';
import ContentSections from '../base/ContentSections';
import MessageSettingSection from './MessageSettingSection';
import { useState } from 'react';

function CreateMessageStepThree() {
  const dispatch = useDispatch();

  const [sumbitData, setSubmitData] = useState({});
  const [onCheck, setOnCheck] = useState({
    value: false,
    isBack: false,
  });
  const isChecking = ({ isBack }) => {
    setOnCheck({
      value: true,
      isBack: isBack,
    });
  };

  const stopCheck = () => {
    setOnCheck({
      value: false,
      isBack: false,
    });
  };

  useEffect(() => {
    if (onCheck.value) {
      dispatch(
        createAction('createMessage/stepChange')({
          data: sumbitData,
          isBack: onCheck.isBack,
          step: 2,
        }),
      );
      stopCheck();
    }
  }, [onCheck.value, onCheck.isBack, dispatch, sumbitData]);

  const sections = [
    <MessageSettingSection
      onSubmit={onCheck.value}
      onSubmitData={(data) => {
        console.log('@70', data);
        setSubmitData(data);
      }}
    />,
  ];
  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />

      <ContinueWithBackButtons
        backAction={() => {
          isChecking({ isBack: true });
        }}
        continueAction={() => {
          isChecking({ isBack: false });
        }}
      />
    </>
  );
}

export default CreateMessageStepThree;
