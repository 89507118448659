import React, { useEffect } from 'react';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { createAction } from '../../../utils';
import Loading from '../../../components/base/Loading';
import { APIStatus } from '../../../config/CustomEnums';
import BaseForm from '../../../components/base/v2/BaseForm';

import CreateMarketContent from './CreateMarketContent';

function CreateMarket() {
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = params;

  const {
    formHasSubmitted,
    market,
    hasUpdatedDefaultValues,
    createStatus,
    apiStatus,
  } = useSelector((state) => ({
    formHasSubmitted: state.targetMarket.formHasSubmitted,
    market: state.targetMarket.detail,
    hasUpdatedDefaultValues: state.targetMarket.hasUpdatedDefaultValues,
    createStatus: state.targetMarket.createStatus,
    apiStatus: state.targetMarket.apiStatus,
  }));

  const stepSet = [<CreateMarketContent />];

  useEffect(() => {
    if (id) {
      dispatch(createAction('targetMarket/getOneDetail')({ id: params.id }));
    } else {
      dispatch(createAction('targetMarket/loadFromCookie')());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  useEffect(() => {
    dispatch({type: 'language/getAllList'})
    return () => {
      dispatch({ type: 'targetMarket/clearData' });
      dispatch({ type: 'language/clearData' })
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (apiStatus === APIStatus.success) history.push('/markets');
  }, [apiStatus, history]);
  console.log('@@54: ', apiStatus);

  if (id && !market?.pk) {
    return <Loading customClass={'common-full-height'} />;
  }

  return (
    <>
      <BaseForm
        defaultValues={market}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={formHasSubmitted}
        tempSave={(save, getValues) => {
          if (!save) {
            dispatch({
              type: 'targetMarket/removeFromCookie',
            });
          } else {
            dispatch({
              type: 'targetMarket/saveToCookie',
              payload: getValues(),
            });
          }
        }}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: 'Target market is successfully created.',
          steps: null,
          buttons: [
            {
              text: 'Back to target market list',
              action: () => {
                history.push({
                  pathname: '/markets',
                });
              },
            },
          ],
        }}
        continueCreateConfig={{
          onConfirm: () => {
            history.push(history.location.state?.from);
          },
          onCancel: () => history.push('/markets'),
          onClose: () => history.push('/markets'),
        }}
        showFinishPop={createStatus === APIStatus.success}
        content={stepSet}
        currentStep={0}
        breadcrumb={
          <CustomBreadcrumb
            name={params.id ? market.sourceName : 'Create target market'}
          />
        }
        caution={{
          detail: 'coming soon',
          title: params.id ? market.sourceName : 'Create target market',
        }}
      />
    </>
  );
}

export default CreateMarket;
