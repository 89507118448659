import { defaultStep, getNewStepConfig } from './StepBarUtil';
import { LanguageConfig, StatusTag, SESSION_KEYS } from '../config/CustomEnums';
import {
  createOrUpdateGeneralAlert,
  getGeneralAlertDetail,
  getGeneralAlertList,
  deleteGeneralAlerts,
} from '../services/GeneralAlertApiHelper';
import {
  saveToSessionStorage,
  getObjectFromSessionStorage,
  removeFromSessionStorage,
  decodedStripedHtml,
} from '../utils';
import { createModel } from './BaseModel';
import { formatDate, TimeFormater } from '../utils/TimeFormatUtil';
import { apiWithResponseHandle } from './LoadingUtil';

export const sessionDataKey = {
  objectKey: SESSION_KEYS.CREATE_GENERAL_ALERT_SESSION_KEY,
  stepEndKey: SESSION_KEYS.CREATE_GENERAL_ALERT_END_SESSION_KEY,
  origionalData: SESSION_KEYS.CREATE_GENERAL_ALERT_ORIGIN_DATA_SESSION_KEY,
};

export const saveGeneralAlertToSessionStorage = (data) => {
  saveToSessionStorage(sessionDataKey.stepEndKey, true);
  saveToSessionStorage(sessionDataKey.objectKey, data);
};

export const removeGeneralAlertSessionStroage = () => {
  removeFromSessionStorage(sessionDataKey.objectKey);
};

const stepNames = ['Content', 'Property', 'Preview & Send'];

const parseOneGeneralAlert = (apiGeneralAlert) => {
  const translations = {
    [LanguageConfig.english]: {
      content: apiGeneralAlert.content,
    },
  };
  const edges = apiGeneralAlert.translations?.edges || [];
  edges.forEach((translation) => {
    const { language, content, pk } = translation.node;
    translations[language] = { content, pk };
  });
  const deliveryStartDate = formatDate(
    apiGeneralAlert.startDate,
    TimeFormater.dayMonthYearWeekTimeA,
  );
  const deliveryEndDate = formatDate(
    apiGeneralAlert.endDate,
    TimeFormater.dayMonthYearWeekTimeA,
  );

  return {
    ...apiGeneralAlert,
    translations,
    deliveryStartDate: deliveryStartDate,
    deliveryEndDate: deliveryEndDate,
    originalStartDate: apiGeneralAlert.startDate,
    originalEndDate: apiGeneralAlert.endDate,
    displayStatus: apiGeneralAlert.isActive
      ? StatusTag.active
      : StatusTag.inactive,
    title: decodedStripedHtml(apiGeneralAlert.content),
  };
};

const parseDisplayGeneralAlert = (apiGeneralAlert) => {
  const deliveryStartDate = formatDate(
    apiGeneralAlert.startDate,
    TimeFormater.dayMonthYearWeekTimeA,
  );
  const deliveryEndDate = formatDate(
    apiGeneralAlert.endDate,
    TimeFormater.dayMonthYearWeekTimeA,
  );

  return {
    ...apiGeneralAlert,
    displayDeliveryDate: deliveryStartDate + ' - ' + deliveryEndDate,
    displayStatus: apiGeneralAlert.isActive
      ? StatusTag.active
      : StatusTag.inactive,
    title: decodedStripedHtml(apiGeneralAlert.content),
  };
};

export default createModel({
  namespace: 'generalAlert',
  params: {
    sessionKey: sessionDataKey.objectKey,
    dataKey: 'generalAlerts',
    listAPI: getGeneralAlertList,
    pkNode: 'GeneralAlertNode',
    detailAPI: getGeneralAlertDetail,
    deleteAPI: deleteGeneralAlerts,
    parse: (data) =>
      data.generalAlerts.edges.map((edge) =>
        parseDisplayGeneralAlert(edge.node),
      ),
    parseDetail: (data) => parseOneGeneralAlert(data.generalAlert),
  },
  states: {
    errorFields: [],
    stepConfig: defaultStep(stepNames),
    currentStep: 0,
    languageTag: LanguageConfig.english,
    formChanged: false,
  },
  reducers: {
    loadGeneralAlertFromCookie(state, { payload }) {
      const generalAlert = getObjectFromSessionStorage(
        sessionDataKey.objectKey,
      );
      if (!generalAlert) {
        return {
          ...state,
          detail: {
            translations: [],
            isActive: true,
          },
          hasUpdatedDefaultValues: true,
        };
      }
      saveToSessionStorage(sessionDataKey.origionalData, generalAlert);
      saveToSessionStorage(sessionDataKey.objectKey, generalAlert);
      return {
        ...state,
        detail: {
          ...generalAlert,
        },
        hasUpdatedDefaultValues: true,
      };
    },

    saveOrRemoveMessageFromCookie(state, { payload }) {
      if (!payload) {
        removeFromSessionStorage(sessionDataKey.objectKey);
      }
      saveToSessionStorage(sessionDataKey.stepEndKey, true);
      return {
        ...state,
      };
    },
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    updateScheduleState(state, { payload }) {
      const data = payload.data || {};
      const pagedList = state.pagedList.map((item, index) => {
        if (item.pk === data.pk) {
          item.displayStatus = data.isActive
            ? StatusTag.active
            : StatusTag.inactive;
          item.scheduledStatus = data.scheduledStatus;
          item.isActive = data.isActive;
        }
        return { ...item };
      });
      return { ...state, ...pagedList };
    },
    updateGeneralAlertState(state, { payload }) {
      let data = payload;

      if (payload.content) {
        if (payload.language === LanguageConfig.english) {
          data = { content: payload.content };
        } else {
          data = {
            translations: {
              ...state.detail.translations,
              [payload.language]: {
                ...state.detail.translations?.[payload.language],
                language: payload.language,
                content: payload.content,
              },
            },
          };
        }
      }

      const generalAlert = { ...state.detail, ...data };
      saveToSessionStorage(sessionDataKey.objectKey, generalAlert);
      return {
        ...state,
        detail: {
          ...generalAlert,
        },
        formChanged: true,
        hasUpdatedDefaultValues: true,
      };
    },

    updateMessageTranslation(state, { payload }) {
      const { language } = payload;
      const message = {
        ...state.detail.translations,
        [language]: {
          ...payload,
        },
      };
      saveToSessionStorage(sessionDataKey.objectKey, message);
      return {
        ...state,
        detail: {
          ...state.detail,
          translations: message,
        },
      };
    },

    stepChange(state, { payload }) {
      const isBack = payload.isBack;
      let step = payload.step;
      const isValid = payload.isValid;
      const stepConfig = getNewStepConfig(
        step,
        state.stepConfig,
        !isValid,
        isBack,
      );
      if (isValid) {
        step = isBack ? step - 1 : step + 1;
      }
      return {
        ...state,
        currentStep: step,
        stepConfig,
      };
    },
  },

  effects: {
    createOrUpdate: [
      function* ({ payload }, { select, put }) {
        const payloadData = payload?.data;
        const modelDetail = yield select((state) => state.generalAlert.detail);
        const detail = payloadData || modelDetail;
        const data = {
          content: detail.translations?.[LanguageConfig.english]?.content,
          displayPriority: detail.displayPriority,
          severityLevel: detail.severityLevel,
          startDate: detail.startDate,
          endDate: detail.endDate,
          isActive: detail.isActive,
          isPublished: payload.isPublished,
          translations: Object.keys(detail.translations)
            .filter((key) => key !== 'en')
            .map((key) => {
              const translation = {
                language: key,
                content: detail.translations[key].content,
              };
              console.log('@@72: ', key, detail?.translations);
              if (key in (detail?.translations || {})) {
                translation.id = detail.translations[key].pk;
              }
              return translation;
            }),
        };

        if (detail.id) {
          data.id = detail.pk;
          data.translations = [...data.translations];
        } else {
          const languages = yield select((state) => state.language.allList);
          const filledTranslations = languages
            .map((item) => {
              if (item.code in detail.translations) {
                return;
              }
              if (item.code === 'en') return;
              return {
                language: item.code,
                content: '',
              };
            })
            .filter((item) => item !== undefined);
          data.translations = [...data.translations, ...filledTranslations];
        }
        const serviceArgs = [createOrUpdateGeneralAlert, data];
        const afterActions = payload.afterActions || (() => {});
        yield put({
          type: 'responseCreateOrUpdate',
          payload: { serviceArgs, isCreate: !payload.backToList, afterActions },
        });
      },
      { type: 'takeLatest' },
    ],
    DeactiveOrAcitve: [
      function* ({ payload }, { select, put }) {
        console.log('@327 model', payload);
        const data = {
          isPublished: payload.isPublished,
          isActive: payload.isActive,
          id: payload.id,
        };
        const serviceArgs = [createOrUpdateGeneralAlert, data];
        const afterActions = payload.afterActions || (() => {});
        yield put({
          type: 'responseCreateOrUpdate',
          payload: { serviceArgs, isCreate: !payload.backToList, afterActions },
        });
      },
      { type: 'takeLatest' },
    ], 
    updateOrder: [
      function* ({ payload }, { all, select, put }) {
        const { data, afterAction } = payload;
        const serviceArgs = [createOrUpdateGeneralAlert, data];
        function* onSuccess(data) {
          if (afterAction) {
            afterAction();
          }
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
});
