import React, { useState } from 'react';
import AnimatedNumber from 'animated-number-react';
import { useSelector } from 'react-redux';
import './CouponsCard.scss';
import { enLocaleNumberFormatter } from '../../utils';
import {
  COUPON_PIE_CHART_CONFIG,
  ANIMATION_DURATION,
} from '../../config/DashboardConfig';
import { Link } from 'react-router-dom';
import icon from '../../assets/images/customer_overview_vector.svg';
import { Button, Image, ButtonGroup, ProgressBar } from 'react-bootstrap';
import './Top5CustomerNationalityCard.scss';
import NoDataPage from '../base/NoDataPage';
import { TimeFormater, formatDate } from '../../utils/TimeFormatUtil';

const Top5CustomerNationalityCard = () => {
  const { endDate, top5CustomerNationality } = useSelector((state) => ({
    endDate: state.dashboard.endDate,
    top5CustomerNationality: state.dashboard.top5CustomerNationality,
  }));

  return (
    <div className="dashboard-coupon-card dashboard-top5-customer-nationality-card">
      <div className="dashboard-card-title">
        <div>TOP 5 NATIONALITY</div>
        <label className="dashboard-top5-nationality-label-caution">
          {endDate
            ? `as of ${formatDate(
                endDate,
                TimeFormater.dayMonthYearTimePeriod,
              )}`
            : null}
        </label>
      </div>
      <div className="dashboard-card-body">
        {top5CustomerNationality?.top5CustomerNationalityData?.length <= 0 ? (
          <NoDataPage />
        ) : (
          <>
            <div className="dashboard-card-head">
              <label className="dashboard-card-name">Residence</label>
              <label className="dashboard-card-number">No. of visitors</label>
            </div>
            <div className="dashboard-coupon-card-data">
              {top5CustomerNationality?.top5CustomerNationalityData?.map(
                (item, index) => {
                  return (
                    <div
                      className={`dashboard-coupon-card-data-single dashboard-coupon-card-data-${
                        index <= 2 ? 'top3' : 'others'
                      }`}
                    >
                      <div>
                        <label className="dashboard-coupon-card-data-single-name">
                          {item.name}
                        </label>
                        <label className="dashboard-coupon-card-data-single-value">
                          {item.value}
                        </label>
                      </div>
                      <ProgressBar
                        className={`${
                          item.percentage === 0 ? 'non-progress' : ''
                        }`}
                        now={item.percentage}
                        label={
                          <label className="dashboard-card-data-percentage">
                            {`${item.percentage}%`}
                          </label>
                        }
                      />
                    </div>
                  );
                },
              )}
            </div>
          </>
        )}
      </div>
      <div className="dashboard-card-data-defined">
        <div className="dashboard-card-data-collected">
          <label>Data collected</label>
          <label className="dashboard-card-data-number">
            {top5CustomerNationality?.totalWithNationality || 0}
          </label>
        </div>
        <div className="dashboard-card-data-undefined">
          <label>Undefined</label>
          <label className="dashboard-card-data-number">
            {top5CustomerNationality?.totalCustomerNationality -
              top5CustomerNationality?.totalWithNationality || 0}
          </label>
        </div>
      </div>
      <Link
        className="dashboard-overview-link coupons-overview-link"
        to={{ pathname: '/dashboard_customer' }}
      >
        {/* Customer Overview */}
        See detail
        <img className="dashboard-overview-link-icon" src={icon} alt="" />
      </Link>
    </div>
  );
};

export default Top5CustomerNationalityCard;
