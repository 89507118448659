import React from 'react';
import { Table, Image } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  LanguageTabTransLation,
  LanguageConfig,
} from '../../config/CustomEnums';
import CustomViewer from '../base/CustomViewer';

const fieldsLanguages = [
  LanguageConfig.english,
  LanguageConfig.traditionalChinese,
  LanguageConfig.simplifiedChinese,
];

function MessageOverviewTableSection(props) {
  const data = props.data || {};

  const getSectionTitle = () => {
    return data.displayChannels?.toUpperCase();
  };

  const fieldValueText = (value, index) => {
    return (
      <td
        key={`fieldValueText-${value}-${index}`}
        className="create-section-field-input"
      >
        <CustomViewer initValue={value} />
      </td>
    );
  };

  const fieldNameText = (value) => {
    return (
      <td className="create-section-label content-section-field-clumn">
        {value}
      </td>
    );
  };

  const fieldColumnText = (value) => {
    return (
      <th className="create-section-label content-section-value-clumn">
        {value}
      </th>
    );
  };

  const sectionValues = (valueKey) => {
    return (
      <>
        {fieldsLanguages.map((language, index) => {
          if (language === LanguageConfig.english) {
            return fieldValueText(data[valueKey], index);
          } else {
            return fieldValueText(
              data.translations?.[language]?.[valueKey],
              index,
            );
          }
        })}
      </>
    );
  };

  const coverImage = () => {
    return (
      <>
        {fieldsLanguages.map((language, index) => {
          let image = '';
          if (language === LanguageConfig.english) {
            image = data.photo;
          } else {
            image = data.translations?.[language]?.photo;
          }
          if (Array.isArray(image)) {
            const imageList = image.map((item) => {
              if (item.type) {
                return item.value;
              }
              return image;
            });
            image = imageList[0];
          }
          return (
            <td key={`coverImage-${language}-${index}`}>
              {image && image !== '' ? (
                <Image
                  className="content-section-rectangle-image"
                  src={image}
                />
              ) : (
                '-'
              )}
            </td>
          );
        })}
      </>
    );
  };

  return (
    <>
      <label className="create-section-title">{getSectionTitle()}</label>
      <PerfectScrollbar>
        <Table bordered className="content-section-table-container">
          <thead>
            <tr>
              {fieldNameText()}
              {fieldColumnText(LanguageTabTransLation.english)}
              {fieldColumnText(LanguageTabTransLation.traditionalChinese)}
              {fieldColumnText(LanguageTabTransLation.simplifiedChinese)}
            </tr>
          </thead>
          <tbody>
            <tr>
              {fieldNameText('Title (optional)')}
              {sectionValues('name')}
            </tr>
            <tr>
              {fieldNameText('Content')}
              {sectionValues('content')}
            </tr>
            <tr>
              {fieldNameText('Image (optional)')}
              {coverImage()}
            </tr>
          </tbody>
        </Table>
      </PerfectScrollbar>
    </>
  );
}

export default MessageOverviewTableSection;
