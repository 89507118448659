import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CreateGoodieBagStepOne from '../../../components/goodieBags/CreateGoodieBagStepOne';
import CreateGoodieBagStepTwo from '../../../components/goodieBags/CreateGoodieBagStepTwo';
import CreateGoodieBagStepThree from '../../../components/goodieBags/CreateGoodieBagStepThree';
import CreateGoodieBagStepFour from '../../../components/goodieBags/CreateGoodieBagStepFour';
import { createAction } from '../../../utils';
import { CampaignType, APIStatus } from '../../../config/CustomEnums';
import AuthButton from '../../../components/base/AuthButton';
import BaseForm from '../../../components/base/v2/BaseForm';
import Loading from '../../../components/base/Loading';
import {
  saveGoodieBagToSessionStorage,
  removeGoodieBagSessionStorage,
  GOODIE_BAG_SESSION_KEY,
} from '../../../models/GoodieBagsModel';

function CreateGoodieBag({
  stepConfig,
  currentStep = 0,
  goodieBag = {},
  formHasSubmitted,
  hasUpdatedDefaultValues,
}) {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const goodieBagId = goodieBag.pk;

  const loadData = (data) => {
    if (!params.id) {
      dispatch({
        type: 'goodieBags/loadGoodieBagFromCookie',
        payload: data,
      });
      return;
    } else {
      dispatch(
        createAction('goodieBags/getOneGoodieBag')({
          id: params.id,
          ...data,
        }),
      );
    }
  };

  const clearData = () => {
    dispatch({ type: 'campaignList/clearData' });
    dispatch({ type: 'brand/clearData' });
    dispatch({ type: 'goodieBags/clearData' });
    dispatch({ type: 'countryCode/clearData' });
    dispatch({ type: 'couponLabel/clearData' });
  };

  useEffect(() => {
    clearData();
    dispatch({
      type: 'brand/getAllList',
      payload: { params: { handleFortuneBagEntitlement: true } },
    });
    dispatch({
      type: 'campaignList/getAllList',
      // type: 'goodieBags/getSlotAllCampaigns',
      payload: {
        others: {
          typeIn: `${CampaignType.cashVoucherCampaign},${CampaignType.giftCampaign},${CampaignType.fortuneBagForGoodieBagCampaign}`,
          isLinkedGoodieBag: false,
        },
        from: 'goodiebagDetail',
      },
    });
    dispatch({ type: 'countryCode/getAllList' });
    dispatch({ type: 'couponLabel/getList' });
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
    loadData();

    return () => {
      clearData();
    };
  }, []);

  const stepSet = [
    <CreateGoodieBagStepOne />,
    <CreateGoodieBagStepTwo />,
    <CreateGoodieBagStepThree />,
    <CreateGoodieBagStepFour />,
  ];

  const toGoodieBagList = () => {
    history.push({
      pathname: `/goodie_bags`,
    });
  };
  console.log('@@93: ', goodieBag);
  return (
    <BaseForm
      defaultValues={goodieBag}
      formHasSubmitted={formHasSubmitted}
      hasUpdatedDefaultValues={hasUpdatedDefaultValues}
      tempSave={(save, getValues) => {
        if (save) {
          saveGoodieBagToSessionStorage(getValues());
        } else {
          removeGoodieBagSessionStorage();
        }
      }}
      continueCreateConfig={{
        onConfirm: () => {
          history.push(history.location.state?.from);
        },
        onCancel: toGoodieBagList,
        onClose: toGoodieBagList,
      }}
      sessionDataKey={GOODIE_BAG_SESSION_KEY}
      content={stepSet}
      currentStep={currentStep}
      breadcrumb={
        <CustomBreadcrumb
          name={goodieBagId ? goodieBag?.name : 'Create Goodie Bag'}
        />
      }
      stepsConfig={stepConfig}
      caution={{
        detail:
          'Create a Goodie bag to enable customers to choose their desired coupons with entitled credits or quota.',
        title: goodieBagId ? goodieBag?.name : 'Create Goodie Bag',
      }}
    />
  );
}

const mapPropsToState = (state) => ({
  stepConfig: state.goodieBags.stepConfig,
  currentStep: state.goodieBags.currentStep,
  goodieBag: state.goodieBags.detail,
  formHasSubmitted: state.goodieBags.formHasSubmitted,
  hasUpdatedDefaultValues: state.goodieBags.hasUpdatedDefaultValues,
});

export default connect(mapPropsToState)(CreateGoodieBag);
