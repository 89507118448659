import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from '../../../utils';
import ContentSections from '../../../components/base/ContentSections';
import InformationSection from '../../../components/paymentSettlement/InformationSection';
import CampaignSection from '../../../components/paymentSettlement/campaignSection';
import CouponsSection from '../../../components/paymentSettlement/couponsSection';
import CouponUseRecordSection from '../../../components/paymentSettlement/couponUseRecordSection';
import CommentSection from '../../../components/paymentSettlement/commentSection';
import { useHistory, useParams } from 'react-router-dom';
import { PermissionCodes } from '../../../config/PermissionCodes';
import CustomListComponent from '../../../components/base/CustomListComponent';
import AuthButton from '../../../components/base/AuthButton';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { ImportResource } from '../../../models/DownloadImportModel';
import './PaymentSettlementChangeStatusButton.scss';
import {
  DisplayPaymentSettlementReviewStatusType,
  ADMIN_TYPE,
} from '../../../config/CustomEnums';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import PaymentSettlementUpdateStatusPrompt from '../../../components/paymentSettlement/PaymentSettlementUpdateStatusPrompt';

export default function PaymentSettlementReportDetail() {
  const params = useParams();
  const { id } = params;
  const dispatch = useDispatch();
  const history = useHistory();
  const { adminType, detail , reviewStatus, comment, brandPk} = useSelector(
    (state) => ({
        adminType: state.users?.adminType,
        detail: state.paymentSettlementList?.detail,
        reviewStatus: state.paymentSettlementList?.detail?.displayStatus,
        comment: state.paymentSettlementList?.detail?.comment,
        brandPk: state.paymentSettlementList?.detail?.brand,
    }),
  );

  const [showAlterInfoPrompt, setShowAlterInfoPrompt] = useState(false);
  const [clickButton, setClickButton] = useState();

  useEffect(() => {
    dispatch(createAction('paymentSettlementList/getOneDetail')({ id }));
    return () => dispatch({ type: 'paymentSettlementList/clearData' });
  }, [id]);

  const sections = (comment)=>{
    return  [<InformationSection />, <CampaignSection />, 
        <CouponsSection />, <CouponUseRecordSection />,
        comment?.length == 0 ? null : <CommentSection />];
  }

  const tabs = [
    {
      name: 'Detail',
      content: (
        <div className="scroll-container-common">
          <ContentSections sections={sections(comment)} hidePreview={true} />
          {/* <PaymentSettlementChangeStatusButton adminType={adminType} reviewStatus={reviewStatus} id={id} brandPk={brandPk}/> */}
          <SaveAndBackButtons
            saveText={ (adminType == ADMIN_TYPE.HKTB_ADMIN) 
              ? 'Amendment'
              : ((adminType == ADMIN_TYPE.MERCHANT_ADMIN &&
                reviewStatus != DisplayPaymentSettlementReviewStatusType.agree) 
                ? 'Agree' 
                : '')
            }
            saveTempText={ (adminType == ADMIN_TYPE.MERCHANT_ADMIN &&
              reviewStatus == DisplayPaymentSettlementReviewStatusType.waiting) ? 'Disagree' : ''}
            saveAction={() => {
              setClickButton(adminType == ADMIN_TYPE.HKTB_ADMIN ? "Amendment" : "Agree");
              setShowAlterInfoPrompt(true);
            }}
            saveTempAction={() => {
              setClickButton("Disagree")
              setShowAlterInfoPrompt(true);
            }}
            baseTempSaveArea="payment-settlement-save-area"
          />
        </div>
      ),
    },
  ];

  const GoToViewListPage = () => {
    history.push({
      pathname: `/payment_settlement/byMerchant/${brandPk}`, 
      hash: "withCouponData"
    });
  };

  const exportPDFAction = () => {
    dispatch(
      createAction('downloadAndImport/createDownloadTask')({
        from: ImportResource.paymentAndSettlementReport,
        related: {reportId: parseInt(id)},
        afterAction: () => GoToViewListPage(),
      }),
    );
  };

  const buttons = [
    <AuthButton
      title="Export PDF"
      action={exportPDFAction}
      requires={PermissionCodes.addExportjob}
    />,
  ];

  return (
    <>
    <CustomListComponent
      caution={{
        detail: 'detail',
        title: detail?.reportName,
      }}
      breadcrumb={
        <CustomBreadcrumb
        name={detail?.reportName}
        />
      }
      buttons={buttons}
      tabs={tabs}
    />
    <PaymentSettlementUpdateStatusPrompt
      show={showAlterInfoPrompt} 
      clickButton={clickButton}
      onClose={() => setShowAlterInfoPrompt(false)}
      id={id}
      brandPk={brandPk}
    />
    </>
  );
}
