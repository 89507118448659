import React from 'react';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import CustomEditor from './CustomEditor';
import CustomTitleWithInput from './CustomTitleWithInput';
import './BaseMarkDownArea.scss';

function BaseMarkDownAreaWithoutController({
  title = {},
  setTitleValue = () => { },
  content = {},
  setContentValue = () => { },
}) {

  return (
    <div className="base-mark-down-area">
      <CustomTitleWithInput
        title={title?.titleName}
        customClass='base-mark-down-input'
        defaultValue={title?.titleValue}
        setValue={(data) => {
          setTitleValue(data);
        }}
        disabled={title?.titleDisabled}
        error={{
          id: title?.titleKey,
          error: title?.titleError,
          message: title?.titleErrorMessage,
        }}
        extra={title?.titleExtra}
      />
      {title?.titleReminder
        ? <label className="link-to-terms-and-conditions-template-tips">
          (The content of the selected template has been modified)
        </label>
        : <></>
      }

      <CustomTitleLabel title={content?.contentName} />
      {content?.contentTips ? <label className="tips-message">{content?.contentTips}</label> : null}
      <CustomEditor
        disabled={content?.contentDisabled}
        initialValue={content?.contentValue}
        onValueChange={(data) => setContentValue(data)}
        errorMessage={content?.contentErrorMessage}
        error={content?.contentError}
        {...content?.contentExtra}
      />
      {content?.contentReminder
        ? <label className="link-to-terms-and-conditions-template-tips">
          (The content of the selected template has been modified)
        </label>
        : <></>
      }
    </div>
  );
}

export default BaseMarkDownAreaWithoutController;