import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class SurveyFormOverView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: null,
    };
  }

  renderItemBody = (title, index) => {
    const value = this.props.itemValueList[index];
    if (value) {
      if (Array.isArray(value)) {
        return this.props.itemCouponsTitle.includes(title) ? (
          <div className="second-section-content related-items-content">
            {value.length === 0
              ? '-'
              : value.map((coupon) => (
                  <div>
                    <Link
                      className="detail-page-link"
                      to={`/coupons/${coupon.pk}/`}
                    >{`[ID: ${coupon.pk}] ${coupon.name}`}</Link>
                  </div>
                ))}
          </div>
        ) : (
          value.map((option) => (
            <div className="second-section-content">{option}</div>
          ))
        );
      }
      return <div className="second-section-content">{value}</div>;
    }
    return <div className="second-section-content">-</div>;
  };

  render() {
    return (
      <div
        className={`${
          this.props.hideSection ? '' : 'coupon-overview-container'
        } ${this.props.className || ''}`}
      >
        {this.props.title ? (
          <div className="first-section-title overview-section-title">
            {this.props.title}
          </div>
        ) : null}
        {this.props.itemTitleList.map((title, index) => {
          return (
            <div className="second-section">
              <div className="second-title-section">
                <div className="second-section-title">{title}</div>
                {this.renderItemBody(title, index)}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default SurveyFormOverView;
