import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router';
import { CampaignType } from '../../../config/CustomEnums';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { createAction } from '../../../utils';
import { ContinueCreate } from '../CampaignUrlConfig';
import { CustomTitleLabel } from '../../earning/CustomBaseComponments';
import BasePrompt from '../../base/prompt/BasePrompt';
import CustomTitleWithSwitch from '../../base/CustomTitleWithSwitch';
import SorableTourCard from '../../tourCard/SortableTourCard';
import arrayMove from 'array-move';
import AuthButton from '../../base/AuthButton';
import SpecificCustomSwitchButton from '../../../containers/merchants/stores/SpecificCustomSwitchButton';
import { insertKeyToTranslations } from '../../../utils';
import { LanguageConfig } from '../../../config/CustomEnums';
import LabelWithSwitch from '../../base/LabelWithSwitch';
import './GeneralSection.scss';
import {
  changeSwitch,
  isShowCouponRelated,
} from '../../../models/CreateCampaignModel';

function TourCardSection({ language }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const id = params.id;
  const { watch, setValue, getValues } = useFormContext();
  const showTourCard = watch('showTourCard');
  const tourCards = watch('tourCards') || [];

  const translations = watch('translations') || {};
  const tourCardSectionTitle = translations[language]?.tourCardSectionTitle;
  const campaignType = watch('campaignType');
  const [promptConfig, setPromptConfig] = useState();
  const isFollowCouponTemplateTourCard = watch(
    'isFollowCouponTemplateTourCard',
  );
  const isCouponRelated = isShowCouponRelated(campaignType);

  useEffect(() => {
    dispatch(createAction('tourCard/getList')({ isSelectorLoad: true }));
  }, []);

  return (
    <>
      <LabelWithSwitch
        labelText={'Tour card'}
        className="create-section-title"
        notUseDefaultLabelStyle
        showSwitch={isCouponRelated}
        checked={isFollowCouponTemplateTourCard}
        onChange={() =>
          changeSwitch({
            getValues,
            setValue,
            key: 'isFollowCouponTemplateTourCard',
            value: !isFollowCouponTemplateTourCard,
          })
        }
      />
      <CustomTitleWithSwitch
        title="Show Tour card"
        defaultValue={showTourCard}
        disabled={isFollowCouponTemplateTourCard && isCouponRelated}
        setValue={(value) => {
          setValue('showTourCard', !!value, { shouldDirty: true });
        }}
      />
      {showTourCard ? (
        <>
          <label className="second-section-title create-section-label-bottom-space">
            Tour card section title (Optional)
          </label>
          <input
            type="text"
            className={'text-input-field'}
            value={tourCardSectionTitle}
            disabled={isFollowCouponTemplateTourCard && isCouponRelated}
            onChange={(e) => {
              insertKeyToTranslations({
                language,
                key: 'tourCardSectionTitle',
                value: e.target.value,
                setValue,
                getValues,
              });
            }}
            maxLength={200}
          />
          <SorableTourCard
            data={tourCards || []}
            onSortEnd={(oldIndex, newIndex) => {
              setValue('tourCards', arrayMove(tourCards, oldIndex, newIndex), {
                shouldDirty: true,
              });
            }}
            setData={(value) => {
              setValue('tourCards', value, {
                shouldDirty: true,
              });
            }}
            disabled={
              language !== LanguageConfig.english ||
              (isFollowCouponTemplateTourCard && isCouponRelated)
            }
          />

          {id ? (
            <AuthButton
              variant={'primary'}
              title={'Add new Tour card'}
              action={() => {
                setPromptConfig({
                  rightButton: {
                    text: 'Go to create a Tour card',
                    action: () =>
                      history.push({
                        pathname: '/tour_cards/create',
                        state: { from: history.location, ...ContinueCreate },
                      }),
                  },
                  title: 'Go to create a Tour card?',
                  description:
                    'You will leave the coupon set creation process. After you create tour card you can come back to create a coupon set.',
                });
              }}
              customClass={`btn-back-button-common base-buttons-item base-buttons-secondary exist-button exist-button-margin-top align-left`}
              requires={PermissionCodes.changeTourCard}
              disabled={
                language !== LanguageConfig.english ||
                (isFollowCouponTemplateTourCard && isCouponRelated)
              }
            />
          ) : null}
        </>
      ) : null}

      <BasePrompt
        show={!!promptConfig}
        closeAction={() => {
          setPromptConfig(null);
        }}
        rightButton={promptConfig?.rightButton}
        leftButton={promptConfig?.leftButton}
        title={promptConfig?.title}
        description={promptConfig?.description}
      />
    </>
  );
}

export default TourCardSection;
