import { createModel } from './BaseModel';
import { apiWithResponseHandle, loading } from './LoadingUtil';
import { getPagedList, createOrUpdate } from '../services/RateLimitApiHelper';
import { SESSION_KEYS, APIStatus } from '../config/CustomEnums';

export const RATE_LIMIT_TYPE = {
  COUPON_GRANT: 'Coupon assignment',
  COUPON_STATUS_MODIFICATION: 'Coupon status modification',
  COUPON_GENERATED: 'Coupon generation',
  CUSTOMER_OTP_CHECK: 'Search of OTP',
  CUSTOMER_MEMBERSHIP_ID_CHECK: 'Check membership ID by using mobile number',
  EXPORT_JOB_CREATE: 'Export of systems records',
  ADMINISTRATOR_CREATE: 'Admin account creations',
};

export const REVERT_RATE_LIMIT_TYPE = {
  'Coupon assignment': 'COUPON_GRANT',
  'Coupon status modification': 'COUPON_STATUS_MODIFICATION',
  'Coupon generation': 'COUPON_GENERATED',
  'Search of OTP': 'CUSTOMER_OTP_CHECK',
  'Check membership ID by using mobile number': 'CUSTOMER_MEMBERSHIP_ID_CHECK',
  'Export of systems records': 'EXPORT_JOB_CREATE',
  'Admin account creations': 'ADMINISTRATOR_CREATE',
};

const parseRateLimitType = (type) => {
  let displayType = '';
  if (type in RATE_LIMIT_TYPE) {
    displayType = RATE_LIMIT_TYPE[type];
  }
  return displayType;
};
export const revertParseRateLimitType = (displayType) => {
  let rateLimitType = '';
  if (displayType in REVERT_RATE_LIMIT_TYPE) {
    rateLimitType = REVERT_RATE_LIMIT_TYPE[displayType];
  }
  return rateLimitType;
};

const parseDetail = (data) => {
  return {
    ...data,
    rateLimitType: parseRateLimitType(data.rateLimitType),
  };
};

const parseList = (data) => {
  return data?.map((item, index) => {
    return {
      ...parseDetail(item),
      pk: data.length - index,
    };
  });
};

const parseInputData = (source) => {
  return {
    ...source,
  };
};

const getInitialState = () => ({
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    {
      displayName: 'Function',
      fieldName: 'rateLimitType',
    },
  ],
  detail: {},
  totalCount: 0,
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  currentPage: 0,
  totalPage: 0,
  hasUpdatedDefaultValues: false,
  formHasSubmitted: false,
});

export default createModel({
  namespace: 'rateLimit',
  params: {
    sessionKey: SESSION_KEYS.RATE_LIMIT_SESSION_KEY,
    dataKey: SESSION_KEYS.RATE_LIMIT_DATA_SESSION_KEY,
    initState: {
      createUpdateError: [],
    },
  },
  states: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },
  },
  effects: {
    getList: [
      function* ({ payload }, { call, select, put }) {
        const serviceArgs = [getPagedList];

        function* onSuccess(data) {
          const totalCount = data?.administratorRateLimits?.length;
          yield put({
            type: 'updateListData',
            payload: {
              ...payload,
              data: parseList(data?.administratorRateLimits),
              totalCount: totalCount,
              pageInfo: {
                startCursor: 0,
                endCursor: totalCount - 1,
              },
            },
          });
        }
        if (payload?.loading) {
          yield loading(serviceArgs, onSuccess);
        } else {
          yield apiWithResponseHandle(serviceArgs, onSuccess);
        }
      },
      { type: 'takeLatest' },
    ],
    getOneDetail: [
      function* ({ payload }, { all, select, put }) {
        const rateLimitType = revertParseRateLimitType(payload.rateLimitType);
        const serviceArgs = [getPagedList, { rateLimitType }];
        function* onSuccess(data) {
          if (data?.administratorRateLimits?.length) {
            const detail = parseDetail(data.administratorRateLimits[0]);
            yield all([
              put({
                type: 'updateState',
                payload: {
                  detail: detail,
                  hasUpdatedDefaultValues: true,
                },
              }),
            ]);
          } else {
            yield put({
              type: 'updateState',
              payload: { apiStatus: APIStatus.failed },
            });
          }
        }
        function* onError(err) {
          yield put({
            type: 'updateState',
            payload: { apiStatus: APIStatus.failed },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],
    createOrUpdate: [
      function* ({ payload }, { call, select, put }) {
        const { data } = payload;
        const inputData = {
          rateLimitType: revertParseRateLimitType(data.rateLimitType),
          settings: data.settings?.map((item) => {
            return {
              id: item.pk,
              administratorGroup: item.administratorGroup.pk,
              threshold: item.threshold,
              duration: item.duration,
            };
          }),
        };

        const serviceArgs = [createOrUpdate, inputData];
        yield put({
          type: 'responseCreateOrUpdate',
          payload: { serviceArgs, isCreate: false },
        });
      },
      { type: 'takeLatest' },
    ],
  },
});
