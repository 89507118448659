import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { formatDate, TimeFormater } from '../../../utils/TimeFormatUtil';
import './FortuneBagDetail.scss';

function FortuneBagDetail(props) {
  // const { fortuneBagCoupons, batchFortunes, campaign } = useSelector(
  //   (state) => ({

  //     campaign: state.createCampaign.campaign,
  //   }),
  // );
  // console.log('@@13: ', batchFortunes, fortuneBagCoupons, campaign);

  const campaign = props.campaign;
  const fortuneBagCoupons = campaign?.fortunes || {};
  const batchFortunes = campaign?.batchesFortuneBag || {};

  return (
    <>
      <label className="create-section-title">fortune bags</label>
      <table
        border="1"
        className="content-section-table-container fortunebag-detail-table"
      >
        <thead>
          <tr>
            <td>Batch | Last updated time</td>
            <td>Bag(s) create in this batch</td>
            <td>Link to coupon set</td>
            <td>Target Merchant (OTA)</td>
          </tr>
        </thead>
        <tbody>
          {campaign.assignRemain === false ? (
            <tr>
              <td>
                {Object.keys(batchFortunes || {})?.length + 1}
                <label className="batch-dselimiter">|</label>
                <label className="batch-updatetime">-</label>
              </td>
              <td>{campaign.batchBagQuantity}</td>
              <td>
                {[].concat
                  .apply(
                    [],
                    Object.values(fortuneBagCoupons).map((slot) =>
                      slot.map((coupon) => coupon.name),
                    ),
                  )
                  .join(', ')}
              </td>
              <td>
                {campaign.isPublished
                  ? campaign.batchOta?.map((ota) => ota.name).join(', ')
                  : campaign.ota.map((ota) => ota.name).join(', ')}
              </td>
            </tr>
          ) : null}
          {campaign.isPublished || props.detailMode
            ? Object.keys(batchFortunes)
                .sort(function (a, b) {
                  return b - a;
                })
                .map((batchKey, index) => {
                  const batch = batchFortunes[batchKey];
                  return (
                    <tr>
                      <td>
                        <label className="batch-no">
                          {campaign.assignRemain ||
                          campaign.assignRemain === undefined
                            ? parseInt(batchKey)
                            : parseInt(batchKey)}
                        </label>
                        <label className="batch-dselimiter">|</label>
                        <label className="batch-updatetime">
                          {formatDate(
                            batch.lastModifiedDate,
                            TimeFormater.monthDayYearTime,
                          )}
                        </label>
                      </td>
                      <td>{batch.fortuneBagQuantity}</td>
                      <td>
                        {batch.linkedCoupons
                          ?.map((item) => item.name)
                          .join(', ')}
                      </td>
                      <td>
                        {campaign.assignRemain &&
                        index === 0
                          ? batch.fortuneBagBrands?.edges
                              ?.map((item) => item.node.name)
                              .join(', ') +
                            ', ' +
                            campaign.batchOta?.map((ota) => ota.name).join(', ')
                          : batch.fortuneBagBrands?.edges
                              ?.map((item) => item.node.name)
                              .join(', ')}
                      </td>
                    </tr>
                  );
                })
            : null}
        </tbody>
      </table>
    </>
  );
}

export default FortuneBagDetail;
