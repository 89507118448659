import { createModel } from './BaseModel';
import { getAttributeTagList } from '../services/AttributeTagAPIHelper'; 

export default createModel({
  namespace: 'attributeTag',
  params: {
    initState: {},
    sessionKey: 'attributeTag',
    dataKey: 'attributeTags',
    listAPI: getAttributeTagList,
    parse: (data) => data?.attributeTags.edges.map((item) => ({
      ...item.node,
      title: `[ID:${item.node.pk}] ${item.node.name}`,
      tagKey: {
        ...item.node.tagKey,
        title: `[ID:${item.node.tagKey.pk}] ${item.node.tagKey.name}`,
      }
    })),
    objectKey: 'attributeTags'
  },
  states: {},
  reducers: {},
  effects: {},
});
