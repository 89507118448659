import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import CustomListComponent from '../../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { useDispatch, connect } from 'react-redux';
import ManageRecaptchaContent from '../../../components/manageRecaptcha/ManageRecaptchaContent';

const ManageRecaptcha = ({ recaptchaOpen }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: 'systemSetting/getOneDetail', payload: {} });
  }, []);
  const buttons = [];

  const tabs = [
    {
      name: '',
      content: (
        <>
          <ManageRecaptchaContent stateRecaptchaOpen={recaptchaOpen} />
        </>
      ),
    },
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          title: 'Manage reCAPTCHA',
        }}
        buttons={buttons}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={true}
        tabs={tabs}
      />
    </>
  );
};
const mapPropsToState = (state) => ({
  recaptchaOpen: state.systemSetting.detail.systemSettings?.recaptchaOpen,
});
export default connect(mapPropsToState)(ManageRecaptcha);
