import { BaseHelper } from './BaseGraphQLAPIHelper';

export const COUPON_DATA_TYPE = {
  totalNumberOfAvailableCouponCampaigns: `totalNumberOfAvailableCouponCampaigns {
    value
    change
  }`,
  totalNumberOfCouponAcquired: `totalNumberOfCouponAcquired {
    value
    change
  }`,
  totalNumberOfCouponUsed: `totalNumberOfCouponUsed {
    value
    change
  }`,
  usedCouponsConversionRate: `usedCouponsConversionRate {
    value
    change
  }`,
};

const oldDashboardData = `
totalNumberOfPublishedGeneralMessageCampaigns {
  value
  change
}
totalNumberOfNonpublishedGeneralMessageCampaigns {
  value
  change
}
totalNumberOfExpiredGeneralMessageCampaigns {
  value
  change
}
totalNumberOfPublishedCouponCampaigns {
  value
  change
}
totalNumberOfNonpublishedCouponCampaigns {
  value
  change
}
totalNumberOfExpiredCouponCampaigns {
  value
  change
}


top10CouponUsedByBrandChart {
  name
  count
}
top10CouponUsedByDistrictChart {
  name
  count
}
top10CouponUsedByStoreChart {
  name
  count
}`;

export const getDashboardData = (
  startDate,
  endDate,
  campaginTypes,
  isStarred,
) => {
  let filter = '';
  if (campaginTypes?.length > 0) {
    filter += `, campaignTypeIn: "${campaginTypes?.join(',')}"`;
  }

  if (isStarred) {
    filter += `, campaignIsStarred: ${isStarred}`;
  }

  const query = `{
    analyticsData(fromDate: "${startDate}", toDate: "${endDate}"${filter}) {
      totalNumberOfGeneratedGoodieBags {
        value
        change
      }
      totalNumberOfAcquiredGoodieBags {
        value
        change
      }
      totalNumberOfPublishedGoodieBags {
        value
        change
      }
      totalNumberOfNonpublishedGoodieBags {
        value
        change
      }
      totalNumberOfExpiredGoodieBags {
        value
        change
      }      
    }
  }`;
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getDashboardCouponData = (
  startDate,
  endDate,
  campaginTypes,
  isStarred,
) => {
  let filter = '';
  if (campaginTypes?.length > 0) {
    filter += `, campaignTypeIn: "${campaginTypes?.join(',')}"`;
  }

  if (isStarred) {
    filter += `, campaignIsStarred: ${isStarred}`;
  }

  const query = `{
    analyticsData(fromDate: "${startDate}", toDate: "${endDate}"${filter}) {
      ${COUPON_DATA_TYPE}
    }
  }`;
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getDashboardCampaignData = (
  startDate,
  endDate,
  campaginTypes,
  isStarred,
) => {
  let filter = '';
  if (campaginTypes?.length > 0) {
    filter += `, campaignTypeIn: "${campaginTypes?.join(',')}"`;
  }

  if (isStarred) {
    filter += `, campaignIsStarred: ${isStarred}`;
  }

  const query = `{
    analyticsData(fromDate: "${startDate}", toDate: "${endDate}"${filter}) {
      totalNumberOfPublishedCampaigns {
        value
        change
      }
      totalNumberOfNonpublishedCampaigns {
        value
        change
      }
      totalNumberOfExpiredCampaigns {
        value
        change
      }
          
    }
  }`;
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getDashboardTop10CouponRedeemData = (
  startDate,
  endDate,
  campaginTypes,
  isStarredCamapign,
) => {
  console.log(startDate, endDate, isStarredCamapign);
  let filter = '';
  if (campaginTypes?.length > 0) {
    filter += `, campaignTypeIn: "${campaginTypes?.join(',')}"`;
  }

  if (isStarredCamapign) {
    filter += `, campaignIsStarred: ${isStarredCamapign}`;
  }

  const query = `{
    analyticsData(fromDate: "${startDate}", toDate: "${endDate}" ${filter}) {
      totalNumberOfCouponUsed {
        value
        change
      }
      top10CouponUsedByBrandChart {
        name
        count
      }
      top10CouponUsedByDistrictChart {
        name
        count
      }
      top10CouponUsedByStoreChart {
        name
        count
      }
      top10CouponUsedByCampaignChart {
        name
        count
      }
    }
  }`;
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getDashboardTop10CouponAcquiredData = (
  startDate,
  endDate,
  campaginTypes,
  isStarredCamapign,
) => {
  let filter = '';
  if (campaginTypes?.length > 0) {
    filter += `, campaignTypeIn: "${campaginTypes?.join(',')}"`;
  }

  if (isStarredCamapign) {
    filter += `, campaignIsStarred: ${isStarredCamapign}`;
  }

  const query = `{
    analyticsData(fromDate: "${startDate}", toDate: "${endDate}"${filter}) {
      totalNumberOfCouponAcquired {
        value
        change
      }
      top10CouponAcquiredByCampaignChart {
        name
        count
      }
      top10CouponAcquiredByBrandChart {
        name
        count
      }
    }
  }`;
  const variables = {};
  console.log('@121', startDate, endDate);

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getDashboardTop5NationalityData = (startDate, endDate) => {
  const query = `{
    analyticsData(fromDate: "${startDate}", toDate: "${endDate}") {
      totalNumberOfCustomers {
        value
        change
      }
      top5NationalityOfCustomersChart {
        name
        count
      }
      totalNumberOfCustomersWithNationality {
        value
        change
      }
    }
  }`;
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getDashboardGenderData = (startDate, endDate) => {
  const query = `{
    analyticsData(fromDate: "${startDate}", toDate: "${endDate}") {
      totalNumberOfCustomers {
        value
        change
      }
      totalNumberOfMaleCustomers {
        value
        change
      }
      totalNumberOfFemaleCustomers {
          value
          change
      }
    }
  }`;
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCampaignsOverviewSingleData = (
  startDate,
  endDate,
  field,
  overFilter,
) => {
  console.log('@165', overFilter);
  let filter = '';
  if (overFilter?.channel) {
    filter += `, channel: ${overFilter.channel}`;
  }
  if (overFilter?.targetMarket) {
    filter += `, targetMarket: ${overFilter.targetMarket}`;
  }
  if (overFilter?.brand) {
    filter += `, brand: ${overFilter.brand}`;
  }
  if (overFilter?.campaign) {
    filter += `, campaign: ${overFilter.campaign}`;
  }
  if (overFilter?.cashcampaigns) {
    filter += `, cashVoucherGoodieBagCampaign: ${overFilter.cashcampaigns}`;
  }
  if (overFilter?.giftcampaigns) {
    filter += `, giftGoodieBagCampaign: ${overFilter.giftcampaigns}`;
  }
  if (overFilter?.goodiebags) {
    filter += `, goodieBags: "${overFilter.goodiebags}"`;
  }
  if (overFilter?.isStarred || overFilter?.isStarred === false) {
    filter += `, campaignIsStarred: ${overFilter.isStarred}`;
  }
  if (overFilter?.fromModel) {
    filter += `, fromModel:"${overFilter.fromModel}"`;
  }
  if (overFilter?.campaginTypes?.length > 0) {
    filter += `, campaignTypeIn: "${overFilter.campaginTypes?.join(',')}"`;
  }

  const query = `{
    analyticsData(fromDate: "${startDate}", toDate: "${endDate}"${filter}) {
      ${field} {
        value
        change
      }
    }
  }`;
  const variables = {};
  console.log('@212', query);
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCampaignsOverviewRakingTable = (
  startDate,
  endDate,
  overviewFilter,
  others,
) => {
  if (!startDate || !endDate) {
    return {};
  }
  console.log('@217', others, overviewFilter);
  let filter = '';
  if (others?.searchName) {
    filter += `, searchName: "${others.searchName}"`;
  }
  if (others?.orderBy) {
    filter += `, orderBy: "${others.orderBy}"`;
  }
  if (others?.pageSize) {
    filter += `, pageSize: ${others.pageSize}`;
  }
  if (others?.pageNumber) {
    filter += `, pageNumber: ${others.pageNumber}`;
  }
  if (overviewFilter?.brand) {
    filter += `, brand: ${overviewFilter?.brand}`;
  }

  if (overviewFilter?.isStarred || overviewFilter?.isStarred === false) {
    filter += `, campaignIsStarred: ${overviewFilter?.isStarred}`;
  }
  if (overviewFilter?.goodiebags) {
    filter += `, goodieBags: "${overviewFilter?.goodiebags}"`;
  }
  if (overviewFilter?.cashcampaign) {
    filter += `, cashVoucherGoodieBagCampaign: ${overviewFilter?.cashcampaign}`;
  }
  if (overviewFilter?.giftcampaign) {
    filter += `, giftGoodieBagCampaign: ${overviewFilter?.giftcampaign}`;
  }
  if (others?.fromModel) {
    filter += `, fromModel:"${others.fromModel}"`;
  }
  if (others?.campaginTypes?.length > 0) {
    filter += `, campaignTypeIn: "${others.campaginTypes?.join(',')}"`;
  }

  const query = `{
    analyticsData(fromDate: "${startDate}", toDate: "${endDate}"${filter}) {
      couponAcquiredAndUsedByCampaignTableWithPagination {
        totalCount
        tableData
      }
    }
  }`;
  const variables = {};
  console.log('@246', query);
  return BaseHelper.callGraphQLAPI({ query, variables });
};

// export const getCustomersOverviewData = (startDate, endDate) => {
//   const query = `{
//     analyticsData(fromDate: "${startDate}", toDate: "${endDate}") {
//       totalNumberOfCustomers {
//         value
//         change
//       }
//       totalNumberOfNewCustomers {
//         value
//         change
//       }
//       totalNumberOfNewCustomersChart {
//         periods
//         points
//       }
//       totalNumberOfActiveCustomers {
//         value
//         change
//       }
//     }
//   }`;
//   const variables = {};

//   return BaseHelper.callGraphQLAPI({ query, variables });
// };

export const getCouponsOverviewSingleData = (
  startDate,
  endDate,
  field,
  others,
) => {
  let filter = '';
  if (others?.couponOverviewFilter?.brand) {
    filter += `, brand: ${others.couponOverviewFilter.brand}`;
  }
  if (others?.couponOverviewFilter?.isStarred) {
    filter += `, campaignIsStarred: ${others.couponOverviewFilter.isStarred}`;
  }
  if (others?.couponOverviewFilter?.couponSet) {
    filter += `, couponTemplate: ${others.couponOverviewFilter.couponSet}`;
  }
  if (others?.fromModel) {
    filter += `, fromModel:"${others.fromModel}"`;
  }
  const query = `{
    analyticsData(fromDate: "${startDate}", toDate: "${endDate}"${filter}) {
      ${field} {
        value
        change
      }
    }
  }`;
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCouponsOverviewRakingTable = (startDate, endDate, others) => {
  console.log('@247', others);
  let filter = '';
  if (others?.searchName) {
    filter += `, searchName: "${others.searchName}"`;
  }
  if (others?.orderBy) {
    filter += `, orderBy: "${others.orderBy}"`;
  }
  if (others?.pageSize) {
    filter += `, pageSize: ${others.pageSize}`;
  }
  if (others?.pageNumber) {
    filter += `, pageNumber: ${others.pageNumber}`;
  }
  if (others?.isStarred) {
    filter += `, campaignIsStarred: ${others?.isStarred}`;
  }
  if (others?.fromModel) {
    filter += `, fromModel:"${others.fromModel}"`;
  }
  const query = `{
    analyticsData(fromDate: "${startDate}", toDate: "${endDate}"${filter}) {
      couponAcquiredAndUsedByBrandTableWithPagination {
        totalCount
        tableData
      }
    }
  }`;
  const variables = {};
  console.log('@276', query);
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCouponsTop10RedemptionByDistrict = (
  startDate,
  endDate,
  others,
) => {
  console.log('@275', others);
  let filter = '';
  if (others?.orderBy) {
    filter += `, orderBy: "${others.orderBy}"`;
  }
  if (others?.brand) {
    filter += `, brand: ${others.brand}`;
  }
  if (others?.isStarred) {
    filter += `, campaignIsStarred: ${others.isStarred}`;
  }
  if (others?.fromModel) {
    filter += `, fromModel:"${others.fromModel}"`;
  }
  const query = `{
    analyticsData(fromDate: "${startDate}", toDate: "${endDate}"${filter}) {
      top10CouponUsedByDistrictTable 
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCouponsTop10RedemptionByStore = (
  startDate,
  endDate,
  others,
) => {
  console.log('@275', others);
  let filter = '';
  if (others?.orderBy) {
    filter += `, orderBy: "${others.orderBy}"`;
  }
  if (others?.brand) {
    filter += `, brand: ${others.brand}`;
  }
  if (others?.isStarred) {
    filter += `, campaignIsStarred: ${others.isStarred}`;
  }
  if (others?.fromModel) {
    filter += `, fromModel:"${others.fromModel}"`;
  }
  const query = `{
    analyticsData(fromDate: "${startDate}", toDate: "${endDate}"${filter}) {
      top10CouponUsedByStoreTable 
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCouonDashboardData = (
  startDate,
  endDate,
  request,
  campaginTypes,
  isStarredCamapign,
) => {
  console.log(startDate, endDate);
  let filter = '';
  if (campaginTypes?.length > 0) {
    filter += `, campaignTypeIn: "${campaginTypes?.join(',')}"`;
  }

  if (isStarredCamapign) {
    filter += `, campaignIsStarred: ${isStarredCamapign}`;
  }

  const query = `{
    analyticsData(fromDate: "${startDate}", toDate: "${endDate}"${filter}) {
      ${request}
    }
  }`;
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getGoodieBagOverviewSingleData = (startDate, endDate, others) => {
  let filter = '';
  if (others?.goodiebagOverviewFilter?.goodiebag) {
    filter += `, goodieBags: "${others.goodiebagOverviewFilter.goodiebags}"`;
  }
  if (others?.goodiebagOverviewFilter?.isStarred) {
    filter += `, campaign_is_starred: ${others?.goodiebagOverviewFilter?.isStarred}`;
  }
  if (others?.goodiebagOverviewFilter?.cashCampaign) {
    filter += `, cashVoucherGoodieBagCampaign: ${others?.goodiebagOverviewFilter?.cashCampaign}`;
  }
  if (others?.goodiebagOverviewFilter?.giftCampaign) {
    filter += `, giftGoodieBagCampaign: ${others?.goodiebagOverviewFilter?.giftCampaign}`;
  }
  if (others?.fromModel) {
    filter += `, fromModel:"${others.fromModel}"`;
  }
  const query = `{
    analyticsData(fromDate: "${startDate}", toDate: "${endDate}"${filter}) {
      totalNumberOfPublishedGoodieBags {
        value
        change
      }
      totalNumberOfNonpublishedGoodieBags {
        value
        change
      }
      totalNumberOfExpiredGoodieBags {
        value
        change
      }
    }
  }`;
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getGoodieBagOverviewRankingTable = (
  startDate,
  endDate,
  goodiebags,
  isStarred,
  cashCampaign,
  giftCampaign,
  others,
) => {
  if (!startDate || !endDate) {
    return {};
  }
  console.log('@217', others);
  let filter = '';
  if (others?.searchName) {
    filter += `, searchName: "${others.searchName}"`;
  }
  if (others?.orderBy) {
    filter += `, orderBy: "${others.orderBy}"`;
  }
  if (others?.pageSize) {
    filter += `, pageSize: ${others.pageSize}`;
  }
  if (others?.pageNumber) {
    filter += `, pageNumber: ${others.pageNumber}`;
  }

  if (isStarred) {
    filter += `, campaignIsStarred: ${isStarred}`;
  }
  if (goodiebags) {
    filter += `, goodieBags: "${goodiebags}"`;
  }
  if (cashCampaign) {
    filter += `, cashVoucherGoodieBagCampaign: ${cashCampaign}`;
  }
  if (giftCampaign) {
    filter += `, giftGoodieBagCampaign: ${giftCampaign}`;
  }

  filter += `, fromModel:"GOODIE_BAG"`;
  const query = `{
    analyticsData(fromDate: "${startDate}", toDate: "${endDate}"${filter}) {
      goodieBagAcquiredTableWithPagination {
        totalCount
        tableData
      }
    }
  }`;
  const variables = {};
  console.log('@246', query);
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getGoodieBagDashboardData = (startDate, endDate) => {
  let filter = '';
  // if (others?.goodiebagOverviewFilter?.goodiebag) {
  //   filter += `, goodiebags: "${others.goodiebagOverviewFilter.goodiebags}"`;
  // }
  // if (others?.goodiebagOverviewFilter?.isStarred) {
  //   filter += `, campaign_is_starred: ${others?.goodiebagOverviewFilter?.isStarred}`;
  // }
  // if (others?.goodiebagOverviewFilter?.cashCampaign) {
  //   filter += `, cashVoucherGoodieBagCampaign: ${others?.goodiebagOverviewFilter?.cashCampaign}`;
  // }
  // if (others?.goodiebagOverviewFilter?.giftCampaign) {
  //   filter += `, giftGoodieBagCampaign: ${others?.goodiebagOverviewFilter?.giftCampaign}`;
  // }
  // filter += `, fromModel:"GOODIE_BAG"`;
  const query = `{
    analyticsData(fromDate: "${startDate}", toDate: "${endDate}"${filter}) {
      totalNumberOfPublishedGoodieBags {
        value
        change
      }
      totalNumberOfNonpublishedGoodieBags {
        value
        change
      }
      totalNumberOfExpiredGoodieBags {
        value
        change
      }
      totalNumberOfGeneratedGoodieBags {
        value
        change
      }
      totalNumberOfAcquiredGoodieBags {
        value
        change
      }
    }
  }`;
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};
