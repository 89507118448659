import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, connect } from 'react-redux';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import BaseForm from '../../../components/base/v2/BaseForm';
import { createCouponLabelBreads } from '../../../config/CustomBreadConfig';
import { createAction } from '../../../utils';
import CreateCouponLabelStepOne from '../../../components/couponLabel/CreateCouponLabelStepOne';
import {
  removeCouponLabelSessionStroage,
  saveCouponLabelToSessionStorage,
} from '../../../models/CouponLabelModel';
import { APIStatus } from '../../../config/CustomEnums';

const CreateCouponLabel = ({
  defaultValues,
  dispatch,
  hasUpdatedDefaultValues,
  formHasSubmitted,
  apiStatus,
  newCouponLabelPk,
}) => {
  const params = useParams();
  const history = useHistory();
  const { id } = params;
  const queryString = require('query-string');
  useEffect(() => {
    if (id) {
      dispatch(createAction('couponLabel/getOneDetail')({ id }));
    } else {
      dispatch(createAction('couponLabel/loadCouponLabelFromCookie')());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(createAction('couponLabel/clearData')());
    };
  }, []);

  useEffect(() => {
    if (
      formHasSubmitted &&
      apiStatus === APIStatus.success &&
      !history.location.state?.from
    ) {
      history.push({
        pathname: '/coupon_label',
        search: '?sort=displayPriority',
      });
    }
  }, [formHasSubmitted, apiStatus, history, newCouponLabelPk]);

  return (
    <>
      <BaseForm
        defaultValues={defaultValues}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={formHasSubmitted}
        tempSave={(save, getValues) => {
          if (save) {
            saveCouponLabelToSessionStorage(getValues());
          } else {
            removeCouponLabelSessionStroage();
          }
        }}
        continueCreateConfig={{
          onConfirm: () => {
            history.push({
              ...history.location.state?.from,
              hash: queryString.stringify({
                ...queryString.parse(history.location.state?.from?.hash),
                ...{ newCouponLabel: newCouponLabelPk },
              }),
            });
          },
          onCancel: () =>
            history.push({
              pathname: '/coupon_label',
              search: '?sort=displayPriority',
            }),
          onClose: () =>
            history.push({
              pathname: '/coupon_label',
              search: '?sort=displayPriority',
            }),
        }}
        showFinishPop={
          formHasSubmitted &&
          apiStatus === APIStatus.success &&
          history.location.state?.from
        }
        content={[<CreateCouponLabelStepOne />]}
        currentStep={0}
        breadcrumb={
          <CustomBreadcrumb
            breadcrumbs={createCouponLabelBreads(id, defaultValues?.name)}
          />
        }
        caution={{
          detail: '',
          title: id ? defaultValues?.name : 'Create coupon label',
        }}
      />
    </>
  );
};
const mapPropsToState = (state) => ({
  defaultValues: state.couponLabel.detail,
  hasUpdatedDefaultValues: state.couponLabel.hasUpdatedDefaultValues,
  formHasSubmitted: state.couponLabel.formHasSubmitted,
  apiStatus: state.couponLabel.apiStatus,
  newCouponLabelPk: state.couponLabel?.newCouponLabelPk,
});
export default connect(mapPropsToState)(CreateCouponLabel);
