import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ERROR_MESSAGE } from '../../../utils';
import CustomTitleWithSwitch from '../../base/CustomTitleWithSwitch';

import BasePrompt from '../../base/prompt/BasePrompt';

import { CustomNumberInputWithUnit } from '../../earning/CustomBaseComponments';
import { useFormContext } from 'react-hook-form';
import { PermissionCodes } from '../../../config/PermissionCodes';

function WechatFilterPrompt({ show = false, onClose = () => {} }) {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => ({
    user: state.users,
  }));

  const hasChangeSettingPermission =
    user?.userPermissions?.filter(
      (val) => val === PermissionCodes.changeCampaignSetting,
    ).length > 0;

  const { showCampaignCategory, showBrand, showOfferTypeLabel, showLocation } =
    useSelector((state) => ({
      showCampaignCategory:
        state.campaignList.settings?.showCampaignCategoryFilter,
      showBrand: state.campaignList.settings?.showBrandFilter,
      showOfferTypeLabel: state.campaignList.settings?.showOfferTypeLabelFilter,
      showLocation: state.campaignList.settings?.showLocationFilter,
    }));

  console.log('@@22', showCampaignCategory);

  const [showCampaignCategoryFilter, setShowCampaignCategoryFilter] =
    useState(showCampaignCategory);
  const [showBrandFilter, setShowBrandFilter] = useState(showBrand);
  const [showOfferTypeLabelFilter, setShowOfferTypeLabelFilter] =
    useState(showOfferTypeLabel);
  const [showLocationFilter, setShowLocationFilter] = useState(showLocation);

  const recoverData = () => {
    setShowCampaignCategoryFilter(showCampaignCategory);
    setShowBrandFilter(showBrand);
    setShowOfferTypeLabelFilter(showOfferTypeLabel);
    setShowLocationFilter(showLocation);
  };

  useEffect(() => {
    if (show) {
      dispatch({ type: 'campaignList/getCampaignSettings' });
    }
  }, [show]);

  useEffect(() => {
    recoverData();
  }, [showCampaignCategory, showBrand, showOfferTypeLabel, showLocation]);

  return (
    <BasePrompt
      show={show}
      closeAction={() => {
        recoverData();
        onClose();
      }}
      rightButton={{
        text: 'Confirm',
        action: () => {
          dispatch({
            type: 'campaignList/updateCampaignSettings',
            payload: {
              showCampaignCategoryFilter,
              showBrandFilter,
              showOfferTypeLabelFilter,
              showLocationFilter,
            },
          });
          onClose();
        },
        class: hasChangeSettingPermission ? '' : 'd-none',
      }}
      leftButton={{
        text: `Cancel`,
        action: () => {
          recoverData();
          onClose();
        },
      }}
      title={'Manage filter in e-solution campaign list (WeChat)'}
      description={
        'Manage show or hide the following items in the front-end activity list default filter.'
      }
      otherBody={() => {
        return (
          <>
            <CustomTitleWithSwitch
              title="Category"
              defaultValue={showCampaignCategoryFilter}
              disabled={!hasChangeSettingPermission}
              tips="If on, the frontend campaign list filter will display the ‘Category’ filter."
              setValue={(value) => {
                setShowCampaignCategoryFilter(value);
              }}
            />
            <CustomTitleWithSwitch
              title="Brand"
              defaultValue={showBrandFilter}
              disabled={!hasChangeSettingPermission}
              tips="If on, the frontend campaign list filter will display the ‘Brand’ filter."
              setValue={(value) => {
                setShowBrandFilter(value);
              }}
            />
            <CustomTitleWithSwitch
              title="Offer type label"
              defaultValue={showOfferTypeLabelFilter}
              disabled={!hasChangeSettingPermission}
              tips="If on, the frontend campaign list filter will display the ‘Offer type label’ filter. (Also depends on the linked campaign has this label added or not.)"
              setValue={(value) => {
                setShowOfferTypeLabelFilter(value);
              }}
            />
            <div className="campaign-devide-line" />
            <CustomTitleWithSwitch
              title="Location"
              defaultValue={showLocationFilter}
              disabled={!hasChangeSettingPermission}
              tips="If on, the frontend campaign list will display ‘Location’ filter. (Display as a separate filter button in frontend.)"
              setValue={(value) => {
                setShowLocationFilter(value);
              }}
            />
          </>
        );
      }}
    />
  );
}

export default WechatFilterPrompt;
