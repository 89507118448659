import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { NavBarNames } from '../../../config/NavBarNameList';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import { createAction } from '../../../utils';

import DeletePrompt from '../../../components/base/DeletePrompt';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';

function WorkingTeamList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    listFields,
    dataList,
    pageInfo,
    totalPage,
    totalCount,
    checkedList,
    userAdminType,
  } = useSelector((state) => ({
    listFields: state.workingTeam.listDisplayFields,
    dataList: state.workingTeam.pagedList,
    pageInfo: state.workingTeam.pageInfo,
    totalPage: state.workingTeam.totalPage,
    totalCount: state.workingTeam.totalCount,
    checkedList: state.workingTeam.checkedList,
  }));

  const itemAction = ['Detail', 'Edit', 'Delete', 'Duplicate'];
  const buttons = [
    <AuthButton
      title="Create Working team"
      action={() => history.push('/working_teams/create')}
      requires={PermissionCodes.changeWorkingTeam}
    />,
  ];
  const customActions = {};
  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.changeWorkingTeam,
    },
  ];

  const tabs = [
    {
      name: '',
      content: (
        <BaseTabListContainer
          hideTab={true}
          tabs={{}}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount}
          //   filter={{
          //     hasFilter: false,
          //     componment: Filter,
          //   }}
          listContent={
            <BaseListContainer
              fields={listFields}
              dataList={dataList}
              totalPage={totalPage}
              model={'workingTeam'}
              permissionGroup={PermissionCodes.workingTeam}
              deleteInfo={{
                data: [],
                title: 'working team',
                relatedName: '',
                onComfirm: {},
                relatedSections: [],
              }}
              hasOtherActions={true}
              actions={itemAction}
              customActions={customActions}
            />
          }
        />
      ),
    },
  ];

  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [activeTab, setActiveTab] = useState(tabs[0].name);

  useEffect(() => {
    dispatch({ type: 'workingTeam/clearData' });

    const queryString = require('query-string');
    const querySearch = queryString.parse(location.search);
    const searchKey = querySearch['search'];
    const stringRank = querySearch['rank'] || 'true';
    const stringPage = querySearch['page'] || 1;
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);

    dispatch(
      createAction('workingTeam/getList')({
        ...querySearch,
        page,
        reverse: rank,
        search: searchKey,
        withAdmin: true,
      }),
    );
  }, [dispatch, location]);

  return (
    <>
      <CustomListComponent
        caution={{
          detail: '',
          title: NavBarNames.workingTeam,
        }}
        defaultActiveKey={activeTab}
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        hideTab={true}
        onTabChange={(key) => {
          setActiveTab(key);
          //   let search = '';
          //   search = queryString.stringify({
          //     [URLFilterKeys.page]: 1,
          //     [URLFilterKeys.searchKey]: '',
          //     [URLFilterKeys.rank]: true,
          //   });
          //   history.push({ pathname: '/working', hash: key, search: search });
        }}
      />
      <DeletePrompt
        data={checkedList}
        title={'Working team'}
        relatedName={''}
        relatedSections={[]}
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch(
            createAction('workingTeam/delete')({
              afterAction: () => {
                history.push(location);
              },
            }),
          );
        }}
      />
    </>
  );
}

export default WorkingTeamList;
