import React, { useEffect, useState } from 'react';
import '../../components/base/prompt/FilterExportPrompt.scss';
import { Row } from 'react-bootstrap';
import queryString from 'query-string';
import { PermissionCodes } from '../../config/PermissionCodes';
import { useDispatch, useSelector } from 'react-redux';
import { ImportResource } from '../../models/DownloadImportModel';
import { createAction } from '../../utils';
import { formatDate, TimeFormater } from '../../utils/TimeFormatUtil';
import { getStartEndDate } from '../../components/dashboard/DateRangeSelection';
import moment from 'moment';
import BaseMutipleSelectorV2 from '../base/BaseMultipleSelectorV2';
import CustomRadios from '../base/CustomRadios';
import CustomTitleWithDateRange from '../base/CustomTitleWithDateRange';
import CustomSwitchButton from '../base/CustomSwitchButton';
import BasePrompt from '../base/prompt/BasePrompt';

function GoodieBagRecordExportPrompt({
  title,
  show,
  onClose = () => {},
  exportFilterConditions,
  otherBody = {},
  total,
  description,
}) {
  const dispatch = useDispatch();

  const [enableSeparate, setEnableSeparate] = useState(false);
  const [includeFilter, setIncludeFilter] = useState({ isInclude: false });
  const [selectExtraIds, setSelectExtraIds] = useState([]);

  const startEndDate = getStartEndDate();
  const [selectStartDate, setSelectStartDate] = useState(
    startEndDate.startDate,
  );
  const [selectEndDate, setSelectEndDate] = useState(startEndDate.endDate);

  const [filterStartDate, setFilterStartDate] = useState('');
  const [filterEndDate, setFilterEndDate] = useState('');

  const activeTimeRange = exportFilterConditions?.active_time_range;
  const exportFor = ImportResource.goodieBagParticipationRecord;

  const periodToDate = (period) => {
    if (!period) {
      return '';
    }
    const periods = period.split(',');
    const startDate = moment(periods[0])
      .set('hour', 0)
      .set('minute', 0)
      .set('second', 0);
    const endDate = moment(periods[1])
      .set('hour', 23)
      .set('minute', 59)
      .set('second', 59);
    return { startDate, endDate };
  };

  useEffect(() => {
    const periodDate = periodToDate(activeTimeRange);
    if (periodDate) {
      setFilterStartDate(periodDate.startDate);
      setFilterEndDate(periodDate.endDate);
    } else {
      setFilterStartDate(startEndDate.startDate);
      setFilterEndDate(startEndDate.endDate);
    }
  }, [activeTimeRange]);

  const { allGoodieBagList } = useSelector((state) => ({
    allGoodieBagList: state.goodieBags.notPagedAllList,
  }));

  const totalTasks = parseInt(
    total % 100000 > 0 ? total / 100000 + 1 : total / 100000,
  );

  const canExport =
    (!includeFilter.isInclude &&
      selectExtraIds?.length > 0 &&
      selectStartDate &&
      selectEndDate) ||
    (includeFilter.isInclude && filterStartDate && filterEndDate);

  useEffect(() => {
    if (show && canExport) {
      const goodieBagIn = selectExtraIds?.map((item) => item?.pk);
      const { name_icontains, ...filterConditions } = exportFilterConditions;
      console.log('@@93 goodieBagIn', goodieBagIn);
      dispatch({
        type: 'goodieBags/getGoodieBagParticipationRecordsCount',
        payload: {
          filter: {
            ...(includeFilter.isInclude
              ? {
                  filterConditions: JSON.stringify(filterConditions).replaceAll(
                    '"',
                    "'",
                  ),
                  filterName: exportFilterConditions?.name_icontains,
                }
              : {
                  goodieBagIn,
                }),
            activeTimeRange:
              (includeFilter.isInclude
                ? filterStartDate
                : selectStartDate
              ).format(TimeFormater.GTMFormat) +
              ',' +
              (includeFilter.isInclude ? filterEndDate : selectEndDate).format(
                TimeFormater.GTMFormat,
              ),
          },
        },
      });
    }
  }, [
    selectExtraIds,
    selectStartDate,
    selectEndDate,
    filterStartDate,
    filterEndDate,
    includeFilter,
  ]);

  useEffect(() => {
    if (show && !allGoodieBagList.length) {
      dispatch(
        createAction('goodieBags/getAllList')({
          sort: '-pk',
          pageSize: 100,
          getAll: 'false',
          isSimplify: true,
        }),
      );
    }

    return () => {
      setSelectExtraIds([]);
    };
  }, [show]);

  const getOtherBody = () => {
    return (
      <>
        <label className={`create-section-label landing-title-margin-bottom `}>
          {otherBody.rangeTitle || 'Select the export range'}
        </label>
        <Row
          style={{ marginLeft: '0px', marginRight: '0px', marginTop: '20px' }}
        >
          <CustomRadios
            onChange={(value) => {
              setIncludeFilter({ isInclude: value });
            }}
            default={includeFilter.isInclude}
            options={[
              {
                label: 'Select items from the list',
                value: false,
              },
              {
                label: 'All filtered items from the list',
                value: true,
              },
            ]}
          />
        </Row>

        {!includeFilter.isInclude ? (
          <BaseMutipleSelectorV2
            title={'Goodie bag'}
            size={['shorter']}
            searchPlaceholder={'Search by name or ID'}
            data={{
              sourceData: allGoodieBagList,
              targetData: selectExtraIds,
              targetChange: setSelectExtraIds,
            }}
            custom={{
              customItem: (value) => (
                <>
                  <label className="slot-title">[{value.pk}]</label>
                  {value.name}
                </>
              ),
              customFilter: (inputValue, option) => {
                const inputIgnoreCase = inputValue?.toLowerCase();
                const optionIgnoreCase = option?.name?.toLowerCase();
                return (
                  optionIgnoreCase?.includes(inputIgnoreCase) ||
                  option.pk == inputValue
                );
              },
            }}
            showSelectAll={title === 'Export report' ? false : true}
          />
        ) : null}

        <CustomTitleWithDateRange
          title={'Time range of the records'}
          // tips={
          //   'The time range is referring the active period of the goodie bag.'
          // }
          value={
            includeFilter.isInclude
              ? [filterStartDate, filterEndDate]
              : [selectStartDate, selectEndDate]
          }
          onChange={(values) => {
            if (!values) {
              return;
            }
            if (includeFilter.isInclude) {
              setFilterStartDate(values[0]);
              setFilterEndDate(values[1]);
            } else {
              setSelectStartDate(values[0]);
              setSelectEndDate(values[1]);
            }
          }}
        />
        <div className="d-flex flex-column">
          <label
            className={`create-section-label landing-title-margin-bottom `}
          >
            Number of items to be exported
          </label>
          <label className="tips-message margin-top-8-percent ">
            Maximum export records per system task is 100,000. If over 100,000,
            will split into separate system tasks.
          </label>
          <label className="goodiebag-record-total-number">
            {total >= 0 && canExport ? total : '-'}
            {totalTasks > 1
              ? `(Total ${totalTasks} system
            tasks)`
              : ''}
          </label>
        </div>

        {otherBody.reportSperate ? (
          <div>
            <label className="create-section-label create-section-label-top-space">
              Export in separate files by{' '}
              {exportFor.replaceAll('_', ' ').toLowerCase()}(s)
            </label>
            <div
              className="second-section_description"
              style={{ marginBottom: 12 }}
            >
              If On, multiple .xlsx files will be downloaded based on different{' '}
              {exportFor.replaceAll('_', ' ').toLowerCase()}. If off, all the
              data merge into one .xlsx file.
            </div>

            <CustomSwitchButton
              defaultChecked={enableSeparate}
              onChange={setEnableSeparate}
            />
          </div>
        ) : null}
      </>
    );
  };

  const exportCSVAction = (index, reportName) => {
    console.log('@@176: ', selectExtraIds);
    const related = !includeFilter.isInclude
      ? {
          filterConditions: JSON.stringify({
            id_in: selectExtraIds?.map((item) => item.pk).join(','),
            from_date: selectStartDate.format(TimeFormater.GTMFormat),
            to_date: selectEndDate.format(TimeFormater.GTMFormat),
          }),
        }
      : {
          filterConditions: JSON.stringify({
            ...exportFilterConditions,
            from_date: filterStartDate.format(TimeFormater.GTMFormat),
            to_date: filterEndDate.format(TimeFormater.GTMFormat),
          }),
        };
    if (index >= 0 && reportName) {
      related.index = index + 1;
      related.startCursor = index * 100000;
      related.reportName = reportName.replace('_-', '');
    }
    dispatch({
      type: 'downloadAndImport/createDownloadTask',
      payload: {
        from: exportFor,
        related: { ...related, separateFiles: enableSeparate },
      },
    });
    setIncludeFilter({ isInclude: false });
  };

  return (
    <BasePrompt
      show={show}
      closeAction={onClose}
      description={description}
      basePromptDescriptionClassName="upload-description"
      rightButton={{
        text: 'Export',
        action: () => {
          const reportName = `Goodie_bag_record_${
            (includeFilter.isInclude ? filterStartDate : selectStartDate)
              .format(TimeFormater.yearMonthDay)
              ?.replaceAll('-', '') || ''
          }-${
            (includeFilter.isInclude ? filterEndDate : selectEndDate)
              .format(TimeFormater.yearMonthDay)
              .replaceAll('-', '') || ''
          }`;
          [...Array(totalTasks).keys()].forEach((index) => {
            exportCSVAction(
              index,
              `${reportName}${totalTasks > 1 ? `_${index + 1}` : ''}.${
                enableSeparate ? 'zip' : 'xlsx'
              }`,
            );
          });

          onClose();
        },
        requires: PermissionCodes.addExportjob,
        disabled:
          (selectExtraIds?.length <= 0 && !includeFilter.isInclude) ||
          total <= 0 ||
          !total,
      }}
      title={title}
      otherBody={getOtherBody}
    />
  );
}

export default GoodieBagRecordExportPrompt;
