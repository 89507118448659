import React, { useEffect, useState } from 'react';
import i18n from '../../I18n';
import CustomMarkDownArea from '../base/CustomMarkDownArea';
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from '../../utils';
import { isShowError } from '../campaign/campaignCreation/CreateCampaignHandleError';
import { createCustomerGroupError } from '../../models/CustomerGroupModel';
import { LanguageConfig } from '../../config/CustomEnums';

function CreateCustomerGroupGeneral() {
  const dispatch = useDispatch();
  const {
    errorFields,
    customerGroupName,
    customerGroupDescription,
  } = useSelector((state) => ({
    errorFields: state.customerGroup.errorFields,
    customerGroupName: state.customerGroup.selectedCustomerGroup.name,
    customerGroupDescription:
      state.customerGroup.selectedCustomerGroup.description,
  }));

  const isError = () => {
    return isShowError(
      createCustomerGroupError.generalName.name,
      errorFields,
      LanguageConfig.english,
    );
  };

  const [title, setTitle] = useState();
  useEffect(() => {
    if (customerGroupName && customerGroupName !== title) {
      setTitle(customerGroupName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerGroupName]);

  const formChanged = () => {
    dispatch({
      type: 'customerGroup/updateState',
      payload: { formChanged: true },
    });
  };

  return (
    <>
      <CustomMarkDownArea
        areaTitle={i18n.t('general')}
        title={{
          label: i18n.t('name'),
          value: title,
          valueChange: (value) => {
            dispatch(
              createAction('customerGroup/updateSelectedCustomerGroupState')({
                name: value,
              }),
            );
            formChanged();
          },
          error: isError(),
          errorId: createCustomerGroupError.generalName.name,
          errorMessage: createCustomerGroupError.generalName.message,
        }}
        content={{
          label: 'Description(Optional)',
          value: customerGroupDescription,
          valueChange: (value) => {
            formChanged();
            dispatch(
              createAction('customerGroup/updateSelectedCustomerGroupState')({
                description: value,
              }),
            );
          },
        }}
      />
    </>
  );
}

export default CreateCustomerGroupGeneral;
