import React, { isValidElement, useEffect, useRef, useState } from 'react';
import { CustomTitleWithDropDown } from '../../../components/customer/CustomTitleWithDropDown';
import { useDispatch, useSelector } from 'react-redux';
import i18n from '../../../I18n';
import { useFormContext, Controller } from 'react-hook-form';
import CustomTitleWithInput from '../../../components/base/CustomTitleWithInput';
import { ReactHookFormErrorMessage } from '../../../components/base/ErrorFieldMessage';
import { LanguageConfig } from '../../../config/CustomEnums';
import { StoreErrorHandleFields } from './StoreErrorHandleFields';

import { createAction } from '../../../utils';

import CustomMap from '../../../components/base/CustomGoogleMap';

export default function DetailSection({ language, focusChange }) {
  const dispatch = useDispatch();

  const { districtList } = useSelector((state) => ({
    districtList: state.storeModel.districtList,
  }));
  const { watch, setValue, control, formState } = useFormContext();
  const { errors } = formState;
  // const watchDistrict = watch('district');
  // const watchAddress = watch(`storeTranslations.${language}.address`);
  const watchLongitude = watch('longitude');
  const watchLatitude = watch('latitude');
  // const watchMapAddress = watch('mapAddress');

  const [addressValue, setAddressValue] = useState();

  useEffect(() => {
    if (addressValue) {
      dispatch(
        createAction('storeModel/getAddressMarker')({
          address: addressValue,
          onSuccess: (result) => {
            if (result.status === 'OK' && result.results.length > 0) {
              const location = result.results[0].geometry.location;
              setValue('latitude', location.lat.toFixed(6), {
                shouldDirty: true,
              });
              setValue('longitude', location.lng.toFixed(6), {
                shouldDirty: true,
              });
            }
          },
        }),
      );
    }
  }, [addressValue]);

  const districtArea = (
    <Controller
      control={control}
      name={'district'}
      rules={
        language === LanguageConfig.english
          ? { required: StoreErrorHandleFields.district.required }
          : {}
      }
      render={({ field: { value } }) => (
        <>
          <CustomTitleWithDropDown
            title={'District'}
            source={districtList}
            defaultValue={value}
            setValue={(value) => {
              setValue('district', value, { shouldDirty: true });
            }}
            onFocus={() => focusChange('district')}
            loadMoreAction={'storeModel/getDistrictList'}
            filterAction={'storeModel/getDistrictList'}
          />
          <ReactHookFormErrorMessage errors={errors} id="district" />
        </>
      )}
    />
  );

  const detailField = ({
    name,
    defaultValue,
    required = {},
    controlName,
    type,
    disabled,
    blurAction = () => {},
  }) => {
    return (
      <Controller
        control={control}
        name={controlName}
        rules={required}
        render={({ field: { value } }) => (
          <>
            <CustomTitleWithInput
              customClass={'latitude-and-latitude'}
              title={i18n.t(name, { locale: 'en' })}
              type={type}
              defaultValue={value}
              setValue={(value) =>
                setValue(controlName, value, { shouldDirty: true })
              }
              focusChange={() => focusChange(name)}
              disabled={disabled}
              blurAction={blurAction}
              useDefaultValue={false}
            />
            {language === LanguageConfig.english ? (
              <ReactHookFormErrorMessage errors={errors} id={controlName} />
            ) : null}
          </>
        )}
      />
    );
  };

  const displatAddressArea = detailField({
    name: 'Address for frontend display',
    controlName: `storeTranslations.${language}.address`,
    // defaultValue: watchAddress,
    required: {
      required: StoreErrorHandleFields.address.required,
    },
  });

  const addressArea = detailField({
    name: 'Address for below map location selection',
    controlName: 'mapAddress',
    // defaultValue: watchMapAddress,
    // required: {
    //   required: StoreErrorHandleFields.address.required,
    // },
    blurAction: (target) => {
      if (target.value) {
        setAddressValue(target.value);
      }
    },
  });
  const latitudeArea = detailField({
    type: 'number',
    name: 'latitude',
    controlName: 'latitude',
    required: {
      validate: {
        inner: (value) => {
          if (!value) {
            return true;
          }
          if (value?.length >= 11) {
            return StoreErrorHandleFields.latitudeLength1.required;
          }
          const numberList = `${value}`.split('.');
          const decimal = numberList[1];
          if (decimal?.length >= 7) {
            return StoreErrorHandleFields.latitudeLength2.required;
          }
          return (
            (value <= 90 && value >= -90) ||
            StoreErrorHandleFields.latitude.required
          );
        },
      },
    },
    // defaultValue: watchLatitude,
    // disabled: true,
  });
  const longitudeArea = detailField({
    type: 'number',
    name: 'longitude',
    controlName: 'longitude',
    required: {
      validate: {
        inner: (value) => {
          if (!value) {
            return true;
          }
          if (value?.length >= 11) {
            return StoreErrorHandleFields.longitudeLength1.required;
          }
          const numberList = `${value}`.split('.');
          const decimal = numberList[1];
          if (decimal?.length >= 7) {
            return StoreErrorHandleFields.longitudeLength2.required;
          }
          return (
            (value <= 180 && value >= -180) ||
            StoreErrorHandleFields.longitude.required
          );
        },
      },
    },
    // defaultValue: watchLongitude,
    // disabled: true,
  });

  return (
    <>
      <label className="create-section-title">
        {i18n.t('detail_required', { locale: 'en' })}
      </label>
      {language !== LanguageConfig.english ? null : districtArea}
      {displatAddressArea}
      {language !== LanguageConfig.english ? null : (
        <>
          {addressArea}

          <CustomMap
            onClick={(e) => {
              console.log('@@314: ', e);
              setValue('latitude', e.latLng.lat().toFixed(6), {
                shouldDirty: true,
              });
              setValue('longitude', e.latLng.lng().toFixed(6), {
                shouldDirty: true,
              });
            }}
            lat={watchLatitude}
            lng={watchLongitude}
            style={{ container: { marginTop: '30px' } }}
          />
        </>
      )}

      {language !== LanguageConfig.english ? null : latitudeArea}
      {language !== LanguageConfig.english ? null : longitudeArea}
    </>
  );
}
