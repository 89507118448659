import React from 'react';
import ContentSections from '../base/ContentSections';
import GeneralAlertGeneralSection from './GeneralAlertGeneralSection';
import {
  ContinueWithBackButtons,
  OnlyContinueButton,
} from '../base/BottomStepComponent';
import { useDispatch, connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { validate } from './MessageValidate';

function CreateGeneralAlertStepOne({ languages }) {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const { getValues, clearErrors, setError } = useFormContext();

  const renderTabForLanguage = (language) => {
    return [<GeneralAlertGeneralSection language={language} />];
  };

  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 0,
      isBack,
      languages: languages,
    });
    dispatch({
      type: 'generalAlert/stepChange',
      payload: { isBack: isBack, step: 0, isValid },
    });
  };

  return (
    <div className="general_alert_container">
      <ContentSections
        languageTabContent={{
          containers: languages?.map((item) => ({
            container: [renderTabForLanguage(item.code)],
            key: item.code,
            title: item.sourceName,
          })),
        }}
        hidePreview={true}
      />
      {params.id ? (
        <OnlyContinueButton
          continueAction={() => {
            stepChangeAction(false);
          }}
        />
      ) : (
        <ContinueWithBackButtons
          backAction={() => {
            history.goBack();
          }}
          continueAction={() => {
            stepChangeAction(false);
          }}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({ languages: state.language.allList });

export default connect(mapStateToProps)(CreateGeneralAlertStepOne);
