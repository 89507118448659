import React, { useEffect, useState } from 'react';
import './GoodieBagEntitlement.scss';
import queryString from 'query-string';
import ContentSections from '../../../../components/base/ContentSections';
import { SaveAndBackButtons } from '../../../../components/base/BottomStepComponent';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { PermissionCodes } from '../../../../config/PermissionCodes';
import CustomListComponent from '../../../../components/base/CustomListComponent';
import AuthButton from '../../../../components/base/AuthButton';
import CustomBreadcrumb from '../../../../components/base/CustomBreadcrumb';
import { createAction } from '../../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { ADMIN_TYPE } from '../../../../config/CustomEnums';
import { Image } from 'react-bootstrap';
import copy from 'copy-to-clipboard';
import CopyIcon from '../../../../assets/images/campaign/copy_icon.svg';
import Instruction_PDF from '../../../../media/OperationInstructionGoodieBag.pdf';
import { ToastType } from '../../../../models/NavBarModel';
import { GOODIE_BAG_ENTITLEMENT_METHOD_TYPE_DISPLAY } from '../../../../models/GoodieBagsModel';
import { goodieBagsEntitlementTrackingCodeBreads } from '../../../../config/CustomBreadConfig';
import GoodieBagEntitlementTrackingCodeFilter from './GoodieBagEntitlementTrackingCodeFilter';
import CountComponent from '../../../../components/base/CountComponent';
import { ImageTextButton } from '../../../../components/base/CustomImageButton';
import filterImage from '../../../../assets/images/32filter.svg';
import ListFilterComponent from '../../../../components/base/ListFilterComponent';
import BaseSimpleTable from '../../../../components/base/BaseSimpleTable';
import './GoodieBagEntitlementTrackingCode.scss';
import CreateTrackingCodePrompt from '../../../../components/goodieBags/CreateTrackingCodePrompt';

const WECHAT_APP_ID = process.env.REACT_APP_WECHAT_APP_ID;

function TrackingCodeTable({
  formatedDataList,
  setChangeTrackingCodes,
  goodieBag,
}) {
  const params = useParams();
  const { id } = params;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [showFilter, setShowFilter] = useState(false);

  const { pageInfo, totalPage, totalCount, entitlementDetail, userAdminType } =
    useSelector((state) => ({
      pageInfo: state.entitlementTrackingCodes.pageInfo,
      totalPage: state.entitlementTrackingCodes.totalPage,
      totalCount: state.entitlementTrackingCodes.totalCount,
      entitlementDetail: state.fortuneBagEntitlement.entitlementDetail,
      userAdminType: state.users.adminType,
    }));

  const getFilterCount = () => {
    let filterCount = 0;
    const querySearch = queryString.parse(location.search);
    Object.entries(querySearch).map(([key, value]) => {
      if (Object.keys(querySearch).includes(querySearch[key])) {
        return;
      }
      if (!['page', 'rank', 'search', 'sort'].includes(key) && value) {
        filterCount += 1;
      }
    });

    return filterCount;
  };

  const filterCount = getFilterCount();
  const permissionGroup = PermissionCodes.entitlementTrackingCode;
  const filter = {
    hasFilter: true,
    componment: GoodieBagEntitlementTrackingCodeFilter,
  };
  const isMerchant = userAdminType === ADMIN_TYPE.MERCHANT_ADMIN;

  return (
    <>
      {showFilter ? (
        <filter.componment backAction={() => setShowFilter(false)} />
      ) : null}

      <div className="all-list-scrollable-area entitlement-info-area">
        <div className="list-section-container-button-and-title">
          <div className="list-section-container-header-left">
            <label className="create-section-title">
              {isMerchant
                ? GOODIE_BAG_ENTITLEMENT_METHOD_TYPE_DISPLAY.OPTION_D.title
                : 'MERCHANTS'}{' '}
              -{' '}
              {`[ID: ${entitlementDetail?.merchant?.pk}] ${entitlementDetail?.merchant?.name}`}
            </label>
          </div>
          <div className="list-section-container-header-buttons">
            <AuthButton
              title="Add tracking code"
              action={() => {
                console.log('add');
                setChangeTrackingCodes({ code: {} });
              }}
              requires={PermissionCodes.changeEntitlementTrackingCode}
            />
          </div>
        </div>

        <div id="tabindex" className="all-list-component-action-header">
          <div className="all-list-component-action-header-div">
            <label>
              Goodie bag VISIBLE PERIOD:{' '}
              {entitlementDetail?.goodieBag?.visiblePeriod}
            </label>
            <CountComponent
              start={pageInfo?.startCursor}
              end={pageInfo?.endCursor}
              total={totalCount}
            />
          </div>
        </div>

        <div className="goodie-bag-mini-program">
          <label className="goodie-bag-mini-program-title">
            App ID (HKTB mini program):
          </label>
          <label className="goodie-bag-mini-program-id">{WECHAT_APP_ID}</label>
          <AuthButton
            className="lebel-right-button"
            onClick={(e) => {
              copy(WECHAT_APP_ID);
              dispatch({
                type: 'navBars/updateState',
                payload: {
                  saveDiscardToastShowing: {
                    value: true,
                    type: 'App ID copied',
                  },
                },
              });
            }}
            variant="link"
          >
            <Image src={CopyIcon} />
          </AuthButton>
        </div>
        {filter.hasFilter ? (
          <div
            className={`all-list-filter-container ${filter.customFilterAreaClass}`}
            style={showFilter && filter.offset ? { right: filter.offset } : {}}
          >
            <ImageTextButton
              action={() => {
                setShowFilter(!showFilter);
              }}
              image={filterImage}
              title={'Filter'}
            />
            {filterCount > 0 ? (
              <label className="filter-badge-icon">{filterCount}</label>
            ) : null}
          </div>
        ) : null}
        <div className="all-list-search-container">
          <ListFilterComponent
            searchPlaceholder={'Search by Tracking code'}
            onSearch={(key) => {
              history.push({
                pathname: location.pathname,
                hash: location.hash,
                search: queryString.stringify({
                  ...queryString.parse(location.search),
                  ...{ search: key, page: 1 },
                }),
              });
            }}
          />
        </div>
      </div>

      <div className="all-list-table-content all-list-table-content-simple">
        <BaseSimpleTable
          customTableClassName="entitlement-tracking-code-simple-table"
          fields={[
            {
              displayName: 'Tracking code',
              fieldName: 'trackingCode',
            },
            {
              displayName: 'QR code',
              fieldName: 'qrcodeImage',
            },
            {
              displayName: 'Active start',
              fieldName: 'displayStartDate',
            },
            {
              displayName: 'Active end',
              fieldName: 'displayEndDate',
            },
            {
              displayName:
                'Generic URL - Distribute from email or SMS, and open in web browser',
              fieldName: 'genericURL',
              copyToast: ToastType.merchantContentCopied,
            },
            {
              displayName:
                'Generic URL - Distribute from mini program, and open in mini program',
              fieldName: 'wechatGenericURL',
              copyToast: ToastType.merchantContentCopied,
            },
            {
              displayName: 'Status',
              fieldName: 'status',
            },
            {
              displayName: 'Action',
              fieldName: 'Action',
            },
          ]}
          dataList={formatedDataList}
          totalCount={totalCount}
          customActions={[
            {
              name: 'Edit active period',
              action: (item) => {
                console.log('edit', item);
                setChangeTrackingCodes({
                  code: {
                    pk: item.pk,
                    name: item.trackingCode,
                    isFollowGoodieBag: item.isFollowGoodieBagActivePeriod,
                    startDate: item.startDate,
                    endDate: item.endDate,
                  },
                });
              },
              requires: `change_${permissionGroup}`,
            },
            {
              name: 'Activate',
              action: (item) => {
                dispatch(
                  createAction('entitlementTrackingCodes/updateTrackingCode')({
                    data: {
                      id: item.pk,
                      isForcedInactive: !item.isForcedInactive,
                    },
                    afterAction: () => history.push(location),
                  }),
                );
              },
              requires: `change_${permissionGroup}`,
            },
          ]}
        />
      </div>
    </>
  );
}

export default function GoodieBagEntitlementTrackingCode() {
  const params = useParams();
  const { id, entitlementId } = params;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [formatedDataList, setFormatedDataList] = useState([]);
  const [changeTrackingCodes, setChangeTrackingCodes] = useState();

  const { dataList, entitlementDetail, userAdminType } = useSelector(
    (state) => ({
      dataList: state.entitlementTrackingCodes.pagedList,
      entitlementDetail: state.fortuneBagEntitlement.entitlementDetail,
      userAdminType: state.users.adminType,
    }),
  );

  const TAB_METHOD = 'Method';
  const isMerchant = userAdminType === ADMIN_TYPE.MERCHANT_ADMIN;
  const isAdmin = userAdminType === ADMIN_TYPE.HKTB_ADMIN;

  useEffect(() => {
    dispatch(
      createAction('fortuneBagEntitlement/getEntitlement')({
        id: entitlementId,
      }),
    );
  }, [dispatch, entitlementId]);

  useEffect(() => {
    dispatch({ type: 'entitlementTrackingCodes/clearData' });

    const queryString = require('query-string');
    const querySearch = queryString.parse(location.search);
    const searchKey = querySearch['search'] || '';
    const searchActivePeriod = querySearch['active_period'] || '';
    const searchStatus = querySearch['status'] || '';
    const stringPage = querySearch['page'] || 1;
    const page = parseInt(stringPage);

    dispatch(
      createAction('entitlementTrackingCodes/getList')({
        goodieBagId: id,
        entitlementId: entitlementId,
        page,
        activeTimeRange: searchActivePeriod,
        status: searchStatus,
        search: searchKey,
      }),
    );
  }, [dispatch, location]);

  useEffect(() => {
    if (entitlementDetail && dataList) {
      setFormatedDataList(
        dataList.map((item) => {
          return {
            ...item,
            genericURL: `${entitlementDetail.staticLink}${item.genericURL}`,
            wechatGenericURL: `${
              entitlementDetail.wechatStaticLink
            }${encodeURIComponent(item.wechatGenericURL)}`,
          };
        }),
      );
    } else if (dataList) {
      setFormatedDataList(dataList);
    }
  }, [entitlementDetail, dataList]);

  const firstTabName = () => {
    return isMerchant
      ? TAB_METHOD
      : GOODIE_BAG_ENTITLEMENT_METHOD_TYPE_DISPLAY.OPTION_D.title;
  };

  const buttons = [
    <AuthButton
      title="Operation Instruction pdf"
      action={() => {
        window.open(Instruction_PDF);
      }}
    />,
  ];

  const activeTab = firstTabName();

  return (
    <>
      <CustomListComponent
        caution={{
          title: 'Entitlement',
        }}
        defaultActiveKey={activeTab}
        onTabChange={(key) => {
          history.replace({
            pathname: location.pathname,
            hash: key,
            state: location.state,
          });
        }}
        hideTab={false}
        breadcrumb={
          <CustomBreadcrumb
            breadcrumbs={goodieBagsEntitlementTrackingCodeBreads(
              id,
              history.location?.state?.goodieBagName ||
                entitlementDetail?.goodieBag?.name,
              entitlementDetail?.merchant?.name,
            )}
          />
        }
        buttons={buttons}
        tabs={[
          {
            name: isMerchant ? TAB_METHOD : firstTabName(),
            content: (
              <>
                <div className="scroll-container-common entitlement-content-section">
                  <ContentSections
                    sections={[
                      <TrackingCodeTable
                        formatedDataList={formatedDataList}
                        setChangeTrackingCodes={(value) => {
                          setChangeTrackingCodes(value);
                        }}
                        goodieBag={entitlementDetail?.goodieBag}
                      />,
                    ]}
                    hidePreview={true}
                  />
                  <SaveAndBackButtons
                    saveTempText={''}
                    saveText={''}
                    backAction={() => history.goBack()}
                    saveAction={() => {}}
                    saveTempAction={() => {}}
                  />
                </div>
              </>
            ),
          },
        ]}
      />
      <CreateTrackingCodePrompt
        show={!!changeTrackingCodes}
        onClose={() => setChangeTrackingCodes()}
        onConfirm={(payload) => {
          dispatch(
            createAction('entitlementTrackingCodes/createUpdateTrackingCodes')(
              payload,
            ),
          );
          // setChangeTrackingCodes();
        }}
        title={
          changeTrackingCodes?.code?.pk
            ? 'Edit active period'
            : 'Add tracking code'
        }
        data={{
          merchantName: entitlementDetail?.merchant?.name,
          merchantId: entitlementDetail?.merchant?.pk,
          goodiebagId: id,
          goodiebagStartDate: entitlementDetail?.goodieBag?.displayStartDate,
          goodiebagEndDate: entitlementDetail?.goodieBag?.displayEndDate,
          code: changeTrackingCodes?.code,
          // code: {
          //   name: '',
          //   isFollowGoodieBagPeriod: false,
          //   goodiebagStartDate: '',
          //   goodiebagEndDate: '',
          //   startDate: '',
          //   endDate: '',
          // },
        }}
      />
    </>
  );
}
