import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import './Dashboard.scss';
import '../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../components/base/CustomBreadcrumb';
import { NavBarNames } from '../../config/NavBarNameList';
import CustomTips from '../../components/base/CustomTips';
import CustomersCard from '../../components/dashboard/CustomersCard';
import CampaignsCard from '../../components/dashboard/CampaignsCard';
import CouponsCardNew from '../../components/dashboard/CouponsCardNew';
import DateRangeSelection from '../../components/dashboard/DateRangeSelection';
import { createAction } from '../../utils';
import DateRangeCompare from '../../components/dashboard/DateRangeCompare';
import {
  CategoryCard,
  AttributeCard,
  InterestCard,
} from '../../components/dashboard/PreferenceCard';
import CustomAgeCard from '../../components/dashboard/CustomAgeCard';
import Onboarding from '../onboarding/Onboarding';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Top10CouponRedeemCard from '../../components/dashboard/Top10CouponRedeemCard';
import Top10CouponAcquiredCard from '../../components/dashboard/Top10CouponAcquiredCard';
import Top5CustomerNationalityCard from '../../components/dashboard/Top5CustomerNationalityCard';
import CustomerGenderCard from '../../components/dashboard/CustomerGenderCard';
import { TimeFormater } from '../.././utils/TimeFormatUtil';
import CampaignsCardV2 from '../../components/dashboard/CampaignsCardV2';
import GoodiebagCard from '../../components/dashboard/GoodiebagCard';

const Dashboard = () => {
  const dispatch = useDispatch();
  const tip =
    'The dashboard shows an overview of the customers, campaigns, and coupons. The percentage change is compared to the previous period.';
  // const [firstTimePickerChange, setFirstTimePickerChange] = useState(true);
  const [datePicker, setDatePicker] = useState();

  useEffect(() => {
    return () => {
      dispatch({ type: 'dashboardCustomer/clearState' });
      dispatch({
        type: 'attributeTag/updateState',
        payload: { selectedAllListLoading: false },
      });
    };
  }, []);

  const requestData = (start, end) => {
    if (!start || !end) {
      return;
    }
    dispatch({ type: 'dashboardCustomer/clearState' });
    dispatch({ type: 'dashboard/clearState' });
    const startDate = start.format(TimeFormater.GTMFormat);
    const endDate = end.format(TimeFormater.GTMFormat);

    dispatch(
      createAction('dashboard/setConfig')({
        startDate,
        endDate,
      }),
    );

    dispatch(
      createAction('dashboardCustomer/getCustomerAnalyticData')({
        startDate,
        endDate,
        isChart: true,
      }),
    );
    dispatch(
      createAction('dashboard/getDashboardData')({
        startDate,
        endDate,
      }),
    );
    dispatch(
      createAction('dashboard/getTop10CouponRedeemData')({
        startDate,
        endDate,
      }),
    );
    dispatch(
      createAction('dashboard/getTop10CouponAcquiredData')({
        startDate,
        endDate,
      }),
    );
    dispatch(
      createAction('dashboard/getTop5NationalityData')({
        startDate,
        endDate,
      }),
    );
    dispatch(
      createAction('dashboard/getGenderData')({
        startDate,
        endDate,
      }),
    );

    dispatch(
      createAction('dashboard/getGoodieBagData')({
        startDate,
        endDate,
      }),
    );
    dispatch({ type: 'attributeTag/getAllList' });
  };

  return (
    <>
      <div className="list-section-container-header list-section-container-header-bottom dashboard-header">
        <CustomBreadcrumb />
        <div className="list-section-container-button-and-title">
          <div className="list-section-container-header-left dashboard-header-left">
            <div className="dashboard-title">
              <CustomTips detail={tip}>
                <label className="list-section-container-header-title">
                  {NavBarNames.dashboard}
                </label>
              </CustomTips>
            </div>
            <DateRangeCompare />
            <button
              type="button"
              className="btn-further btn btn-primary"
              onClick={() => {
                requestData(datePicker?.start, datePicker?.end);
              }}
            >
              Refresh
            </button>
          </div>
          <DateRangeSelection
            applyDateRangeEvent={(start, end) => {
              // setFirstTimePickerChange(false);
              setDatePicker({ start: start, end: end });
              // if (firstTimePickerChange) {
              //   return;
              // }
              // requestData(start, end);
            }}
            clearDataEvent={() => {
              dispatch(createAction('dashboard/clearState')());
            }}
          />
        </div>
      </div>
      <CustomersCard />
      <GoodiebagCard />
      <div className="dashboard-campaigns-coupons">
        <CouponsCardNew />
        {/* <CampaignsCard /> */}
        <CampaignsCardV2 />
      </div>
      <CategoryCard />
      <AttributeCard />
      <InterestCard />
      <CustomAgeCard />
      <div className="dashboard-campaigns-coupons">
        <CustomerGenderCard />
        <Top5CustomerNationalityCard />
      </div>
      <div className="dashboard-campaigns-coupons">
        <Top10CouponAcquiredCard />
        <Top10CouponRedeemCard />
      </div>
    </>
  );
};

export default Dashboard;
