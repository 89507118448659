import { BaseHelper } from './BaseGraphQLAPIHelper';

export const getCampaignGroupList = (afterCursor, others) => {
  let filter = '';
  const { filterName, sort, search, campaign_in } = others || {};
  if (search) {
    filter = `, search:"${search}"`;
  }
  if (filterName) {
    filter = `, nameIcontains:"${filterName}"`;
  }
  if (sort) {
    filter += `, orderBy: "${sort}"`;
  } else {
    filter += `, orderBy: "-pk"`;
  }
  if (campaign_in) {
    filter += `, campaignIn: "${campaign_in}"`;
  }
  const query = `{
    campaignGroups(first: ${others.pageSize || 20}, after:"${afterCursor}"${filter}) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            pk
            name
            perHeadLimit
            alertMessageOfExceedLimit
            translations(first: 10) {
              edges {
                node {
                  pk
                  language
                  alertMessageOfExceedLimit
                }
              }
            }
            campaigns(first: 10) {
              edges {
                node {
                  pk
                  name
                }
              }
            }
          }
        }
      }
    }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getCampaignGroup = (campaignGroupID) => {
  const query = `
    {
      campaignGroup(id: "${campaignGroupID}") {
        id
        pk
        name
        perHeadLimit
        alertMessageOfExceedLimit
        translations(first: 10) {
          edges {
            node {
              pk
              language
              alertMessageOfExceedLimit
            }
          }
        }
        campaigns(first: 10) {
          edges {
            node {
              pk
              name
            }
          }
        }
      }
    }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createCampaignGroup = (inputBody) => {
  const query = `
  mutation CreateCampaignGroup($input: CreateCampaignGroupInput!) {
    createCampaignGroup(input: $input) {
      success
      node {
        id
        pk
        name
        perHeadLimit
        alertMessageOfExceedLimit
        translations(first: 10) {
          edges {
            node {
              language
              alertMessageOfExceedLimit
            }
          }
        }
        campaigns(first: 10) {
          edges {
            node {
              pk
              name
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateCampaignGroup = (inputBody) => {
  const query = `
  mutation UpdateCampaignGroup($input: UpdateCampaignGroupInput!) {
    updateCampaignGroup(input: $input) {
      success
      node {
        id
        pk
        name
        perHeadLimit
        alertMessageOfExceedLimit
        translations(first: 10) {
          edges {
            node {
              language
              alertMessageOfExceedLimit
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteCampaignGroups = (ids) => {
  const query = `
  mutation DeleteCampaignGroups($input: DeleteCampaignGroupsInput!) {
    deleteCampaignGroups(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
