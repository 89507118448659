import React from 'react';
import i18n from '../../I18n';
import ImageUploader from '../base/ImageUploader';
import { LanguageConfig } from '../../config/CustomEnums';
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from '../../utils';
import { isShowError } from '../campaign/campaignCreation/CreateCampaignHandleError';
import { CreateTransactionError } from '../../models/CreateTransactionModel';

function PurchaseReceiptSection(props) {
  const dispatch = useDispatch();

  const { receiptImage, creditCardSlipImage, errorFields } = useSelector(
    (state) => ({
      receiptImage: state.createTransaction.transaction.receiptImage,
      creditCardSlipImage:
        state.createTransaction.transaction.creditCardSlipImage,
      errorFields: state.createTransaction.errorFields,
    }),
  );

  const isError = (fieldName) => {
    return isShowError(fieldName, errorFields, LanguageConfig.english);
  };
  return (
    <>
      <div className="first-section-title">PURCHASE RECEIPT</div>
      <div className="second-section">
        <div className="second-section-title">Receipt image</div>
        <div className="second-section_description">
          Suggested Image Size: 1125 px * 2001 px At least : 375 px * 667px(Less
          than 2MB, support JPG, PNG and GIF only)
        </div>
        <ImageUploader
          minWidth={375}
          minHeight={667}
          // skipSize={true}
          images={receiptImage ? [receiptImage] : []}
          // aspect={3 / 2}
          maxImageNum={1}
          language={LanguageConfig.english}
          onImageStateChange={(data) => {
            console.log('@30', data);
            dispatch(
              createAction('createTransaction/updateTransactionState')({
                receiptImage: data?.[0],
              }),
            );
          }}
          uploadImageClicked={() => {}}
          errorMessage={
            isError(CreateTransactionError.receiptImage.name)
              ? CreateTransactionError.receiptImage.message
              : ''
          }
        />
      </div>
      <div className="second-section">
        <div className="second-section-title">Credit card slip image</div>
        <div className="second-section_description">
          Suggested Image Size: 1125 px * 2001 px At least : 375 px * 667px(Less
          than 2MB, support JPG, PNG and GIF only)
        </div>
        <ImageUploader
          minWidth={375}
          minHeight={667}
          // skipSize={true}
          images={creditCardSlipImage ? [creditCardSlipImage] : []}
          // aspect={3 / 2}
          maxImageNum={1}
          language={LanguageConfig.english}
          onImageStateChange={(data) => {
            dispatch(
              createAction('createTransaction/updateTransactionState')({
                creditCardSlipImage: data?.[0],
              }),
            );
          }}
          uploadImageClicked={() => {}}
          errorMessage={isError(
            CreateTransactionError.creditCardSlipImage.name
              ? CreateTransactionError.creditCardSlipImage.message
              : '',
          )}
        />
      </div>
    </>
  );
}

export default PurchaseReceiptSection;
