import { createModel } from './BaseModel';
import {
  getCouponLabelList,
  getCouponLabel,
  createOrUpdateCouponLabel,
  deleteCouponLabels,
} from '../services/CouponLabelAPIHelper';
import { APIStatus, LanguageConfig, SESSION_KEYS } from '../config/CustomEnums';
import {
  createAction,
  getObjectFromSessionStorage,
  removeFromSessionStorage,
  saveToSessionStorage,
} from '../utils';
import { apiWithResponseHandle } from './LoadingUtil';

const COUPON_LABEL_SESSION_KEY = SESSION_KEYS.COUPON_LABEL_SESSION_KEY;

export const saveCouponLabelToSessionStorage = (data) => {
  saveToSessionStorage(COUPON_LABEL_SESSION_KEY, data);
};

export const removeCouponLabelSessionStroage = () => {
  removeFromSessionStorage(COUPON_LABEL_SESSION_KEY);
};

const parseDetail = (data) => {
  const translations = { en: { name: data.name } };
  const temp = data.translations?.edges.forEach((item) => {
    translations[item.node.language || LanguageConfig.english] = {
      pk: item.node.pk,
      name: item.node.name,
    };
  });

  return {
    ...data,
    id: data.id,
    pk: data.pk,
    name: data.name,
    order: data.displayPriority,
    translations,
  };
};

const cleanTranslation = (translations, detailTranslations) => {
  const cleanedTranslations = [];
  console.log(translations, detailTranslations);
  Object.keys(translations).forEach((language) => {
    if (language === LanguageConfig.english) {
      return;
    }
    const translation = translations[language];
    const cleanedOneTranslation = {
      language,
      name: translation.name,
    };

    if (detailTranslations) {
      cleanedOneTranslation.id = detailTranslations[language]?.pk;
    }
    cleanedTranslations.push(cleanedOneTranslation);
  });
  return cleanedTranslations;
};

const cleanCouponLabel = (data, detail) => {
  const enData = data.translations[LanguageConfig.english];
  const payload = {
    name: enData.name,
    displayPriority: data.displayPriority,
    translations: cleanTranslation(data.translations, detail?.translations),
  };
  if (detail) {
    payload.id = detail.pk;
  }
  return payload;
};

const getInitialState = () => {
  return {
    totalCount: 0,
    totalPage: 0,
    pageInfo: {
      startCursor: '',
      endCursor: '',
      hasNextPage: false,
      hasPreviousPage: false,
    },
    checkedList: [],
    hasUpdatedDefaultValues: false,
    formHasSubmitted: false,
  };
};

export default createModel({
  namespace: 'couponLabel',
  params: {
    sessionKey: SESSION_KEYS.COUPON_LABEL_SESSION_KEY,
    dataKey: SESSION_KEYS.COUPON_LABEL_DATA_SESSION_KEY,
    objectKey: 'couponLabels',
    listAPI: getCouponLabelList,
    parse: (data) =>
      data?.couponLabels?.edges.map((item) => parseDetail(item.node)),
    deleteAPI: deleteCouponLabels,
    pkNode: 'CouponLabelNode',
    detailAPI: getCouponLabel,
    parseDetail: (data) => parseDetail(data.couponLabel),
    createdNewParse: (data) => parseDetail(data.createCouponLabel.node),
  },
  state: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    loadCouponLabelFromCookie(state, { payload }) {
      const couponLabel = getObjectFromSessionStorage(COUPON_LABEL_SESSION_KEY);
      if (!couponLabel) {
        return {
          ...state,
          detail: {
            hasUpdatedDefaultValues: false,
          },
        };
      }
      return {
        ...state,
        detail: {
          ...couponLabel,
          hasUpdatedDefaultValues: true,
        },
      };
    },
  },
  effects: {
    createCouponLabel: [
      function* ({ payload }, { all, select, put }) {
        const { data, afterAction } = payload;
        const serviceArgs = [createOrUpdateCouponLabel, cleanCouponLabel(data)];
        yield put(
          createAction('updateState')({
            apiStatus: APIStatus.calling,
          }),
        );
        function* onSuccess(data) {
          removeCouponLabelSessionStroage();
          yield put(
            createAction('updateState')({
              apiStatus: APIStatus.success,
              formHasSubmitted: true,
              newCouponLabelPk: data?.createCouponLabel?.node?.pk,
            }),
          );
          if (afterAction) {
            yield afterAction();
          }
        }
        function* onError(err) {
          yield put(
            createAction('updateState')({
              apiStatus: APIStatus.failed,
            }),
          );
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],
    updateCouponLabel: [
      function* ({ payload }, { all, select, put }) {
        const { data } = payload;
        const detail = yield select((state) => state.couponLabel.detail);

        const serviceArgs = [
          createOrUpdateCouponLabel,
          cleanCouponLabel(data, detail),
        ];
        yield put(
          createAction('updateState')({
            apiStatus: APIStatus.calling,
          }),
        );
        function* onSuccess(data) {
          removeCouponLabelSessionStroage();
          yield put(
            createAction('updateState')({
              apiStatus: APIStatus.success,
              formHasSubmitted: true,
            }),
          );
        }
        function* onError(err) {
          yield put(
            createAction('updateState')({
              apiStatus: APIStatus.failed,
            }),
          );
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],
    updateCouponLabelOrder: [
      function* ({ payload }, { all, select, put }) {
        const { data, afterAction } = payload;
        const serviceArgs = [createOrUpdateCouponLabel, data];
        yield put(
          createAction('updateState')({
            apiStatus: APIStatus.calling,
          }),
        );
        function* onSuccess(data) {
          if (afterAction) {
            yield afterAction();
          }
        }
        function* onError(err) {
          yield put(
            createAction('updateState')({
              apiStatus: APIStatus.failed,
            }),
          );
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],
  },
});
