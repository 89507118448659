import { MessageChannel } from '../../config/CustomEnums';
import InboxImage from '../../assets/images/message/inbox.svg';
import PushImage from '../../assets/images/message/push.svg';

export const MessageChannelConfig = [
  {
    channel: MessageChannel.inbox,
    image: InboxImage,
    description: 'In-app messages to explain new content and promotions.',
    suggest: 'Suggest length: 1,000 character',
  },
  {
    channel: MessageChannel.push,
    image: PushImage,
    description: 'Encourage customers to open the app.',
    suggest: 'Suggest length: 235 character',
  },
];
