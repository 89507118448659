import React from 'react';
import { useDispatch, connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import CustomRadios from '../base/CustomRadios';
import { Row } from 'react-bootstrap';
import ImageUploader from '../base/ImageUploader';
import {
  BannerErrorFields,
  BannerLocation,
  MPLinkType,
  BANNER_LOCATION_OPTIONS,
  TargetCustomerType,
  TARGET_CUSTOMER_OPTIONS,
  SelectCountryCodeType,
  SELECT_COUNTRY_CODE_OPTIONS,
} from '../../models/HomeBannersModel';
import { LanguageConfig } from '../../config/CustomEnums';
import { LandingPageType, BannerType } from '../../models/HomeBannersModel';
import { isLongScreen } from '../../utils/ScreenUtil';
import { useFormContext, Controller } from 'react-hook-form';
import CustomTitleWithInput from '../base/CustomTitleWithInput';
import { hasError, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import {
  CustomTitleWithRadio,
  CustomTitleLabel,
} from '../earning/CustomBaseComponments';
import BaseMutipleSelectorV2 from '../base/BaseMultipleSelectorV2';
import CustomSwitchButton from '../base/CustomSwitchButton';
import { CheckboxItem } from '../base/BaseFilter';
import { removeElementFromArray } from '../../utils';

const getRadioOptions = () => [
  LandingPageType.NO_LANDING_PAGE,
  LandingPageType.MANUAL_INPUT_URL,
];

const BANNER_TYPE_OPTIONS = [
  { label: 'Homepage', value: BannerType.HOME_PAGE },
  { label: 'My Reward page for Goodie bag', value: BannerType.GOODIE_BAG },
  { label: 'Prompt for Goodie bag', value: BannerType.GOODIE_BAG_PROMPT },
];

const CreateBannerContent = ({ language, countryCodeList }) => {
  const param = useParams();

  const { watch, setValue, control, formState } = useFormContext();
  const { errors } = formState;
  const watchCoverPhotos = watch(`coverPhotos.${language}.0`);
  const watchMobilePhotos = watch(`mobilePhotos.${language}.0`);
  const watchLandingPageType = param.id
    ? watch(`landingPageType`)
    : watch(`landingPageType`) || LandingPageType.NO_LANDING_PAGE.value;
  const watchManualInputUrl = watch(`manualInputUrl.${language}`);
  const watchOrder = watch('order');

  const watchBannerType = watch('bannerType');
  const watchLinkToMPAppId = watch(`linkToMPAppId.${language}`);
  const watchLinkToMPPath = watch(`linkToMPPath.${language}`);
  console.log(
    '@@49: ',
    language,
    watchLandingPageType,
    watch(`landingPageType`),
    watchBannerType,
  );
  const watchPublishLocation = watch(`publishLocation`);
  const watchMPLink = watch(`mpLink.${language}`) || MPLinkType.URL;
  const watchWechatUrl = watch(`wechatUrl.${language}`);
  console.log(watchPublishLocation);

  const watchTargetCustomers = watch('targetCustomers');
  const watchSelectCountryCodeType = watch('selectCountryCodeType');
  const watchCountryCodes = watch('countryCodes');

  const targetCustomersSelector = () => {
    return (
      <>
        <CustomTitleLabel title={'Target customers'} />
        <div className="display-flex-area">
          {TARGET_CUSTOMER_OPTIONS.map((item, index) => {
            return (
              <CheckboxItem
                index={index}
                title={item.label}
                defaultValue={watchTargetCustomers?.indexOf(item.value) > -1}
                customClass={`platform-checkbox ${
                  index === 0 ? '' : 'platform-checkbox-notfirst'
                }`}
                onChange={() => {
                  if (watchTargetCustomers?.includes(item.value)) {
                    const newCheckedData = removeElementFromArray(
                      watchTargetCustomers,
                      item.value,
                    );
                    setValue('targetCustomers', newCheckedData, {
                      shouldDirty: true,
                    });
                  } else {
                    setValue(
                      'targetCustomers',
                      [...(watchTargetCustomers || []), item.value],
                      { shouldDirty: true },
                    );
                  }
                }}
              />
            );
          })}
        </div>
        <ReactHookFormErrorMessage errors={errors} id={`targetCustomers`} />

        {watchTargetCustomers?.includes(TargetCustomerType.LOGGED_IN) ? (
          <>
            <CustomTitleLabel title={'Select country codes'} />
            <Row className="featured-exclusive-row">
              <CustomRadios
                onChange={(value) => {
                  setValue('selectCountryCodeType', value, {
                    shouldDirty: true,
                  });
                }}
                default={
                  watchSelectCountryCodeType || SelectCountryCodeType.ALL
                }
                options={SELECT_COUNTRY_CODE_OPTIONS}
              />
            </Row>
            {watchSelectCountryCodeType === SelectCountryCodeType.ALL ? null : (
              <>
                <BaseMutipleSelectorV2
                  namespace="countryCodes"
                  hideTopSpace={true}
                  data={{
                    sourceData: countryCodeList,
                    targetData: watchCountryCodes,
                    targetChange: (value) => {
                      setValue('countryCodes', value, {
                        shouldDirty: true,
                      });
                    },
                  }}
                />
                <ReactHookFormErrorMessage
                  errors={errors}
                  id={`countryCodes`}
                />
              </>
            )}
          </>
        ) : null}
      </>
    );
  };

  const bannerTypeSelector = (language) => {
    return (
      <>
        <CustomTitleLabel title={'Landing page'} />
        <Controller
          control={control}
          name={`landingPageType`}
          render={() => (
            <Row className="featured-exclusive-row">
              {/* <CustomRadios
                onChange={(value) => {
                  setValue(`landingPageType.${language}`, value, {
                    shouldDirty: true,
                  });
                }}
                default={watchLandingPageType}
                options={getRadioOptions()}
              /> */}
              <CustomSwitchButton
                defaultChecked={
                  watchLandingPageType ===
                  LandingPageType.MANUAL_INPUT_URL.value
                }
                onChange={(isChecked) => {
                  console.log('@@81');
                  if (isChecked) {
                    setValue(
                      `landingPageType`,
                      LandingPageType.MANUAL_INPUT_URL.value,
                      {
                        shouldDirty: true,
                      },
                    );
                  } else {
                    setValue(
                      `landingPageType`,
                      LandingPageType.NO_LANDING_PAGE.value,
                      {
                        shouldDirty: true,
                      },
                    );
                  }
                }}
              />
            </Row>
          )}
        />
        <ReactHookFormErrorMessage errors={errors} id={`landingPageType`} />
      </>
    );
  };

  const manualInputUrlArea = (language) => {
    if (watchLandingPageType === LandingPageType.NO_LANDING_PAGE.value) {
      return null;
    }
    return (
      <>
        {watchPublishLocation?.includes(BannerLocation.HOME_PAGE) ? (
          <>
            <Controller
              control={control}
              name={`manualInputUrl.${language}`}
              render={() => (
                <CustomTitleWithInput
                  title={'Target URL from web browser'}
                  tips={'Support redirection to URL only, not to mini program.'}
                  defaultValue={watchManualInputUrl}
                  error={{
                    error: hasError(errors, `manualInputUrl.${language}`),
                  }}
                  setValue={(value) => {
                    setValue(`manualInputUrl.${language}`, value, {
                      shouldDirty: true,
                    });
                  }}
                  multipleLines={true}
                  customClass={'muiltpleLinesInput'}
                />
              )}
            />
            <ReactHookFormErrorMessage
              errors={errors}
              id={`manualInputUrl.${language}`}
            />
          </>
        ) : null}

        {/* <CustomTitleLabel title={'Link direct to mini program (optional)'} /> */}

        {watchPublishLocation?.includes(BannerLocation.WECHAT) ? (
          <>
            <CustomTitleWithRadio
              title="Target destination from mini program"
              options={[
                // { value: MPLinkType.SAME, label: 'Same URL' },
                { value: MPLinkType.URL, label: 'URL' },
                { value: MPLinkType.MP_LINK, label: 'Direct to mini program' },
              ]}
              defaultValue={watchMPLink || MPLinkType.SAME}
              setValue={(value) =>
                setValue(`mpLink.${language}`, value, { shouldDirty: true })
              }
            />
            {watchMPLink === MPLinkType.URL ? (
              <>
                <Controller
                  control={control}
                  name={`wechatUrl.${language}`}
                  render={() => (
                    <CustomTitleWithInput
                      tips={
                        'Mini program not allow to directly open URL, user can browse by copying the URL and paste in web browser.'
                      }
                      tipsAtBottom={true}
                      defaultValue={watchWechatUrl}
                      error={{
                        error: hasError(errors, `wechatUrl.${language}`),
                      }}
                      setValue={(value) => {
                        setValue(`wechatUrl.${language}`, value, {
                          shouldDirty: true,
                        });
                      }}
                      customClass={'wechat-url-input muiltpleLinesInput'}
                      multipleLines={true}
                    />
                  )}
                />
                <ReactHookFormErrorMessage
                  errors={errors}
                  id={`wechatUrl.${language}`}
                />
              </>
            ) : null}

            {watchMPLink === MPLinkType.MP_LINK ? (
              <>
                <Controller
                  control={control}
                  name={`linkToMPAppId.${language}`}
                  render={() => (
                    <CustomTitleWithInput
                      title={'App ID'}
                      customTitleClass="without-margin-top"
                      defaultValue={watchLinkToMPAppId}
                      setValue={(value) => {
                        setValue(`linkToMPAppId.${language}`, value, {
                          shouldDirty: true,
                        });
                      }}
                    />
                  )}
                />
                <ReactHookFormErrorMessage
                  errors={errors}
                  id={`linkToMPAppId.${language}`}
                />
                <Controller
                  control={control}
                  name={`linkToMPPath.${language}`}
                  render={() => (
                    <CustomTitleWithInput
                      title={'Path'}
                      customTitleClass="without-margin-top"
                      defaultValue={watchLinkToMPPath}
                      setValue={(value) => {
                        setValue(`linkToMPPath.${language}`, value, {
                          shouldDirty: true,
                        });
                      }}
                      customClass={'muiltpleLinesInput'}
                      multipleLines={true}
                    />
                  )}
                />
                <ReactHookFormErrorMessage
                  errors={errors}
                  id={`linkToMPPath.${language}`}
                />
              </>
            ) : null}
          </>
        ) : null}
      </>
    );
  };

  const bannerImage = (
    name,
    title,
    tips,
    minWidth,
    minHeight,
    image,
    setImage,
    aspect,
    errorMessage,
  ) => {
    return (
      <>
        <label className="create-section-label create-section-label-bottom-space">
          {title}
        </label>
        <label className="create-campaign-cover-photo-reminder">{tips}</label>
        <Controller
          control={control}
          name={name}
          rules={{ required: errorMessage }}
          render={() => (
            <ImageUploader
              minWidth={minWidth}
              minHeight={minHeight}
              images={image ? [image] : []}
              onImageStateChange={(newState) => {
                setImage(newState[0] ? newState[0] : null);
              }}
              uploadImageClicked={() => {}}
              aspect={aspect}
              maxImageNum={1}
              language={language}
            />
          )}
        />
        <ReactHookFormErrorMessage errors={errors} id={name} />
      </>
    );
  };
  console.log(watchBannerType);
  return (
    <>
      {language === LanguageConfig.english ? (
        <>
          <label
            className={`create-section-label landing-title-margin-bottom `}
          >
            {'Banner type'}
          </label>
          <Row className="featured-exclusive-row">
            <CustomRadios
              onChange={(value) => {
                setValue('bannerType', value, { shouldDirty: true });
                // if(value === BannerType.WECHAT_HOME){
                //   setValue(`publishLocation`, [BannerType.WECHAT_HOME], {
                //     shouldDirty: true,
                //   })
                // }
              }}
              default={watchBannerType || BannerType.HOME_PAGE}
              options={BANNER_TYPE_OPTIONS}
            />
          </Row>
          {targetCustomersSelector()}
          <CustomTitleLabel title="Platform" />
          <div className="display-flex-area">
            {BANNER_LOCATION_OPTIONS.map((item, index) => {
              return (
                <CheckboxItem
                  index={index}
                  title={item.label}
                  defaultValue={watchPublishLocation?.indexOf(item.value) > -1}
                  customClass={`platform-checkbox ${
                    index === 0 ? '' : 'platform-checkbox-notfirst'
                  }`}
                  onChange={() => {
                    if (watchPublishLocation?.includes(item.value)) {
                      const newCheckedData = removeElementFromArray(
                        watchPublishLocation,
                        item.value,
                      );
                      setValue('publishLocation', newCheckedData, {
                        shouldDirty: true,
                      });
                    } else {
                      setValue(
                        'publishLocation',
                        [...(watchPublishLocation || []), item.value],
                        { shouldDirty: true },
                      );
                    }
                  }}
                />
              );
            })}
          </div>
          <ReactHookFormErrorMessage errors={errors} id={`publishLocation`} />
          {watchPublishLocation && watchPublishLocation?.length > 0
            ? bannerTypeSelector(language)
            : null}
        </>
      ) : // <CustomTitleWithRadio title="Banner type"
      //   options={BANNER_TYPE_OPTIONS}
      //   defaultValue={watchBannerType || BannerType.HOME_PAGE}
      //   setValue={(value) => setValue("bannerType", value, { shouldDirty: true })} />
      null}

      {manualInputUrlArea(language)}
      {watchPublishLocation?.includes(BannerLocation.HOME_PAGE)
        ? bannerImage(
            `coverPhotos.${language}.0`,
            'Desktop Image (Only for Web browser)',
            'Suggested Image Size: 2892px*450px  At least : 964px*150px(Less than 2MB, support JPG, PNG and GIF only.)',
            964,
            150,
            watchCoverPhotos,
            (value) => {
              setValue(`coverPhotos.${language}.0`, value, {
                shouldDirty: true,
              });
            },
            482 / 75,
            BannerErrorFields.bannerImage.message,
          )
        : null}
      {bannerImage(
        `mobilePhotos.${language}.0`,
        'Mobile Image',
        'Suggested Image Size: 1029px*396px At least : 343px*132px(Less than 2MB, support JPG, PNG and GIF only.)',
        343,
        132,
        watchMobilePhotos,
        (value) => {
          setValue(`mobilePhotos.${language}.0`, value, {
            shouldDirty: true,
          });
        },
        343 / 132,
        BannerErrorFields.mobileImage.message,
      )}
      {language === LanguageConfig.english ? (
        <>
          <Controller
            control={control}
            name={'order'}
            rules={{
              min: { value: 1, message: 'Minimum value is 1' },
              required: BannerErrorFields.order.message,
            }}
            render={() => (
              <CustomTitleWithInput
                title={'Display order'}
                error={{ error: hasError(errors, 'order') }}
                defaultValue={watchOrder}
                setValue={(value) => {
                  setValue('order', value, {
                    shouldDirty: true,
                  });
                }}
                type={'number'}
                // extra={{
                //   min: 1,
                // }}
              />
            )}
          />
          <ReactHookFormErrorMessage errors={errors} id={'order'} />
          <label className="create-message-suggest">
            {`Minimum value is 1. The smaller value, the higher priority.`}
          </label>
        </>
      ) : null}
    </>
  );
};

export default CreateBannerContent;
