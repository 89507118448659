import React, { useEffect, useState } from 'react';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import './ManageRecaptchaContent.scss';
import CustomTitleWithSwitch from '../base/CustomTitleWithSwitch';
import BasePrompt from '../base/prompt/BasePrompt';
import { useDispatch } from 'react-redux';
import SpecificCustomSwitchButton from '../../containers/merchants/stores/SpecificCustomSwitchButton';

function ManageRecaptchaContent({ stateRecaptchaOpen }) {
  const dispatch = useDispatch();
  const [recaptchaOpen, setRecaptchaOpen] = useState(true);
  const [showConfirmPrompt, setShowConfirmPrompt] = useState(false);

  useEffect(() => {
    setRecaptchaOpen(stateRecaptchaOpen);
  }, [stateRecaptchaOpen]);

  return (
    <div className="manage-recaptcha-container">
      <label className="manage-recaptch-title">
        {'Enable reCAPTCHA in Frontend'}
      </label>
      <label className="manage-recaptch-tips">
        {`Upon enabling the reCAPTCHA, reCAPTCHA will be applied to flows including
      acquiring coupons from coupon campaign and goodie bag related campaigns.`}
      </label>
      <SpecificCustomSwitchButton
        checked={recaptchaOpen}
        onChange={(value) => {
          if (value) {
            setShowConfirmPrompt(true);
          } else {
            setRecaptchaOpen(false);
            dispatch({
              type: 'systemSetting/updateSystemSettings',
              payload: { recaptchaOpen: false },
            });
          }
        }}
      />
      <BasePrompt
        show={showConfirmPrompt}
        closeAction={() => {
          setShowConfirmPrompt(false);
        }}
        rightButton={{
          text: 'Confirm',
          action: () => {
            setRecaptchaOpen(true);
            setShowConfirmPrompt(false);
            dispatch({
              type: 'systemSetting/updateSystemSettings',
              payload: { recaptchaOpen: true },
            });
          },
        }}
        leftButton={{
          text: 'Cancel',
          action: () => {
            setShowConfirmPrompt(false);
          },
        }}
        title={'Confirm to enable reCAPTCHA in frontend ?'}
      />
    </div>
  );
}

export default ManageRecaptchaContent;
