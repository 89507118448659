import { loading, apiWithResponseHandle } from './LoadingUtil';
import {
  convertNumberToCursor,
  convertCursorToNumber,
  getFileNameFromUrl,
  getObjectFromSessionStorage,
  removeFromSessionStorage,
  saveToSessionStorage,
  convertPKToId,
} from '../utils';
import {
  getHomeBanners,
  updateHomeBanners,
  createHomeBanners,
  getOneHomeBanner,
  deleteHomeBanners,
} from '../services/HomeManageApiHelper';
import { duplicateObjects } from '../services/CouponAPIHelper';
import {
  PublishTagType,
  APIStatus,
  LanguageConfig,
  SESSION_KEYS,
  YES_OR_NO_TAG,
} from '../config/CustomEnums';
export const LandingPageType = {
  NO_LANDING_PAGE: { value: 'NO_LANDING_PAGE', label: 'None' },
  MANUAL_INPUT_URL: { value: 'MANUAL_INPUT_URL', label: 'Manual input ' },
};

export const BannerType = {
  HOME_PAGE: 'E_SOLUTION_HOMEPAGE',
  GOODIE_BAG: 'GOODIE_BAG',
  WECHAT_HOME: 'WECHAT_HOMEPAGE',
  GOODIE_BAG_PROMPT: 'GOODIE_BAG_PROMPT',
};

export const BannerLocation = {
  HOME_PAGE: 'HOMEPAGE',
  WECHAT: 'WECHAT',
};

export const BANNER_LOCATION_OPTIONS = [
  { label: 'e-Solution website', value: BannerLocation.HOME_PAGE },
  { label: 'e-Solution mini program', value: BannerLocation.WECHAT },
];

export const MPLinkType = {
  SAME: 'SAME_URL',
  URL: 'URL',
  MP_LINK: 'DIRECT_TO_WECHAT',
};

export const PUBLISH_LOCATION_LIST = [
  { pk: 'GOODIE_BAG_LISTING_PAGE', name: 'Goodie bag listing page' },
  {
    pk: 'GOODIE_BAG_ACQUIRE_SUCCESSFUL_PROMPT',
    name: 'Goodie bag acquire successful prompt',
  },
];

export const TargetCustomerType = {
  GUEST: 'GUEST',
  LOGGED_IN: 'LOGGED_IN',
};
export const TARGET_CUSTOMER_OPTIONS = [
  { label: 'Guest', value: TargetCustomerType.GUEST },
  { label: 'Logged in', value: TargetCustomerType.LOGGED_IN },
];

export const SelectCountryCodeType = {
  ALL: 'ALL',
  SPECIFIC: 'SPECIFIC',
};
export const SELECT_COUNTRY_CODE_OPTIONS = [
  { label: 'All countries', value: SelectCountryCodeType.ALL },
  { label: 'Specific country code', value: SelectCountryCodeType.SPECIFIC },
];

const languages = [
  LanguageConfig.english,
  LanguageConfig.traditionalChinese,
  LanguageConfig.simplifiedChinese,
];

export const BANNER_SESSION_KEY = SESSION_KEYS.BANNER_SESSION_KEY;
export const CREATE_BANNER_SUCCESS = 'CREATE_BANNER_SUCCESS';
export const CREATE_BANNER_FAILED = 'CREATE_BANNER_FAILED';
export const BANNER_ORDER_LAST = 'BANNER_ORDER_LAST';

export const BannerErrorFields = {
  bannerImage: {
    name: 'bannerImage',
    message: 'Please provide a desktop image.',
  },
  mobileImage: {
    name: 'mobileImage',
    message: 'Please provide a mobile image.',
  },
  order: {
    name: 'order',
    message: 'Please provide a display order.',
  },
  manualyUrl: {
    name: 'manualyUrl',
    message:
      'Please enter a correct formatted URL. Starts with " http:// " or " https:// " .',
  },
  wechatUrl: {
    name: 'wechatUrl',
    message:
      'Please enter a correct formatted URL. Starts with " http:// " or " https:// " .',
  },
  publishLocation: {
    name: 'publishLocation',
    message: 'Please provide a platform.',
  },
  linkToMPAppId: {
    name: 'linkToMPAppId',
    message: 'Please provide an App id.',
  },
  linkToMPPath: {
    name: 'linkToMPPath',
    message:
      'Please provide a mini program path which length not larger than 255.',
  },
  mpLink: {
    name: 'mpLink',
    message: 'Please provide a mini program path type.',
  },
  // linkToMPPathLength: {
  //   name: 'linkToMPPath',
  //   message: 'Please provide a path.',
  // },
  targetCustomers: {
    name: 'targetCustomers',
    message: 'Please select at least one type of target customers.',
  },
  countryCodes: {
    name: 'countryCodes',
    message: 'Please select at least one country code.',
  },
};

export const ErrorWithLanguageFields = {
  [LanguageConfig.english]: BannerErrorFields,
  [LanguageConfig.traditionalChinese]: BannerErrorFields,
  [LanguageConfig.simplifiedChinese]: BannerErrorFields,
};

const getValueWithLanguage = (data, language, key) => {
  console.log(
    '@@96: ',
    data?.[key]?.[language],
    data?.[key]?.[language]?.[0]?.value,
  );
  const value = data?.[key]?.[language]?.[0]?.value;
  if (!value || typeof value === 'string') {
    return value;
  }
  return value[0]?.value;
};

const getInitialState = () => ({
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    { displayName: 'Target URL from web browser', fieldName: 'name' },
    { displayName: 'Target destination from mini program', fieldName: 'title' },
    // { displayName: 'Cover photo', fieldName: 'coverPhoto' },
    { displayName: 'Desktop Image', fieldName: 'coverPhoto' },
    { displayName: 'Mobile Image', fieldName: 'mobilePhoto' },
    { displayName: 'Banner type', fieldName: 'displayBannerType' },
    { displayName: 'Platform', fieldName: 'displayPublishLocation' },
    { displayName: 'Landing page', fieldName: 'landingPageTypeDisplay' },
    {
      displayName: 'Display Order',
      fieldName: 'order',
      orderField: 'displayPriority',
    },
    {
      displayName: 'Country Codes',
      fieldName: 'displayCountryCodes',
    },
    { displayName: ' Status', fieldName: 'status' },
  ],
  bannerList: [],
  banner: {
    selectCountryCodeType: SelectCountryCodeType.ALL,
  },
  totalCount: 0,
  totalPage: 0,
  pageInfo: {
    startCursor: 0,
    endCursor: 0,
  },
  checkedList: [],
  errorFields: [],
  createStatus: APIStatus.none,
  formChanged: false,
  loadingStatus: APIStatus.none,
  skusWithCategoryList: [],
  hasUpdatedDefaultValues: false,
});

const inputUrlCheck = (errorField, errors, url, language) => {
  if (!url) {
    errorField.push({ ...errors.manualyUrl, language });
    return;
  }
  const validUrlReg =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
  const result = validUrlReg.test(url);
  if (!result) {
    errorField.push({
      ...errors.manualyUrl,
      message: 'Invalid URL pattern. Please check and try again',
      language,
    });
  }
};

const checkFields = (data) => {
  const errorFields = [];
  const language = LanguageConfig.english;
  const errors = ErrorWithLanguageFields[language];
  const manualyUrl = data.manualInputUrl[language];
  const landingPageType = data.landingPageType[language];

  landingPageType === LandingPageType.MANUAL_INPUT_URL.value &&
    inputUrlCheck(errorFields, errors, manualyUrl, language);
  const bannerImage = getValueWithLanguage(data, language, 'coverPhotos');
  !bannerImage && errorFields.push({ ...errors.bannerImage, language });
  !getValueWithLanguage(data, language, 'mobilePhotos') &&
    errorFields.push({ ...errors.mobileImage, language });

  if (!data.order) {
    errorFields.push(BannerErrorFields.order);
  }
  return errorFields;
};

const addBannerTranslation = (banner, language) => {
  const needLandingPage =
    banner?.landingPageType === LandingPageType.MANUAL_INPUT_URL.value;

  const input = {};
  input.coverPhoto = null;
  input.manualInputUrl = '';
  if (banner?.publishLocation.includes(BannerLocation.HOME_PAGE)) {
    const coverPhoto = getValueWithLanguage(banner, language, `coverPhotos`);
    input.coverPhoto = getFileNameFromUrl(coverPhoto) || null;

    if (needLandingPage) {
      input.manualInputUrl = banner?.['manualInputUrl']?.[language];
    }
  }

  const mobilePhoto = getValueWithLanguage(banner, language, `mobilePhotos`);
  input.mobilePhoto = getFileNameFromUrl(mobilePhoto) || null;

  // input.landingPageType =
  //   banner?.['landingPageType']?.[language] ||
  //   LandingPageType.NO_LANDING_PAGE.value;
  // input.manualInputUrl =
  //   needLandingPage &&
  //   banner?.publishLocation.includes(BannerLocation.HOME_PAGE)
  //     ? banner?.['manualInputUrl']?.[language]
  //     : '';

  input.wechatLandingPageUrlType =
    banner?.['mpLink']?.[language] || MPLinkType.URL;
  input.wechatUrl = '';

  input.wechatAppId = '';
  input.wechatPath = '';
  if (
    needLandingPage &&
    banner?.publishLocation.includes(BannerLocation.WECHAT)
  ) {
    input.wechatUrl = banner?.['wechatUrl']?.[language];

    input.wechatAppId = banner?.['linkToMPAppId']?.[language];
    input.wechatPath = banner?.['linkToMPPath']?.[language];
  }

  return input;
};

const getBannerForCreateOrUpdate = (banner, isCreate, languages) => {
  let input = {};
  if (banner.pk && !isCreate) {
    input.id = banner.pk;
  }
  if (banner.order) {
    input.displayPriority = banner.order;
  }
  if (banner.isPublished !== null && banner.isPublished !== undefined) {
    input.isPublished = banner.isPublished || false;
  }

  console.log('@@224: ', banner, languages);
  const translations = [];
  languages.forEach((language) => {
    const translationData = addBannerTranslation(banner, language.code);

    if (language.code === LanguageConfig.english) {
      input = { ...input, ...translationData };

      return;
    }

    translationData.language = language.code;
    const originalTranslation = banner.translations?.filter(
      (item) => item.langauge === language.code,
    )?.[0];

    if (originalTranslation?.pk) {
      translationData.id = originalTranslation?.pk;
    }

    translations.push({ ...(originalTranslation | {}), ...translationData });
  });
  input.translations = translations;

  input.bannerType = banner.bannerType || BannerType.HOME_PAGE;
  input.landingPageType =
    banner?.landingPageType || LandingPageType.NO_LANDING_PAGE.value;

  input.bannerLocation = banner?.publishLocation;

  input.targetCustomers = banner?.targetCustomers;
  input.selectCountryCodeType = banner?.selectCountryCodeType;
  input.countryCodes = banner?.countryCodes?.map((item) => item.pk);
  if (banner?.targetCustomers?.includes(TargetCustomerType.LOGGED_IN)) {
    if (banner.selectCountryCodeType === SelectCountryCodeType.ALL) {
      input.countryCodes = [];
    }
  } else {
    input.selectCountryCodeType = SelectCountryCodeType.ALL;
    input.countryCodes = [];
  }

  input.translations.forEach((item, index) => {
    const trans = input.translations[index];
    const originTrans = banner.translations?.find(
      (translation) => translation.language === trans.language,
    );
    if (!isCreate && originTrans) {
      trans.id = originTrans.pk;
    }
  });
  console.log('@@251: ', input);
  return input;
};

const getInitData = (field, banner) => {
  let enField = banner[field];
  let data = {};
  (banner.translations?.edges || []).forEach((item) => {
    const trans = item.node;
    let languageData = trans[field];
    if (field === 'coverPhoto' || field === 'mobilePhoto') {
      enField = banner[field]
        ? [{ type: 'TYPE_URL', value: banner[field] }]
        : null;
      languageData = languageData
        ? [{ type: 'TYPE_URL', value: languageData }]
        : null;
    }

    data[trans.language] = languageData;
  });
  data[LanguageConfig.english] = enField;

  return data;
};

const assembleBanner = (node) => {
  const coverPhotos = getInitData('coverPhoto', node);
  const mobilePhotos = getInitData('mobilePhoto', node);
  const manualInputUrl = getInitData('manualInputUrl', node);

  const mpLink = getInitData('wechatLandingPageUrlType', node);
  const linkToMPAppId = getInitData('wechatAppId', node);
  const linkToMPPath = getInitData('wechatPath', node);
  const wechatUrl = getInitData('wechatUrl', node);
  // const publishLocation = getInitData('publishLocation', node);

  const publishLocation = BANNER_LOCATION_OPTIONS.filter((item) =>
    node.bannerLocation?.includes(item.value),
  );
  const targetCustomers = TARGET_CUSTOMER_OPTIONS.filter((item) =>
    node.targetCustomers?.includes(item.value),
  );
  const countryCodesList = node.targetCustomers?.includes(
    TargetCustomerType.LOGGED_IN,
  )
    ? node.countryCodesList
    : [];

  console.log('@@298: ', node);
  const banner = {
    id: node.id,
    pk: node.pk,
    bannerType: node.bannerType,
    displayBannerType:
      node.bannerType === BannerType.GOODIE_BAG
        ? 'My Reward page for Goodie bag'
        : node.bannerType === BannerType.GOODIE_BAG_PROMPT
        ? 'Prompt for Goodie bag'
        : 'Homepage',
    coverPhotos: coverPhotos,
    mobilePhotos: mobilePhotos,
    coverPhoto: node.coverPhoto,
    displayImage: node.coverPhoto || node.mobilePhoto,
    mobilePhoto: node.mobilePhoto,
    order: node.displayPriority,
    status: node.isPublished
      ? PublishTagType.published
      : PublishTagType.unPublished,
    isPublished: node.isPublished,
    name:
      node.landingPageType === LandingPageType.NO_LANDING_PAGE.value
        ? null
        : node.manualInputUrl,
    title:
      node.landingPageType === LandingPageType.NO_LANDING_PAGE.value
        ? null
        : node.wechatLandingPageUrlType === MPLinkType.MP_LINK
        ? `AppID: ${node.wechatAppId || '-'}\nPath: ${node.wechatPath || '-'}`
        : node.wechatUrl,
    manualInputUrl: manualInputUrl,
    landingPageType: node.landingPageType,
    landingPageTypeDisplay:
      node.landingPageType === LandingPageType.MANUAL_INPUT_URL.value
        ? YES_OR_NO_TAG.yes
        : YES_OR_NO_TAG.no,
    translations: node.translations?.edges?.map((item) => item.node),
    linkToMPAppId,
    linkToMPPath,
    publishLocation: publishLocation.map((item) => item.value),
    displayPublishLocation: publishLocation
      .map((item) => item.label)
      ?.join(', '),
    mpLink,
    wechatUrl,
    targetCustomers: targetCustomers.map((item) => item.value),
    displayTargetCustomers: targetCustomers
      .map((item) => item.label)
      ?.join(', '),
    selectCountryCodeType: node.selectCountryCodeType,
    countryCodes: countryCodesList?.map((item) => {
      return {
        ...item,
        pk: parseInt(item.pk),
      };
    }),
    countryCodesList: countryCodesList,
    displayCountryCodes: countryCodesList
      ?.map((item) => `+${item.pk} ${item.name}`)
      ?.join(', '),
  };
  console.log('@@375: ', banner);

  return banner;
};

export default {
  namespace: 'bannerList',
  state: { ...getInitialState(), productList: [] },
  reducers: {
    updateBanner(state, { payload }) {
      return {
        ...state,
        banner: {
          ...state.banner,
          ...payload,
        },
      };
    },

    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },

    assembleBanner(state, { payload }) {
      const banner = assembleBanner(payload.banner);
      return {
        ...state,
        loadingStatus: APIStatus.success,
        banner: banner,
      };
    },

    assembleBannerList(state, { payload }) {
      const bannerList = payload.bannerList.map(({ node }) =>
        assembleBanner(node),
      );
      const pageInfo = payload.pageInfo;
      const startCursor = convertCursorToNumber(pageInfo.startCursor);
      const endCursor = convertCursorToNumber(pageInfo.endCursor);
      return {
        ...state,
        bannerList: bannerList,
        totalCount: payload.totalCount,
        totalPage: Math.ceil(payload.totalCount / 20),
        pageInfo: {
          startCursor: startCursor + 1,
          endCursor: endCursor + 1,
        },
      };
    },

    changeVals(state, { payload }) {
      let tempData = getObjectFromSessionStorage(BANNER_SESSION_KEY);
      if (payload.vals) {
        tempData = { ...tempData, ...payload.vals };
        saveToSessionStorage(BANNER_SESSION_KEY, tempData);
      }
      return {
        ...state,
        formChanged: true,
      };
    },

    loadFromSessionStorage(state, { payload }) {
      const banner = getObjectFromSessionStorage(BANNER_SESSION_KEY);
      if (!banner) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        banner,
        hasUpdatedDefaultValues: true,
      };
    },

    saveBannerToSessionStorage(state, { payload }) {
      saveToSessionStorage(BANNER_SESSION_KEY, payload.values);
      return {
        ...state,
      };
    },

    removeBannerFromSessionStorage(state, { payload }) {
      removeFromSessionStorage(BANNER_SESSION_KEY);
      return {
        ...state,
      };
    },

    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },
  },
  effects: {
    getPageBannerList: [
      function* ({ payload }, { put }) {
        const searchPage = payload.page;
        let page = searchPage;
        if (typeof searchPage === 'string') {
          page = parseInt(searchPage) || 1;
        }
        const pageCursor = convertNumberToCursor((page - 1) * 20 - 1);
        const serviceArgs = [getHomeBanners, pageCursor, payload];
        function* onSuccess(data) {
          const banners = data.homepageBanners;
          yield put({
            type: 'assembleBannerList',
            payload: {
              bannerList: banners.edges,
              totalCount: banners.totalCount,
              pageInfo: banners.pageInfo,
            },
          });
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    updateBannerFromListAction: [
      function* ({ payload }, { put }) {
        yield put({
          type: 'updateState',
          payload: { createStatus: APIStatus.calling },
        });
        const input = payload.data || (() => {});
        const afterAction = payload.afterAction || (() => {});
        console.log('@383', payload);
        const serviceArgs = [updateHomeBanners, input];

        function* onSuccess(data) {
          yield put({
            type: 'updateState',
            payload: { createStatus: APIStatus.success },
          });
          yield afterAction();
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    createOrUpdateBanner: [
      function* ({ payload }, { put, select }) {
        yield put({
          type: 'updateState',
          payload: { createStatus: APIStatus.calling },
        });

        const languages = yield select((state) => state.language.pagedList);

        const afterAction = payload.afterAction || (() => {});
        const isCreate = payload.isCreate;
        const banner = payload.data || {};
        const input = getBannerForCreateOrUpdate(banner, isCreate, languages);
        const service = isCreate ? createHomeBanners : updateHomeBanners;

        const serviceArgs = [service, input];
        function* onSuccess(data) {
          yield put({
            type: 'updateState',
            payload: { createStatus: APIStatus.success, formChanged: false },
          });
          yield afterAction();
          removeFromSessionStorage(BANNER_SESSION_KEY);
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    duplicate: [
      function* ({ payload }, { put, select }) {
        const serviceArgs = [
          duplicateObjects,
          {
            id: payload.data.pk,
            modelName: 'HomepageBanner',
            displayPriority: payload.data.order || 1,
          },
        ];

        function* onSuccess(data) {
          if (payload.afterAction) {
            yield payload.afterAction();
          }
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    delete: [
      function* ({ payload }, { put, select }) {
        const afterAction = payload.afterAction || (() => {});
        const checkedList = yield select(
          (state) => state.bannerList.checkedList,
        );
        const ids = checkedList.map((item) => item.pk);
        const serviceArgs = [deleteHomeBanners, ids];
        function* onSuccess(data) {
          yield afterAction();
        }
        yield yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
    ],

    getOneBanner: [
      function* ({ payload }, { put }) {
        yield put({
          type: 'updateState',
          payload: { loadingStatus: APIStatus.calling },
        });
        const afterAction = payload.afterAction || (() => {});
        const bannerId = convertPKToId('HomepageBannerNode', payload.id);
        const serviceArgs = [getOneHomeBanner, bannerId];
        function* onSuccess(data) {
          yield put({
            type: 'assembleBanner',
            payload: { banner: data.homepageBanner },
          });

          yield put({
            type: 'updateState',
            payload: {
              hasUpdatedDefaultValues: true,
            },
          });

          yield afterAction();
        }
        yield yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
    ],
  },
};
