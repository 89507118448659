import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { connect } from 'react-redux';
import AuthButton from '../../../components/base/AuthButton';
import ContentSections from '../../../components/base/ContentSections';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { createAction } from '../../../utils';
import Loading from '../../../components/base/Loading';
import { LanguageConfig, AdminActivityType } from '../../../config/CustomEnums';
import BaseOverView from '../../../components/coupon/couponList/BaseOverview';
import { Link } from 'react-router-dom';
import BasePrompt from '../../../components/base/prompt/BasePrompt';
import StepFourPropertiesSection from '../../../components/goodieBags/StepFourPropertiesSection';
import StepFourEntitlementSection from '../../../components/goodieBags/StepFourEntitlementSection';
import './GoodieBagDetail.scss';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import StepFourShowFilterSection from '../../../components/goodieBags/StepFourShowFilterSection';
import { GOODIE_BAG_REWARD_TYPE_OPTIONS } from '../../../models/GoodieBagsModel';
import StepFourOutOfStockSection from '../../../components/goodieBags/StepFourOutOfStockSection';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';

const GoodieBagDetail = ({
  detail = {},
  dispatch,
  languages,
  selectedAllListLoading,
}) => {
  const history = useHistory();
  const params = useParams();
  const { id } = params;

  useEffect(() => {
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
    dispatch(createAction('goodieBags/getOneGoodieBag')({ id }));
    return () => {
      dispatch(createAction('goodieBags/clearData')());
    };
  }, []);

  const renderTabForLanguage = (language) => {
    const name = detail.translations?.[language]?.name;
    const shortDescription = detail.translations?.[language]?.shortDescription;
    const shortDescriptionInCampaignListingPage =
      detail.translations?.[language]?.shortDescriptionInCampaignListingPage;
    const coverImage = detail.translations?.[language]?.coverPhoto;
    const mobilePhoto = detail.translations?.[language]?.mobilePhoto;
    const genericTermsAndConditionsTitle =
      detail.translations?.[language]?.genericTermsAndConditionsTitle;
    const genericTermsAndConditionsContent =
      detail.translations?.[language]?.genericTermsAndConditionsContent;

    const cashVoucherName = detail.translations?.[language]?.cashVoucherName;
    const limitedOfferName = detail.translations?.[language]?.limitedOfferName;

    const othersTitleValueList = (language) => {
      let titleList = ['Set slot to landing after acquire', 'Selected slot'];
      let valueList = [
        detail.isSetLanding ? 'Yes' : 'No',
        detail.isSetLanding
          ? `Slot ${
              Object.keys(detail.slotNames).indexOf(detail.landingSlot) + 1
            } name: ${
              detail.slotNames[detail.landingSlot][language]?.name || '-'
            }`
          : '-',
      ];

      Object.keys(detail.slotNames || {}).forEach((key, index) => {
        titleList.push(`Slot ${index + 1} name`);
        valueList.push(
          `[Slot ID:${key.split('-')[2]}] ${
            detail.slotNames?.[key]?.[language]?.name
          }`,
        );
      });

      // switch (detail.rewardType) {
      //   case GOODIE_BAG_REWARD_TYPE_OPTIONS.cashVoucher.value:
      //     titleList = ['Cash voucher name'];
      //     valueList = [cashVoucherName];
      //     break;
      //   case GOODIE_BAG_REWARD_TYPE_OPTIONS.gift.value:
      //     titleList = ['Goodie bag coupon name'];
      //     valueList = [limitedOfferName];
      //     break;
      //   case GOODIE_BAG_REWARD_TYPE_OPTIONS.both.value:
      //     titleList = ['Cash voucher name', 'Goodie bag coupon name'];
      //     valueList = [cashVoucherName, limitedOfferName];
      //     break;
      //   default:
      //     titleList = ['Cash voucher name'];
      //     valueList = [cashVoucherName];
      //     break;
      // }

      return { titleList, valueList };
    };
    console.log('91: ', language, detail.translations?.[language]);
    const data = {
      ...detail.translations?.[language],
      isAgeRestricted: detail.isAgeRestricted ? 'Yes' : 'No',
      ageRestrictedPrimaryButtonName:
        !detail.translations?.[language]?.primaryButton1 &&
        !detail.translations?.[language]?.primaryButton2
          ? '-'
          : `${detail.translations?.[language]?.primaryButton1 || ''} ${
              detail.translations?.[language]?.primaryButton2 || ''
            }`.trim(),
      ageRestrictedSecondaryButtonName:
        !detail.translations?.[language]?.secondaryButton1 &&
        !detail.translations?.[language]?.secondaryButton2
          ? '-'
          : `${detail.translations?.[language]?.secondaryButton1 || ''} ${
              detail.translations?.[language]?.secondaryButton2 || ''
            }`.trim(),
    };

    return (
      <>
        <BaseOverView
          hideSection
          title="information"
          imageTitleNames={[
            'Cover Image for Desktop',
            'Cover Image for Mobile',
          ]}
          itemTitleList={[
            'Title',
            'Short Description for Goodie Bag Landing Page',
            detail.rewardType ===
            GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value
              ? null
              : 'Short Description for Cash Voucher / Goodie Bag Coupon Listing Page',
            'Cover Image for Desktop',
            'Cover Image for Mobile',
            'Generic Terms & Conditions Title',
            'Generic Terms & Conditions Content',
          ]}
          itemValueList={[
            name,
            shortDescription,
            GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value
              ? null
              : shortDescriptionInCampaignListingPage,
            coverImage,
            mobilePhoto,
            genericTermsAndConditionsTitle,
            genericTermsAndConditionsContent,
          ]}
        />
        {detail.rewardType ===
        GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value ? (
          <div style={{ marginTop: '30px' }}>
            <MembershipInfoCard
              title={'age restricted'}
              data={data}
              fields={[
                [
                  {
                    title: `Is age restricted`,
                    field: 'isAgeRestricted',
                  },
                ],
                [
                  {
                    title: `Age restricted Terms & Conditions title`,
                    field: 'ageRestrictedConditionsTitle',
                    isHtml: true,
                  },
                ],
                [
                  {
                    title: `Age restricted Terms & Conditions content`,
                    field: 'ageRestrictedConditionsContent',
                    isHtml: true,
                  },
                ],
                [
                  {
                    title: `Primary button`,
                    field: 'ageRestrictedPrimaryButtonName',
                  },
                ],
                [
                  {
                    title: `Secondary button`,
                    field: 'ageRestrictedSecondaryButtonName',
                  },
                ],
              ]}
            />
          </div>
        ) : (
          <BaseOverView
            hideSection
            title="Others"
            itemTitleList={othersTitleValueList(language)['titleList']}
            itemValueList={othersTitleValueList(language)['valueList']}
          />
        )}
      </>
    );
  };

  const sections = [
    <StepFourPropertiesSection data={detail} isDetail={true} />,
    ...(detail.rewardType !== GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value
      ? [
          <StepFourShowFilterSection data={detail} />,
          <StepFourOutOfStockSection data={detail} />,
        ]
      : []),
    <StepFourEntitlementSection data={detail} fromDetail={true} />,
  ];

  const tabs = [
    {
      name: 'Detail',
      content:
        detail.pk && !selectedAllListLoading ? (
          <div className="scroll-container-common">
            <ContentSections
              hidePreview
              languageTabContent={{
                containers: languages?.map((item) => ({
                  container: [renderTabForLanguage(item.code)],
                  key: item.code,
                  title: item.sourceName,
                })),
              }}
            />
            <ContentSections sections={sections} hidePreview={true} />
          </div>
        ) : (
          <Loading />
        ),
    },
  ];

  const getButtons = () => {
    const buttons = [
      <AuthButton
        title="Edit"
        action={() => {
          history.push({
            pathname: 'edit/',
          });
        }}
        requires={PermissionCodes.changeGoodieBag}
      />,
    ];

    if (id && detail?.isPublished) {
      buttons.unshift(
        <AuthButton
          title="Entitlement"
          customClass="btn-further goodie-bag-entitlement-button"
          action={() => {
            history.push({
              pathname: `/goodie_bags/${id}/entitlement/`,
              state: {
                goodieBagName: detail?.name,
                goodieBagMethod: detail?.entitlementMethod,
              },
            });
            dispatch({
              type: 'adminActivityLog/createAdministratorActivityLog',
              payload: {
                activityType: AdminActivityType.VIEW_ENTITLEMENT.pk,
                objectId: id,
              },
            });
          }}
          requires={[
            PermissionCodes.viewGoodiebagEntitlement,
            PermissionCodes.changeGoodiebagEntitlement,
          ]}
        />,
      );
    }

    return buttons;
  };

  return (
    <>
      <CustomListComponent
        caution={{
          detail: '',
          title: detail?.name,
        }}
        defaultActiveKey={tabs[0].name}
        breadcrumb={<CustomBreadcrumb name={detail?.name} />}
        buttons={getButtons()}
        tabs={tabs}
      />
      <SaveAndBackButtons
        saveTempText={''}
        saveText={'Preview'}
        saveAction={() => {
          const domain = process.env.REACT_APP_FRONTEND_LINK;
          const link =
            detail.rewardType ===
            GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value
              ? `${domain}/en/goodie-bag-detail?goodie_bag_id=${detail.pk}&tester_preview=true`
              : `${domain}/en/my-reward?tester_preview=true`;
          window.open(link);
        }}
        saveTempAction={() => {}}
        baseTempSaveArea="goodie-bag-preview-area"
      />
    </>
  );
};

const mapPropsToState = (state) => ({
  detail: state.goodieBags.detail,
  languages: state.language.allList,
  selectedAllListLoading: state.goodieBags.selectedAllListLoading,
});
export default connect(mapPropsToState)(GoodieBagDetail);
