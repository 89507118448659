import React, { useState, useEffect, useRef } from 'react';
import './Header.scss';
import {
  Image,
  OverlayTrigger,
  Popover,
  Button,
  Overlay,
  Collapse,
} from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import settingIcon from '../../assets/images/setting_icon.svg';
import usePhoto from '../../assets/images/userPhoto.jpg';
import downloadIcon from '../../assets/images/download.svg';
import closeIcon from '../../assets/images/close.svg';
import taskUnio from '../../assets/images/taskUnio.svg';
import { withRouter, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { CustomStatusTagFromText } from '../../components/base/CustomStatusTag';
import { DownloadTag } from '../../config/CustomEnums';
import { createAction, checkHasPermission } from '../../utils';
import { CustomTitleLabel } from '../../components/earning/CustomBaseComponments';
import LogoutBackButton from '../../assets/images/logout_dropdown.svg';
import OpenMenuBtn from '../../assets/images/open-menu-btn.svg';
import AuthButton from '../../components/base/AuthButton';
import { PermissionCodes } from '../../config/PermissionCodes';
import { useHistory } from 'react-router-dom';
import { CalendarContainer } from 'react-datepicker';
import CustomTips from '../base/CustomTips';
import ListFilterComponent from '../base/ListFilterComponent';
import { ImportResource } from '../../models/DownloadImportModel';

const SYSTEM_TASK_TABS = {
  GENERAL: 'General',
  SECHEDULE: 'Scheduled',
};

function Header(props) {
  // const [showTasks, setShowTasks] = useState(false);
  const [isOpenLogoutDropdown, setIsOpenLogoutDropdown] = useState(false);
  const [selectedTab, setSelectedTab] = useState(SYSTEM_TASK_TABS.GENERAL);

  const [search, setSearch] = useState({
    [SYSTEM_TASK_TABS.GENERAL]: '',
    [SYSTEM_TASK_TABS.SECHEDULE]: '',
  });

  const dispatch = useDispatch();
  const target = useRef(null);
  const history = useHistory();
  const {
    user,
    systemTasks,
    downloadingCount,
    scheduledDownloadingCount,
    showTasks,
    secheduledTask,
    isLoginBySaml,
  } = useSelector((state) => ({
    user: state.users,
    systemTasks: state.downloadAndImport.systemTasks,
    downloadingCount: state.downloadAndImport.downloadingCount,
    scheduledDownloadingCount:
      state.downloadAndImport.scheduledDownloadingCount,
    showTasks: state.downloadAndImport.showTasks,
    secheduledTask: state.downloadAndImport.secheduledTask,
    isLoginBySaml: state.users.isLoginBySaml,
    // secheduledTask: state.downloadAndImport.secheduledTask || [
    //   {
    //     displayStatus: 'Scheduled import',
    //     errors: null,
    //     fileName: 'Campaign_performance_report list_20220511092655.xlsx',
    //     fileUrl:
    //       'https://nginx.hktb.motherapp.cn:7543/media/19933888-0c66-4aa6-a83e-dd49f1d546c2.xlsx',
    //     id: 'U3lzdGVtVGFza05vZGU6MzE3',
    //     name: '[Import] Campaign_performance_report list_20220511092655.xlsx',
    //     pk: 317,
    //     resource: 'CAMPAIGN_PERFORMANCE_REPORT',
    //     resultFileUrl: null,
    //     status: 'START_UP',
    //     type: 'EXPORT_JOB',
    //   },
    // ],
  }));

  const [data, setData] = useState(systemTasks);

  const tasksIncludeSearch = (item) =>
    item.name?.toLowerCase().includes(search[selectedTab]?.toLowerCase());
  const logout = () => {
    if (isLoginBySaml) {
      dispatch({ type: 'users/logout' });
    } else {
      history.push({ pathname: '/login', state: { isLogout: true } });
    }
  };

  const hasSystemTaskInfoPermission =
    checkHasPermission(user, PermissionCodes.addExportjob) ||
    checkHasPermission(user, PermissionCodes.addImportjob);

  useEffect(() => {
    const interval = setInterval(() => {
      console.log('This will run every 2 mins!');
      if (hasSystemTaskInfoPermission) {
        dispatch(createAction('downloadAndImport/getSystemTasks')({}));
        dispatch(createAction('downloadAndImport/getScheduledTasks')({}));
      }
    }, 1000 * 60 * 2);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (selectedTab === SYSTEM_TASK_TABS.GENERAL) {
      setData(systemTasks?.filter(tasksIncludeSearch));
    } else {
      setData(secheduledTask?.filter(tasksIncludeSearch));
    }

    // setData(data?.filter(tasksIncludeSearch));
  }, [search]);

  useEffect(() => {
    console.log('@@81');
    if (selectedTab === SYSTEM_TASK_TABS.GENERAL) {
      setData(systemTasks?.filter(tasksIncludeSearch));
    } else {
      setData(secheduledTask?.filter(tasksIncludeSearch));
    }
  }, [selectedTab]);

  useEffect(() => {
    if (selectedTab === SYSTEM_TASK_TABS.GENERAL) {
      setData(systemTasks?.filter(tasksIncludeSearch));
    }
  }, [systemTasks]);

  useEffect(() => {
    if (selectedTab !== SYSTEM_TASK_TABS.GENERAL) {
      setData(secheduledTask?.filter(tasksIncludeSearch));
    }
  }, [secheduledTask]);

  const getCancelOrDownload = (item) => {
    const showResultDownload =
      item?.errors?.numberOfFailed ||
      (item?.resultFileUrl && item.resource === ImportResource.trackingCode);
    if (showResultDownload && item.fileUrl) {
      return (
        <a
          href={item.resultFileUrl}
          className="download-cancel-button download-button"
        >
          Result-Download
        </a>
      );
    }
    switch (item.displayStatus) {
      case DownloadTag.pendingForExport:
      case DownloadTag.pendingForGenerate:
      case DownloadTag.pendingForImport:
      case DownloadTag.downloading:
      case DownloadTag.importing:
      case DownloadTag.generating:
        return (
          <button
            type="button"
            className="btn-link reset-button download-cancel-button "
            onClick={() => {
              dispatch(
                createAction('downloadAndImport/cancelTask')({
                  pk: item.pk,
                  type: item.type,
                }),
              );
            }}
          >
            Cancel
          </button>
        );
      case DownloadTag.scheduledImport:
        return (
          <div className="schedule-btns">
            <button
              type="button"
              className="btn-link reset-button download-cancel-button download-schedule-button download-schedule-startup-button "
              onClick={() => {
                dispatch(
                  createAction('downloadAndImport/updateTask')({
                    pk: item.pk,
                    type: item.type,
                    status: 'PENDING',
                  }),
                );
              }}
            >
              Start up
            </button>
            <button
              type="button"
              className="btn-link reset-button download-cancel-button download-schedule-button download-schedule-cancel-button "
              onClick={() => {
                dispatch(
                  createAction('downloadAndImport/cancelTask')({
                    pk: item.pk,
                    type: item.type,
                  }),
                );
              }}
            >
              Cancel
            </button>
          </div>
        );
      case DownloadTag.completed:
        return (
          <a
            href={item.fileUrl}
            target="_blank"
            className="download-cancel-button download-button"
          >
            Download
          </a>
        );
      default:
        return null;
    }
  };

  const generateTypes = [
    'GENERATE_CODE_COUPON',
    'GENERATE_GENERIC_COUPON',
    'GENERATE_QR_CODE_COUPON',
    'GENERATE_IMAGE_COUPON',
  ];

  const resultTag = (item, isSuccess) => {
    const errors = item.errors;
    const resource = item.resource;
    const type = item.type;
    const status = item.status;
    const successCount = errors?.numberOfImported;
    const failedCount = errors?.numberOfFailed;
    if (!successCount && !failedCount) {
      return null;
    }
    if (type === 'EXPORT_JOB') {
      return null;
    }
    const count = isSuccess ? successCount : failedCount;
    const extraStyle = isSuccess ? 'result-success-item' : 'result-failed-item';
    let title = '';
    if (status === 'COMPLETED') {
      title = 'Imported';
    }
    if (generateTypes.includes(resource) && status === 'COMPLETED') {
      title = 'Generated';
    }
    if (!isSuccess) {
      title = 'Failed';
    }
    if (type === 'CANCELLED') {
      title = 'Cancelled';
    }
    if (!title) {
      return null;
    }
    return (
      <label className={`result-common ${extraStyle}`}>
        •{title}:{count || 0}
      </label>
    );
  };

  const downloadIconClassName = (item) => {
    if (item?.couponLink) {
      return 'download-icon-cursor';
    }
  };

  const downloadIconOnClick = (item) => {
    if (item?.couponLink) {
      return () => {
        history.push(item.couponLink);
      };
    }
  };

  const downloadLine = (item, showBorder) => {
    const successResult = resultTag(item, true);
    const errorResult = resultTag(item);
    const showOneLine = successResult || errorResult;

    return (
      <>
        <div className="system-task-row">
          <div>
            <Image
              src={taskUnio}
              className={`download-icon ${downloadIconClassName(item)}`}
              onClick={downloadIconOnClick(item)}
            />
            <div>
              <label
                className={`download-title ${
                  showOneLine
                    ? 'download-title-line-1'
                    : 'download-title-line-2'
                }`}
              >
                {item.name || '-'}
              </label>
              <div className="system-task-result-container">
                {successResult}
                {errorResult}
              </div>
            </div>
            <div
              className={`d-flex flex-column ${
                item.status !== DownloadTag.cancelled
                  ? 'system-task-row-status-downloading'
                  : 'system-task-row-status'
              } `}
            >
              {CustomStatusTagFromText(
                item.displayStatus,
                'system-task-row-status-label',
              )}
              {getCancelOrDownload(item)}
            </div>
          </div>
        </div>
        {showBorder ? <div className="system-task-row-border" /> : null}
      </>
    );
  };

  const tabChange = (tabKey) => {
    setSelectedTab(tabKey);
  };

  return (
    <div className="header-container">
      {/* <Image src={settingIcon} className="setting-icon" />
      <label className="header-text">Setting</label> */}
      {props.openMenu ? null : (
        <Image
          src={OpenMenuBtn}
          alt=""
          style={{ marginRight: 'auto', marginLeft: '40px', cursor: 'pointer' }}
          onClick={props.closeMenuAction}
        />
      )}
      <div
        onMouseEnter={() => setIsOpenLogoutDropdown(true)}
        onMouseLeave={() => setIsOpenLogoutDropdown(false)}
        className="header-user-area"
      >
        <div className="header-user-area-photo-area">
          <Image src={usePhoto} className="uesr-photo" />
          <Collapse in={isOpenLogoutDropdown}>
            <div className="header-user-area-logout-dropdown" onClick={logout}>
              <div className="header-user-area-logout-dropdown-container">
                <Image
                  className="header-user-area-logout-image"
                  src={LogoutBackButton}
                />
                <label className="header-user-area-logout-text">LOGOUT</label>
              </div>
            </div>
          </Collapse>
        </div>
        <label className="header-text">{user?.username}</label>
      </div>
      {hasSystemTaskInfoPermission ? (
        <button
          className="system-task-area"
          ref={target}
          onClick={() => {
            dispatch(createAction('downloadAndImport/showTasks')({}));
            dispatch(createAction('downloadAndImport/getSystemTasks')({}));
            dispatch(createAction('downloadAndImport/getScheduledTasks')({}));
          }}
        >
          <Image src={downloadIcon} className="download-icon" />
          <label className="header-text">System task</label>
          {downloadingCount > 0 || scheduledDownloadingCount > 0 ? (
            <label className="badge-icon">
              {downloadingCount || scheduledDownloadingCount}
            </label>
          ) : null}
        </button>
      ) : null}
      <Overlay
        target={target.current}
        show={showTasks}
        placement="bottom"
        rootClose
        onHide={() => {
          dispatch(createAction('downloadAndImport/hideTasks')({}));
        }}
      >
        {(props) => (
          <div
            {...props}
            className="overlay-trigger"
            style={{
              // backgroundColor: '#ffffff',
              padding: '2px 10px',
              color: 'white',
              borderRadius: 3,
              ...props.style,
              zIndex: 3,
              height: 'calc(100vh - 60px)',
            }}
          >
            <Popover
              id={`popover-positioned-bottom`}
              style={{}}
              arrowProps={null}
              bsClass="systemTaskPopover"
            >
              <Popover.Title>
                <div className="system-task-header">
                  <label className="system-task">System task</label>

                  <button
                    className="custom-image-button margin-left-auto"
                    onClick={() => {
                      dispatch(createAction('downloadAndImport/hideTasks')({}));
                    }}
                  >
                    <Image src={closeIcon} className="system-task-close" />
                  </button>
                </div>
              </Popover.Title>
              <Popover.Content>
                <div className="tabs">
                  <div
                    className={`tabs-tab ${
                      selectedTab === SYSTEM_TASK_TABS.GENERAL
                        ? 'tab-selected'
                        : ''
                    }`}
                    onClick={() => tabChange(SYSTEM_TASK_TABS.GENERAL)}
                  >
                    <label>{SYSTEM_TASK_TABS.GENERAL}</label>
                    <label
                      className={`${
                        downloadingCount > 0 ? 'task-badge-icon' : ''
                      }`}
                    ></label>
                  </div>
                  <div
                    className={`tabs-tab ${
                      selectedTab === SYSTEM_TASK_TABS.SECHEDULE
                        ? 'tab-selected'
                        : ''
                    }`}
                    onClick={() => tabChange(SYSTEM_TASK_TABS.SECHEDULE)}
                  >
                    <label style={{}}>
                      {SYSTEM_TASK_TABS.SECHEDULE}
                      <CustomTips
                        customClass={{ area: 'system-task-tab-tips' }}
                        detail={
                          'Tasks with status "Scheduled import" will work from 00:00 AM to 5:00 AM'
                        }
                        placement="bottom"
                      ></CustomTips>
                    </label>
                    <label
                      className={`${
                        scheduledDownloadingCount > 0 ? 'task-badge-icon' : ''
                      }`}
                    ></label>
                  </div>
                </div>
                <div className="filter-search">
                  <ListFilterComponent
                    index={SYSTEM_TASK_TABS.GENERAL}
                    onSearch={(key) => {
                      setSearch({ ...search, [selectedTab]: key });
                    }}
                    customClass={`${
                      selectedTab === SYSTEM_TASK_TABS.GENERAL
                        ? 'showTab'
                        : 'hideTab'
                    }`}
                  />
                  <ListFilterComponent
                    index={SYSTEM_TASK_TABS.SECHEDULE}
                    onSearch={(key) => {
                      setSearch({ ...search, [selectedTab]: key });
                    }}
                    customClass={`${
                      selectedTab === SYSTEM_TASK_TABS.SECHEDULE
                        ? 'showTab'
                        : 'hideTab'
                    }`}
                  />
                </div>
                {data?.length > 0 ? (
                  <PerfectScrollbar>
                    <div className="system-task-content">
                      {data.map((item, index) => {
                        return downloadLine(item, index < data.length - 1);
                      })}
                    </div>
                  </PerfectScrollbar>
                ) : (
                  <div className="system-task-no-content">
                    <CustomTitleLabel title="No task here !" />
                    <label className="tips-message">
                      No task here ! There is no uploaded or downloaded task at
                      the moment
                    </label>
                  </div>
                )}
              </Popover.Content>
            </Popover>
          </div>
        )}
      </Overlay>
    </div>
  );
}

export default withRouter(Header);
