import React, { useEffect, useState } from 'react';
import './CampaignList.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { campaignListBreads } from '../../../config/CustomBreadConfig';
import {
  getHashKeyString,
  createAction,
  copyCampaignLink,
  checkHasPermission,
} from '../../../utils';
import {
  URLFragments,
  URLFilterKeys,
} from '../../../components/campaign/CampaignUrlConfig';
import {
  ADMIN_TYPE,
  CampaignType,
  DELETE_RELATED_SECTIONS,
} from '../../../config/CustomEnums';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import BaseTabListContainer from '../../../containers/base/BaseTabListContainer';
import BaseListContainer from '../../../containers/base/BaseListContainer';
import DeleteCampaignPrompt from '../../../components/campaign/campaignList/DeleteCamapignPrompt';
import queryString from 'query-string';
import BasePrompt from '../../../components/base/prompt/BasePrompt';
import Filter from './Filter';
import FortuneBagsAllocationPrompt from '../../../components/campaign/campaignCreation/FortuneBagsAllocationPrompt';

import { CustomDropDownButton } from '../../../components/base/CustomDropDownButton';
import { CustomImportFilePrompt } from '../../../components/base/CustomImportFilePrompt';
import { CustomBatchDownloadPrompt } from '../../../components/base/CustomBatchDownloadPrompt';
import { ImportResource } from '../../../models/DownloadImportModel';
import FilterExportPrompt from '../../../components/base/prompt/FilterExportPrompt';

import StarCamapignSettingPrompt from '../../../components/campaign/campaignList/StarCamapignSettingPrompt';
import WechatFilterPrompt from '../../../components/campaign/campaignList/WechatFilterPrompt';
import OutOfStockPrompt from '../../../components/campaign/campaignList/OutOfStockPrompt';

const BATCH_IMPORT_CAMPAIGN_TEMPLATE_LINK =
  process.env.REACT_APP_BATCH_IMPORT_CAMPAIGN_TEMPLATE_LINK || '';

const TabsConfig = [
  { key: CampaignType.allTypes, name: 'All Types' },
  { key: CampaignType.couponsCampaign, name: 'Coupon' },
  { key: CampaignType.generalMessageCampaign, name: 'General Message' },
  // { key: CampaignType.fortuneBagCampaign, name: 'Fortune Bag Campaign' },
  { key: CampaignType.cashVoucherCampaign, name: 'Cash Voucher' },
  { key: CampaignType.giftCampaign, name: 'Goodie Bag Coupon' },
  {
    key: CampaignType.fortuneBagForGoodieBagCampaign,
    name: 'Goodie bag offer (Direct assign)',
  },
];

function CampaignList() {
  const dispatch = useDispatch();
  const [showPrompt, setShowPrompt] = useState(false);
  const [showUploadPrompt, setShowUploadPrompt] = useState(false);
  const [showDownloadPrompt, setShowDownloadPrompt] = useState(false);
  const [showExportPrompt, setShowExportPrompt] = useState(false);
  // const [firstTimePickerChange, setFirstTimePickerChange] = useState(true);

  const history = useHistory();
  const location = useLocation();
  const activeTab = URLFragments.list;
  const {
    listFields,
    dataList,
    pageInfo,
    totalPage,
    totalCount,
    resume,
    user,
  } = useSelector((state) => ({
    listFields: state.campaignList.listFields,
    dataList: state.campaignList.pagedList,
    pageInfo: state.campaignList.pageInfo,
    totalPage: state.campaignList.totalPage,
    totalCount: state.campaignList.totalCount,
    checkedList: state.campaignList.checkedList,
    isSuperuser: state.users.isSuperuser,
    userPermissions: state.users.userPermissions,
    resume: state.campaignList.resume,
    user: state.users,
  }));

  const [showSetAllocatePrompt, setShowSetAllocatePrompt] = useState(false);
  const [publishFortunebag, setPublishFortunebag] = useState(null);
  const [showStarSetting, setShowStarSetting] = useState(null);
  const [showWechatFilter, setShowWechatFilter] = useState(null);
  const [showOutOfStock, setShowOutOfStock] = useState(null);

  const getFilterConditionsFromUrl = () => {
    const parsedSearch = queryString.parse(location.search);
    const searchKey = parsedSearch['search'] || '';
    const searchType = parsedSearch['type'] || '';
    const isStarred = parsedSearch['starred_campaign'] || '';
    const searchPublishStatus = parsedSearch['status'] || '';
    const searchExpiredStatus = parsedSearch['expired'] || '';
    const searchMessageChannel = parsedSearch['message_channel'] || '';
    const searchBrand = parsedSearch['brand'] || '';
    const searchActivePeriod = parsedSearch['active_period'] || '';
    const searchVisablePeriod = parsedSearch['visible_period'] || '';

    // const searchTargetCustomer = parsedSearch['target_customer'] || '';
    // const searchGroups = parsedSearch['groupsIn'] || '';
    // const searchSegments = parsedSearch['segmentsIn'] || '';
    // const searchLevels = parsedSearch['levelsIn'] || '';
    const searchIsRedirect = parsedSearch['redirection_campaign'] || '';
    const searchGoodie = parsedSearch['for_goodie_bag'] || '';
    const searchAttributeTag = parsedSearch['tags'] || '';
    const searchAvailableCoupon = parsedSearch['available_coupon'] || '';
    const searchUsedCoupon = parsedSearch['used_coupon'] || '';
    const searchAcquiredCoupon = parsedSearch['acquired_coupon'] || '';
    const searchGeneratedCoupon = parsedSearch['generated_coupon'] || '';
    const searchWorkingTeam = parsedSearch['working_team'] || '';

    return {
      name_icontains: searchKey,
      type: searchType === CampaignType.allTypes ? '' : searchType,
      is_published: searchPublishStatus,
      is_expired: searchExpiredStatus,
      publish_channel_in: searchMessageChannel,
      brand_in: searchBrand,
      active_time_range: searchActivePeriod,
      display_time_range: searchVisablePeriod,
      is_redirection_campaign: searchIsRedirect,
      goodie_bag_in: searchGoodie,
      attribute_tags_in: searchAttributeTag,
      has_available_coupon: searchAvailableCoupon,
      has_used_coupon: searchUsedCoupon,
      has_acquired_coupon: searchAcquiredCoupon,
      has_generated_coupon: searchGeneratedCoupon,
      is_starred: isStarred,
      working_teams_in_and_null: searchWorkingTeam,
    };
  };

  const getFilterMapFromUrl = () => {
    const querySearch = queryString.parse(location.search);
    const type =
      queryString.parse(location.search)[URLFilterKeys.type] ||
      CampaignType.allTypes;
    const searchKey = queryString.parse(location.search)[
      URLFilterKeys.searchKey
    ];
    const stringRank = queryString.parse(location.search)[URLFilterKeys.rank];
    const stringPage =
      queryString.parse(location.search)[URLFilterKeys.page] || 1;
    const rank = stringRank === 'false';
    const page = parseInt(stringPage);

    return {
      ...querySearch,
      page: page,
      type: type,
      reverse: !rank,
      searchKey: searchKey,
    };
  };

  const getList = () => {
    const data = getFilterMapFromUrl();
    dispatch(
      createAction('campaignList/getCampaignList')({
        ...data,
        useStock: true,
        getAll: 'false',
      }),
    );
  };

  useEffect(() => {
    if (activeTab === URLFragments.list) {
      getList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, location]);

  useEffect(() => {
    if (
      checkHasPermission(
        user,
        PermissionCodes.changeStarredCampaignLimitSettings,
      )
    ) {
      dispatch({ type: 'campaignList/getStarLimit' });
    }

    dispatch({ type: 'campaignList/campaignResume' });

    return () => {
      dispatch({ type: 'campaignList/clearData' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getButton = (name, className) => {
    return (
      <AuthButton
        title={name}
        className={className}
        action={() => {
          dispatch({ type: 'createCampaign/clearData' });
          history.push({
            pathname: '/campaigns/create',
          });
        }}
        requires={PermissionCodes.addCampaign}
      />
    );
  };

  const buttons = [
    <div class="batch-manager">
      <CustomDropDownButton
        title={'Batch manage'}
        items={[
          ...(user.adminType === ADMIN_TYPE.HKTB_ADMIN
            ? [
                {
                  title: 'Download Template',
                  action: () => setShowDownloadPrompt(true),
                },
                {
                  title: 'Import Campaign',
                  action: () => {
                    setShowUploadPrompt(true);
                  },
                },
              ]
            : []),
          {
            title: 'Export Existing Campaign',
            action: () =>
              // dispatch(
              //   createAction('downloadAndImport/createDownloadTask')({
              //     from: ImportResource.campaign,
              //   }),
              // ),
              setShowExportPrompt(true),
          },
        ]}
      />
    </div>,
    getButton('Create Campaign', 'btn-further'),
    <CustomDropDownButton
      title={'Manage WeChat campaign list'}
      customClass={'wechat-filter'}
      items={[
        {
          title: 'Manage Filter',
          action: () => setShowWechatFilter(true),
        },
        {
          title: 'Out-Of-stock campaign',
          action: () => setShowOutOfStock(true),
        },
      ]}
    />,
    activeTab === URLFragments.list ? (
      <AuthButton
        title={'Starred campaign limit settings'}
        className={'btn-back-button-common star-setting'}
        action={() => {
          setShowStarSetting(true);
        }}
        // disabled={}
        requires={PermissionCodes.changeStarredCampaignLimitSettings}
      />
    ) : null,
  ];

  if (resume.showResumeButton) {
    buttons.unshift(getButton('Resume', 'campaign-resume-button btn-further'));
  }

  const groupActions = [
    {
      name: 'Delete',
      action: (campaign) => setShowPrompt(true),
      requires: PermissionCodes.deleteCampaign,
    },
  ];

  const tabs = [
    {
      name: URLFragments.list,
      content: (
        <BaseTabListContainer
          hideTab={false}
          maxTabWidth={400}
          tabs={TabsConfig}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount}
          filter={{
            hasFilter: true,
            componment: Filter,
          }}
          listContent={
            <BaseListContainer
              fields={listFields}
              dataList={dataList}
              totalPage={totalPage}
              model={'campaignList'}
              specificActions={[
                {
                  condition: (item) => {
                    return (
                      item.type === CampaignType.fortuneBagCampaign &&
                      item?.isPublished
                    );
                  },
                  buttons: [
                    {
                      name: 'View entitlement',
                      action: (item) => {
                        history.push(`/campaigns/${item.pk}/edit/entitlement/`);
                      },
                      requires: [
                        PermissionCodes.viewFortunebagEntitlement,
                        PermissionCodes.changeFortunebagEntitlement,
                      ],
                    },
                  ],
                },
                {
                  condition: (item) => {
                    return item.accessCampaignCopyUrl;
                  },
                  buttons: [
                    {
                      name: 'Copy URL',
                      action: (item) => {
                        copyCampaignLink(dispatch, item.pk);
                      },
                      requires: [
                        PermissionCodes.viewCampaign,
                        PermissionCodes.changeCampaign,
                      ],
                    },
                  ],
                },
                {
                  condition: (item) => {
                    return (
                      item.type !== CampaignType.fortuneBagForGoodieBagCampaign
                    );
                  },
                  buttons: [
                    {
                      name: 'Duplicate',
                      action: (item) => {
                        dispatch(
                          createAction(`campaignList/duplicate`)({
                            data: item,
                            afterAction: () => history.push(location),
                          }),
                        );
                      },
                      requires: PermissionCodes.addCampaign,
                    },
                  ],
                },
              ]}
              actions={['Detail', 'Edit', 'Delete']}
              permissionGroup={PermissionCodes.campaign}
              deleteInfo={{
                data: [],
                title: 'Campaign',
                relatedName: '',
                onComfirm: {},
                relatedSections: DELETE_RELATED_SECTIONS.CAMPAIGN,
              }}
              hasOtherActions={true}
              customActions={{
                publish: {
                  condition: (item) => {
                    if (item.type === CampaignType.fortuneBagCampaign) {
                      if (!item.isPublished) {
                        return true;
                      }
                      return false;
                    } else {
                      return true;
                    }
                  },
                  action: (item) => {
                    console.log('@@256: ', item);
                    if (
                      item.type === CampaignType.fortuneBagCampaign &&
                      item.isOptionC &&
                      !item.isPublished &&
                      item.ota?.length > 1
                    ) {
                      dispatch({
                        type: `createCampaign/assembleCampaign`,
                        payload: { campaign: item },
                      });
                      setShowSetAllocatePrompt(true);
                      setPublishFortunebag({ ...item });
                    } else {
                      const type = item.isPublished
                        ? 'unPublishCampaign'
                        : 'doPublish';
                      dispatch(
                        createAction(`campaignList/publish`)({
                          campaignPk: item.pk,
                          isPublished: item.isPublished,
                          afterAction: () => getList(),
                        }),
                      );
                    }
                  },
                },
              }}
            />
          }
        />
      ),
    },
  ];

  // const tabs = [
  //   isSuperuser ||
  //   userPermissions?.filter(
  //     (val) => val.split('_')[1] === PermissionCodes.campaign,
  //   ).length > 0
  //     ?
  //     : {},
  // ];

  // if (tabs.length === 1 && activeTab === URLFragments.overview) {
  //   history.push({
  //     pathname: '/campaigns',
  //     hash: URLFragments.list,
  //     search: location.search,
  //   });
  // }

  const hideResumePop = (hide) => {
    dispatch({
      type: 'campaignList/resumeAction',
      payload: { hideResumePop: hide },
    });
  };

  return (
    <>
      <CustomListComponent
        caution={{
          detail: 'Campaign list, should edit with the following',
          title: 'Campaigns',
        }}
        // onTabChange={(key) => {
        //   let search = '';
        //   if (key !== URLFragments.overview) {
        //     search = queryString.stringify({
        //       [URLFilterKeys.type]: CampaignType.allTypes,
        //       [URLFilterKeys.page]: 1,
        //       [URLFilterKeys.searchKey]: '',
        //       [URLFilterKeys.rank]: true,
        //     });
        //   }
        //   history.push({ pathname: '/campaigns', hash: key, search: search });
        // }}
        defaultActiveKey={activeTab}
        breadcrumb={<CustomBreadcrumb breadcrumbs={campaignListBreads()} />}
        buttons={buttons}
        tabs={tabs}
        hideTab={false}
      />
      <DeleteCampaignPrompt
        show={showPrompt}
        onClose={() => setShowPrompt(false)}
        onConfirm={() => {
          setShowPrompt(false);
          dispatch(
            createAction('campaignList/delete')({
              afterAction: () => {
                history.push(location);
              },
            }),
          );
        }}
      />
      <BasePrompt
        show={
          resume.showResumePop &&
          user?.userPermissions?.includes('change_campaign')
        }
        closeAction={() => hideResumePop(false)}
        rightButton={{
          text: 'Resume',
          action: () => {
            hideResumePop(false);
            history.push('/campaigns/create');
          },
        }}
        leftButton={{
          text: `Dismiss and don't remind me again`,
          action: () => hideResumePop(true),
        }}
        title={'You have unfinished campaign '}
        description={`You have unfinished campaign before. You can resume it by click “Resume” button at the top of the page`}
      />

      <FortuneBagsAllocationPrompt
        show={showSetAllocatePrompt}
        closeAction={() => {
          setShowSetAllocatePrompt(false);
        }}
        nextAction={(otaAllocatedNum) => {
          setShowSetAllocatePrompt(false);
          dispatch(
            createAction(`campaignList/publish`)({
              campaignPk: publishFortunebag?.pk,
              isPublished: publishFortunebag?.isPublished,
              otaAllocatedNum,
              afterAction: () => getList(),
            }),
          );
        }}
        ota={publishFortunebag?.ota}
        campaign={publishFortunebag}
      />

      <CustomImportFilePrompt
        show={showUploadPrompt}
        title={'Import Campaign'}
        description={
          'If the imported campagin already existed, the existing campaign will be replaced.\nMake sure the coupon set has been imported before importing coupon campaign.'
        }
        onClose={() => setShowUploadPrompt(false)}
        accept={'.zip'}
        from={ImportResource.campaign}
      />

      <CustomBatchDownloadPrompt
        show={showDownloadPrompt}
        title={'Download form for import campaigns'}
        onClose={() => setShowDownloadPrompt(false)}
        fromModel="CAMPAIGN"
        source={[
          {
            title: 'Import template sheet',
            link: BATCH_IMPORT_CAMPAIGN_TEMPLATE_LINK,
            reference: [],
          },
          {
            title: 'ID reference sheet',
            downloadTitle: 'Download all',
            resource: ImportResource.campaignReference,
            reference: [
              { title: 'Working Teams', resource: ImportResource.workingTeam },
              { title: 'Coupon', resource: ImportResource.couponTemplate },
              { title: 'Goodie bags', resource: ImportResource.goodieBag },
              { title: 'Channels', resource: ImportResource.channel },
              {
                title: 'Target markets',
                resource: ImportResource.targetMarket,
              },
              { title: 'brands', resource: ImportResource.brand },
              { title: 'logo label', resource: ImportResource.logoLabel },
              { title: 'tour card', resource: ImportResource.tourCard },
              {
                title: 'Terms & Conditions',
                resource: ImportResource.termsAndConditionsTemplate,
              },
              {
                title: 'offer type labels',
                resource: ImportResource.offerTypeLabel,
              },
              {
                title: 'categories',
                resource: ImportResource.campaignCategory,
              },
              {
                title: 'attribute tags',
                resource: ImportResource.attributeTag,
              },
            ],
          },
        ]}
      />

      <FilterExportPrompt
        show={showExportPrompt}
        onClose={() => setShowExportPrompt(false)}
        title={'Export Campaign'}
        description={`The export format will be in "Import template sheet" format, which is compatible to import again.`}
        otherBody={{
          rangeTitle: 'Select the export range',
        }}
        exportSource={ImportResource.campaign}
        exportFilterConditions={getFilterConditionsFromUrl()}
      />
      <StarCamapignSettingPrompt
        show={showStarSetting}
        onClose={() => setShowStarSetting(false)}
      />

      <WechatFilterPrompt
        show={showWechatFilter}
        onClose={() => setShowWechatFilter(false)}
      />

      <OutOfStockPrompt
        show={showOutOfStock}
        onClose={() => setShowOutOfStock(false)}
      />
    </>
  );
}

export default CampaignList;
