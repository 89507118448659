import React, { useEffect, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CustomBreadcrumb from '../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../components/base/CustomListComponent';
import { campaignListBreads } from '../../config/CustomBreadConfig';
import CampaignOverviewTab from '../../components/campaign/campaignList/CampaignOverviewTab';
import { getHashKeyString, createAction } from '../../utils';
import { URLFragments } from '../../components/campaign/CampaignUrlConfig';

import DateRangeSelection from '../../components/dashboard/DateRangeSelection';
import DateRangeCompare from '../../components/dashboard/DateRangeCompare';

import { TimeFormater } from '../../utils/TimeFormatUtil';
import GoodiebagOverview from '../engagement/goodieBags/GoodiebagOverview';
import { CampaignType } from '../../config/CustomEnums';

function GoodiebagAnalytics() {
  const dispatch = useDispatch();
  // const [firstTimePickerChange, setFirstTimePickerChange] = useState(true);
  const [datePicker, setDatePicker] = useState();
  const [pickerGoodiebagOverviewDate, setPickerGoodiebagOverviewDate] = useState(null);

  const location = useLocation();
  const activeTab = getHashKeyString(location.hash) || '';
  const { goodiebagOverviewFilter, goodiebagOverviewDate } = useSelector(
    (state) => ({
      goodiebagOverviewFilter: state.overview.goodiebagOverviewFilter,
      goodiebagOverviewDate: state.overview.goodiebagOverviewDate,
    }),
  );
  const fromModel = 'GOODIE_BAG';

  useEffect(() => {
    const start = datePicker?.start;
    const end = datePicker?.end;
    if (start && end) {
      setPickerGoodiebagOverviewDate({
        startDate: start.format(TimeFormater.GTMFormat),
        endDate: end.format(TimeFormater.GTMFormat),
        startDateWithoutFormat: start,
        endDateWithoutFormat: end,
      })
    }
  }, [datePicker])

  useEffect(() => {
    return () => {
      dispatch({ type: 'overview/clearState' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (goodiebagOverviewDate?.startDate && goodiebagOverviewDate?.endDate) {
  //     const actionFilter = {
  //       fromModel: fromModel,
  //       startDate: goodiebagOverviewDate.startDate,
  //       endDate: goodiebagOverviewDate.endDate,
  //       goodiebagOverviewFilter: {
  //         goodiebags: goodiebagOverviewFilter?.goodiebag
  //           ?.map((item) => item.value?.pk)
  //           .join(','),
  //         isStarred: goodiebagOverviewFilter?.isStarred,
  //         cashcampaigns: goodiebagOverviewFilter?.cashCampaign?.value?.pk,
  //         giftcampaigns: goodiebagOverviewFilter?.giftCampaign?.value?.pk,
  //       },
  //     };
  //     dispatch(createAction('overview/getGoodiebagData')(actionFilter));

  //     let campaignTypes = [];
  //     if (goodiebagOverviewFilter?.cashCampaign?.value?.pk) {
  //       campaignTypes.push(CampaignType.cashVoucherCampaign);
  //     }
  //     if (goodiebagOverviewFilter?.giftCampaign?.value?.pk) {
  //       campaignTypes.push(CampaignType.giftCampaign);
  //     }

  //     dispatch(
  //       createAction('overview/getCampaignsData')({
  //         ...actionFilter,
  //         typeIn: campaignTypes,
  //       }),
  //     );

  //     dispatch(
  //       createAction('overview/getCashCampaignCouponData')(actionFilter),
  //     );
  //     dispatch(
  //       createAction('overview/getGiftCampaignCouponData')(actionFilter),
  //     );

  //     dispatch(createAction('overview/getGoodiebagFieldData')(actionFilter));
  //   }
  // }, [goodiebagOverviewFilter, goodiebagOverviewDate]);

  // useEffect(() => {
  //   if (goodiebagOverviewDate?.startDate && goodiebagOverviewDate?.endDate) {
  //     const actionFilter = {
  //       fromModel: fromModel,
  //       startDate: goodiebagOverviewDate.startDate,
  //       endDate: goodiebagOverviewDate.endDate,
  //       goodiebagOverviewFilter: {
  //         goodiebags: goodiebagOverviewFilter?.goodiebag
  //           ?.map((item) => item.value?.pk)
  //           .join(','),
  //         isStarred: goodiebagOverviewFilter?.isStarred,
  //         cashcampaigns: goodiebagOverviewFilter?.cashCampaign?.value?.pk,
  //         giftcampaigns: goodiebagOverviewFilter?.giftCampaign?.value?.pk,
  //       },
  //     };

  //     if (
  //       !goodiebagOverviewFilter?.goodiebag ||
  //       goodiebagOverviewFilter?.goodiebag?.length > 1
  //     )
  //       dispatch(
  //         createAction('overview/getGoodiebagRankingTable')(actionFilter),
  //       );

  //     if (!goodiebagOverviewFilter?.cashCampaign?.value?.pk) {
  //       dispatch(
  //         createAction('overview/getCampaignsRankingTable')({
  //           ...actionFilter,
  //           campaginTypes: [CampaignType.cashVoucherCampaign],
  //           key: 'cashCampaignRankingTable',
  //         }),
  //       );
  //     }
  //     if (!goodiebagOverviewFilter?.giftCampaign?.value?.pk) {
  //       dispatch(
  //         createAction('overview/getCampaignsRankingTable')({
  //           ...actionFilter,
  //           campaginTypes: [CampaignType.giftCampaign],
  //           key: 'giftCampaignRankingTable',
  //         }),
  //       );
  //     }
  //   }
  // }, [goodiebagOverviewFilter, goodiebagOverviewDate]);

  const requestData = (start, end) => {
    if (!start || !end) {
      return;
    }
    dispatch({ type: 'overview/clearStateExcludeFilter' });
    dispatch(
      createAction('overview/updateState')({
        goodiebagOverviewDate: {
          startDate: start.format(TimeFormater.GTMFormat),
          endDate: end.format(TimeFormater.GTMFormat),
          startDateWithoutFormat: start,
          endDateWithoutFormat: end,
        },
      }),
    );

    const actionFilter = {
      fromModel: fromModel,
      startDate: start.format(TimeFormater.GTMFormat), //goodiebagOverviewDate.startDate,
      endDate: end.format(TimeFormater.GTMFormat), //goodiebagOverviewDate.endDate,
      goodiebagOverviewFilter: {
        goodiebags: goodiebagOverviewFilter?.goodiebag
          ?.map((item) => item.value?.pk)
          .join(','),
        isStarred: goodiebagOverviewFilter?.isStarred,
        cashcampaigns: goodiebagOverviewFilter?.cashCampaign?.value?.pk,
        giftcampaigns: goodiebagOverviewFilter?.giftCampaign?.value?.pk,
      },
    };

    dispatch(createAction('overview/getGoodiebagData')(actionFilter));

    let campaignTypes = [];
    if (goodiebagOverviewFilter?.cashCampaign?.value?.pk) {
      campaignTypes.push(CampaignType.cashVoucherCampaign);
    }
    if (goodiebagOverviewFilter?.giftCampaign?.value?.pk) {
      campaignTypes.push(CampaignType.giftCampaign);
    }

    dispatch(
      createAction('overview/getCampaignsData')({
        ...actionFilter,
        typeIn: campaignTypes,
      }),
    );

    dispatch(createAction('overview/getCashCampaignCouponData')(actionFilter));
    dispatch(createAction('overview/getGiftCampaignCouponData')(actionFilter));
    dispatch(
      createAction('overview/getFortunebagCampaignCouponData')(actionFilter),
    );

    dispatch(createAction('overview/getGoodiebagFieldData')(actionFilter));

    if (
      !goodiebagOverviewFilter?.goodiebag ||
      goodiebagOverviewFilter?.goodiebag?.length > 1
    )
      dispatch(createAction('overview/getGoodiebagRankingTable')(actionFilter));

    if (!goodiebagOverviewFilter?.filterByCashVouchers?.value?.pk) {
      dispatch(
        createAction('overview/getCampaignsRankingTable')({
          ...actionFilter,
          campaginTypes: [CampaignType.cashVoucherCampaign],
          key: 'cashCampaignRankingTable',
        }),
      );
    }
    if (!goodiebagOverviewFilter?.goodiebagCoupons?.value?.pk) {
      dispatch(
        createAction('overview/getCampaignsRankingTable')({
          ...actionFilter,
          campaginTypes: [CampaignType.giftCampaign],
          key: 'giftCampaignRankingTable',
        }),
      );
    }

    if (!goodiebagOverviewFilter?.fortunebags?.value?.pk) {
      dispatch(
        createAction('overview/getCampaignsRankingTable')({
          ...actionFilter,
          campaginTypes: [CampaignType.fortuneBagForGoodieBagCampaign],
          key: 'fortunebagCampaignRankingTable',
        }),
      );
    }
  };

  const tabs = [
    {
      name: '',
      content: (pickerGoodiebagOverviewDate?.startDate) && <GoodiebagOverview
        goodiebagOverviewDate={pickerGoodiebagOverviewDate} />
    },
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail:
            'Showing data from Goodie bag, cash voucher campaign and limited offer campaign.',
          title: 'Goodie Bags & Related Campaigns',
        }}
        defaultActiveKey={activeTab}
        breadcrumb={<CustomBreadcrumb />}
        buttons={[]}
        renderCompare={() => {
          return (
            <>
              <DateRangeCompare inCustomListComponent />
              <button
                type="button"
                className="btn-further btn btn-primary"
                onClick={() => {
                  requestData(datePicker?.start, datePicker?.end);
                }}
              >
                Refresh
              </button>
            </>
          );
        }}
        renderTabsRight={() => {
          return (
            <DateRangeSelection
              onTab
              applyDateRangeEvent={(startDate, endDate) => {
                console.log('@348', startDate, endDate);
                // setFirstTimePickerChange(false);
                setDatePicker({ start: startDate, end: endDate });
                // if (firstTimePickerChange) {
                //   return;
                // }
                // requestData(startDate, endDate);
              }}
              clearDataEvent={() => {
                dispatch(createAction('overview/clearStateExcludeFilter')());
              }}
            />
          );
        }}
        tabs={tabs}
      />
    </>
  );
}

export default GoodiebagAnalytics;
