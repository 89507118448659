import React, { useEffect } from 'react';
import CustomSwitchButton from '../../base/CustomSwitchButton';
import { Collapse } from 'react-bootstrap';
import CustomDateTimeSelect from '../../base/CustomDateTimeSelect';
import ErrorFieldMessage from '../../base/ErrorFieldMessage';
import { isShowError, firstError } from './CreateCampaignHandleError';

function PeriodSection(props) {
  const switchButtonTitle = props.switchButtonTitle;
  const isAlwaysPeriod = props.isAlwaysPeriod;
  const switchOnChange = props.switchOnChange || (() => {});
  const startDate = props.startDate;
  const startDateChange = props.startDateChange || (() => {});
  const endDate = props.endDate;
  const endDateChange = props.endDateChange || (() => {});
  const maxDate = props.maxDate;
  const minDate = props.minDate;
  const reminder = props.reminder;
  const disabled = props.disabled;
  const startErrorObject = props.startErrorObject || {};
  const endErrorObject = props.endErrorObject || {};

  const dataPicker = ({
    title,
    defaultTime,
    errorObject = {},
    onTimeChange = () => {},
    seconds = 0,
  }) => {
    return (
      <div>
        <label className="create-section-label create-section-label-bottom-space">
          {title}
        </label>
        <CustomDateTimeSelect
          disabled={isAlwaysPeriod}
          defaultTime={defaultTime}
          maxDate={maxDate}
          minDate={minDate}
          seconds={seconds}
          error={!!errorObject.message}
          onTimeChange={onTimeChange}
        />
        {errorObject.message ? null : reminder ? (
          <label className="tips-message">{reminder}</label>
        ) : null}
        <ErrorFieldMessage
          id={errorObject.id}
          error={errorObject.message}
          message={errorObject.message}
        />
      </div>
    );
  };

  return (
    <>
      {switchButtonTitle ? (
        <label className="create-section-label create-section-label-bottom-space">
          {switchButtonTitle}
        </label>
      ) : null}
      <CustomSwitchButton
        disabled={disabled}
        defaultChecked={isAlwaysPeriod}
        onChange={switchOnChange}
      />
      <Collapse in={!isAlwaysPeriod}>
        <div>
          {dataPicker({
            title: 'Start date',
            defaultTime: startDate,
            onTimeChange: startDateChange,
            errorObject: startErrorObject,
          })}
          {dataPicker({
            title: 'End date',
            defaultTime: endDate,
            onTimeChange: endDateChange,
            errorObject: endErrorObject,
            seconds: 59,
          })}
        </div>
      </Collapse>
    </>
  );
}

export default PeriodSection;
