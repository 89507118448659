import React from 'react';

const CheckboxItem = ({ checked, onChange, title, disabled = false }) => (
  <label
    className={`checkbox-container checkbox-container-no-margin ${
      disabled ? 'check-box-container-disabled' : ''
    }`}
  >
    {title}
    <input
      type="checkbox"
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    />
    <span className={`checkmark ${disabled ? 'checkbox-disabled' : ''}`}></span>
  </label>
);

export default CheckboxItem;
