import React, { useState, useEffect } from 'react';
import ContentSections from '../base/ContentSections';
import PurchaseItemSection from './PurchaseItemSection';
import PurchaseReceiptSection from './PurchaseReceiptSection';
import { useSelector, useDispatch } from 'react-redux';
import { TRANSACTION_RECORD_TYPE } from '../../containers/record/records/TransactionList';
import { SaveAndBackWithOutTempButtons } from '../base/BottomStepComponent';
import { createAction } from '../../utils';
import { useLocation } from 'react-router-dom';

function CreateTransactionStepThree() {
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    transactionType,
    purchasedItems,
    errorFields,
    currentStep,
  } = useSelector((state) => ({
    transactionType: state.createTransaction.transaction.transactionDisplayType,
    purchasedItems: state.createTransaction.transaction.purchasedItems,
    errorFields: state.createTransaction.errorFields,
    currentStep: state.createTransaction.currentStep,
  }));

  const offlineRewardSection = [<PurchaseReceiptSection />];

  const offlinePOSsections = purchasedItems.map((item, index) => {
    return (
      <PurchaseItemSection
        index={index + 1}
        addItem={() => {}}
        deleteIndex={() => {}}
        numberOfItems={purchasedItems.length}
      />
    );
  });

  const getSections = () => {
    if (transactionType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS) {
      return offlinePOSsections;
    }
    return offlineRewardSection;
  };

  return (
    <>
      <ContentSections sections={getSections()} hidePreview={true} />
      <SaveAndBackWithOutTempButtons
        backAction={() =>
          dispatch(
            createAction('createTransaction/stepChange')({
              isBack: true,
              step: 2,
            }),
          )
        }
        saveText={'Save'}
        saveAction={() => {
          dispatch(
            createAction('createTransaction/checkStepThree')({
              isBack: false,
              afterActions: () => {
                if (location.pathname.includes('edit')) {
                  dispatch(
                    createAction('createTransaction/updateTransaction')(),
                  );
                } else {
                  dispatch(
                    createAction('createTransaction/createTransaction')(),
                  );
                }
              },
            }),
          );
        }}
      />
    </>
  );
}

export default CreateTransactionStepThree;
