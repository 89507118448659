import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { connect, useDispatch, useSelector } from 'react-redux';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { createAction, getHashKeyString } from '../../../utils';

import { PermissionCodes } from '../../../config/PermissionCodes';
import AuthButton from '../../../components/base/AuthButton';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';

import DeletePrompt from '../../../components/base/DeletePrompt';
import { NavBarNames } from '../../../config/NavBarNameList';
import Filter from './Filter';

export default function MarketList() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const {
    totalCount,
    pageInfo,
    totalPage,
    displayFields,
    pagedList,
    checkedList,
  } = useSelector((state) => ({
    totalCount: state.targetMarket.totalCount,
    pageInfo: state.targetMarket.pageInfo,
    totalPage: state.targetMarket.totalPage,
    displayFields: state.targetMarket.listDisplayFields,
    pagedList: state.targetMarket.pagedList,
    checkedList: state.targetMarket.checkedList,
  }));

  const actionList = [
    {
      name: 'Delete',
      action: () => setShowDeleteView(true),
      requires: PermissionCodes.changeTargetMarket,
    },
  ];

  const tabs = [
    {
      name: 'List',
      content: (
        <BaseTabListContainer
          hideTab={true}
          groupActions={actionList}
          pageInfo={pageInfo}
          totalCount={totalCount || 0}
          filter={{ hasFilter: true, componment: Filter }}
          listContent={
            <BaseListContainer
              fields={displayFields}
              model={'targetMarket'}
              permissionGroup={PermissionCodes.targetMarket}
              dataList={pagedList}
              totalPage={totalPage}
              deleteInfo={{
                data: [],
                title: 'Target market',
                relatedName: '',
                onComfirm: {},
                relatedSections: [],
              }}
              actions={['Edit', 'Detail', 'Delete']}
            />
          }
        />
      ),
    },
  ];

  const [showDeleteView, setShowDeleteView] = useState(false);

  useEffect(() => {
    const searchDict = queryString.parse(location.search);
    const searchKey = searchDict['search'];
    const stringRank = searchDict['rank'] || 'true';
    const stringPage = searchDict['page'] || 1;
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);
    console.log(searchKey);
    dispatch(
      createAction('targetMarket/getList')({
        ...searchDict,
        rank: rank,
        filterName: searchKey,
        page: page,
      }),
    );
  }, [dispatch, location]);

  const buttons = [
    <AuthButton
      title="Create Target Market"
      action={() => {
        history.push('/markets/create');
        dispatch(createAction('targetMarket/clearState')());
      }}
      requires={PermissionCodes.changeTargetMarket}
    />,
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail: 'Create a Channel to Manage your Market.',
          title: NavBarNames.targetMarket,
        }}
        defaultActiveKey={tabs[0].name}
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        hideTab={true}
      />
      <DeletePrompt
        data={checkedList}
        show={showDeleteView}
        title={'Target market'}
        relatedName=""
        relatedSections={[]}
        onClose={() => setShowDeleteView(false)}
        onConfirm={() => {
          setShowDeleteView(false);
          dispatch(
            createAction('targetMarket/delete')({
              afterAction: () => history.push(location),
            }),
          );
        }}
      />
    </>
  );
}
