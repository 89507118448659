import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import './CouponList.scss';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { connect, useDispatch, useSelector } from 'react-redux';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { createAction, getHashKeyString } from '../../../utils';
import { PermissionCodes } from '../../../config/PermissionCodes';
import AuthButton from '../../../components/base/AuthButton';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import DeactiveCouponSetView from '../../../components/coupon/couponList/DeactiveCouponSetView';
import DeletePrompt from '../../../components/base/DeletePrompt';
import { COUPON_STATUS } from '../../../models/CouponListModel';
import Filter from './Filter';
import {
  ADMIN_TYPE,
  DELETE_RELATED_SECTIONS,
} from '../../../config/CustomEnums';
import ExportCouponView from '../../../components/coupon/couponList/ExportCouponView';

import HandleLeftoverView from '../../../components/coupon/couponList/HandleLeftoverView';
import { CustomDropDownButton } from '../../../components/base/CustomDropDownButton';
import FilterExportPrompt from '../../../components/base/prompt/FilterExportPrompt';
import { CustomBatchDownloadPrompt } from '../../../components/base/CustomBatchDownloadPrompt';
import { CustomImportFilePrompt } from '../../../components/base/CustomImportFilePrompt';
import { ImportResource } from '../../../models/DownloadImportModel';

const BATCH_IMPORT_OUPON_SET_TEMPLATE_LINK =
  process.env.REACT_APP_BATCH_IMPORT_COUPON_SET_TEMPLATE_LINK || '';

export const TAB_OVERVIEW = 'Overview';
export const TAB_LIST_OF_COUPON_SETS = 'List of Coupon sets';

export default function CouponList() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const {
    totalCount,
    pageInfo,
    totalPage,
    displayFields,
    couponSetList,
    checkedList,
    user,
    isSuperuser,
    userPermissions,
    // singleCouponList,
  } = useSelector((state) => ({
    totalCount: state.couponList.totalCount,
    pageInfo: state.couponList.pageInfo,
    totalPage: state.couponList.totalPage,
    displayFields: state.couponList.listDisplayFields,
    couponSetList: state.couponList.currentPageCouponSetList,
    checkedList: state.couponList.checkedList,
    user: state.users,
    isSuperuser: state.users.isSuperuser,
    userPermissions: state.users.userPermissions,
    // singleCouponList: state.singleCoupon.singleCouponList,
  }));

  const actionList = [
    {
      name: 'Delete',
      action: () => setShowDeleteView(true),
      requires: PermissionCodes.deleteCouponTemplate,
    },
  ];

  // const tabs = [
  //   // { name: TAB_OVERVIEW, content: <CouponsOverview /> },
  //   isSuperuser ||
  //   userPermissions.filter(
  //     (val) => val.split('_')[1] === PermissionCodes.coupontemplate,
  //   ).length > 0
  //     ?
  //     : {},
  // ];

  const tabs = [
    {
      name: TAB_LIST_OF_COUPON_SETS,
      content: (
        <BaseTabListContainer
          hideTab={true}
          groupActions={actionList}
          pageInfo={pageInfo}
          totalCount={totalCount || 0}
          filter={{ hasFilter: true, componment: Filter }}
          listContent={
            <BaseListContainer
              fields={displayFields}
              model={'couponList'}
              permissionGroup={PermissionCodes.coupontemplate}
              dataList={couponSetList}
              totalPage={totalPage}
              deleteInfo={{
                data: [],
                title: 'Coupon Set',
                relatedName: '',
                onComfirm: {},
                relatedSections: DELETE_RELATED_SECTIONS.COUPON_TEMPLATE,
              }}
              actions={['Edit', 'Detail', 'Delete', 'Duplicate']}
              hasOtherActions={true}
              customActions={{
                deactive: (item) => {
                  dispatch(
                    createAction('couponList/updateState')({
                      couponSet: item,
                    }),
                  );
                  if (item.status === COUPON_STATUS.ACTIVE) {
                    setShowDeactiveView(true);
                    setOperationItem(item);
                  } else if (
                    item.status === COUPON_STATUS.INACTIVE ||
                    item.status === COUPON_STATUS.LINKED_TO_FORTUNE_BAG ||
                    item.status === COUPON_STATUS.LINKED_TO_GOODIE_BAG
                  ) {
                    activeOrDeactiveAction(item);
                  }
                },
                requires: PermissionCodes.changeCouponTemplate,
              }}
            />
          }
        />
      ),
    },
  ];

  const activeTab = TAB_LIST_OF_COUPON_SETS;
  const [showDeleteView, setShowDeleteView] = useState(false);
  const [showDeactiveView, setShowDeactiveView] = useState(false);
  const [operationItem, setOperationItem] = useState();
  const [showExportView, setShowExportView] = useState(false);
  const [showLeftoverView, setShowLeftoverView] = useState(false);

  // const [firstTimePickerChange, setFirstTimePickerChange] = useState(true);

  const [showUploadPrompt, setShowUploadPrompt] = useState(false);
  const [showDownloadPrompt, setShowDownloadPrompt] = useState(false);
  const [showExportPrompt, setShowExportPrompt] = useState(false);

  const getFilterConditionsFromUrl = () => {
    const parsedSearch = queryString.parse(location.search);
    const searchKey = parsedSearch['search'] || '';
    const searchOutOfStock = parsedSearch['stock'] || '';
    const searchStatus = parsedSearch['status'] || '';

    const searchExpiredDateType = parsedSearch['expired_date'] || '';
    const searchExpiredDate = parsedSearch['absolute'] || '';
    const searchRelativeDay = parsedSearch['relativeDay'] || '';
    const searchCouponType = parsedSearch['coupon_type'] || '';
    const searchCouponMedium = parsedSearch['coupon_medium'] || '';
    const searchRedemptionMethod = parsedSearch['redemption_method'] || '';
    const searchWorkingTeam = parsedSearch['working_team'] || '';

    let filterConditions = {
      name_icontains_or_id: searchKey,
      out_of_stock: searchOutOfStock,
      coupon_template_type_in: searchCouponType,
      coupon_medium_in: searchCouponMedium,
      redemption_method_in: searchRedemptionMethod,
      working_teams_in_and_null: searchWorkingTeam,
    };
    if (searchStatus) {
      switch (searchStatus) {
        case 'linkedFortuneBag':
          filterConditions = {
            ...filterConditions,
            is_linked_fortune_bag: true,
          };
          break;
        case 'linkedGoodieBag':
          filterConditions = {
            ...filterConditions,
            is_linked_goodie_bag: true,
          };
          break;
        case 'expired':
          filterConditions = {
            ...filterConditions,
            is_linked_fortune_bag: false,
            is_expired: true,
          };
          break;
        case 'physicalExpired':
          filterConditions = {
            ...filterConditions,
            is_linked_fortune_bag: false,
            physical_is_expired: true,
          };
          break;
        case 'active':
          filterConditions = {
            ...filterConditions,
            is_linked_fortune_bag: false,
            is_expired: false,
            physical_is_expired: false,
            is_active: true,
          };
          break;
        case 'inactive':
          filterConditions = {
            ...filterConditions,
            is_linked_fortune_bag: false,
            is_expired: false,
            physical_is_expired: false,
            is_active: false,
          };
          break;
        case 'notGoodieBag':
          filterConditions = {
            ...filterConditions,
            is_linked_fortune_bag: false,
            is_linked_goodie_bag: false,
            is_expired: false,
            physical_is_expired: false,
            is_active: true,
          };
          break;
        case 'handledLeftover':
          filterConditions = {
            ...filterConditions,
            handled_leftover: true,
          };
          break;
        default:
          break;
      }
    }
    if (searchExpiredDateType) {
      switch (searchExpiredDateType) {
        case 'absolute':
          if (searchExpiredDate) {
            const expiredDateRange = searchExpiredDate.split(',');
            filterConditions = {
              ...filterConditions,
              absolute_gte: expiredDateRange[0],
              absolute_lte: expiredDateRange[1],
            };
          }
          break;
        case 'relativeDay':
          if (searchRelativeDay) {
            filterConditions = {
              ...filterConditions,
              relative_day: searchRelativeDay,
            };
          }
          break;
        default:
          filterConditions = {
            ...filterConditions,
            all_time: true,
          };
          break;
      }
    }
    return filterConditions;
  };

  useEffect(() => {
    const searchDict = queryString.parse(location.search);
    const searchKey = searchDict['search'];
    const stringRank = searchDict['rank'] || 'true';
    const stringPage = searchDict['page'] || 1;
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);

    if (activeTab === TAB_LIST_OF_COUPON_SETS) {
      dispatch(
        createAction('couponList/getCurrentPageTemplateList')({
          ...searchDict,
          rank: rank,
          filterName: searchKey,
          page: page,
          getAll: 'false',
        }),
      );
    }
  }, [dispatch, location]);

  // useEffect(() => {
  //   setFirstTimePickerChange(true);
  // }, [activeTab]);

  const activeOrDeactiveAction = (couponSet) => {
    let isForcedInactive = true;
    let singleCouponAction = 'singleCoupon/deactiveCoupons';
    if (couponSet.status === COUPON_STATUS.INACTIVE) {
      isForcedInactive = false;
      singleCouponAction = 'singleCoupon/activeCoupons';
    }
    dispatch(
      createAction('couponList/updateCouponSetActiveStatus')({
        couponSetPK: couponSet.pk,
        isForcedInactive,
        afterAction: () => history.push(location),
        // updateAction: () => {
        //   dispatch(
        //     createAction(singleCouponAction)({
        //       afterAction: () => history.push(location),
        //     }),
        //   );
        // },
      }),
    );
  };

  const buttons = [
    <AuthButton
      customClass="btn-back-button-common btn-download"
      title="Handle leftover coupons"
      action={() => {
        setShowLeftoverView(true);
      }}
      requires={PermissionCodes.changeCoupon}
    />,
    <AuthButton
      customClass="btn-back-button-common btn-download"
      title="Export report"
      action={() => {
        setShowExportView(true);
      }}
      requires={PermissionCodes.viewCoupon}
    />,
    <div class="batch-manager">
      <CustomDropDownButton
        title={'Batch manage'}
        items={[
          ...(user.adminType === ADMIN_TYPE.HKTB_ADMIN
            ? [
                {
                  title: 'Download Template',
                  action: () => setShowDownloadPrompt(true),
                },
                {
                  title: 'Import Coupon Set',
                  action: () => {
                    setShowUploadPrompt(true);
                  },
                },
              ]
            : []),
          {
            title: 'Export Existing Coupon Set',
            action: () =>
              // dispatch(
              //   createAction('downloadAndImport/createDownloadTask')({
              //     from: ImportResource.campaign,
              //   }),
              // ),
              setShowExportPrompt(true),
          },
        ]}
      />
    </div>,
    <AuthButton
      title="Create Coupon Set"
      action={() => {
        history.push('/coupons/create');
        dispatch(createAction('createCoupon/clearState')());
      }}
      requires={PermissionCodes.addCouponTemplate}
    />,
  ];

  return (
    <>
      <CustomListComponent
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        defaultActiveKey={activeTab}
        caution={{
          detail:
            'Coupon set stores the content and controls a list of coupons. After you create a coupon set, you can generate a list of single coupons for your customers',
          title: 'Coupon Set',
        }}
        onTabChange={(key) => {
          history.push({ pathname: location.pathname, hash: key });
        }}
      />
      <DeletePrompt
        data={checkedList}
        show={showDeleteView}
        title={'Coupon Set'}
        relatedName=""
        relatedSections={DELETE_RELATED_SECTIONS.COUPON_TEMPLATE}
        onClose={() => setShowDeleteView(false)}
        onConfirm={() => {
          setShowDeleteView(false);
          dispatch(
            createAction('couponList/delete')({
              afterAction: () => history.push(location),
            }),
          );
        }}
      />
      <DeactiveCouponSetView
        couponSet={operationItem}
        showDeactiveView={showDeactiveView}
        onHide={() => setShowDeactiveView(false)}
        deactiveAction={() => {
          activeOrDeactiveAction(operationItem);
        }}
      />
      {/* <ExportCouponView
        show={showExportView}
        onHide={() => {
          setShowExportView(false);
        }}
      /> */}
      <FilterExportPrompt
        show={showExportView}
        onClose={() => setShowExportView(false)}
        title={'Export report'}
        exportSource={ImportResource.coupon}
        exportFilterConditions={getFilterConditionsFromUrl()}
        otherBody={{ reportSperate: true }}
      />
      <HandleLeftoverView
        show={showLeftoverView}
        onHide={() => {
          setShowLeftoverView(false);
        }}
      />

      <CustomImportFilePrompt
        show={showUploadPrompt}
        title={'Import Coupon Set'}
        description={
          'If the imported coupon set already existed, the existing coupon set will be replaced.'
        }
        onClose={() => setShowUploadPrompt(false)}
        accept={'.zip'}
        from={ImportResource.couponTemplate}
      />

      <CustomBatchDownloadPrompt
        show={showDownloadPrompt}
        title={'Download form for import coupon sets'}
        onClose={() => setShowDownloadPrompt(false)}
        fromModel="COUPON"
        source={[
          {
            title: 'Import template sheet',
            link: BATCH_IMPORT_OUPON_SET_TEMPLATE_LINK,
            reference: [],
          },
          {
            title: 'ID reference sheet',
            downloadTitle: 'Download all',
            resource: ImportResource.couponTemplateReference,
            reference: [
              { title: 'Working Teams', resource: ImportResource.workingTeam },
              { title: 'brands', resource: ImportResource.brand },
              { title: 'stores', resource: ImportResource.store },
              { title: 'logo label', resource: ImportResource.logoLabel },
              { title: 'tour card', resource: ImportResource.tourCard },
              {
                title: 'Terms & Conditions',
                resource: ImportResource.termsAndConditionsTemplate,
              },
              {
                title: 'survey form',
                resource: ImportResource.surveyForm,
              },
            ],
          },
        ]}
      />

      <FilterExportPrompt
        show={showExportPrompt}
        onClose={() => setShowExportPrompt(false)}
        title={'Export existing coupon set'}
        exportSource={ImportResource.couponTemplate}
        exportFilterConditions={getFilterConditionsFromUrl()}
      />
    </>
  );
}
