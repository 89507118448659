import React, { useEffect, useState } from 'react';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import { Image } from 'react-bootstrap';
import deleteSelectedButton from '../../assets/images/drop_down_delete_selected.png';
import { AutoComplete, Input } from 'antd';
import './CustomAutoCompleteInput.scss';

function CustomAutoCompleteInput({
  title,
  value,
  setValue = () => {},
  filterAction = () => {},
  options = [],
  clearable = true,
  clearAction = () => {},
  selectAction = () => {},
  renderBeforeInput,
  customInputClass,
  placeholder = 'Please input',
}) {
  return (
    <div className="d-flex flex-column">
      {title ? <CustomTitleLabel title={title} /> : null}
      <AutoComplete
        defaultActiveFirstOption={false}
        defaultValue={value}
        options={options}
        style={{ width: '100%' }}
        onChange={(data) => {
          setValue(data);
        }}
        onSelect={(data) => {
          selectAction(data);
        }}
        onSearch={(text) => {
          filterAction(text);
        }}
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {renderBeforeInput}
          <input
            className={`custom-base-input ${customInputClass}`}
            placeholder={placeholder}
            onChange={() => {}}
            // defaultValue={inputValue}
            value={value}
          />
          {clearable ? (
            <Image
              src={deleteSelectedButton}
              className="custom-delete-icon"
              onClick={(e) => {
                e.stopPropagation();
                clearAction();
              }}
            />
          ) : null}
        </div>
      </AutoComplete>
    </div>
  );
}

export default CustomAutoCompleteInput;
