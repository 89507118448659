import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { createAction } from '../../../utils';
import ContentSections from '../../../components/base/ContentSections';
import './CampaignCategoryDetail.scss';
import { APIStatus, LanguageConfig } from '../../../config/CustomEnums';
import Loading from '../../../components/base/Loading';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import i18n from '../../../I18n';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';

const CampaignCategoryDetail = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  const { category, createStatus, languages } = useSelector((state) => ({
    category: state.createCampaignCategory.category,
    createStatus: state.createCampaignCategory.createStatus,
    languages: state.language.allList,
  }));

  const renderTabForLanguage = (language) => {
    const categoryName =
      (language === LanguageConfig.english
        ? category.name
        : category.translations?.[language]?.name) || '-';
    const order = category?.order;
    const categoryNameWithOrder = `${categoryName}(${order})`;
    const categoryStatus = category.isForcedInactive ? 'Inactive' : 'Active';

    const tags = category.displayTags?.map((item) => {
      const name = item.translations?.[language] || item.name;
      return `[ID:${item.pk}] ${name}`;
    });
    const tagKey = category.displayTagKey?.pk
      ? `[ID:${category.displayTagKey?.pk}] ${
          category.displayTagKey?.translations?.[language] ||
          category.displayTagKey?.name
        }`
      : '-';
    const icon = category.icon;

    return (
      <MembershipInfoCard
        title={i18n.t('content', { locale: 'en' })}
        data={{ categoryNameWithOrder, categoryStatus, tagKey, icon, tags }}
        fields={[
          [
            {
              title: 'Name(Order)',
              field: 'categoryNameWithOrder',
            },
          ],
          [
            {
              title: 'Linked with Tag',
              field: 'tagKey',
            },
          ],
          [
            {
              title: 'Linked with Attribute Tag',
              field: 'tags',
            },
          ],
          (language === LanguageConfig.english?
          [
            {
              title: 'Image',
              field: 'icon',
              customClass: 'demo-image-circle',
            },
          ]:null),
          [
            {
              title: 'Status',
              field: 'categoryStatus',
            },
          ],
        ]}
      />
    );
  };

  const tabs = [
    {
      name: 'Detail',
      content:
        createStatus === APIStatus.calling ? (
          <Loading />
        ) : (
          <ContentSections
            hidePreview
            languageTabContent={{
              containers: languages?.map((item) => ({
                container: [renderTabForLanguage(item.code)],
                key: item.code,
                title: item.sourceName,
              })),
            }}
            activeSection={LanguageConfig.english}
            // sectionStyle=""
            // contentStyle=""
          />
        ),
    },
  ];

  const buttons = [
    <AuthButton
      title="Edit"
      action={() => {
        // dispatch({ type: 'customerList/clearData' });
        history.push({
          pathname: 'edit/',
        });
      }}
      requires={PermissionCodes.changeCampaignCategory}
    />,
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].name);

  useEffect(() => {
    const categoryID = params.id;
    if (categoryID) {
      dispatch(createAction('language/getList')({ isSelectorLoad: true }));
      dispatch(
        createAction('createCampaignCategory/getCategory')({ id: categoryID }),
      );
    }
    return () => {
      dispatch({ type: 'createCampaignCategory/reset' });
    };
  }, []);

  return (
    <div className="campaign-category-detail">
      <CustomListComponent
        caution={{
          detail: '',
          title: category.name,
        }}
        defaultActiveKey={activeTab}
        breadcrumb={<CustomBreadcrumb name={category.name} />}
        buttons={buttons}
        tabs={tabs}
        onTabChange={(key) => {
          setActiveTab(key);
        }}
      />
    </div>
  );
};

export default CampaignCategoryDetail;
