import React, { useState, useEffect } from 'react';
import BasePrompt from '../base/prompt/BasePrompt';
import { ImageTextButton } from '../base/CustomImageButton';
import filterImage from '../../assets/images/32filter.svg';
import { useSelector, useDispatch } from 'react-redux';
import { createAction } from '../../utils';
import { useHistory } from 'react-router-dom';
import BaseMutipleSelectorV2 from '../base/BaseMultipleSelectorV2';

const AttributeBody = ({ selectedList, setValue }) => {
  const allList = useSelector((state) => state.attributeTag.notPagedAllList) || [];
  return (
    <>
      <BaseMutipleSelectorV2
        title={'Select Key tags or Attribute tags'}
        namespace="attributeTag"
        size={['shorter', 'wider']}
        searchPlaceholder="Search by key tag, attribute tag"
        data={{
          sourceData: allList,
          targetData: selectedList || [],
          targetChange: setValue,
        }}
        custom={{
          customFilter: (inputValue, option) => {
            const inputIgnoreCase = inputValue?.toLowerCase();
            const optionIgnoreCase = option?.name?.toLowerCase();
            const groupIgnoreCase = option?.tagKey?.name?.toLowerCase();
            return (
              optionIgnoreCase?.includes(inputIgnoreCase) ||
              groupIgnoreCase?.includes(inputIgnoreCase)
            );
          },
        }}
        groupKey={'tagKey'}
      />
      {selectedList?.length > 0
        ? <label
          className="histogram-chart-attribute-reset popup-reset"
          onClick={() => setValue([])}
        >
          Reset All
        </label>
        : null
      }
    </>
  );
};

function AttributeFilter() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [filteredAttributes, setFilteredAttributes] = useState([]);
  const [filterTemp, setFilterTemp] = useState([]);
  console.log('selectedAttributes:', selectedAttributes);

  useEffect(() => {
    setSelectedAttributes(filterTemp);
  }, [show]);

  useEffect(() => {
    setFilteredAttributes([])
    setSelectedAttributes([])
    setFilterTemp([])
    setShow(false)
  }, [history.location])

  const requestNewAttributeAnalytic = (selected = []) => {
    const filterOption = {};
    if (selected?.length) {
      const attributePKs = selected.map((item) => item.pk);
      console.log('attributePKs:', attributePKs);
      filterOption.attributeTags = attributePKs.toString();
    }
    dispatch(
      createAction('dashboardCustomer/getAttributeChart')({
        ...filterOption,
      }),
    );
  };

  return (
    <>
      <div className="all-list-filter-container">
        {filteredAttributes?.length ? (
          <label
            style={{ marginRight: '7px' }}
            className="histogram-chart-attribute-reset"
            onClick={() => {
              setSelectedAttributes([]);
              setFilteredAttributes([]);
              setFilterTemp([]);
              requestNewAttributeAnalytic([]);
            }}
          >
            Reset All
          </label>
        ) : null}
        <ImageTextButton
          action={() => setShow(true)}
          image={filterImage}
          title={'Filter'}
        />
        {filteredAttributes?.length ? (
          <label
            className="filter-badge-icon"
            style={{ marginLeft: '-5px', marginTop: '-3px' }}
          />
        ) : null}
      </div>
      <BasePrompt
        show={show}
        closeAction={() => setShow(false)}
        rightButton={{
          text: 'Apply',
          action: () => {
            setFilteredAttributes(selectedAttributes);
            requestNewAttributeAnalytic(selectedAttributes);
            setFilterTemp(selectedAttributes);
            setShow(false);
          },
        }}
        leftButton={{
          text: 'Cancel',
          action: () => {
            setShow(false);
          },
        }}
        title={'Filter'}
        description={''}
        otherBody={() => (
          <AttributeBody
            selectedList={selectedAttributes}
            setValue={setSelectedAttributes}
          />
        )}
      />
    </>
  );
}

export default AttributeFilter;
