import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { createAction, getHashKeyString } from '../../utils';
import { PermissionCodes } from '../../config/PermissionCodes';
import AuthButton from './AuthButton';
import BasePrompt from './prompt/BasePrompt';
import { Image } from 'react-bootstrap';
import LoadingIcon from '../../assets/images/loading.gif';

function CustomFileUploader({
  title = 'Import .csv',
  accept = '.csv',
  action = () => {},
  showFileName = false,
  fileKey = 'importedFile',
}) {
  const dispatch = useDispatch();
  const hiddenFileInput = React.useRef(null);

  const { importedFile, uploading } = useSelector((state) => ({
    importedFile: state.uploadFiles?.[fileKey],
    uploading: state.uploadFiles.uploading,
  }));
  console.log('@@25',fileKey,title);

  const [fileName, setFileName] = useState();
  const [showFile, setShowFile] = useState(false);

  useEffect(() => {
    if (importedFile) {
      // setFileName(event.target.value.replace(/^.*[\\\/]/, ''));
      // console.log('@@27: ', importedFile);
      setShowFile(showFileName);
    } else {
      setShowFile(false);
    }
  }, [importedFile]);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    // props.handleFile(fileUploaded);
    dispatch(
      createAction('uploadFiles/uploadFile')({
        file: fileUploaded,
        source: event,
        fileKey,
      }),
    );

    // if (showFileName) {
    setShowFile(false);
    setFileName(event.target.value.replace(/^.*[\\\/]/, ''));
    // }

    event.target.value = null;

    action();
  };

  const [showError, setShowError] = useState(false);
  const { importError } = useSelector((state) => ({
    importError: state.downloadAndImport.error,
  }));

  useEffect(() => {
    setShowError(Object.keys(importError || {}).length > 0);
  }, [importError]);

  return (
    <>
      {/* <Button className="btn-further" onClick={handleClick}>
                Import .csv
            </Button> */}
      {uploading ? (
        <Image src={LoadingIcon} className="loading-icon"></Image>
      ) : (
        <AuthButton
          className={showFileName ? 'link-button-text' : ''}
          title={showFile ? fileName : title}
          action={handleClick}
          requires={PermissionCodes.addImportjob}
        />
      )}

      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
        accept={accept}
      />

      <BasePrompt
        show={showError}
        title={importError?.title}
        description={importError?.content}
        closeAction={() => {
          setShowError(false);
        }}
        leftButton={{
          text: 'Cancel',
          action: () => {
            setShowError(false);
          },
        }}
        rightButton={{
          text: 'Import again',
          action: () => {
            handleClick();
            setShowError(false);
          },
          requires: PermissionCodes.addImportjob,
        }}
      />
    </>
  );
}

export default CustomFileUploader;
