import React from 'react';
import { Tooltip } from 'antd';

export default function LabelWithToolTips({ title, className }) {
  return (
    <Tooltip placement="topLeft" title={title}>
      <label className={className}>{title}</label>
    </Tooltip>
  );
}
