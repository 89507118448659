import React, { Component } from 'react';
import CustomViewer from '../../base/CustomViewer';
import PreviewImage from '../../base/prompt/PreviewImage';

class BaseOverView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: null,
    };
  }

  renderItemBody = (title, index) => {
    const value = this.props.itemValueList[index];
    if (value) {
      if (value.component) {
        return value.component;
      }

      return (this.props.imageTitleNames || ['Image']).includes(title) ? (
        <img
          alt="img"
          src={value}
          style={{ maxWidth: 191, maxHeight: 191, marginTop: 25 }}
          onClick={() => {
            this.setState({
              imageUrl: value,
            });
          }}
        />
      ) : (
        <CustomViewer className="viewer-content" initValue={value} />
      );
    }
    return title === null ? null : (
      <div className="second-section-content">-</div>
    );
  };

  render() {
    return (
      <div
        className={`${
          this.props.hideSection ? '' : 'coupon-overview-container'
        } ${this.props.className || ''}`}
      >
        {this.props.title ? (
          <div className="first-section-title overview-section-title">
            {this.props.title}
          </div>
        ) : null}
        {this.props.itemTitleList.map((title, index) => {
          return (
            <div className="second-section">
              <div className="second-title-section">
                <div className="second-section-title">{title}</div>
                {this.renderItemBody(title, index)}
              </div>
            </div>
          );
        })}
        <PreviewImage
          show={this.state.imageUrl ? true : false}
          onClose={() => {
            this.setState({
              imageUrl: null,
            });
          }}
          imageUrl={this.state.imageUrl}
        />
      </div>
    );
  }
}

export default BaseOverView;
