import React from 'react';
import { CustomTitleLabel } from '../../earning/CustomBaseComponments';
import { useState } from 'react';
import ErrorFieldMessage from '../../base/ErrorFieldMessage';

function CouponGenerateCount({
  title,
  count,
  inputTitle = 'Quantity',
  setQuantity = () => {},
}) {
  const [error, setError] = useState(null);
  return (
    <>
      {title ? <CustomTitleLabel title={title} /> : null}
      {count >= 0 ? <div>{count}</div> : null}
      <div className="second-section quantity_section">
        <div className="second-title-section">
          <div className="second-section-title">{inputTitle}</div>
        </div>
        <div className="d-flex">
          <input
            type="number"
            className={`day-input-fields `}
            onChange={(e) => {
              const { value } = e.target;
              if (parseInt(value) > 100000) {
                setError({
                  message:
                    'You can set to max 100,000 if there is no specific limit.',
                });
              } else {
                setError(null);
              }
              setQuantity(value);
            }}
            defaultValue={0}
            min={0}
            max={100000}
          />
          <label className="days-label">numbers of coupons</label>
        </div>
        <div className="max-number-label">
          {`You can set to max 100,000 if there is no specific limit.${
            title
              ? '\nIf do not need to generate this type of coupon, fill in 0.'
              : ''
          }`}
        </div>
        {error ? (
          <ErrorFieldMessage error={true} message={error.message} />
        ) : null}
      </div>
    </>
  );
}

export default CouponGenerateCount;
