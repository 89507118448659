import React from 'react';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import { GoodieBagErrorHandleField } from './CreateGoodieBagValidate';
import { hasError, errorMessage } from '../base/ErrorFieldMessage';

function NumberInput({
  labelText,
  type,
  valueChange,
  defaultValue = undefined,
  errors,
  onError = false,
  errorFieldName,
  disabled = false,
  typeClassName = '',
  typePosition = 'row',
}) {
  return (
    <>
      {labelText ? (
        <label className="create-section-label create-section-label-bottom-space">
          {labelText}
        </label>
      ) : null}

      <div className={`d-flex flex-${typePosition}`}>
        <input
          disabled={disabled}
          style={disabled ? { backgroundColor: '#F7F7F7' } : {}}
          value={defaultValue}
          type="number"
          min="0"
          className={`coupon-campaign-section-input ${
            onError ? 'error-field' : ''
          }`}
          onChange={({ target }) => {
            let value = target.value;
            if (value === '') {
              value = null;
            }
            valueChange(value);
          }}
          onKeyDown={(event) => {
            if (event.keyCode === 189) {
              event.preventDefault();
              return false;
            }
          }}
        />
        <label className={`section-short-description ${typeClassName}`}>
          {type}
        </label>
      </div>
      <ErrorFieldMessage
        id={GoodieBagErrorHandleField?.[errorFieldName]?.name}
        error={hasError(errors, errorFieldName)}
        message={errorMessage(errors, errorFieldName)}
      />
    </>
  );
}

export default NumberInput;
