import React, { useEffect } from 'react';
import ContentSections from '../../../components/base/ContentSections';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';
import { Button } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { createAction } from '../../../utils';
import { POINT_RECORD_TYPE } from '../../../components/pointRecord/CreatePointRecordStepOne';
import { OnlyBackButton } from '../../../components/base/BottomStepComponent';
import { pointRecordQueryKey } from '../../../components/pointRecord/RecordsUrlConfig';

function PointTransactionOverview() {
  const queryString = require('query-string');
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const { selectedPointRecord } = useSelector((state) => ({
    selectedPointRecord: state.pointTransactionList.selectedPointTransaction,
  }));

  useEffect(() => {
    const pointRecordPK = params.id;
    dispatch(
      createAction('pointTransactionList/getSinglePointRecord')({
        pointRecordPK,
      }),
    );
  }, [dispatch, params.id]);

  const getPointDetailSectionFieldsWithType = (pointType) => {
    let extraFields = [];
    if (pointType === 'Remove point') {
      extraFields = extraFields.concat([
        [{ title: 'Used date (optional)', field: 'useDate' }],
        [{ title: 'Used type (optional)', field: 'usedDisplayType' }],
      ]);
    }
    return [
      [{ title: 'Value', field: 'points' }],
      ...extraFields,
      [{ title: 'Remarks (optional)', field: 'remarks' }],
      [{ title: 'Expiry date (optional)', field: 'expiryDate' }],
    ];
  };

  const tabs = [
    {
      name: '',
      content: (
        <div className="scroll-container-common">
          <ContentSections
            sections={[
              <MembershipInfoCard
                title={'POINT RECORD DETAILS'}
                data={selectedPointRecord}
                fields={[
                  [{ title: 'Customer', field: 'name' }],
                  [
                    {
                      title: 'Transaction Type',
                      field: 'transactionDisplayType',
                    },
                  ],
                ]}
              />,
              <MembershipInfoCard
                title={
                  selectedPointRecord.pointDisplayType ===
                  POINT_RECORD_TYPE.TYPE_ADD
                    ? 'ADD POINT'
                    : 'REMOVE POINT'
                }
                data={selectedPointRecord}
                fields={getPointDetailSectionFieldsWithType(
                  selectedPointRecord.pointDisplayType,
                )}
              />,
            ]}
            hidePreview={true}
            sectionStyle="transaction-record-detail-section"
          />
        </div>
      ),
    },
  ];

  // const buttons = [
  //   <Button
  //     className="btn-further"
  //     onClick={() => {
  //       history.push(`/point_records/${selectedPointRecord.pk}/edit`);
  //     }}
  //   >
  //     Edit
  //   </Button>,
  // ];
  return (
    <>
      <CustomListComponent
        caution={{
          detail: '', //view point records
          title: 'View Point records',
        }}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={true}
        tabs={tabs}
        buttons={[]}
      />
      <OnlyBackButton
        backAction={() => {
          history.push({
            pathname: '/point_records',
            search: queryString.stringify({
              [pointRecordQueryKey.page]: 1,
              [pointRecordQueryKey.rank]: true,
              [pointRecordQueryKey.searchKey]: '',
              [pointRecordQueryKey.tab]: 'all',
            }),
          });
        }}
      />
    </>
  );
}

export default PointTransactionOverview;
