import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import './CreateCampaignStepTwo.scss';
import {
  CampaignType,
  TermsAndConditionsType,
} from '../../../config/CustomEnums';
import { defaultPreviewImage } from './CampaignStepTwoPreviewConfig';
import ContentSections from '../../base/ContentSections';
import GeneralSection from './GeneralSection';
import KeyVisualSection from './KeyVisualSection';
import { ContinueWithBackButtons } from '../../base/BottomStepComponent';
import TourCardSection from './TourCardSection';
import CreateFortuneBagCampaignStepTwo from './CreateFortuneBagCampaignStepTwo';
import { useFormContext } from 'react-hook-form';
import { validate } from '../../../containers/engagement/campaign/CreateCampaignValidate';
import SpecificCustomSwitchButton from '../../../containers/merchants/stores/SpecificCustomSwitchButton';
import BasePrompt from '../../base/prompt/BasePrompt';
import {
  switchAll,
  markdownUpdateField,
  isShowCouponRelated,
} from '../../../models/CreateCampaignModel';
import CreateCampaignStepTwoMarkDownArea from './CreateCampaignStepTwoMarkDownArea';
import CreateCampaignStepTwoMarkDownAreaWithTC from './CreateCampaignStepTwoMarkDownAreaWithTC';

function FollowCouponSwitch({ brandStoreList }) {
  const { watch, setValue, getValues } = useFormContext();
  const isAllFollowCouponTemplate = watch('isAllFollowCouponTemplate');
  const [isShow, setIsShow] = useState(false);
  return (
    <>
      <label className="create-section-title">
        {'All the settings in Content (Step 2) follow coupon set'}
      </label>
      <div style={{ marginTop: '12px', marginBottom: '8px' }}>
        <SpecificCustomSwitchButton
          checked={isAllFollowCouponTemplate}
          onChange={() => {
            if (isAllFollowCouponTemplate) {
              switchAll({
                getValues,
                setValue,
                isOn: !isAllFollowCouponTemplate,
              });
              return;
            }
            setIsShow(true);
          }}
        />
      </div>

      <label className="create-campaign-cover-photo-reminder">
        if off, the "Follow Coupon Set" setting is available in each supported
        field. Any further changes from the coupon set will not be updated to
        this campaign.
      </label>
      <BasePrompt
        show={isShow}
        closeAction={() => {
          setIsShow(false);
        }}
        rightButton={{
          text: 'Confirm',
          action: () => {
            switchAll({
              getValues,
              setValue,
              isOn: !isAllFollowCouponTemplate,
            });
            setIsShow(false);
            if (!isAllFollowCouponTemplate) {
              setValue('linkedStores', brandStoreList);
            }
          },
        }}
        leftButton={{
          text: 'Cancel',
          action: () => setIsShow(false),
        }}
        title={'Confirm to follow all the coupon set’s setting?'}
        description={'Upon confirmation, all current settings will be removed'}
      />
    </>
  );
}

function CreateCampaignStepTwo({
  storeLoading,
  brandStoreList,
  merchantTermsAndConditionsList,
  genericTermsAndConditionsList,
}) {
  const { getValues, watch, formState, clearErrors, setError } =
    useFormContext();
  const errors = formState.errors;
  const fieldError = Object.keys(errors || []).length;
  // console.log('CreateCampaignStepTwo errors:', errors);
  const watchCampaignType = watch('campaignType');
  const selectedLanguages = watch('selectedLanguages');

  const dispatch = useDispatch();
  const defaultImage = defaultPreviewImage(watchCampaignType);
  const isCouponRelated = isShowCouponRelated(watchCampaignType);
  const sections =
    isCouponRelated &&
    watchCampaignType !== CampaignType.fortuneBagForGoodieBagCampaign
      ? [<FollowCouponSwitch brandStoreList={brandStoreList} />]
      : [];

  const getTabs = (language) => {
    return watchCampaignType === CampaignType.fortuneBagForGoodieBagCampaign
      ? [<GeneralSection language={language} hideDescription={true} />]
      : [
          <GeneralSection language={language} hideDescription={false} />,
          <KeyVisualSection language={language} />,
          <TourCardSection language={language} />,
          <CreateCampaignStepTwoMarkDownArea
            language={language}
            areaTitle="OFFER DETAIL (OPTIONAL)"
            followKey="isFollowCouponTemplateOfferSection"
            resetKey={markdownUpdateField.offerContent}
            titleKey="offerDetailTitle"
            titleName="Offer detail section title"
            contentKey="offerDetailContent"
            contentName="Offer detail section content"
          />,
          <CreateCampaignStepTwoMarkDownArea
            language={language}
            areaTitle="INSTRUCTION (OPTIONAL)"
            followKey="isFollowCouponTemplateInstructionSection"
            resetKey={markdownUpdateField.instructionContent}
            titleKey="instructionSectionTitle"
            titleName="Instruction section title"
            contentKey="instructionSectionContent"
            contentName="Instruction section content"
          />,
          <CreateCampaignStepTwoMarkDownAreaWithTC
            language={language}
            followKey="isFollowCouponTemplateMerchantTerms"
            resetKey={markdownUpdateField.merchantContent}
            areaTitle="MERCHANT TERMS & CONDITIONS (OPTIONAL)"
            titleKey="merchantTermsAndConditionsTitle"
            titleName="Merchant Terms & Conditions section title"
            contentKey="merchantTermsAndConditionsContent"
            contentName="Merchant Terms & Conditions section content"
            linkToTCTemplate="merchantTermsAndConditionsTemplate"
            followTemplateKey="isFollowMerchantTermsAndConditionsTemplate"
            getTCListKey="getMerchantList"
            TCList={merchantTermsAndConditionsList}
            defaultFilterTC={{ type: TermsAndConditionsType.merchant }}
          />,
          <CreateCampaignStepTwoMarkDownAreaWithTC
            language={language}
            followKey="isFollowCouponTemplateGenericTerms"
            resetKey={markdownUpdateField.genericContent}
            areaTitle="GENERIC TERMS & CONDITIONS (OPTIONAL)"
            titleKey="genericTermsAndConditionsTitle"
            titleName="Generic Terms & Conditions section title"
            contentKey="genericTermsAndConditionsContent"
            contentName="Generic Terms & Conditions section content"
            linkToTCTemplate="genericTermsAndConditionsTemplate"
            followTemplateKey="isFollowGenericTermsAndConditionsTemplate"
            getTCListKey="getGenericList"
            TCList={genericTermsAndConditionsList}
            defaultFilterTC={{ type: TermsAndConditionsType.generic }}
          />,
        ];
  };

  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 1,
      isBack,
    });
    dispatch({
      type: 'createCampaign/stepChange',
      payload: { isBack: isBack, step: 1, isValid },
    });
  };

  if (watchCampaignType === CampaignType.fortuneBagCampaign) {
    return <CreateFortuneBagCampaignStepTwo />;
  }
  console.log('@@187: ', selectedLanguages);
  return (
    <>
      <ContentSections
        fieldsError={fieldError}
        sections={sections}
        languageTabContent={{
          containers: selectedLanguages
            ?.sort((language1, language2) => {
              return language1.pk - language2.pk;
            })
            ?.map((language) => {
              return {
                container: getTabs(language.code),
                key: language.code,
                title: language.name,
              };
            }),
        }}
        hidePreview
        defaultImage={defaultImage}
      />
      <ContinueWithBackButtons
        disabledContinue={storeLoading}
        continueAction={() => {
          stepChangeAction(false);
        }}
        backAction={() => {
          stepChangeAction(true);
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  storeLoading: state.storeModel.allListLoading,
  brandStoreList: state.storeModel.brandStoreList || [],
  merchantTermsAndConditionsList: state.termsAndConditions?.merchantList || [],
  genericTermsAndConditionsList: state.termsAndConditions?.genericList || [],
});

export default connect(mapPropsToState)(CreateCampaignStepTwo);
