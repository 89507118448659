import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import './commentSection.scss';
import moment from 'moment';
import {
  TimeFormater,
  formatDate
} from '../../utils/TimeFormatUtil';

function CommentSection(props) {
  const { detail } = useSelector((state) => ({
    detail: state.paymentSettlementList.detail,
  }));

  const fieldValue = (item) => {
    let isMerchant = item.isMerchantAdministrator
    let classname = ""
    if (isMerchant){
        classname = "comment-section-field-input comment-section-field-input-merchant";
    }else{
        classname = "comment-section-field-input comment-section-field-input-admin";
    }  
    if (isMerchant){
        return (
            <>
              <div className={classname}>
                <span className="merchant-remark">Merchant remark</span>
                <span> | {formatDate(
                    item.lastModifiedDate, TimeFormater.monthDayYearTime)}
                </span>
              </div>
              <div>
                {item.remark 
                ? item.remark.split("\n").map((row) => {
                  return <>{row}<br/></>}) 
                : "-"}
              </div>    
            </>
        );
    }
    return (
        <>
          <div className={classname}>
            <span className="HKTB-amendment">HKTB Amendment</span>
            <span> | {formatDate(
                item.lastModifiedDate, TimeFormater.monthDayYearTime)}
            </span>
          </div>
          <div className="comment-section-field-input comment-section-field-input-ul">
          <li>
            Amendment number: {item.amendmentNumber ? item.amendmentNumber : "-"}
          </li>  
          <li>
            Remark: {item.remark 
              ? item.remark.split("\n").map((row, index) => {
                if (index == 0) {
                  return <>{row}<br/></>
                }
                else {
                  return <><p className="payment-settlement-amendment-remark-row">{row}</p></>
                }
              })
              : "-"}
          </li>  
          </div>
        </>
    );
  };

  const commentList = () => {
    return detail.comment?.map((item) => (
        fieldValue(item)
      ))
  };

  return (
    <>
      <label className="create-section-title">COMMENT</label>
        {commentList()}
    </>
  );
}

export default CommentSection;
