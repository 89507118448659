import React from 'react';
import { Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import './InformationSection.scss';
import CheckOnIcon from '../../assets/images/check_on.svg';
import { DisplayPaymentSettlementReviewStatusType } from '../../config/CustomEnums';

function InformationSection() {
  const { detail } = useSelector((state) => ({
    detail: state.paymentSettlementList.detail,
  }));

  const profileField = (
    field,
    value,
    showShortDescription = false,
    showValue = true,
  ) => {
    let className = "create-section-field-input profile-section-label-bottom";
    if (field == 'Review status'){
      switch(value){
        case DisplayPaymentSettlementReviewStatusType.waiting:
          className += " status-waiting";
          break;
        case DisplayPaymentSettlementReviewStatusType.agree:
          className += " status-agreed";
          break;
        case DisplayPaymentSettlementReviewStatusType.disagree:
          className += " status-disagreed";
          break;
      }
    };
    return (
      <>
        <label className="create-section-label information-section-label-bottom-space">
          {field}
        </label>
        {showValue ? (
          <label className={`${className}`}>
            {value}
          </label>
        ) : null}
        {showShortDescription ? (
          <div className="display-over-all-limit-container">
            <Image src={CheckOnIcon} className="display-over-all-limit-icon" />
            <label className="display-over-all-limit">
              Display the overall limit in the payment settlement.
            </label>
          </div>
        ) : null}
      </>
    );
  };

  const displayFieldContentList =[
    {
      field: 'Brand name',
      value: detail?.brandName,
    },
    {
      field: 'Report name',
      value: detail?.reportName,
    },
    {
      field: 'Report issue date',
      value: detail?.creationDate_format,
    },
    {
      field: 'Review status',
      value: detail?.displayStatus,
    },
    {
      field: 'Last update date',
      value: detail?.lastModifiedDate_format,
    },
    {
      field: 'Total Number of coupon acquired by end-user',
      value: detail?.totalNumberOfCouponAcquiredByUser,
    },
    {
      field: 'Total Number of coupon used by end-user',
      value: detail?.totalNumberOfCouponUsedByUser,
    },
    {
      field: 'Total HKTB gives to merchant(HKD)',
      value: detail?.totalHktbGivesToMerchant,
    },
    {
      field: 'Total merchant gives to HKTB(HKD)',
      value: detail?.totalMerchantGivesToHktb,
    },
    ];

  return (
    <>
      <label className="create-section-title">INFORMATION</label>
      {displayFieldContentList.map((item, index) => {
        if (item.field)
          return profileField(
            item.field,
            item.value,
            item.showShortDescription,
            item.showValue,
          );
      })}
    </>
  );
}

export default InformationSection;
