import { DatePicker } from 'antd';
import React from 'react';
import { TimeFormater } from '../../utils/TimeFormatUtil';
import './CustomTitleWithDateRange.scss';

const { RangePicker } = DatePicker;
function CustomTitleWithDateRange({
  customContainerClass,
  title,
  customTitleClass,
  tips,
  customTipsClass,
  format = TimeFormater.dayMonthYearTimePeriod,
  value,
  customDropDownClass,
  onChange = () => {},
  showTime = true,
}) {
  return (
    <div className={`date-range-container ${customContainerClass}`}>
      {title ? (
        <label className={`create-section-label ${customTitleClass}`}>
          {title}
        </label>
      ) : null}
      {tips ? (
        <label className={`date-range-tips ${customTipsClass}`}>{tips}</label>
      ) : (
        <div className="blank-tip-block" />
      )}
      <RangePicker
        showTime={showTime}
        format={format}
        separator={'-'}
        value={value}
        dropdownClassName={`${
          showTime ? 'date-range-dropdown' : ''
        } ${customDropDownClass}`}
        onChange={(values) => {
          onChange(values);
        }}
      />
    </div>
  );
}

export default CustomTitleWithDateRange;
