import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import CouponGeneral from '../../../components/coupon/couponCreation/CouponGeneral';
import './CreateCouponStepTwo.scss';
import CouponKeyVisual from '../../../components/coupon/couponCreation/CouponKeyVisual';
import CouponDefaultPreview from '../../../assets/images/coupon/preview/couponset_all.png';
import {
  COUPON_REDEMPTION_METHOD,
  LanguageConfig,
  TermsAndConditionsType,
} from '../../../config/CustomEnums';
import ContentSections from '../../../components/base/ContentSections';
import { ContinueWithBackButtons } from '../../../components/base/BottomStepComponent';
import { useDispatch, connect } from 'react-redux';
import { checkUrlIsValid, createAction, ERROR_MESSAGE } from '../../../utils';
import {
  DISCOUNT_CODE_FORMAT,
  saveCouponToSessionStorage,
} from '../../../models/CreateCouponModel';
import CouponContentSection from '../../../components/coupon/couponCreation/CouponContentSection';
import i18n from '../../../I18n';
import CouponTourCardSection from '../../../components/coupon/couponCreation/CouponTourCardSection';
import { useHistory, useParams } from 'react-router-dom';
import MarkDownAreaWithTC from '../../../components/base/MarkDownAreaWithTC';

const inputWithImageConfig = {};
const defaultImage = CouponDefaultPreview;

const PREVIEW_IMAGE_DEFAULT_WIDTH = 250;
const PREVIEW_IMAGE_DEFAULT_HEIGHT = 1531;
const PREVIEW_IMAGE_SCROLL_PREFIX = 100;
const PREVIEW_IMAGE_TOP_CONFIG = {
  coverPhoto: 241 - 166,
  otherPhotos: 241 - 166,
  name: 245,
  shortDescription: 308,
  relatedBrand: 458,
  selectedStores: 524,
  offerDetailTitle: 572,
  offerDetailContent: 572,
  tourCard: 706,
  instructionSectionTitle: 1072,
  instructionSectionContent: 1072,
  merchantTermsAndConditionsTitle: 1190,
  merchantTermsAndConditionsContent: 1190,
  genericTermsAndConditionsTitle: 1290,
  genericTermsAndConditionsContent: 1290,
  logoLabel: 1530,
};

const PREVIEW_IMAGE_COVER_CONFIG = {
  coverPhoto: { height: 166, top: 241 - 166 },
  otherPhotos: { height: 166, top: 241 - 166 },
  name: { height: 34, top: 245 },
  shortDescription: { height: 147, top: 308 },
  relatedBrand: { height: 61, top: 458 },
  selectedStores: { height: 43, top: 524 },
  offerDetailTitle: { height: 129, top: 572 },
  offerDetailContent: { height: 129, top: 572 },
  tourCard: { height: 365, top: 706 },
  instructionSectionTitle: { height: 119, top: 1072 },
  instructionSectionContent: { height: 119, top: 1072 },
  merchantTermsAndConditionsTitle: { height: 93, top: 1190 },
  merchantTermsAndConditionsContent: { height: 93, top: 1190 },
  genericTermsAndConditionsTitle: { height: 235, top: 1290 },
  genericTermsAndConditionsContent: { height: 235, top: 1290 },
  logoLabel: { height: 62, top: 1530 },
};

const NewCreateCouponStepTwo = ({
  storeLoading,
  storeEditMode,
  merchantTermsAndConditionsList,
  genericTermsAndConditionsList,
  languages,
}) => {
  const dispatch = useDispatch();
  const [activeSection, setActiveSection] = useState(null);
  const [previewImageContainerRef, setPreviewImageContainerRef] =
    useState(null);
  const [previewCoverConfig, setPreviewCoverConfig] = useState({
    height: 0,
    width: 0,
  });
  const params = useParams();
  const { id } = params;
  const { getValues, trigger, setError, clearErrors, setFocus } =
    useFormContext();
  useEffect(() => {
    if (
      Object.keys(PREVIEW_IMAGE_TOP_CONFIG).includes(activeSection) &&
      previewImageContainerRef
    ) {
      const previewImageWidth = previewImageContainerRef.clientWidth;
      const scale = previewImageWidth / PREVIEW_IMAGE_DEFAULT_WIDTH;
      const matchedConfig = PREVIEW_IMAGE_COVER_CONFIG[activeSection];
      setPreviewCoverConfig({
        height: matchedConfig.height * scale,
        top: matchedConfig.top * scale,
      });
      previewImageContainerRef.scrollTop =
        PREVIEW_IMAGE_TOP_CONFIG[activeSection] * scale -
        PREVIEW_IMAGE_SCROLL_PREFIX;
    }
  }, [activeSection, previewImageContainerRef]);
  const getTabs = (language) => {
    return [
      <CouponGeneral language={language} focusChange={setActiveSection} />,
      <CouponKeyVisual
        focusChange={setActiveSection}
        language={language}
        languages={languages}
      />,
      <CouponTourCardSection
        isCoupon={true}
        language={language}
        focusChange={setActiveSection}
      />,
      <CouponContentSection
        language={language}
        areaTitle="Offer detail (optional)"
        titleName="Offer detail section title"
        titleKey="offerDetailTitle"
        contentName="Offer detail section content"
        contentKey="offerDetailContent"
      />,
      <CouponContentSection
        language={language}
        areaTitle="Instruction (optional)"
        titleName="Instruction section title"
        titleKey="instructionSectionTitle"
        contentName="Instruction section content"
        contentKey="instructionSectionContent"
      />,
      <MarkDownAreaWithTC
        languages={languages}
        language={language}
        hideTC={false}
        linkToTCTemplate="merchantTermsAndConditionsTemplate"
        followTemplateKey="isFollowMerchantTermsAndConditionsTemplate"
        getTCListKey="getMerchantList"
        defaultFilterTC={{ type: TermsAndConditionsType.merchant }}
        TCList={merchantTermsAndConditionsList}
        areaTitle="Merchant Terms & Conditions (optional)"
        titleKey="merchantTermsAndConditionsTitle"
        titleName="Merchant Terms & Conditions section title"
        contentKey="merchantTermsAndConditionsContent"
        contentName="Merchant Terms & Conditions section content"
      />,
      <MarkDownAreaWithTC
        languages={languages}
        language={language}
        hideTC={false}
        linkToTCTemplate="genericTermsAndConditionsTemplate"
        followTemplateKey="isFollowGenericTermsAndConditionsTemplate"
        getTCListKey="getGenericList"
        defaultFilterTC={{ type: TermsAndConditionsType.generic }}
        TCList={genericTermsAndConditionsList}
        areaTitle="Generic Terms & Conditions (optional)"
        titleKey="genericTermsAndConditionsTitle"
        titleName="Generic Terms & Conditions section title"
        contentKey="genericTermsAndConditionsContent"
        contentName="Generic Terms & Conditions section content"
      />,
    ];
  };

  const isNotRequire = (redemptionMethod, stores, brandStores) => {
    if (
      redemptionMethod !== COUPON_REDEMPTION_METHOD.USERS_SCAN_STORE_QR_CODE
    ) {
      return false;
    }
    if (!brandStores.length) {
      return true;
    }
    if (!id && !stores?.length) {
      return true;
    }
    if (storeEditMode && !stores?.length) {
      return true;
    }
    return false;
  };

  return (
    <div>
      <ContentSections
        languageTabContent={{
          containers: languages?.map((item) => ({
            container: getTabs(item.code),
            key: item.code,
            title: item.sourceName,
          })),
        }}
        inputWithImageConfig={inputWithImageConfig}
        setPreviewImageContainerRef={setPreviewImageContainerRef}
        defaultImage={defaultImage}
        activeSection={activeSection}
        previewImageCoverConfig={previewCoverConfig}
      />
      <ContinueWithBackButtons
        disabledContinue={storeLoading}
        continueAction={async () => {
          let isValid = await trigger(
            [
              'translations.en.shortDescription',
              'selectedBrand',
              'translations.en.coverPhoto',
            ],
            { shouldFocus: true },
          );
          const [
            translations,
            nameEN,
            brand,
            stores,
            redemptionMethod,
            showTourCard,
            tourCards,
            showLandingPage,
            landingPageButtonName,
            landingPageUrl,
            discountCodeFormat,
            showColorIndicator,
            indicatorColor,
          ] = getValues([
            'translations',
            'translations.en.name',
            'selectedBrand',
            'selectedStores',
            'redemptionMethod',
            'showTourCard',
            'tourCards',
            'showLandingPage',
            'translations.en.landingPageButtonName',
            'translations.en.landingPageUrl',
            'discountCodeFormat',
            'showColorIndicator',
            'indicatorColor',
          ]);
          clearErrors([
            'translations.en.name',
            'selectedStores',
            'tourCards',
            'translations.en.landingPageButtonName',
            'translations.en.landingPageUrl',
            'indicatorColor',
          ]);
          if (!nameEN || nameEN?.length > 200) {
            setError(
              'translations.en.name',
              {
                type: 'require',
                message: 'Please provide a coupon name and must less than 200.',
              },
              { shouldFocus: true },
            );
            isValid = false;
          }

          const brandStores = brand?.relatedStores || [];
          console.log('stores:', stores);
          console.log('brandStores:', brandStores);
          const selectedStoresError = isNotRequire(
            redemptionMethod,
            stores,
            brandStores,
          );
          if (selectedStoresError) {
            setError(
              'selectedStores',
              {
                type: 'require',
                message: 'Please provide applicable stores.',
              },
              { shouldFocus: true },
            );
            isValid = false;
          }

          if (showColorIndicator && !indicatorColor) {
            setError(
              'indicatorColor',
              {
                type: 'require',
                message: 'Please pick a color ',
              },
              { shouldFocus: true },
            );
            isValid = false;
          }

          if (showTourCard && (tourCards || []).length === 0) {
            setError(
              'tourCards',
              {
                type: 'require',
                message: 'Please provide tour cards.',
              },
              { shouldFocus: true },
            );
            isValid = false;
          }
          if (
            redemptionMethod ===
            COUPON_REDEMPTION_METHOD.USERS_COPY_THE_DISCOUNT_CODE
          ) {
            // if (!discountCodeFormat) {
            //   setError(
            //     'discountCodeFormat',
            //     {
            //       type: 'require',
            //       message: ERROR_MESSAGE.MISS_FIELD,
            //     },
            //     { shouldFocus: true },
            //   );
            //   isValid = false;
            // }
            // console.log(
            //   '@@297: ',
            //   getValues(),
            //   landingPageButtonName,
            //   landingPageUrl,
            // );
            if (
              (showLandingPage ||
                discountCodeFormat === DISCOUNT_CODE_FORMAT.URL) &&
              !landingPageButtonName &&
              landingPageButtonName !== undefined
            ) {
              setError(
                'translations.en.landingPageButtonName',
                {
                  type: 'require',
                  message: ERROR_MESSAGE.MISS_FIELD,
                },
                { shouldFocus: true },
              );
              isValid = false;
            }

            if (
              showLandingPage &&
              (discountCodeFormat === DISCOUNT_CODE_FORMAT.CODE ||
                discountCodeFormat === undefined)
            ) {
              if (!landingPageUrl) {
                setError(
                  'translations.en.landingPageUrl',
                  {
                    type: 'require',
                    message: ERROR_MESSAGE.MISS_FIELD,
                  },
                  { shouldFocus: true },
                );
                isValid = false;
              } else if (!checkUrlIsValid(landingPageUrl)) {
                setError(
                  'translations.en.landingPageUrl',
                  {
                    type: 'require',
                    message: ERROR_MESSAGE.INVAILD_URL,
                  },
                  { shouldFocus: true },
                );
                isValid = false;
              }
            }
          }

          dispatch(
            createAction('createCoupon/stepChange')({
              currentStep: 1,
              isValid,
            }),
          );
          saveCouponToSessionStorage(getValues());
        }}
        backAction={() => {
          dispatch(
            createAction('createCoupon/stepChange')({
              currentStep: 1,
              isValid: true,
              isBack: true,
            }),
          );
          saveCouponToSessionStorage(getValues());
        }}
      />
    </div>
  );
};

const mapPropsToState = (state) => ({
  storeLoading: state.storeModel.allListLoading,
  storeEditMode: state.storeModel.storeEditMode,
  merchantTermsAndConditionsList: state.termsAndConditions?.merchantList || [],
  genericTermsAndConditionsList: state.termsAndConditions?.genericList || [],
  languages: state.language.allList,
});

export default connect(mapPropsToState)(NewCreateCouponStepTwo);
