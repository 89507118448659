import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import CustomMultipleSelect from '../base/CustomMultipleSelect';
import { DropDownWithDefaultSelect } from '../base/ActionsDropdown';
import { useCompare, isNumber } from '../../utils';

export default function LookupWithMutipleNumber(props) {
  const title = props.title;
  const groupName = props.groupName;
  const conditionIndex = props.conditionIndex;

  const defaultLookup = props.defaultLookup || 'Please select';
  const defaultMin = props.defaultMin; // || '';
  const defaultMax = props.defaultMax; // || '';

  const onReset = props.isReset;
  const changeReset = props.changeReset || (() => {});
  const isCreate = props.isCreate;

  const dropDownName = props.dropDownName || `${title}-lookup`;
  const minName = props.dropDownName || `${title}-minNum`;
  const maxName = props.dropDownName || `${title}-maxNum`;

  const getInputNumberCount = (lookup) => {
    if (lookup === 'Please select') {
      return 0;
    }
    let showInputCount = 1;
    if (lookup.toLowerCase().indexOf('empty') >= 0) {
      showInputCount = 0;
    } else if (lookup.toLowerCase() === 'between') {
      showInputCount = 2;
    }

    return showInputCount;
  };

  const [lookup, setLookup] = useState(defaultLookup);
  const [showInputNumber, setShowInputNumber] = useState(
    getInputNumberCount(lookup),
  );
  const [minNum, setMinNum] = useState(defaultMin);
  const [maxNum, setMaxNum] = useState(defaultMax);

  const lookups =
    groupName === 'signUpAnniversaryCharacteristic'
      ? ['Equal to', 'Not equal to', 'Between', 'Less than', 'Greater than']
      : [
          'Equal to',
          'Not equal to',
          'Is empty',
          'Is not empty',
          'Between',
          'Less than',
          'Greater than',
        ];

  const dropDownOptions = lookups.map((lookup) => ({
    name: lookup,
    action: () => {
      setLookup(lookup);
      setShowInputNumber(getInputNumberCount(lookup));
    },
  }));

  const dispatch = useDispatch();
  const vals = { lookup, minNum, maxNum };
  const hasValsChanged = useCompare(vals);

  useEffect(() => {
    if (hasValsChanged) {
      dispatch({
        type: 'segments/changeVals',
        payload: {
          vals,
          groupName,
          conditionIndex,
        },
      });
    }
  });

  useEffect(() => {
    if (onReset) {
      if (isCreate) {
        setLookup('Please select');
        setShowInputNumber(getInputNumberCount('Please select'));
        setMinNum('');
        setMaxNum('');
      } else {
        setLookup(defaultLookup || 'Please select');
        setShowInputNumber(getInputNumberCount(defaultLookup));
        setMinNum(defaultMin || '');
        setMaxNum(defaultMax || '');
      }

      changeReset(false);
    }
  }, [onReset, isCreate, defaultLookup, defaultMin, defaultMax, changeReset]);

  return (
    <div>
      <CustomTitleLabel title={title} />

      <div style={{ display: 'flex' }}>
        <DropDownWithDefaultSelect
          name={dropDownName}
          actions={dropDownOptions}
          defaultValue={lookup}
        />

        <input
          name={minName}
          type="number"
          onChange={({ target }) => setMinNum(target.value)}
          className="custom-number-input-short"
          value={minNum}
          onFocus={() => {}}
          hidden={showInputNumber < 1}
        />
        <label
          className="help-message margin-left-10-percent margin-right-10-percent"
          hidden={showInputNumber < 2}
        >
          and
        </label>
        <input
          name={maxName}
          type="number"
          onChange={({ target }) => setMaxNum(target.value)}
          className="custom-number-input-short"
          value={maxNum}
          onFocus={() => {}}
          hidden={showInputNumber < 2}
        />
      </div>
    </div>
  );
}
