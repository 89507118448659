import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { createAction, useDeviceDetect } from '../../../utils';
import { Controller, useFormContext } from 'react-hook-form';
import ContentSections from '../../../components/base/ContentSections';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import DeletePrompt from '../../../components/base/DeletePrompt';
import { useDispatch, connect } from 'react-redux';
// import PersonalInfoEditCard from '../../../components/customer/PersonalInfoEditCard';
import MembershipInfoEditCard from '../../../components/customer/MembershipInfoEditCard';
import { PermissionCodes } from '../../../config/PermissionCodes';
import {
  CheckStatus,
  DELETE_RELATED_SECTIONS,
  SavedStatus,
} from '../../../config/CustomEnums';
import { CustomTitleWithDropDown } from '../../../components/customer/CustomTitleWithDropDown';
import CheckboxItem from '../../../components/coupon/couponList/CheckboxItem';

function PersonalInfoEditCard({ title }) {
  const { setValue, control, formState } = useFormContext();
  return (
    <div>
      <label className="create-section-title">{title}</label>

      <Controller
        control={control}
        name="salutation"
        render={({ field: { value } }) => (
          <CustomTitleWithDropDown
            title={'Salutation (Optional)'}
            source={[
              { label: 'Mr', value: 'MR' },
              { label: 'Ms', value: 'MS' },
              { label: 'Mrs', value: 'MRS' },
            ]}
            defaultValue={value}
            setValue={(value) => {
              setValue('salutation', value, { shouldDirty: true });
            }}
            needFilter={false}
          />
        )}
      />
    </div>
  );
}

const CustomerEditFormStepOne = ({ customer, interestPreferences }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [actionType, setActionType] = useState('Delete');
  const [remarks, setRemarks] = useState('');
  const [confirmed, setConfirmed] = useState(false);
  const { getValues, trigger, setError, clearErrors, setFocus } =
    useFormContext();

  const sections = [
    <PersonalInfoEditCard title="Personal Information" />,
    <MembershipInfoEditCard
      title="Membership Information"
      customer={customer}
    />,
  ];

  const otherBody = (data) => {
    return (
      <>
        <div className="customer-action-remarks">
          <textarea
            onChange={({ target }) => {
              setRemarks(target.value);
            }}
            className={`custom-textarea-remakrs`}
            onFocus={() => {}}
            value={remarks}
            placeholder="Please input remark"
          />
          {actionType === 'Edit' ? (
            <CheckboxItem
              title="I confirm I had verified the customer identity before performing this request"
              checked={confirmed}
              onChange={() => {
                setConfirmed(!confirmed);
              }}
            />
          ) : null}
        </div>
      </>
    );
  };

  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <SaveAndBackButtons
        baseTempSaveArea="customer-bottom-buttons"
        saveTempText="Delete"
        saveText={'Update'}
        backAction={() => history.goBack()}
        saveAction={async () => {
          console.log('@103: ', getValues());
          // return;
          let isValid = await trigger(
            ['removedCoupons', 'referrerUser', 'isForcedInactive'],
            { shouldFocus: true },
          );
          if (isValid) {
            setShowDeletePrompt(true);
            setActionType('Edit');
          }
        }}
        saveTempAction={() => {
          setShowDeletePrompt(true);
          setActionType('Delete');
        }}
        tempRequires={PermissionCodes.deleteCustomer}
      />
      <DeletePrompt
        message={{
          // title: 'Delete this Customer',
          // content: 'Did you confirm to delete this customer?',
          otherBody,
        }}
        model={'customerList'}
        title={'Customer'}
        data={customer}
        relatedSections={DELETE_RELATED_SECTIONS.CUSTOMER}
        inputRemarks={true}
        remarks={(actionType === 'Edit' ? confirmed : true) && remarks}
        actionType={actionType}
        show={showDeletePrompt}
        onClose={() => {
          setShowDeletePrompt(false);
          setRemarks('');
        }}
        onConfirm={() => {
          setShowDeletePrompt(false);
          if (actionType !== 'Delete') {
            dispatch(
              createAction('customerList/updateCustomer')({
                data: { ...getValues(), remarks },
              }),
            );
          } else {
            dispatch({
              type: `customerList/updateState`,
              payload: { checkedList: [customer] },
            });
            dispatch(
              createAction('customerList/delete')({
                remarks: remarks,
                afterAction: () => {
                  history.push('/customers#List%20of%20Customers');
                },
              }),
            );
          }
          setRemarks('');
        }}
      />
    </>
  );
};

const mapPropsToState = (state) => ({
  customer: state.customerList.customer || {},
});

export default connect(mapPropsToState)(CustomerEditFormStepOne);
