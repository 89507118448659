import React, { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import BaseForm from '../../../components/base/v2/BaseForm';
import ManageSearchContent from '../../../components/manageSearch/ManageSearchContent';

const ManageSearch = ({
  searchSettingsData,
  formHasSubmitted,
  hasUpdatedDefaultValues,
}) => {
  const dispatch = useDispatch();

  const clearData = () => {
    dispatch({ type: 'searchSetting/clearData' });
  };

  useEffect(() => {
    clearData();
    dispatch({ type: 'searchSetting/getOneDetail', payload: {} });

    return () => {
      clearData();
    };
  }, []);

  return (
    <>
      <BaseForm
        defaultValues={searchSettingsData || {}}
        formHasSubmitted={formHasSubmitted}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        content={[<ManageSearchContent />]}
        currentStep={0}
        breadcrumb={<CustomBreadcrumb />}
        caution={{
          title: 'Manage search function',
        }}
      />
    </>
  );
};
const mapPropsToState = (state) => ({
  searchSettingsData: state.searchSetting.detail,
  formHasSubmitted: state.searchSetting.formHasSubmitted,
  hasUpdatedDefaultValues: state.searchSetting.hasUpdatedDefaultValues,
});
export default connect(mapPropsToState)(ManageSearch);
