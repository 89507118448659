import React, { useEffect } from 'react';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CreateGeneralAlertStepOne from '../../../components/message/CreateGeneralAlertStepOne';
import CreateGeneralAlertStepTwo from '../../../components/message/CreateGeneralAlertStepTwo';
import CreateGeneralAlertStepThree from '../../../components/message/CreateGeneralAlertStepThree';
import { createAction } from '../../../utils';
import { useParams, useHistory } from 'react-router-dom';
import { sessionDataKey } from '../../../models/GeneralAlertModel';
import queryString from 'query-string';
import {
  getCmapaignCreateSucessStepBar,
  NOTIFY_YOUR_CUSTOMER,
} from '../../../components/base/prompt/NextStepConfig';
import { URLFragments } from '../../../components/campaign/CampaignUrlConfig';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { connect } from 'react-redux';
import './CreateGeneralAlert.scss';
import Loading from '../../../components/base/Loading';
import BaseForm from '../../../components/base/v2/BaseForm';
import { useDispatch } from 'react-redux';
import {
  saveGeneralAlertToSessionStorage,
  removeGeneralAlertSessionStroage,
} from '../../../models/GeneralAlertModel';

const CreateGeneralAlert = ({
  hasUpdatedDefaultValues,
  formHasSubmitted,
  defaultValues,
  stepConfig,
  currentStep,
}) => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const search = queryString.parse(history.location.search);
  const { id } = params;

  useEffect(() => {
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
    if (id) {
      dispatch(createAction('generalAlert/getOneDetail')({ id }));
    } else {
      dispatch(createAction('generalAlert/loadGeneralAlertFromCookie')());
    }
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(createAction('generalAlert/clearData')());
    };
  }, []);

  const stepContent = defaultValues
    ? [
        <CreateGeneralAlertStepOne />,
        <CreateGeneralAlertStepTwo />,
        <CreateGeneralAlertStepThree />,
      ]
    : [<Loading />];

  const getButtonsWithType = () => {
    const backGoMessageList = {
      text: 'Go to General Alert section',
      action: () =>
        history.push({
          pathname: '/general_alert',
          search: queryString.stringify({
            page: 1,
            rank: true,
            search: '',
          }),
        }),
      requires: PermissionCodes.changeMessage,
    };
    const buttons = [backGoMessageList];
    let text = 'Back to campaign listing page';
    let locationParm = {
      pathname: '/campaigns',
      hash: URLFragments.list,
    };

    buttons.unshift({ text: text, action: () => history.push(locationParm) });
    return buttons;
  };

  return (
    <>
      <BaseForm
        defaultValues={defaultValues}
        formHasSubmitted={formHasSubmitted}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        tempSave={(save, getValues) => {
          if (save) {
            saveGeneralAlertToSessionStorage(getValues());
          } else {
            removeGeneralAlertSessionStroage();
          }
        }}
        nextStepConfig={{
          title: 'Message Created!',
          description:
            'Campaign and message are ready. Come back later to check the campaign result.',
          steps: {
            stepNames: getCmapaignCreateSucessStepBar(search.type, search?.id),
            current: NOTIFY_YOUR_CUSTOMER,
          },
          buttons: getButtonsWithType(),
        }}
        sessionDataKey={sessionDataKey}
        content={stepContent}
        currentStep={currentStep}
        breadcrumb={
          <CustomBreadcrumb
            name={`${
              params.id
                ? defaultValues.content && defaultValues.content !== ''
                  ? defaultValues.content
                  : 'Edit Message'
                : 'Create Message'
            }`}
            nameIsHtml
          />
        }
        stepsConfig={stepConfig}
        caution={{
          detail: '',
          title: `${
            params.id
              ? defaultValues.content && defaultValues.content !== ''
                ? defaultValues.content
                : 'Edit Message'
              : 'Create Message'
          }`,
          titleIsHtml: true,
          model: 'generalAlert',
        }}
      />
    </>
  );
};
const mapPropsToState = (state) => ({
  defaultValues: state.generalAlert.detail,
  hasUpdatedDefaultValues: state.generalAlert.hasUpdatedDefaultValues,
  formHasSubmitted: state.generalAlert.formHasSubmitted,
  stepConfig: state.generalAlert.stepConfig,
  currentStep: state.generalAlert.currentStep,
});

export default connect(mapPropsToState)(CreateGeneralAlert);
