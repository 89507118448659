import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { createAction } from '../../../utils';
import './ExportRankingAndPerformanceReport.scss';
import i18n from '../../../I18n';
import PromptButton from '../../base/prompt/BaseButtons';
import { BUTTON_TYPES } from '../../base/prompt/BaseButtons';
import BasePrompt from '../../base/prompt/BasePrompt';
import CustomSwitchButton from '../../base/CustomSwitchButton';
import CustomRadios from '../../../components/base/CustomRadios';
import BaseMutipleSelectorV2 from '../BaseMultipleSelectorV2';
import { ImportResource } from '../../../models/DownloadImportModel';
import Loading from '../Loading';
import { CustomTitleWithRadio } from '../../earning/CustomBaseComponments';
import { CampaignType } from '../../../config/CustomEnums';

const FROM_MODEL = {
  goodiebag: 'GOODIE_BAG',
  campaign: 'CAMPAIGN',
  coupon: 'COUPON',
  goodiebagCampaign: 'GOODIE_BAG_CAMPAIGN',
};

const GOODIEBAG_REPORT_EXPORT_BY = {
  brand: 'BY_BRAND',
  rewardType: 'BY_REWARD_TYPE',
  entitlementMethod: 'BY_ENTITLEMENT_METHOD',
};

export const SELECT_CAMPAIGN_BY_CONFIG = {
  brands: {
    label: 'Brands',
    value: 'Select Brands',
  },
  attributeTags: {
    label: 'Attribute tags (by Key tags)',
    value: 'Select Key tags or Attribute tags',
  },
  campaigns: {
    label: 'Campaigns (by Brands)',
    value: 'Select Brands or Campaigns',
  },
  couponSets: {
    label: 'Coupon sets (by Brands)',
    value: 'Select Brands or Coupon set',
  },
};

export const SELECT_GOODIE_BAG_EXPORT_LEVEL = {
  goodieBags: {
    label: 'Goodie bag',
    value: FROM_MODEL.goodiebag,
  },
  goodieBagCampaigns: {
    label:
      'Goodie bag related campaign(Cash Voucher+Goodie Bag Coupon+Goodie bag offer (Direct assign))',
    value: FROM_MODEL.goodiebagCampaign,
  },
};

export const SELECT_GOODIE_BAG_BY = {
  brand: {
    label: 'Entitlement method',
    value: GOODIEBAG_REPORT_EXPORT_BY.brand,
  },
  rewardType: {
    label: 'Reward Type',
    value: GOODIEBAG_REPORT_EXPORT_BY.rewardType,
  },
};

export const SELECT_STARRED_CAMPAIGN_OPTIONS = {
  all: {
    label: 'All campaign',
    value: 'all',
  },
  starred: {
    label: 'Starred campaign',
    value: 'true',
  },
  nonStarred: {
    label: 'Non-Starred campaign',
    value: 'false',
  },
};

const ExportRankingAndPerformanceReport = ({
  brandList,
  attributeTagList,
  campaignList,
  couponSetList,
  overviewDate,
  preSelectedBrand,
  preSelectedCampaign,
  preSelectedCouponSet,
  showExportRakingReportPrompt,
  setShowExportRakingReportPrompt = () => {},
  showExportPerformanceReportPrompt,
  setShowExportPerformanceReportPrompt = () => {},
  fromModel,
  preSelectedGoodieBags,
  preSelectedIsStarred,
  goodiebagList,
}) => {
  if (fromModel === FROM_MODEL.goodiebag) {
    SELECT_CAMPAIGN_BY_CONFIG.goodieBags = {
      label: 'Goodie bag (by entitlement method)',
      value: 'Select Goodie bag',
    };
    SELECT_CAMPAIGN_BY_CONFIG.goodieBagsRewardType = {
      label: 'Goodie bag (by reward type)',
      value: 'Select Goodie bag by reward type',
    };
  } else {
    if (SELECT_CAMPAIGN_BY_CONFIG.hasOwnProperty('goodieBags')) {
      delete SELECT_CAMPAIGN_BY_CONFIG['goodieBags'];
    }
    if (SELECT_CAMPAIGN_BY_CONFIG.hasOwnProperty('goodieBagsRewardType')) {
      delete SELECT_CAMPAIGN_BY_CONFIG['goodieBagsRewardType'];
    }
  }

  const dispatch = useDispatch();
  const [rakingReportSeparate, setRakingReportSeparateFiles] = useState(false);
  const [selectCampaignBy, setSelectCampaignBy] = useState(
    SELECT_CAMPAIGN_BY_CONFIG.brands.value,
  );
  const [performanceReportSeparate, setPerformanceReportSeparateFiles] =
    useState(false);
  const [selectedRakingBrand, setSelectedRakingBrand] = useState([]);
  const [selectedPerformanceBrand, setSelectedPerformanceBrand] = useState([]);
  const [selectedAttributeTag, setSelectedAttributeTag] = useState([]);
  const [selectedPerformanceCampaign, setSelectedPerformanceCampaign] =
    useState([]);
  const [selectedCouponSetList, setSelectedCouponSetList] = useState([]);

  const [selectedReportLevel, setSelectedReportLevel] = useState(
    fromModel === FROM_MODEL.goodiebag
      ? SELECT_GOODIE_BAG_EXPORT_LEVEL.goodieBags.value
      : null,
  );
  const [selectedBy, setSelectedBy] = useState();
  const [selectedIsStarred, setSelectedIsStarred] = useState(
    SELECT_STARRED_CAMPAIGN_OPTIONS.all.value,
  );
  const [selectedGoodieBagList, setSelectedGoodieBagList] = useState([]);
  const [selectedGoodiebagByRewardType, setSelectedGoodiebagByRewardType] =
    useState([]);

  const [selectedRakingGoodiebag, setSelectedRakingGoodiebag] = useState([]);

  const getDateRangeString = (startDate, endDate) => {
    if (startDate && endDate) {
      const startYear = startDate.year();
      const endYear = endDate.year();
      if (startYear === endYear) {
        return `${startDate.format('D MMM')} - ${endDate.format(
          'D MMM, YYYY',
        )}`;
      } else {
        return `${startDate.format('D MMM, YYYY')} - ${endDate.format(
          'D MMM, YYYY',
        )}`;
      }
    }
    return '';
  };

  useEffect(() => {
    // dispatch({ type: 'brand/getAllList' });
    // dispatch({ type: 'attributeTag/getAllList' });
    // dispatch({ type: 'campaignList/getAllList', payload: { pageSize: 1000 } });
    // dispatch({ type: 'couponList/getAllList', payload: { pageSize: 1000 } });
  }, []);

  useEffect(() => {
    if (showExportRakingReportPrompt) {
      setSelectedBy(GOODIEBAG_REPORT_EXPORT_BY.brand);

      dispatch({ type: 'brand/getAllList' });
      dispatch({ type: 'goodieBags/getAllList' });
    }
    return () => {
      dispatch({ type: 'brand/clearData' });
      dispatch({ type: 'goodieBags/clearData' });
    };
  }, [showExportRakingReportPrompt]);

  useEffect(() => {
    if (showExportPerformanceReportPrompt) {
      setSelectedBy(
        fromModel === FROM_MODEL.goodiebag
          ? GOODIEBAG_REPORT_EXPORT_BY.entitlementMethod
          : null,
      );

      dispatch({ type: 'brand/getAllList' });
      dispatch({ type: 'attributeTag/getAllList' });
      dispatch({
        type: 'campaignList/getAllList',
        payload: {
          pageSize: 1000,
          others:
            fromModel === FROM_MODEL.goodiebag
              ? {
                  typeIn: `${CampaignType.cashVoucherCampaign},${CampaignType.giftCampaign}`,
                }
              : {},
        },
      });
      dispatch({
        type: 'couponList/getAllList',
        payload: { pageSize: 1000, getAll: 'false' },
      });
      dispatch({ type: 'goodieBags/getAllList', payload: { pageSize: 50 } });
    }
    return () => {
      dispatch({ type: 'brand/clearData' });
      dispatch({ type: 'attributeTag/clearData' });
      dispatch({ type: 'campaignList/clearData' });
      dispatch({ type: 'couponList/clearData' });
      dispatch({ type: 'goodieBags/clearData' });
    };
  }, [showExportPerformanceReportPrompt]);

  useEffect(() => {
    if (Array.isArray(preSelectedCampaign)) {
      setSelectedPerformanceCampaign(preSelectedCampaign);
    } else if (preSelectedCampaign?.value) {
      setSelectedPerformanceCampaign([preSelectedCampaign]);
    } else {
      setSelectedPerformanceCampaign([]);
    }
  }, [preSelectedCampaign]);

  const getExportRakingReportPrompt = () => {
    return (
      <div
        className="raking-report-prompt-other-body"
        style={{ marginTop: '-20px' }}
      >
        {fromModel === FROM_MODEL.goodiebag ? (
          <>
            <CustomTitleWithRadio
              title="Select exported level"
              options={Object.values(SELECT_GOODIE_BAG_EXPORT_LEVEL)}
              defaultValue={selectedReportLevel}
              setValue={setSelectedReportLevel}
            />
            <CustomTitleWithRadio
              title="Select by"
              options={Object.values(SELECT_GOODIE_BAG_BY)}
              defaultValue={selectedBy}
              setValue={setSelectedBy}
            />
          </>
        ) : null}

        <div className="other-body-multi-selector">
          {selectedBy === SELECT_GOODIE_BAG_BY.brand.value ? (
            <BaseMutipleSelectorV2
              size={['shorter']}
              title={
                selectedReportLevel ===
                SELECT_GOODIE_BAG_EXPORT_LEVEL.goodieBags.value
                  ? 'Select Target Merchant (OTA)'
                  : 'Select Brands'
              }
              namespace="brand"
              searchPlaceholder={
                selectedReportLevel ===
                SELECT_GOODIE_BAG_EXPORT_LEVEL.goodieBags.value
                  ? 'Search by OTA ID, name'
                  : 'Search by brand ID, name'
              }
              data={{
                sourceData:
                  fromModel === FROM_MODEL.goodiebag
                    ? brandList.filter(
                        (item) =>
                          item?.handleFortuneBagEntitlement &&
                          item?.handleOption.value,
                      )
                    : brandList,
                targetData: selectedRakingBrand,
                targetChange: setSelectedRakingBrand,
              }}
              groupKey={
                fromModel === FROM_MODEL.goodiebag ? 'handleOptionTitle' : ''
              }
              custom={{
                // customItem: (value) => `[${value?.pk}] ${value?.name}`,
                customFilter: (inputValue, option) => {
                  const inputIgnoreCase = inputValue?.toLowerCase();
                  const optionIgnoreCase = option?.name?.toLowerCase();
                  return (
                    optionIgnoreCase?.includes(inputIgnoreCase) ||
                    option.pk == inputValue
                  );
                },
              }}
              customTree={{
                renderChildrenTitle: (value) =>
                  `[ID:${value?.pk}] ${value?.name}`,
              }}
            />
          ) : (
            <BaseMutipleSelectorV2
              size={['shorter']}
              title={'Select Goodie bag'}
              namespace="goodieBags"
              searchPlaceholder={'Search by Goodie bag ID, name'}
              data={{
                sourceData: goodiebagList,
                targetData: selectedRakingGoodiebag,
                targetChange: setSelectedRakingGoodiebag,
              }}
              groupKey={'handleRewardType'}
              custom={{
                // customItem: (value) => `[${value?.pk}] ${value?.name}`,
                customFilter: (inputValue, option) => {
                  const inputIgnoreCase = inputValue?.toLowerCase();
                  const optionIgnoreCase = option?.name?.toLowerCase();
                  return (
                    optionIgnoreCase?.includes(inputIgnoreCase) ||
                    option.pk == inputValue
                  );
                },
              }}
              customTree={{
                renderChildrenTitle: (value) =>
                  `[ID:${value?.pk}] ${value?.name}`,
              }}
            />
          )}
        </div>
        {selectedReportLevel !==
        SELECT_GOODIE_BAG_EXPORT_LEVEL.goodieBags.value ? (
          <CustomTitleWithRadio
            title="Filter by starred campaign"
            options={Object.values(SELECT_STARRED_CAMPAIGN_OPTIONS)}
            defaultValue={selectedIsStarred}
            setValue={setSelectedIsStarred}
          />
        ) : null}

        {selectedBy === SELECT_GOODIE_BAG_BY.rewardType.value ? null : (
          <>
            <div className="other-body-title">
              Export in separate files by{' '}
              {fromModel === FROM_MODEL.goodiebag
                ? 'Target Merchant (OTA)'
                : 'brand(s)'}
            </div>
            <div className="other-body-discription">
              If On, multiple files will be downloaded based on different{' '}
              {fromModel === FROM_MODEL.goodiebag
                ? 'Target Merchant (OTA)'
                : 'brands'}
              . If off, all the data merge into one file.
            </div>
            <CustomSwitchButton
              defaultChecked={rakingReportSeparate || false}
              onChange={(isChecked) => {
                setRakingReportSeparateFiles(isChecked);
              }}
            />
          </>
        )}
      </div>
    );
  };

  const getPerformanceReportMultiSelected = () => {
    console.log('@@244: ', selectedGoodieBagList, selectedPerformanceCampaign);
    switch (selectCampaignBy) {
      case SELECT_CAMPAIGN_BY_CONFIG.brands.value:
        return (
          <div className="other-body-multi-selector">
            <>
              <BaseMutipleSelectorV2
                title={SELECT_CAMPAIGN_BY_CONFIG.brands.value}
                size={['shorter']}
                namespace="brand"
                searchPlaceholder="Search by brand name"
                data={{
                  sourceData: brandList,
                  targetData: selectedPerformanceBrand,
                  targetChange: setSelectedPerformanceBrand,
                }}
              />
            </>
          </div>
        );
      case SELECT_CAMPAIGN_BY_CONFIG.attributeTags.value:
        return (
          <div className="other-body-multi-selector">
            <BaseMutipleSelectorV2
              size={['shorter', 'wider']}
              title={SELECT_CAMPAIGN_BY_CONFIG.attributeTags.value}
              namespace="attributeTag"
              searchPlaceholder="Search by key tag, attribute tag"
              groupKey={'tagKey'}
              custom={{
                customFilter: (inputValue, option) => {
                  const inputIgnoreCase = inputValue?.toLowerCase();
                  const optionIgnoreCase = option?.name?.toLowerCase();
                  const groupIgnoreCase = option?.tagKey?.name?.toLowerCase();
                  return (
                    optionIgnoreCase?.includes(inputIgnoreCase) ||
                    groupIgnoreCase?.includes(inputIgnoreCase)
                  );
                },
              }}
              data={{
                sourceData: attributeTagList,
                targetData: selectedAttributeTag,
                targetChange: setSelectedAttributeTag,
              }}
            />
          </div>
        );
      case SELECT_CAMPAIGN_BY_CONFIG.campaigns.value:
        return (
          <div className="other-body-multi-selector">
            <BaseMutipleSelectorV2
              size={['shorter', 'wider']}
              title={SELECT_CAMPAIGN_BY_CONFIG.campaigns.value}
              namespace="campaignList"
              searchPlaceholder="Search by campaign ID, name"
              groupKey={'relatedBrand'}
              custom={{
                customFilter: (inputValue, option) => {
                  const inputIgnoreCase = inputValue?.toLowerCase();
                  const optionIgnoreCase = option?.name?.toLowerCase();
                  return (
                    optionIgnoreCase?.includes(inputIgnoreCase) ||
                    option.pk == inputValue
                  );
                },
              }}
              data={{
                sourceData: campaignList,
                targetData: selectedPerformanceCampaign,
                targetChange: setSelectedPerformanceCampaign,
              }}
              customTree={{ treeHeight: 252 }}
            />
          </div>
        );
      case SELECT_CAMPAIGN_BY_CONFIG.couponSets.value:
        return (
          <div className="other-body-multi-selector">
            <BaseMutipleSelectorV2
              size={['shorter', 'wider']}
              title={SELECT_CAMPAIGN_BY_CONFIG.couponSets.value}
              namespace="couponList"
              searchPlaceholder="Search by coupon set ID, name"
              groupKey={'selectedBrand'}
              data={{
                sourceData: couponSetList,
                targetData: selectedCouponSetList,
                targetChange: setSelectedCouponSetList,
              }}
              custom={{
                customItem: (value) => `[${value?.pk}] ${value?.name}`,
                customFilter: (inputValue, option) => {
                  const inputIgnoreCase = inputValue?.toLowerCase();
                  const optionIgnoreCase = option?.name?.toLowerCase();
                  return (
                    optionIgnoreCase?.includes(inputIgnoreCase) ||
                    option.pk == inputValue
                  );
                },
              }}
              customTree={{ treeHeight: 252 }}
            />
          </div>
        );
      case SELECT_CAMPAIGN_BY_CONFIG.goodieBags?.value:
        return (
          <div className="other-body-multi-selector">
            <BaseMutipleSelectorV2
              size={['shorter', 'wider']}
              title={SELECT_CAMPAIGN_BY_CONFIG.goodieBags?.value}
              namespace="goodieBags"
              searchPlaceholder="Search by Goodie bag ID, name"
              groupKey={'handleOptionTitle'}
              data={{
                sourceData: goodiebagList,
                targetData: selectedGoodieBagList,
                targetChange: setSelectedGoodieBagList,
              }}
              custom={{
                customItem: (value) => `[${value?.pk}] ${value?.name}`,
                customFilter: (inputValue, option) => {
                  const inputIgnoreCase = inputValue?.toLowerCase();
                  const optionIgnoreCase = option?.name?.toLowerCase();
                  return (
                    optionIgnoreCase?.includes(inputIgnoreCase) ||
                    option.pk == inputValue
                  );
                },
              }}
              customTree={{
                renderChildrenTitle: (value) =>
                  `[ID: ${value?.pk}] ${value?.name}`,
              }}
            />
          </div>
        );
      case SELECT_CAMPAIGN_BY_CONFIG.goodieBagsRewardType?.value:
        return (
          <div className="other-body-multi-selector">
            <BaseMutipleSelectorV2
              size={['shorter', 'wider']}
              title={SELECT_CAMPAIGN_BY_CONFIG.goodieBags?.value}
              namespace="goodieBags"
              searchPlaceholder="Search by Goodie bag ID, name"
              groupKey={'handleRewardType'}
              data={{
                sourceData: goodiebagList,
                targetData: selectedGoodiebagByRewardType,
                targetChange: setSelectedGoodiebagByRewardType,
              }}
              custom={{
                customItem: (value) => `[${value?.pk}] ${value?.name}`,
                customFilter: (inputValue, option) => {
                  const inputIgnoreCase = inputValue?.toLowerCase();
                  const optionIgnoreCase = option?.name?.toLowerCase();
                  return (
                    optionIgnoreCase?.includes(inputIgnoreCase) ||
                    option.pk == inputValue
                  );
                },
              }}
              customTree={{
                renderChildrenTitle: (value) =>
                  `[ID: ${value?.pk}] ${value?.name}`,
              }}
            />
          </div>
        );
      default:
        return <></>;
    }
  };

  const getExportPerformanceReportPrompt = () => {
    return (
      <div className="performance-report-prompt-other-body">
        {fromModel === FROM_MODEL.goodiebag ? (
          <>
            <CustomTitleWithRadio
              title="Select exported level"
              options={Object.values(SELECT_GOODIE_BAG_EXPORT_LEVEL)}
              defaultValue={selectedReportLevel}
              setValue={setSelectedReportLevel}
            />
            {selectedReportLevel ===
            SELECT_GOODIE_BAG_EXPORT_LEVEL.goodieBags.value ? (
              <CustomTitleWithRadio
                title="Select Goodie bag by"
                options={[
                  {
                    label: 'Entitlement method',
                    value: GOODIEBAG_REPORT_EXPORT_BY.entitlementMethod,
                  },
                  {
                    label: 'Reward Type',
                    value: GOODIEBAG_REPORT_EXPORT_BY.rewardType,
                  },
                ]}
                defaultValue={selectedBy}
                setValue={(v) => {
                  setSelectedBy(v);
                  if (v === GOODIEBAG_REPORT_EXPORT_BY.rewardType) {
                    setSelectCampaignBy(
                      SELECT_CAMPAIGN_BY_CONFIG.goodieBagsRewardType.value,
                    );
                  } else {
                    setSelectCampaignBy(
                      SELECT_CAMPAIGN_BY_CONFIG.goodieBags.value,
                    );
                  }
                }}
              />
            ) : null}
          </>
        ) : null}
        {selectedReportLevel !==
        SELECT_GOODIE_BAG_EXPORT_LEVEL.goodieBags.value ? (
          <>
            <div className="other-body-title">Select campaign by</div>
            <div className="custom-radios-selector">
              <CustomRadios
                onChange={(value) => {
                  setSelectCampaignBy(value);
                }}
                default={selectCampaignBy}
                options={Object.values(SELECT_CAMPAIGN_BY_CONFIG)}
              />
            </div>
          </>
        ) : null}

        <div className="other-body-multi-selector">
          {getPerformanceReportMultiSelected()}
        </div>

        {selectedReportLevel !==
        SELECT_GOODIE_BAG_EXPORT_LEVEL.goodieBags.value ? (
          <CustomTitleWithRadio
            title="Filter by starred campaign"
            options={Object.values(SELECT_STARRED_CAMPAIGN_OPTIONS)}
            defaultValue={selectedIsStarred}
            setValue={setSelectedIsStarred}
          />
        ) : null}

        <div className="other-body-title">
          Export in separate files by{' '}
          {selectedReportLevel ===
          SELECT_GOODIE_BAG_EXPORT_LEVEL.goodieBags.value
            ? 'Goodie bag'
            : 'brand(s)'}
        </div>
        <div className="other-body-discription">
          If On, multiple files will be downloaded based on different{' '}
          {selectedReportLevel ===
          SELECT_GOODIE_BAG_EXPORT_LEVEL.goodieBags.value
            ? 'Goodie bags'
            : 'brands'}
          . If off, all the data merge into one file.
        </div>
        <CustomSwitchButton
          defaultChecked={performanceReportSeparate || false}
          onChange={(isChecked) => {
            setPerformanceReportSeparateFiles(isChecked);
          }}
        />
      </div>
    );
  };

  return (
    <>
      <div className="coupon-overview-export-button">
        <PromptButton
          disabled={
            fromModel === 'CAMPAIGN' && selectedPerformanceCampaign.length > 0
              ? true
              : false
          }
          title="Export Ranking Report"
          action={() => {
            const newSelectedRakingBrand = preSelectedBrand?.value
              ? [preSelectedBrand.value]
              : [];
            setSelectedRakingBrand(newSelectedRakingBrand);
            if (preSelectedIsStarred) {
              setSelectedIsStarred(preSelectedIsStarred);
            }

            setShowExportRakingReportPrompt(true);
          }}
          className="export-raking-report-button"
          type={BUTTON_TYPES.secondary}
        />
        <PromptButton
          title="Export Performance Report"
          action={() => {
            const newSelectedPerformanceBrand = preSelectedBrand?.value
              ? [preSelectedBrand.value]
              : [];
            setSelectedPerformanceBrand(newSelectedPerformanceBrand);
            // if (preSelectedBrand?.value) {
            //   const brandContained =
            //     selectedPerformanceBrand?.filter((item) => {
            //       return item.pk === preSelectedBrand.value?.pk;
            //     })?.length >= 1;
            //   if (!brandContained) {
            //     setSelectedPerformanceBrand([
            //       ...selectedPerformanceBrand,
            //       preSelectedBrand.value,
            //     ]);
            //   }
            // }
            // const newSelectedPerformanceCampaign = preSelectedCampaign?.value
            //   ? [preSelectedCampaign.value]
            //   : [];
            // setSelectedPerformanceCampaign(newSelectedPerformanceCampaign);
            if (
              (Array.isArray(preSelectedCampaign) &&
                preSelectedCampaign?.length > 0) ||
              (!Array.isArray(preSelectedCampaign) &&
                preSelectedCampaign?.value)
            ) {
              setSelectCampaignBy(SELECT_CAMPAIGN_BY_CONFIG.campaigns.value);
              setSelectedPerformanceCampaign(
                Array.isArray(preSelectedCampaign)
                  ? preSelectedCampaign
                  : preSelectedCampaign?.value
                  ? [preSelectedCampaign?.value]
                  : [],
              );
            }
            // if (preSelectedCampaign?.value) {
            //   const campaignContained =
            //     selectedPerformanceCampaign?.filter((item) => {
            //       return item.pk === preSelectedCampaign.value?.pk;
            //     })?.length >= 1;
            //   if (!campaignContained) {
            //     setSelectedPerformanceCampaign([
            //       ...selectedPerformanceCampaign,
            //       preSelectedCampaign.value,
            //     ]);
            //   }
            //   setSelectCampaignBy(SELECT_CAMPAIGN_BY_CONFIG.campaigns.value);
            // }
            const newSelectedCouponSetList = preSelectedCouponSet?.value
              ? [preSelectedCouponSet?.value]
              : [];
            setSelectedCouponSetList(newSelectedCouponSetList);
            if (preSelectedCouponSet?.value) {
              setSelectCampaignBy(SELECT_CAMPAIGN_BY_CONFIG.couponSets.value);
            }

            if (preSelectedGoodieBags?.length > 0) {
              setSelectedGoodieBagList(preSelectedGoodieBags);
              setSelectedGoodiebagByRewardType(preSelectedGoodieBags);
              setSelectCampaignBy(SELECT_CAMPAIGN_BY_CONFIG.goodieBags?.value);
            }

            if (fromModel === FROM_MODEL.goodiebag) {
              setSelectCampaignBy(SELECT_CAMPAIGN_BY_CONFIG.goodieBags?.value);
            }
            // if (preSelectedCouponSet?.value) {
            //   const couponSetContained =
            //     selectedCouponSetList?.filter((item) => {
            //       return item.pk === preSelectedCouponSet.value?.pk;
            //     })?.length >= 1;
            //   if (!couponSetContained) {
            //     setSelectedCouponSetList([
            //       ...selectedCouponSetList,
            //       preSelectedCouponSet.value,
            //     ]);
            //   }
            // }

            if (preSelectedIsStarred) {
              setSelectedIsStarred(preSelectedIsStarred);
            }

            setShowExportPerformanceReportPrompt(true);
          }}
          className="export-performance-reprot-button"
          type={BUTTON_TYPES.secondary}
        />
      </div>
      <BasePrompt
        show={showExportRakingReportPrompt}
        closeAction={() => setShowExportRakingReportPrompt(false)}
        rightButton={{
          text: 'Export',
          action: () => {
            setTimeout(() => {
              console.log(
                '@200',
                fromModel,
                selectedReportLevel,
                fromModel === fromModel.goodiebag,
                fromModel.goodiebag,
              );
              dispatch(
                createAction('downloadAndImport/createDownloadTask')({
                  from: ImportResource.couponRakingExport,
                  related: {
                    fromModel:
                      fromModel === FROM_MODEL.goodiebag
                        ? selectedReportLevel
                        : fromModel,
                    fromDate: overviewDate?.startDate,
                    toDate: overviewDate?.endDate,
                    separateFiles: rakingReportSeparate,
                    brands: selectedRakingBrand?.map((item) => item.pk),
                    goodieBags: selectedRakingGoodiebag?.map((item) => item.pk),
                    exportBy: selectedBy?.replace('BY_', ''),
                    ...(selectedIsStarred === 'all'
                      ? {}
                      : {
                          campaignIsStarred:
                            selectedIsStarred === 'false'
                              ? false
                              : !!selectedIsStarred,
                        }),
                  },
                }),
              );
              setShowExportRakingReportPrompt(false);
            }, 1500);
          },
          disabled:
            (selectedBy === SELECT_GOODIE_BAG_BY.brand.value &&
              selectedRakingBrand?.length <= 0) ||
            (selectedBy === SELECT_GOODIE_BAG_BY.rewardType.value &&
              selectedRakingGoodiebag?.length <= 0),
        }}
        title={'Export Ranking Report (.xlsx)'}
        description={
          <div className="report-prompt-description">
            {`Export data within  ${getDateRangeString(
              overviewDate?.startDateWithoutFormat,
              overviewDate?.endDateWithoutFormat,
            )}`}
          </div>
        }
        otherBody={getExportRakingReportPrompt}
      />
      <BasePrompt
        show={showExportPerformanceReportPrompt}
        closeAction={() => setShowExportPerformanceReportPrompt(false)}
        rightButton={{
          text: 'Export',
          action: () => {
            setTimeout(() => {
              dispatch(
                createAction('downloadAndImport/createDownloadTask')({
                  from: ImportResource.campaignPerformanceExport,
                  related: {
                    fromModel:
                      fromModel === FROM_MODEL.goodiebag
                        ? selectedReportLevel
                        : fromModel,
                    fromDate: overviewDate?.startDate,
                    toDate: overviewDate?.endDate,
                    separateFiles: performanceReportSeparate,
                    brands:
                      selectCampaignBy ===
                      SELECT_CAMPAIGN_BY_CONFIG.brands.value
                        ? selectedPerformanceBrand?.map((item) => item.pk)
                        : null,
                    attributeTags:
                      selectCampaignBy ===
                      SELECT_CAMPAIGN_BY_CONFIG.attributeTags.value
                        ? selectedAttributeTag?.map((item) => item.pk)
                        : null,
                    campaigns:
                      selectCampaignBy ===
                      SELECT_CAMPAIGN_BY_CONFIG.campaigns.value
                        ? selectedPerformanceCampaign?.map((item) => item.pk)
                        : null,
                    couponTemplates:
                      selectCampaignBy ===
                      SELECT_CAMPAIGN_BY_CONFIG.couponSets.value
                        ? selectedCouponSetList?.map((item) => item.pk)
                        : null,
                    goodieBags:
                      selectCampaignBy ===
                      SELECT_CAMPAIGN_BY_CONFIG.goodieBags?.value
                        ? selectedGoodieBagList?.map((item) => item.pk)
                        : selectCampaignBy ===
                          SELECT_CAMPAIGN_BY_CONFIG.goodieBagsRewardType?.value
                        ? selectedGoodiebagByRewardType?.map((item) => item.pk)
                        : null,
                    exportBy: selectedBy?.replace('BY_', ''),
                    ...(selectedIsStarred === 'all'
                      ? {}
                      : {
                          campaignIsStarred:
                            selectedIsStarred === 'false'
                              ? false
                              : !!selectedIsStarred,
                        }),
                  },
                }),
              );
              setShowExportPerformanceReportPrompt(false);
            }, 1500);
          },
          disabled:
            (selectCampaignBy === SELECT_CAMPAIGN_BY_CONFIG.brands.value &&
              selectedPerformanceBrand?.length <= 0) ||
            (selectCampaignBy ===
              SELECT_CAMPAIGN_BY_CONFIG.attributeTags.value &&
              selectedAttributeTag?.length <= 0) ||
            (selectCampaignBy === SELECT_CAMPAIGN_BY_CONFIG.campaigns.value &&
              selectedPerformanceCampaign?.length <= 0) ||
            (selectCampaignBy === SELECT_CAMPAIGN_BY_CONFIG.couponSets.value &&
              selectedCouponSetList?.length <= 0) ||
            (selectCampaignBy === SELECT_CAMPAIGN_BY_CONFIG.goodieBags?.value &&
              selectedGoodieBagList?.length <= 0) ||
            (selectCampaignBy ===
              SELECT_CAMPAIGN_BY_CONFIG.goodieBagsRewardType?.value &&
              selectedGoodiebagByRewardType?.length <= 0),
        }}
        title={'Export Campaign Performance Report (.xlsx)'}
        description={
          <div className="report-prompt-description">
            {`Export data within  ${getDateRangeString(
              overviewDate?.startDateWithoutFormat,
              overviewDate?.endDateWithoutFormat,
            )}`}
          </div>
        }
        otherBody={getExportPerformanceReportPrompt}
      />
    </>
  );
};

const mapPropsToState = (state) => ({
  brandList: state.brand.notPagedAllList,
  attributeTagList: state.attributeTag?.notPagedAllList,
  campaignList: state.campaignList.notPagedAllList,
  couponSetList: state.couponList.notPagedAllList,
  goodiebagList: state.goodieBags.notPagedAllList,
});

export default connect(mapPropsToState)(ExportRankingAndPerformanceReport);
