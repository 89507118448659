import React from 'react';
import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import OverViewContent from '../../../components/goodieBagGroup/OverViewContent';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import ContentSections from '../../../components/base/ContentSections';
import CustomListComponent from '../../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import './GoodieBagGroupDetail.scss';
import {
  OnlyBackButton,
  SaveAndBackButtons,
} from '../../../components/base/BottomStepComponent';

function GoodieBagGroupDetail({ goodieBagGroup, languages }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch({ type: 'language/getAllList', payload: {} });
    dispatch({
      type: 'goodieBagGroups/getGoodieBagGroup',
      payload: { goodieBagGroupPK: id },
    });
    return ()=>{
      dispatch({
        type: 'goodieBagGroups/clearData',
        payload: {},
      });
    }
  }, [id]);

  const getLanguageTab = (language) => {
    return <OverViewContent language={language} />;
  };

  const buttons = [
    <AuthButton
      title="Edit"
      action={() => {
        history.push(`/goodie_bag_groups/${id}/edit`);
      }}
      requires={PermissionCodes.changeGoodieBagGroup}
    />,
  ];
  const tabs = [
    {
      name: '',
      content: (
        <div>
          <ContentSections
            languageTabContent={{
              containers: languages?.map((item) => ({
                container: [getLanguageTab(item.code)],
                key: item.code,
                title: item.sourceName,
              })),
            }}
            hidePreview
          />
        </div>
      ),
    },
  ];
  return (
    <>
      <CustomListComponent
        caution={{
          title: goodieBagGroup?.name,
        }}
        buttons={buttons}
        breadcrumb={<CustomBreadcrumb name={goodieBagGroup?.name} />}
        hideTab={true}
        tabs={tabs}
      />
      <OnlyBackButton
        backAction={() => {
          history.push({
            pathname: '/goodie_bag_groups',
          });
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  goodieBagGroup: state.goodieBagGroups.goodieBagGroup,
  languages: state.language.notPagedAllList,
});

export default connect(mapPropsToState)(GoodieBagGroupDetail);
