import { BaseHelper } from './BaseGraphQLAPIHelper';
import { PublishTagType } from '../config/CustomEnums';

const extraStoreField = (withDistrictTranslation) => {
  return `displayPriority
  address
  phoneNumberCountryCode
  phoneNumberSubscriberNumber
  latitude
  longitude
  emailAddress
  description
  codeDisplayImage
  codeDownloadImage
  codeDownloadImageA5
  isPublished
  isFollowBrandAttribute
  inWeekHours {
    edges {
        node {
            pk
            id
            weekDays {
                edges {
                    node {
                        pk
                    }
                }
            }
            isClose
            openHour
            closeHour
        }
    }
  }
  holidayHour {
    pk
    id
    isClose
    openHour
    closeHour
  }
  district {
      region {
          pk
          name
          ${
            withDistrictTranslation
              ? `translations(first: 20) {
            edges {
              node {
                language
                name
                pk
              }
            }
          }`
              : ''
          }
      }
      pk
      name
      ${
        withDistrictTranslation
          ? `translations(first: 20) {
        edges {
          node {
            language
            name
            pk
          }
        }
      }`
          : ''
      }
  }
  ${
    withDistrictTranslation
      ? ''
      : `brand {
    pk
    name
    attributeTags {
      edges {
          node {
              pk
              id
              name
              tagKey {
                  pk
                  id
                  name
              }
          }
      }
    }
}
attributeTags {
  edges {
      node {
          pk
          id
          name
          tagKey {
              pk
              id
              name
          }
      }
  }
}`
  }
  
  translations(first: 20) {
    edges {
      node {
        language
        name
        description
        address
        pk
      }
    }
  }`;
};

export const getStores = () => {
  const query = `{
        stores(first: 100) {
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          edges {
            cursor
            node {
              id
              pk
              name
              photo
              address
              translations(first: 3) {
                edges {
                  node {
                    language
                    name
                    pk
                  }
                }
              }
            }
          }
        }
      }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getStoresOfBrand = (brandID, afterCursor) => {
  const query = `{
    brand(id: "${brandID}", after: "${afterCursor}") {
      stores(first: 20) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            pk
            name
            photo
            address
            translations {
              edges {
                node {
                  language
                  name
                  pk
                }
              }
            }
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getPagedStores = (afterCursor, search = {}) => {
  console.log('getPagedStores search:', search);
  let searchString = '';
  if (search.search) {
    searchString += `, nameIcontains: "${search.search}"`;
  }
  if (search.subcategory) {
    searchString += `, subcategoriesIn: "${search.subcategory}"`;
  }
  if (search.attribute_tags) {
    searchString += `, attributeTagsIn: "${search.attribute_tags}"`;
  }
  if (search.status) {
    searchString += `, isPublished: ${
      search.status === PublishTagType.published
    }`;
  }
  if (search.brands?.[0] || search.brands) {
    searchString += `, brandIn: "${search.brands}"`;
  }
  if (search.campaignIn) {
    searchString += `, campaignIn: "${search.campaignIn}"`;
  }
  if (search.couponTemplateIn) {
    searchString += `, couponTemplateIn: "${search.couponTemplateIn}"`;
  }

  if (search.ids) {
    searchString += `, idIn: "${search.ids}"`;
  }

  let orderBy = 'displayPriority';
  if (search?.sort) {
    orderBy = search.sort;
  }
  let pageSize = 20;
  if (search?.pageSize) {
    pageSize = search.pageSize;
  }
  const query = `{
    stores(first: ${pageSize}, after: "${afterCursor}", orderBy: "${orderBy}"${searchString}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          name
          ${
            search?.isSelectorLoad
              ? ''
              : extraStoreField(search.showDistrictTranslation)
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getOneStore = (id) => {
  const query = `{
    store(id: "${id}") {
      id
      pk
      name
      displayPriority
      address
      phoneNumberCountryCode
      phoneNumberSubscriberNumber
      latitude
      longitude
      emailAddress
      description
      codeDisplayImage
      codeDownloadImage
      codeDownloadImageA5
      isPublished
      isFollowBrandAttribute
      inWeekHours {
        edges {
            node {
                pk
                id
                weekDays {
                    edges {
                        node {
                            pk
                        }
                    }
                }
                isClose
                openHour
                closeHour
            }
        }
      }
      holidayHour {
        pk
        id
        isClose
        openHour
        closeHour
      }
      district {
          region {
              pk
              name
          }
          pk
          name
      }
      brand {
          pk
          name
          attributeTags {
            edges {
                node {
                    pk
                    id
                    name
                    tagKey {
                        pk
                        id
                        name
                    }
                }
            }
          }
      }
      attributeTags {
        edges {
            node {
                pk
                id
                name
                tagKey {
                    pk
                    id
                    name
                }
            }
        }
      }
      translations(first: 20) {
        edges {
          node {
            language
            name
            description
            address
            pk
          }
        }
      }
    }
  }
  `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteStores = (ids) => {
  const query = `mutation DeleteStores($input: DeleteStoresInput!) {
    deleteStores(input: $input) {
      success
    }
  }
  `;
  const variables = { input: { ids: ids } };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createOrUpdateStore = (values) => {
  const action = values.id ? 'Update' : 'Create';
  let query = `mutation ${action}Store($input: ${action}StoreInput!) {
    ${action.toLowerCase()}Store(input: $input) {
      success
      node {
          pk
      }
      errors {
        field
        messages
      }
    }
  }

  `;

  const variables = { input: values };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getDistricts = (afterCursor, search) => {
  let filter = '';

  if (search.search) {
    filter += `, nameIcontains: "${search.search}"`;
  }
  const query = `{
    districts(first: 50, after: "${afterCursor}", orderBy: "pk"${filter}) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          region {
              pk
              name
          }
          name
          translations {
            edges {
              node {
                language
                name
                pk
              }
            }
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getDownloadStoreCodesZip = (values) => {
  const query = `mutation DownloadStores($input: DownloadStoreCodesInput!) {
    downloadStores(input: $input) {
      success
      zipLink
    }
  }`;
  const variables = { input: values };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
