import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import { createAction } from '../../../utils';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import CustomListComponent from '../../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import DeletePrompt from '../../../components/base/DeletePrompt';

const InterestPreferenceList = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  const {
    pageInfo,
    totalCount,
    InterestPreferenceList,
    totalPage,
    checkedList,
  } = useSelector((state) => ({
    pageInfo: state.interestPreference.pageInfo,
    totalCount: state.interestPreference.totalCount,
    InterestPreferenceList: state.interestPreference.pagedList,
    totalPage: state.interestPreference.totalPage,
    checkedList: state.interestPreference.checkedList,
  }));

  useEffect(() => {
    const searchDict = queryString.parse(location.search);
    const searchKey = searchDict['search'];
    const stringRank = searchDict['rank'] || 'true';
    const stringPage = searchDict['page'] || 1;
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);
    dispatch(
      createAction('interestPreference/getList')({
        ...searchDict,
        page,
        rank,
        filterName: searchKey,
      }),
    );
  }, [dispatch, location]);

  const buttons = [
    <AuthButton
      title="Create Interest Preference"
      action={() => {
        history.push('/interest_preferences/create');
      }}
      requires={PermissionCodes.changeInterestPreference}
    />,
  ];

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.changeInterestPreference,
    },
  ];

  const tabs = [
    {
      name: '',
      content: (
        <BaseTabListContainer
          hideTab={true}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount ? totalCount : 0}
          listContent={
            <BaseListContainer
              fields={[
                { displayName: 'ID', fieldName: 'pk', orderField: 'pk' },
                { displayName: 'Name', fieldName: 'name', orderField: 'name' },
                {
                  displayName: 'Related Customer number',
                  fieldName: 'relatedCustomerNumber',
                },
              ]}
              model={'interestPreference'}
              permissionGroup={PermissionCodes.interestPreference}
              dataList={InterestPreferenceList}
              totalPage={totalPage}
              deleteInfo={{
                data: [],
                title: 'Interest Preference',
                onComfirm: {},
              }}
            />
          }
        />
      ),
    },
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail: 'TBC',
          title: 'Interest Preference',
        }}
        buttons={buttons}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={true}
        tabs={tabs}
      />
      <DeletePrompt
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch(
            createAction('interestPreference/delete')({
              afterAction: () => history.push(location),
            }),
          );
        }}
        data={checkedList}
        title={'Interest Preference'}
      />
    </>
  );
};

export default InterestPreferenceList;
