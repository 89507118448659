import React from 'react';
import './EmptyChart.scss';
import { Image } from 'react-bootstrap';
import Empty from '../../assets/images/empty_chart.jpg';

function EmptyChart({ customClass }) {
  return (
    <div className={`${customClass} empty-container`}>
      <Image src={Empty} className="empty-container-image"></Image>
    </div>
  );
}

export default EmptyChart;
