import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
// import './CampaignOverviewTab.scss';
import OverviewCard from '../../../components/dashboard/OverviewCard';
import FilterSection from '../../../components/goodieBags/GoodiebagOverviewFilter';
import { createAction, getHashKeyString } from '../../../utils';
import OverviewTable from '../../../components/base/OverviewTable';
// import { COUPOINS_DATA_LIST_CONFIG } from '../../coupon/couponList/CouponsOverview';
import { URLFragments } from '../../../components/campaign/CampaignUrlConfig';
import { CampaignType } from '../../../config/CustomEnums';
import CustomTips from '../../../components/base/CustomTips';

const GOODIEBAG_RAKING_TABLE_TITLE = [
  { name: 'Goodie bag acquired ranking', fieldName: 'acquired_rank' },
  { name: 'Goodie bag', fieldName: 'name' },
  { name: 'Number of goodie bag acquired ', fieldName: 'acquired_count' },
];

const getCampaignsRakingTableStatus = (
  goodiebagOverviewFilter,
  campaign,
  campaignType,
  alwaysShow,
) => {
  const campaignName =
    campaignType === CampaignType.cashVoucherCampaign
      ? 'cash voucher'
      : campaignType === CampaignType.giftCampaign
      ? 'goodie bag coupon'
      : 'Goodie bag offer (Direct assign)';

  let showCampaignMessage = `all ${campaignName}`;

  if (campaign?.pk && campaign?.type === campaignType) {
    if (!alwaysShow) {
      return 'Not applicable if filtered by specific campaign';
    }

    showCampaignMessage = `${campaignName}: ${campaign?.pk}`;
  }
  return `Showing result of ${showCampaignMessage} ${
    goodiebagOverviewFilter?.isStarred
      ? goodiebagOverviewFilter?.isStarred === 'true'
        ? '(Starred) '
        : '(Non-Starred) '
      : ''
  }from ${
    goodiebagOverviewFilter?.goodiebag
      ? `goodie bag name: ${goodiebagOverviewFilter?.goodiebag
          ?.slice(0, 2)
          ?.map((item) => item?.value?.name)
          .join(', ')}${
          goodiebagOverviewFilter?.goodiebag?.length > 2 ? '...' : ''
        }`
      : 'all goodie bag(s)'
  }`;
};

const getGoodiebagSectionStatus = (goodiebag, alwaysShow) => {
  if (goodiebag?.length === 1) {
    if (alwaysShow) {
      return `Showing result of goodie bag ID: ${goodiebag
        ?.map((item) => item?.value?.pk)
        .join(', ')}`;
    }
    return 'Not applicable if filtered by specific goodie bag.';
  }
  if (goodiebag?.length > 1) {
    return `Showing result of goodie bag ID: ${goodiebag
      ?.slice(0, 2)
      ?.map((item) => item?.value?.pk)
      .join(', ')}${goodiebag?.length > 2 ? '...' : ''}`;
  }
  return `Showing result of all goodie bag(s)`;
};

// const getCouponsSectionStatus = (campaign, campaignList, campaignsStatus) => {
//   if (campaign) {
//     if (campaign?.type === CampaignType.generalMessageCampaign) {
//       return 'Not applicable if the campaign selected is a general message campaign.';
//     }
//   }
//   if (campaignList?.length <= 0) {
//     return 'No campaign found, please fine tune the fitler setting.';
//   }
//   return campaignsStatus;
// };

const RankingTable = ({
  name,
  data = {},
  goodiebagOverviewFilter,
  overviewDate,
  hide,
  status,
  searchPlaceholder = '',
  titles,
  actionType,
  campaginTypes,
}) => {
  const dispatch = useDispatch();
  const [sort, sortChange] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [searchName, setSearchName] = useState();

  console.log('@293', goodiebagOverviewFilter, overviewDate);

  useEffect(() => {
    console.log('@96', sort, searchName);
    sortChange('');
    setSearchName('');
  }, [overviewDate]);

  return (
    <OverviewTable
      name={name}
      hidden={hide}
      status={status}
      placeholder={searchPlaceholder}
      titles={titles}
      onSortChange={sortChange}
      currentSort={sort}
      data={data?.tableData}
      searchName={searchName}
      onFileterChange={(key) => {
        setSearchName(key);
      }}
      currentPage={currentPage}
      totalCount={data?.totalCount}
      pageChange={(page) => {
        setCurrentPage(page);
      }}
      hasCountInfo={true}
      disableRank={false}
      actionKey={actionType}
      fromModel="GOODIE_BAG"
      campaginTypes={campaginTypes}
      goodiebagOverviewFilter={{
        ...goodiebagOverviewFilter,
        goodiebags: goodiebagOverviewFilter?.goodiebag?.map(
          (item) => item?.value?.pk,
        ),
      }}
      overviewDate={overviewDate}
    />
  );
};

function GoodiebagRelatedSection({
  title,
  status,
  cardList = [],
  data,
  overviewDate,
  showCardList = true,
  customClass = {},
}) {
  console.log('@@135: ', title, showCardList, data, overviewDate);
  return (
    <>
      <div className="overview-row-title overview-campaigns-title">
        <label>{title}</label>
        <label className="overview-campaigns-status-information">
          {status}
        </label>
      </div>
      {showCardList ? (
        <div
          className={`overview-row overview-row-campaigns ${
            customClass.card || ''
          }`}
        >
          {cardList.map((config, index) => (
            <OverviewCard
              title={config.title}
              value={data?.[config.key]?.value}
              change={data?.[config.key]?.change}
              showPercentage={config.showPercentage}
              asOfDate={config.asOf ? overviewDate?.endDateWithoutFormat : null}
            />
          ))}
        </div>
      ) : (
        <div className="overview-row"></div>
      )}
    </>
  );
}

const GoodiebagOverview = (props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const activeTab = getHashKeyString(location.hash) || URLFragments.overview;
  const {
    overviewData,
    goodiebagOverviewFilter,
    goodiebagRakingTable,
    overviewDate,
    giftCampaignRankingTable,
    cashCampaignRankingTable,
    fortunebagCampaignRankingTable,
    // cashCampaignList,
    // giftCampaignList,
  } = useSelector((state) => ({
    overviewData: state.overview,
    goodiebagOverviewFilter: state.overview.goodiebagOverviewFilter,
    goodiebagRakingTable: state.overview.goodiebagRakingTable,
    overviewDate: state.overview.goodiebagOverviewDate,
    giftCampaignRankingTable: state.overview.giftCampaignRankingTable,
    cashCampaignRankingTable: state.overview.cashCampaignRankingTable,
    fortunebagCampaignRankingTable:
      state.overview.fortunebagCampaignRankingTable,

    // cashCampaignList: state.campaignList.cashCampaignList,
    // giftCampaignList: state.campaignList.giftCampaignList,
  }));

  const campaignCardList = [
    {
      title: (
        <CustomTips
          detail={
            <div>
              <label className="coupon-overview-coupon-card-title">
                The sum of
                <br />
                - coupon generated from CMS;
                <br />
                - coupon generated from generic codes;
                <br />
                - coupon imported from files.
                <br />
                The value is as of the previous time before the selected period.
              </label>
            </div>
          }
          iconClassName="coupon-overview-coupon-card-title-icon"
          popoverName="coupon-overview-coupon-tips-area"
        >
          <div className="coupon-overview-coupon-card-content">
            <label>Total coupon generated and imported </label>
          </div>
        </CustomTips>
      ),
      key: 'totalNumberOfCoupons',
      asOf: true,
      showPercentage: false,
    },
    {
      title: (
        <CustomTips
          detail={
            <div>
              <label className="coupon-overview-coupon-card-title">
                Total number of single coupon, minus number of coupon acquired,
                number of coupon expired and number of coupon deactivated and
                number of coupon Voided. The value is as of the previous time
                before the selected period.
              </label>
            </div>
          }
          iconClassName="coupon-overview-coupon-card-title-icon"
          popoverName="coupon-overview-coupon-tips-area"
        >
          <div className="coupon-overview-coupon-card-content">
            <label>Total available coupon </label>
          </div>
        </CustomTips>
      ),
      key: 'totalNumberOfCouponAvailable',
      asOf: true,
      showPercentage: false,
    },
    { title: 'Coupon acquired', key: 'totalNumberOfCouponAcquired' },
    {
      title: (
        <CustomTips
          detail={
            <div>
              <label className="coupon-overview-coupon-card-title">
                Number of coupon acquired / over Total number of available
                coupon (as of the time before the selected period) x 100%
              </label>
            </div>
          }
          iconClassName="coupon-overview-coupon-card-title-icon"
          popoverName="coupon-overview-coupon-tips-area"
        >
          <div className="coupon-overview-coupon-card-content">
            <label>Coupon acquired rate </label>
          </div>
        </CustomTips>
      ),
      key: 'acquiredCouponsConversionRate',
      showPercentage: true,
    },
    { title: 'Coupon redeemed', key: 'totalNumberOfCouponUsed' },
    {
      title: (
        <CustomTips
          detail={
            <div>
              <label className="coupon-overview-coupon-card-title">
                Number of coupon used / over acquired x 100%
              </label>
            </div>
          }
          iconClassName="coupon-overview-coupon-card-title-icon"
          popoverName="coupon-overview-coupon-tips-area"
        >
          <div className="coupon-overview-coupon-card-content">
            <label>Coupon redemption rate</label>
          </div>
        </CustomTips>
      ),
      key: 'usedCouponsConversionRate',
      showPercentage: true,
    },
  ];

  return (
    <>
      <FilterSection
        propGoodiebagOverviewDate={
          overviewDate?.startDate ? overviewDate : props.goodiebagOverviewDate
        }
      />
      <div>
        <GoodiebagRelatedSection
          title={'Goodie bags'}
          status={getGoodiebagSectionStatus(goodiebagOverviewFilter?.goodiebag)}
          cardList={[
            {
              title: 'Published goodie bag',
              key: 'totalNumberOfPublishedGoodieBags',
            },
            {
              title: 'Non-published goodie bag',
              key: 'totalNumberOfNonpublishedGoodieBags',
            },
            {
              title: 'Expired goodie bag',
              key: 'totalNumberOfExpiredGoodieBags',
            },
          ]}
          data={overviewData}
          showCardList={goodiebagOverviewFilter?.goodiebag?.length !== 1}
        />

        <GoodiebagRelatedSection
          title={'cash voucher'}
          status={getCampaignsRakingTableStatus(
            goodiebagOverviewFilter,
            goodiebagOverviewFilter?.cashVoucher,
            CampaignType.cashVoucherCampaign,
          )}
          cardList={[
            {
              title: 'Published cash voucher',
              key: 'totalNumberOfPublishedCashVoucherCampaigns',
            },
            {
              title: 'Non-published cash voucher',
              key: 'totalNumberOfNonpublishedCashVoucherCampaigns',
            },
            {
              title: 'Expired cash voucher',
              key: 'totalNumberOfExpiredCashVoucherCampaigns',
            },
          ]}
          data={overviewData}
          showCardList={!goodiebagOverviewFilter?.cashVoucher?.pk}
        />

        <GoodiebagRelatedSection
          title={'Goodie bag coupon'}
          status={getCampaignsRakingTableStatus(
            goodiebagOverviewFilter,
            goodiebagOverviewFilter?.goodiebagCoupons,
            CampaignType.giftCampaign,
          )}
          cardList={[
            {
              title: 'Published Goodie bag coupon',
              key: 'totalNumberOfPublishedGiftCampaigns',
            },
            {
              title: 'Non-published Goodie bag coupon',
              key: 'totalNumberOfNonpublishedGiftCampaigns',
            },
            {
              title: 'Expired Goodie bag coupon',
              key: 'totalNumberOfExpiredGiftCampaigns',
            },
          ]}
          data={overviewData}
          showCardList={!goodiebagOverviewFilter?.goodiebagCoupons?.pk}
        />

        <GoodiebagRelatedSection
          title={'Goodie bag offer (Direct assign)'}
          status={getCampaignsRakingTableStatus(
            goodiebagOverviewFilter,
            goodiebagOverviewFilter?.fortunebags,
            CampaignType.fortuneBagForGoodieBagCampaign,
          )}
          cardList={[
            {
              title: 'Published Goodie bag offer (Direct assign)',
              key: 'totalNumberOfPublishedFortuneCampaigns',
            },
            {
              title: 'Non-Published Goodie bag offer (Direct assign)',
              key: 'totalNumberOfNonpublishedFortuneCampaigns',
            },
            {
              title: 'Expired Goodie bag offer (Direct assign)',
              key: 'totalNumberOfExpiredFortuneCampaigns',
            },
          ]}
          data={overviewData}
          showCardList={!goodiebagOverviewFilter?.goodiebagCoupons?.pk}
        />

        <GoodiebagRelatedSection
          title={'GOODIE bag Credit/quota'}
          status={getGoodiebagSectionStatus(
            goodiebagOverviewFilter?.goodiebag,
            true,
          )}
          cardList={[
            {
              title: 'Goodie bag generated',
              key: 'totalNumberOfGeneratedGoodieBags',
            },
            {
              title: 'Goodie bag acquired',
              key: 'totalNumberOfAcquiredGoodieBags',
            },
            {
              title: (
                <CustomTips
                  detail={
                    <div>
                      <label className="coupon-overview-coupon-card-title">
                        Number of goodie bag acquired / over Total number of
                        goodie bag (as of the time before the selected period) x
                        100%
                      </label>
                    </div>
                  }
                  iconClassName="coupon-overview-coupon-card-title-icon"
                  popoverName="coupon-overview-coupon-tips-area"
                >
                  <div className="coupon-overview-coupon-card-content">
                    <label>Goodie bag acquired rate</label>
                  </div>
                </CustomTips>
              ),
              key: 'acquiredGoodieBagsConversionRate',
              showPercentage: true,
            },
            {
              title: 'Goodie bag acquired credit',
              key: 'totalNumberOfAcquiredGoodieBagCredit',
            },
            {
              title: 'Goodie bag unused credit',
              key: 'totalNumberOfUnusedGoodieBagCredit',
            },
            {
              title: 'Goodie bag acquired quota',
              key: 'totalNumberOfAcquiredGoodieBagQuota',
            },
            {
              title: 'Goodie bag unused quota',
              key: 'totalNumberOfUnusedGoodieBagQuota',
            },
          ]}
          data={overviewData}
          showCardList={true}
        />

        <GoodiebagRelatedSection
          title={'cash voucher linked Coupons'}
          status={
            // cashCampaignList?.length <= 0
            //   ? 'No campaign found, please fine tune the filter setting'
            //   :
            getCampaignsRakingTableStatus(
              goodiebagOverviewFilter,
              goodiebagOverviewFilter?.cashVoucher,
              CampaignType.cashVoucherCampaign,
              true,
            )
          }
          cardList={campaignCardList}
          data={overviewData?.cashCampaignCoupons}
          overviewDate={overviewDate}
          showCardList={true}
        />

        <GoodiebagRelatedSection
          title={'goodie bag Coupon linked Coupons'}
          status={
            // giftCampaignList?.length <= 0
            //   ? 'No campaign found, please fine tune the filter setting'
            //   :
            getCampaignsRakingTableStatus(
              goodiebagOverviewFilter,
              goodiebagOverviewFilter?.goodiebagCoupons,
              CampaignType.giftCampaign,
              true,
            )
          }
          cardList={campaignCardList}
          data={overviewData?.giftCampaignCoupons}
          overviewDate={overviewDate}
          showCardList={true}
        />

        <GoodiebagRelatedSection
          title={'Goodie bag offer (Direct assign) LINKED COUPONS'}
          status={
            // giftCampaignList?.length <= 0
            //   ? 'No campaign found, please fine tune the filter setting'
            //   :
            getCampaignsRakingTableStatus(
              goodiebagOverviewFilter,
              goodiebagOverviewFilter?.fortunebags,
              CampaignType.fortuneBagForGoodieBagCampaign,
              true,
            )
          }
          cardList={campaignCardList}
          data={overviewData?.fortunebagCampaignCoupons}
          overviewDate={overviewDate}
          showCardList={true}
        />

        <RankingTable
          name="Goodie bag ACQUIRED Ranking"
          data={goodiebagRakingTable}
          goodiebagOverviewFilter={goodiebagOverviewFilter}
          overviewDate={overviewDate}
          hide={goodiebagOverviewFilter?.goodiebag?.pk}
          status={getGoodiebagSectionStatus(goodiebagOverviewFilter?.goodiebag)}
          searchPlaceholder={'Search by goodie bag name '}
          titles={GOODIEBAG_RAKING_TABLE_TITLE}
          actionType={'overview/getGoodiebagRankingTable'}
        />

        <RankingTable
          name="COUPON ACQUIRED & REDEMPTION Ranking - by cash voucher"
          data={cashCampaignRankingTable}
          goodiebagOverviewFilter={goodiebagOverviewFilter}
          overviewDate={overviewDate}
          hide={goodiebagOverviewFilter?.cashCampaign?.pk}
          status={getCampaignsRakingTableStatus(
            goodiebagOverviewFilter,
            goodiebagOverviewFilter?.cashVoucher,
            CampaignType.cashVoucherCampaign,
          )} //cashCampaignList?.length <= 0 ? 'No campaign found, please fine tune the filter setting'
          searchPlaceholder={'Search by cash voucher name'}
          titles={[
            { name: 'Coupon acquired ranking', fieldName: 'acquired_rank' },
            { name: 'Coupon redeemed ranking', fieldName: 'used_rank' },
            { name: 'Campaign ', fieldName: 'name' },
            {
              name: 'Number of coupon acquired  ',
              fieldName: 'acquired_count',
            },
            { name: 'Number of coupon redeemed ', fieldName: 'used_count' },
          ]}
          actionType={'overview/getCampaignsRankingTable'}
          campaginTypes={[CampaignType.cashVoucherCampaign]}
        />

        <RankingTable
          name="COUPON ACQUIRED & REDEMPTION Ranking - by goodie bag coupon"
          data={giftCampaignRankingTable}
          goodiebagOverviewFilter={goodiebagOverviewFilter}
          overviewDate={overviewDate}
          hide={goodiebagOverviewFilter?.goodiebagCoupons?.pk}
          status={
            // giftCampaignList?.length <= 0
            //   ? 'No campaign found, please fine tune the filter setting'
            //   :
            getCampaignsRakingTableStatus(
              goodiebagOverviewFilter,
              goodiebagOverviewFilter?.goodiebagCoupons,
              CampaignType.giftCampaign,
            )
          }
          searchPlaceholder={'Search by goodie bag coupon name '}
          titles={[
            { name: 'Coupon acquired ranking', fieldName: 'acquired_rank' },
            { name: 'Coupon redeemed ranking', fieldName: 'used_rank' },
            { name: 'Campaign ', fieldName: 'name' },
            {
              name: 'Number of coupon acquired  ',
              fieldName: 'acquired_count',
            },
            { name: 'Number of coupon redeemed ', fieldName: 'used_count' },
          ]}
          actionType={'overview/getCampaignsRankingTable'}
          campaginTypes={[CampaignType.giftCampaign]}
        />

        <RankingTable
          name="COUPON ACQUIRED & REDEMPTION Ranking - by Goodie bag offer (Direct assign)"
          data={fortunebagCampaignRankingTable}
          goodiebagOverviewFilter={goodiebagOverviewFilter}
          overviewDate={overviewDate}
          hide={goodiebagOverviewFilter?.fortunebags?.pk}
          status={
            // giftCampaignList?.length <= 0
            //   ? 'No campaign found, please fine tune the filter setting'
            //   :
            getCampaignsRakingTableStatus(
              goodiebagOverviewFilter,
              goodiebagOverviewFilter?.fortunebags,
              CampaignType.fortuneBagForGoodieBagCampaign,
            )
          }
          searchPlaceholder={'Search by Goodie bag offer (Direct assign) name'}
          titles={[
            { name: 'Coupon acquired ranking', fieldName: 'acquired_rank' },
            { name: 'Coupon redeemed ranking', fieldName: 'used_rank' },
            { name: 'Campaign ', fieldName: 'name' },
            {
              name: 'Number of coupon acquired  ',
              fieldName: 'acquired_count',
            },
            { name: 'Number of coupon redeemed ', fieldName: 'used_count' },
          ]}
          actionType={'overview/getCampaignsRankingTable'}
          campaginTypes={[CampaignType.fortuneBagForGoodieBagCampaign]}
        />
      </div>
    </>
  );
};

export default GoodiebagOverview;
