import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { APIStatus, CampaignType } from '../../config/CustomEnums';
import { OnlyContinueButton } from '../base/BottomStepComponent';
import { useHistory, useParams } from 'react-router-dom';
import ContentSections from '../base/ContentSections';
import Loading from '../base/Loading';
import BasePrompt from '../base/prompt/BasePrompt';
import { PermissionCodes } from '../../config/PermissionCodes';
import { useFormContext, Controller } from 'react-hook-form';
import {
  ReactHookFormErrorMessage,
  hasError,
  errorMessage,
} from '../../components/base/ErrorFieldMessage';
import BaseMutipleSelectorV2 from '../base/BaseMultipleSelectorV2';
import { validate } from './CreateGoodieBagValidate';
import { Image, Row } from 'react-bootstrap';
import CustomRadios from '../base/CustomRadios';
import { GOODIE_BAG_REWARD_TYPE_OPTIONS } from '../../models/GoodieBagsModel';
import './CreateGoodieBagStepOne.scss';
import DeleteIcon from '../../assets/images/deleteIcon.png';
import PromptButton, { BUTTON_TYPES } from '../base/prompt/BaseButtons';
import _ from 'lodash';
import CustomSwitchButton from '../base/CustomSwitchButton';

function CreateGoodieBagStepOne({
  status,
  campaignList = [],
  brandList,
  countryCodeList,
  isPublished,
  originalGoodieBagCouponCampaigns = [],
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const {
    getValues,
    watch,
    setValue,
    control,
    formState,
    clearErrors,
    setError,
    register,
  } = useFormContext();
  const { errors } = formState;

  // const watchCouponCampaignList = watch('goodieBagCouponCampaigns') || [];
  // const watchCashVoucherCampaignList =
  //   watch('goodieBagCashVoucherCampaign') || [];
  // const watchGiftCampaignList = watch('goodieBagGiftCampaign') || [];
  const watchRewardType =
    watch('rewardType') || GOODIE_BAG_REWARD_TYPE_OPTIONS.cashVoucher.value;

  const slotCashCampaigns = watch('slotCashCampaigns') || {}; //{ newCash0: [] };
  const slotGiftCampaigns = watch('slotGiftCampaigns') || {}; //{ newGift0: [] };
  const slotFortuneBagCampaigns = watch('slotFortuneBagCampaigns') || {};

  const [tempSlotCashCampaigns, setTempSlotCashCampaigns] = useState();
  const [tempSlotGiftCampaigns, setTempSlotGiftCampaigns] = useState();

  const [previousRewardType, setPreviousRewardType] = useState(watchRewardType);

  const campaginSlotsToDelete = watch('campaginSlotsToDelete') || [];

  const allCampaignList = campaignList?.concat(
    originalGoodieBagCouponCampaigns,
  );

  const cashVoucherCampaignList = allCampaignList?.filter(
    (item) => item.type === CampaignType.cashVoucherCampaign,
  );
  const giftCampaignList = allCampaignList?.filter(
    (item) => item.type === CampaignType.giftCampaign,
  );
  const fortuneBagCampaignList = allCampaignList?.filter(
    (item) => item.type === CampaignType.fortuneBagForGoodieBagCampaign,
  );

  const watchOta = watch('ota') || [];
  const watchBatchOta = watch('batchOta') || [];
  const watchCountryCode = watch('countryCodes') || [];
  const [showAddPrompt, setShowAddPrompt] = useState(false);
  const [addPromptConfig, setAddPromptConfig] = useState();
  const [newCashCampaignSlotIndex, setNewCashCampaignSlotIndex] = useState(
    _.isEmpty(slotCashCampaigns) ? 1 : 0,
  );
  const [newGiftCampaignSlotIndex, setNewGiftCampaignSlotIndex] = useState(
    _.isEmpty(slotGiftCampaigns) ? 1 : 0,
  );

  const campaignListMultipleSelector = ({
    num,
    index,
    title,
    soruceData,
    targetData,
    targetDataKey,
    deleteAction,
  }) => {
    return (
      <Controller
        control={control}
        name={targetDataKey}
        render={({ field: { value } }) => (
          <BaseMutipleSelectorV2
            title={title}
            searchPlaceholder="Search by campaign ID, name"
            size={['wider']}
            namespace="goodieBags"
            groupKey="relatedBrand"
            data={{
              sourceData: soruceData,
              targetData: targetData,
              targetChange: (value) => {
                console.log('@@108: ', targetDataKey);
                setValue(targetDataKey, value, { shouldDirty: true });
              },
            }}
            custom={{
              customTitle: (
                <div className="slot-head" style={{ paddingRight: '20px' }}>
                  <label
                    className={`slot-label ${
                      targetDataKey.indexOf('slotFortuneBagCampaigns') >= 0
                        ? 'slot-title'
                        : ''
                    }`}
                  >
                    {targetDataKey.indexOf('slotFortuneBagCampaigns') >=
                    0 ? null : (
                      <label className="slot-title">Slot {num}: </label>
                    )}
                    {title}
                  </label>

                  {num > 1 && !isPublished ? (
                    <Image
                      src={DeleteIcon}
                      className="slot-delete-icon goodiebag-slot-delete-icon"
                      onClick={() => {
                        deleteAction && deleteAction(index);
                        // setSelectedList([]);
                      }}
                    />
                  ) : null}
                </div>
              ),
              customItem: (value) => (
                <>
                  <label className="slot-title">[{value.pk}]</label>
                  {value.name}
                </>
              ),
              customFilter: (inputValue, option) => {
                const inputIgnoreCase = inputValue?.toLowerCase();
                const optionIgnoreCase = option?.name?.toLowerCase();
                return (
                  optionIgnoreCase?.includes(inputIgnoreCase) ||
                  option.pk == inputValue
                );
              },
            }}
            error={{
              error: hasError(errors, targetDataKey),
              message: errorMessage(errors, targetDataKey),
              id: targetDataKey,
            }}
          />
        )}
      />
    );
  };

  useEffect(() => {
    console.log('@179: ', previousRewardType, watchRewardType);
    // if (
    //   !(
    //     previousRewardType ===
    //       GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value ||
    //     watchRewardType === GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value
    //   )
    // ) {
    if (
      (previousRewardType !== GOODIE_BAG_REWARD_TYPE_OPTIONS.both.value &&
        watchRewardType === GOODIE_BAG_REWARD_TYPE_OPTIONS.both.value) ||
      (watchRewardType !== GOODIE_BAG_REWARD_TYPE_OPTIONS.both.value &&
        previousRewardType === GOODIE_BAG_REWARD_TYPE_OPTIONS.both.value)
    ) {
      const tempCash = { ...tempSlotCashCampaigns };
      const tempGift = { ...tempSlotGiftCampaigns };

      setTempSlotCashCampaigns({ ...slotCashCampaigns });
      setTempSlotGiftCampaigns({ ...slotGiftCampaigns });

      setValue('slotCashCampaigns', tempCash, { shouldDirty: true });
      setValue('slotGiftCampaigns', tempGift, { shouldDirty: true });
    }
    // }

    setPreviousRewardType(watchRewardType);
  }, [watchRewardType]);

  const firstPart = (
    <div>
      <div className="first-section-title">
        Link Cash Voucher or Goodie Bag Coupon to Goodie Bag (Optional for save,
        mandatory to publish)
      </div>
      <div className="create-section-label create-section-label-bottom-space">
        Reward type
      </div>
      <div className="goodie-bag-step-one-label">
        Once the Goodie bag is published, cannot be changed.
      </div>
      <Row className="featured-exclusive-row">
        <CustomRadios
          disabled={isPublished}
          onChange={(value) => {
            setValue('rewardType', value, { shouldDirty: true });
          }}
          default={watchRewardType}
          options={Object.values(GOODIE_BAG_REWARD_TYPE_OPTIONS)}
        />
      </Row>

      {[GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value].includes(
        watchRewardType,
      ) ? (
        <div {...register('slotFortuneBagCampaigns')}>
          {Object.keys(
            _.isEmpty(slotFortuneBagCampaigns)
              ? { newFortune0: [] }
              : slotFortuneBagCampaigns,
          ).map((key, index) => {
            return campaignListMultipleSelector({
              num: index + 1,
              index: key.toString(),
              title: `Link ${GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.label}`,
              soruceData: fortuneBagCampaignList,
              targetData: slotFortuneBagCampaigns[key] || [],
              targetDataKey: `slotFortuneBagCampaigns.${key}`,
              deleteAction: (item) => {
                const temp = { ...slotFortuneBagCampaigns };
                delete temp[item];

                setValue(`slotFortuneBagCampaigns`, temp, {
                  shouldDirty: true,
                });

                if (item.indexOf('new') !== 0) {
                  setValue('CampaignSlotsToDelete', [
                    ...campaginSlotsToDelete,
                    item,
                  ]);
                }
              },
            });
          })}
        </div>
      ) : null}

      {[
        GOODIE_BAG_REWARD_TYPE_OPTIONS.cashVoucher.value,
        GOODIE_BAG_REWARD_TYPE_OPTIONS.both.value,
      ].includes(watchRewardType) ? (
        <div {...register('slotCashCampaigns')}>
          {Object.keys(
            _.isEmpty(slotCashCampaigns) ? { newCash0: [] } : slotCashCampaigns,
          ).map((key, index) => {
            return campaignListMultipleSelector({
              num: index + 1,
              index: key.toString(),
              title: 'Link Cash Voucher',
              soruceData: cashVoucherCampaignList,
              targetData: slotCashCampaigns[key] || [],
              targetDataKey: `slotCashCampaigns.${key}`,
              deleteAction: (item) => {
                const temp = { ...slotCashCampaigns };
                delete temp[item];

                setValue(`slotCashCampaigns`, temp, {
                  shouldDirty: true,
                });

                if (item.indexOf('new') !== 0) {
                  setValue('CampaignSlotsToDelete', [
                    ...campaginSlotsToDelete,
                    item,
                  ]);
                }
              },
            });
          })}
          {isPublished ? null : (
            <div style={{ margin: '20px 0px' }}>
              <PromptButton
                title="Add new cash voucher"
                className={`'left-create'`}
                action={() => {
                  setNewCashCampaignSlotIndex(newCashCampaignSlotIndex + 1);

                  setValue(
                    'slotCashCampaigns',
                    {
                      ...slotCashCampaigns,
                      [`newCash${newCashCampaignSlotIndex + 1}`]: [],
                    },
                    { shouldDirty: true },
                  );

                  // setSlotCashCampaigns({
                  //   ...slotCashCampaigns,
                  //   [`new${newCashCampaignSlotIndex + 1}`]: [],
                  // });
                }}
                type={BUTTON_TYPES.secondary}
              />
            </div>
          )}
        </div>
      ) : null}
      {watchRewardType === GOODIE_BAG_REWARD_TYPE_OPTIONS.both.value ? (
        <div
          style={{ marginTop: '20px', borderBottom: 'dashed 2px #DADADA' }}
        ></div>
      ) : null}

      {[
        GOODIE_BAG_REWARD_TYPE_OPTIONS.gift.value,
        GOODIE_BAG_REWARD_TYPE_OPTIONS.both.value,
      ].includes(watchRewardType) ? (
        <div {...register('slotGiftCampaigns')}>
          {Object.keys(
            _.isEmpty(slotGiftCampaigns) ? { newGift0: [] } : slotGiftCampaigns,
          ).map((key, index) => {
            return campaignListMultipleSelector({
              num:
                index +
                1 +
                (watchRewardType === GOODIE_BAG_REWARD_TYPE_OPTIONS.gift.value
                  ? 0
                  : Object.keys(slotCashCampaigns).length || 0),
              index: key.toString(),
              title: 'Link goodie bag coupon',
              soruceData: giftCampaignList,
              targetData: slotGiftCampaigns[key] || [],
              targetDataKey: `slotGiftCampaigns.${key}`,
              deleteAction: (item) => {
                const temp = { ...slotGiftCampaigns };
                delete temp[key];

                setValue(`slotGiftCampaigns`, temp, {
                  shouldDirty: true,
                });

                if (item.indexOf('new') !== 0) {
                  setValue('CampaignSlotsToDelete', [
                    ...campaginSlotsToDelete,
                    item,
                  ]);
                }
              },
            });
          })}
          {isPublished ? null : (
            <div style={{ margin: '20px 0px' }}>
              <PromptButton
                title="Add new goodie bag coupon"
                className={`'left-create'`}
                action={() => {
                  setNewGiftCampaignSlotIndex(newGiftCampaignSlotIndex + 1);

                  setValue(
                    'slotGiftCampaigns',
                    {
                      ...slotGiftCampaigns,
                      [`newGift${newGiftCampaignSlotIndex + 1}`]: [],
                    },
                    { shouldDirty: true },
                  );
                }}
                type={BUTTON_TYPES.secondary}
              />
            </div>
          )}
        </div>
      ) : null}

      {/* // {watchRewardType !== GOODIE_BAG_REWARD_TYPE_OPTIONS.gift.value
      //   ? campaignListMultipleSelector({
      //     title: 'Link Cash Voucher',
      //     soruceData: cashVoucherCampaignList,
      //     targetData: watchCashVoucherCampaignList,
      //     targetDataKey: 'goodieBagCashVoucherCampaign',
      //   })
      //   : null}
      // {watchRewardType !== GOODIE_BAG_REWARD_TYPE_OPTIONS.cashVoucher.value
      //   ? campaignListMultipleSelector({
      //     title: 'Link Goodie Bag Coupon',
      //     soruceData: giftCampaignList,
      //     targetData: watchGiftCampaignList,
      //     targetDataKey: 'goodieBagGiftCampaign',
      //   })
      //   : null} */}
    </div>
  );

  const targetMerchantData = brandList?.filter((item) => {
    if (
      isPublished &&
      watchOta?.filter((val) => val.pk === item.pk).length > 0
    ) {
      return false;
    }

    if (watchOta?.length > 0) {
      return (
        item?.handleFortuneBagEntitlement &&
        item?.handleOption.value &&
        item?.handleOption.value === watchOta[0].handleOption.value
      );
    }
    return item?.handleFortuneBagEntitlement && item?.handleOption.value;
  });

  const getSecondPart = () => {
    return (
      <>
        <div>
          <BaseMutipleSelectorV2
            title="Target Merchant (OTA)"
            namespace="brand"
            data={{
              sourceData: targetMerchantData,
              targetData: isPublished ? watchBatchOta : watchOta,
              targetChange: (value) => {
                setValue(isPublished ? 'batchOta' : 'ota', value, {
                  shouldDirty: true,
                });
              },
            }}
            groupKey="handleOptionTitle"
            addButton={{
              action: () => {
                setAddPromptConfig({
                  button: {
                    text: 'Add merchant',
                    actionPath: '/brands/create',
                  },
                  title: 'Go to create brand?',
                  description: `You will leave the campaign creation process. After you create a new brand, you can back to this page.`,
                });
                setShowAddPrompt(true);
              },
              title: 'Add Merchants',
              requires: PermissionCodes.addBrand,
              customClass:
                'general-section-add-new-brand btn-add-button-common',
            }}
            error={{
              id: 'ota',
              error: hasError(errors, 'ota'),
              message: 'please select ota',
            }}
          />
        </div>
      </>
    );
  };

  const getThirdPart = () => {
    return (
      <>
        <div>
          <BaseMutipleSelectorV2
            title="Country code (Optional)"
            tips={'Control which Country code user can “earn” this goodie bag.'}
            namespace="countryCode"
            data={{
              sourceData: countryCodeList,
              targetData: watchCountryCode,
              targetChange: (value) => {
                setValue('countryCodes', value, {
                  shouldDirty: true,
                });
              },
            }}
          />
        </div>
      </>
    );
  };

  const getSection = () => {
    if (status === APIStatus.calling) {
      return [<Loading />];
    }
    const parts = [firstPart];
    const secondPart = getSecondPart();
    parts.push(secondPart);
    const thirdPart = getThirdPart();
    parts.push(thirdPart);
    return parts;
  };

  const sections = getSection();

  const goToNextStep = () => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 0,
      isBack: false,
    });
    console.log('@167', isValid, errors);
    dispatch({
      type: 'goodieBags/stepChange',
      payload: {
        step: 0,
        isValid,
      },
    });
  };

  const buttonDisabled = () => {
    return !isPublished && (!watchOta || watchOta?.length <= 0);
  };

  const buttonDisable = buttonDisabled();

  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <OnlyContinueButton
        continueAction={async () => {
          goToNextStep();
        }}
        disabledContinue={buttonDisable}
      />

      <BasePrompt
        show={showAddPrompt}
        closeAction={() => {
          setShowAddPrompt(false);
          setAddPromptConfig(null);
        }}
        rightButton={
          addPromptConfig
            ? {
                text: addPromptConfig?.button.text,
                action: () =>
                  history.push({
                    pathname: addPromptConfig?.button.actionPath,
                    state: {
                      from: history.location,
                      ...{
                        title: 'Continue to Create Goodie Bag',
                        content: 'You can continue to create the goodie bag.',
                      },
                    },
                  }),
              }
            : null
        }
        title={addPromptConfig?.title}
        description={addPromptConfig?.description}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  status: state.goodieBags.createStatus,
  campaignList: state.campaignList.notPagedAllList,
  brandList: state.brand.notPagedAllList,
  countryCodeList: state.countryCode.notPagedAllList,
  isPublished: state.goodieBags.detail.isPublished,
  originalGoodieBagCouponCampaigns:
    state.goodieBags.detail.originalGoodieBagCouponCampaigns,
});

export default connect(mapPropsToState)(CreateGoodieBagStepOne);
