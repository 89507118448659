import moment from 'moment';
import {
  getDashboardData,
  getDashboardTop10CouponRedeemData,
  getDashboardTop10CouponAcquiredData,
  getDashboardTop5NationalityData,
  getDashboardGenderData,
  getCouonDashboardData,
  COUPON_DATA_TYPE,
  getDashboardCampaignData,
  getGoodieBagDashboardData,
} from '../services/AnalyticsApiHelper';
import { apiWithResponseHandle } from './LoadingUtil';
import { createAction } from '../utils';
import { COUPON_PIE_CHART_CONFIG } from '../config/DashboardConfig';

export const parseCustomerChartData = (chartData) => {
  return (chartData?.periods || []).map((period, index) => {
    const startDate = moment(period.split(' - ')[0]);
    const endDate = moment(period.split(' - ')[1]);
    return {
      period: period,
      point: chartData.points[index],
      startDate,
      startDateString: startDate.format('D MMM'),
      endDate,
    };
  });
};
const parseDashboardApiData = (resp) => {
  if (!resp) {
    return;
  }
  const { analyticsData } = resp;
  if (!analyticsData) {
    return;
  }
  // const coupons = {
  //   totalActivities: {
  //     ...analyticsData.totalNumberOfCouponActivities,
  //   },
  //   totalPoints: {
  //     ...analyticsData.totalNumberOfCouponsRedeemedByPoints,
  //   },
  //   totalCampaigns: {
  //     ...analyticsData.totalNumberOfCouponsAcquiredByCampaigns,
  //   },
  //   totalUsed: {
  //     ...analyticsData.totalNumberOfCouponsUsed,
  //   },
  // };
  // coupons.chartData = COUPON_PIE_CHART_CONFIG.map((item) => ({
  //   key: item.key,
  //   value: coupons ? coupons[item.key]?.value : 0,
  // }));
  return {
    customers: {
      // totalCustomers: {
      //   ...analyticsData.totalNumberOfCustomers,
      // },
      // activeCustomers: {
      //   ...analyticsData.totalNumberOfActiveCustomers,
      // },
      // newCustomers: {
      //   ...analyticsData.totalNumberOfNewCustomers,
      // },
      // chartData: parseCustomerChartData(
      //   analyticsData.totalNumberOfNewCustomersChart,
      // ),
    },
    // coupons,
    // campaigns: {
    //   totalPublished: {
    //     ...analyticsData.totalNumberOfPublishedCampaigns,
    //   },
    //   totalNonpublished: {
    //     ...analyticsData.totalNumberOfNonpublishedCampaigns,
    //   },
    //   totalExpired: {
    //     ...analyticsData.totalNumberOfExpiredCampaigns,
    //   },
    // couponCampaign: {
    //   published: {
    //     ...analyticsData.totalNumberOfPublishedCouponCampaigns,
    //   },
    //   unpublished: {
    //     ...analyticsData.totalNumberOfNonpublishedCouponCampaigns,
    //   },
    //   expired: {
    //     ...analyticsData.totalNumberOfExpiredCouponCampaigns,
    //   },
    // },
    // generalMessageCampaign: {
    //   published: {
    //     ...analyticsData.totalNumberOfPublishedGeneralMessageCampaigns,
    //   },
    //   unpublished: {
    //     ...analyticsData.totalNumberOfNonpublishedGeneralMessageCampaigns,
    //   },
    //   expired: {
    //     ...analyticsData.totalNumberOfExpiredGeneralMessageCampaigns,
    //   },
    // },
    // conversions: {
    //   ...analyticsData.totalNumberOfCampaignConversions,
    // },
    // views: {
    //   ...analyticsData.totalNumberOfCampaignViews,
    // },
    // shareRate: {
    //   ...analyticsData.campaignShareRate,
    // },
    // },
  };
};

const parseTop10CouponRedeemApiData = (resp) => {
  if (!resp) {
    return;
  }
  const { analyticsData } = resp;
  if (!analyticsData) {
    return;
  }
  const totalCouponRedeemed = analyticsData.totalNumberOfCouponUsed?.value || 0;
  const brandChartData = analyticsData.top10CouponUsedByBrandChart?.map(
    (item) => {
      return {
        name: item?.name,
        value: item?.count,
        percentage: totalCouponRedeemed
          ? Math.round((item?.count / totalCouponRedeemed) * 1000) / 10
          : 0,
      };
    },
  );
  const storeChartData = analyticsData.top10CouponUsedByStoreChart?.map(
    (item) => {
      return {
        name: item?.name,
        value: item?.count,
        percentage: totalCouponRedeemed
          ? Math.round((item?.count / totalCouponRedeemed) * 1000) / 10
          : 0,
      };
    },
  );
  const districtChartData = analyticsData.top10CouponUsedByDistrictChart?.map(
    (item) => {
      return {
        name: item?.name,
        value: item?.count,
        percentage: totalCouponRedeemed
          ? Math.round((item?.count / totalCouponRedeemed) * 1000) / 10
          : 0,
      };
    },
  );
  const campaignChartData = analyticsData.top10CouponUsedByCampaignChart?.map(
    (item) => {
      return {
        name: item?.name,
        value: item?.count,
        percentage: totalCouponRedeemed
          ? Math.round((item?.count / totalCouponRedeemed) * 1000) / 10
          : 0,
      };
    },
  );

  return {
    totalCouponRedeemed,
    brandChartData,
    storeChartData,
    districtChartData,
    campaignChartData,
  };
};

const parseTop10CouponAcquiredApiData = (resp) => {
  if (!resp) {
    return;
  }
  const { analyticsData } = resp;
  if (!analyticsData) {
    return;
  }

  const totalCouponAcquired =
    analyticsData.totalNumberOfCouponAcquired?.value || 0;
  const top10CouponAcquiredByBrandData =
    analyticsData.top10CouponAcquiredByBrandChart?.map((item) => {
      return {
        name: item?.name,
        value: item?.count,
        percentage: totalCouponAcquired
          ? Math.round((item?.count / totalCouponAcquired) * 1000) / 10
          : 0,
      };
    });
  const top10CouponAcquiredByCampaignData =
    analyticsData.top10CouponAcquiredByCampaignChart?.map((item) => {
      return {
        name: item?.name,
        value: item?.count,
        percentage: totalCouponAcquired
          ? Math.round((item?.count / totalCouponAcquired) * 1000) / 10
          : 0,
      };
    });
  return {
    totalCouponAcquired,
    top10CouponAcquiredByCampaignData: top10CouponAcquiredByCampaignData,
    top10CouponAcquiredByBrandData: top10CouponAcquiredByBrandData,
  };
};

const parseTop5CustomerNationalityApiData = (resp) => {
  if (!resp) {
    return;
  }
  const { analyticsData } = resp;
  if (!analyticsData) {
    return;
  }

  const totalCustomerNationality =
    analyticsData.totalNumberOfCustomers?.value || 0;
  const totalWithNationality =
    analyticsData.totalNumberOfCustomersWithNationality?.value || 0;
  const top5CustomerNationalityData =
    analyticsData.top5NationalityOfCustomersChart?.map((item) => {
      return {
        name: item?.name,
        value: item?.count,
        percentage: totalWithNationality
          ? Math.round((item?.count / totalWithNationality) * 1000) / 10
          : 0,
      };
    });
  return {
    totalCustomerNationality,
    totalWithNationality,
    top5CustomerNationalityData,
  };
};

const parseGenderApiData = (resp) => {
  if (!resp) {
    return;
  }
  const { analyticsData } = resp;
  if (!analyticsData) {
    return;
  }

  const totalNumberOfCustomers =
    analyticsData.totalNumberOfCustomers?.value || 0;
  const totalNumberOfMaleCustomers =
    analyticsData.totalNumberOfMaleCustomers?.value || 0;
  const totalNumberOfFemaleCustomers =
    analyticsData.totalNumberOfFemaleCustomers?.value || 0;
  const dataCollected =
    totalNumberOfMaleCustomers + totalNumberOfFemaleCustomers;
  const maleCustomersData = {
    value: totalNumberOfMaleCustomers,
    percentage: dataCollected
      ? Math.round((totalNumberOfMaleCustomers / dataCollected) * 1000) / 10
      : 0,
  };
  const femaleCustomersData = {
    value: totalNumberOfFemaleCustomers,
    percentage: dataCollected
      ? Math.round((totalNumberOfFemaleCustomers / dataCollected) * 1000) / 10
      : 0,
  };

  return {
    totalNumberOfCustomers,
    dataCollected,
    maleCustomersData,
    femaleCustomersData,
  };
};

export default {
  namespace: 'dashboard',
  state: {
    customers: {},
    coupons: {},
    campaigns: {},
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clearState(state, { payload }) {
      return {
        customers: {},
        coupons: {},
        campaigns: {},
        couponFilter: state?.couponFilter || {},
        campaignFilter: state?.campaignFilter || {},
        campaginCategoryFilter: state?.campaginCategoryFilter || {},
        couponAcquireFilter: state?.couponAcquireFilter || {},
        couponRedeemFilter: state?.couponRedeemFilter || {},
        campaginAttributeTagsFilter: state?.campaginAttributeTagsFilter || {},
      };
    },
  },
  effects: {
    setConfig: [
      function* ({ payload }, { call, select, put }) {
        yield put({ type: 'updateState', payload: payload });
      },
      { type: 'takeLatest' },
    ],

    getDashboardData: [
      function* ({ payload }, { call, select, put }) {
        yield put({ type: 'getCouponData', payload: payload });
        yield put({ type: 'getCampaignData', payload: payload });

        // const { startDate, endDate } = payload;
        // const serviceArgs = [getDashboardData, startDate, endDate];

        // function* onSuccess(data) {
        //   const processedData = parseDashboardApiData(data);
        //   yield put(
        //     createAction('updateState')({
        //       ...processedData,
        //       endDate,
        //       startDate,
        //     }),
        //   );
        // }
        // function* onFailed(data) {
        //   console.log('@Get dashboard data failed: ', data);
        // }
        // yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    doCouponDataRequest: [
      function* ({ payload }, { call, select, put }) {
        const { startDate, endDate, key, campaginTypes, isStarred } = payload;
        const request = COUPON_DATA_TYPE[key];

        const serviceArgs = [
          getCouonDashboardData,
          startDate,
          endDate,
          request,
          campaginTypes,
          isStarred,
        ];
        function* onSuccess(data) {
          yield put(
            createAction('updateState')({
              [key]: data?.analyticsData?.[key] || {},
            }),
          );
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
    ],

    getCouponData: [
      function* ({ payload }, { call, select, put, all }) {
        const { campaginTypes, isStarred } = yield select((state) => ({
          campaginTypes: state.dashboard.couponFilter?.selectedCampaignType,
          isStarred: state.dashboard.couponFilter?.isStarred,
        }));

        const couponReqeustKeys = Object.keys(COUPON_DATA_TYPE);
        const requests = couponReqeustKeys.map((key) =>
          put({
            type: 'doCouponDataRequest',
            payload: { ...payload, key, campaginTypes, isStarred },
          }),
        );
        yield all(requests);
      },
      { type: 'takeLatest' },
    ],
    getCampaignData: [
      function* ({ payload }, { call, select, put, all }) {
        const { campaginTypes, isStarred } = yield select((state) => ({
          campaginTypes: state.dashboard.campaignFilter?.selectedCampaignType,
          isStarred: state.dashboard.campaignFilter?.isStarred,
        }));
        // const requests = couponReqeustKeys.map((key) =>
        //   put({
        //     type: 'doCouponDataRequest',
        //     payload: { ...payload, key: key },
        //   }),
        // );
        // yield all(requests);
        const serviceArgs = [
          getDashboardCampaignData,
          payload?.startDate,
          payload?.endDate,
          campaginTypes,
          isStarred,
        ];
        function* onSuccess(data) {
          yield put(
            createAction('updateState')({
              campaigns: {
                totalPublished:
                  data?.analyticsData?.totalNumberOfPublishedCampaigns,
                totalNonpublished:
                  data?.analyticsData?.totalNumberOfNonpublishedCampaigns,
                totalExpired:
                  data?.analyticsData?.totalNumberOfExpiredCampaigns,
              },
            }),
          );
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    getTop10CouponRedeemData: [
      function* ({ payload }, { call, select, put }) {
        const { startDate, endDate } = payload;
        const { campaginTypes, isStarred } = yield select((state) => ({
          campaginTypes:
            state.dashboard.couponRedeemFilter?.selectedCampaignType,
          isStarred: state.dashboard.couponRedeemFilter?.isStarred,
        }));

        const serviceArgs = [
          getDashboardTop10CouponRedeemData,
          startDate,
          endDate,
          campaginTypes,
          isStarred,
        ];
        function* onSuccess(data) {
          const processedData = parseTop10CouponRedeemApiData(data);

          yield put(
            createAction('updateState')({ top10CouponRedeem: processedData }),
          );
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getTop10CouponAcquiredData: [
      function* ({ payload }, { call, select, put }) {
        const { startDate, endDate } = payload;
        const { campaginTypes, isStarred } = yield select((state) => ({
          campaginTypes:
            state.dashboard.couponAcquireFilter?.selectedCampaignType,
          isStarred: state.dashboard.couponAcquireFilter?.isStarred,
        }));
        const serviceArgs = [
          getDashboardTop10CouponAcquiredData,
          startDate,
          endDate,
          campaginTypes,
          isStarred,
        ];
        function* onSuccess(data) {
          // const processedData = parseTop10CouponAcquiredApiData(example);
          const processedData = parseTop10CouponAcquiredApiData(data);

          yield put(
            createAction('updateState')({ top10CouponAcquired: processedData }),
          );
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getTop5NationalityData: [
      function* ({ payload }, { call, select, put }) {
        const { startDate, endDate } = payload;
        const serviceArgs = [
          getDashboardTop5NationalityData,
          startDate,
          endDate,
        ];
        function* onSuccess(data) {
          // const processedData = parseTop5CustomerNationalityApiData(example1);
          const processedData = parseTop5CustomerNationalityApiData(data);

          yield put(
            createAction('updateState')({
              top5CustomerNationality: processedData,
            }),
          );
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getGenderData: [
      function* ({ payload }, { call, select, put }) {
        const { startDate, endDate } = payload;
        const serviceArgs = [getDashboardGenderData, startDate, endDate];
        function* onSuccess(data) {
          // const processedData = parseGenderApiData(example2);
          const processedData = parseGenderApiData(data);

          yield put(
            createAction('updateState')({ customerGender: processedData }),
          );
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getGoodieBagData: [
      function* ({ payload }, { call, select, put, all }) {
        // const { campaginTypes, isStarred } = yield select((state) => ({
        //   campaginTypes: state.dashboard.campaignFilter?.selectedCampaignType,
        //   isStarred: state.dashboard.campaignFilter?.isStarred,
        // }));
        // const requests = couponReqeustKeys.map((key) =>
        //   put({
        //     type: 'doCouponDataRequest',
        //     payload: { ...payload, key: key },
        //   }),
        // );
        // yield all(requests);
        const serviceArgs = [
          getGoodieBagDashboardData,
          payload?.startDate,
          payload?.endDate,
        ];
        function* onSuccess(data) {
          yield put(
            createAction('updateState')({
              ...data?.analyticsData,
            }),
          );
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
};
