import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GOODIE_BAG_REWARD_TYPE_OPTIONS } from '../../../models/GoodieBagsModel';
import { CustomTitleWithDropDown } from '../../customer/CustomTitleWithDropDown';

function GoodieBagSelector(props) {
  const title = props.title;
  const rewardType = props.rewardType;
  const defaultValue = props.defaultValue || null;
  const setValue = props.setValue || (() => {});
  const [isReset, setIsReset] = useState(false);

  const { goodieBagList } = useSelector((state) => ({
    goodieBagList: state.goodieBags.allList,
  }));

  useEffect(() => {
    if (rewardType) {
      setIsReset(true);
      if (
        defaultValue?.value?.rewardType !==
          GOODIE_BAG_REWARD_TYPE_OPTIONS.both.value &&
        defaultValue?.value?.rewardType !== rewardType
      ) {
        setValue(null);
      }
    }
  }, [rewardType]);

  const onSelectChange = (value) => {
    setValue(value?.value);
  };
  return (
    <CustomTitleWithDropDown
      title={title}
      titleStyle={'create-section-label-no-top-space'}
      defaultValue={defaultValue}
      setValue={onSelectChange}
      source={goodieBagList}
      loadMoreAction={'goodieBags/getList'}
      filterAction={'goodieBags/getList'}
      isReset={isReset}
      resetChange={() => {
        setIsReset(false);
      }}
      defaultFilter={{ rewardType: rewardType, isSimplify: true }}
    />
  );
}

export default GoodieBagSelector;
