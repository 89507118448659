import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import './CustomListComponent.scss';
import '../../containers/base/BaseListContainer.scss';
import './BaseSimpleTable.scss';
import { CustomStatusTagFromText } from './CustomStatusTag';
import CustomPagination from './CustomPagination';
import { ActionsDropdownForItem } from './ActionsDropdown';
import CustomQRCode from './CustomQRCode';
import copy from 'copy-to-clipboard';
import CopyIcon from '../../assets/images/campaign/copy_icon.svg';
import AuthButton from '../../components/base/AuthButton';
import NoDataPage from './NoDataPage';

export default function BaseSimpleTable({
  fields = [],
  dataList = [],
  totalCount,
  customTableClassName = '',
  customActions,
}) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const queryString = require('query-string');

  const [currentPage, setCurrentPage] = useState();

  useEffect(() => {
    const searchDict = queryString.parse(location.search);
    const stringPage = searchDict['page'] || 1;
    const currentPage = parseInt(stringPage);
    setCurrentPage(currentPage - 1);
  }, [location]);

  const renderRowData = (item, rowIndex) => {
    return (
      <>
        {fields.map((field, index) => {
          switch (field.fieldName) {
            case 'merchantName':
              return (
                <td key={`td-${rowIndex}-${index}`}>
                  <Link
                    to={`${location.pathname.replace(/\/$/g, '')}/${item.pk}/`}
                    className="all-list-scrollable-area-text-common all-list-scrollable-area-clickable-field"
                  >
                    {item[field.fieldName] || '-'}
                  </Link>
                </td>
              );
            case 'qrcodeImage':
              return (
                <td key={`td-${rowIndex}-${index}`} className="">
                  <div className="">
                    <label className="">
                      {item[field.fieldName] ? (
                        <CustomQRCode imageLink={item[field.fieldName]} />
                      ) : (
                        '-'
                      )}
                    </label>
                  </div>
                </td>
              );
            case 'status':
              return (
                <td
                  key={`td-${rowIndex}-${index}`}
                  className="simple-list-status-field"
                >
                  {CustomStatusTagFromText(item[field.fieldName])}
                </td>
              );
            case 'Action':
              const actions = customActions.map((actionItem) => {
                return {
                  name:
                    actionItem.name === 'Activate'
                      ? item.isActive
                        ? 'Deactivate'
                        : 'Activate'
                      : actionItem.name,
                  action: (item) => actionItem.action(item),
                  requires: actionItem.requires,
                };
              });
              return (
                <td
                  key={`td-${rowIndex}-${index}`}
                  className="all-list-scrollable-area-actions simple-list-action-field"
                >
                  {actions ? (
                    <ActionsDropdownForItem object={item} actions={actions} />
                  ) : null}
                </td>
              );
            default:
              if (field.copyToast && item[field.fieldName]) {
                return (
                  <td key={`td-${rowIndex}-${index}`}>
                    {item[field.fieldName]}
                    <AuthButton
                      className="lebel-right-button"
                      onClick={(e) => {
                        copy(item[field.fieldName]);
                        dispatch({
                          type: 'navBars/updateState',
                          payload: {
                            saveDiscardToastShowing: {
                              value: true,
                              type: field.copyToast,
                            },
                          },
                        });
                      }}
                      variant="link"
                    >
                      <Image src={CopyIcon} />
                    </AuthButton>
                  </td>
                );
              }
              return (
                <td key={`td-${rowIndex}-${index}`}>
                  {item[field.fieldName] || '-'}
                </td>
              );
          }
        })}
      </>
    );
  };
  return (
    <>
      <div className="base-simple-table-div">
        <table
          border="1"
          className={`base-simple-table ${customTableClassName}`}
        >
          <thead>
            <tr>
              {fields.map((item, index) => {
                return <td key={`th-${index}`}>{item.displayName}</td>;
              })}
            </tr>
          </thead>
          <tbody>
            {dataList?.length > 0 ? (
              dataList.map((item, rowIndex) => {
                return (
                  <tr key={`tr-${rowIndex}`}>
                    {renderRowData(item, rowIndex)}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={fields.length}>
                  <NoDataPage />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {totalCount > 0 ? (
        <div className="all-list-scrollable-area-pagination">
          <CustomPagination
            initPage={currentPage}
            pageSize={Math.ceil(totalCount / 20)}
            pageChange={(page) => {
              history.push({
                pathname: location.pathname,
                hash: location.hash,
                search: queryString.stringify({
                  ...queryString.parse(location.search),
                  page: page + 1,
                }),
              });
            }}
          />
        </div>
      ) : null}
    </>
  );
}
