import { BaseHelper } from './BaseGraphQLAPIHelper';
const LIST_CONTENT = `{
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        creationDate
        lastModifiedDate
        id
        pk
        name
        month
        reviewer{
            id
            username
        }
        reviewStatus
        totalNumberOfCouponAcquiredByUser
        totalNumberOfCouponUsedByUser
        reportComments{
          edges{
            node{
                isMerchantAdministrator
                remark
                pk
              }
          }
      }
        merchant{
            pk
            name
        }
      }
    }
  }`



const DETAIL_CONTENT = `{
  creationDate
  lastModifiedDate
  id
  pk
  name
  month
  reviewer{
      id
      username
  }
  reviewStatus
  totalNumberOfCouponAcquiredByUser
  totalNumberOfCouponUsedByUser
  totalHktbGivesToMerchant
  totalMerchantGivesToHktb
  campaigns
  reportCouponTemplates{
      edges{
          node{
            creationDate
            lastModifiedDate
            id
            report{
                id
                pk
                name
            }
            couponTemplate{
                id
                pk
                name
            }
            couponTemplateName
            perCouponSettlementValueForHktb
            perCouponSettlementValueForMerchant
            numberOfCouponAcquiredByUser
            numberOfCouponUsedByUser
            settlementValueHktbTotalContribute
            settlementValueMerchantTotalContribute
            pk
          }
      }
  }
  reportComments{
      edges{
        node{
            creationDate
            lastModifiedDate
            id
            report{
                id
                pk
                name
            }
            administrator{
                pk
                username
            }
            isMerchantAdministrator
            amendmentNumber
            remark
            pk
          }
      }
  }
  merchant{
      pk
      name
  }
}`;

export const getPaymentSettlementList = (afterCursor, others) => {
  let filter = ``;
  let type = others?.type == 'MERCHANT' ? 'MERCHANT' : 'MONTHLY';
  let orderBy = others?.sort || 'pk';
  if (others?.filterName && others?.type == 'MONTHLY') {
    filter = `, name:"${others.filterName}"`;
  }else if(others?.filterName && others?.type == 'MERCHANT'){
    filter = `, merchantName: "${others?.filterName}"`
  };
  const query = `{
    paymentAndSettlementReports(
        first: 20, 
        after:"${afterCursor}", 
        reportFilterType: "${type}", 
        orderBy:"${orderBy}"${filter}
      ) ${LIST_CONTENT}
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getPaymentSettlementReportListByName = (afterCursor, others) => {
    let month = '';
    let filter = '';
    let orderBy = others?.sort || 'reviewStatusOrder,pk';
    if (others?.month){
        month = others?.month;
    };
    if (others?.reviewStatus){
        filter += `, reviewStatus: "${others?.reviewStatus}"`;
    };
    if (others?.filterName){
        filter += `, merchantName: "${others?.filterName}"`;
    }
    const query = `{
      paymentAndSettlementReports(
        first: 20, 
        after:"${afterCursor}", 
        month: "${month}", 
        orderBy:"${orderBy}"${filter}
      ) ${LIST_CONTENT}
    }`;
    const variables = {};
    return BaseHelper.callGraphQLAPI({
      query,
      variables,
    });
  };

  export const getPaymentSettlementReportListByMerchant = (afterCursor, others) => {
    let merchant = '';
    let filter = '';
    let orderBy = others?.sort || 'reviewStatusOrder,pk';
    if (others?.merchant){
        merchant = others?.merchant;
    };
    if (others?.reviewStatus){
        filter += `, reviewStatus: "${others?.reviewStatus}"`;
    };
    if (others?.filterName){
        filter += `, name: "${others?.filterName}"`;
    };
    if (others?.month){
        filter += `, monthly: ${others?.month}`
    }
    const query = `{
      paymentAndSettlementReports(
        first: 20, 
        after:"${afterCursor}",
        merchantId: ${merchant},
        orderBy:"${orderBy}"${filter}
      ) ${LIST_CONTENT}
    }`;
    const variables = {};
    return BaseHelper.callGraphQLAPI({
      query,
      variables,
    });
  };

export const getPaymentSettlement = (id) => {
  const query = `{
    paymentAndSettlementReport(id: "${id}") ${DETAIL_CONTENT}
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const updatePaymentSettlementStatus = (input) => {
  const query = `mutation updatePaymentAndSettlementReportStatus($input: UpdatePaymentAndSettlementReportStatusInput!) {
    updatePaymentAndSettlementReportStatus(input: $input) {
        success
    }
  }
  `;
  const variables = {
    input: input
  };
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
}