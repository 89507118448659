import React, { useEffect } from 'react';
import ContentSections from '../base/ContentSections';
import { OnlyContinueButton } from '../base/BottomStepComponent';
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from '../../utils';
import { APIStatus, MessageChannel } from '../../config/CustomEnums';
import Loading from '../base/Loading';
import { MessageChannelConfig } from './CreateMessageConfig';
import MessageChannelCard from './MessageChannelCard';
import CampaignSelectDropdown from './CampaignSelectDropdown';

function CreateMessageStepOne() {
  const dispatch = useDispatch();

  const { status, channels, relatedCampaign } = useSelector((state) => ({
    status: state.createMessage.createStatus,
    channels: state.createMessage.message.channels,
    relatedCampaign: state.createMessage.message.relatedCampaign,
  }));

  const messageChannelArea = () => {
    return (
      <div className="step-type-area message-channel-area">
        {MessageChannelConfig.map((channel) => (
          <MessageChannelCard
            key={channel.channel}
            channelConfig={channel}
            selectedChannels={channels}
            select={(channel) => {
              let selectedChannels = channels;
              const index = selectedChannels.indexOf(channel);
              if (index === -1) {
                selectedChannels.push(channel);
                if (
                  channel === MessageChannel.push &&
                  selectedChannels.indexOf(MessageChannel.inbox) === -1
                ) {
                  selectedChannels.push(MessageChannel.inbox);
                }
              } else {
                selectedChannels.splice(index, 1);
              }
              dispatch(
                createAction('createMessage/updateMessageState')({
                  channels: selectedChannels,
                  displayChannels: selectedChannels.toString(),
                }),
              );
            }}
          />
        ))}
      </div>
    );
  };

  const relatedCampaigns = () => {
    return (
      <CampaignSelectDropdown
        title={'Related campaign (optional)'}
        isPrompt
        defaultValue={{ label: relatedCampaign?.name, value: relatedCampaign }}
        setValue={(value) => {
          dispatch(
            createAction('createMessage/updateMessageState')({
              relatedCampaign: value,
              relatedCampaignName: value.name,
            }),
          );
        }}
      />
    );
  };

  const sections = [
    <>
      {status === APIStatus.calling ? (
        <Loading />
      ) : (
        <div>
          <label className="step-one-title">Message channel</label>
          {messageChannelArea()}
          {relatedCampaigns()}
        </div>
      )}
    </>,
  ];

  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <OnlyContinueButton
        disabledContinue={channels.length === 0}
        continueAction={() => {
          dispatch(
            createAction('createMessage/stepChange')({
              step: 0,
            }),
          );
        }}
      />
    </>
  );
}

export default CreateMessageStepOne;
