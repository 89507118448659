import React, { Component } from 'react';
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
} from 'react-google-maps';
import { GOOGLE_API_KEY } from '../../utils';

class CustomMap extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   lat: props.lat,
    //   lng: props.lng,
    // };
  }

  mapClick = (data) => {
    this.props.onClick && this.props.onClick(data);
  };

  shouldComponentUpdate(nextProps) {
    return nextProps.lat !== this.props.lat || nextProps.lng !== this.props.lng;
    // return false;
  }

  render() {
    const GoogleMapExample = withScriptjs(
      withGoogleMap((props) => (
        <GoogleMap
          defaultCenter={{
            lat: parseFloat(props.lat) || 22.3193,
            lng: parseFloat(props.lng) || 114.1694,
          }}
          defaultZoom={16}
          onClick={(e) => {
            // this.setState({
            //   lat: e.latLng.lat().toFixed(6),
            //   lng: e.latLng.lng().toFixed(6),
            // });
            props.onClick && props.onClick(e);
          }}
          editable={false}
          defaultOptions={{
            fullscreenControl: false,
            gestureHandling: 'none',
            mapTypeControl: false,
            zoomControl: false,
            streetViewControl: false,
          }}
        >
          <Marker
            position={{
              lat: parseFloat(props.lat),
              lng: parseFloat(props.lng),
            }}
          />
        </GoogleMap>
      )),
    );
    return (
      <div>
        <GoogleMapExample
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}`}
          loadingElement={
            <div style={{ height: `${this.props.height || 500}px` }} />
          }
          containerElement={
            <div
              style={{
                height: `${this.props.height || 500}px`,
                ...this.props.style?.container,
              }}
            />
          }
          mapElement={
            <div style={{ height: `${this.props.height || 500}px` }} />
          }
          onClick={this.mapClick}
          lat={this.props.lat}
          lng={this.props.lng}
        ></GoogleMapExample>
      </div>
    );
  }
}

export default CustomMap;
