import React, { useEffect, useRef, useState } from 'react';
import './Login.scss';
import { Button } from 'react-bootstrap';
import { withResizeDetector } from 'react-resize-detector';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { Image, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import LoginLeftBackground from '../../assets/images/hktb_login_bg.png';
import LoginLeftIcon from '../../assets/images/hktb_login_icon.png';
import LoginLogo from '../../assets/images/hktb_login_logo.svg';
import { createAction } from '../../utils';
import {
  CustomTitleLabel,
  CustomTitleWithRadio,
} from '../../components/earning/CustomBaseComponments';
import ErrorFieldMessage from '../../components/base/ErrorFieldMessage';

const LEFT_ICON_MAX_WIDTH = 604;
const LEFT_ICON_MAX_LEFT_POSITION = 142;
const FORM_MAX_MARGIN_LEFT = 566;
const FORM_MAX_PADDING_LEFT = 100;
const MAX_DYNAMIC_SCREEN_WIDTH = 1280;
const MIN_DYNAMIC_SCREEN_WIDTH = 750;
const CHANGE_FIX = 1.25;
const TIMER_LENGTH = 59;

const LoginMain = ({ width, height }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    isLogin,
    loginError,
    userExist,
    haveLoginMethod,
    adminLoginMethodError,
  } = useSelector((state) => ({
    isLogin: state.users.isLogin,
    loginError: state.users.loginError,
    userExist: state.users.userExist,
    haveLoginMethod: state.users.haveLoginMethod,
    adminLoginMethodError: state.users.adminLoginMethodError,
  }));
  const [sendLoading, setSendLoading] = useState(false);
  const [sendButtonLoading, setSendButtonLoading] = useState(false);
  const [loadingSeconds, setLoadingSeconds] = useState(TIMER_LENGTH);
  const [formData, setFormData] = useState({});
  const fillInfo =
    formData.username && formData.password && formData.method && formData.code;

  const login = (event) => {
    event.preventDefault();
    // console.log('@@21: ', username, password);
    dispatch({
      type: 'users/login',
      payload: formData,
    });
  };

  useEffect(() => {
    if (isLogin) {
      history.push('/');
    }
  }, [isLogin]);

  let leftIconWidth = LEFT_ICON_MAX_WIDTH;
  let leftIconLeft = LEFT_ICON_MAX_LEFT_POSITION;
  let formLeftMargin = FORM_MAX_MARGIN_LEFT;
  let formLeftPadding = FORM_MAX_PADDING_LEFT;
  if (width < MAX_DYNAMIC_SCREEN_WIDTH && width >= MIN_DYNAMIC_SCREEN_WIDTH) {
    const changePercentage =
      (MAX_DYNAMIC_SCREEN_WIDTH - width) / MAX_DYNAMIC_SCREEN_WIDTH;
    leftIconWidth = (1 - CHANGE_FIX * changePercentage) * LEFT_ICON_MAX_WIDTH;
    leftIconLeft =
      (1 - CHANGE_FIX * changePercentage) * LEFT_ICON_MAX_LEFT_POSITION;
    formLeftMargin = (width / MAX_DYNAMIC_SCREEN_WIDTH) * FORM_MAX_MARGIN_LEFT;
    formLeftPadding =
      FORM_MAX_PADDING_LEFT -
      (((MAX_DYNAMIC_SCREEN_WIDTH - width) * CHANGE_FIX) /
        (MAX_DYNAMIC_SCREEN_WIDTH - MIN_DYNAMIC_SCREEN_WIDTH)) *
        FORM_MAX_PADDING_LEFT;
  }
  const hideLeft = width < MIN_DYNAMIC_SCREEN_WIDTH;
  if (hideLeft) {
    formLeftMargin = 0;
    formLeftPadding = 0;
  }

  const getFormItem = (title, name, type) => {
    return (
      <div className="login-form-item">
        <label className="login-form-label">{title}</label>
        <input
          defaultValue={formData?.[name]}
          name={name}
          type={type}
          className="login-form-input"
          onChange={(e) => {
            setFormData({ ...formData, [name]: e.target.value });
            if (loginError?.credentialsError) {
              const loginError = { credentialsError: false };
              dispatch({
                type: 'users/updateState',
                payload: {
                  loginError,
                },
              });
            }
          }}
          {...(name === 'username'
            ? {
                onBlur: (e) => {
                  formData.username &&
                    dispatch({
                      type: 'users/checkAdminLoginValid',
                      payload: { username: formData.username },
                    });
                },
              }
            : null)}
        />
        {loginError?.credentialsError ? (
          <label className={`login-error `}>
            We cannot recognize your login / password combination.
          </label>
        ) : null}
        {!userExist && name === 'username' ? (
          <label className={`login-error`}>User is not exist.</label>
        ) : null}
        {adminLoginMethodError && name === 'username' ? (
          <label className={`login-error`}>Please login via ParterNet.</label>
        ) : null}
      </div>
    );
  };

  const handleSendCode = () => {
    setSendButtonLoading(true);
    const { username, password, method } = formData;
    dispatch({
      type: 'users/sendCode',
      payload: {
        data: {
          username,
          password,
          method,
        },
        handleSuccess: () => {
          setSendLoading(true);
        },
        afterAction: () => {
          setSendButtonLoading(false);
        },
      },
    });
  };

  useEffect(() => {
    const loadingInterval =
      sendLoading &&
      loadingSeconds > 0 &&
      setInterval(() => {
        if (loadingSeconds > 1) {
          setLoadingSeconds(loadingSeconds - 1);
        } else {
          setSendLoading(false);
          setLoadingSeconds(TIMER_LENGTH);
        }
      }, 1000);
    return () => {
      clearInterval(loadingInterval);
    };
  }, [sendLoading, loadingSeconds]);

  useEffect(() => {
    if (haveLoginMethod?.length === 1) {
      setFormData({ ...formData, method: haveLoginMethod[0] });
    }
  }, [haveLoginMethod]);

  return (
    <div className="login">
      {hideLeft ? null : (
        <>
          <Image className="left-bg" src={LoginLeftBackground} />
          <Image
            className="left-icon"
            style={{
              width: leftIconWidth,
              left: leftIconLeft,
            }}
            src={LoginLeftIcon}
          />
        </>
      )}
      <Form
        style={{ marginLeft: formLeftMargin, paddingLeft: formLeftPadding }}
        className="login-form"
        autoComplete="off"
        noValidate
        onSubmit={login}
      >
        <Image className="login-form-logo" src={LoginLogo} />
        {getFormItem('Account', 'username', 'text')}
        {getFormItem('Password', 'password', 'password')}
        {haveLoginMethod?.length > 1 ? (
          <div className="verify-channel-container">
            <CustomTitleWithRadio
              title={'Verification channel'}
              customTitleClass={'verify-channel-title'}
              options={[
                { label: 'SMS', value: 'MOBILE_PHONE_NUMBER' },
                { label: 'Email', value: 'EMAIL_ADDRESS' },
              ]}
              setValue={(value) => {
                setFormData({ ...formData, method: value });
              }}
            />
          </div>
        ) : null}
        <div className="otp-code-container">
          <CustomTitleLabel
            className={'otp-code-title'}
            title={'Enter verification code'}
          />
          <div className="d-flex">
            <input
              defaultValue={formData?.code}
              type={'number'}
              name={'code'}
              className={`custom-markdown-area-title ${
                loginError?.code?.error ? 'error-field' : ''
              }  opt-code-input`}
              onChange={(e) => {
                setFormData({ ...formData, code: e.target.value });
              }}
              onKeyUp={(e) => {
                if (e.code === 'Enter' && fillInfo && userExist) {
                  login(e);
                }
              }}
            />
            <Button
              className="btn-further otp-code-send-button"
              onClick={() => {
                handleSendCode();
              }}
              disabled={
                sendLoading ||
                !formData.username ||
                !formData.password ||
                !formData.method ||
                !userExist ||
                sendButtonLoading ||
                adminLoginMethodError
              }
            >
              {sendLoading ? `${loadingSeconds}s` : 'Send code'}
            </Button>
          </div>
          {loginError?.code?.error ? (
            <ErrorFieldMessage
              id={loginError?.code?.id}
              error={loginError?.code}
              message={loginError?.code?.message}
            />
          ) : null}
        </div>

        <Button
          type="submit"
          className="btn-further login-form-button"
          disabled={!fillInfo || !userExist || adminLoginMethodError}
        >
          Login
        </Button>
        <div className="login-devide-line-container">
          <div className="login-devide-line"></div>
          <label className="login-devide-wording">OR</label>
          <div className="login-devide-line"></div>
        </div>
        <label className="third-login-button-title">
          Login with your PartnerNet Account
        </label>
        <Button
          className="btn-further login-form-button"
          onClick={() => {
            const CMS_DOMAIN =
              process.env.REACT_APP_DOMAIN || 'https://hktb.motherapp.cn:7543';
            window.location.href = `${CMS_DOMAIN}/SP/AssertionConsumer`;
          }}
        >
          Login via PartnerNet
        </Button>
      </Form>
    </div>
  );
};

const LoginWithSize = withResizeDetector(LoginMain);

function Login() {
  const history = useHistory();
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state.users.isLogin);
  if (history?.location?.state?.isLogout) {
    dispatch({ type: 'users/logout' });
    return <LoginWithSize />;
  }
  return isLogin ? <Redirect to="/" /> : <LoginWithSize />;
}

export default withRouter(Login);
