import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { isGoodieBagType } from '../../../models/CreateCampaignModel';
import { CampaignType } from '../../../config/CustomEnums';

const channelAction = (channel, campaign) => {
  let link = `/campaigns/preview/0`;
  if (campaign.pk) {
    link = `/campaigns/preview/${campaign.pk}/0`;
  }

  const newWindow = window.open(link, `_blank`);
  if (!newWindow) {
    return;
  }
  newWindow.channel = { name: 'all type' };
  newWindow.campaign = campaign;
};

const DHKchannelAction = (channel, campaign) => {
  let link = `/campaigns/dhk_preview/0`;
  if (campaign.pk) {
    link = `/campaigns/dhk_preview/${campaign.pk}/0`;
  }

  const newWindow = window.open(link, `_blank`);
  if (!newWindow) {
    return;
  }
  newWindow.channel = { name: 'all type' };
  newWindow.campaign = campaign;
};

const esolutionTesterPreview = (campaign) => {
  const domain = process.env.REACT_APP_FRONTEND_LINK;
  const link = `${domain}/en/campaign-detail?campaignId=${
    campaign.pk || campaign.id
  }&tester_preview=true`;
  window.open(link);
};

export default function CampaignPreviewButton({
  channels,
  campaign,
  fromDetail = false,
}) {
  const actionButtons = () => {
    const data = [
      {
        name: 'HongKong Discover',
        action: () => {
          channelAction(channels, campaign);
        },
      },
      {
        name: 'DHK preview',
        action: () => {
          DHKchannelAction(channels, campaign);
        },
      },
    ];
    if (fromDetail) {
      //isGoodieBagType(campaign?.campaignType) &&
      data.push({
        name: 'E-Solution (View by Test user only)',
        action: () => {
          esolutionTesterPreview(campaign);
        },
      });
    }
    return data;
  };
  const buttons = actionButtons();

  return (
    <Dropdown>
      <Dropdown.Toggle
        className="base-save-button-temp-button base-save-and-back-component-button btn-back-button-common"
        variant="none"
        id={`dropdown-`}
      >
        Preview campaign
      </Dropdown.Toggle>

      <Dropdown.Menu
        className="preview-prop-area"
        style={{ zIndex: 2, width: 300 }}
      >
        <label className="preview-prop-font preview-prop-title">Channel</label>
        {buttons.map((item) => (
          <li
            className="preview-prop-font preview-prop-item"
            onClick={item.action}
          >
            {item.name}
          </li>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
