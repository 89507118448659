import React, { useState, useEffect }  from 'react';
import BasePrompt from '../base/prompt/BasePrompt';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { createAction } from '../../utils';
import { PaymentAndSettlementReviewStatusType } from '../../config/CustomEnums';

function PaymentSettlementUpdateStatusPrompt(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const show = props.show || false;
  const onClose = props.onClose || (() => {});
  const clickButton = props.clickButton || null;
  const id = props.id || null;
  const brandPk = props.brandPk || null;
  
  const [promptDict, setPromptDict] = useState({
    title: '',
    subTitle: '',
    content: <></>,
    rightButton: '',
    status: '',
  });
  const [amendmentNumber, setAmendmentNumber] = useState('');
  const [Remark, setRemark] = useState('');
  const [numValid, setNumValid] = useState(true);
  
  const infoContent = () => {
    return <div className="alert-info-prompt payment-settlement-alert-info-prompt">{promptDict.content}</div>;
  };

  const GoToViewListPage = () => {
    history.push({
      pathname: `/payment_settlement/byMerchant/${brandPk}`, 
      hash: "withCouponData"
    });
  };

  const getRightButton = () => {
    return {
          text: promptDict.rightButton,
          action: () => {
            if (numValid) {
              dispatch(
                createAction('paymentSettlementList/updatePaymentSettlementReportStatus')(
                  { id: id,
                    reviewStatus: promptDict['status'],
                    remark: Remark,
                    amendmentNumber: amendmentNumber,
                    afterAction: () => GoToViewListPage(),
                  },
                ),
              );
            }
          },
        };
  };

  const getDisagreeRemark = () => {
    return (
      <>
        <label className="payment-settlement-disagree-content">
          Remark(optional)
        </label>
        <>
          <textarea
            className="payment-settlement-disagree-remark"
            onChange={({ target }) => {
              const { value } = target;
              setRemark(value);
            }}
            rows="4"
            defaultValue={Remark}
          />
        </>
      </>
    );
  };

  const getAmendmentRemark = () => {
    return (
      <>
        <label className="payment-settlement-amendment-content">
          Amendment number(optional)
        </label>
        <>
          <input
            type="text"
            name="amendmentNumber"
            className="payment-settlement-amendment-number"
            onChange={({ target }) => {
              const { value } = target;
              let isnum = /^\d+$/.test(value);
              setNumValid(value?.length <= 9 && isnum || value === '')
              setAmendmentNumber(value);
            }}
          />
          { numValid
          ? null 
          : <p className={"payment-settlement-amendment-content payment-settlement-amendment-content-error-field"}>
              Please input valid number
            </p>
          }
        </>
        <label className="payment-settlement-amendment-content">
          Remark(optional)
        </label>
        <>
          <textarea
            className="payment-settlement-amendment-remark"
            onChange={({ target }) => {
              const { value } = target;
              setRemark(value);
            }}
            rows="4"
          />
        </>
      </>
    );
  };

  useEffect(()=> {
    switch (clickButton) {
      case "Agree":
        setPromptDict({
          title: 'Confirm to agree?',
          subTitle:
            'Please check the report carefully. Once you click "agree", you will not change the status.',
          content: <></>,
          rightButton: 'Confirm to agree',
          status: PaymentAndSettlementReviewStatusType.agree,
        });
        break;
      case "Disagree":
        setPromptDict({
          title: 'Confirm to disagree?',
          subTitle:
            'You can fill in some information to help HKTBD administrator understand your objection.',
          content: getDisagreeRemark(),
          rightButton: 'Submit',
          status: PaymentAndSettlementReviewStatusType.disagree,
        });
        break;
      case "Amendment":
        setPromptDict({
          title: 'Add Amendment to merchant',
          subTitle:
            'After submit this Amendment, the status of this report will change to "Waiting for review"',
          content: getAmendmentRemark(),
          rightButton: 'Submit',
          status: '',
        });
        break;
    }
  }, [clickButton, numValid]);

  return (
    <BasePrompt
      show={show}
      closeAction={() => {
        onClose(); 
        setNumValid(true);
      }}
      rightButton={getRightButton()}
      title={promptDict.title}
      description={promptDict.subTitle}
      otherBody={infoContent}
    />
  );
}

export default PaymentSettlementUpdateStatusPrompt;
