import { createModel } from './BaseModel';
import {
  getSurveyFormList,
  getSurveyForm,
  deleteSurveyForms,
  createOrUpdateSurveyForm,
} from '../services/SurveyFormAPIHelper';
import { SESSION_KEYS } from '../config/CustomEnums';
import { convertNumberToCursor } from '../utils';
import { apiWithResponseHandle } from './LoadingUtil';

const parseDetail = (data) => {
  return {
    pk: data.pk,
    name: data.name,
    options: data.options,

    // couponTemplates: data.couponTemplates.edges?.map((coupon) => coupon.node),
  };
};

const parseSurveyFormList = (data, user) => {
  const userWorkingTeams = user.workingTeams.map((item) => item.pk);

  return data?.surveyForms.edges.map((item) => {
    return {
      ...item.node,
      couponTemplatesName:
        item.node.linkedCouponTemplatesName
          ?.filter(
            (item) =>
              item &&
              (user?.isSuperuser ||
                (item.workingTeamIds?.length > 0 &&
                  item.workingTeamIds?.filter((val) =>
                    userWorkingTeams?.includes(val),
                  ).length > 0) ||
                item.workingTeamIds?.length <= 0),
          )
          ?.map((coupon) => coupon.name)
          .join('\n') || '-',
      option: item.node.options?.map((option) => option).join(',') || '-',
    };
  });
};

export default createModel({
  namespace: 'surveyForm',
  states: {
    listDisplayFields: [
      { displayName: 'ID', fieldName: 'pk', orderField: 'pk' },
      { displayName: 'Name', fieldName: 'name' },
      { displayName: 'Options', fieldName: 'option' },
      {
        displayName: 'Linked Coupons',
        fieldName: 'couponTemplatesName',
        style: { width: '450px' },
      },
    ],
  },
  reducers: {},
  params: {
    dataKey: SESSION_KEYS.SURVEY_FORM_SESSION_KEY,
    listAPI: getSurveyFormList,
    parse: parseSurveyFormList,
    deleteAPI: deleteSurveyForms,
    pkNode: 'SurveyFormNode',
    detailAPI: getSurveyForm,
    parseDetail: (data) => parseDetail(data.surveyForm),
  },
  effects: {
    getList: [
      function* ({ payload }, { call, select, put }) {
        const page = payload?.page || 1;
        const pageCount = 20;
        const pageCursor = convertNumberToCursor((page - 1) * pageCount - 1);
        const serviceArgs = [getSurveyFormList, pageCursor, payload];

        const user = yield select((state) => state.users);

        function* onSuccess(data) {
          yield put({
            type: 'updateListData',
            payload: {
              ...payload,
              data: parseSurveyFormList(data, user),
              totalCount: data[SESSION_KEYS.SURVEY_FORM_SESSION_KEY].totalCount,
              pageInfo: data[SESSION_KEYS.SURVEY_FORM_SESSION_KEY].pageInfo,
            },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    createOrUpdate: [
      function* ({ payload }, { call, put }) {
        if (payload.pk) {
          const data = {
            name: payload.name,
            options: Object.keys(payload.options)
              .filter((key) => !payload.delOption?.includes(key))
              .map((key) => {
                return payload.options[key];
              }),
          };
          data.id = payload.pk;
          console.log('@@90: ', data);
          const serviceArgs = [createOrUpdateSurveyForm, data];
          yield put({
            type: 'responseCreateOrUpdate',
            payload: { serviceArgs, isCreate: !payload.pk },
          });
        } else {
          const optionList = [];
          optionList.push(payload.defaultOption);
          if (payload.options) {
            Object.keys(payload.options)
              .filter((key) => !payload.delOption?.includes(key))
              .map((key) => {
                optionList.push(payload.options[key]);
                return optionList;
              });
          }
          const data = {
            name: payload.name,
            options: optionList,
          };
          console.log('@@90: ', data);
          const serviceArgs = [createOrUpdateSurveyForm, data];
          yield put({
            type: 'responseCreateOrUpdate',
            payload: { serviceArgs, isCreate: !payload.pk },
          });
        }
      },
      { type: 'takeLatest' },
    ],
  },
});
