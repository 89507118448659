import React, { useEffect, useState } from 'react';
import './PreviewBody.scss';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import TourCard, { getTranslationField } from './TourCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import CustomViewer from '../../../../components/base/CustomViewer';
import i18n from '../../../../I18n';
import Loading from '../../../../components/base/Loading';
SwiperCore.use([Pagination, Autoplay]);

const defaultLanguage = { code: 'en', name: 'English', parameter: 'EN', pk: 1 };

function CampaignGeneralInfo({ language = defaultLanguage, campaign }) {
  const brand = campaign?.linkedBrand;
  return (
    <div className="offer-book">
      <div className="offer-book-content">
        <div className="logo-detail">
          <div className="offer-logo">
            <img className="logo-image" src={brand?.icon} alt="logo" />
            <span>
              {getTranslationField({ language, data: brand, field: 'name' })}
            </span>
          </div>
          <div className="offer-hour">
            9 {i18n.t('preview_hours', { locale: language.code })}
          </div>
        </div>
        <h1>
          {getTranslationField({
            language,
            data: campaign,
            field: 'generalName',
          })}
        </h1>
        <CustomViewer
          className="description"
          initValue={getTranslationField({
            language,
            data: campaign,
            field: 'shortDescription',
          })}
        />
      </div>
    </div>
  );
}

const getImages = (language, campaign) => {
  const images = [];
  const fields = [
    'coverPhoto',
    'detailPhotos',
  ];
  fields.forEach((item) => {
    const image = getTranslationField({ language, field: item, campaign });
    if (image) {
      if (Array.isArray(image)) {
        image.forEach((item) => {
          if (item?.value) {
            images.push(item.value)
          }
          else {
            images.push(item)
          }
        })
      }
      else {
        if (image?.value) {
          images.push(image.value)
        }
        else {
          images.push(image);
        }
      }
    }
  });
  return images;
};

function Images({ language, campaign }) {
  const images = getImages(language, campaign);
  return (
    <Swiper
      init={true}
      observer="true"
      autoplay={{
        stopOnLastSlide: false,
        disableOnInteraction: false,
        delay: 3000,
      }}
      loop
      className={`image-container`}
      pagination={{ clickable: true }}
    >
      {images.map((item, index) => (
        <SwiperSlide key={`slide-${index}`} className="swiper-slide">
          <img src={item} alt="" />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

function Quality({ language, campaign }) {
  return (
    <div className="quality-provider">
      <div className="offer-quality">
        <div className="detail">
          <div className="logo">
            <img src="undefined" alt="undefined" />
          </div>
          <span>
            <a href="#" />
          </span>
        </div>
      </div>
      <div className="offer-quality">
        <div className="detail">
          <div className="logo">
            <img src="undefined" alt="undefined" />
          </div>
          <span>
            <a href="#" />
          </span>
        </div>
      </div>
    </div>
  );
}

function MarkDownSection({
  language,
  titleKey,
  contentKey,
  topClass,
  isTerms,
  campaign,
  defaultTitle,
}) {
  const title = getTranslationField({
    language,
    field: titleKey,
    campaign,
  });
  const content = getTranslationField({
    language,
    field: contentKey,
    campaign,
  });
  const [more, setMore] = useState(false);
  if (!content) {
    return <div />;
  }
  return (
    <div className={topClass}>
      {isTerms ? (
        <h5>{title || defaultTitle}</h5>
      ) : (
        <h3>{title || defaultTitle}</h3>
      )}
      <div className="content" style={{ maxHeight: more ? '9999px' : '220px' }}>
        <CustomViewer initValue={content} />
        <p
          className="view-more"
          style={{ display: more ? 'none' : 'inline-block' }}
        >
          <a className="button" href onClick={() => setMore(true)}>
            + View More
          </a>
        </p>
      </div>
    </div>
  );
}

function Details({ language, campaign }) {
  return (
    <MarkDownSection
      language={language}
      titleKey="offerDetailTitle"
      contentKey="offerDetailContent"
      topClass="details"
      campaign={campaign}
      defaultTitle={i18n.t('preview_detail_offer_detail', {
        locale: language.code,
      })}
    />
  );
}

function Book({ language, campaign }) {
  return (
    <MarkDownSection
      language={language}
      titleKey="instructionSectionTitle"
      contentKey="instructionSectionContent"
      topClass="book"
      defaultTitle={i18n.t('preview_detail_how_to_use', {
        locale: language.code,
      })}
      campaign={campaign}
    />
  );
}

function Offer({ language, campaign }) {
  return (
    <MarkDownSection
      language={language}
      titleKey="merchantTermsAndConditionsTitle"
      contentKey="merchantTermsAndConditionsContent"
      topClass="offer"
      campaign={campaign}
      defaultTitle={i18n.t('preview_detail_merchant', {
        locale: language.code,
      })}
    />
  );
}

function Provided() {
  return (
    <div className="provided">
      <p>Content provided by merchants.</p>
    </div>
  );
}

function CampaignDetailInfo({ language, campaign }) {
  const contentLanguage = language || defaultLanguage;
  return (
    <div className="offer-info" id={'offer-info'}>
      <Images language={contentLanguage} campaign={campaign} />
      <Details language={contentLanguage} campaign={campaign} />
      <TourCard language={contentLanguage} campaign={campaign} />
      <Book language={contentLanguage} campaign={campaign} />
      <Offer language={contentLanguage} campaign={campaign} />
    </div>
  );
}

function Terms({ language, campaign }) {
  return (
    <MarkDownSection
      language={language}
      titleKey="genericTermsAndConditionsTitle"
      contentKey="genericTermsAndConditionsContent"
      topClass="offer-terms"
      campaign={campaign}
      defaultTitle={i18n.t('preview_detail_generalt', {
        locale: language.code,
      })}
    />
  );
}

function PreviewBody({ language, campaign, linkCouponDetail = false}) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (campaign?.pk && !campaign?.linkedCoupon?.pk || linkCouponDetail) {
      window.dispatchEvent(new Event('resize'));
      setIsLoading(false);
    };
  }, [campaign, linkCouponDetail]);

  if (isLoading) {
    return <Loading customClass={'common-full-height'} />;
  };

  return (
    <div className="main">
      <div>
        <div className="offer-details-wrapper">
          <CampaignGeneralInfo language={language} campaign={campaign} />
          <CampaignDetailInfo language={language} campaign={campaign} />
        </div>
        <Terms language={language || defaultLanguage} campaign={campaign} />
      </div>
    </div>
  );
}

export default PreviewBody;
