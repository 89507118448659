import { BaseHelper } from './BaseGraphQLAPIHelper';

const getCouponLabelNode = ({ isList }) => {
  return `{
    id
    pk
    name
    displayPriority
    ${
      isList
        ? ''
        : `
    translations {
      edges {
        node {
          id
          pk
          language
          name
        }
      }
    }`
    }
    
  }`;
};

export const getCouponLabelList = (afterCursor, payload) => {
  let filter = '';
  let pageSize = 20;
  if (payload?.isAll) {
    pageSize = 100;
  }
  if (payload?.searchKey) {
    filter = `, nameIcontains:"${payload?.searchKey}"`;
  }
  let orderBy = 'displayPriority';
  if (payload?.sort) {
    orderBy = payload.sort;
  }

  const query = `{
    couponLabels(first: ${pageSize}, after:"${afterCursor}", orderBy: "${orderBy}"${filter}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node ${getCouponLabelNode({ isList: true })}
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getCouponLabel = (id) => {
  const query = `{
    couponLabel(id: "${id}") ${getCouponLabelNode({})}
  }
  `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const createOrUpdateCouponLabel = (values) => {
  const action = values.id ? 'Update' : 'Create';
  const query = `mutation ${action}CouponLabel($input: ${action}CouponLabelInput!) {
    ${action.toLowerCase()}CouponLabel(input: $input) {
      success
      node ${getCouponLabelNode({})}
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = {
    input: values,
  };
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const deleteCouponLabels = (ids) => {
  const query = `mutation DeleteCouponLabels($input: DeleteCouponLabelsInput!) {
    deleteCouponLabels(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids,
    },
  };
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};
