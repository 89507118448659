import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import ScrollableComponent from '../../../components/base/ScrollableComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import ContentSections from '../../../components/base/ContentSections';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import DeletePrompt from '../../../components/base/DeletePrompt';
import {
  LanguageConfig,
  APIStatus,
  DELETE_RELATED_SECTIONS,
} from '../../../config/CustomEnums';
import ErrorFieldMessage from '../../../components/base/ErrorFieldMessage';
import i18n from '../../../I18n';
import './CreateStoreCategory.scss';
import Loading from '../../../components/base/Loading';
import {
  anchorElementWithId,
  createAction,
  removeFromSessionStorage,
  saveToSessionStorage,
} from '../../../utils';
import { Button } from 'react-bootstrap';
import { STORE_CATEGORY_SESSION_KEY } from '../../../models/CreateStoreCategoryModel';
import {
  getStoreCreateSuccessStepBar,
  CREATE_STORE_CATEGORY,
} from '../../../components/base/prompt/NextStepConfig';
import { PermissionCodes } from '../../../config/PermissionCodes';

const getError = (errors, fieldName) => {
  let errorMessage = '';
  let hasError = false;
  errors.forEach((error) => {
    if (error.field === fieldName) {
      errorMessage = error.message;
      hasError = true;
    }
  });
  return [hasError, errorMessage];
};

const CreateStoreCategory = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const caution = () => {
    if (params.id) {
      return {
        detail: '', //Need fill in the category name and display order
        title: category.name,
        model: 'createStoreCategory',
      };
    } else {
      return {
        detail: '', //Need fill in the category name and display order
        title: 'Create store category',
        model: 'createStoreCategory',
      };
    }
  };
  const [showDeletePrompt, setShowDeletePrompt] = React.useState(false);
  const [name, setName] = React.useState();
  const [translations, setTranslations] = React.useState([]);
  const [subCategories, setSubCategories] = React.useState([
    { id: null, name: '', order: null, translations: [] },
  ]);
  const [order, setOrder] = React.useState(null);
  const { errorFields, category, createStatus } = useSelector((state) => ({
    errorFields: state.createStoreCategory.errorFields,
    category: state.createStoreCategory.category,
    createStatus: state.createStoreCategory.createStatus,
  }));
  React.useEffect(() => {
    const categoryID = params.id;
    if (categoryID) {
      dispatch(
        createAction('createStoreCategory/getCategory')({ id: categoryID }),
      );
    } else {
      dispatch({
        type: 'createStoreCategory/loadStoreCategoryFromSessionStorage',
      });
    }
    return () => {
      dispatch({ type: 'createStoreCategory/reset' });
    };
  }, [dispatch, params.id]);
  React.useEffect(() => {
    if (
      createStatus === APIStatus.success &&
      history.location.pathname.includes('edit')
    ) {
      history.push('/store_category');
      return;
    }
    const { name, order } = category;
    if (category.subCategories && category.subCategories.length > 0) {
      setSubCategories(category.subCategories);
    }
    setTranslations(category.translations);
    setName(name);
    setOrder(order);
    console.log(category);
  }, [createStatus, category, history]);
  React.useEffect(() => {
    if (errorFields && errorFields.length > 0) {
      setTimeout(() => {
        anchorElementWithId(errorFields[0].field);
      }, 100);
    }
  }, [errorFields]);
  const [hasNameError, nameErrorMessage] = getError(errorFields, 'name');
  const [hasOrderError, orderErrorMessage] = getError(errorFields, 'order');
  const formChanged = () => {
    dispatch({ type: 'createStoreCategory/formHasChanged' });
  };

  const getSubCategories = (language) => {
    return subCategories.map((subCategory, index) => {
      const nameField = `sub-category-name-${language}-${index + 1}`;
      const [hasNameError, nameErrorMessage] = getError(errorFields, nameField);
      const orderField = `sub-category-order-${index + 1}`;
      const [hasOrderError, orderErrorMessage] = getError(
        errorFields,
        orderField,
      );
      const isEnglish = language === LanguageConfig.english;
      return (
        <>
          <div className="create-section-title">
            {i18n.t('subcategory', { locale: language })} #{index + 1}
            {isEnglish ? (
              <label
                className="delete-sub-category-btn"
                onClick={() => {
                  const tempArray = [].concat(subCategories);
                  tempArray.splice(index, 1);
                  setSubCategories(tempArray);
                }}
              >
                Delete
              </label>
            ) : null}
          </div>
          <label className="create-section-label create-section-label-bottom-space">
            {i18n.t('name', { locale: language })}
          </label>
          <>
            <input
              type="text"
              id={nameField}
              onChange={({ target }) => {
                const { value } = target;
                const tempArray = [].concat(subCategories);
                if (isEnglish) {
                  tempArray[index].name = value;
                } else {
                  const subCategoryTranslations =
                    tempArray[index]?.translations;
                  let hasThisLanguage = false;
                  subCategoryTranslations.forEach((translation) => {
                    if (translation.language === language) {
                      hasThisLanguage = true;
                      translation.name = value;
                    }
                  });
                  if (!hasThisLanguage) {
                    subCategoryTranslations.push({ language, name: value });
                  }
                }
                setSubCategories(tempArray);
                formChanged();
              }}
              value={
                isEnglish
                  ? subCategory.name
                  : subCategory.translations.filter(
                      (translation) => translation.language === language,
                    )[0]?.name
              }
              className={`custom-markdown-area-title `}
            />
            {isEnglish ? (
              <ErrorFieldMessage
                error={hasNameError}
                message={nameErrorMessage}
              />
            ) : null}
          </>
          {isEnglish ? (
            <>
              <label className="create-section-label create-section-label-bottom-space">
                Display order
              </label>
              <input
                id={orderField}
                type="number"
                min="1"
                onChange={({ target }) => {
                  const { value } = target;
                  const tempArray = [].concat(subCategories);
                  tempArray[index].order = value;
                  setSubCategories(tempArray);
                  formChanged();
                }}
                value={subCategory.order}
                className={`coupon-campaign-section-input `}
              />
              <ErrorFieldMessage
                error={hasOrderError}
                message={orderErrorMessage}
              />
              <label className="coupon-campaign-section-caution-description">
                Minimum value is 1. The smaller value, the higher priority.
              </label>
            </>
          ) : null}
        </>
      );
    });
  };

  const getLanguageTab = (language) => {
    if (createStatus === APIStatus.calling) {
      return [<Loading />];
    }
    const isEnglish = language === LanguageConfig.english;
    return [
      <>
        <div className="create-section-title">
          {i18n.t('category', { locale: language })}
        </div>
        <label className="create-section-label create-section-label-bottom-space">
          {i18n.t('name', { locale: language })}
        </label>
        <>
          <input
            type="text"
            onChange={({ target }) => {
              const { value } = target;
              formChanged();
              if (isEnglish) {
                setName(value);
              } else {
                let hasThisLanguage = false;
                const tempTranslations = [].concat(translations);
                tempTranslations.forEach((translation) => {
                  if (translation.language === language) {
                    hasThisLanguage = true;
                    translation.name = value;
                  }
                });
                if (!hasThisLanguage) {
                  tempTranslations.push({ language, name: value });
                }
                setTranslations(tempTranslations);
              }
            }}
            id="name"
            value={
              isEnglish
                ? name
                : translations.filter(
                    (translation) => translation.language === language,
                  )[0]?.name
            }
            className={`custom-markdown-area-title `}
          />
          {isEnglish ? (
            <ErrorFieldMessage
              error={hasNameError}
              message={nameErrorMessage}
            />
          ) : null}
        </>

        {isEnglish ? (
          <>
            <label className="create-section-label create-section-label-bottom-space">
              Display order
            </label>
            <input
              id="order"
              type="number"
              min="1"
              onChange={({ target }) => {
                setOrder(target.value);
                formChanged();
              }}
              value={order}
              className={`coupon-campaign-section-input `}
            />
            <ErrorFieldMessage
              error={hasOrderError}
              message={orderErrorMessage}
            />
            <label className="coupon-campaign-section-caution-description">
              Minimum value is 1. The smaller value, the higher priority.
            </label>
          </>
        ) : null}
      </>,
      ...getSubCategories(language),
    ];
  };
  const languageTabConfig = {
    enContainer: getLanguageTab(LanguageConfig.english),
    hantContainer: getLanguageTab(LanguageConfig.traditionalChinese),
    hansContainer: getLanguageTab(LanguageConfig.simplifiedChinese),
  };
  const renderForm = () => (
    <>
      <ContentSections
        fieldsError={errorFields && errorFields.length > 0}
        languageTabContent={languageTabConfig}
        hidePreview
      />
      <Button
        onClick={() => {
          setSubCategories([
            ...subCategories,
            { id: null, name: '', order: null, translations: [] },
          ]);
        }}
        className="btn-back-button-common add-sub-category-btn"
      >
        Add Subcategory
      </Button>
      <SaveAndBackButtons
        saveTempText={params.id ? 'Delete' : null}
        saveText={params.id ? 'Updated' : 'Save'}
        backAction={() => history.goBack()}
        saveAction={() => {
          if (params.id) {
            dispatch({
              type: 'createStoreCategory/checkAndUpdate',
              payload: {
                name,
                translations,
                order,
                categoryID: params.id,
                subCategories,
              },
            });
          } else {
            dispatch({
              type: 'createStoreCategory/checkAndSave',
              payload: { name, translations, order, subCategories },
            });
          }
        }}
        saveTempAction={() => setShowDeletePrompt(true)}
      />
    </>
  );
  return (
    <div className="campaign-category">
      <ScrollableComponent
        tempSave={(save) => {
          if (save) {
            saveToSessionStorage(STORE_CATEGORY_SESSION_KEY, {
              name,
              order,
              subCategories,
              translations,
            });
          } else {
            removeFromSessionStorage(STORE_CATEGORY_SESSION_KEY);
          }
        }}
        showFinishPop={createStatus === APIStatus.success}
        nextStepConfig={{
          title: 'Successfully Created!',
          description:
            'Store category is successfully created. Let’s create a store to apply this store category.',
          steps: {
            stepNames: getStoreCreateSuccessStepBar(true),
            current: CREATE_STORE_CATEGORY,
          },
          buttons: [
            {
              text: 'Create store',
              action: () => {
                history.push({
                  pathname: '/stores/create',
                  search: `category=${category?.pk}`,
                });
              },
              requires: PermissionCodes.addStore,
            },
            {
              text: 'Maybe Later',
              action: () => history.push('/store_category'),
              requires: PermissionCodes.changeStorecategory,
            },
          ],
        }}
        continueCreateConfig={{
          onConfirm: () => {
            history.push(history.location.state?.from);
          },
          onCancel: () => history.push('/store_category'),
          onClose: () => history.push('/store_category'),
        }}
        content={[renderForm()]}
        currentStep={0}
        breadcrumb={<CustomBreadcrumb name={category.name} />}
        caution={caution()}
      />
      <DeletePrompt
        message={{
          title: 'Delete this store category',
          content: 'Do you confirm to delete this store category?',
        }}
        title={'Store category'}
        data={category}
        relatedSections={DELETE_RELATED_SECTIONS.CAMPAIGN_CATEGORY}
        show={showDeletePrompt}
        onClose={() => {
          setShowDeletePrompt(false);
        }}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch(
            createAction('createStoreCategory/deleteCategories')({
              ids: [params.id],
            }),
          );
        }}
      />
    </div>
  );
};

export default CreateStoreCategory;
