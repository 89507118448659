import React from 'react';
import { useFormContext } from 'react-hook-form';
import { hasError, errorMessage } from '../base/ErrorFieldMessage';
import { Collapse } from 'react-bootstrap';
import { GoodieBagErrorHandleField } from './CreateGoodieBagValidate';
import CustomSwitchButton from '../base/CustomSwitchButton';
import CustomDateTimeSelect from '../base/CustomDateTimeSelect';
import ErrorFieldMessage from '../base/ErrorFieldMessage';

function VisiblePeriodSection() {
  const { watch, setValue, formState } = useFormContext();
  const errors = formState.errors;
  const isAlwaysVisiblePeriod = watch('isAlwaysVisiblePeriod');
  const watchDisplayStartDate = watch('displayStartDate');
  const watchDisplayEndDate = watch('displayEndDate');

  return (
    <>
      <label className="create-section-title">Visible Period</label>
      <label className="section-short-description">
        Available time to display this campaign.
      </label>
      <label className="create-section-label create-section-label-bottom-space">
        It is an all-time visible campaign
      </label>
      <CustomSwitchButton
        defaultChecked={isAlwaysVisiblePeriod}
        onChange={(value) => {
          setValue('isAlwaysVisiblePeriod', value, { shouldDirty: true });
        }}
      />
      <Collapse in={!isAlwaysVisiblePeriod}>
        <div>
          <label className="create-section-label create-section-label-bottom-space">
            Start date
          </label>
          <CustomDateTimeSelect
            disabled={isAlwaysVisiblePeriod}
            defaultTime={watchDisplayStartDate}
            onTimeChange={(date) => {
              setValue('displayStartDate', date, { shouldDirty: true });
            }}
          />
          <label className="create-section-label create-section-label-bottom-space">
            End date
          </label>
          <CustomDateTimeSelect
            error={hasError(errors, 'displayEndDate')}
            defaultTime={watchDisplayEndDate}
            disabled={isAlwaysVisiblePeriod}
            onTimeChange={(date) => {
              setValue('displayEndDate', date, { shouldDirty: true });
            }}
            seconds={59}
          />
          <ErrorFieldMessage
            id={GoodieBagErrorHandleField.displayEndDate.name}
            error={hasError(errors, 'displayEndDate')}
            message={errorMessage(errors, 'displayEndDate')}
          />
        </div>
      </Collapse>
    </>
  );
}

export default VisiblePeriodSection;
