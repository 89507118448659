import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createAction, useCompare } from '../../../utils';
import { CustomTitleWithDropDown } from '../../customer/CustomTitleWithDropDown';

function CouponSelectDropdown(props) {
  const dispatch = useDispatch();
  const title = props.title;
  const defaultValue = props.defaultValue || null;
  const setValue = props.setValue || (() => {});
  const addButtonInfo = props.addButtonInfo || {};
  const isGoodieBag = props.isGoodieBag;
  const isFortuneBag = props.isFortuneBag;
  const workingTeams = props.workingTeams || [];

  const vals = { workingTeams, isGoodieBag, isFortuneBag };
  const workingTeamChanged = useCompare(vals);

  const { couponTemplateList } = useSelector((state) => ({
    couponTemplateList: state.couponList.couponTemplateList,
  }));
  const [isReset, setIsReset] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      dispatch({
        type: 'couponList/updateState',
        payload: { couponTemplateList: [] },
      });
      setIsReset(true);
    }, 500);
  }, [workingTeamChanged]);

  // useEffect(() => {
  //   console.log('@@30');
  // }, [workingTeamChanged]);

  const onSelectChange = (value) => {
    setValue(value?.value);
  };

  return (
    <CustomTitleWithDropDown
      title={title}
      titleStyle={props.isPrompt ? '' : 'create-section-label-no-top-space'}
      defaultValue={defaultValue}
      setValue={onSelectChange}
      source={couponTemplateList}
      loadMoreAction={'couponList/getCurrentPageTemplateList'}
      filterAction={'couponList/getCurrentPageTemplateList'}
      errors={props.errors}
      errorName={props.errorName}
      addButtonInfo={addButtonInfo}
      isReset={isReset}
      labelContainPk={true}
      resetChange={() => {
        setIsReset(false);
      }}
      defaultFilter={{
        ...(isGoodieBag ? { status: 'notGoodieBag' } : {}),
        ...(isFortuneBag
          ? { isLinked: 'false' }
          : isGoodieBag
          ? {}
          : { isLinkedFortuneBag: 'false' }),
        ...(props.defaultFilter || {}),
      }}
    />
  );
}

export default CouponSelectDropdown;
