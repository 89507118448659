import {
  getCustomerGroups,
  getAllCustomers,
  getCustomersByPage,
  deleteCustomers,
  updateCustomer,
  getOneCustomer,
  getCustomerActivityLog,
  getCustomerActivityLogVersion2,
  deactiveCustomer,
  activeCustomer,
  getInterestPreferences,
} from '../services/CustomerAPIHelper';
import {
  createAction,
  convertNumberToCursor,
  convertCursorToNumber,
  delay,
  convertPKToId,
  getObjectFromSessionStorage,
  saveToSessionStorage,
} from '../utils';
import { apiWithResponseHandle, loading } from './LoadingUtil';
import {
  StatusTag,
  LanguageTransLation,
  CheckStatus,
  SavedStatus,
  SESSION_KEYS,
  YES_OR_NO_TAG,
  DefaultLanguages,
} from '../config/CustomEnums';
import { formatDate, TimeFormater } from '../utils/TimeFormatUtil';
import { CustomerErrorHandleFields } from '../containers/customers/customer/CustomerErrorHandleFields';

export const CUSTOMER_ACTIVITY_LOG_BY = {
  CUSTOMER: 'Customer',
  ADMIN: 'HKTB admin',
  SYSTEM: 'System',
};

export const CUSTOMER_ACTIVITY_TYPE = {
  REGISTER_AT_DHK: 'Registered account (from DHK)',
  REGISTER_AT_ESOLUTION: 'Registered account (at e-solution)',
  REGISTER_AT_WECHAT: 'Registered account (at WeChat)',
  LOGIN_AT_DHK: 'Login (at DHK)',
  LOGIN_AT_ESOLUTION: 'Login (at e-solution)',
  LOGIN_AT_WECHAT: 'Login (at WeChat)',
  LOGIN_FAIL_WITH_WRONG_PASSWORD: 'Login fail - Input wrong pw',
  LOGIN_LOCKED: 'Account locked',
  LOGOUT_AT_DHK: 'Logout (at DHK)',
  LOGOUT_AT_ESOLUTION: 'Logout (at e-solution)',
  LOGOUT_AT_WECHAT: 'Logout (at WeChat)',
  OTP_INPUT_WRONG: 'Input wrong OTP',
  FORGOT_PASSWORD_OTP_REQUEST_SUCCESS_SMS: 'Forgot pw OTP request success sms',
  FORGOT_PASSWORD_OTP_REQUEST_FAIL_WITH_RECAPTCHA_SCORE_SMS:
    'Forgot pw OTP request fail (sms - Recaptcha score too low)',
  FORGOT_PASSWORD_OTP_REQUEST_SUCCESS_WHATSAPP:
    'Forgot pw OTP request success whatsapp',
  FORGOT_PASSWORD_OTP_REQUEST_FAIL_WITH_RECAPTCHA_SCORE_WHATSAPP:
    'Forgot pw OTP request fail (whatsapp - Recaptcha score too low)',
  FORGOT_PASSWORD_OTP_REQUEST_SUCCESS_VOICE:
    'Forgot pw OTP request success voice',
  FORGOT_PASSWORD_OTP_REQUEST_FAIL_WITH_RECAPTCHA_SCORE_VOICE:
    'Forgot pw OTP request fail (voice - Recaptcha score too low)',
  RESET_PASSWORD_SUCCESS: 'Password reset success',
  COUPON_ACQUIRE_SUCCESS: 'Acquire coupon success',
  COUPON_ACQUIRE_FAIL_WITH_OUT_OF_STOCK: 'Acquire coupon fail (out of stock)',
  COUPON_ACQUIRE_FAIL_WITH_WRONG_COUNTRY_CODE:
    'Acquire coupon fail (Wrong country code)',
  COUPON_ACQUIRE_FAIL_WITH_CAMPAIGN_EXPIRED:
    'Acquire coupon fail (campaign is expired)',
  COUPON_ACQUIRE_FAIL_WITH_EXCEED_PER_HEAD_LIMIT:
    'Acquire coupon fail (exceed per head limit)',
  COUPON_ACQUIRE_FAIL_WITH_EXCEED_PER_HEAD_OWNED_LIMIT:
    'Acquire coupon fail (exceed per head owned coupon limit)',
  COUPON_ACQUIRE_FAIL_WITH_EXCEED_START_PER_HEAD_OWNED_LIMIT:
    "Acquire coupon fail (exceed limited quota offer's quota)",
  COUPON_ACQUIRE_FAIL_WITH_LACK_CREDIR_OR_QUOTA:
    'Acquire coupon fail (not enough quota / credit)',
  COUPON_ACQUIRE_FAIL_WITH_EXCEED_ACQUIRE_PERIOD:
    'Acquire coupon fail (exceed acquire coupon period)',
  GOODIE_BAG_ACQUIRE_SUCCESS: 'Acquired goodie bag success',
  GOODIE_BAG_ACQUIRE_FAIL_WITH_WRONG_COUNTRY_CODE:
    'Acquired goodie bag fail (Wrong country code)',
  GOODIE_BAG_ACQUIRE_FAIL_WITH_OUT_OF_STOCK:
    'Acquired goodie bag fail (goodie bag is out of stock)',
  GOODIE_BAG_ACQUIRE_FAIL_WITH_EXCEED_PER_HEAD_LIMIT:
    'Acquired goodie bag fail (exceed per head limit)',
  GOODIE_BAG_ACQUIRE_FAIL_WITH_EXCEED_GROUP_PER_HEAD_LIMIT:
    'Acquired goodie bag fail (exceed goodie bag group limit)',
  GOODIE_BAG_ACQUIRE_FAIL_WITH_ACQUIRED_BY_USER:
    'Acquired goodie bag fail (goodie bag has already acquired by user)',
  GOODIE_BAG_ACQUIRE_FAIL_WITH_ACQUIRED_BY_OTHERS:
    'Acquired goodie bag fail (goodie bag has already acquired by others)',
  GOODIE_BAG_ACQUIRE_FAIL_WITH_EXPIRED:
    'Acquired goodie bag fail (goodie bag is expired)',
  GOODIE_BAG_ACQUIRE_FAIL_WITH_NOT_ACTIVE_YET:
    'Acquired goodie bag fail (goodie bag is not active yet)',
  COUPON_USE_SUCCESS: 'Use coupon success',
  COUPON_USE_FAIL_WITH_CAMERA_PERMISSION_DECLINED:
    'Use coupon fail (camera permission declined)',
  COUPON_USE_FAIL_WITH_BLACKOUT_PERIOD:
    'Use coupon fail (within blackout period)',
  COUPON_USE_FAIL_WITH_NOT_ACTIVE_YET:
    'Use coupon fail (coupon is not active yet)',
  COUPON_USE_FAIL_WITH_EXPIRED: 'Use coupon fail (expired)',
  COUPON_USE_FAIL_WITH_SCAN_WRONG_STORE_CODE:
    'Use coupon fail (scan wrong store code)',
  COUPON_USE_FAIL_WITH_SCAN_INVALID_QR_CODE:
    'Use coupon fail (scan invalid QR code)',
  COUPON_USE_FAIL_WITH_USED:
    'Use coupon fail (coupon has already redeemed before)',
  PASSWORD_UPDATE_SUCCESS: 'Update password success',
  PASSWORD_UPDATE_FAIL: 'Update password fail',
  CUSTOMER_REQUEST_TO_DELETE: 'Request to delete account',
  CUSTOMER_CONFIRM_TO_DELETE: 'Confirm to delete account (clicked SMS URL)',
  CUSTOMER_ACTIVE: 'Reactivate account by admin',
  CUSTOMER_DEACTIVE: 'Deactivate account by admin',
  CUSTOMER_UNLOCK: 'Unlock by admin',
  COUPON_ACTIVE: 'Admin reactivated coupon',
  COUPON_DEACTIVE: 'Admin deactivated coupon',
  COUPON_GRANT: 'Admin assigned coupon',
  COUPON_RECLAIM: 'Admin removed coupon',
  COUPON_EXPIRY_DATE_EXTEND: 'Admin extended coupon expiry date',
  GOODIE_BAG_EXPIRY_DATE_EXTEND: 'Admin extended goodie bag expiry date',
  LOGOUT_BY_FORCE: 'Force logout (Login session expired)',
  COUPON_EXPITED: 'Coupon expired',
  GOODIE_BAG_EXPIRED: 'Goodie bag expired',
  CUSTOMER_PROFILE_UPDATE: 'Update personal information success',
  CUSTOMER_PROFILE_UPDATE_FAIL: 'Update personal information fail',
  CUSTOMER_DELETE: 'Account deleted',
  SEND_EMAIL_ADDRESS_VERIFICATION_EMAIL:
    "Send verification email to customers' email address",
  SEND_EMAIL_ADDRESS_VERIFICATION_EMAIL_MANUAL:
    'Request verification email for email address',
  EMAIL_ADDRESS_VERIFICATION_SUCCESS: 'Email verification success',
  EMAIL_ADDRESS_VERIFICATION_FAIL:
    'Email verification failed - the confirmation link is invalid, customer updated his/her email address to another one',
  EMAIL_ADDRESS_VERIFICATION_FAIL_WITH_EXPIRED_LINK:
    'Email verification failed - the confirmation link expired',
  EMAIL_ADDRESS_VERIFICATION_FAIL_WITH_VERIFIED_BY_USER:
    'Email verification failed - the confirmation link is invalid, email address already verified by user',
  EMAIL_ADDRESS_VERIFICATION_FAIL_WITH_VERIFIED_BY_OTHERS:
    'Email verification failed - the confirmation link is invalid, email address already verified by others',
  CUSTOMER_PROFILE_UPDATE_WITH_OTP_IN:
    'Update direct marketing preference to opt in',
  CUSTOMER_PROFILE_UPDATE_WITH_OTP_OUT:
    'Update direct marketing preference to opt out',
  CUSTOMER_PROFILE_UPDATE_WITH_EMAIL_ADD_SUCCESS:
    'Add unverified email success',
  CUSTOMER_PROFILE_UPDATE_WITH_EMAIL_ADD_FAIL_INVALID:
    'Add unverified email fail - invalid email address',
  CUSTOMER_PROFILE_UPDATE_WITH_EMAIL_ADD_FAIL_USED:
    'Add unverified email fail - email address occupied by others',
  CUSTOMER_PROFILE_UPDATE_WITH_EMAIL_ADD_FAIL_EXCEED_LIMIT:
    'Add unverified email fail - request too many times',
  CUSTOMER_PROFILE_UPDATE_WITH_EMAIL_UPDATE_SUCCESS:
    'Update unverified email success',
  CUSTOMER_PROFILE_UPDATE_WITH_EMAIL_UPDATE_FAIL_INVALID:
    'Update unverified email fail - invalid email address',
  CUSTOMER_PROFILE_UPDATE_WITH_EMAIL_UPDATE_FAIL_USED:
    'Update unverified email fail - email address occupied by others',
  CUSTOMER_PROFILE_UPDATE_WITH_EMAIL_UPDATE_FAIL_EXCEED_LIMIT:
    'Update unverified email fail - request too many times',
  CUSTOMER_PROFILE_UPDATE_WITH_EMAIL_REMOVE_VERIFIED: 'Remove verified email',
  CUSTOMER_PROFILE_UPDATE_WITH_EMAIL_REMOVE_UNVERIFIED:
    'Remove unverified email',
};

const customerSessionKey = SESSION_KEYS.TEMP_CUSTOMER_SESSION_KEY;
const getInitialState = () => ({
  customerList: [],
  customerGroup: [],
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk', linked: true },
    // {
    //   displayName: 'Name (preferred name)',
    //   fieldName: 'name',
    //   orderField: 'firstName',
    // },
    // { displayName: 'Test Customer', fieldName: 'testCustomer' },
    // { displayName: 'Mobile number', fieldName: 'mobileNumber' },
    // { displayName: 'Email', fieldName: 'email' },
    {
      displayName: 'Membership ID',
      fieldName: 'membershipId',
      orderField: 'membershipId',
    },
    {
      displayName: 'UUID',
      fieldName: 'ssoUid',
      // orderField: 'membershipId',
    },
    {
      displayName: 'Interest Preferences',
      fieldName: 'displayInterestPreferences',
    },
    { displayName: 'Test User', fieldName: 'isPreviewTesterDisplay' },
    // { displayName: 'Current Level', fieldName: 'level', orderField: 'level' },
    // { displayName: 'Segment', fieldName: 'segment' },
    { displayName: 'Status', fieldName: 'status' },
  ],
  pagedCustomerList: [],
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  currentLastCursor: '',
  currentPage: 0,
  totalPage: 0,
  totalCount: 0,
  checkedList: [],
  customer: {},
  hasUpdatedDefaultValues: false,
  checked: CheckStatus.initOrNotChecked,
  errorFields: {},
  test: false,
  saved: SavedStatus.init,
  activityLogs: [],
  formChanged: false,
  filters: {
    groups: [],
    segments: [],
    levels: [],
    genders: [],
    age: [0, 0],
    startDate: '',
    endDate: '',
    count: 0,
  },
  checkMemberIdListDisplayFields: [
    { displayName: 'Membership ID', fieldName: 'membershipId', linked: true },
    { displayName: 'Country code', fieldName: 'countryCode' },
    { displayName: 'Mobile number', fieldName: 'mobileNumber' },
    {
      displayName: 'Account registration date',
      fieldName: 'displayCreationDate',
    },
  ],
  hasSearched: false,
  searchMembershipId: '',
  loadData: false,
  checkMemberIdError: null,
  requestLimitExceedError: null,

  activityLogsVersion2ListFields: [
    { displayName: 'ID', fieldName: 'order' },
    { displayName: 'Create Date Time', fieldName: 'displayCreationDate' },
    { displayName: 'Action By', fieldName: 'displayLogBy' },
    { displayName: 'Action', fieldName: 'displayType', displayFull: true },
    { displayName: 'Coupon ID', fieldName: 'couponId' },
    {
      displayName: 'Coupon Set',
      fieldName: 'displayCouponSet',
      displayFull: true,
    },
    { displayName: 'Coupon Expiry Date', fieldName: 'displayCouponExpiryDate' },
    {
      displayName: 'Goodie Bag',
      fieldName: 'displayGoodieBag',
      displayFull: true,
    },
    {
      displayName: 'Campaign',
      fieldName: 'displayCampaign',
      displayFull: true,
    },
    { displayName: 'Reference ID', fieldName: 'couponReferenceId' },
    {
      displayName: 'Required Token(s) / Credit(s)',
      fieldName: 'displayRewardQuantity',
    },
    { displayName: 'Store', fieldName: 'displayStore', displayFull: true },
    { displayName: 'Starred Campaign', fieldName: 'campaignIsStarred' },
    { displayName: 'Remark', fieldName: 'remarks', displayFull: true },
  ],
  activityLogsVersion2PageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  activityLogsVersion2CurrentLastCursor: '',
  activityLogsVersion2CurrentPage: 0,
  activityLogsVersion2TotalPage: 0,
  activityLogsVersion2TotalCount: 0,
  activityLogsVersion2: [],
});

const getLevelPrivilege = (level) => {
  let levelPrivileges = [];
  switch (level) {
    case 'Level 1':
      levelPrivileges = ['Discount for the transaction'];
      break;
    case 'Level 2':
      levelPrivileges = [
        'Discount for the transaction',
        'Refunds for no reason',
      ];
      break;
    case 'Level 3':
      levelPrivileges = [
        'Discount for the transaction',
        'Refunds for no reason',
        '7 days insured',
      ];
      break;
    case 'Level 4':
      levelPrivileges = [
        'Discount for the transaction',
        'Refunds for no reason',
        '7 days insured',
        '24h Customer service',
      ];
      break;
    default:
      levelPrivileges = [];
      break;
  }

  return levelPrivileges;
};

const getReferralSource = (item) => {
  let referralSource = [];
  const referrer = item.referrer
    ? item.referrer.firstName + ' ' + item.referrer.lastName
    : '';
  const referredCampaign = item.referredByCampaign
    ? item.referredByCampaign.name
    : '';

  if (referrer) {
    referralSource.push(referrer);
  }

  if (referredCampaign) {
    referralSource.push('campaign ' + referredCampaign);
  }

  return referralSource.join(', ');
};

const paraseOneCustomer = (item) => {
  const salutationLabel = item?.salutation
    ? item.salutation.charAt(0) + item.salutation.toLowerCase().slice(1)
    : null;
  return {
    ...item,
    // owner: `${item.firstName} ${item.lastName}`,
    // name: item.nickname
    //   ? `${item.firstName} ${item.lastName} (${item.nickname})`
    //   : `${item.firstName} ${item.lastName}`,
    name: item.membershipId,
    membershipId: item.membershipId,
    isPreviewTester: item.isPreviewTester,
    isPreviewTesterDisplay: item.isPreviewTester
      ? YES_OR_NO_TAG.yes
      : YES_OR_NO_TAG.no,
    // chineseFirstName: '-',
    // chineseLastName: '-',
    // chineseName: '-',
    // mobileNumber:
    //   item.mobilePhoneNumberCountryCode &&
    //   item.mobilePhoneNumberSubscriberNumber
    //     ? `+${item.mobilePhoneNumberCountryCode}\r\n${item.mobilePhoneNumberSubscriberNumber}`
    //     : '',
    // email: item.emailAddress,
    currentLevel: item.pointAccount?.currentLevel,
    level: item.pointAccount?.currentLevel?.levelName,
    levelRenewalDate: formatDate(item.pointAccount?.currentLevelRenewDatetime),
    levelExpiredIndays: item.pointAccount?.currentLevelExpiredInXDays,
    segment: item.segments?.edges.map((seg) => seg.node.name).join(', '),
    status: item.status,
    group: item.groups?.edges.map((seg) => seg.node.name).join(', '),
    inGroups: item.groups?.edges.map((seg) => ({
      name: seg.node.name,
      value: seg.node,
    })),
    referrerUser: item.referrer
      ? {
          label: `${item.referrer.firstName} ${item.referrer.lastName}`,
          value: item.referrer,
        }
      : null,
    referrerCampaign: item.referredByCampaign
      ? {
          label: item.referredByCampaign?.name,
          value: item.referredByCampaign,
        }
      : null,
    referralSource: getReferralSource(item),
    formateCreated: formatDate(item.creationDate),
    lifetime: item.totalSpending,
    averageOrder: item.averageOrderValue,
    totalOrders: item.totalNumberOfOrders,
    lastOrderDays: item.daysFromLastOrder,
    hasAgreedDirectMarketing: item.hasAgreedDirectMarketing
      ? 'Opt-In'
      : 'Opt-out',
    // legalAgreement: 'Yes',
    dataProcessingAgreement: 'Yes',
    pointsBalance: item.pointAccount?.balance,
    totalLifetimePointsUsed: item.pointAccount?.totalPointsUsed,
    totalLifetimePointsExpired: item.pointAccount?.totalPointsExpired,
    tpe: item.pointAccount?.totalPointsEarned,
    levelPrivilege: getLevelPrivilege(
      item.pointAccount?.currentLevel?.levelName,
    ).join(', '),
    availableCampaign: '-',
    coupons: item.coupons?.edges.map((item, index) => item.node.template.name),
    ownedCoupons: item.coupons?.edges.map((item, index) => ({
      pk: item.node.pk,
      name: item.node.template.name,
      value: item.node,
    })),
    preferredMessageLanguageDisplay: item.preferredMessageLanguage
      ? LanguageTransLation[item.preferredMessageLanguage]
      : '-',
    preferredMessageLanguage: item.preferredMessageLanguage
      ? DefaultLanguages.filter(
          (val) =>
            val.value.toLowerCase() ==
            item.preferredMessageLanguage.toLowerCase(),
        )?.[0]
      : null,
    // socialMedia: '-',
    // gender: item.gender?.value,
    displayGender: item.gender?.label,
    testCustomer: item.isAssignedAsTestingCustomer ? 'Test\r\ncustomer' : '-',
    assignToTest: item.isAssignedAsTestingCustomer ? 'Yes' : 'No',
    // displayDateOfBirth: formatDate(item.dateOfBirth),
    interestPreferences: item.interestPreferences?.edges?.map(
      (item) => item.node,
    ),
    salutation: item.salutation
      ? {
          label: salutationLabel,
          value: item.salutation,
        }
      : null,
    salutationLabel: salutationLabel,

    displayInterestPreferences: item.interestPreferences?.edges
      ?.map((item) => item.node.name)
      .join(', '),
    transferPersonlaInfo: item.allowPersonalInfoShare ? 'Yes' : 'No',
    // interestPreferences: item.interestPreferences.edges.map(
    //   (item) => item.node,
    // ),
  };
};

const parseCustomerList = (data) => {
  return data.map((item) => {
    return paraseOneCustomer(item.node);
  });
};

const parseOneCustomerForCheck = (item) => {
  return {
    pk: item.pk,
    membershipId: item.membershipId,
    countryCode: item.mobilePhoneNumberCountryCode
      ? `+${item.mobilePhoneNumberCountryCode}`
      : item.mobilePhoneNumberCountryCode,
    mobileNumber: item.mobilePhoneNumberSubscriberNumber,
    displayCreationDate: formatDate(
      item.creationDate,
      TimeFormater.yearMonthDayTimeSecondsWithBlank,
    ),
  };
};

const parseCheckCustomerList = (data) => {
  return data.map((item) => {
    return parseOneCustomerForCheck(item.node);
  });
};

const parseActivityLogs = (data) => {
  return data?.map((item) => {
    return {
      ...item.node,
      text: item.node.logTitle,
      updateTime: formatDate(
        item.node.creationDate,
        TimeFormater.dayMonthYearWeekTime,
      ),
    };
  });
};

const parseActivityLogVersion2DisplayType = (data, displayAdmin) => {
  let displayType =
    data.activityType in CUSTOMER_ACTIVITY_TYPE
      ? CUSTOMER_ACTIVITY_TYPE[data.activityType]
      : null;
  if (
    [
      'CUSTOMER_ACTIVE',
      'CUSTOMER_DEACTIVE',
      'CUSTOMER_UNLOCK',
      'CUSTOMER_DELETE',
      'CUSTOMER_PROFILE_UPDATE',
    ].includes(data.activityType) &&
    data.logBy === 'ADMIN'
  ) {
    if (data.activityType === 'CUSTOMER_DELETE') {
      displayType = `${displayType} by admin (${displayAdmin})`;
    } else {
      displayType = `${displayType} (${displayAdmin})`;
    }
  }
  if ('CUSTOMER_UNLOCK' === data.activityType && data.logBy === 'SYSTEM') {
    displayType = 'Unlock by system';
  }
  if ('SEND_EMAIL_ADDRESS_VERIFICATION_EMAIL' === data.activityType) {
    if (data.isRegister)
      displayType = `${displayType} upon registration (${data.emailAddress})`;
    else {
      displayType = `${displayType} upon email update (${data.emailAddress})`;
    }
  } else if (
    data.activityType.includes('EMAIL_ADDRESS_VERIFICATION') ||
    data.activityType.includes('CUSTOMER_PROFILE_UPDATE_WITH_EMAIL')
  ) {
    displayType = `${displayType} (${data.emailAddress})`;
    if (data.activityType === 'EMAIL_ADDRESS_VERIFICATION_FAIL') {
      displayType = `Email verification failed - the confirmation link is invalid, customer updated his/her email address (${
        data.oldEmailAddress || ''
      }) to another one (${data.emailAddress})`;
    }
  }
  return displayType;
};

const parseActivityLogsVersion2 = (data) => {
  const dataLength = data?.customerActivityLogs?.totalCount || 0;
  const startCursor = convertCursorToNumber(
    data?.customerActivityLogs?.pageInfo?.startCursor,
  );

  return data?.customerActivityLogs.edges?.map((item, index) => {
    const displayAdmin = item.node.logContent?.administratorId
      ? `[ID:${item.node.logContent?.administratorId}] ${item.node.logContent?.administratorName}`
      : null;
    const parsedData = {
      ...item.node,
      order: dataLength - startCursor - index,
      displayCreationDate: formatDate(
        item.node.creationDate,
        TimeFormater.dayMonthYearWeekTime,
      ),
      displayLogBy:
        item.node.logBy in CUSTOMER_ACTIVITY_LOG_BY
          ? CUSTOMER_ACTIVITY_LOG_BY[item.node.logBy]
          : null,
      displayType: parseActivityLogVersion2DisplayType(item.node, displayAdmin),
      adminId: item.node.logContent?.administratorId,
      adminName: item.node.logContent?.administratorName,
      displayAdmin: displayAdmin,
      couponId: item.node.logContent?.couponId,
      displayCouponSet: item.node.logContent?.couponTemplateId
        ? `[ID:${item.node.logContent?.couponTemplateId}]${item.node.logContent?.couponTemplateName}`
        : '',
      displayCouponExpiryDate: formatDate(
        item.node.logContent?.couponExpiryDate,
        TimeFormater.dayMonthYearWeekTime,
      ),

      displayGoodieBag: item.node.logContent?.goodieBagId
        ? `[ID:${item.node.logContent?.goodieBagId}]${item.node.logContent?.goodieBagName}`
        : '',
      displayCampaign: item.node.logContent?.campaignId
        ? `[ID:${item.node.logContent?.campaignId}]${item.node.logContent?.campaignName}`
        : '',
      couponReferenceId: item.node.logContent?.couponReferenceId,
      displayRewardQuantity: item.node.logContent
        ?.goodieBagCampaignSlotRewardType
        ? item.node.logContent?.goodieBagCampaignSlotRewardType ===
          'CASH_VOUCHER'
          ? `-${item.node.logContent?.goodieBagRewardQuantity} credit`
          : `-${item.node.logContent?.goodieBagRewardQuantity} quota`
        : null,
      displayStore: item.node.logContent?.storeId
        ? `[ID:${item.node.logContent?.storeId}]${item.node.logContent?.storeName}`
        : '',
      campaignIsStarred: item.node.logContent?.campaignIsStarred ? 'Yes' : 'No',
    };
    return parsedData;
  });
};

export default {
  namespace: 'customerList',
  state: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },

    updateCustomerList(state, { payload }) {
      const { customerList, page } = payload;

      return {
        ...state,
        customerList:
          page > 1 ? [...state.customerList, ...customerList] : customerList,
        // totalCount:
        //   page > 1
        //     ? state.customerList.length + customerList.length
        //     : customerList.length,
      };
    },

    assembleCustomerGroup(state, { payload }) {
      const groups = payload.list.map((group) => {
        const node = group.node;
        return {
          id: node.id,
          pk: node.pk,
          name: node.name,
        };
      });
      return { ...state, customerGroup: [...groups] };
    },

    changeVals(state, { payload }) {
      console.log('@@customer edit: vals changed', payload);
      let tempCustomer = getObjectFromSessionStorage(customerSessionKey);

      let data = {};
      if (payload.vals) {
        data = payload.vals;
      }

      tempCustomer = { ...tempCustomer, ...data };
      saveToSessionStorage(customerSessionKey, tempCustomer);

      return {
        ...state,
        formChanged: true,
      };
    },

    checkValsValid(state, { payload }) {
      let tempCustomer = getObjectFromSessionStorage(customerSessionKey);

      let errorFields = { fields: [], messages: [] };
      let checked = CheckStatus.initOrNotChecked;

      Object.keys(CustomerErrorHandleFields).map((field) => {
        if (!tempCustomer[field]) {
          errorFields.fields.push(field);
          errorFields.messages.push({ field, errorType: 'required' });
        }
      });

      if (errorFields.fields.length > 0) {
        checked = CheckStatus.checkedWithFail;
      } else {
        checked = CheckStatus.checkedWithSuccess;
      }

      return {
        ...state,
        checked,
        errorFields,
        test: true,
      };
    },

    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },
  },

  effects: {
    getCustomerGroups: [
      function* ({ payload }, { call, select, put }) {
        const serviceArgs = [getCustomerGroups];
        function* onSuccess(data) {
          yield put({
            type: 'assembleCustomerGroup',
            payload: { list: data.customerGroups.edges },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getAllCustomers: [
      function* ({ payload }, { call, put }) {
        const { afterCursor, search } = payload;
        if (!afterCursor) {
          yield put({ type: 'updateState', payload: { customerList: [] } });
        }
        const response = yield call(getAllCustomers, afterCursor, search);
        if (!response || response.status >= 300) {
          return;
        }
        if (response.data.data?.customers) {
          const customerInfo = response.data.data.customers;
          const apiInfoList = customerInfo.edges;
          // const customerList = parseCustomer(apiInfoList);
          const customerList = parseCustomerList(apiInfoList);
          yield put(createAction('updateCustomerList')({ customerList }));
          if (customerInfo.pageInfo.hasNextPage) {
            yield put(
              createAction('getAllCustomers')({
                afterCursor: customerInfo.pageInfo.endCursor,
              }),
            );
          }
        }
      },
      { type: 'takeLatest' },
    ],
    getPagedCustomers: [
      function* ({ payload }, { call, put, all, select }) {
        const page = payload.page;
        const pageCursor = payload.page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const serviceArgs = [
          getCustomersByPage,
          pageCursor,
          payload.reverse,
          payload.search,
          payload.customer_groups,
          payload.segments,
          payload.levels,
          payload.age,
          payload.gender,
          payload.start_date,
          payload.end_date,
          payload.moreSearch,
          payload.sort,
          payload,
        ];

        function* onSuccess(data) {
          const pageInfo = data?.customers?.pageInfo;

          const currentLastCursor = pageInfo?.endCursor;
          const totalCount = data?.customers?.totalCount;

          const stateCustomerList = yield select(
            (state) => state.customerList.pagedCustomerList,
          );

          let pagedCustomerList = parseCustomerList(data?.customers?.edges);
          if (payload.isLoadMore && page > 1) {
            pagedCustomerList = [...stateCustomerList, ...pagedCustomerList];
          }

          yield all([
            put({
              type: 'updateState',
              payload: {
                pagedCustomerList,
                pageInfo: {
                  startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1,
                  endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1,
                  hasNextPage: pageInfo.hasNextPage,
                },
                currentLastCursor,
                totalCount,
                totalPage: Math.ceil(totalCount / 20),
              },
            }),
            put({
              type: 'updateCustomerList',
              payload: {
                customerList: pagedCustomerList,
                page,
              },
            }),
          ]);
        }
        function* onFailed(data) {
          console.log('@@122: ', data);
        }

        yield loading(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    delete: [
      function* ({ payload }, { all, put, select }) {
        const { checkedList } = yield select((state) => ({
          checkedList: state.customerList.checkedList,
        }));

        let pks = [];
        checkedList.forEach((item) => {
          pks.push(item.pk);
        });

        const serviceArgs = [deleteCustomers, pks, payload.remarks];
        const afterAction = payload.afterAction || (() => {});
        console.log('@@231: ', pks);
        function* onSuccess(data) {
          console.log('@@115: ', data);

          yield all([
            put({
              type: 'updateState',
              payload: {
                checkedList: [],
                formChanged: false,
              },
            }),
          ]);
          yield delay(1000);
          afterAction();
        }
        function* onFailed(data) {
          console.log('@@122: ', data);

          yield put({
            type: 'updateState',
            payload: {
              checkedList: [],
              formChanged: false,
            },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    updateCustomer: [
      function* ({ payload }, { all, put, select }) {
        const tempCustomer = payload.data;
        console.log('@@103-1: ', tempCustomer);
        const data = {
          id: tempCustomer.pk,
          company: tempCustomer.company,
          signUpChannel: tempCustomer.signUpChannel,
          referrer:
            tempCustomer.referrerUser?.value.pk || tempCustomer.referrer,
          couponsToReclaim: tempCustomer.removedCoupons?.map((item) => item.pk),
          groupsToLeave: tempCustomer.leaveGroups?.map((item) => item.value.pk),
          pointsChange: {
            type: tempCustomer.pointTransaction?.value || null,
            value: tempCustomer.transactionPoint || null,
            remarks: tempCustomer.pointTransactionRemark || null,
          },
          isAssignedAsTestingCustomer: tempCustomer.isAssignedAsTestingCustomer,
          isForcedInactive: tempCustomer.isForcedInactive,
          isPreviewTester: tempCustomer.isPreviewTester || false,
          salutation:
            tempCustomer.salutation?.value?.value ||
            tempCustomer.salutation?.value ||
            null,
          interestPreferences:
            tempCustomer.interestPreferences?.map((item) => item.pk) || null,
          preferredMessageLanguage:
            tempCustomer.preferredMessageLanguage?.value?.value ||
            tempCustomer.preferredMessageLanguage?.value ||
            null,
          hasAgreedDirectMarketing: tempCustomer.hasAgreedDirectMarketing,
          remarks: tempCustomer.remarks,
        };
        console.log('@@103-2: ', data);
        const serviceArgs = [updateCustomer, data];
        const afterAction = payload.afterAction || (() => {});

        console.log('@@382: ', data);

        function* onSuccess(data) {
          console.log('@@115: ', data);

          yield put({
            type: 'updateState',
            payload: {
              formChanged: false,
              saved: SavedStatus.savedWithSuccess,
            },
          });

          // yield delay(1000);
          // afterAction();
        }
        function* onFailed(data) {
          console.log('@@122: ', data);

          yield put({
            type: 'updateState',
            payload: {
              formChanged: false,
              saved: SavedStatus.savedWithFail,
            },
          });

          yield put({
            type: 'navBars/updateState',
            payload: {
              saveDiscardToastShowing: {
                value: true,
                type: data?.data?.errors[0].message,
              },
            },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed, onFailed);
      },
      { type: 'takeLatest' },
    ],
    updateCustomerActive: [
      function* ({ payload }, { all, put, select }) {
        let activeAPI = deactiveCustomer;
        if (payload.data.isForcedInactive) {
          activeAPI = activeCustomer;
        }
        const afterAction = payload.afterAction || (() => {});
        const serviceArgs = [
          activeAPI,
          { id: payload.data.id, remarks: payload.data.remarks },
        ];

        function* onSuccess(data) {
          console.log('@@115: ', data);

          afterAction();
        }
        function* onFailed(data) {
          console.log('@@122: ', data);
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    getOneCustomer: [
      function* ({ payload }, { call, put }) {
        const serviceArgs = [
          getOneCustomer,
          convertPKToId('CustomerNode', payload.id),
        ];
        function* onSuccess(data) {
          console.log('@@115: ', data);
          const customer = paraseOneCustomer(data?.customer);
          yield put({
            type: 'updateState',
            payload: {
              customer,
              hasUpdatedDefaultValues: true,
            },
          });

          saveToSessionStorage(customerSessionKey, customer);
        }
        function* onFailed(data) {
          console.log('@@122: ', data);
        }

        yield loading(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    getCustomerActivityLog: [
      function* ({ payload }, { call, put }) {
        const serviceArgs = [
          getCustomerActivityLog,
          payload.ssoUid || '',
          payload,
        ];
        function* onSuccess(data) {
          console.log('@@115: ', data);
          const activityLogs = parseActivityLogs(
            data?.customerActivityLogs.edges,
          );
          yield put({
            type: 'updateState',
            payload: {
              activityLogs,
            },
          });
        }
        function* onFailed(data) {
          console.log('@@122: ', data);
        }

        yield loading(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    getInterestPreferences: [
      function* ({ payload }, { call, put }) {
        const serviceArgs = [getInterestPreferences];
        function* onSuccess(data) {
          console.log('@@115: ', data);
          const interestPreferences = data.interestPreferences.edges.map(
            (item) => item.node,
          );
          yield put({
            type: 'updateState',
            payload: {
              interestPreferences,
            },
          });
        }
        function* onFailed(data) {
          console.log('@@122: ', data);
        }

        yield loading(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    getCustomersForCheck: [
      function* ({ payload }, { call, put }) {
        yield put({ type: 'updateState', payload: { loadData: true } });

        const { countryCode, mobileNumber } = payload;
        const search = {
          // mobilePhoneNumberCountryCodeIn: `"${countryCode}"`,
          // mobilePhoneNumberSubscriberNumber: `"${mobileNumber}"`,
          mobilePhoneNumberLimitCheck: `"${countryCode},${mobileNumber},membershipid"`,
        };
        const serviceArgs = [
          getAllCustomers,
          '',
          search,
          { isCheckCustomer: true },
        ];
        function* onSuccess(data) {
          console.log('@@115: ', data);
          if (data?.errorMessage) {
            yield put({
              type: 'updateState',
              payload: {
                ...getInitialState(),
                hasSearched: true,
                checkMemberIdError: null,
                requestLimitExceedError: data?.errorMessage,
              },
            });
            return;
          }
          const pageInfo = data?.customers?.pageInfo;
          const currentLastCursor = pageInfo?.endCursor;
          const totalCount = data?.customers?.totalCount;

          const customers = parseCheckCustomerList(data.customers.edges);
          const membershipId = customers?.[0]?.membershipId;
          yield put({
            type: 'updateState',
            payload: {
              pagedCustomerList: customers,
              pageInfo: {
                startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1,
                endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1,
                hasNextPage: pageInfo.hasNextPage,
              },
              currentLastCursor,
              totalCount,

              isRegister: customers?.length > 0,
              hasSearched: true,
              searchMembershipId: membershipId,
              loadData: false,
              checkMemberIdError: null,
              requestLimitExceedError: null,
            },
          });
        }
        function* onFailed(data) {
          console.log('@@122: ', data);
          yield put({
            type: 'updateState',
            payload: {
              loadData: false,
              checkMemberIdError: data?.data,
              requestLimitExceedError: null,
            },
          });
        }

        yield loading(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    getCustomerActivityLogVersion2: [
      function* ({ payload }, { call, put }) {
        const page = payload?.page || 1;
        const pageCount = 20;
        const pageCursor = convertNumberToCursor((page - 1) * pageCount - 1);

        const serviceArgs = [
          getCustomerActivityLogVersion2,
          payload.ssoUid || '',
          pageCursor,
          payload,
        ];
        function* onSuccess(data) {
          console.log('@@115: ', data);
          const pageInfo = data?.customerActivityLogs?.pageInfo;

          const currentLastCursor = pageInfo?.endCursor;
          const totalCount = data?.customerActivityLogs?.totalCount;

          const activityLogs = parseActivityLogsVersion2(data);
          yield put({
            type: 'updateState',
            payload: {
              activityLogsVersion2PageInfo: {
                startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1,
                endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1,
                hasNextPage: pageInfo.hasNextPage,
              },
              activityLogsVersion2CurrentLastCursor: currentLastCursor,
              // activityLogsVersion2CurrentPage: 0,
              activityLogsVersion2TotalPage: Math.ceil(totalCount / 20),
              activityLogsVersion2TotalCount: totalCount,
              activityLogsVersion2: activityLogs,
            },
          });
        }
        function* onFailed(data) {
          console.log('@@122: ', data);
        }

        yield loading(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
  },
};
