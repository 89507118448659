import React, { useEffect, useState } from 'react';
import '../../components/base/prompt/FilterExportPrompt.scss';
import { Row } from 'react-bootstrap';
import queryString from 'query-string';
import { PermissionCodes } from '../../config/PermissionCodes';
import { useDispatch, useSelector } from 'react-redux';
import { ImportResource } from '../../models/DownloadImportModel';
import { createAction } from '../../utils';
import { formatDate, TimeFormater } from '../../utils/TimeFormatUtil';
import { getStartEndDate } from '../../components/dashboard/DateRangeSelection';
import moment from 'moment';
import BaseMutipleSelectorV2 from '../base/BaseMultipleSelectorV2';
import CustomRadios from '../base/CustomRadios';
import CustomTitleWithDateRange from '../base/CustomTitleWithDateRange';
import CustomSwitchButton from '../base/CustomSwitchButton';
import BasePrompt from '../base/prompt/BasePrompt';
import './AdminActivityLogExportPrompt.scss';

function AdminActivityLogExportPrompt({
  title,
  show,
  onClose = () => {},
  exportFilterConditions,
  otherBody = {},
  total,
  description,
}) {
  const dispatch = useDispatch();

  const [includeFilter, setIncludeFilter] = useState({ isInclude: false });

  const startEndDate = getStartEndDate();
  const [selectStartDate, setSelectStartDate] = useState(
    startEndDate.startDate,
  );
  const [selectEndDate, setSelectEndDate] = useState(startEndDate.endDate);

  const [filterStartDate, setFilterStartDate] = useState('');
  const [filterEndDate, setFilterEndDate] = useState('');

  const creationDateRange = exportFilterConditions?.creation_date_range;
  const exportFor = ImportResource.adminActivityLog;

  const periodToDate = (period) => {
    if (!period) {
      return '';
    }
    const periods = period.split(',');
    const startDate = moment(periods[0])
      .set('hour', 0)
      .set('minute', 0)
      .set('second', 0);
    const endDate = moment(periods[1])
      .set('hour', 23)
      .set('minute', 59)
      .set('second', 59);
    return { startDate, endDate };
  };

  useEffect(() => {
    const periodDate = periodToDate(creationDateRange);
    if (periodDate) {
      setFilterStartDate(periodDate.startDate);
      setFilterEndDate(periodDate.endDate);
    } else {
      setFilterStartDate(startEndDate.startDate);
      setFilterEndDate(startEndDate.endDate);
    }
  }, [creationDateRange]);

  const totalTasks = parseInt(
    total % 100000 > 0 ? total / 100000 + 1 : total / 100000,
  );

  const canExport =
    (!includeFilter.isInclude && selectStartDate && selectEndDate) ||
    includeFilter.isInclude;

  useEffect(() => {
    if (show && canExport) {
      const filterConditions = includeFilter.isInclude
        ? exportFilterConditions
        : {
            creation_datetime_range:
              selectStartDate.format(TimeFormater.GTMFormat) +
              ',' +
              selectEndDate.format(TimeFormater.GTMFormat),
          };
      dispatch({
        type: 'adminActivityLog/getRecordsCount',
        payload: {
          filter: {
            ...filterConditions,
          },
        },
      });
    }
  }, [
    selectStartDate,
    selectEndDate,
    filterStartDate,
    filterEndDate,
    includeFilter,
    show,
  ]);

  const getOtherBody = () => {
    return (
      <>
        <label className={`create-section-label landing-title-margin-bottom `}>
          {otherBody.rangeTitle || 'Select the export range'}
        </label>
        <Row
          style={{ marginLeft: '0px', marginRight: '0px', marginTop: '20px' }}
        >
          <CustomRadios
            onChange={(value) => {
              setIncludeFilter({ isInclude: value });
            }}
            default={includeFilter.isInclude}
            options={[
              {
                label: 'All items',
                value: false,
              },
              {
                label: 'All filtered items from the list',
                value: true,
              },
            ]}
          />
        </Row>

        {!includeFilter.isInclude ? (
          <CustomTitleWithDateRange
            title={'Time range of the records'}
            value={[selectStartDate, selectEndDate]}
            onChange={(values) => {
              if (!values) {
                return;
              }
              setSelectStartDate(values[0]);
              setSelectEndDate(values[1]);
            }}
          />
        ) : null}
        <div className="d-flex flex-column flex-column-margin-top">
          <label
            className={`create-section-label landing-title-margin-bottom `}
          >
            Number of items to be exported
          </label>
          <label className="tips-message margin-top-8-percent ">
            Maximum export records per system task is 100,000. If over 100,000,
            will split into separate system tasks.
          </label>
          <label className="goodiebag-record-total-number">
            {total >= 0 && canExport ? total : '-'}
            {totalTasks > 1
              ? `(Total ${totalTasks} system
            tasks)`
              : ''}
          </label>
        </div>
      </>
    );
  };

  const exportAction = (index, reportName) => {
    const related = !includeFilter.isInclude
      ? {
          filterConditions: JSON.stringify({
            from_date: selectStartDate.format(TimeFormater.GTMFormat),
            to_date: selectEndDate.format(TimeFormater.GTMFormat),
          }),
        }
      : {
          filterConditions: JSON.stringify(exportFilterConditions),
        };
    if (index >= 0 && reportName) {
      related.index = index + 1;
      related.startCursor = index * 100000;
      related.reportName = reportName.replace('_-', '');
    }
    dispatch({
      type: 'downloadAndImport/createDownloadTask',
      payload: {
        from: exportFor,
        related: related,
      },
    });
    setIncludeFilter({ isInclude: false });
  };

  return (
    <BasePrompt
      show={show}
      closeAction={onClose}
      description={description}
      basePromptDescriptionClassName="upload-description"
      rightButton={{
        text: 'Export',
        action: () => {
          const reportName = includeFilter.isInclude
            ? 'Admin_activity_log'
            : `Admin_activity_log_${
                selectStartDate
                  .format(TimeFormater.yearMonthDay)
                  ?.replaceAll('-', '') || ''
              }-${
                selectEndDate
                  .format(TimeFormater.yearMonthDay)
                  .replaceAll('-', '') || ''
              }`;
          [...Array(totalTasks).keys()].forEach((index) => {
            exportAction(
              index,
              `${reportName}${totalTasks > 1 ? `_${index + 1}` : ''}.xlsx`,
            );
          });

          onClose();
        },
        requires: PermissionCodes.addExportjob,
        disabled: total <= 0 || !total,
      }}
      title={title}
      otherBody={getOtherBody}
    />
  );
}

export default AdminActivityLogExportPrompt;
