import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { connect, useDispatch, useSelector } from 'react-redux';
import CustomListComponent from '../../../components/base/CustomListComponent';

import { PermissionCodes } from '../../../config/PermissionCodes';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import CustomerListCard from '../../../components/customer/CustomerListCard';
import BaseMobileListContainer from '../../base/BaseMobileListContainer';

import { NavBarNames } from '../../../config/NavBarNameList';
import SearchArea from '../../../components/checkOtp/SearchArea';
import './CheckMemberIdList.scss';
import { useDeviceDetect } from '../../engagement/campaign/preview/DHKPreviewBody';
import FlatList from 'flatlist-react/lib';
import SearchAreaMobile from '../../../components/checkOtp/SearchAreaMobile';
import Loading from '../../../components/base/Loading';
import CheckMemberIdItem from './CheckMemberIdItem';
import LoadingIcon from '../../../assets/images/loadingNew.gif';
import { APIStatus } from '../../../config/CustomEnums';

export default function CheckMemberIdList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const isMobile = useDeviceDetect(768);

  const {
    displayFields,
    totalCount,
    pageInfo,
    pagedCustomerList,
    isRegister,
    hasSearched,
    countryCodeList,
    searchMembershipId,
    loadData,
    status,
    checkMemberIdError,
    requestLimitExceedError,
  } = useSelector((state) => ({
    displayFields: state.customerList.checkMemberIdListDisplayFields,
    totalCount: state.customerList.totalCount,
    pageInfo: state.customerList.pageInfo,
    pagedCustomerList: state.customerList.pagedCustomerList,
    isRegister: state.customerList.isRegister,
    hasSearched: state.customerList.hasSearched,
    countryCodeList: state.countryCode.notPagedAllList,
    searchMembershipId: state.customerList.searchMembershipId,
    loadData: state.customerList.loadData,
    checkMemberIdError: state.customerList.checkMemberIdError,
    requestLimitExceedError: state.customerList.requestLimitExceedError,
    status: state.loading.status,
  }));

  const formatCountryCodeList = countryCodeList?.map((item) => ({
    label: `${item?.pk}`,
    value: `${item?.pk}`,
  }));

  const noRecordView = () => {
    if ((loadData && isMobile) || (status === APIStatus.calling && !isMobile)) {
      return <Loading customClass={'check-otp-loading-area'} />;
    }
    if (requestLimitExceedError) {
      return (
        <div className="search-request-limit-exceeded-page">
          <div className="title">Request Limit Exceeded</div>
          <div className="content">{requestLimitExceedError}</div>
        </div>
      );
    }
    return hasSearched ? (
      isRegister ? (
        <></>
      ) : (
        <div className="search-no-result-page">
          {
            'No record found.\nThe provide mobile number has not been registered.'
          }
        </div>
      )
    ) : (
      <div className="search-to-show-page">Search to show records</div>
    );
  };

  useEffect(() => {
    dispatch({ type: 'customerList/clearData' });
    dispatch({ type: 'countryCode/getAllList' });
    return () => {
      dispatch({ type: 'customerList/clearData' });
      dispatch({ type: 'countryCode/clearData' });
    };
  }, []);

  const tabs = [
    {
      name: 'List',
      content: (
        <>
          <BaseTabListContainer
            hideSearch
            hideTab
            hideTabNav
            pageInfo={pageInfo}
            totalCount={totalCount || 0}
            filter={{ hasFilter: false, componment: null }}
            hideActionHeader
            needMobile
            listContent={
              <>
                <SearchArea
                  countryCodeList={formatCountryCodeList}
                  isRegister={isRegister}
                  searchMembershipId={searchMembershipId}
                  isCheckOTP={false}
                />
                <BaseListContainer
                  fields={displayFields}
                  model={'customerList'}
                  permissionGroup={PermissionCodes.checkMemberId}
                  dataList={hasSearched && pagedCustomerList}
                  totalPage={1}
                  deleteInfo={{}}
                  hideCheckbox
                  noRecordView={noRecordView()}
                  hideActions
                />
              </>
            }
            mobileListContent={
              <BaseMobileListContainer
                header={
                  <>
                    <SearchAreaMobile
                      title="Check Member ID"
                      countryCodeList={formatCountryCodeList}
                      isRegister={isRegister}
                      searchMembershipId={searchMembershipId}
                      isCheckOTP={false}
                    />
                    <div className="check-otp-count-container">
                      <label className="check-otp-count-text">
                        Showing {totalCount || 0} entries
                      </label>
                    </div>
                  </>
                }
                dataList={hasSearched && pagedCustomerList}
                renderItem={(item, index) => (
                  <CustomerListCard
                    item={item}
                    fields={[
                      {
                        name: 'Membership ID',
                        value: item.membershipId,
                        isLink: true,
                      },
                      {
                        name: 'Country code',
                        value: item.countryCode,
                      },
                      {
                        name: 'Mobile number',
                        value: item.mobileNumber,
                      },
                      {
                        name: 'Account registration date',
                        value: item.displayCreationDate,
                      },
                    ]}
                    linkPath={`/customers/${item.pk}/`}
                    index={index}
                    hideAction={true}
                    canView={true}
                    isCheckCustomer={true}
                  />
                )}
                listHeight="calc(100vh - 177px - 16px)"
                renderWhenEmpty={noRecordView}
              />
            }
          />
        </>
      ),
    },
  ];
  const checkMemberIdErrorDisplay = () => {
    return (
      <>
        <div className="search-error-container">
          <div className="search-error-title">{checkMemberIdError.title}</div>
          <div className="search-error-content">
            {checkMemberIdError.messages.map((message, index) => {
              return (
                <>
                  <label>{message}</label>
                </>
              );
            })}
          </div>
        </div>
      </>
    );
  };
  const errorTabs = checkMemberIdError
    ? [
        {
          name: 'List',
          content: (
            <>
              {isMobile ? (
                <div className="check-otp-search-container-mobile">
                  <label className="list-section-container-header-title">
                    Check Member ID
                  </label>
                  {checkMemberIdErrorDisplay()}
                </div>
              ) : (
                checkMemberIdErrorDisplay()
              )}
            </>
          ),
        },
      ]
    : [];

  return (
    <>
      <CustomListComponent
        caution={{
          title: NavBarNames.checkMemberId,
        }}
        defaultActiveKey={tabs[0].name}
        breadcrumb={<CustomBreadcrumb />}
        tabs={errorTabs.length ? errorTabs : tabs}
        hideTab={true}
        hideHeader={isMobile}
      />
    </>
  );
}
