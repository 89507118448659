import { BaseHelper } from './BaseGraphQLAPIHelper';

const getSearchString = (search) => {
  console.log('getCouponTemplateList:', search);
  let order = '-pk';
  if (search.rank === false || search.rank === 'false') {
    order = 'pk';
  }

  let searchString = `, orderBy: "${order}"`;
  if (search?.sort) {
    searchString = `, orderBy: "${search?.sort}"`;
  }
  if (search.all || search.isAll) {
    searchString += `, first: ${search.pageSize || 100}`;
  } else {
    searchString += `, first: 20`;
  }
  if (search.stock !== undefined) {
    searchString += `, outOfStock: ${search.stock}`;
  }
  if (search.status) {
    switch (search.status) {
      case 'linkedFortuneBag':
        searchString += `, isLinkedFortuneBag: true`;
        break;
      case 'linkedGoodieBag':
        searchString += `, isLinkedGoodieBag: true`;
        break;
      case 'expired':
        searchString += `, isExpired: true, isLinkedFortuneBag: false`;
        break;
      case 'physicalExpired':
        searchString += `, physicalIsExpired: true, isLinkedFortuneBag: false`;
        break;
      case 'active':
        searchString += `, isActive: true, isExpired: false, isLinkedFortuneBag: false`;
        break;
      case 'inactive':
        searchString += `, isActive: false, isExpired: false, isLinkedFortuneBag: false`;
        break;
      case 'notGoodieBag':
        searchString += `, isActive: true, isExpired: false, isLinkedFortuneBag: false, isLinkedGoodieBag: false`;
        break;
      case 'handledLeftover':
        searchString += `, handledLeftover: true`;
        break;
      default:
        break;
    }
  }
  if (search.isLinked) {
    searchString += `, isLinked: ${search.isLinked}`;
  }

  if (search.isLinkedFortuneBag) {
    searchString += `, isLinkedFortuneBag: ${search.isLinkedFortuneBag}`;
  }

  if (search.coupon_type) {
    searchString += `, couponTemplateTypeIn: "${search.coupon_type}"`;
  }
  if (search.redemption_method) {
    searchString += `, redemptionMethodIn: "${search.redemption_method}"`;
  }
  if (search.expired_date) {
    switch (search.expired_date) {
      case 'absolute':
        if (search.absolute) {
          const expiredDateRange = search.absolute.split(',');
          searchString += `, absoluteGte: "${expiredDateRange[0]}", absoluteLte: "${expiredDateRange[1]}"`;
        }
        break;
      case 'relativeDay':
        if (search.relativeDay) {
          searchString += `, relativeDay: ${search.relativeDay}`;
        }
        break;
      default:
        searchString += `, allTime: true`;
        break;
    }
  }

  if (search.search || search.searchKey || search.filterName) {
    searchString += `, nameIcontainsOrId: "${
      search.search || search.searchKey || search.filterName
    }"`;
  }

  if (search?.brandIn) {
    searchString += `, brandIn: "${search.brandIn}"`;
  }

  if (search?.campaign) {
    searchString += `, campaign: ${search.campaign}`;
  }

  if (search?.starred_campaign) {
    searchString += `, isStarredCampaign: ${search.starred_campaign}`;
  }

  if (search.workingTeamsIn || search.working_team) {
    searchString += `, workingTeamsInAndNull: "${
      search.workingTeamsIn || search.working_team
    }"`;
  }

  if (search?.termsAndConditionsTemplateIn) {
    searchString += `, termsAndConditionsTemplateIn: "${search?.termsAndConditionsTemplateIn}"`;
  }

  if (search?.surveyFormIn) {
    searchString += `, surveyFormIn: "${search?.surveyFormIn}"`;
  }

  if (search.getAll !== undefined) {
    searchString += `, getAll: ${search.getAll}`;
  }

  if (search.template) {
    searchString += `, template: ${search.template}`;
  }

  if (search.couponMediumIn || search.coupon_medium) {
    searchString += `, couponMediumIn: "${
      search.couponMediumIn || search.coupon_medium
    }"`;
  }

  return searchString;
};

export const getCouponTemplateList = (afterCursor, search) => {
  const searchString = getSearchString(search);
  console.log('@@73', searchString);
  const query = `{
    couponTemplates(after: "${afterCursor}"${searchString}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          name
          ${
            search.needWorkingTeam
              ? `
          workingTeams {
            edges {
              node {
                pk
                name
              }
            }
          }`
              : ''
          }
          ${
            search?.noNeedBrand
              ? ''
              : `brand {
            pk
            name
          }`
          }
          handledLeftover
          ${
            search.all || search.isAll
              ? ''
              : `
          isForcedInactive
          lastModifiedDate
          creationDate
          absoluteEffectiveDate
          absoluteExpiryDate
          status
          validPeriodType
          numberOfDaysToExpireAfterAcquisition
          formats
          redemptionMethod
          couponTemplateType
          isLinkedFortuneBag
          isLinkedGoodieBag
          requireHkidOrPassportNumber
          workingTeams {
            edges {
              node {
                pk
                name
              }
            }
          }
          # physicalCouponTemplates {
            # pk
            # creationDate
            # lastModifiedDate
            # physicalDocumentTitle
            # freeText1Title
            # freeText1Content
            # freeText2Title
            # freeText2Content
            # eventLogo
            # showCouponSetExpiryDate
            # physicalCouponPhoto
            # freeText3Title
            # freeText3Content
            # freeText4Title
            # freeText4Content
            # freeText5Title
            # freeText5Content
            # freeText6Title
            # freeText6Content
            # freeText7Title
            # freeText7Content
            # freeText8Content
          # }
          couponMedium
          physicalValidPeriodType
          physicalAbsoluteEffectiveDate
          physicalAbsoluteExpiryDate
          `
          }
        }
      }
    }
  }
  `;
  const variables = {
    input: {},
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCouponTemplateWithStockList = (afterCursor, search) => {
  const searchString = getSearchString(search);
  console.log('@@73', searchString, search);
  const query = `{
    couponStockTemplates(after: "${afterCursor}"${searchString}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          name
          stock
          handledLeftover
          ${
            search?.leftover
              ? `
          totalNubmerOfCanbeLeftoverdCoupons
          isActiveAndUnexpired
          hasActiveCampaign
          isInactiveOrExpired`
              : ''
          }
          ${
            search?.noNeedBrand
              ? ''
              : `brand {
            pk
            name
          }`
          }
          ${
            search.all || search.isAll
              ? ''
              : `
          isForcedInactive
          lastModifiedDate
          absoluteEffectiveDate
          absoluteExpiryDate
          status
          validPeriodType
          numberOfDaysToExpireAfterAcquisition
          formats
          redemptionMethod
          couponTemplateType
          isLinkedFortuneBag
          isLinkedGoodieBag
          requireHkidOrPassportNumber
          `
          }
        }
      }
    }
  }
  `;
  const variables = {
    input: {},
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCouponSet = (couponSetID) => {
  const query = `{
    couponStockTemplate(id: "${couponSetID}") {
      id
      pk
      name
      isLinkedCustomer
      lastModifiedDate
      creationDate
      instructionSectionTitle
      instructionSectionContent
      detailSectionTitle
      detailSectionContent
      handledLeftover
      totalNubmerOfLeftoverdCoupons
      totalNubmerOfCanbeLeftoverdCoupons
      merchantTermsAndConditionsTemplate {
        pk
        templateType
        title
        content
        translations {
          edges {
            node {
              language
              title
              content
              pk
            }
          }
        }
      }
      isFollowMerchantTermsAndConditionsTemplate
      merchantTermsAndConditionsTitle
      merchantTermsAndConditionsContent
      genericTermsAndConditionsTemplate {
        pk
        templateType
        title
        content
        translations {
          edges {
            node {
              language
              title
              content
              pk
            }
          }
        }
      }
      isFollowGenericTermsAndConditionsTemplate
      genericTermsAndConditionsTitle
      genericTermsAndConditionsContent
      applyCoverPhotoToAllLanguage
      applyDetailPhotosToAllLanguage
      validPeriodType
      absoluteEffectiveDate
      absoluteExpiryDate
      numberOfDaysToExpireAfterAcquisition
      relativeValidSetDeadline
      isForcedInactive
      tourCardSectionTitle
      showColorIndicator
      indicatorColor
      indicatorText
      campaigns (first: 100){
        edges {
          node {
            name
            pk
            isAllFollowCouponTemplate
            isFollowCouponTemplateStores
            isFollowCouponTemplateTourCard
            isFollowCouponTemplateName
            isFollowCouponTemplateShortDescription
            isFollowCouponTemplateCoverPhoto
            isFollowCouponTemplateDetailPhotos
            isFollowCouponTemplateLogoLabels
            isFollowCouponTemplateOfferSection
            isFollowCouponTemplateInstructionSection
            isFollowCouponTemplateMerchantTerms
            isFollowCouponTemplateGenericTerms
          }
        }
      }
      linkedCampaignsName {
        id
        name
      }
      translations {
        edges {
          node {
            language
            name
            id
            pk
            instructionSectionTitle
            instructionSectionContent
            detailSectionTitle
            detailSectionContent
            merchantTermsAndConditionsTitle
            merchantTermsAndConditionsContent
            genericTermsAndConditionsTitle
            genericTermsAndConditionsContent
            tourCardSectionTitle
            coverPhoto
            detailPhoto1
            detailPhoto2
            detailPhoto3
            detailPhoto4
            logoLabels {
              edges {
                node {
                  id
                  pk
                  name
                }
              }
            }

            shortDescription
            offerDetailTitle
            offerDetailContent

            landingPageUrl
            landingPageButtonName

            indicatorText
          }
        }
      }
      brand {
        id
        name
        pk
        translations {
          edges {
            node {
              language
              name
              description
              id
            }
          }
        }
        stores {
          edges {
            node {
              id
              pk
              name
              address
              description
              translations {
                edges {
                  node {
                    language
                    name
                    address
                    description
                  }
                }
              }
            }
          }
        }
      }
      applicableStores {
        edges {
          node {
            id
            pk
            name
            address
            description
            translations {
              edges {
                node {
                  language
                  name
                  address
                  description
                }
              }
            }
          }
        }
      }
      shouldUseInStore
      # applicableStores
      coverPhoto
      detailPhoto1
      detailPhoto2
      detailPhoto3
      detailPhoto4
      # computed fields
      totalNubmerOfGeneratedCoupons
      totalNubmerOfGeneratedPhysicalCoupons
      stock
      status
      formats
      barcodeFormat {
        id
        pk
        name
      }
      redemptionMethod
      requireHkidOrPassportNumber
      settlementValueForHktb
      settlementValueForMerchant
      couponTemplateType
      logoLabels {
        edges {
          node {
            id
            pk
            name
          }
        }
      }
      showTourCard
      tourCards(orderBy: "displayPriority") {
        edges {
          node {
            tourCard {
              pk
              title
              coverPhoto
            }
            displayPriority
          }
        }
      }

      couponCodeType
      genericCode

      displayIn
      shortDescription
      offerDetailTitle
      offerDetailContent

      requireTransactionAmountSurvey
      transactionAmountSurvey {
        pk
        name
      }
      isLinkedFortuneBag
      isLinkedGoodieBag

      discountCodeFormat
      displayCode
      haveLandingPage
      landingPageUrl
      landingPageButtonName

      showLowStockLabel
      measureLowStockWay
      lowStockStandardValue
      workingTeams {
        edges {
          node {
            id
            pk
            name
          }
        }
      }
      couponMedium
      physicalValidPeriodType
      physicalAbsoluteEffectiveDate
      physicalAbsoluteExpiryDate
      periodRestriction
      weeklyRules {
        edges {
          node {
            pk
            couponMedium
            weekDays {
              edges {
                node {
                  pk
                }
              }
            }
            timePeriodType
            timePeriods (orderBy: "startTime"){
              edges {
                node {
                  startTime
                  endTime
                }
              }
            }
          }
        }
      }
      dateRules {
        edges {
          node {
            pk
            couponMedium
            isExclude
            dayDate
            timePeriodType
            timePeriods (orderBy: "startTime"){
              edges {
                node {
                  startTime
                  endTime
                }
              }
            }
          }
        }
      }

    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createCouponTemplate = (couponTemplateInfo) => {
  const query = `mutation CreateCouponTemplate($input: CreateCouponTemplateInput!) {
      createCouponTemplate(input: $input) {
        node {
          id
          pk
          name
          brand {
            name
          }
          couponTemplateType
          couponCodeType
          redemptionMethod
          formats
          barcodeFormat {
            pk
          }
          couponMedium
          totalNubmerOfGeneratedCoupons
          totalNubmerOfGeneratedPhysicalCoupons
        }
        errors {
          field
          messages
        }
      }
    }`;
  const variables = {
    input: {
      name: 'campaign',
      ...couponTemplateInfo,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const generateCoupon = (payload) => {
  const query = `mutation GenerateCoupons($input: GenerateCouponsInput!) {
      generateCoupons(input: $input) {
        success
      }
    }`;
  const variables = {
    input: {
      ...payload,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getSingleCouponListWithTemplate = (
  templateID,
  afterCursor = null,
  rank = null,
  filterKey = null,
  search = {},
) => {
  let order = '-pk';
  if (rank === false || rank === 'false') {
    order = 'pk';
  }
  // orderBy: "${order}",
  console.log('@@634', filterKey, search);
  let searchString = `idMatch: "${filterKey || ''}"`;
  if (search.couponMedium) {
    searchString += `, couponMediumIn: "${search.couponMedium}"`;
  }
  if (search.singleCouponStatus) {
    searchString += `, couponStatusIn: "${search.singleCouponStatus}"`;
  }
  const query = `{
    coupons(first: 20, after: "${afterCursor}", ${searchString}, template: ${templateID}) {
          totalCount
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          edges {
            node {
              id
              pk
              serialNumber
              serialNumberEncrypted
              creationDate
              lastModifiedDate
              dateOfPurchase
              dateOfEarning
              dateOfGrant
              isUsed
              isExpired
              isActive
              isForcedInactive
              isVoid
              effectiveDate
              expiryDate
              owner {
                id
                pk
                membershipId
              }
              code
              forcedInactiveBy
              #store {
              #  id
              #  pk
              #  name
              #  staffCode
              #}
              #image
              usedPlatform
              referenceId
              srk
              membershipId
              batch {
                couponMedium
              }
            }
          }
        }
  }`;
  const variables = {
    input: {},
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getAllSingleCoupons = (afterCursor, querySearch) => {
  let search = `, first: ${querySearch?.pageSize || 20}`;
  let orderBy = 'pk';

  if (querySearch?.orderBy) {
    orderBy = querySearch?.orderBy;
  }

  // if (querySearch?.templateID) {
  //   search += `, template: ${querySearch?.templateID}`;
  // }

  if (querySearch?.search) {
    search += `, serialNumberIcontains: "${querySearch?.search}"`;
  }

  if (querySearch?.ids) {
    search += `, idIn: "${querySearch?.ids}"`;
  }

  if (querySearch?.ssoUid) {
    search += `, customerSsoUid: "${querySearch?.ssoUid}"`;
  }

  if (querySearch?.isExpired) {
    search += `, isExpired: ${querySearch?.isExpired}`;
  }

  if (querySearch?.isUsed) {
    search += `, isUsed: ${querySearch?.isUsed}`;
  }

  const query = `{
    coupons(after: "${afterCursor}"${search}, orderBy: "${orderBy}") {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          dateOfGrant
          serialNumber
          isVoid
          ${
            querySearch?.needTemplate
              ? `template${
                  querySearch?.getAllTemplate !== undefined
                    ? `(getAll: ${querySearch?.getAllTemplate})`
                    : ''
                } {
            pk
            name
          }`
              : ''
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getSimpleSingleCoupons = (afterCursor, querySearch) => {
  let search = `, first: ${querySearch?.pageSize || 20}`;

  // if (querySearch?.templateID) {
  //   search += `, template: ${querySearch?.templateID}`;
  // }

  if (querySearch?.search) {
    search += `, serialNumberIcontains: "${querySearch?.search}"`;
  }

  if (querySearch?.ids) {
    search += `, idIn: "${querySearch?.ids}"`;
  }

  const query = `{
    simpleCoupons(after: "${afterCursor}"${search}, orderBy: "pk") {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          serialNumber
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getAllSingleCouponWithTemplate = (templateID) => {
  const query = `{
    coupons(template: ${templateID}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          dateOfGrant
          serialNumber
          isVoid
          owner {
            pk
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getSingleCoupon = (id) => {
  const query = `
  {
    coupon(id: "${id}") {
      id
      pk
      bindFortuneBagContent
      couponTransaction {
        transactionValue
        transactionType
        store {
          pk
          name
        }
      }
      transactions(first:1, orderBy:"-pk", typeIn:"USE"){
        edges{
          node{
            administrator{
              pk
              username
              adminType
            }
          }
        }
      }
      template {
        name
        formats
        barcodeFormat {
          id
          pk
          name
        }
        redemptionMethod
        discountCodeFormat
        periodRestriction
        weeklyRules {
          edges {
            node {
              pk
              couponMedium
              weekDays {
                edges {
                  node {
                    pk
                  }
                }
              }
              timePeriodType
              timePeriods (orderBy: "startTime"){
                edges {
                  node {
                    startTime
                    endTime
                  }
                }
              }
            }
          }
        }
        dateRules (orderBy: "dayDate"){
          edges {
            node {
              pk
              couponMedium
              isExclude
              dayDate
              timePeriodType
              timePeriods (orderBy: "startTime"){
                edges {
                  node {
                    startTime
                    endTime
                  }
                }
              }
            }
          }
        }
      }
      isForcedInactive
      isVoid
      #store {
      #  name
      #}
      owner {
        membershipId
      }
      dateOfPurchase
      dateOfEarning
      dateOfGrant
      serialNumber
      serialNumberEncrypted
      code
      isUsed
      isExpired
      isActive
      effectiveDate
      expiryDate
      #image
      creationDate
      usedAt
      referenceId
      srk
      membershipId
      batch {
        couponMedium
      }
      usedPlatform
      useMethod
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deactiveCoupons = (ids) => {
  const query = `
  mutation DeactivateCoupons($input: DeactivateCouponsInput!) {
    deactivateCoupons(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteSingleCoupon = (couponPK) => {
  const query = `
  mutation DeleteCoupon($input: DeleteCouponInput!) {
    deleteCoupon(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      id: couponPK,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteSingleCoupons = (ids) => {
  const query = `
  mutation DeleteCoupons($input: DeleteCouponsInput!) {
    deleteCoupons(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids: ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deactiveSingleCoupon = (couponPK) => {
  const query = `
  mutation DeactivateCoupon($input: DeactivateCouponInput!) {
    deactivateCoupon(input: $input) {
      node {
        id
        template {
          name
        }
      }
    }
  }`;
  const variables = {
    input: {
      id: couponPK,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const activeSingleCoupon = (couponPK) => {
  const query = `
  mutation ActivateCoupon($input: ActivateCouponInput!) {
    activateCoupon(input: $input) {
      node {
        id
        template {
          name
        }
      }
    }
  }`;
  const variables = {
    input: {
      id: couponPK,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const activeCoupons = (ids) => {
  const query = `
  mutation ActivateCoupons($input: ActivateCouponsInput!) {
    activateCoupons(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids: ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const grantCoupon = (couponPK, customerPK) => {
  const query = `mutation GrantCoupon($input: GrantCouponInput!) {
    grantCoupon(input: $input) {
      node {
        id
        pk
        isForcedInactive
        owner {
          pk
        }
        dateOfPurchase
        dateOfEarning
        dateOfGrant
        serialNumber
        isUsed
        isExpired
        isActive
        effectiveDate
        expiryDate
      }
    }
  }`;
  const variables = {
    input: {
      id: couponPK,
      customer: customerPK,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const reclaimCoupon = (id) => {
  const query = `
  mutation ReclaimCoupon($input: ReclaimCouponInput!) {
    reclaimCoupon(input: $input) {
      node {
        id
        pk
        template {
          name
        }
        isForcedInactive
        dateOfPurchase
        dateOfEarning
        dateOfGrant
        serialNumber
        isUsed
        isExpired
        isActive
        effectiveDate
        expiryDate
      }
    }
  }`;
  const variables = {
    input: {
      id,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteCouponSet = (couponSetPK) => {
  const query = `
  mutation DeleteCouponTemplate($input: DeleteCouponTemplateInput!) {
    deleteCouponTemplate(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      id: couponSetPK,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteCouponSets = (ids) => {
  const query = `
  mutation DeleteCouponTemplates($input: DeleteCouponTemplatesInput!) {
    deleteCouponTemplates(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateCouponSetActiveStatus = (
  couponSetPK,
  isForcedInactive = false,
) => {
  const query = `
  mutation UpdateCouponTemplate($input: UpdateCouponTemplateInput!) {
    updateCouponTemplate(input: $input) {
      node {
        id
        name
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: {
      id: couponSetPK,
      isForcedInactive: isForcedInactive,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateCouponSet = (couponSet) => {
  const query = `
  mutation UpdateCouponTemplate($input: UpdateCouponTemplateInput!) {
    updateCouponTemplate(input: $input) {
      node {
        id
        pk
        name
        brand {
          name
        }
        couponTemplateType
        couponCodeType
        redemptionMethod
        formats
        barcodeFormat {
          pk
        }
        couponMedium
        totalNubmerOfGeneratedCoupons
        totalNubmerOfGeneratedPhysicalCoupons
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: {
      ...couponSet,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getAllCouponSet = (ssoUid) => {
  const query = `
  {
    coupons(customerSsoUid: "${ssoUid}", customerName: "", isExpired: false, isUsed: false orderBy: "-pk") {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          template {
            name
          }
          isForcedInactive
          store {
            name
            staffCode
          }
          owner {
            id
            pk
            membershipId
          }
          dateOfPurchase
          dateOfEarning
          dateOfGrant
          serialNumber
          isUsed
          isExpired
          isActive
          effectiveDate
          expiryDate
        }
      }
    }
  }

  `;

  const variables = {
    input: {},
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getBarcodeFormats = (search) => {
  const searchName = search || '';
  const query = `{
    barcodeFormats(nameIcontains:"${searchName}") {
      edges {
        node {
          id
          pk
          name
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getRelatedCampaignWorkingTeams = (couponID) => {
  const query = `{
    couponTemplateRelatedWorkingTeams(couponTemplate: ${couponID}) {
      id
      name
      campaignWorkingTeamIds
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const duplicateObjects = (value) => {
  const query = `
  mutation duplicateObject($input: DuplicateObjectInput!) {
    duplicateObject(input: $input) {
      success
    }
  }`;
  const variables = {
    input: value,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCouponBatches = (afterCursor, search) => {
  const searchString = getSearchString(search);
  console.log('@@73', searchString);
  const query = `{
    couponBatches(after:"${afterCursor}", ${searchString}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          template {
            pk
            name
          }
          creationDate
          couponMedium
          index
          quantity
        }
      }
    }
  }`;
  const variables = {
    input: {},
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const manualUseCoupon = (storeId, srk) => {
  console.log('@1234', storeId, srk);
  const query = `
  mutation ManualUseCoupon($input: ManualUseCouponInput!) {
    manualUseCoupon(input: $input) {
      success
      errors {
        code
        message
      }
      alreadyRedeemed
      coupon {
        id
        referenceId
        srk
        usedAt
        template {
          name
        }
      }
    }
  }`;
  const variables = {
    input: {
      srk: srk,
      store: storeId,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCouponInfoBySrk = (srk) => {
  const query = `
  mutation GetCouponInfoBySrk($input: GetCouponInfoBySrkInput!) {
    getCouponInfoBySrk(input: $input) {
      success
      errors {
          code
          message
      }
      coupon {
        template {
          pk
          name
        }
        srk
      }
      campaignNames
      brand {
        id
        name
      }
    }
  }
  `;
  const variables = {
    input: {
      srk: srk,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updatePhysicalCouponTemplate = (templateInfo) => {
  const query = `mutation updatePhysicalCouponTemplate($input: UpdatePhysicalCouponTemplateInput!) {
    updatePhysicalCouponTemplate(input: $input) {
        success
        node {
          pk
          creationDate
          lastModifiedDate
          couponTemplate {
            pk
          }
          physicalDocumentTitle
          # freeText1Title
          # freeText1Content
          # freeText2Title
          # freeText2Content
          # eventLogo
          # showCouponSetExpiryDate
          # physicalCouponPhoto
          # freeText3Title
          # freeText3Content
          # freeText4Title
          # freeText4Content
          # freeText5Title
          # freeText5Content
          # freeText6Title
          # freeText6Content
          # freeText7Title
          # freeText7Content
          # freeText8Content
        }
        errors {
          field
          messages
        }
      }
    }`;
  const variables = {
    input: {
      ...templateInfo,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getPhysicalCouponTemplateList = (afterCursor, search) => {
  const searchString = getSearchString(search);
  console.log('@@13');

  const query = `{
    physicalCouponTemplates(after:"${afterCursor}", ${searchString}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          pk
          creationDate
          lastModifiedDate
          couponTemplate {
            pk
          }
          physicalDocumentTitle
          freeText1Title
          freeText1Content
          freeText2Title
          freeText2Content
          eventLogo
          showCouponSetExpiryDate
          physicalCouponPhoto
          freeText3Title
          freeText3Content
          freeText4Title
          freeText4Content
          freeText5Title
          freeText5Content
          freeText6Title
          freeText6Content
          freeText7Title
          freeText7Content
          freeText8Content
        }
      }
    }
  }`;
  const variables = {
    input: {},
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
