import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import './CreateSegment.scss';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import ScrollableComponent from '../../../components/base/ScrollableComponent';
import { createAction, removeFromSessionStorage } from '../../../utils';
import { SaveAndBackWithOutTempButtons } from '../../../components/base/BottomStepComponent';
import ContentSections from '../../../components/base/ContentSections';
import OverviewSection from '../../../components/earning/OverviewSection';
import { CheckStatus, SavedStatus } from '../../../config/CustomEnums';
import ContentSection from '../../../components/segment/ContentSection';

const dataArray = [];

function CreateSegment() {
  const { savedSuccess, checked, tempSegment } = useSelector((state) => ({
    savedSuccess: state.segments.saved,
    checked: state.segments.checked,
    tempSegment: state.segments.segment,
  }));
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [onCheck, setOnCheck] = useState(false);

  useEffect(() => {
    dispatch({ type: 'segments/clearData' });

    const segmentId = params.id;
    if (segmentId) {
      removeFromSessionStorage('tempSegment');
      dispatch(
        createAction('segments/getOneSegment')({
          id: segmentId,
        }),
      );
    } else {
      dispatch({
        type: 'segments/loadSegmentFromCookie',
      });
    }
    dispatch(createAction('levels/getLevelList')({}));
    // return () => {
    //   dispatch({ type: 'segments/clearData' });
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (checked === CheckStatus.checkedWithSuccess) {
      dispatch(createAction('segments/createOrUpdateSegment')({}));
    }

    if (onCheck) {
      setTimeout(() => {
        let formatedData = {};
        dataArray.forEach((item) => {
          formatedData = {
            ...formatedData,
            ...item,
          };
        });

        dispatch({
          type: 'segments/checkValsValid',
          payload: { data: formatedData },
        });
      }, 500);
    }
    setOnCheck(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, checked, onCheck]);

  useEffect(() => {
    if (
      savedSuccess === SavedStatus.savedWithSuccess &&
      history.location.pathname.includes('edit')
    ) {
      history.push('/segments');
    }
  }, [savedSuccess, history]);

  useEffect(() => {
    setTimeout(() => {
      dispatch({
        type: 'segments/updateState',
        payload: { formChanged: false },
      });
    }, 500);
  }, [tempSegment]);

  const submitAction = (data) => {
    // console.log('@@71');
    dataArray.push(data);
  };

  const sections = [
    <OverviewSection
      focusChange={() => {}}
      language={'en'}
      onSubmit={onCheck}
      onSubmitAction={(data) => {
        submitAction(data);
      }}
      areaTitle={''}
      defaultValue={tempSegment}
      model={'segments'}
    />,
    <ContentSection defaultValue={tempSegment} />,
  ];

  const stepSet = [
    <>
      <ContentSections sections={sections} hidePreview={true} />

      <SaveAndBackWithOutTempButtons
        saveText={'Save'}
        saveAction={(event) => {
          setOnCheck(true);
          // handleSave(event);
        }}
        backAction={() => history.goBack()}
      />
    </>,
  ];

  const caution = () => {
    return {
      detail: '', //The customers need to fulfill all the fields below in order to enter this segment.
      title: params.id ? 'Edit Segment' : 'Create Segment',
      model: 'segments',
    };
  };

  return (
    <div className="segment">
      <ScrollableComponent
        tempSave={(save) => {
          if (!save) {
            dispatch({
              type: 'segments/removeFromCookie',
            });
          }
        }}
        showFinishPop={savedSuccess === SavedStatus.savedWithSuccess}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: 'Segment is successfully created.',
          steps: null,
          buttons: [
            {
              text: 'Back to segment list',
              action: () => {
                history.push({
                  pathname: '/segments',
                });
              },
            },
          ],
        }}
        continueCreateConfig={{
          onConfirm: () => {
            history.push(history.location.state?.from);
          },
          onCancel: () => history.push('/segments'),
          onClose: () => history.push('/segments'),
        }}
        content={stepSet}
        currentStep={0}
        breadcrumb={
          <CustomBreadcrumb
            name={params.id ? tempSegment?.name : 'Create Segment'}
          />
        }
        caution={caution()}
      />
    </div>
  );
}

export default CreateSegment;
