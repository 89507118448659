import React from 'react';
import { useFormContext } from 'react-hook-form';
import MembershipInfoCard from '../../components/customer/MembershipInfoCard';
import {
  getGoodieBagPerviewPropertiesFields,
  getPeriodDetail,
  GOODIE_BAG_REWARD_TYPE_OPTIONS,
} from '../../models/GoodieBagsModel';
import './StepFourPropertiesSection.scss';
import { formatDate, TimeFormater } from '../../utils/TimeFormatUtil';

function StepFourShowFilterSection({ data }) {
  const { getValues } = useFormContext() || {};
  const goodieBag = data || getValues() || {};

  return (
    <div className="goodie-bag-step-four-properties">
      <MembershipInfoCard
        title="Items to show in filter in frontend campaign list"
        data={{
          showCampaignCategoryFilter: goodieBag.showCampaignCategoryFilter
            ? 'Yes'
            : 'No',
          showBrandFilter: goodieBag.showBrandFilter ? 'Yes' : 'No',
          showOfferTypeLabelFilter: goodieBag.showOfferTypeLabelFilter
            ? 'Yes'
            : 'No',
          showCreditRangeFilter: goodieBag.showCreditRangeFilter ? 'Yes' : 'No',
          showLocationFilter: goodieBag.showLocationFilter ? 'Yes' : 'No',
        }}
        fields={[
          [
            {
              title: 'Category',
              field: 'showCampaignCategoryFilter',
            },
          ],
          [
            {
              title: 'Brand',
              field: 'showBrandFilter',
              showTips: false,
            },
          ],
          [
            {
              title: 'Offer type label',
              field: 'showOfferTypeLabelFilter',
            },
          ],
          // ...(goodieBag.rewardType !== GOODIE_BAG_REWARD_TYPE_OPTIONS.gift.value
          //   ? [
          //       [
          //         {
          //           title: 'Credit range',
          //           field: 'showCreditRangeFilter',
          //         },
          //       ],
          //     ]
          //   : []),
          [
            {
              title: 'Credit range',
              field: 'showCreditRangeFilter',
            },
          ],
          [
            {
              title: 'Location',
              field: 'showLocationFilter',
            },
          ],
        ]}
      />
    </div>
  );
}

export default StepFourShowFilterSection;
