import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import './SortableTourCard.scss';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import DeleteIcon from '../../assets/images/deleteIcon.png';
import TourCardSection from '../campaign/campaignCreation/TourCardSection';
import TourCardSelectorPrompt from './TourCardSelectorPrompt';
import { useDispatch, useSelector } from 'react-redux';
import { createAction, removeElementFromArray } from '../../utils';
import AuthButton from '../base/AuthButton';
import BasePrompt from '../base/prompt/BasePrompt';
import { useHistory } from 'react-router-dom';

export const SortableRow = SortableElement(({ children }) => children);

export const SortableDivContainer = SortableContainer(
  ({ children, hasError }) => {
    return (
      <div className={`sortable-tour-card${hasError ? ' error' : ''}`}>
        {children}
      </div>
    );
  },
);

const SorableTourCard = ({
  data,
  disabled,
  onSortEnd,
  setData,
  isCoupon,
  hasError,
  errorMessage = 'Please provide tour cards.',
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showExistPrompt, setShowExistPrompt] = useState(false);
  const [showCreatePop, setShowCreatePop] = useState(false);
  const handleSortStart = ({ node, helper }) => {
    document.body.style.cursor = 'move';
    const color = window
      .getComputedStyle(node, null)
      .getPropertyValue('background-color');
    helper.style.backgroundColor = color;
    // const tds = helper.childNodes;
    // node.childNodes.forEach(
    //   (node, idx) => (tds[idx].style.width = `${node.offsetWidth}px`),
    // );
    node.classList.add('drag-item-ghost');
  };

  const { tourCardList } = useSelector((state) => ({
    tourCardList: state.tourCard.allList,
  }));

  useEffect(() => {
    dispatch(createAction('tourCard/getList')({ isSelectorLoad: true }));
  }, [dispatch]);
  return (
    <div className="second-section">
      <div className="second-section-title">Tour card</div>
      <div className="second-section_description">
        Can drag and drop to change the order
      </div>
      <SortableDivContainer
        hasError={hasError}
        distance={10}
        shouldCancelStart={(e) => {
          if (disabled) {
            return true;
          }
          console.log(e.target.tagName);
          if (e.target.tagName === 'A' || e.target.tagName === 'INPUT') {
            return true;
          }
          if (e.target.className.indexOf('sort-disabled') !== -1) {
            return true;
          }
        }}
        hideSortableGhost={false}
        helperClass="sortable-helper"
        onSortStart={handleSortStart}
        onSortEnd={({ oldIndex, newIndex, nodes }) => {
          nodes.forEach((node, idx) => {
            node.node.classList.remove('drag-item-ghost');
          });
          document.body.style.cursor = '';
          onSortEnd && onSortEnd(oldIndex, newIndex);
        }}
      >
        {data.map((item, index) => (
          <SortableRow index={index}>
            <div className="tour-card-row">
              <div className="tour-card-left">
                <div className="tour-card-index">{index + 1}</div>
                <img
                  draggable={false}
                  className="tour-card-cover-img"
                  src={item.coverPhoto}
                  alt=""
                />
                <div className="tour-card-info">
                  <div className="tour-card-title">{item.title}</div>
                  <div className="tour-card-id">ID: {item.pk}</div>
                </div>
              </div>

              {disabled ? null : (
                <Image
                  draggable={false}
                  src={DeleteIcon}
                  className="tour-card-delete-icon"
                  onClick={() => {
                    setData(data.filter((val) => val.pk !== item.pk));
                  }}
                />
              )}
            </div>
          </SortableRow>
        ))}
        <AuthButton
          variant={'primary'}
          title={'Add existing Tour card'}
          action={() => setShowExistPrompt(true)}
          customClass={`btn-back-button-common base-buttons-item base-buttons-secondary exist-button ${
            data?.length > 0 ? 'exist-button-margin-top' : ''
          }`}
          disabled={disabled}
        />
      </SortableDivContainer>
      {hasError ? (
        <div className="error-field-message-style">{errorMessage}</div>
      ) : null}
      {isCoupon ? (
        <AuthButton
          variant={'primary'}
          title={'Add new Tour card'}
          action={() => setShowCreatePop(true)}
          customClass={`btn-back-button-common add-new-tour-card${
            hasError ? ' error' : ''
          }`}
          disabled={disabled}
        />
      ) : null}
      <BasePrompt
        show={showCreatePop}
        closeAction={() => setShowCreatePop(false)}
        rightButton={{
          text: 'Go to Create Tour card',
          action: () => {
            setShowCreatePop(false);
            history.push({
              pathname: '/tour_cards/create',
              state: {
                from: history.location,
                title: 'Continue to create coupon?',
                content: 'You can continue to create the coupon.',
              },
            });
          },
        }}
        title={'Go to create a Tour card?'}
        description={
          'You will leave the coupon set creation process. After you create tour card you can come back to create a coupon set.'
        }
      />
      <TourCardSelectorPrompt
        show={showExistPrompt}
        closeAction={() => setShowExistPrompt(false)}
        doneAction={(selected) => {
          setShowExistPrompt(false);
          setData(selected);
        }}
        productList={tourCardList}
        defaultValue={data}
        filterAction={(value) => {
          dispatch(
            createAction('tourCard/getList')({
              isSelectorLoad: true,
              filter: value,
            }),
          );
        }}
      />
    </div>
  );
};
export default SorableTourCard;
