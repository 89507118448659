import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import './CouponsOverview.scss';
import OverviewCard from '../../dashboard/OverviewCard';
import FilterSection from './CouponOverviewFilter';
import { createAction, getHashKeyString } from '../../../utils';
import CustomTips from '../../base/CustomTips';
import OverviewTable from '../../base/OverviewTable';
import { TAB_OVERVIEW } from '../../../containers/engagement/coupon/CouponList';

export const COUPOINS_DATA_LIST_CONFIG = [
  {
    title: (
      <>
        <CustomTips
          detail={
            <div>
              <label className="coupon-overview-coupon-card-title">
                The sum of
                <br />
                - coupon generated from CMS;
                <br />
                - coupon generated from generic codes;
                <br />
                - coupon imported from files.
                <br />
                The value is as of the previous time before the selected period.
              </label>
            </div>
          }
          iconClassName="coupon-overview-coupon-card-title-icon"
          popoverName="coupon-overview-coupon-tips-area"
        >
          <div className="coupon-overview-coupon-card-content">
            <label>Total coupon generated and imported</label>
          </div>
        </CustomTips>
      </>
    ),
    key: 'totalNumberOfCoupons',
    asOf: true,
  },
  {
    title: (
      <>
        <CustomTips
          detail={
            <div>
              <label className="coupon-overview-coupon-card-title">
                Total number of single coupon, minus number of coupon acquired,
                number of coupon expired and number of coupon deactivated.
                <br />
                The value is as of the previous time before the selected period.
              </label>
            </div>
          }
          iconClassName="coupon-overview-coupon-card-title-icon"
          popoverName="coupon-overview-coupon-tips-area"
        >
          <div className="coupon-overview-coupon-card-content">
            <label>Total available coupon</label>
          </div>
        </CustomTips>
      </>
    ),
    key: 'totalNumberOfCouponAvailable',
    asOf: true,
  },
  {
    title: 'Coupon acquired',
    key: 'totalNumberOfCouponAcquired',
  },
  {
    title: (
      <>
        <CustomTips
          detail={
            <div>
              <label className="coupon-overview-coupon-card-title">
                Number of coupon acquired / over Total number of available
                coupon (as of the time before the selected period) x 100%
              </label>
            </div>
          }
          iconClassName="coupon-overview-coupon-card-title-icon"
          popoverName="coupon-overview-coupon-tips-area"
        >
          <div className="coupon-overview-coupon-card-content">
            <label>Coupon acquired rate</label>
          </div>
        </CustomTips>
      </>
    ),
    key: 'acquiredCouponsConversionRate',
    showPercentage: true,
  },
  {
    title: 'Coupon redeemed',
    key: 'totalNumberOfCouponUsed',
  },
  {
    title: (
      <>
        <CustomTips
          detail={
            <div>
              <label className="coupon-overview-coupon-card-title">
                Number of coupon used / over acquired x 100%
              </label>
            </div>
          }
          iconClassName="coupon-overview-coupon-card-title-icon"
          popoverName="coupon-overview-coupon-tips-area"
        >
          <div className="coupon-overview-coupon-card-content">
            <label>Coupon redemption rate</label>
          </div>
        </CustomTips>
      </>
    ),
    key: 'usedCouponsConversionRate',
    showPercentage: true,
  },
];

const RAKING_TABLE_STATUS_CONFIG = {
  all: 'Showing result of all campaign(s).',
  hidden: 'Not applicable if specific brand is selected.',
};

const COUPON_RAKING_TABLE_TITLE = [
  { name: 'Coupon acquired ranking', fieldName: 'acquired_rank' },
  { name: 'Coupon redeemed ranking', fieldName: 'used_rank' },
  { name: 'Brand', fieldName: 'name' },
  {
    name: 'Number of coupon acquired',
    fieldName: 'acquired_count',
  },
  {
    name: 'Number of coupon redeemed',
    fieldName: 'used_count',
  },
];

const COUPON_TOP10_REDEMPTION_TABLE_TITLE = [
  { name: 'Rank', fieldName: 'count' },
  { name: 'Name', fieldName: 'name' },
  { name: 'Number', fieldName: 'count' },
  { name: 'Percentage', fieldName: 'count' },
];

const Top10FieldEnum = {
  rank: { name: 'Rank', fieldIndex: 0 },
  name: { name: 'Name', fieldIndex: 1 },
  number: { name: 'Number', fieldIndex: 2 },
  percentage: { name: 'Percentage', fieldIndex: 3 },
}

const getCouponStatus = (couponOverviewFilter) => {
  let campaignsLabel = 'all';
  if (couponOverviewFilter?.isStarred === 'true') {
    campaignsLabel = 'starred';
  } else if (couponOverviewFilter?.isStarred === 'false') {
    campaignsLabel = 'non-starred';
  }

  return couponOverviewFilter?.brand?.pk
    ? `Showing result of ${campaignsLabel} campaign(s) from ${couponOverviewFilter?.brand?.name} `
    : `Showing result of ${campaignsLabel} campaign(s) from all brand`;
};

const CouponRakingTable = ({ data, couponOverviewFilter }) => {
  const [sort, sortChange] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [searchName, setSearchName] = useState();

  return (
    <OverviewTable
      name="COUPON ACQUIRED & REDEMPTION Ranking - by brand"
      hidden={couponOverviewFilter?.brand?.pk}
      status={
        couponOverviewFilter?.brand?.pk
          ? RAKING_TABLE_STATUS_CONFIG.hidden
          : getCouponStatus(couponOverviewFilter)
      }
      placeholder="Search by brand name"
      titles={COUPON_RAKING_TABLE_TITLE}
      onSortChange={sortChange}
      currentSort={sort}
      data={data?.tableData}
      searchName={searchName}
      onFileterChange={(key) => {
        setSearchName(key);
      }}
      currentPage={currentPage}
      totalCount={data?.totalCount}
      pageChange={(page) => {
        setCurrentPage(page);
      }}
      hasCountInfo={true}
      disableRank={false}
      actionKey={'overview/getCouponsRakingTable'}
      fromModel="COUPON"
    />
  );
};

const CouponTop10RedemptionByDistrictTable = ({
  data,
  couponOverviewFilter,
}) => {
  const [sort, sortChange] = useState();
  const [isAscending, setIsAscending] = useState(true);
  const [sortData, setSortData] = useState(data);
  const dispatch = useDispatch();
  const {
    CouponsTop10RedemptionByDistrictTableNew,
  } = useSelector((state) => ({
    CouponsTop10RedemptionByDistrictTable:
      state.overview.CouponsTop10RedemptionByDistrictTableNew,
  }));

  useEffect(() => {
    setSortData(data)
  }, [data]);

  useEffect(() => {
    setSortData(CouponsTop10RedemptionByDistrictTableNew);
  }, [CouponsTop10RedemptionByDistrictTableNew]);

  useEffect(() => {
    dispatch(createAction('overview/getCouponsTop10RedemptionByDistrictNew')({
      sortData,
      sort,
      isAscending,
    }))
    setIsAscending(!isAscending);
  }, [sort])

  // console.log('@242', data);
  return (
    <OverviewTable
      name="Top 10 redemption - by district"
      status={getCouponStatus(couponOverviewFilter)}
      titles={COUPON_TOP10_REDEMPTION_TABLE_TITLE}
      onSortChange={sortChange}
      currentSort={sort}
      data={sortData}
      hideFilter
      disableRank={false}
      // actionKey={'overview/getCouponsTop10RedemptionByDistrict'}
      fromModel="COUPON"
    />
  );
};

const CouponTop10RedemptionByStoreTable = ({ data, couponOverviewFilter }) => {
  const [sort, sortChange] = useState();
  const [isAscending, setIsAscending] = useState(true);
  const [sortData, setSortData] = useState(data);
  const dispatch = useDispatch();
  const {
    CouponsTop10RedemptionByStoreTableNew,
  } = useSelector((state) => ({
    CouponsTop10RedemptionByDistrictTable:
      state.overview.CouponsTop10RedemptionByStoreTableNew,
  }));
  
  useEffect(() => {
    setSortData(data)
  }, [data]);

  useEffect(() => {
    setSortData(CouponsTop10RedemptionByStoreTableNew);
  }, [CouponsTop10RedemptionByStoreTableNew])

  useEffect(() => {
    dispatch(createAction('overview/getCouponsTop10RedemptionByStoreNew')({
      sortData,
      sort,
      isAscending,
    }))
    setIsAscending(!isAscending);
  }, [sort])

  return (
    <OverviewTable
      name="Top 10 redemption - by store"
      status={getCouponStatus(couponOverviewFilter)}
      titles={COUPON_TOP10_REDEMPTION_TABLE_TITLE}
      onSortChange={sortChange}
      currentSort={sort}
      data={sortData}
      hideFilter
      disableRank={false}
      // actionKey={'overview/getCouponsTop10RedemptionByStore'}
      fromModel="COUPON"
    />
  );
};

const CouponsOverview = (props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const activeTab = getHashKeyString(location.hash) || TAB_OVERVIEW;
  const {
    couponSetList,
    overviewData,
    couponsStatus,
    couponsRakingTable,
    CouponsTop10RedemptionByDistrictTable,
    CouponsTop10RedemptionByStoreTable,
    couponsOverviewDate,
    couponOverviewFilter,
  } = useSelector((state) => ({
    couponSetList: state.couponList.couponSetListAllByOverview,
    overviewData: state.overview,
    couponsStatus: state.overview.couponOverviewCouponsStatus,
    couponsRakingTable: state.overview.couponsRakingTable,
    CouponsTop10RedemptionByDistrictTable:
      state.overview.CouponsTop10RedemptionByDistrictTable,
    CouponsTop10RedemptionByStoreTable:
      state.overview.CouponsTop10RedemptionByStoreTable,
    couponsOverviewDate: state.overview.couponsOverviewDate,
    couponOverviewFilter: state.overview.couponOverviewFilter,
  }));
  return (
    <div>
      <FilterSection
        propsCouponsOverviewDate={couponsOverviewDate?.startDate ? couponsOverviewDate : props.couponsOverviewDate} />
      <div className="coupon-overview-section">
        <div className="overview-row-title overview-coupons-title">
          <label>coupons</label>
          <label className="overview-coupons-status-information">
            {couponSetList?.length <= 0
              ? 'No coupon found, please fine tune the filter setting.'
              : couponsStatus}
          </label>
        </div>
        {couponSetList?.length <= 0 ? (
          <div className="overview-row"></div>
        ) : (
          <div className="overview-row overview-row-coupons">
            {COUPOINS_DATA_LIST_CONFIG.map((config, index) => {
              return (
                <OverviewCard
                  title={config.title}
                  value={
                    // couponOverviewFilter.isStarred &&
                    // [
                    //   'totalNumberOfCoupons',
                    //   'totalNumberOfCouponAvailable',
                    // ].indexOf(config.key) >= 0
                    //   ? 'N/A'
                    //   : overviewData?.[config.key]?.value
                    overviewData?.[config.key]?.value
                  }
                  change={overviewData?.[config.key]?.change}
                  showPercentage={config.showPercentage}
                  asOfDate={
                    config.asOf
                      ? couponsOverviewDate?.endDateWithoutFormat
                      : null
                  }
                />
              );
            })}
          </div>
        )}
      </div>
      <CouponRakingTable
        data={couponsRakingTable}
        couponOverviewFilter={couponOverviewFilter}
      />
      <CouponTop10RedemptionByDistrictTable
        data={CouponsTop10RedemptionByDistrictTable}
        couponOverviewFilter={couponOverviewFilter}
      />
      <CouponTop10RedemptionByStoreTable
        data={CouponsTop10RedemptionByStoreTable}
        couponOverviewFilter={couponOverviewFilter}
      />
    </div>
  );
};

export default CouponsOverview;
