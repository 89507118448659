import React, { useState, useEffect } from 'react';
import CustomEditor from '../base/CustomEditor';
import { POINT_RECORD_TYPE } from './CreatePointRecordStepOne';
import { useSelector, useDispatch } from 'react-redux';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import { CreatePointRecordError } from '../../models/CreatePointRecordModel';
import {
  isShowError,
  firstError,
} from '../campaign/campaignCreation/CreateCampaignHandleError';
import { LanguageConfig } from '../../config/CustomEnums';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import { createAction } from '../../utils';

function CreatePointRecordDetail(props) {
  const dispatch = useDispatch();
  const { errorFields, defaultPoints, defaultRemarks } = useSelector(
    (state) => ({
      errorFields: state.createPointRecord.errorFields,
      defaultPoints: state.createPointRecord.pointRecord.points,
      defaultRemarks: state.createPointRecord.pointRecord.remarks,
      expiryDate: state.createPointRecord.pointRecord.expiryDate,
      usedDate: state.createPointRecord.pointRecord.usedDate,
    }),
  );

  const type = props.type;
  // const isEdit = props.isEdit;

  useEffect(() => {
    firstError(
      `${CreatePointRecordError.operatedPoints.name}`,
      errorFields,
      LanguageConfig.english,
    );
  }, [errorFields]);

  const isError = () => {
    return isShowError(
      CreatePointRecordError.operatedPoints.name,
      errorFields,
      LanguageConfig.english,
    );
  };

  const sectionTitle = (type) => {
    if (type === POINT_RECORD_TYPE.TYPE_ADD) {
      return 'ADD POINT';
    }
    if (type === POINT_RECORD_TYPE.TYPE_REMOVE) {
      return 'REMOVE POINT';
    }
  };

  return (
    <>
      <div className="first-section-title">{sectionTitle(type)}</div>
      <div>
        <CustomTitleLabel title="Value" />
        <div className="d-flex">
          <input
            type="text"
            className={`day-input-fields ${isError() ? 'error-field' : ''}`}
            value={defaultPoints}
            onInput={(e) => {
              let data = e.target.value;
              data = data.replace(/[^0-9.]/g, '');
              dispatch(
                createAction('createPointRecord/updatePointRecordState')({
                  points: data,
                }),
              );
            }}
          />
          <label className="days-label">points</label>
        </div>
        <ErrorFieldMessage
          id={`${CreatePointRecordError.operatedPoints.name}`}
          error={isError()}
          message={CreatePointRecordError.operatedPoints.message}
        />
      </div>
      {/* {isEdit ? editDetailExtraSection(type) : null} */}
      <div>
        <CustomTitleLabel title="Remarks (optional)" />
        <CustomEditor
          initialValue={defaultRemarks}
          onValueChange={(data) => {
            dispatch(
              createAction('createPointRecord/updatePointRecordState')({
                remarks: data,
              }),
            );
          }}
        />
      </div>
    </>
  );
}

export default CreatePointRecordDetail;
