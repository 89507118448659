import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import MembershipInfoCard from '../../components/customer/MembershipInfoCard';
import {
  getGoodieBagPerviewPropertiesFields,
  getPeriodDetail,
  GOODIE_BAG_REWARD_TYPE_OPTIONS,
} from '../../models/GoodieBagsModel';
import './StepFourPropertiesSection.scss';
import { formatDate, TimeFormater } from '../../utils/TimeFormatUtil';
import ListFortuneBagCampaigns from './ListFortuneBagCampaigns';

function StepFourPropertiesSection({ data, isDetail = false }) {
  const { getValues } = useFormContext() || {};
  const goodieBag = data || getValues() || {};
  const rewardType =
    goodieBag.rewardType || GOODIE_BAG_REWARD_TYPE_OPTIONS.cashVoucher.value;
  console.log('@@17: ', goodieBag);
  const now = new Date();
  const latestValidCoupopnAmount = useMemo(() => {
    let total = [];
    let stock = [];

    const campaigns = Object.values(
      goodieBag.slotFortuneBagCampaigns || {},
    )?.[0];
    if (!campaigns || campaigns?.length <= 0) {
      return { total: [0], stock: [0] };
    }
    campaigns.forEach((item) => {
      if (
        (!item.endDate ||
          new Date(item.startDate) <= now <= new Date(item.endDate)) &&
        item.isPublished
      ) {
        total.push(
          parseInt(
            (item.couponCampaignInfo?.quantityOfGenerate || 0) /
              (item.goodieBagReward || 1),
          ),
        );
        stock.push(
          parseInt(
            (item.couponCampaignInfo?.quantityOfRemaining || 0) /
              (item.goodieBagReward || 1),
          ),
        );
      }
    });

    return { total, stock };
  }, [goodieBag.slotFortuneBagCampaigns]);

  const remainingGoodiebagLinks = useMemo(
    () =>
      (goodieBag.quantityOfCreated || 0) +
      (isDetail ? 0 : goodieBag.overallAmount || 0) -
      (goodieBag.quantityOfAcquired || 0),
    [goodieBag],
  );

  const newGoodieBag = {
    ...goodieBag,
    ...getPeriodDetail(goodieBag),
    slotCampaigns: {
      ...goodieBag.slotCashCampaigns,
      ...goodieBag.slotGiftCampaigns,
    },
    overallLimit:
      (goodieBag.quantityOfCreated || 0) + (goodieBag.overallAmount || 0),
    assignCreditPerLink: Object.keys(goodieBag?.slotCashCampaigns || {})
      .map(
        (key, index) =>
          `${goodieBag.assignReward?.[key] || 'unlimited'} for slot ${
            index + 1
          }`,
      )
      .join('\n'),
    assignQuotaPerLink: Object.keys(goodieBag.slotGiftCampaigns || {})
      .map(
        (key, index) =>
          `${goodieBag.assignReward?.[key] || 'unlimited'} for slot ${
            index +
            1 +
            (rewardType === GOODIE_BAG_REWARD_TYPE_OPTIONS.both.value
              ? Object.keys(goodieBag.slotCashCampaigns || {}).length || 0
              : 0)
          }`,
      )
      .join('\n'),
    groups: goodieBag?.groups?.map((item) => `[ID:${item?.pk}] ${item?.name}`),
    remainingGoodiebagLinks: remainingGoodiebagLinks.toString(),
    validGoodiebagLinks: Math.min(
      ...[
        remainingGoodiebagLinks,
        Math.min(...latestValidCoupopnAmount?.stock),
      ],
    ).toString(),
    emptyGoodiebagLinks: (
      remainingGoodiebagLinks -
      Math.min(
        ...[
          remainingGoodiebagLinks,
          Math.min(...latestValidCoupopnAmount?.stock),
        ],
      )
    ).toString(),
  };

  const extraComponments =
    rewardType === GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value
      ? {
          linkedFortuneBagCampaigns: (
            <ListFortuneBagCampaigns
              isPreview={true}
              campaigns={
                Object.values(goodieBag?.slotFortuneBagCampaigns || {})?.[0]
              }
              goodiebagRemainedCount={
                goodieBag?.quantityOfCreated +
                (isDetail ? 0 : goodieBag.overallAmount || 0) -
                goodieBag?.quantityOfAcquired
              }
            />
          ),
        }
      : null;

  return (
    <div className="goodie-bag-step-four-properties">
      <MembershipInfoCard
        title="Properties"
        data={newGoodieBag}
        fields={getGoodieBagPerviewPropertiesFields(
          rewardType,
          isDetail,
          extraComponments,
        )}
      />
    </div>
  );
}

export default StepFourPropertiesSection;
