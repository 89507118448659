import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './TourCardSelectorPrompt.scss';
import BasePrompt from '../base/prompt/BasePrompt';
import CustomSearchInput from '../base/CustomSearchInput';
import { Image } from 'react-bootstrap';
import { createAction, removeElementFromArray } from '../../utils';

function ProductDetail({ item }) {
  return (
    <div className="display-flex-area">
      <Image src={item.coverPhoto} className="tourcard-selector-image" />
      <div className="d-flex flex-column tourcard-info-area">
        <label>{item.title}</label>
        <label className="gray-label">ID: {item.pk}</label>
      </div>
    </div>
  );
}

function TourCardSelectorPrompt(props) {
  const dispatch = useDispatch();

  const defaultValue = props.defaultValue || [];
  const [popSelected, setPopSelected] = useState(defaultValue);
  const [showSelectPopup, setShowSelectPopup] = useState(props.show);

  if (!showSelectPopup && props.show) {
    setShowSelectPopup(true);
  }

  useEffect(() => {
    setPopSelected(props.defaultValue);
  }, [props.defaultValue]);

  return (
    <BasePrompt
      show={showSelectPopup}
      customClass="product-selector-popup"
      title={'Tour Card'}
      closeAction={() => {
        setShowSelectPopup(false);
        props.closeAction();
        props.filterAction('');
      }}
      leftButton={{
        text: 'Cancel',
        action: () => {
          setShowSelectPopup(false);
          props.closeAction();
          props.filterAction('');
        },
      }}
      rightButton={{
        text: 'Done',
        action: () => {
          setShowSelectPopup(false);
          // setSource(productList);
          props.doneAction(popSelected);
          props.filterAction('');
        },
      }}
      otherBody={() => {
        return (
          <>
            <CustomSearchInput
              customClass={'product-selector-popup-searchbar'}
              customIconClass="product-selector-search-icon"
              filterAction={(value) => {
                // setSource(
                //   productList.filter(
                //     (item) =>
                //       item.name.toLowerCase().indexOf(value.toLowerCase()) >= 0,
                //   ),
                // );
                props.filterAction(value);
              }}
              placeholder="Search by Title and ID"
            />

            <div className="product-selector-popup-list">
              {props.productList?.map((item) => {
                return (
                  <div>
                    <label
                      className={`checkbox-container product-selector-checkbox-container`}
                    >
                      <ProductDetail item={item} />
                      <input
                        type="checkbox"
                        checked={
                          popSelected.filter((val) => val.pk === item.pk)
                            .length > 0
                        }
                        onChange={() => {
                          if (
                            popSelected.filter((val) => val.pk === item.pk)
                              .length > 0
                          ) {
                            setPopSelected(
                              popSelected.filter((val) => val.pk !== item.pk),
                            );
                          } else {
                            setPopSelected([...popSelected, item]);
                          }
                        }}
                      />
                      <span className="checkmark product-selector-checkmark"></span>
                    </label>
                  </div>
                );
              })}
            </div>
          </>
        );
      }}
    />
  );
}

export default TourCardSelectorPrompt;
