// home breadcrumb
export const homeBreads = () => {
  return [{ name: 'Home', link: '/' }];
};

//campaign breadcrumb
export const campaignListBreads = () => {
  const homeBreadList = homeBreads();
  homeBreadList.push({ name: 'Campaigns', link: '/campaigns' });
  return homeBreadList;
};

export const createCampaignBreads = () => {
  const camapignListBreadList = campaignListBreads();
  camapignListBreadList.push({
    name: 'Create Campaign',
    link: '/campaigns/create',
  });
  return camapignListBreadList;
};

export const editCampaignBreads = (name) => {
  const camapignListBreadList = campaignListBreads();
  camapignListBreadList.push({
    name: name,
  });
  return camapignListBreadList;
};

export const goodieBagsEntitlementBreads = (id, name) => {
  return [
    { name: 'Home', link: '/' },
    { name: 'Goodie Bags', link: '/goodie_bags' },
    { name: name, link: `/goodie_bags/${id}/` },
    { name: 'Entitlement' },
  ];
};
export const goodieBagsEntitlementTrackingCodeBreads = (
  id,
  name,
  brandName,
) => {
  return [
    { name: 'Home', link: '/' },
    { name: 'Goodie Bags', link: '/goodie_bags' },
    { name: name, link: `/goodie_bags/${id}/` },
    { name: 'Entitlement', link: `/goodie_bags/${id}/entitlement` },
    { name: brandName },
  ];
};

// coupon breadcrumb

export const couponSetBreads = () => {
  const homeBreadList = homeBreads();
  homeBreadList.push({
    name: 'Coupon Sets',
    link: '/coupons',
  });
  return homeBreadList;
};

export const createCouponBreads = (id) => {
  const couponSetBreadList = couponSetBreads();
  couponSetBreadList.push({
    name: `${id ? 'Edit' : 'Create'} Coupon Set`,
    link: '/coupons/create',
  });
  return couponSetBreadList;
};

export const couponSetDetailBreads = (couponSetName, isBack = false) => {
  const couponSetListBreadList = couponSetBreads();
  couponSetListBreadList.push({
    name: couponSetName,
    link: '/coupons/couponSet',
    isBack: isBack,
  });
  return couponSetListBreadList;
};

export const singleCouponBreads = (couponSetName, singleCouponSerialNum) => {
  const couponSetDetailBreadList = couponSetDetailBreads(couponSetName, true);
  couponSetDetailBreadList.push({
    name: singleCouponSerialNum,
    link: '/coupons/couponSet/singleCoupon',
  });
  return couponSetDetailBreadList;
};

// Logo Label breadcrumb
export const LogoLabelBreads = () => {
  const homeBreadList = homeBreads();
  homeBreadList.push({
    name: 'Logo label',
    link: '/logo_label',
  });
  return homeBreadList;
};

export const createLogoLabelBreads = (id, name) => {
  const logoLabelBreadList = LogoLabelBreads();
  logoLabelBreadList.push({
    name: id ? name : 'Create Logo Label',
    link: '/logo_label/create',
  });
  return logoLabelBreadList;
};

// Tour card breadcrumb
export const TourCardBreads = () => {
  const homeBreadList = homeBreads();
  homeBreadList.push({
    name: 'Tour card',
    link: '/tour_cards',
  });
  return homeBreadList;
};

export const createTourCardBreads = (id, name) => {
  const tourCardBreadList = TourCardBreads();
  tourCardBreadList.push({
    name: id ? name : 'Create Tour Card',
    link: '/tour_cards/create',
  });
  return tourCardBreadList;
};

// Terms and conditions breadcrumb
export const termsAndConditionsBreads = () => {
  const homeBreadList = homeBreads();
  homeBreadList.push({
    name: 'Terms & Conditions',
    link: '/terms_and_conditions',
  });
  return homeBreadList;
};

export const createOrEditTermsAndConditionsBreads = (id, name) => {
  const termsAndConditionsList = termsAndConditionsBreads();
  termsAndConditionsList.push({
    name: id ? name : 'Create T & C Template',
  });
  return termsAndConditionsList;
};

// Payment settlement breadcrumb
export const paymentSettlementBreads = () => {
  const homeBreadList = homeBreads();
  homeBreadList.push({
    name: 'Payment settlement',
    link: '/payment_settlement',
  });
  return homeBreadList;
};

export const paymentSettlementReportBreads = (reportOrMerchantName) => {
  const paymentSettlementList = paymentSettlementBreads();
  paymentSettlementList.push({
    name: reportOrMerchantName,
    link: '/payment_settlement/',
  });
  return paymentSettlementList;
};

export const alertDetailBreads = (id, name) => {
  return [
    { name: 'Home', link: '/' },
    { name: 'Messages', link: '/general_alert/' },
    { name: name, link: `/general_alert/${id}/` },
  ];
};

// Coupon Label breadcrumb
export const CouponLabelBreads = () => {
  const homeBreadList = homeBreads();
  homeBreadList.push({
    name: 'Coupon label',
    link: '/coupon_label',
  });
  return homeBreadList;
};

export const createCouponLabelBreads = (id, name) => {
  const couponLabelBreadList = CouponLabelBreads();
  couponLabelBreadList.push({
    name: id ? name : 'Create coupon label',
    link: '/coupon_label/create',
  });
  return couponLabelBreadList;
};
