import React from 'react';
import { useFormContext } from 'react-hook-form';
import { changeSwitch, isShowCouponRelated } from '../../../models/CreateCampaignModel';
import { insertKeyToTranslations } from '../../../utils';
import LabelWithSwitch from '../../base/LabelWithSwitch';
import MarkDownAreaWithTC from '../../base/MarkDownAreaWithTC';

function CreateCampaignStepTwoMarkDownAreaWithTC({
  language,
  followKey,
  resetKey,
  areaTitle,
  titleKey,
  titleName,
  contentKey,
  contentName,
  linkToTCTemplate,
  followTemplateKey,
  getTCListKey,
  TCList,
  defaultFilterTC,
}) {
  const { watch, setValue, getValues } = useFormContext();
  const watchCampaignType = watch('campaignType');
  const languages = watch('selectedLanguages') || [];
  const translations = watch('translations') || {};
  const resetValue = translations?.[language]?.[resetKey];
  const isCouponRelated = isShowCouponRelated(watchCampaignType);
  const isFollow = watch(followKey);

  return (
    <>
      <LabelWithSwitch
        labelText={areaTitle}
        className="create-section-title"
        notUseDefaultLabelStyle
        showSwitch={isCouponRelated}
        checked={isFollow}
        onChange={() => {
          changeSwitch({
            getValues,
            setValue,
            languages,
            key: followKey,
            value: !isFollow,
          });
          if (!isFollow) {
            insertKeyToTranslations({
              language,
              key: resetKey,
              value: true,
              setValue,
              getValues,
            });
          }
        }}
      />
      <MarkDownAreaWithTC
        withoutController={true}
        languages={languages}
        language={language}
        hideTC={isFollow}
        linkToTCTemplate={linkToTCTemplate}
        followTemplateKey={followTemplateKey}
        getTCListKey={getTCListKey}
        defaultFilterTC={defaultFilterTC}
        TCList={TCList}
        areaTitle={null}
        titleKey={titleKey}
        titleName={titleName}
        titleDisabled={isFollow}
        contentKey={contentKey}
        contentName={contentName}
        contentDisabled={isFollow}
        contentExtra={{
          resetInitValue: resetValue,
          resetInit: () => {
            insertKeyToTranslations({
              language,
              key: resetKey,
              value: false,
              setValue,
              getValues,
            });
          }
        }}
      />
    </>
  );
}

export default CreateCampaignStepTwoMarkDownAreaWithTC;