import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import BaseFilter, { FilterTypeEnum } from '../base/BaseFilter';
import { ArrayStringData } from '../../utils';
import {
  COUPON_REDEMPTION_METHOD,
  COUPON_SET_TYPES,
  CampaignType,
  CouponMediumType,
  DisplayCouponMediumType,
} from '../../config/CustomEnums';
import { Tooltip } from 'antd';
import { GOODIE_BAG_REWARD_TYPE_OPTIONS } from '../../models/GoodieBagsModel';
import LabelWithToolTips from '../base/LabelWithToolTips';

export const starredCampaignValue = {
  starredAndActive: 'starredAndActive',
  starredAndInactive: 'starredAndInactive',
  nonStarredAndActive: 'nonStarredAndActive',
};

function SimpleDasnboardFilter({ backAction = () => {} }) {
  const location = useLocation();
  const dispatch = useDispatch();

  const parsedSearch = queryString.parse(location.search);
  const searchCouponMedium = parsedSearch['coupon_medium'] || '';
  const searchStarredCampaign = parsedSearch['starred_campaign'];
  const searchFeaturedCampaign = parsedSearch['featured_campaign'];
  const searchGoodie = parsedSearch['goodie_bag_id'] || '';
  const searchBrand = parsedSearch['brand'] || '';
  const searchCampaignCategory = parsedSearch['campaign_category'] || '';
  const searchAttributeTag = parsedSearch['tag'] || '';
  const searchPublishStatus = parsedSearch['campaign_status'] || '';
  const searchVisiblePeriod = parsedSearch['campaign_visible_period'] || '';

  const searchGoodiebagRewardType =
    parsedSearch['goodie_bag_reward_type'] || '';
  const searchCampaignType = parsedSearch['campaign_type'] || '';

  const { goodieBags, brandList, categories, attributeTags } = useSelector(
    (state) => ({
      goodieBags: state.goodieBags.notPagedAllList,
      brandList: state.brand.notPagedAllList,
      categories: state.campaignCategoryList.notPagedAllList,
      attributeTags: state.attributeTag.notPagedAllList,
    }),
  );

  const [couponMedium, setCouponMedium] = useState(
    ArrayStringData(searchCouponMedium),
  );
  const [isStarredCampaign, setIsStarredCampaign] = useState(
    searchStarredCampaign,
  );
  const [isFeaturedCampaign, setIsFeaturedCampaign] = useState(
    searchFeaturedCampaign,
  );
  const [goodie, setGoodie] = useState(ArrayStringData(searchGoodie));
  const [goodieBagData, setGoodieBagData] = useState();
  const [brand, setBrand] = useState(ArrayStringData(searchBrand));
  const [brandListData, setBrandListData] = useState();
  const [category, setCategory] = useState(
    ArrayStringData(searchCampaignCategory),
  );
  const [categoriesData, setCategoriesData] = useState();
  const [tags, setTags] = useState(ArrayStringData(searchAttributeTag));
  const [tagsData, setTagsData] = useState();
  const [status, setStatus] = useState(searchPublishStatus);
  const [visiblePeriod, setVisiblePeriod] = useState(searchVisiblePeriod);
  const [filterDisabled, setFilterDisabled] = useState(true);

  const [goodiebagRewardType, setGoodiebagRewardType] = useState(
    searchGoodiebagRewardType,
  );
  const [campaignType, setCampaignType] = useState(searchCampaignType);

  const hasValue = (value) => {
    return (
      (value !== null && value !== undefined && value != '') ||
      value?.length > 0
    );
  };

  useEffect(() => {
    if (
      hasValue(couponMedium) ||
      hasValue(isStarredCampaign) ||
      hasValue(isFeaturedCampaign) ||
      hasValue(goodie) ||
      hasValue(brand) ||
      hasValue(category) ||
      hasValue(tags) ||
      hasValue(status) ||
      hasValue(visiblePeriod) ||
      hasValue(goodiebagRewardType) ||
      hasValue(campaignType)
    ) {
      setFilterDisabled(false);
    } else {
      setFilterDisabled(true);
    }
  }, [
    couponMedium,
    isStarredCampaign,
    isFeaturedCampaign,
    goodie,
    brand,
    category,
    tags,
    status,
    visiblePeriod,
    goodiebagRewardType,
    campaignType,
  ]);

  useEffect(() => {
    dispatch({ type: 'goodieBags/getAllList', payload: { isSimplify: true } });
    dispatch({ type: 'brand/getAllList' });
    dispatch({
      type: 'campaignCategoryList/getAllList',
    });
    dispatch({
      type: 'attributeTag/getAllList',
    });
    return () => {
      dispatch({ type: 'goodieBags/clearData' });
      dispatch({ type: 'brand/clearData' });
      dispatch({
        type: 'campaignCategoryList/clearData',
      });
      dispatch({
        type: 'attributeTag/clearData',
      });
    };
  }, []);

  useEffect(() => {
    console.log('attributeTags: ', attributeTags);
    const treeData = [];
    let others = {};
    attributeTags.forEach((item) => {
      const parent = item?.tagKey;
      let parentPK = item?.tagKey?.pk || -1;

      parent.title = (
        <LabelWithToolTips
          title={`[ID:${parent.pk}] ${parent.name}`}
          className="list-tree-title"
        />
      );

      const exsitGroup = treeData.find((group) => group.pk == parentPK);
      if (exsitGroup) {
        exsitGroup.children.push({
          ...item,
          title: (
            <LabelWithToolTips
              title={item.title}
              className="list-tree-sub-title"
            />
          ),
          key: item.pk,
        });
        return;
      }

      treeData.push({
        ...parent,
        key: `group-${parentPK}`,
        children: [
          {
            ...item,
            title: (
              <LabelWithToolTips
                title={item.title}
                className="list-tree-sub-title"
              />
            ),
            key: item.pk,
          },
        ],
      });
    });
    setTagsData(treeData);
  }, [attributeTags]);
  console.log('@177', tagsData);

  const content = [
    {
      title: 'Coupon medium',
      data: [
        { name: 'eWallet', pk: CouponMediumType.EWallet },
        {
          name: 'Physical',
          pk: CouponMediumType.Physical,
        },
        {
          name: 'Both eWallet and Physical',
          pk: CouponMediumType.Both,
        },
      ],
      value: couponMedium,
      setValue: setCouponMedium,
      componment: FilterTypeEnum.choice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Goodie bag reward type',
      data: [
        {
          pk: GOODIE_BAG_REWARD_TYPE_OPTIONS.cashVoucher.value,
          name: 'Cash voucher',
        },
        {
          pk: GOODIE_BAG_REWARD_TYPE_OPTIONS.gift.value,
          name: 'Goodie bag coupon',
        },
        {
          pk: GOODIE_BAG_REWARD_TYPE_OPTIONS.both.value,
          name: 'Both cash voucher and goodie bag coupon',
        },
        {
          pk: GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value,
          name: 'Goodie bag offer (Direct assign)',
        },
      ],
      value: goodiebagRewardType,
      setValue: setGoodiebagRewardType,
      componment: FilterTypeEnum.singleChoice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Campaign type',
      data: [
        { pk: CampaignType.couponsCampaign, name: 'Coupon campaign' },
        { pk: CampaignType.cashVoucherCampaign, name: 'Cash Voucher' },
        { pk: CampaignType.giftCampaign, name: 'Goodie Bag Coupon' },
        {
          pk: CampaignType.fortuneBagForGoodieBagCampaign,
          name: 'Goodie bag offer (Direct assign)',
        },
      ],
      value: campaignType,
      setValue: setCampaignType,
      componment: FilterTypeEnum.singleChoice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Starred campaign',
      data: [
        { name: 'Starred & Active', pk: starredCampaignValue.starredAndActive },
        {
          name: 'Starred & Inactive',
          pk: starredCampaignValue.starredAndInactive,
        },
        {
          name: 'Non-starred & Active',
          pk: starredCampaignValue.nonStarredAndActive,
        },
      ],
      value: isStarredCampaign,
      setValue: setIsStarredCampaign,
      componment: FilterTypeEnum.singleChoice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Featured campaign',
      data: [
        { name: 'Yes', pk: 'true' },
        { name: 'No', pk: 'false' },
      ],
      value: isFeaturedCampaign,
      setValue: setIsFeaturedCampaign,
      componment: FilterTypeEnum.singleChoice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Goodie bag ID',
      data: !!goodieBagData ? goodieBagData : goodieBags,
      value: goodie,
      setValue: setGoodie,
      componment: FilterTypeEnum.searchableChoice,
      placeholder: 'Search by ID, Name',
      renderDisplayName: (item) => `[ID:${item.pk}] ${item.name}`,
      filterAction: (searchKey) => {
        const newData = goodieBags?.filter((item) => {
          const name = item.name?.toLowerCase() || '';
          return (
            name.includes(searchKey?.toLowerCase() || '') ||
            String(item.pk) === searchKey
          );
        });
        setGoodieBagData(newData);
      },
    },
    {
      title: 'Brand',
      data: !!brandListData ? brandListData : brandList,
      value: brand,
      setValue: setBrand,
      componment: FilterTypeEnum.searchableChoice,
      placeholder: 'Search by ID, Name',
      renderDisplayName: (item) => `[ID:${item.pk}] ${item.name}`,
      filterAction: (searchKey) => {
        const newData = brandList?.filter((item) => {
          const name = item.name?.toLowerCase() || '';
          return (
            name.includes(searchKey?.toLowerCase() || '') ||
            String(item.pk) === searchKey
          );
        });
        setBrandListData(newData);
      },
    },
    {
      title: 'Campaign category',
      data: !!categoriesData ? categoriesData : categories,
      value: category,
      setValue: setCategory,
      componment: FilterTypeEnum.searchableChoice,
      placeholder: 'Search by ID, Name',
      renderDisplayName: (item) => `[ID:${item.pk}] ${item.name}`,
      filterAction: (searchKey) => {
        const newData = categories?.filter((item) => {
          const name = item.name?.toLowerCase() || '';
          return (
            name.includes(searchKey?.toLowerCase() || '') ||
            String(item.pk) === searchKey
          );
        });
        setCategoriesData(newData);
      },
    },
    {
      title: 'Tag',
      displayTitle: 'Tag',
      data: tagsData,
      value: tags,
      setValue: setTags,
      componment: FilterTypeEnum.searchable2LevelChoice,
      placeholder: 'Search by ID, Name',
      filterAction: (searchKey) => {
        const newData = tagsData
          ?.filter((item) => {
            const name = item.title.props.title?.toLowerCase() || '';
            return (
              name.includes(searchKey?.toLowerCase() || '') ||
              // item.pk === searchKey ||
              item.children.filter((val) =>
                val.title.props.title
                  .toLowerCase()
                  .includes(searchKey?.toLowerCase() || ''),
              ).length > 0
            );
          })
          ?.map((item) => {
            const name = item.title.props.title?.toLowerCase() || '';
            const categoryMatched = name.includes(
              searchKey?.toLowerCase() || '',
            );

            if (categoryMatched) {
              return item;
            }

            return {
              ...item,
              children: item.children.filter((val) =>
                val.title.props.title
                  .toLowerCase()
                  .includes(searchKey?.toLowerCase() || ''),
              ),
            };
          });
        setTagsData(newData);
      },
    },
    {
      title: 'Campaign status',
      data: [
        { name: 'Unpublished', pk: 'false' },
        { name: 'Published', pk: 'true' },
      ],
      value: status,
      setValue: setStatus,
      componment: FilterTypeEnum.singleChoiceSegment,
    },
    {
      title: 'Campaign visible period',
      data: [
        { name: 'Inbox', pk: 'inbox' },
        { name: 'Push', pk: 'push' },
      ],
      value: visiblePeriod,
      setValue: setVisiblePeriod,
      componment: FilterTypeEnum.dateRange,
    },
  ];

  return (
    <BaseFilter
      content={content}
      backAction={backAction}
      disabled={filterDisabled}
    />
  );
}

export default SimpleDasnboardFilter;
