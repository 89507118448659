import React, { useState, forwardRef } from 'react';
import { Dropdown } from 'react-bootstrap';
import Barcode from 'react-barcode';
import './CouponPartnerBarcode.scss';

const popperConfig = {
  strategy: 'fixed',
  modifiers: [
    {
      name: 'computeStyles',
      options: {
        gpuAcceleration: false,
        adaptive: false,
      },
    },
  ],
};

const CustomToggle = forwardRef(({ children, onClick }, ref) => {
  return (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  );
});

function CouponPartnerBarcode({ value, format }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Dropdown
      show={isOpen}
      drop="right"
      className="coupon-barcode-container"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <Dropdown.Toggle as={CustomToggle}>
        <Barcode
          height={55}
          value={value}
          format={format}
          displayValue={false}
        />
      </Dropdown.Toggle>

      <Dropdown.Menu popperConfig={popperConfig}>
        <Dropdown.Item>
          <div className="hover-barcode-container">
            <label className="hover-barcode-title">Partner Barcode</label>
            <div className="hover-barcode-inner-container">
              <Barcode value={value} format={format} />
            </div>
          </div>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default CouponPartnerBarcode;
