import { BaseHelper } from './BaseGraphQLAPIHelper';

export const getLevels = (payload) => {
  const query = `{
    levels(first: 10, orderBy:"pk") {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          levelName
          translations(first: 10) {
            edges {
              node {
                levelName
              }
            }
          }
          segmentlevelcharacteristicSet(first: 10) {
            edges {
              node {
                conditionGroup {
                  id
                  pk
                  displayOrder
                  segment {
                      pk
                      name
                      customers(first: 10) {
                          edges {
                              node {
                                  id
                                  pk
                                  firstName
                                  lastName
                              }
                          }
                      }
                  }
                }
              }
            }
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};
