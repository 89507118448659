import React, { useEffect, useState } from 'react';
import CustomSwitchButton from '../../base/CustomSwitchButton';
import './CouponCampaignSection.scss';
import ErrorFieldMessage from '../../base/ErrorFieldMessage';
import {
  CampaignErrorHandleField,
  firstError,
} from './CreateCampaignHandleError';
import {
  CustomNumberInputWithUnit,
  CustomTitleLabel,
} from '../../earning/CustomBaseComponments';
import { Controller, useFormContext } from 'react-hook-form';
import { hasError, errorMessage } from '../../base/ErrorFieldMessage';
import SpecificCustomSwitchButton from '../../../containers/merchants/stores/SpecificCustomSwitchButton';
import FilterableDropdownClickButton from '../../base/FilterableDropdownClickButton';
import PerTargetMarketLimitArea from './PerTargetMarketLimitArea';
import NumberInputArea from '../../base/NumberInputArea';
import { connect, useDispatch } from 'react-redux';
import { createAction } from '../../../utils';
import { CampaignType } from '../../../config/CustomEnums';

export const isChannelWithoutMarket = (channel) => {
  if (!channel) {
    return false;
  }
  if (channel?.name == 'e-Solution' || channel?.name == 'WeChat Mini Program') {
    return true;
  }
  return false;
};

function CouponCampaignSection({ allMarketList, couponAcquired }) {
  const dispatch = useDispatch();
  const { watch, setValue, formState } = useFormContext();
  const errors = formState.errors;
  const channels = watch('channels') || [];
  const markets = watch('markets') || [];
  const acquiredCouponsFromThisCampaign = watch(
    'acquiredCouponsFromThisCampaign',
  );
  const channelLimit = watch('channelLimit') || {};
  const linkedCoupon = watch('linkedCoupon') || {};
  const editAllocation = watch('editAllocation');
  const [totalAllocation, setTotalAllocation] = useState(0);
  const [marketFilter, setMarketFilter] = useState({});

  useEffect(() => {
    firstError('name', []);
    getTotalAllocation(channelLimit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dispatch(createAction('targetMarket/getAllList')({ needChannel: true }));
  }, []);

  const getTotalAllocation = (list) => {
    const keys = Object.keys(list || {});
    let available = 0;
    keys.forEach((item) => {
      const limit = list[item]?.overallLimit;
      if (limit) {
        available = Number(available) + Number(limit);
      }
    });
    setTotalAllocation(available);
  };

  const channelLists = (left) => {
    return (
      <div
        className="coupon-limit-area"
        style={{
          border: 'none',
          padding: 'unset',
          paddingBottom: '23px',
          marginTop: '0px',
        }}
      >
        {channels.map((channel, index) => {
          const channelPK = channel.pk ? `channel${channel.pk}` : null;
          const channelAcquired = couponAcquired?.[channelPK]?.acquired || 0;
          return (
            <>
              <div
                style={{
                  background: '#E3E3E3',
                  borderRadius: '10px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '0px 23px',
                  maxWidth: '805px',
                  height: '58px',
                  marginTop: '30px',
                }}
              >
                <label
                  style={{ marginBottom: 'unset' }}
                  className="create-section-label"
                >
                  {channel.name}
                </label>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label
                    style={{
                      fontFamily: 'Mulish',
                      fontStyle: 'normal',
                      fontSize: '16px',
                      textAlign: 'end',
                    }}
                  >
                    Per channel allocated:{' '}
                    <label className="create-section-label">
                      {channelLimit[channel.pk]?.overallLimit || 0}
                    </label>
                  </label>
                </div>
              </div>

              <NumberInputArea
                labelText="Per campaign coupon download limit"
                type="number of coupons"
                valueChange={(value) => {
                  const newChannelLimit = {
                    ...channelLimit,
                    [channel.pk]: {
                      ...channelLimit[channel.pk],
                      name: channel.name,
                      overallLimit: value,
                    },
                  };
                  getTotalAllocation(newChannelLimit);
                  setValue('channelLimit', newChannelLimit, {
                    shouldDirty: true,
                  });
                }}
                defaultValue={channelLimit[channel.pk]?.overallLimit}
                errors={errors}
                errorFieldName={`${channel.pk}-overallLimit`}
                index={`${channel.pk}-overallLimit`}
              />
              <label className="campaign-coupon-tips-messages">
                Acquired: {channelAcquired}, Available to acquired in this
                channel:{' '}
                {(channelLimit[channel.pk]?.overallLimit || 0) -
                  channelAcquired}
              </label>

              {isChannelWithoutMarket(channel) ? (
                <></>
              ) : (
                <>
                  <CustomTitleLabel title="Enable per target market limit" />
                  <CustomSwitchButton
                    defaultChecked={channelLimit[channel.pk]?.enableMarketLimit}
                    onChange={(isChecked) => {
                      const newChannelLimit = {
                        ...channelLimit,
                        [channel.pk]: {
                          ...channelLimit[channel.pk],
                          enableMarketLimit: isChecked,
                        },
                      };
                      setValue('channelLimit', newChannelLimit, {
                        shouldDirty: true,
                      });
                    }}
                  />
                </>
              )}
              {channelLimit[channel.pk]?.enableMarketLimit &&
              !isChannelWithoutMarket(channel) ? (
                <div className="per-target-market-limit-area">
                  <div>
                    {Object.values(
                      channelLimit[channel.pk]?.['perTargetMarketLimit'] || {},
                    )
                      .sort(
                        (firstMarket, secondMarket) =>
                          firstMarket?.indexOf - secondMarket?.indexOf,
                      )
                      ?.map((market) => (
                        <PerTargetMarketLimitArea
                          channel={channel.pk}
                          targetMarket={market?.pk}
                          targetMarketAcquired={
                            couponAcquired[channelPK]?.markets?.[
                              market?.pk ? `market${market?.pk}` : null
                            ]?.acquired
                          }
                          areaTitle={market?.name}
                          deleteButtonAction={() => {
                            delete channelLimit[channel.pk]?.[
                              'perTargetMarketLimit'
                            ]?.[market?.pk];
                            setValue('channelLimit', channelLimit, {
                              shouldDirty: true,
                            });
                          }}
                          perTargetMarketLimitNumber={
                            channelLimit[channel.pk]?.[
                              'perTargetMarketLimit'
                            ]?.[market?.pk]?.['overallLimit']
                          }
                          setPerTargetMarketLimitNumber={(value) => {
                            const newChannelLimit = {
                              ...channelLimit,
                              [channel.pk]: {
                                ...channelLimit[channel.pk],
                                ['perTargetMarketLimit']: {
                                  ...channelLimit[channel.pk]?.[
                                    'perTargetMarketLimit'
                                  ],
                                  [market.pk]: {
                                    ...channelLimit[channel.pk]?.[
                                      'perTargetMarketLimit'
                                    ]?.[market.pk],
                                    ['overallLimit']: value,
                                  },
                                },
                              },
                            };
                            setValue('channelLimit', newChannelLimit, {
                              shouldDirty: true,
                            });
                          }}
                          // defaultChecked={channelLimit[channel.pk]?.["perTargetMarketLimit"]?.[market?.pk]?.["displayLimit"]}
                          // displayNumberInCampaignAction={(value) => {
                          //   const newChannelLimit = {
                          //     ...channelLimit,
                          //     [channel.pk]: {
                          //       ...channelLimit[channel.pk],
                          //       ["perTargetMarketLimit"]: {
                          //         ...channelLimit[channel.pk]?.["perTargetMarketLimit"],
                          //         [market.pk]: {
                          //           ...channelLimit[channel.pk]?.["perTargetMarketLimit"]?.[market.pk],
                          //           ["displayLimit"]: value,
                          //         },
                          //       }
                          //     },
                          //   };
                          //   setValue('channelLimit', newChannelLimit, {
                          //     shouldDirty: true,
                          //   });
                          // }}
                        />
                      ))}
                    {markets
                      ?.filter(
                        // market is in this channel
                        (market) =>
                          market.channels
                            ?.map((item) => item.pk)
                            ?.includes(channel.pk),
                      )
                      ?.filter(
                        // market is selected
                        (market) =>
                          Object.values(
                            channelLimit[channel.pk]?.[
                              'perTargetMarketLimit'
                            ] || {},
                          ).filter((item) => item.pk === market.pk)?.length <=
                          0,
                      )?.length > 0 ? ( //remain market unSelected
                      <PerTargetMarketLimitArea
                        disabled={true}
                        isOtherMarket={true}
                        channel={channel.pk}
                        targetMarket={0}
                        targetMarketAcquired={Object.values(
                          couponAcquired[channelPK]?.['markets'] || {},
                        )
                          ?.filter(
                            (value) =>
                              !Object.keys(
                                channelLimit[channel.pk]?.[
                                  'perTargetMarketLimit'
                                ] || {},
                              ).includes(`${value.pk}`),
                          )
                          ?.map((market) => market.acquired || 0)
                          ?.reduce(
                            (perviousValue, currentValue) =>
                              perviousValue + currentValue,
                            0,
                          )}
                        perTargetMarketLimitNumber={
                          channelLimit[channel.pk]?.['overallLimit'] -
                          Object.values(
                            channelLimit[channel.pk]?.[
                              'perTargetMarketLimit'
                            ] || {},
                          )
                            .map((market) => market.overallLimit || 0)
                            ?.reduce(
                              (perviousValue, currentValue) =>
                                perviousValue + currentValue,
                              0,
                            )
                        }
                        // defaultChecked={channelLimit[channel.pk]?.displayLimit}
                        // displayNumberInCampaignAction={(value) => {
                        //   const newChannelLimit = {
                        //     ...channelLimit,
                        //     [channel.pk]: {
                        //       ...channelLimit[channel.pk],
                        //       displayLimit: value,
                        //     },
                        //   };
                        //   setValue('channelLimit', newChannelLimit, {
                        //     shouldDirty: true,
                        //   });
                        // }}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                  <FilterableDropdownClickButton
                    buttonTitle="+ Add target market"
                    buttonAction={() => {}}
                    buttonClass=""
                    selectChange={(value) => {
                      const newChannelLimit = {
                        ...channelLimit,
                        [channel.pk]: {
                          ...channelLimit[channel.pk],
                          ['perTargetMarketLimit']: {
                            ...channelLimit[channel.pk]['perTargetMarketLimit'],
                            [value?.value?.pk]: {
                              ...value?.value,
                              indexOf:
                                (Object.values(
                                  channelLimit[channel.pk]?.[
                                    'perTargetMarketLimit'
                                  ] || {},
                                )
                                  .sort(
                                    (firstMarket, secondMarket) =>
                                      firstMarket?.indexOf -
                                      secondMarket?.indexOf,
                                  )
                                  ?.pop()?.indexOf || 0) + 1,
                            },
                          },
                        },
                      };
                      setValue('channelLimit', newChannelLimit, {
                        shouldDirty: true,
                      });
                    }}
                    isPromptDropdown={true}
                    options={(channel.pk === null // is e-solution
                      ? allMarketList
                      : markets?.filter(
                          // market is in this channel
                          (market) =>
                            market.channels
                              ?.map((item) => item.pk)
                              ?.includes(channel.pk),
                        )
                    )
                      ?.filter(
                        //marketFilter
                        (market) => {
                          if (marketFilter?.[channel.pk]) {
                            return (
                              market.pk == marketFilter[channel.pk] ||
                              market.name
                                ?.toLowerCase()
                                ?.includes(
                                  marketFilter[channel.pk]?.toLowerCase(),
                                )
                            );
                          }
                          return true;
                        },
                      )
                      ?.filter(
                        // market is selected
                        (market) =>
                          Object.values(
                            channelLimit[channel.pk]?.[
                              'perTargetMarketLimit'
                            ] || {},
                          ).filter((item) => item.pk === market.pk)?.length <=
                          0,
                      )
                      ?.map((item) => ({
                        label: `[ID:${item.pk}] ${item.name}`,
                        value: item,
                      }))}
                    needFilter={true}
                    customClass="per-target-market-limit-dropdown"
                    onToggle={() => {}}
                    loadMoreAction={() => {}}
                    filterAction={(value) => {
                      setMarketFilter({
                        ...marketFilter,
                        [channel.pk]: value,
                      });
                    }}
                    placeholder="Search by ID or Name"
                  />
                </div>
              ) : (
                <>
                  {/* <CustomTitleLabel title="Display the remaining number of coupons in the campaign" />
                  <CustomSwitchButton
                    defaultChecked={channelLimit[channel.pk]?.displayLimit}
                    onChange={(isChecked) => {
                      const newChannelLimit = {
                        ...channelLimit,
                        [channel.pk]: {
                          ...channelLimit[channel.pk],
                          displayLimit: isChecked,
                        },
                      };
                      setValue('channelLimit', newChannelLimit, {
                        shouldDirty: true,
                      });
                    }}
                  /> */}
                </>
              )}
            </>
          );
        })}
      </div>
    );
  };

  const allocationArea = () => {
    if (!editAllocation) {
      return;
    }
    const left =
      (linkedCoupon?.totalNubmerOfAvailableCoupons || 0) -
      (totalAllocation || 0);
    return (
      <>
        <div className="create-option-label-top-space" />
        <div className="campagin-coupon-allocation">
          Available coupons:{' '}
          <span className="campagin-coupon-allocation-number">
            {linkedCoupon?.totalNubmerOfAvailableCoupons || 0}
          </span>
        </div>
        <div className="campaign-coupon-tips-messages">
          Available single coupons = Total single coupon - minus the sum of
          (Total acquired coupon + expired coupon + deactivated coupon )
          <br />
          Total single coupons:{' '}
          {linkedCoupon?.totalNubmerOfGeneratedCoupons || 0}
          <br />
          Total acquired coupons from the linked coupon set:{' '}
          {linkedCoupon?.totalNubmerOfAcquiredCoupons || 0}
          <br />
          <li>
            Acquired coupons from this campaign:{' '}
            {acquiredCouponsFromThisCampaign || 0}
          </li>
          Expired coupons: {linkedCoupon?.totalNubmerOfExpiredCoupons || 0}
          <br />
          Deactivated coupons:{' '}
          {linkedCoupon?.totalNubmerOfDeactivatedCoupons || 0}
        </div>
        <div className="create-option-label-top-space" />
        <label className="campagin-coupon-allocation">
          Total assigned limit in this campaign : {totalAllocation || 0}
        </label>
        <label className="campaign-coupon-tips-messages">
          For channels and target markets which has no limit assigned, user is
          able to acquired all the available coupons from them.
        </label>
        {channelLists(left)}
      </>
    );
  };

  return (
    <>
      <label className="create-section-title">
        Per CHANNEL and Target market ALLOCATION(OPtional)
      </label>
      <CustomTitleLabel title="Edit allocation" />
      <SpecificCustomSwitchButton
        checked={editAllocation}
        onChange={() => {
          setValue('editAllocation', !editAllocation, { shouldDirty: true });
        }}
      />
      {allocationArea()}
      {/* <label className="tips-message">
        The overall limit should not be greater than {linkedCoupon?.stock}.
      </label> */}
    </>
  );
}

const mapPropsToState = (state) => ({
  allMarketList: state.targetMarket.notPagedAllList,
  couponAcquired: state.createCampaign.couponAcquired,
});

export default connect(mapPropsToState)(CouponCampaignSection);

export const PerHeadSection = () => {
  const { watch, setValue, formState } = useFormContext();
  const errors = formState.errors;
  const perHeadDownloadLimit = watch('perHeadDownloadLimit');
  const perHeadOwnedLimit = watch('perHeadOwnedLimit');
  const perHeadLimit = watch('perHeadLimit');
  const linkedCoupon = watch('linkedCoupon') || {};
  const perHeadDownloadForEachTime = watch('perHeadDownloadForEachTime');

  return (
    <>
      <label className="create-section-title">Per Head limit</label>
      <div className="coupon-limit-area">
        <CustomNumberInputWithUnit
          title="Per head coupon download limit during the campaign active period (optional) "
          setValue={(value) => {
            setValue('perHeadLimit', value, { shouldDirty: true });
          }}
          max={linkedCoupon?.stock}
          defaultValue={perHeadLimit}
          unit="number of coupons"
          error={hasError(errors, 'perHeadLimit')}
          errorMessage={errorMessage(errors, 'perHeadLimit')}
        />

        <CustomNumberInputWithUnit
          title="Per head coupon download limit (for each action)"
          setValue={(value) => {
            setValue('perHeadDownloadLimit', value, { shouldDirty: true });
          }}
          defaultValue={perHeadDownloadLimit}
          unit="max coupon can be download per call [X number can be download]"
          errorId="perHeadDownloadLimit"
          error={hasError(errors, 'perHeadDownloadLimit')}
          errorMessage={errorMessage(errors, 'perHeadDownloadLimit')}
          others={{ value: perHeadDownloadLimit }}
        />
        <CustomNumberInputWithUnit
          {...(hasError(errors, 'perHeadDownloadLimit')
            ? { customContainerClass: 'per-head-download-for-each-time' }
            : {})}
          unit="per 1 amount in front end = x number of coupons added to My Wallet"
          setValue={(value) => {
            setValue('perHeadDownloadForEachTime', value, {
              shouldDirty: true,
            });
          }}
          defaultValue={perHeadDownloadForEachTime}
          errorId={'perHeadDownloadForEachTime'}
          error={hasError(errors, 'perHeadDownloadForEachTime')}
          errorMessage={errorMessage(errors, 'perHeadDownloadForEachTime')}
          others={{ min: 1 }}
        />

        <CustomNumberInputWithUnit
          title="Per head owned coupon limit (optional)"
          setValue={(value) => {
            setValue('perHeadOwnedLimit', value, { shouldDirty: true });
          }}
          defaultValue={perHeadOwnedLimit}
          unit="number of coupons"
          errorId={'perHeadOwnedLimit'}
          error={hasError(errors, 'perHeadOwnedLimit')}
          errorMessage={errorMessage(errors, 'perHeadOwnedLimit')}
        />
      </div>
    </>
  );
};


export const FortuneBagPerHeadSection = () => {
  const { control, setValue, formState } = useFormContext();
  const errors = formState.errors;
  console.log('@@FortuneBagPerHeadSection: ', errors);
  return (
    <Controller
      control={control}
      name="goodieBagReward"
      render={({ field: { value } }) => (
        <>
          <label className="create-section-title">Per entitlement</label>
          <CustomTitleLabel
            title={'Number of coupon can be acquired per entitlement'}
            className={'mb-4'}
          />

          <CustomNumberInputWithUnit
            setValue={(value) => {
              setValue('goodieBagReward', value, {
                shouldDirty: true,
              });
            }}
            defaultValue={value}
            unit="number of coupon(s)"
            errorId={'goodieBagReward'}
            error={hasError(errors, 'goodieBagReward')}
            errorMessage={errorMessage(errors, 'goodieBagReward')}
          />
        </>
      )}
    />
  );
};
