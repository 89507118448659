import { ERROR_MESSAGE } from '../../../utils';

export const CouponErrorFields = {
  generalName: {
    name: 'generalName',
    message: 'Please provide a coupon name and must less than 200.',
  },
  shortDescription: {
    name: 'shortDescription',
    message: 'Please provide a short description.',
  },
  coverPhotoEmpty: {
    name: 'coverPhoto',
    message: 'Please provide a cover photo.',
  },
  endDatetime: {
    name: 'couponTemplateEndDatetime',
    message:
      'The end date & time of a coupon cannot be on or before the start date and time.',
  },
  relativeDay: {
    name: 'couponTemplateRelativeDay',
    message: 'Please provide required days.',
  },
  couponStock: {
    name: 'couponStockField',
    message: 'This field is required',
  },
  transactionAmountSurvey: {
    name: 'transactionAmountSurvey',
    message: 'Please provide a survey form.',
  },
  lowStockMethod: {
    name: 'transactionAmountSurvey',
    message: 'Please provide a low stock method.',
  },
  lowStockValueNull: {
    name: 'lowStockValue',
    message: ERROR_MESSAGE.MISS_FIELD,
  },
  lowStockValueLessThanZero: {
    name: 'lowStockValue',
    message:
      'Please input the correct format. The value should be within 0~100. ',
  },
  lowStockValuePercentageRange: {
    name: 'lowStockValue',
    message:
      'Please input the correct format. The value should be within 0~100.',
  },
  barcodeFormat: {
    name: 'barcodeFormat',
    message: 'Please select one barcode format.',
  },
  dateRules: {
    name: 'couponTemplateEndDatetime',
    message: "Please select a date and time within the 'Coupon valid period'.",
  },
  nonRules: {
    name: 'nonRules',
    message: 'Please add at least one time rule.',
  },
};
