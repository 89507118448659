import React, { useState, useEffect } from 'react';
import '../../components/base/CustomListComponent.scss';
import './BaseListContainer.scss';
// import './EarningruleListWithType.scss';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  createAction,
  removeElementFromArray,
  checkHasPermission,
  getFileNameFromUrl,
} from '../../utils';
import { ActionsDropdownForItem } from '../../components/base/ActionsDropdown';
import { CustomStatusTagFromText } from '../../components/base/CustomStatusTag';
import CustomPagination from '../../components/base/CustomPagination';
import {
  APIStatus,
  StatusTag,
  MessageTag,
  DisplayPaymentSettlementReviewStatusType,
} from '../../config/CustomEnums';
import Loading from '../../components/base/Loading';
import NoDataPage from '../../components/base/NoDataPage';
import DeletePrompt from '../../components/base/DeletePrompt';
import CustomEditableRank from '../../components/base/CustomEditableRank';
import CustomListCheckbox from '../../components/base/CustomListCheckbox';
import CustomLinkableName from '../../components/base/CustomLinkableName';
import { SortableTableBody } from '../../components/base/SortableTable';
import DisplayOrderTableData from '../../components/base/DisplayOrderTableData';
import { PermissionCodes } from '../../config/PermissionCodes';
import PreviewImage from '../../components/base/prompt/PreviewImage';
import QRCodeCollapse from '../../containers/merchants/stores/QRCodeCollapse';
import CustomViewer from '../../components/base/CustomViewer';
import { COUPON_STATUS } from '../../models/CouponListModel';
// import { URLFilterKeys } from '../CampaignUrlConfig';
import QRCode from 'react-qr-code';
import CouponQrCode from '../engagement/coupon/CouponQrCode';
import CountryCodesPrompt from '../../components/banners/CountryCodesPrompt';
import LabelWithToolTips from '../../components/base/LabelWithToolTips';

const showdown = require('showdown');
const converter = new showdown.Converter();

function checkHasField(fields, name) {
  for (let i = 0; i < fields?.length; i++) {
    if (fields[i].displayName === name) {
      return true;
    }
  }
  return false;
}

function getCommonFieldsCount(fields) {
  let count = 0;

  for (let i = 0; i < fields.length; i++) {
    if (
      ['ID', 'Name', 'Name (preferred name)', 'Status'].indexOf(
        fields[i].displayName,
      ) < 0
    ) {
      count = count + 1;
    }
  }

  return count;
}

function BaseListContainer(props) {
  const [checkedList, setCheckedList] = useState([]);
  const [top, setTop] = useState(0);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const dataList = props.dataList || [];
  const noRecordView = props.noRecordView;

  const {
    totalPage,
    model,
    fields = [],
    deleteInfo,
    specificActions,
    customActions,
    extraRankStyle,
    hasOtherActions,
    actions = ['Edit', 'Detail', 'Delete'],
    customDeleteAction = () => {},
    sortable = false,
    onSortEnd,
    permissionGroup,
    onOrderChangedByEdit,
    sortDisabled,
    rankDisabled = false,
    hideCheckbox = false,
    onPageChange,
    showTableLeftShadow = true,
    showTableRightShadow = true,
    showPlaceholderAction = false,
    hidePagination = false,
  } = props;
  const hideActions = props.hideActions || false;
  const useCustomCommonActions = props.useCustomCommonActions;
  const customCommonActions = props.customCommonActions || (() => {});
  const customClassName = props.customClassName || '';
  const queryString = require('query-string');
  const { status } = useSelector((state) => {
    return {
      status: state.loading.status,
    };
  });

  const [showPrompt, setShowPrompt] = useState(false);
  const [operatedItem, setOperatedItem] = useState();
  const [tableStartReach, setTableStartReach] = useState(true);
  const [tableEndReach, setTableEndReach] = useState(false);
  const searchQuery = queryString.parse(location.search);
  const currentPage = searchQuery['page']
    ? parseInt(searchQuery['page']) - 1
    : 0;
  const [enlargeImage, setEnlargeImage] = useState();
  const [disabledSort, setDisabledSort] = useState(sortDisabled);

  const [isOpenCountryCodesPrompt, setIsOpenCountryCodesPrompt] =
    useState(false);
  const [selectedItem, setSelectedItem] = useState();

  const { user } = useSelector((state) => ({
    user: state.users,
  }));

  useEffect(() => {
    setCheckedList([]);
    dispatch({
      type: `${model}/updateState`,
      payload: { checkedList: [] },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const AllActions = [
    {
      name: 'Edit',
      action: (item) => {
        dispatch({ type: `${model}/clearData` });
        return history.push(
          `${location.pathname}/${item.rateLimitType || item.pk}/edit/`,
        );
      },
      requires: `change_${permissionGroup}`,
    },
    {
      name: 'Detail',
      action: (item) => {
        dispatch({ type: `${model}/clearData` });
        return history.push(
          `${location.pathname.replace(/\/$/g, '')}/${item.pk}/`,
        );
      },
      requires: `view_${permissionGroup}`,
    },
    {
      name: 'Delete',
      action: (item) => {
        setOperatedItem(item);
        setShowPrompt(true);
        dispatch({
          type: `${model}/updateState`,
          payload: { checkedList: [item] },
        });
        customDeleteAction && customDeleteAction();
      },
      requires: `delete_${permissionGroup}`,
    },
    {
      name: 'Duplicate',
      action: (item) => {
        dispatch(
          createAction(`${model}/duplicate`)({
            data: item,
            afterAction: () => history.push(location),
          }),
        );
      },
      requires: `add_${permissionGroup}`,
    },
  ];

  /**
   *
   * @param {*} item
   * @returns array
   */

  const getSpecificActions = (item) => {
    const result = [];
    if (!specificActions?.length) {
      return result;
    }
    specificActions.forEach((action) => {
      const conditionFunc = action.condition || (() => {});
      const condition = conditionFunc(item) || false;
      const actionButtons = action?.buttons || [];
      if (condition && actionButtons.length) {
        result.push(...actionButtons);
      }
    });
    return result;
  };

  const getNormalActions = (item) => {
    let filteredActions = AllActions.filter((val) =>
      actions.includes(val.name),
    );
    if (item.status) {
      switch (item.status) {
        case MessageTag.sent:
          filteredActions = filteredActions.filter(
            (val) => val.name !== 'Edit',
          );
          break;

        case StatusTag.deleted:
          filteredActions = filteredActions.filter(
            (val) => val.name !== 'Edit' && val.name !== 'Delete',
          );
          break;

        default:
          break;
      }
    }
    const filteredSpecificActions = getSpecificActions(item);
    filteredActions.push(...filteredSpecificActions);
    return filteredActions;
  };

  const otherActions = (data) => {
    if (
      !hasOtherActions ||
      data.status === StatusTag.acquired ||
      data.status === COUPON_STATUS.VOID ||
      data.handledLeftover
    ) {
      return null;
    }

    let actions = [];
    if (
      customActions.deactive &&
      data.status !== StatusTag.linkedGoodieBag &&
      data.status !== StatusTag.deleted
    ) {
      actions.push({
        name: `${
          data.status === StatusTag.active ||
          data.status === StatusTag.activeRequired ||
          data.status === StatusTag.linkedFortuneBag
            ? 'Deactivate'
            : 'Activate'
        }`,
        action: (item) => {
          console.log('create action to deactive item: ', item);
          customActions.deactive(item);
        },
        requires: `change_${permissionGroup}`,
      });
    }

    if (customActions.schedule) {
      actions.push({
        name: data.isActive ? 'Deactivate' : 'Activate',
        action: (item) => {
          customActions.schedule(item);
        },
        requires: `change_${permissionGroup}`,
      });
    }

    if (
      customActions.publish &&
      (customActions.publish?.condition === undefined ||
        (customActions.publish?.condition &&
          customActions.publish?.condition(data)))
    ) {
      actions.push({
        name: data.isPublished ? 'UnPublish' : 'Publish',
        action: (item) => {
          customActions.publish.action(item);
        },
        requires:
          customActions.publish?.requires || PermissionCodes.changeCampaign,
      });
    }

    if (customActions.moveToFirst) {
      actions.push({
        name: 'Move to first',
        action: (item) => {
          customActions.moveToFirst(item);
        },
        requires: `change_${permissionGroup}`,
      });
    }

    if (customActions.moveToLast) {
      actions.push({
        name: 'Move to last',
        action: (item) => {
          customActions.moveToLast(item);
        },
        requires: `change_${permissionGroup}`,
      });
    }

    if (customActions.paymentSettlementAgree) {
      if (
        data.displayStatus != DisplayPaymentSettlementReviewStatusType.agree
      ) {
        actions.push({
          name: 'Agree',
          action: (item) => {
            customActions.paymentSettlementAgree(item);
          },
          requires: PermissionCodes.changePaymentSettlement,
        });
      }
    }

    if (customActions.paymentSettlementDisagree) {
      if (
        data.displayStatus == DisplayPaymentSettlementReviewStatusType.waiting
      ) {
        actions.push({
          name: 'Disagree',
          action: (item) => {
            customActions.paymentSettlementDisagree(item);
          },
          requires: PermissionCodes.changePaymentSettlement,
        });
      }
    }

    return actions;
  };

  const stringSort = queryString.parse(location.search)['sort'] || '';
  let sorts = [];
  if (typeof stringSort === 'string' && stringSort) {
    sorts = stringSort.split(',');
  }

  const orientationForSort = (columnName) => {
    const sortKey = sorts.find((sort) => sort.includes(columnName));
    if (!sortKey || !sortKey?.includes('-')) {
      return false;
    }
    return true;
  };

  function iterate(relate, newSorts) {
    const otherSearchIndex = newSorts.findIndex((item) => relate === item);
    newSorts.splice(otherSearchIndex, 1);
  }

  const reDefineSort = (columnName, relatedOrderField) => {
    let newSorts = sorts?.filter((item) => item.length > 0) || [];
    const sortIndex = newSorts.findIndex((sort) => sort.includes(columnName));
    const sort = newSorts[sortIndex];
    if (sortIndex > -1) {
      newSorts.splice(sortIndex, 1);
    }
    if (relatedOrderField && relatedOrderField.length > 0) {
      relatedOrderField.forEach((item) => iterate(item, newSorts));
      newSorts.unshift(...relatedOrderField);
    }
    if (sort?.includes('-')) {
      newSorts.unshift(columnName);
    } else {
      newSorts.unshift(`-${columnName}`);
    }
    return newSorts.toString();
  };

  const checkAll = () => {
    let list = dataList;
    if (dataList.length === checkedList.length) {
      list = [];
    }
    dispatch({
      type: `${model}/updateState`,
      payload: { checkedList: list },
    });
    setCheckedList(list);
  };

  const itemCheck = (item) => {
    const list = [];
    let processed = false;
    checkedList.forEach((checkedItem) => {
      if (checkedItem.pk !== item.pk) {
        list.push(checkedItem);
      } else {
        processed = true;
      }
    });
    if (!processed) {
      list.push(item);
    }
    setCheckedList(list);
    dispatch({
      type: `${model}/updateState`,
      payload: { checkedList: list },
    });
  };

  const fieldsHasStatus =
    checkHasField(fields, 'Status') || checkHasField(fields, 'Review status');
  const fieldClassName = (fieldName) => {
    let className = '';
    switch (fieldName) {
      case 'ID':
        className = 'rank-area-extra-width';
        break;
      case 'Name':
      case 'Name (preferred name)':
        // case 'Membership ID':
        className = fieldsHasStatus
          ? 'common-name-area-with-status'
          : 'common-name-area-without-status';
        break;
      case 'Review status':
      case 'Status':
        className = 'common-status-area';
        break;
      case 'Scheduled Status':
        className = 'common-status-area';
        break;
      case 'Display order':
        className = 'common-status-order';
        break;
      default:
        className = fieldsHasStatus
          ? 'common-name-area-with-status-count-'
          : 'common-name-area-without-status-count-';
        const commonFieldsCount = getCommonFieldsCount(fields);
        className = `${className}${commonFieldsCount}`;
        break;
    }

    return className;
  };
  const getTableTitles = () => {
    return (
      <tr>
        {hideCheckbox ? null : (
          <th key={0}>
            <CustomListCheckbox
              checked={
                checkedList.length && checkedList.length === dataList.length
              }
              onChange={() => checkAll()}
            />
          </th>
        )}
        {fields.map((item, index) => {
          if (item.orderField) {
            return (
              <th key={index + 1} className={fieldClassName(item.displayName)}>
                <CustomEditableRank
                  rank={orientationForSort(item.orderField)}
                  show={
                    item?.show === false
                      ? false
                      : searchQuery['sort']?.indexOf(item.orderField) >= 0 ||
                        (item.orderField === 'displayPriority' &&
                          Object.keys(searchQuery).length <= 0)
                  }
                  title={item.displayName}
                  extraAreaStyle={'base-rank-container'}
                  extraRankStyle={extraRankStyle}
                  onClick={(rank) => {
                    history.push({
                      pathname: location.pathname,
                      hash: location.hash,
                      search: queryString.stringify({
                        ...queryString.parse(location.search),
                        ...{
                          page: 1,
                          rank: rank,
                          sort: reDefineSort(
                            item.orderField,
                            item.relatedOrderField,
                          ),
                        },
                      }),
                    });
                  }}
                />
              </th>
            );
          }

          return (
            <th key={index + 1} className={fieldClassName(item.displayName)}>
              <div
                className={`all-list-scrollable-area-table-item-common all-list-scrollable-area-title `}
                style={item.style || {}}
              >
                {item.displayName}
              </div>
            </th>
          );
        })}
        {hideActions ? null : (
          <th key={fields.length + 2} className="common-action-area">
            <div
              className={`all-list-scrollable-area-table-item-common all-list-scrollable-area-title all-list-scrollable-area-action-title`}
            >
              Actions
            </div>
          </th>
        )}
        {/* {showPlaceholderAction ? (
          <th className="all-list-scrollable-area-table-item-common all-list-scrollable-area-title all-list-scrollable-area-empty-column"></th>
        ) : null} */}
      </tr>
    );
  };

  const renderRowData = (item) => {
    return (
      <tr>
        {hideCheckbox ? null : (
          <td className="all-list-scrollable-area-checkbox-container">
            <CustomListCheckbox
              checked={
                checkedList.find(
                  (checkedItem) => checkedItem.pk === item.pk,
                ) !== undefined
              }
              onChange={() => itemCheck(item)}
            />
          </td>
        )}
        {fields.map((field, index) => {
          switch (field.fieldName) {
            case 'pk':
            case 'membershipId':
              if (
                field.linked &&
                checkHasPermission(user, `view_${permissionGroup}`)
              ) {
                return (
                  <td className="all-list-scrollable-area-id-container">
                    <div
                      className={`all-list-scrollable-area-table-item-common all-list-scrollable-area-id-container ${
                        field.fieldName === 'membershipId'
                          ? 'all-list-scrollable-area-membershipid-container'
                          : ''
                      }`}
                    >
                      <Link
                        to={
                          field.fieldName === 'membershipId'
                            ? `customers/${item.pk}/`
                            : `${location.pathname}/${item.pk}/`
                        }
                        className="all-list-scrollable-area-text-common all-list-scrollable-area-clickable-field"
                      >
                        {field.fieldName === 'membershipId'
                          ? item[field.fieldName] || '-'
                          : item.pk || '-'}
                      </Link>
                    </div>
                  </td>
                );
              }
              return (
                <td
                  index={`${index}-${field.fieldName}`}
                  className="all-list-scrollable-area-id-container"
                >
                  <div
                    className={`all-list-scrollable-area-table-item-common all-list-scrollable-area-id-container ${
                      field.fieldName === 'membershipId'
                        ? 'all-list-scrollable-area-membershipid-container'
                        : ''
                    }`}
                  >
                    <label className="all-list-scrollable-area-text-common all-list-scrollable-area-unclickable-field">
                      {item[field.fieldName] || '-'}
                    </label>
                  </div>
                </td>
              );
            case 'reportName':
              if (field.list == false) {
                return (
                  <td className="all-list-scrollable-area-name">
                    <CustomLinkableName
                      to={`/payment_settlement/detail/${item.pk}/`}
                      text={item[field.fieldName]}
                    />
                  </td>
                );
              }
              return (
                <td className="all-list-scrollable-area-name">
                  <CustomLinkableName
                    to={`${location.pathname}/byMonthly/${item.month}/`}
                    text={item[field.fieldName]}
                  />
                </td>
              );
            case 'merchantAdministrators':
              if (field.list == false) {
                return (
                  <td className="all-list-scrollable-area-name">
                    <CustomLinkableName
                      to={`/payment_settlement/detail/${item.pk}/`}
                      text={item[field.fieldName]}
                    />
                  </td>
                );
              }
              return (
                <td className="all-list-scrollable-area-name">
                  <CustomLinkableName
                    to={`${location.pathname}/byMerchant/${item.brand}/`}
                    text={item[field.fieldName]}
                  />
                </td>
              );
            case 'paymentSettlementComment':
              return (
                <td className="all-list-scrollable-area-name">
                  <textarea
                    rows="3"
                    cols="25"
                    readOnly
                    className="payment-settlement-comment-area"
                  >
                    {item[field.fieldName][0] || '-'}
                  </textarea>
                </td>
              );
            case 'name':
            case 'title':
            case 'linkedCoupon':
            case 'rateLimitType':
              let linked = field.linked;
              if (linked === 'followValue') {
                linked = item[field.fieldName] ? true : false;
              }

              if (
                linked === false ||
                !checkHasPermission(
                  user,
                  `${
                    actions.includes('Detail') ? 'view' : 'change'
                  }_${permissionGroup}`,
                )
              ) {
                return (
                  <td className="all-list-scrollable-area-name">
                    <div className="all-list-scrollable-area-name all-list-scrollable-area-table-item-common">
                      <label className="all-list-scrollable-area-text-common list-new-line">
                        {item[field.fieldName] || '-'}
                      </label>
                    </div>
                  </td>
                );
              }
              const to = field.linkedPathField
                ? item[field.linkedPathField]
                : `${location.pathname.replace(/\/$/g, '')}/${
                    field.fieldName === 'rateLimitType'
                      ? item.rateLimitType
                      : item.pk
                  }/${actions.includes('Detail') ? '' : 'edit/'}`;

              return (
                <td className="all-list-scrollable-area-name">
                  <CustomLinkableName
                    to={to} //need change link
                    text={item[field.fieldName]}
                    isHtml={field.isHtml}
                  />
                </td>
              );
            case 'status':
            case 'displayStatus':
            case 'scheduledStatus':
            case 'displayScheduledStatus':
            case 'isHighValueCoupon':
            case 'displayRedirection':
            case 'landingPageTypeDisplay':
            case 'campaignIsStarred':
            case 'transactionBy':
              return (
                <td className="common-status-area all-list-scrollable-area-table-item-common">
                  {CustomStatusTagFromText(item[field.fieldName])}
                </td>
              );
            case 'coverPhoto':
            case 'mobilePhoto':
            case 'icon':
            case 'displayImage':
              return (
                <td className="common-status-area">
                  <div style={{ padding: '20px 0px' }}>
                    {item[field.fieldName] ? (
                      <img
                        alt="cover"
                        className="base-display-item-photo"
                        src={item[field.fieldName]}
                        style={{
                          width: 100,
                          height: 100,
                          border: '1px solid #DADADA',
                        }}
                        onClick={
                          field.enlarge
                            ? () => {
                                setEnlargeImage(item[field.fieldName]);
                              }
                            : () => {}
                        }
                      />
                    ) : (
                      '-'
                    )}
                  </div>
                </td>
              );
            case 'codeDisplayImage':
              return (
                <td className="common-status-area">
                  <div style={{ padding: '20px 0px' }}>
                    <QRCodeCollapse {...item} />
                  </div>
                </td>
              );
            case 'order':
            case 'displayPriority':
              return (
                <DisplayOrderTableData
                  item={item}
                  orderKey={field.fieldName}
                  onDone={onOrderChangedByEdit}
                  editable={
                    checkHasPermission(user, `change_${permissionGroup}`) &&
                    (searchQuery['sort']?.indexOf('displayPriority') === 0 ||
                      (field.orderField === 'displayPriority' &&
                        Object.keys(searchQuery).length <= 0))
                  }
                />
              );
            case 'relatedCouponsNameList':
            case 'relatedCampaignsNameList':
            case 'marketDisplayRelatedCampaigns':
            case 'appliedCouponSet':
            case 'appliedCampaign':
              const dataList = item[field.fieldName] || [];
              const count = dataList.length || 0;
              if (count === 0) {
                return (
                  <td>
                    <div className="all-list-scrollable-area-table-item-common">
                      <label className="all-list-scrollable-area-text-common list-new-line">
                        -
                      </label>
                    </div>
                  </td>
                );
              }
              return (
                <td>
                  <div className="all-list-scrollable-area-table-item-common">
                    {dataList.slice(0, 2).map((data) => (
                      <label
                        className={`all-list-scrollable-area-text-common list-new-line ${
                          count > 1 ? 'label-lines-1' : ''
                        }`}
                      >
                        {data || '-'}
                      </label>
                    ))}
                  </div>
                </td>
              );
            case 'qrCodeString':
              return (
                <td className="all-list-scrollable-area-name">
                  <div className="all-list-scrollable-area-name all-list-scrollable-area-table-item-common">
                    <label className="all-list-scrollable-area-text-common list-new-line">
                      {item[field.fieldName] ? (
                        <CouponQrCode
                          value={item[field.fieldName]}
                          srkValue={item[field?.referenceFieldName]}
                        />
                      ) : (
                        '-'
                      )}
                    </label>
                  </div>
                </td>
              );
            case 'displayCountryCodes':
              return (
                <td>
                  {item[field.fieldName] ? (
                    <CustomLinkableName
                      onClick={(e) => {
                        e.preventDefault();
                        setDisabledSort(true);
                        setIsOpenCountryCodesPrompt(true);
                        setSelectedItem(item);
                      }}
                      text={item[field.fieldName]}
                      isHtml={field.isHtml}
                    />
                  ) : (
                    <div
                      className={`all-list-scrollable-area-table-item-common`}
                    >
                      -
                    </div>
                  )}
                </td>
              );
            case 'displayType':
              return (
                <td>
                  <div
                    className={`all-list-scrollable-area-table-item-common`}
                    style={{
                      ...(field.lines
                        ? {
                            padding: '0px 20px',
                            height: '110px',
                            display: 'contents',
                          }
                        : {}),
                      ...(field.style || {}),
                    }}
                  >
                    <LabelWithToolTips
                      title={item[field.fieldName] || '-'}
                      className={`all-list-scrollable-area-text-common list-new-line test`}
                    />
                  </div>
                </td>
              );
            case 'changeTo':
              if (item[field.fieldName]?.startsWith('http')) {
                return (
                  <td className="all-list-scrollable-area-id-container">
                    <div
                      className={`all-list-scrollable-area-table-item-common all-list-scrollable-area-id-container ${
                        field.fieldName === 'membershipId'
                          ? 'all-list-scrollable-area-membershipid-container'
                          : ''
                      }`}
                    >
                      <a
                        href={item[field.fieldName]}
                        className="all-list-scrollable-area-text-common all-list-scrollable-area-clickable-field"
                      >
                        {getFileNameFromUrl(item[field.fieldName])}
                      </a>
                    </div>
                  </td>
                );
              }
              return (
                <td>
                  <div
                    className={`all-list-scrollable-area-table-item-common`}
                    style={{
                      ...(field.lines
                        ? {
                            padding: '0px 20px',
                            height: '110px',
                            display: 'contents',
                          }
                        : {}),
                      ...(field.style || {}),
                    }}
                  >
                    <label
                      className={`all-list-scrollable-area-text-common ${
                        field.isDate ? 'list-nowrap' : 'list-new-line'
                      } ${field.lines ? 'label-lines-' + field.lines : ''}`}
                    >
                      {item[field.fieldName] || '-'}
                    </label>
                  </div>
                </td>
              );
            default:
              if (field.isTag) {
                return (
                  <td className="common-status-area all-list-scrollable-area-table-item-common">
                    {CustomStatusTagFromText(item[field.fieldName])}
                  </td>
                );
              }
              return (
                <td>
                  <div
                    className={`all-list-scrollable-area-table-item-common`}
                    style={{
                      ...(field.lines
                        ? {
                            padding: '0px 20px',
                            height: '110px',
                            display: 'contents',
                          }
                        : {}),
                      ...(field.style || {}),
                    }}
                  >
                    {field.displayFull ? (
                      <LabelWithToolTips
                        title={item[field.fieldName] || '-'}
                        className={`all-list-scrollable-area-text-common list-new-line test`}
                      />
                    ) : field.isHtml ? (
                      <CustomViewer
                        className={`all-list-scrollable-area-text-common list-new-line ${
                          field.lines ? 'label-lines-' + field.lines : ''
                        }`}
                        initValue={item[field.fieldName]}
                      />
                    ) : (
                      <label
                        className={`all-list-scrollable-area-text-common ${
                          field.isDate ? 'list-nowrap' : 'list-new-line'
                        } ${field.lines ? 'label-lines-' + field.lines : ''}`}
                      >
                        {item[field.fieldName] || '-'}
                      </label>
                    )}
                  </div>
                </td>
              );
          }
        })}
        {hideActions ? null : (
          <td className="all-list-scrollable-area-actions all-list-scrollable-area-table-item-common">
            {useCustomCommonActions ? (
              customCommonActions(item)
            ) : (
              <ActionsDropdownForItem
                object={item}
                actions={getNormalActions(item)}
                otherActions={otherActions(item)}
              />
            )}
          </td>
        )}
        {/* {showPlaceholderAction ? (
          <td className="all-list-scrollable-area-empty-column"></td>
        ) : null} */}
      </tr>
    );
  };

  const renderTableBody = () => {
    if (dataList?.length <= 0 && noRecordView) {
      return noRecordView;
    }
    if (
      sortable &&
      checkHasPermission(user, `change_${permissionGroup}`) &&
      (searchQuery['sort']?.indexOf('displayPriority') === 0 ||
        (fields.filter((field) => field.orderField === 'displayPriority')
          .length > 0 &&
          Object.keys(searchQuery).length <= 0))
    ) {
      return (
        <SortableTableBody
          dataList={dataList}
          disabled={disabledSort}
          onSortEnd={onSortEnd}
          renderRowData={(item) => renderRowData(item)}
        />
      );
    }
    return <tbody>{dataList.map((item, index) => renderRowData(item))}</tbody>;
  };

  // console.log('dataList:', dataList);

  const renderBody = () => {
    if (status === APIStatus.calling) {
      return <Loading />;
    }
    if (dataList?.length <= 0 && !noRecordView) {
      return <NoDataPage />;
    }

    const tableIncludeImage = fields.some(
      (field) =>
        field.fieldName === 'coverPhoto' ||
        field.fieldName === 'icon' ||
        field.fieldName === 'displayImage',
    );
    const tableReachHeight = tableIncludeImage
      ? dataList.length * 140 + 82 > 630
        ? 630
        : dataList.length * 140 + 82
      : (dataList.length + 1) * 82 > 630
      ? 630
      : (dataList.length + 1) * 82;
    return (
      <>
        <PerfectScrollbar
          onScrollLeft={() => {
            setTableEndReach(false);
            console.log('onScrollLeft');
          }}
          onScrollRight={() => {
            setTableStartReach(false);
            console.log('onScrollRight');
          }}
          onXReachStart={() => {
            setTableStartReach(true);
            console.log('onXReachStart');
          }}
          onXReachEnd={() => {
            setTableEndReach(true);
            console.log('onXReachEnd');
          }}
          onScrollUp={() => {
            setTableEndReach(false);
            console.log('onScrollUp');
          }}
          onScrollDown={() => {
            setTableStartReach(false);
            console.log('onScrollDown');
          }}
          onYReachStart={() => {
            setTableStartReach(true);
            console.log('onYReachStart');
          }}
          onYReachEnd={() => {
            setTableEndReach(true);
            console.log('onYReachEnd');
          }}
        >
          <table className={`all-list-table ${customClassName}`}>
            <thead>{getTableTitles()}</thead>
            {renderTableBody()}
          </table>
        </PerfectScrollbar>
        {!tableStartReach && showTableLeftShadow ? (
          <div
            className={`table-shadow-left table-shadow-of-${customClassName}`}
            style={{ height: `${tableReachHeight}px` }}
          />
        ) : null}
        {!tableEndReach && showTableRightShadow ? (
          <div
            className="table-shadow-right"
            style={{ height: `${tableReachHeight}px` }}
          />
        ) : null}
      </>
    );
  };

  return (
    <div
      className="campaign-list-section common-table-overflow "
      key={`${model}-list`}
      style={
        hidePagination
          ? { minHeight: 'unset', height: 'unset' }
          : { minHeight: 'unset' }
      }
    >
      {renderBody()}
      {dataList.length > 0 && !hidePagination ? (
        <div className="all-list-scrollable-area-pagination">
          <CustomPagination
            initPage={currentPage}
            pageSize={totalPage}
            pageChange={(page) => {
              if (onPageChange) {
                onPageChange(page);
                return;
              }

              history.push({
                pathname: location.pathname,
                hash: location.hash,
                search: queryString.stringify({
                  ...queryString.parse(location.search),
                  ...{
                    page: page + 1,
                  },
                }),
              });
            }}
          />
        </div>
      ) : null}

      <DeletePrompt
        model={model}
        title={deleteInfo.title}
        message={deleteInfo.message}
        relatedName={deleteInfo.relatedName}
        relatedSections={deleteInfo.relatedSections}
        inputRemarks={deleteInfo.inputRemarks}
        remarks={deleteInfo.remarks}
        actionType={deleteInfo.actionType}
        data={operatedItem}
        show={showPrompt}
        basePromptButtonsClassName={deleteInfo.basePromptButtonsClassName}
        onClose={() => {
          setShowPrompt(false);
          setOperatedItem(null);
          deleteInfo.onClose && deleteInfo.onClose();
        }}
        onConfirm={() => {
          setShowPrompt(false);

          deleteInfo.onConfirm
            ? deleteInfo.onConfirm()
            : dispatch(
                createAction(`${model}/delete`)({
                  afterAction: () => {
                    history.push({
                      pathname: location.pathname,
                      hash: location.hash,
                      search: queryString.stringify({
                        ...queryString.parse(location.search),
                        page: 1,
                      }),
                    });
                  },
                }),
              );
        }}
      />
      <PreviewImage
        show={enlargeImage ? true : false}
        onClose={() => {
          setEnlargeImage(null);
        }}
        imageUrl={enlargeImage}
      />
      <CountryCodesPrompt
        show={isOpenCountryCodesPrompt}
        onClose={() => {
          setDisabledSort(false);
          setIsOpenCountryCodesPrompt(false);
          setSelectedItem(null);
        }}
        {...selectedItem}
      />
    </div>
  );
}

export default BaseListContainer;
