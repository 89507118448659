import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { LanguageConfig } from '../../config/CustomEnums';
import { SaveAndBackWithOutTempButtons } from '../base/BottomStepComponent';
import ContentSections from '../base/ContentSections';
import { hasError, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import ImageUploader from '../base/ImageUploader';
import { createAction } from '../../utils';
import CustomEditor from '../base/CustomEditor';
import { saveTourCardToSessionStorage } from '../../models/TourCardModel';

const TOUR_CARD_ERRORS = {
  title: {
    required: 'Please provide title',
  },
  description: {
    required: 'Please provide description',
  },
  coverPhoto: {
    required: 'Please provide cover photo',
  },
};

const CreateTourCardStepOne = ({ languages, dispatch }) => {
  const history = useHistory();
  const params = useParams();
  const { id } = params;
  const {
    control,
    watch,
    setValue,
    getValues,
    register,
    trigger,
    formState: { errors },
  } = useFormContext();
  const renderLanguageTab = (language) => {
    const coverName = `translations.${language}.coverPhoto`;
    const descriptionName = `translations.${language}.description`;
    const watchCover = watch(coverName);
    const watchDescription = watch(descriptionName);
    return [
      <div>
        <div className="first-section-title">General</div>
        <div className="second-section">
          <div className="second-title-section">
            <div className="second-section-title">Title</div>
          </div>
          <div>
            <input
              type="text"
              className={`text-input-field ${
                hasError(errors, `translations.${language}.title`)
                  ? 'error-field'
                  : ''
              }`}
              {...register(
                `translations.${language}.title`,
                language === LanguageConfig.english
                  ? TOUR_CARD_ERRORS.title
                  : {},
              )}
              maxLength={255}
            />
          </div>
          <ReactHookFormErrorMessage
            errors={errors}
            id={`translations.${language}.title`}
          />
        </div>
        <div className="second-section">
          <div className="second-title-section">
            <div className="second-section-title">Description (Optional)</div>
          </div>
          <Controller
            name={descriptionName}
            control={control}
            rules={{ required: TOUR_CARD_ERRORS.description.required }}
            render={() => (
              <CustomEditor
                initialValue={watchDescription || ''}
                onValueChange={(data) => {
                  setValue(descriptionName, data, { shouldDirty: true });
                }}
                errorMessage={TOUR_CARD_ERRORS.description.required}
                error={hasError(errors, descriptionName)}
              />
            )}
          />
        </div>
        <div className="second-section">
          <div className="second-section-title">Cover photo</div>
          <div className="second-section_description">
            Suggested Image Size:600 px * 600 px (Less than 2MB, support JPG,
            PNG and GIF only.)
          </div>
          <Controller
            control={control}
            name={coverName}
            rules={
              language === LanguageConfig.english
                ? TOUR_CARD_ERRORS.coverPhoto
                : {}
            }
            render={() => (
              <ImageUploader
                minWidth={600}
                minHieght={600}
                images={watchCover ? [watchCover] : []}
                onImageStateChange={(newState) => {
                  console.log(newState);
                  setValue(
                    coverName,
                    newState ? (newState?.[0] ? newState?.[0] : null) : null,
                    {
                      shouldDirty: true,
                    },
                  );
                  // saveTourCardToSessionStorage(getValues());
                }}
                aspect={1 / 1}
                maxImageNum={1}
                language={language}
                uploadImageClicked={() => {}}
                errorMessage={
                  hasError(errors, coverName)
                    ? TOUR_CARD_ERRORS.coverPhoto.required
                    : ''
                }
              />
            )}
          />
        </div>
      </div>,
    ];
  };
  return (
    <>
      <ContentSections
        languageTabContent={{
          containers: languages?.map((item) => ({
            container: [renderLanguageTab(item.code)],
            key: item.code,
            title: item.sourceName,
          })),
        }}
        hidePreview={true}
      />
      <SaveAndBackWithOutTempButtons
        backAction={() => {
          history.goBack();
        }}
        saveText="Save"
        saveAction={async () => {
          const isValid = await trigger([
            'translations.en.title',
            // 'translations.en.description',
            'translations.en.coverPhoto',
          ]);
          console.log(getValues(), isValid);
          if (isValid) {
            const data = getValues();
            if (id) {
              dispatch(createAction('tourCard/updateTourCard')({ data }));
            } else {
              dispatch(createAction('tourCard/createTourCard')({ data }));
            }
          }
        }}
      />
    </>
  );
};
const mapPropsToState = (state) => ({
  languages: state.language.allList,
});

export default connect(mapPropsToState)(CreateTourCardStepOne);
