import React, { useState, useEffect } from 'react';
import './CreateLogoLabelStepOne.scss';
import './CreateLogoLabelPrompt.scss';
import { useDispatch, useSelector, connect} from 'react-redux';
import { LanguageConfig } from '../../config/CustomEnums';
import { saveLogoLabelToSessionStorage } from '../../models/LogoLabelModel';
import { SaveAndBackWithOutTempButtons } from '../base/BottomStepComponent';
import ContentSections from '../base/ContentSections';
import CustomTitleWithInput from '../base/CustomTitleWithInput';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import ImageUploader from '../base/ImageUploader';
import { createAction } from '../../utils';
import BasePrompt from '../../components/base/prompt/BasePrompt';

const LOGO_LABEL_ERRORS = {
    name: 'Please provide name',
    displayPriority: 'Please provide display priority',
    icon: 'Please provide logo image',
};

const CreateLogoLabelPrompt = ({
  onClose = (()=>{}),
  onConfirm= (()=>{}),
  show,
  languages,
}) => {
    const [logoLabelData, setLogoLabelData] = useState({});
    const [errors, setErrors] = useState();
    const dispatch = useDispatch();

    useEffect(()=> {
      dispatch(createAction('language/getList')({ isSelectorLoad: true }));
    },[])

    const isError = (name) => {
      if(errors) {
        return errors.includes(name);
      }
      return false;
    }

    const handleConfirm = async () => {
      if (logoLabelData.enName 
        && logoLabelData.displayPriority 
        && logoLabelData.enIcon) {
        let translations = {};
        (languages || []).forEach((item) => {
          const language = item.code;
          translations = {
            ...translations,
            [language]: {
              name:logoLabelData?.[`${language}Name`],
              logoLabelUrl:logoLabelData?.[`${language}Url`] || "",
              icon:logoLabelData?.[`${language}Icon`],
            },
          };
        });
        return {
          translations: translations,
          displayPriority: logoLabelData.displayPriority,
        };
      }
      else {
        const errorList = [];
        if (!logoLabelData.enName) {
          errorList.push("translations.en.logoLabelName");
        }
        if (!logoLabelData.enIcon) {
          errorList.push("translations.en.icon");
        }
        if (!logoLabelData.displayPriority) {
          errorList.push("displayPriority");
        }
        setErrors(errorList);
        return false;
      }
    }

    const renderLanguageTab = (language) => {
        return [
          <>
            <div className="create-logo-label-prompt-name-section">
            <CustomTitleWithInput
              title={'Name'}
              error={{
                error: isError(`translations.${language}.logoLabelName`),
                id: `translations.${language}.logoLabelName`,
                message: LOGO_LABEL_ERRORS.name,
              }}
              defaultValue={""}
              setValue={(value) => {
                setLogoLabelData({...logoLabelData, [`${language}Name`]: value});
              }}
            />
            </div>
            <CustomTitleWithInput
              title={'URL for logo label(optional)'}
              defaultValue={""}
              setValue={(value) => {
                setLogoLabelData({...logoLabelData, [`${language}Url`]: value});
              }}
            />
            <div className="second-section">
              <div className="second-section-title">Logo image</div>
              <div className="second-section_description">
                Suggested Image Size:600 px * 600 px (Less than 2MB, support JPG,
                PNG and GIF only.)
              </div>
              <ImageUploader
                minWidth={600}
                minHieght={600}
                images={logoLabelData?.[`${language}Icon`] ? [logoLabelData?.[`${language}Icon`]] : []}
                onImageStateChange={(newState) => {
                  setLogoLabelData({...logoLabelData, [`${language}Icon`]: newState[0]});
                }}
                aspect={1 / 1}
                maxImageNum={1}
                language={language}
                uploadImageClicked={() => {}}
                errorMessage={
                  isError(`translations.${language}.icon`)
                    ? LOGO_LABEL_ERRORS.icon
                    : ''
                }
              />
            </div>
            {language === LanguageConfig.english ? (
              <>
                <CustomTitleWithInput
                  title={'Display order'}
                  customTitleClass={'create-logo-label-prompt-display-priority-section'}
                  error={{
                    error: isError('displayPriority'),
                    id: 'displayPriority',
                    message: LOGO_LABEL_ERRORS.displayPriority,
                  }}
                  defaultValue={""}
                  setValue={(value) => {
                    if(language === LanguageConfig.english) {
                      setLogoLabelData({...logoLabelData, displayPriority: value});
                    }
                  }}
                  type={'number'}
                  extra={{
                    min: 1,
                  }}
                />
                <label className="create-message-suggest">
                  {`Minimum value is 1. The smaller value, the higher priority.`}
                </label>
              </>
            ) : null}
          </>,
        ];
      };

    const languageTabConfig = {
      containers: languages?.map((item) => ({
        container: [renderLanguageTab(item.code)],
        key: item.code,
        title: item.sourceName,
      })),
    };
    
    const otherBody = () => {
        return (
            <ContentSections
                languageTabContent={languageTabConfig}
                hidePreview={true}
                fieldsError={errors}
                sectionStyle="create-logo-label-prompt-section"
                contentStyle="create-logo-label-prompt-content"
                languageTabsStyle="create-logo-label-prompt-languageTabs"
                languageTabStyle="create-logo-label-prompt-languageTab"
            />
        )
    }
    return (
        <BasePrompt
        show={show}
        closeAction={() => {
            setLogoLabelData({})
            onClose();
        }}
        rightButton={{
          text: 'Save',
          action: async () => {
            const isValid = await handleConfirm();
            if (isValid) {
                const data = isValid;
                dispatch(createAction('logoLabel/createLogoLabel')({ 
                    data: data, 
                    afterAction: ()=> {
                        setLogoLabelData({})
                        onConfirm();
                        onClose();
                    }}));
            }
          },
        }}
        title={'Create New Logo Label'}
        description={'Please enter a new Logo label with its translation below:'}
        otherBody={otherBody}
        customClass={'create-logo-label-prompt-custom-class'}
        basePromptButtonsClassName={'create-logo-label-prompt-button'}
      />
    );
  };
  
  const mapPropsToState = (state) => ({
    languages: state.language.allList,
  });

  export default connect(mapPropsToState)(CreateLogoLabelPrompt);
  