import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import { createAction } from '../../../utils';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import CustomListComponent from '../../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import DeletePrompt from '../../../components/base/DeletePrompt';

const TourCardList = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  const { pageInfo, totalCount, tourCardList, totalPage, checkedList } =
    useSelector((state) => ({
      pageInfo: state.tourCard.pageInfo,
      totalCount: state.tourCard.totalCount,
      tourCardList: state.tourCard.pagedList,
      totalPage: state.tourCard.totalPage,
      checkedList: state.tourCard.checkedList,
    }));

  useEffect(() => {
    const searchDict = queryString.parse(location.search);
    const searchKey = searchDict['search'];
    const stringRank = searchDict['rank'] || 'true';
    const stringPage = searchDict['page'] || 1;
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);
    dispatch(
      createAction('tourCard/getList')({
        ...searchDict,
        page,
        rank,
        filterName: searchKey,
      }),
    );
  }, [dispatch, location]);

  const buttons = [
    <AuthButton
      title="Create Tour Card"
      action={() => {
        history.push('/tour_cards/create');
      }}
      requires={PermissionCodes.changeTourCard}
    />,
  ];

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.changeTourCard,
    },
  ];

  const tabs = [
    {
      name: '',
      content: (
        <BaseTabListContainer
          hideTab={true}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount ? totalCount : 0}
          listContent={
            <BaseListContainer
              fields={[
                { displayName: 'ID', fieldName: 'pk', orderField: 'pk' },
                { displayName: 'Title', fieldName: 'title' },
                { displayName: 'Cover photo', fieldName: 'coverPhoto' },
                {
                  displayName: 'Description',
                  fieldName: 'displayDescription',
                  // isHtml: true,
                },
              ]}
              model={'tourCard'}
              permissionGroup={PermissionCodes.tourCard}
              dataList={tourCardList}
              totalPage={totalPage}
              deleteInfo={{
                data: [],
                title: 'Tour card',
                onComfirm: {},
              }}
              actions={['Edit', 'Detail', 'Duplicate', 'Delete']}
            />
          }
        />
      ),
    },
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail: 'TBC',
          title: 'Tour card',
        }}
        buttons={buttons}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={true}
        tabs={tabs}
      />
      <DeletePrompt
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch(
            createAction('tourCard/delete')({
              afterAction: () => history.push(location),
            }),
          );
        }}
        data={checkedList}
        title={'Tour card'}
      />
    </>
  );
};

export default TourCardList;
