import React, { useState, useEffect } from 'react';
import './PreviewCampaignPage.scss';
import LanguageChange from './LanguageChange';
import PreviewBody from './PreviewBody';
import Loading from '../../../../components/base/Loading';
import { useParams, useHistory } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { createAction, getObjectFromSessionStorage } from '../../../../utils';
import { SESSION_KEYS } from '../../../../config/CustomEnums';

function PreviewCampaignPage({
  storeCampaign,
  linkCouponDetail,
}) {
  const params = useParams();
  const dispatch = useDispatch();
  const campaignId = params?.id;
  const [paramCampaign, setParamCampaign] = useState();
  const [language, setLanguage] = useState();
  const [previewCampaign, setPreviewCampaign] = useState({});
  useEffect(() => {
    setParamCampaign(getObjectFromSessionStorage(SESSION_KEYS.CREATE_CAMPAIGN_PERVIEW_SESSION_KEY));
    dispatch(createAction('targetMarket/getAllList')());
    if (campaignId) {
      dispatch(createAction('createCampaign/getOneCampaign')({ id: campaignId, isDetail: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (paramCampaign && campaignId == paramCampaign?.pk) {
      setPreviewCampaign(paramCampaign);
      return;
    };
    if (campaignId && storeCampaign.id) {
      setPreviewCampaign(storeCampaign);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramCampaign, storeCampaign]);

  if (campaignId && !storeCampaign.pk) {
    return <Loading customClass={'common-full-height'} />;
  }

  return (
    <div className="preview-container">
      <LanguageChange
        onLanguageChange={(language) => setLanguage(language)}
        campaign={previewCampaign}
        selectedLanguage={language}
      />
      <PreviewBody language={language} campaign={previewCampaign} linkCouponDetail={linkCouponDetail}/>
    </div>
  );
}

const mapPropsToState = (state) => ({
  storeCampaign: state.createCampaign.campaign,
  linkCouponDetail: state.createCampaign.linkCouponDetail,
});

export default connect(mapPropsToState)(PreviewCampaignPage);
