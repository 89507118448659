import { BaseHelper } from './BaseGraphQLAPIHelper';

const BASE_NODE_CONTENT = `{
  id
  pk
  name
}`;

const NODE_CONTENT = `{
  id
  pk
  name
  markets {
    edges {
      node {
        pk
        id
        name
        parameter
      }
    }
  }
  relatedCampaigns {
    id
    name
  }
}`;

const LIST_NODE_CONTENT = `{
  id
  pk
  name
  markets {
    edges {
      node {
        pk
        id
        name
        parameter
      }
    }
  }
  relatedCampaignNames
}`;

export const getChannelList = (afterCursor, moreSearch) => {
  let filter = '';
  console.log('@@31: ', moreSearch);
  if (moreSearch.filterName) {
    filter = `, nameIcontains:"${moreSearch.filterName}"`;
  }
  if (moreSearch.searchKey) {
    filter += `, search:"${moreSearch.searchKey}"`;
  }
  if (moreSearch.market) {
    filter += `, marketsIn:"${moreSearch.market}"`;
  }

  let orderBy = moreSearch.rank ? '-pk' : 'pk';
  //   if (moreSearch?.sort) {
  //     orderBy = moreSearch.sort;
  //   }
  const query = `{
    channels(first: ${
      moreSearch.pageSize ? moreSearch.pageSize : 20
    }, after:"${afterCursor}", orderBy:"${orderBy}", queryCampaign: ${
    moreSearch?.isAll || moreSearch?.isSelectorLoad ? false : true
  } ${filter}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node ${
          moreSearch?.isAll || moreSearch?.isSelectorLoad
            ? BASE_NODE_CONTENT
            : LIST_NODE_CONTENT
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getChannel = (id) => {
  const query = `{
    channel(id: "${id}") ${NODE_CONTENT}
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const deleteChannels = (ids) => {
  const query = `mutation DeleteChannels($input: DeleteChannelsInput!) {
    deleteChannels(input: $input) {
      success
    }
  }
  `;
  const variables = { input: { ids: ids } };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createOrUpdateChannel = (values) => {
  const action = values.id ? 'Update' : 'Create';
  let query = `mutation ${action}Channel($input: ${action}ChannelInput!) {
    ${action.toLowerCase()}Channel(input: $input) {
      success
      node ${NODE_CONTENT}
      errors {
        field
        messages
      }
    }
  }

  `;

  const variables = { input: values };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
