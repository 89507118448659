import React, { useEffect } from 'react';
import ScrollableComponent from '../../../components/base/ScrollableComponent';
import CreateTransactionStepOne from '../../../components/transactions/CreateTransactionStepOne';
import CreateTransactionStepTwo from '../../../components/transactions/CreateTransactionStepTwo';
import CreateTransactionStepThree from '../../../components/transactions/CreateTransactionStepThree';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { useSelector, useDispatch } from 'react-redux';
import { createAction } from '../../../utils';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { sessionDataKey } from '../../../models/CreateTransactionModel';

function CreateTransaction() {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const history = useHistory();

  const { stepConfig, currentStep, transactionStatusChanged } = useSelector(
    (state) => ({
      stepConfig: state.createTransaction.stepConfig,
      currentStep: state.createTransaction.currentStep,
      transactionStatusChanged:
        state.createTransaction.transactionStatusChanged,
    }),
  );
  // console.log('@@26: currentStep', currentStep);
  useEffect(() => {
    dispatch(createAction('createTransaction/clearData')());
    if (location.pathname.includes('edit')) {
      const transactionPK = params.id;
      dispatch(
        createAction('transactions/getTransaction')({
          transactionPK,
          afterActions: () =>
            dispatch(
              createAction('createTransaction/stepChange')({
                step: 0,
              }),
            ),
        }),
      );
    } else {
      dispatch(createAction('createTransaction/loadTransactionFromCookie')());
    }
  }, [dispatch, location.pathname, params.id]);

  const toTransactionList = () => {
    history.push('/transactions');
  };

  useEffect(() => {
    return function () {
      dispatch(createAction('createTransaction/clearData')());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (transactionStatusChanged && location.pathname.includes('edit')) {
      toTransactionList();
    }
    console.log('@@53: ', transactionStatusChanged);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, transactionStatusChanged]);

  useEffect(() => {
    return () => {
      dispatch(createAction('createTransaction/clearData')());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stepContent = [
    <CreateTransactionStepOne />,
    <CreateTransactionStepTwo />,
    <CreateTransactionStepThree />,
  ];
  const caution = {
    detail: '', //TODO: change this wording Create Transaction
    title: `${params.id ? 'Edit Transaction' : 'Create Transaction'}`,
  };
  return (
    <div className="customer">
      <ScrollableComponent
        tempSave={(save) => {
          dispatch({
            type: 'createTransaction/saveOrRemoveTransactionFromCookie',
            payload: save,
          });
        }}
        showFinishPop={transactionStatusChanged}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: 'Transaction is successfully created.',
          steps: null,
          buttons: [
            {
              text: 'Back to transaction list',
              action: toTransactionList,
            },
          ],
        }}
        sessionDataKey={sessionDataKey}
        content={stepContent}
        currentStep={currentStep}
        stepsConfig={stepConfig}
        breadcrumb={<CustomBreadcrumb />}
        caution={caution}
      />
    </div>
  );
}

export default CreateTransaction;
