import React from 'react';
import CustomerList from '../containers/customers/customer/CustomerList';
import LevelList from '../containers/customers/level/LevelList';
import TransactionList from '../containers/record/records/TransactionList';
import SegmentList from '../containers/customers/segments/SegmentList';
import CampaignList from '../containers/engagement/campaign/CampaignList';
import GoodieBagsList from '../containers/engagement/goodieBags/GoodieBagsList';
import GoodieBagGroupList from '../containers/engagement/goodieBagGroup/GoodieBagGroupList';
import CouponList from '../containers/engagement/coupon/CouponList';
import EarningRuleList from '../containers/engagement/earningRule/EarningRuleList';
import MessageList from '../containers/engagement/message/MessageList';
import RewardList from '../containers/engagement/rewards/RewardList';
import BrandList from '../containers/merchants/brands/BrandList';
import StoreList from '../containers/merchants/stores/StoreList';
import Dashboard from '../containers/dashboard/Dashboard';
import { NavBarNames } from './NavBarNameList';
import { PermissionCodes } from './PermissionCodes';
import TranslationList from '../containers/merchants/translations/TranslationList';
import { URLFragments } from '../components/campaign/CampaignUrlConfig';
import { CouponURLQueryKeys } from '../components/coupon/CouponUrlConfig';
import CustomerGroup from '../containers/customers/customerGroup/CustomerGroup';
import CampaignCategory from '../containers/engagement/campaignCategory/CampaignCategory';
import StoreCategory from '../containers/merchants/storeCategory/StoreCategory';
import WebviewContent from '../containers/others/WebviewContent';
import CouponTransaction from '../containers/record/couponRecords/CouponTransaction';
import PointTransactionList from '../containers/record/pointRecords/PointTransactionList';
import AdminList from '../containers/admin/admins/AdminList';
import AdminGroupList from '../containers/admin/adminGroups/AdminGroupList';
import BannerList from '../containers/homeManage/banners/BannerList';
import FeaturedCampaignList from '../containers/homeManage/featuredCampaigns/FeaturedCampaignList';
import TagList from '../containers/engagement/attributeTag/AttributeTagList';
import StoreCodeList from '../containers/merchants/stores/StoreCodeList';

import {
  pointRecordQueryKey,
  couponRecordQueryKey,
  transactionQueryKey,
} from '../components/pointRecord/RecordsUrlConfig';
import LogoLabelList from '../containers/engagement/logoLabel/LogoLabelList';
import MarketList from '../containers/engagement/markets/MarketList';
import ChannelList from '../containers/engagement/channel/ChannelList';
import LanguageList from '../containers/engagement/language/LanguageList';
import OfferTypeLabelList from '../containers/engagement/offerTypeLabel/OfferTypeLabelList';
import TourCardList from '../containers/engagement/tourCard/TourCardList';
import TermsAndConditionsList from '../containers/engagement/termsAndConditions/TermsAndConditionsList';
import PaymentSettlementOverviewList from '../containers/engagement/paymentSettlement/PaymentSettlementOverviewList';
import SurveyFormList from '../containers/engagement/surveyForm/SurveyFormList';
import InterestPreferenceList from '../containers/customers/interestPreference/InterestPreferenceList';
import GeneralAlertList from '../containers/engagement/message/GeneralAlertList';
import CouponLabelList from '../containers/engagement/couponLabel/CouponLabelList';

import CouponsAnalytics from '../containers/dashboard/CouponsAnalytics';
import CampaignsAnalytics from '../containers/dashboard/CampaignsAnalytics';
import CustomerAnalytics from '../containers/dashboard/CustomerAnalytics';
import GoodiebagAnalytics from '../containers/dashboard/GoodiebagAnalytics';

import OTPChannelList from '../containers/customers/otpChannel/OTPChannelList';
import SimpleDasnboard from '../containers/dashboard/SimpleDashboard';
import moment from 'moment';
import { TimeFormater } from '../utils/TimeFormatUtil';
import WorkingTeamList from '../containers/admin/workingTeam/WorkingTeamList';
import AdminActivityLogList from '../containers/admin/adminActivityLog/AdminActivityLogList';
import RateLimitList from '../containers/admin/rateLimit/RateLimitList';
import CampaignGroupList from '../containers/engagement/campaignGroup/CampaignGroupList';
import ManualUseCouponOverview from '../containers/merchants/manualUseCoupon/ManualUseCouponOverview';
import CheckOtpList from '../containers/customers/checkOtp/CheckOtpList';
import CheckMemberIdList from '../containers/customers/checkMember/CheckMemberIdList';
import ManageRecaptcha from '../containers/engagement/manageRecaptcha/ManageRecaptcha';
import ManageSearch from '../containers/engagement/manageSearch/ManageSearch';

const queryString = require('query-string');
const dashboard = {
  // path: '/',
  name: 'Dashboard',
  // location: {
  //   pathname: '/',
  // },
  // exact: true,
  // main: () => <Dashboard />,
  list: [
    {
      path: '/dashboard_overview',
      location: {
        pathname: '/dashboard_overview',
      },
      exact: true,
      name: NavBarNames.dashboardOverview,
      // codename: PermissionCodes.administratorgroup,
      main: () => <Dashboard />,
    },
    {
      path: '/dashboard/simple',
      location: {
        pathname: '/dashboard/simple',
      },
      exact: true,
      name: NavBarNames.simpleDashboard,
      codename: PermissionCodes.dashboard,
      main: () => <SimpleDasnboard />,
    },
    {
      path: '/dashboard_coupon',
      location: {
        pathname: '/dashboard_coupon',
      },
      exact: true,
      name: NavBarNames.dashboardCoupon,
      codename: PermissionCodes.dashboard,
      // codename: PermissionCodes.administrator,
      main: () => <CouponsAnalytics />,
    },
    {
      path: '/dashboard_campaign',
      location: {
        pathname: '/dashboard_campaign',
      },
      exact: true,
      name: NavBarNames.dashboardCampaign,
      codename: PermissionCodes.dashboard,
      // codename: PermissionCodes.administrator,
      main: () => <CampaignsAnalytics />,
    },
    {
      path: '/dashboard_goodiebag',
      location: {
        pathname: '/dashboard_goodiebag',
      },
      exact: true,
      name: NavBarNames.dashboardGoodiebag,
      codename: PermissionCodes.dashboard,
      // codename: PermissionCodes.administrator,
      main: () => <GoodiebagAnalytics />,
    },
    {
      path: '/dashboard_customer',
      location: {
        pathname: '/dashboard_customer',
      },
      exact: true,
      name: NavBarNames.dashboardCustomer,
      codename: PermissionCodes.dashboard,
      // codename: PermissionCodes.administrator,
      main: () => <CustomerAnalytics />,
    },
  ],
};

const admin = {
  name: 'administrators',
  list: [
    {
      path: '/administrator_groups',
      location: {
        pathname: '/administrator_groups',
      },
      exact: true,
      name: NavBarNames.adminGroup,
      codename: PermissionCodes.administratorgroup,
      main: () => <AdminGroupList />,
    },
    {
      path: '/administrators',
      location: {
        pathname: '/administrators',
      },
      exact: true,
      name: NavBarNames.admin,
      codename: PermissionCodes.administrator,
      main: () => <AdminList />,
    },
    {
      path: '/working_teams',
      location: {
        pathname: '/working_teams',
      },
      exact: true,
      name: NavBarNames.workingTeam,
      codename: PermissionCodes.workingTeam,
      main: () => <WorkingTeamList />,
    },
    {
      path: '/admin_activity_log',
      location: {
        pathname: '/admin_activity_log',
      },
      exact: true,
      name: NavBarNames.adminActivityLog,
      // codename: PermissionCodes.adminActivityLog,
      main: () => <AdminActivityLogList />,
    },
    {
      path: '/rate_limit',
      location: {
        pathname: '/rate_limit',
      },
      exact: true,
      name: NavBarNames.rateLimit,
      // codename: ,
      main: () => <RateLimitList />,
    },
  ],
};

const customers = {
  name: 'user',
  list: [
    {
      path: '/customers',
      location: {
        pathname: '/customers',
      },
      exact: true,
      name: NavBarNames.customer,
      codename: PermissionCodes.customer,
      main: () => <CustomerList />,
    },
    // {
    //   path: '/segments',
    //   location: {
    //     pathname: '/segments',
    //   },
    //   exact: true,
    //   name: NavBarNames.segments,
    //   codename: PermissionCodes.segment,
    //   main: () => <SegmentList />,
    // },
    // {
    //   path: '/customer_group',
    //   location: {
    //     pathname: '/customer_group',
    //     search: queryString.stringify({
    //       page: 1,
    //       rank: true,
    //       search: '',
    //     }),
    //   },
    //   exact: true,
    //   name: NavBarNames.customerGroup,
    //   codename: PermissionCodes.customergroup,
    //   main: () => <CustomerGroup />,
    // },
    {
      path: '/interest_preferences',
      location: {
        pathname: '/interest_preferences',
        search: queryString.stringify({
          page: 1,
          rank: true,
          search: '',
        }),
      },
      exact: true,
      name: NavBarNames.interestPreference,
      codename: PermissionCodes.interestPreference,
      main: () => <InterestPreferenceList />,
    },
    {
      path: '/otp_channel',
      location: {
        pathname: '/otp_channel',
        search: queryString.stringify({
          page: 1,
          rank: true,
          search: '',
        }),
      },
      exact: true,
      name: NavBarNames.otpChannel,
      codename: PermissionCodes.otpChannel,
      main: () => <OTPChannelList />,
    },
    {
      path: '/check_otp',
      location: {
        pathname: '/check_otp',
      },
      exact: true,
      name: NavBarNames.checkOtp,
      codename: PermissionCodes.checkOtp,
      main: () => <CheckOtpList />,
    },
    {
      path: '/check_member',
      location: {
        pathname: '/check_member',
      },
      exact: true,
      name: NavBarNames.checkMemberId,
      codename: PermissionCodes.checkMemberId,
      main: () => <CheckMemberIdList />,
    },
  ],
};

const engagement = {
  name: 'Engagement',
  list: [
    {
      path: '/campaigns',
      location: {
        pathname: '/campaigns',
        // hash: URLFragments.overview,
      },
      exact: true,
      name: NavBarNames.campaigns,
      codename: PermissionCodes.campaign,
      main: () => <CampaignList />,
    },
    // {
    //   path: '/campaign_groups',
    //   location: {
    //     pathname: '/campaign_groups',
    //   },
    //   exact: true,
    //   name: NavBarNames.campaignGroups,
    //   codename: PermissionCodes.campaignGroup,
    //   main: () => <CampaignGroupList />,
    // },
    {
      path: '/goodie_bags',
      location: {
        pathname: '/goodie_bags',
      },
      exact: true,
      name: NavBarNames.goodieBags,
      codename: PermissionCodes.goodieBag,
      main: () => <GoodieBagsList />,
    },
    {
      path: '/goodie_bag_groups',
      location: {
        pathname: '/goodie_bag_groups',
      },
      exact: true,
      name: NavBarNames.goodieBagGroups,
      codename: PermissionCodes.goodieBagGroup,
      main: () => <GoodieBagGroupList />,
    },
    {
      path: '/campaign_category',
      location: {
        pathname: '/campaign_category',
        search: queryString.stringify({
          sort: 'displayPriority',
        }),
      },
      exact: true,
      name: NavBarNames.campaignCategory,
      codename: PermissionCodes.campaigncategory,
      main: () => <CampaignCategory />,
    },
    {
      path: '/general_alert',
      location: {
        pathname: '/general_alert',
        search: queryString.stringify({
          page: 1,
          rank: true,
          search: '',
        }),
      },
      exact: true,
      name: NavBarNames.message,
      codename: PermissionCodes.message,
      main: () => <GeneralAlertList />,
    },
    // {
    //   path: '/messages',
    //   location: {
    //     pathname: '/messages',
    //     search: queryString.stringify({
    //       page: 1,
    //       rank: true,
    //       search: '',
    //     }),
    //   },
    //   exact: true,
    //   name: NavBarNames.message,
    //   codename: PermissionCodes.message,
    //   main: () => <MessageList />,
    // },
    //   path: '/earns',
    //   location: {
    //     pathname: '/earns',
    //   },
    //   exact: true,
    //   name: NavBarNames.earnRules,
    //   codename: PermissionCodes.earningrule,
    //   main: () => <EarningRuleList />,
    // },
    {
      path: '/coupons',
      location: {
        pathname: '/coupons',
      },
      exact: true,
      name: NavBarNames.coupons,
      codename: PermissionCodes.coupontemplate,
      main: () => <CouponList />,
    },
    {
      path: '/coupon_label',
      location: {
        pathname: '/coupon_label',
        search: queryString.stringify({
          sort: 'displayPriority',
        }),
      },
      exact: true,
      name: NavBarNames.couponLabel,
      codename: PermissionCodes.coupontemplate,
      main: () => <CouponLabelList />,
    },
    {
      path: '/tags',
      location: {
        pathname: '/tags',
        search: queryString.stringify({
          sort: 'displayPriority',
        }),
      },
      exact: true,
      name: NavBarNames.tags,
      codename: PermissionCodes.tagKey,
      main: () => <TagList />,
    },
    {
      path: '/logo_label',
      location: {
        pathname: '/logo_label',
        search: queryString.stringify({
          sort: 'displayPriority',
        }),
      },
      exact: true,
      name: NavBarNames.logoLabel,
      codename: PermissionCodes.logolabel,
      main: () => <LogoLabelList />,
    },
    {
      path: '/languages',
      location: {
        pathname: '/languages',
      },
      exact: true,
      name: NavBarNames.langauge,
      codename: PermissionCodes.language,
      main: () => <LanguageList />,
    },
    {
      path: '/channels',
      location: {
        pathname: '/channels',
      },
      exact: true,
      name: NavBarNames.channel,
      codename: PermissionCodes.channel,
      main: () => <ChannelList />,
    },
    {
      path: '/survey_forms',
      location: {
        pathname: '/survey_forms',
      },
      exact: true,
      name: NavBarNames.surveyForm,
      codename: PermissionCodes.surveyform,
      main: () => <SurveyFormList />,
    },
    {
      path: '/markets',
      location: {
        pathname: '/markets',
      },
      exact: true,
      name: NavBarNames.targetMarket,
      codename: PermissionCodes.targetMarket,
      main: () => <MarketList />,
    },
    {
      path: '/offer_type_labels',
      location: {
        pathname: '/offer_type_labels',
      },
      exact: true,
      name: NavBarNames.offerTypeLabel,
      codename: PermissionCodes.offerTypeLabel,
      main: () => <OfferTypeLabelList />,
    },

    {
      path: '/tour_cards',
      location: {
        pathname: '/tour_cards',
      },
      exact: true,
      name: NavBarNames.tourCard,
      codename: PermissionCodes.tourCard,
      main: () => <TourCardList />,
    },

    {
      path: '/terms_and_conditions',
      location: {
        pathname: '/terms_and_conditions',
      },
      exact: true,
      name: NavBarNames.termsAndConditions,
      codename: PermissionCodes.termsAndConditions,
      main: () => <TermsAndConditionsList />,
    },

    {
      path: '/payment_settlement',
      location: {
        pathname: '/payment_settlement',
      },
      exact: true,
      name: NavBarNames.paymentSettlement,
      codename: PermissionCodes.paymentSettlement,
      main: () => <PaymentSettlementOverviewList />,
    },

    {
      path: '/manage_recaptcha',
      location: {
        pathname: '/manage_recaptcha',
      },
      exact: true,
      name: NavBarNames.manageRecaptcha,
      codename: '',
      main: () => <ManageRecaptcha />,
    },

    {
      path: '/manage_search',
      location: {
        pathname: '/manage_search',
      },
      exact: true,
      name: NavBarNames.manageSearchFunction,
      codename: '',
      customClassName: 'side-menu-nav-item-two-line',
      main: () => <ManageSearch />,
    },
  ],
};

const merchants = {
  name: 'Merchants',
  list: [
    {
      path: '/manual_use_coupon',
      location: {
        pathname: '/manual_use_coupon',
      },
      exact: true,
      name: NavBarNames.manualUseCoupon,
      codename: PermissionCodes.coupon,
      main: () => <ManualUseCouponOverview />,
    },
    {
      path: '/brands',
      location: {
        pathname: '/brands',
        search: queryString.stringify({
          page: 1,
          rank: true,
          search: '',
        }),
      },
      exact: true,
      name: NavBarNames.brands,
      codename: PermissionCodes.brand,
      main: () => <BrandList />,
    },
    {
      path: '/stores',
      location: {
        pathname: '/stores',
        search: queryString.stringify({
          sort: 'displayPriority',
        }),
      },
      exact: true,
      name: NavBarNames.stores,
      codename: PermissionCodes.store,
      main: () => <StoreList />,
    },
    {
      path: '/store_codes',
      location: {
        pathname: '/store_codes',
      },
      exact: true,
      name: NavBarNames.storeCodes,
      codename: PermissionCodes.store,
      main: () => <StoreCodeList />,
    },
    // {
    //   path: '/store_category',
    //   location: {
    //     pathname: '/store_category',
    //   },
    //   exact: true,
    //   name: NavBarNames.storeCategory,
    //   codename: PermissionCodes.storecategory,
    //   main: () => <StoreCategory />,
    // },
  ],
};

const others = {
  name: 'Others',
  list: [
    {
      path: '/webview',
      // location: {
      //   pathname: '/webview',
      // },
      exact: true,
      name: NavBarNames.others,
      main: () => <WebviewContent />,
    },
  ],
};

const records = {
  name: 'Record',
  list: [
    // {
    //   path: '/transactions',
    //   location: {
    //     pathname: '/transactions',
    //     search: queryString.stringify({
    //       [transactionQueryKey.searchKey]: '',
    //       [transactionQueryKey.page]: 1,
    //       [transactionQueryKey.rank]: true,
    //       [transactionQueryKey.tab]: 'all',
    //     }),
    //   },
    //   exact: true,
    //   name: NavBarNames.transactions,
    //   codename: PermissionCodes.transaction,
    //   main: () => <TransactionList />,
    // },
    // {
    //   path: '/point_records',
    //   location: {
    //     pathname: '/point_records',
    //     search: queryString.stringify({
    //       [pointRecordQueryKey.searchKey]: '',
    //       [pointRecordQueryKey.page]: 1,
    //       [pointRecordQueryKey.rank]: true,
    //       [pointRecordQueryKey.tab]: 'all',
    //     }),
    //   },
    //   exact: true,
    //   name: NavBarNames.pointRecords,
    //   codename: PermissionCodes.pointtransaction,
    //   main: () => <PointTransactionList />,
    // },
    {
      path: '/coupon_records',
      location: {
        pathname: '/coupon_records',
        search: queryString.stringify({
          [couponRecordQueryKey.searchKey]: '',
          [couponRecordQueryKey.page]: 1,
          [couponRecordQueryKey.rank]: true,
          [couponRecordQueryKey.tab]: 'all',
          // report_period: `${moment()
          //   .add(-8, 'days')
          //   .format(TimeFormater.yearMonthDay)},${moment()
          //   .add(-1, 'days')
          //   .format(TimeFormater.yearMonthDay)}`,
        }),
      },
      exact: true,
      name: NavBarNames.couponRecords,
      codename: PermissionCodes.coupontransaction,
      main: () => <CouponTransaction />,
    },
  ],
};

const homemanager = {
  name: 'Manage Home page',
  list: [
    {
      path: '/banners',
      location: {
        pathname: '/banners',
        search: queryString.stringify({
          sort: 'displayPriority',
        }),
      },
      exact: true,
      name: NavBarNames.banners,
      codename: PermissionCodes.banners,
      main: () => <BannerList />,
    },
    // {
    //   path: '/home_campaigns',
    //   location: {
    //     pathname: '/home_campaigns',
    //   },
    //   exact: true,
    //   name: NavBarNames.featuredCampaign,
    //   codename: PermissionCodes.campaign,
    //   main: () => <FeaturedCampaignList />,
    // },
  ],
};

export const tabList = [
  dashboard,
  customers,
  engagement,
  merchants,
  // others,
  records,
  admin,
  homemanager,
];

export const merchantAdminRoute = [
  NavBarNames.campaigns,
  NavBarNames.paymentSettlement,
  NavBarNames.brands,
  NavBarNames.stores,
  NavBarNames.storeCodes,
  NavBarNames.couponRecords,
  NavBarNames.admin,
];
