import React, { useState, useEffect } from 'react';
import AnimatedNumber from 'animated-number-react';
import { useSelector } from 'react-redux';
import './CouponsCard.scss';
import { enLocaleNumberFormatter } from '../../utils';
import {
  COUPON_PIE_CHART_CONFIG,
  ANIMATION_DURATION,
} from '../../config/DashboardConfig';
import { Link } from 'react-router-dom';
import icon from '../../assets/images/customer_overview_vector.svg';
import { Button, Image, ButtonGroup, ProgressBar } from 'react-bootstrap';
import './Top10CouponAcquiredCard.scss';
import NoDataPage from '../base/NoDataPage';
import StarredCampaignFilter from '../../containers/dashboard/StarredCampaignFilter';

const TOP_BY_CONFIG = [
  { key: 'top10CouponAcquiredByCampaignData', name: 'Campaign' },
  { key: 'top10CouponAcquiredByBrandData', name: 'Brand' },
];

const Top10CouponAcquiredCard = () => {
  const [topBy, setTopBy] = useState(TOP_BY_CONFIG[0]);
  const top10CouponAcquired = useSelector(
    (state) => state.dashboard.top10CouponAcquired,
  );
  const [data, setData] = useState(
    top10CouponAcquired?.top10CouponAcquiredByCampaignData || [],
  );

  useEffect(() => {
    setData(top10CouponAcquired?.top10CouponAcquiredByCampaignData || []);
    setTopBy(TOP_BY_CONFIG[0]);
  }, [top10CouponAcquired]);

  return (
    <div className="dashboard-coupon-card dashboard-top10-coupon-acquired-card dashboard-top">
      <div className="dashboard-card-title">TOP 10 COUPON ACQUIRING</div>
      <div>
        <StarredCampaignFilter
          filterModel="couponAcquireFilter"
          includeGeneralMessage={false}
        />
      </div>
      <div className="dashboard-card-choice-buttons">
        <ButtonGroup className="filter-button-group">
          {TOP_BY_CONFIG.map((item, index) => {
            return (
              <Button
                className={`filter-button-group-item ${
                  topBy.key === item.key ? 'filter-button-selectd' : ''
                } filter-button-selectd-${index}`}
                onClick={() => {
                  if (topBy.key !== item.key) {
                    setTopBy(item);
                    setData(top10CouponAcquired?.[item.key]);
                  }
                }}
              >
                {item.name}
              </Button>
            );
          })}
        </ButtonGroup>
      </div>
      <div className="dashboard-card-body">
        {data?.length <= 0 ? (
          <NoDataPage />
        ) : (
          <>
            <div className="dashboard-card-head">
              <label className="dashboard-card-name">{topBy?.name}</label>
              <label className="dashboard-card-number">
                No. of coupon acquired
              </label>
            </div>
            <div className="dashboard-coupon-card-data">
              {data?.map((item, index) => {
                return (
                  <div
                    className={`dashboard-coupon-card-data-single dashboard-coupon-card-data-${
                      index <= 2 ? 'top3' : 'others'
                    }`}
                  >
                    <div>
                      <label className="dashboard-coupon-card-data-single-name">
                        {item.name}
                      </label>
                      <label className="dashboard-coupon-card-data-single-value">
                        {item.value}
                      </label>
                    </div>
                    <ProgressBar
                      className={`${
                        item.percentage === 0 ? 'non-progress' : ''
                      }`}
                      now={item.percentage}
                      label={
                        <label className="dashboard-card-data-percentage">
                          {`${item.percentage}%`}
                        </label>
                      }
                    />
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
      <div className="dashboard-card-data-total">
        <div className="dashboard-card-data-total-line">
          <label>Total acquired:</label>
          <label className="dashboard-card-data-number">
            {top10CouponAcquired?.totalCouponAcquired || 0}
          </label>
        </div>
      </div>
      <Link
        className="dashboard-overview-link coupons-overview-link"
        to={{
          pathname:
            topBy === TOP_BY_CONFIG[1]
              ? '/dashboard_coupon'
              : '/dashboard_campaign',
        }}
      >
        {/* {topBy === TOP_BY_CONFIG[1]
          ? 'Coupon set Overview'
          : 'Campaign Overview'} */}
        See detail
        <img className="dashboard-overview-link-icon" src={icon} alt="" />
      </Link>
    </div>
  );
};

export default Top10CouponAcquiredCard;
