import { extraQueryParameterString } from '../utils';
import { BaseHelper } from './BaseGraphQLAPIHelper';

const CUSTOMER_CONTENT = `
id
pk
membershipId
ssoUid
isAssignedAsTestingCustomer
company
isPreviewTester
signUpChannel
referrer {
  id
}
referredByCampaign {
  id
}
isForcedInactive
isLocked
status
preferredMessageLanguage
salutation
creationDate
lastModifiedDate
hasAgreedDirectMarketing
groups {
  edges {
    node {
      id
      name
    }
  }
}
interestPreferences {
  edges {
    node {
      pk
      name
    }
  }
}
`;
const others = `
pointAccount {
  id
  pk
  totalPointsEarned
  balance
  totalPointsUsed
  totalPointsExpired
  currentLevel {
    pk
    id
    levelName
  }
  currentLevelRenewDatetime
  currentLevelExpiredInXDays
}
segments {
  edges {
    node {
      id
      name
      conditionGroups {
        edges {
            node {
                levelCharacteristic {
                  id
                  levels(first: 2) {
                      edges {
                          node {
                              id
                              levelName
                              translations(first: 2) {
                                  edges {
                                      node {
                                          levelName
                                          levelBenefitUrl
                                      }
                                  }
                              }
                          }
                      }
                  }
              }
            }
        }
      }
    }
  }
}`;

export const getCustomerGroups = () => {
  const query = `{
    customerGroups(first: 100) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          name
        }
      }
    }
  }
    `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getAllCustomers = (afterCursor, search = {}, moreSearch) => {
  let searchValue = '';
  if (search && Object.keys(search).length > 0) {
    searchValue =
      ', ' +
      Object.keys(search)
        .map((key) => `${key}: ${search[key]}`)
        .join(', ');
  }
  let orderBy = 'pk';
  if (moreSearch?.sort) {
    orderBy = moreSearch.sort;
  }

  // pointAccount {
  //   id
  //   pk
  //   totalPointsEarned
  //   balance
  //   totalPointsUsed
  //   totalPointsExpired
  //   currentLevel {
  //     pk
  //     id
  //     levelName
  //   }
  //   currentLevelRenewDatetime
  //   currentLevelExpiredInXDays
  // }

  const query = `{
    customers(first: 20, after:"${afterCursor}", orderBy:"${orderBy}", ${searchValue}) {
      ${moreSearch?.isCheckCustomer ? `totalCount` : ''}
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          ssoUid
          membershipId
          isPreviewTester
          ${
            moreSearch?.isCheckOtp || moreSearch?.isCheckCustomer
              ? ''
              : `interestPreferences {
                  edges {
                    node {
                      pk
                      name
                    }
                  }
                }`
          }
          ${
            moreSearch?.isCheckCustomer
              ? `
          mobilePhoneNumberCountryCode
          mobilePhoneNumberSubscriberNumber
          creationDate
          `
              : ''
          }
        }
      }
    }
  }`;
  const variables = {};
  const xLabel = moreSearch?.isCheckCustomer
    ? 'check-membership-id-limit'
    : null;
  return BaseHelper.callGraphQLAPI({ query, variables, xLabel });
};

export const getCustomersByPage = (
  afterCursor,
  rank = 'true',
  search,
  groups,
  segments,
  levels,
  age,
  genders,
  startDate,
  endDate,
  moreSearch,
  sort,
  allSearch,
) => {
  // firstName_Icontains: "", groups_Name: "", gender_In: "", dateOfBirth_Month: "", mobilePhoneNumberCountryCode_In: "852,853,86", ageBetween:"0,2",signUpAnniversaryBetween: "0,2", level: "Level 2"

  let order = rank ? '-pk' : 'pk';

  if (sort) {
    order = sort;
  }

  let searches = [];
  if (search) {
    searches.push(`customerName: "${search}"`);
  }
  if (groups) {
    searches.push(`groupsIn: "${groups}"`);
  }
  if (segments) {
    searches.push(`segmentsIn: "${segments}"`);
  }
  if (levels) {
    searches.push(`levelIn: "${levels}"`);
  }
  if (age) {
    let ages = age.split(',');
    if (ages[1] > 0) {
      searches.push(`ageGte: ${ages[0]}, ageLte: ${ages[1]}`);
    }
  }
  if (genders) {
    searches.push(`genderIn: "${genders}"`);
  }
  // if (startDate && endDate) {
  //   searches.push(`dateOfBirthRange: "${startDate},${endDate}"`);
  // } else if (startDate) {
  //   searches.push(`dateOfBirth: "${startDate}"`);
  // } else if (endDate) {
  //   searches.push(`dateOfBirth: "${endDate}"`);
  // }
  if (startDate) {
    searches.push(`dateOfBirthGte: "${startDate}"`);
  }
  if (endDate) {
    searches.push(`dateOfBirthLte: "${endDate}"`);
  }
  console.log('@@244: ', allSearch);
  if (allSearch.interestPreferenceIn || allSearch.interest_preference) {
    searches.push(
      `interestPreferenceIn: "${
        allSearch.interestPreferenceIn || allSearch.interest_preference
      }"`,
    );
  }

  // console.log('@@127: ', searches);
  if (moreSearch) {
    searches = [...searches, ...moreSearch];
  }

  // console.log('@@104: ', searches.join(', '));
  const query = `{
    customers(first: 20, after:"${afterCursor}", orderBy:"${order}"${
    searches.length > 0 ? ',' + searches.join(', ') : ''
  }) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ${CUSTOMER_CONTENT}
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteCustomers = (ids, remarks) => {
  const query = `
    mutation DeleteCustomers($input: DeleteCustomersInput!) {
      deleteCustomers(input: $input) {
        success
        deleteFail
      }
    }
  `;
  const variables = {
    input: {
      ids: ids,
      remarks: remarks,
    },
  };

  // console.log('@@338: ', query);

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateCustomer = (values) => {
  const query = `
  mutation UpdateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      node {
        id
        pk
        ssoUid
        company
        signUpChannel
        referrer {
          id
        }
        referredByCampaign {
          id
        }
        isForcedInactive
        preferredMessageLanguage
        salutation
        creationDate
        lastModifiedDate
      }
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = {
    input: values,
  };

  // console.log('@@338: ', query);

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getOneCustomer = (id) => {
  // coupons(first: 100, isExpired: false, isUsed: false orderBy: "-pk") {
  //   edges {
  //     node {
  //       id
  //       pk
  //       isForcedInactive
  //       dateOfPurchase
  //       dateOfEarning
  //       dateOfGrant
  //       serialNumber
  //       isUsed
  //       isExpired
  //       isActive
  //       effectiveDate
  //       expiryDate
  //       template {
  //         id
  //         pk
  //         name
  //       }
  //     }
  //   }
  // }

  const query = `{
    customer(id: "${id}") {
      id
      pk
      ssoUid
      membershipId
      isAssignedAsTestingCustomer
      company
      signUpChannel
      isPreviewTester
      referrer {
        id
        pk
      }
      referredByCampaign {
        id
        pk
        name
      }
      isForcedInactive
      status
      preferredMessageLanguage
      salutation
      creationDate
      lastModifiedDate
      totalSpending
      averageOrderValue
      totalNumberOfOrders
      daysFromLastOrder
      hasAgreedDirectMarketing
      allowPersonalInfoShare
      interestPreferences {
        edges {
          node {
            pk
            name
          }
        }
      }
    }
  }
  `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCustomerActivityLog = (ssoUid, payload) => {
  // activityTypeIn: "CUSTOMER_POINTS_EARNED,CUSTOMER_POINTS_USED,CUSTOMER_POINTS_EXPIRED,CUSTOMER_LEVEL_CHANGED,CUSTOMER_LEVEL_RETAINED"
  console.log('@456', payload);
  var extraPama = '';
  if (ssoUid) {
    extraPama = extraQueryParameterString('customerSsoUid', ssoUid);
    extraPama = extraQueryParameterString('customerLogs', true);
  } else if (payload?.fortuneBagId) {
    extraPama = extraQueryParameterString(
      'fortuneBagLogsHktb',
      payload?.fortuneBagId,
    );
  } else if (payload?.goodieBagId) {
    extraPama = extraQueryParameterString(
      'goodieBagLogsHktb',
      payload?.goodieBagId,
    );
  }

  const query = `{
    customerActivityLogs(first: 50, orderBy: "-pk" ${extraPama}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          activityType
          customerSsoUid
          logTitle
          logContentId
          logContentType {
            appLabel
            model
          }
          creationDate
        }
      }
    }
  }
  `;

  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const activeCustomer = (values) => {
  const query = `
  mutation ActivateCustomer($input: ActivateCustomerInput!) {
    activateCustomer(input: $input) {
      node {
        id
        pk
        isForcedInactive
      }
    }
  }
  `;
  const variables = {
    input: values,
  };

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deactiveCustomer = (values) => {
  console.log(values);
  const query = `
  mutation DeactivateCustomer($input: DeactivateCustomerInput!) {
    deactivateCustomer(input: $input) {
      node {
        id
        pk
        isForcedInactive
      }
    }
  }
  `;
  const variables = {
    input: values,
  };

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getInterestPreferences = () => {
  const query = `{
    interestPreferences(first: 100) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          name
        }
      }
    }
  }
    `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getCustomerActivityLogVersion2 = (
  ssoUid,
  afterCursor,
  payload,
) => {
  console.log('@456', payload);
  let filter = '';
  filter += `, customerSsoUid: "${ssoUid}"`;
  filter += `, version: 2`;

  let orderBy = '-pk';
  if (payload?.sort) {
    orderBy = payload.sort;
  }

  if (payload?.createAt) {
    filter += `, creationDateRange: "${payload?.createAt}"`;
  }
  if (payload?.logBy) {
    filter += `, logBy: "${payload?.logBy}"`;
  }
  if (payload?.activityGroupTypeIn) {
    filter += `, groupActivityTypeIn: "${payload?.activityGroupTypeIn}"`;
  }

  const query = `{
    customerActivityLogs(first: 20, after:"${afterCursor}", orderBy: "${orderBy}" ${filter}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          activityType
          logBy
          customerSsoUid
          logContent {
            administratorId
            administratorName
            couponId
            couponExpiryDate
            couponReferenceId
            couponTemplateId
            couponTemplateName
            goodieBagCampaignSlotRewardType
            goodieBagRewardQuantity
            goodieBagId
            goodieBagName
            campaignId
            campaignName
            campaignIsStarred
            storeId
            storeName
          }
          isRegister
          oldEmailAddress
          emailAddress
          creationDate
          remarks
        }
      }
    }
  }
  `;

  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};
