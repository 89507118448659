import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import { ArrayStringData, createAction } from '../../../utils';
import { COUPON_REDEMPTION_METHOD } from '../../../config/CustomEnums';
import moment from 'moment';
import { TimeFormater } from '../../../utils/TimeFormatUtil';

function Filter({
  backAction = () => {},
  renderAboveFilterText = () => {},
  customFilterAreaClass,
}) {
  const location = useLocation();
  const dispatch = useDispatch();

  const { couponSetList } = useSelector((state) => ({
    couponSetList: state.couponList.notPagedAllList,
  }));

  const [sourceCouponSet, setSourceCouponSet] = useState();

  const parsedSearch = queryString.parse(location.search);
  const searchReportPeriod = parsedSearch['use_time'] || '';
  const searchCouponSet = parsedSearch['coupon_set'] || '';
  const searchCouponCode = parsedSearch['coupon_code'] || '';
  const searchReferenceId = parsedSearch['reference_id'] || '';
  const searchSrk = parsedSearch['srk'] || '';

  const [ustTime, setUseTime] = useState(searchReportPeriod);
  const [couponSet, setCouponSet] = useState(ArrayStringData(searchCouponSet));
  const [couponCode, setCouponCode] = useState(searchCouponCode);
  const [srk, setSrk] = useState(searchSrk);
  const [referenceId, setReferenceId] = useState(searchReferenceId);

  useEffect(() => {
    dispatch({
      type: 'couponList/getAllList',
      payload: {
        isAll: true,
        pageSize: 1000,
      },
    });

    return () => {
      dispatch({ type: 'brand/clearData' });
      dispatch({ type: 'couponList/clearData' });
      dispatch({ type: 'campaignList/clearData' });
    };
  }, []);

  const content = [
    {
      title: 'Use time',
      value: ustTime,
      setValue: setUseTime,
      allowEmpty: [true, true],
      componment: FilterTypeEnum.dateRange,
    },
    {
      displayTitle: 'Coupon set name',
      title: 'Coupon set',
      data: sourceCouponSet || couponSetList,
      value: couponSet,
      setValue: setCouponSet,
      componment: FilterTypeEnum.searchableChoice,
      placeholder: 'Search by ID, Name',
      filterAction: (searchKey) => {
        const newData = couponSetList?.filter((item) => {
          const name = item.name?.toLowerCase() || '';
          return (
            name.includes(searchKey?.toLowerCase() || '') ||
            item.pk.toString().includes(searchKey?.toLowerCase() || '')
          );
        });
        setSourceCouponSet(newData);
      },
      renderDisplayName: (item) => `[ID:${item.pk}] ${item.name}`,
    },
    {
      title: 'Reference ID',
      value: referenceId,
      setValue: setReferenceId,
      componment: FilterTypeEnum.searchable,
      placeholder: 'Search by reference ID',
    },
    {
      title: 'Coupon code',
      value: couponCode,
      setValue: setCouponCode,
      componment: FilterTypeEnum.searchable,
      placeholder: 'Search by code',
    },
    {
      title: 'SRK',
      value: srk,
      setValue: setSrk,
      componment: FilterTypeEnum.searchable,
      placeholder: 'Search by SRK',
    },
  ];

  return (
    <BaseFilter
      content={content}
      backAction={backAction}
      init={() => {}}
      renderAboveFilterText={renderAboveFilterText}
      customFilterAreaClass={customFilterAreaClass}
    />
  );
}

export default Filter;
