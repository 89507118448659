import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { CustomerActivityType } from '../../../config/CustomEnums';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import {
  CUSTOMER_ACTIVITY_LOG_BY,
  CUSTOMER_ACTIVITY_TYPE,
} from '../../../models/CustomerListModel';
import { useSelector, useDispatch } from 'react-redux';
import { ArrayStringData } from '../../../utils/index';

function CustomerActivityLogFilter({ backAction = () => {} }) {
  const location = useLocation();
  const dispatch = useDispatch();

  const parsedSearch = queryString.parse(location.search);
  const searchCreateAt = parsedSearch['record_period'] || '';
  const searchLogBy = parsedSearch['log_by'] || '';
  const searchActivityGroupType = parsedSearch['group_type'] || '';

  const [createAt, setCreateAt] = useState(searchCreateAt);
  const [logBy, setLogBy] = useState(ArrayStringData(searchLogBy));
  const [activityGroupType, setActivityGroupType] = useState(
    ArrayStringData(searchActivityGroupType),
  );

  const content = [
    {
      title: 'Record period',
      value: createAt,
      setValue: setCreateAt,
      componment: FilterTypeEnum.dateRange,
    },
    {
      displayTitle: 'Action By',
      title: 'log_by',
      data: [
        {
          pk: 'CUSTOMER',
          name: CUSTOMER_ACTIVITY_LOG_BY.CUSTOMER,
        },
        {
          pk: 'ADMIN',
          name: CUSTOMER_ACTIVITY_LOG_BY.ADMIN,
        },
        {
          pk: 'SYSTEM',
          name: CUSTOMER_ACTIVITY_LOG_BY.SYSTEM,
        },
      ],
      value: logBy,
      setValue: setLogBy,
      componment: FilterTypeEnum.choice,
    },
    {
      displayTitle: 'Action Type',
      title: 'group_type',
      data: [
        { pk: 'ACQUIRE_COUPON', name: 'Acquire coupon' },
        { pk: 'ACQUIRE_GOODIE_BAG', name: 'Acquire goodie bag' },
        { pk: 'CHANGE_ACCOUNT', name: 'Change account' },
        { pk: 'CHANGE_COUPON', name: 'Change coupon' },
        { pk: 'CHANGE_GOODIE_BAG', name: 'Change goodie bag' },
        { pk: 'CHANGE_PASSWORD', name: 'Change password' },
        { pk: 'CHANGE_PERSONAL_DETAILS', name: 'Change personal details' },
        { pk: 'EMAIL_ADDRESS_VERIFICATION', name: 'Email verification' },
        { pk: 'DELETE_ACCOUNT', name: 'Delete account' },
        { pk: 'FORGOT_PASSWORD', name: 'Forgot password' },
        { pk: 'LOGIN', name: 'Login' },
        { pk: 'LOGOUT', name: 'Logout' },
        { pk: 'REGISTRATION', name: 'Registration' },
        { pk: 'USE_COUPON', name: 'Use coupon' },
      ],
      value: activityGroupType,
      setValue: setActivityGroupType,
      componment: FilterTypeEnum.choice,
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

export default CustomerActivityLogFilter;
