import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import NextBlueIcon from '../../assets/images/nextBlueIcon.svg';
import { CustomDropDownButton } from '../base/CustomDropDownButton';
import { StatusTag } from '../../config/CustomEnums';
import { CustomStatusTagFromText } from '../base/CustomStatusTag';
import { useDispatch } from 'react-redux';

function Row({ name, value, isTag }) {
  return (
    <span>
      {name}: {isTag ? CustomStatusTagFromText(value) : <b>{value || '-'}</b>}
    </span>
  );
}

function CustomerListCard({
  item,
  index,
  deactiveAction,
  deleteAction,
  canEdit,
  canView,
  hideAction,
  fields,
  linkPath,
  isCheckCustomer,
}) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const getActionItems = () => {
    let actionItems = [];
    if (canEdit && item.status !== StatusTag.deleted) {
      actionItems.push({
        title: 'Edit',
        action: () => {
          history.push(`/customers/${item.pk}/edit/`);
        },
      });
    }
    if (canView || canEdit) {
      actionItems.push({
        title: 'Detail',
        action: () => {
          console.log('@@314');
          history.push(`/customers/${item.pk}/`);
        },
      });
    }

    if (canEdit && item.status !== StatusTag.deleted) {
      actionItems.push({
        title: 'Delete',
        action: () => {
          dispatch({
            type: `customerList/updateState`,
            payload: { checkedList: [item] },
          });
          deleteAction && deleteAction();
        },
      });
    }

    return actionItems;
  };

  return (
    <div className={'customer-card'}>
      {!isCheckCustomer ? (
        <div className="customer-card-title">
          <span className="">#{item.pk}</span>
          {item.status ? (
            <div className="customer-card-title-status">
              Status:&nbsp;{CustomStatusTagFromText(item.status)}
            </div>
          ) : null}
        </div>
      ) : null}
      {/* <div className="otp-code-title-devide-line" /> */}
      <div
        className="customer-card-detail"
        onClick={() => {
          if (
            (canView || canEdit) &&
            fields?.filter((val) => val.isLink)?.length > 0
          ) {
            history.push(
              linkPath
                ? linkPath
                : `${location.pathname.replace(/\/$/g, '')}/${item.pk}/`,
            );
          }
        }}
      >
        {fields?.map((field) => {
          if (field.isLink && canView) {
            return (
              <div>
                <Row name={field.name} value={field.value} />
                {field.linkNarrow === false ? null : <img src={NextBlueIcon} />}
              </div>
            );
          }

          return (
            <Row name={field.name} value={field.value} isTag={field.isTag} />
          );
        })}
      </div>
      {/* <ActionsDropdownForItem
                            object={item}
                            actions={['Edit']}
                            otherActions={[]}
                          /> */}
      {hideAction ? null : (
        <CustomDropDownButton
          customClass={'action-dropdown'}
          title={'Action'}
          items={[
            {
              group: 'Actions',
              items: getActionItems(),
            },
            ...(canEdit && item.status === StatusTag.deleted
              ? []
              : [
                  {
                    group: 'Others',
                    items: [
                      {
                        title:
                          item.isForcedInactive || item.isLocked
                            ? 'Activate'
                            : 'Deactivate',
                        action: () => {
                          deactiveAction(item);
                        },
                      },
                    ],
                  },
                ]),
          ]}
        />
      )}
    </div>
  );
}

export default CustomerListCard;
