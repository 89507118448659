import React, { useEffect, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { NavBarNames } from '../../config/NavBarNameList';
import CustomBreadcrumb from '../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../components/base/CustomListComponent';

import { createAction, getHashKeyString } from '../../utils';

import DateRangeCompare from '../../components/dashboard/DateRangeCompare';
import DateRangeSelection from '../../components/dashboard/DateRangeSelection';
import CustomerOverview from '../../components/customer/CustomerOverview';

import { TimeFormater, formatDate } from '../../utils/TimeFormatUtil';

const TAB_OVERVIEW = '';

function CustomerAnalytics() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [firstTimePickerChange, setFirstTimePickerChange] = useState(true);
  const [datePicker, setDatePicker] = useState();
  const activeTab = getHashKeyString(location.hash) || TAB_OVERVIEW;

  const tabs = [
    {
      name: TAB_OVERVIEW,
      content: <CustomerOverview />,
    },
  ];

  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  const requestData = (start, end) => {
    if (!start || !end) {
      return;
    }
    dispatch({ type: 'dashboardCustomer/clearState' });
    dispatch({ type: 'dashboard/clearState' });
    dispatch(
      createAction('dashboardCustomer/getCustomerAnalyticData')({
        startDate: start.format(TimeFormater.GTMFormat),
        endDate: end.format(TimeFormater.GTMFormat),
      }),
    );
  };

  return (
    <>
      <CustomListComponent
        caution={{
          detail:
            'All the customers are listed here, and you can view their details and records. You can learn about the user usage of the app here.',
          title: NavBarNames.customer,
        }}
        defaultActiveKey={activeTab}
        breadcrumb={<CustomBreadcrumb />}
        buttons={[]}
        tabs={tabs}
        hideTab={false}
        renderCompare={() => {
          return (
            <>
              <DateRangeCompare />
              <button
                type="button"
                className="btn-further btn btn-primary"
                onClick={() => {
                  requestData(datePicker?.start, datePicker?.end);
                }}
              >
                Refresh
              </button>
            </>
          );
        }}
        renderTabsRight={() => {
          return (
            <DateRangeSelection
              onTab
              applyDateRangeEvent={(startDate, endDate) => {
                // setFirstTimePickerChange(false);
                setDatePicker({ start: startDate, end: endDate });
                // if (firstTimePickerChange) {
                //   return;
                // }
                // requestData(startDate, endDate);
              }}
              clearDataEvent={() => {
                dispatch(createAction('overview/clearState')());
              }}
            />
          );
        }}
      />
    </>
  );
}

export default CustomerAnalytics;
