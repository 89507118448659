import React from 'react';
import './OTPCodeCard.scss';

function OTPCodeCard({ otpCode = {} }) {
  const {
    requestCodeDateTime,
    countryCode,
    mobileNumber,
    membershipId,
    requestCode,
  } = otpCode;
  return (
    <div className={'otp-code-card-container'}>
      <div className="otp-code-title-container">
        <div className="otp-code-title-date">{requestCodeDateTime}</div>
      </div>
      <div className="otp-code-title-devide-line" />
      <div className="otp-code-content-container otp-code-content-text">
        <div>
          Country code: <b>{countryCode || '-'}</b>
        </div>
        <div>
          Mobile number: <b>{mobileNumber || '-'}</b>
        </div>
        <div>
          Membership ID: <b>{membershipId || '-'}</b>
        </div>
        <div>
          OTP code: <b>{requestCode || '-'}</b>
        </div>
      </div>
    </div>
  );
}

export default OTPCodeCard;
