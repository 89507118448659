import { defaultStep, getNewStepConfig } from './StepBarUtil';
import { loading } from './LoadingUtil';
import {
  APIStatus,
  LanguageConfig,
  MessageChannel,
  SESSION_KEYS,
} from '../config/CustomEnums';
import {
  createMessage,
  sendTestMessageBeforeCreate,
  sendTestMessageBeforeUpdate,
  updateMessage,
} from '../services/MessageApiHelper';
import {
  createAction,
  saveToSessionStorage,
  getObjectFromSessionStorage,
  removeFromSessionStorage,
} from '../utils';

export const CreateMessageError = {
  content: {
    name: 'content',
    message: 'Please provide a content.',
  },
  scheduledDate: {
    name: 'scheduledDate',
    message: 'Please provide a schedule time.',
  },
};

export const sessionDataKey = {
  objectKey: SESSION_KEYS.CREATE_MESSAGE_SESSION_KEY,
  stepEndKey: SESSION_KEYS.CREATE_MESSAGE_END_SESSION_KEY,
  origionalData: SESSION_KEYS.CREATE_MESSAGE_ORIGIN_DATA_SESSION_KEY,
};

const getMessageInitState = () => {
  return {
    pk: null,
    id: null,
    name: null,
    content: null,
    photo: null,
    channels: [],
    displayChannels: null,
    relatedCampaign: {
      pk: null,
      name: null,
    },
    relatedCampaignName: null,
    targetCustomerGroup: null,
    displayTargetCustomer: null,
    targetedSegments: null,
    displayTargetedSegments: null,
    scheduledDate: null,
    deliveryDate: null,
    translations: {},
  };
};

const stepNames = ['Channel', 'Content', 'Property', 'Preview & Send'];

const getInitialState = () => {
  return {
    message: getMessageInitState(),
    errorFields: [],
    stepConfig: defaultStep(stepNames),
    currentStep: 0,
    languageTag: LanguageConfig.english,
    createStatus: APIStatus.none,
    scheduledMessage: false,
  };
};

const parsePhotoName = (photo) => {
  let photoNameArray = [];
  let tempPhoto = photo;
  if (Array.isArray(tempPhoto) && tempPhoto.length > 0) {
    tempPhoto = tempPhoto[0];
  } else {
    return null;
  }
  if (tempPhoto.type) {
    photoNameArray = tempPhoto.value.split('/');
  } else {
    photoNameArray = tempPhoto.split('/');
  }
  const photoName = photoNameArray[photoNameArray.length - 1];
  return photoName;
};

const parseMessageInputBody = (message, isDraft, isEdit = false) => {
  const parsedChannels = message.channels.map((item) => {
    if (item === MessageChannel.inbox) {
      return 'INBOX';
    }
    if (item === MessageChannel.push) {
      return 'PUSH_NOTIFICATION';
    }
  });

  let inputBody = {
    channels: parsedChannels,
    scheduledDate: message.scheduledDate,
    title: message.name,
    content: message.content,
    photo: message.photo ? parsePhotoName(message.photo) : null,
    isExclusive:
      message.targetCustomerGroup?.length > 0 ||
      message.targetedSegments?.length > 0,
    targetedCustomerGroups:
      message.targetCustomerGroup?.length > 0
        ? message.targetCustomerGroup.map((item) => item.pk)
        : [],
    targetedSegments: message.targetedSegments?.length
      ? message.targetedSegments.map((item) => item.pk)
      : [],
    campaign: message.relatedCampaign?.pk,
    isDraft: isDraft,
  };

  if (isEdit) {
    inputBody.id = message.pk;
  }

  return inputBody;
};

const parseMessageTranslationInput = (translation, messagePK, language) => {
  return {
    // source: messagePK,
    language: language,
    title: translation.name,
    content: translation.content,
    photo: translation.photo ? parsePhotoName(translation.photo) : null,
    id: translation.pk,
  };
};

const checkStepTwoFields = (data, isBack) => {
  const content = data.content;
  let errorFields = [];
  if (!content) {
    errorFields.push(CreateMessageError.content.name);
  }
  return {
    data,
    invalid: isBack ? false : errorFields.length > 0,
    errorFields: isBack ? [] : errorFields,
  };
};

const checkStepThreeFields = (data, isBack) => {
  const scheduledDate = data.scheduledDate;
  let errorFields = [];
  if (!scheduledDate) {
    errorFields.push(CreateMessageError.scheduledDate.name);
  }

  return {
    data: {
      ...data,
      isExclusive:
        data.targetedSegments?.length > 0 ||
        data.targetCustomerGroup?.length > 0,
    },
    invalid: isBack ? false : errorFields.length > 0,
    errorFields: isBack ? [] : errorFields,
  };
};

export default {
  namespace: 'createMessage',
  state: getInitialState(),

  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },

    updateMessageState(state, { payload }) {
      const message = { ...state.message, ...payload };
      saveToSessionStorage(sessionDataKey.objectKey, message);
      return {
        ...state,
        message: message,
      };
    },

    updateMessageTranslation(state, { payload }) {
      const { language } = payload;
      const message = {
        ...state.message.translations,
        [language]: {
          ...payload,
        },
      };
      saveToSessionStorage(sessionDataKey.objectKey, message);
      return {
        ...state,
        message: {
          ...state.message,
          translations: message,
        },
      };
    },

    loadMessageFromCookie(state, { payload }) {
      const message = getObjectFromSessionStorage(sessionDataKey.objectKey);
      if (!message) {
        return {
          ...state,
        };
      }
      saveToSessionStorage(sessionDataKey.origionalData, message);
      saveToSessionStorage(sessionDataKey.objectKey, message);
      return {
        ...state,
        message: message,
      };
    },

    saveOrRemoveMessageFromCookie(state, { payload }) {
      if (!payload) {
        removeFromSessionStorage(sessionDataKey.objectKey);
      }
      saveToSessionStorage(sessionDataKey.stepEndKey, true);
      return {
        ...state,
      };
    },

    stepChange(state, { payload }) {
      const isBack = payload.isBack;
      const data = payload.data;
      const message = state.message;
      let step = payload.step;
      let result = { invalid: false, errorFields: [], data: {} };
      if (step === 1) {
        result = checkStepTwoFields(data, isBack);
      }
      if (step === 2) {
        result = checkStepThreeFields(message, isBack);
      }
      const errorFields = result.errorFields;
      const stepConfig = getNewStepConfig(
        step,
        state.stepConfig,
        result.invalid,
        isBack,
      );

      if (!result.invalid) {
        step = isBack ? step - 1 : step + 1;
      }

      return {
        ...state,
        currentStep: step,
        stepConfig,
        errorFields: errorFields,
        message: {
          ...state.message,
          ...result.data,
        },
      };
    },

    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },
  },

  effects: {
    createMessage: [
      function* ({ payload }, { select, put }) {
        const { isDraft } = payload;
        const message = yield select((state) => state.createMessage.message);
        const inputBody = parseMessageInputBody(message, isDraft);
        const serviceArgs = [
          createMessage,
          {
            ...inputBody,
            translations: [
              message.translations[LanguageConfig.traditionalChinese]
                ? parseMessageTranslationInput(
                    message.translations[LanguageConfig.traditionalChinese],
                    message.pk,
                    LanguageConfig.traditionalChinese,
                  )
                : {},
              message.translations[LanguageConfig.simplifiedChinese]
                ? parseMessageTranslationInput(
                    message.translations[LanguageConfig.simplifiedChinese],
                    message.pk,
                    LanguageConfig.simplifiedChinese,
                  )
                : {},
            ],
          },
        ];
        saveToSessionStorage(sessionDataKey.stepEndKey, true);
        function* onSuccess(data) {
          const messageData = data.createMessage.node;
          yield put(
            createAction('updateMessageState')({
              pk: messageData.pk,
              id: messageData.id,
            }),
          );
          yield put(
            createAction('updateState')({
              scheduledMessage: true,
            }),
          );

          removeFromSessionStorage(sessionDataKey.objectKey);
          const afterActions = payload.afterActions || (() => {});
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    testMessageSend: [
      function* ({ payload }, { select }) {
        const message = yield select((state) => state.createMessage.message);
        const customer = payload.customer;
        const isEdit = payload.isEdit;
        let inputBody = parseMessageInputBody(message);
        inputBody.customer = customer.pk;
        let translations = message.translations;
        let translationInputBody = [];
        if (translations[LanguageConfig.traditionalChinese]) {
          const traditionalChineseInputBody = parseMessageTranslationInput(
            translations[LanguageConfig.traditionalChinese],
            message.pk,
            LanguageConfig.traditionalChinese,
          );
          delete traditionalChineseInputBody['source'];
          translationInputBody.push(traditionalChineseInputBody);
        }
        if (translations[LanguageConfig.simplifiedChinese]) {
          const simplifiedChineseInputBody = parseMessageTranslationInput(
            translations[LanguageConfig.simplifiedChinese],
            message.pk,
            LanguageConfig.simplifiedChinese,
          );
          delete simplifiedChineseInputBody['source'];
          translationInputBody.push(simplifiedChineseInputBody);
        }
        inputBody = {
          ...inputBody,
          translations: translationInputBody,
        };
        let testService = sendTestMessageBeforeCreate;
        if (isEdit) {
          inputBody.id = message.pk;
          testService = sendTestMessageBeforeUpdate;
        } else {
          inputBody.isDraft = true;
        }
        const serviceArgs = [testService, inputBody];
        function* onSuccess() {
          const afterActions = payload.afterActions || (() => {});
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    updateMessage: [
      function* ({ payload }, { select, put }) {
        const { isDraft } = payload;
        const message = yield select((state) => state.createMessage.message);
        const inputBody = parseMessageInputBody(message, isDraft, true);
        const serviceArgs = [
          updateMessage,
          {
            ...inputBody,
            translations: [
              message.translations[LanguageConfig.traditionalChinese]
                ? parseMessageTranslationInput(
                    message.translations[LanguageConfig.traditionalChinese],
                    message.pk,
                    LanguageConfig.traditionalChinese,
                  )
                : {},
              message.translations[LanguageConfig.simplifiedChinese]
                ? parseMessageTranslationInput(
                    message.translations[LanguageConfig.simplifiedChinese],
                    message.pk,
                    LanguageConfig.simplifiedChinese,
                  )
                : {},
            ],
          },
        ];
        saveToSessionStorage(sessionDataKey.stepEndKey, true);
        function* onSuccess() {
          removeFromSessionStorage(sessionDataKey.objectKey);
          // yield put(createAction('createAndUpdateMessageTranslation')());
          const afterActions = payload.afterActions || (() => {});
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
};
