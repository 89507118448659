import { BaseHelper } from './BaseGraphQLAPIHelper';

const NODE = `
  id
  pk
  name
`;

export const getWorkingTeamList = (afterCursor, params) => {
  let filter = '';
  console.log('@33', afterCursor, params);
  const { sort, filterName, search } = params || {};
  if (filterName || search) {
    filter = `, nameIcontains:"${filterName || search}"`;
  }

  let orderBy = '-pk';
  if (sort) {
    orderBy = sort;
  }
  const query = `{
    workingTeams(first: ${
      params.pageSize || 20
    }, after:"${afterCursor}"${filter}, orderBy: "${orderBy}") {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
            ${NODE}
            ${
              params.withAdmin
                ? `administrators {
                edges {
                  node {
                    pk
                    username
                  }
                }
              }`
                : ''
            }
            
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getWorkingTeamDetail = (id) => {
  const query = `{
    workingTeam(id: "${id}"){
        ${NODE}
        administrators {
            edges {
                node {
                    pk
                    username
                }
            }
        }
    }
      
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const createWorkingTeam = (payload) => {
  const query = `mutation CreateWorkingTeam($input: CreateWorkingTeamInput!) {
    createWorkingTeam(input: $input) {
      node {
        ${NODE}
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: {
      ...payload,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateWorkingTeam = (payload) => {
  const query = `mutation UpdateWorkingTeam($input: UpdateWorkingTeamInput!) {
    updateWorkingTeam(input: $input) {
      node {
        ${NODE}
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: {
      ...payload,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteWorkingTeams = (ids) => {
  const query = `mutation DeleteWorkingTeams($input: DeleteWorkingTeamsInput!) {
    deleteWorkingTeams(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids,
    },
  };
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};
