import React, { useEffect, useState } from 'react';
import './SearchAreaContent.scss';
import { Button, Image } from 'react-bootstrap';
import deleteSelectedButton from '../../assets/images/drop_down_delete_selected.png';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import { useDispatch } from 'react-redux';
import { useDeviceDetect } from '../../containers/engagement/campaign/preview/DHKPreviewBody';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import CustomAutoCompleteInput from '../base/CustomAutoCompleteInput';

function SearchAreaContent({
  countryCodeList,
  isRegister,
  searchMembershipId,
  isCheckOTP,
}) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [mobileNumber, setMobileNumber] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [countryCodes, setCountryCodes] = useState([]);
  const isMobile = useDeviceDetect(768);

  useEffect(() => {
    if (countryCodeList?.length > 0) {
      setCountryCodes(countryCodeList);
    }
  }, [countryCodeList]);

  return (
    <>
      <CustomAutoCompleteInput
        value={countryCode}
        setValue={(value) => {
          setCountryCode(value);
        }}
        selectAction={(value) => {
          setCountryCode(value);
          setCountryCodes(countryCodeList);
        }}
        title={'Country code'}
        filterAction={(searchKey) => {
          const newData = countryCodeList?.filter((item) => {
            const name = item.label?.toLowerCase() || '';
            return name.includes(searchKey?.toLowerCase() || '');
          });
          console.log('@@29', searchKey, newData);

          setCountryCodes(newData);
        }}
        options={countryCodes}
        clearAction={() => {
          setCountryCode('');
          setCountryCodes(countryCodeList);
        }}
        renderBeforeInput={<div className="plus-icon">+</div>}
        customInputClass={'country-code-input'}
      />
      <div className="d-flex flex-column">
        <CustomTitleLabel title={'Mobile number'} />
        <div className="check-otp-mobile-number-container">
          <input
            className="custom-markdown-area-label custom-markdown-area-label-short custom-base-input"
            placeholder="Please input"
            value={mobileNumber}
            onChange={({ target }) => setMobileNumber(target.value)}
          />
          <Image
            src={deleteSelectedButton}
            className="custom-delete-icon"
            onClick={(e) => {
              setMobileNumber('');
            }}
          />
        </div>
      </div>

      {isRegister === undefined || !isCheckOTP ? (
        <></>
      ) : (
        <div
          className={`check-otp-customer-label ${
            isRegister ? 'registered' : ''
          }`}
        >
          {isRegister
            ? `This mobile number has been registered.\nMembership ID: ${searchMembershipId}`
            : 'This mobile number has NOT been registered.'}
        </div>
      )}

      <Button
        className="btn-further check-otp-search-button"
        disabled={!countryCode || !mobileNumber}
        onClick={() => {
          if (isCheckOTP) {
            if (isMobile) {
              dispatch({
                type: 'otpCode/updateState',
                payload: {
                  otpCodeList: [],
                  loadData: true,
                  hasSearched: false,
                },
              });
            } else {
              dispatch({
                type: 'otpCode/updateState',
                payload: {
                  hasSearched: false,
                },
              });
            }

            dispatch({
              type: 'otpCode/checkUserRegister',
              payload: {
                countryCode,
                mobileNumber,
              },
            });
          } else {
            dispatch({
              type: 'customerList/getCustomersForCheck',
              payload: {
                countryCode,
                mobileNumber,
              },
            });
          }
        }}
      >
        Search
      </Button>
    </>
  );
}

export default SearchAreaContent;
