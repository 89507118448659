import React, { Component } from 'react';
import { connect } from 'react-redux';
import './GenerateCouponView.scss';
import { createAction } from '../../../utils';
import {
  COUPON_CODE_TYPE,
  COUPON_REDEMPTION_METHOD,
  COUPON_SET_TYPES,
  COUPON_TEMPLATE_TYPE,
  CouponMediumType,
  LanguageConfig,
} from '../../../config/CustomEnums';
import { withRouter } from 'react-router-dom';
import CustomFileUploader from '../../base/CustomFileUploader';
import { CouponURLFragments } from '../CouponUrlConfig';
import BasePrompt from '../../base/prompt/BasePrompt';
import { CustomTitleLabel } from '../../earning/CustomBaseComponments';
import { ImportResource } from '../../../models/DownloadImportModel';
import ErrorFieldMessage from '../../base/ErrorFieldMessage';
import CouponGenerateCount from './CouponGenerateCount';

@connect(({ createCoupon, uploadFiles, downloadAndImport }) => ({
  generateCouponStatus: createCoupon.generateCoupon.generateCouponStatus,
  generateCoupon: createCoupon.generateCoupon.generateCoupon,
  importedFile: uploadFiles.importedFile,
  importError: downloadAndImport.error,
  uploading: uploadFiles.uploading,
}))
class GenerateCouponView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // currentSelectedStore:
      //   props.shouldBeUsedInStores && props.selectedStores?.length > 0
      //     ? props.selectedStores[0].storeName
      //     : null,
      quantity: 0,
      physicalQuantity: 0,
      errorMessage: '',
      generateButtonClicked: false,
      generateButtonDisabled: true,
    };
  }

  componentDidUpdate(prev, preState) {
    if (
      prev.importError !== this.props.importError &&
      Object.keys(this.props.importError || {}).length <= 0
    ) {
      this.props.history.push({
        pathname: `/coupons/${this.props.couponTemplatePk}`,
        hash: CouponURLFragments.list,
      });

      this.modalOnHide();
    }
    if (
      preState.quantity !== this.state.quantity ||
      preState.physicalQuantity !== this.state.physicalQuantity
    ) {
      this.setState({
        generateButtonDisabled: this.isGenerateButtonDisabled(),
      });
    }
  }

  renderRelateBrandView = () => {
    if (this.props.relateBrand) {
      return (
        <div className="second-section">
          <div className="second-title-section">
            <div className="second-section-title">Related brand</div>
          </div>
          <div className="second-section-content">
            {this.props.relateBrand.name}
          </div>
        </div>
      );
    }
  };

  modalOnHide = () => {
    this.props.dispatch(
      createAction('createCoupon/updateGenerateCouponState')({
        generateCoupon: false,
      }),
    );
  };

  isValidQuantity = (quantity) => {
    const quantityIntValue = parseInt(quantity);
    return (
      quantity &&
      quantity !== '0' &&
      /^\+?\d+$/.test(quantity) &&
      quantityIntValue > 0 &&
      quantityIntValue <= 100000
    );
  };

  isGenerateButtonDisabled = () => {
    const { quantity, physicalQuantity } = this.state;
    const { couponTemplateType, couponRedemptionMethod, couponMedium } =
      this.props;
    const hasCouponMedium =
      couponTemplateType === COUPON_SET_TYPES.SYSTEM_GENERATED &&
      couponRedemptionMethod ===
        COUPON_REDEMPTION_METHOD.MERCHANT_APP_SCAN_COUPON_QR_CODE;
    const isPhysical =
      hasCouponMedium && couponMedium === CouponMediumType.Physical;
    const isBoth = hasCouponMedium && couponMedium === CouponMediumType.Both;
    let isValid = true;
    if (isBoth) {
      isValid =
        this.isValidQuantity(physicalQuantity) ||
        this.isValidQuantity(quantity);
    } else if (isPhysical) {
      isValid = this.isValidQuantity(physicalQuantity);
    } else {
      isValid = this.isValidQuantity(quantity);
    }
    return !isValid;
  };

  generateCouponButtonClicked = () => {
    const successAction = () => {
      if (
        this.props.history.location.pathname.includes('edit') ||
        this.props.toSingleCouponList
      ) {
        this.props.history.push({
          pathname: `/coupons/${this.props.couponTemplatePk}`,
          hash: CouponURLFragments.list,
        });
      }
      this.props.dispatch(createAction('downloadAndImport/showTasks')({}));
    };
    if (
      this.props.couponTemplateType === COUPON_SET_TYPES.SYSTEM_GENERATED ||
      (this.props.couponTemplateType ===
        COUPON_SET_TYPES.IMPORT_EXISTING_COUPON &&
        this.props.couponCodeType === COUPON_CODE_TYPE.GENERIC)
    ) {
      let from = ImportResource.generateCodeCoupon;
      if (
        this.props.couponTemplateType ===
          COUPON_SET_TYPES.IMPORT_EXISTING_COUPON &&
        this.props.couponCodeType === COUPON_CODE_TYPE.GENERIC
      ) {
        from = ImportResource.generateGenericCoupon;
      }
      const quantityIntValue = this.state.quantity
        ? parseInt(this.state.quantity)
        : null;
      const physicalQuantityIntValue = this.state.physicalQuantity
        ? parseInt(this.state.physicalQuantity)
        : null;
      if (quantityIntValue && quantityIntValue > 0) {
        this.props.dispatch(
          createAction('downloadAndImport/importFile')({
            from,
            related: {
              templateId: this.props.couponTemplatePk,
              couponQuantity: quantityIntValue,
            },
            successAction,
          }),
        );
      }
      if (physicalQuantityIntValue && physicalQuantityIntValue > 0) {
        this.props.dispatch(
          createAction('downloadAndImport/importFile')({
            from,
            related: {
              templateId: this.props.couponTemplatePk,
              couponPhysicalQuantity: physicalQuantityIntValue,
            },
            successAction,
          }),
        );
      }

      this.modalOnHide();
    } else {
      this.props.dispatch(
        createAction('downloadAndImport/importFile')({
          from: ImportResource.coupon,
          file: this.props.importedFile,
          related: {
            templateId: this.props.couponTemplatePk,
          },
          successAction,
        }),
      );
    }
  };

  renderSystemGeneratedBody = () => {
    const {
      couponRedemptionMethod,
      couponMedium,
      totalNubmerOfGeneratedCoupons,
      totalNubmerOfGeneratedPhysicalCoupons,
    } = this.props;
    const hasCouponMedium =
      couponRedemptionMethod ===
      COUPON_REDEMPTION_METHOD.MERCHANT_APP_SCAN_COUPON_QR_CODE;
    const hasEWallet =
      couponMedium === CouponMediumType.EWallet ||
      couponMedium === CouponMediumType.Both;
    const hasPhysical =
      couponMedium === CouponMediumType.Physical ||
      couponMedium === CouponMediumType.Both;
    if (!hasCouponMedium) {
      return (
        <>
          <CouponGenerateCount
            setQuantity={(value) => {
              this.setState({ quantity: value });
            }}
          />
        </>
      );
    } else {
      return (
        <>
          {hasEWallet ? (
            <CouponGenerateCount
              title={'Total eWallet coupon generated'}
              inputTitle={'Quantity for eWallet coupon this batch'}
              count={totalNubmerOfGeneratedCoupons}
              setQuantity={(value) => {
                this.setState({ quantity: value });
              }}
            />
          ) : null}
          {hasPhysical ? (
            <CouponGenerateCount
              title={'Total Physical coupon generated'}
              inputTitle={'Quantity for Physical coupon this batch'}
              count={totalNubmerOfGeneratedPhysicalCoupons}
              setQuantity={(value) => {
                this.setState({ physicalQuantity: value });
              }}
            />
          ) : null}
        </>
      );
    }
  };

  renderImportBody = () => {
    if (this.props.couponCodeType === COUPON_CODE_TYPE.GENERIC) {
      return this.renderSystemGeneratedBody();
    }
    return (
      <div class="d-flex flex-column">
        <CustomTitleLabel title="Import code in .csv or Import QR code in .csv." />
        <label className="create-message-suggest">
          Please select or drag and drop the .csv files which you want to
          import.
        </label>
        <CustomFileUploader
          title="Select files"
          accept=".csv"
          showFileName={true}
        />
      </div>
    );
  };

  otherBody = () => {
    return (
      <>
        {this.renderRelateBrandView()}
        {this.props.couponTemplateType === COUPON_SET_TYPES.SYSTEM_GENERATED
          ? this.renderSystemGeneratedBody()
          : this.renderImportBody()}
      </>
    );
  };

  render() {
    let method = 'Generate';
    const isImportTypeCoupon =
      this.props.couponTemplateType === COUPON_SET_TYPES.IMPORT_EXISTING_COUPON;
    if (isImportTypeCoupon) {
      method = 'Import';
    }
    return (
      <BasePrompt
        show={this.props.generateCoupon}
        closeAction={() => {
          this.modalOnHide();
          this.props.dispatch({
            type: 'uploadFiles/updateState',
            payload: { importedFile: null },
          });
        }}
        rightButton={{
          text: `${method} Coupons`,
          action: this.generateCouponButtonClicked,
          disabled:
            isImportTypeCoupon &&
            this.props.couponCodeType === COUPON_CODE_TYPE.UNIQUE
              ? !this.props.importedFile
              : this.state.generateButtonDisabled,
          // disabled:
          //   this.props.couponFormat === COUPON_TEMPLATE_TYPE.couponCodeImage &&
          //   this.props.uploading,
          // custom: <CustomFileUploader title="Import .zip" accept=".zip" />,
        }}
        title={`${method} Single Coupons`}
        description={`${method} Single Coupons by using the coupon set "${
          this.props.couponTemplateName ? this.props.couponTemplateName : ''
        }"`}
        otherBody={this.otherBody}
      />
    );
  }
}

export default withRouter(GenerateCouponView);
