import React, { useState } from 'react';
import ContentSections from '../base/ContentSections';
import MembershipInfoCard from '../customer/MembershipInfoCard';
import { useSelector, useDispatch } from 'react-redux';
import MessageOverviewTableSection from './MessageOverviewTableSection';
import {
  BackAndMultipleButtons,
  SaveTempButton,
  SaveButton,
} from '../base/BottomStepComponent';
import { createAction } from '../../utils';
import TestAccountPrompt from './TestAccountPrompt';
import CreateMessageSuccessPrompt from './CreateMessageSuccessPrompt';
import { useLocation } from 'react-router-dom';
import { PermissionCodes } from '../../config/PermissionCodes';
import BasePrompt from '../base/prompt/BasePrompt';
import ListButtonsGroup from '../base/ListButtonsGroup';
import AuthButton from '../base/AuthButton';

function CreateMessageStepFour() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { message } = useSelector((state) => ({
    message: state.createMessage.message,
  }));

  const [showTestPrompt, setShowTestPrompt] = useState(false);
  const [showSentPrompt, setShowSentPrompt] = useState(false);
  const [isTestSend, setIsTestSend] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [showTimeoutPrompt, setShowTimeoutPrompt] = useState(false);

  const sections = [
    <MembershipInfoCard
      title={'PROPERTIES'}
      data={message}
      fields={[
        [{ title: 'Message channel', field: 'displayChannels' }],
        [{ title: 'Related campaign', field: 'relatedCampaignName' }],
        [{ title: 'Target customers', field: 'displayTargetCustomer' }],
        [{ title: 'Target segments', field: 'displayTargetedSegments' }],
        [{ title: 'Delivery Time', field: 'deliveryDate' }],
      ]}
    />,
    <MessageOverviewTableSection data={message} />,
  ];

  const saveButtonAction = (isSaveOnly) => {
    if (new Date(message.scheduledDate) < new Date()) {
      setShowTimeoutPrompt(true);
      return;
    }
    setIsDraft(isSaveOnly);
    if (location.pathname.includes('edit')) {
      dispatch(
        createAction('createMessage/updateMessage')({
          isDraft: isSaveOnly,
          afterActions: () => {
            setIsTestSend(false);
            setShowSentPrompt(true);
          },
        }),
      );
    } else {
      dispatch(
        createAction('createMessage/createMessage')({
          isDraft: isSaveOnly,
          afterActions: () => {
            setIsTestSend(false);
            setShowSentPrompt(isSaveOnly);
          },
        }),
      );
    }
  };

  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <BackAndMultipleButtons
        backAction={() => {
          dispatch(
            createAction('createMessage/stepChange')({
              step: 3,
              isBack: true,
            }),
          );
        }}
        multipleButtons={[
          <ListButtonsGroup
            hideExtraButtonWidth={600}
            hideAllButtonWidth={400}
            hideExtraPopPosition="top"
            hideAllPopPosition="topRight"
            extraButtons={[
              <SaveTempButton
                text="Test Channel"
                action={() => {
                  setShowTestPrompt(true);
                }}
              />,
              <SaveTempButton
                text="Save Only"
                action={() => saveButtonAction(true)}
              />,
            ]}
            extraPopContent={[
              {
                content: 'Test Channel',
                action: () => {
                  setShowTestPrompt(true);
                },
                requires: PermissionCodes.testMessage,
              },
              {
                content: 'Save Only',
                action: () => saveButtonAction(true),
              },
            ]}
            primaryButton={
              <SaveButton
                text="Schedule"
                action={() => saveButtonAction(false)}
              />
            }
            primaryPopContent={{
              requires: PermissionCodes.addAdministratorGroup,
              action: () => saveButtonAction(false),
              content: 'Create Administrator group',
            }}
          />,
          // {
          //   text: 'Test Channel',
          //   action: () => {
          //     setShowTestPrompt(true);
          //   },
          //   requires: PermissionCodes.testMessage,
          // },
          // {
          //   text: 'Save Only',
          //   action: () => saveButtonAction(true),
          // },
          // {
          //   text: 'Schedule',
          //   action: () => saveButtonAction(false),
          // },
        ]}
      />
      <TestAccountPrompt
        show={showTestPrompt}
        onHide={() => setShowTestPrompt(false)}
        onConfirm={(selectedAccount) => {
          setShowTestPrompt(false);
          dispatch(
            createAction('createMessage/testMessageSend')({
              isEdit: location.pathname.includes('edit'),
              customer: selectedAccount,
              afterActions: () => {
                setIsTestSend(true);
                setShowSentPrompt(true);
              },
            }),
          );
        }}
      />
      <CreateMessageSuccessPrompt
        isTest={isTestSend}
        isDraft={isDraft}
        show={showSentPrompt}
        onClose={() => setShowSentPrompt(false)}
      />

      <BasePrompt
        show={showTimeoutPrompt}
        closeAction={() => setShowTimeoutPrompt(false)}
        rightButton={{
          text: 'Reschedule',
          action: () => {
            setShowTimeoutPrompt(false);
            dispatch(
              createAction('createMessage/updateState')({
                currentStep: 2,
              }),
            );
          },
        }}
        leftButton={{
          text: 'Cancel',
          action: () => setShowTimeoutPrompt(false),
        }}
        title={'Schedule timeout'}
        description={
          'Your schecule time is expried, please update delivery time.'
        }
      />
    </>
  );
}

export default CreateMessageStepFour;
