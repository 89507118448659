import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SearchIcon from '../../assets/images/drop_down_filter.svg';
import deleteSelectedButton from '../../assets/images/drop_down_delete_selected.png';
import './ListFilterComponent.scss';
import { Image, Form } from 'react-bootstrap';
import AuthButton from './AuthButton';

function ListFilterComponent(props) {
  const queryString = require('query-string');
  const location = useLocation();

  const onSearch = props.onSearch || (() => {});
  const searchPlaceholder = props.searchPlaceholder || 'Search by name';
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const searchKey = form.searchKey.value;
    console.log('@@submit button clicked: ', searchKey);
    onSearch(searchKey);
  };

  const [searchKey, setSearchKey] = useState();
  useEffect(() => {
    setSearchKey(queryString.parse(location.search)['search']);
  }, [location]);

  // console.log('@@19: ', defaultValue, searchKey);
  return (
    <>
      <Form
        autoComplete="off"
        noValidate
        className={`list-filter-list-search-section ${props.customClass}`}
        onSubmit={handleSubmit}
      >
        <div>
          <Image src={SearchIcon} className="list-filter-search-icon" />
          <input
            name="searchKey"
            value={searchKey}
            onChange={({ target }) => setSearchKey(target.value)}
            type="text"
            className="list-filter-search-field"
            placeholder={searchPlaceholder}
          />
          {props.needDelete ? (
            <Image
              src={deleteSelectedButton}
              className="filterable-dropdown-delete-selected list-filter-delete-icon"
              onClick={(e) => {
                e.stopPropagation();
                console.log('delete');
                setSearchKey('');
              }}
            />
          ) : null}
        </div>
        {props.showSearchButton ? (
          <AuthButton
            title={'Search'}
            type="submit"
            // action={handleSubmit}
            // requires={PermissionCodes.addImportjob}
          />
        ) : null}
      </Form>
    </>
  );
}

export default ListFilterComponent;
