import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PermissionCodes } from '../../../config/PermissionCodes';
import BasePrompt from '../../base/prompt/BasePrompt';
import CustomTitleWithSwitch from '../../base/CustomTitleWithSwitch';

function OutOfStockPrompt({ show = false, onClose = () => {} }) {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => ({
    user: state.users,
  }));

  const hasChangeSettingPermission =
    user?.userPermissions?.filter(
      (val) => val === PermissionCodes.changeCampaignSetting,
    ).length > 0;

  const { displayHideOutOfStockCampaign } = useSelector((state) => ({
    displayHideOutOfStockCampaign:
      state.campaignList.settings?.hideOutOfStockCampaign,
  }));

  const [hideOutOfStockCampaign, setHideOutOfStockCampaign] = useState(null);

  const recoverData = () => {
    setHideOutOfStockCampaign(displayHideOutOfStockCampaign);
  };

  useEffect(() => {
    if (show) {
      dispatch({ type: 'campaignList/getCampaignSettings' });
    }
  }, [show]);

  useEffect(() => {
    recoverData();
  }, [displayHideOutOfStockCampaign]);

  return (
    <BasePrompt
      show={show}
      closeAction={() => {
        recoverData();
        onClose();
      }}
      rightButton={{
        text: 'Confirm',
        action: () => {
          dispatch({
            type: 'campaignList/updateCampaignSettings',
            payload: {
              hideOutOfStockCampaign,
            },
          });
          onClose();
        },
        class: hasChangeSettingPermission ? '' : 'd-none',
      }}
      leftButton={{
        text: `Cancel`,
        action: () => {
          recoverData();
          onClose();
        },
      }}
      title={'Out-of-stock campaign'}
      otherBody={() => {
        return (
          <>
            <CustomTitleWithSwitch
              title="Hide the campaign which is out-of-stock in the campaign list "
              defaultValue={hideOutOfStockCampaign}
              disabled={!hasChangeSettingPermission}
              tips="If on, the frontend campaign list will hide all the out-of-stock campaign. If off, the out-of-stock campaign will show as dimmed. "
              setValue={(value) => {
                setHideOutOfStockCampaign(value);
              }}
            />
          </>
        );
      }}
    />
  );
}

export default OutOfStockPrompt;
