import React, { useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { LanguageConfig, EMAIL_REG } from '../../../config/CustomEnums';
import {
  hasError,
  ReactHookFormErrorMessage,
} from '../../../components/base/ErrorFieldMessage';
import i18n from '../../../I18n';
import { useHistory } from 'react-router-dom';
import BrandSelectDropdown from '../../../components/campaign/campaignCreation/BrandSelectDropdown';
import BasePrompt from '../../../components/base/prompt/BasePrompt';
import { ReactHookFormMarkDown } from '../../../components/base/CustomMarkDownArea';
import { CustomTitleLabel } from '../../../components/earning/CustomBaseComponments';
import { CustomTitleWithDropDownAndInput } from '../../../components/customer/CustomTitleWithDropDown';
import { StoreErrorHandleFields } from './StoreErrorHandleFields';
const countryCodes = [
  { name: '853', pk: '853' },
  { name: '852', pk: '852' },
  { name: '86', pk: '86' },
];

export default function ContentGeneralSection({ language, focusChange }) {
  const { register, watch, setValue, control, formState } = useFormContext();
  const { errors } = formState;
  const watchSelectedBrand = watch('brand');
  const sourceBrandName = watch('displayBrand');
  const watchMobileNumber = watch('phoneNumberSubscriberNumber');
  const watchCountryCode = watch('phoneNumberCountryCode');
  const [showAddBrandPrompt, setShowAddBrandPrompt] = useState(false);
  const history = useHistory();
  const brandSection = (
    <Controller
      control={control}
      name="brand"
      rules={{ required: StoreErrorHandleFields.brand.required }}
      render={() => (
        <>
          <BrandSelectDropdown
            customClass={hasError(errors, 'brand') ? 'field-has-error' : ''}
            title={i18n.t('related_brand_required', {
              locale: 'en',
            })}
            defaultValue={{
              value: watchSelectedBrand,
              label: watchSelectedBrand?.name,
            }}
            onSelectChange={(item) => {
              focusChange('brand');
              if (item.value !== watchSelectedBrand) {
                setValue('brand', item.value, { shouldDirty: true });
              }
            }}
            addButton={{
              title: 'Create Now',
              action: () => {
                setShowAddBrandPrompt(true);
              },
              customClass: 'multiple-select-option-add btn-add-button-common',
            }}
            editable={
              !history.location.pathname?.includes('edit') || !sourceBrandName
            }
            onFocus={() => focusChange('brand')}
          />
          <label className="tips-message">Once set, cannot be changed.</label>
          {language === LanguageConfig.english ? (
            <ReactHookFormErrorMessage errors={errors} id="brand" />
          ) : null}
        </>
      )}
    />
  );

  const mobileNumber = (
    <Controller
      control={control}
      name={'phoneNumberSubscriberNumber'}
      render={() => (
        <CustomTitleWithDropDownAndInput
          title="Mobile number(optional)"
          source={countryCodes}
          defaultValue={watchCountryCode}
          defaultInputValue={watchMobileNumber}
          needFilter={false}
          customClass="dropdown-short"
          inputCustomClass="dropdown-input-long"
          setValue={(value) => {
            if (value !== watchCountryCode) {
              setValue('phoneNumberCountryCode', value, { shouldDirty: true });
            }
          }}
          setInputValue={(value) => {
            if (value !== watchMobileNumber) {
              setValue('phoneNumberSubscriberNumber', value, {
                shouldDirty: true,
              });
            }
          }}
          onFocus={() => {
            focusChange('phoneNumberSubscriberNumber');
          }}
          inputOnFocusChange={() => focusChange('phoneNumberSubscriberNumber')}
        />
      )}
    />
  );

  const email = (
    <>
      <CustomTitleLabel title={'Email(optional)'} />
      <input
        {...register('emailAddress', {
          validate: {
            reg: (value) => {
              if (!value) {
                return true;
              }
              return (
                EMAIL_REG.test(value) ||
                StoreErrorHandleFields.emailAddress.valid
              );
            },
          },
        })}
        type="emailAddress"
        className={`text-input-field ${
          hasError(errors, 'emailAddress') ? 'error-field' : ''
        }`}
        onFocus={() => focusChange('emailAddress')}
      />
      <ReactHookFormErrorMessage errors={errors} id="emailAddress" />
    </>
  );
  return (
    <>
      <ReactHookFormMarkDown
        areaTitle={i18n.t('general', { locale: 'en' })}
        title={{
          label: i18n.t('name', {
            locale: 'en',
          }),
          error:
            language === LanguageConfig.english
              ? hasError(errors, `storeTranslations.en.name`)
              : false,
          required: StoreErrorHandleFields.name.required,
          key: `storeTranslations.${language}.name`,
          focus: () => {
            focusChange('name');
          },
        }}
        content={{
          label: i18n.t('description_optional', {
            locale: 'en',
          }),
          key: `storeTranslations.${language}.description`,
          focus: () => {
            focusChange('description');
          },
        }}
      />
      {language !== LanguageConfig.english ? null : brandSection}
      {language !== LanguageConfig.english ? null : mobileNumber}
      {language !== LanguageConfig.english ? null : email}
      <BasePrompt
        show={showAddBrandPrompt}
        closeAction={() => {
          setShowAddBrandPrompt(false);
        }}
        rightButton={{
          text: `Go to create Brand`,
          action: () => {
            setShowAddBrandPrompt(false);
            history.push({
              pathname: '/brands/create',
              state: {
                from: history.location,
                title: 'Continue to create store?',
                content: 'You can continue to create the store.',
              },
            });
          },
        }}
        title={`Go to create a Brand?`}
        description={`You will leave the store creation process. After you create a new Brand, you can back to this page.`}
      />
    </>
  );
}
