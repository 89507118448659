import React, { Component } from 'react';
import './CouponTemplateOverview.scss';
import { parseCouponSetOverviewDatetime } from '../../../models/CouponUtil';
import BaseOverView from './BaseOverview';
import { connect } from 'react-redux';

@connect(({ createCoupon, users }) => ({
  isSuperuser: users.isSuperuser,
  userWorkingTeams: users.workingTeams?.map((v) => v.pk),
  relatedCampaigns: createCoupon.relatedCampaigns,
}))
class CouponTemplateOverview extends Component {
  render() {
    const titleList = [
      'Total Stock',
      'Total e-coupon(eWallet) stock',
      'Total physical coupon stock',
      'Leftover coupon',
      // 'e-Coupon(eWallet) leftover coupon',
      // 'Physical coupon leftover coupon',
      'e-Coupon(eWallet) stock left',
      'Linked Campaign',
      'Create at',
      'Last Modified',
      'Status',
    ];
    const valueList = [
      (this.props.couponSet?.totalNubmerOfGeneratedCoupons || 0) +
        (this.props.couponSet?.totalNubmerOfGeneratedPhysicalCoupons || 0),
      this.props.couponSet?.totalNubmerOfGeneratedCoupons,
      this.props.couponSet?.totalNubmerOfGeneratedPhysicalCoupons,
      this.props.couponSet?.leftOverStock,
      this.props.couponSet?.stock,
      // this.props.couponSet?.linkedCampaignsName,
      {
        component: (
          <label className="viewer-content coupon-linked-campaign">
            {this.props.relatedCampaigns?.map((item) => {
              if (
                this.props.isSuperuser ||
                item.campaignWorkingTeamIds?.length <= 0 ||
                (item.campaignWorkingTeamIds.length > 0 &&
                  item.campaignWorkingTeamIds?.filter((wt) =>
                    this.props.userWorkingTeams?.includes(wt),
                  ).length > 0)
              ) {
                return <span>{item.name}</span>;
                // return item.name;
              }

              // return 'The content cannot be accessed by your belonging teams';
              return (
                <span className="name-hide">
                  The content cannot be accessed by your belonging teams
                </span>
              );
            }) || '-'}
          </label>
        ),
      },
      this.props.couponSet?.creationDate
        ? parseCouponSetOverviewDatetime(this.props.couponSet?.creationDate)
        : '-',
      this.props.couponSet?.lastModifiedDate
        ? parseCouponSetOverviewDatetime(this.props.couponSet?.lastModifiedDate)
        : '-',
      this.props.couponSet?.status,
    ];
    return <BaseOverView itemTitleList={titleList} itemValueList={valueList} />;
  }
}

export default CouponTemplateOverview;
