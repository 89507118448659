import React, { useState, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import './CouponGeneral.scss';
import {
  COUPON_REDEMPTION_METHOD,
  LanguageConfig,
  PublishTagType,
  STORE_LOADING_TYPE,
} from '../../../config/CustomEnums';
import BaseMutipleSelectorV2 from '../../base/BaseMultipleSelectorV2';
import { CouponErrorFields } from './CouponHandleError';
import {
  errorMessage,
  hasError,
  ReactHookFormErrorMessage,
} from '../../base/ErrorFieldMessage';
import i18n from '../../../I18n';
import { useHistory, useParams } from 'react-router-dom';
import BrandSelectDropdown from '../../campaign/campaignCreation/BrandSelectDropdown';
import BasePrompt from '../../base/prompt/BasePrompt';
import { CustomTitleLabel } from '../../earning/CustomBaseComponments';
import CustomEditor from '../../base/CustomEditor';
import { PermissionCodes } from '../../../config/PermissionCodes';
import AuthButton from '../../base/AuthButton';
import { useDispatch, connect } from 'react-redux';
import SpecificCustomSwitchButton from '../../../containers/merchants/stores/SpecificCustomSwitchButton';
import CustomTitleWithInput from '../../base/CustomTitleWithInput';

const CREATE_NEW_TYPE = {
  brand: 'brand',
  store: 'store',
  none: 'none',
};

const CouponGeneral = ({
  language,
  focusChange,
  appliableStoreList = [],
  brandStoreList = [],
  storeEditMode,
  originalBrandPK,
  originalLinkedStores,
}) => {
  const [createNewType, setCreateNewType] = useState(CREATE_NEW_TYPE.none);
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;
  const createNewText =
    createNewType === CREATE_NEW_TYPE.brand ? 'brand' : 'store';
  const { register, watch, setValue, control, formState, clearErrors } =
    useFormContext();
  const { errors } = formState;
  const watchRedemptionMethod = watch('redemptionMethod');
  const watchSelectedBrand = watch('selectedBrand') || {};
  const watchSelectedStores = watch('selectedStores') || [];
  const watchName = watch(`translations.${language}.name`);
  // const watchShortDescription = watch(
  //   `translations.${language}.shortDescription`,
  // );
  const history = useHistory();

  const getRelatedStores = (keys) => {
    console.log('getRelatedStores: ', keys);
    dispatch({
      type: 'storeModel/doStoreRequest',
      payload: { ...keys, status: PublishTagType.published },
    });
  };

  const stopStoreLoading = () => {
    dispatch({
      type: 'storeModel/updateState',
      payload: { allListLoading: false },
    });
  };

  useEffect(() => {
    console.log('watchSelectedBrand2:', watchSelectedBrand);
    if (!watchSelectedBrand.pk || watchSelectedBrand.pk === originalBrandPK) {
      return;
    }
    dispatch({
      type: 'storeModel/updateState',
      payload: { storeEditMode: true },
    });
  }, [watchSelectedBrand.pk]);

  useEffect(() => {
    console.log('watchSelectedBrand1:', watchSelectedBrand);
    if (!watchSelectedBrand.pk || language !== LanguageConfig.english) {
      return;
    }
    if (id && !storeEditMode) {
      stopStoreLoading();
      return;
    }
    if (!id || storeEditMode) {
      getRelatedStores({
        [STORE_LOADING_TYPE.BRAND]: watchSelectedBrand.pk,
        status: PublishTagType.published,
      });
      return;
    }
    if (!id) {
      return;
    }
    if (originalBrandPK && originalBrandPK !== watchSelectedBrand.pk) {
      return;
    }
    getRelatedStores({
      [STORE_LOADING_TYPE.COUPON]: id,
      notBrandRelate: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchSelectedBrand.pk, storeEditMode]);

  useEffect(() => {
    setValue('selectedStores', appliableStoreList, {
      shouldDirty: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliableStoreList.length]);

  useEffect(() => {
    if (brandStoreList.length) {
      if (watchSelectedBrand.pk === originalBrandPK) {
        setValue('selectedStores', originalLinkedStores, { shouldDirty: true });
      } else {
        setValue('selectedStores', brandStoreList, { shouldDirty: true });
      }
      setValue(
        'selectedBrand',
        { ...watchSelectedBrand, relatedStores: brandStoreList },
        { shouldDirty: true },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandStoreList.length]);

  const brandSection = (
    <Controller
      control={control}
      name="selectedBrand"
      rules={{ required: 'Please provide related brand.' }}
      render={() => (
        <>
          <BrandSelectDropdown
            customClass={
              hasError(errors, 'selectedBrand') ? 'field-has-error' : ''
            }
            title={i18n.t('related_brand_required', {
              locale: 'en',
            })}
            defaultValue={{
              value: watchSelectedBrand,
              label: watchSelectedBrand?.name,
            }}
            onSelectChange={(item) => {
              focusChange('relatedBrand');
              if (item.value !== watchSelectedBrand) {
                setValue('selectedBrand', item.value, { shouldDirty: true });
                setValue('selectedStores', [], {
                  shouldDirty: true,
                });
                clearErrors('selectedStores');
              }
            }}
            addButton={{
              title: 'Create Now',
              action: () => {
                setCreateNewType(CREATE_NEW_TYPE.brand);
              },
              customClass: 'multiple-select-option-add btn-add-button-common',
            }}
            editable={language === LanguageConfig.english}
            onFocus={() => focusChange('relatedBrand')}
          />

          {language === LanguageConfig.english ? (
            <ReactHookFormErrorMessage errors={errors} id="selectedBrand" />
          ) : null}
        </>
      )}
    />
  );

  const enableStoreEdit = () => {
    if (!id || language !== LanguageConfig.english) {
      return null;
    }
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <CustomTitleLabel title="Edit Applicable stores " />
        <SpecificCustomSwitchButton
          checked={storeEditMode || false}
          onChange={(isChecked) => {
            dispatch({
              type: 'storeModel/updateState',
              payload: { storeEditMode: isChecked },
            });
          }}
        />
      </div>
    );
  };

  const renderStoreSection = () => {
    switch (language) {
      case 'en':
        // if (
        //   (watchSelectedBrand?.brandStores || []).length === 0 &&
        //   watchRedemptionMethod ===
        //     COUPON_REDEMPTION_METHOD.MERCHANT_APP_SCAN_COUPON_QR_CODE
        // ) {
        //   return null;
        // }
        if (id && !storeEditMode) {
          return null;
        }
        return (
          <div>
            <Controller
              control={control}
              name="selectedStores"
              render={() => (
                <>
                  <BaseMutipleSelectorV2
                    title={i18n.t('applicable_stores_required', {
                      locale: 'en',
                    })}
                    tips={`This brand don’t have related store. As this coupon set type is “Users scan Store QR code”, so you need add a store to support this coupon set.`}
                    onFocusFunc={() => {
                      focusChange('selectedStores');
                    }}
                    namespace="storeModel"
                    data={{
                      sourceData: brandStoreList,
                      targetData: watchSelectedStores,
                      targetChange: (value) => {
                        setValue('selectedStores', value, {
                          shouldDirty: true,
                        });
                      },
                    }}
                    addButton={{
                      action: () => {
                        setCreateNewType(CREATE_NEW_TYPE.store);
                      },
                      title: 'Add store',
                      requires: PermissionCodes.addStore,
                      customClass:
                        'general-section-add-new btn-add-button-common',
                    }}
                    error={{
                      error: hasError(errors, 'selectedStores'),
                    }}
                  />
                </>
              )}
            />
            <ReactHookFormErrorMessage errors={errors} id="selectedStores" />
          </div>
        );
      default:
        if (watchSelectedStores && watchSelectedStores.length > 0) {
          return (
            <div>
              <CustomTitleLabel
                title={i18n.t('applicable_stores_required', {
                  locale: 'en',
                })}
              />
              {watchSelectedStores.map((store) => {
                return (
                  <div className="second-section-content" key={store.value}>
                    {store.name}
                  </div>
                );
              })}
            </div>
          );
        } else {
          return (
            <div>
              <CustomTitleLabel
                title={i18n.t('applicable_stores_required', {
                  locale: 'en',
                })}
              />
              <div>-</div>
            </div>
          );
        }
    }
  };
  return (
    <>
      <div className="first-section-title">
        {i18n.t('general', { locale: 'en' })}
      </div>
      <div className="second-section">
        <Controller
          name={`translations.${language}.name`}
          control={control}
          render={() => (
            <CustomTitleWithInput
              title={i18n.t('name', { locale: 'en' })}
              defaultValue={watchName}
              useDefaultValue={false}
              setValue={(data) => {
                setValue(`translations.${language}.name`, data, {
                  shouldDirty: true,
                });
              }}
              extra={{
                autoComplete: 'off',
                maxLength: 200,
              }}
              error={{
                id: `translations.${language}.name`,
                error: hasError(errors, `translations.${language}.name`),
                message: errorMessage(errors, `translations.${language}.name`),
              }}
            />
          )}
        />
      </div>
      <div className="second-section">
        <div className="second-title-section">
          <div className="second-section-title">Short description</div>
        </div>
        <Controller
          name={`translations.${language}.shortDescription`}
          control={control}
          rules={
            language === LanguageConfig.english
              ? {
                  required: CouponErrorFields.shortDescription.message,
                }
              : {}
          }
          render={({ field: { value } }) => (
            <CustomEditor
              initialValue={value}
              onValueChange={(data) => {
                setValue(`translations.${language}.shortDescription`, data, {
                  shouldDirty: true,
                });
              }}
              onFocus={() => {
                focusChange('shortDescription');
              }}
              errorMessage={CouponErrorFields.shortDescription.message}
              error={hasError(
                errors,
                `translations.${language}.shortDescription`,
              )}
            />
          )}
        />
      </div>
      {brandSection}
      <div
        // className="second-section"
        hidden={!(watchSelectedBrand?.pk > 0)}
        onFocus={() => focusChange('selectedStores')}
      >
        {enableStoreEdit()}
        {renderStoreSection()}
      </div>
      <BasePrompt
        show={createNewType !== CREATE_NEW_TYPE.none}
        closeAction={() => {
          setCreateNewType(CREATE_NEW_TYPE.none);
        }}
        rightButton={{
          text: `Go to create ${createNewText}`,
          action: () => {
            setCreateNewType(CREATE_NEW_TYPE.none);
            const pathname =
              createNewType === CREATE_NEW_TYPE.brand
                ? '/brands/create'
                : '/stores/create';
            history.push({
              pathname: pathname,
              state: {
                from: history.location,
                title: 'Continue to create coupon?',
                content: 'You can continue to create the coupon.',
              },
            });
          },
        }}
        title={`Go to create a ${createNewText}?`}
        description={`You will leave the coupon creation process. After you create a new ${createNewText}, you can back to this page.`}
      />
    </>
  );
};

const mapPropsToState = (state) => ({
  appliableStoreList: state.storeModel.appliableStoreList || [],
  brandStoreList: state.storeModel.brandStoreList || [],
  storeLoading: state.storeModel.allListLoading,
  storeEditMode: state.storeModel.storeEditMode,
  originalBrandPK: state.createCoupon.couponTemplate?.selectedBrand?.pk,
  // originalLinkedStores: state.createCoupon.couponTemplate?.selectedStores,
  originalLinkedStores: state.storeModel.notPagedAllList,
});

export default connect(mapPropsToState)(CouponGeneral);
