import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { connect, useSelector } from 'react-redux';
import AuthButton from '../../../components/base/AuthButton';
import ContentSections from '../../../components/base/ContentSections';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { createAction } from '../../../utils';
import Loading from '../../../components/base/Loading';
import { LanguageConfig } from '../../../config/CustomEnums';

import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';
import i18n from '../../../I18n';

const AttributeTagDetail = ({ detail, dispatch }) => {
  const history = useHistory();
  const params = useParams();
  const { id } = params;
  useEffect(() => {
    dispatch(createAction('attributeTagKey/getOneDetail')({ id }));
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
    return () => {
      dispatch(createAction('attributeTagKey/clearData')());
    };
  }, [id]);

  const { languages } = useSelector((state) => ({
    languages: state.language.allList,
  }));

  const { attributeTags = {} } = detail;
  const renderTabForLanguage = (language) => {
    const transName =
      language === LanguageConfig.english
        ? detail.name
        : detail.translations?.[language]?.name;
    const name = `[ID:${detail.pk}] ${transName}`;

    const tagNames = Object.keys(attributeTags).map((key) => {
      const transTag = attributeTags[key][language]?.name;
      const parseName = `• [ID:${key}] ${transTag}`;
      return parseName;
    });

    return (
      <MembershipInfoCard
        title={'Information'} //i18n.t('information', { locale: language }) ||
        data={{
          name,
          displayShowInWechat: detail.displayShowInWechat,
          displayShowInGoodieBag: detail.displayShowInGoodieBag,
          order: detail.displayPriority,
          tagNames,
        }}
        fields={[
          [
            {
              title: 'Tag', //i18n.t('tag', { locale: language }) ||
              field: 'name',
            },
          ],
          ...(language === LanguageConfig.english
            ? [
                [
                  {
                    title: 'Show in filter level 1 in WeChat campaign list', //i18n.t('tag', { locale: language }) ||
                    field: 'displayShowInWechat',
                  },
                ],
                [
                  {
                    title: 'Show in filter level 1 in Goodie bag campaign list', //i18n.t('tag', { locale: language }) ||
                    field: 'displayShowInGoodieBag',
                  },
                ],
                [
                  {
                    title: 'Display order', //i18n.t('tag', { locale: language }) ||
                    field: 'order',
                  },
                ],
              ]
            : []),
          [
            {
              title: 'Attribute Tag', //i18n.t('attributeTag', { locale: language }) ||
              field: 'tagNames',
            },
          ],
        ]}
      />
    );
  };

  const tabs = [
    {
      name: 'Detail',
      content: detail.pk ? (
        <div className="scroll-container-common">
          <ContentSections
            hidePreview
            languageTabContent={{
              containers: languages?.map((item) => ({
                container: [renderTabForLanguage(item.code)],
                key: item.code,
                title: item.sourceName,
              })),
            }}
            activeSection={LanguageConfig.english}
          />
        </div>
      ) : (
        <Loading />
      ),
    },
  ];

  const buttons = [
    <AuthButton
      title="Edit"
      action={() => {
        history.push({
          pathname: 'edit/',
        });
      }}
      requires={PermissionCodes.changeTagKey}
    />,
  ];
  return (
    <>
      <CustomListComponent
        caution={{
          detail: '',
          title: detail?.name,
        }}
        defaultActiveKey={tabs[0].name}
        breadcrumb={<CustomBreadcrumb name={detail?.name} />}
        buttons={buttons}
        tabs={tabs}
      />
    </>
  );
};

const mapPropsToState = (state) => ({
  detail: state.attributeTagKey.detail,
});
export default connect(mapPropsToState)(AttributeTagDetail);
