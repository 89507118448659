import { LanguageConfig } from '../../../config/CustomEnums';
import { anchorElementWithId } from '../../../utils';
export const CampaignErrorHandleField = {
  generalName: {
    name: 'generalName',
    message:
      'Please provide a campaign name and ensure the length not more than 200.',
  },
  coverPhoto: {
    name: 'coverPhoto',
    message: 'Please provide a cover photo.',
  },
  squarePhoto: {
    name: 'squarePhoto',
    message: 'Please provide an square photo.',
  },
  shortDescription: {
    name: 'shortDescription',
    message: 'Please provide a short description', //and ensure the length not more than 200.
  },
  customerGroup: {
    name: 'customerGroup',
    message: 'Please provide a target customer group.',
  },
  segment: {
    name: 'customerGroup',
    message: 'Please provide a target segment.',
  },
  activeEndDate: {
    name: 'activeEndDate',
    message:
      'The end date & time of a campaign cannot be on or before the start date and time.',
  },
  activeEndDateInvalid: {
    name: 'activeEndDate',
    message: 'The selected time must be within the Coupon acquire period.',
  },
  fortunebagActiveEndDateInvalid: {
    name: 'activeEndDate',
    message: 'The selected time must be within the Goodie bag active period.',
  },
  visibleEndDate: {
    name: 'visibleEndDate',
    message:
      'The end date & time of a campaign cannot be on or before the start date and time.',
  },
  visibleEndDateInvalid: {
    name: 'visibleEndDate',
    message: 'The selected time must be within the Visible period.',
  },
  requiredPoints: {
    name: 'requiredPoints',
    message: 'Please provide a required points.',
  },
  channels: {
    name: 'publicChannel',
    message: 'Please provide a public channel.',
  },
  markets: {
    name: 'targetMarket ',
    message: 'Please provide a target market.',
  },
  overallLimit: {
    name: 'overallLimit',
    message: 'overall limit should be less than or equal to remaining number',
  },
  overallLimitPer: {
    name: 'overallLimit',
    message: 'overall limit should be more than or equal to acquired number',
  },
  perHeadOwnedLimit: {
    name: 'perHeadOwnedLimit',
    message:
      'per head owned limit should be less than per head limit and greater than 0',
  },
  perHeadLimit: {
    name: 'perHeadLimit',
    message:
      'Per head coupon download limit during the campaign active period should be less than remaining number of single coupon in the coupon set and greater than 0',
  },
  perHeadDownloadLimit: {
    name: 'perHeadDownloadLimit',
    zeroMessage:
      '“max coupon can be download per call [X number can be download]” should be greater than 0',
    lessThanOwnedMessage:
      '“max coupon can be download per call [X number can be download]” should be greater than 0 and less than “Per head owned coupon limit (Optional)”',
    lessThanPerHeadMessage: `“max coupon can be download per call [X number can be download]” should be greater than 0 and less than
       “Per head coupon download limit during the campaign active period (Optional)”`,
    requiredMessage: 'Please input a value',
  },
  offerTypeLabel: {
    name: 'offerTypeLabel',
    message: 'Please provide a offer type label.',
  },
  campaignCategory: {
    name: 'campaignCategory',
    message: 'Please provide a campaign category.',
  },
  attributeTag: {
    name: 'attributeTag',
    message: 'Please provide a attribute tag.',
  },
  applicableStore: {
    name: 'applicableStore',
    message: 'Please provide a applicable store.',
  },
  landingPageUrl: {
    name: 'landingPageUrl',
    message: 'Please provide a valid landing page url.',
  },
  landingPageButtonName: {
    name: 'landingPageButtonName',
    message: 'Please provide landing page button name.',
  },
  linkedBrand: {
    name: 'linkedBrand',
    message: 'Please provide a related brand.',
  },
  rewards: {
    name: 'goodieBagReward',
    message: 'the reward must be greater than 0.',
  },
  statementWordingForCountryCodeLimit: {
    name: 'statementWordingForCountryCodeLimit',
    message: 'Please provide Wording.',
  },
  perHeadDownloadForEachTimeLimit: {
    name: 'perHeadDownloadForEachTime',
    message: `“per 1 amount in front end = x number of coupons added to My Wallet” should be greater than 0 
      and less than “max coupon can be download per call [X number can be download]”`,
    requiredMessage: 'Please input a value',
  },
};

export const firstError = (id, errors, language = LanguageConfig.english) => {
  const firstError = errors[0];
  const error = id?.includes(firstError) && language === LanguageConfig.english;
  if (error) {
    setTimeout(() => {
      anchorElementWithId(id);
    }, 100);
  }
};

export const isShowError = (
  field,
  errors,
  language = LanguageConfig.english,
) => {
  const error =
    errors.indexOf(field) > -1 && language === LanguageConfig.english;
  return error;
};

export const isShowErrorWithLangauge = (
  field,
  errors,
  language = LanguageConfig.english,
) => {
  return errors ? errors[language]?.includes(field) : false;
};
