import React, { useState, useEffect } from 'react';
import ContentSections from '../base/ContentSections';
import { LanguageConfig } from '../../config/CustomEnums';
import MessageGeneralSection from './MessageGeneralSection';
import {
  ContinueWithBackButtons,
  OnlyContinueButton,
} from '../base/BottomStepComponent';
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from '../../utils';
import { useParams } from 'react-router-dom';

function CreateMessageStepTwo() {
  let dataArray = [];
  const dispatch = useDispatch();
  const params = useParams();

  const { errorFields } = useSelector((state) => ({
    errorFields: state.createMessage.errorFields,
  }));

  // const [onSubmit, setOnSubmit] = useState(false);
  const [error, setError] = useState(false);
  const [onCheck, setOnCheck] = useState({
    value: false,
    isBack: false,
  });
  const isChecking = ({ isBack }) => {
    setOnCheck({
      value: true,
      isBack: isBack,
    });
  };

  const stopCheck = () => {
    setOnCheck({
      value: false,
      isBack: false,
    });
  };

  useEffect(() => {
    if (!onCheck.value) {
      setError(errorFields.length > 0);
    } else {
      let formatedData = {};
      dataArray.forEach((item, index) => {
        const language = item.language;
        if (language === LanguageConfig.english) {
          formatedData = {
            ...formatedData,
            ...item,
          };
        } else {
          formatedData = {
            ...formatedData,
            translations: {
              ...formatedData.translations,
              [language]: {
                ...item,
              },
            },
          };
        }
      });
      dispatch(
        createAction('createMessage/stepChange')({
          data: formatedData,
          isBack: onCheck.isBack,
          step: 1,
        }),
      );
      setError(false);
      stopCheck();
    }
  }, [onCheck.value, onCheck.isBack, errorFields.length, dataArray, dispatch]);

  const getTabs = (language) => {
    return [
      <MessageGeneralSection
        language={language}
        onSubmit={onCheck.value}
        onSubmitData={(data) => {
          dataArray.push(data);
        }}
      />,
    ];
  };

  const languageTabConfig = {
    enContainer: getTabs(LanguageConfig.english),
    hantContainer: getTabs(LanguageConfig.traditionalChinese),
    hansContainer: getTabs(LanguageConfig.simplifiedChinese),
  };

  return (
    <>
      <ContentSections
        fieldsError={error}
        languageTabContent={languageTabConfig}
        hidePreview={true}
      />
      {params.id ? (
        <OnlyContinueButton
          continueAction={() => {
            isChecking({ isBack: false });
          }}
        />
      ) : (
        <ContinueWithBackButtons
          backAction={() => {
            isChecking({ isBack: true });
          }}
          continueAction={() => {
            isChecking({ isBack: false });
          }}
        />
      )}
    </>
  );
}

export default CreateMessageStepTwo;
