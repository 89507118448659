import React, { useState, useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { createAction } from '../../../utils';
import { useFormContext } from 'react-hook-form';
import ContentSections from '../../../components/base/ContentSections';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import DeletePrompt from '../../../components/base/DeletePrompt';
import { useDispatch, connect } from 'react-redux';
import {
  LanguageConfig,
  APIStatus,
  DELETE_RELATED_SECTIONS,
} from '../../../config/CustomEnums';
import Loading from '../../../components/base/Loading';
import CampaignCategoryContentSection from '../../../components/campaignCategory/CampaignCategoryContentSection';
import './CreateCampaignCategory.scss'

const CreateCampaignCategoryStepOne = ({
  category,
  createStatus,
  languages,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const { getValues, trigger, setError, clearErrors, setFocus } =
    useFormContext();

  useEffect(() => {
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
  }, []);

  return (
    <>
      {createStatus === APIStatus.calling ? (
        <Loading />
      ) : (
        <ContentSections
          languageTabContent={{
            containers: languages?.map((item) => ({
              container: [
                <CampaignCategoryContentSection language={item.code} />,
              ],
              key: item.code,
              title: item.sourceName,
            })),
          }}
          hidePreview
        />
      )}
      <SaveAndBackButtons
        saveTempText={params.id ? 'Delete' : null}
        saveText={params.id ? 'Update' : 'Save'}
        backAction={() => history.goBack()}
        saveAction={async () => {
          console.log('@54', getValues());
          let isValid = await trigger(['name', 'order', 'isForcedInactive'], {
            shouldFocus: true,
          });
          if (isValid) {
            if (params.id) {
              dispatch({
                type: 'createCampaignCategory/checkAndUpdate',
                payload: getValues(),
              });
            } else {
              dispatch({
                type: 'createCampaignCategory/checkAndSave',
                payload: getValues(),
              });
            }
          }
        }}
        saveTempAction={() => setShowDeletePrompt(true)}
      />
      <DeletePrompt
        message={{
          title: 'Delete this campaign category',
          content: 'Did you confirm to delete this campaign category?',
        }}
        title={'Campaign category'}
        data={category}
        relatedSections={DELETE_RELATED_SECTIONS.CAMPAIGN_CATEGORY}
        show={showDeletePrompt}
        onClose={() => {
          setShowDeletePrompt(false);
        }}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch(
            createAction('createCampaignCategory/deleteCategories')({
              ids: [params.id],
            }),
          );
        }}
      />
    </>
  );
};

const mapPropsToState = (state) => ({
  category: state.createCampaignCategory.category,
  createStatus: state.createCampaignCategory.createStatus,
  languages: state.language.allList,
});

export default connect(mapPropsToState)(CreateCampaignCategoryStepOne);
