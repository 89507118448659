import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { createAction } from '../../../utils';
import ContentSections from '../../../components/base/ContentSections';
import { APIStatus, LanguageConfig } from '../../../config/CustomEnums';
import Loading from '../../../components/base/Loading';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';
import QRCodeCollapse from './QRCodeCollapse';
import { CustomTitleLabel } from '../../../components/earning/CustomBaseComponments';
import i18n from '../../../I18n';
import BasePrompt from '../../../components/base/prompt/BasePrompt';
import StoreShowMorePromt from './StoreShowMorePromt';

const getItemOrderList = (datas) => {
  if (!datas || !datas.length) {
    return '-';
  }
  return datas.map((data) => <li>{data.name}</li>);
};

function StoreDetail() {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  const { store, status, languages } = useSelector((state) => ({
    store: state.storeModel.oneStore,
    status: state.loading.status,
    languages: state.language.allList,
  }));

  const attributeTags = store?.attributeTags?.map(
    (data) => `[ID:tag_${data.pk}] ${data.name}`,
  );

  const getValueFromTranslations = (language, valueKeys) => {
    const data = {};
    const datas = valueKeys?.forEach((valueKey) => {
      data[valueKey] = store?.storeTranslations?.[language]?.[valueKey];
    });

    return data;
  };

  const renderTabForLanguage = (language) => {
    const data = getValueFromTranslations(language, [
      'name',
      'description',
      'address',
    ]);

    return (
      <MembershipInfoCard
        title={i18n.t('content', { locale: 'en' })}
        data={data}
        fields={[
          [
            {
              title: 'Name',
              field: 'name',
            },
          ],
          [
            {
              title: 'Description',
              field: 'description',
              isHtml: true,
            },
          ],
          [
            {
              title: 'Address',
              field: 'address',
            },
          ],
        ]}
      />
    );
  };

  const tabs = [
    {
      name: 'Detail',
      content:
        status === APIStatus.calling ? (
          <Loading />
        ) : (
          <>
            <ContentSections
              hidePreview
              languageTabContent={{
                containers: languages?.map((item) => ({
                  container: [renderTabForLanguage(item.code)],
                  key: item.code,
                  title: item.sourceName,
                })),
              }}
              activeSection={LanguageConfig.english}
              // sectionStyle=""
              // contentStyle=""
            />
            <ContentSections
              sections={[
                <>
                  <MembershipInfoCard
                    title="information"
                    data={store}
                    fields={[
                      [{ title: 'Display order', field: 'displayPriority' }],
                      [{ title: 'District', field: 'displayDistrict' }],
                      [
                        {
                          title: 'Phone number',
                          field: 'displayPhoneNumner',
                        },
                      ],
                      [{ title: 'Email', field: 'emailAddress' }],
                      [{ title: 'Latitude', field: 'latitude' }],
                      [{ title: 'Longitude', field: 'longitude' }],
                      [{ title: 'Brand', field: 'displayBrand' }],
                    ]}
                  />
                  <CustomTitleLabel title={'QR code preview'} />
                  <label>The store QR code is auto generate</label>
                  <QRCodeCollapse
                    pk={store?.pk}
                    codeDisplayImage={store?.codeDisplayImage}
                    codeDownloadImage={store?.codeDownloadImage}
                    codeDownloadImageA5={store?.codeDownloadImageA5}
                  />
                  <CustomTitleLabel title={'attributeTags'} />
                  <StoreShowMorePromt
                    datas={attributeTags}
                    promptTitle={'Attribute tag'}
                  />
                </>,
              ]}
              hidePreview
            />
          </>
        ),
    },
  ];

  const buttons = [
    <AuthButton
      title="Edit"
      action={() => {
        dispatch({ type: 'storeModel/clearData' });
        history.push({
          pathname: 'edit/',
        });
      }}
      requires={PermissionCodes.changeStore}
    />,
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].name);

  useEffect(() => {
    if (params.id) {
      dispatch(createAction('language/getList')({ isSelectorLoad: true }));
      dispatch(
        createAction('storeModel/getOneStore')({ id: params.id, view: true }),
      );
    }
    return () => {
      dispatch({ type: 'storeModel/clearData' });
    };
  }, [dispatch, params.id]);
  return (
    <div className="campaign-category-detail">
      <CustomListComponent
        caution={{
          detail: '',
          title: store?.name,
        }}
        defaultActiveKey={activeTab}
        breadcrumb={<CustomBreadcrumb name={store?.name} />}
        buttons={buttons}
        tabs={tabs}
        onTabChange={(key) => {
          setActiveTab(key);
        }}
      />
    </div>
  );
}

export default StoreDetail;
