import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { hasError, errorMessage } from '../base/ErrorFieldMessage';
import { Collapse } from 'react-bootstrap';
import { GoodieBagErrorHandleField } from './CreateGoodieBagValidate';
import CustomSwitchButton from '../base/CustomSwitchButton';
import CustomDateTimeSelect from '../base/CustomDateTimeSelect';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import { GOODIE_BAG_REWARD_TYPE_OPTIONS } from '../../models/GoodieBagsModel';

function ActivePeriodSection({ rewardType, isOptionD }) {
  const { watch, setValue, formState, control } = useFormContext();
  const errors = formState.errors;
  const isAlwaysActivePeriod = watch('isAlwaysActivePeriod');
  const watchStartDate = watch('startDate');
  const watchEndDate = watch('endDate');
  const watchSkipCheckLimit = watch('skipCheckLimit');
  console.log('@@17: ', watchSkipCheckLimit);

  useEffect(() => {
    if (isOptionD) {
      setValue('isAlwaysActivePeriod', true, { shouldDirty: true });
    }
  }, [isOptionD]);
  return (
    <>
      <label className="create-section-title">Active Period</label>
      <label className="section-short-description">
        Campaign available time. The availble time to acquire the goodie bag and
        also the coupons in this goodie bag.
      </label>

      {rewardType !== GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value ? (
        <>
          <label className="create-section-label create-section-label-bottom-space">
            Skip check credit and quota after customer acquired the coupon
          </label>
          <CustomSwitchButton
            defaultChecked={watchSkipCheckLimit}
            onChange={(value) => {
              setValue('skipCheckLimit', value, { shouldDirty: true });
            }}
          />
        </>
      ) : null}

      <label className="create-section-label create-section-label-bottom-space">
        It is an all-time active campaign
      </label>
      <CustomSwitchButton
        defaultChecked={isAlwaysActivePeriod}
        onChange={(value) => {
          setValue('isAlwaysActivePeriod', value, { shouldDirty: true });
        }}
        disabled={isOptionD}
      />
      <Collapse in={!isAlwaysActivePeriod}>
        <div>
          <label className="create-section-label create-section-label-bottom-space">
            Start date
          </label>
          <CustomDateTimeSelect
            disabled={isAlwaysActivePeriod}
            defaultTime={watchStartDate}
            onTimeChange={(date) => {
              setValue('startDate', date, { shouldDirty: true });
            }}
          />
          <label className="create-section-label create-section-label-bottom-space">
            End date
          </label>
          <CustomDateTimeSelect
            error={hasError(errors, 'endDate')}
            defaultTime={watchEndDate}
            disabled={isAlwaysActivePeriod}
            onTimeChange={(date) => {
              setValue('endDate', date, { shouldDirty: true });
            }}
            seconds={59}
          />
          <ErrorFieldMessage
            id={GoodieBagErrorHandleField.endDate.name}
            error={hasError(errors, 'endDate')}
            message={errorMessage(errors, 'endDate')}
          />
        </div>
      </Collapse>
    </>
  );
}

export default ActivePeriodSection;
