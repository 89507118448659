import React, { useState, useEffect } from 'react';
import './CouponLabelDetail.scss';
import { useHistory, useLocation, useParams } from 'react-router';
import { connect } from 'react-redux';
import AuthButton from '../../../components/base/AuthButton';
import ContentSections from '../../../components/base/ContentSections';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { createAction } from '../../../utils';
import Loading from '../../../components/base/Loading';
import { LanguageConfig } from '../../../config/CustomEnums';
import BaseOverView from '../../../components/coupon/couponList/BaseOverview';

const CouponLabelDetail = ({ detail, dispatch, languages }) => {
  const history = useHistory();
  const params = useParams();
  const { id } = params;
  useEffect(() => {
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
    dispatch(createAction('couponLabel/getOneDetail')({ id }));
    return () => {
      dispatch(createAction('couponLabel/clearData')());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTabForLanguage = (language) => {
    const name =
      language === LanguageConfig.english
        ? detail.name
        : detail.translations?.[language]?.name;

    return (
      <>
        <BaseOverView
          hideSection
          title="information"
          itemTitleList={['Name', 'Order']}
          itemValueList={[name, detail.displayPriority]}
        />
      </>
    );
  };

  const tabs = [
    {
      name: 'Detail',
      content: detail.pk ? (
        <div className="scroll-container-common">
          <ContentSections
            hidePreview
            languageTabContent={{
              containers: languages?.map((item) => ({
                container: [renderTabForLanguage(item.code)],
                key: item.code,
                title: item.sourceName,
              })),
            }}
          />
        </div>
      ) : (
        <Loading />
      ),
    },
  ];

  const buttons = [
    <AuthButton
      title="Edit"
      action={() => {
        history.push({
          pathname: 'edit/',
        });
      }}
      requires={PermissionCodes.changeCouponTemplate}
    />,
  ];
  return (
    <>
      <CustomListComponent
        caution={{
          detail: '',
          title: detail?.name,
        }}
        defaultActiveKey={tabs[0].name}
        breadcrumb={<CustomBreadcrumb name={detail?.name} />}
        buttons={buttons}
        tabs={tabs}
      />
    </>
  );
};

const mapPropsToState = (state) => ({
  detail: state.couponLabel.detail,
  languages: state.language.allList,
});
export default connect(mapPropsToState)(CouponLabelDetail);
