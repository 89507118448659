import { createModel } from './BaseModel';
import {
  getCustomerData,
  getCouponData,
} from '../services/SimpleDashboardApiHelper';
import { getDisplayType } from './CampaignListModel';
import { GOODIE_BAG_REWARD_TYPE_OPTIONS } from './GoodieBagsModel';

export default createModel({
  namespace: 'simpleDashboardModel',
  states: {
    customerData: [],
    loadingCustomer: false,
    couponData: [],
    loadingCoupon: false,
    couponTotalData: [],
    couponDownloadListDisplayFields: [
      { displayName: 'Goodie bag ID', fieldName: 'goodieBagId' },
      { displayName: 'Campaign ID', fieldName: 'campaignId' },
      { displayName: 'Brand', fieldName: 'brand' },
      { displayName: 'Campaign category', fieldName: 'couponCategory' },
      {
        displayName: 'Coupon set name',
        fieldName: 'couponName',
        orderField: 'couponName',
      },
      {
        displayName: 'Total qty',
        fieldName: 'totalQty',
        orderField: 'totalQty',
      },
      {
        displayName: 'Total e-coupon qty',
        fieldName: 'totalECouponQty',
        orderField: 'totalECouponQty',
      },
      {
        displayName: 'Total physical coupon qty',
        fieldName: 'totalPhysicalCouponQty',
        orderField: 'totalPhysicalCouponQty',
      },
      {
        displayName: 'e-Coupon download qty',
        fieldName: 'eCouponDownloadQty',
        orderField: 'eCouponDownloadQty',
      },
      {
        displayName: 'e-Coupon download unique user',
        fieldName: 'eCouponDownloadUniqueUser',
        orderField: 'eCouponDownloadUniqueUser',
      },
      {
        displayName: 'e-Coupon remaining qty',
        fieldName: 'eCouponRemainingQty',
        orderField: 'eCouponRemainingQty',
      },
      {
        displayName: 'e-Coupon remaining  %',
        fieldName: 'eCouponRemaining',
        orderField: 'eCouponRemaining',
      },
      {
        displayName: 'Coupon redeem qty',
        fieldName: 'couponRedeemQty',
        orderField: 'couponRedeemQty',
      },
      {
        displayName: 'e-Coupon redeem unique user',
        fieldName: 'eCouponRedeemUniqueUser',
        orderField: 'eCouponRedeemUniqueUser',
      },
      {
        displayName: 'Coupon redeem %',
        fieldName: 'couponRedeem',
        // orderField: 'couponRedeem',
      },
      {
        displayName: 'Goodie bag reward type',
        fieldName: 'goodiebagRewardType',
        // orderField: 'couponRedeem',
      },
      {
        displayName: 'Campaign type',
        fieldName: 'campaignType',
        // orderField: 'couponRedeem',
      },
    ],
  },
  reducers: {
    updateCouponListData(state, { payload }) {
      if (!payload.data?.[0]) {
        return {
          ...state,
        };
      }

      let total = state.couponTotalData;
      const oneData = [...payload.data[0]];
      //   const oneData = Array.from(payload.data?.[0], (x) => {
      //     return x;
      //   });
      if (total.length <= 0) {
        total = ['Total', ...oneData];
      } else {
        const totalCount =
          parseFloat(total[3].toString()) + parseFloat(oneData[2]);
        const totalDownload =
          parseFloat(total[4].toString()) + parseFloat(oneData[3]);
        const totalRemaining =
          parseFloat(total[6].toString()) + parseFloat(oneData[5]);
        const totalRedeem =
          parseFloat(total[8].toString()) + parseFloat(oneData[7]);
        total = [
          'Total',
          '',
          '',
          totalCount,
          parseFloat(total[4].toString()) + parseFloat(oneData[3]),
          parseFloat(total[5].toString()) + parseFloat(oneData[4]),
          totalRemaining,
          ((totalRemaining / totalCount) * 100).toFixed(2),
          totalRedeem,
          parseFloat(total[9].toString()) + parseFloat(oneData[8]),
          (totalDownload ? (totalRedeem / totalDownload) * 100 : 0).toFixed(2),
        ];
      }
      console.log('@@54: ', total);
      const analyticData = payload.data[0];
      return {
        ...state,
        couponData: [
          ...state.couponData,
          {
            goodieBagId: analyticData[0],
            campaignId: analyticData[1],
            brand: analyticData[2],
            couponCategory: analyticData[3],
            couponName: analyticData[4],
            totalQty: analyticData[5],
            totalECouponQty: analyticData[6],
            totalPhysicalCouponQty: analyticData[7],
            eCouponDownloadQty: analyticData[8],
            eCouponDownloadUniqueUser: analyticData[9],
            eCouponRemainingQty: analyticData[10],
            eCouponRemaining: Math.round(analyticData[11]) + '%',
            couponRedeemQty: analyticData[12],
            eCouponRedeemUniqueUser: analyticData[13],
            couponRedeem: Math.round(analyticData[14]) + '%',
            goodiebagRewardType:
              Object.values(GOODIE_BAG_REWARD_TYPE_OPTIONS).filter(
                (item) => item.value === payload.goodiebagRewardType,
              )?.[0]?.label || '',
            campaignType: getDisplayType(payload.campaignType),
          },
        ],
        couponTotalData: total,
      };
    },
  },
  params: {},
  effects: {
    getCustomerData: [
      function* ({ payload }, { call, put }) {
        const { startDate, endDate } = payload;

        if (startDate && endDate) {
          yield put({
            type: 'updateState',
            payload: {
              loadingCustomer: true,
            },
          });
        }

        const response = yield call(getCustomerData, {
          startDate,
          endDate,
        });
        if (response && response.status === 200) {
          const responseData = response?.data;

          if (!responseData?.errors) {
            yield put({
              type: 'updateState',
              payload: {
                customerData: [
                  [
                    responseData.data.analyticsData?.totalNumberOfNewCustomers
                      ?.value || 0,
                    responseData.data.analyticsData?.totalNumberOfCustomers
                      ?.value || 0,
                  ],
                ],
              },
            });
          }
        }

        yield put({
          type: 'updateState',
          payload: {
            loadingCustomer: false,
          },
        });
      },
      { type: 'takeLatest' },
    ],

    getCouponData: [
      function* ({ payload }, { call, put, select }) {
        const { startDate, endDate } = payload;

        const { starredCampaignList } = yield select((state) => ({
          starredCampaignList: state.campaignList.notPagedAllList,
        }));

        if (starredCampaignList?.length > 0 && startDate && endDate) {
          yield put({
            type: 'updateState',
            payload: {
              loadingCoupon: true,
            },
          });

          for (var index = 0; index < starredCampaignList.length; index++) {
            const element = starredCampaignList[index];

            const response = yield call(getCouponData, {
              startDate,
              endDate,
              campaign: element.pk,
            });
            if (!response || response.status >= 300) {
              continue;
            }
            const responseData = response?.data;
            if (responseData?.errors) {
              continue;
            }

            yield put({
              type: 'updateCouponListData',
              payload: {
                data: responseData?.data?.analyticsData
                  ?.couponTemplateStatusTableBySql,
                campaign: element.pk,
                campaignType: element.type,
                goodiebagRewardType: element.goodieBag?.rewardType,
              },
            });
          }

          // (starredCampaignList || []).forEach((element) => {

          // });
        }

        yield put({
          type: 'updateState',
          payload: {
            loadingCoupon: false,
          },
        });
      },
      { type: 'takeLatest' },
    ],
  },
});
