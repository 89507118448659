import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import { ArrayStringData } from '../../../utils';
import { PublishTagType } from '../../../config/CustomEnums';
import { useDispatch, connect } from 'react-redux';

function Filter({ brandList, attributeTagList, backAction = () => {} }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const parsedSearch = queryString.parse(location.search);
  const searchBrands = parsedSearch['brands'] || '';
  const searchAttributes = parsedSearch['attribute_tags'] || '';
  const searchStatus = parsedSearch['status'] || '';

  const [brands, setBrands] = useState(ArrayStringData(searchBrands));
  const [attributes, setAttributes] = useState(
    ArrayStringData(searchAttributes),
  );
  const [status, setStatus] = useState(searchStatus);

  const [searchBrandList, setSearchBrandList] = useState(brandList);

  const content = [
    {
      title: 'Status',
      data: [
        { name: 'Publish', pk: PublishTagType.published },
        { name: 'Unpublish', pk: PublishTagType.unPublished },
      ],
      value: status,
      setValue: setStatus,
      componment: FilterTypeEnum.singleChoiceSegment,
    },
    {
      title: 'Brands',
      data: searchBrandList,
      value: brands,
      setValue: setBrands,
      componment: FilterTypeEnum.searchableChoice,
      placeholder: 'Search by Name',
      filterAction: (searchKey) => {
        const newData = brandList?.filter((item) => {
          const name = item.name?.toLowerCase() || '';
          return name.includes(searchKey?.toLowerCase() || '');
        });
        setSearchBrandList(newData);
      },
    },
    {
      title: 'Attribute Tags',
      data: attributeTagList,
      value: attributes,
      setValue: setAttributes,
      componment: FilterTypeEnum.choice,
    },
  ];

  useEffect(() => {
    dispatch({ type: 'brand/getAllList', payload: { isAll: true } });
    dispatch({ type: 'attributeTag/getAllList', payload: { isAll: true } });
  }, []);

  useEffect(() => {
    setSearchBrandList(brandList);
  }, [brandList]);

  return <BaseFilter content={content} backAction={backAction} />;
}

const mapPropsToState = (state) => ({
  brandList: state.brand.notPagedAllList,
  attributeTagList: state.attributeTag.notPagedAllList,
});
export default connect(mapPropsToState)(Filter);
