import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  CustomNumberInputWithUnit,
  CustomTitleLabel,
} from '../earning/CustomBaseComponments';

export default function PerHeadSection() {
  const { control, setValue } = useFormContext();
  return (
    <Controller
      control={control}
      name="perHeadLimit"
      render={({ field: { value } }) => (
        <>
          <label className="create-section-title">
            Per Head limit(Optional)
          </label>
          <CustomTitleLabel
            title={
              'Per head goodie bag download limit during the goodie bag active period '
            }
            className={'mb-4'}
          />

          <CustomNumberInputWithUnit
            setValue={(value) => {
              setValue('perHeadLimit', value, { shouldDirty: true });
            }}
            defaultValue={value}
            unit="number of goodie bag(s)"
          />
        </>
      )}
    />
  );
}
