import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, connect } from 'react-redux';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import BaseForm from '../../../components/base/v2/BaseForm';
import { createLogoLabelBreads } from '../../../config/CustomBreadConfig';
import {
  createAction,
  removeFromSessionStorage,
  saveToSessionStorage,
} from '../../../utils';

import { APIStatus, SESSION_KEYS } from '../../../config/CustomEnums';
import CreateOTPChannelContent from './CreateOTPChannelContent';
import Loading from '../../../components/base/Loading';
import BasePrompt from '../../../components/base/prompt/BasePrompt';

const CreateOTPChannel = ({
  defaultValues,
  dispatch,
  hasUpdatedDefaultValues,
  formHasSubmitted,
  apiStatus,
  createUpdateError,
}) => {
  const params = useParams();
  const history = useHistory();
  const { id } = params;
  const queryString = require('query-string');

  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(createAction('otpChannel/getOneDetail')({ id }));
    } else {
      dispatch(createAction('otpChannel/loadFromCookie')());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(createAction('otpChannel/clearData')());
    };
  }, []);

  useEffect(() => {
    if (
      formHasSubmitted &&
      apiStatus === APIStatus.success &&
      !history.location.state?.from
    ) {
      history.push({ pathname: '/otp_channel' });
    }
  }, [formHasSubmitted, apiStatus, history]);

  // useEffect(() => {
  //   if (apiStatus !== APIStatus.success && createUpdateError?.length > 0) {
  //     setShowError(
  //       createUpdateError[0].message ===
  //         'Show in frontend: at least has one method turned on',
  //     );
  //   }
  // }, [createUpdateError]);

  return (
    <>
      <BaseForm
        defaultValues={defaultValues}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={formHasSubmitted}
        tempSave={(save, getValues) => {
          if (save) {
            saveToSessionStorage(
              SESSION_KEYS.OTP_CHANNEL_SESSION_KEY,
              getValues(),
            );
          } else {
            removeFromSessionStorage(SESSION_KEYS.OTP_CHANNEL_SESSION_KEY);
          }
        }}
        continueCreateConfig={{
          onConfirm: () => {
            history.push({
              ...history.location.state?.from,
              hash: queryString.stringify({
                ...queryString.parse(history.location.state?.from?.hash),
                // ...{ newLogoLabel: newLogoLabelPk },
              }),
            });
          },
          onCancel: () => history.push({ pathname: '/otp_channel' }),
          onClose: () => history.push({ pathname: '/otp_channel' }),
        }}
        showFinishPop={
          formHasSubmitted &&
          apiStatus === APIStatus.success &&
          history.location.state?.from
        }
        content={
          id && defaultValues?.name
            ? [<CreateOTPChannelContent />]
            : [<Loading />]
        }
        currentStep={0}
        breadcrumb={
          <CustomBreadcrumb
            name={id ? defaultValues?.name : 'Create OTP channel'}
          />
        }
        caution={{
          detail: 'TBC',
          title: id ? defaultValues?.name : 'Create OTP channel',
        }}
      />
      <BasePrompt
        show={showError}
        closeAction={() => {
          setShowError(false);
        }}
        rightButton={{
          text: ' Ok ',
          action: () => setShowError(false),
        }}
        title={createUpdateError?.[0]?.message?.split(': ')?.[0]}
        description={createUpdateError?.[0]?.message?.split(': ')?.[1]}
      />
    </>
  );
};
const mapPropsToState = (state) => ({
  defaultValues: state.otpChannel.detail,
  hasUpdatedDefaultValues: state.otpChannel.hasUpdatedDefaultValues,
  formHasSubmitted: state.otpChannel.formHasSubmitted,
  apiStatus: state.otpChannel.apiStatus,
  createUpdateError: state.otpChannel.createUpdateError,
});
export default connect(mapPropsToState)(CreateOTPChannel);
