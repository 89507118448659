import React, { useEffect } from 'react';
import CustomListComponent from '../../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { createAction } from '../../../utils';
import { ImportResource } from '../../../models/DownloadImportModel';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import Filter from './Filter';
import ListButtonsGroup from '../../../components/base/ListButtonsGroup';

export const POINT_TRANSACTION_TYPE = {
  CUSTOMER_EARN: 'Customer earn',
  CUSTOMER_USED: 'Customer use',
  ADMIN_EDITED: 'Admin edit',
  EXPIRED: 'Point expired',
};

function PointTransactionList() {
  const queryString = require('query-string');
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const {
    displayFields,
    pointTransactionList,
    totalCount,
    pageInfo,
    totalPage,
  } = useSelector((state) => ({
    displayFields: state.pointTransactionList.listDisplayFields,
    pointTransactionList:
      state.pointTransactionList.currentPagePointTransactionList,
    totalCount: state.pointTransactionList.totalCount,
    pageInfo: state.pointTransactionList.pageInfo,
    totalPage: state.pointTransactionList.totalPage,
  }));

  useEffect(() => {
    const searchDict = queryString.parse(location.search);
    const pointType = searchDict['type'];
    const searchKey = searchDict['search'];
    const stringRank = searchDict['rank'];
    const stringPage = searchDict['page'] || 1;
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);
    dispatch(
      createAction('pointTransactionList/getCurrentPagePointRecords')({
        ...searchDict,
        pointType,
        searchKey,
        rank,
        page,
      }),
    );
  }, [dispatch, location.search, queryString]);

  const exportCSVAction = () => {
    dispatch(
      createAction('downloadAndImport/createDownloadTask')({
        from: ImportResource.pointRecotd,
      }),
    );
  };
  const updatePointAction = () => {
    history.push('/point_records/create');
  };

  const buttons = [
    <ListButtonsGroup
      hideExtraButtonWidth={600}
      hideAllButtonWidth={530}
      extraButtons={[
        <AuthButton
          title="Export .csv"
          customClass="btn-back-button-common btn-download"
          action={exportCSVAction}
          requires={PermissionCodes.addExportjob}
        />,
      ]}
      extraPopContent={[
        {
          requires: PermissionCodes.addExportjob,
          action: exportCSVAction,
          content: 'Export .csv',
        },
      ]}
      primaryButton={
        <AuthButton
          title="Update Point Records"
          action={updatePointAction}
          requires={[
            PermissionCodes.addCustomerPoints,
            PermissionCodes.removeCustomerPoints,
          ]}
        />
      }
      primaryPopContent={{
        requires: [
          PermissionCodes.addCustomerPoints,
          PermissionCodes.removeCustomerPoints,
        ],
        action: updatePointAction,
        content: 'Update Point Records',
      }}
    />,
  ];

  const typeTabs = [
    { key: 'all', name: 'All Types' },
    { key: 'ADD', name: 'Add Points' },
    { key: 'REMOVE', name: 'Remove Points' },
  ];

  const tabs = [
    {
      name: '',
      content: (
        <BaseTabListContainer
          hideTab={false}
          maxTabWidth={300}
          tabs={typeTabs}
          groupActions={[]}
          pageInfo={pageInfo}
          totalCount={totalCount}
          filter={{
            hasFilter: true,
            componment: Filter,
          }}
          listContent={
            <BaseListContainer
              fields={displayFields}
              dataList={pointTransactionList}
              totalPage={totalPage ? totalPage : 0}
              deleteInfo={{
                data: [],
                title: '',
                relatedName: '',
                onComfirm: {},
              }}
              model={'pointTransactionList'}
              permissionGroup={PermissionCodes.pointtransaction}
              actions={['Detail']}
              hideActions={false}
              customClassName="coupon-record-table"
            />
          }
        />
      ),
    },
  ];

  return (
    <CustomListComponent
      caution={{
        detail:
          'All point records are listed here, you can view the point transactions of each customer.',
        title: 'Point records',
      }}
      buttons={buttons}
      breadcrumb={<CustomBreadcrumb />}
      hideTab={true}
      tabs={tabs}
    />
  );
}

export default PointTransactionList;
