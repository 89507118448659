import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ContentSections from '../base/ContentSections';
import CreatePointRecordGeneral from './CreatePointRecordGeneral';
import CreatePointRecordDetail from './CreatePointRecordDetail';
import { SaveAndBackWithOutTempButtons } from '../base/BottomStepComponent';
import { createAction } from '../../utils';
import { useHistory } from 'react-router-dom';
import { pointRecordQueryKey } from './RecordsUrlConfig';

function CreatePointRecordStepTwo() {
  const queryString = require('query-string');
  const dispatch = useDispatch();
  const history = useHistory();
  const { selectedType } = useSelector((state) => ({
    selectedType: state.createPointRecord.selectedType,
  }));

  const firstSection = [
    <CreatePointRecordGeneral />,
    <CreatePointRecordDetail type={selectedType} />,
  ];

  return (
    <>
      <ContentSections sections={firstSection} hidePreview={true} />
      <SaveAndBackWithOutTempButtons
        backAction={() => {
          dispatch(
            createAction('createPointRecord/stepChange')({
              isBack: true,
              step: 1,
            }),
          );
        }}
        saveText={'Save'}
        saveAction={() => {
          dispatch(
            createAction('createPointRecord/checkCreatePointRecordStepTwo')({
              isBack: false,
              afterActions: () => {
                dispatch(createAction('createPointRecord/createPointRecord')());
              },
            }),
          );
        }}
      />
    </>
  );
}

export default CreatePointRecordStepTwo;
