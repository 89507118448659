import React from 'react';
import './ManualUseRecordCard.scss';
import { Button } from 'react-bootstrap';

function ManualUseRecodCard({ couponRecord = {} }) {
  const {
    pk,
    usedDateManual,
    membershipIdManual,
    couponTemplateName,
    couponCode,
    referenceId,
  } = couponRecord;
  return (
    <div className={'card-container'}>
      <div className="title-container">
        <div className="title-id">#{pk}</div>
        <div className="title-date">{usedDateManual}</div>
      </div>
      <div className="title-devide-line" />
      <div className="content-container content-text">
        <div>
          Member ID: <b>{membershipIdManual || '-'}</b>
        </div>
        <div>
          Coupon name: <b>{couponTemplateName || '-'}</b>
        </div>
        <div>
          Coupon code: <b>{couponCode || '-'}</b>
        </div>
        <div>
          Reference ID: <b>{referenceId || '-'}</b>
        </div>
      </div>
      {couponCode && (
        <Button className="view-code-button btn-back-button-common">
          View Code
        </Button>
      )}
    </div>
  );
}

export default ManualUseRecodCard;
