import React, { useEffect, useState } from 'react';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';

import ErrorFieldMessage from '../base/ErrorFieldMessage';

function CustomTitleWithInput({
  title,
  defaultValue,
  setValue,
  error = {},
  type = 'text',
  tips,
  focusChange = () => {},
  extra,
  customTitleClass,
  customClass,
  disabled,
  blurAction = (target) => {},
  useDefaultValue = true,
  tipsAtBottom = false,
  multipleLines = false,
}) {
  // console.log('@@15: ', error);

  return (
    <div className="d-flex flex-column">
      {title ? (
        <CustomTitleLabel title={title} className={customTitleClass} />
      ) : null}
      {tips && !tipsAtBottom ? (
        <label className="tips-message">{tips}</label>
      ) : null}
      {multipleLines ? (
        <textarea
          onChange={({ target }) => setValue(target.value)}
          {...(useDefaultValue
            ? { defaultValue: defaultValue || '' }
            : { value: defaultValue || '' })}
          className={`custom-markdown-area-title ${
            error.error ? 'error-field' : ''
          } custom-markdown-area-title-short ${
            type === 'number' ? 'custom-number-input-short' : ''
          } ${customClass}`}
          onFocus={focusChange}
          {...extra}
          disabled={disabled}
          onBlur={({ target }) => blurAction(target)}
          onKeyDown={(e) => {
            console.log('e.key: ', e.key);
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
        />
      ) : (
        <input
          type={type}
          onChange={({ target }) => setValue(target.value)}
          {...(useDefaultValue
            ? { defaultValue: defaultValue || '' }
            : { value: defaultValue || '' })}
          className={`custom-markdown-area-title ${
            error.error ? 'error-field' : ''
          } custom-markdown-area-title-short ${
            type === 'number' ? 'custom-number-input-short' : ''
          } ${customClass}`}
          onFocus={focusChange}
          {...extra}
          disabled={disabled}
          onBlur={({ target }) => blurAction(target)}
          onKeyDown={(event) => {
            console.log('@@91: ', event.keyCode);
            if (
              type === 'number' &&
              (event.keyCode === 189 || //-
                event.keyCode === 69 || //e
                event.keyCode === 187) //+
            ) {
              event.preventDefault();
              return false;
            }
          }}
        />
      )}

      {tips && tipsAtBottom ? (
        <label className="tips-message">{tips}</label>
      ) : null}
      {error.error ? (
        <ErrorFieldMessage
          id={error.id}
          error={error}
          message={error.message}
        />
      ) : null}
    </div>
  );
}

export default CustomTitleWithInput;
