import React, { useState } from 'react';
import './PublishCampaignPrompt.scss';
import { useHistory } from 'react-router-dom';
import { URLFragments } from '../CampaignUrlConfig';
import NextStepPrompt from '../../base/prompt/NextStepPrompt';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { useDispatch, useSelector } from 'react-redux';
import { copyCampaignLink } from '../../../utils';

function PublishCampaignPrompt(props) {
  const defaultShow = props.show || false;
  const isPublish = props.isPublish;
  const isSave = props.isSave;
  const history = useHistory();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const { campaignId, accessCampaignCopyUrl } = useSelector((state) => ({
    campaignId: state.createCampaign.campaign.pk,
    accessCampaignCopyUrl: state.createCampaign.campaign.accessCampaignCopyUrl,
  }));

  if (!show && defaultShow) {
    setShow(true);
  }

  const getTitle = () => {
    if (isSave) {
      return 'Successfully Saved!';
    }
    if (isPublish) {
      return 'Successfully Published!';
    }
    return 'Successfully UnPublished!';
  };

  const getDescription = () => {
    if (isSave) {
      return `Campaign is successfully saved.
      You can publish by edit the status.`;
    }
    if (isPublish) {
      return `Campaign is successfully published.`;
    }
    return `Campaign is successfully unpublished.
    You can publish again by edit the status.`;
  };

  const description = getDescription();
  const title = getTitle();
  const buttons = [
    {
      text: 'Back to campaign listing page',
      action: () =>
        history.push({
          pathname: '/campaigns',
          hash: URLFragments.list,
        }),
      requires: PermissionCodes.changeCampaign,
    },
    {
      text: 'Continue edit',
      action: () => {
        window.location.href = `/campaigns/${props.continueId}/edit/`;
      },
    },
  ];

  if (accessCampaignCopyUrl) {
    buttons.unshift({
      text: 'Copy Campaign’s URL',
      action: () => {
        copyCampaignLink(dispatch, campaignId);
      },
      requires: PermissionCodes.campaign,
      neverDismiss: true,
    });
  }

  return (
    <NextStepPrompt
      show={show}
      title={title}
      description={`${description}${
        accessCampaignCopyUrl
          ? 'You can copy the campaign URL and share this campaign'
          : ''
      }`}
      steps={null}
      buttons={buttons}
    />
  );
}

export default PublishCampaignPrompt;
