import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, connect } from 'react-redux';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import BaseForm from '../../../components/base/v2/BaseForm';
import { createLogoLabelBreads } from '../../../config/CustomBreadConfig';
import { createAction } from '../../../utils';
import CreateLogoLabelStepOne from '../../../components/logoLabel/CreateLogoLabelStepOne';
import {
  removeLogoLabelSessionStroage,
  saveLogoLabelToSessionStorage,
} from '../../../models/LogoLabelModel';
import { APIStatus } from '../../../config/CustomEnums';

const CreateLogoLabel = ({
  defaultValues,
  dispatch,
  hasUpdatedDefaultValues,
  formHasSubmitted,
  apiStatus,
  newLogoLabelPk,
}) => {
  const params = useParams();
  const history = useHistory();
  const { id } = params;
  const queryString = require('query-string');
  useEffect(() => {
    if (id) {
      dispatch(createAction('logoLabel/getOneDetail')({ id }));
    } else {
      dispatch(createAction('logoLabel/loadLogoLabelFromCookie')());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(createAction('logoLabel/clearData')());
    };
  }, []);

  useEffect(() => {
    if (
      formHasSubmitted &&
      apiStatus === APIStatus.success &&
      !history.location.state?.from
    ) {
      history.push({
        pathname: '/logo_label',
        search: '?sort=displayPriority',
      });
    }
  }, [formHasSubmitted, apiStatus, history, newLogoLabelPk]);

  return (
    <>
      <BaseForm
        defaultValues={defaultValues}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={formHasSubmitted}
        tempSave={(save, getValues) => {
          if (save) {
            saveLogoLabelToSessionStorage(getValues());
          } else {
            removeLogoLabelSessionStroage();
          }
        }}
        continueCreateConfig={{
          onConfirm: () => {
            history.push({
              ...history.location.state?.from,
              hash: queryString.stringify({
                ...queryString.parse(history.location.state?.from?.hash),
                ...{ newLogoLabel: newLogoLabelPk },
              }),
            });
          },
          onCancel: () =>
            history.push({
              pathname: '/logo_label',
              search: '?sort=displayPriority',
            }),
          onClose: () =>
            history.push({
              pathname: '/logo_label',
              search: '?sort=displayPriority',
            }),
        }}
        showFinishPop={
          formHasSubmitted &&
          apiStatus === APIStatus.success &&
          history.location.state?.from
        }
        content={[<CreateLogoLabelStepOne />]}
        currentStep={0}
        breadcrumb={
          <CustomBreadcrumb
            breadcrumbs={createLogoLabelBreads(id, defaultValues?.name)}
          />
        }
        caution={{
          detail: 'TBC',
          title: id ? defaultValues?.name : 'Create Logo label',
        }}
      />
    </>
  );
};
const mapPropsToState = (state) => ({
  defaultValues: state.logoLabel.detail,
  hasUpdatedDefaultValues: state.logoLabel.detail.hasUpdatedDefaultValues,
  formHasSubmitted: state.logoLabel.formHasSubmitted,
  apiStatus: state.logoLabel.apiStatus,
  newLogoLabelPk: state.logoLabel?.newLogoLabelPk,
});
export default connect(mapPropsToState)(CreateLogoLabel);
