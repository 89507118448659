import React, { useEffect, useState, useRef } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import { ArrayStringData, createAction } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';

function Filter({ backAction = () => {} }) {
  const location = useLocation();
  const dispatch = useDispatch();

  const parsedSearch = queryString.parse(location.search);
  const searchMarket = parsedSearch['market'] || '';

  const { marketList } = useSelector((state) => ({
    marketList: state.targetMarket.allList,
  }));

  useEffect(() => {
    dispatch(createAction('targetMarket/getList')({ isSelectorLoad: true }));
  }, []);

  const [market, setMarket] = useState(ArrayStringData(searchMarket));

  const content = [
    {
      title: 'Market',
      data: marketList.map((item) => item),
      value: market,
      setValue: setMarket,
      componment: FilterTypeEnum.choice,
      className: 'fit-content-height-area',
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

export default Filter;
