import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { createAction } from '../../../utils';
import './CouponOverviewFilter.scss';
import i18n from '../../../I18n';
import { CustomTitleWithDropDown } from '../../customer/CustomTitleWithDropDown';
import ExportRankingAndPerformanceReport from '../../base/prompt/ExportRankingAndPerformanceReport';
import { CustomTitleLabel } from '../../earning/CustomBaseComponments';
import { CheckboxItem } from '../../base/BaseFilter';

const starredOptions = [
  { label: 'Starred coupon set', value: 'true' },
  { label: 'Non-Starred coupon set', value: 'false' },
];

const FilterSection = ({ brandList, couponsOverviewDate, propsCouponsOverviewDate, couponSetList }) => {
  const dispatch = useDispatch();
  const [filterByBrands, setFilterByBrands] = useState();
  const [filterByCouponSet, setFilterByCouponSet] = useState();
  const [showExportRakingReportPrompt, setShowExportRakingReportPrompt] =
    useState(false);
  const [
    showExportPerformanceReportPrompt,
    setShowExportPerformanceReportPrompt,
  ] = useState(false);

  const [filterIsStarred, setFilterIsStarred] = useState();

  const getCouponOverviewCouponsStatus = (brand, couponSet, isStarred) => {
    let campaignsLabel = 'all';
    if (isStarred === 'true') {
      campaignsLabel = 'starred';
    } else if (isStarred === 'false') {
      campaignsLabel = 'non-starred';
    }

    if (couponSet?.value?.pk) {
      return `Showing result of ${campaignsLabel} campaign(s) from coupon set ID: ${couponSet?.value?.pk} of ${brand?.label}`;
    } else if (!couponSet?.value?.pk && brand?.value) {
      return `Showing result of ${campaignsLabel} campaign(s) from ${brand?.label}`;
    }
    return `Showing result of ${campaignsLabel} campaign(s) from all brand`;
  };

  useEffect(() => {
    setFilterByCouponSet({ label: null, value: null });
    dispatch(
      createAction('couponList/getCurrentPageTemplateList')({
        brandIn: filterByBrands?.value?.pk,
        isSelectorByOverview: true,
        getAll: 'false',
      }),
    );
  }, [filterByBrands]);

  useEffect(() => {
    setFilterByCouponSet({ label: null, value: null });
    dispatch(
      createAction('couponList/getCurrentPageTemplateList')({
        brandIn: filterByBrands?.value?.pk,
        starred_campaign: filterIsStarred?.value?.value
          ? filterIsStarred?.value?.value.toString()
          : null,
        isSelectorByOverview: true,
        getAll: 'false',
      }),
    );
  }, [filterIsStarred]);

  useEffect(() => {
    dispatch(
      createAction('overview/updateState')({
        couponOverviewFilter: {
          brand: filterByBrands?.value,
          couponSet: filterByCouponSet?.value,
          isStarred: filterIsStarred?.value?.value,
        },
        couponOverviewCouponsStatus: getCouponOverviewCouponsStatus(
          filterByBrands,
          filterByCouponSet,
          filterIsStarred?.value?.value,
        ),
      }),
    );
  }, [filterByBrands, filterByCouponSet, filterIsStarred]);

  return (
    <>
      <div className="coupon-overview-filter">
        <ExportRankingAndPerformanceReport
          showExportRakingReportPrompt={showExportRakingReportPrompt}
          setShowExportRakingReportPrompt={setShowExportRakingReportPrompt}
          showExportPerformanceReportPrompt={showExportPerformanceReportPrompt}
          setShowExportPerformanceReportPrompt={
            setShowExportPerformanceReportPrompt
          }
          overviewDate={couponsOverviewDate?.startDate ? couponsOverviewDate : propsCouponsOverviewDate}
          preSelectedBrand={filterByBrands}
          preSelectedCouponSet={filterByCouponSet}
          preSelectedIsStarred={filterIsStarred?.value?.value}
          fromModel="COUPON"
        />
        <div className="coupon-overview-filter-head">
          <label className="coupon-overview-filter-title">FILTER</label>
          {filterByBrands?.value || filterByCouponSet?.value ? (
            <label
              className="coupon-overview-filter-reset-all"
              onClick={() => {
                setFilterByBrands({ label: null, value: null });
                setFilterByCouponSet({ label: null, value: null });
              }}
            >
              Reset All
            </label>
          ) : (
            <></>
          )}
        </div>
        <div className="coupon-overview-brand-select">
          <CustomTitleWithDropDown
            customClass={''}
            title={'Filter by brands'}
            source={brandList}
            placeholder={'Search by brand ID, brand name'}
            labelContainPk={true}
            defaultValue={
              filterByBrands?.value
                ? filterByBrands
                : { label: 'All Brands', value: null }
            }
            setValue={setFilterByBrands}
            loadMoreAction={'brand/getCurrentPageBrands'}
            filterAction={'brand/getCurrentPageBrands'}
          // addButtonInfo={}
          // onFocus={}
          />
        </div>

        <div className="campaign-overview-brand-select">
          <CustomTitleWithDropDown
            customClass={''}
            title={'Filter by from starred coupon set'}
            tips={
              'Starred coupon set means coupon set linked to starred campagin '
            }
            source={starredOptions}
            defaultValue={
              filterIsStarred?.value
                ? starredOptions.filter(
                  (item) => item.value === filterIsStarred?.value?.value,
                )?.[0]
                : { label: 'All coupon set', value: null }
            }
            setValue={(value) => {
              setFilterIsStarred(value);
            }}
            needFilter={false}
          />
        </div>

        {filterByBrands?.value || filterIsStarred?.value ? (
          <div className="coupon-overview-coupon-set-select">
            <CustomTitleWithDropDown
              customClass={''}
              title={'Filter by coupon set'}
              source={couponSetList}
              placeholder={'Search by coupon set ID, coupon set name'}
              labelContainPk={true}
              defaultValue={
                filterByCouponSet?.value
                  ? filterByCouponSet
                  : {
                    label: `All Coupon Sets${filterIsStarred?.value ? ' related to ' : ''
                      } ${filterIsStarred?.value?.value === 'true'
                        ? 'starred campaign'
                        : filterIsStarred?.value
                          ? 'non-starred campaign'
                          : ''
                      }`,
                    value: null,
                  }
              }
              setValue={setFilterByCouponSet}
              loadMoreAction={'couponList/getCurrentPageTemplateList'}
              filterAction={'couponList/getCurrentPageTemplateList'}
              defaultFilter={{
                isSelectorByOverview: true,
                ...(filterByBrands?.value?.pk
                  ? { brand: filterByBrands?.value?.pk }
                  : null),
                ...(filterIsStarred?.value
                  ? { starred_campaign: filterIsStarred?.value?.value }
                  : null),
                getAll: 'false',
              }}
            // addButtonInfo={}
            // onFocus={}
            />
          </div>
        ) : (
          <></>
        )}

        {/* <div className="d-flex flex-column">
          <CustomTitleLabel title="Filter by from starred campaign" />
          <label className="tips-message">
            Unselect all checkboxes (default) means showing data of both coupon
            sets which linked or un-linked the campaign.
          </label>
          <div className="display-flex-area">
            {[
              { label: 'Starred', value: 'true' },
              { label: 'Non- Starred', value: 'false' },
            ].map((item, index) => {
              return (
                <CheckboxItem
                  index={index}
                  title={item.label}
                  defaultValue={filterIsStarred === item.value}
                  customClass={`platform-checkbox ${
                    index === 0 ? '' : 'platform-checkbox-notfirst'
                  }`}
                  onChange={() => {
                    if (filterIsStarred === item.value) {
                      setFilterIsStarred();
                    } else {
                      setFilterIsStarred(item.value);
                    }
                  }}
                />
              );
            })}
          </div>
        </div> */}
      </div>
    </>
  );
};

const mapPropsToState = (state) => ({
  brandList: state.brand.brandList,
  couponSetList: state.couponList.couponSetListAllByOverview,
  couponsOverviewDate: state.overview.couponsOverviewDate,
});

export default connect(mapPropsToState)(FilterSection);
