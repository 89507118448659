import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from '../../../utils';
import { CustomTitleWithDropDown } from '../../customer/CustomTitleWithDropDown';
import { CustomTitleLabel } from '../../earning/CustomBaseComponments';
import './BrandSelectDropdown.scss';
function BrandSelectDropdown(props) {
  const customClass = props.customClass || '';
  const title = props.title;
  const defaultValue = props.defaultValue;
  const onSelectChange = props.onSelectChange || (() => {});
  const addButton = props.addButton;
  const editable = props.editable;
  const onFocus = props.onFocus || (() => {});
  const dispatch = useDispatch();
  const { brandList } = useSelector((state) => ({
    brandList: state.brand.brandList,
  }));

  // const onSelectChange = (value) => {
  //   dispatch({
  //     type: 'createCampaign/updateCampaign',
  //     payload: { linkedBrand: value?.value },
  //   });
  // };

  useEffect(() => {
    dispatch(createAction('brand/getCurrentPageBrands')({isSelectorLoad: true}));
  }, [dispatch]);

  if (editable)
    return (
      <CustomTitleWithDropDown
        customClass={customClass}
        title={title}
        source={brandList}
        defaultValue={defaultValue}
        setValue={onSelectChange}
        loadMoreAction={'brand/getCurrentPageBrands'}
        filterAction={'brand/getCurrentPageBrands'}
        addButtonInfo={addButton}
        onFocus={onFocus}
      />
    );
  else
    return (
      <div className="brand-select-dropdown-container">
        <CustomTitleLabel title={title} />
        <label>{defaultValue?.label || '-'}</label>
      </div>
    );
}

export default BrandSelectDropdown;
