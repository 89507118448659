import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomerFilterableDropdown from './CustomerFilterableDropdown';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import CustomDateTimeSelect from '../base/CustomDateTimeSelect';
import StoreSelectDropdown from './StoreSelectDropdown';
import { TRANSACTION_RECORD_TYPE } from '../../containers/record/records/TransactionList';
import OfflineEventTypeSelectDropdown from './OfflineEventTypeSelectDropdown';
import StaffNameSelectDropdown from './StaffNameSelectDropdown';
import CustomEditor from '../base/CustomEditor';
import { createAction } from '../../utils';
import {
  isShowError,
  firstError,
} from '../campaign/campaignCreation/CreateCampaignHandleError';
import { CreateTransactionError } from '../../models/CreateTransactionModel';
import { LanguageConfig } from '../../config/CustomEnums';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import { useLocation } from 'react-router-dom';
import { PermissionCodes } from '../../config/PermissionCodes';

function TransactionGeneralSection() {
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    transactionType,
    selectedCustomer,
    selectedStore,
    transactionDate,
    offlineEventType,
    offlineDisplayEventType,
    staffName,
    posInvoiceId,
    invoiceId,
    remarks,
    errorFields,
  } = useSelector((state) => ({
    transactionType: state.createTransaction.transaction.transactionDisplayType,
    selectedCustomer: state.createTransaction.transaction.customer,
    selectedStore: state.createTransaction.transaction.store,
    transactionDate: state.createTransaction.transaction.transactionDate,
    offlineEventType: state.createTransaction.transaction.offlineEventType,
    offlineDisplayEventType:
      state.createTransaction.transaction.displayOfflineEventType,
    staffName: state.createTransaction.transaction.staffName,
    posInvoiceId: state.createTransaction.transaction.posInvoiceId,
    invoiceId: state.createTransaction.transaction.invoiceId,
    remarks: state.createTransaction.transaction.remarks,
    errorFields: state.createTransaction.errorFields,
  }));

  useEffect(() => {
    firstError(
      CreateTransactionError.transactionDate.name,
      errorFields,
      LanguageConfig.english,
    );
    firstError(
      CreateTransactionError.offlineEventType.name,
      errorFields,
      LanguageConfig.english,
    );
    firstError(
      CreateTransactionError.posInvoiceId.name,
      errorFields,
      LanguageConfig.english,
    );
    firstError(
      CreateTransactionError.invoiceId.name,
      errorFields,
      LanguageConfig.english,
    );
    firstError(
      CreateTransactionError.offlineEventType.name,
      errorFields,
      LanguageConfig.english,
    );
    firstError(
      CreateTransactionError.staffName.name,
      errorFields,
      LanguageConfig.english,
    );
  }, [errorFields]);

  const isError = (fieldName) => {
    return isShowError(fieldName, errorFields, LanguageConfig.english);
  };

  const invoiceIdError = () => {
    let isInvoiceIDError = false;
    if (transactionType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS) {
      isInvoiceIDError = isError(CreateTransactionError.posInvoiceId.name);
    } else if (
      transactionType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM
    ) {
      isInvoiceIDError = isError(CreateTransactionError.invoiceId.name);
    }
    return isInvoiceIDError;
  };

  const [date, setDate] = useState(
    transactionDate ? new Date(transactionDate) : new Date(),
  );

  useEffect(() => {
    if (transactionDate) {
      setDate(new Date(transactionDate));
    }
  }, [transactionDate]);

  return (
    <>
      <div className="first-section-title">GENERAL</div>
      <CustomerFilterableDropdown
        title={'Customer'}
        errorName={CreateTransactionError.customer.name}
        errorMessage={CreateTransactionError.customer.message}
        errorFields={errorFields}
        defaultSelectedCustomer={{
          label: selectedCustomer.owner,
          value: selectedCustomer,
        }}
        needLevelFilter={true}
        selectCustomer={(customer) => {
          dispatch(
            createAction('createTransaction/updateTransactionState')({
              customer: customer.value,
            }),
          );
        }}
      />
      <div>
        <CustomTitleLabel title="Transaction date" />
        <CustomDateTimeSelect
          defaultTime={date ? new Date(date) : new Date()}
          error={isShowError(
            CreateTransactionError.transactionDate.name,
            errorFields,
            LanguageConfig.english,
          )}
          onTimeChange={(date) => {
            dispatch(
              createAction('createTransaction/updateTransactionState')({
                transactionDate: date,
              }),
            );
          }}
        />
        <ErrorFieldMessage
          id={CreateTransactionError.transactionDate.name}
          error={isError(CreateTransactionError.transactionDate.name)}
          message={CreateTransactionError.transactionDate.message}
        />
      </div>
      <StoreSelectDropdown
        selectedStore={selectedStore}
        selectStore={(store) => {
          dispatch(
            createAction('createTransaction/updateTransactionState')({
              store: store,
            }),
          );
        }}
        hideAddButton={false}
        addButtonRequires={PermissionCodes.addStore}
      />
      <div>
        <CustomTitleLabel
          title={
            transactionType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS
              ? 'Pos invoice id'
              : 'Invoice id'
          }
        />
        <div>
          <input
            type="text"
            className={`text-input-field ${
              invoiceIdError() ? 'error-field' : ''
            }`}
            onChange={(e) => {
              if (
                transactionType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS
              ) {
                dispatch(
                  createAction('createTransaction/updateTransactionState')({
                    posInvoiceId: e.target.value,
                  }),
                );
              } else if (
                transactionType ===
                TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM
              ) {
                dispatch(
                  createAction('createTransaction/updateTransactionState')({
                    invoiceId: e.target.value,
                  }),
                );
              }
            }}
            value={
              transactionType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS
                ? posInvoiceId
                : invoiceId
            }
          />
        </div>
        <div>
          <ErrorFieldMessage
            id={
              transactionType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS
                ? CreateTransactionError.posInvoiceId.name
                : CreateTransactionError.invoiceId.name
            }
            error={invoiceIdError()}
            message={
              transactionType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS
                ? CreateTransactionError.posInvoiceId.message
                : CreateTransactionError.invoiceId.message
            }
          />
        </div>
      </div>
      {transactionType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM &&
      location.pathname.includes('create') ? null : (
        <div>
          <OfflineEventTypeSelectDropdown
            transactionType={transactionType}
            defaultEventType={offlineEventType}
            defaultDisplayEventType={offlineDisplayEventType}
            selectEventType={(item) => {
              dispatch(
                createAction('createTransaction/updateTransactionState')({
                  offlineEventType: item.value,
                  displayOfflineEventType: item.label,
                }),
              );
            }}
          />
          <ErrorFieldMessage
            id={CreateTransactionError.offlineEventType.name}
            error={isError(CreateTransactionError.offlineEventType.name)}
            message={CreateTransactionError.offlineEventType.message}
          />
        </div>
      )}
      <div>
        {/* <StaffNameSelectDropdown
          selectedStaff={staffName}
          selectStaff={(data) => {
            dispatch(
              createAction('createTransaction/updateTransactionState')({
                staffName: data,
              }),
            );
          }}
        /> */}
        <CustomTitleLabel title="Staff name" />
        <div>
          <input
            type="text"
            className={`text-input-field ${
              isError(CreateTransactionError.staffName.name)
                ? 'error-field'
                : ''
            }`}
            onChange={(e) => {
              dispatch(
                createAction('createTransaction/updateTransactionState')({
                  staffName: e.target.value,
                }),
              );
            }}
            value={staffName || ''}
          />
        </div>

        <ErrorFieldMessage
          id={CreateTransactionError.staffName.name}
          error={isError(CreateTransactionError.staffName.name)}
          message={CreateTransactionError.staffName.message}
        />
      </div>
      <div>
        <CustomTitleLabel title="Remarks (optional)" />
        <CustomEditor
          initialValue={remarks}
          onValueChange={(data) => {
            dispatch(
              createAction('createTransaction/updateTransactionState')({
                remarks: data,
              }),
            );
          }}
        />
      </div>
    </>
  );
}

export default TransactionGeneralSection;
