import React, { useEffect, useRef } from 'react';
import { withResizeDetector } from 'react-resize-detector';
import './PreviewBody.scss';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import CustomViewer from '../../../../components/base/CustomViewer';
import { decodedStripedHtml } from '../../../../utils';
import i18n from '../../../../I18n';

SwiperCore.use([Pagination, Autoplay]);

export const getTranslationField = ({ language, data, field, campaign }) => {
  const originData = data || campaign;

  return (
    originData?.translations?.[language?.code]?.[field] || originData?.[field]
  );
};

function getTransform(el) {
  var results = window
    .getComputedStyle(el, null)
    .getPropertyValue('-webkit-transform')
    .match(
      /matrix(?:(3d)\(\d+(?:, \d+)*(?:, (\d+))(?:, (\d+))(?:, (\d+)), \d+\)|\(\d+(?:, \d+)*(?:, (\d+))(?:, (\d+))\))/,
    );

  if (!results) return [0, 0, 0];
  if (results[1] == '3d') return results.slice(2, 5);

  results.push(0);
  return results.slice(5, 8);
}

function getSlidesOffsetBefore() {
  let browserWidth = document.body.clientWidth;
  return browserWidth > 768 ? browserWidth * 0.145 : browserWidth * 0.1;
}

function SwiperTourCard({ datas, language, swiperConfig = {} }) {
  const mySwiper = useRef();

  return (
    <Swiper
      init={true}
      loop={false}
      className={`itinerary-image-container`}
      pagination={{
        clickable: true,
        renderBullet: function (index, className) {
          return '';
        },
      }}
      ref={mySwiper}
      {...swiperConfig}
    >
      {datas.map((item, index) => (
        <SwiperSlide key={`slide-${index}`} className="swiper-slide">
          <li>
            <div>
              <div className="our-itinerary-details">
                <img src={item.coverPhoto} alt="" />
                <div className="content">
                  <div>
                    <div className="tt">
                      {(index + 1).toString().padStart(2, '0')}
                    </div>
                    <h5>{item.title}</h5>
                  </div>

                  <p>{decodedStripedHtml(item.description)}</p>
                </div>
              </div>
              <div className="more-mask-layer">
                <div
                  className="more-btn"
                  onClick={(e) => {
                    e.target.parentNode.parentNode.firstChild.querySelector(
                      '.content',
                    ).style.height = 'auto';
                    e.target.parentNode.parentNode.parentNode.parentNode.style.height =
                      'auto';
                    e.target.parentNode.style.display = 'none';
                  }}
                >
                  {i18n.t('more_genericterms_condition_content', {
                    locale: language?.code,
                  })}
                </div>
              </div>
            </div>
          </li>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

function TourCard({ language, width, campaign, isDHK = false }) {
  const screenWidth = width || window.screen.width;
  const tours = campaign?.tourCards;
  const datas = tours?.map((item) => {
    const title = getTranslationField({
      language,
      data: item,
      field: 'title',
      campaign,
    });
    const description = getTranslationField({
      language,
      data: item,
      field: 'description',
      campaign,
    });
    const coverPhoto = getTranslationField({
      language,
      data: item,
      field: 'coverPhoto',
      campaign,
    });
    return {
      title,
      description,
      coverPhoto,
    };
  });
  if (!datas?.length) {
    return <div />;
  }

  return (
    <div className={`our-itinerary ${campaign.isStarred ? 'star' : ''}`}>
      <h3>
        {getTranslationField({
          language,
          field: 'tourCardSectionTitle',
          campaign,
        })}
      </h3>
      <div className="itinerary-container">
        <ol>
          {screenWidth <= 500 ? (
            <SwiperTourCard
              datas={datas}
              language={language}
              swiperConfig={
                isDHK
                  ? {
                      freeMode: true,
                      slidesPerView: 1.6,
                      spaceBetween: 12,
                      centeredSlides: false,
                      freeModeMomentum: true,
                      slidesOffsetBefore: getSlidesOffsetBefore,
                      slidesOffsetAfter: getSlidesOffsetBefore,
                      onInit: (swiper) => {
                        let tours =
                          swiper.wrapperEl.querySelectorAll('.content');
                        console.log(tours);
                        tours.forEach((item) => {
                          let moreMaskLayer =
                            item.parentNode.parentNode.querySelector(
                              '.more-mask-layer',
                            );

                          if (item.clientHeight <= 140) {
                            moreMaskLayer.style.display = 'none';
                          } else {
                            item.style.height = '140px';
                          }
                        });
                      },
                    }
                  : {}
              }
            />
          ) : (
            datas.map((item, index) => (
              <li>
                <div>
                  <div className="tt">
                    {(index + 1).toString().padStart(2, '0')}
                  </div>
                  <div className="our-itinerary-details">
                    <img src={item.coverPhoto} alt="" />
                    <div className="content">
                      <h5>{item.title}</h5>
                      <p>{decodedStripedHtml(item.description)}</p>
                      {/* <CustomViewer
                        className="label-one-line"
                        initValue={item.description}
                      /> */}
                    </div>
                  </div>
                </div>
              </li>
            ))
          )}
        </ol>
      </div>
    </div>
  );
}

export default withResizeDetector(TourCard);
