import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Image, Form } from 'react-bootstrap';
import './CustomerOverview.scss';
import '../base/ListFilterComponent.scss';
import OverviewCard from '../dashboard/OverviewCard';
import NewCustomerCard from './NewCustomerCard';
import CustomPagination from '../../components/base/CustomPagination';
import CustomEditableRank from '../../components/base/CustomEditableRank';
import SearchIcon from '../../assets/images/drop_down_filter.svg';
import { createAction } from '../../utils';
import { CUSTOMER_PAGE_DATA_TYPE } from '../../services/DashboardCustomerAPIHelper';
import { PermissionCodes } from '../../config/PermissionCodes';
import AuthButton from '../../components/base/AuthButton';
import { ImportResource } from '../../models/DownloadImportModel';
import { enLocaleNumberFormatter } from '../../utils';
import CustomTips from '../base/CustomTips';
import AnimatedNumber from 'animated-number-react';
import { ANIMATION_DURATION } from '../../config/DashboardConfig';
import { getValueChangeLabel } from '../dashboard/CustomersCard';
import { formatDate, TimeFormater } from '../../utils/TimeFormatUtil';
import { useHistory } from 'react-router-dom';

const agePerids = [
  '0~14',
  '15～24',
  '25～34',
  '35～44',
  '45～54',
  '55～64',
  '65～74',
  '75+',
];

const DefaultAge = agePerids.map((item) => [item, 0, '0%', '0%']);

const CUSTOMER_CARD_CONFIG = [
  {
    title: 'Total Customers',
    key: 'totalNumberOfCustomers',
    detail:
      'Accumulated number of signed up customers from the start of this program til the end of the selected period ',
    showAs: true,
  },
  {
    title: 'New Customers',
    key: 'totalNumberOfNewCustomers',
    detail: 'Number of signed up customers within the selected period',
  },
  {
    title: 'Active Customers',
    key: 'totalNumberOfActiveCustomers',
    detail: 'Login at least 1 time within the selected period',
  },
];

const Gender_CARD_CONFIG = [
  {
    title: 'Male',
    key: 'totalNumberOfMaleCustomers',
  },
  {
    title: 'Female',
    key: 'totalNumberOfFemaleCustomers',
  },
];

function AsOfTime({ hide, style = {} }) {
  const endDate = useSelector((state) => state.dashboardCustomer.endDate);
  if (hide) {
    return null;
  }
  return (
    <label
      className="dashboard-and-overview-as-of-label"
      style={style}
    >{`as of ${formatDate(
      endDate,
      TimeFormater.dayMonthYearTimePeriod,
    )}`}</label>
  );
}

function DataCount({ pre, suffix }) {
  return (
    <label className="histogram-zoomable-card-percentage">
      {pre + ' '}
      <label className="histogram-zoomable-card-count">{suffix}</label>
    </label>
  );
}

function TableSearcher({ onSearch }) {
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const searchKey = form.searchKey.value;
    onSearch(searchKey);
  };
  const [searchKey, setSearchKey] = useState();
  return (
    <Form
      autoComplete="off"
      noValidate
      className="list-filter-list-search-section"
      onSubmit={handleSubmit}
    >
      <Image src={SearchIcon} className="list-filter-search-icon" />
      <input
        name="searchKey"
        value={searchKey}
        onChange={({ target }) => setSearchKey(target.value)}
        type="text"
        className="list-filter-search-field"
        placeholder={'Search by name'}
      />
    </Form>
  );
}

function CollectedAndUndefined({ collected, total }) {
  return (
    <div>
      <DataCount pre={'Data collected'} suffix={collected || 0} />
      <label
        className="histogram-zoomable-card-percentage"
        style={{ marginLeft: '10px', marginRight: '10px' }}
      >
        |
      </label>
      <DataCount pre={'Undefined'} suffix={total - collected || 0} />
    </div>
  );
}

const Title = ({ name, collected, total, hide, showDataFrom, showExport }) => {
  return (
    <div className={'customer-overview-title-container'}>
      <div className="customer-overview-row-title">{name}</div>
      {showExport ? (
        <AuthButton
          title="Export Customer Report"
          action={() => showExport()}
          requires={PermissionCodes.addExportjob}
          customClass={`btn-back-button-common base-buttons-item base-buttons-secondary exist-button`}
        />
      ) : null}
      {hide ? null : (
        <CollectedAndUndefined collected={collected || 0} total={total || 0} />
      )}
      {showDataFrom ? (
        <DataCount pre={'Data from all customer'} suffix={collected || 0} />
      ) : null}
    </div>
  );
};

const CardTwo = ({
  data = [],
  total = {},
  totalNew = {},
  name,
  hide,
  notShowCampare,
}) => {
  console.log('totalNumberOfCustomersAgeTable:', data);
  return (
    <div>
      <Title
        name={name}
        collected={total.value}
        total={totalNew.value}
        hide={hide}
      />
      <AsOfTime style={{ padding: '0px 38px' }} />
      <div className="overview-row customer-overview-row">
        {data.map((config) => {
          const number = config[2];
          let newNumber = number;
          if (number?.includes('%')) {
            newNumber = number.replace('%', '');
          }
          return (
            <OverviewCard
              title={`${config[0]} years old`}
              value={config[1]}
              notShowCampare={notShowCampare}
              change={newNumber / 100}
            />
          );
        })}
      </div>
    </div>
  );
};

const CustomersCard = ({
  hide,
  name,
  cardConfig,
  data = {},
  total = {},
  totalNew = {},
  showExport,
}) => {
  return (
    <>
      <Title
        name={name}
        hide={hide}
        collected={total.value}
        total={totalNew.value}
        showExport={showExport}
      />
      <div className="overview-row customer-overview-row">
        {cardConfig.map((config) => {
          return (
            <>
              <div
                className="overview-card-container"
                style={{
                  paddingBottom: '30px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div className="overview-card-title">
                  <CustomTips
                    detail={config.detail}
                    iconClassName="dashboard-customer-card-hint-icon"
                  >
                    <label>{config.title}</label>
                  </CustomTips>
                  <AsOfTime hide={!config.showAs} />
                </div>
                <AnimatedNumber
                  className="dashboard-customer-card-info-number"
                  duration={ANIMATION_DURATION}
                  value={data[config.key]?.value}
                  formatValue={(value) =>
                    enLocaleNumberFormatter(value.toFixed(0))
                  }
                />
                {getValueChangeLabel(data[config.key]?.change)}
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};
const CardOne = ({
  hide,
  name,
  cardConfig,
  data = {},
  total = {},
  totalNew = {},
  showExport,
  notShowCampare,
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Title
        name={name}
        hide={hide}
        collected={total.value}
        total={totalNew.value}
        showExport={showExport}
      />
      <AsOfTime style={{ padding: '0px 38px' }} />
      <div className="overview-row customer-overview-row">
        {cardConfig.map((config) => {
          return (
            <OverviewCard
              title={config.title}
              value={data[config.key]?.value}
              change={data[config.key]?.value / total.value}
              notShowCampare={notShowCampare}
            />
          );
        })}
      </div>
    </div>
  );
};

const OverTable = ({
  name,
  total = {},
  totalNew = {},
  data = [],
  currentPage = 0,
  totalCount,
  pageChange = () => {},
  onSortChange = () => {},
  onFileterChange = () => {},
  titles = [],
  currentSort,
  hideFilter,
  showDataFrom,
  hide,
  tableKey,
  searchName,
  sortLocal,
}) => {
  const dispatch = useDispatch();
  console.log('currentSort:', currentSort);
  const doFilter = (object) => {
    if (object.pageNumber) {
      pageChange(object.pageNumber - 1);
    } else {
      pageChange(0);
    }
    dispatch(
      createAction('dashboardCustomer/getTablePagedData')({
        orderBy: currentSort?.fieldName,
        searchName: searchName,
        tableKey,
        ...object,
      }),
    );
  };
  const getSecondEntry = () => {
    if (!totalCount) {
      return 0;
    }
    if (totalCount <= 20) {
      return totalCount;
    }
    const pageNumber = (currentPage + 1) * 20;
    if (pageNumber > totalCount) {
      return totalCount;
    }
    return pageNumber;
  };
  const firstEntry = !!totalCount ? currentPage * 20 + 1 : 0;
  const secondEntry = getSecondEntry();
  return (
    <div className="customer-table-overview">
      <Title
        name={name}
        collected={total?.value}
        total={totalNew?.value}
        showDataFrom={showDataFrom}
        hide={hide}
      />
      <AsOfTime style={{ padding: '0px 38px' }} />
      {!tableKey ? null : (
        <label className="customer-over-page-info">
          Showing {firstEntry} to {secondEntry} of {totalCount} entries
        </label>
      )}
      <div
        className="overview-list-section common-table-overflow overview"
        key={`${name}-list`}
      >
        {hideFilter ? null : (
          <TableSearcher
            onSearch={(name) => {
              onFileterChange(name);
              doFilter({ searchName: name });
            }}
          />
        )}
        <table
          className={`overview-all-list-table customer-overview overview`}
          style={{ minHeight: '200px' }}
        >
          <thead>
            <tr>
              {titles.map((title, index) => {
                return (
                  <th
                    key={index + 1}
                    className={
                      index === titles.length - 1
                        ? 'customer-overview-header-th'
                        : ''
                    }
                    style={{ width: `${100 / titles.length}%` }}
                  >
                    <CustomEditableRank
                      rank={currentSort?.fieldName?.includes('-')}
                      show={currentSort?.name === title.name}
                      title={title.name}
                      extraAreaStyle={'base-rank-container'}
                      onClick={(rank) => {
                        if (currentSort?.fieldName?.includes('-')) {
                          const newField = title.fieldName?.substring(0);
                          onSortChange({
                            name: title.name,
                            fieldName: newField,
                          });
                          if (sortLocal) {
                            return;
                          }
                          doFilter({ orderBy: newField });
                          return;
                        }
                        onSortChange({
                          name: title.name,
                          fieldName: `-${title.fieldName}`,
                        });
                        if (sortLocal) {
                          return;
                        }
                        doFilter({ orderBy: `-${title.fieldName}` });
                      }}
                    />
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => {
              return (
                <tr>
                  {item.map((column, index) => {
                    return (
                      <td
                        className={
                          index === titles.length - 1
                            ? 'customer-overview-header-th'
                            : ''
                        }
                        style={{ width: `${100 / titles.length}%` }}
                      >
                        <div>
                          <label style={{ wordBreak: 'break-word' }}>
                            {column}
                          </label>
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>

        {totalCount > 20 ? (
          <div className="all-list-scrollable-area-pagination">
            <CustomPagination
              initPage={currentPage}
              pageSize={Math.ceil(totalCount / 20)}
              pageChange={(page) => {
                doFilter({ pageNumber: page + 1 });
              }}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

const tableTitles = (needTag) => {
  const data = [{ name: 'Rank', fieldName: 'rank' }];
  if (needTag) {
    data.push({ name: 'Key Tag', fieldName: 'tag_key__name' });
  }
  data.push(
    { name: 'Name', fieldName: 'name' },
    { name: 'Number', fieldName: 'count' },
    { name: 'Percentage', fieldName: 'count' },
  );
  return data;
};

const TopFiveNational = ({ total = {}, totalNew = {}, data = [] }) => {
  const [sort, sortChange] = useState();
  const tableTitle = tableTitles();
  const history = useHistory();
  data.sort((paramsA, paramsB) => {
    const sortKey = sort?.fieldName;
    if (sortKey === '-rank') {
      return paramsA[0] > paramsB[0] ? -1 : 1;
    }
    if (sortKey === 'rank') {
      return paramsA[0] > paramsB[0] ? 1 : -1;
    }
    if (sortKey === '-count') {
      return paramsA[3] > paramsB[3] ? -1 : 1;
    }
    if (sortKey === 'count') {
      return paramsA[3] > paramsB[3] ? 1 : -1;
    }
    const nameA = paramsA[1].toUpperCase();
    const nameB = paramsB[1].toUpperCase();
    if (sortKey === '-name') {
      return nameA > nameB ? -1 : 1;
    }
    if (sortKey === 'name') {
      return nameA > nameB ? 1 : -1;
    }
    return 0;
  });

  useEffect(() => {
    sortChange(null);
  }, [history.location]);

  console.log('PreferenceAttributeData:', data);
  return (
    <OverTable
      name="TOP 5 NATIONALITY"
      titles={tableTitle}
      sortLocal={true}
      onSortChange={sortChange}
      currentSort={sort}
      total={total}
      totalNew={totalNew}
      data={data}
      hidePage
      hideFilter
    />
  );
};

const PreferenceCategory = ({ total = {}, totalNew = {} }) => {
  const history = useHistory();
  const [sort, sortChange] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [searchName, setSearchName] = useState();
  const tableTitle = tableTitles();
  const data = useSelector(
    (state) =>
      state.dashboardCustomer[CUSTOMER_PAGE_DATA_TYPE.CAMPAIGN_CATEGORY],
  );
  useEffect(() => {
    sortChange(null);
    setCurrentPage(0);
    setSearchName('');
  }, [history.location]);
  return (
    <OverTable
      name="Preference Ranking - by campaign category"
      titles={tableTitle}
      onSortChange={sortChange}
      currentSort={sort}
      total={total}
      totalNew={totalNew}
      data={data?.tableData}
      searchName={searchName}
      onFileterChange={(key) => {
        setSearchName(key);
      }}
      tableKey={CUSTOMER_PAGE_DATA_TYPE.CAMPAIGN_CATEGORY}
      currentPage={currentPage}
      totalCount={data?.totalCount}
      pageChange={(page) => {
        setCurrentPage(page);
      }}
      showDataFrom
      hide
    />
  );
};

const PreferenceAttribute = ({ total = {}, totalNew = {} }) => {
  const history = useHistory();
  const [sort, sortChange] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [searchName, setSearchName] = useState();
  const tableTitle = tableTitles(true);
  const data = useSelector(
    (state) => state.dashboardCustomer[CUSTOMER_PAGE_DATA_TYPE.ATTRIBUTE_TAGE],
  );
  useEffect(() => {
    sortChange(null);
    setCurrentPage(0);
    setSearchName('');
  }, [history.location]);
  return (
    <OverTable
      name="Preference Ranking - by Attribute tags"
      titles={tableTitle}
      onSortChange={sortChange}
      currentSort={sort}
      total={total}
      totalNew={totalNew}
      data={data?.tableData}
      searchName={searchName}
      onFileterChange={(key) => {
        setSearchName(key);
      }}
      tableKey={CUSTOMER_PAGE_DATA_TYPE.ATTRIBUTE_TAGE}
      currentPage={currentPage}
      totalCount={data?.totalCount}
      pageChange={(page) => {
        setCurrentPage(page);
      }}
      showDataFrom
      hide
    />
  );
};

const InterestPreference = ({ total = {}, totalNew = {} }) => {
  const data = useSelector(
    (state) =>
      state.dashboardCustomer[CUSTOMER_PAGE_DATA_TYPE.INTEREST_PREFERENCE],
  );
  const history = useHistory();
  const [sort, sortChange] = useState();
  const [currentPage, setCurrentPage] = useState();
  const [searchName, setSearchName] = useState();
  const tableTitle = tableTitles();
  useEffect(() => {
    sortChange(null);
    setCurrentPage(0);
    setSearchName('');
  }, [history.location]);
  return (
    <OverTable
      name="Preference Ranking - by interest preference"
      titles={tableTitle}
      onSortChange={sortChange}
      currentSort={sort}
      total={total}
      totalNew={totalNew}
      data={data?.tableData}
      searchName={searchName}
      onFileterChange={(key) => {
        setSearchName(key);
      }}
      tableKey={CUSTOMER_PAGE_DATA_TYPE.INTEREST_PREFERENCE}
      currentPage={currentPage}
      totalCount={data?.totalCount}
      pageChange={(page) => {
        setCurrentPage(page);
      }}
    />
  );
};

const CustomerOverview = () => {
  const { customerOverviewData, startDate, endDate } = useSelector((state) => ({
    startDate: state.dashboardCustomer.startDate,
    endDate: state.dashboardCustomer.endDate,
    customerOverviewData: state.dashboardCustomer.customers,
  }));
  const genderTotal =
    customerOverviewData.totalNumberOfMaleCustomers?.value +
    customerOverviewData.totalNumberOfFemaleCustomers?.value;
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch({ type: 'dashboardCustomer/clearState' });
    };
  }, []);
  return (
    <div>
      <CustomersCard
        name="Customers"
        hide
        cardConfig={CUSTOMER_CARD_CONFIG}
        data={customerOverviewData}
        showExport={() => {
          dispatch(
            createAction('downloadAndImport/createDownloadTask')({
              from: ImportResource.customExport,
              related: {
                fromDate:
                  typeof startDate === 'string' || startDate instanceof String
                    ? formatDate(startDate, TimeFormater.GTMFormat)
                    : startDate.format(TimeFormater.GTMFormat),
                toDate:
                  typeof endDate === 'string' || endDate instanceof String
                    ? formatDate(endDate, TimeFormater.GTMFormat)
                    : endDate.format(TimeFormater.GTMFormat),
              },
            }),
          );
        }}
      />
      <NewCustomerCard />
      <CardTwo
        data={customerOverviewData.totalNumberOfCustomersAgeTable || DefaultAge}
        totalNew={customerOverviewData.totalNumberOfCustomers}
        total={customerOverviewData.totalNumberOfCustomersWithAge}
        name={'Age'}
        notShowCampare={true}
      />
      <CardOne
        data={customerOverviewData}
        totalNew={customerOverviewData.totalNumberOfCustomers}
        total={{ value: genderTotal || 0 }}
        cardConfig={Gender_CARD_CONFIG}
        name={'Gender'}
        notShowCampare={true}
      />
      <TopFiveNational
        data={customerOverviewData.top5NationalityOfCustomersTable}
        totalNew={customerOverviewData.totalNumberOfCustomers}
        total={customerOverviewData.totalNumberOfCustomersWithNationality}
      />
      <PreferenceCategory
        data={customerOverviewData.campaignCategoryOfTimesCouponAcquiredTable}
        total={customerOverviewData.totalNumberOfCustomers}
      />
      <PreferenceAttribute
        data={customerOverviewData.attributeTagOfTimesCouponAcquiredTable}
        total={customerOverviewData.totalNumberOfCustomers}
      />
      <InterestPreference
        data={customerOverviewData.interestPreferenceOfCustomersTable}
        totalNew={customerOverviewData.totalNumberOfCustomers}
        total={
          customerOverviewData.totalNumberOfCustomersWithInterestPreference
        }
      />
    </div>
  );
};
export default CustomerOverview;
