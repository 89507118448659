import React, { useState, forwardRef } from 'react';
import { Image, Button, Dropdown } from 'react-bootstrap';
import { downloadZipFiles } from '../../containers/merchants/stores/QRCodeDownloadUtil';

import './CustomQRCode.scss';

const popperConfig = {
  strategy: 'fixed',
  modifiers: [
    {
      name: 'computeStyles',
      options: {
        gpuAcceleration: false,
        adaptive: false,
      },
    },
  ],
};

const CustomToggle = forwardRef(({ source, customClass = '' }, ref) => {
  return (
    <Image
      src={source}
      ref={ref}
      value={source}
      className={`display-qr-code ${customClass}`}
      onClick={(e) => {
        e.preventDefault();
      }}
    />
  );
});

function CustomQRCode({ imageLink, customQRCodeClass }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Dropdown
      show={isOpen}
      drop="right"
      className="qr-code-container"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <Dropdown.Toggle
        as={CustomToggle}
        source={imageLink}
        customClass={customQRCodeClass}
      />

      <Dropdown.Menu popperConfig={popperConfig}>
        <Dropdown.Item>
          <div className="hover-qr-code-container">
            <label className="hover-qr-code-title">QR code</label>
            <Image className="qr-code-download-image" src={imageLink} />
            <Button
              className="qr-code-download-button btn-further"
              onClick={() => {
                downloadZipFiles(imageLink);
              }}
            >
              Download
            </Button>
          </div>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default CustomQRCode;
