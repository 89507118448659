import { CreateCampaignGroupError } from '../../../models/CampaignGroupModel';

function firstStepValidate({ getValues, setError }) {
  const errorList = [];
  const [name, campaigns, perHeadLimit] = getValues([
    'name',
    'campaigns',
    'perHeadLimit',
  ]);

  if (!name) {
    errorList.push({
      name: 'name',
      message: CreateCampaignGroupError.name.message,
    });
  }

  if (!campaigns?.length) {
    errorList.push({
      name: 'campaigns',
      message: CreateCampaignGroupError.campaigns.message,
    });
  }

  if (!perHeadLimit || parseInt(perHeadLimit) < 1) {
    errorList.push({
      name: 'perHeadLimit',
      message: CreateCampaignGroupError.perHeadLimit.message,
    });
  }

  errorList.forEach((error) => {
    setError(error.name, {
      type: 'manual',
      message: error.message,
    });
  });
  return !errorList.length;
}

export function validate({ getValues, setError, clearErrors }) {
  console.log('CampaignGroupValidate:', getValues());
  clearErrors();
  const valid = firstStepValidate({ getValues, setError });
  return valid;
}
