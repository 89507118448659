import { BaseHelper } from './BaseGraphQLAPIHelper';

// const SURVEY_FORM_NODE = `
//     couponTemplates(first: 2) {
//       edges {
//           node {
//               pk
//               name
//           }
//       }
//   }
// `;

const SURVEY_FORM_NODE = `
linkedCouponTemplatesName {
  id
  name
  workingTeamIds
}
`;

const SURVEY_FORM_FULL_NODE = `{
    id
    pk
    name
    options
}`;

// couponTemplates {
//   edges {
//       node {
//           pk
//           name
//       }
//   }
// }

export const getSurveyFormList = (afterCursor, params) => {
  let filter = '';
  const { sort, searchKey } = params || {};
  if (searchKey) {
    filter = `, nameIcontains:"${searchKey}"`;
  }
  let orderBy = params.rank ? '-pk' : 'pk';
  if (sort) {
    orderBy = sort;
  }
  const query = `{
      surveyForms(first: 20, after:"${afterCursor}", orderBy:"${orderBy}"${filter}) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            pk
            name
            options
            ${params.isSelectorLoad ? '' : SURVEY_FORM_NODE}
          }
        }
      }
    }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getSurveyForm = (id) => {
  const query = `{
      surveyForm(id: "${id}") ${SURVEY_FORM_FULL_NODE}
    }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const deleteSurveyForms = (ids) => {
  const query = `mutation DeleteSurveyForms($input: DeleteSurveyFormsInput!) {
      deleteSurveyForms(input: $input) {
        success
      }
    }
    `;
  const variables = { input: { ids: ids } };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createOrUpdateSurveyForm = (values) => {
  const action = values.id ? 'Update' : 'Create';
  let query = `mutation ${action}SurveyForm($input: ${action}SurveyFormInput!) {
      ${action.toLowerCase()}SurveyForm(input: $input) {
        success
        node {
          id
          pk
          name
        }
        errors {
          field
          messages
        }
      }
    }

    `;

  const variables = { input: values };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
