import React, { useState, useEffect } from 'react';
import './LogoLabelDetail.scss';
import { useHistory, useLocation, useParams } from 'react-router';
import { connect } from 'react-redux';
import AuthButton from '../../../components/base/AuthButton';
import ContentSections from '../../../components/base/ContentSections';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { createAction } from '../../../utils';
import Loading from '../../../components/base/Loading';
import { LanguageConfig } from '../../../config/CustomEnums';
import BaseOverView from '../../../components/coupon/couponList/BaseOverview';
import { Link } from 'react-router-dom';
import BasePrompt from '../../../components/base/prompt/BasePrompt';

const LogoLabelDetail = ({ detail, dispatch, languages }) => {
  const history = useHistory();
  const params = useParams();
  const [showRelatedPrompt, setShowRelatedPrompt] = useState(false);
  const [relatedPromptKey, setRelatedPromptKey] = useState(null);
  const [relatedPromptTitle, setRelatedPromptTitle] = useState(null);
  const { id } = params;
  useEffect(() => {
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
    dispatch(createAction('logoLabel/getOneDetail')({ id }));
    return () => {
      dispatch(createAction('logoLabel/clearData')());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { relatedCampaigns = [], relatedCoupons = [] } = detail;
  const renderTabForLanguage = (language) => {
    const name =
      language === LanguageConfig.english
        ? detail.name
        : detail.translations?.[language]?.name;
    const logoLabelUrl =
      language === LanguageConfig.english
        ? detail.logoLabelUrl
        : detail.translations?.[language]?.logoLabelUrl;
    const coverImage =
      language === LanguageConfig.english
        ? detail.icon
        : detail.translations?.[language]?.icon;

    return (
      <>
        <BaseOverView
          hideSection
          title="information"
          imageTitleNames={['Cover image']}
          itemTitleList={['Name', 'Logo label url', 'Cover image', 'Order']}
          itemValueList={[name, logoLabelUrl, coverImage, detail.displayPriority]}
        />
        <div className="second-section-title">Related Coupon</div>
        <div className="second-section-content related-items-content">
          {relatedCoupons.length === 0
            ? '-'
            : relatedCoupons.slice(0, 10).map((coupon) => (
                <div>
                  <Link
                    className="detail-page-link"
                    to={`/coupons/${coupon.id}/`}
                  >{`[ID: ${coupon.id}] ${coupon.name}`}</Link>
                </div>
              ))}
          {relatedCoupons.length > 10 ? (
            <label
              className="show-more"
              onClick={() => {
                setShowRelatedPrompt(true);
                setRelatedPromptKey('relatedCoupons');
                setRelatedPromptTitle('Related Coupons');
              }}
            >
              show more
            </label>
          ) : null}
        </div>
        <div className="second-section-title">Related campaign</div>
        <div className="second-section-content related-items-content">
          {relatedCampaigns.length === 0
            ? '-'
            : relatedCampaigns.slice(0, 10).map((campaign) => (
                <div>
                  <Link
                    className="detail-page-link"
                    to={`/campaigns/${campaign.id}/edit`}
                  >{`[ID: ${campaign.id}] ${campaign.name}`}</Link>
                </div>
              ))}
          {relatedCampaigns.length > 10 ? (
            <label
              className="show-more"
              onClick={() => {
                setShowRelatedPrompt(true);
                setRelatedPromptKey('relatedCampaigns');
                setRelatedPromptTitle('Related Campaigns');
              }}
            >
              show more
            </label>
          ) : null}
        </div>
      </>
    );
  };

  const tabs = [
    {
      name: 'Detail',
      content: detail.pk ? (
        <div className="scroll-container-common">
          <ContentSections
            hidePreview
            languageTabContent={{
              containers: languages?.map((item) => ({
                container: [renderTabForLanguage(item.code)],
                key: item.code,
                title: item.sourceName,
              })),
            }}
          />
        </div>
      ) : (
        <Loading />
      ),
    },
  ];

  const buttons = [
    <AuthButton
      title="Edit"
      action={() => {
        history.push({
          pathname: 'edit/',
        });
      }}
      requires={PermissionCodes.changeLogoLabel}
    />,
  ];
  return (
    <>
      <CustomListComponent
        caution={{
          detail: '',
          title: detail?.name,
        }}
        defaultActiveKey={tabs[0].name}
        breadcrumb={<CustomBreadcrumb name={detail?.name} />}
        buttons={buttons}
        tabs={tabs}
      />
      <BasePrompt
        show={showRelatedPrompt}
        title={relatedPromptTitle}
        description="The user can see the following activities"
        closeAction={() => {
          setShowRelatedPrompt(false);
          setRelatedPromptKey(null);
        }}
        otherBody={() => (
          <ul className="related-items-container">
            {(detail?.[relatedPromptKey] || []).map((item) => (
              <li className="related-items">{`[ID: ${item.id}] ${item.name}`}</li>
            ))}
          </ul>
        )}
      />
    </>
  );
};

const mapPropsToState = (state) => ({
  detail: state.logoLabel.detail,
  languages: state.language.allList,
});
export default connect(mapPropsToState)(LogoLabelDetail);
