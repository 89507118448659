import React from 'react';
import './OverviewCard.scss';
import AnimatedNumber from 'animated-number-react';
import { getValueChangeLabel, getValuePercentLabel } from './CustomersCard';
import { ANIMATION_DURATION } from '../../config/DashboardConfig';
import { enLocaleNumberFormatter } from '../../utils';
import { TimeFormater, formatDate } from '../../utils/TimeFormatUtil';

const OverviewCard = ({
  title,
  value,
  change,
  showPercentage,
  asOfDate,
  notShowCampare,
}) => {
  return (
    <div className="overview-card-container">
      <div className="overview-card-title">{title}</div>
      {asOfDate ? (
        <div className="overview-card-as-of">
          {`as of ${formatDate(asOfDate, TimeFormater.dayMonthYearTimePeriod)}`}
        </div>
      ) : (
        <></>
      )}
      {value === 'N/A' || value?.toString().indexOf('/') > 0 ? (
        <label className="overview-card-value">{value}</label>
      ) : (
        <AnimatedNumber
          className="overview-card-value"
          duration={ANIMATION_DURATION}
          value={value}
          formatValue={(input) => {
            if (showPercentage) {
              return `${((input || 0) * 100).toFixed(0)}%`;
            }
            return enLocaleNumberFormatter(input.toFixed(0));
          }}
        />
      )}

      {notShowCampare
        ? getValuePercentLabel(change)
        : getValueChangeLabel(change)}
    </div>
  );
};
export default OverviewCard;
