import React from 'react';
import { Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import './couponsSection.scss';

function CouponsSection(props) {
  const { detail } = useSelector((state) => ({
    detail: state.paymentSettlementList.detail
  }));

  const fieldNameText = (value, colspanValue, rowspanValue) => {
    return (
      <th className="coupon-section-label coupon-section-field-clumn" 
          colspan={colspanValue} rowspan={rowspanValue}>
        <label className="coupon-section-field-th">
          {value}
        </label>
      </th>
    );
  };

  const fieldValueText = (value, className) => {
    return (
      <td>
        <label className={className ? className : "coupon-section-field-input"}>
          {value}
        </label>
      </td>
    );
  };

  const getOneRow=(item) =>{
    return <>
      <tr>
          {fieldValueText(item.couponTemplate.pk, "coupon-section-field-input-pk")}
          {fieldValueText(item.couponTemplate.name, "coupon-section-field-input-name")}
          {fieldValueText(item.perCouponSettlementValueForHktb)}
          {fieldValueText(item.perCouponSettlementValueForMerchant)}
          {fieldValueText(item.numberOfCouponAcquiredByUser)}
          {fieldValueText(item.numberOfCouponUsedByUser)}
          {fieldValueText(item.settlementValueHktbTotalContribute)}
          {fieldValueText(item.settlementValueMerchantTotalContribute)}
      </tr>
    </>
  }

  const couponsList = (detail) => {
    return detail.coupons?.map((item) => (
      getOneRow(item)
      ))
  };

  return (
    <>
      <label className="create-section-title">COUPONS</label>
        <Table bordered className="coupon-section-table-container">
          <tr>
            {fieldNameText('Coupon set ID',1,2)}
            {fieldNameText('Coupon set Name',1,2)}
            {fieldNameText('Per coupon Settlement value',2,1)}
            {fieldNameText('Number of coupon acquired by end-user',1,2)}
            {fieldNameText('Number of coupon used by end-user',1,2)}
            {fieldNameText('Settlement Value',2,1)}
          </tr>
          <tr>
            {fieldNameText('HKTB',1,1)}
            {fieldNameText('Merchant',1,1)}
            {fieldNameText('HKTB total contribute',1,1)}
            {fieldNameText('Merchant total contribute',1,1)}
          </tr>
          <tbody>
            {couponsList(detail)}
          </tbody>
        </Table>
    </>
  );
}

export default CouponsSection;
