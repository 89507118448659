import React from 'react';
import './AlertInfoPrompt.scss';
import BasePrompt from '../../base/prompt/BasePrompt';

function AlertInfoPrompt(props) {
  const onCancel = props.onCancel || (() => {});
  const onConfirm = props.onConfirm || (() => {});
  const title = props.title;
  const subTitle = props.subTitle;
  const content = props.content;
  const leftButton = props.leftButton;
  const rightButton = props.rightButton;
  const contentClass = props.contentClass;

  const infoContent = () => {
    return <div className={`alert-info-prompt ${contentClass}`}>{content}</div>;
  };

  const getLeftButton = () => {
    return leftButton
      ? {
          text: leftButton,
          action: () => onCancel(true),
        }
      : null;
  };

  const getRightButton = () => {
    return rightButton
      ? {
          text: rightButton,
          action: () => onConfirm(),
        }
      : null;
  };

  return (
    <BasePrompt
      show={props.show}
      closeAction={props.onClose}
      leftButton={getLeftButton()}
      rightButton={getRightButton()}
      title={title}
      description={subTitle}
      otherBody={infoContent}
    />
  );
}

export default AlertInfoPrompt;
