import React, { useState, useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import CustomEditableRank from '../base/CustomEditableRank';
import './DataTable.scss';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import LoadingIcon from '../../assets/images/loading.gif';
import CustomTips from '../base/CustomTips';

const valueFrame = (columnsLength, index, value, columnWidth) => {
  return (
    <td
      className={
        index === columnsLength - 1 ? 'customer-overview-header-th' : ''
      }
      style={{
        width: `${
          columnWidth ? columnWidth[index] : `${100 / columnsLength}%`
        }`,
        // overflow: 'scroll',
      }}
    >
      <div
        style={{
          whiteSpace: `${
            value.toString().indexOf(', ') > 0 ? 'pre' : 'normal'
          }`,
          wordBreak: 'normal',
        }}
      >
        {value === '' ? '-' : value.toString().replaceAll(', ', ', \n')}
      </div>
    </td>
  );
};

export default function DataTable({
  name,
  tips,
  data = [],
  titles = [],
  customButton,
  refreshAction = () => {},
  isLoading = false,
  total = [],
  refreshButtonDisabled = false,
  columnWidth,
  cautionDetail,
}) {
  const columnsLength = Array.isArray(titles?.[0])
    ? titles?.[0].length
    : titles.length;

  return (
    <div className="customer-table-overview custom-data-table">
      <div className="head">
        <div className="d-flex flex-column">
          <div className="d-flex flex-row">
            <CustomTitleLabel title={name} className={'letter-uppercase'} />
            <CustomTips
              detail={cautionDetail}
              customClass={{
                area: 'data-table-caution-area',
                PopoverContentClass: 'data-table-caution-content',
              }}
              popoverName={'data-table-caution-container'}
            />
          </div>
          <label className="tips-message">{tips}</label>
        </div>
        <div className="buttons">
          <button
            className="refresh-button btn-further btn btn-primary"
            onClick={refreshAction}
            disabled={refreshButtonDisabled}
          >
            Refresh
          </button>
          <div className="custom-button">{customButton}</div>
        </div>
      </div>

      <div
        className="overview-list-section common-table-overflow overview"
        key={`${name}-list`}
      >
        {/* {hideFilter ? null : (
          <TableSearcher
            onSearch={(name) => {
              onFileterChange(name);
              doFilter({ searchName: name });
            }}
          />
        )} */}
        <table
          className={`overview-all-list-table customer-overview overview`}
          style={{ minHeight: '200px' }}
        >
          <thead>
            <tr>
              {Array.isArray(titles?.[0])
                ? titles[0].map((name, index) => (
                    <th
                      className={'mutiple-head'}
                      style={{
                        width: `${
                          columnWidth
                            ? columnWidth[index]
                            : `${100 / columnsLength}%`
                        }`,
                      }}
                    >
                      <div>
                        <span
                        //   style={
                        //     titles[1][index]
                        //       ? { borderBottom: '2px solid #ccc' }
                        //       : {}
                        //   }
                        >
                          {name}
                        </span>
                        <span>{titles[1][index]}</span>
                      </div>
                    </th>
                    // <div>

                    // </div>
                  ))
                : titles.map((title, index) => {
                    return (
                      <th
                        key={index + 1}
                        className={
                          index === columnsLength - 1
                            ? 'customer-overview-header-th'
                            : ''
                        }
                        style={{
                          width: `${
                            columnWidth
                              ? columnWidth[index]
                              : `${100 / columnsLength}%`
                          }`,
                        }}
                      >
                        <CustomEditableRank
                          // rank={currentSort?.fieldName?.includes('-')}
                          // show={currentSort?.name === title.name}
                          title={title}
                          extraAreaStyle={'base-rank-container'}
                          disabled={false}
                          //   onClick={(rank) => {
                          //     if (currentSort?.fieldName?.includes('-')) {
                          //       const newField = title.fieldName?.substring(0);
                          //       onSortChange({
                          //         name: title.name,
                          //         fieldName: newField,
                          //       });
                          //       if (sortLocal) {
                          //         return;
                          //       }
                          //       doFilter({ orderBy: newField });
                          //       return;
                          //     }
                          //     onSortChange({
                          //       name: title.name,
                          //       fieldName: `-${title.fieldName}`,
                          //     });
                          //     if (sortLocal) {
                          //       return;
                          //     }
                          //     doFilter({ orderBy: `-${title.fieldName}` });
                          //   }}
                        />
                      </th>
                    );
                  })}
            </tr>
          </thead>
          <tbody>
            {/* <tr>
              {[[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]].map((item) => {
                return item.map((column, index) => {
                  return valueFrame(columnsLength, index, column, columnWidth);
                });
              })}
            </tr> */}
            {data?.map((item, index) => {
              return (
                <tr
                  style={
                    index === data.length - 1 && total.length <= 0
                      ? {}
                      : { borderBottom: '1px solid #ccc' }
                  }
                >
                  {item.map((column, index) => {
                    return Array.isArray(column)
                      ? column.map((value, index) =>
                          valueFrame(columnsLength, index, value, columnWidth),
                        )
                      : valueFrame(columnsLength, index, column, columnWidth);
                  })}
                </tr>
              );
            })}
            {isLoading ? (
              <tr>
                <td colSpan={columnsLength} align={'center'}>
                  <Image src={LoadingIcon} className="loading-icon"></Image>
                </td>
              </tr>
            ) : total.length > 0 ? (
              <tr>
                {total.map((value, index) =>
                  valueFrame(columnsLength, index, value, columnWidth),
                )}
              </tr>
            ) : null}

            {data?.length <= 0 && !isLoading ? (
              <tr>
                <td colSpan={columnsLength} align={'center'}>
                  NO Records
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>

        {/* {totalCount > 20 ? (
          <div className="all-list-scrollable-area-pagination">
            <CustomPagination
              initPage={currentPage}
              pageSize={Math.ceil(totalCount / 20)}
              pageChange={(page) => {
                doFilter({ pageNumber: page + 1 });
              }}
            />
          </div>
        ) : null} */}
      </div>
    </div>
  );
}
