import React from 'react';
import './BottomStepComponent.scss';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import { checkHasPermission } from '../../utils';

function Base(props) {
  const { user, openMenu } = useSelector((state) => ({
    user: state.users,
    openMenu: state.navBars.openMenu,
  }));

  const backButton = props.backButton || null;
  const continueButton = props.continueButton || null;
  const saveTempButton = props.saveTempButton || null;
  const saveButton = props.saveButton || null;
  const hasMoreThanTwoButtons = props.hasMoreThanTwoButtons || false;
  const multipleButtons = props.multipleButtons || [];
  const extraButtons = props.extraButtons || [];
  const baseTempSaveArea = props.baseTempSaveArea || null;
  return (
    <div
      className={`base-save-area ${
        openMenu ? 'base-save-area-menu-show' : ''
      } ${props.customClassName}`}
    >
      {checkHasPermission(user, props.backRequires) ? backButton : null}
      {continueButton}
      {hasMoreThanTwoButtons ? (
        <div className={`base-temp-save-area ${baseTempSaveArea}`}>
          {multipleButtons.map((item, index) => {
            // console.log('@@33: ', item.text);
            return item; //TODO: add permission
          })}
        </div>
      ) : (
        <div className={`base-temp-save-area ${baseTempSaveArea}`}>
          {extraButtons.map((item, index) => {
            // console.log('@@33: ', item.text);
            return item; //TODO: add permission
          })}
          {checkHasPermission(user, props.tempRequires) ? saveTempButton : null}
          {checkHasPermission(user, props.saveRequires) ? saveButton : null}
        </div>
      )}
    </div>
  );
}

function ContinueButton(props) {
  const continueAction = props.action || (() => {});
  const className =
    props.className ||
    'base-continue-button base-save-and-back-component-button btn-further';
  return (
    <Button
      disabled={props.disabledContinue}
      onClick={() => {
        continueAction();
      }}
      className={className}
    >
      {props.text || 'Continue'}
    </Button>
  );
}

function BackButton(props) {
  const backAction = props.action || (() => {});
  return (
    <Button
      onClick={() => {
        backAction();
      }}
      className="base-back-button base-save-and-back-component-button btn-back-button-common"
    >
      Back
    </Button>
  );
}

export function SaveTempButton(props) {
  const saveTempAction = props.action || (() => {});
  return (
    <Button
      onClick={() => {
        saveTempAction();
      }}
      className="base-save-button-temp-button base-save-and-back-component-button btn-back-button-common"
      disabled={props.disabled}
    >
      {props.text}
    </Button>
  );
}

export function SaveButton(props) {
  const saveAction = props.action || (() => {});
  return (
    <Button
      onClick={(event) => {
        saveAction(event);
      }}
      className="base-save-button base-save-and-back-component-button btn-further"
      disabled={props.disabled}
    >
      {props.text}
    </Button>
  );
}

function DeleteButton(props) {
  const deleteAction = props.action || (() => {});
  return (
    <button
      onClick={() => {
        deleteAction();
      }}
      className="base-delete-button base-back-button"
    >
      Delete
    </button>
  );
}

function ActionButtonWithoutBorder(props) {
  const action = props.action || (() => {});
  return (
    <button
      className="base-delete-button base-back-button"
      onClick={() => {
        action();
      }}
    >
      {props.text}
    </button>
  );
}

export function OnlyContinueButton(props) {
  return (
    <Base
      continueButton={
        <ContinueButton
          text={props.text}
          disabledContinue={props.disabledContinue}
          action={props.continueAction}
          className={props.className}
        />
      }
    />
  );
}

export function OnlyBackButton(props) {
  return <Base backButton={<BackButton action={props.backAction} />} />;
}

export function ContinueWithBackButtons(props) {
  return (
    <Base
      continueButton={
        <ContinueButton
          action={props.continueAction}
          disabledContinue={props.disabledContinue}
        />
      }
      backButton={<BackButton action={props.backAction} />}
    />
  );
}

export function SaveAndBackButtons(props) {
  if (props.hideSave) {
    return (
      <Base
        {...props}
        backButton={
          props.backAction ? <BackButton action={props.backAction} /> : null
        }
        saveButton={
          <SaveButton
            action={props.saveTempAction}
            text={props.saveTempText}
            {...props.save}
          />
        }
      />
    );
  }
  return (
    <Base
      {...props}
      backButton={
        props.backAction ? <BackButton action={props.backAction} /> : null
      }
      saveButton={
        props.saveText ? (
          <SaveButton
            action={props.saveAction}
            text={props.saveText}
            {...props.save}
          />
        ) : null
      }
      saveTempButton={
        props.saveTempText ? (
          <SaveTempButton
            action={props.saveTempAction}
            text={props.saveTempText}
            {...props.saveTemp}
          />
        ) : null
      }
    />
  );
}

export function SaveAndBackWithOutTempButtons(props) {
  return (
    <Base
      {...props}
      backButton={<BackButton action={props.backAction} />}
      saveButton={
        <SaveButton action={props.saveAction} text={props.saveText} />
      }
    />
  );
}

export function DeleteAndMultipleButtons(props) {
  return (
    <Base
      {...props}
      backButton={<DeleteButton action={props.deleteAction} />}
      hasMoreThanTwoButtons={true}
      multipleButtons={props.multipleButtons.map((item, index) => {
        if (index < props.multipleButtons.length - 1) {
          return <SaveTempButton text={item.text} action={item.action} />;
        }
        return <SaveButton text={item.text} action={item.action} />;
      })}
    />
  );
}

export function OnlySaveButton(props) {
  return (
    <Base
      {...props}
      customClassName={props.customBaseClassName}
      saveButton={
        <SaveButton action={props.saveAction} text={props.saveText} />
      }
    />
  );
}

export function SaveAndBackAndWithoutBorderButtons(props) {
  return (
    <Base
      {...props}
      backButton={<BackButton action={props.backAction} />}
      saveButton={
        <SaveButton action={props.saveAction} text={props.saveText} />
      }
      saveTempButton={
        <ActionButtonWithoutBorder
          action={props.saveTempAction}
          text={props.saveTempText}
        />
      }
    />
  );
}

export function BackAndWithoutBorderButton(props) {
  return (
    <Base
      {...props}
      backButton={<BackButton action={props.backAction} />}
      saveButton={
        <ActionButtonWithoutBorder
          action={props.action}
          text={props.actionText}
        />
      }
    />
  );
}

export function BackAndMultipleButtons(props) {
  return (
    <Base
      {...props}
      backButton={<BackButton action={props.backAction} />}
      hasMoreThanTwoButtons={true}
      multipleButtons={props.multipleButtons.map((item, index) => item)}
    />
  );
}
