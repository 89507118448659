import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import CustomListComponent from '../../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import { createAction } from '../../../utils';
import DeletePrompt from '../../../components/base/DeletePrompt';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';

const SurveyFormList = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  const {
    pageInfo,
    totalCount,
    surveyFormList,
    totalPage,
    checkedList,
    displayFields,
  } = useSelector((state) => ({
    pageInfo: state.surveyForm.pageInfo,
    totalCount: state.surveyForm.totalCount,
    surveyFormList: state.surveyForm.pagedList,
    totalPage: state.surveyForm.totalPage,
    checkedList: state.surveyForm.checkedList,
    displayFields: state.surveyForm.listDisplayFields,
    }));

  useEffect(() => {
    const searchDict = queryString.parse(location.search);
    const searchKey = searchDict['search'];
    const stringRank = searchDict['rank'] || 'true';
    const stringPage = searchDict['page'] || 1;
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);
    dispatch(
      createAction('surveyForm/getList')({
        ...searchDict,
        page,
        rank,
        searchKey: searchKey,
      }),
    );
  }, [dispatch, location]);

  const buttons = [
    <AuthButton
      title="Create Survey Form"
      action={() => {
        history.push('/survey_forms/create');
      }}
      requires={PermissionCodes.addSurveyForm}
    />,
  ];

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.changeSurveyForm,
    },
  ];

  const tabs = [
    {
      name: '',
      content: (
        <BaseTabListContainer
          hideTab={true}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount ? totalCount : 0}
          listContent={
            <BaseListContainer
              fields={displayFields}
              model={'surveyForm'}
              permissionGroup={PermissionCodes.surveyform}
              dataList={surveyFormList}
              totalPage={totalPage}
              deleteInfo={{
                data: [],
                title: 'Survey Form',
                onComfirm: {},
              }}
              actions={['Edit', 'Detail', 'Delete']}
            />
          }
        />
      ),
    },
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail: 'Survey Form',
          title: 'Survey Form',
        }}
        buttons={buttons}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={true}
        tabs={tabs}
      />
      <DeletePrompt
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch(
            createAction('surveyForm/delete')({
              afterAction: () => history.push(location),
            }),
          );
        }}
        data={checkedList}
        title={'Survey Form'}
      />
    </>
  );
};

export default SurveyFormList;
