import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, connect } from 'react-redux';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import BaseForm from '../../../components/base/v2/BaseForm';
import { createOrEditTermsAndConditionsBreads } from '../../../config/CustomBreadConfig';
import { createAction } from '../../../utils';
import {
  saveTermsAndConditionsToSessionStorage,
  removeTermsAndConditionsSessionStroage,
} from '../../../models/TermsAndConditionsModel';
import { APIStatus } from '../../../config/CustomEnums';
import CreateTermsAndConditionsStepOne from '../../../components/termsAndConditions/CreateTermsAndConditionsStepOne';
import './CreateTermsAndConditions.scss';

const CreateTermsAndConditions = ({
  defaultValues,
  dispatch,
  hasUpdatedDefaultValues,
  formHasSubmitted,
  apiStatus,
}) => {
  const params = useParams();
  const history = useHistory();
  const { id } = params;
  const queryString = require('query-string');
  useEffect(() => {
    if (id) {
      dispatch(createAction('termsAndConditions/getOneDetail')({ id }));
      dispatch(
        createAction('campaignList/getAllList')({
          others: {
            termsAndConditionsTemplateIn: id,
            getAll: false,
          },
        }),
      );
      dispatch(
        createAction('couponList/getAllList')({
          termsAndConditionsTemplateIn: id,
          getAll: false,
        }),
      );
    } else {
      dispatch(
        createAction('termsAndConditions/loadTermsAndConditionsFromCookie')(),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
    return () => {
      dispatch(createAction('termsAndConditions/clearData')());
      dispatch(createAction('campaignList/clearData')());
      dispatch(createAction('couponList/clearData')());
    };
  }, []);

  useEffect(() => {
    if (
      formHasSubmitted &&
      apiStatus === APIStatus.success &&
      !history.location.state?.from
    ) {
      history.push({ pathname: '/terms_and_conditions' });
    }
  }, [formHasSubmitted, apiStatus, history]);

  return (
    <>
      <BaseForm
        defaultValues={defaultValues}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={formHasSubmitted}
        tempSave={(save, getValues) => {
          if (save) {
            saveTermsAndConditionsToSessionStorage(getValues());
          } else {
            removeTermsAndConditionsSessionStroage();
          }
        }}
        showFinishPop={
          formHasSubmitted &&
          apiStatus === APIStatus.success &&
          history.location.state?.from
        }
        content={[<CreateTermsAndConditionsStepOne detail={defaultValues} />]}
        currentStep={0}
        breadcrumb={
          <CustomBreadcrumb
            breadcrumbs={createOrEditTermsAndConditionsBreads(
              id,
              defaultValues?.title,
            )}
          />
        }
        caution={{
          detail:
            'Create  T & C template for applying in Campaign or Coupon set.',
          title: id ? defaultValues?.title : 'Create T & C Template',
        }}
      />
    </>
  );
};
const mapPropsToState = (state) => ({
  defaultValues: state.termsAndConditions.detail,
  hasUpdatedDefaultValues:
    state.termsAndConditions.detail.hasUpdatedDefaultValues,
  formHasSubmitted: state.termsAndConditions.formHasSubmitted,
  apiStatus: state.termsAndConditions.apiStatus,
});
export default connect(mapPropsToState)(CreateTermsAndConditions);
