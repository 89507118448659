import { BaseHelper } from './BaseGraphQLAPIHelper';

const INTEREST_PREFERENCE_NODE = `{
  id
  pk
  name
  relatedCustomerNumber
  translations {
      edges {
          node {
              language
              pk
              name
          }
      }
  }
}`;

export const getInterestPreferenceList = (afterCursor, params) => {
  let filter = '';
  // console.log('@33', afterCursor, params);
  const { sort, filterName } = params || {};
  if (filterName) {
    filter = `, nameIcontains:"${filterName}"`;
  }
  if (params.filter) {
    filter += `, nameOrIdIcontains:"${params.filter}"`;
  }

  let orderBy = '-pk';
  if (sort) {
    orderBy = sort;
  }
  let pageSize = 20;
  if (params.isAll) {
    pageSize = 100;
  }

  const query = `{
    interestPreferences(first: ${pageSize}, after:"${afterCursor}"${filter}, orderBy: "${orderBy}") {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node ${INTEREST_PREFERENCE_NODE}
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getInterestPreferenceDetail = (id) => {
  const query = `{
    interestPreference(id: "${id}")
      ${INTEREST_PREFERENCE_NODE}
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const createInterestPreference = (payload) => {
  const query = `mutation CreateInterestPreference($input: CreateInterestPreferenceInput!) {
    createInterestPreference(input: $input) {
      node ${INTEREST_PREFERENCE_NODE}
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: {
      ...payload,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateInterestPreference = (payload) => {
  const query = `mutation UpdateInterestPreference($input: UpdateInterestPreferenceInput!) {
    updateInterestPreference(input: $input) {
      node ${INTEREST_PREFERENCE_NODE}
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: {
      ...payload,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteInterestPreferences = (ids) => {
  const query = `mutation DeleteInterestPreferences($input: DeleteInterestPreferencesInput!) {
    deleteInterestPreferences(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids,
    },
  };
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};
