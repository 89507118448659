import React, { useEffect } from 'react';
import '../logoLabel/LogoLabelDetail.scss';
import { useHistory, useParams } from 'react-router';
import { connect } from 'react-redux';
import AuthButton from '../../../components/base/AuthButton';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { createAction } from '../../../utils';
import Loading from '../../../components/base/Loading';
import SurveyFormOverView from '../../../components/surveyForm/SurveyFormOverView';

const SurveyFormDetail = ({ detail, dispatch, relatedCouponsetList }) => {
  const history = useHistory();
  const params = useParams();
  const { id } = params;
  useEffect(() => {
    dispatch(createAction('surveyForm/getOneDetail')({ id }));
    dispatch(
      createAction('couponList/getAllList')({
        surveyFormIn: id,
        getAll: false,
      }),
    );
    return () => {
      dispatch(createAction('surveyForm/clearData')());
      dispatch(createAction('couponList/clearData')());
    };
  }, [id]);

  const tabs = [
    {
      name: 'Detail',
      content: detail.pk ? (
        <>
          <SurveyFormOverView
            title="information"
            itemCouponsTitle={['Linked coupons']}
            itemTitleList={['Name', 'Options', 'Linked coupons']}
            itemValueList={[detail.name, detail.options, relatedCouponsetList]}
          />
        </>
      ) : (
        <Loading />
      ),
    },
  ];

  const buttons = [
    <AuthButton
      title="Edit"
      action={() => {
        history.push({
          pathname: 'edit/',
        });
      }}
      requires={PermissionCodes.changeSurveyForm}
    />,
  ];
  return (
    <>
      <CustomListComponent
        caution={{
          detail: '',
          title: detail?.name,
        }}
        defaultActiveKey={tabs[0].name}
        breadcrumb={<CustomBreadcrumb name={detail?.name} />}
        buttons={buttons}
        tabs={tabs}
      />
    </>
  );
};

const mapPropsToState = (state) => ({
  detail: state.surveyForm.detail,
  relatedCouponsetList: state.couponList.notPagedAllList,
});
export default connect(mapPropsToState)(SurveyFormDetail);
