import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import CustomSearchInput from '../base/CustomSearchInput';
import { DropDownWithDefaultSelect } from '../base/ActionsDropdown';
import { createAction, removeElementFromArray, useCompare } from '../../utils';
import { Image } from 'react-bootstrap';
import Search from '../../assets/images/drop_down_filter.svg';
import XButton from '../../assets/images/x-button.svg';
import BasePrompt from '../base/prompt/BasePrompt';

function ProductDetail({ item }) {
  return (
    <div className="display-flex-area">
      <Image src={item.image?.originalSrc} className="product-selector-image" />
      <div className="d-flex flex-column">
        <label>{item.name}</label>
        <label>ID:{item.pk}</label>
      </div>
    </div>
  );
}

export default function ProductSelector(props) {
  const title = props.title;
  const groupName = props.groupName;
  const conditionIndex = props.conditionIndex;

  const defaultValue = props.defaultValue || [];

  const isReset = props.isReset || false;
  const changeReset = props.changeReset || (() => {});
  const isCreate = props.isCreate;

  const { productList } = useSelector((state) => ({
    productList: state.bannerList.productList,
  }));

  const [selected, setSelected] = useState(defaultValue);
  const [showSelectPopup, setShowSelectPopup] = useState(false);
  const [filterKey, setFilterKey] = useState();
  const [popSelected, setPopSelected] = useState(defaultValue);
  const [source, setSource] = useState(productList);

  const dispatch = useDispatch();
  const vals = { selected };
  const hasValsChanged = useCompare(vals);

  useEffect(() => {
    if (hasValsChanged) {
      dispatch({
        type: 'segments/changeVals',
        payload: {
          vals,
          groupName,
          conditionIndex,
        },
      });
    }
  });

  useEffect(() => {
    dispatch(createAction('bannerList/getProductList')({}));
  }, [dispatch]);

  useEffect(() => {
    if (isReset) {
      if (isCreate) {
        setSelected([]);
      } else {
        setSelected(defaultValue || []);
      }

      changeReset(false);
    }
  }, [isReset, isCreate, changeReset]);

  return (
    <>
      <div>
        <CustomTitleLabel title={title} />
        <div className="d-flex flex-column">
          <button
            type="button"
            className="product-selector-search-button product-selector-search-button-text"
            onClick={() => {
              setShowSelectPopup(true);
              setSource(productList);

              setFilterKey();
              // setSource([{ name: "111", pk: "dsds333" }, { name: "2222", pk: "dsds333" }, { name: "33333", pk: "dsds333" }, { name: "44444", pk: "dsds333" }, { name: "555555", pk: "dsds333" }, { name: "66666", pk: "dsds333" }])
            }}
          >
            <Image
              src={Search}
              className="filterable-search-bar-icon search_bar_icon product-selector-search-button-icon"
            />
            Search for…
          </button>
          {productList.map((item) => {
            if (selected.includes(item.pk)) {
              return (
                <div className="display-flex-area">
                  <ProductDetail item={item} />
                  <button
                    className="product-selector-search-delete"
                    onClick={() => {
                      const leftProducts = removeElementFromArray(
                        selected,
                        item.pk,
                      );
                      setSelected(leftProducts);
                      setPopSelected(leftProducts);
                    }}
                  >
                    x
                  </button>
                </div>
              );
            }
          })}
        </div>
      </div>
      <BasePrompt
        show={showSelectPopup}
        customClass="product-selector-popup"
        title={title}
        closeAction={() => setShowSelectPopup(false)}
        leftButton={{ text: 'Cancel', action: () => setShowSelectPopup(false) }}
        rightButton={{
          text: 'Done',
          action: () => {
            setShowSelectPopup(false);
            setSelected(popSelected);
            // setSource(productList);
          },
        }}
        otherBody={() => {
          return (
            <>
              <CustomSearchInput
                customClass={'product-selector-popup-searchbar'}
                customIconClass="product-selector-search-icon"
                filterAction={(value) => {
                  setSource(
                    productList.filter(
                      (item) =>
                        item.name.toLowerCase().indexOf(value.toLowerCase()) >=
                        0,
                    ),
                  );
                }}
              />

              <div className="product-selector-popup-list">
                {source.map((item) => {
                  return (
                    <div>
                      <label
                        className={`checkbox-container product-selector-checkbox-container`}
                      >
                        <ProductDetail item={item} />
                        <input
                          type="checkbox"
                          checked={popSelected.includes(item.pk)}
                          onChange={() => {
                            if (popSelected.includes(item.pk)) {
                              setPopSelected(
                                removeElementFromArray(popSelected, item.pk),
                              );
                            } else {
                              setPopSelected([...popSelected, item.pk]);
                            }
                          }}
                        />
                        <span className="checkmark product-selector-checkmark"></span>
                      </label>
                    </div>
                  );
                })}
              </div>
            </>
          );
        }}
      />
    </>
  );
}
