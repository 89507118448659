import React from 'react';
import './CouponKeyVisual.scss';
import { Controller, useFormContext } from 'react-hook-form';
import arrayMove from 'array-move';
import SorableTourCard from '../../tourCard/SortableTourCard';
import CustomTitleWithSwitch from '../../base/CustomTitleWithSwitch';
import { hasError } from '../../base/ErrorFieldMessage';

const EMPTY_TOUR_CARD_LIST = [];

const CouponTourCardSection = ({ language, focusChange, isCoupon }) => {
  const { control, watch, setValue, formState, getValues, register } =
    useFormContext();
  const { errors } = formState;
  const watchTourCards = watch('tourCards');
  const watchShowTourCard = watch('showTourCard');
  const watchTouCardName = watch(
    `translations.${language}.tourCardSectionTitle`,
  );
  return (
    <div
      onFocus={() => {
        focusChange('tourCard');
      }}
    >
      <div className="first-section-title">TOUR CARD</div>
      <Controller
        control={control}
        name="showTourCard"
        render={({ field: { value } }) => (
          <CustomTitleWithSwitch
            title="Show Tour card"
            defaultValue={value}
            setValue={(newValue) => {
              setValue('showTourCard', newValue, {
                shouldDirty: true,
              });
            }}
          />
        )}
      />
      {watchShowTourCard ? (
        <>
          <label className="second-section-title create-section-label-bottom-space">
            Tour card section title (Optional)
          </label>
          <div>
            <input
              type="text"
              className={'text-input-field'}
              value={watchTouCardName || ''}
              {...register(`translations.${language}.tourCardSectionTitle`)}
              maxLength={200}
            />
          </div>
          <SorableTourCard
            hasError={hasError(errors, 'tourCards')}
            isCoupon={isCoupon}
            data={watchTourCards || EMPTY_TOUR_CARD_LIST}
            onSortEnd={(oldIndex, newIndex) => {
              setValue(
                'tourCards',
                arrayMove(watchTourCards, oldIndex, newIndex),
                {
                  shouldDirty: true,
                },
              );
            }}
            setData={(values) => {
              setValue('tourCards', values, {
                shouldDirty: true,
              });
            }}
          />
        </>
      ) : null}
    </div>
  );
};

export default CouponTourCardSection;
