import React, { useState, useEffect, useMemo } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import ContentSections from '../base/ContentSections';
import StepFourContentSection from './StepFourContentSection';
import StepFourPropertiesSection from './StepFourPropertiesSection';
import StepFourEntitlementSection from './StepFourEntitlementSection';
import PublishGoodieBagPrompt from './PublishGoodieBagPrompt';
import {
  SaveAndBackButtons,
  BackAndMultipleButtons,
  SaveTempButton,
  SaveButton,
} from '../base/BottomStepComponent';
import ListButtonsGroup from '../base/ListButtonsGroup';
import { useHistory, useParams } from 'react-router-dom';
import { createAction, isArrayEmpty } from '../../utils';
import { APIStatus } from '../../config/CustomEnums';
import Loading from '../base/Loading';
import { PermissionCodes } from '../../config/PermissionCodes';
import GoodieBagsAllocationPrompt from './GoodieBagsAllocationPrompt';
import { validate } from './CreateGoodieBagValidate';
import { useFormContext } from 'react-hook-form';
import {
  GOODIE_BAG_REWARD_TYPE_OPTIONS,
  getGoodieBagCouponCampaignsList,
  checkIsOptionD,
} from '../../models/GoodieBagsModel';
import StepFourShowFilterSection from './StepFourShowFilterSection';
import StepFourOutOfStockSection from './StepFourOutOfStockSection';
import BasePrompt from '../base/prompt/BasePrompt';

const ActionType = {
  save: 'save',
  publish: 'publish',
  none: 'none',
};

function CreateGoodieBagStepFour() {
  const params = useParams();
  const { getValues, clearErrors, setError } = useFormContext();

  const [saveDisabled, setSaveDisabled] = useState(false);
  const [showPublishPrompt, setShowPublishPrompt] = useState(false);
  const [actionType, setActionType] = useState(ActionType.none);
  const [showSetAllocatePrompt, setShowSetAllocatePrompt] = useState(false);
  const [showCodeReminder, setShowCodeReminder] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const { createStatus, originalGoodieBag, trackingCodeList } = useSelector(
    (state) => ({
      createStatus: state.goodieBags.createStatus,
      originalGoodieBag: state.goodieBags.detail,
      trackingCodeList: state.entitlementTrackingCodes.pagedList,
    }),
  );

  const goodieBag = getValues() || {};
  console.log('CreateGoodieBagStepFour:', goodieBag);

  useEffect(() => {
    if (createStatus === APIStatus.success) {
      setShowPublishPrompt(!history.location?.state?.from);
    }
    if (
      createStatus === APIStatus.success ||
      createStatus === APIStatus.failed
    ) {
      setSaveDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStatus]);

  const sections = [
    <StepFourPropertiesSection />,
    ...(goodieBag.rewardType !== GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value
      ? [<StepFourShowFilterSection />, <StepFourOutOfStockSection />]
      : []),
    <StepFourEntitlementSection data={getValues()} fromDetail={false} />,
  ];

  const isOptionD = (merchants) =>
    (merchants || []).filter((item) => item.handleOption.value === 'OPTION_D')
      .length > 0;

  const saveGoodieBag = (isPublish, merchants, allocatedNum, afterAction) => {
    const optionCOTACount = (merchants || []).filter(
      (item) => item.handleOption.value === 'OPTION_C',
    ).length;
    if (optionCOTACount > 1 && allocatedNum > 0) {
      setShowSetAllocatePrompt(true);
    } else if (optionCOTACount === 1 && allocatedNum > 0) {
      setActionType(ActionType.publish);
      dispatch(
        createAction('goodieBags/createOrUpdateGoodieBag')({
          isPublished: isPublish,
          data: goodieBag,
          otaAllocatedNum: [
            {
              brandId: merchants[0].pk,
              number: allocatedNum,
            },
          ],
          afterAction,
          isOptionD: isOptionD(merchants),
        }),
      );
    } else {
      dispatch(
        createAction('goodieBags/createOrUpdateGoodieBag')({
          isPublished: isPublish,
          data: goodieBag,
          otaAllocatedNum: [],
          afterAction,
          isOptionD: isOptionD(merchants),
        }),
      );
    }
  };

  const saveTempAction = (afterAction) => {
    if (
      goodieBag.rewardType ===
        GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value &&
      goodieBag.isPublished &&
      goodieBag.batchOta?.length > 0 &&
      (!goodieBag.overallAmount || parseInt(goodieBag.overallAmount) < 0) &&
      !isOptionD
    ) {
      dispatch({
        type: 'navBars/updateState',
        payload: {
          saveDiscardToastShowing: {
            value: true,
            type: 'When publish goodie bag at first time, must have overall amount value',
          },
        },
      });
      return;
    }

    setSaveDisabled(true);
    if (goodieBag.isPublished) {
      saveGoodieBag(
        true,
        isArrayEmpty(goodieBag.batchOta) ? goodieBag.ota : goodieBag.batchOta,
        goodieBag.overallAmount,
        afterAction,
      );
    } else {
      setActionType(ActionType.save);
      dispatch(
        createAction('goodieBags/createOrUpdateGoodieBag')({
          data: goodieBag,
          afterAction: afterAction,
          isOptionD: isOptionD(
            isArrayEmpty(goodieBag.batchOta)
              ? goodieBag.ota
              : goodieBag.batchOta,
          ),
        }),
      );
    }
  };

  const saveAction = () => {
    const campaignList = getGoodieBagCouponCampaignsList(goodieBag);
    if (campaignList?.length <= 0) {
      dispatch({
        type: 'navBars/updateState',
        payload: {
          saveDiscardToastShowing: {
            value: true,
            type: 'When publish goodie bag at first time, must have one campaign related',
          },
        },
      });
      return;
    }

    if (
      goodieBag.rewardType ===
        GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value &&
      ((!goodieBag.isPublished &&
        (!goodieBag.overallAmount || parseInt(goodieBag.overallAmount) <= 0)) ||
        (goodieBag.isPublished &&
          !isOptionD &&
          goodieBag.batchOta?.length > 0 &&
          (!goodieBag.overallAmount || parseInt(goodieBag.overallAmount) < 0)))
    ) {
      dispatch({
        type: 'navBars/updateState',
        payload: {
          saveDiscardToastShowing: {
            value: true,
            type: 'When publish goodie bag at first time, must have overall amount value',
          },
        },
      });
      return;
    }

    setSaveDisabled(true);
    saveGoodieBag(
      !goodieBag.isPublished,
      goodieBag.ota,
      goodieBag.overallAmount,
    );
  };

  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 3,
      isBack,
    });
    dispatch({
      type: 'goodieBags/stepChange',
      payload: { isBack: isBack, step: 3, isValid },
    });
  };

  const getButtons = () => {
    const buttons = [];

    if (
      goodieBag.rewardType === GOODIE_BAG_REWARD_TYPE_OPTIONS.fortuneBag.value
    ) {
      buttons.push(
        <SaveTempButton
          text="Save and preview"
          action={() =>
            saveTempAction((id) => {
              console.log('@@179: ', id);
              const domain = process.env.REACT_APP_FRONTEND_LINK;
              window.open(
                `${domain}/en/goodie-bag-detail?goodie_bag_id=${id}&tester_preview=true`,
                '_blank',
              );
            })
          }
        />,
      );
    }

    if (!params.id && !goodieBag.pk) {
      buttons.push(
        <SaveTempButton
          text="Save only"
          action={saveTempAction}
          disabled={saveDisabled}
        />,
      );
    }
    if (params.id || goodieBag.pk) {
      const ComponentInstance = goodieBag.isPublished
        ? SaveButton
        : SaveTempButton;
      buttons.push(
        <ComponentInstance
          text="Update"
          action={() => {
            if (
              trackingCodeList?.length > 0 &&
              checkIsOptionD(
                isArrayEmpty(goodieBag.batchOta)
                  ? goodieBag.ota
                  : goodieBag.batchOta,
              )
            ) {
              setShowCodeReminder(true);
            } else {
              saveTempAction();
            }
          }}
          disabled={saveDisabled}
        />,
      );
    }
    if (!goodieBag.isPublished) {
      buttons.push(
        <SaveButton
          text="Save and publish"
          action={saveAction}
          disabled={saveDisabled}
        />,
      );
    }
    return buttons;
  };
  const bottomButtons = getButtons();

  return (
    <>
      {createStatus !== APIStatus.calling ? (
        <>
          <StepFourContentSection />
          <ContentSections sections={sections} hidePreview={true} />
        </>
      ) : (
        <ContentSections sections={[<Loading />]} hidePreview={true} />
      )}

      <BackAndMultipleButtons
        backAction={() => stepChangeAction(true)}
        multipleButtons={[
          <ListButtonsGroup
            hideExtraButtonWidth={600}
            hideAllButtonWidth={400}
            hideExtraPopPosition="top"
            hideAllPopPosition="topRight"
            extraButtons={bottomButtons}
          />,
        ]}
      />

      <PublishGoodieBagPrompt
        isSave={actionType === ActionType.save}
        isPublish={!goodieBag.isPublished}
        show={showPublishPrompt}
        continueId={originalGoodieBag.pk}
      />

      <GoodieBagsAllocationPrompt
        show={showSetAllocatePrompt}
        closeAction={() => {
          setSaveDisabled(false);
          setShowSetAllocatePrompt(false);
        }}
        nextAction={(otaAllocatedNum) => {
          setSaveDisabled(false);
          setShowSetAllocatePrompt(false);
          setActionType(ActionType.publish);
          dispatch(
            createAction('goodieBags/createOrUpdateGoodieBag')({
              data: goodieBag,
              isPublished: true,
              otaAllocatedNum,
              isOptionD: isOptionD(
                isArrayEmpty(goodieBag.batchOta)
                  ? goodieBag.ota
                  : goodieBag.batchOta,
              ),
            }),
          );
        }}
        ota={
          isArrayEmpty(goodieBag.batchOta) ? goodieBag.ota : goodieBag.batchOta
        }
        goodieBag={goodieBag}
      />

      <BasePrompt
        show={showCodeReminder}
        customClass={'reminder-prompt'}
        closeAction={() => {
          setShowCodeReminder(false);
        }}
        leftButton={{
          text: 'Cancel',
          action: () => setShowCodeReminder(false),
        }}
        rightButton={{
          text: 'Continue',
          action: () => {
            saveTempAction();
            setShowCodeReminder(false);
          },
        }}
        title={'Reminder'}
        description={
          'Due to the change of VISIBLE PERIOD of goodie bag, the following tracking code(s) will be unable to acquire. Please go to the Entitlement Page to adjust the ‘tracking code active period(s)’.'
        }
        otherBody={() => {
          const grouped = trackingCodeList.reduce((codeGroup, newItem) => {
            codeGroup[newItem?.merchant?.pk] = codeGroup[
              newItem.merchant.pk
            ] || { name: newItem.merchant.name, codes: [] };
            codeGroup[newItem.merchant.pk].codes.push(newItem.trackingCode);
            return codeGroup;
          }, {});

          return (
            <>
              {Object.keys(grouped || {}).map((key) => (
                <li>
                  [ID: {key}]{grouped[key].name} -{' '}
                  {grouped[key].codes?.join(', ')}
                </li>
              ))}
            </>
          );
        }}
      />
    </>
  );
}

export default CreateGoodieBagStepFour;
