export const CouponURLQueryKeys = {
  searchKey: 'searchKey',
  rank: 'rank',
  page: 'page',
};

export const CouponURLFragments = {
  overview: 'Overview',
  list: 'Single Coupons Generated',
  physical: 'Physical coupon',
};
