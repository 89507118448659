import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import SpecificCustomSwitchButton from "../../containers/merchants/stores/SpecificCustomSwitchButton";
import { insertKeyToTranslations } from "../../utils";
import { CustomTitleWithDropDown } from "../customer/CustomTitleWithDropDown";
import { CustomTitleLabel } from "../earning/CustomBaseComponments";
import BaseMarkDownArea from "./BaseMarkDownArea";
import { errorMessage, hasError } from "./ErrorFieldMessage";
import BasePrompt from "./prompt/BasePrompt";
import './MarkDownAreaWithTC.scss';
import BaseMarkDownAreaWithoutController from "./BaseMarkDownAreaWithoutController";

const REMINDER_PROMPT_TYPE = {
  follow: {
    title: 'Confirm to follow the selected template?',
    description:
      'The content of the selected template has been modified. If “ON”, it will be replaced by the selected template again.',
  },
  change: {
    title: 'Confirm to change or clear the selected template?',
    description:
      'The content of the template has been modified. Upon confirmation, the modified content will be removed.',
  },
};

function MarkDownAreaWithTC({
  withoutController,
  languages,
  language,
  hideTC,
  linkToTCTemplate,
  followTemplateKey,
  getTCListKey,
  defaultFilterTC,
  TCList,
  areaTitle,
  titleKey,
  titleName,
  titleDisabled,
  contentKey,
  contentName,
  contentTips,
  contentDisabled,
  contentExtra,
}) {
  const { watch, formState, setValue, getValues } = useFormContext();
  const errors = formState.errors;
  const translations = watch('translations') || {};
  const titleValue = translations?.[language]?.[titleKey];
  const contentValue = translations?.[language]?.[contentKey];

  const formLinkToTCTemplate = linkToTCTemplate ? watch(linkToTCTemplate) : {};
  const followTemplateValue = followTemplateKey ? watch(followTemplateKey) : false;
  const [linkToTCTemplateSelected, setLinkToTCTemplateSelected] = useState(formLinkToTCTemplate);
  const [showReminderPrompt, setShowReminderPrompt] = useState(false);
  const [reminderPromptType, setReminderPromptType] = useState('change');
  const [titleChanged, setTitleChanged] = useState(false);
  const [contentChanged, setContentChanged] = useState(false);
  const resetContentChanged = watch(`resetContentChanged-${language}`) || false;

  const setTCTitleAndContent = (data) => {
    setTitleChanged(false);
    setContentChanged(false);
    (languages || []).forEach((language) => {
      setValue(`resetContentChanged-${language?.code}`, true);
      insertKeyToTranslations({
        language: language?.code,
        key: titleKey,
        value: data?.translations?.[language?.code]?.title || '',
        setValue,
        getValues,
      });
      insertKeyToTranslations({
        language: language?.code,
        key: contentKey,
        value: data?.translations?.[language?.code]?.content || '',
        setValue,
        getValues,
      });
    })
  }

  const promptCancelAction = () => {
    reminderPromptType === 'change'
      ? setLinkToTCTemplateSelected(formLinkToTCTemplate)
      : setValue(followTemplateKey, false, { shouldDirty: true });
    setShowReminderPrompt(false);
  };

  const promptConfirmAction = () => {
    if (reminderPromptType === 'change') {
      setValue(linkToTCTemplate, linkToTCTemplateSelected, { shouldDirty: true });
      setValue(followTemplateKey, true, { shouldDirty: true });
    }
    setTCTitleAndContent(linkToTCTemplateSelected);
    setTitleChanged(false);
    setContentChanged(false);
    setShowReminderPrompt(false);
  };

  const setFieldValue = (data, field, key) => {
    if (field === 'title') {
      setTitleChanged(true);
    }
    if (field === 'content') {
      setContentChanged(true);
    }
    insertKeyToTranslations({
      language,
      key: key,
      value: data,
      setValue,
      getValues,
    });
  };

  useEffect(() => {
    if (hideTC) {
      setTitleChanged(false);
      setContentChanged(false);
    }
    if (!hideTC && formLinkToTCTemplate?.pk && followTemplateValue) {
      setTCTitleAndContent(linkToTCTemplateSelected);
    }
  }, [hideTC])

  return (
    <>
      {areaTitle
        ? <label className="create-section-title">{areaTitle}</label>
        : <></>
      }
      {hideTC
        ? <></>
        : <>
          <CustomTitleWithDropDown
            customClass="link-to-terms-and-conditions-template-dropdown"
            title="Link to Terms & Conditions Template（optional）"
            labelContainPk={true}
            defaultValue={
              formLinkToTCTemplate?.pk
                ? {
                  label: `[${formLinkToTCTemplate?.pk}] ${formLinkToTCTemplate?.name}`,
                  value: formLinkToTCTemplate,
                }
                : { label: '', value: '' }
            }
            setValue={
              (value) => {
                setLinkToTCTemplateSelected(value?.value);
                if (value?.value === null && value?.label === null
                  && followTemplateValue === false
                ) {
                  setValue(linkToTCTemplate, null, { shouldDirty: true });
                  return;
                }
                if (formLinkToTCTemplate?.pk && (titleChanged || contentChanged)) {
                  setShowReminderPrompt(true);
                  setReminderPromptType('change');
                  return;
                }
                setValue(linkToTCTemplate, value?.value, { shouldDirty: true });
                setValue(followTemplateKey, true, { shouldDirty: true });
                setTCTitleAndContent(value?.value);
              }}
            source={TCList}
            loadMoreAction={`termsAndConditions/${getTCListKey}`}
            filterAction={`termsAndConditions/${getTCListKey}`}
            placeholder="Search by T&C Template ID and name"
            defaultFilter={defaultFilterTC}
          />
          {formLinkToTCTemplate?.pk
            ? <>
              <CustomTitleLabel title="Follow Selected Terms & Conditions Template" />
              <div>
                <SpecificCustomSwitchButton
                  checked={followTemplateValue}
                  onChange={
                    (value) => {
                      setValue(followTemplateKey, value, { shouldDirty: true });
                      if (value) {
                        if (formLinkToTCTemplate?.pk && (titleChanged || contentChanged)) {
                          setShowReminderPrompt(true);
                          setReminderPromptType('follow');
                          return;
                        }
                        setTCTitleAndContent(linkToTCTemplateSelected);
                      }
                    }}
                />
              </div>
              <label className="link-to-terms-and-conditions-template-tips">
                If Off, any further changes on the template will not be updated here.
              </label>
            </>
            : <></>}
        </>}
      {withoutController
        ? <BaseMarkDownAreaWithoutController
          title={{
            titleKey: `translations.${language}.${titleKey}`,
            titleName: titleName,
            titleValue: titleValue,
            titleDisabled: titleDisabled || (formLinkToTCTemplate?.pk && followTemplateValue),
            titleError: hasError(errors, titleKey),
            titleErrorMessage: errorMessage(errors, titleKey),
            titleExtra: { value: titleValue },
            titleReminder: formLinkToTCTemplate?.pk && titleChanged,
          }}
          setTitleValue={(data) => setFieldValue(data, 'title', titleKey)}
          content={{
            contentKey: `translations.${language}.${contentKey}`,
            contentName: contentName,
            contentTips: contentTips,
            contentValue: contentValue,
            contentDisabled: contentDisabled || (formLinkToTCTemplate?.pk && followTemplateValue),
            contentError: hasError(errors, contentKey),
            contentErrorMessage: errorMessage(errors, contentKey),
            contentExtra: {
              resetInitValueByTC: resetContentChanged,
              resetInitByTC: () => {
                setValue(`resetContentChanged-${language}`, false);
              },
              ...contentExtra,
            },
            contentReminder: formLinkToTCTemplate && contentChanged,
          }}
          setContentValue={(data) => setFieldValue(data, 'content', contentKey)}
        />
        : <BaseMarkDownArea
          title={{
            titleKey: `translations.${language}.${titleKey}`,
            titleName: titleName,
            titleValue: titleValue,
            titleDisabled: titleDisabled || (formLinkToTCTemplate?.pk && followTemplateValue),
            titleError: hasError(errors, titleKey),
            titleErrorMessage: errorMessage(errors, titleKey),
            titleExtra: { value: titleValue },
            titleReminder: formLinkToTCTemplate?.pk && titleChanged,
          }}
          setTitleValue={(data) => setFieldValue(data, 'title', titleKey)}
          content={{
            contentKey: `translations.${language}.${contentKey}`,
            contentName: contentName,
            contentTips: contentTips,
            contentValue: contentValue,
            contentDisabled: contentDisabled || (formLinkToTCTemplate?.pk && followTemplateValue),
            contentError: hasError(errors, contentKey),
            contentErrorMessage: errorMessage(errors, contentKey),
            contentExtra: {
              resetInitValueByTC: resetContentChanged,
              resetInitByTC: () => {
                setValue(`resetContentChanged-${language}`, false);
              },
              ...contentExtra,
            },
            contentReminder: formLinkToTCTemplate && contentChanged,
          }}
          setContentValue={(data) => setFieldValue(data, 'content', contentKey)}
        />
      }
      <BasePrompt
        show={showReminderPrompt}
        closeAction={promptCancelAction}
        rightButton={{
          text: 'Confirm',
          action: promptConfirmAction,
        }}
        leftButton={{
          text: 'Cancel',
          action: promptCancelAction,
        }}
        title={REMINDER_PROMPT_TYPE?.[reminderPromptType]?.title}
        description={REMINDER_PROMPT_TYPE?.[reminderPromptType]?.description}
      />
    </>
  );
}

export default MarkDownAreaWithTC;