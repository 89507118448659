import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { createAction } from '../../../utils';
import ContentSections from '../../../components/base/ContentSections';
import { APIStatus, LanguageConfig } from '../../../config/CustomEnums';
import Loading from '../../../components/base/Loading';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';

const StoreCategoryDetail = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  const { category, createStatus } = useSelector((state) => ({
    category: state.createStoreCategory.category,
    createStatus: state.createStoreCategory.createStatus,
  }));

  const getSubCategoryDataWithLanguage = (language) => {
    if (language === LanguageConfig.english) {
      return (
        category.subCategories
          .map((subCategory) => `${subCategory.name}(${subCategory.order})`)
          .join('\n') || '-'
      );
    }
    return (
      category.subCategories
        .map((subCategory) => {
          const name =
            subCategory.translations.filter(
              (translation) => translation.language === language,
            )[0]?.name || '-';
          return `${name}(${subCategory.order})`;
        })
        .join('\n') || '-'
    );
  };
  const order = `(${category?.order})`;
  const tabs = [
    {
      name: 'Detail',
      content: (
        <ContentSections
          sections={[
            createStatus === APIStatus.calling ? (
              <Loading />
            ) : (
              <>
                <label className="create-section-title">CONTENT</label>
                <PerfectScrollbar>
                  <table className="campaign-category-detail-table">
                    <thead>
                      <th></th>
                      <th>English</th>
                      <th>繁體</th>
                      <th>简体</th>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Name(Order)</td>
                        <td>
                          {category.name}
                          {order}
                        </td>
                        <td>
                          {category.translations.filter(
                            (translation) =>
                              translation.language ===
                              LanguageConfig.traditionalChinese,
                          )[0]?.name || '-'}
                          {order}
                        </td>
                        <td>
                          {category.translations.filter(
                            (translation) =>
                              translation.language ===
                              LanguageConfig.simplifiedChinese,
                          )[0]?.name || '-'}
                          {order}
                        </td>
                      </tr>
                      <tr>
                        <td>Subcategory(Order)</td>
                        <td>
                          {getSubCategoryDataWithLanguage(
                            LanguageConfig.english,
                          )}
                        </td>
                        <td>
                          {getSubCategoryDataWithLanguage(
                            LanguageConfig.traditionalChinese,
                          )}
                        </td>
                        <td>
                          {getSubCategoryDataWithLanguage(
                            LanguageConfig.simplifiedChinese,
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </PerfectScrollbar>
              </>
            ),
          ]}
          hidePreview
          // sectionStyle="detail-info-section-item"
        />
      ),
    },
  ];

  const buttons = [
    <AuthButton
      title="Edit"
      action={() => {
        history.push({
          pathname: 'edit/',
        });
      }}
      requires={PermissionCodes.changeStorecategory}
    />,
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].name);

  useEffect(() => {
    const categoryID = params.id;
    if (categoryID) {
      dispatch(
        createAction('createStoreCategory/getCategory')({ id: categoryID }),
      );
    }
    return () => {
      dispatch({ type: 'createStoreCategory/reset' });
    };
  }, []);

  return (
    <div className="campaign-category-detail">
      <CustomListComponent
        caution={{
          detail: '',
          title: category.name,
        }}
        defaultActiveKey={activeTab}
        breadcrumb={<CustomBreadcrumb name={category.name} />}
        buttons={buttons}
        tabs={tabs}
        onTabChange={(key) => {
          setActiveTab(key);
        }}
      />
    </div>
  );
};

export default StoreCategoryDetail;
