import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import Search from '../../assets/images/drop_down_filter.svg';

function CustomSearchInput({
  customClass,
  customIconClass,
  filterAction,
  placeholder = 'Search for...',
  defaultValue,
}) {
  const [filterKey, setFilterKey] = useState(defaultValue);

  useEffect(() => {
    setFilterKey(defaultValue);
  }, [defaultValue]);

  return (
    <div
      className={`filterable-search-bar content-add-button-selection-filter ${customClass}`}
    >
      <Image
        src={Search}
        className={`filterable-search-bar-icon search_bar_icon ${customIconClass}`}
      />
      <input
        autoFocus
        className="filterable-search-bar-input"
        placeholder={placeholder}
        onChange={(e) => {
          const value = e.target.value;
          setFilterKey(value);

          filterAction(value);
        }}
        value={filterKey}
      />
    </div>
  );
}

export default CustomSearchInput;
