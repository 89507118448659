import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button, Image } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { createAction } from '../../../utils';
import DeleteIcon from '../../../assets/images/delete_icon.svg';

import CustomTitleWithInput from '../../../components/base/CustomTitleWithInput';
import ContentSections from '../../../components/base/ContentSections';
import { hasError } from '../../../components/base/ErrorFieldMessage';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import './SurveyForm.scss';
import { CustomTitleLabel } from '../../../components/earning/CustomBaseComponments';
import ErrorFieldMessage from '../../../components/base/ErrorFieldMessage';

const RenderOptions = () => {
  const {
    setValue,
    watch,
    register,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const watchOptions = watch('options');
  const delOption = watch('delOption');
  return Object.keys(watchOptions || {})
    ?.filter((key) => !delOption?.includes(key))
    ?.map((key, index) => {
      const optionField = `options.${key}`;
      const watchOption = watch(optionField);
      const error = hasError(errors, optionField)
        ? {
            id: optionField,
            error: hasError(errors, optionField),
            message: 'please input option',
          }
        : {};
      const extra = register(optionField);
      return (
        <>
          <div className="d-flex flex-row survey-form-flex-div">
            <input
              onChange={(value) => {
                setValue(optionField, value, {
                  shouldDirty: true,
                });
              }}
              defaultValue={watchOption || ''}
              className={`survey-form-option-input ${
                error.error ? 'error-field' : ''
              } custom-markdown-area-title custom-markdown-area-title-short }`}
              {...extra}
            />
            <Image
              src={DeleteIcon}
              className="survey-form-delete-icon"
              onClick={() => {
                clearErrors(optionField);
                setValue(`delOption`, [...(delOption || []), key], {
                  shouldDirty: true,
                });
              }}
            />
          </div>
          {error ? (
            <ErrorFieldMessage
              id={error.id}
              error={error}
              message={error.message}
            />
          ) : null}
        </>
      );
    });
};

const SurveyFormContent = () => {
  const {
    setValue,
    watch,
    register,
    formState: { errors },
  } = useFormContext();
  const params = useParams();

  const [newIndex, setNewIndex] = useState(0);
  const watchName = watch('name');
  const defaultOptionField = `defaultOption`;
  const watchDefaultOption = watch(defaultOptionField);
  const watchOptions = watch('options');
  if (!params.id) {
    return [
      <>
        <label className="create-section-title">General</label>
        <CustomTitleWithInput
          title="Name"
          defaultValue={watchName}
          setValue={(value) => {
            setValue('name', value, {
              shouldDirty: true,
            });
          }}
          error={
            hasError(errors, 'name')
              ? {
                  id: 'name',
                  error: hasError(errors, 'name'),
                  message: 'please input a name',
                }
              : {}
          }
          extra={register('name')}
        />
        <CustomTitleWithInput
          title="Options"
          defaultValue={watchDefaultOption}
          setValue={(value) => {
            setValue(defaultOptionField, value, {
              shouldDirty: true,
            });
          }}
          error={
            hasError(errors, defaultOptionField)
              ? {
                  id: defaultOptionField,
                  error: hasError(errors, defaultOptionField),
                  message: 'please input a option',
                }
              : {}
          }
          extra={register(defaultOptionField)}
        />
      </>,
      ...RenderOptions(),
      <Button
        onClick={() => {
          setNewIndex(newIndex + 1);

          const newOption = { [`new${newIndex}`]: '' };
          setValue(
            'options',
            {
              ...watchOptions,
              ...newOption,
            },
            {
              shouldDirty: true,
            },
          );
        }}
        className="btn-back-button-common content-add-button"
      >
        + Add options
      </Button>,
    ];
  } else {
    return [
      <>
        <label className="create-section-title">General</label>
        <CustomTitleWithInput
          title="Name"
          defaultValue={watchName}
          setValue={(value) => {
            setValue('name', value, {
              shouldDirty: true,
            });
          }}
          error={
            hasError(errors, 'name')
              ? {
                  id: 'name',
                  error: hasError(errors, 'name'),
                  message: 'please input a name',
                }
              : {}
          }
          extra={register('name')}
        />
      </>,
      <label
        className={`create-section-label create-option-label-top-space`}
        style={{ marginBottom: 0 }}
      >
        Options
      </label>,
      ...RenderOptions(),
      <Button
        onClick={() => {
          setNewIndex(newIndex + 1);

          const newOption = { [`new${newIndex}`]: '' };
          setValue(
            'options',
            {
              ...watchOptions,
              ...newOption,
            },
            {
              shouldDirty: true,
            },
          );
        }}
        className="btn-back-button-common content-add-button"
      >
        + Add options
      </Button>,
    ];
  }
};

export default function CreateSurveyFormContent({}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const { getValues, setError, clearErrors } = useFormContext();
  const validate = async () => {
    clearErrors(['name', 'defaultOption']);
    const [name, options, defaultOption, delOption] = getValues([
      'name',
      'options',
      'defaultOption',
      'delOption',
    ]);
    if (!name) {
      setError(
        'name',
        {
          type: 'require',
          message: 'please input name',
        },
        { shouldFocus: true },
      );
    }

    if (!defaultOption) {
      setError(
        'defaultOption',
        {
          type: 'require',
          message: 'please input option',
        },
        { shouldFocus: true },
      );
    }
    const optionList = [];
    // console.log('@@247: ', delOption, options, Object.keys(options));
    Object.keys(options || {})
      .filter((key) => !delOption?.includes(key))
      .map((key) => {
        const optionField = `options.${key}`;
        console.log('@@252: ', key, optionField, options[key]);
        if (options[key]) {
          clearErrors([optionField]);
        } else {
          optionList.push(...optionList, optionField);
          setError(
            optionField,
            {
              type: 'require',
              message: 'please input option',
            },
            { shouldFocus: true },
          );
        }
      });
    console.log('@@72: ', name, optionList, optionList.length);
    return name && optionList.length <= 0;
  };

  useEffect(() => {
    dispatch(createAction('surveyForm/getList')({ isSelectorLoad: true }));
  }, []);

  return (
    <>
      <ContentSections sections={[<SurveyFormContent />]} hidePreview={true} />
      <SaveAndBackButtons
        saveText={params.id ? 'Update' : 'Save'}
        backAction={() => history.goBack()}
        saveAction={async () => {
          const isValid = await validate();
          console.log('@@210: ', isValid, getValues());
          if (isValid) {
            dispatch(createAction('surveyForm/createOrUpdate')(getValues()));
          }
        }}
      />
    </>
  );
}
