import React from 'react';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import CustomSwitchButton from '../base/CustomSwitchButton';
import { useFormContext } from 'react-hook-form';
import { StatusTag } from '../../config/CustomEnums';

function GeneralAlertSatusSection() {
  const { watch, setValue } = useFormContext();
  const isActive = watch('isActive');

  return (
    <>
      <label className="create-section-title">STATUS</label>
      <CustomTitleLabel title="Active" />
      <CustomSwitchButton
        defaultChecked={isActive === undefined ? true : isActive}
        onChange={(isChecked) => {
          setValue('isActive', isChecked, { shouldDirty: true });
          setValue(
            'displayStatus',
            isChecked ? StatusTag.active : StatusTag.inactive,
          );
        }}
      />
    </>
  );
}
export default GeneralAlertSatusSection;
