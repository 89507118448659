import React, { useEffect, useState } from 'react';
import { Modal, Image, Button } from 'react-bootstrap';
import PromptButton, { BUTTON_TYPES } from '../../base/prompt/BaseButtons';
import CloseIcon from '../../../assets/images/prompt_close_icon.svg';
import DeleteIcon from '../../../assets/images/deleteIcon.png';
import CustomTips from '../../base/CustomTips';
import './FortuneBagLinkCouponPrompt.scss';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { ContinueCreate } from '../CampaignUrlConfig';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import { useFormContext } from 'react-hook-form';
import BaseMutipleSelectorV2 from '../../base/BaseMultipleSelectorV2';

const reducer = (accumulator, currentValue) => accumulator + currentValue;

function SlotArea({
  index,
  item,
  couponData,
  allSelected,
  setAllSelected,
  deleteAction = () => { },
  slotCouponCount = 0,
  setSlotCouponCount,
  isRefill,
  workingTeams,
}) {
  const [selectedList, setSelectedList] = useState(
    (item.selectedList || allSelected[item.pk] || []).filter((item) => {
      return (
        workingTeams?.length <= 0 ||
        workingTeams.filter(
          (val) =>
            item.workingTeamIds?.length <= 0 ||
            item.workingTeamIds?.indexOf(val.pk) >= 0,
        ).length > 0
      );
    }),
  );
  const [selectedCouponCount, setSelectedCouponCount] =
    useState(slotCouponCount);

  useEffect(() => {
    console.log('useEffect:');
  }, []);
  const cleanData =
    couponData.filter(
      (item) =>
        [].concat
          .apply([], Object.values(allSelected))
          .map((selected) => selected.pk)
          .indexOf(item.pk) < 0,
    ) || [];
  cleanData.push(...selectedList);
  console.log('cleanData:', cleanData, selectedList);
  const customTitle = (
    <div className="slot-head" style={{ paddingRight: '20px' }}>
      <label className="slot-label">
        <label className="slot-title">Slot {index}: </label>
        Link to an existing coupon set
      </label>
      <label className="slot-label flex-right">
        No. of single coupon:{' '}
        <label className="slot-title">{selectedCouponCount}</label>
      </label>
      {index > 1 ? (
        <Image
          src={DeleteIcon}
          className="slot-delete-icon"
          onClick={() => {
            deleteAction();
            setSelectedList([]);
          }}
        />
      ) : null}
    </div>
  );

  return (
    <>
      <BaseMutipleSelectorV2
        namespace="couponList"
        hideTopSpace={index === 1}
        custom={{
          customTitle: customTitle,
          customContainerStyle: index > 1 ? { marginTop: '20px' } : {},
          customItem: (value) => {
            return (
              <>
                <Tooltip placement="topLeft" title={value?.name}>
                  <label className="slot-title">[Qty: {value?.stock}]</label>
                  {value?.name}
                </Tooltip>
              </>
            );
          },
        }}
        data={{
          sourceData: cleanData,
          targetData: selectedList,
          targetChange: (value) => {
            console.log('targetChange: ', value, item.pk);
            setSelectedList(value);
            const couponCount =
              value?.length > 0
                ? value.map((item) => item?.stock).reduce(reducer)
                : 0;
            setSelectedCouponCount(couponCount);
            setSlotCouponCount([item.pk], couponCount);
            setAllSelected({ ...allSelected, [item.pk]: value });
          },
        }}
      />
    </>
  );
}

export default function FortuneBagLinkCouponPrompt(props) {
  const closeAction = props.closeAction || (() => { });
  const customClass = props.customClass || '';
  const nextAction = props.nextAction || (() => { });
  const isRefill = props.isRefill || false;

  const history = useHistory();
  const { watch, setValue } = useFormContext();
  const watchFortunes = watch('fortunes');
  const watchWorkingTeams = watch('workingTeams');

  const { fortuneBagQuantity, fortuneBagRemained } = useSelector((state) => ({
    fortuneBagQuantity: state.createCampaign.campaign.fortuneBagQuantity,
    fortuneBagRemained: state.createCampaign.campaign.fortuneBagRemained,
  }));
  const [show, setShow] = useState(props.show);
  const [slotNum, setSlotNum] = useState(0);
  const [slotList, setSlotList] = useState([{ pk: `new1` }]);
  const [slotCouponCount, setSlotCouponCount] = useState({});

  const [remainNum, setRemainNum] = useState(fortuneBagRemained || 0);
  const [totalGenerateNum, setTotalGenerateNum] = useState(
    fortuneBagQuantity || 0,
  );
  const { couponList } = useSelector((state) => ({
    couponList: state.couponList.notPagedAllList,
  }));

  const handleClose = () => {
    setShow(false);
    closeAction();
  };

  if (!show && props.show) {
    setShow(true);
  }

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  useEffect(() => {
    let bagCoupons = watchFortunes;
    setSlotNum(Object.keys(bagCoupons || {}).length);
    setSlotList(Object.keys(bagCoupons || {}).map((key) => ({ pk: key })));
    const tempSlotCount = {};
    Object.keys(bagCoupons || {}).forEach((key, index) => {
      tempSlotCount[key] =
        bagCoupons[key].length > 0
          ? bagCoupons[key].map((item) => item.stock)?.reduce(reducer)
          : 0;
    });
    setSlotCouponCount(tempSlotCount);
  }, [watchFortunes]);

  const getMinSlotCount = () => {
    console.log('@@getMinSlotcount:');
    console.log(slotCouponCount);
    const validSlotCouponCounts = Object.values(slotCouponCount).filter(
      (val) => val > 0,
    );
    console.log(validSlotCouponCounts);
    return (
      Math.min(
        ...(validSlotCouponCounts.length <= 0 ? [0] : validSlotCouponCounts),
      ) || 0
    );
  };

  const body = () => {
    return (
      <>
        <div className="caution-area">
          <CustomTips detail="You are suggested to select the coupon set with the same coupon number ; otherwise, there will be not enough amount when pairing the coupons.">
            <label className="caution-text">Link to Existing Coupon Set</label>
          </CustomTips>
        </div>

        <div className="base-prompt-description">
          Please link to an existing coupon set. After you linked to an existing
          coupon set, the system will auto-fill in the content of the coupon set
          in the next step.
        </div>

        <div className="refill-title-area">
          {isRefill ? (
            <>
              <label className="bag-num-title">
                Remaining / Total generate fortune bag (exclude this batch) :{' '}
                <label className="bag-num-error">{remainNum}</label> /{' '}
                <label>{totalGenerateNum}</label>
              </label>
              <label className="bag-num-title refill-bag-num-title">
                No. of fortune bag can be created in this batch:{' '}
                <label className="bag-num">{getMinSlotCount()}</label>
              </label>
            </>
          ) : (
              <label className="bag-num-title">
                No. of fortune bag can be created:{' '}
                <label className="bag-num">{getMinSlotCount()}</label>
              </label>
            )}
        </div>

        <div className="base-prompt-other-body">
          {slotList.map((item, index) => (
            <SlotArea
              index={index + 1}
              item={item}
              couponData={couponList}
              allSelected={watchFortunes}
              setAllSelected={(values) => {
                console.log('SlotAreafortunes', values);
                setValue('fortunes', values, { shouldDirty: true });
              }}
              deleteAction={() => {
                setSlotList(slotList.filter((slot) => slot.pk !== item.pk));
                const tempSlotCouponCount = { ...slotCouponCount };
                delete tempSlotCouponCount[item.pk];
                setSlotCouponCount(tempSlotCouponCount);
                const tmpAllSelectedCoupon = { ...watchFortunes };
                delete tmpAllSelectedCoupon[item.pk];
                setValue('fortunes', tmpAllSelectedCoupon, {
                  shouldDirty: true,
                });
              }}
              slotCouponCount={slotCouponCount[item.pk]}
              setSlotCouponCount={(index, value) =>
                setSlotCouponCount({ ...slotCouponCount, [index]: value })
              }
              isRefill={isRefill}
              workingTeams={watchWorkingTeams}
            />
          ))}
        </div>
        <div className="base-prompt-buttons">
          <div className="button-left-area">
            <PromptButton
              title="+ Add slot"
              className={`${isRefill ? 'left' : 'left-create'}`}
              action={() => {
                const num =
                  Math.max(
                    ...slotList.map((item) =>
                      parseInt(item.pk.replace('new', '')),
                    ),
                  ) || slotNum;
                console.log('@@268: ', num, slotNum);
                setSlotNum(num + 1);
                setSlotList([...slotList, { pk: `new${num + 1}` }]);
                setValue(
                  'fortunes',
                  { ...watchFortunes, [`new${num + 1}`]: [] },
                  { shouldDirty: true },
                );
              }}
              type={BUTTON_TYPES.secondary}
            />
            {isRefill ? (
              <PromptButton
                title="Assign my remaining fortune bag"
                className="right-assign"
                action={() => nextAction(true)}
                type={BUTTON_TYPES.link}
              />
            ) : (
                <PromptButton
                  title="Create Coupon Set"
                  className="right"
                  action={() =>
                    history.push({
                      pathname: '/coupons/create',
                      state: { from: history.location, ...ContinueCreate },
                    })
                  }
                  requires={PermissionCodes.addCouponTemplate}
                  type={BUTTON_TYPES.link}
                />
              )}
          </div>
          <PromptButton
            title="Continue"
            action={() => {
              nextAction(false);
              setValue('batchBagQuantity', getMinSlotCount(), {
                shouldDirty: false,
              });
            }}
            type={BUTTON_TYPES.main}
            disabled={
              Object.values(watchFortunes || {}).filter(
                (item) => item.length <= 0,
              ).length > 0
            }
          />
        </div>
      </>
    );
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body
        className={`base-prompt-container ${customClass}`}
        style={{ width: '840px' }}
      >
        <Image
          src={CloseIcon}
          className="base-prompt-close-button"
          onClick={handleClose}
        />
        {body()}
      </Modal.Body>
    </Modal>
  );
}
