import React, { useEffect, useState, useCallback } from 'react';
import { Modal, Image, Button } from 'react-bootstrap';
import PromptButton, { BUTTON_TYPES } from '../../base/prompt/BaseButtons';
import CloseIcon from '../../../assets/images/prompt_close_icon.svg';

import CustomTips from '../../base/CustomTips';
import './FortuneBagsAllocationPrompt.scss';
import { useSelector } from 'react-redux';
import { CustomNumberInputWithUnit } from '../../earning/CustomBaseComponments';
import ErrorFieldMessage from '../../base/ErrorFieldMessage';
import { strftime } from 'i18n-js';
// import { ContinueCreate } from '../CampaignUrlConfig';

const reducer = (accumulator, currentValue) => accumulator + currentValue;

export default function FortuneBagsAllocationPrompt(props) {
  const closeAction = props.closeAction || (() => {});
  const customClass = props.customClass || '';
  const nextAction = props.nextAction || (() => {});

  const campaign = props.campaign;
  const fortuneCoupons = campaign?.fortunes;
  const batchFortunes = campaign?.batchFortunes;
  console.log(props?.ota);
  console.log(campaign);
  // const { fortuneCoupons, batchFortunes, campaign } = useSelector((state) => ({
  //   fortuneCoupons: state.createCampaign.campaign.fortunes,
  //   batchFortunes: state.createCampaign.campaign.batchFortunes,
  //   campaign: state.createCampaign.campaign,
  // }));

  const getRemain = () => {
    let minRemain;
    if (campaign?.assignRemain) {
      minRemain = campaign?.fortuneBagRemained;
    } else {
      Object.keys(fortuneCoupons || {}).forEach((key) => {
        const slotCoupons = fortuneCoupons[key];
        const stockList = slotCoupons?.map((item) => item.stock);
        const total = stockList.length > 0 ? stockList.reduce(reducer) : 0;

        if (!minRemain || minRemain > total) {
          minRemain = total;
        }
      });
    }

    return minRemain;
  };
  const [canAllocatedNum, setCanAllocatedNum] = useState(getRemain());
  const [remain, setRemain] = useState(getRemain());

  const [show, setShow] = useState(props.show);

  const [otaAllocatedNum, setOTAAllocatedNum] = useState({});
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setCanAllocatedNum(getRemain());
    setRemain(getRemain());
  }, [fortuneCoupons]);

  const handleClose = () => {
    setShow(false);
    closeAction();
  };

  if (!show && props.show) {
    setShow(true);
  }

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const body = () => {
    return (
      <>
        <div className="caution-area fortune-bags-caution-area">
          <CustomTips detail="You are suggested to select the coupon set with the same coupon number ; otherwise, there will be not enough amount when pairing the coupons.">
            <label className="caution-text">Fortune bags allocation</label>
          </CustomTips>
        </div>

        <label className="bag-num-title-normal">
          No. of fortune bags can be allocated:
          <label
            className={`bag-num ${canAllocatedNum < 0 ? 'bag-num-error' : ''}`}
          >
            {canAllocatedNum < 0 ? 0 : canAllocatedNum}
          </label>
        </label>
        <ErrorFieldMessage
          id={'eerror'}
          error={canAllocatedNum < 0}
          message={'Exceed the total no. of fortune bags.'}
        />

        <label className="bag-num-title">
          Allocate the number of links for option C’s merchants
        </label>

        <div className="base-prompt-other-body fortune-bags-allocation-other-body">
          {props?.ota?.map((item, index) => (
            <CustomNumberInputWithUnit
              title={item.name}
              setValue={(value) => {
                setOTAAllocatedNum({ ...otaAllocatedNum, [item.pk]: value });
                const others = Object.keys(otaAllocatedNum)
                  .filter((key) => key !== item.pk.toString())
                  ?.map((key) => parseInt(otaAllocatedNum[key]) || 0);

                setCanAllocatedNum(
                  remain -
                    (others.length > 0 ? others.reduce(reducer) : 0) -
                    value,
                );
              }}
              defaultValue={''}
              unit={'number of fortune bag'}
              errorId={item.name}
              error={
                errors.includes(item.name) ||
                otaAllocatedNum[item.pk] === '0' ||
                canAllocatedNum < 0
              }
              errorMessage={
                otaAllocatedNum[item.pk] === '0'
                  ? 'Input value cannot be 0'
                  : ''
              }
            />
          ))}
        </div>
        <div className="base-prompt-buttons">
          <PromptButton
            title="Allocate"
            className="fortune-bags-allocation-button"
            action={() =>
              nextAction(
                Object.keys(otaAllocatedNum || {}).map((key) => ({
                  brandId: parseInt(key),
                  number: otaAllocatedNum[key],
                })),
              )
            }
            type={BUTTON_TYPES.main}
            disabled={
              canAllocatedNum < 0 ||
              Object.values(otaAllocatedNum).filter(
                (num) => num === '0' || !num,
              ).length > 0 ||
              Object.keys(otaAllocatedNum).length < props?.ota?.length
            }
          />
        </div>
      </>
    );
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body
        className={`base-prompt-container ${customClass}`}
        style={{ width: '800px' }}
      >
        <Image
          src={CloseIcon}
          className="base-prompt-close-button fortune-bags-close-button"
          onClick={handleClose}
        />
        {body()}
      </Modal.Body>
    </Modal>
  );
}
