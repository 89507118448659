import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import CustomMultipleSelect from '../base/CustomMultipleSelect';
import { DropDownWithDefaultSelect } from '../base/ActionsDropdown';
import { useCompare, isNumber } from '../../utils';

export default function InputWithMutipleDropDown(props) {
  const title = props.title;
  const groupName = props.groupName;
  const conditionIndex = props.conditionIndex;

  const defaultRange = props.defaultRange || 'Please select';
  const hasNumber = props.hasNumber;
  const defaultNumber = props.defaultNumber; // || '';
  const defaultDays = props.defaultPeriod || 'Please select';

  const rangeOptions = props.rangeOptions || ['All the time', 'Within'];
  const hasPeriodDropdown = props.hasPeriodDropdown;

  const onReset = props.isReset;
  const changeReset = props.changeReset || (() => {});
  const isCreate = props.isCreate;

  const [number, setNumber] = useState(defaultNumber);
  const [periodRange, setPeriodRange] = useState(
    rangeOptions.length === 1 ? rangeOptions[0] : defaultRange,
  );
  const [showPeriodDays, setShowPeriodDays] = useState(
    props.rangeOptions && props.rangeOptions[0] === 'Within',
  );
  const [periodDays, setPeriodDays] = useState(defaultDays);

  const dropDownOptions = (values, setAction) => {
    return values.map((value) => ({
      name: value,
      action: () => {
        setAction(value);
        setShowPeriodDays(value !== 'All the time');
      },
    }));
  };

  const dispatch = useDispatch();
  const vals = { number, periodRange, periodDays };
  const hasValsChanged = useCompare(vals);

  useEffect(() => {
    if (hasValsChanged) {
      dispatch({
        type: 'segments/changeVals',
        payload: {
          vals,
          groupName,
          conditionIndex,
        },
      });
    }
  });

  useEffect(() => {
    if (props.defaultRange === 'Within') {
      setShowPeriodDays(true);
    }
  }, [props.defaultRange]);

  useEffect(() => {
    if (!rangeOptions?.includes(periodRange)) {
      setPeriodRange('Please select');
    }
  }, [rangeOptions]);

  useEffect(() => {
    if (onReset) {
      if (isCreate) {
        setNumber();
        setPeriodRange(
          rangeOptions.length === 1 ? rangeOptions[0] : 'Please select',
        );
        setPeriodDays('Please select');
      } else {
        setNumber(defaultNumber);
        setPeriodRange(
          rangeOptions.length === 1 ? rangeOptions[0] : defaultRange,
        );
        setPeriodDays(defaultDays);
      }

      changeReset(false);
    }
  }, [onReset, isCreate, changeReset]);

  return (
    <div>
      {title ? <CustomTitleLabel title={title} /> : null}
      <div style={{ display: 'flex' }}>
        {hasNumber ? (
          <input
            type="number"
            onChange={({ target }) => setNumber(target.value)}
            className="custom-number-input-long"
            value={number}
            onFocus={() => {}}
          />
        ) : null}

        {rangeOptions.length === 1 ? (
          <label className="help-message margin-right-10-percent">
            {rangeOptions[0]}
          </label>
        ) : (
          <DropDownWithDefaultSelect
            actions={dropDownOptions(rangeOptions, setPeriodRange)}
            defaultValue={periodRange}
          />
        )}

        {showPeriodDays && hasPeriodDropdown ? (
          <DropDownWithDefaultSelect
            actions={dropDownOptions(
              ['1 day', '7 days', '1 month', '3 months', '6 months', '1 year'],
              setPeriodDays,
            )}
            defaultValue={periodDays}
          />
        ) : null}
      </div>
    </div>
  );
}
