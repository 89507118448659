import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ImportResource } from '../../../models/DownloadImportModel';
import { createAction } from '../../../utils';
import CustomSwitchButton from '../../base/CustomSwitchButton';
import BaseMutipleSelectorV2 from '../../base/BaseMultipleSelectorV2';
import { CustomTitleLabel } from '../../earning/CustomBaseComponments';
import BasePrompt from '../../base/prompt/BasePrompt';
import CheckboxItem from './CheckboxItem';
import { CustomTitleWithDropDown } from '../../customer/CustomTitleWithDropDown';
import './HandleLeftoverView.scss';
import Loading from '../../base/Loading';

const HandleLeftoverView = ({ show, onHide, coupons, campaigns, dispatch }) => {
  const [selectedCampaign, setSelectedCampaign] = useState();
  const [enableSeparate, setEnableSeparate] = useState(true);

  const [couponResult, setCouponResult] = useState([]);
  const [exportCoupons, setExportedCoupons] = useState([]);
  const [startGetCoupon, setStartGetCoupon] = useState(false);
  const [hasOthers, setHasOthers] = useState(false);

  //   useEffect(() => {
  //     if (!show) {
  //       setIncludeTransaction(false);
  //       setIncludeForm(false);
  //       setSelectedCouponTemplates([]);
  //       setSelectedCampaigns([]);
  //       setEnableSeparate(true);
  //     }
  //   }, [show, dispatch]);

  useEffect(() => {
    if (show) {
      setEnableSeparate(true);
      dispatch({
        type: 'campaignList/getList',
        payload: {
          listName: 'expiredList',
          isSelectorLoad: true,
          others: {
            expired: true,
            typeIn:
              'COUPON_CAMPAIGN,FORTUNE_BAG_CAMPAIGN,CASH_VOUCHER_CAMPAIGN_FOR_GOODIE_BAG,GIFT_CAMPAIGN_FOR_GOODIE_BAG',
          },
        },
      });
    }

    return () => {
      dispatch(createAction('campaignList/clearData')());
      dispatch({
        type: 'couponList/updateState',
        payload: {
          handleLeftoverCoupons: [],
        },
      });
    };
  }, [show]);

  useEffect(() => {
    const canExportCoupons = coupons?.filter(
      (item) =>
        item.isInactiveOrExpired &&
        !item.hasActiveCampaign &&
        !item.isActiveAndUnexpired &&
        !item.handledLeftover,
    );

    setHasOthers(
      coupons?.filter(
        (item) =>
          item.hasActiveCampaign ||
          item.isActiveAndUnexpired ||
          item.handledLeftover,
      ).length > 0,
    );
    setCouponResult(coupons);
    setExportedCoupons(canExportCoupons);
    setStartGetCoupon(false);

    if (canExportCoupons) {
      setEnableSeparate(false);
    }
  }, [coupons]);

  const clear = () => {
    setCouponResult([]);
    setExportedCoupons([]);
    setStartGetCoupon(true);
  };

  const body = () => (
    <>
      <CustomTitleWithDropDown
        title={'Select expired campaign'}
        titleStyle={'create-section-label-no-top-space'}
        defaultValue={selectedCampaign}
        setValue={(value) => {
          //   setValue('tagKey', value || {}, { shouldDirty: true });
          setSelectedCampaign(value);
          console.log(value);

          // setCouponResult([]);
          // setExportedCoupons([]);
          // setStartGetCoupon(true);
          // clear();

          if (value?.value?.pk) {
            dispatch({
              type: 'couponList/getCouponTemplateList',
              payload: {
                campaign: value?.value?.pk,
                pageSize: 200,
                leftover: true,
                listName: 'handleLeftoverCoupons',
              },
            });
          } else {
            clear();
          }
        }}
        source={campaigns}
        loadMoreAction={'campaignList/getList'}
        filterAction={'campaignList/getList'}
        isReset={false}
        resetChange={() => {}}
        defaultFilter={{
          listName: 'expiredList',
          others: {
            expired: true,
            typeIn:
              'COUPON_CAMPAIGN,FORTUNE_BAG_CAMPAIGN,CASH_VOUCHER_CAMPAIGN_FOR_GOODIE_BAG,GIFT_CAMPAIGN_FOR_GOODIE_BAG',
          },
        }}
        labelContainPk={true}
      />
      <div className="result-area">
        {/* {startGetCoupon ? <Loading /> : null} */}

        {couponResult?.filter((item) => item.isActiveAndUnexpired).length >
        0 ? (
          <div className="grid-area">
            <label className="coupons-warning">
              The coupon set(s) linked below has not been expired and are in
              active status, it is not recommended to handle leftover coupon at
              this moment.
            </label>
            <label className="coupons-warning coupons-warning-tips">
              The coupon set(s) can be ready for handling until the coupon set
              status has changed to “inactivated”. Please reminded that, before
              inactivate the coupon set(s), make sure the acquired coupons has
              been used. Please noted that, for coupon sets linked to goodie bag
              cannot be inactivated, but able to manually expire the coupon set.
            </label>
            {/* <div style={{ maxHeight: 100, overflow: 'auto', display: 'grid' }}> */}
            {couponResult
              ?.filter((item) => item.isActiveAndUnexpired)
              .map((item) => (
                <label className="coupons-warning coupons-normal">
                  [ID:{item.pk}] {item.name}
                </label>
              ))}
          </div>
        ) : //   </div>
        null}
        {couponResult?.filter((item) => item.hasActiveCampaign).length > 0 ? (
          <div className="grid-area">
            <label className="coupons-warning">
              Below linked coupon set(s) is also linked with other coupon
              campaign which is still active, it is not recommended to handle
              leftover coupon at this moment.
            </label>
            {/* <div style={{ maxHeight: 100, overflow: 'auto', display: 'grid' }}> */}
            {couponResult
              ?.filter((item) => item.hasActiveCampaign)
              .map((item) => (
                <label className="coupons-warning coupons-normal">
                  ({item.stock}) [ID:{item.pk}] {item.name}
                </label>
              ))}
          </div>
        ) : //   </div>
        null}
        {couponResult?.filter((item) => item.handledLeftover).length > 0 ? (
          <div className="grid-area">
            <label className="coupons-warning">
              Below linked coupon set(s) has already handled the leftover
              coupons.
            </label>
            {/* <div style={{ maxHeight: 100, overflow: 'auto', display: 'grid' }}> */}
            {couponResult
              ?.filter((item) => item.handledLeftover)
              .map((item) => (
                <label className="coupons-warning coupons-normal">
                  [ID:{item.pk}] {item.name}
                </label>
              ))}
          </div>
        ) : //   </div>
        null}
        {exportCoupons?.length > 0 ? (
          <>
            <div className="grid-area">
              <div
                className="sperate-line"
                style={hasOthers ? { marginTop: 0 } : {}}
              ></div>
              <label className="coupons-warning coupons-normal ">
                Below are the linked Coupon set(s):
                <br />
                Once clicked “Export” button, the leftover single coupon from
                below coupon set(s) will be voided and a .xlsx file will be
                exported (able to download in System task.)
              </label>
              <label className="coupons-warning coupons-normal-tips">
                Format: (No. of coupon(s) leftover) [ID:xxx] Coupon set name.
              </label>

              {/* <div
                style={{ maxHeight: 100, overflow: 'auto', display: 'grid' }}
              > */}
              {exportCoupons.map((item) => (
                <label className="coupons-warning coupons-normal normal-weight">
                  ({item.totalNubmerOfCanbeLeftoverdCoupons}) [ID:{item.pk}]{' '}
                  {item.name}
                </label>
              ))}
              {/* </div> */}
            </div>
          </>
        ) : null}
      </div>

      {exportCoupons?.length > 1 ? (
        <div>
          <div className="sperate-line"></div>
          <label className="create-section-label">
            Export in separate files by coupon set(s)
          </label>
          <div
            className="second-section_description"
            style={{ marginBottom: 12 }}
          >
            If On, multiple XLSX files will be downloaded based on different
            coupon set. If off, all the data merge into one XLSX file.
          </div>

          <CustomSwitchButton
            defaultChecked={enableSeparate}
            onChange={setEnableSeparate}
          />
        </div>
      ) : null}
    </>
  );
  return (
    <BasePrompt
      show={show}
      closeAction={() => {
        clear();
        setSelectedCampaign();
        onHide();
      }}
      rightButton={{
        text: 'Export',
        action: () => {
          console.log('@export');
          let data = {
            from: ImportResource.leftoverCoupon,
            related: {
              couponTemplates: exportCoupons.map((item) => item.pk),
              separateFiles: enableSeparate,
            },
          };

          dispatch(createAction('downloadAndImport/createDownloadTask')(data));
          clear();
          setSelectedCampaign();
          onHide();
        },
        disabled: !selectedCampaign?.value?.pk || exportCoupons?.length <= 0,
      }}
      title="Handle leftover coupons"
      otherBody={body}
    />
  );
};

const mapPropsToState = (state) => ({
  campaigns: state.campaignList.expiredList,
  coupons: state.couponList.handleLeftoverCoupons,
});

export default connect(mapPropsToState)(HandleLeftoverView);
