import React, { useState, useEffect } from 'react';
import './LanguageChange.scss';
import LanguageIcon from '@material-ui/icons/Language';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isGoodieBagType } from '../../../../models/CreateCampaignModel';
import LanguageImage from '../../../../assets/images/preview/languageIcon.svg';

const campaignLanguages = (channelPK, campaign) => {
  if (!campaign) {
    return [];
  }
  const markets = campaign.markets;
  if (!markets) {
    return [];
  }
  let selectedMarkets = markets;
  const languages = [];
  const result = [];
  if (channelPK != '0') {
    selectedMarkets = markets.filter((item) => item.channel.pk == channelPK);
  }
  selectedMarkets.forEach((item) => {
    console.log('item.languages:', item.languages);
    languages.push(...(item.languages || item.selectedLanguages));
  });
  languages.forEach((item) => {
    const exist = result.some((language) => language.pk === item.pk);
    if (!exist) {
      result.push(item);
    }
  });
  return result;
};

export default function LanguageChange({
  campaign = {},
  onLanguageChange = () => {},
  selectedLanguage,
  showLanguageName = true,
}) {
  const params = useParams();
  const channelPK = params.channel;
  const [languages, setLanguages] = useState([]);
  const marketList = useSelector((state) => state.targetMarket.notPagedAllList);
  const [anchorEl, setAnchorEl] = useState(null);

  console.log('languages:', languages);

  useEffect(() => {
    const isGoodieBag = isGoodieBagType(campaign.campaignType);
    console.log('marketList:', marketList, campaign, isGoodieBag);
    if (marketList.length && isGoodieBag) {
      const newLanguages = campaignLanguages(channelPK, {
        ...campaign,
        markets: marketList,
      });
      setLanguages(newLanguages);
      return;
    }
    if (campaign?.markets && !isGoodieBag) {
      setLanguages(campaignLanguages(channelPK, campaign));
      return;
    }
  }, [marketList.length, campaign, channelPK]);

  useEffect(() => {
    if (languages?.length) {
      onLanguageChange(languages[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languages.length]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (event, item) => {
    console.log(item);
    onLanguageChange(item);
    setAnchorEl(null);
  };

  return (
    <div className="language-container">
      <div
        className={'web-language-settings'}
        aria-controls="lang-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {showLanguageName ? (
          <>
            <LanguageIcon className={'language-icons'} />
            <label className={'web-language'}>{selectedLanguage?.name}</label>
            <KeyboardArrowDownIcon
              className={'language-icons language-icons-2'}
            />
          </>
        ) : (
          <>
            <img src={LanguageImage} className={'web-language-icon'} alt="" />
            {/* <label className={'web-language-label'}>
              {selectedLanguage?.name}
            </label> */}
          </>
        )}
      </div>
      <Menu
        id="lang-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {languages.map((item, index) => (
          <MenuItem
            key={item.name}
            onClick={(event) => handleMenuItemClick(event, item)}
          >
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
