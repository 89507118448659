import React from 'react';
import i18n from '../../../I18n';
import { Collapse } from 'react-bootstrap';
import CustomDateTimeSelect from '../../../components/base/CustomDateTimeSelect';
import CustomSwitchButton from '../../../components/base/CustomSwitchButton';
import './WeekHours.scss';
import moment from 'moment';
import { formatDate, TimeFormater } from '../../../utils/TimeFormatUtil';

const transferHourToDate = (hour) => {
  if (!hour) {
    return moment().valueOf();
  }
  return moment(hour, 'HH:mm:ss');
};

export default function StoreHours({
  hour,
  switchChange,
  openHourChange,
  closeHourChange,
  closeStyle,
}) {
  const openHour = transferHourToDate(hour?.openHour);
  const closeHour = transferHourToDate(hour?.closeHour);
  return (
    <>
      <label
        className={`create-section-label create-section-label-bottom-space ${closeStyle}`}
      >
        Close
      </label>
      <CustomSwitchButton
        defaultChecked={hour?.isClose}
        onChange={switchChange}
      />
      <Collapse in={!hour?.isClose}>
        <div>
          <label className="create-section-label create-section-label-bottom-space">
            Open hour
          </label>
          <CustomDateTimeSelect
            hideDate
            defaultTime={openHour}
            onTimeChange={(date) => {
              const timeString = formatDate(date, TimeFormater.time);
              openHourChange(timeString);
            }}
          />
          <label className="create-section-label create-section-label-bottom-space">
            Close hour
          </label>
          <CustomDateTimeSelect
            hideDate
            defaultTime={closeHour}
            onTimeChange={(date) => {
              const timeString = formatDate(date, TimeFormater.time);
              closeHourChange(timeString);
            }}
          />
        </div>
      </Collapse>
    </>
  );
}
