export const CustomerErrorHandleFields = {
  // firstName: {
  //   required: 'Please provide first name',
  // },
  // lastName: {
  //   required: 'Please provide last name',
  // },
  // currentLevel: {
  //   require: 'Please provide current level',
  // },
  // mobilePhoneNumberCountryCode: {
  //   required: 'Please provide country code',
  // },
  // mobilePhoneNumberSubscriberNumber: {
  //   required: 'Please provide phone number',
  // },
  // preferredMessageLanguage: {
  //   required: 'Please provide preferred message language',
  // },
};
