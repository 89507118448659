import React, { useEffect, useState } from 'react';
// import './MessageList.scss';
import queryString from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import CustomListComponent from '../../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import { createAction } from '../../../utils';
import DeletePrompt from '../../../components/base/DeletePrompt';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
// import { DELETE_RELATED_SECTIONS } from '../../../config/CustomEnums';

const OfferTypeLabelList = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  const {
    pageInfo,
    totalCount,
    offerTypeLabelList,
    totalPage,
    checkedList,
    listDisplayFields,
  } = useSelector((state) => ({
    listDisplayFields: state.offerTypeLabel.listDisplayFields,
    pageInfo: state.offerTypeLabel.pageInfo,
    totalCount: state.offerTypeLabel.totalCount,
    offerTypeLabelList: state.offerTypeLabel.pagedList,
    totalPage: state.offerTypeLabel.totalPage,
    checkedList: state.offerTypeLabel.checkedList,
  }));

  useEffect(() => {
    const searchDict = queryString.parse(location.search);
    const searchKey = searchDict['search'];
    const stringRank = searchDict['rank'] || 'true';
    const stringPage = searchDict['page'] || 1;
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);
    dispatch(
      createAction('offerTypeLabel/getList')({
        ...searchDict,
        page,
        rank,
        filterName: searchKey,
      }),
    );
  }, [dispatch, location]);

  const buttons = [
    <AuthButton
      title="Create Offer type Label"
      action={() => {
        history.push('/offer_type_labels/create');
      }}
      requires={PermissionCodes.changeOfferTypeLabel}
    />,
  ];

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.changeOfferTypeLabel,
    },
  ];

  const tabs = [
    {
      name: '',
      content: (
        <BaseTabListContainer
          hideTab={true}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount ? totalCount : 0}
          listContent={
            <BaseListContainer
              fields={listDisplayFields}
              model={'offerTypeLabel'}
              permissionGroup={PermissionCodes.offerTypeLabel}
              dataList={offerTypeLabelList}
              totalPage={totalPage}
              deleteInfo={{
                data: [],
                title: 'Offer type label',
                onComfirm: {},
              }}
              actions={['Edit', 'Detail', 'Delete']}
            />
          }
        />
      ),
    },
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail: 'TBC',
          title: 'Offer type label',
        }}
        buttons={buttons}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={true}
        tabs={tabs}
      />
      <DeletePrompt
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch(
            createAction('offerTypeLabel/delete')({
              afterAction: () => history.push(location),
            }),
          );
        }}
        data={checkedList}
        title={'Offer type label'}
      />
    </>
  );
};

export default OfferTypeLabelList;
