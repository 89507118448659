import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { Button, Image, ButtonGroup } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import {
  CustomNumberInput,
  CustomTitleLabel,
} from '../earning/CustomBaseComponments';
import addImage from '../../assets/images/Addclose.svg';
import openImage from '../../assets/images/Addopen.svg';
import backImage from '../../assets/images/Vectorback.svg';
import { DatePicker } from 'antd';
import moment from 'moment';
import Slider from '@material-ui/core/Slider';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { removeElementFromArray, createAction } from '../../utils';
import { TimeFormater } from '../..//utils/TimeFormatUtil';
import '../customer/Filter.scss';
import CustomSearchInput from '../base/CustomSearchInput';
import { Transfer, Tree, Tooltip } from 'antd';
import Loading from './Loading';

const { RangePicker } = DatePicker;

const ImageButton = ({ action, image, disabled }) => {
  //   const [buttonImage, setButtonImage] = useState(addImage);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <button
      className={`custom-image-button create-section-label-bottom-space filter-open-button-margin ${
        disabled ? 'filter-button-disabled' : ''
      }`}
      disabled={disabled}
      onClick={() => {
        action();
        // setButtonImage();
        setIsOpen(!isOpen);
      }}
    >
      <Image src={image ? image : isOpen ? openImage : addImage} />
    </button>
  );
};

export const CheckboxItem = ({
  index,
  title,
  defaultValue,
  onChange,
  customClass = '',
  showSquare = '',
  needNoMargin = true,
}) => {
  return (
    <label
      className={`checkbox-container ${
        index === 0 && needNoMargin ? 'checkbox-container-no-margin' : ''
      } ${customClass}`}
    >
      {title}
      <input type="checkbox" checked={defaultValue} onChange={onChange} />
      <span className={`checkmark`}></span>
      {showSquare ? <span className="checkmark-square"></span> : null}
    </label>
  );
};

const FilterItemsWithSearchBar = ({
  title,
  data,
  value = [],
  setValue,
  className,
  filterAction,
  placeholder,
  renderDisplayName = null,
}) => {
  const [show, setShow] = useState(!title || false);
  const [checked, setChecked] = useState(value);
  const [inSearch, setInSearch] = useState(false);

  useEffect(() => {
    setChecked(value);
  }, [value]);

  useEffect(() => {
    setValue(checked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  // console.log('placeholder:', data);

  return (
    <>
      {title ? (
        <div className="filter_choice_title_area">
          <CustomTitleLabel title={title} className={'filter_choice_title'} />
          <ImageButton
            action={() => {
              setShow(!show);
            }}
          />
        </div>
      ) : null}

      {show ? (
        <>
          {filterAction ? (
            <div className="list-filter-search-area">
              <CustomSearchInput
                customClass="list-fitler-search-bar"
                customIconClass="list-filter-search-icon"
                filterAction={(value) => {
                  filterAction(value);
                }}
                placeholder={placeholder}
              />
            </div>
          ) : null}
          {/* {inSearch ? <Loading /> : null} */}
          <div
            className={`d-flex flex-column filter-list-items-area ${className}`}
          >
            {data?.map((item, index) => {
              return (
                <CheckboxItem
                  index={index}
                  title={
                    renderDisplayName ? renderDisplayName(item) : item.name
                  }
                  defaultValue={checked.indexOf(item.pk) > -1}
                  onChange={() => {
                    if (checked.includes(item.pk)) {
                      const newCheckedData = removeElementFromArray(
                        checked,
                        item.pk,
                      );
                      setChecked(newCheckedData);
                    } else {
                      setChecked([...checked, item.pk]);
                    }
                  }}
                />
              );
            })}
          </div>
        </>
      ) : null}

      {title ? <div className="filter-menu-border" /> : null}
    </>
  );
};

const FilterItemsWithSearch = ({
  title,
  value,
  setValue,
  className,
  placeholder,
}) => {
  const [show, setShow] = useState(!title || false);

  // console.log('placeholder:', value);

  return (
    <>
      {title ? (
        <div className="filter_choice_title_area">
          <CustomTitleLabel title={title} className={'filter_choice_title'} />
          <ImageButton
            action={() => {
              setShow(!show);
            }}
          />
        </div>
      ) : null}

      {show ? (
        <>
          <div className="list-filter-search-area">
            <CustomSearchInput
              customClass="list-fitler-search-bar"
              customIconClass="list-filter-search-icon"
              defaultValue={value}
              filterAction={(value) => {
                setValue(value);
              }}
              placeholder={placeholder}
            />
          </div>
        </>
      ) : null}

      {title ? <div className="filter-menu-border" /> : null}
    </>
  );
};

const TwoLevelFilterItemsWithSearchBar = ({
  title,
  displayTitle,
  data,
  value = [],
  setValue,
  className,
  filterAction,
  placeholder,
}) => {
  const [show, setShow] = useState(!title || false);
  const [checked, setChecked] = useState(value);

  useEffect(() => {
    setChecked(value);
  }, [value]);

  useEffect(() => {
    setValue(checked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  const onSelect = (selectedKeys: React.Key[], info: any) => {
    console.log('selected', selectedKeys, info);

    const key = info.node.key;
    if (typeof key === 'string') {
      const newChecked = info.node.children
        .filter((item) => !checked.includes(item.pk))
        .map((item) => item.pk);
      if (
        newChecked.length > 0 &&
        newChecked.length <= info.node.children.length
      ) {
        setChecked([...checked, ...newChecked]);
      } else {
        let newCheckedData = Array.from(checked);
        info.node.children.forEach((item) => {
          newCheckedData = removeElementFromArray(newCheckedData, item.pk);
        });

        setChecked(newCheckedData);
      }
    } else {
      if (checked.includes(key)) {
        const newCheckedData = removeElementFromArray(checked, key);
        setChecked(newCheckedData);
      } else {
        setChecked([...checked, key]);
      }
    }

    // setChecked(selectedKeys.filter((key) => typeof key !== "string"));
  };

  const onCheck = (checkedKeys: React.Key[], info: any) => {
    console.log('onCheck', checkedKeys);

    setChecked(checkedKeys.filter((key) => typeof key !== 'string'));
  };

  return (
    <>
      {displayTitle ? (
        <div className="filter_choice_title_area">
          <CustomTitleLabel
            title={displayTitle}
            className={'filter_choice_title'}
          />
          <ImageButton
            action={() => {
              setShow(!show);
            }}
          />
        </div>
      ) : null}

      {show ? (
        <>
          {filterAction ? (
            <div className="list-filter-search-area">
              <CustomSearchInput
                customClass="list-fitler-search-bar"
                customIconClass="list-filter-search-icon"
                filterAction={(value) => {
                  filterAction(value);
                }}
                placeholder={placeholder}
              />
            </div>
          ) : null}
          <div
            className={`d-flex flex-column filter-list-items-area ${className}`}
          >
            <Tree
              checkable
              defaultExpandAll={true}
              checkedKeys={checked}
              // onSelect={(_, { node: { key } }) => {
              //   console.log("@@key: ", key)
              //   // if (checked.includes(key)) {
              //   //   const newCheckedData = removeElementFromArray(
              //   //     checked,
              //   //     key,
              //   //   );
              //   //   setChecked(newCheckedData);
              //   // } else {
              //   //   setChecked([...checked, key]);
              //   // }
              // }

              //     // onSelect(key)
              // }
              onSelect={onSelect}
              onCheck={onCheck}
              treeData={data}
            />
          </div>
        </>
      ) : null}

      {title ? <div className="filter-menu-border" /> : null}
    </>
  );
};

const FilterItems = ({
  title,
  data,
  value = [],
  setValue,
  className,
  renderDisplayName = null,
}) => {
  const [show, setShow] = useState(!title || false);
  const [checked, setChecked] = useState(value);

  useEffect(() => {
    setChecked(value);
  }, [value]);

  useEffect(() => {
    setValue(checked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  return (
    <>
      {title ? (
        <div className="filter_choice_title_area">
          <CustomTitleLabel title={title} className={'filter_choice_title'} />
          <ImageButton
            action={() => {
              setShow(!show);
            }}
          />
        </div>
      ) : null}

      {show ? (
        <div
          className={`d-flex flex-column filter-list-items-area ${className}`}
        >
          {data?.map((item, index) => {
            return (
              <CheckboxItem
                index={index}
                title={renderDisplayName ? renderDisplayName(item) : item.name}
                defaultValue={checked.indexOf(item.pk) > -1}
                onChange={() => {
                  if (checked.includes(item.pk)) {
                    const newCheckedData = removeElementFromArray(
                      checked,
                      item.pk,
                    );
                    setChecked(newCheckedData);
                  } else {
                    setChecked([...checked, item.pk]);
                  }
                }}
              />
            );
          })}
        </div>
      ) : null}

      {title ? <div className="filter-menu-border" /> : null}
    </>
  );
};

const FilterItemsSingleChoice = ({
  displayTitle,
  title,
  data,
  value,
  setValue,
  className,
  inGroup = false,
}) => {
  const [show, setShow] = useState(inGroup);
  // const [checked, setChecked] = useState(value);

  // useEffect(() => {
  //   setChecked(value);
  // }, [value]);

  // useEffect(() => {
  //   setValue(checked);
  // }, [checked]);

  return (
    <div className={inGroup ? 'group-choice' : ''}>
      <div style={{ display: 'flex' }}>
        <CustomTitleLabel title={displayTitle || title} />
        {inGroup ? null : (
          <ImageButton
            action={() => {
              setShow(!show);
            }}
          />
        )}
      </div>
      {show ? (
        <div
          className={`d-flex ${
            inGroup ? 'flex-row' : 'flex-column'
          } filter-list-items-area ${className}`}
        >
          {data?.map((item, index) => {
            return (
              <>
                <CheckboxItem
                  index={index}
                  title={item.name}
                  defaultValue={value === item.pk}
                  onChange={() => {
                    if (value === item.pk) {
                      setValue();
                    } else {
                      setValue(item.pk);
                    }
                  }}
                />
                {value === item.pk && item.subComponment ? (
                  <item.subComponment.componment
                    {...item.subComponment}
                    className={item.subComponment.className}
                    data={item.subComponment.data}
                    value={item.subComponment.value}
                    setValue={item.subComponment.setValue}
                  />
                ) : null}
              </>
            );
          })}
        </div>
      ) : null}

      {inGroup ? null : <div className="filter-menu-border" />}
    </div>
  );
};

const FilterItemsGroupSingleChoice = ({
  title,
  displayTitle,
  data,
  value,
  setValue,
  className,
}) => {
  const [show, setShow] = useState(false);
  // const [checked, setChecked] = useState(value);

  // useEffect(() => {
  //   setChecked(value);
  // }, [value]);

  // useEffect(() => {
  //   setValue(checked);
  // }, [checked]);

  return (
    <>
      <div style={{ display: 'flex' }}>
        <CustomTitleLabel title={displayTitle || title} />
        <ImageButton
          action={() => {
            setShow(!show);
          }}
        />
      </div>
      {show ? (
        <div
          className={`d-flex flex-column filter-list-items-area ${className}`}
        >
          {data?.map((item, index) => {
            return (
              <>
                <item.subComponment.componment
                  {...item.subComponment}
                  className={item.subComponment.className}
                  data={item.subComponment.data}
                  value={item.subComponment.value}
                  setValue={item.subComponment.setValue}
                />
              </>
            );
          })}
        </div>
      ) : null}

      <div className="filter-menu-border" />
    </>
  );
};

const FilterSegment = ({ title, data, value, setValue }) => {
  return (
    <>
      <CustomTitleLabel title={title} />
      <ButtonGroup className="filter-button-group">
        {data.map((item, index) => {
          return (
            <Button
              className={`filter-button-group-item ${
                value.includes(item.pk) ? 'filter-button-selectd' : ''
              } filter-button-selectd-${index}`}
              onClick={() => {
                if (value.includes(item.pk)) {
                  console.log('@@192: remove');
                  setValue(removeElementFromArray(value, item.pk));
                } else {
                  console.log('@@192: add');
                  setValue([...value, item.pk]);
                }
              }}
            >
              {item.name}
            </Button>
          );
        })}
      </ButtonGroup>
    </>
  );
};

const FilterSingle = ({ title, data, value, setValue }) => {
  return (
    <>
      <CustomTitleLabel title={title} />
      <ButtonGroup className="filter-button-group">
        {data.map((item, index) => {
          return (
            <Button
              className={`filter-button-group-item ${
                value === item.pk ? 'filter-button-selectd' : ''
              } filter-button-selectd-${index}`}
              onClick={(e) => {
                // if (value.includes(item.pk)) {
                //   setValue(removeElementFromArray(value, item.pk));
                // } else {
                //   setValue([...value, item.pk]);
                // }
                if (value === item.pk) {
                  setValue();
                } else {
                  setValue(item.pk);
                }
              }}
            >
              {item.name}
            </Button>
          );
        })}
      </ButtonGroup>
    </>
  );
};

const FilterSlider = ({ title, data, value, setValue = () => {} }) => {
  const ageSlider = useRef();

  const PrettoSlider = withStyles({
    root: {
      color: '#4E8DD7',
      height: 1.5,
    },
    thumb: {
      height: 15,
      width: 15,
      backgroundColor: '#4E8DD7',
      marginTop: -6,
      marginLeft: -6,
    },
  })(Slider);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // const handleChange = (value) => {
  //   setValue(value);
  // };

  return (
    <>
      <CustomTitleLabel title={title} />

      {/* <Slider
        range
        min={data ? data[0] : 0}
        max={data ? data[1] : 80}
        trackStyle={{ backgroundColor: '#4E8DD7' }}
        handleStyle={{ borderColor: '#4E8DD7', backgroundColor: '#4E8DD7' }}
        value={value}
        onChange={handleChange}
      /> */}
      <PrettoSlider
        value={value}
        ref={ageSlider}
        min={data ? data[0] : 0}
        max={data ? data[1] : 80}
        // getAriaValueText={valueLabelFormat}
        // valueLabelFormat={valueLabelFormat}
        onChange={handleChange}
        valueLabelDisplay="auto"
        aria-labelledby="non-linear-slider"
        className="filter-slider"
        // defaultValue={age}
      />
      <div className="display-flex-area">
        <label className="filter-age-min filter-age-font">{value[0]}</label>
        <label className="filter-age-max filter-age-font">{value[1]}</label>
      </div>
    </>
  );
};

const FilterDate = ({ title, value, setValue = () => {} }) => {
  return (
    <>
      <CustomTitleLabel title={title} />
      <DatePicker
        value={value ? moment(value, TimeFormater.yearMonthDay) : null}
        format={TimeFormater.dayMonthYearWeek}
        onChange={(date, dateString) =>
          setValue(date?.format(TimeFormater.yearMonthDay))
        }
        // popupStyle={{ position: 'fixed' }}
        getPopupContainer={(trigger) => {
          return trigger.parentNode;
        }}
      />
    </>
  );
};

const FilterDatetime = ({ title, value, setValue = () => {} }) => {
  console.log('@@291: ', value);
  return (
    <>
      <CustomTitleLabel title={title} />
      <DatePicker
        value={value ? moment(value, TimeFormater.yearMonthDayTime) : null}
        showTime={{ format: TimeFormater.timePeriod }}
        format={TimeFormater.yearMonthDayTimePeriod}
        onChange={(date, dateString) => {
          // setValue(dateString);
          setValue(dateString);
        }}
        onOk={(value) => {
          setValue(value?.format(TimeFormater.yearMonthDayTime));
        }}
        // popupStyle={{ position: 'fixed' }}
        // getPopupContainer={(trigger) => {
        //   return trigger.parentNode;
        // }}
      />
    </>
  );
};

const FilterDateRange = ({
  title,
  value,
  setValue = () => {},
  allowEmpty = [false, false],
}) => {
  const dateRange = Array.isArray(value)
    ? value
    : value
    ? value.split(',')
    : [];

  // const [left, setLeft] = useState(0);
  // const [top, setTop] = useState(0);
  // const [popupContainer, setPopupContainer] = useState();
  console.log('@@645: ', dateRange);
  return (
    <>
      {title ? <CustomTitleLabel title={title} /> : null}
      <div style={{ marginTop: '10px' }} />
      <RangePicker
        // defaultValue={[
        //   dateRange[0]
        //     ? moment(dateRange[0], TimeFormater.yearMonthDay)
        //     : null,
        //   dateRange[1]
        //     ? moment(dateRange[1], TimeFormater.yearMonthDay)
        //     : null,
        // ]}
        allowEmpty={allowEmpty}
        defaultPickerValue={[
          dateRange[0] ? moment(dateRange[0], TimeFormater.yearMonthDay) : null,
          dateRange[1] ? moment(dateRange[1], TimeFormater.yearMonthDay) : null,
        ]}
        value={
          !value
            ? []
            : [
                dateRange[0] ? moment(dateRange[0]) : null,
                dateRange[1] ? moment(dateRange[1]) : null,
              ]
        }
        // popupStyle={{ position: 'relative' }}
        format={TimeFormater.dayMonthYearWeek}
        separator={'-'}
        onChange={(dates, dateStrings) => {
          if (dates) {
            const [start, end] = dates;
            setValue(
              (start?.format(TimeFormater.yearMonthDay) || '') +
                ',' +
                (end?.format(TimeFormater.yearMonthDay) || ''),
            );
          } else {
            setValue('');
          }
        }}
        // popupStyle={{ position: 'fixed', left: left, top }}
        // getPopupContainer={(trigger) => {
        //   console.log('@@376: ', trigger);
        //   console.log('@@376-1: ', trigger?.getBoundingClientRect());
        //   // trigger.style.position = 'flex';
        //   // trigger.style.left = ''
        //   setPopupContainer(trigger);
        //   return trigger.parentNode;
        // }}
        // onOpenChange={(open) => {
        //   if (open) {
        //     console.log('@@402');
        //     setTimeout(() => {
        //       console.log('@@405');
        //       setLeft(window.innerWidth - 600);
        //       console.log('@@409: ', popupContainer);
        //       setTop(popupContainer?.getBoundingClientRect().top);
        //     }, 500);
        //   } else {
        //     setLeft(0);
        //     setTop(0);
        //   }
        // }}
      />
    </>
  );
};

const FilterInput = ({ value, setValue }) => {
  return <CustomNumberInput defaultValue={value} setValue={setValue} />;
};
const FilterInputWithLabel = ({ value, setValue, label }) => {
  return (
    <div style={{ marginTop: '10px' }}>
      <input
        type="number"
        min="0"
        onChange={({ target }) => setValue(target.value)}
        className="custom-number-input-long"
        value={value}
        // defaultValue={defaultValue}
        onFocus={() => {}}
      />
      <label>{label}</label>
    </div>
  );
};

export const FilterTypeEnum = {
  choice: FilterItems,
  segment: FilterSegment,
  slider: FilterSlider,
  date: FilterDate,
  dateRange: FilterDateRange,
  singleChoiceSegment: FilterSingle,
  singleChoice: FilterItemsSingleChoice,
  input: FilterInput,
  inputWithLabel: FilterInputWithLabel,
  datetime: FilterDatetime,
  searchableChoice: FilterItemsWithSearchBar,
  searchable2LevelChoice: TwoLevelFilterItemsWithSearchBar,
  groupSingleChoice: FilterItemsGroupSingleChoice,
  searchable: FilterItemsWithSearch,
};

function BaseFilter({
  content = [],
  backAction = () => {},
  init = () => {},
  renderAboveFilterText = () => {},
  disabled = false,
  customFilterAreaClass,
}) {
  const history = useHistory();
  const location = useLocation();
  const parsedSearch = queryString.parse(location.search);

  const reset = () => {
    content.map((item) => {
      switch (item.componment) {
        case FilterTypeEnum.date:
        case FilterTypeEnum.datetime:
          item.setValue(null);
          break;
        case FilterTypeEnum.dateRange:
        case FilterTypeEnum.searchable:
          item.setValue('');
          break;
        case FilterTypeEnum.singleChoice:
          item.setValue();

          item.data.map((val) => {
            if (val.subComponment) {
              val.subComponment.setValue();
            }
          });
          break;
        case FilterTypeEnum.slider:
          item.setValue([0, 0]);
          break;
        case FilterTypeEnum.groupSingleChoice:
          item.data.map((val) => {
            if (val.subComponment) {
              val.subComponment.setValue();
            }
          });
          break;
        default:
          item.setValue([]);
          break;
      }
    });
    init();
    // backAction();
  };

  return (
    <div className={`filter-area ${customFilterAreaClass}`}>
      <div style={{ display: 'grid', width: '100%', height: 'fit-content' }}>
        <div style={{ display: 'flex' }}>
          {renderAboveFilterText()}
          <label className="create-section-title">Filters</label>
          <ImageButton
            action={() => {
              // backAction();

              if (disabled) {
                return;
              }

              let search = {};

              content.map((item) => {
                const key = item.title.toLowerCase().replace(/ /g, '_');
                const value = item.value;

                switch (item.componment) {
                  case FilterTypeEnum.date:
                  case FilterTypeEnum.dateRange:
                  case FilterTypeEnum.datetime:
                  case FilterTypeEnum.singleChoiceSegment:
                  case FilterTypeEnum.searchable:
                    if (value) {
                      search[key] = value;
                    }
                    break;
                  case FilterTypeEnum.singleChoice:
                    if (value) {
                      search[key] = value;
                    }

                    item.data.map((val) => {
                      if (val.pk === value && val.subComponment) {
                        if (
                          val.subComponment.componment ===
                            FilterTypeEnum.choice &&
                          val.subComponment.value.length > 0
                        ) {
                          search[value] = val.subComponment.value.join(',');
                        } else {
                          search[value] = val.subComponment.value;
                        }
                      }
                    });

                    break;
                  case FilterTypeEnum.slider:
                    if (value[1] > 0) {
                      search[key] = value.join(',');
                    }
                    break;
                  case undefined:
                    break;
                  case FilterTypeEnum.groupSingleChoice:
                    item.data.map((val) => {
                      if (val.subComponment) {
                        // val.subComponment.setValue();
                        search[
                          `${val.subComponment.title
                            .trim()
                            .replace(':', '')
                            .toLowerCase()}_${item.title.toLowerCase()}`
                        ] = val.subComponment.value;
                      }
                    });
                    break;
                  default:
                    if (value.length > 0) {
                      search[key] = value.join(',');
                    }
                    break;
                }
              });
              let customSearch = backAction();
              if (customSearch && customSearch.page == null) {
                delete customSearch.page;
              } else {
                customSearch = { ...(customSearch || {}), page: 1 };
              }

              history.push({
                pathname: location.pathname,
                hash: location.hash,
                search: queryString.stringify({
                  rank: parsedSearch['rank'],
                  search: parsedSearch['search'],
                  ...search,
                  ...(customSearch ? customSearch : {}),
                }),
              });
            }}
            image={backImage}
            disabled={disabled}
          />
        </div>

        <button
          type="button"
          className="btn-link filter-reset-button"
          onClick={reset}
        >
          Reset All
        </button>

        {content.map((item) => {
          if (item.customComponment) return item.customComponment;

          return (
            <item.componment
              title={item.displayTitle ? item.displayTitle : item.title}
              displayTitle={item.displayTitle}
              className={item.className || ''}
              data={item.data}
              value={item.value}
              setValue={item.setValue}
              placeholder={item.placeholder}
              filterAction={item.filterAction}
              renderDisplayName={item.renderDisplayName}
              allowEmpty={item.allowEmpty}
            />
          );
        })}
      </div>
    </div>
  );
}

export default BaseFilter;
