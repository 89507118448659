import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import { connect, useDispatch } from 'react-redux';

function Filter({ backAction = () => {}, goodieBagList }) {
  const location = useLocation();
  const dispatch = useDispatch();

  const parsedSearch = queryString.parse(location.search);
  const searchGoodieBagIn = parsedSearch['goodie_bag_in'] || '';

  const [goodieBagIn, setGoodieBagIn] = useState(searchGoodieBagIn);
  const [goodieData, setGoodieData] = useState();

  useEffect(() => {
    dispatch({
      type: 'goodieBags/getAllList',
      payload: { isSimplify: true },
    });
  }, []);

  const content = [
    {
      title: 'Goodie Bag in',
      data: goodieData || goodieBagList,
      value: goodieBagIn,
      setValue: setGoodieBagIn,
      componment: FilterTypeEnum.searchableChoice,
      placeholder: 'Search by ID, Name',
      filterAction: (searchKey) => {
        const newData = goodieBagList?.filter((item) => {
          const name = item.name?.toLowerCase() || '';
          return (
            name.includes(searchKey?.toLowerCase() || '') ||
            String(item.pk) === searchKey
          );
        });
        setGoodieData(newData);
      },
      renderDisplayName: (item) =>
        `${item.pk === 'null' ? '' : `[ID:${item.pk}] `}${item.name}`,
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

const mapPropsToState = (state) => ({
  goodieBagList: state.goodieBags.notPagedAllList,
});

export default connect(mapPropsToState)(Filter);
