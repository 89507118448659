import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../campaign/campaignCreation/PropertiesGeneralSection.scss';
import './RewardSection.scss';
import CustomRadios from '../base/CustomRadios';
import BasePrompt from '../base/prompt/BasePrompt';
import CouponSelectDropdown from '../campaign/campaignCreation/CouponSelectDropdown';
import {
  CustomTitleLabel,
  CustomNumberMutipleInputWithUnit,
} from './CustomBaseComponments';
import { Image } from 'react-bootstrap';
import addImage from '../../assets/images/addx1.png';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import { EarningRuleErrorHandleField } from './EarningRuleHandleError';
import {
  isShowError,
  firstError,
} from '../campaign/campaignCreation/CreateCampaignHandleError';
import {
  CampaignType,
  EarningRuleType,
  EarningRuleRewardType,
} from '../../config/CustomEnums';
import { getCampaignTypeSelectPromptConfig } from '../campaign/campaignCreation/CreateCampaignStepOneConfigs';
import { useCompare } from '../../utils';
import { useHistory, useParams } from 'react-router-dom';
import { PermissionCodes } from '../../config/PermissionCodes';

function RewardSection(props) {
  const radioOptions = [
    { label: 'Coupons', value: EarningRuleRewardType.coupons },
    { label: 'Points', value: EarningRuleRewardType.points },
  ];
  const promptconfig = getCampaignTypeSelectPromptConfig(
    CampaignType.couponsCampaign,
  );

  const defaultValue = props.defaultValue || {};
  const [rewardType, setRewardType] = useState(
    defaultValue.rewardType || EarningRuleRewardType.coupons,
  );
  const [selectedCoupons, setSelectedCoupons] = useState(defaultValue.coupons);
  const [quantity, setQuantity] = useState(defaultValue.quantity);
  const [rewardTypeX, setRewardTypeX] = useState(defaultValue.rewardTypeX);
  const [showCreateNewLinkPrompt, setShowCreateNewLinkPrompt] = useState(false);
  //   const [showCoupons, setShowCoupons] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const vals = { rewardType, coupons: selectedCoupons, quantity, rewardTypeX };
  const hasValchanged = useCompare(vals);
  useEffect(() => {
    if (hasValchanged) {
      dispatch({ type: 'createEarningRules/changeVals', payload: { vals } });
    }
  });
  useEffect(() => {
    // dispatch(createAction('couponList/getCouponTemplateList')());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const onSubmit = props.onSubmit;
  const onSubmitAction = props.onSubmitAction || (() => {});
  const { errorFields } = useSelector((state) => ({
    errorFields: state.createEarningRules.errorFields,
  }));
  const isError = (name) => {
    let names = Array.isArray(name) ? name : [name];
    let errors = [];
    names.forEach((field) => {
      const error = isShowError(
        EarningRuleErrorHandleField[field].name,
        errorFields,
      );
      if (error) {
        errors.push(error);
      }
    });

    return errors[0];
  };
  useEffect(() => {
    if (onSubmit) {
      onSubmitAction(vals);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmit]);

  useEffect(() => {
    firstError(`${EarningRuleErrorHandleField.coupons.name}`, errorFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorFields]);

  const rewardQuality = () => {
    if (rewardType === EarningRuleRewardType.points) {
      return 'number of points';
    }
    return 'coupons per number';
  };

  return (
    <>
      <label className="create-section-title">REWARD</label>
      <CustomTitleLabel title="Reward type" />
      <div style={{ display: 'flex' }}>
        <CustomRadios
          onChange={(value) => {
            setRewardType(value);
          }}
          default={rewardType}
          options={radioOptions}
        />
      </div>

      {rewardType === EarningRuleRewardType.coupons ? (
        <>
          <CouponSelectDropdown
            title={'Coupon set'}
            defaultValue={{
              value: selectedCoupons,
              label: selectedCoupons?.name,
            }}
            setValue={setSelectedCoupons}
            addButtonAction={() => setShowCreateNewLinkPrompt(true)}
            isPrompt
            errors={errorFields}
            errorName={EarningRuleErrorHandleField.coupons.name}
            addButtonInfo={{
              requires: PermissionCodes.addCouponTemplate,
              children: <Image src={addImage} />,
              action: () => setShowCreateNewLinkPrompt(true),
              customClass: 'earning-rule-add-new-link-button',
            }}
          />
        </>
      ) : null}
      {defaultValue.type === EarningRuleType.generalPurchase &&
      rewardType === EarningRuleRewardType.points ? (
        <>
          <CustomTitleLabel title="Reward points" />
          <label className="tips-message">
            Eg. Customer earns 3 points for every 5 dollars spent. HK$ 5 = 3
            points
          </label>
          <CustomNumberMutipleInputWithUnit
            numberTitle={'HK$'}
            firstDefaultValue={rewardTypeX}
            setFirstValue={setRewardTypeX}
            secondDefaultValue={quantity}
            setSecondValue={setQuantity}
            per="="
            unit="points"
          />
          <ErrorFieldMessage
            id={`${EarningRuleErrorHandleField.quantity.name}`}
            error={isError(['rewardTypeX', 'quantity'])}
            message={`${EarningRuleErrorHandleField.quantity.message}`}
          />
        </>
      ) : (
        <>
          <CustomTitleLabel title="Quantity" />
          <div style={{ display: 'flex' }}>
            <input
              type="number"
              onChange={({ target }) => setQuantity(target.value)}
              className="custom-number-input-short"
              defaultValue={quantity}
              onFocus={() => {}}
            />
            <label className="help-message margin-left-10-percent">
              {rewardQuality()}
            </label>
          </div>
          <ErrorFieldMessage
            id={`${EarningRuleErrorHandleField.quantity.name}`}
            error={isError('quantity')}
            message={`${EarningRuleErrorHandleField.quantity.message}`}
          />
        </>
      )}
      <BasePrompt
        show={showCreateNewLinkPrompt}
        closeAction={() => setShowCreateNewLinkPrompt(false)}
        rightButton={{
          text: promptconfig.button,
          action: () => {
            history.push({
              pathname: '/coupons/create',
              state: {
                from: params.id ? `/earns/${params.id}/edit/` : '/earns/create',
                title: 'Continue to Create Earning Rule',
                content: 'You can continue to create the earning rule.',
              },
            });
          },
        }}
        title={promptconfig.title}
        description={
          'You will leave the campaign creation process. After you create a coupon set, you can come back to create a earning rule.'
        }
      />
    </>
  );
}

export default RewardSection;
