import React, { useEffect } from 'react';
import { CampaignErrorHandleField } from './CreateCampaignHandleError';
import PeriodSection from './PeriodSection';
import { campaignAvaliableClue } from './CreateCampaignUtil';
import { useFormContext } from 'react-hook-form';
import { hasError, errorMessage } from '../../base/ErrorFieldMessage';
import { isGoodieBagType } from '../.././../models/CreateCampaignModel';
import { CampaignType } from '../../../config/CustomEnums';

function ActivePeriodSection() {
  const { watch, setValue, formState } = useFormContext();
  const errors = formState.errors;
  const isRedirectionCampaign = watch('isRedirectionCampaign');
  const isAlwaysActivePeriod = watch('isAlwaysActivePeriod');
  const isFollowGoodieBagActivePeriod = watch('isFollowGoodieBagActivePeriod');
  const activeStartDate = watch('activeStartDate');
  const activeEndDate = watch('activeEndDate');
  const campaignType = watch('campaignType');
  const goodieBag = watch('goodieBag') || {};
  const isGoodieBag = isGoodieBagType(campaignType);

  useEffect(() => {
    if (goodieBag.couponAcquirePeriodType === 'RELATIVE') {
      setValue('isFollowGoodieBagActivePeriod', true);
    }
  }, [goodieBag.couponAcquirePeriodType]);

  const timeSection = () => {
    return (
      <>
        {isGoodieBag ? (
          <>
            <label className="create-section-label create-section-label-bottom-space">
              {campaignType === CampaignType.fortuneBagForGoodieBagCampaign
                ? 'Follow the “Active period” from the linked Goodie bag'
                : 'Follow the “Coupon acquire period” from the linked Goodie bag'}
            </label>
            <label className="section-short-description">
              {goodieBag?.displayActivePeriod}
            </label>
          </>
        ) : null}
        {goodieBag.couponAcquirePeriodType === 'RELATIVE' ? null : (
          <PeriodSection
            disabled={goodieBag.couponAcquirePeriodType === 'RELATIVE'}
            title="Active Period"
            startErrorObject={{
              id: 'activeStartDate',
              error: hasError(
                errors,
                campaignType === CampaignType.fortuneBagForGoodieBagCampaign
                  ? 'fortunebagActiveStartDate'
                  : 'activeStartDate',
              ),
              message: errorMessage(
                errors,
                campaignType === CampaignType.fortuneBagForGoodieBagCampaign
                  ? 'fortunebagActiveStartDate'
                  : 'activeStartDate',
              ),
            }}
            endErrorObject={{
              id: 'activeEndDate',
              error: hasError(
                errors,
                campaignType === CampaignType.fortuneBagForGoodieBagCampaign
                  ? 'fortunebagActiveEndDate'
                  : 'activeEndDate',
              ),
              message: errorMessage(
                errors,
                campaignType === CampaignType.fortuneBagForGoodieBagCampaign
                  ? 'fortunebagActiveEndDate'
                  : 'activeEndDate',
              ),
            }}
            endDateChange={(date) =>
              setValue('activeEndDate', date, { shouldDirty: true })
            }
            titleDescription={campaignAvaliableClue('')}
            switchButtonTitle={
              !isGoodieBag ? 'It is an all-time active campaign' : null
            }
            isAlwaysPeriod={
              isGoodieBag ? isFollowGoodieBagActivePeriod : isAlwaysActivePeriod
            }
            switchOnChange={(value) => {
              setValue(
                isGoodieBag
                  ? 'isFollowGoodieBagActivePeriod'
                  : 'isAlwaysActivePeriod',
                value,
                { shouldDirty: true },
              );
            }}
            startDate={activeStartDate}
            startDateChange={(date) =>
              setValue('activeStartDate', date, { shouldDirty: true })
            }
            reminder={
              isGoodieBag
                ? campaignType === CampaignType.fortuneBagForGoodieBagCampaign
                  ? CampaignErrorHandleField.fortunebagActiveEndDateInvalid
                      .message
                  : CampaignErrorHandleField.activeEndDateInvalid.message
                : null
            }
            endDate={activeEndDate}
          />
        )}
        {goodieBag.couponAcquirePeriodType === 'RELATIVE' ? (
          <label className="tips-message">
            The setting of the coupon acquire period from the linked Goodie bag
            is “Number of days since acquired goodie bag”. The active period
            must follow the setting.
          </label>
        ) : null}
      </>
    );
  };

  return (
    <>
      <label className="create-section-title">
        {isRedirectionCampaign ? 'Active Period(Optional)' : 'Active Period'}
      </label>
      <label className="section-short-description">
        Campaign available time. The availble time to acquire the coupon.
      </label>
      {timeSection()}
    </>
  );
}

export default ActivePeriodSection;
