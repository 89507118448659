import React from 'react';
import { ContinueWithBackButtons } from '../base/BottomStepComponent';
import { useDispatch } from 'react-redux';
import ContentSections from '../base/ContentSections';
import GeneralAlertSettingSection from './GeneralAlertSettingSection';
import GeneralAlertStatusSection from './GeneralAlertStatusSection';
import { validate } from './MessageValidate';
import { useFormContext } from 'react-hook-form';

function CreateGeneralAlertStepTwo() {
  const dispatch = useDispatch();
  const { getValues, clearErrors, setError } = useFormContext();

  const sections = [
    <GeneralAlertStatusSection />,
    <GeneralAlertSettingSection />,
  ];
  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 1,
      isBack
    });
    dispatch({
      type: 'generalAlert/stepChange',
      payload: { isBack: isBack, step: 1, isValid },
    });
  };

  return (
    <div className="general_alert_container">
      <ContentSections sections={sections} hidePreview={true} />

      <ContinueWithBackButtons
        backAction={() => {
          stepChangeAction(true);
        }}
        continueAction={() => {
          stepChangeAction(false);
        }}
      />
    </div>
  );
}

export default CreateGeneralAlertStepTwo;
