import { saveAs } from 'file-saver';
import axios from 'axios';

const saveFile = (url, data) => {
  const fileName = url.substr(url.lastIndexOf('/') + 1);
  saveAs(data, fileName);
};

export const downloadZipFiles = (url) => {
  if (url?.includes('.zip')) {
    saveFile(url, url);
    return;
  }
  if (url?.includes('.csv')) {
    axios({ url, responseType: 'blob' }).then((response) => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      const fileName = url.substr(url.lastIndexOf('/') + 1);
      link.setAttribute('download', fileName);
      link.click();
      link.remove();
    });
    return;
  }
  fetch(url, { cache: 'no-cache' })
    .then((response) => response.blob())
    .then((blob) => {
      console.log('response blob:', blob);
      saveFile(url, blob);
    })
    .catch((error) => console.log('save error:', error));
};
