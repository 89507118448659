import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { createAction } from '../../../utils';
import { CustomTitleLabel } from '../../../components/earning/CustomBaseComponments';
import CustomTitleWithInput from '../../../components/base/CustomTitleWithInput';
import {
  hasError,
  ReactHookFormErrorMessage,
} from '../../../components/base/ErrorFieldMessage';
import ContentSections from '../../../components/base/ContentSections';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import { LanguageConfig } from '../../../config/CustomEnums';
import { Button } from 'react-bootstrap';
import CustomTitleWithSwitch from '../../../components/base/CustomTitleWithSwitch';

export default function CreateAttributeTagContent({ attributeTagKeyLength }) {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    setValue,
    watch,
    register,
  } = useFormContext();
  const { languages } = useSelector((state) => ({
    languages: state.language.allList,
  }));

  const [newIndex, setNewIndex] = useState(0);

  useEffect(() => {
    if (attributeTagKeyLength) {
      setNewIndex(attributeTagKeyLength);
    }
  }, [attributeTagKeyLength]);

  const validate = async () => {
    console.log('@@11');
    clearErrors([`translations.en.name`]);
    const [name, attributeTags, delAttributeTags, order] = getValues([
      'translations.en.name',
      'attributeTags',
      'delAttributeTags',
      'order',
    ]);

    if (!name) {
      setError(
        'translations.en.name',
        {
          type: 'require',
          message: 'please input key name',
        },
        { shouldFocus: true },
      );
    }

    if (!order) {
      setError(
        'order',
        {
          type: 'require',
          message: 'please input display order',
        },
        { shouldFocus: true },
      );
    } else if (parseInt(order) <= 0) {
      setError(
        'order',
        {
          type: 'require',
          message: 'display order must largger than 0',
        },
        { shouldFocus: true },
      );
    }

    let tagNames = [];
    Object.keys(attributeTags || {})
      .filter((key) => !delAttributeTags?.includes(key))
      .map((key, index) => {
        const enNameField = `attributeTags.${key}.en.name`;
        if (attributeTags[key].en.name) {
          clearErrors([enNameField]);
        } else {
          tagNames = [...tagNames, enNameField];
          setError(
            enNameField,
            {
              type: 'require',
              message: 'please input tag name',
            },
            { shouldFocus: true },
          );
        }
      });
    console.log('@@72: ', name, tagNames.length, order);
    return name && tagNames.length <= 0 && order && parseInt(order) > 0;
  };

  useEffect(() => {
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
  }, [dispatch]);

  const watchTags = watch('attributeTags');
  const delAttributeTags = watch('delAttributeTags');
  const renderTags = (language) => {
    return Object.keys(watchTags || {})
      ?.filter((key) => !delAttributeTags?.includes(key))
      .map((key, index) => {
        const nameField = `attributeTags.${key}.${language}.name`;
        const watchName = watch(nameField);

        const isEnglish = language === LanguageConfig.english;
        return (
          <>
            <div className="create-section-title">
              Attribute tag #{(index + 1).toString().padStart(3, '0')}
              {isEnglish && index > 0 ? (
                <label
                  className="delete-sub-category-btn"
                  onClick={() => {
                    setValue(
                      `delAttributeTags`,
                      [...(delAttributeTags || []), key],
                      {
                        shouldDirty: true,
                      },
                    );
                  }}
                >
                  Delete
                </label>
              ) : null}
            </div>
            <CustomTitleWithInput
              title="Name"
              defaultValue={watchName}
              setValue={(value) => {
                setValue(nameField, value, {
                  shouldDirty: true,
                });
              }}
              error={
                hasError(errors, nameField)
                  ? {
                      id: 'name',
                      error: hasError(errors, nameField),
                      message: 'please input tag name',
                    }
                  : {}
              }
              extra={register(
                nameField,
                language === LanguageConfig.english
                  ? { required: 'please input name' }
                  : {},
              )}
            />
            <CustomTitleLabel title={'ID'} />
            {!isNaN(key) ? (
              <label
                style={{
                  fontFamily: 'Mulish',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: '#404040',
                }}
              >
                {key}
              </label>
            ) : (
              <label
                style={{
                  fontFamily: 'Mulish',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: '#8C8C8C',
                }}
              >
                The ID will not be available until it is updated.
              </label>
            )}
          </>
        );
      });
  };

  const renderOfferTypeLabelContent = (language) => {
    const watchName = watch(`translations.${language}.name`);
    const watchShowInWechat = watch(`showInWechat`);
    const watchShowInGoodieBag = watch(`showInGoodieBag`);
    const watchOrder = watch(`order`);
    console.log('@@205: ', errors);
    return [
      <>
        <label className="create-section-title">Key tag</label>

        <CustomTitleWithInput
          title="Name"
          defaultValue={watchName}
          setValue={(value) => {
            setValue(`translations.${language}.name`, value, {
              shouldDirty: true,
            });
          }}
          error={
            hasError(errors, `translations.${language}.name`)
              ? {
                  id: 'name',
                  error: hasError(errors, `translations.${language}.name`),
                  message: 'please input a name',
                }
              : {}
          }
          extra={register(
            `translations.${language}.name`,
            language === LanguageConfig.english
              ? { required: 'please input name' }
              : {},
          )}
        />

        {language === LanguageConfig.english ? (
          <>
            <CustomTitleWithSwitch
              title={'Show in filter level 1 in WeChat campaign list'}
              tips={
                'If on, this tag will be displayed directly in filter level 1 in WeChat campaign list.'
              }
              defaultValue={watchShowInWechat}
              setValue={(value) => {
                setValue('showInWechat', !!value, { shouldDirty: true });
              }}
            />
            <CustomTitleWithSwitch
              title={'Show in filter level 1 in Goodie bag campaign list'}
              tips={
                'If on, this tag will be displayed directly in filter level 1 in Goodie bag campaign list.'
              }
              defaultValue={watchShowInGoodieBag}
              setValue={(value) => {
                setValue('showInGoodieBag', !!value, { shouldDirty: true });
              }}
            />
            <CustomTitleWithInput
              title={'Display order'}
              error={
                hasError(errors, `order`)
                  ? {
                      id: 'order',
                      error: hasError(errors, `order`),
                      message: errors?.order?.message,
                    }
                  : {}
              }
              defaultValue={watchOrder}
              setValue={(value) => {
                setValue('order', value, {
                  shouldDirty: true,
                });
              }}
              type={'number'}
              tips={
                'Minimum value is 1. The smaller value, the higher priority.'
              }
              tipsAtBottom
              // extra={{
              //   min: 1,
              // }}
            />
          </>
        ) : null}
      </>,
      ...renderTags(language),
    ];
  };

  return (
    <>
      <ContentSections
        hidePreview
        languageTabContent={{
          containers: languages.map((item) => ({
            container: renderOfferTypeLabelContent(item.code),
            key: item.code,
            title: item.sourceName,
          })),
        }}
      />
      <div className="create-section-and-preview-container row">
        <div style={{ width: '1140px', marginTop: '-60px' }}>
          <Button
            onClick={() => {
              setNewIndex(newIndex + 1);

              const newTag = { [`new${newIndex}`]: {} };
              const temp = languages.forEach((item) => {
                newTag[`new${newIndex}`][item.code] = { name: '' };
              });

              setValue(
                `attributeTags`,
                {
                  ...watchTags,
                  ...newTag,
                },
                {
                  shouldDirty: true,
                },
              );
              // setSubCategories([
              //   ...subCategories,
              //   { id: null, name: '', order: null, translations: [] },
              // ]);
            }}
            className="btn-back-button-common add-btn"
          >
            Add Attribute tag
          </Button>
        </div>
      </div>

      <SaveAndBackButtons
        saveText={params.id ? 'Update' : 'Save'}
        backAction={() => history.goBack()}
        saveAction={async () => {
          const isValid = await validate();
          console.log('@@210: ', isValid, getValues());
          if (isValid) {
            dispatch(
              createAction('attributeTagKey/createOrUpdate')(getValues()),
            );
          }
        }}
      />
    </>
  );
}
