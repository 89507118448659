import React, { useEffect, useState } from 'react';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import CustomEditor from '../base/CustomEditor';
import { connect } from 'react-redux';
import i18n from '../../I18n';
import ImageUploader from '../base/ImageUploader';
import { LanguageConfig } from '../../config/CustomEnums';
import './CreateBrandContent.scss';
import { CreateBrandError } from '../../models/BrandModel';
import { useParams } from 'react-router-dom';
import BasePrompt from '../../components/base/prompt/BasePrompt';
import { useHistory } from 'react-router-dom';
import CustomSwitchButton from '../../components/base/CustomSwitchButton';
import { Collapse, Row } from 'react-bootstrap';
import CustomRadios from '../../components/base/CustomRadios';
import { useFormContext, Controller } from 'react-hook-form';
import { hasError, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import CustomTitleWithInput from '../../components/base/CustomTitleWithInput';
import BaseMutipleSelectorV2 from '../base/BaseMultipleSelectorV2';

export const fortuneBagOptions = {
  optionA: { pk: 1, label: 'Option A - Signed URL', value: 'OPTION_A' },
  optionB: { pk: 2, label: 'Option B - Static URL', value: 'OPTION_B' },
  optionC: { pk: 3, label: 'Option C - Unique URL', value: 'OPTION_C' },
  optionD: { pk: 4, label: 'Option D - Generic URL', value: 'OPTION_D' },
};

const radioOptions = [
  fortuneBagOptions.optionA,
  fortuneBagOptions.optionB,
  fortuneBagOptions.optionC,
  fortuneBagOptions.optionD,
];

function CreateBrandContent({ language, selectedBrand, attributeTagList }) {
  const isEnglish = language === LanguageConfig.english;
  const history = useHistory();
  const params = useParams();
  const { register, watch, setValue, control, formState, clearErrors } =
    useFormContext();
  const { errors } = formState;
  const watchName = isEnglish
    ? watch('name')
    : watch(`translations.${language}.name`);
  const watchDescription = isEnglish
    ? watch('description')
    : watch(`translations.${language}.description`);
  const watchAttributeTags = watch('attributeTags');
  const watchCoverPhoto = watch('coverPhoto');
  const watchFortuneBagChecked = watch('fortuneBagChecked');
  const watchFortuneBagOption = watch('fortuneBagOption');
  const [showPrompt, setShowPrompt] = useState(false);

  const relatedStores = (
    <>
      <div>
        <CustomTitleLabel title={'Related store'} />
      </div>
      <label className="related-store-name">
        {selectedBrand.relatedStoreNames || '-'}
      </label>
    </>
  );

  const logoImage = (
    <div className="second-section">
      <div className="second-section-title">Logo image</div>
      <div className="second-section_description">
        {/* {i18n.t('square_cover_photo_suggest', { locale: language })}
         */}
        Suggested Image Size: 300 px * 300 px, At least : 180 px * 180 px (Less
        than 2MB, support JPG, PNG and GIF only)
      </div>
      <Controller
        control={control}
        name="coverPhoto"
        rules={{ required: CreateBrandError.brandIcon.message }}
        render={() => (
          <ImageUploader
            minWidth={180}
            minHeight={180}
            // maxWidth={300}
            // maxHeight={300}
            images={watchCoverPhoto ? [watchCoverPhoto] : []}
            aspect={1 / 1}
            maxImageNum={1}
            language={LanguageConfig.english}
            onImageStateChange={(newState) => {
              setValue(
                'coverPhoto',
                newState ? (newState?.[0] ? newState?.[0] : null) : null,
                {
                  shouldDirty: true,
                },
              );
            }}
            uploadImageClicked={() => {}}
          />
        )}
      />
      <ReactHookFormErrorMessage errors={errors} id="coverPhoto" />
      {params.id ? relatedStores : null}
    </div>
  );

  const attributeTagsArea = (
    <>
      <Controller
        control={control}
        name="attributeTags"
        render={() => (
          <BaseMutipleSelectorV2
            title={'Attribute tag(Optional)'}
            namespace="attributeTag"
            data={{
              sourceData: attributeTagList,
              targetData: watchAttributeTags,
              targetChange: (value) => {
                setValue('attributeTags', value, {
                  shouldDirty: true,
                });
              },
            }}
            custom={{
              customFilter: (inputValue, option) => {
                const inputIgnoreCase = inputValue?.toLowerCase();
                const optionIgnoreCase = option?.name?.toLowerCase();
                const groupIgnoreCase = option?.tagKey?.name?.toLowerCase();
                return (
                  optionIgnoreCase?.includes(inputIgnoreCase) ||
                  groupIgnoreCase?.includes(inputIgnoreCase)
                );
              },
            }}
            groupKey="tagKey"
            addButton={{
              title: 'Add Attribute tag',
              customClass: 'btn-back-button-common add-remove-botton-style',
              action: () => setShowPrompt(true),
            }}
          />
        )}
      />
      <ReactHookFormErrorMessage errors={errors} id="attributeTags" />
    </>
  );

  const fortuneBagArea = (
    <>
      <label className="create-section-label create-section-label-bottom-space">
        Handle the entitlement of goodie bags
      </label>
      <Controller
        control={control}
        name="fortuneBag"
        render={() => (
          <>
            <CustomSwitchButton
              defaultChecked={watchFortuneBagChecked}
              onChange={(value) => {
                setValue('fortuneBagChecked', value, {
                  shouldDirty: true,
                });
              }}
              disabled={selectedBrand.relatedFortuneBagCampaign}
            />
            <Collapse in={watchFortuneBagChecked}>
              <Row className="handle-entilment-option-row">
                <CustomRadios
                  onChange={(value) => {
                    setValue('fortuneBagOption', value, {
                      shouldDirty: true,
                    });
                  }}
                  default={
                    watchFortuneBagOption || fortuneBagOptions.optionA.value
                  }
                  options={radioOptions}
                  disabled={selectedBrand.relatedFortuneBagCampaign}
                />
              </Row>
            </Collapse>
          </>
        )}
      />
      <ReactHookFormErrorMessage errors={errors} id="fortuneBag" />
    </>
  );

  const noneTranslationArea = () => {
    if (language !== LanguageConfig.english) {
      return null;
    }
    return (
      <>
        {attributeTagsArea}
        {logoImage}
        {fortuneBagArea}
      </>
    );
  };

  return (
    <>
      <div className="first-section-title">
        {i18n.t('general', { locale: 'en' })}
      </div>
      <Controller
        control={control}
        name={isEnglish ? 'name' : `translations.${language}.name`}
        rules={
          isEnglish
            ? {
                required: CreateBrandError.brandName.message,
              }
            : {}
        }
        render={() => (
          <CustomTitleWithInput
            title={i18n.t('name', { locale: 'en' })}
            defaultValue={watchName}
            error={{
              error: hasError(
                errors,
                isEnglish ? 'name' : `translations.${language}.name`,
              ),
            }}
            setValue={(value) => {
              setValue(
                isEnglish ? 'name' : `translations.${language}.name`,
                value,
                {
                  shouldDirty: true,
                },
              );
            }}
          />
        )}
      />
      <ReactHookFormErrorMessage
        errors={errors}
        id={isEnglish ? 'name' : `translations.${language}.name`}
      />
      <CustomTitleLabel
        title={i18n.t('description_optional', { locale: 'en' })}
      />
      <Controller
        control={control}
        name="description"
        render={() => (
          <CustomEditor
            initialValue={watchDescription}
            onValueChange={(value) => {
              setValue(
                isEnglish
                  ? 'description'
                  : `translations.${language}.description`,
                value,
                {
                  shouldDirty: true,
                },
              );
            }}
          />
        )}
      />
      <ReactHookFormErrorMessage errors={errors} id="description" />
      {noneTranslationArea()}
      <BasePrompt
        show={showPrompt}
        closeAction={() => {
          setShowPrompt(false);
        }}
        rightButton={{
          text: `Go to create attribute tag`,
          action: () => {
            setShowPrompt(false);
            history.push({
              pathname: '/tags/create',
              state: {
                from: history.location,
                title: 'Continue to create brand?',
                description: 'You can continue to create the brand.',
              },
            });
          },
        }}
        title={'Go to create a attribute tag?'}
        description={
          'You will leave the brand creation process. After you create a new attribute tag, you can back to this page.'
        }
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  selectedBrand: state.brand.selectedBrand,
  attributeTagList: state.attributeTag.notPagedAllList,
});

export default connect(mapPropsToState)(CreateBrandContent);
