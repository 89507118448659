import React, { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import CreateBrandContent from '../../../components/brand/CreateBrandContent';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import { useHistory, useParams } from 'react-router-dom';
import { createAction } from '../../../utils';
import DeletePrompt from '../../../components/base/DeletePrompt';
import {
  DELETE_RELATED_SECTIONS,
  LanguageConfig,
} from '../../../config/CustomEnums';
import ContentSections from '../../../components/base/ContentSections';
import { useFormContext } from 'react-hook-form';

function CreateBrandStepOne({
  selectedBrand,
  languages,
}) {
  const queryString = require('query-string');
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const { getValues, trigger, setError, clearErrors, setFocus } =
  useFormContext();

  const getLanguageTab = (language) => {
    return <CreateBrandContent language={language} />;
  };

  const gotoBrandList = () => {
    history.push({
      pathname: '/brands',
      search: queryString.stringify({
        page: 1,
        rank: true,
        search: '',
      }),
    });
  };

  return (
    <>
      <ContentSections
        languageTabContent={{
          containers: languages?.map((item) => ({
            container: [getLanguageTab(item.code)],
            key: item.code,
            title: item.sourceName,
          })),
        }}
        hidePreview
      />
      <SaveAndBackButtons
        backAction={gotoBrandList}
        saveTempText={params.id ? 'Delete Brand' : null}
        saveTempAction={() => {
          dispatch(
            createAction('brand/updateState')({
              checkedList: [selectedBrand],
            }),
          );
          setShowDeletePrompt(true);
        }}
        saveText={params.id ? 'Update' : 'Save'}
        saveAction={ async () => {
          let isValid = await trigger(
            [
              'name',
              'coverPhoto',
            ],
            { shouldFocus: true },
          );
          console.log("@83",isValid,getValues())
          if (isValid) {
            if (params.id) {
              dispatch(
                createAction('brand/updateBrand')({
                  values: getValues(),
                  afterActions: gotoBrandList,
                }),
              );
              return;
            }
            dispatch(
              createAction('brand/createBrand')({
                values: getValues(),
                data: {},
              }),
            );
          }
        }}
      />

      <DeletePrompt
        title={'Brand'}
        data={selectedBrand}
        relatedSections={DELETE_RELATED_SECTIONS.BRAND}
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch(
            createAction('brand/delete')({
              afterAction: () => history.push('/brands'),
            }),
          );
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  selectedBrand: state.brand.selectedBrand,
  languages: state.language.notPagedAllList,
});

export default connect(mapPropsToState)(CreateBrandStepOne);
