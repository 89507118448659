import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './CouponsCard.scss';

import { Link } from 'react-router-dom';
import icon from '../../assets/images/customer_overview_vector.svg';
import { Button, Image, ButtonGroup, ProgressBar } from 'react-bootstrap';
import './Top10CouponRedeemCard.scss';
import NoDataPage from '../base/NoDataPage';
import StarredCampaignFilter from '../../containers/dashboard/StarredCampaignFilter';

const segments = [
  { key: 'campaignChartData', name: 'Campaign' },
  { key: 'brandChartData', name: 'Brand' },
  { key: 'districtChartData', name: 'District' },
  { key: 'storeChartData', name: 'Store' },
];

const Top10CouponRedeemCard = () => {
  const couponsData = useSelector((state) => state.dashboard.top10CouponRedeem);
  const [topBy, setTopBy] = useState(segments[0]);
  const [data, setData] = useState(
    couponsData?.[topBy?.key || 'campaignChartData'] || [],
  );
  console.log('@24', couponsData);
  //   const animation = (toWidth) => css`
  //     animation: ${myEffect(toWidth)} 4s ease-in-out;
  //   `;

  useEffect(() => {
    if (couponsData) {
      setData(couponsData?.[topBy?.key] || []);
    }
  }, [couponsData]);

  return (
    <div className="dashboard-coupon-card dashboard-top">
      <div className="dashboard-card-title dashboard-card-top-10-redemption-title">
        TOP 10 redemption
      </div>
      <StarredCampaignFilter
        filterModel="couponRedeemFilter"
        includeGeneralMessage={false}
      />
      <div className="dashboard-segments-buttons">
        <ButtonGroup className="filter-button-group">
          {segments.map((item, index) => {
            return (
              <Button
                className={`filter-button-group-item ${
                  topBy.key === item.key ? 'filter-button-selectd' : ''
                } filter-button-selectd-${index}`}
                onClick={() => {
                  if (topBy.key !== item.key) {
                    setTopBy(item);
                    setData(couponsData?.[item.key] || []);
                  }
                }}
              >
                {item.name}
              </Button>
            );
          })}
        </ButtonGroup>
      </div>

      {data?.length <= 0 ? (
        <NoDataPage />
      ) : (
        <>
          <div className="header">
            <label className="header-0 top-font">{topBy.name}</label>
            <label className="header-1 top-font">No. of coupon used</label>
          </div>
          <div className="top3">
            {data?.slice(0, 3)?.map((item, index) => {
              return (
                <>
                  <div className="header">
                    <label className="top-font header-0 top-title">
                      {item.name}
                    </label>
                    <label className="top-font header-1 top-title">
                      {item.value}
                    </label>
                  </div>
                  <ProgressBar
                    className={`${item.percentage === 0 ? 'non-progress' : ''}`}
                    // css={animation(50)}
                    now={item.percentage}
                    label={
                      <label
                        className={`${
                          item.percentage === 0
                            ? ''
                            : 'dashboard-card-data-percentage'
                        }`}
                      >
                        {`${item.percentage}%`}
                      </label>
                    }
                  />
                  {/* <label>
                {index} - {percentage[index]}
              </label> */}
                </>
              );
            })}
          </div>
          <div className="others">
            {data?.slice(3)?.map((item, index) => {
              return (
                <>
                  <div className="header">
                    <label className="top-font header-0 top-title">
                      {item.name}
                    </label>
                    <label className="top-font header-1 top-title">
                      {item.value}
                    </label>
                  </div>
                  <ProgressBar
                    className={`${item.percentage === 0 ? 'non-progress' : ''}`}
                    now={item.percentage}
                    label={
                      <label
                        className={`${
                          item.percentage === 0
                            ? ''
                            : 'dashboard-card-data-percentage'
                        }`}
                      >
                        {`${item.percentage}%`}
                      </label>
                    }
                  />
                </>
              );
            })}
          </div>
        </>
      )}
      <div className="dashboard-card-data-total">
        <div className="dashboard-card-data-total-line">
          <label>Total used:</label>
          <label className="dashboard-card-data-number">
            {couponsData?.totalCouponRedeemed || 0}
          </label>
        </div>
      </div>
      <Link
        className="dashboard-overview-link coupons-overview-link"
        to={{
          pathname:
            topBy === segments[0] ? '/dashboard_campaign' : '/dashboard_coupon',
        }}
      >
        {/* {topBy === segments[0] ? 'Campaign Overview' : 'Coupon set Overview'} */}
        See detail
        <img className="dashboard-overview-link-icon" src={icon} alt="" />
      </Link>
    </div>
  );
};

export default Top10CouponRedeemCard;
