import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { paymentSettlementBreads } from '../../../config/CustomBreadConfig';
import { NavBarNames } from '../../../config/NavBarNameList';
import { PermissionCodes } from '../../../config/PermissionCodes';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import { 
  PaymentSettlementType,
  ADMIN_TYPE
} from '../../../config/CustomEnums';
import { createAction } from '../../../utils';
import queryString from 'query-string';
import './PaymentSettlementOverviewList.scss';

const TabsConfig = [
    { key: PaymentSettlementType.byMonthly, name: 'By monthly' },
    { key: PaymentSettlementType.byMerchant, name: 'By merchant' },
]

export default function PaymentSettlementOverviewList() {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const {
      totalCount,
      pageInfo,
      totalPage,
      displayFieldsByMonthly,
      displayFieldsByMerchant,
      pagedList,
      adminType,
    } = useSelector((state) => ({
      totalCount: state.paymentSettlementList.totalCount,
      pageInfo: state.paymentSettlementList.pageInfo,
      totalPage: state.paymentSettlementList.totalPage,
      displayFieldsByMonthly: state.paymentSettlementList.listDisplayFieldsByMonthly,
      displayFieldsByMerchant: state.paymentSettlementList.listDisplayFieldsByMerchant,
      pagedList: state.paymentSettlementList.pagedList,
      adminType: state.users.adminType,
    }));
    
    const choiceFields= () =>{
      const searchDict = queryString.parse(location.search);
      return searchDict['type'] === 'MERCHANT' ? displayFieldsByMerchant : displayFieldsByMonthly
    }

    const tabs = [
      {
        name: 'List',
        content: (
          <BaseTabListContainer
            hideTab={false}
            maxTabWidth={200}
            tabs={TabsConfig} 
            defaultTabKey={TabsConfig[0].key}
            pageInfo={pageInfo}
            totalCount={totalCount || 0}
            listContent={
              <BaseListContainer
                fields={choiceFields()}
                model={'paymentSettlementList'}
                permissionGroup={PermissionCodes.viewPaymentSettlement}
                dataList={pagedList}
                totalPage={totalPage}
                deleteInfo={{
                    data: [],
                    title: 'PaymentSettlement',
                    relatedName: '',
                    onComfirm: {},
                    relatedSections: [],
                  }}
                hideActions = {true}
              />
            }
          />
        ),
      },
    ];
  
    useEffect(() => {
      return () => {
        dispatch({ type: 'paymentSettlementList/clearData' });
      };
    }, []);

    useEffect(() => {
      const searchDict = queryString.parse(location.search);
      const searchKey = searchDict['search'];
      const type = searchDict['type'] || 'MONTHLY';
      const stringRank = searchDict['rank'] || 'true';
      const stringPage = searchDict['page'] || 1;
      const rank = stringRank === 'true';
      const page = parseInt(stringPage);
      dispatch({ type: 'paymentSettlementList/clearData' });
      dispatch(
        createAction('paymentSettlementList/getList')({
          ...searchDict,
          type: type,
          rank: rank,
          filterName: searchKey,
          page: page,
        }),
      );
    }, [dispatch, location]);

    if ( adminType == ADMIN_TYPE.MERCHANT_ADMIN ){
      history.replace({
        pathname: `/payment_settlement/byMerchant/merchantAdmin`, 
        hash: "withCouponData"
      });
    }
  
    return (
      <>
        <CustomListComponent
          caution={{
            detail: 'payment settlement',
            title: NavBarNames.paymentSettlement,
          }}
          breadcrumb={<CustomBreadcrumb breadcrumbs={paymentSettlementBreads()}/>}
          tabs={tabs}
          hideTab={true}
        />
      </>
    );
  }