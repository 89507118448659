import React, { useEffect, useState } from 'react';
import './FilterExportPrompt.scss';
import BasePrompt from './BasePrompt';
import { Row } from 'react-bootstrap';
import CustomRadios from '../CustomRadios';
import queryString from 'query-string';
import CustomSwitchButton from '../CustomSwitchButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { useDispatch, useSelector } from 'react-redux';
import { ImportResource } from '../../../models/DownloadImportModel';
import BaseMutipleSelectorV2 from '../BaseMultipleSelectorV2';
import { createAction } from '../../../utils';
import { CampaignType } from '../../../config/CustomEnums';
import { formatDate } from '../../../utils/TimeFormatUtil';

function FilterExportPrompt(props) {
  const dispatch = useDispatch();

  const title = props.title;
  const subTitle = props.subTitle;
  const otherBody = props.otherBody || {};
  const exportFor = props.exportSource;
  const exportFilterConditions = props.exportFilterConditions;

  const [enableSeparate, setEnableSeparate] = useState(false);
  const [includeFilter, setIncludeFilter] = useState({ isInclude: false });
  const [selectExtraIds, setSelectExtraIds] = useState([]);

  const { allCampaignList, allCouponList, allGoodieBagList } = useSelector(
    (state) => ({
      allCampaignList: state.campaignList.notPagedAllList,
      allCouponList: state.couponList.notPagedAllList,
      allGoodieBagList: state.goodieBags.notPagedAllList,
    }),
  );

  useEffect(() => {
    if (props.show) {
      if (exportFor === ImportResource.campaign && !allCampaignList?.length) {
        dispatch(
          createAction('campaignList/getAllList')({
            typeIn: `${CampaignType.generalMessageCampaign},${CampaignType.couponsCampaign}`,
            sort: '-pk',
            pageSize: 1000,
            getAll: 'false',
            // status: 'true',
          }),
        );
      }

      if (
        (exportFor === ImportResource.couponTemplate ||
          exportFor === ImportResource.coupon) &&
        !allCouponList.length
      ) {
        dispatch(
          createAction('couponList/getAllList')({
            sort: '-pk',
            pageSize: 1000,
            getAll: 'false',
          }),
        );
      }

      if (
        (exportFor === ImportResource.goodieBagParticipationRecord ||
          exportFor === ImportResource.goodieBagConfig) &&
        !allGoodieBagList.length
      ) {
        dispatch(
          createAction('goodieBags/getAllList')({
            sort: '-pk',
            pageSize: 100,
            getAll: 'false',
            isSimplify: true,
          }),
        );
      }
    }

    return () => {
      setSelectExtraIds([]);
    };
  }, [props.show]);

  const getSourceData = () => {
    if (exportFor === ImportResource.campaign) {
      return allCampaignList;
    } else if (
      exportFor === ImportResource.couponTemplate ||
      exportFor === ImportResource.coupon
    ) {
      return allCouponList;
    } else if (
      exportFor === ImportResource.goodieBagParticipationRecord ||
      exportFor === ImportResource.goodieBagConfig
    ) {
      return allGoodieBagList;
    }
  };

  const getOtherBody = () => {
    let selectorTitle = '';
    if (exportFor === ImportResource.campaign) {
      // selectorTitle = 'General Message campaign and Coupon campaign';
      selectorTitle = 'Campaign';
    } else if (
      exportFor === ImportResource.goodieBagParticipationRecord ||
      exportFor === ImportResource.goodieBagConfig
    ) {
      selectorTitle = 'Goodie bag';
    } else {
      selectorTitle = 'Coupon set';
    }

    return (
      <>
        <label className={`create-section-label landing-title-margin-bottom `}>
          {otherBody.rangeTitle || 'Select the export range'}
        </label>
        <Row
          style={{ marginLeft: '0px', marginRight: '0px', marginTop: '20px' }}
        >
          <CustomRadios
            onChange={(value) => {
              // includeFilter.isInclude = value;
              setIncludeFilter({ isInclude: value });
            }}
            default={includeFilter.isInclude}
            options={[
              {
                label:
                  exportFor === ImportResource.couponRecord
                    ? 'All items'
                    : 'Select items from the list',
                value: false,
              },
              {
                label: 'All filtered items from the list',
                value: true,
              },
            ]}
          />
        </Row>

        {exportFor !== ImportResource.couponRecord &&
        !includeFilter.isInclude ? (
          <BaseMutipleSelectorV2
            title={selectorTitle}
            size={['shorter']}
            searchPlaceholder={'Search by name or ID'}
            data={{
              sourceData: getSourceData(),
              targetData: selectExtraIds,
              targetChange: setSelectExtraIds,
            }}
            custom={{
              customItem: (value) => (
                <>
                  <label className="slot-title">[{value.pk}]</label>
                  {value.name}
                </>
              ),
              customFilter: (inputValue, option) => {
                const inputIgnoreCase = inputValue?.toLowerCase();
                const optionIgnoreCase = option?.name?.toLowerCase();
                return (
                  optionIgnoreCase?.includes(inputIgnoreCase) ||
                  option.pk == inputValue
                );
              },
            }}
            showSelectAll={title === 'Export report' ? false : true}
          />
        ) : null}

        {otherBody.reportSperate ? (
          <div>
            <label className="create-section-label create-section-label-top-space">
              Export in separate files by{' '}
              {exportFor.replaceAll('_', ' ').toLowerCase()}(s)
            </label>
            <div
              className="second-section_description"
              style={{ marginBottom: 12 }}
            >
              If On, multiple .xlsx files will be downloaded based on different{' '}
              {exportFor.replaceAll('_', ' ').toLowerCase()}. If off, all the
              data merge into one .xlsx file.
            </div>

            <CustomSwitchButton
              defaultChecked={enableSeparate}
              onChange={setEnableSeparate}
            />
          </div>
        ) : null}
      </>
    );
  };

  const exportCSVAction = () => {
    // const parsedSearch = queryString.parse(location.search);
    const exportData = {};
    // if (parsedSearch.attribute_tags) {
    //   exportData.attribute_tags = parsedSearch.attribute_tags.split(',');
    // }
    // if (parsedSearch.brands) {
    //   exportData.brands = parsedSearch.brands.split(',');
    // }
    // if (parsedSearch.status) {
    //   exportData.is_published =
    //     parsedSearch.status === 'Published' ? true : false;
    // }
    // if (parsedSearch.search) {
    //   exportData.search = parsedSearch.search;
    // }
    console.log('@@176: ', selectExtraIds);
    const related = !includeFilter.isInclude
      ? {
          filterConditions: JSON.stringify({
            id_in: selectExtraIds?.map((item) => item.pk).join(','),
          }),
        }
      : {
          // filterConditions: JSON.stringify(exportData),
          filterConditions: JSON.stringify(exportFilterConditions),
        };
    dispatch({
      type: 'downloadAndImport/createDownloadTask',
      payload: {
        from: exportFor,
        related: { ...related, separateFiles: enableSeparate },
      },
    });
    // includeFilter.isInclude = false;
    setIncludeFilter({ isInclude: false });
  };

  return (
    <BasePrompt
      show={props.show}
      closeAction={props.onClose}
      description={props.description}
      basePromptDescriptionClassName="upload-description"
      rightButton={{
        text: 'Export',
        action: () => {
          exportCSVAction();
          props.onClose();
        },
        requires: PermissionCodes.addExportjob,
        disabled: selectExtraIds?.length <= 0 && !includeFilter.isInclude,
      }}
      title={title}
      otherBody={getOtherBody}
    />
  );
}

export default FilterExportPrompt;
