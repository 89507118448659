import React, { useState } from 'react';
import BasePrompt from '../../base/prompt/BasePrompt';
import CheckboxItem from './CheckboxItem';
import { CustomTitleLabel } from '../../earning/CustomBaseComponments';
import './CouponBatchExportPrompt.scss';
import { ImportResource } from '../../../models/DownloadImportModel';
import { useDispatch } from 'react-redux';
import { CouponBatchExportType } from '../../../config/CustomEnums';

function CouponBatchExportPrompt({
  exportBatch = {},
  show = false,
  closeAction = () => {},
  template,
  couponSet,
  exportAction = () => {},
}) {
  const dispatch = useDispatch();
  const [qrCodeOnly, setQrCodeOnly] = useState(false);
  const [codeAndTemplate, setCodeAndTemplate] = useState(false);
  const [qrCodeString, setQrCodeString] = useState(false);
  const hasPhoto = template?.physicalCouponPhoto || template?.eventLogo;
  const otherBody = () => {
    return (
      <>
        <CustomTitleLabel title={'QR code export type'} />
        <label className="coupon-batch-export-tips">
          {`Per QR code export type will be exported as 1 system task. Maximum 3 system tasks
            Please upload the physical coupon photo in “Physical coupon” section, before selecting “QR code and template (.pdf)”.
            The template refers to the LATEST uploaded version only.`}
        </label>
        <div className="coupon-batch-export-checkbox-container">
          <CheckboxItem
            title="QR code only (.png)"
            checked={qrCodeOnly}
            onChange={() => {
              setQrCodeOnly(!qrCodeOnly);
            }}
          />
          <CheckboxItem
            title="QR code and template (.pdf)"
            checked={codeAndTemplate && hasPhoto}
            onChange={() => {
              setCodeAndTemplate(!codeAndTemplate);
            }}
            disabled={!hasPhoto}
          />
          <CheckboxItem
            title="QR code string, coupon ID, serial number (.csv)"
            checked={qrCodeString}
            onChange={() => {
              setQrCodeString(!qrCodeString);
            }}
          />
        </div>
        <CustomTitleLabel
          title={'Per QR code export type, the number of items to be exported'}
        />
        <label className="coupon-batch-export-tips">
          {`Maximum export records per system task is 100,000. If over 100,000, will split into separate system tasks.`}
        </label>
        <label>{exportBatch?.quantity}</label>
      </>
    );
  };
  return (
    <div>
      <BasePrompt
        show={show}
        title={'Export physical coupon'}
        closeAction={closeAction}
        otherBody={otherBody}
        rightButton={{
          text: 'Export',
          action: () => {
            const exportType = [];
            if (qrCodeOnly) {
              exportType.push(CouponBatchExportType.Image);
            }
            if (codeAndTemplate) {
              exportType.push(CouponBatchExportType.Template);
            }
            if (qrCodeString) {
              exportType.push(CouponBatchExportType.CSV);
            }
            console.log('@@83', exportType.join(','));
            dispatch({
              type: 'downloadAndImport/createDownloadTask',
              payload: {
                from: ImportResource.physicalCoupon,
                related: {
                  data: JSON.stringify({
                    template_id: couponSet?.pk,
                    coupon_batch_id: exportBatch?.pk,
                    coupon_batch_index: exportBatch?.index,
                  }),
                  exportTypes: exportType.join(','),
                },
              },
            });
            exportAction();
          },
          disabled: !qrCodeOnly && !codeAndTemplate && !qrCodeString,
        }}
      />
    </div>
  );
}

export default CouponBatchExportPrompt;
