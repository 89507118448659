import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import ListFilterComponent from '../../../components/base/ListFilterComponent';

export default function CustomerMobileListHeader({
  title,
  titleClassName,
  customTitle,
  totalCount,
  searchTitle,
  onSearch,
}) {
  const location = useLocation();
  const history = useHistory();

  return (
    <>
      <div className="header">
        {customTitle ? (
          customTitle()
        ) : (
          <label
            className={`list-section-container-header-title ${
              titleClassName || ''
            }`}
          >
            {title}
          </label>
        )}

        {searchTitle ? (
          <div>
            <label className="create-section-label create-section-label-bottom-space">
              {searchTitle}
            </label>
            <ListFilterComponent
              showSearchButton
              customClass="customer-search-form"
              needDelete
              searchPlaceholder="Please input"
              onSearch={(key) => {
                if (onSearch) {
                  onSearch(key);
                  return;
                }
                history.push({
                  pathname: location.pathname,
                  hash: location.hash,
                  search: queryString.stringify({
                    ...queryString.parse(location.search),
                    ...{ search: key, page: 1 },
                  }),
                });
              }}
            />
          </div>
        ) : null}
      </div>

      {totalCount ? (
        <label className="total-count">Showing {totalCount || 0} entries</label>
      ) : null}
    </>
  );
}
