import React, { useState, useEffect } from 'react';
import { TRANSACTION_RECORD_TYPE } from '../../containers/record/records/TransactionList';
import OnlineImage from '../../assets/images/transaction/online.svg';
import OfflinePOSImage from '../../assets/images/transaction/offlinePOS.svg';
import offlineRewardClaimImage from '../../assets/images/transaction/offlineRewardClaim.svg';
import ContentSections from '../base/ContentSections';
import { OnlyContinueButton } from '../base/BottomStepComponent';
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from '../../utils';
import RedirectToShopifyPrompt from './RedirectToShopifyPrompt';
import { useHistory } from 'react-router-dom';
import { transactionQueryKey } from '../pointRecord/RecordsUrlConfig';
import { APIStatus } from '../../config/CustomEnums';
import Loading from '../base/Loading';
import ItemTypeSet, { MIN_WIDTH, SPACE_BETWEEN } from '../base/ItemTypeSet';
import { getScrollbarWidth, useMainContentWidth } from '../../utils/ScreenUtil';

function CreateTransactionStepOne() {
  const queryString = require('query-string');
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedType, setSelectedType] = useState();
  const [showRedirectPrompt, setShowRedirectPrompt] = useState(false);

  const { defaultSelectedType, status } = useSelector((state) => ({
    defaultSelectedType:
      state.createTransaction.transaction.transactionDisplayType,
    status: state.loading.status,
  }));

  useEffect(() => {
    if (defaultSelectedType !== selectedType) {
      setSelectedType(defaultSelectedType);
    }
  }, [defaultSelectedType, selectedType]);

  const transactionTypeList = [
    {
      id: TRANSACTION_RECORD_TYPE.TYPE_ONLINE,
      title: 'Online',
      description: 'Online shopify transaction',
      image: OnlineImage,
    },
    {
      id: TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS,
      title: 'Offline POS',
      description: 'Physical store transaction',
      image: OfflinePOSImage,
    },
    {
      id: TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM,
      title: 'Offline Reward Claim',
      description: 'Physical store transaction',
      image: offlineRewardClaimImage,
    },
  ];
  const mainContentWidth = useMainContentWidth();
  const fullContainerWith = mainContentWidth - 80 - 60 - getScrollbarWidth();
  const sections = [
    status === APIStatus.calling ? (
      <Loading />
    ) : (
      <div className="create-point-step-one-title">
        <label className="step-one-title">Transaction Type</label>
        <div
          className="point-record-step-type-area transaction-step-type-area"
          style={{
            justifyContent:
              fullContainerWith < (MIN_WIDTH + SPACE_BETWEEN) * 2
                ? 'center'
                : 'flex-start',
          }}
        >
          {transactionTypeList.map((item, index) => {
            return (
              <ItemTypeSet
                key={index}
                item={item}
                selected={selectedType}
                onClick={(id) => {
                  setSelectedType(id);
                  dispatch(
                    createAction('createTransaction/updateTransactionType')({
                      type: id,
                    }),
                  );
                }}
              />
            );
          })}
        </div>
      </div>
    ),
  ];

  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <OnlyContinueButton
        continueAction={() => {
          if (selectedType === TRANSACTION_RECORD_TYPE.TYPE_ONLINE) {
            setShowRedirectPrompt(true);
          } else {
            dispatch(createAction('createTransaction/stepChange')({ step: 0 }));
          }
        }}
        disabledContinue={!selectedType}
      />
      <RedirectToShopifyPrompt
        show={showRedirectPrompt}
        onHide={() => {
          setShowRedirectPrompt(false);
        }}
        onConfirm={() => {
          setShowRedirectPrompt(false);
          history.push({
            pathname: '/transactions',
            search: queryString.stringify({
              [transactionQueryKey.page]: 1,
              [transactionQueryKey.rank]: true,
              [transactionQueryKey.searchKey]: '',
              [transactionQueryKey.tab]: 'all',
            }),
          });
        }}
      />
    </>
  );
}

export default CreateTransactionStepOne;
