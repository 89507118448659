import React from 'react';
import { CampaignType } from '../../../config/CustomEnums';
import PreviewGeneralCampaign from '../../../assets/images/campaign/preview_general_campaign.svg';
import PreviewGeneralCampaignOne from '../../../assets/images/campaign/preview_general_campaign_1.svg';
import PreviewGeneralCampaignTwo from '../../../assets/images/campaign/preview_general_campaign_2.svg';
import PreviewGeneralCampaignThree from '../../../assets/images/campaign/preview_general_campaign_3.svg';
import PreviewGeneralCampaignFour from '../../../assets/images/campaign/preview_general_campaign_4.svg';
import PreviewGeneralCampaignFive from '../../../assets/images/campaign/preview_general_campaign_5.svg';
import PreviewGeneralCampaignSix from '../../../assets/images/campaign/preview_general_campaign_6.svg';

import PreviewCouponCampaign from '../../../assets/images/campaign/preview_coupon_campaign.svg';
import PreviewCouponCampaignOne from '../../../assets/images/campaign/preview_coupon_campaign_1.svg';
import PreviewCouponCampaignTwo from '../../../assets/images/campaign/preview_coupon_campaign_2.svg';
import PreviewCouponCampaignThree from '../../../assets/images/campaign/preview_coupon_campaign_3.svg';
import PreviewCouponCampaignFour from '../../../assets/images/campaign/preview_coupon_campaign_4.svg';
import PreviewCouponCampaignFive from '../../../assets/images/campaign/preview_coupon_campaign_5.svg';
import PreviewCouponCampaignSix from '../../../assets/images/campaign/preview_coupon_campaign_6.svg';
import PreviewCouponCampaignSeven from '../../../assets/images/campaign/preview_coupon_campaign_7.svg';
import PreviewCouponCampaignEight from '../../../assets/images/campaign/preview_coupon_campaign_8.svg';

import PreviewEarnningCampaign from '../../../assets/images/campaign/preview_earning_campaigns.svg';
import PreviewEarnningCampaignOne from '../../../assets/images/campaign/preview_earning_campaigns_1.svg';
import PreviewEarnningCampaignTwo from '../../../assets/images/campaign/preview_earning_campaigns_2.svg';
import PreviewEarnningCampaignThree from '../../../assets/images/campaign/preview_earning_campaigns_3.svg';
import PreviewEarnningCampaignFour from '../../../assets/images/campaign/preview_earning_campaigns_4.svg';
import PreviewEarnningCampaignFive from '../../../assets/images/campaign/preview_earning_campaigns_5.svg';
import PreviewEarnningCampaignSix from '../../../assets/images/campaign/preview_earning_campaigns_6.svg';
import PreviewEarnningCampaignSeven from '../../../assets/images/campaign/preview_earning_campaigns_7.svg';
import PreviewEarnningCampaignEight from '../../../assets/images/campaign/preview_earning_campaigns_8.svg';

import TipsCouponWithOutDetailTitleCom from '../../../assets/images/campaign/coupon_without_detail_title.svg';
import TipsCouponWithOutDetailCom from '../../../assets/images/campaign/coupon_without_detail.svg';
import TipsCouponWithOutInstructionTitleCom from '../../../assets/images/campaign/coupon_without_instruction_title.svg';
import TipsCouponWithOutInstructionCom from '../../../assets/images/campaign/coupon_without_instruction.svg';

import TipsEarningWithOutDetailTitleCom from '../../../assets/images/campaign/earning_without_detail_title.svg';
import TipsEarningWithOutDetailCom from '../../../assets/images/campaign/earning_without_detail.svg';
import TipsEarningWithOutInstructionTitleCom from '../../../assets/images/campaign/earning_without_instruction_title.svg';
import TipsEarningWithOutInstructionCom from '../../../assets/images/campaign/earning_without_instruction.svg';

import TipsGeneralWithOutDetailTitleCom from '../../../assets/images/campaign/general_without_detail_title.svg';
import TipsGeneralWithOutDetailCom from '../../../assets/images/campaign/general_without_detail.svg';

const withOutTitle = (title, defaultText) =>
  `The ${title} section title is optional, if didn’t input this part, then will show “${defaultText}” default in app. Please refer to the following sample.`;
const withOutSection = (title) =>
  `The ${title} section is optional, if didn’t input this part, then will hide this part in app. Please refer to the following sample.`;
const withOutInstructionTitleText = withOutTitle('instruction', 'How to use');
const withOutDetailTitleText = withOutTitle('detail', 'Details');
const withOutInstruction = withOutSection('instruction');
const withOutDetail = withOutSection('detail');

const couponCampaignPreview = {
  name: PreviewCouponCampaignOne,
  shortDescription: PreviewCouponCampaignThree,
  relatedBrand: PreviewCouponCampaignTwo,
  otherPhotos: PreviewCouponCampaignEight,
  instructionTitle: PreviewCouponCampaignFour,
  instructionContent: PreviewCouponCampaignFive,
  detailsTitle: PreviewCouponCampaignSix,
  detailsContent: PreviewCouponCampaignSeven,
};

export const earningRulesCampaignPreview = {
  name: PreviewEarnningCampaignOne,
  shortDescription: PreviewEarnningCampaignThree,
  relatedBrand: PreviewEarnningCampaignTwo,
  otherPhotos: PreviewEarnningCampaignEight,
  instructionTitle: PreviewEarnningCampaignFour,
  instructionContent: PreviewEarnningCampaignFive,
  detailsTitle: PreviewEarnningCampaignSix,
  detailsContent: PreviewEarnningCampaignSeven,
};

const generalMessageCampaignPreview = {
  name: PreviewGeneralCampaignOne,
  shortDescription: PreviewGeneralCampaignFour,
  relatedBrand: PreviewGeneralCampaignThree,
  otherPhotos: PreviewGeneralCampaignTwo,
  detailsTitle: PreviewGeneralCampaignFive,
  detailsContent: PreviewGeneralCampaignSix,
};

export const getPreviewLayoutImages = (campaignType) => {
  if (campaignType === CampaignType.couponsCampaign) {
    return couponCampaignPreview;
  }
  if (campaignType === CampaignType.earningRulesCampaign) {
    return earningRulesCampaignPreview;
  }
  return generalMessageCampaignPreview;
};

export const getTipsImages = (campaignType) => {
  let detailsTitleImage = TipsCouponWithOutDetailTitleCom;
  let detailsContentImage = TipsCouponWithOutDetailCom;
  let instructionTitleImage = TipsCouponWithOutInstructionTitleCom;
  let instructionContentImage = TipsCouponWithOutInstructionCom;

  if (campaignType === CampaignType.generalMessageCampaign) {
    detailsTitleImage = TipsGeneralWithOutDetailTitleCom;
    detailsContentImage = TipsGeneralWithOutDetailCom;
    instructionTitleImage = null;
    instructionContentImage = null;
  }
  if (campaignType === CampaignType.earningRulesCampaign) {
    detailsTitleImage = TipsEarningWithOutDetailTitleCom;
    detailsContentImage = TipsEarningWithOutDetailCom;
    instructionTitleImage = TipsEarningWithOutInstructionTitleCom;
    instructionContentImage = TipsEarningWithOutInstructionCom;
  }
  const tips = {
    detailsTitle: {
      text: withOutDetailTitleText,
      // image: detailsTitleImage,
    },
    detailsContent: {
      text: withOutDetail,
      // image: detailsContentImage,
    },
  };
  if (instructionTitleImage && instructionContentImage) {
    tips.instructionTitle = {
      text: withOutInstructionTitleText,
      image: instructionTitleImage,
    };
    tips.instructionContent = {
      text: withOutInstruction,
      image: instructionContentImage,
    };
  }
  return tips;
};

export const defaultPreviewImage = (campaignType) => {
  if (campaignType === CampaignType.couponsCampaign) {
    return PreviewCouponCampaign;
  }
  if (campaignType === CampaignType.earningRulesCampaign) {
    return PreviewEarnningCampaign;
  }
  return PreviewGeneralCampaign;
};
