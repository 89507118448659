import React, { useEffect, useState, useRef } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import { ArrayStringData } from '../../../utils';

function Filter({ backAction = () => {} }) {
  const location = useLocation();

  const parsedSearch = queryString.parse(location.search);
  const searchScheduledStatus = parsedSearch['scheduled_status'] || '';
  const searchDeliveryStartTime = parsedSearch['start_time'] || '';
  const searchDeliveryEndTime = parsedSearch['end_time'] || '';

  const searchStatus = parsedSearch['status'] || '';
  const searchSeverityLevel = parsedSearch['severity_level'] || '';
  const [scheduledStatus, setScheduledStatus] = useState(
    ArrayStringData(searchScheduledStatus),
  );
  const [severityLevel, setSeverityLevel] = useState(
    ArrayStringData(searchSeverityLevel),
  );
  const [startDate, setDeliveryStartTime] = useState(searchDeliveryStartTime);
  const [endDate, setDeliveryEndTime] = useState(searchDeliveryEndTime);
  const [isActive, setStatus] = useState(ArrayStringData(searchStatus));

  const content = [
    {
      title: 'Scheduled Status',
      data: [
        { name: 'Scheduled', pk: 'Scheduled' },
        { name: 'Saved', pk: 'Saved' },
        { name: 'Expired', pk: 'Expired' },
      ],
      value: scheduledStatus,
      setValue: setScheduledStatus,
      componment: FilterTypeEnum.choice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Severity level',
      data: [
        { name: 'Informative', pk: 'NORMAL' },
        { name: 'Critical', pk: 'CRITICAL' },
      ],
      value: severityLevel,
      setValue: setSeverityLevel,
      componment: FilterTypeEnum.choice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Status',
      data: [
        { name: 'Active', pk: 'true' },
        { name: 'Inactive', pk: 'false' },
      ],
      value: isActive,
      setValue: setStatus,
      componment: FilterTypeEnum.segment,
    },
    {
      title: 'Start time',
      value: startDate,
      setValue: setDeliveryStartTime,
      componment: FilterTypeEnum.datetime,
    },
    {
      title: 'End time',
      value: endDate,
      setValue: setDeliveryEndTime,
      componment: FilterTypeEnum.datetime,
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

export default Filter;
