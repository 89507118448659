import React, { useState } from 'react';
import './FilterExportPrompt.scss';
import BasePrompt from './BasePrompt';
import { Row } from 'react-bootstrap';
import CustomRadios from '../CustomRadios';
import queryString from 'query-string';
import CustomSwitchButton from '../CustomSwitchButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { useDispatch } from 'react-redux';
import { formatDate } from '../../../utils/TimeFormatUtil';

const EXPORT_BY = {
  ALL: '',
  BY_COUPON_SET: 'COUPON_TEMPLATE',
  BY_BRAND: 'BRAND',
};

function BatchFilterExportPrompt(props) {
  const dispatch = useDispatch();

  const title = props.title;
  const subTitle = props.subTitle;
  const otherBody = props.otherBody || {};
  const exportFor = props.exportSource;
  const sperateBy = props.sperateBy;

  const totalTasks = parseInt(
    props.total % 100000 > 0 ? props.total / 100000 + 1 : props.total / 100000,
  );
  const [enableSeparate, setEnableSeparate] = useState(false);
  // const [includeFilter] = useState({ isInclude: true });

  const [exportBy, setExportBy] = useState('');

  const getOtherBody = () => {
    return (
      <div style={props.description ? {} : { marginTop: 9 }}>
        {/* <label className={`create-section-label landing-title-margin-bottom `}>
          {otherBody.rangeTitle || 'Select the export range'}
        </label>
        <Row
          style={{ marginLeft: '0px', marginRight: '0px', marginTop: '20px' }}
        >
          <CustomRadios
            onChange={(value) => {
              includeFilter.isInclude = value;
            }}
            default={includeFilter.isInclude}
            options={[
              {
                label: 'All items',
                value: false,
              },
              {
                label: 'All filtered items from the list',
                value: true,
              },
            ]}
          />
        </Row> */}

        <div className="d-flex flex-column">
          <label
            className={`create-section-label landing-title-margin-bottom `}
          >
            Number of items to be exported (filtered items in listing page)
          </label>
          <label className="tips-message margin-top-8-percent ">
            Maximum export records per system task is 100,000. If over 100,000,
            will split into separate system tasks.
          </label>
          <label className="total-number">
            {props.total.toLocaleString('en-US')}
            {totalTasks > 1
              ? `(Total ${totalTasks} system
            tasks)`
              : ''}
          </label>

          {/* {props.total > 100000 ? (
            <label className="error-field-message-style">
              The number of record you have selected is over the maximum
              (100,000) that can be exported, try modify the filter again.
            </label>
          ) : null} */}
        </div>

        {otherBody.reportSperate ? (
          // <div>
          //   <label className="create-section-label create-section-label-top-space">
          //     Export in separate files by{' '}
          //     {(sperateBy || exportFor).replace('_', ' ').toLowerCase()}(s)
          //   </label>
          //   <div
          //     className="second-section_description"
          //     style={{ marginBottom: 12 }}
          //   >
          //     If On, multiple .xlsx files will be downloaded based on different{' '}
          //     {(sperateBy || exportFor).replace('_', ' ').toLowerCase()}. If
          //     off, all the data merge into one .xlsx file.
          //   </div>

          //   <CustomSwitchButton
          //     defaultChecked={enableSeparate}
          //     onChange={setEnableSeparate}
          //   />
          // </div>
          <div
            style={{
              marginTop: '28px',
            }}
          >
            <label
              className={`create-section-label landing-title-margin-bottom `}
            >
              Export in
            </label>
            <Row
              style={{
                marginLeft: '0px',
                marginRight: '0px',
                marginTop: '8px',
              }}
            >
              <CustomRadios
                onChange={(value) => {
                  setExportBy(value);
                }}
                default={exportBy}
                options={[
                  {
                    label: 'One file',
                    value: '',
                  },
                  {
                    label: 'Separate files by coupon set(s)',
                    value: EXPORT_BY.BY_COUPON_SET,
                  },
                  {
                    label: 'Separate files by brands',
                    value: EXPORT_BY.BY_BRAND,
                  },
                ]}
              />
            </Row>
          </div>
        ) : null}
      </div>
    );
  };

  const exportCSVAction = (index, reportName) => {
    // const related = !includeFilter.isInclude
    //   ? {}
    const related = {
      filterConditions: JSON.stringify(props.filterRelated || {}),
      index: index + 1,
      startCursor: index * 100000,
      reportName: reportName.replace('_-', ''),
      exportBy,
    };
    dispatch({
      type: 'downloadAndImport/createDownloadTask',
      payload: { from: exportFor, related: related },
    });
    // includeFilter.isInclude = true;
  };

  return (
    <BasePrompt
      show={props.show}
      closeAction={props.onClose}
      description={props.description}
      basePromptDescriptionClassName="upload-description"
      rightButton={{
        text: 'Export',
        action: () => {
          // const reportTime = formatDate(Date(), 'yyyyMMDDhhmmss');

          const reportName = `Coupon_record_${
            props.filterRelated.report_date_gte?.replaceAll('-', '') || ''
          }-${props.filterRelated.report_date_lte?.replaceAll('-', '') || ''}`;
          // .replace('_-', '_')
          //
          // .replace('--', '');
          [...Array(totalTasks).keys()].forEach((index) => {
            exportCSVAction(
              index,
              `${reportName}${totalTasks > 1 ? `_${index + 1}` : ''}.${
                exportBy ? 'zip' : 'xlsx'
              }`,
            );
          });

          props.onClose();
        },
        requires: PermissionCodes.addExportjob,
        disabled: props.total <= 0,
      }}
      title={title}
      otherBody={getOtherBody}
    />
  );
}

export default BatchFilterExportPrompt;
