import React, { Component } from 'react';
import { createAction } from '../../../utils';
import './DeleteSingleCouponView.scss';
import { connect } from 'react-redux';
import BaseActionModal from './BaseActionModalView';

@connect()
class DeleteSingleCouponView extends Component {
  getSingleCouponContent = () => {
    return (
      <div className="delete-campaign-pop-content">
        {this.props.singleCouponList?.map((singleCoupon, index) => {
          if (singleCoupon?.dateOfGrant) {
            return (
              <li className="delete-campaign-pop-content-text" key={index}>
                Coupon:{' '}
                {`${this.props.couponSet.name}(${singleCoupon.serialNumber})`}
                <ul className="">
                  <li>
                    {`Coupon transaction: ${this.props.couponSet.name}(${singleCoupon.serialNumber}) at ${singleCoupon.dateOfGrant}`}
                  </li>
                </ul>
              </li>
            );
          } else {
            return (
              <li className="delete-campaign-pop-content-text" key={index}>
                Coupon:{' '}
                {`${this.props.couponSet.name}(${singleCoupon?.serialNumber})`}{' '}
              </li>
            );
          }
        })}
      </div>
    );
  };

  render() {
    return (
      <BaseActionModal
        showModal={this.props.showDeleteView}
        onHide={this.props.onHide}
        modalTitle={`Delete the Single Coupon`}
        modalGeneralContent={`You will not be able to recover it. All of the following objects and their related items will be deleted:`}
        modalDetailContent={this.getSingleCouponContent()}
        action={() => {
          this.props.deleteBtnClicked();
        }}
        actionName={`Delete`}
      />
    );
  }
}

export default DeleteSingleCouponView;
