import React from 'react';
import './SideMenuNav.scss';
import CollapseNav from './CollapseNav';
import { Accordion, Image } from 'react-bootstrap';
import menuLogo from '../../assets/images/menu_logo.svg';
import { tabList } from '../../config/NavBarConfig';
import closeMenuButton from '../../assets/images/close-menu-btn.svg';

function SideMenuNav({ closeMenuAction = () => {} }) {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingLeft: '20px',
          paddingRight: '20px',
        }}
      >
        <Image src={menuLogo} className="side-bar-logo" />
        <Image
          src={closeMenuButton}
          className="side-close-btn"
          onClick={closeMenuAction}
        />
      </div>

      <Accordion className="side-menu-nav-background">
        {tabList.map((tab, index) => (
          <CollapseNav tab={tab} key={`${tab.name}-${index}`} />
        ))}
      </Accordion>
    </div>
  );
}

export default SideMenuNav;
