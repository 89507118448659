import { createModel } from './BaseModel';
import {
  getSystemSettings,
  updateSystemSettings,
} from '../services/SystemSettingApiHelper';
import { apiWithResponseHandle } from './LoadingUtil';

export default createModel({
  namespace: 'systemSetting',
  params: {
    initState: {},
    dataKey: 'systemSettings',
    detailAPI: getSystemSettings,
    parseDetail: (data) => {
      return data;
    },
  },
  states: {},
  reducers: {},
  effects: {
    updateSystemSettings: [
      function* ({ payload }, { put, call }) {
        const serviceArgs = [updateSystemSettings, payload];
        yield apiWithResponseHandle(serviceArgs);
      },
      { type: 'takeLatest' },
    ],
  },
});
