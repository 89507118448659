import { createModel } from './BaseModel';
import {
  createTourCard,
  getTourCardDetail,
  getTourCardList,
  deleteTourCards,
  updateTourCard,
} from '../services/TourCardApiHelper';
import { APIStatus, LanguageConfig, SESSION_KEYS } from '../config/CustomEnums';
import {
  createAction,
  decodedStripedHtml,
  getObjectFromSessionStorage,
  parsePhotoUrlForAPI,
  removeFromSessionStorage,
  saveToSessionStorage,
} from '../utils';
import { apiWithResponseHandle } from './LoadingUtil';

const TOUR_CARD_SESSION_KEY = SESSION_KEYS.TOUR_CARD_SESSION_KEY;

export const saveTourCardToSessionStorage = (data) => {
  saveToSessionStorage(TOUR_CARD_SESSION_KEY, data);
};

export const removeTourCardSessionStroage = () => {
  removeFromSessionStorage(TOUR_CARD_SESSION_KEY);
};

const parseOneTourCard = (apiTourCard) => {
  const translations = {
    [LanguageConfig.english]: {
      title: apiTourCard.title,
      coverPhoto: apiTourCard.coverPhoto,
      description: apiTourCard.description,
    },
  };

  const temp = apiTourCard.translations?.edges.forEach((translation) => {
    const { language, title, description, coverPhoto, pk } = translation.node;
    translations[language] = { title, coverPhoto, pk, description };
  });
  return {
    pk: apiTourCard.pk,
    title: apiTourCard.title,
    coverPhoto: apiTourCard.coverPhoto,
    description: apiTourCard.description,
    translations,
    hasUpdatedDefaultValues: true,
  };
};

const clearnTourCardTranslation = (formTranslationData, oldTranslationData) => {
  const cleanedTranslations = [];
  const temp = Object.keys(formTranslationData).forEach((language) => {
    if (language === LanguageConfig.english) {
      return;
    }
    const translation = formTranslationData[language];
    const cleanedOneTranslation = {
      language,
      title: translation.title,
      description: translation.description,
      coverPhoto: parsePhotoUrlForAPI(translation.coverPhoto),
    };

    if (oldTranslationData) {
      cleanedOneTranslation.id = oldTranslationData[language]?.pk;
    }
    cleanedTranslations.push(cleanedOneTranslation);
  });
  return cleanedTranslations;
};

const cleanTourCard = (formData, oldData) => {
  const enData = formData.translations[LanguageConfig.english];
  const payload = {
    title: enData.title,
    coverPhoto: parsePhotoUrlForAPI(enData.coverPhoto),
    description: enData.description,
    translations: clearnTourCardTranslation(
      formData.translations,
      oldData?.translations,
    ),
  };
  if (oldData) {
    payload.id = oldData.pk;
  }
  return payload;
};

export default createModel({
  namespace: 'tourCard',
  params: {
    dataKey: 'tourCards',
    listAPI: getTourCardList,
    pkNode: 'TourCardNode',
    detailAPI: getTourCardDetail,
    deleteAPI: deleteTourCards,
    parse: (data) =>
      data.tourCards.edges.map((edge) => ({
        ...edge.node,
        displayDescription: decodedStripedHtml(edge.node.description),
      })),
    parseDetail: (data) => parseOneTourCard(data.tourCard),
  },
  states: {
    hasUpdatedDefaultValues: false,
    formHasSubmitted: false,
  },
  reducers: {
    loadTourCardFromCookie(state, { payload }) {
      const logoLabel = getObjectFromSessionStorage(TOUR_CARD_SESSION_KEY);
      if (!logoLabel) {
        return {
          ...state,
          detail: {
            hasUpdatedDefaultValues: false,
          },
        };
      }
      return {
        ...state,
        detail: {
          ...logoLabel,
          hasUpdatedDefaultValues: true,
        },
      };
    },
  },
  effects: {
    createTourCard: [
      function* ({ payload }, { all, select, put }) {
        const { data } = payload;
        const serviceArgs = [createTourCard, cleanTourCard(data)];
        yield put(
          createAction('updateState')({
            apiStatus: APIStatus.calling,
          }),
        );
        function* onSuccess(data) {
          const node = data.createTourCard.node;
          const listDetail = {
            ...node,
            displayDescription: decodedStripedHtml(node.description),
          };
          removeTourCardSessionStroage();
          yield put(
            createAction('updateState')({
              apiStatus: APIStatus.none,
              createStatus: APIStatus.success,
              formHasSubmitted: true,
              detail: listDetail,
            }),
          );
        }
        function* onError(err) {
          yield put(
            createAction('updateState')({
              apiStatus: APIStatus.failed,
            }),
          );
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],
    duplicate: [
      function* ({ payload }, { all, select, put }) {
        const { data, afterAction } = payload;
        const duplicateData = parseOneTourCard(data);
        const title =
          duplicateData.translations?.[LanguageConfig.english]?.title;
        if (title) {
          duplicateData.translations[
            LanguageConfig.english
          ].title = `Copy of ${title}`;
        }
        const serviceArgs = [createTourCard, cleanTourCard(duplicateData)];
        yield put(
          createAction('updateState')({
            apiStatus: APIStatus.calling,
          }),
        );
        function* onSuccess(data) {
          yield put(
            createAction('updateState')({
              apiStatus: APIStatus.success,
            }),
          );
          if (afterAction) {
            yield afterAction();
          }
        }
        function* onError(err) {
          yield put(
            createAction('updateState')({
              apiStatus: APIStatus.failed,
            }),
          );
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],
    updateTourCard: [
      function* ({ payload }, { all, select, put }) {
        const { data } = payload;
        const detail = yield select((state) => state.tourCard.detail);

        console.log(data, detail);
        const serviceArgs = [updateTourCard, cleanTourCard(data, detail)];
        yield put(
          createAction('updateState')({
            apiStatus: APIStatus.calling,
          }),
        );
        function* onSuccess(data) {
          removeTourCardSessionStroage();
          yield put(
            createAction('updateState')({
              apiStatus: APIStatus.success,
              formHasSubmitted: true,
            }),
          );
        }
        function* onError(err) {
          yield put(
            createAction('updateState')({
              apiStatus: APIStatus.failed,
            }),
          );
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],
  },
});
