import { createModel } from './BaseModel';
import {
  APIStatus,
  SESSION_KEYS,
  LanguageTransLation,
  LanguageConfig,
} from '../config/CustomEnums';
import {
  getCampaignGroupList,
  getCampaignGroup,
  updateCampaignGroup,
  createCampaignGroup,
  deleteCampaignGroups,
} from '../services/CampaignGroupApiHelper';
import {
  convertPKToId,
  saveToSessionStorage,
  removeFromSessionStorage,
  getObjectFromSessionStorage,
} from '../utils/index';
import { apiWithResponseHandle } from './LoadingUtil';
import { AllInbox } from '@material-ui/icons';
import { getGoodieBagsList } from '../services/GoodieBagsApiHelper';
import { getCampaignList } from '../services/CampaignAPIHelper';

export const CreateCampaignGroupError = {
  name: {
    name: 'name',
    message: 'Please provide a name.',
  },
  campaigns: {
    name: 'campaigns',
    message: 'Please select fortune bag.',
  },
  perHeadLimit: {
    name: 'perHeadLimit',
    message: 'Please enter a value greater than 0.',
  },
};

export const sessionDataKey = {
  objectKey: SESSION_KEYS.GOODIE_BAG_GROUP_SESSION_KEY,
};

export const saveDataToSessionStorage = (data = {}) => {
  saveToSessionStorage(sessionDataKey.objectKey, data);
};

export const removeDataFromSessionStorage = () => {
  removeFromSessionStorage(sessionDataKey.objectKey);
};

const getInitialState = () => ({
  totalCount: 0,
  couponTemplateList: [],
  currentPage: 0,
  getCouponTemplateListStatus: APIStatus.none,
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  exportFormData: {
    hasNextPage: false,
    couponTemplateList: [],
  },
  couponSetList: [],
  currentPageCouponSetList: [],
  currentLastCursor: '',
  totalPage: 0,
  formHasSubmitted: false,
  checkedList: [],
  campaignGroup: {},
  couponSetListAll: [],
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk', orderField: 'pk' },
    { displayName: 'Name', fieldName: 'name', orderField: 'name' },
    { displayName: 'Related fortune bag', fieldName: 'displayCampaign' },
    { displayName: 'Limit', fieldName: 'perHeadLimit' },
  ],
});

const getTranslation = (translation) => {
  return {
    [translation.language]: {
      ...translation,
      alertMessageOfExceedLimit: translation.alertMessageOfExceedLimit,
    },
  };
};

const parseCampaignGroupTranslations = (translations) => {
  let parsedTranslations = {};
  const translationList = translations?.edges;
  translationList.forEach((item) => {
    parsedTranslations = {
      ...parsedTranslations,
      ...getTranslation(item?.node),
    };
  });
  return parsedTranslations;
};

export const parseCampaignGroup = (data) => {
  const translations = parseCampaignGroupTranslations(data?.translations);
  return {
    id: data.id,
    pk: data.pk,
    name: data.name,
    campaigns: data?.campaigns?.edges?.map((item) => item?.node),
    displayCampaign: data?.campaigns?.edges
      ?.map((item) => `[ID: ${item?.node?.pk}] ${item?.node?.name}`)
      .join(','),
    perHeadLimit: data.perHeadLimit,
    alertMessageOfExceedLimit: data?.alertMessageOfExceedLimit,
    translations,
  };
};

const getCampaignGroupData = (data, languages) => {
  let translations = Object.keys(data?.translations || [])?.map((language) => {
    const translation = data?.translations?.[language];
    return {
      id: translation?.pk,
      alertMessageOfExceedLimit: translation?.alertMessageOfExceedLimit,
      language,
    };
  });
  const otherLanguages = languages?.filter(
    (language) =>
      !Object.keys(data?.translations || [])?.some(
        (item) => item === language?.code,
      ),
  );
  otherLanguages.forEach((item) => {
    translations = [
      ...translations,
      {
        alertMessageOfExceedLimit:
          item?.code === LanguageConfig.english
            ? data.alertMessageOfExceedLimit || ''
            : '',
        language: item.code,
      },
    ];
  });
  let result = {
    name: data?.name,
    perHeadLimit: data?.perHeadLimit,
    alertMessageOfExceedLimit: data?.alertMessageOfExceedLimit,
    campaigns: data?.campaigns?.map((item) => item.pk),
    translations,
  };
  if (data?.pk) {
    result = {
      ...result,
      id: data?.pk,
    };
  }
  console.log('@161', result);
  return result;
};

export default createModel({
  namespace: 'campaignGroups',
  states: getInitialState(),
  params: {
    sessionKey: 'campaignGroup',
    dataKey: 'campaignGroups',
    listAPI: getCampaignGroupList,
    deleteAPI: deleteCampaignGroups,
    parse: (data) =>
      data?.campaignGroups?.edges?.map((item) => parseCampaignGroup(item.node)),
    objectKey: 'campaignGroups',
  },
  reducers: {
    clearData(state, { payload }) {
      if (payload?.deleteSession) {
        removeDataFromSessionStorage();
      }
      return { ...state, ...getInitialState() };
    },
    loadCampaignGroupFromCookie(state, { payload }) {
      const campaignGroup = getObjectFromSessionStorage(
        sessionDataKey.objectKey,
      );
      console.log('@@248: ', campaignGroup);
      if (!campaignGroup) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        campaignGroup,
        hasUpdatedDefaultValues: true,
      };
    },
  },
  effects: {
    getCampaignGroup: [
      function* ({ payload }, { put, all }) {
        const { campaignGroupPK } = payload;
        const campaignGroupID = convertPKToId(
          'CampaignGroupNode',
          campaignGroupPK,
        );
        const serviceArgs = [getCampaignGroup, campaignGroupID];

        function* afterAction() {
          yield put({
            type: 'updateState',
            payload: {
              apiStatus: APIStatus.success,
            },
          });
        }

        function* onSuccess(data) {
          const campaignGroupData = data.campaignGroup;
          const campaignGroup = parseCampaignGroup(campaignGroupData);
          yield all([
            put({
              type: 'updateState',
              payload: {
                campaignGroup,
                hasUpdatedDefaultValues: true,
                apiStatus: APIStatus.calling,
              },
            }),
            put({
              type: 'updateProperty',
              payload: {
                others: {
                  groupIn: [campaignGroupPK],
                },
                pageSize: 100,
                page: 1,
                stateName: 'campaignGroup',
                dataKey: 'campaigns',
                updateKey: 'campaigns',
                updateApi: getCampaignList,
                isAll: true,
                afterAction,
              },
            }),
          ]);
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    createOrUpdateCampaignGroup: [
      function* ({ payload }, { select, put, call }) {
        const languages = yield select(
          (state) => state.language.notPagedAllList,
        );
        const campaignGroup = payload.campaignGroup;
        console.log('@179', campaignGroup);
        const campaignGroupData = getCampaignGroupData(
          campaignGroup,
          languages,
        );
        if (campaignGroupData?.pk) {
          campaignGroupData.id = campaignGroupData.pk;
        }

        const serviceArgs = [
          campaignGroup?.pk ? updateCampaignGroup : createCampaignGroup,
          campaignGroupData,
        ];
        function* onSuccess() {
          removeFromSessionStorage(sessionDataKey.objectKey);

          yield put({
            type: 'updateState',
            payload: {
              formHasSubmitted: true,
            },
          });

          const afterActions = payload.afterActions || (() => {});
          yield afterActions();
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
});
