import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import './ManageSearchContent.scss';
import ContentSections from '../base/ContentSections';
import Loading from '../base/Loading';
import NextStepPrompt from '../base/prompt/NextStepPrompt';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import { OnlySaveButton } from '../base/BottomStepComponent';
import { APIStatus } from '../../config/CustomEnums';

function ManageSearchContent() {
  const dispatch = useDispatch();

  const { watch, getValues, setValue, formState, clearErrors, setError } =
    useFormContext();
  const errors = formState.errors;
  const fieldError = Object.keys(errors || []).length;

  const watchCampaignNameWeight = watch('campaignNameWeight');
  const watchCampaignShortDescriptionWeight = watch(
    'campaignShortDescriptionWeight',
  );
  const watchCampaignCategoryNameWeight = watch('campaignCategoryNameWeight');
  const watchBrandNameWeight = watch('brandNameWeight');
  const watchStoreNameWeight = watch('storeNameWeight');
  const watchStoreAddressWeight = watch('storeAddressWeight');
  const watchTourCardNameWeight = watch('tourCardNameWeight');
  const watchAttributeTagNameWeight = watch('attributeTagNameWeight');
  const watchAttributeTagKeyNameWeight = watch('attributeTagKeyNameWeight');
  const watchSearchTagsWeight = watch('searchTagsWeight');
  const watchSemTagsWeight = watch('semTagsWeight');

  const watchThreshold = watch('threshold');

  const [showPrompt, setShowPrompt] = useState(true);

  const { apiStatus, formHasSubmitted } = useSelector((state) => ({
    apiStatus: state.searchSetting.apiStatus,
    formHasSubmitted: state.searchSetting.formHasSubmitted,
  }));

  const handleClose = () => {
    setShowPrompt(false);
  };

  const saveButtonClicked = () => {
    setShowPrompt(true);
    let data = getValues();
    let toUpdateData = {};
    for (const [key, value] of Object.entries(data)) {
      if (key !== 'pk') {
        toUpdateData[key] = value || null;
      }
    }
    dispatch({
      type: 'searchSetting/updateSearchSettings',
      payload: toUpdateData,
    });
  };

  const onNumberInputKeyDown = (event) => {
    console.log('@@91: ', event.keyCode);
    if (
      event.keyCode === 189 || //-
      event.keyCode === 109 || //-
      event.keyCode === 69 || //e
      event.keyCode === 187 || //=
      event.keyCode === 107 || //+
      event.keyCode === 110 //.
    ) {
      event.preventDefault();
      return false;
    }
  };

  const weightsFields = [
    {
      title: 'Campaign name',
      value: watchCampaignNameWeight,
      onChange: (value) => setValue('campaignNameWeight', value),
    },
    {
      title: 'Short description',
      value: watchCampaignShortDescriptionWeight,
      onChange: (value) => setValue('campaignShortDescriptionWeight', value),
    },
    {
      title: 'Brand name',
      value: watchBrandNameWeight,
      onChange: (value) => setValue('brandNameWeight', value),
    },
    {
      title: 'Store name',
      value: watchStoreNameWeight,
      onChange: (value) => setValue('storeNameWeight', value),
    },
    {
      title: 'Tour cards name',
      value: watchTourCardNameWeight,
      onChange: (value) => setValue('tourCardNameWeight', value),
    },
    {
      title: 'Address for frontend display',
      value: watchStoreAddressWeight,
      onChange: (value) => setValue('storeAddressWeight', value),
    },
    {
      title: 'Attribute keys',
      value: watchAttributeTagKeyNameWeight,
      onChange: (value) => setValue('attributeTagKeyNameWeight', value),
    },
    {
      title: 'Attribute tags',
      value: watchAttributeTagNameWeight,
      onChange: (value) => setValue('attributeTagNameWeight', value),
    },
    {
      title: 'Search tags',
      value: watchSearchTagsWeight,
      onChange: (value) => setValue('searchTagsWeight', value),
    },
    {
      title: 'SEM tags',
      value: watchSemTagsWeight,
      onChange: (value) => setValue('semTagsWeight', value),
    },
    {
      title: 'Category name',
      value: watchCampaignCategoryNameWeight,
      onChange: (value) => setValue('campaignCategoryNameWeight', value),
    },
  ];

  const content = (
    <>
      <div className="manage-search-container">
        <div>
          <div className="row">
            <div className="col">
              <label className="create-section-label">Fields</label>
            </div>
            <div className="col">
              <label className="create-section-label">Weights</label>
            </div>
          </div>
        </div>
        <label className="tips-message">
          Please input positive numbers or 0 (blank = 0). If set weight as 0,
          system will exclude the field when calculate the score.
        </label>
        <div>
          {weightsFields?.map((item) => {
            return (
              <>
                <div className="row">
                  <div className="col">
                    <label className="create-section-label">{item.title}</label>
                  </div>
                  <div className="col">
                    <input
                      type="number"
                      min={0}
                      onChange={({ target }) => item.onChange(target.value)}
                      value={item.value}
                      className={`custom-markdown-area-title custom-markdown-area-title-short custom-number-input-short`}
                      onKeyDown={onNumberInputKeyDown}
                    />
                  </div>
                </div>
              </>
            );
          })}
        </div>

        <div className="d-flex flex-column">
          <CustomTitleLabel title="Threshold" />
          <label className="tips-message">
            {
              'Only campaigns scoring >= threshold will be resulted after search. Please input positive numbers or 0 (blank = 0).'
            }
          </label>
          <input
            type="number"
            onChange={({ target }) => {
              setValue('threshold', target.value);
            }}
            value={watchThreshold}
            className={`custom-markdown-area-title custom-markdown-area-title-short custom-number-input-short`}
            onKeyDown={onNumberInputKeyDown}
          />
        </div>
      </div>
    </>
  );
  return (
    <>
      {apiStatus !== APIStatus.calling ? (
        <>
          <ContentSections
            fieldsError={fieldError}
            sections={[content]}
            hidePreview={true}
          />
          <OnlySaveButton
            customBaseClassName="flex-end-save-button"
            saveAction={saveButtonClicked}
            saveText="Save"
          />
          {formHasSubmitted && apiStatus === APIStatus.success ? (
            <NextStepPrompt
              show={showPrompt}
              title="Successfully Saved!"
              // description="Copuon is successfully saved."
              steps={null}
              buttons={[
                {
                  text: 'OK',
                  action: handleClose,
                },
              ]}
            />
          ) : null}
        </>
      ) : (
        <ContentSections sections={[<Loading />]} hidePreview={true} />
      )}
    </>
  );
}

export default ManageSearchContent;
