import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ImportResource } from '../../../models/DownloadImportModel';
import { createAction } from '../../../utils';
import CustomSwitchButton from '../../base/CustomSwitchButton';
import BaseMutipleSelectorV2 from '../../base/BaseMultipleSelectorV2';
import { CustomTitleLabel } from '../../earning/CustomBaseComponments';
import BasePrompt from '../../base/prompt/BasePrompt';
import CheckboxItem from './CheckboxItem';

export const EXPORT_BY = {
  coupon: 'COUPON',
  campaign: 'CAMPAIGN',
};

const ExportCouponView = ({
  show,
  onHide,
  allData,
  campaigns,
  dispatch,
  exportBy,
}) => {
  const [includeTransaction, setIncludeTransaction] = useState(false);
  const [includeForm, setIncludeForm] = useState(false);
  const [selectedCouponTemplates, setSelectedCouponTemplates] = useState([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [enableSeparate, setEnableSeparate] = useState(true);
  useEffect(() => {
    if (!show) {
      setIncludeTransaction(false);
      setIncludeForm(false);
      setSelectedCouponTemplates([]);
      setSelectedCampaigns([]);
      setEnableSeparate(true);
    }
  }, [show, dispatch]);

  useEffect(() => {
    dispatch({
      type: 'campaignList/getAllList',
      payload: {
        sort: '-pk',
        pageSize: 1000,
      },
    });
    return () => dispatch(createAction('campaignList/clearData')());
  }, []);

  useEffect(() => {
    if (!allData?.length && show) {
      dispatch({
        type: 'couponList/getAllList',
        payload: {
          sort: '-pk',
          pageSize: 1000,
          getAll: 'false',
        },
      });
    }
  }, [allData?.length, show]);

  const body = () => (
    <>
      {exportBy === EXPORT_BY.campaign ? (
        <>
          <div>
            <BaseMutipleSelectorV2
              size={['shorter']}
              title="Campaign(s)"
              namespace="campaignList"
              searchPlaceholder="Search by name or ID"
              data={{
                sourceData: campaigns,
                targetData: selectedCampaigns,
                targetChange: setSelectedCampaigns,
              }}
              custom={{
                customItem: (value) => (
                  <>
                    <label className="slot-title">[{value.pk}]</label>
                    {value.name}
                  </>
                ),
                customFilter: (inputValue, option) => {
                  const inputIgnoreCase = inputValue?.toLowerCase();
                  const optionIgnoreCase = option?.name?.toLowerCase();
                  return (
                    optionIgnoreCase?.includes(inputIgnoreCase) ||
                    option.pk == inputValue
                  );
                },
              }}
            />
          </div>
        </>
      ) : (
        <>
          <div>
            <CustomTitleLabel title="Coupons(optional)" />
            <CheckboxItem
              title="Include customer transaction record"
              checked={includeTransaction}
              onChange={() => {
                setIncludeTransaction(!includeTransaction);
              }}
            />
            <CheckboxItem
              title="Include data from “high-value coupon form”"
              checked={includeForm}
              onChange={() => {
                setIncludeForm(!includeForm);
              }}
            />
          </div>
          <div>
            <BaseMutipleSelectorV2
              title="Coupon set"
              size={['shorter']}
              namespace="campaignList"
              searchPlaceholder="Search by name or ID"
              data={{
                sourceData: allData,
                targetData: selectedCouponTemplates,
                targetChange: setSelectedCouponTemplates,
              }}
              custom={{
                customItem: (value) => (
                  <>
                    <label className="slot-title">[{value.pk}]</label>
                    {value.name}
                  </>
                ),
                customFilter: (inputValue, option) => {
                  const inputIgnoreCase = inputValue?.toLowerCase();
                  const optionIgnoreCase = option?.name?.toLowerCase();
                  return (
                    optionIgnoreCase?.includes(inputIgnoreCase) ||
                    option.pk == inputValue
                  );
                },
              }}
            />
          </div>
        </>
      )}
      <div>
        <label className="create-section-label create-section-label-top-space">
          Export in separate files by coupon set(s)
        </label>
        <div
          className="second-section_description"
          style={{ marginBottom: 12 }}
        >
          If On, multiple CSV files will be downloaded based on different coupon
          set. If off, all the data merge into one CSV file.
        </div>

        <CustomSwitchButton
          defaultChecked={enableSeparate}
          onChange={setEnableSeparate}
        />
      </div>
    </>
  );
  return (
    <BasePrompt
      show={show}
      closeAction={onHide}
      rightButton={{
        text: 'Export',
        action: () => {
          console.log('@export');
          let data = {};
          if (exportBy === EXPORT_BY.campaign) {
            data = {
              from: ImportResource.couponRecord,
              related: {
                targetCampaignIds: selectedCampaigns.map((item) => item.pk),
                separateFiles: enableSeparate,
              },
            };
          } else {
            data = {
              from: ImportResource.coupon, //ImportResource.exportCouponTemplate,
              related: {
                includeTransaction,
                includeHighValueCouponForm: includeForm,
                targetCouponTemplateIds: selectedCouponTemplates.map(
                  (item) => item?.pk,
                ),
                separateFiles: enableSeparate,
              },
            };
          }

          dispatch(createAction('downloadAndImport/createDownloadTask')(data));
          onHide();
        },
        disabled:
          (exportBy !== EXPORT_BY.campaign &&
            selectedCouponTemplates.length === 0) ||
          (exportBy === EXPORT_BY.campaign && selectedCampaigns.length === 0),
      }}
      title="CSV configuration"
      otherBody={body}
    />
  );
};

const mapPropsToState = (state) => ({
  allData: state.couponList.notPagedAllList,
  campaigns: state.campaignList.notPagedAllList,
});

export default connect(mapPropsToState)(ExportCouponView);
