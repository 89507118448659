import React, { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import GeneralSection from '../../../components/goodieBagGroup/GeneralSection';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import { useHistory, useParams } from 'react-router-dom';
import { createAction } from '../../../utils';
import ContentSections from '../../../components/base/ContentSections';
import { useFormContext } from 'react-hook-form';
import queryString from 'query-string';
import { validate } from './GoodieBagGroupValidate';

function CreateGoodieBagGroupStepOne({ languages, goodieBagGroup }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const { getValues, setError, clearErrors } = useFormContext();

  const getLanguageTab = (language) => {
    return <GeneralSection language={language} languages={languages} />;
  };

  const gotoGoodieBagGroupList = () => {
    history.push({
      pathname: '/goodie_bag_groups',
      search: queryString.stringify({
        page: 1,
        rank: true,
        search: '',
      }),
    });
  };

  return (
    <>
      <ContentSections
        languageTabContent={{
          containers: languages?.map((item) => ({
            container: [getLanguageTab(item.code)],
            key: item.code,
            title: item.sourceName,
          })),
        }}
        hidePreview
      />
      <SaveAndBackButtons
        backAction={gotoGoodieBagGroupList}
        saveText={params.id ? 'Update' : 'Save'}
        saveAction={async () => {
          const isValid = validate({
            getValues: getValues,
            setError,
            clearErrors,
          });
          console.log('@83', isValid, getValues());
          if (isValid) {
            dispatch({
              type: 'goodieBagGroups/createOrUpdateGoodieBagGroup',
              payload: {
                goodieBagGroup: getValues(),
                afterActions: params.id
                  ? () => {
                      gotoGoodieBagGroupList();
                    }
                  : () => {},
              },
            });
          }
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  languages: state.language.notPagedAllList,
  goodieBagGroup: state.goodieBagGroups.goodieBagGroup,
});

export default connect(mapPropsToState)(CreateGoodieBagGroupStepOne);
