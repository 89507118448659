import { BaseHelper } from './BaseGraphQLAPIHelper';

export const CUSTOMER_PAGE_DATA_TYPE = {
  INTEREST_PREFERENCE: 'interestPreferenceOfCustomersTableWithPagination',
  CAMPAIGN_CATEGORY: 'campaignCategoryOfTimesCouponAcquiredTableWithPagination',
  ATTRIBUTE_TAGE: 'attributeTagOfTimesCouponAcquiredTableWithPagination',
};

const commonTypes = `totalNumberOfCustomers {
  value
  change
}
totalNumberOfNewCustomers {
  value
  change
}
totalNumberOfNewCustomersChart {
  periods
  points
}
totalNumberOfActiveCustomers {
  value
  change
}
totalNumberOfCustomersWithAge {
  value
  change
}
totalNumberOfCustomersAgeChart {
  name
  count
}
totalNumberOfCustomersAgeTable
totalNumberOfMaleCustomers {
  value
  change
}
totalNumberOfFemaleCustomers {
  value
  change
}
top5NationalityOfCustomersChart {
  name
  count
}
totalNumberOfCustomersWithInterestPreference {
  value
  change
}
interestPreferenceOfCustomersChart {
  periods
  points
}
campaignCategoryOfTimesCouponAcquiredChart {
  periods
  points
}
totalNumberOfCustomersWithNationality {
  value
  change
}
totalNumberOfPublishedCampaigns {
  value
  change
} 
totalNumberOfTimesCouponAcquired {
  value
  change
}
top5NationalityOfCustomersTable`;

const attributesRequest = `attributeTagOfTimesCouponAcquiredChart {
  periods
  points
}
totalNumberOfTimesCouponAcquired {
  value
  change
}`;

const categoryRequest = `
campaignCategoryOfTimesCouponAcquiredChart {
  periods
  points
}
totalNumberOfTimesCouponAcquired {
  value
  change
}
`;

const chartRequest = `totalNumberOfCustomers {
  value
  change
}
totalNumberOfNewCustomers {
  value
  change
}
totalNumberOfNewCustomersChart {
  periods
  points
}
totalNumberOfActiveCustomers {
  value
  change
}
totalNumberOfCustomersWithInterestPreference {
  value
  change
}
interestPreferenceOfCustomersChart {
  periods
  points
}
totalNumberOfTimesCouponAcquired {
  value
  change
}

totalNumberOfCustomersWithAge {
  value
  change
}
totalNumberOfCustomersAgeChart {
  name
  count
}
`;

const queryBody = (tableKey, isAttribute, isChart, isCategory) => {
  if (isAttribute) {
    return attributesRequest;
  }

  if (isCategory) {
    return categoryRequest;
  }

  if (isChart) {
    return chartRequest;
  }

  if (!tableKey) {
    return commonTypes;
  }

  return `${tableKey} {
    totalCount
    tableData
  }`;
};

export const getCustomerData = ({
  startDate,
  endDate,
  orderBy,
  searchName,
  pageSize = 20,
  pageNumber = 1,
  attributeTags,
  tableKey,
  isAttribute,
  isChart,
  campaginTypes,
  isStarred,
  isCategory,
}) => {
  const body = queryBody(tableKey, isAttribute, isChart, isCategory);
  let filter = `fromDate: "${startDate}", toDate: "${endDate}", pageSize: ${pageSize}, pageNumber: ${pageNumber}`;
  if (searchName) {
    filter += `, searchName: "${searchName}"`;
  }
  if (attributeTags) {
    filter += `, attributeTags: "${attributeTags}"`;
  }
  if (orderBy) {
    filter += `, orderBy: "${orderBy}"`;
  }
  if (campaginTypes?.length > 0) {
    filter += `, campaignTypeIn: "${campaginTypes?.join(',')}"`;
  }

  if (isStarred) {
    filter += `, campaignIsStarred: ${isStarred}`;
  }

  const query = `{
    analyticsData(${filter}) { 
      ${body}
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};
